import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationTariff } from '@products/models/confirmation.model';
import { CurrencySettingsModel } from '@store/customization/customization.interfaces';

@Component({
  selector: 'app-confirmation-workshop-prices',
  templateUrl: './confirmation-workshop-prices.component.html',
  styleUrls: ['../web-shop-confirmation.component.scss', './confirmation-workshop-prices.component.scss']
})
export class ConfirmationWorkshopPricesComponent implements OnInit {
  @Input() confirmationTariff: ConfirmationTariff;
  @Input() currencySettings: CurrencySettingsModel;

  constructor() {}

  ngOnInit() {}
}
