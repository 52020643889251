import { OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isProductTypeTariff } from '../_pages/products/models/product-selection.model';
import { getActiveVoucherProducts } from '../shared/services-with-reducers/products/product-selection/product-selection.selectors';
var SponsorListComponent = /** @class */ (function () {
    function SponsorListComponent(store) {
        this.store = store;
        this.hasSponsors = false;
        this.vouchers = [];
        this.subscriptions = new Subscription();
    }
    SponsorListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.store.pipe(select(getActiveVoucherProducts)).subscribe(function (products) {
            _this.hasSponsors = products.some(function (product) {
                return isProductTypeTariff(product) &&
                    product.tariffType.tariffs.some(function (tariff) { return !!tariff.sponsors && !!tariff.sponsors.length; });
            });
            products.forEach(function (product) {
                var _a;
                return isProductTypeTariff(product) && (_a = _this.vouchers).push.apply(_a, product.tariffType.tariffs);
            });
        }));
    };
    SponsorListComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return SponsorListComponent;
}());
export { SponsorListComponent };
