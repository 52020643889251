import { createSelector } from '@ngrx/store';
import {
  WorkshopAssignedHolders,
  WorkshopAvailableSeats,
  WorkshopStatusState
} from '@products/models/workshop-status.model';
import { getProductStatusState } from '@store/products/product.selectors';

/**
 * Get workshop status
 * @description Get workshop status object
 * @returns Workshop status object
 */
export const getWorkshopStatus = createSelector(
  getProductStatusState,
  (productStatusState): WorkshopStatusState => productStatusState.workshop
);

/**
 * Get workshop available seats
 * @description Get workshop available seats grouped by workshop id
 * @returns Object of workshop id keys and available seats value
 */
export const getWorkshopsAvailableSeatsGroupedByWorkshopId = createSelector(
  getWorkshopStatus,
  (workshopStatus): WorkshopAvailableSeats => workshopStatus.availableSeats
);

/**
 * Get workshop available seats
 * @description Get workshop available seats by provided workshop id
 * @returns Number
 */
export const getWorkshopAvailableSeatsByWorkshopId = (workshopId: number) =>
  createSelector(
    getWorkshopsAvailableSeatsGroupedByWorkshopId,
    (workshopsAvailableSeats): number => workshopsAvailableSeats[workshopId]
  );

/**
 * Get workshop assigned holders
 * @description Get workshop assigned holders grouped by workshop id
 * @returns Object of workshop id keys and assigned holders value
 */
export const getWorkshopsAssignedHoldersGroupedByWorkshopId = createSelector(
  getWorkshopStatus,
  (workshopStatus): WorkshopAssignedHolders => workshopStatus.assignedHolders
);

/**
 * Get workshop assigned holders
 * @description Get workshop assigned holders by provided workshop id
 * @returns Number
 */
export const getWorkshopAssignedHoldersByWorkshopId = (workshopId: number) =>
  createSelector(
    getWorkshopsAvailableSeatsGroupedByWorkshopId,
    (workshopAssignedHolders): number => workshopAssignedHolders[workshopId]
  );

/**
 * Get is workshop loading
 * @description Get status if workshop is loading
 * @returns Boolean
 */
export const getIsWorkshopLoading = createSelector(
  getWorkshopStatus,
  (workshopStatus): boolean => workshopStatus.isWorkshopLoading
);

/**
 * Get is workshop detail modal open
 * @description Check if workshop detail modal is open
 * @returns Boolean
 */
export const getIsWorkshopDetailModalOpen = createSelector(
  getWorkshopStatus,
  (workshopStatus): boolean => workshopStatus.isWorkshopDetailModalOpen
);
