/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sponsor-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./sponsor-list.component";
import * as i6 from "@ngrx/store";
var styles_SponsorListComponent = [i0.styles];
var RenderType_SponsorListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SponsorListComponent, data: {} });
export { RenderType_SponsorListComponent as RenderType_SponsorListComponent };
function View_SponsorListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "voucher-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.logo; _ck(_v, 0, 0, currVal_0); }); }
function View_SponsorListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "voucher-info col-6 col-sm-3 col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SponsorListComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sponsor-name black"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.logo; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); }); }
function View_SponsorListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SponsorListComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.sponsors; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SponsorListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "voucher-info-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h4", [["class", "sponsors-headline"], ["data-translation", "ticket-selection.voucher.subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SponsorListComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.vouchers; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ticket-selection.voucher.subtitle")))); _ck(_v, 2, 0, currVal_0); }); }
export function View_SponsorListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SponsorListComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSponsors; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SponsorListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sponsor-list", [], null, null, null, View_SponsorListComponent_0, RenderType_SponsorListComponent)), i1.ɵdid(1, 245760, null, 0, i5.SponsorListComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SponsorListComponentNgFactory = i1.ɵccf("app-sponsor-list", i5.SponsorListComponent, View_SponsorListComponent_Host_0, {}, {}, []);
export { SponsorListComponentNgFactory as SponsorListComponentNgFactory };
