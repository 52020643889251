var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { getExhibitionSettings, getOrderUuid, getSelectedExhibitionId } from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { isProductTypeTariff } from '@products/models/product-selection.model';
import { TariffWarningMessage } from '@products/models/tariff.model';
import { VoucherType, VoucherWarningMessage } from '@products/models/voucher.model';
import { VoucherValidationService } from '@products/services/voucher-validation.service';
import { AppConstants } from '@shared/app-constants';
import { environment } from '@src/environments/environment';
import { SetExhibitionSettings, SetRandomSponsor } from '@store/customization/customization.actions';
import { CustomizationService } from '@store/customization/customization.service';
import { getBookedProducts, getRemainingTicketCountForVoucherTariff, getTotalTicketCountPerVoucherTariffByTicketPersonId } from '@store/products/booking/booking.selectors';
import { getActiveVoucherProducts, getActiveVoucherTariffs, getVoucherTypeState, isRedeemedAnonymousVoucherProductInProductSelectionList, isVoucherCodeRedeemed } from '@store/products/product-selection/product-selection.selectors';
import { RedeemAndReleaseVoucher, ReleaseExpiredVoucher, ReleaseVoucher as ReleaseVoucherAction } from '@store/products/product.actions';
import { getAvailableTariffsByTicketPersonId } from '@store/products/status/tariff/tariff.selectors';
import { getIsTranslationLoaded } from '@store/translation/translation.selectors';
import cloneDeep from 'lodash.clonedeep';
import { Subject, combineLatest, forkJoin, iif, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "./voucher-validation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../status-bar/status-bar.service";
import * as i6 from "../../../shared/services-with-reducers/customization/customization.service";
var INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND, VOUCHER_PRODUCT_DEFAULT_AMOUNT = AppConstants.VOUCHER_PRODUCT_DEFAULT_AMOUNT;
var ERROR_TICKET_WARNING = TariffWarningMessage.ERROR_TICKET_WARNING, VOUCHER_LIMITED_WARNING = TariffWarningMessage.VOUCHER_LIMITED_WARNING, TICKET_LIMIT_PER_USER_ACCOUNT_WARNING = TariffWarningMessage.TICKET_LIMIT_PER_USER_ACCOUNT_WARNING;
var VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING = VoucherWarningMessage.VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING, VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING = VoucherWarningMessage.VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING;
var VoucherService = /** @class */ (function () {
    function VoucherService(store, http, voucherValidationService, translateService, statusBarService, customizationService) {
        var _this = this;
        this.store = store;
        this.http = http;
        this.voucherValidationService = voucherValidationService;
        this.translateService = translateService;
        this.statusBarService = statusBarService;
        this.customizationService = customizationService;
        this.destroy$ = new Subject();
        this._voucherViewState$ = new BehaviorSubject({
            maxTicketLimit: null,
            limitPromoCodes: null,
            limitLimitedPromoCodes: null,
            ticketLimitPerUserAccount: null,
            currentUserAccountTicketLimit: null,
            isVoucherIncludedPerUserAccountLimit: null,
            voucherCode: null,
            isLoading: null,
            isSelfRegistration: null,
            barcode: null,
            voucherTypingActive: null,
            ticketLimitWarning: null,
            bookedProductsCount: null,
            isRedeemedAnonymousVoucherProductInProductSelectionList: null,
            isCurrentVoucherTypeMandatory: null,
            isCurrentVoucherTypeAnonymous: null,
            voucherWarning: null,
            invalidVoucherWarningStatus: null
        });
        this.voucherViewState$ = this._voucherViewState$.asObservable().distinctUntilChanged();
        this.voucherViewState$.pipe(takeUntil(this.destroy$)).subscribe(function (voucherViewState) {
            _this.voucherServiceState = __assign({}, _this.voucherServiceState, voucherViewState);
        });
    }
    VoucherService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    VoucherService.prototype.initState = function (voucherState) {
        this.clearTicketLimitWarning(voucherState);
        this._voucherViewState$.next(voucherState);
    };
    VoucherService.prototype.updateState = function (voucherState) {
        this.clearTicketLimitWarning(voucherState);
        this._voucherViewState$.next(__assign({}, this.voucherServiceState, voucherState));
    };
    VoucherService.prototype.getState = function () {
        return this.voucherServiceState;
    };
    VoucherService.prototype.geStateProperty = function (propertyName) {
        return propertyName in this.voucherServiceState ? this.voucherServiceState[propertyName] : null;
    };
    VoucherService.prototype.getVoucherDetails$ = function (voucherDetail) {
        var params = new HttpParams();
        params = params.append('sr', "" + this.voucherServiceState.isSelfRegistration);
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/voucher/voucher-details/" + voucherDetail.eventId + "/" + voucherDetail.voucherCode, { params: params });
    };
    VoucherService.prototype.redeemVoucher$ = function (voucherRedeem) {
        var params = new HttpParams();
        params = params.append('sr', "" + this.voucherServiceState.isSelfRegistration);
        params = params.append('uuid', "" + voucherRedeem.orderUuid);
        params = params.append('totalTicketCount', "" + voucherRedeem.totalTicketCount);
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/voucher/redeem-voucher/" + voucherRedeem.eventId + "/" + voucherRedeem.voucherCode, { params: params });
    };
    VoucherService.prototype.releaseVoucher$ = function (releaseVoucher) {
        var body = new HttpParams();
        body = body.set('sr', "" + this.voucherServiceState.isSelfRegistration);
        body = body.set('uuid', "" + releaseVoucher.orderUuid);
        if (releaseVoucher.ticketPersonId) {
            body = body.set('ticketPersonId', "" + releaseVoucher.ticketPersonId);
            body = body.set('remainingTicketCount', "" + releaseVoucher.remainingTicketCount);
        }
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/voucher/reset-event-voucher/" + releaseVoucher.eventId + "/" + releaseVoucher.voucherCode, body);
    };
    VoucherService.prototype.releaseAllVouchers$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getActiveVoucherTariffs))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            return !!eventId && !!orderUuid;
        }), switchMap(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], activeVoucherTariffs = _a[2];
            var releaseVouchers = _this.mapActiveVoucherTariffsToReleaseVoucher(activeVoucherTariffs, eventId, orderUuid);
            return _this.releaseMultipleVouchers$(releaseVouchers);
        }));
    };
    VoucherService.prototype.prepareAndValidateVoucherDetailRequestData$ = function (voucherCode) {
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(isVoucherCodeRedeemed(voucherCode)))
        ]).pipe(take(1), filter(function (_a) {
            var eventId = _a[0], isVoucherCodeNotRedeemed = _a[1];
            return !!eventId && !isVoucherCodeNotRedeemed;
        }), map(function (_a) {
            var eventId = _a[0];
            return ({
                eventId: eventId,
                voucherCode: voucherCode
            });
        }));
    };
    VoucherService.prototype.prepareRedeemVoucherPayloadData$ = function (voucherDetail) {
        var _this = this;
        var eventId = voucherDetail.eventId, ticketPersonId = voucherDetail.ticketPersonId, voucherCode = voucherDetail.voucherCode;
        return combineLatest([
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedProducts)),
            this.store.pipe(select(getActiveVoucherTariffs)),
            this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList)),
            this.store.pipe(select(getTotalTicketCountPerVoucherTariffByTicketPersonId(ticketPersonId))),
            this.store.pipe(select(getRemainingTicketCountForVoucherTariff(ticketPersonId, voucherCode)))
        ]).pipe(first(function (_a) {
            var orderUuid = _a[0];
            return !!orderUuid;
        }), map(function (_a) {
            var orderUuid = _a[0], bookedProducts = _a[1], activeVoucherTariffs = _a[2], isRedeemedAnonymousVoucherProductInProductSelectionList = _a[3], totalTicketCount = _a[4], remainingTicketCount = _a[5];
            var countryCode = _this.translateService.currentLang;
            var isAnonymous = _this.voucherServiceState.isCurrentVoucherTypeAnonymous;
            var setVoucherPayload = {
                redeemVoucher: {
                    eventId: eventId,
                    orderUuid: orderUuid,
                    voucherCode: voucherCode,
                    countryCode: countryCode,
                    isAnonymous: isAnonymous,
                    totalTicketCount: totalTicketCount + VOUCHER_PRODUCT_DEFAULT_AMOUNT
                },
                releaseVouchers: [],
                removeProductBookings: []
            };
            if (_this.voucherServiceState.isSelfRegistration ||
                (isAnonymous && !isRedeemedAnonymousVoucherProductInProductSelectionList)) {
                activeVoucherTariffs.forEach(function (_a) {
                    var ticketPersonId = _a.ticketPersonId, voucherCode = _a.voucherCode;
                    var releaseVoucher = {
                        eventId: eventId,
                        orderUuid: orderUuid,
                        ticketPersonId: ticketPersonId,
                        voucherCode: voucherCode,
                        countryCode: countryCode,
                        remainingTicketCount: remainingTicketCount
                    };
                    setVoucherPayload.releaseVouchers.push(releaseVoucher);
                });
                var cloneBookedProducts = cloneDeep(bookedProducts);
                cloneBookedProducts.forEach(function (bookedProduct) {
                    if (isBookingProductTypeTariff(bookedProduct)) {
                        bookedProduct.tariffs.forEach(function (bookedTariff) {
                            bookedTariff.count = 0;
                            bookedTariff.holderUuids = [];
                        });
                        var isBookingTariffTypeInRemoveProductBookings = setVoucherPayload.removeProductBookings.some(function (removeProductBooking) {
                            if (isBookingProductTypeTariff(removeProductBooking)) {
                                return removeProductBooking.ticketTypeId === bookedProduct.ticketTypeId;
                            }
                        });
                        if (!isBookingTariffTypeInRemoveProductBookings) {
                            setVoucherPayload.removeProductBookings.push(bookedProduct);
                        }
                    }
                    else if (isBookingProductTypePackage(bookedProduct)) {
                        bookedProduct.productTypes.forEach(function (bookedPackageProduct) {
                            if (isBookingProductTypeTariff(bookedPackageProduct)) {
                                bookedPackageProduct.tariffs.forEach(function (bookedPackageTariff) {
                                    bookedPackageTariff.count = 0;
                                    bookedPackageTariff.holderUuids = [];
                                });
                            }
                        });
                        setVoucherPayload.removeProductBookings.push(bookedProduct);
                    }
                });
            }
            return setVoucherPayload;
        }));
    };
    VoucherService.prototype.prepareReleaseVoucherPayloadData$ = function (ticketPersonId, voucherCode) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getRemainingTicketCountForVoucherTariff(ticketPersonId, voucherCode)))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            return !!eventId && !!orderUuid;
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], remainingTicketCount = _a[2];
            return ({
                eventId: eventId,
                orderUuid: orderUuid,
                ticketPersonId: ticketPersonId,
                voucherCode: voucherCode,
                countryCode: _this.translateService.currentLang,
                remainingTicketCount: remainingTicketCount
            });
        }));
    };
    VoucherService.prototype.mapVoucherDetailResponseToVoucherDetail = function (voucherDetailResponse, eventId) {
        var groupId = voucherDetailResponse.groupId, ticketTypeId = voucherDetailResponse.ticketTypeId, ticketPersonId = voucherDetailResponse.ticketPersonId, voucherCode = voucherDetailResponse.voucherCode, voucherType = voucherDetailResponse.voucherType;
        return {
            eventId: eventId,
            ticketTypeId: groupId,
            ticketPersonTypeId: ticketTypeId,
            ticketPersonId: ticketPersonId,
            voucherCode: voucherCode,
            voucherType: voucherType
        };
    };
    VoucherService.prototype.mapActiveVoucherTariffsToReleaseVoucher = function (activeVoucherTariff, eventId, orderUuid) {
        var releaseVouchers = [];
        var countryCode = this.translateService.currentLang;
        activeVoucherTariff.forEach(function (_a) {
            var ticketPersonId = _a.ticketPersonId, voucherCode = _a.voucherCode;
            var releaseVoucher = {
                eventId: eventId,
                orderUuid: orderUuid,
                ticketPersonId: ticketPersonId,
                voucherCode: voucherCode,
                countryCode: countryCode,
                remainingTicketCount: 0
            };
            releaseVouchers.push(releaseVoucher);
        });
        return releaseVouchers;
    };
    VoucherService.prototype.isValidVoucherDetail$ = function (voucherDetail) {
        var _this = this;
        var ticketPersonId = voucherDetail.ticketPersonId, voucherType = voucherDetail.voucherType;
        return this.store.pipe(select(getVoucherTypeState(ticketPersonId)), first(function (voucherTypeState) {
            if (!voucherTypeState) {
                _this.showInvalidVoucherStatusWarningMessage();
                return false;
            }
            return true;
        }), switchMap(function (voucherTypeState) {
            var initialNumberOfAvailableTariffs = voucherTypeState.initialNumberOfAvailableTariffs, isMandatory = voucherTypeState.isMandatory, isAnonymous = voucherTypeState.isAnonymous, isAnonymousWithPrice = voucherTypeState.isAnonymousWithPrice;
            return combineLatest([
                _this.isAvailableTariffsLimitReached$(ticketPersonId, initialNumberOfAvailableTariffs),
                _this.isVoucherTypeLimitReached$(voucherType, isAnonymous),
                _this.isAnonymousProductBookedAndCurrentVoucherMandatory$(isMandatory, isAnonymous, isAnonymousWithPrice)
            ]).pipe(first(function (validatedVoucherDetails) {
                return validatedVoucherDetails.every(function (isInvalidVoucherDetail) { return !isInvalidVoucherDetail; });
            }));
        }));
    };
    VoucherService.prototype.isAvailableTariffsLimitReached$ = function (ticketPersonId, initialNumberOfAvailableTariffs) {
        var _this = this;
        return this.store.pipe(select(getAvailableTariffsByTicketPersonId(ticketPersonId)), switchMap(function (currentNumberOfAvailableTariffs) {
            return iif(function () { return currentNumberOfAvailableTariffs > 0; }, of(''), _this.getInvalidVoucherAvailableTariffsWarningMessageTranslation$(initialNumberOfAvailableTariffs)).pipe(map(function (availableTariffsLimitWarningMessage) { return ({
                currentNumberOfAvailableTariffs: currentNumberOfAvailableTariffs,
                availableTariffsLimitWarningMessage: availableTariffsLimitWarningMessage
            }); }));
        }), first(), tap(function (_a) {
            var availableTariffsLimitWarningMessage = _a.availableTariffsLimitWarningMessage;
            if (availableTariffsLimitWarningMessage) {
                _this.showInvalidVoucherStatusWarningMessage(availableTariffsLimitWarningMessage);
            }
        }), map(function (_a) {
            var currentNumberOfAvailableTariffs = _a.currentNumberOfAvailableTariffs;
            return !currentNumberOfAvailableTariffs;
        }));
    };
    VoucherService.prototype.isVoucherTypeLimitReached$ = function (voucherType, isAnonymous) {
        var _this = this;
        return this.voucherValidationService
            .validateRedeemedVoucherTypeCount$(voucherType, VOUCHER_PRODUCT_DEFAULT_AMOUNT, isAnonymous)
            .pipe(switchMap(function (voucherTypeValidatedCount) {
            return iif(function () { return voucherTypeValidatedCount > 0; }, of(''), _this.getInvalidVoucherTypeWarningMessageTranslation$(voucherType)).pipe(map(function (voucherTypeLimitWarningMessage) { return ({
                voucherTypeValidatedCount: voucherTypeValidatedCount,
                voucherTypeLimitWarningMessage: voucherTypeLimitWarningMessage
            }); }));
        }), first(), tap(function (_a) {
            var voucherTypeLimitWarningMessage = _a.voucherTypeLimitWarningMessage;
            if (voucherTypeLimitWarningMessage) {
                _this.showInvalidVoucherStatusWarningMessage(voucherTypeLimitWarningMessage);
            }
        }), map(function (_a) {
            var voucherTypeValidatedCount = _a.voucherTypeValidatedCount;
            return !voucherTypeValidatedCount;
        }));
    };
    VoucherService.prototype.isAnonymousProductBookedAndCurrentVoucherMandatory$ = function (isMandatory, isAnonymous, isAnonymousWithPrice) {
        this.updateState({
            isCurrentVoucherTypeMandatory: isMandatory,
            isCurrentVoucherTypeAnonymous: isAnonymous
        });
        if (isAnonymousWithPrice) {
            this.updateState({
                isRedeemedAnonymousVoucherProductInProductSelectionList: isAnonymous,
                voucherWarning: VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING
            });
            return of(true);
        }
        if (this.voucherServiceState.isRedeemedAnonymousVoucherProductInProductSelectionList && isMandatory) {
            this.updateState({
                voucherWarning: VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING
            });
            return of(true);
        }
        return of(false);
    };
    VoucherService.prototype.getInvalidVoucherAvailableTariffsWarningMessageTranslation$ = function (initialNumberOfAvailableTariffs) {
        return this.getStatusWarningMessageTranslation$(ERROR_TICKET_WARNING, {
            maxTicketLimit: initialNumberOfAvailableTariffs
        });
    };
    VoucherService.prototype.getInvalidVoucherTypeWarningMessageTranslation$ = function (voucherType) {
        var _a = this.voucherServiceState, limitPromoCodes = _a.limitPromoCodes, limitLimitedPromoCodes = _a.limitLimitedPromoCodes;
        return this.getStatusWarningMessageTranslation$(VOUCHER_LIMITED_WARNING, {
            maxTicketLimit: voucherType === VoucherType.PromoCode ? limitPromoCodes : limitLimitedPromoCodes
        });
    };
    VoucherService.prototype.getStatusWarningMessageTranslation$ = function (key, params) {
        return combineLatest([
            this.store.pipe(select(getIsTranslationLoaded)),
            this.translateService.stream(key, params)
        ]).pipe(first(function (_a) {
            var isTranslationLoaded = _a[0], statusWarningMessage = _a[1];
            return isTranslationLoaded && statusWarningMessage;
        }), map(function (_a) {
            var _ = _a[0], statusWarningMessage = _a[1];
            return statusWarningMessage;
        }));
    };
    VoucherService.prototype.showInvalidVoucherStatusWarningMessage = function (invalidVoucherWarningStatus) {
        var warningStatusMessage = invalidVoucherWarningStatus || this.voucherServiceState.invalidVoucherWarningStatus;
        this.statusBarService.setStatus(warningStatusMessage, 'error', null, "[" + this.statusMessageDateTime() + "]");
    };
    VoucherService.prototype.statusMessageDateTime = function () {
        var tzOffset = new Date().getTimezoneOffset() * 60000;
        var localISOTime = new Date(Date.now() - tzOffset).toISOString().slice(0, -1);
        return localISOTime.substring(0, localISOTime.indexOf('.')).replace('T', ' ');
    };
    VoucherService.prototype.isValidTariffLimit = function () {
        if (!!this.voucherServiceState.ticketLimitPerUserAccount) {
            if (this.voucherServiceState.isVoucherIncludedPerUserAccountLimit) {
                if (this.voucherServiceState.currentUserAccountTicketLimit !== undefined &&
                    this.voucherServiceState.bookedProductsCount !== undefined &&
                    this.voucherServiceState.bookedProductsCount === this.voucherServiceState.currentUserAccountTicketLimit) {
                    this.updateState({ ticketLimitWarning: TICKET_LIMIT_PER_USER_ACCOUNT_WARNING });
                    return false;
                }
            }
        }
        if (this.voucherServiceState.maxTicketLimit !== undefined &&
            this.voucherServiceState.bookedProductsCount !== undefined &&
            this.voucherServiceState.bookedProductsCount === this.voucherServiceState.maxTicketLimit) {
            this.updateState({ ticketLimitWarning: ERROR_TICKET_WARNING });
            return false;
        }
        return true;
    };
    VoucherService.prototype.getVoucherWarningTranslation = function () {
        var _this = this;
        this.getStatusWarningMessageTranslation$('response.voucherNotValid')
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (translatedInvalidVoucherWarning) {
            return _this.updateState({ invalidVoucherWarningStatus: translatedInvalidVoucherWarning });
        });
    };
    VoucherService.prototype.redeemVoucher = function (voucherCode) {
        var _this = this;
        if (!voucherCode || !this.isValidTariffLimit()) {
            return;
        }
        this.prepareAndValidateVoucherDetailRequestData$(voucherCode.trim())
            .pipe(tap(function () {
            _this.updateState({ isLoading: true });
            setTimeout(function () {
                _this.updateState({ isLoading: false });
            }, 2000);
        }), switchMap(function (getVoucherDetail) {
            return _this.getVoucherDetails$(getVoucherDetail).pipe(switchMap(function (voucherDetailResponse) {
                var voucherDetail = _this.mapVoucherDetailResponseToVoucherDetail(voucherDetailResponse, getVoucherDetail.eventId);
                return _this.isValidVoucherDetail$(voucherDetail).pipe(switchMap(function () { return _this.prepareRedeemVoucherPayloadData$(voucherDetail); }));
            }));
        }), takeUntil(this.destroy$))
            .subscribe(function (setVoucherPayload) {
            _this.clearVoucherInputAndWarning();
            _this.store.dispatch(new RedeemAndReleaseVoucher(setVoucherPayload));
        }, function () { return _this.updateState({ isLoading: false }); });
    };
    VoucherService.prototype.releaseMultipleVouchers$ = function (releaseVouchers) {
        var _this = this;
        if (!releaseVouchers.length) {
            return of([]);
        }
        return forkJoin(releaseVouchers.map(function (releaseVoucher) {
            return _this.releaseVoucher$(releaseVoucher);
        }));
    };
    VoucherService.prototype.releaseVoucher = function (ticketPersonId, voucherCode) {
        var _this = this;
        if (!ticketPersonId || !voucherCode) {
            return;
        }
        this.clearVoucherInputAndWarning();
        this.clearVoucherWarning();
        this.prepareReleaseVoucherPayloadData$(ticketPersonId, voucherCode).subscribe(function (releaseVoucher) {
            return _this.store.dispatch(new ReleaseVoucherAction(releaseVoucher));
        });
    };
    VoucherService.prototype.releaseExpiredVoucher = function (ticketPersonId, voucherCode) {
        var _this = this;
        if (!ticketPersonId || !voucherCode) {
            return;
        }
        this.clearVoucherInputAndWarning();
        this.clearVoucherWarning();
        this.prepareReleaseVoucherPayloadData$(ticketPersonId, voucherCode).subscribe(function (releaseVoucher) {
            return _this.store.dispatch(new ReleaseExpiredVoucher(releaseVoucher));
        });
    };
    VoucherService.prototype.scanVoucher = function (e) {
        var code = e.keyCode ? e.keyCode : e.which;
        this.updateState({
            isCurrentVoucherTypeAnonymous: false,
            ticketLimitWarning: '',
            voucherWarning: ''
        });
        if (code === 16 || code === 17)
            return; // don't add shift && ctrl to barcode!
        var voucherBarcode = this.voucherServiceState.barcode;
        if (e.ctrlKey && code === 66) {
            this.updateState({ voucherTypingActive: true });
        }
        else if (e.ctrlKey && code === 74) {
            this.updateState({
                voucherCode: voucherBarcode,
                barcode: '',
                voucherTypingActive: false
            });
            this.redeemVoucher(voucherBarcode);
        }
        else if (this.voucherServiceState.voucherTypingActive) {
            if (e.shiftKey) {
                this.updateState({
                    barcode: voucherBarcode + String.fromCharCode(code).toUpperCase()
                });
            }
            else {
                this.updateState({
                    barcode: voucherBarcode + String.fromCharCode(code).toLowerCase()
                });
            }
        }
    };
    VoucherService.prototype.clearVoucherWarning = function () {
        var isAnonymousProductNotBooked = !this.voucherServiceState
            .isRedeemedAnonymousVoucherProductInProductSelectionList;
        var isCurrentVoucherTypeNotMandatory = !this.voucherServiceState.isCurrentVoucherTypeMandatory;
        if (isAnonymousProductNotBooked && isCurrentVoucherTypeNotMandatory) {
            this.updateState({ voucherWarning: '' });
        }
    };
    VoucherService.prototype.clearTicketLimitWarning = function (newVoucherState) {
        if (newVoucherState.bookedProductsCount < newVoucherState.maxTicketLimit) {
            newVoucherState.ticketLimitWarning = '';
        }
    };
    VoucherService.prototype.clearVoucherInputAndWarning = function () {
        this.updateState({
            ticketLimitWarning: '',
            voucherWarning: '',
            voucherCode: ''
        });
    };
    VoucherService.prototype.setSponsorAndExhibitionBanner = function () {
        var _this = this;
        combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getActiveVoucherProducts))])
            .pipe(first())
            .subscribe(function (_a) {
            var exhibitionSettings = _a[0], activeVoucherProducts = _a[1];
            if (!_this.voucherServiceState.voucherWarning) {
                _this.updateState({ voucherCode: '' });
            }
            _this.updateState({ isLoading: false });
            var voucherTariffTypeIndex = activeVoucherProducts.findIndex(isProductTypeTariff);
            if (voucherTariffTypeIndex === INDEX_NOT_FOUND) {
                return;
            }
            var voucherTariffType = activeVoucherProducts[voucherTariffTypeIndex].tariffType;
            if (!voucherTariffType.tariffs.length) {
                return;
            }
            var voucherTariff = voucherTariffType.tariffs[0];
            if (voucherTariff.sponsors && voucherTariff.sponsors.length) {
                var voucherTariffSponsor = voucherTariff.sponsors[0];
                if (!!voucherTariffSponsor.styles) {
                    _this.customizationService.setVoucherStyles(voucherTariffSponsor.styles);
                }
                if (!!voucherTariffSponsor.sponsorBanner) {
                    _this.store.dispatch(new SetRandomSponsor({ sponsorBanner: voucherTariffSponsor.sponsorBanner }));
                }
                if (!!voucherTariffSponsor.eventBanner) {
                    var modifiedExhibitionSettings = __assign({}, exhibitionSettings, { eventBanner: voucherTariffSponsor.eventBanner });
                    _this.store.dispatch(new SetExhibitionSettings(modifiedExhibitionSettings));
                }
            }
        });
    };
    VoucherService.ngInjectableDef = i0.defineInjectable({ factory: function VoucherService_Factory() { return new VoucherService(i0.inject(i1.Store), i0.inject(i2.HttpClient), i0.inject(i3.VoucherValidationService), i0.inject(i4.TranslateService), i0.inject(i5.StatusBarService), i0.inject(i6.CustomizationService)); }, token: VoucherService, providedIn: "root" });
    return VoucherService;
}());
export { VoucherService };
