import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { State } from '../app.reducer';

export const APP_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('AppRootReducer');

export const BOOKING_DEBOUNCE_TIME = new InjectionToken<number>('BookingDebounceTime', {
  providedIn: 'root',
  factory: () => 50
});

export const HOLDER_DEBOUNCE_TIME = new InjectionToken<number>('HolderDebounceTime', {
  providedIn: 'root',
  factory: () => 50
});

export const ADDITIONAL_SERVICES_DEBOUNCE_TIME = new InjectionToken<number>('AdditionalServicesDebounceTime', {
  providedIn: 'root',
  factory: () => 50
});

export const LEGITIMATION_DEBOUNCE_TIME = new InjectionToken<number>('LegitimationDebounceTime', {
  providedIn: 'root',
  factory: () => 50
});

export const TRANSLATION_DEBOUNCE_TIME = new InjectionToken<number>('TranslationDebounceTime', {
  providedIn: 'root',
  factory: () => 50
});
