var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getAllDepartments, getAllOccupationalGroups, getAllProfessions, getAllTitles, getDepartments, getExhibitionSettings, getLanguage, getLegitimationFaxId, getLegitimationPostResponse, getLegitimationStatus, getOccupationalGroups, getOperatorsSettings, getProfessions, getProfile, getSelectedExhibition, getSelectedExhibitionId, getTitles, getUser, isUserLoggedIn } from '@app/app.reducer';
import { getProfileEditForm } from '@app/edit-account-form/form-inputs.edit-account';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '@shared/forms/forms.service';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { GetLegitimationFaxId, GetLegitimationStatus, PostLegitimationInfo, SetLegitimationInfoResponse, SetLegitimationStatus } from '@store/legitimation/legitimation.actions';
import { LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetSelectedStep } from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { GetProfile } from '@store/user/user.actions';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first, skip } from 'rxjs/operators';
var WebShopLegitimationComponent = /** @class */ (function () {
    function WebShopLegitimationComponent(store, formsService, router, route, _statusBarService, _translateService, _stepsFormsService, _helperService, legitimationService) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.router = router;
        this.route = route;
        this._statusBarService = _statusBarService;
        this._translateService = _translateService;
        this._stepsFormsService = _stepsFormsService;
        this._helperService = _helperService;
        this.legitimationService = legitimationService;
        this.customUrl = ''; //`${environment.apiUrl}/event/${eventId}/user/${userId}legitimation-file?legitimationType=corporateBookExtract`;
        //http://expo-demo.teamaxess.com/WebShop/api/event/eventId/user/userId/legitimation-file/legitimationType
        this.legitimationComment = '';
        this.legitimationUrl = 'http://';
        this.responsePending = false;
        this.faxId = null;
        this.isButtonDisabled = true;
        this.selectedOption = null;
        this.optionsValidity = {
            file: false,
            fax: false,
            url: false,
            noFile: false
        };
        this.isUploading = false;
        this.showProfileForm = false;
        this.isProfileUpdated = false;
        this.afterFaxPrint = false;
        this.modalWindowActive = false;
        this.isLegitimationSubmitted = false;
        this.isPrintWindowOpen = false;
        this.legitimationForNewAccount = false;
        this.doTariffsNeedLegitimation = false;
        this.fileTypes = [];
        this.allowedFileTypes = '.gif,.jpg,.jpeg,.png,.pdf';
        this.maxFileUploadSize = 3500000;
        this.LegitimationStatus = LegitimationStatus;
        this.subscriptions = new Subscription();
        this.uploadedFilesIds = null;
        this.binaryConversionConstant = 1024 * 1024;
        this.isLoggedIn$ = this.store.pipe(select(isUserLoggedIn));
        var firstLoad = true;
        this.subscriptions.add(this.legitimationService
            .isTariffWithRequiredLegitimationBooked$()
            .subscribe(function (doTariffsNeedLegitimation) { return (_this.doTariffsNeedLegitimation = doTariffsNeedLegitimation); }));
        this.store
            .pipe(select(getProfile), filter(function (data) { return !!data; }), first())
            .subscribe(function (profile) {
            _this.store.dispatch(new GetProfile(profile.id));
        });
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(getProfile)),
            this.store.pipe(select(getSelectedExhibition)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getLegitimationStatus)),
            this.store.pipe(select(getLegitimationFaxId)),
            this.store.pipe(select(getOperatorsSettings)),
            this.store.pipe(select(getAllTitles)),
            this.store.pipe(select(getTitles)),
            this.store.pipe(select(getAllProfessions)),
            this.store.pipe(select(getProfessions)),
            this.store.pipe(select(getAllDepartments)),
            this.store.pipe(select(getDepartments)),
            this.store.pipe(select(getAllOccupationalGroups)),
            this.store.pipe(select(getOccupationalGroups))
        ])
            .pipe(skip(1), filter(function (_a) {
            var profile = _a[0], exhibition = _a[1], eventSetting = _a[2], legitimation = _a[3];
            return !!profile && !!exhibition && !!eventSetting && !!legitimation;
        }))
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var profile, exhibition, eventSetting, legitimationStatus, faxId, operatorSettings, allTitles, titles, allProfessions, professions, allDepartments, departments, allOccupationalGroups, occupationalGroups, validationCallback, legitimationValid;
            var _this = this;
            return __generator(this, function (_a) {
                profile = data[0], exhibition = data[1], eventSetting = data[2], legitimationStatus = data[3], faxId = data[4], operatorSettings = data[5], allTitles = data[6], titles = data[7], allProfessions = data[8], professions = data[9], allDepartments = data[10], departments = data[11], allOccupationalGroups = data[12], occupationalGroups = data[13];
                this.userProfile = profile;
                this.activeExhibition = exhibition;
                this.eventSetting = eventSetting;
                this.legitimationStatus = legitimationStatus;
                this.faxId = faxId;
                this.legitimationForNewAccount = eventSetting.goToLegitimationForNewAccount;
                // when user does not have profile complete we hide the legitimation and display profile form
                if (legitimationStatus !== LegitimationStatus.InProgress) {
                    validationCallback = function () {
                        if (_this.isProfileUpdated) {
                            _this.showProfileForm = false;
                            firstLoad = false;
                        }
                        else if (!_this.accountForm.valid) {
                            _this.showProfileForm = true;
                            firstLoad = false;
                        }
                        else if (_this.accountForm.valid && !_this.isProfileUpdated && firstLoad) {
                            _this.showProfileForm = false;
                        }
                        else if (_this.accountForm.valid && !_this.isProfileUpdated && !firstLoad) {
                            _this.showProfileForm = true;
                        }
                    };
                    this.accountForm = this.formsService.toFormGroup(this.createProfileEditForm(operatorSettings, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups));
                    this._helperService.triggerCallbackOnceFormValidationIsDone(this.accountForm, validationCallback);
                }
                legitimationValid = legitimationStatus === LegitimationStatus.Approved;
                this.formsService.setFormValidity(legitimationValid, null, ['legitimation', 'validation']);
                return [2 /*return*/];
            });
        }); }));
        this.subscriptions.add(combineLatest([this.store.pipe(select(getSelectedExhibitionId)), this.store.pipe(select(getProfile))])
            .pipe(filter(function (data) { return !!data[0] && !!data[1]; }))
            .subscribe(function (data) {
            var eventId = data[0], profile = data[1];
            _this.customUrl = environment.webApiUrl + "/event/" + eventId + "/user/" + profile.id + "/legitimation-file";
            _this.store.dispatch(new GetLegitimationStatus({
                userId: profile.id,
                eventId: eventId
            }));
        }));
    }
    // converts maximum file upload size(size in bytes) to correct value size
    WebShopLegitimationComponent.prototype.convertBytes = function () {
        if (this.maxFileUploadSize === 0) {
            return '0 B';
        }
        var k = 1000, dm = 3, sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], i = Math.floor(Math.log(this.maxFileUploadSize) / Math.log(k));
        return (parseFloat((this.maxFileUploadSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]).toString();
    };
    WebShopLegitimationComponent.prototype.createProfileEditForm = function (operatorSettings, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups) {
        var _this = this;
        var profileEditForm = getProfileEditForm(this.eventSetting, operatorSettings, false, [], [], [], []);
        var inputTitles = titles ? titles : [];
        var inputProfessions = professions ? professions : [];
        var inputDepartments = departments ? departments : [];
        var inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];
        var updatedProfileEditForm = profileEditForm.map(function (profileItem) {
            var updatedItem;
            if (_this.userProfile.hasOwnProperty(profileItem.key) &&
                (_this.userProfile[profileItem.key] || _this.userProfile[profileItem.key] === 0)) {
                updatedItem = __assign({}, profileItem, { value: _this.formsService.getFunctionTextValue(_this.userProfile, profileItem, inputTitles, allTitles, inputProfessions, allProfessions, inputDepartments, allDepartments, inputOccupationalGroups, allOccupationalGroups) });
                _this.formsService.resetInvalidFunctionValue(updatedItem, inputTitles, inputProfessions, inputDepartments, inputOccupationalGroups, function (input) {
                    updatedItem = __assign({}, input, { value: null });
                });
            }
            else {
                updatedItem = __assign({}, profileItem, { value: '' });
                if (profileItem.key === 'newsletterChecked' && profileItem.controlType === 'radio') {
                    updatedItem = __assign({}, profileItem, { value: _this.userProfile.newsletterChecked.toString() });
                }
            }
            if (profileItem.controlType === 'checkbox') {
                updatedItem.options.forEach(function (option) {
                    if (_this.userProfile.hasOwnProperty(option.key)) {
                        option.value = _this.userProfile[option.key];
                    }
                });
            }
            return updatedItem;
        });
        return updatedProfileEditForm;
    };
    WebShopLegitimationComponent.prototype.profileUpdated = function (event) {
        this.isProfileUpdated = event;
    };
    WebShopLegitimationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setLegitimationValidity();
        this.subscriptions.add(this._stepsFormsService.isLegitimationRequired$
            .pipe(filter(function (isLegitimationRequired) { return !isLegitimationRequired; }))
            .subscribe(function () {
            _this.store.dispatch(new SetSelectedStep('tickets'));
            _this.router.navigate(['./tickets'], {
                relativeTo: _this.route.parent,
                preserveQueryParams: true
            });
        }));
    };
    WebShopLegitimationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    WebShopLegitimationComponent.prototype.showFax = function () {
        /* Ask for legitimation ID */
        var _this = this;
        this.showFaxInfo = true;
        this.afterFaxPrint = false;
        this.subscriptions.add(combineLatest([this.store.pipe(select(getSelectedExhibitionId)), this.store.pipe(select(getProfile))])
            .pipe(filter(function (_a) {
            var eventId = _a[0], profile = _a[1];
            return !!eventId && !!profile;
        }))
            .subscribe(function (_a) {
            var eventId = _a[0], profile = _a[1];
            _this.store.dispatch(new GetLegitimationFaxId({
                eventId: eventId,
                userId: profile.id
            }));
        }));
    };
    WebShopLegitimationComponent.prototype.closeFaxInfo = function () {
        //if user closed print window and did not legitimate we show him pop up window before he closes the fax template
        this.modalWindowActive = this.afterFaxPrint && !this.isLegitimationSubmitted;
        if (!this.modalWindowActive) {
            this.showFaxInfo = false;
        }
    };
    WebShopLegitimationComponent.prototype.printTemplate = function (printableFax) {
        var _this = this;
        this.store
            .pipe(select(getLegitimationFaxId), first(function (faxId) { return faxId !== null; }))
            .subscribe(function (faxId) {
            _this.isPrintWindowOpen = true;
            _this.faxId = faxId;
            _this.optionsValidity.fax = true;
            _this.setLegitimationValidity();
            setTimeout(function () {
                var myWindow = window.open('', '', 'width=600,height=800');
                myWindow.document.write(printableFax.innerHTML);
                myWindow.document.close();
                myWindow.focus();
                myWindow.print();
                myWindow.close();
                _this.afterFaxPrint = true;
                _this.isPrintWindowOpen = false;
            }, 50);
        });
    };
    WebShopLegitimationComponent.prototype.modalWindowLegitimate = function (event) {
        event.stopPropagation();
        this.submitLegitimation();
        this.modalWindowActive = false;
        this.showFaxInfo = false;
    };
    WebShopLegitimationComponent.prototype.leaveFaxTemplate = function (event) {
        event.stopPropagation();
        this.modalWindowActive = false;
        this.showFaxInfo = false;
    };
    /* selectFile() {
      (<HTMLInputElement>document.querySelector('.ui-fileupload-choose input')).click();
    }*/
    WebShopLegitimationComponent.prototype.selectTypeChange = function (event, index) {
        this.fileTypes[index] = event.target.value;
    };
    WebShopLegitimationComponent.prototype.remove = function (fileupload, event, index) {
        fileupload.remove(event, index);
        this.fileTypes.splice(index, 1);
    };
    WebShopLegitimationComponent.prototype.onFilesSelected = function (event) {
        var _a;
        (_a = this.fileTypes).push.apply(_a, Object.keys(event.files).map(function () { return 'corporateBookExtract'; }));
    };
    WebShopLegitimationComponent.prototype.uploadLegitimationDone = function (event) {
        this.optionsValidity.file = true;
        if (JSON.parse(event.xhr.response).hasOwnProperty('filesId')) {
            this.uploadedFilesIds = JSON.parse(event.xhr.response).filesId;
        }
        this.isUploading = false;
        this.setLegitimationValidity();
        this.submitLegitimation();
    };
    WebShopLegitimationComponent.prototype.onClear = function () {
        this.fileTypes.length = 0;
    };
    WebShopLegitimationComponent.prototype.uploadLegitimationError = function () {
        var _this = this;
        this.isUploading = false;
        this.fileTypes.length = 0;
        this._translateService.get('upload.error').subscribe(function (msg) {
            _this._statusBarService.setStatus(msg, 'error');
        });
    };
    WebShopLegitimationComponent.prototype.onUrlChange = function (event) {
        var isUrlValid = event.target.validity.valid;
        this.optionsValidity.url = isUrlValid;
        this.setLegitimationValidity();
    };
    WebShopLegitimationComponent.prototype.onCommentChange = function () {
        var _a = this.eventSetting, isOptionalLegitimationCommentAllowed = _a.isOptionalLegitimationCommentAllowed, isLegitimationWithCommentAllowed = _a.isLegitimationWithCommentAllowed;
        if (!isOptionalLegitimationCommentAllowed && isLegitimationWithCommentAllowed) {
            var isValid = this.legitimationComment.length >= 50 || false;
            this.optionsValidity.noFile = isValid;
            this.setLegitimationValidity();
        }
    };
    WebShopLegitimationComponent.prototype.setLegitimationValidity = function () {
        // if optional comment is used make legitimation step valid
        if (this.eventSetting &&
            this.eventSetting.isOptionalLegitimationCommentAllowed &&
            this.selectedOption === 'noFile') {
            this.optionsValidity.noFile = true;
        }
        this.isButtonDisabled = !this.optionsValidity[this.selectedOption];
        var stepsFormsActionName = ['legitimation', 'validation'];
        if (this.isButtonDisabled) {
            this.formsService.addStepValidationFeedback(stepsFormsActionName, 'block', 'steps.missing-input.legitimation');
        }
        else {
            this.formsService.removeStepValidationFeedback(stepsFormsActionName, 'block');
        }
    };
    WebShopLegitimationComponent.prototype.selectOption = function (option) {
        this.selectedOption = option;
        this.setLegitimationValidity();
    };
    WebShopLegitimationComponent.prototype.submitLegitimation = function () {
        var _this = this;
        this.responsePending = true;
        this.isLegitimationSubmitted = true;
        this.showFaxInfo = false;
        //set the response to negative, so we know once it changes
        this.store.dispatch(new SetLegitimationInfoResponse(false));
        combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getProfile)),
            this.store.pipe(select(getLanguage))
        ])
            .pipe(first())
            .subscribe(function (data) {
            var eventId = data[0];
            var userId = data[1].id;
            var currentLang = data[2];
            //check what was the post message response
            _this.store
                .pipe(select(getLegitimationPostResponse), filter(function (response) {
                if (!response) {
                    // there was a failure, so let the user resend (reenable the send button)
                    _this.responsePending = false;
                }
                return response;
            }), // it has boolean value, and we are iterested only in true (success) value
            first())
                .subscribe(function () {
                _this.store.dispatch(new SetLegitimationStatus(LegitimationStatus.InProgress));
            });
            _this.store.dispatch(new PostLegitimationInfo({
                feed: {
                    userLanguage: currentLang,
                    legitimationType: _this.selectedOption,
                    comment: _this.legitimationComment,
                    url: _this.legitimationUrl,
                    faxId: _this.faxId,
                    fileId: _this.uploadedFilesIds
                },
                userId: userId,
                eventId: eventId
            }));
        });
    };
    WebShopLegitimationComponent.prototype.beforeUpload = function () {
        this.isUploading = true;
    };
    WebShopLegitimationComponent.prototype.triggerUpload = function (uploader) {
        uploader.upload();
    };
    WebShopLegitimationComponent.prototype.myUploader = function (event, form) {
        var _this = this;
        var authToken = null;
        combineLatest([this.store.pipe(select(getUser)), this.store.pipe(select(getProfile))])
            .pipe(filter(function (data) { return !!data[0] || (data[1] && data[1].hasOwnProperty('authToken')); }), first())
            .subscribe(function (data) {
            var user = data[0], profile = data[1];
            if (user) {
                authToken = user.authToken;
            }
            else if (profile.hasOwnProperty('authToken')) {
                authToken = profile.authToken;
            }
            var files = event.files;
            var xhr = new XMLHttpRequest(), formData = new FormData();
            form.onBeforeUpload.emit({
                xhr: xhr,
                formData: formData
            });
            for (var i = 0; i < files.length; i++) {
                formData.append(_this.fileTypes[i], files[i], files[i].name);
            }
            xhr.upload.addEventListener('progress', function (e) {
                if (e.lengthComputable) {
                    form.progress = Math.round((e.loaded * 100) / e.total);
                }
                form.onProgress.emit({ originalEvent: e, progress: form.progress });
            }, false);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    form.progress = 0;
                    if (xhr.status >= 200 && xhr.status < 300)
                        form.onUpload.emit({ xhr: xhr, files: files });
                    else
                        form.onError.emit({ xhr: xhr, files: files });
                    form.clear();
                }
            };
            xhr.open(form.method, form.url, true);
            if (authToken) {
                xhr.setRequestHeader('Authorization', authToken);
            }
            form.onBeforeSend.emit({
                xhr: xhr,
                formData: formData
            });
            xhr.withCredentials = form.withCredentials;
            xhr.send(formData);
        });
    };
    WebShopLegitimationComponent.prototype.onBackClick = function () {
        this._stepsFormsService.navigateRelativeTo(-1, this.router);
    };
    WebShopLegitimationComponent.prototype.goToEvents = function () {
        this.router.navigate(['/']);
    };
    return WebShopLegitimationComponent;
}());
export { WebShopLegitimationComponent };
