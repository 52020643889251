import { Product } from '@products/models/product-selection.model';
import { AvailableTariffs } from '@products/models/tariff-status.model';

export enum PackageTooltip {
  PACKAGE_COUNTER_TOOLTIP_MESSAGE = 'packages.package-counter-message',
  PACKAGE_COUNTER_TOOLTIP_TIMEOUT = 3000
}

export enum PackageWarningMessage {
  ERROR_PACKAGE_LIMIT_WARNING = 'packages-selection.warning'
}

export interface PackageType {
  packageNumber: number;
  name: string;
  tariff?: string;
  variablePrice: boolean;
  packageTariffSheetNumber: number;
  packageTariffSheetValidNumber: number;
  numberOfAddedPackages: number;
  isDisabled: boolean;
  isVisible: boolean;
  warning?: boolean;
  info: string;
  packages: Packages;
}

export type Packages = Package[];

export interface Package {
  packageIndex: number;
  trackByAddedPackage: number;
  products: Product[];
}

export interface PackageValidationStateViewModel {
  count: number;
  package: Package;
  validatedCount: number;
  previousValidatedCount: number;
  isCountOverLimit: boolean;
  isNextCountOverLimit: boolean;
  packageMinimalTariffCount: number;
  numberOfAllBookedTariffs: number;
  availableTariffsSortedByTicketPersonId: AvailableTariffs;
  maxTariffLimit: number;
  tariffLimitPerUserAccount: number;
  currentUserAccountTariffLimit: number;
  packageLimitWarningMessage: string;
}
