import { OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getExhibitionSettings, getProfile } from '../app.reducer';
import { LoginOptions } from './login.model';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var LoginService = /** @class */ (function () {
    function LoginService(store) {
        this.store = store;
        this.activeTab$ = new Subject();
        this.destroy$ = new Subject();
    }
    LoginService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    LoginService.prototype.setActiveTab = function (tab) {
        this.activeTab$.next(tab);
    };
    LoginService.prototype.checkAccountVerificationStatus$ = function () {
        return combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getProfile))]).pipe(filter(function (_a) {
            var exhibitionSettings = _a[0];
            return !!exhibitionSettings;
        }), map(function (_a) {
            var exhibitionSettings = _a[0], profile = _a[1];
            return ({
                isVerifiedAccountRequired: exhibitionSettings.loginMode === LoginOptions.BeforeTicketSelectionWithRequiredEmailConfirmation,
                isAccountVerified: !!profile ? profile.isEmailVerified : false
            });
        }), takeUntil(this.destroy$));
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.Store)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
