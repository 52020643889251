/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration-questionnaire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/forms/df-input/df-input.component.ngfactory";
import * as i3 from "../../shared/forms/df-input/df-input.component";
import * as i4 from "../../shared/forms/forms.service";
import * as i5 from "../../shared/services-with-reducers/helpers/helper.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../shared/validation/validation.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i12 from "./registration-questionnaire.component";
var styles_RegistrationQuestionnaireComponent = [i0.styles];
var RenderType_RegistrationQuestionnaireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationQuestionnaireComponent, data: {} });
export { RenderType_RegistrationQuestionnaireComponent as RenderType_RegistrationQuestionnaireComponent };
function View_RegistrationQuestionnaireComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i2.View_DfInputComponent_0, i2.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i3.DfInputComponent, [i4.FormsService, i5.HelperService, i6.Store, i1.ElementRef, i1.Renderer2, i7.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.inputs; var currVal_3 = _co.customAction; var currVal_4 = _co.form; var currVal_5 = _co.inputChangeCallback; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_RegistrationQuestionnaireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "section-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "section-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h3", [["data-stranslation", "registration.questionnaire"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 6, "form", [["class", "row"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i9.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(8, 540672, null, 0, i9.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.FormGroupDirective]), i1.ɵdid(10, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegistrationQuestionnaireComponent_2)), i1.ɵdid(12, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.form; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.inputs; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("registration.questionnaire")))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 10).ngClassValid; var currVal_6 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_RegistrationQuestionnaireComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegistrationQuestionnaireComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.form && ((_co.inputs == null) ? null : _co.inputs.length)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RegistrationQuestionnaireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration-questionnaire", [], null, null, null, View_RegistrationQuestionnaireComponent_0, RenderType_RegistrationQuestionnaireComponent)), i1.ɵdid(1, 114688, null, 0, i12.RegistrationQuestionnaireComponent, [i6.Store, i4.FormsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationQuestionnaireComponentNgFactory = i1.ɵccf("app-registration-questionnaire", i12.RegistrationQuestionnaireComponent, View_RegistrationQuestionnaireComponent_Host_0, {}, { isValid: "isValid", questionnaireChanged: "questionnaireChanged" }, []);
export { RegistrationQuestionnaireComponentNgFactory as RegistrationQuestionnaireComponentNgFactory };
