import { createSelector } from '@ngrx/store';
import {
  BookedPackageTotalPriceGroupedByPackageIndex,
  BookedPackageTotalPriceGroupedByPackageNumber,
  BookedProducts,
  BookedVoucherCountSortedByVoucherType,
  BookingContingentReservation,
  BookingPackageType,
  BookingParkingReservation,
  BookingProductType,
  BookingTariff,
  BookingTariffType,
  SharedBookedPersonIdTariffsTotalCount,
  VoucherCountdown,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { HolderUuids } from '@products/models/holder.model';
import { RemovePackageBooking } from '@products/models/products.model';
import { VoucherCode, VoucherType } from '@products/models/voucher.model';
import { getProductBookingState } from '@store/products/product.selectors';
import cloneDeep from 'lodash.clonedeep';

/**
 * Get booked products list
 * @description Lists all booked products
 * @returns Booking or empty array
 */
export const getBookedProducts = createSelector(
  getProductBookingState,
  (productBookingState): BookedProducts => productBookingState.list
);

/**
 * Get if anonymous product is sent to api
 * @description Gets is anonymous product sent to API via prepareDataForSaveAndSend method
 * @returns True if anonymous product was sent with prepareDataForSaveAndSend method, otherwise false
 */
export const isAnonymousProductSentToAPI = createSelector(
  getProductBookingState,
  (productBookingState): boolean => productBookingState.isAnonymousProductSentToAPI
);

/**
 * Get claimed ticket hash
 * @description Gets claimed ticket hash
 * @returns Claimed ticket hash
 */
export const getClaimedTicketHash = createSelector(
  getProductBookingState,
  (productBookingState): string => productBookingState.claimedTicketHash
);

/**
 * Gets if claimed ticket hash is valid
 * @description Gets if claimed ticket hash is valid
 * @returns true if claimed ticket hash is valid
 */
export const getClaimedTicketHashValid = createSelector(
  getProductBookingState,
  (productBookingState): boolean => productBookingState.claimedTicketHashValid
);

/**
 * Get product countdown
 * @description Gets countdown for products
 * @returns number which represents timestamp of when last product was booked
 */
export const getProductCountdown = createSelector(
  getProductBookingState,
  (productBookingState): number => productBookingState.countdown.product
);

/**
 * Get workshop countdown
 * @description Gets countdown for workshops
 * @returns number which represents timestamp of when last workshop was booked
 */
export const getWorkshopCountdown = createSelector(
  getProductBookingState,
  (productBookingState): number => productBookingState.countdown.workshop
);

/**
 * Get vouchers countdown
 * @description Get countdowns for vouchers
 * @returns array of booked vouchers which contains voucher code and timestamp
 */
export const getVouchersCountdown = createSelector(
  getProductBookingState,
  (productBookingState): VoucherCountdown[] => productBookingState.countdown.voucher
);

/**
 * Get all tariff types from bookings
 * @description Get all tariff types from bookings (package tariff types not included)
 * @return Tariff types or empty array
 */
export const getBookedTariffTypes = createSelector(
  getBookedProducts,
  (bookedProductTypes: BookingProductType[]): BookingTariffType[] => {
    const bookedTariffTypes: BookingTariffType[] = [];

    bookedProductTypes.forEach(bookedProductType => {
      if (isBookingProductTypeTariff(bookedProductType)) {
        bookedTariffTypes.push(bookedProductType);
      }
    });

    return bookedTariffTypes;
  }
);

/**
 * Get specified booked tariff type
 * @description Get booked tariff by specified ticketTypeId (package tariff types not included)
 * @param ticketTypeId
 * @returns Tariff type or null
 */
export const getBookedTariffTypeByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getBookedTariffTypes,
    (bookedTariffTypes): BookingTariffType => {
      if (!bookedTariffTypes.length) {
        return null;
      }

      return bookedTariffTypes.find(bookedTariffType => bookedTariffType.ticketTypeId === ticketTypeId) || null;
    }
  );

/**
 * Get booked tariffs
 * @description Get bookings tariff (package tariff not included)
 * @returns Booked tariff or empty array
 */
export const getBookedTariffs = createSelector(
  getBookedTariffTypes,
  (bookedTariffTypes: BookingTariffType[]): BookingTariff[] => {
    const bookedTariffs: BookingTariff[] = [];

    bookedTariffTypes.forEach(bookedTariffType => {
      bookedTariffType.tariffs.forEach(bookedTariff => bookedTariffs.push(bookedTariff));
    });

    return bookedTariffs;
  }
);

/**
 * Get booked tariff by ticket person id
 * @description Get bookings tariff by specified ticketPersonId (package tariff not included)
 * @param ticketPersonId
 * @returns Booked tariff or null
 */
export const getBookedTariffByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getBookedTariffs,
    (bookedTariffs: BookingTariff[]): BookingTariff => {
      if (!bookedTariffs.length) {
        return null;
      }

      return bookedTariffs.find(bookingTicket => bookingTicket.ticketPersonId === ticketPersonId) || null;
    }
  );

/**
 * Get booked tariff product type by ticket person id
 * @description Get booked tariff product type by specified ticketPersonId (package tariff not included)
 * @param ticketPersonId
 * @returns Booked tariff or empty booking tariff type
 */
export const getBookedTariffTypeAndTariffByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getBookedTariffTypes,
    (bookedTariffTypes: BookingTariffType[]): BookingTariffType => {
      const bookedTariffType = bookedTariffTypes.find(bookedTariffType =>
        bookedTariffType.tariffs.some(bookingTariff => bookingTariff.ticketPersonId === ticketPersonId)
      );

      if (!bookedTariffType) {
        return null;
      }

      const bookedTariffTypeClone: BookingTariffType = cloneDeep(bookedTariffType);

      bookedTariffTypeClone.tariffs = bookedTariffTypeClone.tariffs.filter(
        bookedTariff => bookedTariff.ticketPersonId === ticketPersonId
      );

      return bookedTariffTypeClone;
    }
  );

/**
 * Get booked tariff product type by ticket person id and voucher code
 * @description Get booked tariff product type by specified ticketPersonId (package tariff not included) and voucher code (not required)
 * @param ticketPersonId
 * @returns Booked tariff or empty booking tariff type
 */
export const getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode = (
  ticketPersonId: number,
  voucherCode?: VoucherCode
) =>
  createSelector(
    getBookedTariffTypes,
    (bookedTariffTypes: BookingTariffType[]): BookingTariffType => {
      const bookedTariffType = bookedTariffTypes.find(bookedTariffType =>
        bookedTariffType.tariffs.some(bookingTariff => bookingTariff.ticketPersonId === ticketPersonId)
      );

      if (!bookedTariffType) {
        return null;
      }

      const bookedTariffTypeClone: BookingTariffType = cloneDeep(bookedTariffType);

      bookedTariffTypeClone.tariffs = bookedTariffTypeClone.tariffs.filter(
        bookedTariff => bookedTariff.ticketPersonId === ticketPersonId && bookedTariff.voucherCode === voucherCode
      );

      return bookedTariffTypeClone;
    }
  );

/**
 * Get booked package tariff types
 * @description Get booked packages tariff types by ticket type id
 * @param ticketTypeId
 * @returns Booked tariff types or empty array
 */
const getBookedPackageTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getBookedProducts,
    (bookedProductTypes: BookingProductType[]): BookingTariffType[] => {
      const bookedTariffTypes: BookingTariffType[] = [];

      bookedProductTypes.forEach(bookedProductType => {
        if (isBookingProductTypePackage(bookedProductType)) {
          bookedProductType.productTypes.forEach(packageProductType => {
            if (isBookingProductTypeTariff(packageProductType)) {
              if (packageProductType.ticketTypeId === ticketTypeId) {
                bookedTariffTypes.push(packageProductType);
              }
            }
          });
        }
      });

      return bookedTariffTypes;
    }
  );

/**
 * Get all booked packages
 * @description Get all booked packages
 * @returns Booked package types or empty array
 */
export const getBookedPackages = createSelector(
  getBookedProducts,
  (bookedProductTypes: BookingProductType[]): BookingPackageType[] => {
    const bookedPackageTypes: BookingPackageType[] = [];

    bookedProductTypes.forEach(bookedProductType => {
      if (isBookingProductTypePackage(bookedProductType)) {
        bookedPackageTypes.push(bookedProductType);
      }
    });

    return bookedPackageTypes;
  }
);

/**
 * Get booked packages by package number
 * @description Get all booked package types by specified package number
 * @param packageNumber
 * @returns Booked package types or empty array
 */
export const getBookedPackagesByPackageNumber = (packageNumber: number) =>
  createSelector(
    getBookedPackages,
    (bookingPackageTypes): BookingPackageType[] =>
      bookingPackageTypes.filter(bookedPackageType => bookedPackageType.packageNumber === packageNumber) || []
  );

/**
 * Get booked package by package number and index
 * @description Get all booked packages by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package type or null
 */
export const getBookedPackageByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getBookedPackagesByPackageNumber(packageNumber),
    (bookedPackageTypes): BookingPackageType =>
      bookedPackageTypes.find(bookedPackageType => bookedPackageType.packageIndex === packageIndex) || null
  );

/**
 * Get booked packages by package number and indexes
 * @description Get booked packages with specified package indexes
 * @param removePackageBooking
 * @returns Booked package types or empty array
 */
export const getBookedPackageTypeByPackageNumberAndIndexes = (removePackageBooking: RemovePackageBooking) =>
  createSelector(
    getBookedPackagesByPackageNumber(removePackageBooking.packageNumber),
    (bookedPackages): BookingPackageType[] =>
      bookedPackages.filter(bookedPackage => removePackageBooking.packageIndexes.includes(bookedPackage.packageIndex))
  );

/**
 * Get package product type by package and ticketPersonId
 * @description Get package product type by package number, index and ticketPersonId in package tariff
 * @param packageNumber
 * @param packageIndex
 * @param ticketPersonId
 * @returns Booked package or null
 */
export const getBookedPackageTypeByPackageAndTicketPersonId = (
  packageNumber: number,
  packageIndex: number,
  ticketTypeId: number,
  ticketPersonId: number
) =>
  createSelector(
    getBookedPackageByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackage): BookingPackageType => {
      if (!bookedPackage) {
        return null;
      }

      const bookedPackageClone: BookingPackageType = cloneDeep(bookedPackage);
      const bookedPackageTariffType = bookedPackageClone.productTypes.find(bookedProductType => {
        if (isBookingProductTypeTariff(bookedProductType)) {
          return bookedProductType.tariffs.some(
            packageTariff =>
              packageTariff.ticketTypeId === ticketTypeId && packageTariff.ticketPersonId === ticketPersonId
          );
        }
      });

      if (isBookingProductTypeTariff(bookedPackageTariffType)) {
        bookedPackageTariffType.tariffs = bookedPackageTariffType.tariffs.filter(
          bookedPackageTariff => bookedPackageTariff.ticketPersonId === ticketPersonId
        );

        bookedPackageClone.productTypes = [bookedPackageTariffType];
      } else {
        return null;
      }

      return bookedPackageClone;
    }
  );

/**
 * Get booked package product types
 * @description Get package product types by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package product types or empty array
 */
export const getBookedPackageProductTypesByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getBookedPackageByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackage): BookingProductType[] => {
      if (!bookedPackage) {
        return [];
      }

      return bookedPackage.productTypes;
    }
  );

/**
 * Get booked package tariff types
 * @description Get booked package tariff types by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package tariff types or empty array
 */
export const getBookedPackageTariffTypesByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getBookedPackageProductTypesByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackageProductTypes): BookingTariffType[] => {
      if (!bookedPackageProductTypes.length) {
        return [];
      }

      const bookedPackageTariffTypes: BookingTariffType[] = [];

      bookedPackageProductTypes
        .filter(isBookingProductTypeTariff)
        .forEach(bookedPackageTariffType => bookedPackageTariffTypes.push(bookedPackageTariffType));

      return bookedPackageTariffTypes;
    }
  );

/**
 * Get booked package tariffs
 * @description Get booked package tariffs by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked tariffs or empty array
 */
export const getBookedPackageTariffsByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getBookedPackageTariffTypesByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackageTariffTypes): BookingTariff[] => {
      const bookedPackageTariffs: BookingTariff[] = [];

      bookedPackageTariffTypes.forEach(bookedPackageTariffType =>
        bookedPackageTariffType.tariffs.forEach(bookedPackageTariff => bookedPackageTariffs.push(bookedPackageTariff))
      );

      return bookedPackageTariffs;
    }
  );

/**
 * Get booked package ticket
 * @description Get booked package tariff by specified package number, index and ticket person id
 * @param packageNumber
 * @param packageIndex
 * @param ticketPersonId
 * @returns Booked tariff or null
 */
export const getBookedPackageTariffByTicketPersonId = (
  packageNumber: number,
  packageIndex: number,
  ticketPersonId: number
) =>
  createSelector(
    getBookedPackageTariffsByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackageTariffs): BookingTariff =>
      bookedPackageTariffs.find(bookedPackageTariff => bookedPackageTariff.ticketPersonId === ticketPersonId) || null
  );

/**
 * Get booked package tariffs
 * @description Get package booking tariffs (regular tariff not included)
 * @returns Booked package tariff or empty array
 */
export const getBookedPackageTariffs = createSelector(
  getBookedPackages,
  (bookedPackageTypes: BookingPackageType[]): BookingTariff[] => {
    const bookedTariffs: BookingTariff[] = [];

    bookedPackageTypes.forEach(bookedPackageType => {
      bookedPackageType.productTypes.forEach(bookedPackageProductType => {
        if (isBookingProductTypeTariff(bookedPackageProductType)) {
          bookedPackageProductType.tariffs.forEach(bookedPackageTariff => bookedTariffs.push(bookedPackageTariff));
        }
      });
    });

    return bookedTariffs;
  }
);

/**
 * Get all booked tariffs
 * @description Get all tariffs from bookings
 * @returns Booked tariff or empty array
 */
export const getAllBookedTariffs = createSelector(
  getBookedTariffs,
  getBookedPackageTariffs,
  (bookedTariffs: BookingTariff[], bookedPackageTariffs: BookingTariff[]): BookingTariff[] => {
    return [...bookedTariffs, ...bookedPackageTariffs];
  }
);

/**
 * Get all booked tariff types
 * @description Get all booked tariff types by specified ticket type id
 * @param ticketTypeId
 * @returns Booked tariff types or empty array
 */
export const getAllBookedTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getBookedTariffTypeByTicketTypeId(ticketTypeId),
    getBookedPackageTariffTypesByTicketTypeId(ticketTypeId),
    (bookedTariffType, bookedPackageTariffTypes): BookingTariffType[] => [bookedTariffType, ...bookedPackageTariffTypes]
  );

/**
 * Get all booked tariffs
 * @description Get all cart tariffs by ticket person id
 * @param ticketTypeId
 * @param ticketPersonId
 * @returns Booked tariffs or empty array
 */
export const getAllBookedTariffsByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getAllBookedTariffs,
    (allBookedTariffs: BookingTariff[]): BookingTariff[] => {
      const bookedTariffs: BookingTariff[] = [];

      allBookedTariffs.forEach(bookedTariff => {
        if (bookedTariff.ticketPersonId === ticketPersonId) {
          bookedTariffs.push(bookedTariff);
        }
      });

      return bookedTariffs;
    }
  );

/**
 * Get booked voucher tariffs
 * @description Get all booked vouchers form product bookings
 * @returns Booked voucher tariffs or empty array
 */
export const getVoucherTariffBookings = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]) => bookedTariffs.filter(bookedTariff => !!bookedTariff.voucherCode)
);

/**
 * Get booked promo code vouchers
 * @description Get booked promo code vouchers from product bookings
 * @returns Booked promo code voucher tariffs or empty array
 */
export const getBookedPromoCodeVoucherTariffs = createSelector(
  getVoucherTariffBookings,
  (bookedVoucherTariffs: BookingTariff[]) =>
    bookedVoucherTariffs.filter(bookedVoucherTariff => bookedVoucherTariff.voucherType === VoucherType.PromoCode)
);

/**
 * Get booked promo code vouchers count
 * @description Get booked promo code vouchers count from product bookings
 * @returns Booked promo code voucher tariffs count
 */
export const getBookedPromoCodeVoucherTariffsCount = createSelector(
  getBookedPromoCodeVoucherTariffs,
  (bookedPromoCodeVoucherTariffs: BookingTariff[]) =>
    bookedPromoCodeVoucherTariffs.reduce(
      (numberOfBookedPromoCodeVoucherTariffs, bookedPromoCodeVoucherTariff) =>
        numberOfBookedPromoCodeVoucherTariffs + bookedPromoCodeVoucherTariff.count,
      0
    )
);

/**
 * Get booked limited promo code vouchers
 * @description Get booked limited promo code vouchers from product bookings
 * @returns Booked limited promo code voucher tariffs or empty array
 */
export const getBookedLimitedPromoCodeVoucherTariffs = createSelector(
  getVoucherTariffBookings,
  (bookedVoucherTariffs: BookingTariff[]) =>
    bookedVoucherTariffs.filter(bookedVoucherTariff => bookedVoucherTariff.voucherType === VoucherType.LimiterPromoCode)
);

/**
 * Get booked limited promo code vouchers count
 * @description Get booked limited promo code vouchers count from product bookings
 * @returns booked limited promo code voucher tariffs count
 */
export const getBookedLimitedPromoCodeVoucherTariffsCount = createSelector(
  getBookedLimitedPromoCodeVoucherTariffs,
  (bookedLimitedPromoCodeVoucherTariffs: BookingTariff[]) =>
    bookedLimitedPromoCodeVoucherTariffs.reduce(
      (numberOfBookedLimitedPromoCodeVoucherTariffs, bookedLimitedPromoCodeVoucherTariff) =>
        numberOfBookedLimitedPromoCodeVoucherTariffs + bookedLimitedPromoCodeVoucherTariff.count,
      0
    )
);

/**
 * Get booked one time vouchers
 * @description Get booked one time vouchers from product bookings
 * @returns Booked one time voucher tariffs or empty array
 */
export const getBookedOneTimeVoucherTariffs = createSelector(
  getVoucherTariffBookings,
  (bookedVoucherTariffs: BookingTariff[]) =>
    bookedVoucherTariffs.filter(bookedVoucherTariff => bookedVoucherTariff.voucherType === VoucherType.OneTimeVoucher)
);

/**
 * Get booked one time vouchers count
 * @description Get booked one time vouchers count from product bookings
 * @returns booked one time voucher tariffs count
 */
export const getBookedOneTimeVoucherTariffsCount = createSelector(
  getBookedOneTimeVoucherTariffs,
  (bookedOneTimeVoucherTariffs: BookingTariff[]) =>
    bookedOneTimeVoucherTariffs.reduce(
      (numberOfBookedOneTimeVoucherTariffs, bookedOneTimeVoucherTariff) =>
        numberOfBookedOneTimeVoucherTariffs + bookedOneTimeVoucherTariff.count,
      0
    )
);

/**
 * Get booked voucher count sorted by voucher type
 * @description Get booked voucher count sorted by voucher type from product bookings
 * @returns Object of voucher type keys and count as value
 */
export const getBookedVoucherCountSortedByVoucherType = createSelector(
  getBookedPromoCodeVoucherTariffsCount,
  getBookedLimitedPromoCodeVoucherTariffsCount,
  getBookedOneTimeVoucherTariffsCount,
  (
    bookedPromoCodeVoucherTariffsCount,
    bookedLimitedPromoCodeVoucherTariffsCount,
    bookedOneTimeVoucherTariffsCount
  ): BookedVoucherCountSortedByVoucherType => ({
    [VoucherType.PromoCode]: bookedPromoCodeVoucherTariffsCount,
    [VoucherType.LimiterPromoCode]: bookedLimitedPromoCodeVoucherTariffsCount,
    [VoucherType.OneTimeVoucher]: bookedOneTimeVoucherTariffsCount
  })
);

/**
 * Get booked boucher tariff by voucher code
 * @description Get booked voucher tariff by provided voucher code
 * @param voucherCode
 * @returns Booked voucher tariff or null
 */
export const getBookedVoucherTariffByVoucherCode = (voucherCode: string) =>
  createSelector(
    getVoucherTariffBookings,
    (bookedTariffs: BookingTariff[]) =>
      bookedTariffs.find(bookedTariff => bookedTariff.voucherCode === voucherCode) || null
  );

/**
 * Get booked voucher tariff by ticket person id
 * @description Get booked voucher tariff by provided ticket person id
 * @param ticketPersonId
 * @returns Booked voucher tariffs or empty array
 */
export const getVoucherTariffsBookingsByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getVoucherTariffBookings,
    (bookedTariffs: BookingTariff[]) =>
      bookedTariffs.filter(bookedTariff => bookedTariff.ticketPersonId === ticketPersonId) || []
  );

/**
 * Get booked total ticket count per voucher tariff by ticket person id
 * @description Get booked total ticket count per voucher tariff by ticket person id
 * @param ticketPersonId
 * @returns Number of booked voucher tariffs
 */
export const getTotalTicketCountPerVoucherTariffByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getVoucherTariffsBookingsByTicketPersonId(ticketPersonId),
    (bookedTariffs: BookingTariff[]) =>
      bookedTariffs.reduce((totalCount, bookedTariff) => totalCount + bookedTariff.count, 0)
  );

/**
 * Get remaining ticket count for voucher tariff
 * @description Get remaining voucher tickets count after removing voucher codes ticket count
 * @param voucherCode
 * @param ticketPersonId
 * @returns Number of booked tickets for voucher tariff
 */
export const getRemainingTicketCountForVoucherTariff = (ticketPersonId: number, voucherCode: string) =>
  createSelector(
    getBookedVoucherTariffByVoucherCode(voucherCode),
    getTotalTicketCountPerVoucherTariffByTicketPersonId(ticketPersonId),
    (bookedVoucherTariffByVoucherCode, totalTicketCountPerVoucherTariffByTicketPersonId) => {
      if (!bookedVoucherTariffByVoucherCode) {
        return totalTicketCountPerVoucherTariffByTicketPersonId;
      }

      return totalTicketCountPerVoucherTariffByTicketPersonId - bookedVoucherTariffByVoucherCode.count;
    }
  );

/**
 * Get booked anonymous tariffs
 * @description Get all booked anonymous tariffs from tariffs, vouchers and packages
 * @returns Tariffs or empty array
 */
export const getAnonymousTariffs = createSelector(
  getVoucherTariffBookings,
  (bookedTariffs: BookingTariff[]): BookingTariff[] => bookedTariffs.filter(bookedTariff => bookedTariff.isAnonymous)
);

/**
 * Check if anonymous product is booked
 * @description Check if anonymous product is booked from tariffs, vouchers and packages
 * @returns Boolean
 */
export const isAnonymousProductBooked = createSelector(
  getAnonymousTariffs,
  (bookedAnonymousTariffs: BookingTariff[]): boolean => !!bookedAnonymousTariffs.length
);

/**
 * Get if anonymous products should be send to API
 * @description Checks if there's anonymous product booked and
 * if it was not sent to API via prepareDataForSaveAndSend method
 * @returns True if anonymous product should be send to API, otherwise false
 */
export const shouldSendAnonymousProductToAPI = createSelector(
  isAnonymousProductBooked,
  isAnonymousProductSentToAPI,
  (isAnonymousProductBooked: boolean, isAnonymousProductSentToAPI: boolean): boolean =>
    isAnonymousProductBooked && !isAnonymousProductSentToAPI
);

/**
 * Get all holder uuids from booked products
 * @description Get all holder uuids in booked products
 * @returns Holder uuids or empty array
 */
export const getAllHolderUuidsFromBookedProducts = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]): HolderUuids => {
    const bookedHolderUuids: HolderUuids = [];

    bookedTariffs.forEach(bookedTariff => bookedHolderUuids.push(...bookedTariff.holderUuids));

    return bookedHolderUuids;
  }
);

/**
 * Get tariff by ticket holder uuid
 * @description Get tariff by assigned ticket holder uuid
 * @param holderUuid
 * @returns Booked tariff or null
 */
export const getBookedTariffByHolderUuid = (holderUuid: string) =>
  createSelector(
    getAllBookedTariffs,
    (bookedTariffs: BookingTariff[]): BookingTariff => {
      return bookedTariffs.find(bookedTariff => bookedTariff.holderUuids.includes(holderUuid)) || null;
    }
  );

/**
 * Get booked tariff
 * @description Get booked tariff
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Booked tariff or null
 */
export const getBookedTariff = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getAllBookedTariffs,
    (bookedTariffs: BookingTariff[]): BookingTariff =>
      bookedTariffs.find(
        bookedTariff =>
          bookedTariff.ticketTypeId === ticketTypeId &&
          bookedTariff.ticketPersonId === ticketPersonId &&
          bookedTariff.voucherCode === voucherCode &&
          bookedTariff.packageNumber === packageNumber &&
          bookedTariff.packageIndex === packageIndex
      ) || null
  );

/**
 * Get booked tariff holder uuids
 * @description Get booked tariff holder uuids
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Holder uuids or empty array
 */
export const getBookedTariffHolderUuids = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff): HolderUuids => (bookedTariff ? bookedTariff.holderUuids : [])
  );

/**
 * Get booked tariff count
 * @description Get number of booked tariffs
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export const getBookedTariffCount = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff): number => (!!bookedTariff ? bookedTariff.count : 0)
  );

/**
 * Get booked tariff price
 * @description Get price of booked tariff
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export const getBookedTariffPrice = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff): number => (!!bookedTariff ? bookedTariff.price : 0)
  );

/**
 * Get booked tariff workshops
 * @description Get booked tariff assigned workshops
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Workshops or empty array
 */
export const getBookedTariffWorkshops = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (tariff: BookingTariff) => tariff.workshops.length || []
  );

/**
 * Get booked tariff workshop validity
 * @description Get is booked tariff workshop valid
 * @param isWorkshopsSelectionMandatory
 * @param isWorkshopsSelectionMandatoryForZeroPriceTickets
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export const areBookedTariffWorkshopsValid = (
  isWorkshopsSelectionMandatory: boolean,
  isWorkshopsSelectionMandatoryForZeroPriceTickets: boolean,
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff) => {
      if (!bookedTariff) {
        return true;
      }

      return false;
    }
  );

/**
 * Gets all booked tariffs with workshops
 * @description Get all booked tariffs which have booked at least one workshop
 * @returns Tariffs which have booked at least one workshop
 */
export const getAllBookedWorkshopTariffs = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]) => bookedTariffs.filter(bookedTariff => bookedTariff.workshops.length) || []
);

/**
 * Get all assigned workshop holder uuids
 * @description Get all assigned workshop holder uuids by provided workshop id
 * @param workshopId
 * @returns Holder uuids or empty array
 */
export const getAssignedWorkshopHolderUuidsByWorkshopId = (workshopId: number) =>
  createSelector(
    getAllBookedWorkshopTariffs,
    (bookedTariffs: BookingTariff[]): HolderUuids => {
      const assignedWorkshopHolderUuids: HolderUuids = [];

      bookedTariffs.forEach(bookedTariff => {
        bookedTariff.workshops.forEach(bookedTariffWorkshop => {
          if (bookedTariffWorkshop.id === workshopId) {
            bookedTariffWorkshop.holderUuids.forEach(workshopHolderUuid =>
              assignedWorkshopHolderUuids.push(workshopHolderUuid)
            );
          }
        });
      });

      return assignedWorkshopHolderUuids;
    }
  );

/**
 * Get all booked contingent tariffs
 * @description Get all booked contingent tariffs with contingent reservation
 * @return Booked tariff or empty array
 */
export const getAllBookedContingentTariffs = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]) => {
    const contingentBookedTariffs: BookingTariff[] = [];

    bookedTariffs.forEach(bookedTariff => {
      if (bookedTariff.contingentReservations.length) {
        contingentBookedTariffs.push(bookedTariff);
      }
    });

    return contingentBookedTariffs;
  }
);

/**
 * Get all booked contingent reservations
 * @description Get all booked contingent reservations
 * @return Contingent reservation or empty array
 */
export const getAllBookedContingentReservations = createSelector(
  getAllBookedContingentTariffs,
  (bookedTariffs: BookingTariff[]) => {
    const bookingContingentReservations: BookingContingentReservation[] = [];

    bookedTariffs.forEach(bookedTariff =>
      bookedTariff.contingentReservations.forEach(contingentReservation =>
        bookingContingentReservations.push(contingentReservation)
      )
    );

    return bookingContingentReservations;
  }
);

/**
 * Are all booked contingent reservations valid
 * @description Check if all booked contingent reservations are valid
 * @returns Boolean
 */
export const areBookedContingentReservationsValid = createSelector(
  getAllBookedContingentReservations,
  (bookingContingentReservations: BookingContingentReservation[]) =>
    bookingContingentReservations.every(bookingContingentReservation => bookingContingentReservation.isValid)
);

/**
 * Is contingent reservation from date picked and invalid
 * @description Check if contingent reservation from date is picked and invalid
 * @return Boolean
 */
export const isBookedContingentReservationFromDateSetAndInvalid = createSelector(
  getAllBookedContingentReservations,
  (bookingContingentReservations: BookingContingentReservation[]) =>
    bookingContingentReservations.some(
      bookingContingentReservation => bookingContingentReservation.fromDate && !bookingContingentReservation.isValid
    )
);

/**
 * Get booked tariff contingent reservations
 * @description Get booked tariff contingent reservations array
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Contingent reservation or empty array
 */
export const getBookedTariffContingentReservations = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff): BookingContingentReservation[] =>
      !!bookedTariff ? bookedTariff.contingentReservations : []
  );

/**
 * Are booked tariff contingent reservations valid
 * @description Check if booked tariff contingent reservations are valid
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export const areBookedTariffContingentReservationsValid = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariffContingentReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookingContingentReservations: BookingContingentReservation[]) =>
      bookingContingentReservations.every(bookingContingentReservation => bookingContingentReservation.isValid)
  );

/**
 * Get booked tariff contingent reservations with selected date
 * @description Get booked tariff contingent reservations array with selected date
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Contingent reservation or empty array
 */
export const getBookedTariffContingentReservationsWithSelectedDate = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariffContingentReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedContingentReservations: BookingContingentReservation[]): BookingContingentReservation[] =>
      bookedContingentReservations.filter(contingentReservation => contingentReservation.fromDate)
  );

/**
 * Get all booked parking tariffs
 * @description Get all booked parking tariffs with parking reservation
 * @return Booked tariff or empty array
 */
export const getAllBookedParkingTariffs = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]) => {
    const parkingBookedTariffs: BookingTariff[] = [];

    bookedTariffs.forEach(bookedTariff => {
      if (bookedTariff.parkingReservations.length) {
        parkingBookedTariffs.push(bookedTariff);
      }
    });

    return parkingBookedTariffs;
  }
);

/**
 * Get all booked parking reservations
 * @description Get all booked parking reservations by ticketIndex returned from API response
 * @return Parking reservation or empty array
 */
export const getAllBookedParkingReservations = createSelector(
  getAllBookedParkingTariffs,
  (bookedTariffs: BookingTariff[]) => {
    const bookingParkingReservations: BookingParkingReservation[] = [];

    bookedTariffs.forEach(bookedTariff =>
      bookedTariff.parkingReservations.forEach(parkingReservation =>
        bookingParkingReservations.push(parkingReservation)
      )
    );

    return bookingParkingReservations;
  }
);

/**
 * Are all booked parking reservations valid
 * @description Check if all booked parking reservations are valid
 * @returns Boolean
 */
export const areBookedParkingReservationsValid = createSelector(
  getAllBookedParkingReservations,
  (bookingParkingReservations: BookingParkingReservation[]) =>
    bookingParkingReservations.every(bookingParkingReservation => bookingParkingReservation.isValid)
);

/**
 * Get booked tariff parking reservations
 * @description Get booked tariff parking reservations array
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Parking reservation or empty array
 */
export const getBookedTariffParkingReservations = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedTariff: BookingTariff): any[] => (!!bookedTariff ? bookedTariff.parkingReservations : [])
  );

/**
 * Are booked tariff Parking reservations valid
 * @description Check if booked tariff contingent reservations are valid
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export const areBookedTariffParkingReservationsValid = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariffParkingReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookingParkingReservations: BookingParkingReservation[]) =>
      bookingParkingReservations.every(bookingParkingReservation => bookingParkingReservation.isValid)
  );

/**
 * Get booked tariff parking reservations with selected date
 * @description Get booked tariff parking reservations array with selected date
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Parking reservation or empty array
 */
export const getBookedTariffParkingReservationsWithSelectedDate = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariffParkingReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (bookedParkingReservations: BookingParkingReservation[]): BookingParkingReservation[] =>
      bookedParkingReservations.filter(parkingReservation => parkingReservation.since)
  );

/**
 * Get booked tariff parking reservation total price
 * @description Get booked tariff parking reservation total price
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export const getBookedTariffParkingReservationTotalPrice = (
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    getBookedTariffParkingReservationsWithSelectedDate(
      ticketTypeId,
      ticketPersonId,
      voucherCode,
      packageNumber,
      packageIndex
    ),
    (bookedParkingReservations: BookingParkingReservation[]): number =>
      bookedParkingReservations.reduce(
        (bookedParkingReservationTotalPrice, bookedParkingReservation) =>
          bookedParkingReservationTotalPrice + bookedParkingReservation.price,
        0
      )
  );

/**
 * Are booked tariff reservations valid
 * @description Check if booked tariff workshop, contingent and parking reservations are valid
 * @param isWorkshopsSelectionMandatory
 * @param isWorkshopsSelectionMandatoryForZeroPriceTickets
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export const areBookedTariffReservationsValid = (
  isWorkshopsSelectionMandatory: boolean,
  isWorkshopsSelectionMandatoryForZeroPriceTickets: boolean,
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode?: string,
  packageNumber?: number,
  packageIndex?: number
) =>
  createSelector(
    areBookedTariffWorkshopsValid(
      isWorkshopsSelectionMandatory,
      isWorkshopsSelectionMandatoryForZeroPriceTickets,
      ticketTypeId,
      ticketPersonId,
      voucherCode,
      packageNumber,
      packageIndex
    ),
    areBookedTariffContingentReservationsValid(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    areBookedTariffParkingReservationsValid(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex),
    (
      areBookedTariffWorkshopsValid,
      areBookedTariffContingentReservationsValid,
      areBookedTariffParkingReservationsValid
    ) =>
      areBookedTariffWorkshopsValid &&
      areBookedTariffContingentReservationsValid &&
      areBookedTariffParkingReservationsValid
  );

/**
 * Get booked packages count by package number
 * @description Get booked packages in package type array by package number
 * @param packageNumber
 * @returns Number of booked packages
 */
export const getBookedPackagesCount = (packageNumber: number) =>
  createSelector(
    getBookedPackagesByPackageNumber(packageNumber),
    (bookedPackages): number => bookedPackages.length
  );

/**
 * Get unique bookings with total cunt
 * @description Get unique bookings with accumulated count
 * @return Booked tariff or empty array
 */
export const getUniqueBookedTariffsWithTotalCount = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]): BookingTariff[] => {
    return bookedTariffs.reduce((reducedTariffs: BookingTariff[], bookedTariff) => {
      const reducedTariff = reducedTariffs.find(
        reducedTariff => reducedTariff.ticketPersonId === bookedTariff.ticketPersonId
      );

      if (reducedTariff) {
        reducedTariff.count += bookedTariff.count;
        reducedTariff.holderUuids = [...reducedTariff.holderUuids, ...bookedTariff.holderUuids];
      } else {
        reducedTariffs.push(bookedTariff);
      }

      return reducedTariffs;
    }, []);
  }
);

/**
 * Get unique bookings for release
 * @description Get unique bookings with count set to 0
 * @return Booked tariff or empty array
 */
export const getUniqueBookedTariffsForRelease = createSelector(
  getAllBookedTariffs,
  (bookedTariffs: BookingTariff[]): BookingTariff[] => {
    return bookedTariffs.reduce((reducedTariffs: BookingTariff[], bookedTariff) => {
      const reducedTariff = reducedTariffs.find(
        reducedTariff => reducedTariff.ticketPersonId === bookedTariff.ticketPersonId
      );

      if (reducedTariff) {
        reducedTariff.count = 0;
        reducedTariff.holderUuids = [...reducedTariff.holderUuids, ...bookedTariff.holderUuids];
      } else {
        bookedTariff.count = 0;
        reducedTariffs.push(bookedTariff);
      }

      return reducedTariffs;
    }, []);
  }
);

/**
 * Get shared booked ticketPersonId tariffs total count
 * @description Get shared booked ticketPersonId tariffs total count sorted by ticketPersonId (current added tariff(s) count not included)
 * @returns Shared booked tariff count object sorted by ticketPersonId
 */
export const getSharedBookedPersonIdTariffsTotalCount = (bookingProductTypes: BookingProductType[]) =>
  createSelector(
    getAllBookedTariffs,
    (bookedTariffs: BookingTariff[]): SharedBookedPersonIdTariffsTotalCount => {
      const bookedTariffsCount: SharedBookedPersonIdTariffsTotalCount = {};

      bookedTariffs.forEach(bookedTariff => {
        if (bookedTariffsCount[bookedTariff.ticketPersonId]) {
          bookedTariffsCount[bookedTariff.ticketPersonId] += bookedTariff.count;
        } else {
          bookedTariffsCount[bookedTariff.ticketPersonId] = bookedTariff.count;
        }
      });

      const decreaseBookingPersonIdTariffCount = (bookingTariff: BookingTariff) => {
        const bookedTariff = bookedTariffs.find(
          bookedTariff =>
            bookedTariff.ticketPersonId === bookingTariff.ticketPersonId &&
            bookedTariff.voucherCode === bookingTariff.voucherCode &&
            bookedTariff.packageNumber == bookingTariff.packageNumber &&
            bookedTariff.packageIndex === bookingTariff.packageIndex
        );

        if (bookedTariff) {
          bookedTariffsCount[bookedTariff.ticketPersonId] -= bookedTariff.count;
        }
      };

      bookingProductTypes.forEach(bookingProductType => {
        if (isBookingProductTypeTariff(bookingProductType)) {
          bookingProductType.tariffs.forEach(decreaseBookingPersonIdTariffCount);
        } else if (isBookingProductTypePackage(bookingProductType)) {
          bookingProductType.productTypes.forEach(bookingPackageProductType => {
            if (isBookingProductTypeTariff(bookingPackageProductType)) {
              bookingPackageProductType.tariffs.forEach(decreaseBookingPersonIdTariffCount);
            }
          });
        }
      });

      return bookedTariffsCount;
    }
  );

/**
 * Get single package price summed up
 * @description Get booked package tariffs by index and number and sum their price * count
 * @param packageNumber
 * @param packageIndex
 * @returns Package price summed up
 */
export const getBookedPackagePriceByPackageNumberAndPackageIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getBookedPackageTariffsByPackageNumberAndIndex(packageNumber, packageIndex),
    (bookedPackageTariffs: BookingTariff[]): number =>
      bookedPackageTariffs.reduce(
        (packageTotal, bookedPackageTariff) => packageTotal + bookedPackageTariff.count * bookedPackageTariff.price,
        0
      )
  );

/**
 * Get total price from all parkings
 * @description Get booked parking price
 * @returns Number which represents total price amount of all booked parkings
 */
export const getAllBookedParkingTariffsCount = createSelector(
  getAllBookedTariffs,
  (allProductsTotalPrice: BookingTariff[]): BookingTariff[] =>
    allProductsTotalPrice.filter(bookedTariff => bookedTariff.parkingReservations.length > 0)
);

/**
 * Get total count from all booked products
 * @description Get booked products count - both single tariffs and also tariffs from packages with each single count
 * @returns Number which represents total count of all booked products
 */
export const getAllBookedProductsCount = createSelector(
  getAllBookedTariffs,
  (allBookedTariffs: BookingTariff[]): number =>
    allBookedTariffs.reduce((totalCount, bookedTariff) => totalCount + bookedTariff.count, 0)
);

/**
 * Is product count valid
 * @description Check if product is added to product booking list
 * @return Boolean
 */
export const isBookedProductsCountValid = createSelector(
  getAllBookedProductsCount,
  allBookedProductsCount => !!allBookedProductsCount
);

/**
 * Get sorted basket products
 * @description Sorts basket products to have packages, normal tariffs and parking tickets consecutively
 * @returns Array of sorted products
 */
export const getSortedBasketProducts = createSelector(
  getBookedProducts,
  (bookedProductTypes: BookedProducts): BookedProducts =>
    bookedProductTypes.sort((currentBookedProductType, nextBookedProductType) => {
      if (isBookingProductTypePackage(currentBookedProductType)) {
        if (isBookingProductTypePackage(nextBookedProductType)) {
          return 0;
        }

        return -1;
      }

      if (isBookingProductTypeTariff(currentBookedProductType)) {
        if (isBookingProductTypePackage(nextBookedProductType)) {
          return 1;
        }

        if (isBookingProductTypeTariff(nextBookedProductType)) {
          const hasCurrentBookedTariffTypeParkingReservations = currentBookedProductType.tariffs.some(
            bookedTariff => !!bookedTariff.parkingReservations.length
          );

          const hasNextBookedTariffTypeParkingReservations = nextBookedProductType.tariffs.some(
            bookedTariff => !!bookedTariff.parkingReservations.length
          );

          if (hasCurrentBookedTariffTypeParkingReservations && !hasNextBookedTariffTypeParkingReservations) {
            return 1;
          }

          if (!hasCurrentBookedTariffTypeParkingReservations && hasNextBookedTariffTypeParkingReservations) {
            return -1;
          }
        }
      }
    })
);

/**
 * Get total price from all booked products
 * @description Get booked products price - both single tariffs and also tariffs from packages
 * @returns Number which represents total price amount of all booked products
 */
export const getAllBookedProductsTotalPrice = createSelector(
  getAllBookedTariffs,
  (allProductsTotalPrice: BookingTariff[]): number =>
    allProductsTotalPrice.reduce((totalPrice, bookedTariff) => {
      const { price, count, workshops } = bookedTariff;

      if (bookedTariff.parkingReservations.length) {
        return totalPrice + price;
      }

      workshops.forEach(({ holderUuids, price: workshopPrice }) => {
        if (workshopPrice > 0) {
          totalPrice += holderUuids.length * workshopPrice;
        }
      });

      return totalPrice + price * count;
    }, 0)
);

/**
 * Get all grouped package total price
 * @description Get all packages total price grouped by package index
 * @returns Object with key as package index and value as package total price
 */
export const getAllBookedPackagePricesGroupedByPackageIndex = createSelector(
  getBookedPackageTariffs,
  (allBookedPackageTariffs: BookingTariff[]): BookedPackageTotalPriceGroupedByPackageIndex => {
    const packagePricesByPackageIndex: BookedPackageTotalPriceGroupedByPackageIndex = {};

    allBookedPackageTariffs.forEach(packageTariff => {
      const { packageIndex, count, price, workshops } = packageTariff;
      const packageTariffTotalPrice = count * price;

      if (!packagePricesByPackageIndex[packageIndex]) {
        packagePricesByPackageIndex[packageIndex] = packageTariffTotalPrice;
      } else {
        packagePricesByPackageIndex[packageIndex] += packageTariffTotalPrice;
      }

      packagePricesByPackageIndex[packageIndex] = workshops.reduce(
        (packageTotalPrice, { holderUuids, price: workshopPrice }) =>
          packageTotalPrice + holderUuids.length * workshopPrice,
        packagePricesByPackageIndex[packageIndex]
      );
    });

    return packagePricesByPackageIndex;
  }
);

/**
 * Get all grouped packages subtotal price
 * @description Get all packages subtotal total price grouped by package number
 * @returns Object with key as package index and value as package subtotal price
 */
export const getAllBookedPackagePricesGroupedByPackageNumber = createSelector(
  getBookedPackageTariffs,
  (allBookedPackageTariffs: BookingTariff[]): BookedPackageTotalPriceGroupedByPackageNumber => {
    const packagePricesByPackageNumber: BookedPackageTotalPriceGroupedByPackageNumber = {};

    allBookedPackageTariffs.forEach(packageTariff => {
      const { packageNumber, count, price, workshops } = packageTariff;
      const packageTariffTotalPrice = count * price;

      if (!packagePricesByPackageNumber[packageNumber]) {
        packagePricesByPackageNumber[packageNumber] = packageTariffTotalPrice;
      } else {
        packagePricesByPackageNumber[packageNumber] += packageTariffTotalPrice;
      }

      packagePricesByPackageNumber[packageNumber] = workshops.reduce(
        (packageTotalPrice, { holderUuids, price: workshopPrice }) =>
          packageTotalPrice + holderUuids.length * workshopPrice,
        packagePricesByPackageNumber[packageNumber]
      );
    });

    return packagePricesByPackageNumber;
  }
);
