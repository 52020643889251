import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {
  // Allow whole numbers only
  private regex: RegExp = new RegExp(/^\d+$/g);
  // Allow key codes for special events. Reflect:
  // Enter, Backspace, Tab, Home, End, Delete, Arrow Left, Arrow Right
  private specialKeys: Array<string> = [
    'Enter',
    'Backspace',
    'Tab',
    'Home',
    'End',
    'Delete',
    'ArrowLeft',
    'ArrowRight'
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Enter, Backspace, Tab, Home, End, Delete, Arrow Left, Arrow Right
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
