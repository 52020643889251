import { CustomizationService } from '@shared/services-with-reducers/customization/customization.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TicketsWidgetSettings } from '@app/_pages/widget-admin/widget-models.interface';

import * as fromRoot from '@app/app.reducer';
import * as customizationActions from '@shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from '@shared/services-with-reducers/exhibition/exhibition.actions';
import { SetIsWidget } from '@shared/services-with-reducers/helpers/helper.actions';

@Component({
  selector: 'app-widget-page',
  templateUrl: './widget-page.component.html',
  styleUrls: ['./widget-page.component.scss']
})
export class WidgetPage implements OnInit, OnDestroy {
  widgetId: string;

  private _unsubscribe = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService
  ) {}

  ngOnInit() {
    this._store.dispatch(new SetIsWidget(true));

    this._route.queryParams
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((queryParams: TicketsWidgetSettings) =>
        this.readQueryParamsWidgetSettings(queryParams)
      );

    this._store
      .select(fromRoot.getExhibitionSettings)
      .pipe(
        filter(settings => {
          if (settings) {
            return true;
          }
        }),
        first()
      )
      .subscribe(settings => {
        this._customizationService.setStyles();
      });
  }

  ngOnDestroy() {
    this._store.dispatch(new SetIsWidget(false));
    
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  readQueryParamsWidgetSettings(queryParams: TicketsWidgetSettings) {
    const eventId = Number(queryParams.eventId);

    if (!eventId) {
      return;
    }

    this._store.dispatch(new exhibitionActions.SelectAction(eventId));

    this._store.dispatch(
      new customizationActions.SetWidgetSettings({
        type: 'ticketsWidget',
        settings: queryParams
      })
    );
  }
}
