<header class="header">
  <div class="header-wrapper">
    <div class="logo-wrapper">
      <div style="position: relative;">
        <a
          href="{{ !!isEventSelected ? exhibitionSettings?.headerLogoLink : getOperatorsSettings?.headerLogoLink }}"
          target="_blank"
          [ngClass]="{
            'hide-link': !!isEventSelected
              ? !exhibitionSettings?.isHeaderLogoLinkVisible
              : !getOperatorsSettings?.isHeaderLogoLinkVisible
          }"
        >
          <img class="operator-logo" *ngIf="logoUrl" [src]="logoUrl" alt="{{ env.friedrichshafen }}" />
        </a>

        <app-age-rating
          *ngIf="ageRating"
          [rating]="ageRating"
          [adjustPositions]="{ top: '10px', right: '25px' }"
        ></app-age-rating>
      </div>

      <ng-container *ngIf="!isSelfRegistrationEnabled && !isEventSeriesPage">
        <div
          class="select-event"
          *ngIf="displayOnMaintenancePage || (!isEventSelected && router.url !== '/')"
          data-translation="navigation.select-event"
          routerLink="/"
        >
          <span data-translation="navigation.select-event" *ngIf="!displayOnMaintenancePage">
            {{ 'navigation.select-event' | translate | hideTranslation }}</span
          >
          <span data-translation="navigation.select-event.maintenance" *ngIf="displayOnMaintenancePage">
            {{ 'navigation.select-event.maintenance' | translate | hideTranslation }}</span
          >
        </div>
        <div
          class="select-event"
          *ngIf="isEventSelected && displayBackToEvent && selectedStep !== 'invoice'"
          data-translation="navigation.back-to-event"
          (click)="openModalWindow()"
        >
          {{ 'navigation.back-to-event' | translate | hideTranslation }}
        </div>
        <div
          class="select-event"
          *ngIf="isEventSelected && !displayBackToEvent && !displayOnMaintenancePage"
          data-translation="navigation.back-to-shop"
          (click)="backToShop()"
        >
          {{ 'navigation.back-to-shop' | translate | hideTranslation }}
        </div>
      </ng-container>

      <div
        class="select-event"
        *ngIf="isEventSelected && isSelfRegistrationEnabled && displayBactToSelfReg"
        data-translation="navigation.back-to-self-reg"
        (click)="backToSelfReg()"
      >
        {{ 'navigation.back-to-self-reg' | translate | hideTranslation }}
      </div>
    </div>

    <app-modal-window
      [modalWindowActive]="modalWindowOpen"
      translateTitle="true"
      [modalWindowTitle]="'navigation.logo.modalWindow.title'"
      (contextmenu)="closeModalWindowOnRightClick($event)"
    >
      <button class="button button-option" modalWindow-close (click)="closeModalWindow($event)">
        <span data-translation="navigation.modalWindow.stay-on-page">{{
          'navigation.modalWindow.stay-on-page' | translate | hideTranslation
        }}</span>
      </button>
      <button class="button" modalWindow-submit (click)="submitModalWindow($event)">
        <span data-translation="navigation.modalWindow.leave-page">{{
          'navigation.modalWindow.leave-page' | translate | hideTranslation
        }}</span>
      </button>
    </app-modal-window>

    <div class="navigation-wrapper menu-button-wrapper" *ngIf="viewport <= mediumBreakpoint">
      <div class="menu-button" (click)="toggleMenuButton()">
        <span class="menu-button_text">{{ 'navigation.menu' | translate | hideTranslation }}</span>
        <div class="nav-icon" [class.open]="isMenuOpen"><span></span> <span></span> <span></span> <span></span></div>
      </div>
    </div>

    <nav class="navigation-wrapper menu-content-wrapper" [class.closed]="!isMenuOpen">
      <app-countdown></app-countdown>

      <span *ngIf="isSelfRegistrationEnabled" class="navigation-group">
        <app-reset-button></app-reset-button>
      </span>
      <span
        *ngIf="
          (isLoggedIn$ | async) && selectedStep !== 'invoice' && selectedStep !== 'recipe' && !displayOnMaintenancePage
        "
        class="navigation-group"
      >
        <a
          class="navigation-content"
          (click)="closeMenu()"
          routerLink="/profile"
          [routerLinkActive]="['active-nav-link']"
          data-translation="navigation.profile"
        >
          <svg class="icon icon-user-topbar">
            <use xlink:href="assets/defs.svg#icon-user-topbar" href="assets/defs.svg#icon-user-topbar"></use>
          </svg>
          {{ 'navigation.profile' | translate | hideTranslation }}
        </a>
      </span>

      <span
        *ngIf="
          !(isLoggedIn$ | async) &&
          !(hideTopBarLogin$ | async) &&
          !isSelfRegistrationEnabled &&
          !displayOnMaintenancePage
        "
        class="navigation-group"
      >
        <a
          class="navigation-content"
          (click)="closeMenu()"
          routerLink="/login"
          [routerLinkActive]="['active-nav-link']"
          data-translation="navigation.profile"
        >
          {{ 'navigation.login' | translate | hideTranslation }}
        </a>
      </span>

      <span *ngIf="this.isLoggedInAsAdmin$ | async" class="navigation-group">
        <a
          class="navigation-content"
          (click)="closeMenu()"
          routerLink="/translations"
          [routerLinkActive]="['active-nav-link']"
          data-translation="navigation.translations"
        >
          {{ 'navigation.translations' | translate | hideTranslation }}
        </a>
      </span>

      <span *ngIf="isEventSelected && this.isLoggedInAsAdmin$ | async" class="navigation-group">
        <a
          class="navigation-content"
          (click)="closeMenu()"
          routerLink="/widget-admin"
          [routerLinkActive]="['active-nav-link']"
          data-translation="navigation.translations"
        >
          WIDGET
        </a>
      </span>

      <a (click)="togleSubmenuButton()" class="navigation-content language" [class.open]="isLangMenuOpen">
        <svg class="icon icon-earth">
          <use xlink:href="assets/defs.svg#icon-earth" href="assets/defs.svg#icon-earth"></use>
        </svg>
        <span class="toggle-button">{{ currentLanguage }}</span>
      </a>

      <div [class.open]="isLangMenuOpen" class="lang-submenu-wrapper">
        <ul>
          <li *ngFor="let lang of supportedLanguages$ | async" (click)="changeLanguage(lang); closeMenu()">
            <span>{{ lang }}</span>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="bookedProductsCount$ | async as bookedProductsCount">
        <div
          *ngIf="isEventSelected && !!bookedProductsCount && selectedStep !== 'invoice' && !isSelfRegistrationEnabled"
          class="navigation-group navigation-basket"
          [class.alignBasketOnMobile]="this.isMobile && this.basketOpened"
          appClickOutside
          (clickOutside)="basketOpened = false"
        >
          <span
            class="navigation-content basket-link"
            (click)="toggleBasket()"
            data-translation="navigation.basket"
            [class.open]="basketOpened"
          >
            <svg class="icon icon-basket">
              <use xlink:href="assets/defs.svg#icon-basket"></use>
            </svg>
            <span class="toggle-button"></span>
            <span class="basket-count">
              {{ bookedProductsCount }}
            </span>
          </span>

          <app-basket (closeBasket)="closeBasket($event)" class="basket-component" *ngIf="basketOpened"></app-basket>
        </div>
      </ng-container>

      <span *ngIf="(profile$ | async) && !displayOnMaintenancePage" class="user-group">
        <div class="black">{{ profile.firstName }} {{ profile.lastName }}</div>

        <ng-container *ngIf="legitimationStatus$ | async as legitimationStatus">
          <div *ngIf="legitimationStatus !== LegitimationStatus.New && !isSelfRegistrationEnabled">
            <div>
              <span class="legitimation-status-text" data-translation="navigation.legitimation-status.text">
                {{ 'navigation.legitimation-status.text' | translate | hideTranslation }}:
              </span>
              <span
                *ngIf="legitimationStatus === LegitimationStatus.InProgress"
                class="legitimation-status-pending"
                data-translation="navigation.legitimation-status.text-inProgress"
              >
                {{ 'navigation.legitimation-status.text-inProgress' | translate | hideTranslation }}</span
              >
              <span
                *ngIf="legitimationStatus === LegitimationStatus.Approved"
                class="legitimation-status-approved"
                data-translation="navigation.legitimation-status.text-approved"
              >
                {{ 'navigation.legitimation-status.text-approved' | translate | hideTranslation }}</span
              >
              <span
                *ngIf="legitimationStatus === LegitimationStatus.Rejected"
                class="legitimation-status-rejected"
                data-translation="navigation.legitimation-status.text-rejected"
              >
                {{ 'navigation.legitimation-status.text-rejected' | translate | hideTranslation }}</span
              >
            </div>
          </div>
        </ng-container>
      </span>

      <span *ngIf="(profile$ | async) && !displayOnMaintenancePage" class="user-group">
        <div class="logout-user" (click)="userService.logout(); closeMenu()" data-translation="navigation.logout">
          <svg class="icon icon-logout">
            <use xlink:href="assets/defs.svg#icon-logout"></use>
          </svg>
          <span class="logout-text">
            {{ 'navigation.logout' | translate | hideTranslation }}
          </span>
        </div>
      </span>
    </nav>
  </div>
</header>
