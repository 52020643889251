import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslationsService } from '@pages/translations/translations.service';
import { TRANSLATION_DEBOUNCE_TIME } from '@shared/tokens';
import {
  LoadAndSetTranslations,
  SetIsTranslationLoaded,
  SetTranslations,
  ActionTypes as TranslationActionTypes
} from '@store/translation/translation.actions';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class TranslationsEffects {
  @Effect()
  loadAndSetTranslations$: Observable<Action> = this.actions$.pipe(
    ofType<LoadAndSetTranslations>(TranslationActionTypes.LOAD_AND_SET_TRANSLATIONS),
    debounceTime(this.debounce),
    switchMap(({ payload }) => {
      const { lang, eventId } = payload;

      return this.translationsService.loadAndMergeTranslations(lang, eventId).pipe(
        map((translations: { [key: string]: string }) => {
          return new SetTranslations({
            language: lang,
            list: translations
          });
        }),
        catchError(() => of(new SetTranslations(null)))
      );
    })
  );

  @Effect()
  setIsTranslationLoaded$: Observable<Action> = this.actions$.pipe(
    ofType<SetTranslations>(TranslationActionTypes.SET_TRANSLATIONS),
    debounceTime(this.debounce),
    tap(({ payload }) => this.translationsService.setTranslationsIntoTranslateService(payload)),
    switchMap(() => of(new SetIsTranslationLoaded(true)))
  );

  constructor(
    private actions$: Actions,
    @Optional()
    @Inject(TRANSLATION_DEBOUNCE_TIME)
    private debounce: number = 50,
    private translationsService: TranslationsService
  ) {}
}
