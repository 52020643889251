import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingState } from '@products/models/booking.model';
import { HolderState } from '@products/models/holder.model';
import { SelectionState } from '@products/models/product-selection.model';
import { ProductStatusState } from '@products/models/products.model';
import { WorkshopState } from '@products/models/workshop.model';
import { State } from '@store/products/product.reducer';

export const productsFeatureSelector = createFeatureSelector<State>('products');

export const getProductSelectionState = createSelector(
  productsFeatureSelector,
  ({ selection }): SelectionState => selection
);

export const getProductBookingState = createSelector(
  productsFeatureSelector,
  ({ booking }): BookingState => booking
);

export const getProductHolderState = createSelector(
  productsFeatureSelector,
  ({ holder }): HolderState => holder
);

export const getProductWorkshopState = createSelector(
  productsFeatureSelector,
  ({ workshop }): WorkshopState => workshop
);

export const getProductStatusState = createSelector(
  productsFeatureSelector,
  ({ status }): ProductStatusState => status
);
