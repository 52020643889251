import { Store, select } from '@ngrx/store';
import { SetInitialIsWorkshopDetailModalOpenStatus, ToggleIsWorkshopDetailModalOpenStatus } from '@store/products/status/workshop/workshop.actions';
import { getIsWorkshopDetailModalOpen } from '@store/products/status/workshop/workshop.selectors';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var WorkshopStatusService = /** @class */ (function () {
    function WorkshopStatusService(store) {
        this.store = store;
    }
    WorkshopStatusService.prototype.setInitialWorkshopDetailModalOpenState = function () {
        var _this = this;
        var setInitialIsWorkshopDetailModalOpenStatus$ = this.store
            .pipe(select(getIsWorkshopDetailModalOpen))
            .pipe(take(1))
            .subscribe(function (isWorkshopDetailModalOpen) {
            if (isWorkshopDetailModalOpen) {
                _this.store.dispatch(new SetInitialIsWorkshopDetailModalOpenStatus());
            }
        });
        setInitialIsWorkshopDetailModalOpenStatus$.unsubscribe();
    };
    WorkshopStatusService.prototype.toggleWorkshopDetailModalOpenState = function () {
        this.store.dispatch(new ToggleIsWorkshopDetailModalOpenStatus());
    };
    WorkshopStatusService.ngInjectableDef = i0.defineInjectable({ factory: function WorkshopStatusService_Factory() { return new WorkshopStatusService(i0.inject(i1.Store)); }, token: WorkshopStatusService, providedIn: "root" });
    return WorkshopStatusService;
}());
export { WorkshopStatusService };
