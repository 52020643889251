<form [formGroup]="settingsForm">
    <div class="section-headline">
        <h2>{{ 'widget.appearance' | translate }}</h2>
        <div class="section-headline-side">←</div>
    </div>

    <div class="section-body">
        <div>
            <div class="col-lg-6 p-x-0">
                <div class="input-wrapper form-wrapper">
                    <div class="position-relative">
                        <input formControlName="width"
                               id="widget.width"
                               type="text"
                               class="hasvalue" />

                        <label class="placeholder"
                               for="widget.width"
                               data-translation="widget.width">{{
                                                    'widget.width' | translate
                                                }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="col-lg-6 p-x-0">
                <div class="input-wrapper form-wrapper">
                    <div class="position-relative">
                        <input formControlName="height"
                               id="widget.height"
                               type="text"
                               class="hasvalue" />

                        <label class="placeholder"
                               for="widget.height"
                               data-translation="widget.height">{{
                                                    'widget.height' | translate
                                                }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="checkbox-button">
                <div class="decorated-checkbox">
                    <input id="header"
                           formControlName="header"
                           type="checkbox" />

                    <label for="header"></label>
                </div>

                <div>
                    <label class="black checkbox-label"
                           for="header">
                        <span>{{
                                                'widget.show-header' | translate
                                            }}</span>
                    </label>
                </div>
            </div>

            <div class="checkbox-button">
                <div class="decorated-checkbox">
                    <input id="banner"
                           formControlName="banner"
                           type="checkbox" />

                    <label for="banner"></label>
                </div>

                <div>
                    <label class="black checkbox-label"
                           for="banner">
                        <span>{{
                                                'widget.show-banner' | translate
                                            }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="section-headline">
        <h2>{{ 'widget.tickets-in-widget' | translate }}
        </h2>
        <div class="section-headline-side">←</div>
    </div>

    <div class="section-body">
        <div formGroupName="tickets">
          <ng-container *ngFor="let widgetSelectionTariffType of widgetSelectionTariffTypes">
            <ng-container *ngIf="widgetSelectionTariffType.ticketTypeName != null">
              <h4 class="title">
                {{ widgetSelectionTariffType.ticketTypeName | translate }}
              </h4>
              
              <ng-container *ngFor="let tariff of widgetSelectionTariffType.tariffs">
                    <div class="checkbox-button">
                        <ng-container *ngIf="tariff != null">
                            <div class="decorated-checkbox">
                                <input [id]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"
                                       [formControlName]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"
                                       (change)="preserveLastTicketSelected($event)"
                                       type="checkbox" />
                                <label [for]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"></label>
                            </div>
        
                            <div>
                                <label class="black checkbox-label" [for]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId">
                                    <span>{{tariff.name | translate}}</span>
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
          </ng-container>
        </div>
    </div>
</form>

<div class="section-headline">
    <h2>{{ 'widget.embed' | translate }}</h2>
    <div class="section-headline-side">←</div>
</div>

<div class="section-body">
    <ng-container *ngIf="iframeEmbedCode">
        <h4 class="title">Iframe</h4>

        <p class="black">{{ iframeEmbedCode }}</p>
    </ng-container>

    <ng-container *ngIf="iframeURI">
        <h4 class="title">URL</h4>

        <p class="black">{{ iframeURI }}</p>
    </ng-container>
</div>

<div class="section-headline">
    <h2>{{ 'widget.preview' | translate }}</h2>
    <div class="section-headline-side">←</div>
</div>

<div class="section-body">
    <div class="iframe-wrapper">
        <iframe [width]="iframeAttributes?.width"
                [height]="iframeAttributes?.height"
                [src]="iframeURI | safeResource"></iframe>
    </div>
</div>
