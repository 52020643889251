<div class="section-body" *ngIf="form && inputs?.length">
  <div class="section-subtitle">
    <h3 data-stranslation="registration.questionnaire">
      {{ 'registration.questionnaire' | translate | hideTranslation }}
    </h3>
  </div>
  <form class="row" [formGroup]="form">
    <app-df-input
      *ngFor="let input of inputs"
      [input]="input"
      [saveFormCallback]="inputChangeCallback"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [form]="form"
      [action]="customAction"
    >
    </app-df-input>
  </form>
</div>
