import { Injectable } from '@angular/core';
import { State, getAnonymousHiddenSteps, getStepsValidity } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { VisibilityPayloadModel } from '@store/step-forms/step.interface';
import { SetAnonymousHiddenSteps, SetStepsVisibility } from '@store/step-forms/steps-forms.actions';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StepNavigationService {
  constructor(private store: Store<State>) {}

  setStepsVisibility(isVisible: boolean): void {
    combineLatest([this.store.pipe(select(getStepsValidity)), this.store.pipe(select(getAnonymousHiddenSteps))])
      .pipe(first())
      .subscribe(([stepsValidity, anonymousHiddenSteps]) => {
        let stepsArray: string[] = [];

        if (!isVisible) {
          Object.keys(stepsValidity).forEach(key => {
            if (stepsValidity[key].visible && key !== 'tickets' && key !== 'invoice') {
              stepsArray.push(key);
            }
          });
        } else {
          stepsArray = anonymousHiddenSteps;
        }

        if (!getAnonymousHiddenSteps.length) {
          this.store.dispatch(new SetAnonymousHiddenSteps(stepsArray));
        }

        const visibilityPayload: VisibilityPayloadModel[] = stepsArray.map(
          (step): VisibilityPayloadModel => {
            return {
              stepKey: step,
              visible: isVisible
            };
          }
        );

        this.store.dispatch(new SetStepsVisibility(visibilityPayload));
      });
  }
}
