import { OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getAllBookedProductsCount, getAssignedWorkshopHolderUuidsByWorkshopId } from '@store/products/booking/booking.selectors';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
var WorkshopVisitorsCountComponent = /** @class */ (function () {
    function WorkshopVisitorsCountComponent(store) {
        this.store = store;
    }
    WorkshopVisitorsCountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.workshopVisitorsCountViewModel$ = combineLatest([
            this.store.pipe(select(getAllBookedProductsCount)),
            this.store.pipe(select(getAssignedWorkshopHolderUuidsByWorkshopId(this.workshop.workshopId)))
        ]).pipe(map(function (_a) {
            var allBookedProductsCount = _a[0], assignedWorkshopHolderUuids = _a[1];
            return ({
                numberOfAssignedWorkshopHolderUuids: assignedWorkshopHolderUuids.length,
                isLastSeatWithSingleAssignedTariffBooking: _this.availableSeats === 0 && allBookedProductsCount === 1 && assignedWorkshopHolderUuids.length === 1
            });
        }));
    };
    return WorkshopVisitorsCountComponent;
}());
export { WorkshopVisitorsCountComponent };
