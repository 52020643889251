import * as fromRoot from '@app/app.reducer';
import * as userActions from '@store/user/user.actions';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/login/login.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { getIsTranslationLoaded } from '@store/translation/translation.selectors';
import { UserService } from '@store/user/user.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_formBuilder, _router, _store, _translateService, _loginService, _userService, _helperService, route) {
        this._formBuilder = _formBuilder;
        this._router = _router;
        this._store = _store;
        this._translateService = _translateService;
        this._loginService = _loginService;
        this._userService = _userService;
        this._helperService = _helperService;
        this.route = route;
        this.availableSocialLogins = [];
        this._subscriptions = new Subscription();
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initTabs();
        this.loginForm = this._formBuilder.group({
            email: ['', Validators.minLength(3)],
            password: ['', Validators.minLength(1)]
        });
        this._userService
            .getListOfSocialLogins()
            .pipe(filter(function (data) { return !!data; }), first())
            .subscribe(function (socialLogins) {
            _this.availableSocialLogins = socialLogins;
        });
        this.email = this.loginForm.controls['email'];
        this.password = this.loginForm.controls['password'];
        this._subscriptions.add(this.route.queryParams.subscribe(function (params) {
            var loginUserKey = Object.keys(params).find(function (key) { return key.toLowerCase() === 'login_user'; });
            if (loginUserKey) {
                _this.email.setValue(params[loginUserKey]);
            }
            else if (Object.keys(params).some(function (key) { return key.toLowerCase() === 'reg_mail'; })) {
                _this.createUserInfoFromUrlData(params);
            }
        }));
        this._store
            .pipe(select(fromRoot.isContinueAsGuest), first())
            .subscribe(function (isContinueAsGuest) {
            if (isContinueAsGuest &&
                ((_this.validationStepName &&
                    (_this.validationStepName[0] === 'personal' && _this.validationStepName[1] === 'registration')) ||
                    (_this.validationStepName &&
                        _this.validationStepName[0] === 'tickets' &&
                        _this.validationStepName[1] === 'registration'))) {
                _this.activeTab = 'registration';
            }
            else if (!_this.userUrlFormData) {
                _this.activeTab = 'login';
            }
        });
        this._subscriptions.add(this._loginService.activeTab$.subscribe(function (tab) {
            _this.activeTab = tab;
        }));
        this.setEmailFromBuyerInfo();
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    LoginComponent.prototype.onTabChange = function (value) {
        this.activeTab = value;
    };
    LoginComponent.prototype.onSubmit = function (values) {
        var email = values.email, password = values.password;
        email = email.toLowerCase();
        var credentials = { email: email, password: password };
        this._store.dispatch(new userActions.LogIn(credentials));
        this.afterLogin();
    };
    LoginComponent.prototype.setEmailFromBuyerInfo = function () {
        var _this = this;
        this._subscriptions.add(this._store
            .pipe(select(fromRoot.getBuyerInfoFromURL), filter(function (parameters) { return !!parameters; }), first())
            .subscribe(function (parameters) {
            Object.keys(parameters).forEach(function (parameter) {
                if (parameters[parameter].key === 'email' && parameters[parameter].value) {
                    _this.email.setValue(parameters[parameter].value);
                    return;
                }
            });
        }));
    };
    LoginComponent.prototype.initTabs = function () {
        this.tabs$ = this._store.pipe(select(getIsTranslationLoaded), filter(function (isTranslationLoaded) { return isTranslationLoaded; }), withLatestFrom(this._translateService.stream([
            'login.tabs.login',
            'login.tabs.login.text',
            'login.tabs.register',
            'login.tabs.register.text'
        ])), map(function (_a) {
            var _ = _a[0], translations = _a[1];
            return [
                {
                    id: 'login',
                    title: translations['login.tabs.login'],
                    text: translations['login.tabs.login.text']
                },
                {
                    id: 'registration',
                    title: translations['login.tabs.register'],
                    text: translations['login.tabs.register.text']
                }
            ];
        }));
    };
    LoginComponent.prototype.afterLogin = function () {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getProfile), filter(function (profile) { return !!profile; }), first())
            .subscribe(function (profile) {
            if (profile) {
                _this._helperService.redirectAfterLogin();
            }
        });
    };
    LoginComponent.prototype.loginWith = function (socialNetwork) {
        var _this = this;
        var domain = environment.protocol + environment.origin;
        domain = domain || window.location.origin;
        window.open("" + environment.protocol + environment.webApiUrl + "/user/external-login?provider=" + socialNetwork + "&returnUrl=" + domain, 
        //`http://localhost:3000/api/user/external-login?provider=facebook&returnUrl=http%3A//localhost%3A4200`,
        'Social Login', 'width=500,height=500');
        // Create IE + others compatible event handler
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
        // Listen to message from child window
        window[eventMethod](messageEvent, function (e) {
            // Check if origin is proper
            if (e.origin !== domain) {
                return;
            }
            else if ('authToken' in e.data && 'id' in e.data) {
                var user = e.data;
                _this._store.dispatch(new userActions.GetProfile(user.id));
                _this._store.dispatch(new userActions.SetLoginTimestamp(Date.now()));
                _this._store.dispatch(new userActions.SetUser(user));
                if (e.data.hasOwnProperty('firstLogin') && e.data.firstLogin) {
                    _this._store
                        .pipe(select(fromRoot.getProfile), filter(function (profile) { return !!profile; }), first())
                        .subscribe(function () {
                        _this._router.navigate(["/profile"]);
                    });
                }
                else {
                    _this.afterLogin();
                }
            }
        }, false);
    };
    LoginComponent.prototype.createUserInfoFromUrlData = function (params) {
        var _this = this;
        this.userUrlFormData = {};
        Object.keys(params).forEach(function (key) {
            var keyWithLowerCase = key.toLowerCase();
            if (keyWithLowerCase.includes('reg_')) {
                var urlParamKey = void 0;
                switch (keyWithLowerCase) {
                    case 'reg_mail':
                        urlParamKey = 'email';
                        break;
                    case 'reg_fn':
                        urlParamKey = 'firstName';
                        break;
                    case 'reg_ln':
                        urlParamKey = 'lastName';
                }
                if (urlParamKey) {
                    _this.userUrlFormData[urlParamKey] = params[key];
                    if (urlParamKey === 'email') {
                        _this.userUrlFormData['verify-email'] = params[key];
                    }
                }
            }
        });
        this.onTabChange('registration');
    };
    return LoginComponent;
}());
export { LoginComponent };
