import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { State, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  WidgetIframeAttributes,
  WidgetSelectionTariff,
  WidgetSelectionTariffType
} from '@pages/widget-admin/widget-models.interface';
import { GetProductsResponse, ProductType, TariffTypeResponse } from '@products/models/products.model';
import { LoadProductService } from '@products/services/load-product.service';
import { Observable, iif, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WidgetAdminService {
  private selectedExhibitionId: number;
  private widgetSelectionTariffTypes: WidgetSelectionTariffType[] = [];

  constructor(private fb: FormBuilder, private store: Store<State>, private loadProductService: LoadProductService) {}

  processWidgetSettingsForSave(settings: { [key: string]: any }, saveSettingsProcessor: { [key: string]: Function }) {
    for (const key in settings) {
      const setting = settings[key];

      if (saveSettingsProcessor[key]) {
        saveSettingsProcessor[key](setting, settings);
      }
    }
  }

  processWidgetSettingForAdminForm(
    settings: { [key: string]: any },
    loadSettingsProcessor: { [key: string]: Function },
    form: FormGroup
  ): { [key: string]: any } {
    let processedSettings = {};

    for (const key in settings) {
      const setting = settings[key];

      if (key in form.value) {
        if (loadSettingsProcessor[key]) {
          loadSettingsProcessor[key](setting, processedSettings, settings);
        } else {
          processedSettings[key] = setting;
        }
      }
    }

    return processedSettings;
  }

  setTicketsToFormControls(widgetSelectionTariffTypes: WidgetSelectionTariffType[], form: FormGroup) {
    const ticketsFormGroup = form.get('tickets') as FormGroup;

    widgetSelectionTariffTypes.forEach(({ ticketTypeId, tariffs }) => {
      tariffs.forEach(({ ticketPersonTypeId }) =>
        ticketsFormGroup.addControl(`${ticketTypeId}_${ticketPersonTypeId}`, this.fb.control(true))
      );
    });
  }

  createIframeEmbedCode(iframeURI: string, iframeAttributes: WidgetIframeAttributes): string {
    let iframeAttributesString = '';

    for (const attribute in iframeAttributes) {
      const attributeValue = iframeAttributes[attribute];

      iframeAttributesString += ` ${attribute}="${attributeValue}"`;
    }

    return `<iframe ${iframeAttributesString} src="${iframeURI}"></iframe>`;
  }

  getWidgetSelectionTariffTypes$(): Observable<WidgetSelectionTariffType[]> {
    return this.store.pipe(select(getSelectedExhibitionId)).pipe(
      first(selectedExhibitionId => !!selectedExhibitionId),
      switchMap(selectedExhibitionId => {
        return iif(
          () => {
            const isSelectedExhibitionId = this.selectedExhibitionId === selectedExhibitionId;
            const areWidgetSelectionTariffTypesAlreadySet = !!this.widgetSelectionTariffTypes.length;

            return isSelectedExhibitionId && areWidgetSelectionTariffTypesAlreadySet;
          },
          of(this.widgetSelectionTariffTypes),
          this.loadProductService.getProducts$(selectedExhibitionId).switchMap(productsResponse => {
            this.selectedExhibitionId = selectedExhibitionId;
            this.widgetSelectionTariffTypes = this.mapGetProductsResponseToWidgetSelectionTariffTypes(productsResponse);

            return of(this.widgetSelectionTariffTypes);
          })
        );
      })
    );
  }

  private mapGetProductsResponseToWidgetSelectionTariffTypes(
    productsResponse: GetProductsResponse
  ): WidgetSelectionTariffType[] {
    const widgetSelectionTariffTypes: WidgetSelectionTariffType[] = [];

    const setWidgetSelectionTariffType = (tariffType: TariffTypeResponse) => {
      const { ticketTypeId, ticketTypeName, tickets } = tariffType;
      const widgetSelectionTariffs: WidgetSelectionTariff[] = [];

      tickets.forEach(({ ticketName, personTypeId }) =>
        widgetSelectionTariffs.push({ name: ticketName, ticketPersonTypeId: personTypeId })
      );

      widgetSelectionTariffTypes.push({ ticketTypeId, ticketTypeName, tariffs: widgetSelectionTariffs });
    };

    productsResponse.forEach(({ items }) => {
      items.forEach(({ itemType, ticketType }) => {
        if (itemType === ProductType.Tariff) {
          setWidgetSelectionTariffType(ticketType);
        }
      });
    });

    return widgetSelectionTariffTypes;
  }
}
