import { Action, ActionReducer, combineReducers } from '@ngrx/store';
import { ProductsState } from '@products/models/products.model';
import { bookingReducer } from '@store/products/booking/booking.reducer';
import { holderReducer } from '@store/products/holder/holder.reducer';
import { productSelectionReducer } from '@store/products/product-selection/product-selection.reducer';
import { articleStatusReducer } from '@store/products/status/article/article.reducer';
import { packageStatusReducer } from '@store/products/status/package/package.reducer';
import { tariffStatusReducer } from '@store/products/status/tariff/tariff.reducer';
import { workshopStatusReducer } from '@store/products/status/workshop/workshop.reducer';
import { workshopReducer } from '@store/products/workshop/workshop.reducer';

export type State = ProductsState;

export const reducer: ActionReducer<State, Action> = combineReducers({
  selection: productSelectionReducer,
  booking: bookingReducer,
  workshop: workshopReducer,
  holder: holderReducer,
  status: combineReducers({
    article: articleStatusReducer,
    package: packageStatusReducer,
    tariff: tariffStatusReducer,
    workshop: workshopStatusReducer
  })
});
