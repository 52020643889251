import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  TariffClassification,
  TariffValidationStateViewModel,
  TariffWarningMessage
} from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'app-tariff-counter',
  templateUrl: './tariff-counter.component.html',
  styleUrls: ['./tariff-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TariffCounterComponent implements OnInit, OnChanges {
  @Input() tariffValidationViewModel: TariffValidationStateViewModel;
  @Input() isTariffLoading: boolean;
  @Input() isWorkshopDetailModalOpen: boolean;
  @Output() validateTariffCountEvent = new EventEmitter<number>();
  count: number;

  readonly AppConstants = AppConstants;
  readonly TariffWarningMessage = TariffWarningMessage;
  readonly TariffClassification = TariffClassification;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(_: SimpleChanges): void {
    const { validatedCount } = this.tariffValidationViewModel;

    this.count = validatedCount >= 0 ? validatedCount : 0;
  }

  onIncrease() {
    const increasedCount: number = this.count + 1;
    const validateCount = this.count >= 0 ? increasedCount : this.count;

    this.validateTariffCountEvent.emit(validateCount);
  }

  onDecrease() {
    const decreasedCount: number = this.count - 1;
    const validateCount = decreasedCount >= 0 ? decreasedCount : 0;

    this.validateTariffCountEvent.emit(validateCount);
  }

  onCountChange(count: number) {
    const changedCount = +count;
    const validateCount = changedCount >= 0 ? changedCount : 0;

    this.validateTariffCountEvent.emit(validateCount);
  }
}
