var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { getLocaleDateFormat, getLocaleDayNames, getLocaleMonthNames } from '@angular/common';
import { OnInit } from '@angular/core';
import { getLanguage } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Calendar } from 'primeng/calendar';
var CalendarCustomDefaultsDirective = /** @class */ (function () {
    function CalendarCustomDefaultsDirective(calendar, store) {
        var _this = this;
        this.calendar = calendar;
        this.store = store;
        this.locale = {
            firstDayOfWeek: 1,
            dayNames: [],
            dayNamesShort: [],
            dayNamesMin: [],
            monthNames: [],
            monthNamesShort: [],
            today: null,
            clear: null
        };
        this.store.pipe(select(getLanguage)).subscribe(function (activeLanguage) {
            _this.locale = __assign({}, _this.locale, { monthNames: getLocaleMonthNames(activeLanguage, 0, 2), monthNamesShort: getLocaleMonthNames(activeLanguage, 0, 1), dayNames: getLocaleDayNames(activeLanguage, 0, 2), dayNamesShort: getLocaleDayNames(activeLanguage, 0, 3), dayNamesMin: getLocaleDayNames(activeLanguage, 0, 0) });
            _this.calendar.locale = _this.locale;
            _this.calendar.dateFormat =
                activeLanguage === 'en' ? 'dd.mm.yy' : getLocaleDateFormat(activeLanguage, 0).toLowerCase();
        });
    }
    CalendarCustomDefaultsDirective.prototype.ngOnInit = function () {
        if (this.minToday && !this.minDate) {
            this.calendar.minDate = new Date();
        }
    };
    return CalendarCustomDefaultsDirective;
}());
export { CalendarCustomDefaultsDirective };
