import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getLanguage, getSelectedExhibitionId } from '../../../app.reducer';
import { AppConstants } from '../../../shared/app-constants';
import { FormsService } from '../../../shared/forms/forms.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { SetQuestionnaireInputs } from '../../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolderQuestionnaireInputs } from '../../../shared/services-with-reducers/products/holder/holder.selectors';
var TicketHolderQuestionnaireComponent = /** @class */ (function () {
    function TicketHolderQuestionnaireComponent(store, formsService, helperService) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.helperService = helperService;
        this.isValid = new EventEmitter();
        this.customAction = ['ticketHolderQuestionaireForm'];
        this.subscriptions = new Subscription();
        this.inputsChanged = function (inputs) {
            // get updated inputs, now we need to everwrite the old set with updated ones
            _this.inputs = _this.formsService.updateInputs(_this.inputs, inputs);
            _this.store.dispatch(new SetQuestionnaireInputs(_this.inputs));
            _this.setupForm();
        };
        this.validationCallback = function () {
            _this.isValid.emit({
                formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
                valid: !_this.form.invalid,
                inputs: _this.inputs,
                form: _this.form
            });
        };
    }
    TicketHolderQuestionnaireComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(getTicketHolderQuestionnaireInputs)),
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getLanguage))
        ])
            .pipe(filter(function (_a) {
            var ticketHolderQuestionnaireInputs = _a[0], eventId = _a[1], lang = _a[2];
            if ((!!ticketHolderQuestionnaireInputs && !ticketHolderQuestionnaireInputs.length) || (Number(eventId) === 90 && lang === 'en')) {
                _this.isValid.emit({
                    formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
                    valid: true,
                    inputs: null,
                    form: null
                });
                _this.inputs = null;
                return false;
            }
            return !!ticketHolderQuestionnaireInputs && !!ticketHolderQuestionnaireInputs.length;
        }))
            .subscribe(function (_a) {
            var ticketHolderQuestionnaireInputs = _a[0];
            _this.inputs = _this.formsService.updateInputs(_this.inputs, ticketHolderQuestionnaireInputs);
            _this.setupForm();
        }));
    };
    TicketHolderQuestionnaireComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    TicketHolderQuestionnaireComponent.prototype.setInputsVisibility = function () {
        var _this = this;
        this.inputs.map(function (input) {
            if (input.previousValueId) {
                var element = _this.inputs.find(function (p) {
                    return p.value == input.previousValueId ||
                        (p.controlType === 'checkbox' &&
                            p.options.find(function (option) {
                                return option.value === true && option.key === input.previousValueId;
                            }));
                });
                input.hidden = !element;
            }
        });
    };
    TicketHolderQuestionnaireComponent.prototype.setupForm = function () {
        this.setInputsVisibility();
        var visibleInputs = this.inputs.filter(function (p) { return !p.hidden; });
        this.form = this.formsService.toFormGroup(visibleInputs);
        this.helperService.triggerCallbackOnceFormValidationIsDone(this.form, this.validationCallback);
        this.subscriptions.add(this.form.statusChanges.subscribe(this.validationCallback));
    };
    return TicketHolderQuestionnaireComponent;
}());
export { TicketHolderQuestionnaireComponent };
