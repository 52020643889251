var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { getSelfRegistration, getTariffReleaseInMinutes } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { ProductService } from '@products/services/product.service';
import { VoucherValidationService } from '@products/services/voucher-validation.service';
import { VoucherService } from '@products/services/voucher.service';
import { SetShoppingStartTime } from '@store/customization/customization.actions';
import { ActionTypes as BookingActionTypes, RemovePackageFromBookingList, RemoveProductFromBookingList, RemoveProductFromBookingListError, RemoveReleasedVoucherProductFromBookingList, RemoveVoucherCountdown, RemoveVoucherProductFromBookingList, RemoveWorkshopFromBooking, SetContingentReservationDateInBooking, SetPackageInBookingList, SetParkingReservationDateInBooking, SetPreferredProductInBookingList, SetProductCountdown, SetProductInBookingList, SetProductInBookingListError, SetValidatedContingentReservationInBooking, SetValidatedParkingReservationInBooking, SetVoucherCountdown, SetVoucherForAnonymousProductInBookingList, SetVoucherProductInBookingList, SetWorkshopInBooking } from '@store/products/booking/booking.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { ActionTypes as ProductSelectionActionTypes } from '@store/products/product-selection/product-selection.actions';
import { getInitialVoucherTariffByTicketPersonId } from '@store/products/product-selection/product-selection.selectors';
import { ActionTypes as ProductActionTypes, RemoveContingentBookingReservation, RemoveReleasedAndRemovedProductBookingVoucher } from '@store/products/product.actions';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
var BookingEffect = /** @class */ (function () {
    function BookingEffect(bookingDebounceTime, actions$, bookingService, voucherService, voucherValidationService, productService, store) {
        var _this = this;
        this.bookingDebounceTime = bookingDebounceTime;
        this.actions$ = actions$;
        this.bookingService = bookingService;
        this.voucherService = voucherService;
        this.voucherValidationService = voucherValidationService;
        this.productService = productService;
        this.store = store;
        this.setProductBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_TARIFF_BOOKING, ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING, ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload.length) {
                return EMPTY;
            }
            return _this.bookingService.setProductBookings$(payload).pipe(switchMap(function (postProductBookings) {
                return _this.bookingService.postProductBookings$(postProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(payload, postProductBookingsResponse);
                    return new SetProductInBookingList(products);
                }));
            }), catchError(function () { return of(new SetProductInBookingList([])); }));
        }));
        this.setPreferredProductBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_PREFERRED_TARIFF_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            var ticketTypeId = payload.ticketTypeId, ticketPersonTypeId = payload.ticketPersonTypeId, count = payload.count;
            if (!ticketTypeId || !ticketPersonTypeId || !count) {
                return EMPTY;
            }
            return _this.bookingService.setPreferredProductBookings$(ticketTypeId, ticketPersonTypeId, count).pipe(switchMap(function (_a) {
                var preferredBookingAndRemovedBookingProductTypes = _a.preferredBookingAndRemovedBookingProductTypes, postProductBookings = _a.postProductBookings;
                return _this.bookingService.postProductBookings$(postProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(preferredBookingAndRemovedBookingProductTypes, postProductBookingsResponse);
                    return new SetPreferredProductInBookingList(products);
                }));
            }), catchError(function () { return of(new SetPreferredProductInBookingList([])); }));
        }));
        this.setSelfRegistrationTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_SELF_REGISTRATION_TARIFF_BOOKINGS), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            return combineLatest([
                _this.bookingService.setProductBookings$(payload),
                _this.bookingService.removeAllProductBookings$()
            ]).pipe(switchMap(function (_a) {
                var addPostProductBookings = _a[0], _b = _a[1], removeBookingProductTypes = _b.removeBookingProductTypes, removePostProductBookings = _b.removePostProductBookings;
                var addBookingProductTypes = payload;
                if (!addBookingProductTypes.length) {
                    return EMPTY;
                }
                var postProductBookings = addPostProductBookings.concat(removePostProductBookings);
                return _this.bookingService.postProductBookings$(postProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var bookingProductTypes = addBookingProductTypes.concat(removeBookingProductTypes);
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(bookingProductTypes, postProductBookingsResponse);
                    return new SetProductInBookingList(products);
                }));
            }), catchError(function () { return of(new SetProductInBookingList([])); }));
        }));
        this.removeTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_TARIFF_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            return _this.bookingService.removeTariffBookings$(payload).pipe(switchMap(function (_a) {
                var bookingTariffTypes = _a.bookingTariffTypes, removePostProductBookings = _a.removePostProductBookings;
                return _this.bookingService.postProductBookings$(removePostProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(bookingTariffTypes, postProductBookingsResponse);
                    return new RemoveProductFromBookingList(products);
                }));
            }), catchError(function () { return of(new RemoveProductFromBookingList([])); }));
        }));
        this.setVoucherTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            var redeemedVoucher = payload.redeemedVoucher, releaseVouchers = payload.releaseVouchers, removeProductBookings = payload.removeProductBookings;
            if (!redeemedVoucher) {
                return EMPTY;
            }
            var voucherType = redeemedVoucher.voucherType, ticketPersonId = redeemedVoucher.ticketPersonId, ticketCount = redeemedVoucher.ticketCount, isAnonymous = redeemedVoucher.isAnonymous;
            return _this.voucherService.releaseMultipleVouchers$(releaseVouchers).pipe(switchMap(function () {
                return _this.voucherValidationService
                    .validateRedeemedVoucherTypeCount$(voucherType, ticketCount, isAnonymous)
                    .pipe(withLatestFrom(_this.store.pipe(select(getInitialVoucherTariffByTicketPersonId(ticketPersonId)))), map(function (_a) {
                    var validatedVoucherCount = _a[0], voucherTariff = _a[1];
                    var redeemedVoucherProductBooking = _this.bookingService.mapRedeemedVoucherToBookingTariffType(redeemedVoucher, voucherTariff, validatedVoucherCount);
                    var updatedProductBookings = [
                        redeemedVoucherProductBooking
                    ].concat(removeProductBookings);
                    if (isAnonymous) {
                        return new SetVoucherForAnonymousProductInBookingList(updatedProductBookings);
                    }
                    return new SetVoucherProductInBookingList(updatedProductBookings);
                }));
            }), catchError(function () {
                if (isAnonymous) {
                    return of(new SetVoucherForAnonymousProductInBookingList([]));
                }
                return of(new SetVoucherProductInBookingList([]));
            }));
        }));
        this.removeReleasedVoucherTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_RELEASED_VOUCHER), concatMap(function (_a) {
            var payload = _a.payload;
            var ticketPersonId = payload.ticketPersonId, voucherCode = payload.voucherCode;
            if (!ticketPersonId || !voucherCode) {
                return EMPTY;
            }
            return _this.bookingService.removeReleasedVoucherTariffBookings$(payload).pipe(map(function (_a) {
                var bookedVoucherTariffTypes = _a.bookedVoucherTariffTypes, voucherCode = _a.voucherCode;
                if (!!bookedVoucherTariffTypes.length) {
                    return new RemoveReleasedVoucherProductFromBookingList(bookedVoucherTariffTypes);
                }
                return new RemoveReleasedAndRemovedProductBookingVoucher(voucherCode);
            }));
        }));
        this.removeVoucherTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            return _this.bookingService.removeVoucherTariffBookings$(payload).pipe(switchMap(function (_a) {
                var bookingTariffTypes = _a.bookingTariffTypes, removePostProductBookings = _a.removePostProductBookings;
                return _this.bookingService.postProductBookings$(removePostProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(bookingTariffTypes, postProductBookingsResponse);
                    return new RemoveVoucherProductFromBookingList(products);
                }));
            }), catchError(function () { return of(new RemoveVoucherProductFromBookingList([])); }));
        }));
        this.removePackageTariffBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            return _this.bookingService.removePackageTariffBookings$(payload).pipe(switchMap(function (_a) {
                var bookedPackageTypes = _a.bookedPackageTypes, removePostProductBookings = _a.removePostProductBookings;
                return _this.bookingService.postProductBookings$(removePostProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(bookedPackageTypes, postProductBookingsResponse);
                    return new RemoveProductFromBookingList(products);
                }));
            }), catchError(function () { return of(new RemoveProductFromBookingList([])); }));
        }));
        this.setPackageBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_PACKAGE_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload.length) {
                return EMPTY;
            }
            return _this.bookingService.setProductBookings$(payload, true).pipe(switchMap(function (postProductBookings) {
                return _this.bookingService.postProductBookings$(postProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingPackageTypes(payload, postProductBookingsResponse);
                    return new SetPackageInBookingList(products);
                }));
            }), catchError(function () { return of(new SetPackageInBookingList([])); }));
        }));
        this.removePackageBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_PACKAGE_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload.packageIndexes || !payload.packageIndexes.length) {
                return EMPTY;
            }
            return _this.bookingService.removePackageBookings$(payload).pipe(switchMap(function (_a) {
                var bookedPackageTypes = _a.bookedPackageTypes, removePostProductBookings = _a.removePostProductBookings;
                return _this.bookingService.postProductBookings$(removePostProductBookings).pipe(map(function (postProductBookingsResponse) {
                    var products = _this.bookingService.mapPostProductBookingsResponseToBookingPackageTypes(bookedPackageTypes, postProductBookingsResponse);
                    return new RemovePackageFromBookingList(products);
                }));
            }), catchError(function () { return of(new RemovePackageFromBookingList([])); }));
        }));
        this.removeProductBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_PRODUCT_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            var productBookingsToRemove = payload;
            if (!productBookingsToRemove.length) {
                return EMPTY;
            }
            return _this.bookingService.removeProductBookings$(payload).pipe(switchMap(function (removePostProductBookings) {
                return _this.bookingService
                    .postProductBookings$(removePostProductBookings)
                    .pipe(map(function () { return new RemoveProductFromBookingList(productBookingsToRemove); }));
            }), catchError(function () { return of(new RemoveProductFromBookingList([])); }));
        }));
        this.setProductBookingError$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PREFERRED_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST), filter(function (_a) {
            var payload = _a.payload;
            return !payload.length;
        }), switchMap(function () { return of(new SetProductInBookingListError()); }));
        this.removeProductBookingError$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), filter(function (_a) {
            var payload = _a.payload;
            return !payload.length;
        }), switchMap(function () { return of(new RemoveProductFromBookingListError()); }));
        this.setBookingWorkshop$ = this.actions$.pipe(ofType(ProductActionTypes.SET_BOOKING_WORKSHOP), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload.length) {
                return EMPTY;
            }
            return _this.bookingService.setWorkshopBookings$(payload).pipe(switchMap(function (_a) {
                var setBookingWorkshops = _a.setBookingWorkshops, postWorkshopBookings = _a.postWorkshopBookings;
                return _this.bookingService.postAvailableWorkshopBookings$(postWorkshopBookings).pipe(_this.bookingService.removeUnassignedHoldersInSetBookingWorkshopsPipe(setBookingWorkshops, postWorkshopBookings), map(function (setWorkshopBookingsAndSetSeats) { return new SetWorkshopInBooking(setWorkshopBookingsAndSetSeats); }));
            }), catchError(function () { return of(new SetWorkshopInBooking(null)); }));
        }));
        this.removeBookingWorkshop$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_BOOKING_WORKSHOP), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload.length) {
                return EMPTY;
            }
            return _this.bookingService.removeWorkshopBookings$(payload).pipe(switchMap(function (_a) {
                var removeBookingWorkshops = _a.removeBookingWorkshops, postWorkshopBookings = _a.postWorkshopBookings;
                return _this.bookingService
                    .postRemoveMultipleWorkshopBookings$(removeBookingWorkshops, postWorkshopBookings)
                    .pipe(map(function (removeWorkshopBookingsAndSetSeats) { return new RemoveWorkshopFromBooking(removeWorkshopBookingsAndSetSeats); }));
            }), catchError(function (error) { return of(new RemoveWorkshopFromBooking(null)); }));
        }));
        this.setContingentReservationDateInBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION), mergeMap(function (_a) {
            var payload = _a.payload;
            var contingentReservationDates = payload;
            if (!contingentReservationDates.length) {
                return EMPTY;
            }
            return of(new SetContingentReservationDateInBooking(contingentReservationDates));
        }));
        this.setParkingReservationDateInBooking$ = this.actions$.pipe(ofType(ProductActionTypes.SET_PARKING_BOOKING_RESERVATION), switchMap(function (_a) {
            var payload = _a.payload;
            var parkingReservationDate = payload;
            if (!parkingReservationDate) {
                return EMPTY;
            }
            return of(new SetParkingReservationDateInBooking(parkingReservationDate));
        }));
        this.setValidatedParkingReservationDateInBooking$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PARKING_RESERVATION_DATE_IN_BOOKING), switchMap(function (_a) {
            var payload = _a.payload;
            var parkingReservationDate = payload;
            var since = parkingReservationDate.since, until = parkingReservationDate.until;
            if (!since || !until) {
                return EMPTY;
            }
            return _this.bookingService.setParkingReservation$(parkingReservationDate).pipe(switchMap(function (parkingTariffReservationFee) {
                return _this.bookingService.getParkingTariffFee$(parkingTariffReservationFee).pipe(map(function (response) {
                    var parkingReservationPrice = response.body;
                    var setProductBookingParkingReservation = _this.bookingService.mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation(parkingReservationDate, parkingReservationPrice, true);
                    return new SetValidatedParkingReservationInBooking(setProductBookingParkingReservation);
                }), catchError(function () {
                    var setProductBookingParkingReservation = _this.bookingService.mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation(parkingReservationDate, 0, false);
                    return of(new SetValidatedParkingReservationInBooking(setProductBookingParkingReservation));
                }));
            }));
        }));
        this.setValidatedContingentReservationInBooking$ = this.actions$.pipe(ofType(BookingActionTypes.SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING), debounceTime(this.bookingDebounceTime), switchMap(function () {
            return _this.productService.getSortedBookingContingentReservationsByProductSelectionListOrder$([]).pipe(switchMap(function (sortedContingentReservations) {
                var createContingentReservationsMappers = _this.bookingService.mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers(sortedContingentReservations);
                return _this.bookingService.setContingentReservations$(createContingentReservationsMappers).pipe(switchMap(function (setContingentReservations) {
                    var orderUuid = setContingentReservations.orderUuid, selectedExhibition = setContingentReservations.selectedExhibition, reservations = setContingentReservations.reservations;
                    var postCreateContingentReservations = {
                        eventId: selectedExhibition.id,
                        uuid: orderUuid,
                        tickets: reservations
                    };
                    return _this.bookingService.postCreateContingentReservations$(postCreateContingentReservations).pipe(map(function (contingentReservationResponse) {
                        var setProductBookingContingentReservations = _this.bookingService.mapCreateContingentReservationResponseToSetProductBookingContingentReservations(selectedExhibition, contingentReservationResponse, createContingentReservationsMappers);
                        return new SetValidatedContingentReservationInBooking(setProductBookingContingentReservations);
                    }));
                }), catchError(function () { return of(new SetValidatedContingentReservationInBooking([])); }));
            }));
        }));
        this.removeAndRevalidateContingentReservationsBooking$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION), debounceTime(this.bookingDebounceTime), switchMap(function (_a) {
            var payload = _a.payload;
            var removeContingentReservations = payload;
            if (!removeContingentReservations.length) {
                return EMPTY;
            }
            return _this.productService
                .getSortedBookingContingentReservationsByProductSelectionListOrder$(removeContingentReservations)
                .pipe(switchMap(function (sortedContingentReservations) {
                var createContingentReservationsMappers = _this.bookingService.mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers(sortedContingentReservations);
                return _this.bookingService.setContingentReservations$(createContingentReservationsMappers).pipe(switchMap(function (setContingentReservations) {
                    var selectedExhibition = setContingentReservations.selectedExhibition, orderUuid = setContingentReservations.orderUuid, reservations = setContingentReservations.reservations;
                    if (!reservations.length) {
                        return _this.bookingService
                            .postReleaseContingentReservations$(setContingentReservations.orderUuid)
                            .pipe(map(function () { return new SetValidatedContingentReservationInBooking([]); }));
                    }
                    var postCreateContingentReservations = {
                        eventId: selectedExhibition.id,
                        uuid: orderUuid,
                        tickets: reservations
                    };
                    return _this.bookingService.postCreateContingentReservations$(postCreateContingentReservations).pipe(map(function (contingentReservationResponse) {
                        var setProductBookingContingentReservations = _this.bookingService.mapCreateContingentReservationResponseToSetProductBookingContingentReservations(selectedExhibition, contingentReservationResponse, createContingentReservationsMappers);
                        return new SetValidatedContingentReservationInBooking(setProductBookingContingentReservations);
                    }));
                }), catchError(function () { return of(new SetValidatedContingentReservationInBooking([])); }));
            }));
        }));
        this.revalidateContingentReservationsBookingOnPackageRemoval$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            var removeContingentReservations = _this.bookingService.mapRemovedBookingPackageTypesToRemoveContingentReservations(payload);
            return new RemoveContingentBookingReservation(removeContingentReservations);
        }));
        this.setShoppingStartTime$ = this.actions$.pipe(ofType(ProductActionTypes.SET_TARIFF_BOOKING, ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING, ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING, ProductActionTypes.SET_PACKAGE_BOOKING), map(function () { return new SetShoppingStartTime(); }));
        this.setProductCountdown$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST), withLatestFrom(this.store.pipe(select(getTariffReleaseInMinutes)), this.store.pipe(select(getSelfRegistration))), filter(function (_a) {
            var _ = _a[0], tariffReleaseInMinutes = _a[1], isSelfRegistration = _a[2];
            return !!tariffReleaseInMinutes && !isSelfRegistration;
        }), switchMap(function (_a) {
            var _b = _a[0], payload = _b.payload, type = _b.type, tariffReleaseInMinutes = _a[1];
            if (!payload.length ||
                (type === BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST &&
                    !_this.bookingService.isPackageProductAddedInProductBookingList(payload))) {
                return EMPTY;
            }
            return of(new SetProductCountdown(tariffReleaseInMinutes));
        }));
        this.removeProductCountdown$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), withLatestFrom(this.store.pipe(select(getAllBookedTariffs)), this.store.pipe(select(getTariffReleaseInMinutes)), this.store.pipe(select(getSelfRegistration))), filter(function (_a) {
            var _ = _a[0], __ = _a[1], tariffReleaseInMinutes = _a[2], isSelfRegistration = _a[3];
            return !!tariffReleaseInMinutes && !isSelfRegistration;
        }), map(function (_a) {
            var _ = _a[0], allBookedTariffs = _a[1], tariffReleaseInMinutes = _a[2];
            return new SetProductCountdown(allBookedTariffs.length ? tariffReleaseInMinutes : null);
        }));
        this.setVoucherCountdown$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.SET_VOUCHER_PRODUCT_IN_SELECTION_LIST), withLatestFrom(this.store.pipe(select(getTariffReleaseInMinutes), first(function (tariffReleaseInMinutes) { return !!tariffReleaseInMinutes; }))), map(function (_a) {
            var payload = _a[0].payload, tariffReleaseInMinutes = _a[1];
            var bookingProductTypes = payload.bookingProductTypes;
            var voucherCountdowns = _this.bookingService.mapBookingProductTypesToVoucherCountdowns(bookingProductTypes, tariffReleaseInMinutes);
            return new SetVoucherCountdown(voucherCountdowns);
        }));
        this.removeVoucherCountdown$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST, ProductSelectionActionTypes.REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST), withLatestFrom(this.store.pipe(select(getTariffReleaseInMinutes), first(function (tariffReleaseInMinutes) { return !!tariffReleaseInMinutes; }))), map(function (_a) {
            var _b = _a[0], type = _b.type, payload = _b.payload, tariffReleaseInMinutes = _a[1];
            var voucherCountdowns = [];
            if (type === ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST) {
                var bookingProductTypes = payload;
                voucherCountdowns = _this.bookingService.mapBookingProductTypesToVoucherCountdowns(bookingProductTypes, tariffReleaseInMinutes);
            }
            else {
                var voucherCode = payload;
                voucherCountdowns = [{ voucherCode: voucherCode, expiryTimestamp: 0 }];
            }
            return new RemoveVoucherCountdown({ voucherCountdowns: voucherCountdowns, tariffReleaseInMinutes: tariffReleaseInMinutes });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setProductBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setPreferredProductBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setSelfRegistrationTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setVoucherTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeReleasedVoucherTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeVoucherTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removePackageTariffBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setPackageBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removePackageBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeProductBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setProductBookingError$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeProductBookingError$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setBookingWorkshop$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeBookingWorkshop$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setContingentReservationDateInBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setParkingReservationDateInBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setValidatedParkingReservationDateInBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setValidatedContingentReservationInBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeAndRevalidateContingentReservationsBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "revalidateContingentReservationsBookingOnPackageRemoval$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setShoppingStartTime$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setProductCountdown$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeProductCountdown$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "setVoucherCountdown$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BookingEffect.prototype, "removeVoucherCountdown$", void 0);
    return BookingEffect;
}());
export { BookingEffect };
