import { Action } from '@ngrx/store';

export enum ActionTypes {
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class ResetArticleStatus implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions = ResetArticleStatus;
