import { getAnonymousHiddenSteps, getStepsValidity } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { SetAnonymousHiddenSteps, SetStepsVisibility } from '@store/step-forms/steps-forms.actions';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var StepNavigationService = /** @class */ (function () {
    function StepNavigationService(store) {
        this.store = store;
    }
    StepNavigationService.prototype.setStepsVisibility = function (isVisible) {
        var _this = this;
        combineLatest([this.store.pipe(select(getStepsValidity)), this.store.pipe(select(getAnonymousHiddenSteps))])
            .pipe(first())
            .subscribe(function (_a) {
            var stepsValidity = _a[0], anonymousHiddenSteps = _a[1];
            var stepsArray = [];
            if (!isVisible) {
                Object.keys(stepsValidity).forEach(function (key) {
                    if (stepsValidity[key].visible && key !== 'tickets' && key !== 'invoice') {
                        stepsArray.push(key);
                    }
                });
            }
            else {
                stepsArray = anonymousHiddenSteps;
            }
            if (!getAnonymousHiddenSteps.length) {
                _this.store.dispatch(new SetAnonymousHiddenSteps(stepsArray));
            }
            var visibilityPayload = stepsArray.map(function (step) {
                return {
                    stepKey: step,
                    visible: isVisible
                };
            });
            _this.store.dispatch(new SetStepsVisibility(visibilityPayload));
        });
    };
    StepNavigationService.ngInjectableDef = i0.defineInjectable({ factory: function StepNavigationService_Factory() { return new StepNavigationService(i0.inject(i1.Store)); }, token: StepNavigationService, providedIn: "root" });
    return StepNavigationService;
}());
export { StepNavigationService };
