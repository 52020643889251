<ng-container *ngFor="let product of package.products; trackBy: packageProductsTrackBy">
  <div
    *ngIf="
      (product.productType === ProductType.Tariff && product.tariffType.isVisible) ||
      product.productType === ProductType.Package
    "
  >
    <div class="package-tariff-types">
      <ng-container *ngIf="product.productType === ProductType.Tariff">
        <app-tariff-type
          [exhibitionSettings]="exhibitionSettings"
          [productSelectionViewModel]="productSelectionViewModel"
          [tariffStatusViewModel]="tariffStatusViewModel"
          [workshopStatusViewModel]="workshopStatusViewModel"
          [bookingStatusViewModel]="bookingStatusViewModel"
          [productGroup]="productGroup"
          [packageType]="packageType"
          [tariffType]="product.tariffType"
        ></app-tariff-type>
      </ng-container>
    </div>
  </div>
</ng-container>
<div *ngIf="packageType.numberOfAddedPackages > 1" class="package-price">
  {{
    bookingStatusViewModel.bookedPackagePricesGroupedByPackageIndex[package.packageIndex] || 0
      | formatPrice: exhibitionSettings.currencySettings
  }}
</div>
