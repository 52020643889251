var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as helperActions from './helper.actions';
import { InjectionToken } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { HelperService } from './helper.service';
export var HELPER_DEBOUNCE = new InjectionToken('Helper Debounce');
var HelperEffect = /** @class */ (function () {
    function HelperEffect(actions$, helperService, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.helperService = helperService;
        this.debounce = debounce;
        this.loadAllCountries$ = this.actions$.pipe(ofType(helperActions.ActionTypes.GET_ALL_COUNTRIES), debounceTime(this.debounce), switchMap(function () {
            return _this.helperService.getListOfCountries().pipe(map(function (allCountries) {
                return new helperActions.AddAllCountriesToListAction(allCountries);
            }), catchError(function () { return of(new helperActions.AddAllCountriesToListAction([])); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HelperEffect.prototype, "loadAllCountries$", void 0);
    return HelperEffect;
}());
export { HelperEffect };
