import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HelperService } from '@store/helpers/helper.service';
import { WidgetAdminService } from '../widget-admin.service';

import {
  TicketsWidgetSettings,
  WidgetIframeAttributes,
  WidgetSelectionTariffType
} from '@pages/widget-admin/widget-models.interface';

@Component({
  selector: 'app-tickets-widget-admin',
  templateUrl: './tickets-widget-admin.component.html',
  styleUrls: ['./tickets-widget-admin.component.scss']
})
export class TicketsWidgetAdminComponent implements OnInit, OnDestroy {
  @Input() eventId: number;
  @Input() widgetSelectionTariffTypes: WidgetSelectionTariffType[];
  public iframeURI: string = null;
  public iframeEmbedCode: string = null;
  public webshopOriginURL = `${window.location.protocol}//${window.location.host}`;
  public iframePath = '/widget/tickets';
  public settingsForm: FormGroup = this.fb.group({
    tickets: this.fb.group([]),
    width: [null],
    height: [null],
    banner: [null],
    header: [null]
  });
  public saveSettingsProcessor = {
    tickets: (tickets: { [key: string]: boolean }): string => {
      let param = '&tickets=';

      this.iframeURI = this.helperService.removeQueryParam('tickets', this.iframeURI);

      if (!tickets) {
        return;
      }

      const ticketsValuesArray: string[] = Object.keys(tickets).map(key => {
        if (tickets[key]) {
          return key;
        }
      });
      const selectedTicketIds: string[] = ticketsValuesArray.filter(value => value);

      param = param + selectedTicketIds.toString();

      const hasTickets = selectedTicketIds.length > 0;

      if (hasTickets) {
        this.iframeURI = this.iframeURI + param;
      }
    },
    width: (width: string) => (this.iframeAttributes['width'] = width || '100%'),
    height: (height: string) => (this.iframeAttributes['height'] = height || '100%'),
    banner: (showBanner: boolean) => {
      this.iframeURI = this.helperService.removeQueryParam('banner', this.iframeURI);

      if (showBanner) {
        const param = '&banner=true';

        this.iframeURI = this.iframeURI + param;
      }
    },
    header: (showHeader: boolean) => {
      this.iframeURI = this.helperService.removeQueryParam('header', this.iframeURI);

      if (showHeader) {
        const param = '&header=true';

        this.iframeURI = this.iframeURI + param;
      }
    }
  };
  public iframeAttributes: WidgetIframeAttributes = {
    width: '100%',
    height: '100%'
  };

  private unsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private helperService: HelperService,
    private widgetAdminService: WidgetAdminService
  ) {}

  ngOnInit() {
    this.setEventIdToIframeURI();

    this.widgetAdminService.setTicketsToFormControls(this.widgetSelectionTariffTypes, this.settingsForm);

    this.applyWidgetSettings();

    this.settingsForm.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.applyWidgetSettings(value);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setEventIdToIframeURI() {
    const paramEventId = `?eventId=${this.eventId}`;

    this.iframeURI = this.webshopOriginURL + this.iframePath + paramEventId;
  }

  preserveLastTicketSelected(event: Event) {
    const tickets = this.settingsForm.get('tickets') as FormGroup;
    const ticketsValues = tickets.value;

    let selectedCount = 0;

    for (const key in ticketsValues) {
      const ticket = ticketsValues[key];

      if (ticket) {
        selectedCount += 1;
      }
    }

    if (selectedCount === 0) {
      const checkbox = event.target as HTMLInputElement;
      const checkboxName = checkbox.getAttribute('ng-reflect-name');

      tickets.get(checkboxName).setValue(true);
    }
  }

  applyWidgetSettings(formValue?: TicketsWidgetSettings) {
    const value = formValue || this.settingsForm.value;

    this.widgetAdminService.processWidgetSettingsForSave(value, this.saveSettingsProcessor);
    this.iframeEmbedCode = this.widgetAdminService.createIframeEmbedCode(this.iframeURI, this.iframeAttributes);
  }
}
