var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Store, select } from '@ngrx/store';
import { getProductType } from '@products/models/products.model';
import { BookingService } from '@products/services//booking.service';
import { VoucherService } from '@products/services/voucher.service';
import { convertStringToDate } from '@shared/app-utils';
import { getAllBookedContingentTariffs } from '@store/products/booking/booking.selectors';
import { getAllTariffs } from '@store/products/product-selection/product-selection.selectors';
import { combineLatest, forkJoin } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./booking.service";
import * as i3 from "./voucher.service";
var ProductService = /** @class */ (function () {
    function ProductService(store, bookingService, voucherService) {
        this.store = store;
        this.bookingService = bookingService;
        this.voucherService = voucherService;
    }
    ProductService.prototype.getSortedBookingContingentReservationsByProductSelectionListOrder$ = function (removeContingentReservations) {
        return combineLatest([
            this.store.pipe(select(getAllTariffs)),
            this.store.pipe(select(getAllBookedContingentTariffs))
        ]).pipe(first(function (_a) {
            var allTariffs = _a[0], allBookedContingentTariffs = _a[1];
            return !!allTariffs.length && !!allBookedContingentTariffs;
        }), map(function (_a) {
            var productSelectionTariffs = _a[0], bookingContingentTariffs = _a[1];
            var sortedSetProductBookingContingentReservationsByProductSelectionListOrder = [];
            productSelectionTariffs.forEach(function (tariff) {
                var ticketTypeId = tariff.ticketTypeId, ticketPersonId = tariff.ticketPersonId, ticketPersonTypeId = tariff.ticketPersonTypeId, voucherCode = tariff.voucherCode, packageNumber = tariff.packageNumber, packageIndex = tariff.packageIndex;
                var bookedTariff = bookingContingentTariffs.find(function (bookedTariff) {
                    return bookedTariff.ticketTypeId === ticketTypeId &&
                        bookedTariff.ticketPersonId === ticketPersonId &&
                        bookedTariff.voucherCode === voucherCode &&
                        bookedTariff.packageNumber === packageNumber &&
                        bookedTariff.packageIndex === packageIndex;
                });
                if (!!bookedTariff) {
                    var removeCurrentBookingTariffContingentReservation_1 = removeContingentReservations.find(function (removeContingentReservation) {
                        return removeContingentReservation.ticketTypeId === ticketTypeId &&
                            removeContingentReservation.ticketPersonId === ticketPersonId &&
                            removeContingentReservation.voucherCode === voucherCode &&
                            removeContingentReservation.packageNumber === packageNumber &&
                            removeContingentReservation.packageIndex === packageIndex;
                    });
                    var setProductBookingContingentReservation_1 = {
                        productType: getProductType(bookedTariff),
                        ticketTypeId: ticketTypeId,
                        ticketPersonId: ticketPersonId,
                        ticketPersonTypeId: ticketPersonTypeId,
                        voucherCode: voucherCode,
                        packageNumber: packageNumber,
                        packageIndex: packageIndex,
                        contingentReservations: []
                    };
                    bookedTariff.contingentReservations.forEach(function (contingentReservation, bookingReservationIndex) {
                        if (removeCurrentBookingTariffContingentReservation_1 &&
                            removeCurrentBookingTariffContingentReservation_1.removeBookingReservationIndexes.includes(bookingReservationIndex)) {
                            return;
                        }
                        var setBookingContingentReservation = __assign({}, contingentReservation, { duration: tariff.durationInDays, toDate: convertStringToDate(tariff.validTill), bookingReservationIndex: bookingReservationIndex });
                        setProductBookingContingentReservation_1.contingentReservations.push(setBookingContingentReservation);
                    });
                    sortedSetProductBookingContingentReservationsByProductSelectionListOrder.push(setProductBookingContingentReservation_1);
                }
            });
            return sortedSetProductBookingContingentReservationsByProductSelectionListOrder;
        }));
    };
    ProductService.prototype.releaseAllVouchersAndProductBookings = function () {
        var _this = this;
        this.bookingService
            .removeAllProductBookings$()
            .pipe(first(), switchMap(function (_a) {
            var removePostProductBookings = _a.removePostProductBookings;
            return forkJoin([
                _this.bookingService.postProductBookings$(removePostProductBookings),
                _this.voucherService.releaseAllVouchers$()
            ]);
        }))
            .subscribe();
    };
    ProductService.ngInjectableDef = i0.defineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.inject(i1.Store), i0.inject(i2.BookingService), i0.inject(i3.VoucherService)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
