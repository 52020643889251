export const styleList = {
  BaseTxColor: {
    inputType: 'color',
    list: [
      { selector: '.black', styles: 'color' },
      { selector: '.basket-product-text-color', styles: 'color' },
      { selector: '.ticket-accordion-icon .icon', styles: 'fill' }
    ]
  },
  DefaultBackgroundColor: {
    inputType: 'color',
    list: [
      {
        selector: '.page-background-without-image::after',
        styles: 'background-color'
      }
    ]
  },
  TicketPriceTxColor: {
    inputType: 'color',
    list: [{ selector: '.price', styles: 'color' }]
  },
  BaseThemeColor: {
    inputType: 'color',
    list: [
      { selector: '.app-page', styles: 'color' },
      { selector: '.main-bg', styles: 'color' },
      { selector: '.basket-component', styles: 'color' },
      { selector: '.top-info-box', styles: 'color' },
      { selector: '.dot-pulse-wrapper', styles: 'color' }
    ]
  },
  EventTitleName: {
    inputType: 'color',
    list: [{ selector: '.tile-title', styles: 'color' }]
  },
  EventDuration: {
    inputType: 'color',
    list: [{ selector: '.tile-time', styles: 'color' }]
  },
  PackageHeaderBg: {
    inputType: 'color',
    list: [
      { selector: '.package-type-header', styles: 'background-color' },
      { selector: '.package-type-header__warning', styles: 'background-color' }
    ]
  },
  PackageName: {
    inputType: 'color',
    list: [
      {
        selector: '.package-type-header__package-name',
        styles: 'color'
      },
      {
        selector: '.package-type-header__warning',
        styles: 'color'
      },
      { selector: '.section-body', styles: 'border-left-color' }
    ]
  },
  PackageContentBg: {
    inputType: 'color',
    list: [{ selector: '.package-tariff-types', styles: 'background-color' }]
  },
  PackageContentTicketType: {
    inputType: 'color',
    list: [{ selector: '.package-tariff-type-name', styles: 'color' }]
  },
  PackageContentPersonType: {
    inputType: 'color',
    list: [{ selector: '.package-title-black', styles: 'color' }]
  },
  PackageContentTicketsBg: {
    inputType: 'color',
    list: [{ selector: '.package-ticket-type', styles: 'background-color' }]
  },
  PackageContentPriceBg: {
    inputType: 'color',
    list: [{ selector: '.package-price', styles: 'background-color' }]
  },
  PackageContentPriceText: {
    inputType: 'color',
    list: [{ selector: '.package-price', styles: 'color' }]
  },
  PackageContentPriceHeight: {
    inputType: 'text',
    list: [{ selector: '.package-price', styles: 'height' }]
  },
  PackageSubtotalBg: {
    inputType: 'color',
    list: [{ selector: '.package-type__subtotal', styles: 'background-color' }]
  },
  PackageSubtotalText: {
    inputType: 'color',
    list: [{ selector: '.package-type__subtotal', styles: 'color' }]
  },
  PackageSubtotalHeight: {
    inputType: 'text',
    list: [{ selector: '.package-type__subtotal', styles: 'height' }]
  },
  ButtonBgColor: {
    inputType: 'color',
    list: [
      {
        selector: '.button, .ui-button, [disabled="false"].ui-button',
        styles: 'background-color'
      },
      { selector: '.counter-wrapper button', styles: 'background-color' },
      { selector: '.button-color', styles: 'color' },
      { selector: 'button.button-option span', styles: 'color' },
      { selector: '.form-button', styles: 'background-color' },
      { selector: '.button.button-option', styles: 'outline-color' },
      { selector: '.order-info-copy', styles: 'background-color' },
      { selector: '.order-info-buyer-visitor', styles: 'background-color' },
      { selector: '.scroll-button', styles: 'background-color' }
    ]
  },
  ButtonPadding: {
    inputType: 'text',
    list: [{ selector: '.button, .ui-button', styles: 'padding' }]
  },
  ButtonTxColor: {
    inputType: 'color',
    list: [
      {
        selector: '.button span, .counter-wrapper button span, .ui-button span',
        styles: 'color'
      },
      { selector: '.button.button-option', styles: 'background-color' },
      { selector: '.order-info-copy', styles: 'color' },
      { selector: '.order-info-buyer-visitor', styles: 'color' },
      { selector: '.scroll-button svg', styles: 'fill' }
    ]
  },
  TabAreaBg: {
    inputType: 'color',
    list: [
      {
        selector: '.tabs-related-content',
        styles: 'background-color'
      }
    ]
  },
  TabAreaBorderColor: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-tabs-item.active',
        styles: 'border-left-color'
      },
      {
        selector: '.navigation-tabs-item.active',
        styles: 'border-top-color'
      },
      {
        selector: '.navigation-tabs-item.active',
        styles: 'border-right-color'
      },
      {
        selector: '.navigation-tabs-item:not(.active)',
        styles: 'border-bottom-color'
      },
      {
        selector: '.navigation-tabs',
        styles: 'border-color'
      },
      {
        selector: '.tabs-related-content',
        styles: 'border-color'
      }
    ]
  },
  TabActiveBg: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-tabs-item.active',
        styles: 'background-color'
      }
    ]
  },
  TabActiveText: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-tabs-item.active',
        styles: 'color'
      }
    ]
  },
  TabInactiveBg: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-tabs-item',
        styles: 'background-color'
      }
    ]
  },
  TabInactiveText: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-tabs-item',
        styles: 'color'
      }
    ]
  },
  AreaBlockTxInactive: {
    inputType: 'color',
    list: [
      { selector: '.navigation-steps li a.notActive span', styles: 'color' },
      {
        selector: '.navigation-steps li a.notActive .step-navigation-icon',
        styles: 'fill'
      }
    ]
  },
  AreaBlockBgInactive: {
    inputType: 'color',
    list: [{ selector: '.navigation-steps li a.notActive ', styles: 'color' }]
  },
  AreaBlockTxtFutureActive: {
    inputType: 'color',
    list: [
      { selector: '.navigation-steps li a.future span', styles: 'color' },
      {
        selector: '.navigation-steps li a.future .step-navigation-icon',
        styles: 'fill'
      }
    ]
  },
  AreaBlockBgFutureActive: {
    inputType: 'color',
    list: [
      { selector: '.navigation-steps li a.future', styles: 'background-color' },
      {
        selector: '.navigation-steps li a.future:after',
        styles: 'border-left-color'
      }
    ]
  },
  AreaBlockBgActive: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-steps li a.active-link',
        styles: 'background-color'
      },
      {
        selector: '.navigation-steps li .active-link:after',
        styles: 'border-left-color'
      }
    ]
  },
  AreaBlockTxActive: {
    inputType: 'color',
    list: [
      { selector: '.navigation-steps li a.active-link span', styles: 'color' },
      {
        selector: '.navigation-steps li a.active-link .step-navigation-icon',
        styles: 'fill'
      }
      // { selector: '.scan-text-box .scan-text span', styles: 'color' } // for selfregistration scan text arrow
    ]
  },
  AreaBlockTxFuture: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-steps li.step.disabled div span',
        styles: 'color'
      },
      {
        selector: '.navigation-steps li.step.disabled .step-navigation-icon',
        styles: 'fill'
      }
    ]
  },
  AreaBlockBgFuture: {
    inputType: 'color',
    list: [
      {
        selector: '.navigation-steps li.step.disabled div:after',
        styles: 'border-left-color'
      },
      {
        selector: '.navigation-steps li.step.disabled a',
        styles: 'background-color'
      },
      {
        selector: '.navigation-steps li.step.disabled',
        styles: 'background-color'
      },
      {
        selector: '.navigation-steps li.step.disabled a:after',
        styles: 'border-left-color'
      },
      {
        selector: '.navigation-steps li.step.disabled div',
        styles: 'background-color'
      }
    ]
  },
  VoucherBlockTxColor: {
    inputType: 'color',
    list: [{ selector: '.voucher-wrap .voucher-label', styles: 'color' }]
  },
  VoucherBlockBorder: {
    inputType: 'text',
    list: [{ selector: '.voucher-box', styles: 'border' }]
  },
  VoucherBlockBgColor: {
    inputType: 'color',
    list: [{ selector: '.voucher-wrap', styles: 'background-color' }]
  },
  TicketHighlightBgColor: {
    inputType: 'color',
    list: [
      { selector: '.ticket-type', styles: 'background-color' },
      {
        selector: '.ticket-type .mat-expansion-panel',
        styles: 'background-color'
      },
      {
        selector: '.mat-expansion-panel-header:hover',
        styles: 'background-color'
      }
    ]
  },
  VoucherTicketHighlightBgColor: {
    inputType: 'color',
    list: [{ selector: '.ticket-type.has-voucher', styles: 'background-color' }]
  },
  UrlTicketHighlightBgColor: {
    inputType: 'color',
    list: [{ selector: '.ticket-type.has-ticketAddedByUrl', styles: 'background-color' }]
  },
  LinksOnTopTxColor: {
    inputType: 'color',
    list: [{ selector: '.navigation-wrapper', styles: 'color' }]
  },
  PlaceHolderColor: {
    inputType: 'color',
    list: [{ selector: '.form-wrapper .placeholder', styles: 'color' }]
  },
  RadioLabelTxColor: {
    inputType: 'color',
    list: [
      { selector: '.form-wrapper .radio-label', styles: 'color' },
      { selector: '.decorated-radio-button .radio-label', styles: 'color' },
      {
        selector: '.decorated-radio-button .sending-option-icon',
        styles: 'fill'
      }
    ]
  },
  RadioLabelTxWeight: {
    inputType: 'text',
    list: [{ selector: '.radio-label', styles: 'font-weight' }]
  },
  RadioBorder: {
    inputType: 'color',
    list: [
      {
        selector: '.decorated-radio-button .radio-button',
        styles: 'border-color'
      },
      {
        selector: '.decorated-radio-button',
        styles: 'color'
      }
    ]
  },
  RadioBgColor: {
    inputType: 'color',
    list: [
      { selector: '.radio-button-row', styles: 'background-color' },
      { selector: '.legitimation-option-box', styles: 'background-color' }
    ]
  },
  CheckboxLabelTxColor: {
    inputType: 'color',
    list: [
      { selector: '.checkbox-button-row .checkbox-label', styles: 'color' },
      { selector: '.checkbox-icon', styles: 'fill' }
    ]
  },
  CheckboxLabelTxWeight: {
    inputType: 'text',
    list: [
      {
        selector: '.checkbox-button-row .checkbox-label',
        styles: 'font-weight'
      }
    ]
  },
  CheckboxPolicyTextColor: {
    inputType: 'color',
    list: [
      {
        selector: '.checkbox-text',
        styles: 'color'
      }
    ]
  },
  CheckboxPolicyTextWeight: {
    inputType: 'text',
    list: [
      {
        selector: '.checkbox-text',
        styles: 'font-weight'
      }
    ]
  },
  CheckboxBgColor: {
    inputType: 'color',
    list: [
      { selector: '.checkbox-button-row', styles: 'background-color' },
      { selector: '.checkbox-text-row', styles: 'background-color' }
    ]
  },
  CheckboxBorder: {
    inputType: 'color',
    list: [{ selector: '.decorated-checkbox > label', styles: 'border-color' }]
  },
  InputHeight: {
    inputType: 'text',
    list: [
      { selector: '.input-wrapper input', styles: 'height' },
      { selector: '.input-wrapper input', styles: 'line-height' },
      { selector: '.input-wrapper select', styles: 'height' }
    ]
  },
  InputBorderColor: {
    inputType: 'color',
    list: [
      { selector: '.carousel-placeholder', styles: 'border-color' },
      { selector: '.input-wrapper input', styles: 'border-color' },
      { selector: '.input-wrapper select', styles: 'border-color' }
    ]
  },
  MainContentBgColor: {
    inputType: 'color',
    list: [{ selector: '.page-content', styles: 'background-color' }]
  },
  SubTitleTxColor: {
    inputType: 'color',
    list: [
      {
        selector: '.section-headline, .section-subtitle, .subtitle',
        styles: 'color'
      },
      { selector: '.section-body', styles: 'border-left-color' }
    ]
  },
  SubTitleBgColor: {
    inputType: 'color',
    list: [
      {
        selector: '.section-headline, .section-subtitle',
        styles: 'background-color'
      }
    ]
  },
  SubTitleInfoTxColor: {
    inputType: 'color',
    list: [
      { selector: '.section-headline::after', styles: 'color' },
      { selector: '.section-headline-side', styles: 'color' }
    ]
  },
  RequiredErrBrdColor: {
    inputType: 'color',
    list: [
      {
        selector: '.input-wrapper .ng-invalid:not(form), .ng-invalid:not(form), .invalid-input.ng-valid',
        styles: 'border-left-color'
      },
      // {
      //   selector: '.order-wrapper[class] .validity-list[class] li:not(.valid)',
      //   styles: 'background-color'
      // },
      {
        selector: '.input-error-wrapper small, .select-error-wrapper small',
        styles: 'background-color'
      }
    ]
  },
  RequiredErrBrdWidth: {
    inputType: 'text',
    list: [
      {
        selector: '.input-wrapper .ng-invalid:not(form), .ng-invalid:not(form), .invalid-input.ng-valid',
        styles: 'border-left-width'
      }
    ]
  },
  HeaderEventInfoBgColor: {
    inputType: 'color',
    list: [{ selector: '.logo-slope, .exhibition-date', styles: 'background-color' }]
  },
  HeaderEventInfoTxColor: {
    inputType: 'color',
    list: [{ selector: '.exhibition-date span', styles: 'color' }]
  },
  TicketOwnerEmailListTxColor: {
    inputType: 'color',
    list: [{ selector: '.ticket-data .email', styles: 'color' }]
  },
  TicketToBuyerEmailTxColor: {
    inputType: 'color',
    list: [{ selector: '.ticket-data .email.send-to-buyer', styles: 'color' }]
  },
  RowHeight: {
    inputType: 'text',
    list: [{ selector: '.ticket-type', styles: 'min-height' }]
  },
  InfoBlockInfoSignTxColor: {
    inputType: 'color',
    list: [{ selector: '.info-block', styles: 'background-color' }]
  },
  InfoTextTxColor: {
    inputType: 'color',
    list: [{ selector: '.info-block .icon', styles: 'fill' }, { selector: '.info-block .info-text', styles: 'color' }]
  },
  TicketOwnerFormBgColor: {
    inputType: 'color',
    list: [{ selector: '.order-wrapper', styles: 'background-color' }]
  },
  TicketOwnerIndicatorBgColor: {
    inputType: 'color',
    list: [{ selector: '.validity-list-list', styles: 'background-color' }]
  },
  TicketOwnerNavButtons: {
    inputType: 'color',
    list: [
      { selector: '.previous-button', styles: 'background-color' },
      { selector: '.next-button', styles: 'background-color' }
    ]
  },
  ErrorHdrColor: {
    inputType: 'color',
    list: [
      {
        selector: 'app-feedback-message .message-header',
        styles: 'background-color'
      }
    ]
  },
  ErrorTxColor: {
    inputType: 'color',
    list: [
      { selector: 'app-feedback-message .message-list span', styles: 'color' },
      {
        selector: 'app-feedback-message .message-header',
        styles: 'color'
      }
    ]
  },
  ErrorBgColor: {
    inputType: 'color',
    list: [
      {
        selector: 'app-feedback-message .message-body',
        styles: 'background-color'
      },
      { selector: 'app-feedback-message:after', styles: 'border-top-color' }
    ]
  },
  AjaxLoaderColor: {
    inputType: 'color',
    list: [{ selector: '.loader-wrap', styles: 'color' }]
  },
  TicketTypeTxColor: {
    inputType: 'color',
    list: [{ selector: '.confirmation-page .ticket', styles: 'color' }]
  },
  TicketTypeBgColor: {
    inputType: 'color',
    list: [{ selector: '.confirmation-page .ticket', styles: 'background-color' }]
  },
  SectionBgColor: {
    inputType: 'color',
    list: [
      { selector: '.ticket-section-block', styles: 'background-color' },
      { selector: '.section-description', styles: 'background-color' }
    ]
  },
  SectionHeaderBgColor: {
    inputType: 'color',
    list: [{ selector: '.section-header', styles: 'background-color' }]
  },
  SectionHeaderHooverBgColor: {
    inputType: 'color',
    list: [{ selector: '.section-header:hover', styles: 'background-color' }]
  },
  SectionHeaderTxColor: {
    inputType: 'color',
    list: [{ selector: '.section-header-text', styles: 'color' }, { selector: '.section-header .icon', styles: 'fill' }]
  },
  ContingentColorHighlight: {
    inputType: 'color',
    list: [{ selector: '.contingent-highlighted', styles: 'background' }]
  },
  ContingentTextHighlight: {
    inputType: 'color',
    list: [{ selector: '.contingent-highlighted', styles: 'color' }]
  }
};

export function generateSkinCss(variables) {
  if (variables) {
    let cssString = '';
    Object.keys(variables).forEach(variable => {
      if (styleList.hasOwnProperty(variable) && variables[variable]) {
        cssString = styleList[variable].list.reduce((allStyles, currentSelector) => {
          let style = `${currentSelector.styles}: ${variables[variable]} !important;`;
          return (
            allStyles +
            `${currentSelector.selector} {
  ${style}
}
`
          );
        }, cssString);
      }
    });

    //add extra styles
    if (variables.hasOwnProperty('RequiredErrBrdColor')) {
      cssString += `.input-error-wrapper small, .select-error-wrapper small {
      color: #ffffff !important;
    }
    `;
    }

    // if (variables.hasOwnProperty('RowHeight')) {
    //   cssString += `.ticket-type {
    //     height: ${parseInt(variables.RowHeight, 10) - 46}px !important;
    //   }
    //   `;
    // }

    return cssString;
  }
}
/*
"MessageTxColor": "#000000",
"MessageHdrColor": "#00ff00",
"MessageBgColor": "#ffffff",

"RadioTxColor": #ffffff,
"CheckboxTxColor": #ffffff,

"SubTitleInfoTxColor": "#ffffff",
"OddRowColorTxColor": "#000000",
"OddRowColorBgColor": "#EFF3F7",
"InputPadding": "13px 10px 4px 10px",
"SelectPadding": "0 9px",

"ButtonActiveTxColor": "#000000",
"ButtonActiveBgColor": "#FF8C00",
*/

/**
 *
 * let cssString = '';

  if (variables.hasOwnProperty('BaseTxColor')) {
    cssString += `.black {
          color: ${variables.BaseTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('BaseThemeColor')) {
    cssString += `.app-page {
          color: ${variables.BaseThemeColor} !important;
        }

        .main-bg {
          background-color: ${variables.BaseThemeColor}
        }

        .basket-component {
          color: ${variables.BaseThemeColor};
        }
        `;
  }

  if (variables.hasOwnProperty('ButtonBgColor')) {
    cssString += `.button, .ui-button {
          background-color: ${variables.ButtonBgColor} !important;
        }

        .counter-wrapper button {
          background-color: ${variables.ButtonBgColor} !important;
        }

        .button-color {
          color: ${variables.ButtonBgColor} !important;
        }

        .button.button-option span {
          color: ${variables.ButtonBgColor} !important;
        }

        .button.button-option {
          outline-color: ${variables.ButtonBgColor} !important;
        }

        .form-button{
          background-color: ${variables.ButtonBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('ButtonPadding')) {
    cssString += `.button, .ui-button {
          padding: ${variables.ButtonPadding} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('ButtonTxColor')) {
    cssString += `.button span, .counter-wrapper button span, .ui-button span {
          color: ${variables.ButtonTxColor} !important;
        }

        .button.button-option {
          background-color: ${variables.ButtonTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockTxInactive')) {
    cssString += `.navigation-steps li span{
          color: ${variables.AreaBlockTxInactive} !important;
        }
        .navigation-steps li .step-navigation-icon{
          fill: ${variables.AreaBlockTxInactive} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockBgInactive')) {
    cssString += `.navigation-steps li {
          color: ${variables.AreaBlockBgInactive} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockBgActive')) {
    cssString += `.navigation-steps li a.active-link{
          background-color: ${variables.AreaBlockBgActive} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockTxActive')) {
    cssString += `.navigation-steps li a.active-link span{
          color: ${variables.AreaBlockTxActive} !important;
        }
        .navigation-steps li a.active-link .step-navigation-icon{
          fill: ${variables.AreaBlockTxActive} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockBgActive')) {
    cssString += `.navigation-steps li .active-link:after{
          border-left-color: ${variables.AreaBlockBgActive} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockTxFuture')) {
    cssString += `.navigation-steps li.step.disabled a span{
          color: ${variables.AreaBlockTxFuture} !important;
        }

        .navigation-steps li.step.disabled .step-navigation-icon{
          fill: ${variables.AreaBlockTxFuture} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AreaBlockBgFuture')) {
    cssString += `.navigation-steps li.step.disabled a {
          background-color: ${variables.AreaBlockBgFuture} !important;
        }

        .navigation-steps li.step.disabled a:after{
           border-left-color: ${variables.AreaBlockBgFuture} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('VoucherBlockTxColor')) {
    cssString += `.voucher-wrap .voucher-label {
          color: ${variables.VoucherBlockTxColor} !important;
        }
        `;
  }

  // not using on the page atm
  if (variables.hasOwnProperty('VoucherBlockBorder')) {
    cssString += `.voucher-box {
          border: ${variables.VoucherBlockBorder};
        }
        `;
  }

  if (variables.hasOwnProperty('VoucherBlockBgColor')) {
    cssString += `.voucher-wrap {
          background-color: ${variables.VoucherBlockBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('LinksOnTopTxColor')) {
    cssString += `.navigation-wrapper {
          color: ${variables.LinksOnTopTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('PlaceHolderColor')) {
    cssString += `.form-wrapper .placeholder {
          color: ${variables.PlaceHolderColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RadioLabelTxColor')) {
    cssString += `.form-wrapper .radio-label {
          color: ${variables.RadioLabelTxColor} !important;
        }

        .decorated-radio-button .radio-label{
          color: ${variables.RadioLabelTxColor} !important;
        }

        .decorated-radio-button .sending-option-icon{
          fill: ${variables.RadioLabelTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RadioLabelTxWeight')) {
    cssString += `.radio-label {
          font-weight: ${variables.RadioLabelTxWeight} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RadioBorder')) {
    cssString += `.decorated-radio-button   .radio-button {
          border-color: ${variables.RadioBorder} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RadioBgColor')) {
    cssString += `.radio-button-row {
          background-color: ${variables.RadioBgColor} !important;
        }
        .legitimation-option-box {
          background-color: ${variables.RadioBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('CheckboxLabelTxColor')) {
    cssString += `.checkbox-button-row .checkbox-label {
          color: ${variables.CheckboxLabelTxColor} !important;
        }

        .checkbox-icon{
          fill: ${variables.CheckboxLabelTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('CheckboxLabelTxWeight')) {
    cssString += `.checkbox-button-row .checkbox-label {
          font-weight: ${variables.CheckboxLabelTxWeight} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('CheckboxBgColor')) {
    cssString += `.checkbox-button-row {
          background-color: ${variables.CheckboxBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('CheckboxBorder')) {
    cssString += `.decorated-checkbox > label {
          border-color: ${variables.CheckboxBorder} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('InputHeight')) {
    cssString += `.input-wrapper input {
          height: ${variables.InputHeight} !important;
          line-height: ${variables.InputHeight} !important;
        }

        .input-wrapper select {
          height: ${variables.InputHeight} !important
        }
        `;
  }

  if (variables.hasOwnProperty('InputBorderColor')) {
    cssString += `.input-wrapper input {
          border-color: ${variables.InputBorderColor};
        }

        .input-wrapper select {
          border-color: ${variables.InputBorderColor};
        }
        `;
  }

  if (variables.hasOwnProperty('MainContentBgColor')) {
    cssString += `.page-content {
          background-color: ${variables.MainContentBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('SubTitleTxColor')) {
    cssString += `.section-headline, .section-subtitle {
          color: ${variables.SubTitleTxColor} !important;
        }

        .section-body {
          border-left-color: ${variables.SubTitleTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('SubTitleBgColor')) {
    cssString += `.section-headline, .section-subtitle {
          background-color: ${variables.SubTitleBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('SubTitleInfoTxColor')) {
    cssString += `.section-headline::after {
          color: ${variables.SubTitleInfoTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RequiredErrBrdColor')) {
    cssString += `.ng-invalid:not(form), .invalid-input.ng-valid {
          border-left-color: ${variables.RequiredErrBrdColor} !important;
        }

        .order-wrapper[class] .validity-list[class] li {
          background-color: ${variables.RequiredErrBrdColor};
        }

        .input-error-wrapper small, .select-error-wrapper small {
          background-color: ${variables.RequiredSignColor} !important;
        }

        .input-error-wrapper small, .select-error-wrapper small {
          color: #ffffff !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RequiredErrBrdWidth')) {
    cssString += `.ng-invalid:not(form), .invalid-input.ng-valid {
          border-left-width: ${variables.RequiredErrBrdWidth} !important;
        }
        `;
  }



  if (variables.hasOwnProperty('HeaderEventInfoBgColor')) {
    cssString += `.logo-slope, .exhibition-date {
          background-color: ${variables.HeaderEventInfoBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('HeaderEventInfoTxColor')) {
    cssString += `.exhibition-date span{
          color: ${variables.HeaderEventInfoTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('TicketOwnerEmailListTxColor')) {
    cssString += `.ticket-data .email {
          color: ${variables.TicketOwnerEmailListTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('TicketToBuyerEmailTxColor')) {
    cssString += `.ticket-data .email.send-to-buyer {
          color: ${variables.TicketToBuyerEmailTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('RowHeight')) {
    cssString += `.ticket-type {
          padding-bottom: ${parseInt(variables.RowHeight, 10) -
            46}px !important;
        }
        `;
  }

  if (variables.hasOwnProperty('InfoBlockInfoSignTxColor')) {
    cssString += `.info-block {
          background-color: ${variables.InfoBlockInfoSignTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('InfoTextTxColor')) {
    cssString += `.info-block .icon {
          fill: ${variables.InfoTextTxColor} !important;
        }
        .info-block .info-text{
          color: ${variables.InfoTextTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('ErrorHdrColor')) {
    cssString += `app-feedback-message .message-header {
          background-color: ${variables.ErrorHdrColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('ErrorTxColor')) {
    cssString += `app-feedback-message .message-list span{
          color: ${variables.ErrorTxColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('ErrorBgColor')) {
    cssString += `app-feedback-message .message-body {
          background-color: ${variables.ErrorBgColor} !important;
        }

        app-feedback-message:after {
          border-top-color: ${variables.ErrorBgColor} !important;
        }
        `;
  }

  if (variables.hasOwnProperty('AjaxLoaderColor')) {
    cssString += `.loader-wrap {
          color: ${variables.AjaxLoaderColor} !important;
        }
        `;
  }
  if (variables.hasOwnProperty('TicketTypeTxColor')) {
    cssString += `.confirmation-page .ticket {
          color: ${variables.TicketTypeTxColor} !important;
        }
        `;
  }
  if (variables.hasOwnProperty('TicketTypeBgColor')) {
    cssString += `.confirmation-page .ticket {
          background-color: ${variables.TicketTypeBgColor} !important;
        }
        `;
  }
  return cssString;



if (variables.hasOwnProperty('RequiredSignColor')) {
  cssString += `.input-error-wrapper small, .select-error-wrapper small {
        color: ${variables.RequiredSignColor} !important;
      }
      `;
} */
