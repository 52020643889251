import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '@products/services/booking.service';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { StatusBarService } from '../status-bar/status-bar.service';
import { HolderService } from '../_pages/products/services/holder.service';
import { VoucherService } from '../_pages/products/services/voucher.service';
import * as fromRoot from '../app.reducer';
import { AppConstants } from '../shared/app-constants';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { getAllBookedTariffs, getAllBookedWorkshopTariffs, getBookedVoucherTariffByVoucherCode, getProductCountdown, getVouchersCountdown, getWorkshopCountdown } from '../shared/services-with-reducers/products/booking/booking.selectors';
import { getVoucherTariffByVoucherCode } from '../shared/services-with-reducers/products/product-selection/product-selection.selectors';
import { HelperService } from '@store/helpers/helper.service';
var CountdownComponent = /** @class */ (function () {
    function CountdownComponent(store, translateService, holderService, router, statusBarService, appService, customizationService, voucherService, bookingService, helperService) {
        this.store = store;
        this.translateService = translateService;
        this.holderService = holderService;
        this.router = router;
        this.statusBarService = statusBarService;
        this.appService = appService;
        this.customizationService = customizationService;
        this.voucherService = voucherService;
        this.bookingService = bookingService;
        this.helperService = helperService;
        this.showLoginCountdown = false;
        this.loggedOutIn = '';
        this.timeToReleaseWorkshop = '';
        this.timeToReleaseTicket = '';
        this.ticketBookingTimeoutModalOpen = false;
        this.urlBase = 'webshop';
        this.subscription = new Subscription();
    }
    CountdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        var authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;
        this.urlBase = this.helperService.isSelfregistration() ? 'self-registration' : 'webshop';
        this.subscription.add(this.store.pipe(select(fromRoot.getLoginTimestamp)).subscribe(function (timestamp) {
            if (_this.loginInterval) {
                clearInterval(_this.loginInterval);
                _this.loginInterval = null;
            }
            if (timestamp) {
                _this.loginInterval = setInterval(function () {
                    var now = Date.now();
                    if (timestamp + authTimespanInMs - 30 * 60 * 1000 < now) {
                        _this.showLoginCountdown = true;
                        var loggedOutInSecTotal = (timestamp + authTimespanInMs - now) / 1000;
                        _this.loggedOutIn = _this.getCountdownTime(loggedOutInSecTotal);
                    }
                    else {
                        _this.showLoginCountdown = false;
                    }
                }, 1000);
            }
            else {
                _this.showLoginCountdown = false;
            }
        }));
        /* PRODUCT COUNTDOWN */
        this.subscription.add(combineLatest([
            this.store.pipe(select(getAllBookedTariffs)),
            this.store.pipe(select(fromRoot.getExhibitionSettings)),
            this.store.pipe(select(fromRoot.getSelectedStep)),
            this.store.pipe(select(getProductCountdown))
        ])
            .pipe(filter(function (_a) {
            var allBookedTariffs = _a[0];
            return !!allBookedTariffs;
        }))
            .subscribe(function (_a) {
            var _ = _a[0], eventSettings = _a[1], selectedStep = _a[2], productCountdown = _a[3];
            if (!eventSettings) {
                _this.clearTicketInterval();
                return;
            }
            if (_this.ticketInterval) {
                clearInterval(_this.ticketInterval);
                _this.ticketInterval = null;
            }
            if (productCountdown) {
                // if ticket timeout is created and there is workshop timeout we remove it
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
                _this.timeToReleaseWorkshop = '';
                if (selectedStep === 'invoice') {
                    _this.clearTicketInterval();
                    return;
                }
                _this.ticketInterval = setInterval(function () {
                    var now = Date.now();
                    _this.timeToReleaseTicket = _this.getCountdownTime((productCountdown - now) / 1000);
                    if (productCountdown < now) {
                        // release all tickets. Bookings are released automatically on BE, so we just reset the reducer without user
                        _this.ticketBookingTimeoutModalOpen = true;
                        _this.clearTicketInterval();
                        clearInterval(_this.workshopInterval);
                        _this.workshopInterval = null;
                        _this.timeToReleaseWorkshop = '';
                    }
                }, 1000);
            }
            else {
                _this.clearTicketInterval();
                return;
            }
        }));
        /* WORKSHOPS COUNTDOWN */
        this.subscription.add(combineLatest([
            this.store.pipe(select(getAllBookedWorkshopTariffs)),
            this.store.pipe(select(fromRoot.getExhibitionSettings)),
            this.store.pipe(select(fromRoot.getSelectedStep)),
            this.store.pipe(select(getWorkshopCountdown))
        ])
            .pipe(filter(function (_a) {
            var workshopBookings = _a[0];
            return !!workshopBookings.length;
        }))
            .subscribe(function (_a) {
            var bookedTariffs = _a[0], eventSettings = _a[1], selectedStep = _a[2], workshopCountdown = _a[3];
            if (!eventSettings) {
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
                _this.timeToReleaseWorkshop = '';
                // reset bookings on BE
                if (_this.orderId) {
                    _this.bookingService.postReleaseWorkshopBookings$(_this.orderId).subscribe();
                    // reset orderId to prevent reset each time
                    _this.orderId = null;
                }
                return;
            }
            _this.store.pipe(select(fromRoot.getOrderUuid), first())
                .subscribe(function (uuid) { return _this.orderId = uuid; });
            if (_this.workshopInterval) {
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
            }
            var latestBookingTimestamp = workshopCountdown;
            if (latestBookingTimestamp) {
                // const releaseOn = getCountdownTimestamp(latestBookingTimestamp,eventSettings.workshopSeatsReleaseInMinutes);
                var noBookings = bookedTariffs.every(function (booking) {
                    return booking.workshops.some(function (workshop) { return !workshop.holderUuids.length; });
                });
                //if there is a ticket interval we don't need workshop interval !
                if (noBookings ||
                    selectedStep === 'invoice' ||
                    _this.ticketInterval) {
                    clearInterval(_this.workshopInterval);
                    _this.workshopInterval = null;
                    _this.timeToReleaseWorkshop = '';
                    return;
                }
            }
        }));
        /* VOUCHERS COUNTDOWN */
        this.subscription.add(this.store.pipe(select(getVouchersCountdown)).subscribe(function (vouchersCountdown) {
            clearInterval(_this.vouchersInterval);
            _this.vouchersInterval = null;
            if (vouchersCountdown.length) {
                // otherwise check if there is interval already, if not, create new one
                _this.vouchersInterval = setInterval(function () {
                    var now = Date.now();
                    var initialValues = {
                        code: '',
                        remaining: Infinity,
                        showTime: ''
                    };
                    _this.nextExpiringVoucher = vouchersCountdown.reduce(function (result, currVoucher) {
                        // we only relase limited wouchers
                        var timeWhenEnds = currVoucher.expiryTimestamp;
                        if (timeWhenEnds > now) {
                            // if the voucher is still valid get its time
                            if (timeWhenEnds - now < result.remaining) {
                                result.remaining = timeWhenEnds - now;
                                result.code = currVoucher.voucherCode;
                            }
                            return result;
                        }
                        else {
                            /* At this time the voucher was released by backend, so we need to remove it from FE as well */
                            combineLatest([
                                _this.store.pipe(select(getBookedVoucherTariffByVoucherCode(currVoucher.voucherCode))),
                                _this.store.pipe(select(getVoucherTariffByVoucherCode(currVoucher.voucherCode)))
                            ])
                                .pipe(first(), mergeMap(function (_a) {
                                var bookedVoucherTariff = _a[0], voucherTariff = _a[1];
                                // now remove holder forms related to this voucher ticket if there is any booked voucher tariff
                                if (bookedVoucherTariff) {
                                    _this.holderService.removeHoldersInputs(bookedVoucherTariff.holderUuids);
                                }
                                var ticketPersonId = bookedVoucherTariff ? bookedVoucherTariff.ticketPersonId : voucherTariff.ticketPersonId;
                                _this.voucherService.releaseExpiredVoucher(ticketPersonId, currVoucher.voucherCode);
                                return combineLatest([
                                    _this.store.pipe(select(fromRoot.getSelectedExhibition)),
                                    _this.store.pipe(select(fromRoot.getSelectedStep)),
                                    _this.store.pipe(select(getAllBookedTariffs))
                                ])
                                    .pipe(first(function (_a) {
                                    var selectedExhibition = _a[0], selectedStep = _a[1], getAllBookedTariffs = _a[2];
                                    return !getAllBookedTariffs.some(function (bookedTariff) { return bookedTariff.voucherCode === currVoucher.voucherCode; }) && !!selectedExhibition && !!selectedStep;
                                }), mergeMap(function (_a) {
                                    var selectedExhibition = _a[0], selectedStep = _a[1], allBookedTariffs = _a[2];
                                    if (!allBookedTariffs.length && selectedStep !== 'tickets') {
                                        _this.router.navigate([_this.urlBase + "/" + selectedExhibition.id + "/tickets"]);
                                    }
                                    return _this.translateService.get('voucher.timer-ranOut');
                                }));
                            }))
                                .subscribe(function (translation) { return _this.statusBarService.setStatus(translation, 'error'); });
                            return result;
                        }
                    }, initialValues);
                    _this.nextExpiringVoucher.showTime = _this.getCountdownTime(_this.nextExpiringVoucher.remaining / 1000);
                }, 1000);
            }
            else {
                _this.nextExpiringVoucher = null;
            }
        }));
    };
    CountdownComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        clearInterval(this.loginInterval);
        clearInterval(this.vouchersInterval);
        clearInterval(this.workshopInterval);
        this.loginInterval = null;
        this.vouchersInterval = null;
    };
    CountdownComponent.prototype.getCountdownTime = function (secondsTotal) {
        var minRemaining = Math.floor(secondsTotal / 60);
        var secRemaining = Math.floor(secondsTotal - minRemaining * 60);
        var secRemainingWithTwoDigits = secRemaining < 10 ? "0" + secRemaining : secRemaining;
        return minRemaining + ":" + secRemainingWithTwoDigits;
    };
    CountdownComponent.prototype.closeModalWindow = function ($event) {
        var _this = this;
        $event.preventDefault();
        $event.stopPropagation();
        this.ticketBookingTimeoutModalOpen = false;
        this.store.pipe(select(fromRoot.getSelectedExhibitionId), first()).switchMap(function (eventId) {
            _this.customizationService.resetShoppingStartTime();
            return _this.appService.resetReducers().map(function () { return eventId; });
        })
            .subscribe(function (eventId) { return window.location.replace("" + (eventId ? "/" + _this.urlBase + "/" + eventId : '/')); });
    };
    CountdownComponent.prototype.closeModalWindowOnRightClick = function ($event) {
        var _this = this;
        $event.stopPropagation();
        this.store.pipe(select(fromRoot.isLoggedInAsAdmin), first(function (isLoggedInAsAdmin) { return !!isLoggedInAsAdmin; }))
            .subscribe(function () {
            _this.ticketBookingTimeoutModalOpen = false;
        });
    };
    CountdownComponent.prototype.clearTicketInterval = function () {
        clearInterval(this.ticketInterval);
        this.ticketInterval = null;
        this.timeToReleaseTicket = '';
    };
    return CountdownComponent;
}());
export { CountdownComponent };
