import { HttpClient } from '@angular/common/http';
import { getIsWidget, getLanguage, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { LoadProductService } from '@products/services/load-product.service';
import { environment } from '@src/environments/environment';
import { GetExhibitionSettings, GetLocalizedImages } from '@store/customization/customization.actions';
import { CustomizationService } from '@store/customization/customization.service';
import { GetDepartments, GetOccupationalGroups, GetProfessions, GetTitles } from '@store/exhibition/exhibition.actions';
import { HelperService } from '@store/helpers/helper.service';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, skip } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../helpers/helper.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../customization/customization.service";
import * as i5 from "../../../_pages/products/services/load-product.service";
var ExhibitionService = /** @class */ (function () {
    function ExhibitionService(http, helpers, store, customizationService, loadProductsService) {
        var _this = this;
        this.http = http;
        this.helpers = helpers;
        this.store = store;
        this.customizationService = customizationService;
        this.loadProductsService = loadProductsService;
        this.exhibitionId$ = this.store.pipe(select(getSelectedExhibitionId));
        this.exhibitionLanguage$ = this.store.pipe(select(getLanguage));
        /* we skip initial value or value loaded from localstorage,
           so we load exhibition related data only when we realy change exhibition.
           In the reducer no Exhibition ID is set unless it differ from previous one (either from url or localstorage)
        */
        combineLatest([this.exhibitionId$, this.store.pipe(select(getIsWidget))])
            .pipe(skip(1), filter(function (_a) {
            var exhibitionId = _a[0];
            return !!exhibitionId || exhibitionId === 0;
        }), debounceTime(50))
            .subscribe(function (_a) {
            var exhibitionId = _a[0], isWidget = _a[1];
            _this.store.dispatch(new GetExhibitionSettings(exhibitionId));
            _this.loadProductsService.loadProducts(exhibitionId, isWidget);
            _this.loadProductsService.loadWorkshopProducts(exhibitionId);
            _this.customizationService.triggerOnExhibitionChange(exhibitionId);
        });
        this.exhibitionId$.pipe(skip(1)).subscribe(function (exhibitionId) {
            var exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;
            _this.store.dispatch(new GetTitles(exhibitionOrOperatorId));
            _this.store.dispatch(new GetProfessions(exhibitionOrOperatorId));
            _this.store.dispatch(new GetDepartments(exhibitionOrOperatorId));
            _this.store.dispatch(new GetOccupationalGroups(exhibitionOrOperatorId));
        });
        combineLatest([this.exhibitionId$, this.exhibitionLanguage$])
            .pipe(filter(function (_a) {
            var exhibitionId = _a[0];
            return !!exhibitionId;
        }))
            .subscribe(function (_a) {
            var exhibitionId = _a[0], exhibitionLanguage = _a[1];
            var exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;
            // effects are no more triggered in this loop so call it in next one
            setTimeout(function () {
                _this.store.dispatch(new GetLocalizedImages({
                    eventId: Number(exhibitionOrOperatorId),
                    countryCode: exhibitionLanguage
                }));
            });
        });
    }
    ExhibitionService.prototype.getUpcomingExhibitionList = function (langCode) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/active/" + langCode);
    };
    ExhibitionService.prototype.getAllExhibitionsList = function (langCode) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/all/" + langCode);
    };
    ExhibitionService.prototype.getDoubleClickScripts = function (eventId) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/double-click-scripts");
    };
    ExhibitionService.prototype.getGenericScripts = function (eventId) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/scripts");
    };
    ExhibitionService.prototype.getExhibitionHistoryList = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/user/events-history");
    };
    ExhibitionService.prototype.getTitles = function (eventId) {
        var selfReg = "?sr=" + this.helpers.isSelfregistration();
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/titles" + selfReg);
    };
    ExhibitionService.prototype.getProfessions = function (eventId) {
        var selfReg = "?sr=" + this.helpers.isSelfregistration();
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/positions" + selfReg);
    };
    ExhibitionService.prototype.getDepartments = function (eventId) {
        var selfReg = "?sr=" + this.helpers.isSelfregistration();
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/departments" + selfReg);
    };
    ExhibitionService.prototype.getOccupationalGroups = function (eventId) {
        var selfReg = "?sr=" + this.helpers.isSelfregistration();
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/occupational-groups" + selfReg);
    };
    ExhibitionService.prototype.getAllProfessions = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/positions");
    };
    ExhibitionService.prototype.getAllDepartments = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/departments");
    };
    ExhibitionService.prototype.getAllOccupationalGroups = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/occupational-groups");
    };
    ExhibitionService.prototype.getAllTitles = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/titles");
    };
    ExhibitionService.prototype.getEventSeries = function (seriesId, language) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event-series/" + seriesId + "/" + language);
    };
    ExhibitionService.ngInjectableDef = i0.defineInjectable({ factory: function ExhibitionService_Factory() { return new ExhibitionService(i0.inject(i1.HttpClient), i0.inject(i2.HelperService), i0.inject(i3.Store), i0.inject(i4.CustomizationService), i0.inject(i5.LoadProductService)); }, token: ExhibitionService, providedIn: "root" });
    return ExhibitionService;
}());
export { ExhibitionService };
