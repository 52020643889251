import { SendingOptionModel } from '../../../_pages/products/models/product-selection.model';

export interface FieldSetting {
  isVisible: true;
  isMandatory: boolean;
  order: number;
}

export interface FieldSettings {
  Salutation: FieldSetting;
  PersonTitle: FieldSetting;
  FirstName: FieldSetting;
  LastName: FieldSetting;
  DateOfBirth: FieldSetting;
  Street: FieldSetting;
  ZipCode: FieldSetting;
  City: FieldSetting;
  Company: FieldSetting;
  PersonFunction: FieldSetting;
  Department: FieldSetting;
  OccupationalGroup: FieldSetting;
  Phone: FieldSetting;
  Country: FieldSetting;
  Email: FieldSetting;
  SearchAddress: FieldSetting;
  MailConfirm: FieldSetting;
}
export interface TicketOwnerSettingsModel {
  fieldSettings: FieldSettings;
  //ticketSendingMode: string;
}

export interface QuestionnaireDataSection {
  id: number;
  values: Array<QuestionnaireDataInput>;
  label: string;
  name: string;
  type: string;
  order: number;
  isMandatory: boolean;
  textLength: number;
  selectionLimit: number;
  previousValueId?: number;
}

export interface QuestionnaireDataInput {
  id: number;
  text: string;
  order: number;
}

export interface IFieldSettings {
  MailConfirm: FieldSetting;
  SearchAddress: FieldSetting;
  VatNumber: FieldSetting;
  Country: FieldSetting;
  Salutation: FieldSetting;
  PersonTitle: FieldSetting;
  FirstName: FieldSetting;
  LastName: FieldSetting;
  DateOfBirth: FieldSetting;
  Street: FieldSetting;
  ZipCode: FieldSetting;
  City: FieldSetting;
  Company: FieldSetting;
  // CompanyAccount: FieldSetting;
  PersonFunction: FieldSetting;
  Department: FieldSetting;
  OccupationalGroup: FieldSetting;
  // AccountFunction: FieldSetting;
  Phone: FieldSetting;
  Email: FieldSetting;
}

export interface IWithFieldSetting {
  fieldSettings: IFieldSettings;
}

export interface BuyerSettings extends IWithFieldSetting {
  enableDifferentBillingAddress: boolean;
  isReadonlyBuyer: boolean;
  newsletterInputType: string;
  showFairCatalogCheckbox: boolean;
  showCreateAccountCheckbox: boolean;
  fairCatalogDefaultValue: boolean;
}

export interface RegistrationSettings extends IWithFieldSetting {}

export interface ExhibitionSettingModel {
  id: number;
  eventId: number;
  ageRating: number;
  workshopSeatsReleaseInMinutes?: number;
  ticketReleaseInMinutes?: number;
  currency?: string;
  languages: Array<string>;
  eventBanner: string;
  hideHeaderLogoArea: boolean;
  eventSerie: string;
  eventLogo: string;
  hasSponsorArea: boolean;
  promoCodeVisible: PromoCodeVisibility.Hidden | PromoCodeVisibility.AlwaysVisible | PromoCodeVisibility.AfterLogin;
  loginMode: string;
  limitBoughtTickets: number;
  limitPromoCodes: number;
  limitLimitedPromocodes: number;
  tradeVisitorLegitimation: boolean;
  mobileTicket: boolean;
  twintPayment: boolean;
  goToLegitimationForNewAccount: boolean;
  emailIsMandatoryForMobileTicket: boolean;
  enableLastNameForForgottenPassword: boolean;
  isTermsVisible: boolean;
  isTermsMandatory: boolean;
  termsUrl: string;
  isPrivacyLinkVisible: boolean;
  isPrivacyLinkMandatory: boolean;
  isPrivacyOptionalVisible: boolean;
  isPrivacyOptionalMandatory: boolean;
  isSponsorBannerUrlVisible: boolean;
  isSponsorLogoUrlVisible: boolean;
  sponsorBannerUrl: string;
  sponsorLogoUrl: string;
  privacyUrl: string;
  privacyOptionalUrl: string;
  isDisclaimerVisible: boolean;
  disclaimerUrl: string;
  isImprintVisible: boolean;
  imprintUrl: string;
  isGoogleAutocompleteEnabled: boolean;
  faxNumber: string;
  isTelephoneValidationDisabled: boolean;
  buyerSettings: BuyerSettings;
  ticketOwnerSettings: TicketOwnerSettingsModel;
  registrationSettings: RegistrationSettings;
  ticketSelectionDeliveryTypes: SendingOptionModel[];
  customStyles: ExhibitionCustomStyles;
  customStylesId: number;
  isContinueAsGuestEnabled: boolean;
  isTicketHolderVisible?: boolean;
  isWorkshopsSelectionMandatory: boolean;
  workshopsPerTicket: number;
  maintenance: maintenanceModel;
  currencySettings?: CurrencySettingsModel;
  isOptionalLegitimationCommentAllowed?: boolean;
  isLegitimationWithCommentAllowed?: boolean;
  isLegitimationWithFaxAllowed?: boolean;
  isLegitimationWithFileAllowed?: boolean;
  isLegitimationWithUrlAllowed?: boolean;
  uniqueVisitorCheckType: string;
  isDownloadTicketButtonVisible?: boolean;
  localTime: string;
  showAmountOfAvailableWorkshops: boolean;
  showWorkshopsForSelectedTicketsOnly: boolean;
  workshopMandatoryForZeroPriceTickets: boolean;
  hideWorkshopDate: boolean;
  enableWorkshopSelectionOverlapping: boolean;
  workshopsOnTicketSelection: boolean;
  workshopViewType: 'calendar' | 'list';
  paymentMethods: ExhibitorSettingsPaymentMethod[];
  ticketLimitPerEmail: number;
  hideWorkshopPrice: boolean;
  isHeaderLogoLinkVisible: boolean;
  headerLogoLink: string;
  ticketLimitPerUserAccount: number;
  isVoucherIncludedPerUserAccountLimit: boolean;
}

export enum PromoCodeVisibility {
  Hidden = 'hidden',
  AlwaysVisible = 'alwaysVisible',
  AfterLogin = 'afterLogin'
}

export interface CheckboxOptions {
  checkboxKey: string;
  optionKey: string;
  value: boolean;
  label: string;
  isRequired: boolean;
  text?: {
    value: string;
    translation: string;
    position: 'prepend' | 'append';
  };
}

export class ExhibitorSettingsPaymentMethod {
  public id: string;
  public name: string;
}

export interface CurrencySettingsModel {
  currencyCode: string;
  decimalMark: string;
  thousandsSeparator: string;
}

export interface OperatorsSettingsModel {
  languages: Array<string>;
  defaultLanguage: string;
  operatorBanner: string;
  operatorLogo: string;
  enableLastNameForForgottenPassword: boolean;
  newsletterInputType: string;
  isTelephoneValidationDisabled: boolean;
  customStyles: ExhibitionCustomStyles;
  loginMode?: string;
  customStylesId: number;
  maintenance: maintenanceModel;
  dateFormats: { [key: string]: string };
  registrationSettings: RegistrationSettings;
  isBannerLinkVisible: boolean;
  isHeaderLogoLinkVisible: boolean;
  bannerLink: string;
  headerLogoLink: string;
}

export interface maintenanceModel {
  isPlanned: boolean;
  isPresent: boolean;
  reason: string;
  since: Date;
  until: Date;
}

export interface SponsorBannerModel {
  sponsorBanner: string;
}

export interface CssSkinModel {
  priority: number;
  css: string;
  variables: ExhibitionCustomStyles;
  id?: number;
  title?: string;
}

export interface LocalizedImagesModel {
  banner: string;
  logo: string;
  background: string;
  favicon: string;
}

export interface ExhibitionCustomStyles {
  id: number;
  VoucherBlockTxColor: string;
  TicketHighlightBgColor: string;
  VoucherBlockBorder: string;
  VoucherBlockBgColor: string;
  VoucherTicketHighlightBgColor: string;
  UrlTicketHighlightBgColor: string;
  TicketToBuyerEmailTxColor: string;
  TicketOwnerEmailListTxColor: string;
  MessageTxColor: string;
  MessageHdrColor: string;
  MessageBgColor: string;
  LinksOnTopTxColor: string;
  ErrorTxColor: string;
  ErrorHdrColor: string;
  ErrorBgColor: string;
  BaseTxColor: string;
  TicketPriceTxColor: string;
  TicketTypeTxColor: string;
  TicketTypeBgColor: string;
  SubTitleTxColor: string;
  SubTitleInfoTxColor: string;
  SubTitleBgColor: string;
  SelectPadding: string;
  RowHeight: string;
  RequiredSignColor: string;
  RequiredErrBrdWidth: string;
  RequiredErrBrdColor: string;
  RadioTxColor: string;
  RadioLabelTxWeight: string;
  RadioLabelTxColor: string;
  RadioBorder: string;
  RadioBgColor: string;
  PlaceHolderColor: string;
  OddRowColorTxColor: string;
  OddRowColorBgColor: string;
  MainContentBgColor: string;
  InputPadding: string;
  InputHeight: string;
  InputBorderColor: string;
  ticketOwnerNavButtons: string;
  InfoTextTxColor: string;
  ticketOwnerFormBgColor: string;
  ticketOwnerIndicatorBgColor: string;
  InfoBlockInfoSignTxColor: string;
  HeaderEventInfoTxColor: string;
  HeaderEventInfoBgColor: string;
  CheckboxTxColor: string;
  CheckboxLabelTxWeight: string;
  CheckboxLabelTxColor: string;
  CheckboxBorder: string;
  CheckboxBgColor: string;
  ButtonTxColor: string;
  ButtonPadding: string;
  ButtonBgColor: string;
  ButtonActiveTxColor: string;
  ButtonActiveBgColor: string;
  AreaBlockTxInactive: string;
  AreaBlockTxFuture: string;
  AreaBlockTxActive: string;
  AreaBlockBgInactive: string;
  AreaBlockBgFuture: string;
  AreaBlockBgActive: string;
  AjaxLoaderColor: string;
  BaseThemeColor: string;
  EventTitleName: string;
  EventDuration: string;
  PackageHeaderBg: string;
  PackageName: string;
  PackageContentBg: string;
  PackageContentText: string;
  PackageContentPriceBg: string;
  PackageContentPriceText: string;
  PackageSubtotalBg: string;
  PackageSubtotalText: string;
  TabActiveBg: string;
  TabActiveText: string;
  TabAreaBg: string;
  ContingentColorHighlight: string;
  ContingentTextHighlight: string;
}
