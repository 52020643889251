import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, getExhibitionSettings, getLegitimationStatus, getSelfRegistration } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import {
  LegitimationFaxInfoModel,
  LegitimationListModel,
  LegitimationPostModel,
  LegitimationStatus,
  LegitimationStatusResponse
} from '@store/legitimation/legitimation.interface';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { getAllLegitimationTariffs } from '@store/products/product-selection/product-selection.selectors';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegitimationService {
  private readonly destroy$ = new Subject<void>();

  constructor(private http: HttpClient, private store: Store<State>) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  saveLegitimationInfo(
    data: LegitimationPostModel,
    userId: number,
    eventId: number
  ): Observable<LegitimationPostModel> {
    return <Observable<LegitimationPostModel>>(
      this.http.post(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/legitimation/${userId}`, data)
    );
  }

  getLegitimationList(): Observable<LegitimationListModel[]> {
    return <Observable<LegitimationListModel[]>>(
      this.http.get(`${environment.protocol}${environment.webApiUrl}/user/legitimation-history`)
    );
  }

  getLegitimationStatus(eventId: number): Observable<LegitimationStatusResponse> {
    return this.http.get<LegitimationStatusResponse>(
      `${environment.protocol}${environment.webApiUrl}/user/event/${eventId}/legitimation-status`
    );
  }

  getLegitimationFaxId(eventId: number, userId: number): Observable<LegitimationFaxInfoModel> {
    return <Observable<LegitimationFaxInfoModel>>(
      this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/fax-legitimation-id/${userId}`)
    );
  }

  isTariffWithRequiredLegitimationBooked$(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(getAllLegitimationTariffs)),
      this.store.pipe(select(getAllBookedTariffs))
    ]).pipe(
      map(([allLegitimationTariffs, bookedTariffs]) =>
        bookedTariffs.some(bookedTariff => {
          const { ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex } = bookedTariff;

          return allLegitimationTariffs.some(
            legitimationTariff =>
              legitimationTariff.ticketTypeId === ticketTypeId &&
              legitimationTariff.ticketPersonId === ticketPersonId &&
              legitimationTariff.voucherCode === voucherCode &&
              legitimationTariff.packageNumber === packageNumber &&
              legitimationTariff.packageIndex === packageIndex
          );
        })
      ),
      takeUntil(this.destroy$)
    );
  }

  isLegitimationRequiredForBookedProducts$(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getSelfRegistration)),
      this.store.pipe(select(getLegitimationStatus)),
      this.isTariffWithRequiredLegitimationBooked$()
    ]).pipe(
      filter(([exhibitionSettings, isSelfRegistration]) => !!exhibitionSettings && !isSelfRegistration),
      map(([exhibitionSettings, _, legitimationStatus, isTariffWithRequiredLegitimationBooked]) => {
        let isLegitimationRequired =
          exhibitionSettings.tradeVisitorLegitimation || isTariffWithRequiredLegitimationBooked;

        if (legitimationStatus) {
          const isLegitimationApproved: boolean = legitimationStatus === LegitimationStatus.Approved;
          if (isLegitimationApproved) {
            isLegitimationRequired = false;
          }
        }

        return isLegitimationRequired;
      }),
      takeUntil(this.destroy$)
    );
  }
}
