var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import cloneDeep from 'lodash.clonedeep';
import { ActionTypes } from './legitimation.actions';
export var initialState = {
    legitimations: [],
    legitimationStatus: null,
    legitimationFaxId: null,
    legitimationPostResponse: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.ADD_LEGITIMATION_TO_LIST:
            return __assign({}, state, { legitimations: action.payload });
        case ActionTypes.SET_LEGITIMATION_STATUS:
            return __assign({}, state, { legitimationStatus: action.payload });
        case ActionTypes.SET_LEGITIMATION_FAX_ID:
            return __assign({}, state, { legitimationFaxId: action.payload });
        case ActionTypes.SET_LEGITIMATION_INFO_RESPONSE:
            return __assign({}, state, { legitimationPostResponse: action.payload });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
export var getLegitimationList = function (state) { return state.legitimations; };
export var getLegitimationStatus = function (state) { return state.legitimationStatus; };
export var getLegitimationFaxId = function (state) { return state.legitimationFaxId; };
export var getLegitimationPostResponse = function (state) { return state.legitimationPostResponse; };
