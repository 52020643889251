import * as fromRoot from '../app.reducer';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HolderInformation } from '../_pages/products/models/confirmation.model';

@Component({
  moduleId: module.id,
  selector: 'app-ticket-dropdown',
  templateUrl: './ticket-dropdown.component.html',
  styleUrls: ['./ticket-dropdown.component.scss']
})
export class TicketDropdownComponent implements OnInit {
  @Input() confirmationOptions: any;
  @Input() holderInformation: HolderInformation;
  @Output() onSelect = new EventEmitter<any>();

  public selectedSendingOption: string;
  public selectedIcon: string;
  public opened = false;

  constructor(
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.holderInformation) {
      this.setSelecteSendingOption(
        this.holderInformation.sendingOption,
        this.holderInformation.sendtoowner
      );
    } else {
      this.selectedSendingOption = this.confirmationOptions[0].value;
    }

    this.setIcon(this.selectedSendingOption);
  }

  dropdownItemClicked(value: string) {
    let sendtoowner = true;
    if (
      ['mobilePerBuyer', 'ticketRetrivalLinkBuyer', 'allToBuyer'].indexOf(
        value
      ) >= 0
    ) {
      sendtoowner = false;
    }

    this.setSelecteSendingOption(value, sendtoowner);
    this.setIcon(this.selectedSendingOption);

    let sendingOptionValue = value;

    this.opened = false;
    this.onSelect.emit({
      sendingOption: sendingOptionValue,
      sendtoowner,
      holderInformation: this.holderInformation
    });
  }

  setSelecteSendingOption(optionName, sendtoowner) {
    let templateOptionName = optionName;
    if (optionName === 'normalPerOwner' && !sendtoowner) {
      templateOptionName = 'allToBuyer';
    } else if (optionName === 'mobilePerOwner' && !sendtoowner) {
      templateOptionName = 'mobilePerBuyer';
    } else if (optionName === 'ticketRetrivalLink' && !sendtoowner) {
      templateOptionName = 'ticketRetrivalLinkBuyer';
    }

    this.selectedSendingOption = templateOptionName;
  }

  setIcon(selectedSendingOption) {
    this.selectedIcon = selectedSendingOption;
  }
}
