import { Inject, Injectable, Optional } from '@angular/core';
import { State } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { HolderUuids, TicketHolder } from '@products/models/holder.model';
import { BookingService } from '@products/services/booking.service';
import { HolderService } from '@products/services/holder.service';
import { HOLDER_DEBOUNCE_TIME } from '@shared/tokens';
import { QuestionnaireDataSection } from '@store/customization/customization.interfaces';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import {
  ActionTypes as BookingActionTypes,
  RemovePackageFromBookingList,
  RemoveProductFromBookingList,
  RemoveReleasedVoucherProductFromBookingList,
  RemoveVoucherProductFromBookingList,
  SetPackageInBookingList,
  SetProductInBookingList,
  SetTicketClaimedHashValid,
  SetVoucherForAnonymousProductInBookingList,
  SetVoucherProductInBookingList
} from '@store/products/booking/booking.actions';
import { getAllHolderUuidsFromBookedProducts } from '@store/products/booking/booking.selectors';
import {
  DownloadMobileTicket,
  DownloadPassBook,
  DownloadTicket,
  GetQuestionnaireInputs,
  ActionTypes as HolderActionTypes,
  LoadTicketHolder,
  PostTicketHolderForm,
  RemoveProductHolder,
  RemoveProductHoldersAndSetAnonymousHolder,
  SetPostTicketHolderFormResult,
  SetProductHolder,
  SetQuestionnaireInputs,
  SetTicketHolder
} from '@store/products/holder/holder.actions';
import { getAllAnonymousHolderUuids } from '@store/products/holder/holder.selectors';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class HolderEffect {
  // @Effect()
  // createHolder$: Observable<Action> = this.actions$.pipe(
  //   ofType<SetProductInBookingList | SetPackageInBookingList>(
  //     BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
  //     BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST
  //   ),
  //   withLatestFrom(this.store.pipe(select(getAllHolderUuids))),
  //   switchMap(([bookingAction, bookedHolderUuids]) => {
  //     const { payload: currentBookedOrRemovedProducts } = bookingAction;

  //     return this.holderService
  //       .calculateNumberOfHolderUuidsToCreate$(currentBookedOrRemovedProducts, bookedHolderUuids)
  //       .pipe(
  //         filter(numberOfHoldersToCreate => numberOfHoldersToCreate > 0),
  //         map(numberOfHoldersToCreate => new CreateProductHolder(numberOfHoldersToCreate))
  //       );
  //   }),
  //   catchError(error => throwError(error))
  // );

  @Effect()
  setHolders$: Observable<Action> = this.actions$.pipe(
    ofType<SetProductInBookingList | SetPackageInBookingList | SetVoucherProductInBookingList>(
      BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST
    ),
    switchMap(() => {
      return this.holderService
        .addOrRemoveProductHoldersInputs$()
        .pipe(
          map(({ holderUuidsToAddOrRemove, isProductBookingRemoved }) =>
            isProductBookingRemoved
              ? new RemoveProductHolder(holderUuidsToAddOrRemove)
              : new SetProductHolder(holderUuidsToAddOrRemove)
          )
        );
    }),
    catchError(error => throwError(error))
  );

  @Effect()
  setHolderForAnonymousProduct$: Observable<Action> = this.actions$.pipe(
    ofType<SetVoucherForAnonymousProductInBookingList>(
      BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST
    ),
    withLatestFrom(this.store.pipe(select(getAllAnonymousHolderUuids))),
    map(([bookingAction, bookedHolderUuids]) => {
      const { payload: currentBookedOrRemovedProducts } = bookingAction;
      const latestBookedHolderUuids: HolderUuids = this.bookingService.getSetProductBookingsHolderUuids(
        currentBookedOrRemovedProducts
      );
      const isFirstAnonymousVoucher: boolean = !bookedHolderUuids.length;

      if (isFirstAnonymousVoucher) {
        this.holderService.removeHoldersInputs(bookedHolderUuids);
      }

      this.holderService.addHoldersInputs(latestBookedHolderUuids);

      return isFirstAnonymousVoucher
        ? new RemoveProductHoldersAndSetAnonymousHolder(latestBookedHolderUuids)
        : new SetProductHolder(latestBookedHolderUuids);
    }),
    catchError(error => throwError(error))
  );

  @Effect()
  removeHolders$: Observable<Action> = this.actions$.pipe(
    ofType<
      | RemoveProductFromBookingList
      | RemovePackageFromBookingList
      | RemoveVoucherProductFromBookingList
      | RemoveReleasedVoucherProductFromBookingList
    >(
      BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST
    ),
    withLatestFrom(this.store.pipe(select(getAllHolderUuidsFromBookedProducts))),
    map(([_, bookedHolderUuids]) => {
      this.holderService.removeHoldersInputs(bookedHolderUuids);
      return new RemoveProductHolder(bookedHolderUuids);
    }),
    catchError(error => throwError(error))
  );

  @Effect()
  setHolderQuestionnaireInputs$: Observable<Action> = this.actions$.pipe(
    ofType<GetQuestionnaireInputs>(HolderActionTypes.GET_QUESTIONNAIRE_INPUTS),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: { eventId, ticketPersonIds } }) => {
      if (!eventId) {
        return EMPTY;
      }

      return this.holderService.getTariffHolderQuestionnaire$(eventId, ticketPersonIds).pipe(
        map(
          (tariffHolderQuestionnaire: QuestionnaireDataSection[]) =>
            new SetQuestionnaireInputs(
              this.customizationService.tansformQuestionnaireIntoInput(tariffHolderQuestionnaire)
            )
        ),
        catchError(() => of(new SetQuestionnaireInputs([])))
      );
    })
  );

  @Effect()
  getClaimingTicketHolder$: Observable<Action> = this.actions$.pipe(
    ofType<LoadTicketHolder>(HolderActionTypes.LOAD_CLAIMING_TICKET_HOLDER),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: hash }) => {
      if (!hash) {
        return EMPTY;
      }

      return this.holderService.getTicketByHolder(hash).pipe(
        map((ticketHolder: TicketHolder) => {
          // if we load the user
          if (ticketHolder.hasOwnProperty('id')) {
            this.store.dispatch(new SetTicketClaimedHashValid(true));
            return new SetTicketHolder(ticketHolder);
          } else {
            // else if the has is not valid, we need to give some feedback
            this.store.dispatch(new SetTicketClaimedHashValid(false));
            return new SetTicketHolder(null);
          }
        }),
        catchError(() => {
          this.store.dispatch(new SetTicketClaimedHashValid(false));
          return of(new SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  postTariffHolderQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<PostTicketHolderForm>(HolderActionTypes.POST_TICKET_HOLDER_FORM),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: ticketHolderForm }) => {
      if (ticketHolderForm === null) {
        return EMPTY;
      }

      return this.holderService.postTicketHolderForm(ticketHolderForm).pipe(
        map(() => {
          return new SetPostTicketHolderFormResult(true);
        }),
        catchError(() => of(new SetPostTicketHolderFormResult(false)))
      );
    })
  );

  @Effect()
  downloadTicket$: Observable<Action> = this.actions$.pipe(
    ofType<DownloadTicket>(HolderActionTypes.DOWNLOAD_TICKET),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: hash }) => {
      if (!hash) {
        return EMPTY;
      }

      return this.holderService.downloadTicket(hash).pipe(
        map((blob: any) => {
          this.helperService.saveToFileSystem(blob, 'ticket.pdf');

          // if we load the user
          return new SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  downloadMobileTicket$: Observable<Action> = this.actions$.pipe(
    ofType<DownloadMobileTicket>(HolderActionTypes.DOWNLOAD_MOBILE_TICKET),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: hash }) => {
      if (!hash) {
        return EMPTY;
      }

      return this.holderService.downloadMobileTicket(hash).pipe(
        map((blob: any) => {
          this.helperService.saveToFileSystem(blob, 'MobileTicket.pdf');

          // if we load the user
          return new SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  downloadPassBook$: Observable<Action> = this.actions$.pipe(
    ofType<DownloadPassBook>(HolderActionTypes.DOWNLOAD_PASS_BOOK),
    debounceTime(this.holderDebounceTime),
    switchMap(({ payload: hash }) => {
      if (!hash) {
        return EMPTY;
      }

      return this.holderService.downloadPassBook(hash).pipe(
        map((blob: any) => {
          this.helperService.saveToFileSystem(blob, 'Passbook.pkpass');

          // if we load the user
          return new SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new SetTicketHolder(null));
        })
      );
    })
  );

  constructor(
    @Optional() @Inject(HOLDER_DEBOUNCE_TIME) private holderDebounceTime: number,
    private actions$: Actions,
    private store: Store<State>,
    private bookingService: BookingService,
    private holderService: HolderService,
    private customizationService: CustomizationService,
    private helperService: HelperService
  ) {}
}
