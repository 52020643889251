import { AddAvailableTariffs, AvailableTariffs, TariffStatusState } from '@products/models/tariff-status.model';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  ActionTypes as TariffActionTypes,
  Actions as TariffActions
} from '@store/products/status/tariff/tariff.actions';
import _ from 'lodash';

export { TariffStatusState as State };

export const initialState: TariffStatusState = {
  userAccountTicketLimit: null,
  availableTariffs: {},
  isTariffLoading: false,
  isCreateContingentReservationLoading: false
};

export const tariffStatusReducer = (
  state: TariffStatusState = initialState,
  action: TariffActions | ProductActions
): TariffStatusState => {
  switch (action.type) {
    case TariffActionTypes.SET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS:
      const userAccountTicketLimit: number = action.payload;

      return {
        ...state,
        userAccountTicketLimit: userAccountTicketLimit
      };

    case TariffActionTypes.SET_AVAILABLE_TARIFFS_STATUS: {
      const availableTariffs: AvailableTariffs = _.cloneDeep(action.payload);

      return {
        ...state,
        availableTariffs: { ...state.availableTariffs, ...availableTariffs }
      };
    }

    case TariffActionTypes.SET_RELEASED_VOUCHER_AVAILABLE_TARIFFS_STATUS: {
      const addAvailableTariffs: AddAvailableTariffs = _.cloneDeep(action.payload);
      const clonedAvailableTariffs: AvailableTariffs = _.cloneDeep(state.availableTariffs);

      _.keys(addAvailableTariffs).forEach(
        (ticketPersonId: number) => (clonedAvailableTariffs[ticketPersonId] += addAvailableTariffs[ticketPersonId])
      );

      return {
        ...state,
        availableTariffs: { ...state.availableTariffs, ...clonedAvailableTariffs }
      };
    }

    case TariffActionTypes.SET_IS_TARIFF_LOADING_STATUS: {
      const isTariffLoading: boolean = action.payload;

      return {
        ...state,
        isTariffLoading
      };
    }

    case TariffActionTypes.SET_IS_CREATE_CONTINGENT_RESERVATION_LOADING_STATUS:
      const isCreateContingentReservationLoading: boolean = action.payload;

      return {
        ...state,
        isCreateContingentReservationLoading
      };

    case TariffActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
