import { Component, OnDestroy, OnInit } from '@angular/core';
import { State, getSelectedExhibitionId, getSelfRegistration, isLoggedInAsAdmin } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getTranslationList } from '@store/translation/translation.selectors';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-bottom-sticky',
  templateUrl: './bottom-sticky.component.html',
  styleUrls: ['./bottom-sticky.component.scss']
})
export class BottomStickyComponent implements OnInit, OnDestroy {
  public textWithLink: string = '';
  public isCookiePopupEnabled: boolean = false;
  private isLoggedInAsAdmin: boolean = false;
  private checkCookiePopupStatus: boolean = true;
  private _subscriptions: Subscription = new Subscription();

  constructor(private _store: Store<State>, private _translateService: TranslateService) {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(isLoggedInAsAdmin)),
        this._store.pipe(select(getSelfRegistration))
      ]).subscribe(([isLoggedInAsAdmin, isSelfRegistrationEnabled]) => {
        this.checkCookiePopupStatus = !isLoggedInAsAdmin && !isSelfRegistrationEnabled;
        this.isLoggedInAsAdmin = isLoggedInAsAdmin;
      })
    );
  }

  ngOnInit() {
    if (this.checkCookiePopupStatus) {
      this._subscriptions.add(
        combineLatest([
          this._store.pipe(select(isLoggedInAsAdmin)),
          this._store.pipe(select(getSelectedExhibitionId))
        ]).subscribe(() => {
          this.isCookiePopupEnabled = false;
        })
      );

      this._subscriptions.add(
        this._store
          .pipe(
            select(getTranslationList),
            filter(translations => !!translations)
          )
          .subscribe(translations => {
            if (!this.isLoggedInAsAdmin) {
              let cookiePopupText = translations['cookies.popup.text'];

              if (!cookiePopupText) {
                this._translateService.get('cookies.popup.text').subscribe(defaultCookiePopupText => {
                  cookiePopupText = defaultCookiePopupText;
                });
              }

              this.isCookiePopupEnabled = !!cookiePopupText ? !cookiePopupText.startsWith('-notshown-') : false;

              if (this.isCookiePopupEnabled) {
                const linkTextOnly = cookiePopupText.match(/<.*?>/);

                if (linkTextOnly) {
                  const link = `<a href="#" target="_blank">${linkTextOnly[0].slice(1, -1)}</a>`;
                  this.textWithLink = cookiePopupText.replace(linkTextOnly[0], link);
                } else {
                  this.textWithLink = cookiePopupText;
                }
              }
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
