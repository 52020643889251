import { Injectable, OnDestroy } from '@angular/core';
import { State, getExhibitionSettings, notLoggedAndLoginMandatory } from '@app/app.reducer';
import { LoginService } from '@app/login/login.service';
import { Store, select } from '@ngrx/store';
import { PreferredTariffValidationState } from '@products/models/tariff.model';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { AppConstants } from '@shared/app-constants';
import {
  getAllBookedProductsCount,
  getBookedPromoCodeVoucherTariffsCount,
  getBookedTariffCount
} from '@store/products/booking/booking.selectors';
import { getPreferredTariff } from '@store/products/product-selection/product-selection.selectors';
import { getAvailableTariffs, getUserAccountTariffLimit } from '@store/products/status/tariff/tariff.selectors';
import { getWorkshopsAvailableSeatsGroupedByWorkshopId } from '@store/products/status/workshop/workshop.selectors';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PreferredTariffValidationService implements OnDestroy {
  preferredTariffValidationState$: Observable<Partial<PreferredTariffValidationState>>;

  private preferredTariffValidationServiceState: Partial<PreferredTariffValidationState>;
  private readonly destroy$ = new Subject<void>();
  private readonly _preferredTariffValidationState$: BehaviorSubject<
    Partial<PreferredTariffValidationState>
  > = new BehaviorSubject({
    ticketTypeId: null,
    ticketPersonTypeId: null,
    count: 0,
    countDifference: 0,
    validatedCount: 0,
    previousValidatedCount: 0,
    validatedMaxLimit: 0,
    isCountOverLimit: false,
    isTariffSoldOut: false,
    isAvailableTariffsLimitReached: false,
    numberOfBookedVouchers: null,
    numberOfAllBookedTariffs: null,
    initialNumberOfAvailableTariffs: null,
    currentNumberOfAvailableTariffs: null,
    tariffLimit: null,
    maxTariffLimit: null,
    tariffLimitPerUserAccount: null,
    currentUserAccountTariffLimit: null,
    isCurrentUserAccountTariffLimitReached: false,
    hideWorkshopTariffCounter: false,
    allowedWorkshopsSeatsAvailable: null,
    areAllAllowedWorkshopsAssigned: false,
    isWorkshopsSelectionMandatoryAndHasWorkshops: false,
    isVoucher: false,
    isVoucherIncludedPerUserAccountLimit: false,
    tariffLimitWarningMessage: ''
  });

  constructor(
    private store: Store<State>,
    private loginService: LoginService,
    private tariffCountValidationService: TariffCountValidationService
  ) {
    this.preferredTariffValidationState$ = this._preferredTariffValidationState$.asObservable().distinctUntilChanged();
    this.preferredTariffValidationState$.pipe(takeUntil(this.destroy$)).subscribe(tariffValidationState => {
      this.preferredTariffValidationServiceState = {
        ...this.preferredTariffValidationServiceState,
        ...tariffValidationState
      };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  validatePreferredTariffCount$(
    ticketTypeId: number,
    ticketPersonTypeId: number,
    count: number
  ): Observable<{ ticketTypeId: number; ticketPersonTypeId: number; validatedCount: number }> {
    if (!count) {
      return of({
        ticketTypeId,
        ticketPersonTypeId,
        validatedCount: AppConstants.PREFERRED_PRODUCT_DEFAULT_AMOUNT
      });
    }

    const bookingAndStatusState$ = combineLatest([
      this.store.pipe(select(getWorkshopsAvailableSeatsGroupedByWorkshopId)),
      this.store.pipe(select(getUserAccountTariffLimit)),
      this.store.pipe(select(getAvailableTariffs)),
      this.store.pipe(select(getAllBookedProductsCount)),
      this.store.pipe(select(getBookedPromoCodeVoucherTariffsCount))
    ]);

    return combineLatest([
      this.store.pipe(select(notLoggedAndLoginMandatory)),
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getPreferredTariff(ticketTypeId, ticketPersonTypeId))),
      bookingAndStatusState$
    ]).pipe(
      first(
        ([
          notLoggedAndLoginMandatory,
          exhibitionSettings,
          tariff,
          [workshopAvailableSeats, userAccountTariffLimit, currentAvailableTariffs]
        ]) => {
          const isValid =
            !notLoggedAndLoginMandatory &&
            !!tariff &&
            !!exhibitionSettings &&
            !!workshopAvailableSeats &&
            !!currentAvailableTariffs;

          const isUserAccountTariffLimitRequired =
            !notLoggedAndLoginMandatory && exhibitionSettings && exhibitionSettings.ticketLimitPerUserAccount > 0;

          if (isUserAccountTariffLimitRequired) {
            const isCurrentUserAccountTariffLimitSet = userAccountTariffLimit !== null;

            return isValid && isCurrentUserAccountTariffLimitSet;
          }

          return isValid;
        }
      ),
      switchMap(
        ([
          _,
          exhibitionSettings,
          tariff,
          [
            workshopAvailableSeats,
            userAccountTicketLimit,
            currentAvailableTariffs,
            bookedProductsCount,
            numberOfBookedVouchers
          ]
        ]) =>
          of(tariff).pipe(
            withLatestFrom(
              this.store.pipe(
                select(
                  getBookedTariffCount(
                    tariff.ticketTypeId,
                    tariff.ticketPersonId,
                    tariff.voucherCode,
                    tariff.packageNumber,
                    tariff.packageIndex
                  )
                )
              )
            ),
            map(([tariff, currentBookedTariffCount]) => ({
              tariff,
              exhibitionSettings,
              numberOfBookedVouchers,
              bookedProductsCount,
              currentUserAccountTicketLimit: userAccountTicketLimit,
              workshopAvailableSeats,
              currentAvailableTariffs,
              currentBookedTariffCount
            }))
          )
      ),
      map(
        ({
          tariff,
          exhibitionSettings,
          numberOfBookedVouchers,
          bookedProductsCount,
          currentUserAccountTicketLimit,
          currentAvailableTariffs,
          workshopAvailableSeats,
          currentBookedTariffCount
        }) => {
          const { ticketPersonId, isVoucher, availableTickets, ticketLimit, allowedWorkshops } = tariff;

          if (isVoucher) {
            return {
              ticketTypeId,
              ticketPersonTypeId,
              validatedCount: 0
            };
          }

          const {
            limitBoughtTickets,
            ticketLimitPerUserAccount,
            isWorkshopsSelectionMandatory,
            isVoucherIncludedPerUserAccountLimit
          } = exhibitionSettings;

          const {
            allowedWorkshopsSeatsAvailable,
            hideWorkshopTariffCounter,
            areAllAllowedWorkshopsAssigned
          } = this.tariffCountValidationService.tariffAllowedWorkshopsStatus(count, tariff, workshopAvailableSeats);

          this.initAndValidateState({
            ticketTypeId,
            ticketPersonTypeId,
            count: count,
            validatedCount: currentBookedTariffCount,
            previousValidatedCount: currentBookedTariffCount,
            isTariffSoldOut: this.tariffCountValidationService.isTariffSoldOut(
              currentBookedTariffCount,
              availableTickets
            ),
            isAvailableTariffsLimitReached: this.tariffCountValidationService.isTariffSoldOut(
              currentBookedTariffCount,
              currentAvailableTariffs[ticketPersonId]
            ),
            numberOfBookedVouchers: numberOfBookedVouchers,
            numberOfAllBookedTariffs: bookedProductsCount,
            initialNumberOfAvailableTariffs: availableTickets,
            currentNumberOfAvailableTariffs: currentAvailableTariffs[ticketPersonId],
            percentageOfAvailableTariffs: this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(
              count,
              ticketLimit,
              currentAvailableTariffs[ticketPersonId]
            ),
            tariffLimit: ticketLimit,
            maxTariffLimit: limitBoughtTickets,
            tariffLimitPerUserAccount: ticketLimitPerUserAccount,
            currentUserAccountTariffLimit: currentUserAccountTicketLimit,
            isCurrentUserAccountTariffLimitReached: this.tariffCountValidationService.isCurrentUserAccountTariffLimitReached(
              ticketLimitPerUserAccount,
              currentUserAccountTicketLimit
            ),
            hideWorkshopTariffCounter: hideWorkshopTariffCounter,
            allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
            areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
            isWorkshopsSelectionMandatoryAndHasWorkshops: isWorkshopsSelectionMandatory && allowedWorkshops.length > 0,
            isVoucher: isVoucher,
            isVoucherIncludedPerUserAccountLimit: isVoucherIncludedPerUserAccountLimit,
            tariffLimitWarningMessage: ''
          });

          return {
            ticketTypeId,
            ticketPersonTypeId,
            validatedCount: this.preferredTariffValidationServiceState.validatedCount
          };
        }
      )
    );
  }

  initAndValidateState(tariffValidationState: Partial<PreferredTariffValidationState>) {
    this.validate(tariffValidationState);

    this._preferredTariffValidationState$.next({
      ...this.preferredTariffValidationServiceState,
      ...tariffValidationState
    });
  }

  updateState(tariffValidationState: Partial<PreferredTariffValidationState>) {
    this._preferredTariffValidationState$.next({
      ...this.preferredTariffValidationServiceState,
      ...tariffValidationState
    });
  }

  validateState(count: number, tariffValidationState: Partial<PreferredTariffValidationState>) {
    tariffValidationState.count = count;

    this.validate(tariffValidationState);

    this._preferredTariffValidationState$.next({
      ...this.preferredTariffValidationServiceState,
      ...tariffValidationState
    });
  }

  getValidatedState() {
    return this.preferredTariffValidationServiceState;
  }

  geValidatedStateProperty(propertyName: string) {
    return propertyName in this.preferredTariffValidationServiceState
      ? this.preferredTariffValidationServiceState[propertyName]
      : null;
  }

  private validate(tariffValidationState: Partial<PreferredTariffValidationState>) {
    const { count, validatedCount } = tariffValidationState;

    tariffValidationState.countDifference = count - validatedCount;
    tariffValidationState.previousValidatedCount = validatedCount;

    this.tariffCountValidationService.validateIsAvailableTariffsLimit(tariffValidationState);
    this.tariffCountValidationService.validateTariffLimitPerUserAccount(tariffValidationState);
    // this.tariffCountValidationService.validateWorkshopTariff(tariffValidationState);
    this.tariffCountValidationService.validateMaxTariffLimit(tariffValidationState);
    this.tariffCountValidationService.validateInLimitCounter(count, tariffValidationState);
  }
}
