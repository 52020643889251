import { HttpClient, HttpParams } from '@angular/common/http';
import { getOrderUuid, getSelectedExhibition, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { ProductType, getProductType } from '@products/models/products.model';
import { TariffClassification, TariffPersonalizationType } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { getCountdownTimestamp, getUUID } from '@shared/app-utils';
import { getBookedPackageTypeByPackageAndTicketPersonId, getBookedPackageTypeByPackageNumberAndIndexes, getBookedTariffTypeAndTariffByTicketPersonId, getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode, getSharedBookedPersonIdTariffsTotalCount } from '@shared/services-with-reducers/products/booking/booking.selectors';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { getBookedProducts } from '@store/products/booking/booking.selectors';
import { getPreferredTariffTypeWithTariff } from '@store/products/product-selection/product-selection.selectors';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { EMPTY, combineLatest, forkJoin, from, of, pipe, zip } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, takeWhile, toArray } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../../../shared/services-with-reducers/helpers/helper.service";
var INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND;
var BookingService = /** @class */ (function () {
    function BookingService(http, store, helperService) {
        this.http = http;
        this.store = store;
        this.helperService = helperService;
    }
    BookingService.prototype.setProductBookings$ = function (bookingProductTypes, isPackageAdded) {
        var _this = this;
        if (isPackageAdded === void 0) { isPackageAdded = false; }
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount(bookingProductTypes)))
        ]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], sharedBookedPersonIdTariffsCount = _a[2];
            return _this.mapBookingProductTypeToPostProductBooking(bookingProductTypes, eventId, orderUuid, sharedBookedPersonIdTariffsCount, isPackageAdded);
        }));
    };
    BookingService.prototype.setPreferredProductBookings$ = function (ticketTypeId, ticketPersonTypeId, count) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getPreferredTariffTypeWithTariff(ticketTypeId, ticketPersonTypeId))),
            this.store.pipe(select(getBookedProducts))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], preferredTicketTypeWithTariff = _a[2];
            return !!eventId && !!orderUuid && !!preferredTicketTypeWithTariff;
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], preferredTicketTypeWithTariff = _a[2], bookedProductTypes = _a[3];
            var preferredBookingProductTypes = _this.mapTariffTypeToBookingTariffType(preferredTicketTypeWithTariff, preferredTicketTypeWithTariff.tariffs[0], 0, count);
            _this.removeProductBookings(bookedProductTypes);
            var preferredBookingAndRemovedBookingProductTypes = bookedProductTypes.concat([preferredBookingProductTypes]);
            return {
                eventId: eventId,
                orderUuid: orderUuid,
                preferredBookingAndRemovedBookingProductTypes: preferredBookingAndRemovedBookingProductTypes
            };
        }), switchMap(function (_a) {
            var eventId = _a.eventId, orderUuid = _a.orderUuid, preferredBookingAndRemovedBookingProductTypes = _a.preferredBookingAndRemovedBookingProductTypes;
            return zip(of({ eventId: eventId, orderUuid: orderUuid, preferredBookingAndRemovedBookingProductTypes: preferredBookingAndRemovedBookingProductTypes }), _this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount(preferredBookingAndRemovedBookingProductTypes)))).pipe(map(function (_a) {
                var combinedLatest = _a[0], sharedBookedPersonIdTariffsCount = _a[1];
                return ({
                    combinedLatest: combinedLatest,
                    sharedBookedPersonIdTariffsCount: sharedBookedPersonIdTariffsCount
                });
            }));
        }), map(function (_a) {
            var combinedLatest = _a.combinedLatest, sharedBookedPersonIdTariffsCount = _a.sharedBookedPersonIdTariffsCount;
            var eventId = combinedLatest.eventId, orderUuid = combinedLatest.orderUuid, preferredBookingAndRemovedBookingProductTypes = combinedLatest.preferredBookingAndRemovedBookingProductTypes;
            return {
                preferredBookingAndRemovedBookingProductTypes: preferredBookingAndRemovedBookingProductTypes,
                postProductBookings: _this.mapBookingProductTypeToPostProductBooking(preferredBookingAndRemovedBookingProductTypes, eventId, orderUuid, sharedBookedPersonIdTariffsCount)
            };
        }));
    };
    BookingService.prototype.prepareRemoveTariffBookingPipe = function (ticketPersonId) {
        var _this = this;
        return pipe(first(function (combinedLatest) {
            return combinedLatest.every(function (combinedItem) { return !!combinedItem; });
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], bookingTariffType = _a[2];
            return ({ eventId: eventId, orderUuid: orderUuid, bookingTariffType: bookingTariffType });
        }), switchMap(function (_a) {
            var eventId = _a.eventId, orderUuid = _a.orderUuid, bookingTariffType = _a.bookingTariffType;
            return zip(of({ eventId: eventId, orderUuid: orderUuid, bookingTariffType: bookingTariffType }), _this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount([bookingTariffType])))).pipe(map(function (_a) {
                var combinedLatest = _a[0], sharedBookedPersonIdTariffsCount = _a[1];
                return ({
                    combinedLatest: combinedLatest,
                    sharedBookedPersonIdTariffsCount: sharedBookedPersonIdTariffsCount
                });
            }));
        }), map(function (_a) {
            var combinedLatest = _a.combinedLatest, sharedBookedPersonIdTariffsCount = _a.sharedBookedPersonIdTariffsCount;
            var eventId = combinedLatest.eventId, orderUuid = combinedLatest.orderUuid, bookingTariffType = combinedLatest.bookingTariffType;
            var bookingTariff = bookingTariffType.tariffs.find(function (tariff) { return tariff.ticketPersonId === ticketPersonId; });
            bookingTariff.count = 0;
            bookingTariff.holderUuids = [];
            return {
                bookingTariffTypes: [bookingTariffType],
                removePostProductBookings: _this.mapBookingProductTypeToPostProductBooking([bookingTariffType], eventId, orderUuid, sharedBookedPersonIdTariffsCount)
            };
        }));
    };
    BookingService.prototype.removeTariffBookings$ = function (removeTariff) {
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedTariffTypeAndTariffByTicketPersonId(removeTariff.ticketPersonId)))
        ]).pipe(this.prepareRemoveTariffBookingPipe(removeTariff.ticketPersonId));
    };
    BookingService.prototype.removeVoucherTariffBookings$ = function (removeVoucherTariff) {
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode(removeVoucherTariff.ticketPersonId, removeVoucherTariff.voucherCode)))
        ]).pipe(this.prepareRemoveTariffBookingPipe(removeVoucherTariff.ticketPersonId));
    };
    BookingService.prototype.removeReleasedVoucherTariffBookings$ = function (removeVoucher) {
        return this.store.pipe(select(getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode(removeVoucher.ticketPersonId, removeVoucher.voucherCode)), first(), map(function (bookedVoucherTariffType) {
            var bookedVoucherTariff = bookedVoucherTariffType &&
                bookedVoucherTariffType.tariffs.find(function (bookedVoucherTariff) { return bookedVoucherTariff.ticketPersonId === removeVoucher.ticketPersonId; });
            if (bookedVoucherTariff) {
                bookedVoucherTariff.count = 0;
                bookedVoucherTariff.holderUuids = [];
            }
            var bookedVoucherTariffTypes = bookedVoucherTariff ? [bookedVoucherTariffType] : [];
            return {
                bookedVoucherTariffTypes: bookedVoucherTariffTypes,
                voucherCode: removeVoucher.voucherCode
            };
        }));
    };
    BookingService.prototype.removePackageTariffBookings$ = function (removePackageTariff) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedPackageTypeByPackageAndTicketPersonId(removePackageTariff.packageNumber, removePackageTariff.packageIndex, removePackageTariff.ticketTypeId, removePackageTariff.ticketPersonId)))
        ]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], bookedPackageType = _a[2];
            return ({ eventId: eventId, orderUuid: orderUuid, bookedPackageType: bookedPackageType });
        }), switchMap(function (_a) {
            var eventId = _a.eventId, orderUuid = _a.orderUuid, bookedPackageType = _a.bookedPackageType;
            return zip(of({ eventId: eventId, orderUuid: orderUuid, bookedPackageType: bookedPackageType }), _this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount([bookedPackageType])))).pipe(map(function (_a) {
                var combinedLatest = _a[0], sharedBookedPersonIdTariffsCount = _a[1];
                return ({
                    combinedLatest: combinedLatest,
                    sharedBookedPersonIdTariffsCount: sharedBookedPersonIdTariffsCount
                });
            }));
        }), map(function (_a) {
            var combinedLatest = _a.combinedLatest, sharedBookedPersonIdTariffsCount = _a.sharedBookedPersonIdTariffsCount;
            var eventId = combinedLatest.eventId, orderUuid = combinedLatest.orderUuid, bookedPackageType = combinedLatest.bookedPackageType;
            var bookingPackageTariffType = bookedPackageType.productTypes.find(function (packageProductType) {
                if (isBookingProductTypeTariff(packageProductType)) {
                    return packageProductType.ticketTypeId === removePackageTariff.ticketTypeId;
                }
            });
            if (isBookingProductTypeTariff(bookingPackageTariffType)) {
                var bookedTariff = bookingPackageTariffType.tariffs.find(function (tariff) { return tariff.ticketPersonId === removePackageTariff.ticketPersonId; });
                bookedTariff.count = 0;
                bookedTariff.holderUuids = [];
            }
            return {
                bookedPackageTypes: [bookedPackageType],
                removePostProductBookings: _this.mapBookingProductTypeToPostProductBooking([bookedPackageType], eventId, orderUuid, sharedBookedPersonIdTariffsCount)
            };
        }));
    };
    BookingService.prototype.removePackageBookings$ = function (removePackageBookings) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedPackageTypeByPackageNumberAndIndexes(removePackageBookings)))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            return !!eventId && !!orderUuid;
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], bookedPackageTypes = _a[2];
            return ({ eventId: eventId, orderUuid: orderUuid, bookedPackageTypes: bookedPackageTypes });
        }), switchMap(function (_a) {
            var eventId = _a.eventId, orderUuid = _a.orderUuid, bookedPackageTypes = _a.bookedPackageTypes;
            return zip(of({ eventId: eventId, orderUuid: orderUuid, bookedPackageTypes: bookedPackageTypes }), _this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount(bookedPackageTypes)))).pipe(map(function (_a) {
                var combinedLatest = _a[0], sharedBookedPersonIdTariffsCount = _a[1];
                return ({
                    combinedLatest: combinedLatest,
                    sharedBookedPersonIdTariffsCount: sharedBookedPersonIdTariffsCount
                });
            }));
        }), map(function (_a) {
            var combinedLatest = _a.combinedLatest, sharedBookedPersonIdTariffsCount = _a.sharedBookedPersonIdTariffsCount;
            var eventId = combinedLatest.eventId, orderUuid = combinedLatest.orderUuid, bookedPackageTypes = combinedLatest.bookedPackageTypes;
            var emptyPackageIndexes = _.cloneDeep(removePackageBookings.packageIndexes);
            bookedPackageTypes.forEach(function (bookedPackageType) {
                var packageIndexToRemoveFromEmptyPackageIndexes = emptyPackageIndexes.findIndex(function (emptyPackageIndex) { return emptyPackageIndex === bookedPackageType.packageIndex; });
                emptyPackageIndexes.splice(packageIndexToRemoveFromEmptyPackageIndexes, 1);
                bookedPackageType.productTypes.forEach(function (packageProductType) {
                    if (isBookingProductTypeTariff(packageProductType)) {
                        packageProductType.tariffs.forEach(function (packageTariff) {
                            packageTariff.count = 0;
                            packageTariff.holderUuids = [];
                        });
                    }
                });
            });
            var removePostProductBookings = _this.mapBookingProductTypeToPostProductBooking(bookedPackageTypes, eventId, orderUuid, sharedBookedPersonIdTariffsCount);
            var emptyPackagesProductTypes = _this.createEmptyBookingPackageTypeFromPackageNumberAndIndexes(removePackageBookings.packageNumber, emptyPackageIndexes);
            return {
                bookedPackageTypes: bookedPackageTypes.concat(emptyPackagesProductTypes),
                removePostProductBookings: removePostProductBookings
            };
        }));
    };
    BookingService.prototype.setWorkshopBookings$ = function (setBookingWorkshops) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid))
        ]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            var postWorkshopBookings = _this.mapSetBookingWorkshopsToPostWorkshopBookings(setBookingWorkshops, eventId, orderUuid);
            return {
                setBookingWorkshops: setBookingWorkshops,
                postWorkshopBookings: postWorkshopBookings
            };
        }));
    };
    BookingService.prototype.removeWorkshopBookings$ = function (removeBookingWorkshops) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid))
        ]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            var postWorkshopBookings = _this.mapRemoveBookingWorkshopsToPostWorkshopBookings(removeBookingWorkshops, eventId, orderUuid);
            return {
                removeBookingWorkshops: removeBookingWorkshops,
                postWorkshopBookings: postWorkshopBookings
            };
        }));
    };
    BookingService.prototype.postAvailableWorkshopBookings$ = function (postWorkshopBookings) {
        var _this = this;
        var isWorkshopSoldOut = false;
        var postWorkshopBookings$ = from(postWorkshopBookings.map(function (postWorkshopBooking) {
            return _this.postWorkshopBooking$(postWorkshopBooking);
        }));
        return postWorkshopBookings$.pipe(concatMap(function (postWorkshopBooking$) {
            return postWorkshopBooking$.pipe(map(function (postWorkshopBookingResponse) { return postWorkshopBookingResponse; }));
        }), catchError(function () {
            // API throws an error when workshop is sold out
            isWorkshopSoldOut = true;
            return EMPTY;
        }), takeWhile(function () { return !isWorkshopSoldOut; }), toArray(), map(function (postWorkshopBookingResponses) {
            var _a;
            var _b = postWorkshopBookingResponses.reduce(function (previousResponse, nextResponse) {
                return previousResponse.seats < nextResponse.seats ? previousResponse : nextResponse;
            }), workshopId = _b.workshopId, seats = _b.seats;
            return {
                numberOftWorkshopBookingsAssigned: postWorkshopBookingResponses.length,
                workshopAvailableSeats: (_a = {}, _a[workshopId] = seats, _a)
            };
        }));
    };
    BookingService.prototype.postRemoveMultipleWorkshopBookings$ = function (removeBookingWorkshops, postWorkshopBookings) {
        var _this = this;
        return forkJoin(postWorkshopBookings.map(function (postWorkshopBooking) {
            return _this.postWorkshopBooking$(postWorkshopBooking);
        })).pipe(map(function (postWorkshopBookingResponses) {
            var workshopAvailableSeats = {};
            postWorkshopBookingResponses.forEach(function (_a) {
                var workshopId = _a.workshopId, seats = _a.seats;
                return (workshopAvailableSeats[workshopId] = seats);
            });
            return {
                removeBookingWorkshops: removeBookingWorkshops,
                workshopAvailableSeats: workshopAvailableSeats
            };
        }));
    };
    BookingService.prototype.postReleaseAssignedWorkshopHolderSeats$ = function (workshopAssignedHolders, releaseWorkshopAssignedHolders) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid))
        ]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            var postWorkshopBookings = [];
            _.keys(releaseWorkshopAssignedHolders).forEach(function (workshopId) {
                var workshopSeatsToRelease = releaseWorkshopAssignedHolders[workshopId].length;
                if (workshopSeatsToRelease) {
                    var postWorkshopBooking = {
                        eventId: eventId,
                        workshopId: workshopId,
                        seats: -Math.abs(workshopSeatsToRelease),
                        uuid: orderUuid
                    };
                    postWorkshopBookings.push(postWorkshopBooking);
                }
            });
            return postWorkshopBookings;
        }), switchMap(function (postWorkshopBookings) {
            return forkJoin(postWorkshopBookings.map(function (postWorkshopBooking) {
                return _this.postWorkshopBooking$(postWorkshopBooking);
            })).pipe(map(function (postWorkshopBookingResponses) {
                var workshopAvailableSeats = {};
                _.keys(releaseWorkshopAssignedHolders).forEach(function (workshopId) {
                    var releasedWorkshopSeats = postWorkshopBookingResponses.find(function (postWorkshopBookingResponse) { return postWorkshopBookingResponse.workshopId === +workshopId; });
                    if (releasedWorkshopSeats) {
                        workshopAvailableSeats[workshopId] = releasedWorkshopSeats.seats;
                    }
                    else {
                        workshopAssignedHolders[workshopId] = workshopAssignedHolders[workshopId].concat(releaseWorkshopAssignedHolders[workshopId]);
                    }
                });
                return {
                    workshopAssignedHolders: workshopAssignedHolders,
                    workshopAvailableSeats: workshopAvailableSeats
                };
            }));
        }));
    };
    BookingService.prototype.removeUnassignedHoldersInSetBookingWorkshopsPipe = function (setBookingWorkshops, postWorkshopBookings) {
        return pipe(map(function (postAvailableWorkshopBookingsResponse) {
            var _a = postAvailableWorkshopBookingsResponse, workshopAvailableSeats = _a.workshopAvailableSeats, numberOftWorkshopBookingsAssigned = _a.numberOftWorkshopBookingsAssigned;
            var postWorkshopBookingsAssigned = postWorkshopBookings.slice(0, numberOftWorkshopBookingsAssigned);
            var postWorkshopBookingsAssignedHolderUuids = postWorkshopBookingsAssigned.map(function (postWorkshopBookingAssigned) { return postWorkshopBookingAssigned.holderUuid; });
            setBookingWorkshops.forEach(function (setBookingWorkshop) {
                setBookingWorkshop.workshops.forEach(function (workshop) {
                    workshop.holderUuids = _.intersection(workshop.holderUuids, postWorkshopBookingsAssignedHolderUuids);
                });
                setBookingWorkshop.workshops = setBookingWorkshop.workshops.filter(function (workshop) { return !!workshop.holderUuids.length; });
            });
            var setBookingWorkshopsWithAssignedHolderUuids = setBookingWorkshops.filter(function (setBookingWorkshop) { return !!setBookingWorkshop.workshops.length; });
            return {
                setBookingWorkshops: setBookingWorkshopsWithAssignedHolderUuids,
                workshopAvailableSeats: workshopAvailableSeats
            };
        }));
    };
    BookingService.prototype.setContingentReservations$ = function (createContingentReservationsMappers) {
        var _this = this;
        return combineLatest([this.store.pipe(select(getSelectedExhibition)), this.store.pipe(select(getOrderUuid))]).pipe(first(function (combinedLatest) { return combinedLatest.every(function (combinedItem) { return !!combinedItem; }); }), map(function (_a) {
            var selectedExhibition = _a[0], orderUuid = _a[1];
            return {
                selectedExhibition: selectedExhibition,
                orderUuid: orderUuid,
                reservations: _this.mapCreateContingentMappersToCreateContingentTariffReservations(createContingentReservationsMappers)
            };
        }));
    };
    BookingService.prototype.releaseAllContingentReservations$ = function () {
        var _this = this;
        return this.store.pipe(select(getOrderUuid), first(function (orderUuid) { return !!orderUuid; }), switchMap(function (orderUuid) { return _this.postReleaseContingentReservations$(orderUuid).pipe(map(function () { return orderUuid; })); }));
    };
    BookingService.prototype.setParkingReservation$ = function (parkingReservationDate) {
        var _this = this;
        return this.store.pipe(select(getSelectedExhibitionId)).pipe(first(function (eventId) { return !!eventId; }), map(function (eventId) { return _this.mapParkingReservationDatesToGetParkingTariffsFee(eventId, parkingReservationDate); }));
    };
    BookingService.prototype.removeProductBookings$ = function (bookingProductTypes) {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            return !!eventId && !!orderUuid;
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            _this.removeProductBookings(bookingProductTypes);
            return _this.mapBookingProductTypeToPostProductBooking(bookingProductTypes, eventId, orderUuid, {});
        }));
    };
    BookingService.prototype.removeAllProductBookings$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getBookedProducts))
        ]).pipe(first(function (_a) {
            var eventId = _a[0], orderUuid = _a[1];
            return !!eventId && !!orderUuid;
        }), map(function (_a) {
            var eventId = _a[0], orderUuid = _a[1], bookedProductTypes = _a[2];
            _this.removeProductBookings(bookedProductTypes);
            return {
                removeBookingProductTypes: bookedProductTypes,
                removePostProductBookings: _this.mapBookingProductTypeToPostProductBooking(bookedProductTypes, eventId, orderUuid, {})
            };
        }));
    };
    BookingService.prototype.postProductBookings$ = function (postProductBookings) {
        if (!postProductBookings.length) {
            return of([]);
        }
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/ticket-booking", postProductBookings);
    };
    BookingService.prototype.postWorkshopBooking$ = function (postWorkshopBooking) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/workshop-booking", postWorkshopBooking);
    };
    BookingService.prototype.postReleaseWorkshopBookings$ = function (orderUuid) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/workshop-booking/" + orderUuid + "/release", {});
    };
    BookingService.prototype.postCreateContingentReservations$ = function (createContingentReservations) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/day-booking", createContingentReservations);
    };
    BookingService.prototype.postChangeTariffContingentDay$ = function (tariffId, day) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + tariffId + "/change-day-booking", { day: day });
    };
    BookingService.prototype.postReleaseContingentReservations$ = function (uuid) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/day-booking/" + uuid + "/release", {});
    };
    BookingService.prototype.getParkingTariffFee$ = function (parkingTariffReservationFee) {
        var eventId = parkingTariffReservationFee.eventId, ticketTypeId = parkingTariffReservationFee.ticketTypeId, ticketPersonTypeId = parkingTariffReservationFee.ticketPersonTypeId, since = parkingTariffReservationFee.since, until = parkingTariffReservationFee.until;
        var params = new HttpParams();
        params = params.append('since', since);
        params = params.append('until', until);
        return this.http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/ticket-groups/" + ticketTypeId + "/ticket-persons/" + ticketPersonTypeId + "/parking-fee", { params: params, observe: 'response' })
            .pipe(map(function (response) { return response; }));
    };
    BookingService.prototype.getSetProductBookingsHolderUuids = function (bookingProductTypes) {
        var holderUuids = [];
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (bookingTariff) {
                    holderUuids.push.apply(holderUuids, bookingTariff.holderUuids);
                });
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (bookingPackageTariff) {
                            holderUuids.push.apply(holderUuids, bookingPackageTariff.holderUuids);
                        });
                    }
                });
            }
        });
        return holderUuids;
    };
    BookingService.prototype.removeBookingReservationIndexesFromSortedBookingReservations = function (removeContingentReservations, sortedProductBookingContingentReservations) {
        removeContingentReservations.forEach(function (removeContingentReservation) {
            var ticketTypeId = removeContingentReservation.ticketTypeId, ticketPersonId = removeContingentReservation.ticketPersonId, packageNumber = removeContingentReservation.packageNumber, packageIndex = removeContingentReservation.packageIndex, removeBookingReservationIndexes = removeContingentReservation.removeBookingReservationIndexes;
            var bookedContingentReservation = sortedProductBookingContingentReservations.find(function (bookingContingentReservation) {
                return bookingContingentReservation.ticketTypeId === ticketTypeId &&
                    bookingContingentReservation.ticketPersonId === ticketPersonId &&
                    bookingContingentReservation.packageNumber === packageNumber &&
                    bookingContingentReservation.packageIndex === packageIndex;
            });
            if (bookedContingentReservation) {
                removeBookingReservationIndexes
                    .reverse()
                    .forEach(function (bookingReservationIndex) {
                    return bookedContingentReservation.contingentReservations.splice(bookingReservationIndex, 1);
                });
            }
        });
    };
    BookingService.prototype.filterPackagesFromProductBookings = function (bookingProductTypes) {
        var bookingPackageProductTypes = [];
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypePackage(bookingProductType)) {
                bookingPackageProductTypes.push(bookingProductType);
            }
        });
        return bookingPackageProductTypes;
    };
    BookingService.prototype.isPackageProductAddedInProductBookingList = function (bookingProductTypes) {
        return bookingProductTypes.some(function (bookingProductType) {
            if (isBookingProductTypePackage(bookingProductType)) {
                return bookingProductType.productTypes.some(function (bookingPackage) {
                    if (isBookingProductTypeTariff(bookingPackage)) {
                        return bookingPackage.tariffs.some(function (bookingPackageTariff) { return !!bookingPackageTariff.count; });
                    }
                });
            }
        });
    };
    BookingService.prototype.createEmptyBookingPackageTypeFromPackageNumberAndIndexes = function (packageNumber, emptyPackageIndexes) {
        var generatedEmptyBookingPackageType = [];
        emptyPackageIndexes.forEach(function (packageIndex) {
            var emptyBookingPackageType = {
                productType: ProductType.Package,
                packageNumber: packageNumber,
                packageIndex: packageIndex,
                productTypeName: '',
                productTypes: []
            };
            generatedEmptyBookingPackageType.push(emptyBookingPackageType);
        });
        return generatedEmptyBookingPackageType;
    };
    BookingService.prototype.mapBookingProductTypeToPostProductBooking = function (bookingProductTypes, eventId, orderUuid, sharedBookedPersonIdTariffsCount, isPackageAdded) {
        var _this = this;
        if (isPackageAdded === void 0) { isPackageAdded = false; }
        var postProductBookings = [];
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (_a) {
                    var ticketTypeId = _a.ticketTypeId, ticketPersonTypeId = _a.ticketPersonTypeId, ticketPersonId = _a.ticketPersonId, voucherCode = _a.voucherCode, count = _a.count;
                    var currentBookedPersonIdTariffsCount = sharedBookedPersonIdTariffsCount[ticketPersonId] || 0;
                    var postProductBooking = {
                        eventId: eventId,
                        ticketPersonId: ticketPersonId,
                        voucherCode: voucherCode,
                        uuid: orderUuid,
                        groupId: ticketTypeId,
                        ticketTypeId: ticketPersonTypeId,
                        count: currentBookedPersonIdTariffsCount + count
                    };
                    _this.setPostProductBooking(postProductBookings, postProductBooking, count);
                });
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (_a) {
                            var ticketTypeId = _a.ticketTypeId, ticketPersonTypeId = _a.ticketPersonTypeId, ticketPersonId = _a.ticketPersonId, voucherCode = _a.voucherCode, count = _a.count;
                            if (isPackageAdded && !count) {
                                return;
                            }
                            var currentBookedPersonIdTariffsCount = sharedBookedPersonIdTariffsCount[ticketPersonId] || 0;
                            var postProductBooking = {
                                eventId: eventId,
                                uuid: orderUuid,
                                groupId: ticketTypeId,
                                ticketTypeId: ticketPersonTypeId,
                                ticketPersonId: ticketPersonId,
                                voucherCode: voucherCode,
                                count: currentBookedPersonIdTariffsCount + count
                            };
                            _this.setPostProductBooking(postProductBookings, postProductBooking, count);
                        });
                    }
                });
            }
        });
        return postProductBookings;
    };
    BookingService.prototype.mapPostProductBookingsResponseToBookingProductTypes = function (bookingProductTypes, postProductBookingsResponse) {
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (bookingTariff) {
                    var responseTariff = postProductBookingsResponse.find(function (postProductBookingResponse) { return postProductBookingResponse.ticketPersonId === bookingTariff.ticketPersonId; });
                    if (responseTariff) {
                        bookingTariff.availableTickets = responseTariff.availableTickets;
                        bookingTariff.ticketLimit = responseTariff.sellLimit;
                    }
                });
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (bookingPackageTariff) {
                            var responseTariff = postProductBookingsResponse.find(function (postProductBookingResponse) {
                                return postProductBookingResponse.ticketPersonId === bookingPackageTariff.ticketPersonId;
                            });
                            if (!!responseTariff) {
                                bookingPackageTariff.availableTickets = responseTariff.availableTickets;
                                bookingPackageTariff.ticketLimit = responseTariff.sellLimit;
                            }
                        });
                    }
                });
            }
        });
        return bookingProductTypes;
    };
    BookingService.prototype.mapPostProductBookingsResponseToBookingPackageTypes = function (bookingPackageTypes, postProductBookingsResponse) {
        bookingPackageTypes.forEach(function (bookingPackageType) {
            if (isBookingProductTypePackage(bookingPackageType)) {
                bookingPackageType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (bookingPackageTariff) {
                            var responseTariff = postProductBookingsResponse.find(function (postProductBookingResponse) {
                                return postProductBookingResponse.ticketPersonId === bookingPackageTariff.ticketPersonId;
                            });
                            if (responseTariff) {
                                bookingPackageTariff.availableTickets = responseTariff.availableTickets;
                                bookingPackageTariff.ticketLimit = responseTariff.sellLimit;
                            }
                        });
                    }
                });
            }
        });
        return bookingPackageTypes;
    };
    BookingService.prototype.mapTariffToBookingTariff = function (tariff, previousCount, updatedCount, newPackageIndex) {
        var id = tariff.id, name = tariff.name, price = tariff.price, requiresLegitimation = tariff.requiresLegitimation, allowedWorkshops = tariff.allowedWorkshops, allowedWorkshopsFull = tariff.allowedWorkshopsFull, availableTickets = tariff.availableTickets, ticketLimit = tariff.ticketLimit, ticketTypeName = tariff.ticketTypeName, ticketPersonId = tariff.ticketPersonId, ticketPersonTypeId = tariff.ticketPersonTypeId, ticketTypeId = tariff.ticketTypeId, tariffNumber = tariff.tariffNumber, releasedInMinutes = tariff.releasedInMinutes, voucherCode = tariff.voucherCode, voucherType = tariff.voucherType, activatedTimestamp = tariff.activatedTimestamp, sponsors = tariff.sponsors, info = tariff.info, infoExpanded = tariff.infoExpanded, classification = tariff.classification, isVisible = tariff.isVisible, isVoucher = tariff.isVoucher, parking = tariff.parking, hasDaySellLimit = tariff.hasDaySellLimit, days = tariff.days, workshopsByDay = tariff.workshopsByDay, durationInDays = tariff.durationInDays, personalizationType = tariff.personalizationType, validFrom = tariff.validFrom, validTill = tariff.validTill, shouldCalendarBeDisplayed = tariff.shouldCalendarBeDisplayed, daysOffset = tariff.daysOffset, preferredTicket = tariff.preferredTicket, packageSettings = tariff.packageSettings, packageNumber = tariff.packageNumber, packageIndex = tariff.packageIndex;
        // Delete this holder assignment when holders will be assigned in holder reducer
        var newlyAddedTariffsCount = updatedCount - previousCount;
        var holderUuids = this.setHolderUuids(newlyAddedTariffsCount, tariff.classification);
        var contingentReservations = this.setBookingContingentReservations(newlyAddedTariffsCount, hasDaySellLimit, holderUuids);
        var isTariffClassificationParking = classification === TariffClassification.Parking;
        var parkingReservations = this.setBookingParkingReservations(newlyAddedTariffsCount, isTariffClassificationParking);
        var bookingTariffPrice = this.setBookingTariffPrice(price, isTariffClassificationParking);
        var bookingTariff = {
            ticketTypeId: ticketTypeId,
            ticketPersonId: ticketPersonId,
            tariffNumber: tariffNumber,
            ticketTypeName: ticketTypeName,
            ticketPersonTypeId: ticketPersonTypeId,
            packageNumber: packageNumber,
            packageIndex: newPackageIndex || packageIndex,
            ticketName: name,
            availableTickets: availableTickets,
            ticketLimit: ticketLimit,
            voucherCode: voucherCode,
            voucherType: voucherType,
            holderUuids: holderUuids,
            price: bookingTariffPrice,
            isAnonymous: personalizationType === TariffPersonalizationType.Anonymous,
            count: updatedCount,
            workshops: [],
            contingentReservations: contingentReservations,
            parkingReservations: parkingReservations
        };
        return bookingTariff;
    };
    BookingService.prototype.mapTariffTypeToBookingTariffType = function (tariffType, tariff, previousCount, updatedCount) {
        var bookingTariff = this.mapTariffToBookingTariff(tariff, previousCount, updatedCount);
        var bookingTariffType = {
            productType: ProductType.Tariff,
            productTypeName: tariffType.ticketTypeName,
            ticketTypeId: tariffType.ticketTypeId,
            tariffs: [bookingTariff]
        };
        return bookingTariffType;
    };
    BookingService.prototype.mapPackageTypeTariffToBookingPackageTypeTariff = function (packageType, tariffType, tariff, previousCount, updatedCount) {
        var bookingPackageTariffType = this.mapTariffTypeToBookingTariffType(tariffType, tariff, previousCount, updatedCount);
        var bookingPackageType = {
            productType: ProductType.Package,
            productTypeName: packageType.info,
            packageNumber: tariff.packageNumber,
            packageIndex: tariff.packageIndex,
            productTypes: [bookingPackageTariffType]
        };
        return bookingPackageType;
    };
    BookingService.prototype.mapRedeemedVoucherToBookingTariffType = function (redeemedVoucher, voucherTariff, validatedVoucherCount) {
        var voucherCode = redeemedVoucher.voucherCode, voucherType = redeemedVoucher.voucherType, quantity = redeemedVoucher.quantity, ticketCount = redeemedVoucher.ticketCount, isAnonymous = redeemedVoucher.isAnonymous, availableTickets = redeemedVoucher.availableTickets, ticketLimit = redeemedVoucher.ticketLimit;
        var name = voucherTariff.name, price = voucherTariff.price, ticketPersonId = voucherTariff.ticketPersonId, ticketPersonTypeId = voucherTariff.ticketPersonTypeId, ticketTypeId = voucherTariff.ticketTypeId, ticketTypeName = voucherTariff.ticketTypeName, hasDaySellLimit = voucherTariff.hasDaySellLimit;
        var holderUuids = this.setHolderUuids(ticketCount, TariffClassification.Normal);
        var contingentReservations = this.setBookingContingentReservations(ticketCount, hasDaySellLimit, holderUuids);
        var bookingTariff = {
            ticketTypeId: ticketTypeId,
            ticketPersonId: ticketPersonId,
            tariffNumber: null,
            ticketTypeName: ticketTypeName,
            ticketPersonTypeId: ticketPersonTypeId,
            packageNumber: null,
            packageIndex: null,
            ticketName: name,
            availableTickets: availableTickets,
            ticketLimit: ticketLimit,
            voucherCode: voucherCode,
            voucherType: voucherType,
            voucherCountLimit: quantity,
            activatedTimestamp: Date.now(),
            holderUuids: holderUuids,
            price: price,
            count: validatedVoucherCount,
            isAnonymous: isAnonymous,
            workshops: [],
            parkingReservations: [],
            contingentReservations: contingentReservations
        };
        var bookingTariffType = {
            productType: ProductType.Tariff,
            productTypeName: ticketTypeName,
            ticketTypeId: redeemedVoucher.groupId,
            tariffs: [bookingTariff]
        };
        return bookingTariffType;
    };
    BookingService.prototype.mapWorkshopTariffHoldersToSetBookingWorkshops = function (setWorkshopTariffHolders, workshopProductList) {
        var setBookingWorkshops = [];
        setWorkshopTariffHolders.forEach(function (workshopTariffHolder) {
            var ticketTypeId = workshopTariffHolder.ticketTypeId, ticketPersonId = workshopTariffHolder.ticketPersonId, voucherCode = workshopTariffHolder.voucherCode, packageNumber = workshopTariffHolder.packageNumber, packageIndex = workshopTariffHolder.packageIndex, workshopId = workshopTariffHolder.workshopId, holderUuid = workshopTariffHolder.holderUuid;
            var productBooking = setBookingWorkshops.find(function (setProductBooking) {
                return setProductBooking.ticketTypeId === ticketTypeId &&
                    setProductBooking.ticketPersonId === ticketPersonId &&
                    setProductBooking.voucherCode === voucherCode &&
                    setProductBooking.packageNumber === packageNumber &&
                    setProductBooking.packageIndex === packageIndex;
            });
            if (productBooking) {
                var productBookingWorkshop = productBooking.workshops.find(function (bookingWorkshop) { return bookingWorkshop.id === workshopId; });
                if (productBookingWorkshop) {
                    productBookingWorkshop.holderUuids.push(holderUuid);
                }
            }
            else {
                var _a = workshopProductList.find(function (workshopProduct) { return workshopProduct.workshopId === workshopId; }), workshopName = _a.workshopName, price = _a.price;
                var bookingWorkshop = {
                    id: workshopId,
                    name: workshopName,
                    price: price,
                    holderUuids: [holderUuid]
                };
                var setBookingWorkshop = {
                    productType: getProductType(workshopTariffHolder),
                    ticketTypeId: ticketTypeId,
                    ticketPersonId: ticketPersonId,
                    voucherCode: voucherCode,
                    packageNumber: packageNumber,
                    packageIndex: packageIndex,
                    workshops: [bookingWorkshop]
                };
                setBookingWorkshops.push(setBookingWorkshop);
            }
        });
        return setBookingWorkshops;
    };
    BookingService.prototype.mapWorkshopTariffHoldersToRemoveBookingWorkshops = function (removeWorkshopTariffHolders) {
        var removeBookingWorkshops = [];
        removeWorkshopTariffHolders.forEach(function (workshopTariffHolder) {
            var ticketTypeId = workshopTariffHolder.ticketTypeId, ticketPersonId = workshopTariffHolder.ticketPersonId, voucherCode = workshopTariffHolder.voucherCode, packageNumber = workshopTariffHolder.packageNumber, packageIndex = workshopTariffHolder.packageIndex, workshopId = workshopTariffHolder.workshopId, holderUuid = workshopTariffHolder.holderUuid;
            var createdRemoveBookingWorkshop = removeBookingWorkshops.find(function (removeBookingWorkshop) {
                return removeBookingWorkshop.ticketTypeId === ticketTypeId &&
                    removeBookingWorkshop.ticketPersonId === ticketPersonId &&
                    removeBookingWorkshop.voucherCode === voucherCode &&
                    removeBookingWorkshop.packageNumber === packageNumber &&
                    removeBookingWorkshop.packageIndex === packageIndex;
            });
            if (createdRemoveBookingWorkshop) {
                var createdRemoveBookingWorkshopHolderUuids = createdRemoveBookingWorkshop.removeWorkshopsAssignedHolders.find(function (removeBookingWorkshop) { return removeBookingWorkshop.workshopId === workshopId; });
                if (createdRemoveBookingWorkshopHolderUuids) {
                    createdRemoveBookingWorkshopHolderUuids.removeHolderUuids.push(holderUuid);
                }
                else {
                    var removeBookingWorkshopAssignedHolder = {
                        workshopId: workshopId,
                        removeHolderUuids: [holderUuid]
                    };
                    createdRemoveBookingWorkshop.removeWorkshopsAssignedHolders.push(removeBookingWorkshopAssignedHolder);
                }
            }
            else {
                var removeBookedWorkshopAssignedHolders = {
                    workshopId: workshopId,
                    removeHolderUuids: [holderUuid]
                };
                var removeBookingWorkshop = {
                    productType: getProductType(workshopTariffHolder),
                    ticketTypeId: ticketTypeId,
                    ticketPersonId: ticketPersonId,
                    voucherCode: voucherCode,
                    packageNumber: packageNumber,
                    packageIndex: packageIndex,
                    removeWorkshopsAssignedHolders: [removeBookedWorkshopAssignedHolders]
                };
                removeBookingWorkshops.push(removeBookingWorkshop);
            }
        });
        return removeBookingWorkshops;
    };
    BookingService.prototype.mapSetBookingWorkshopsToPostWorkshopBookings = function (setBookingWorkshops, eventId, orderUuid) {
        var postWorkshopBookings = [];
        setBookingWorkshops.forEach(function (setBookingWorkshop) {
            setBookingWorkshop.workshops.forEach(function (bookingWorkshop) {
                bookingWorkshop.holderUuids.forEach(function (holderUuid) {
                    var postWorkshopBooking = {
                        eventId: eventId,
                        uuid: orderUuid,
                        workshopId: bookingWorkshop.id,
                        seats: 1,
                        holderUuid: holderUuid
                    };
                    postWorkshopBookings.push(postWorkshopBooking);
                });
            });
        });
        return postWorkshopBookings;
    };
    BookingService.prototype.mapRemoveBookingWorkshopsToPostWorkshopBookings = function (removeBookingWorkshops, eventId, orderUuid) {
        var removeWorkshopSeatsByWorkshopId = {};
        var postWorkshopBookings = [];
        removeBookingWorkshops.forEach(function (removeBookingWorkshop) {
            removeBookingWorkshop.removeWorkshopsAssignedHolders.forEach(function (removeWorkshopAssignedHolders) {
                removeWorkshopAssignedHolders.removeHolderUuids.forEach(function () {
                    if (!removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId]) {
                        removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId] = 0;
                    }
                    removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId]--;
                });
            });
        });
        for (var workshopId in removeWorkshopSeatsByWorkshopId) {
            var postWorkshopBooking = {
                eventId: eventId,
                uuid: orderUuid,
                workshopId: +workshopId,
                seats: removeWorkshopSeatsByWorkshopId[workshopId]
            };
            postWorkshopBookings.push(postWorkshopBooking);
        }
        return postWorkshopBookings;
    };
    BookingService.prototype.mapSetContingentDateToSetProductBookingContingentReservations = function (contingentReservationDates, sortedProductBookingContingentReservations) {
        contingentReservationDates.forEach(function (contingentReservationDate) {
            var sortedContingentReservationIndex = sortedProductBookingContingentReservations.findIndex(function (sortedContingentReservation) {
                return sortedContingentReservation.ticketTypeId === contingentReservationDate.ticketTypeId &&
                    sortedContingentReservation.ticketPersonId === contingentReservationDate.ticketPersonId &&
                    sortedContingentReservation.voucherCode === contingentReservationDate.voucherCode &&
                    sortedContingentReservation.packageNumber === contingentReservationDate.packageNumber &&
                    sortedContingentReservation.packageIndex === contingentReservationDate.packageIndex;
            });
            if (sortedContingentReservationIndex !== INDEX_NOT_FOUND) {
                var sortedProductBookingContingentReservation = sortedProductBookingContingentReservations[sortedContingentReservationIndex];
                sortedProductBookingContingentReservation.contingentReservations[contingentReservationDate.bookingReservationIndex].fromDate = contingentReservationDate.day;
            }
        });
    };
    BookingService.prototype.mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers = function (sortedProductBookingContingentReservations) {
        var createContingentReservationsMappers = [];
        sortedProductBookingContingentReservations.forEach(function (sortedContingentReservation) {
            return sortedContingentReservation.contingentReservations.forEach(function (contingentReservation, bookingReservationIndex) {
                if (contingentReservation.fromDate) {
                    var productType = sortedContingentReservation.productType, ticketTypeId = sortedContingentReservation.ticketTypeId, ticketPersonId = sortedContingentReservation.ticketPersonId, ticketPersonTypeId = sortedContingentReservation.ticketPersonTypeId, voucherCode = sortedContingentReservation.voucherCode, packageNumber = sortedContingentReservation.packageNumber, packageIndex = sortedContingentReservation.packageIndex;
                    var holderUuid = contingentReservation.holderUuid, duration = contingentReservation.duration, fromDate = contingentReservation.fromDate, toDate = contingentReservation.toDate, isValid = contingentReservation.isValid;
                    var uniqueId = packageNumber
                        ? ticketTypeId + "_" + ticketPersonTypeId + "_" + packageNumber + "_" + packageIndex
                        : ticketTypeId + "_" + ticketPersonTypeId;
                    var createContingentReservationMapper = {
                        productType: productType,
                        holderUuid: holderUuid,
                        duration: duration,
                        fromDate: fromDate,
                        toDate: toDate,
                        isValid: isValid,
                        bookingReservationIndex: bookingReservationIndex,
                        ticketIndex: createContingentReservationsMappers.length,
                        ticketTypeId: ticketTypeId,
                        ticketPersonId: ticketPersonId,
                        ticketPersonTypeId: ticketPersonTypeId,
                        voucherCode: voucherCode,
                        packageNumber: packageNumber,
                        packageIndex: packageIndex,
                        uniqueId: uniqueId
                    };
                    createContingentReservationsMappers.push(createContingentReservationMapper);
                }
            });
        });
        return createContingentReservationsMappers;
    };
    BookingService.prototype.mapCreateContingentMappersToCreateContingentTariffReservations = function (createContingentTariffToBookingContingentReservationsMappers) {
        var createContingentTariffReservations = [];
        createContingentTariffToBookingContingentReservationsMappers.forEach(function (contingentReservationMapper) {
            return createContingentTariffReservations.push({
                day: DateTime.fromJSDate(contingentReservationMapper.fromDate).toISODate(),
                ticketPersonId: contingentReservationMapper.ticketPersonId,
                ticketIndex: contingentReservationMapper.ticketIndex,
                uniqueId: contingentReservationMapper.uniqueId
            });
        });
        return createContingentTariffReservations;
    };
    BookingService.prototype.mapCreateContingentReservationResponseToSetProductBookingContingentReservations = function (selectedExhibition, createContingentReservationResponse, createContingentTariffToBookingContingentReservationsMappers) {
        var _this = this;
        var setProductBookingContingentReservations = [];
        createContingentReservationResponse.forEach(function (contingentReservationResponse) {
            var contingentReservationMapper = createContingentTariffToBookingContingentReservationsMappers.find(function (bookingContingentReservationMapper) {
                return bookingContingentReservationMapper.ticketIndex === contingentReservationResponse.ticketIndex;
            });
            if (contingentReservationMapper) {
                var productType = contingentReservationMapper.productType, ticketTypeId_1 = contingentReservationMapper.ticketTypeId, ticketPersonId_1 = contingentReservationMapper.ticketPersonId, ticketPersonTypeId = contingentReservationMapper.ticketPersonTypeId, voucherCode_1 = contingentReservationMapper.voucherCode, packageNumber_1 = contingentReservationMapper.packageNumber, packageIndex_1 = contingentReservationMapper.packageIndex, holderUuid = contingentReservationMapper.holderUuid, fromDate = contingentReservationMapper.fromDate, toDate = contingentReservationMapper.toDate, duration = contingentReservationMapper.duration, bookingReservationIndex = contingentReservationMapper.bookingReservationIndex;
                var selectedExhibitionClone = _.cloneDeep(selectedExhibition);
                var selectedExhibitionEndDate = selectedExhibitionClone.endDate;
                var fromDateTime = DateTime.fromJSDate(fromDate);
                var isMultiDayDuration = duration > 1;
                var validatedDuration = duration;
                var validatedToDate = isMultiDayDuration
                    ? fromDateTime.plus({ days: duration - 1 }).toJSDate()
                    : fromDate;
                var isValidatedToDateGreaterThenToDate = _this.helperService.isFirstDateGreaterWOTime(validatedToDate, toDate);
                if (isValidatedToDateGreaterThenToDate) {
                    validatedToDate = toDate;
                }
                var isValidatedToDateGreaterThenExhibitionEndDate = _this.helperService.isFirstDateGreaterWOTime(validatedToDate, selectedExhibitionEndDate);
                if (isValidatedToDateGreaterThenExhibitionEndDate) {
                    validatedToDate = selectedExhibitionEndDate;
                }
                var areFromAndToDateEqual = _this.helperService.areDatesSameWOTime(fromDate, validatedToDate);
                if (isMultiDayDuration && areFromAndToDateEqual) {
                    validatedDuration = 1;
                }
                var setBookingContingentReservation = {
                    holderUuid: holderUuid,
                    fromDate: fromDate,
                    toDate: validatedToDate,
                    duration: validatedDuration,
                    isValid: contingentReservationResponse.isValid,
                    bookingReservationIndex: bookingReservationIndex
                };
                var alreadyCreatedSetProductBookingContingentReservation = setProductBookingContingentReservations.find(function (setBookingContingentReservation) {
                    return setBookingContingentReservation.ticketTypeId === ticketTypeId_1 &&
                        setBookingContingentReservation.ticketPersonId === ticketPersonId_1 &&
                        setBookingContingentReservation.voucherCode === voucherCode_1 &&
                        setBookingContingentReservation.packageNumber === packageNumber_1 &&
                        setBookingContingentReservation.packageIndex === packageIndex_1;
                });
                if (alreadyCreatedSetProductBookingContingentReservation) {
                    alreadyCreatedSetProductBookingContingentReservation.contingentReservations.push(setBookingContingentReservation);
                }
                else {
                    setProductBookingContingentReservations.push({
                        productType: productType,
                        ticketTypeId: ticketTypeId_1,
                        ticketPersonId: ticketPersonId_1,
                        ticketPersonTypeId: ticketPersonTypeId,
                        voucherCode: voucherCode_1,
                        packageNumber: packageNumber_1,
                        packageIndex: packageIndex_1,
                        contingentReservations: [setBookingContingentReservation]
                    });
                }
            }
        });
        return setProductBookingContingentReservations;
    };
    BookingService.prototype.mapRemoveContingentReservationsToRemoveProductBookingContingentReservation = function (removeContingentReservations, createContingentTariffToBookingContingentReservationsMappers) {
        var removeProductBookingContingentReservations = [];
        removeContingentReservations.forEach(function (removeContingentReservation) {
            var ticketTypeId = removeContingentReservation.ticketTypeId, ticketPersonId = removeContingentReservation.ticketPersonId, voucherCode = removeContingentReservation.voucherCode, packageNumber = removeContingentReservation.packageNumber, packageIndex = removeContingentReservation.packageIndex;
            var removeProductBookingMapper = createContingentTariffToBookingContingentReservationsMappers.find(function (bookingContingentReservationMapper) {
                return bookingContingentReservationMapper.ticketTypeId === ticketTypeId &&
                    bookingContingentReservationMapper.ticketPersonId === ticketPersonId &&
                    bookingContingentReservationMapper.voucherCode === voucherCode &&
                    bookingContingentReservationMapper.packageNumber === packageNumber &&
                    bookingContingentReservationMapper.packageIndex === packageIndex;
            });
            if (removeProductBookingMapper) {
                var removeProductBookingContingentReservation = {
                    productType: removeProductBookingMapper.productType,
                    ticketTypeId: ticketTypeId,
                    ticketPersonId: ticketPersonId,
                    voucherCode: voucherCode,
                    packageNumber: packageNumber,
                    packageIndex: packageIndex,
                    removeBookingReservationIndexes: removeContingentReservation.removeBookingReservationIndexes
                };
                removeProductBookingContingentReservations.push(removeProductBookingContingentReservation);
            }
        });
        return removeProductBookingContingentReservations;
    };
    BookingService.prototype.mapRemovedBookingPackageTypesToRemoveContingentReservations = function (removedBookingPackageTypes) {
        var removeContingentReservations = [];
        removedBookingPackageTypes.forEach(function (removedBookingPackageType) {
            removedBookingPackageType.productTypes.forEach(function (removedBookingPackageProductType) {
                if (isBookingProductTypeTariff(removedBookingPackageProductType)) {
                    removedBookingPackageProductType.tariffs.forEach(function (removedBookingPackageTariff) {
                        var ticketTypeId = removedBookingPackageTariff.ticketTypeId, ticketPersonId = removedBookingPackageTariff.ticketPersonId, voucherCode = removedBookingPackageTariff.voucherCode, packageNumber = removedBookingPackageTariff.packageNumber, packageIndex = removedBookingPackageTariff.packageIndex, contingentReservations = removedBookingPackageTariff.contingentReservations;
                        var contingentReservationIndexesToRemove = [];
                        contingentReservations.forEach(function (contingentReservation, contingentReservationIndex) {
                            if (contingentReservation.fromDate) {
                                contingentReservationIndexesToRemove.push(contingentReservationIndex);
                            }
                        });
                        var removeContingentReservation = {
                            ticketTypeId: ticketTypeId,
                            ticketPersonId: ticketPersonId,
                            voucherCode: voucherCode,
                            packageNumber: packageNumber,
                            packageIndex: packageIndex,
                            removeBookingReservationIndexes: contingentReservationIndexesToRemove
                        };
                        if (contingentReservationIndexesToRemove.length) {
                            removeContingentReservations.push(removeContingentReservation);
                        }
                    });
                }
            });
        });
        return removeContingentReservations;
    };
    BookingService.prototype.mapParkingReservationDatesToGetParkingTariffsFee = function (eventId, parkingReservationDate) {
        var ticketTypeId = parkingReservationDate.ticketTypeId, ticketPersonTypeId = parkingReservationDate.ticketPersonTypeId, since = parkingReservationDate.since, until = parkingReservationDate.until;
        return {
            eventId: eventId,
            ticketTypeId: ticketTypeId,
            ticketPersonTypeId: ticketPersonTypeId,
            since: this.helperService.toStringWithoutOffset(since),
            until: this.helperService.toStringWithoutOffset(until)
        };
    };
    BookingService.prototype.mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation = function (parkingReservationDate, parkingReservationPrice, isParkingReservationValid) {
        var productType = parkingReservationDate.productType, ticketTypeId = parkingReservationDate.ticketTypeId, ticketPersonId = parkingReservationDate.ticketPersonId, voucherCode = parkingReservationDate.voucherCode, packageNumber = parkingReservationDate.packageNumber, packageIndex = parkingReservationDate.packageIndex, bookingReservationIndex = parkingReservationDate.bookingReservationIndex;
        return {
            productType: productType,
            ticketTypeId: ticketTypeId,
            ticketPersonId: ticketPersonId,
            voucherCode: voucherCode,
            packageNumber: packageNumber,
            packageIndex: packageIndex,
            bookingReservationIndex: bookingReservationIndex,
            price: parkingReservationPrice,
            isValid: isParkingReservationValid
        };
    };
    /**
     * Maps booking product types to voucher countdowns
     * @param bookingProductTypes Booking product types from payload
     * @param tariffReleaseInMinutes In how many seconds does tariff release
     * @returns Array of voucher countdowns
     */
    BookingService.prototype.mapBookingProductTypesToVoucherCountdowns = function (bookingProductTypes, tariffReleaseInMinutes) {
        var anonymousBookingProductTypes = bookingProductTypes.filter(function (bookingProductType) {
            return isBookingProductTypeTariff(bookingProductType) &&
                bookingProductType.tariffs.some(function (bookingTariff) { return bookingTariff.isAnonymous; });
        });
        var vouchersCountdown = [];
        var filteredBookingProductTypes = anonymousBookingProductTypes.length
            ? anonymousBookingProductTypes
            : bookingProductTypes;
        filteredBookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (bookingTariff) {
                    var voucherCode = bookingTariff.voucherCode;
                    var expiryTimestamp = getCountdownTimestamp(tariffReleaseInMinutes, bookingTariff.activatedTimestamp);
                    var isAnonymous = bookingTariff.isAnonymous;
                    var voucherCountdown = { voucherCode: voucherCode, expiryTimestamp: expiryTimestamp, isAnonymous: isAnonymous };
                    vouchersCountdown.push(voucherCountdown);
                });
            }
        });
        return vouchersCountdown;
    };
    BookingService.prototype.setHolderUuids = function (tariffCount, classification) {
        return classification !== TariffClassification.Parking && tariffCount > 0
            ? Array(tariffCount)
                .fill(null)
                .map(getUUID)
            : [];
    };
    BookingService.prototype.setBookingContingentReservations = function (tariffCount, hasDaySellLimit, tariffHolders) {
        return hasDaySellLimit && tariffCount > 0
            ? Array.from(Array(tariffCount), function (_, index) { return ({
                holderUuid: tariffHolders[index],
                duration: null,
                isValid: null,
                fromDate: null,
                toDate: null
            }); })
            : [];
    };
    BookingService.prototype.setBookingParkingReservations = function (tariffCount, isTariffClassificationParking) {
        return isTariffClassificationParking && tariffCount > 0
            ? Array.from(Array(tariffCount), function () { return ({
                isValid: false,
                since: null,
                until: null,
                price: 0
            }); })
            : [];
    };
    BookingService.prototype.setBookingTariffPrice = function (price, isTariffClassificationParking) {
        return isTariffClassificationParking ? 0 : price;
    };
    BookingService.prototype.setPostProductBooking = function (postProductBookings, postProductBookingToAdd, count) {
        var postProductBookingIndex = postProductBookings.findIndex(function (existingPostProductBooking) { return existingPostProductBooking.ticketPersonId === postProductBookingToAdd.ticketPersonId; });
        if (postProductBookingIndex === INDEX_NOT_FOUND) {
            postProductBookings.push(postProductBookingToAdd);
        }
        else {
            postProductBookings[postProductBookingIndex].count += count;
        }
    };
    BookingService.prototype.removeProductBookings = function (bookingProductTypes) {
        bookingProductTypes.forEach(function (bookedProductType) {
            if (isBookingProductTypeTariff(bookedProductType)) {
                bookedProductType.tariffs.forEach(function (bookedTariff) {
                    bookedTariff.count = 0;
                    bookedTariff.holderUuids = [];
                });
            }
            else if (isBookingProductTypePackage(bookedProductType)) {
                bookedProductType.productTypes.forEach(function (packageProductType) {
                    if (isBookingProductTypeTariff(packageProductType)) {
                        packageProductType.tariffs.forEach(function (packageTariff) {
                            packageTariff.count = 0;
                            packageTariff.holderUuids = [];
                        });
                    }
                });
            }
        });
    };
    BookingService.ngInjectableDef = i0.defineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i3.HelperService)); }, token: BookingService, providedIn: "root" });
    return BookingService;
}());
export { BookingService };
