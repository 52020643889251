import { createSelector } from '@ngrx/store';
import { WorkshopProduct, WorkshopProductList } from '@products/models/workshop.model';
import { getProductWorkshopState } from '@store/products/product.selectors';

/**
 * Get workshop products list
 * @description Get workshop products list
 * @returns Workshop product or empty array
 */
export const getWorkshopProductList = createSelector(
  getProductWorkshopState,
  (productWorkshopState): WorkshopProductList => productWorkshopState.list
);

/**
 * Get workshop product
 * @description Get workshop product provided by workshop id
 * @param workshopId
 * @returns Workshop product or null
 */
export const getWorkshopProductByWorkshopId = (workshopId: number) =>
  createSelector(
    getWorkshopProductList,
    (workshopProductList: WorkshopProductList): WorkshopProduct =>
      workshopProductList.find(workshopProduct => workshopProduct.workshopId === workshopId) || null
  );
