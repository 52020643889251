import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ADDITIONAL_SERVICES_DEBOUNCE_TIME } from '@shared/tokens';
import {
  AddLegitimationToList,
  GetLegitimationFaxId,
  GetLegitimationList,
  GetLegitimationStatus,
  ActionTypes as LegitimationActionTypes,
  PostLegitimationInfo,
  SetLegitimationFaxId,
  SetLegitimationInfoResponse,
  SetLegitimationStatus
} from '@store/legitimation/legitimation.actions';
import {
  LegitimationFaxInfoModel,
  LegitimationListModel,
  LegitimationStatus
} from '@store/legitimation/legitimation.interface';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LegitimationEffect {
  @Effect()
  loadLegitimationList$: Observable<Action> = this.actions$.pipe(
    ofType<GetLegitimationList>(LegitimationActionTypes.GET_LEGITIMATION_LIST),
    debounceTime(this.legitimationDebounceTime),
    switchMap(() => {
      return this.legitimationService.getLegitimationList().pipe(
        map((legitimationList: LegitimationListModel[]) => new AddLegitimationToList(legitimationList)),
        catchError(() => of(new AddLegitimationToList([])))
      );
    })
  );

  @Effect()
  loadLegitimationStatus$: Observable<Action> = this.actions$.pipe(
    ofType<GetLegitimationStatus>(LegitimationActionTypes.GET_LEGITIMATION_STATUS),
    debounceTime(this.legitimationDebounceTime),
    switchMap((data: any) => {
      const { userId, eventId } = data.payload;
      if (eventId === '' || userId === '') {
        return EMPTY;
      }

      return this.legitimationService.getLegitimationStatus(eventId).pipe(
        map(({ status }) => new SetLegitimationStatus(status)),
        catchError(() => of(new SetLegitimationStatus(LegitimationStatus.OtherStatus)))
      );
    })
  );

  @Effect()
  loadLegitimationFaxId$: Observable<Action> = this.actions$.pipe(
    ofType<GetLegitimationFaxId>(LegitimationActionTypes.GET_LEGITIMATION_FAX_ID),
    debounceTime(this.legitimationDebounceTime),
    switchMap((data: GetLegitimationFaxId) => {
      const { eventId, userId } = data.payload;
      if (eventId === null) {
        return EMPTY;
      }

      return this.legitimationService.getLegitimationFaxId(eventId, userId).pipe(
        map((legitimationFaxInfo: LegitimationFaxInfoModel) => new SetLegitimationFaxId(legitimationFaxInfo.faxId)),
        catchError(() => of(new SetLegitimationFaxId(null)))
      );
    })
  );

  @Effect()
  postLegitimationInfo$: Observable<Action> = this.actions$.pipe(
    ofType<PostLegitimationInfo>(LegitimationActionTypes.POST_LEGITIMATION_INFO),
    debounceTime(this.legitimationDebounceTime),
    switchMap((data: PostLegitimationInfo) => {
      if (!data.payload) {
        return EMPTY;
      }

      const { feed, userId, eventId } = data.payload;

      return this.legitimationService.saveLegitimationInfo(feed, userId, eventId).pipe(
        map(() => new SetLegitimationInfoResponse(true)),
        catchError(() => of(new SetLegitimationInfoResponse(false)))
      );
    })
  );

  constructor(
    @Optional() @Inject(ADDITIONAL_SERVICES_DEBOUNCE_TIME) private legitimationDebounceTime: number,
    private actions$: Actions,
    private legitimationService: LegitimationService
  ) {}
}
