import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { getBuyerActiveBillingAddressId, getBuyerVisitorCheckbox, getExhibitionSettings, getLanguage, getOrderUuid, getOrderedStepsValidityArray, getPaymentMethod, getProfile, getSelectedExhibitionId, getSelectedStep, getShoppingStartTime, getSteps, getStepsValidity, isDifferenBillingAddressUsed, isTicketHolderVisible } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { HolderService } from '@products/services/holder.service';
import { WorkshopService } from '@products/services/workshop.service';
import { TicketSendingOptions } from '@root/src/app/_pages/products/models/tariff.model';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetIsAnonymousProductSentToAPI } from '@store/products/booking/booking.actions';
import { getTicketHolderQuestionnaireInputs } from '@store/products/holder/holder.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import { SetSelectedStep, SetStepOrder, SetStepsVisibility, sendOrder } from '@store/step-forms/steps-forms.actions';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngrx/store";
import * as i4 from "../helpers/helper.service";
import * as i5 from "../../forms/forms.service";
import * as i6 from "../../error-handling/error-handling.service";
import * as i7 from "../legitimation/legitimation.service";
import * as i8 from "../../../_pages/products/services/workshop.service";
import * as i9 from "../../../_pages/products/services/holder.service";
var StepsFormsService = /** @class */ (function () {
    function StepsFormsService(router, http, store, helperService, formsService, errorHandlingService, legitimationService, workshopService, holderService) {
        this.router = router;
        this.http = http;
        this.store = store;
        this.helperService = helperService;
        this.formsService = formsService;
        this.errorHandlingService = errorHandlingService;
        this.legitimationService = legitimationService;
        this.workshopService = workshopService;
        this.holderService = holderService;
        this.isLegitimationRequired$ = new BehaviorSubject(null);
        this.destroy$ = new Subject();
        // react on need for legitimation and hide/show the legitimation step
        this.setLegitimationStepVisibility();
        this.setLegitimationStepOrder();
        // react on need for workshops and hide/show the workshop step
        this.setWorkshopStepVisibility();
    }
    StepsFormsService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    StepsFormsService.prototype.setLegitimationStepVisibility = function () {
        var _this = this;
        this.legitimationService
            .isLegitimationRequiredForBookedProducts$()
            .pipe(tap(function (isLegitimationRequired) { return _this.isLegitimationRequired$.next(isLegitimationRequired); }), takeUntil(this.destroy$))
            .subscribe(function (isLegitimationRequired) {
            var visibilityPayload = {
                stepKey: 'legitimation',
                visible: isLegitimationRequired
            };
            _this.store.dispatch(new SetStepsVisibility([visibilityPayload]));
        });
    };
    StepsFormsService.prototype.setLegitimationStepOrder = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.legitimationService.isTariffWithRequiredLegitimationBooked$()
        ])
            .pipe(filter(function (_a) {
            var exhibitionSettings = _a[0];
            return !!exhibitionSettings;
        }), takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var exhibitionSettings = _a[0], isTariffWithRequiredLegitimationBooked = _a[1];
            _this.workshopsOnTicketSelection = exhibitionSettings.workshopsOnTicketSelection;
            var orderPayload = {
                stepKey: 'legitimation',
                newOrder: exhibitionSettings.goToLegitimationForNewAccount && !isTariffWithRequiredLegitimationBooked ? 1 : 3
            };
            _this.store.dispatch(new SetStepOrder(orderPayload));
        });
    };
    StepsFormsService.prototype.setWorkshopStepVisibility = function () {
        var _this = this;
        this.workshopService
            .getBookedProductSelectionWorkshopTariffs$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (bookedProductSelectionWorkshopTariffs) {
            var visibilityPayload = {
                stepKey: 'workshop',
                visible: _this.workshopsOnTicketSelection ? false : !!bookedProductSelectionWorkshopTariffs.length
            };
            if (!_this.helperService.isSelfregistration()) {
                // as a fast solution we hide workshops on self registration (later this should come from event settings)
                _this.store.dispatch(new SetStepsVisibility([visibilityPayload]));
            }
        });
    };
    StepsFormsService.prototype.sendFinalOrder = function (orderData) {
        var selfReg = '';
        if (this.helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        //clear all previously received errors from the API (so we don't interpret errors that occured earlier in the shopping process):
        this.errorHandlingService.clearAllErrors();
        return this.http.post(
        //`${environment.apiUrl}/buyer-questionnaire/${eventId}`,
        "" + environment.protocol + environment.webApiUrl + "/order/save-order" + selfReg, orderData, { observe: 'response' });
    };
    StepsFormsService.prototype.getBuyerQuestionnaire = function (eventId, args) {
        var selfReg = '';
        if (this.helperService.isSelfregistration()) {
            selfReg = '&sr=true';
        }
        var ticketPersonIds = args.reduce(function (acc, curr) {
            return acc + ("&ticketPersonIds=" + curr);
        }, '');
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/buyer-questionnaire?" + selfReg + ticketPersonIds);
    };
    StepsFormsService.prototype.navigateRelativeTo = function (x, router) {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getStepsValidity)),
            this.store.pipe(select(getSelectedStep)),
            this.store.pipe(select(getSelectedExhibitionId))
        ])
            .pipe(filter(function (_a) {
            var validations = _a[0], activeStepKey = _a[1], selectedEventId = _a[2];
            return !!validations && !!activeStepKey && selectedEventId !== null;
        }), first())
            .subscribe(function (_a) {
            var validations = _a[0], activeStepKey = _a[1], selectedEventId = _a[2];
            var visibleSteps = Object.keys(validations)
                // make sure steps are ordered correctly
                .sort(function (a, b) {
                return validations[a].order - validations[b].order;
            })
                // only navigate to visible routes
                .filter(function (stepKey) {
                return validations[stepKey].visible;
            });
            var indexOfStep = visibleSteps.indexOf(activeStepKey);
            if ((x < 0 && indexOfStep > 0) || (x > 0 && indexOfStep < visibleSteps.length - 1)) {
                _this.checkAndRedirectToNextStep(validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey);
            }
        });
    };
    StepsFormsService.prototype.checkAndRedirectToNextStep = function (validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey) {
        var urlBase = this.helperService.isSelfregistration() ? 'self-registration' : 'webshop';
        var navigateTo = visibleSteps[indexOfStep + x];
        if (navigateTo === 'payment') {
            console.log('%c currentStep ', 'background: #5AAC56; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + activeStepKey);
            console.log(x === -1 ? '%c "back button" URL ' : '%c "continue button" URL ', 'background: #D46A6A; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + navigateTo);
        }
        if (!validations[navigateTo].showInStepNavigation) {
            var ticketStep = visibleSteps.find(function (step) { return step === 'tickets'; });
            navigateTo = validations[ticketStep].showInStepNavigation
                ? ticketStep
                : validations[visibleSteps[0]].showInStepNavigation
                    ? visibleSteps[0]
                    : 'tickets';
            console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + navigateTo);
        }
        this.store.dispatch(new SetSelectedStep(navigateTo));
        router.navigate(["/" + urlBase + "/" + selectedEventId + "/" + navigateTo]);
    };
    StepsFormsService.prototype.navigateToLastNotDisabledPage = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getOrderedStepsValidityArray)),
            this.store.pipe(select(getSelectedExhibitionId))
        ])
            .first()
            .subscribe(function (data) {
            var orderedStepsValidityArray = data[0], eventId = data[1];
            var lastNotDisabledPage = orderedStepsValidityArray
                .slice(0)
                .reverse()
                .find(function (step) {
                return step.value.disabled === false && step.value.visible && step.value && step.value.showInStepNavigation;
            });
            _this.router.navigate(["/webshop/" + eventId + "/" + lastNotDisabledPage.key]);
        });
    };
    //scroll to top
    StepsFormsService.prototype.scrollToTop = function () {
        window.scroll(0, 0);
    };
    StepsFormsService.prototype.removeAttributes = function (obj, attributes) {
        var clearedObject = Object.assign({}, obj);
        attributes.forEach(function (attr) {
            if (clearedObject.hasOwnProperty(attr)) {
                delete clearedObject[attr];
            }
        });
        return clearedObject;
    };
    StepsFormsService.prototype.renameAttributes = function (obj, attributes) {
        var renamedObject = Object.assign({}, obj);
        attributes.forEach(function (attr) {
            if (renamedObject.hasOwnProperty(attr.from)) {
                renamedObject[attr.to] = renamedObject[attr.from];
                delete renamedObject[attr.from];
            }
        });
        return renamedObject;
    };
    StepsFormsService.prototype.separateAddress = function (obj) {
        var clearedObject = Object.assign({}, obj);
        var toSeparate = ['company', 'street', 'city', 'zipCode', 'country'];
        clearedObject.address = {};
        toSeparate.forEach(function (key) {
            if (clearedObject.hasOwnProperty(key)) {
                clearedObject.address[key] = clearedObject[key];
            }
            else {
                // TODO once BE can accept only data which are really present delete else statement
                clearedObject.address[key] = '';
            }
        });
        clearedObject = this.removeAttributes(clearedObject, toSeparate);
        return clearedObject;
    };
    // TODO: This needs to be refactored sometime because of nested subscription
    StepsFormsService.prototype.prepareDataForSaveAndSend = function (bookedProducts, totalCost, ticketHolderInputSets, anonymousTicket) {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getPaymentMethod)),
            this.store.pipe(select(isTicketHolderVisible)),
            this.store.pipe(select(getOrderUuid)),
            this.store.pipe(select(getShoppingStartTime))
        ])
            .pipe(filter(function (data) {
            var settings = data[0], paymentMethod = data[1];
            return anonymousTicket ? !!data : !!settings && paymentMethod !== null;
        }), first())
            .subscribe(function (_a) {
            var _ = _a[0], paymentMethod = _a[1], isTicketHolderVisible = _a[2], uuid = _a[3], shoppingStartTime = _a[4];
            combineLatest([
                _this.store.pipe(select(getSteps)),
                _this.store.pipe(select(getProfile)),
                _this.store.pipe(select(getSelectedExhibitionId)),
                _this.store.pipe(select(getBuyerActiveBillingAddressId)),
                _this.store.pipe(select(getLanguage)),
                _this.store.pipe(select(getSelectedSendingOption)),
                _this.store.pipe(select(getBuyerVisitorCheckbox)),
                _this.store.pipe(select(getTicketHolderQuestionnaireInputs))
            ])
                .pipe(filter(function (data) {
                var steps = data[0], profile = data[1], eventId = data[2];
                return !!steps && !!eventId;
            }), first())
                .subscribe(function (_a) {
                var steps = _a[0], profile = _a[1], eventId = _a[2], billingaddressId = _a[3], language = _a[4], selectedSendingOption = _a[5], buyerVisitorCheckbox = _a[6], ticketHolderQuestionnaireInputs = _a[7];
                var dataToSave = {
                    paymentProvider: paymentMethod,
                    userAgent: _this.helperService.browserInfo(),
                    shoppingStartTime: shoppingStartTime,
                    shoppingEndTime: new Date(),
                    order: {
                        userId: profile ? profile.id : null
                    },
                    day: new Date() // xgebi Date should be chosen same as amano parking
                };
                dataToSave.order['eventId'] = eventId;
                dataToSave.order['uuid'] = uuid;
                dataToSave.order['parkingTickets'] = [];
                for (var stepKey in steps) {
                    var forms = steps[stepKey] ? steps[stepKey].forms : null;
                    if (forms) {
                        for (var formKey in forms) {
                            var form = forms[formKey];
                            if (form && form.list) {
                                // we need to ensure that we get values of all inputs, even those which are disabled
                                form.list.forEach(function (input) {
                                    input.disabled = false;
                                });
                                var formObj = _this.formsService.toFormGroup(form.list, [formKey]);
                                // if it is not a ticket holder (ticket holder is comming together with tickets) add to to dataToSave
                                if (formObj && !(formKey.substring(0, 13) === 'ticketHolder_')) {
                                    switch (formKey) {
                                        // transform data from buyer questionnaire
                                        case 'questionnaire': {
                                            dataToSave.order.questionnaireFields = _this.helperService.processQuestionnaireValuesBeforeSave(form.list);
                                            break;
                                        }
                                        case 'billingaddress': {
                                            dataToSave.order.billingAddress = _this.helperService.processFormValuesBeforeSave(formObj.value);
                                            break;
                                        }
                                        case 'buyerinfo': {
                                            var buyer = _this.helperService.processFormValuesBeforeSave(formObj.value);
                                            var privacyForm = forms['privacy'];
                                            // set privacy policy value
                                            if (privacyForm) {
                                                var privacyPolicyBase = privacyForm.list.find(function (inputBase) { return inputBase.key === 'disclaimer'; });
                                                var privacyPolicyOption = privacyPolicyBase &&
                                                    privacyPolicyBase.options.find(function (option) { return option.key === 'disclaimerConfirmation'; });
                                                if (privacyPolicyOption) {
                                                    buyer['PrivacyPolicyAccepted'] = privacyPolicyOption.value;
                                                }
                                                var privacyPolicyOptionalBase = privacyForm.list.find(function (inputBase) { return inputBase.key === 'disclaimerOptional'; });
                                                var privacyPolicyOptionalOption = privacyPolicyOptionalBase &&
                                                    privacyPolicyOptionalBase.options.find(function (option) { return option.key === 'disclaimerOptionalConfirmation'; });
                                                if (privacyPolicyOptionalOption) {
                                                    buyer['PrivacyPolicyOptionalAccepted'] = privacyPolicyOptionalOption.value;
                                                }
                                            }
                                            buyer = _this.removeAttributes(buyer, [
                                                'password',
                                                'verifyPassword',
                                                'CreateAccount',
                                                'DifferentBillingAddress',
                                                'verifyEmail',
                                                'createAccountButton',
                                                'address'
                                            ]);
                                            buyer = _this.renameAttributes(buyer, [
                                                {
                                                    from: 'FairCatalogue',
                                                    to: 'isFairCatalogChecked'
                                                },
                                                { from: 'Newsletter', to: 'isNewsletterChecked' }
                                            ]);
                                            buyer = _this.separateAddress(buyer);
                                            dataToSave.order['buyer'] = buyer;
                                            break;
                                        }
                                        // privacy policy (value processed in buyer)
                                        case 'privacy':
                                            break;
                                        // terms and conditions
                                        case 'checkboxes':
                                            var termsBase = form.list.find(function (term) { return term.key === 'terms'; });
                                            var tradeConditionOption = termsBase && termsBase.options.find(function (option) { return option.key === 'tradeConditions'; });
                                            if (tradeConditionOption) {
                                                dataToSave.order['TermsAndConditionsAccepted'] = tradeConditionOption.value;
                                            }
                                            break;
                                        // transform any other data
                                        default: {
                                            dataToSave.order[formKey] = _this.helperService.processFormValuesBeforeSave(formObj.value);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // check if billing address is needed at all
                _this.store
                    .pipe(select(isDifferenBillingAddressUsed), first())
                    .subscribe(function (isDifferentBillingAddressUsed) {
                    if (!isDifferentBillingAddressUsed) {
                        delete dataToSave.order.billingAddress;
                    }
                });
                var ticketsWithHolders = [];
                bookedProducts.forEach(function (bookedProduct) {
                    if (isBookingProductTypeTariff(bookedProduct)) {
                        _this.addBookedTariffsAndParkingReservationsToSaveOrder(bookedProduct, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, dataToSave);
                    }
                    else if (isBookingProductTypePackage(bookedProduct)) {
                        bookedProduct.productTypes.forEach(function (packageProductType) {
                            if (isBookingProductTypeTariff(packageProductType)) {
                                _this.addBookedTariffsAndParkingReservationsToSaveOrder(packageProductType, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, dataToSave);
                            }
                        });
                    }
                });
                dataToSave.order.tickets = ticketsWithHolders;
                dataToSave.order.orderPrice = +totalCost.toFixed(2);
                dataToSave.order.orderTicketSendingMode = selectedSendingOption;
                dataToSave.order.userLanguage = language;
                /* remove unwanted properties */
                if (dataToSave.order.hasOwnProperty('billingAddress')) {
                    delete dataToSave.order.billingAddress.address;
                    // in case buyer used billing address from profile of logged in user add it to billing address
                    if (billingaddressId !== null) {
                        dataToSave.order.billingAddress.id = billingaddressId;
                    }
                }
                if (dataToSave.order.hasOwnProperty('buyerinfo')) {
                    delete dataToSave.order['buyerinfo']['address'];
                    delete dataToSave.order['buyerinfo']['createAccountButton'];
                }
                if (anonymousTicket) {
                    dataToSave.order.buyer.isNewsletterChecked = false;
                    dataToSave.paymentProvider = 'FREE';
                    _this.store.dispatch(new SetIsAnonymousProductSentToAPI(true));
                }
                _this.store.dispatch(new sendOrder(dataToSave));
                console.log(JSON.stringify(dataToSave)
                    .replace(/“([^“]*?)“:/g, '$1:')
                    .replace(/“/g, "'"));
            });
        });
    };
    StepsFormsService.prototype.addBookedTariffsToSaveOrder = function (bookedTariff, ticketHolderInputSets, buyerVisitorCheckbox, clearedTicketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, buyerInfoEmail) {
        var _this = this;
        var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonTypeId = bookedTariff.ticketPersonTypeId, ticketPersonId = bookedTariff.ticketPersonId, voucherCode = bookedTariff.voucherCode, packageNumber = bookedTariff.packageNumber, packageIndex = bookedTariff.packageIndex, holderUuids = bookedTariff.holderUuids, contingentReservations = bookedTariff.contingentReservations, workshops = bookedTariff.workshops;
        holderUuids.forEach(function (holderUuid) {
            var ticketHolder = _this.holderService.getHolderDataForSaveOrder(ticketHolderInputSets, holderUuid, buyerInfoEmail);
            var sendingOption = ticketHolder.sendingOption;
            if (ticketHolder) {
                if (buyerVisitorCheckbox && buyerVisitorCheckbox.buyerVisitorCheckedSlideIndex === ticketHolder.ticketIndex) {
                    ticketHolder.isBuyerAVisitor = buyerVisitorCheckbox.isBuyerVisitorChecked;
                    ticketHolder.visitorQuestionnaire = _this.helperService.processQuestionnaireValuesBeforeSave(ticketHolderQuestionnaireInputs);
                }
                var tickerToOwner = ticketHolder.sendtoowner;
                ticketHolder = _this.removeAttributes(ticketHolder, ['ticketIndex', 'address', 'sendtoowner', 'sendingOption']);
                ticketHolder = _this.separateAddress(ticketHolder);
                if (sendingOption === TicketSendingOptions.MobilePerOwner && !tickerToOwner) {
                    sendingOption = TicketSendingOptions.MobilePerBuyer;
                }
                if (sendingOption === TicketSendingOptions.TicketRetrievalLink && !tickerToOwner) {
                    sendingOption = TicketSendingOptions.TicketRetrievalLinkBuyer;
                }
                var contingentReservation = contingentReservations.find(function (contingentReservation) { return contingentReservation.holderUuid === holderUuid; });
                var workshopIds_1 = [];
                workshops.forEach(function (_a) {
                    var id = _a.id, holderUuids = _a.holderUuids;
                    var bookedWorkshopTariff = holderUuids.some(function (bookedWorkshopHolderUuid) { return bookedWorkshopHolderUuid === holderUuid; });
                    if (bookedWorkshopTariff) {
                        workshopIds_1.push(id);
                    }
                });
                var orderTicket = {
                    groupId: ticketTypeId,
                    ticketTypeId: ticketPersonTypeId,
                    ticketPersonId: ticketPersonId,
                    ticketToOwner: tickerToOwner,
                    ticketSendingMode: sendingOption,
                    ticketHolder: isTicketHolderVisible ? ticketHolder : null,
                    asMobileTicket: sendingOption === TicketSendingOptions.MobilePerOwner ||
                        sendingOption === TicketSendingOptions.MobilePerBuyer,
                    workshops: workshopIds_1,
                    day: contingentReservation ? DateTime.fromJSDate(contingentReservation.fromDate).toISODate() : null
                };
                if (packageNumber) {
                    orderTicket.packageNumber = packageNumber;
                    orderTicket.shoppingCartPkgIndex = packageIndex;
                }
                if (voucherCode) {
                    orderTicket.voucherCode = voucherCode;
                }
                clearedTicketsWithHolders.push(orderTicket);
            }
        });
    };
    StepsFormsService.prototype.addBookedTariffsAndParkingReservationsToSaveOrder = function (bookedTariffType, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, dataToSave) {
        var _this = this;
        bookedTariffType.tariffs.forEach(function (bookedTariff) {
            var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonTypeId = bookedTariff.ticketPersonTypeId;
            bookedTariff.parkingReservations.forEach(function (parking) {
                var since = parking.since, until = parking.until;
                dataToSave.order.parkingTickets.push({
                    groupId: ticketTypeId,
                    ticketTypeId: ticketPersonTypeId,
                    since: _this.helperService.toStringWithoutOffset(since),
                    until: _this.helperService.toStringWithoutOffset(until)
                });
            });
            _this.addBookedTariffsToSaveOrder(bookedTariff, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, dataToSave.order.buyer.email);
        });
    };
    StepsFormsService.ngInjectableDef = i0.defineInjectable({ factory: function StepsFormsService_Factory() { return new StepsFormsService(i0.inject(i1.Router), i0.inject(i2.HttpClient), i0.inject(i3.Store), i0.inject(i4.HelperService), i0.inject(i5.FormsService), i0.inject(i6.ErrorHandlingService), i0.inject(i7.LegitimationService), i0.inject(i8.WorkshopService), i0.inject(i9.HolderService)); }, token: StepsFormsService, providedIn: "root" });
    return StepsFormsService;
}());
export { StepsFormsService };
