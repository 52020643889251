/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/skeleton-loader/skeleton-loader.component.ngfactory";
import * as i3 from "../../../../shared/skeleton-loader/skeleton-loader.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/directives/rich-text/rich-text.directive";
import * as i7 from "../product/product.component.ngfactory";
import * as i8 from "../product/product.component";
import * as i9 from "../../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i10 from "./product-group.component";
var styles_ProductGroupComponent = [i0.styles];
var RenderType_ProductGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductGroupComponent, data: {} });
export { RenderType_ProductGroupComponent as RenderType_ProductGroupComponent };
function View_ProductGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skeleton-loader", [], null, null, null, i2.View_SkeletonLoaderComponent_0, i2.RenderType_SkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkeletonLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ProductGroupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [["class", "section-header-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.productGroup.groupName)))); _ck(_v, 1, 0, currVal_0); }); }
function View_ProductGroupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/defs.svg#icon-arrow-down"]], null, null, null, null, null))], null, null); }
function View_ProductGroupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/defs.svg#icon-arrow-right2"]], null, null, null, null, null))], null, null); }
function View_ProductGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "section-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "section-collapsed": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "section-header-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "section-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_5)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_6)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "section-header"; var currVal_1 = _ck(_v, 2, 0, (_co.productGroup.groupName && !_co.isExpanded)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.productSelectionViewModel.isTranslationLoaded; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.productSelectionViewModel.isTranslationLoaded; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.isExpanded; _ck(_v, 10, 0, currVal_4); var currVal_5 = !_co.isExpanded; _ck(_v, 12, 0, currVal_5); }, null); }
function View_ProductGroupComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skeleton-loader", [], null, null, null, i2.View_SkeletonLoaderComponent_0, i2.RenderType_SkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkeletonLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ProductGroupComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i6.RichTextDirective, [i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.productGroup.groupDescription)))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProductGroupComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "section-description"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_8)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_9)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.productSelectionViewModel.isTranslationLoaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.productSelectionViewModel.isTranslationLoaded; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProductGroupComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-product", [], null, null, null, i7.View_ProductComponent_0, i7.RenderType_ProductComponent)), i1.ɵdid(2, 114688, null, 0, i8.ProductComponent, [], { selectedExhibition: [0, "selectedExhibition"], exhibitionSettings: [1, "exhibitionSettings"], productSelectionViewModel: [2, "productSelectionViewModel"], productsStatusViewModel: [3, "productsStatusViewModel"], productGroup: [4, "productGroup"], product: [5, "product"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedExhibition; var currVal_1 = _co.exhibitionSettings; var currVal_2 = _co.productSelectionViewModel; var currVal_3 = _co.productsStatusViewModel; var currVal_4 = _co.productGroup; var currVal_5 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ProductGroupComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_12)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.productType === _co.ProductType.Tariff) && _v.context.$implicit.tariffType.isVisible) || (_v.context.$implicit.productType === _co.ProductType.Package)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProductGroupComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_11)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productGroup.products; var currVal_1 = _co.productGroupProductsTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProductGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProductGroupComponent_7)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_10)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productGroup.groupName; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.productGroup.groupDescription)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.productGroup.groupName || _co.isExpanded); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ProductGroupComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-product", [], null, null, null, i7.View_ProductComponent_0, i7.RenderType_ProductComponent)), i1.ɵdid(2, 114688, null, 0, i8.ProductComponent, [], { selectedExhibition: [0, "selectedExhibition"], exhibitionSettings: [1, "exhibitionSettings"], productSelectionViewModel: [2, "productSelectionViewModel"], productsStatusViewModel: [3, "productsStatusViewModel"], productGroup: [4, "productGroup"], product: [5, "product"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedExhibition; var currVal_1 = _co.exhibitionSettings; var currVal_2 = _co.productSelectionViewModel; var currVal_3 = _co.productsStatusViewModel; var currVal_4 = _co.productGroup; var currVal_5 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ProductGroupComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_15)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.productType === _co.ProductType.Tariff) && _v.context.$implicit.tariffType.isVisible); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProductGroupComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_14)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productGroup.products; var currVal_1 = _co.productGroupProductsTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProductGroupComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i9.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductGroupComponent_13)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.productSelectionViewModel.isSelfRegistration; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.productSelectionViewModel.isSelfRegistration; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProductGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-group", [], null, null, null, View_ProductGroupComponent_0, RenderType_ProductGroupComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProductGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductGroupComponentNgFactory = i1.ɵccf("app-product-group", i10.ProductGroupComponent, View_ProductGroupComponent_Host_0, { selectedExhibition: "selectedExhibition", exhibitionSettings: "exhibitionSettings", productSelectionViewModel: "productSelectionViewModel", productsStatusViewModel: "productsStatusViewModel", productGroup: "productGroup" }, {}, []);
export { ProductGroupComponentNgFactory as ProductGroupComponentNgFactory };
