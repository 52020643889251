var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ActivatedRoute } from '@angular/router';
import { getOrderUuid } from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from '@products/services/booking.service';
import { LoadProductService } from '@products/services/load-product.service';
import { PreferredTariffValidationService } from '@products/services/preferred-tariff-validation.service';
import { VoucherService } from '@products/services/voucher.service';
import { ActionTypes as ProductSelectionActionTypes, SetInitialProductSelectionList, SetPreferredProductSelectionList } from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes, RemoveReleasedVoucher, SetPreferredTariffBooking, SetRedeemedAndReleaseVoucher } from '@store/products/product.actions';
import { SetInitialWorkshopProductList } from '@store/products/workshop/workshop.actions';
import { EMPTY, Observable, concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
var ProductEffect = /** @class */ (function () {
    function ProductEffect(actions$, store, route, loadProductService, voucherService, translateService, statusBarService, bookingService, preferredTariffValidationService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.route = route;
        this.loadProductService = loadProductService;
        this.voucherService = voucherService;
        this.translateService = translateService;
        this.statusBarService = statusBarService;
        this.bookingService = bookingService;
        this.preferredTariffValidationService = preferredTariffValidationService;
        this.getProducts$ = this.actions$.pipe(ofType(ProductActionTypes.GET_PRODUCTS), switchMap(function (_a) {
            var payload = _a.payload;
            var eventId = payload.eventId, preferredTicketGroupNr = payload.preferredTicketGroupNr, preferredTicketPersonNr = payload.preferredTicketPersonNr;
            if (!eventId) {
                return EMPTY;
            }
            return _this.loadProductService.getProducts$(eventId, preferredTicketGroupNr, preferredTicketPersonNr).pipe(map(function (productsResponse) {
                var products = _this.loadProductService.mapGetProductResponse(productsResponse);
                return new SetInitialProductSelectionList(products);
            }), catchError(function () { return of(new SetInitialProductSelectionList({})); }));
        }));
        this.getPreferredProducts$ = this.actions$.pipe(ofType(ProductActionTypes.GET_PREFERRED_PRODUCTS), switchMap(function (_a) {
            var payload = _a.payload;
            var eventId = payload.eventId, preferredTicketGroupNr = payload.preferredTicketGroupNr, preferredTicketPersonNr = payload.preferredTicketPersonNr;
            if (!eventId) {
                return EMPTY;
            }
            return _this.loadProductService.getProducts$(eventId, preferredTicketGroupNr, preferredTicketPersonNr).pipe(map(function (productsResponse) {
                var products = _this.loadProductService.mapGetProductResponse(productsResponse);
                return new SetPreferredProductSelectionList(products);
            }), catchError(function () { return of(new SetPreferredProductSelectionList({})); }));
        }));
        this.getWorkshopProducts$ = this.actions$.pipe(ofType(ProductActionTypes.GET_WORKSHOP_PRODUCTS), switchMap(function (_a) {
            var payload = _a.payload;
            var eventId = payload;
            if (!eventId) {
                return EMPTY;
            }
            return _this.loadProductService.getWorkshopProducts$(eventId).pipe(map(function (workshopProductsResponse) { return new SetInitialWorkshopProductList(workshopProductsResponse); }), catchError(function () { return of(new SetInitialWorkshopProductList([])); }));
        }));
        this.setPreferredProduct$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST), switchMap(function () {
            var _a = _this.route.snapshot.queryParams, tt = _a.tt, pt = _a.pt, amt = _a.amt;
            if (!tt) {
                return EMPTY;
            }
            return _this.preferredTariffValidationService.validatePreferredTariffCount$(+tt, +pt, +amt).pipe(map(function (_a) {
                var ticketTypeId = _a.ticketTypeId, ticketPersonTypeId = _a.ticketPersonTypeId, validatedCount = _a.validatedCount;
                return new SetPreferredTariffBooking({
                    ticketTypeId: ticketTypeId,
                    ticketPersonTypeId: ticketPersonTypeId,
                    count: validatedCount
                });
            }));
        }));
        this.redeemVoucher$ = this.actions$.pipe(ofType(ProductActionTypes.REDEEM_AND_RELEASE_VOUCHER), switchMap(function (_a) {
            var payload = _a.payload;
            var redeemVoucher = payload.redeemVoucher, releaseVouchers = payload.releaseVouchers, removeProductBookings = payload.removeProductBookings;
            if (!redeemVoucher) {
                return EMPTY;
            }
            return _this.voucherService.redeemVoucher$(redeemVoucher).pipe(switchMap(function (redeemVoucherResponse) {
                if (redeemVoucherResponse.allowedWorkshopsFull) {
                    return _this.translateService
                        .get('voucher.workshops.soldout')
                        .map(function (voucherWorkshopSoldOutTranslation) { return ({
                        redeemVoucherResponse: redeemVoucherResponse,
                        voucherWorkshopSoldOutTranslation: voucherWorkshopSoldOutTranslation
                    }); });
                }
                return of({ redeemVoucherResponse: redeemVoucherResponse, voucherWorkshopSoldOutTranslation: '' });
            }), map(function (_a) {
                var redeemVoucherResponse = _a.redeemVoucherResponse, voucherWorkshopSoldOutTranslation = _a.voucherWorkshopSoldOutTranslation;
                if (redeemVoucherResponse.allowedWorkshopsFull) {
                    _this.statusBarService.setStatus(voucherWorkshopSoldOutTranslation, 'error');
                }
                var isAnonymous = redeemVoucher.isAnonymous;
                var redeemedVoucher = !redeemVoucherResponse.allowedWorkshopsFull
                    ? __assign({}, redeemVoucherResponse, { isAnonymous: isAnonymous }) : null;
                return new SetRedeemedAndReleaseVoucher({
                    redeemedVoucher: redeemedVoucher,
                    releaseVouchers: releaseVouchers,
                    removeProductBookings: removeProductBookings
                });
            }), catchError(function () { return of(new SetRedeemedAndReleaseVoucher(null)); }));
        }));
        this.releaseVoucher$ = this.actions$.pipe(ofType(ProductActionTypes.RELEASE_VOUCHER), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            var eventId = payload.eventId, orderUuid = payload.orderUuid, ticketPersonId = payload.ticketPersonId, voucherCode = payload.voucherCode;
            var removeVoucher = { eventId: eventId, orderUuid: orderUuid, ticketPersonId: ticketPersonId, voucherCode: voucherCode };
            return _this.voucherService.releaseVoucher$(payload).pipe(map(function () { return new RemoveReleasedVoucher(removeVoucher); }), catchError(function () { return of(new RemoveReleasedVoucher(null)); }));
        }));
        this.releaseExpiredVoucher$ = this.actions$.pipe(ofType(ProductActionTypes.RELEASE_EXPIRED_VOUCHER), filter(function (_a) {
            var payload = _a.payload;
            return !!payload;
        }), concatMap(function (_a) {
            var payload = _a.payload;
            var removeReleasedVoucherAction$ = of(new RemoveReleasedVoucher(payload));
            var releaseVoucher$ = _this.voucherService.releaseVoucher$(payload).pipe(switchMap(function () { return EMPTY; }), catchError(function () { return EMPTY; }));
            return concat(removeReleasedVoucherAction$, releaseVoucher$);
        }));
        this.releaseAllContingentReservationsAndWorkshopAssignedSeats$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST, ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST, ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER), withLatestFrom(this.store.pipe(select(getOrderUuid))), switchMap(function (_a) {
            var action = _a[0], orderUuid = _a[1];
            if (!action.payload) {
                return EMPTY;
            }
            var isNotAnonymousVoucherRedeemed = action.type === ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER &&
                !action.payload.redeemedVoucher.isAnonymous;
            if (isNotAnonymousVoucherRedeemed) {
                return EMPTY;
            }
            return forkJoin([
                _this.bookingService.postReleaseContingentReservations$(orderUuid),
                _this.bookingService.postReleaseWorkshopBookings$(orderUuid)
            ]).pipe(switchMap(function () { return EMPTY; }), catchError(function () { return EMPTY; }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "getProducts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "getPreferredProducts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "getWorkshopProducts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "setPreferredProduct$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "redeemVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "releaseVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "releaseExpiredVoucher$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], ProductEffect.prototype, "releaseAllContingentReservationsAndWorkshopAssignedSeats$", void 0);
    return ProductEffect;
}());
export { ProductEffect };
