import { FormBuilder } from '@angular/forms';
import { getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { ProductType } from '@products/models/products.model';
import { LoadProductService } from '@products/services/load-product.service';
import { iif, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@ngrx/store";
import * as i3 from "../products/services/load-product.service";
var WidgetAdminService = /** @class */ (function () {
    function WidgetAdminService(fb, store, loadProductService) {
        this.fb = fb;
        this.store = store;
        this.loadProductService = loadProductService;
        this.widgetSelectionTariffTypes = [];
    }
    WidgetAdminService.prototype.processWidgetSettingsForSave = function (settings, saveSettingsProcessor) {
        for (var key in settings) {
            var setting = settings[key];
            if (saveSettingsProcessor[key]) {
                saveSettingsProcessor[key](setting, settings);
            }
        }
    };
    WidgetAdminService.prototype.processWidgetSettingForAdminForm = function (settings, loadSettingsProcessor, form) {
        var processedSettings = {};
        for (var key in settings) {
            var setting = settings[key];
            if (key in form.value) {
                if (loadSettingsProcessor[key]) {
                    loadSettingsProcessor[key](setting, processedSettings, settings);
                }
                else {
                    processedSettings[key] = setting;
                }
            }
        }
        return processedSettings;
    };
    WidgetAdminService.prototype.setTicketsToFormControls = function (widgetSelectionTariffTypes, form) {
        var _this = this;
        var ticketsFormGroup = form.get('tickets');
        widgetSelectionTariffTypes.forEach(function (_a) {
            var ticketTypeId = _a.ticketTypeId, tariffs = _a.tariffs;
            tariffs.forEach(function (_a) {
                var ticketPersonTypeId = _a.ticketPersonTypeId;
                return ticketsFormGroup.addControl(ticketTypeId + "_" + ticketPersonTypeId, _this.fb.control(true));
            });
        });
    };
    WidgetAdminService.prototype.createIframeEmbedCode = function (iframeURI, iframeAttributes) {
        var iframeAttributesString = '';
        for (var attribute in iframeAttributes) {
            var attributeValue = iframeAttributes[attribute];
            iframeAttributesString += " " + attribute + "=\"" + attributeValue + "\"";
        }
        return "<iframe " + iframeAttributesString + " src=\"" + iframeURI + "\"></iframe>";
    };
    WidgetAdminService.prototype.getWidgetSelectionTariffTypes$ = function () {
        var _this = this;
        return this.store.pipe(select(getSelectedExhibitionId)).pipe(first(function (selectedExhibitionId) { return !!selectedExhibitionId; }), switchMap(function (selectedExhibitionId) {
            return iif(function () {
                var isSelectedExhibitionId = _this.selectedExhibitionId === selectedExhibitionId;
                var areWidgetSelectionTariffTypesAlreadySet = !!_this.widgetSelectionTariffTypes.length;
                return isSelectedExhibitionId && areWidgetSelectionTariffTypesAlreadySet;
            }, of(_this.widgetSelectionTariffTypes), _this.loadProductService.getProducts$(selectedExhibitionId).switchMap(function (productsResponse) {
                _this.selectedExhibitionId = selectedExhibitionId;
                _this.widgetSelectionTariffTypes = _this.mapGetProductsResponseToWidgetSelectionTariffTypes(productsResponse);
                return of(_this.widgetSelectionTariffTypes);
            }));
        }));
    };
    WidgetAdminService.prototype.mapGetProductsResponseToWidgetSelectionTariffTypes = function (productsResponse) {
        var widgetSelectionTariffTypes = [];
        var setWidgetSelectionTariffType = function (tariffType) {
            var ticketTypeId = tariffType.ticketTypeId, ticketTypeName = tariffType.ticketTypeName, tickets = tariffType.tickets;
            var widgetSelectionTariffs = [];
            tickets.forEach(function (_a) {
                var ticketName = _a.ticketName, personTypeId = _a.personTypeId;
                return widgetSelectionTariffs.push({ name: ticketName, ticketPersonTypeId: personTypeId });
            });
            widgetSelectionTariffTypes.push({ ticketTypeId: ticketTypeId, ticketTypeName: ticketTypeName, tariffs: widgetSelectionTariffs });
        };
        productsResponse.forEach(function (_a) {
            var items = _a.items;
            items.forEach(function (_a) {
                var itemType = _a.itemType, ticketType = _a.ticketType;
                if (itemType === ProductType.Tariff) {
                    setWidgetSelectionTariffType(ticketType);
                }
            });
        });
        return widgetSelectionTariffTypes;
    };
    WidgetAdminService.ngInjectableDef = i0.defineInjectable({ factory: function WidgetAdminService_Factory() { return new WidgetAdminService(i0.inject(i1.FormBuilder), i0.inject(i2.Store), i0.inject(i3.LoadProductService)); }, token: WidgetAdminService, providedIn: "root" });
    return WidgetAdminService;
}());
export { WidgetAdminService };
