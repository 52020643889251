import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  State,
  getDepartments,
  getExhibitionSettings,
  getOccupationalGroups,
  getProfessions,
  getTicketHolderInputsKeys,
  getTitles
} from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookingProductType,
  BookingTariff,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { HolderUuids, TicketHolderForm } from '@products/models/holder.model';
import { OrderTicketHolder } from '@products/models/order.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { environment } from '@src/environments/environment';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { prepareTicketHolderData } from '@store/customization/forms/tickets-holder-data';
import { SelectOption } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import {
  getAllBookedProductsCount,
  getAllBookedTariffs,
  getAllHolderUuidsFromBookedProducts
} from '@store/products/booking/booking.selectors';
import { SetActiveSlide } from '@store/products/holder/holder.actions';
import { getHolderUuids } from '@store/products/holder/holder.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import { FormInputsPayloadModel } from '@store/step-forms/step.interface';
import {
  RemoveForm,
  RemoveTicketHolderForm,
  SetAddressCheckbox,
  SetBuyerVisitorCheckbox,
  SetInputs,
  SetTicketHolderFormValidity
} from '@store/step-forms/steps-forms.actions';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HolderService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
  private readonly personaliseFormKey = this.PersonaliseFormsKeys.ticketHolder[0];
  private readonly ticketHolder_ = this.PersonaliseFormsKeys.ticketHolder[1];

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private helperService: HelperService,
    private formsService: FormsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /**
   * Adds ticket holders inputs and sets its validity
   * @param holderUuids holder uuids to add inputs to
   */
  addHoldersInputs(holderUuids: HolderUuids) {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getTitles)),
      this.store.pipe(select(getProfessions)),
      this.store.pipe(select(getDepartments)),
      this.store.pipe(select(getOccupationalGroups)),
      this.store.pipe(select(getAllBookedTariffs)),
      this.store.pipe(select(getSelectedSendingOption)),
      this.store.pipe(select(getAllBookedProductsCount))
    ])
      .first(
        ([
          settings,
          titles,
          professions,
          departments,
          occupationalGroups,
          allBookedTariffs,
          selectedTicketSendingOptions,
          allBookedProductsCount
        ]) =>
          !!settings &&
          !!titles &&
          !!professions &&
          !!departments &&
          !!occupationalGroups &&
          !!allBookedTariffs &&
          !!selectedTicketSendingOptions &&
          allBookedProductsCount !== null
      )
      .subscribe(
        ([
          settings,
          titles,
          professions,
          departments,
          occupationalGroups,
          allBookedTariffs,
          selectedTicketSendingOptions,
          allBookedProductsCount
        ]: [
          ExhibitionSettingModel,
          SelectOption[],
          SelectOption[],
          SelectOption[],
          SelectOption[],
          BookingTariff[],
          TicketSendingOptions,
          number
        ]) => {
          // remove holderUuids of parking tickets, we don't add holder inputs for parking tickets
          const filteredHolderUuids: HolderUuids = holderUuids.filter(uuid =>
            allBookedTariffs.some(
              tariff => tariff.holderUuids.find(holderUuid => holderUuid === uuid) && !tariff.parkingReservations.length
            )
          );

          filteredHolderUuids.forEach(holderUuid => {
            const ticketHolderWithUuid = this.ticketHolder_ + holderUuid;
            const formInfo = [this.personaliseFormKey, ticketHolderWithUuid];

            let formValid: boolean = false;
            // new forms to be created (create new ticket holder)
            const list = prepareTicketHolderData(
              settings,
              selectedTicketSendingOptions,
              titles,
              professions,
              departments,
              occupationalGroups
            );

            if (
              this.helperService.isSelfregistration() ||
              !settings.isTicketHolderVisible ||
              (allBookedProductsCount === 1 && selectedTicketSendingOptions === TicketSendingOptions.MobilePerOwner)
            ) {
              formValid = true;
            }

            const anyFieldRequired =
              list.some(input => input.required && !input.hidden && !input.disabled) ||
              selectedTicketSendingOptions === TicketSendingOptions.TicketRetrievalLink;
            formValid = !anyFieldRequired || formValid;

            const holderInputs: FormInputsPayloadModel = {
              formInfo,
              holderUuid,
              inputSet: {
                rerender: false,
                list
              }
            };

            this.store.dispatch(new SetInputs(holderInputs));
            this.store.dispatch(new SetTicketHolderFormValidity({ formInfo, valid: false }));
            this.formsService.setFormValidity(formValid, null, formInfo);
          });
        }
      );
  }

  /**
   * Gets all holderInputsKeys and compares it to input parameter holderUuids to remove inputs and its validations
   * from holderUuids which no longer exist
   * @param holderUuids holders uuids which are not being removed
   */
  removeHoldersInputs(holderUuids: HolderUuids) {
    this.store
      .pipe(
        select(getTicketHolderInputsKeys),
        first()
      )
      .subscribe(ticketHolderInputsKeys => {
        const removedHolderInputsUuids: string[] = ticketHolderInputsKeys
          .map(key => key.replace(this.ticketHolder_, ''))
          .filter(uuid => !holderUuids.includes(uuid));

        removedHolderInputsUuids.forEach(holderUuid => {
          const ticketHolderWithUuid = this.ticketHolder_ + holderUuid;
          const formName = [this.personaliseFormKey, ticketHolderWithUuid];

          // remove the form and its input sets
          this.store.dispatch(new RemoveForm(formName));
          this.store.dispatch(new RemoveTicketHolderForm(formName));
          this.formsService.removeStepValidation(formName);
        });

        const isNonParkingTicketRemoved: boolean = !!removedHolderInputsUuids.length;

        if (isNonParkingTicketRemoved) {
          /* be sure after removing a ticket to start the carousel on position one
          otherwise it can happen that it wants start on slide which no longer exist */
          this.store.dispatch(new SetActiveSlide(0));

          // User Story 3466: When we remove ticket if checked slide index is same as value we are decreasing to we uncheck it
          this.store.dispatch(
            new SetAddressCheckbox({
              checkedSlideIndex: null,
              isAddressCopied: false
            })
          );

          this.store.dispatch(
            new SetBuyerVisitorCheckbox({
              buyerVisitorCheckedSlideIndex: null,
              isBuyerVisitorChecked: false,
              showVisitorQuestionnaire: false
            })
          );

          this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
          this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.visitorQuestionnaire);
        }
      });
  }

  getTariffHolderQuestionnaire$(eventId, ticketPersonIds: number[]) {
    let params: HttpParams = new HttpParams();

    params = params.append('sr', this.helperService.isSelfregistration());
    ticketPersonIds.forEach(ticketPersonId => (params = params.append('ticketPersonIds', `${ticketPersonId}`)));

    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/visitor-questionnaire`, {
      params
    });
  }

  calculateNumberOfHolderUuidsToCreate$(
    bookingProductTypes: BookingProductType[],
    bookedHolderUuids: HolderUuids
  ): Observable<number> {
    let numberOfHolderBookings = 0;

    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(bookedTariff => {
          if (!bookedTariff.parkingReservations.length) {
            numberOfHolderBookings += bookedTariff.count;
          }
        });
      } else if (isBookingProductTypePackage(bookingProductType)) {
        bookingProductType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(bookingPackageTariff => {
              if (!bookingPackageTariff.parkingReservations.length) {
                numberOfHolderBookings += bookingPackageTariff.count;
              }
            });
          }
        });
      }
    });

    const bookingHolderDifference = numberOfHolderBookings - bookedHolderUuids.length;
    const bookingHoldersToCreate = bookingHolderDifference > 0 ? bookingHolderDifference : 0;

    return of(bookingHoldersToCreate);
  }

  /**
   * Adds or removes product inputs
   * @returns Object of booked holder uuids and was product removed
   */
  addOrRemoveProductHoldersInputs$(): Observable<{
    holderUuidsToAddOrRemove: HolderUuids;
    isProductBookingRemoved: boolean;
  }> {
    return combineLatest([
      this.store.pipe(select(getAllHolderUuidsFromBookedProducts)),
      this.store.pipe(select(getHolderUuids))
    ])
      .pipe(first())
      .map(([bookedHolderUuids, holderUuids]) => {
        const isProductBookingRemoved: boolean = bookedHolderUuids.length < holderUuids.length;
        const holderUuidsToAddOrRemove: HolderUuids = isProductBookingRemoved
          ? holderUuids.filter(holderUuid => bookedHolderUuids.includes(holderUuid))
          : bookedHolderUuids.filter(bookedHolderUuid => !holderUuids.includes(bookedHolderUuid));

        isProductBookingRemoved
          ? this.removeHoldersInputs(holderUuidsToAddOrRemove)
          : this.addHoldersInputs(holderUuidsToAddOrRemove);

        return { holderUuidsToAddOrRemove, isProductBookingRemoved };
      });
  }

  getHolderDataForSaveOrder(
    ticketHolderInputSets: FormInputsPayloadModel[],
    ticketUuid: string,
    buyerInfoEmail?: string
  ): Partial<OrderTicketHolder> {
    const inputMap: Partial<OrderTicketHolder> = {};

    const ticketHolderIndex = ticketHolderInputSets.findIndex(
      formInputsGroup => formInputsGroup.holderUuid === ticketUuid
    );

    if (ticketHolderIndex === AppConstants.INDEX_NOT_FOUND) {
      return null;
    }

    const ticketHolderInputSet = ticketHolderInputSets[ticketHolderIndex];

    if (ticketHolderInputSet && ticketHolderInputSet.hasOwnProperty('inputSet')) {
      ticketHolderInputSet.inputSet.list.forEach(input => {
        const { key, value, options } = input;

        // assign input value to the ticketOwner model
        inputMap[key] = value;

        // if ticket holder email is not mandatory and missing we use buyers email
        // in case of send to owner, we need to assign the value from option number zero, as sets of checkboxes do not have any common value
        if (key === 'email' && !value) {
          inputMap[key] = input.value = buyerInfoEmail;
        } else if (key === 'sendtoowner' && options && options.length) {
          inputMap[key] = options[0].value;
        }
      });

      inputMap['ticketIndex'] = ticketHolderIndex;

      return inputMap;
    }

    return null;
  }

  getTicketByHolder(hash) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/person/ticket-by-holder/${hash}`);
  }

  postTicketHolderForm(data: TicketHolderForm) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/person/visitors-form${
        this.helperService.isSelfregistration() ? '?sr=true' : ''
      }`,
      data
    );
  }

  downloadTicket(hash: string) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/person/download-ticket/${hash}`, {
      responseType: 'blob'
    });
  }

  downloadMobileTicket(hash: string) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/person/download-mobile-ticket/${hash}`, {
      responseType: 'blob'
    });
  }

  downloadPassBook(hash: string) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/person/download-passbook/${hash}`, {
      responseType: 'blob'
    });
  }

  getVisitorFieldsForPersonalization(ticketId: number) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}`);
  }

  postVisitorFieldsFromPersonalization(ticketId: number, data: any) {
    return this.http.post(`${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}`, data);
  }
}
