<app-banner></app-banner>

<div class="container-fluid">
  <div class="page-content widget-product-selection">
    <ng-container *ngIf="productSelectionProductsAndExhibitionState$ | async as productSelectionProductsAndExhibitionState; else loader">
      <ng-container *ngIf="productSelectionViewModel$ | async as productSelectionViewModel">
        <ng-container *ngIf="productSelectionProductsStatusViewModel$ | async as productsStatusViewModel">
          <ng-container *ngFor="let productListItem of productSelectionProductsAndExhibitionState.productSelectionListProducts; trackBy: productSelectionListTrackBy">
            <ng-container *ngIf="!!productListItem.products">
              <app-product-group 
                [selectedExhibition]="productSelectionProductsAndExhibitionState.selectedExhibition"
                [exhibitionSettings]="productSelectionProductsAndExhibitionState.exhibitionSettings" 
                [productSelectionViewModel]="productSelectionViewModel"
                [productsStatusViewModel]="productsStatusViewModel" 
                [productGroup]="productListItem"
              ></app-product-group>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      
      <div class="page-padding-wrapper">
        <div class="ticket-section">
          <div class="feedback-button">
            <button
              [ngClass]="{ 'feedback-button-disabled': isButtonDisabled }"
              class="continue-button button"
              type="button"
              (click)="onContinueClick($event, productSelectionProductsAndExhibitionState.selectedExhibition.id)"
            >
              <span
                class="continue-button-text"
                data-translation="step-navigation.continue-button"
                >{{
                  'step-navigation.continue-button'
                    | translate
                    | hideTranslation
                }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loader>
      <app-loader ></app-loader>
    </ng-template>
  </div>
</div>