import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, getOrderUuid, getSelectedExhibition, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookingContingentReservation,
  BookingPackageType,
  BookingParkingReservation,
  BookingProductType,
  BookingTariff,
  BookingTariffType,
  BookingWorkshop,
  PostProductBooking,
  PostProductBookingResponse,
  PostWorkshopBooking,
  PostWorkshopResponse,
  RemoveBookingWorkshop,
  RemoveBookingWorkshopAssignedHolders,
  RemoveProductBookingContingentReservation,
  SetBookingContingentReservation,
  SetBookingWorkshop,
  SetProductBookingContingentReservation,
  SetProductBookingParkingReservation,
  SharedBookedPersonIdTariffsTotalCount,
  VoucherCountdown,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import {
  CreateContingentReservationsResponse,
  CreateContingentTariffReservation,
  CreateContingentTariffToBookingContingentReservationsMapper,
  PostCreateContingentReservations,
  RemoveContingentReservation,
  SetContingentReservation,
  SetContingentReservationDate
} from '@products/models/contingent.model';
import { HolderUuids } from '@products/models/holder.model';
import { PackageType } from '@products/models/package.model';
import { GetParkingTariffReservationFee, SetParkingReservationDate } from '@products/models/parking.model';
import {
  ProductType,
  RemovePackageBooking,
  RemovePackageTariffBooking,
  RemoveTariffBooking,
  getProductType
} from '@products/models/products.model';
import { Tariff, TariffClassification, TariffPersonalizationType, TariffType } from '@products/models/tariff.model';
import { RedeemedVoucher, RemoveVoucherTariffBooking, VoucherCode } from '@products/models/voucher.model';
import { WorkshopAssignedHolders, WorkshopAvailableSeats } from '@products/models/workshop-status.model';
import {
  WorkshopId,
  WorkshopProduct,
  WorkshopProductList,
  WorkshopTariffHolder
} from '@products/models/workshop.model';
import { AppConstants } from '@shared/app-constants';
import { getCountdownTimestamp, getUUID } from '@shared/app-utils';
import {
  getBookedPackageTypeByPackageAndTicketPersonId,
  getBookedPackageTypeByPackageNumberAndIndexes,
  getBookedTariffTypeAndTariffByTicketPersonId,
  getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode,
  getSharedBookedPersonIdTariffsTotalCount
} from '@shared/services-with-reducers/products/booking/booking.selectors';
import { environment } from '@src/environments/environment';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import { getBookedProducts } from '@store/products/booking/booking.selectors';
import { getPreferredTariffTypeWithTariff } from '@store/products/product-selection/product-selection.selectors';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { EMPTY, Observable, combineLatest, forkJoin, from, of, pipe, zip } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, takeWhile, toArray } from 'rxjs/operators';

const { INDEX_NOT_FOUND } = AppConstants;

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private http: HttpClient, private store: Store<State>, private helperService: HelperService) {}

  setProductBookings$(
    bookingProductTypes: BookingProductType[],
    isPackageAdded: boolean = false
  ): Observable<PostProductBooking[]> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount(bookingProductTypes)))
    ]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(([eventId, orderUuid, sharedBookedPersonIdTariffsCount]) => {
        return this.mapBookingProductTypeToPostProductBooking(
          bookingProductTypes,
          eventId,
          orderUuid,
          sharedBookedPersonIdTariffsCount,
          isPackageAdded
        );
      })
    );
  }

  setPreferredProductBookings$(ticketTypeId: number, ticketPersonTypeId: number, count: number) {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getPreferredTariffTypeWithTariff(ticketTypeId, ticketPersonTypeId))),
      this.store.pipe(select(getBookedProducts))
    ]).pipe(
      first(
        ([eventId, orderUuid, preferredTicketTypeWithTariff]) =>
          !!eventId && !!orderUuid && !!preferredTicketTypeWithTariff
      ),
      map(([eventId, orderUuid, preferredTicketTypeWithTariff, bookedProductTypes]) => {
        const preferredBookingProductTypes = this.mapTariffTypeToBookingTariffType(
          preferredTicketTypeWithTariff,
          preferredTicketTypeWithTariff.tariffs[0],
          0,
          count
        );

        this.removeProductBookings(bookedProductTypes);

        const preferredBookingAndRemovedBookingProductTypes = [...bookedProductTypes, preferredBookingProductTypes];

        return {
          eventId,
          orderUuid,
          preferredBookingAndRemovedBookingProductTypes
        };
      }),
      switchMap(({ eventId, orderUuid, preferredBookingAndRemovedBookingProductTypes }) =>
        zip(
          of({ eventId, orderUuid, preferredBookingAndRemovedBookingProductTypes }),
          this.store.pipe(
            select(getSharedBookedPersonIdTariffsTotalCount(preferredBookingAndRemovedBookingProductTypes))
          )
        ).pipe(
          map(([combinedLatest, sharedBookedPersonIdTariffsCount]) => ({
            combinedLatest,
            sharedBookedPersonIdTariffsCount
          }))
        )
      ),
      map(({ combinedLatest, sharedBookedPersonIdTariffsCount }) => {
        const { eventId, orderUuid, preferredBookingAndRemovedBookingProductTypes } = combinedLatest;

        return {
          preferredBookingAndRemovedBookingProductTypes,
          postProductBookings: this.mapBookingProductTypeToPostProductBooking(
            preferredBookingAndRemovedBookingProductTypes,
            eventId,
            orderUuid,
            sharedBookedPersonIdTariffsCount
          )
        };
      })
    );
  }

  prepareRemoveTariffBookingPipe(ticketPersonId: number) {
    return pipe(
      first((combinedLatest: [number, string, BookingTariffType]) =>
        combinedLatest.every(combinedItem => !!combinedItem)
      ),
      map(([eventId, orderUuid, bookingTariffType]) => ({ eventId, orderUuid, bookingTariffType })),
      switchMap(({ eventId, orderUuid, bookingTariffType }) =>
        zip(
          of({ eventId, orderUuid, bookingTariffType }),
          this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount([bookingTariffType])))
        ).pipe(
          map(([combinedLatest, sharedBookedPersonIdTariffsCount]) => ({
            combinedLatest,
            sharedBookedPersonIdTariffsCount
          }))
        )
      ),
      map(({ combinedLatest, sharedBookedPersonIdTariffsCount }) => {
        const { eventId, orderUuid, bookingTariffType } = combinedLatest;

        const bookingTariff = bookingTariffType.tariffs.find(tariff => tariff.ticketPersonId === ticketPersonId);

        bookingTariff.count = 0;
        bookingTariff.holderUuids = [];

        return {
          bookingTariffTypes: [bookingTariffType],
          removePostProductBookings: this.mapBookingProductTypeToPostProductBooking(
            [bookingTariffType],
            eventId,
            orderUuid,
            sharedBookedPersonIdTariffsCount
          )
        };
      })
    );
  }

  removeTariffBookings$(
    removeTariff: RemoveTariffBooking
  ): Observable<{
    bookingTariffTypes: BookingTariffType[];
    removePostProductBookings: PostProductBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getBookedTariffTypeAndTariffByTicketPersonId(removeTariff.ticketPersonId)))
    ]).pipe(this.prepareRemoveTariffBookingPipe(removeTariff.ticketPersonId));
  }

  removeVoucherTariffBookings$(
    removeVoucherTariff: RemoveVoucherTariffBooking
  ): Observable<{
    bookingTariffTypes: BookingTariffType[];
    removePostProductBookings: PostProductBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(
        select(
          getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode(
            removeVoucherTariff.ticketPersonId,
            removeVoucherTariff.voucherCode
          )
        )
      )
    ]).pipe(this.prepareRemoveTariffBookingPipe(removeVoucherTariff.ticketPersonId));
  }

  removeReleasedVoucherTariffBookings$(
    removeVoucher: RemoveVoucherTariffBooking
  ): Observable<{
    bookedVoucherTariffTypes: BookingTariffType[];
    voucherCode: VoucherCode;
  }> {
    return this.store.pipe(
      select(
        getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode(
          removeVoucher.ticketPersonId,
          removeVoucher.voucherCode
        )
      ),
      first(),
      map(bookedVoucherTariffType => {
        const bookedVoucherTariff =
          bookedVoucherTariffType &&
          bookedVoucherTariffType.tariffs.find(
            bookedVoucherTariff => bookedVoucherTariff.ticketPersonId === removeVoucher.ticketPersonId
          );

        if (bookedVoucherTariff) {
          bookedVoucherTariff.count = 0;
          bookedVoucherTariff.holderUuids = [];
        }

        const bookedVoucherTariffTypes = bookedVoucherTariff ? [bookedVoucherTariffType] : [];

        return {
          bookedVoucherTariffTypes,
          voucherCode: removeVoucher.voucherCode
        };
      })
    );
  }

  removePackageTariffBookings$(
    removePackageTariff: RemovePackageTariffBooking
  ): Observable<{
    bookedPackageTypes: BookingPackageType[];
    removePostProductBookings: PostProductBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(
        select(
          getBookedPackageTypeByPackageAndTicketPersonId(
            removePackageTariff.packageNumber,
            removePackageTariff.packageIndex,
            removePackageTariff.ticketTypeId,
            removePackageTariff.ticketPersonId
          )
        )
      )
    ]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(([eventId, orderUuid, bookedPackageType]) => ({ eventId, orderUuid, bookedPackageType })),
      switchMap(({ eventId, orderUuid, bookedPackageType }) =>
        zip(
          of({ eventId, orderUuid, bookedPackageType }),
          this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount([bookedPackageType])))
        ).pipe(
          map(([combinedLatest, sharedBookedPersonIdTariffsCount]) => ({
            combinedLatest,
            sharedBookedPersonIdTariffsCount
          }))
        )
      ),
      map(({ combinedLatest, sharedBookedPersonIdTariffsCount }) => {
        const { eventId, orderUuid, bookedPackageType } = combinedLatest;

        const bookingPackageTariffType = bookedPackageType.productTypes.find(packageProductType => {
          if (isBookingProductTypeTariff(packageProductType)) {
            return packageProductType.ticketTypeId === removePackageTariff.ticketTypeId;
          }
        });

        if (isBookingProductTypeTariff(bookingPackageTariffType)) {
          const bookedTariff = bookingPackageTariffType.tariffs.find(
            tariff => tariff.ticketPersonId === removePackageTariff.ticketPersonId
          );

          bookedTariff.count = 0;
          bookedTariff.holderUuids = [];
        }

        return {
          bookedPackageTypes: [bookedPackageType],
          removePostProductBookings: this.mapBookingProductTypeToPostProductBooking(
            [bookedPackageType],
            eventId,
            orderUuid,
            sharedBookedPersonIdTariffsCount
          )
        };
      })
    );
  }

  removePackageBookings$(
    removePackageBookings: RemovePackageBooking
  ): Observable<{
    bookedPackageTypes: BookingPackageType[];
    removePostProductBookings: PostProductBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getBookedPackageTypeByPackageNumberAndIndexes(removePackageBookings)))
    ]).pipe(
      first(([eventId, orderUuid]) => !!eventId && !!orderUuid),
      map(([eventId, orderUuid, bookedPackageTypes]) => ({ eventId, orderUuid, bookedPackageTypes })),
      switchMap(({ eventId, orderUuid, bookedPackageTypes }) =>
        zip(
          of({ eventId, orderUuid, bookedPackageTypes }),
          this.store.pipe(select(getSharedBookedPersonIdTariffsTotalCount(bookedPackageTypes)))
        ).pipe(
          map(([combinedLatest, sharedBookedPersonIdTariffsCount]) => ({
            combinedLatest,
            sharedBookedPersonIdTariffsCount
          }))
        )
      ),
      map(({ combinedLatest, sharedBookedPersonIdTariffsCount }) => {
        const { eventId, orderUuid, bookedPackageTypes } = combinedLatest;
        const emptyPackageIndexes: number[] = _.cloneDeep(removePackageBookings.packageIndexes);

        bookedPackageTypes.forEach(bookedPackageType => {
          const packageIndexToRemoveFromEmptyPackageIndexes = emptyPackageIndexes.findIndex(
            emptyPackageIndex => emptyPackageIndex === bookedPackageType.packageIndex
          );
          emptyPackageIndexes.splice(packageIndexToRemoveFromEmptyPackageIndexes, 1);

          bookedPackageType.productTypes.forEach(packageProductType => {
            if (isBookingProductTypeTariff(packageProductType)) {
              packageProductType.tariffs.forEach(packageTariff => {
                packageTariff.count = 0;
                packageTariff.holderUuids = [];
              });
            }
          });
        });

        const removePostProductBookings = this.mapBookingProductTypeToPostProductBooking(
          bookedPackageTypes,
          eventId,
          orderUuid,
          sharedBookedPersonIdTariffsCount
        );

        const emptyPackagesProductTypes = this.createEmptyBookingPackageTypeFromPackageNumberAndIndexes(
          removePackageBookings.packageNumber,
          emptyPackageIndexes
        );

        return {
          bookedPackageTypes: [...bookedPackageTypes, ...emptyPackagesProductTypes],
          removePostProductBookings: removePostProductBookings
        };
      })
    );
  }

  setWorkshopBookings$(
    setBookingWorkshops: SetBookingWorkshop[]
  ): Observable<{
    setBookingWorkshops: SetBookingWorkshop[];
    postWorkshopBookings: PostWorkshopBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid))
    ]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(([eventId, orderUuid]) => {
        const postWorkshopBookings: PostWorkshopBooking[] = this.mapSetBookingWorkshopsToPostWorkshopBookings(
          setBookingWorkshops,
          eventId,
          orderUuid
        );

        return {
          setBookingWorkshops,
          postWorkshopBookings
        };
      })
    );
  }

  removeWorkshopBookings$(
    removeBookingWorkshops: RemoveBookingWorkshop[]
  ): Observable<{
    removeBookingWorkshops: RemoveBookingWorkshop[];
    postWorkshopBookings: PostWorkshopBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid))
    ]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(([eventId, orderUuid]) => {
        const postWorkshopBookings: PostWorkshopBooking[] = this.mapRemoveBookingWorkshopsToPostWorkshopBookings(
          removeBookingWorkshops,
          eventId,
          orderUuid
        );

        return {
          removeBookingWorkshops,
          postWorkshopBookings
        };
      })
    );
  }

  postAvailableWorkshopBookings$(
    postWorkshopBookings: PostWorkshopBooking[]
  ): Observable<{ numberOftWorkshopBookingsAssigned: number; workshopAvailableSeats: WorkshopAvailableSeats }> {
    let isWorkshopSoldOut = false;

    const postWorkshopBookings$ = from(
      postWorkshopBookings.map(postWorkshopBooking => {
        return this.postWorkshopBooking$(postWorkshopBooking);
      })
    );

    return postWorkshopBookings$.pipe(
      concatMap(postWorkshopBooking$ =>
        postWorkshopBooking$.pipe(map(postWorkshopBookingResponse => postWorkshopBookingResponse))
      ),
      catchError(() => {
        // API throws an error when workshop is sold out
        isWorkshopSoldOut = true;

        return EMPTY;
      }),
      takeWhile(() => !isWorkshopSoldOut),
      toArray(),
      map(postWorkshopBookingResponses => {
        const { workshopId, seats } = postWorkshopBookingResponses.reduce((previousResponse, nextResponse) =>
          previousResponse.seats < nextResponse.seats ? previousResponse : nextResponse
        );

        return {
          numberOftWorkshopBookingsAssigned: postWorkshopBookingResponses.length,
          workshopAvailableSeats: { [workshopId]: seats }
        };
      })
    );
  }

  postRemoveMultipleWorkshopBookings$(
    removeBookingWorkshops: RemoveBookingWorkshop[],
    postWorkshopBookings: PostWorkshopBooking[]
  ): Observable<{ removeBookingWorkshops: RemoveBookingWorkshop[]; workshopAvailableSeats: WorkshopAvailableSeats }> {
    return forkJoin(
      postWorkshopBookings.map(postWorkshopBooking => {
        return this.postWorkshopBooking$(postWorkshopBooking);
      })
    ).pipe(
      map(postWorkshopBookingResponses => {
        const workshopAvailableSeats: WorkshopAvailableSeats = {};

        postWorkshopBookingResponses.forEach(({ workshopId, seats }) => (workshopAvailableSeats[workshopId] = seats));

        return {
          removeBookingWorkshops,
          workshopAvailableSeats
        };
      })
    );
  }

  postReleaseAssignedWorkshopHolderSeats$(
    workshopAssignedHolders: WorkshopAssignedHolders,
    releaseWorkshopAssignedHolders: WorkshopAssignedHolders
  ): Observable<{ workshopAssignedHolders: WorkshopAssignedHolders; workshopAvailableSeats: WorkshopAvailableSeats }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid))
    ]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(([eventId, orderUuid]) => {
        const postWorkshopBookings: PostWorkshopBooking[] = [];

        _.keys(releaseWorkshopAssignedHolders).forEach((workshopId: WorkshopId) => {
          const workshopSeatsToRelease = releaseWorkshopAssignedHolders[workshopId].length;

          if (workshopSeatsToRelease) {
            const postWorkshopBooking: PostWorkshopBooking = {
              eventId,
              workshopId,
              seats: -Math.abs(workshopSeatsToRelease),
              uuid: orderUuid
            };

            postWorkshopBookings.push(postWorkshopBooking);
          }
        });

        return postWorkshopBookings;
      }),
      switchMap(postWorkshopBookings =>
        forkJoin(
          postWorkshopBookings.map(postWorkshopBooking => {
            return this.postWorkshopBooking$(postWorkshopBooking);
          })
        ).pipe(
          map(postWorkshopBookingResponses => {
            const workshopAvailableSeats: WorkshopAvailableSeats = {};

            _.keys(releaseWorkshopAssignedHolders).forEach((workshopId: WorkshopId) => {
              const releasedWorkshopSeats = postWorkshopBookingResponses.find(
                postWorkshopBookingResponse => postWorkshopBookingResponse.workshopId === +workshopId
              );

              if (releasedWorkshopSeats) {
                workshopAvailableSeats[workshopId] = releasedWorkshopSeats.seats;
              } else {
                workshopAssignedHolders[workshopId] = [
                  ...workshopAssignedHolders[workshopId],
                  ...releaseWorkshopAssignedHolders[workshopId]
                ];
              }
            });

            return {
              workshopAssignedHolders,
              workshopAvailableSeats
            };
          })
        )
      )
    );
  }

  removeUnassignedHoldersInSetBookingWorkshopsPipe(
    setBookingWorkshops: SetBookingWorkshop[],
    postWorkshopBookings: PostWorkshopBooking[]
  ) {
    return pipe(
      map(postAvailableWorkshopBookingsResponse => {
        const { workshopAvailableSeats, numberOftWorkshopBookingsAssigned } = postAvailableWorkshopBookingsResponse as {
          numberOftWorkshopBookingsAssigned: number;
          workshopAvailableSeats: WorkshopAvailableSeats;
        };

        const postWorkshopBookingsAssigned = postWorkshopBookings.slice(0, numberOftWorkshopBookingsAssigned);
        const postWorkshopBookingsAssignedHolderUuids = postWorkshopBookingsAssigned.map(
          postWorkshopBookingAssigned => postWorkshopBookingAssigned.holderUuid
        );

        setBookingWorkshops.forEach(setBookingWorkshop => {
          setBookingWorkshop.workshops.forEach(workshop => {
            workshop.holderUuids = _.intersection(workshop.holderUuids, postWorkshopBookingsAssignedHolderUuids);
          });

          setBookingWorkshop.workshops = setBookingWorkshop.workshops.filter(workshop => !!workshop.holderUuids.length);
        });

        const setBookingWorkshopsWithAssignedHolderUuids = setBookingWorkshops.filter(
          setBookingWorkshop => !!setBookingWorkshop.workshops.length
        );

        return {
          setBookingWorkshops: setBookingWorkshopsWithAssignedHolderUuids,
          workshopAvailableSeats
        };
      })
    );
  }

  setContingentReservations$(
    createContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[]
  ): Observable<SetContingentReservation> {
    return combineLatest([this.store.pipe(select(getSelectedExhibition)), this.store.pipe(select(getOrderUuid))]).pipe(
      first(combinedLatest => combinedLatest.every(combinedItem => !!combinedItem)),
      map(
        ([selectedExhibition, orderUuid]): SetContingentReservation => {
          return {
            selectedExhibition,
            orderUuid,
            reservations: this.mapCreateContingentMappersToCreateContingentTariffReservations(
              createContingentReservationsMappers
            )
          };
        }
      )
    );
  }

  releaseAllContingentReservations$() {
    return this.store.pipe(
      select(getOrderUuid),
      first(orderUuid => !!orderUuid),
      switchMap(orderUuid => this.postReleaseContingentReservations$(orderUuid).pipe(map(() => orderUuid)))
    );
  }

  setParkingReservation$(
    parkingReservationDate: SetParkingReservationDate
  ): Observable<GetParkingTariffReservationFee> {
    return this.store.pipe(select(getSelectedExhibitionId)).pipe(
      first(eventId => !!eventId),
      map(eventId => this.mapParkingReservationDatesToGetParkingTariffsFee(eventId, parkingReservationDate))
    );
  }

  removeProductBookings$(bookingProductTypes: BookingProductType[]): Observable<PostProductBooking[]> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid))
    ]).pipe(
      first(([eventId, orderUuid]) => !!eventId && !!orderUuid),
      map(([eventId, orderUuid]) => {
        this.removeProductBookings(bookingProductTypes);

        return this.mapBookingProductTypeToPostProductBooking(bookingProductTypes, eventId, orderUuid, {});
      })
    );
  }

  removeAllProductBookings$(): Observable<{
    removeBookingProductTypes: BookingProductType[];
    removePostProductBookings: PostProductBooking[];
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getBookedProducts))
    ]).pipe(
      first(([eventId, orderUuid]) => !!eventId && !!orderUuid),
      map(([eventId, orderUuid, bookedProductTypes]) => {
        this.removeProductBookings(bookedProductTypes);

        return {
          removeBookingProductTypes: bookedProductTypes,
          removePostProductBookings: this.mapBookingProductTypeToPostProductBooking(
            bookedProductTypes,
            eventId,
            orderUuid,
            {}
          )
        };
      })
    );
  }

  postProductBookings$(postProductBookings: PostProductBooking[]): Observable<PostProductBookingResponse[]> {
    if (!postProductBookings.length) {
      return of([]);
    }

    return this.http.post<PostProductBookingResponse[]>(
      `${environment.protocol}${environment.webApiUrl}/order/ticket-booking`,
      postProductBookings
    );
  }

  postWorkshopBooking$(postWorkshopBooking: PostWorkshopBooking): Observable<PostWorkshopResponse> {
    return this.http.post<PostWorkshopResponse>(
      `${environment.protocol}${environment.webApiUrl}/order/workshop-booking`,
      postWorkshopBooking
    );
  }

  postReleaseWorkshopBookings$(orderUuid: string) {
    return this.http.post<void>(
      `${environment.protocol}${environment.webApiUrl}/order/workshop-booking/${orderUuid}/release`,
      {}
    );
  }

  postCreateContingentReservations$(
    createContingentReservations: PostCreateContingentReservations
  ): Observable<CreateContingentReservationsResponse[]> {
    return this.http.post<CreateContingentReservationsResponse[]>(
      `${environment.protocol}${environment.webApiUrl}/order/day-booking`,
      createContingentReservations
    );
  }

  postChangeTariffContingentDay$(tariffId: number, day: any) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${tariffId}/change-day-booking`,
      { day }
    );
  }

  postReleaseContingentReservations$(uuid: string) {
    return this.http.post<void>(
      `${environment.protocol}${environment.webApiUrl}/order/day-booking/${uuid}/release`,
      {}
    );
  }

  getParkingTariffFee$(parkingTariffReservationFee: GetParkingTariffReservationFee) {
    const { eventId, ticketTypeId, ticketPersonTypeId, since, until } = parkingTariffReservationFee;

    let params = new HttpParams();
    params = params.append('since', since);
    params = params.append('until', until);

    return this.http
      .get(
        `${environment.protocol}${environment.webApiUrl}/event/${eventId}/ticket-groups/${ticketTypeId}/ticket-persons/${ticketPersonTypeId}/parking-fee`,
        { params, observe: 'response' }
      )
      .pipe(map(response => response));
  }

  getSetProductBookingsHolderUuids(bookingProductTypes: BookingProductType[]): HolderUuids {
    const holderUuids: HolderUuids = [];

    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(bookingTariff => {
          holderUuids.push(...bookingTariff.holderUuids);
        });
      } else if (isBookingProductTypePackage(bookingProductType)) {
        bookingProductType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(bookingPackageTariff => {
              holderUuids.push(...bookingPackageTariff.holderUuids);
            });
          }
        });
      }
    });

    return holderUuids;
  }

  removeBookingReservationIndexesFromSortedBookingReservations(
    removeContingentReservations: RemoveContingentReservation[],
    sortedProductBookingContingentReservations: SetProductBookingContingentReservation[]
  ) {
    removeContingentReservations.forEach(removeContingentReservation => {
      const {
        ticketTypeId,
        ticketPersonId,
        packageNumber,
        packageIndex,
        removeBookingReservationIndexes
      } = removeContingentReservation;

      const bookedContingentReservation = sortedProductBookingContingentReservations.find(
        bookingContingentReservation =>
          bookingContingentReservation.ticketTypeId === ticketTypeId &&
          bookingContingentReservation.ticketPersonId === ticketPersonId &&
          bookingContingentReservation.packageNumber === packageNumber &&
          bookingContingentReservation.packageIndex === packageIndex
      );

      if (bookedContingentReservation) {
        removeBookingReservationIndexes
          .reverse()
          .forEach(bookingReservationIndex =>
            bookedContingentReservation.contingentReservations.splice(bookingReservationIndex, 1)
          );
      }
    });
  }

  filterPackagesFromProductBookings(bookingProductTypes: BookingProductType[]): BookingPackageType[] {
    const bookingPackageProductTypes: BookingPackageType[] = [];

    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypePackage(bookingProductType)) {
        bookingPackageProductTypes.push(bookingProductType);
      }
    });

    return bookingPackageProductTypes;
  }

  isPackageProductAddedInProductBookingList(bookingProductTypes: BookingProductType[]): boolean {
    return bookingProductTypes.some(bookingProductType => {
      if (isBookingProductTypePackage(bookingProductType)) {
        return bookingProductType.productTypes.some(bookingPackage => {
          if (isBookingProductTypeTariff(bookingPackage)) {
            return bookingPackage.tariffs.some(bookingPackageTariff => !!bookingPackageTariff.count);
          }
        });
      }
    });
  }

  createEmptyBookingPackageTypeFromPackageNumberAndIndexes(
    packageNumber: number,
    emptyPackageIndexes: number[]
  ): BookingPackageType[] {
    const generatedEmptyBookingPackageType: BookingPackageType[] = [];

    emptyPackageIndexes.forEach(packageIndex => {
      const emptyBookingPackageType: BookingPackageType = {
        productType: ProductType.Package,
        packageNumber: packageNumber,
        packageIndex: packageIndex,
        productTypeName: '',
        productTypes: []
      };

      generatedEmptyBookingPackageType.push(emptyBookingPackageType);
    });

    return generatedEmptyBookingPackageType;
  }

  mapBookingProductTypeToPostProductBooking(
    bookingProductTypes: BookingProductType[],
    eventId: number,
    orderUuid: string,
    sharedBookedPersonIdTariffsCount: SharedBookedPersonIdTariffsTotalCount,
    isPackageAdded: boolean = false
  ): PostProductBooking[] {
    const postProductBookings: PostProductBooking[] = [];

    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(
          ({ ticketTypeId, ticketPersonTypeId, ticketPersonId, voucherCode, count }) => {
            const currentBookedPersonIdTariffsCount: number = sharedBookedPersonIdTariffsCount[ticketPersonId] || 0;
            const postProductBooking: PostProductBooking = {
              eventId,
              ticketPersonId,
              voucherCode,
              uuid: orderUuid,
              groupId: ticketTypeId,
              ticketTypeId: ticketPersonTypeId,
              count: currentBookedPersonIdTariffsCount + count
            };

            this.setPostProductBooking(postProductBookings, postProductBooking, count);
          }
        );
      } else if (isBookingProductTypePackage(bookingProductType)) {
        bookingProductType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(
              ({ ticketTypeId, ticketPersonTypeId, ticketPersonId, voucherCode, count }) => {
                if (isPackageAdded && !count) {
                  return;
                }

                const currentBookedPersonIdTariffsCount: number = sharedBookedPersonIdTariffsCount[ticketPersonId] || 0;
                const postProductBooking: PostProductBooking = {
                  eventId,
                  uuid: orderUuid,
                  groupId: ticketTypeId,
                  ticketTypeId: ticketPersonTypeId,
                  ticketPersonId: ticketPersonId,
                  voucherCode: voucherCode,
                  count: currentBookedPersonIdTariffsCount + count
                };

                this.setPostProductBooking(postProductBookings, postProductBooking, count);
              }
            );
          }
        });
      }
    });

    return postProductBookings;
  }

  mapPostProductBookingsResponseToBookingProductTypes(
    bookingProductTypes: BookingProductType[],
    postProductBookingsResponse: PostProductBookingResponse[]
  ): BookingProductType[] {
    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(bookingTariff => {
          const responseTariff = postProductBookingsResponse.find(
            postProductBookingResponse => postProductBookingResponse.ticketPersonId === bookingTariff.ticketPersonId
          );

          if (responseTariff) {
            bookingTariff.availableTickets = responseTariff.availableTickets;
            bookingTariff.ticketLimit = responseTariff.sellLimit;
          }
        });
      } else if (isBookingProductTypePackage(bookingProductType)) {
        bookingProductType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(bookingPackageTariff => {
              const responseTariff = postProductBookingsResponse.find(
                postProductBookingResponse =>
                  postProductBookingResponse.ticketPersonId === bookingPackageTariff.ticketPersonId
              );

              if (!!responseTariff) {
                bookingPackageTariff.availableTickets = responseTariff.availableTickets;
                bookingPackageTariff.ticketLimit = responseTariff.sellLimit;
              }
            });
          }
        });
      }
    });

    return bookingProductTypes;
  }

  mapPostProductBookingsResponseToBookingPackageTypes(
    bookingPackageTypes: BookingPackageType[],
    postProductBookingsResponse: PostProductBookingResponse[]
  ): BookingPackageType[] {
    bookingPackageTypes.forEach(bookingPackageType => {
      if (isBookingProductTypePackage(bookingPackageType)) {
        bookingPackageType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(bookingPackageTariff => {
              const responseTariff = postProductBookingsResponse.find(
                postProductBookingResponse =>
                  postProductBookingResponse.ticketPersonId === bookingPackageTariff.ticketPersonId
              );

              if (responseTariff) {
                bookingPackageTariff.availableTickets = responseTariff.availableTickets;
                bookingPackageTariff.ticketLimit = responseTariff.sellLimit;
              }
            });
          }
        });
      }
    });

    return bookingPackageTypes;
  }

  mapTariffToBookingTariff(
    tariff: Tariff,
    previousCount: number,
    updatedCount: number,
    newPackageIndex?: number
  ): BookingTariff {
    const {
      id,
      name,
      price,
      requiresLegitimation,
      allowedWorkshops,
      allowedWorkshopsFull,
      availableTickets,
      ticketLimit,
      ticketTypeName,
      ticketPersonId,
      ticketPersonTypeId,
      ticketTypeId,
      tariffNumber,
      releasedInMinutes,
      voucherCode,
      voucherType,
      activatedTimestamp,
      sponsors,
      info,
      infoExpanded,
      classification,
      isVisible,
      isVoucher,
      parking,
      hasDaySellLimit,
      days,
      workshopsByDay,
      durationInDays,
      personalizationType,
      validFrom,
      validTill,
      shouldCalendarBeDisplayed,
      daysOffset,
      preferredTicket,
      packageSettings,
      packageNumber,
      packageIndex
    } = tariff;

    // Delete this holder assignment when holders will be assigned in holder reducer
    const newlyAddedTariffsCount = updatedCount - previousCount;

    const holderUuids: HolderUuids = this.setHolderUuids(newlyAddedTariffsCount, tariff.classification);

    const contingentReservations: BookingContingentReservation[] = this.setBookingContingentReservations(
      newlyAddedTariffsCount,
      hasDaySellLimit,
      holderUuids
    );

    const isTariffClassificationParking = classification === TariffClassification.Parking;

    const parkingReservations: BookingParkingReservation[] = this.setBookingParkingReservations(
      newlyAddedTariffsCount,
      isTariffClassificationParking
    );

    const bookingTariffPrice = this.setBookingTariffPrice(price, isTariffClassificationParking);

    const bookingTariff: BookingTariff = {
      ticketTypeId,
      ticketPersonId,
      tariffNumber,
      ticketTypeName,
      ticketPersonTypeId,
      packageNumber,
      packageIndex: newPackageIndex || packageIndex,
      ticketName: name,
      availableTickets,
      ticketLimit,
      voucherCode,
      voucherType,
      holderUuids,
      price: bookingTariffPrice,
      isAnonymous: personalizationType === TariffPersonalizationType.Anonymous,
      count: updatedCount,
      workshops: [],
      contingentReservations,
      parkingReservations
    };

    return bookingTariff;
  }

  mapTariffTypeToBookingTariffType(
    tariffType: TariffType,
    tariff: Tariff,
    previousCount: number,
    updatedCount: number
  ) {
    const bookingTariff = this.mapTariffToBookingTariff(tariff, previousCount, updatedCount);
    const bookingTariffType: BookingTariffType = {
      productType: ProductType.Tariff,
      productTypeName: tariffType.ticketTypeName,
      ticketTypeId: tariffType.ticketTypeId,
      tariffs: [bookingTariff]
    };

    return bookingTariffType;
  }

  mapPackageTypeTariffToBookingPackageTypeTariff(
    packageType: PackageType,
    tariffType: TariffType,
    tariff: Tariff,
    previousCount: number,
    updatedCount: number
  ): BookingPackageType {
    const bookingPackageTariffType: BookingTariffType = this.mapTariffTypeToBookingTariffType(
      tariffType,
      tariff,
      previousCount,
      updatedCount
    );

    const bookingPackageType: BookingPackageType = {
      productType: ProductType.Package,
      productTypeName: packageType.info,
      packageNumber: tariff.packageNumber,
      packageIndex: tariff.packageIndex,
      productTypes: [bookingPackageTariffType]
    };

    return bookingPackageType;
  }

  mapRedeemedVoucherToBookingTariffType(
    redeemedVoucher: RedeemedVoucher,
    voucherTariff: Tariff,
    validatedVoucherCount: number
  ): BookingTariffType {
    const {
      voucherCode,
      voucherType,
      quantity,
      ticketCount,
      isAnonymous,
      availableTickets,
      ticketLimit
    } = redeemedVoucher;

    const {
      name,
      price,
      ticketPersonId,
      ticketPersonTypeId,
      ticketTypeId,
      ticketTypeName,
      hasDaySellLimit
    } = voucherTariff;

    const holderUuids: HolderUuids = this.setHolderUuids(ticketCount, TariffClassification.Normal);
    const contingentReservations: BookingContingentReservation[] = this.setBookingContingentReservations(
      ticketCount,
      hasDaySellLimit,
      holderUuids
    );

    const bookingTariff: BookingTariff = {
      ticketTypeId,
      ticketPersonId,
      tariffNumber: null,
      ticketTypeName,
      ticketPersonTypeId,
      packageNumber: null,
      packageIndex: null,
      ticketName: name,
      availableTickets,
      ticketLimit,
      voucherCode,
      voucherType,
      voucherCountLimit: quantity,
      activatedTimestamp: Date.now(),
      holderUuids,
      price,
      count: validatedVoucherCount,
      isAnonymous,
      workshops: [],
      parkingReservations: [],
      contingentReservations
    };

    const bookingTariffType: BookingTariffType = {
      productType: ProductType.Tariff,
      productTypeName: ticketTypeName,
      ticketTypeId: redeemedVoucher.groupId,
      tariffs: [bookingTariff]
    };

    return bookingTariffType;
  }

  mapWorkshopTariffHoldersToSetBookingWorkshops(
    setWorkshopTariffHolders: WorkshopTariffHolder[],
    workshopProductList: WorkshopProductList
  ): SetBookingWorkshop[] {
    const setBookingWorkshops: SetBookingWorkshop[] = [];

    setWorkshopTariffHolders.forEach(workshopTariffHolder => {
      const {
        ticketTypeId,
        ticketPersonId,
        voucherCode,
        packageNumber,
        packageIndex,
        workshopId,
        holderUuid
      } = workshopTariffHolder;

      const productBooking = setBookingWorkshops.find(
        setProductBooking =>
          setProductBooking.ticketTypeId === ticketTypeId &&
          setProductBooking.ticketPersonId === ticketPersonId &&
          setProductBooking.voucherCode === voucherCode &&
          setProductBooking.packageNumber === packageNumber &&
          setProductBooking.packageIndex === packageIndex
      );

      if (productBooking) {
        const productBookingWorkshop = productBooking.workshops.find(
          bookingWorkshop => bookingWorkshop.id === workshopId
        );

        if (productBookingWorkshop) {
          productBookingWorkshop.holderUuids.push(holderUuid);
        }
      } else {
        const { workshopName, price }: WorkshopProduct = workshopProductList.find(
          workshopProduct => workshopProduct.workshopId === workshopId
        );

        const bookingWorkshop: BookingWorkshop = {
          id: workshopId,
          name: workshopName,
          price,
          holderUuids: [holderUuid]
        };

        const setBookingWorkshop: SetBookingWorkshop = {
          productType: getProductType(workshopTariffHolder),
          ticketTypeId,
          ticketPersonId,
          voucherCode,
          packageNumber,
          packageIndex,
          workshops: [bookingWorkshop]
        };

        setBookingWorkshops.push(setBookingWorkshop);
      }
    });

    return setBookingWorkshops;
  }

  mapWorkshopTariffHoldersToRemoveBookingWorkshops(
    removeWorkshopTariffHolders: WorkshopTariffHolder[]
  ): RemoveBookingWorkshop[] {
    const removeBookingWorkshops: RemoveBookingWorkshop[] = [];

    removeWorkshopTariffHolders.forEach(workshopTariffHolder => {
      const {
        ticketTypeId,
        ticketPersonId,
        voucherCode,
        packageNumber,
        packageIndex,
        workshopId,
        holderUuid
      } = workshopTariffHolder;

      const createdRemoveBookingWorkshop = removeBookingWorkshops.find(
        removeBookingWorkshop =>
          removeBookingWorkshop.ticketTypeId === ticketTypeId &&
          removeBookingWorkshop.ticketPersonId === ticketPersonId &&
          removeBookingWorkshop.voucherCode === voucherCode &&
          removeBookingWorkshop.packageNumber === packageNumber &&
          removeBookingWorkshop.packageIndex === packageIndex
      );

      if (createdRemoveBookingWorkshop) {
        const createdRemoveBookingWorkshopHolderUuids = createdRemoveBookingWorkshop.removeWorkshopsAssignedHolders.find(
          removeBookingWorkshop => removeBookingWorkshop.workshopId === workshopId
        );

        if (createdRemoveBookingWorkshopHolderUuids) {
          createdRemoveBookingWorkshopHolderUuids.removeHolderUuids.push(holderUuid);
        } else {
          const removeBookingWorkshopAssignedHolder: RemoveBookingWorkshopAssignedHolders = {
            workshopId,
            removeHolderUuids: [holderUuid]
          };

          createdRemoveBookingWorkshop.removeWorkshopsAssignedHolders.push(removeBookingWorkshopAssignedHolder);
        }
      } else {
        const removeBookedWorkshopAssignedHolders: RemoveBookingWorkshopAssignedHolders = {
          workshopId,
          removeHolderUuids: [holderUuid]
        };

        const removeBookingWorkshop: RemoveBookingWorkshop = {
          productType: getProductType(workshopTariffHolder),
          ticketTypeId,
          ticketPersonId,
          voucherCode,
          packageNumber,
          packageIndex,
          removeWorkshopsAssignedHolders: [removeBookedWorkshopAssignedHolders]
        };

        removeBookingWorkshops.push(removeBookingWorkshop);
      }
    });

    return removeBookingWorkshops;
  }

  mapSetBookingWorkshopsToPostWorkshopBookings(
    setBookingWorkshops: SetBookingWorkshop[],
    eventId: number,
    orderUuid: string
  ): PostWorkshopBooking[] {
    const postWorkshopBookings: PostWorkshopBooking[] = [];

    setBookingWorkshops.forEach(setBookingWorkshop => {
      setBookingWorkshop.workshops.forEach(bookingWorkshop => {
        bookingWorkshop.holderUuids.forEach(holderUuid => {
          const postWorkshopBooking: PostWorkshopBooking = {
            eventId,
            uuid: orderUuid,
            workshopId: bookingWorkshop.id,
            seats: 1,
            holderUuid
          };

          postWorkshopBookings.push(postWorkshopBooking);
        });
      });
    });

    return postWorkshopBookings;
  }

  mapRemoveBookingWorkshopsToPostWorkshopBookings(
    removeBookingWorkshops: RemoveBookingWorkshop[],
    eventId: number,
    orderUuid: string
  ): PostWorkshopBooking[] {
    const removeWorkshopSeatsByWorkshopId: { [workshopId: number]: number } = {};
    const postWorkshopBookings: PostWorkshopBooking[] = [];

    removeBookingWorkshops.forEach(removeBookingWorkshop => {
      removeBookingWorkshop.removeWorkshopsAssignedHolders.forEach(removeWorkshopAssignedHolders => {
        removeWorkshopAssignedHolders.removeHolderUuids.forEach(() => {
          if (!removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId]) {
            removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId] = 0;
          }

          removeWorkshopSeatsByWorkshopId[removeWorkshopAssignedHolders.workshopId]--;
        });
      });
    });

    for (const workshopId in removeWorkshopSeatsByWorkshopId) {
      const postWorkshopBooking: PostWorkshopBooking = {
        eventId,
        uuid: orderUuid,
        workshopId: +workshopId,
        seats: removeWorkshopSeatsByWorkshopId[workshopId]
      };

      postWorkshopBookings.push(postWorkshopBooking);
    }

    return postWorkshopBookings;
  }

  mapSetContingentDateToSetProductBookingContingentReservations(
    contingentReservationDates: SetContingentReservationDate[],
    sortedProductBookingContingentReservations: SetProductBookingContingentReservation[]
  ) {
    contingentReservationDates.forEach(contingentReservationDate => {
      const sortedContingentReservationIndex = sortedProductBookingContingentReservations.findIndex(
        sortedContingentReservation =>
          sortedContingentReservation.ticketTypeId === contingentReservationDate.ticketTypeId &&
          sortedContingentReservation.ticketPersonId === contingentReservationDate.ticketPersonId &&
          sortedContingentReservation.voucherCode === contingentReservationDate.voucherCode &&
          sortedContingentReservation.packageNumber === contingentReservationDate.packageNumber &&
          sortedContingentReservation.packageIndex === contingentReservationDate.packageIndex
      );

      if (sortedContingentReservationIndex !== INDEX_NOT_FOUND) {
        const sortedProductBookingContingentReservation: SetProductBookingContingentReservation =
          sortedProductBookingContingentReservations[sortedContingentReservationIndex];

        sortedProductBookingContingentReservation.contingentReservations[
          contingentReservationDate.bookingReservationIndex
        ].fromDate = contingentReservationDate.day;
      }
    });
  }

  mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers(
    sortedProductBookingContingentReservations: SetProductBookingContingentReservation[]
  ): CreateContingentTariffToBookingContingentReservationsMapper[] {
    const createContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[] = [];

    sortedProductBookingContingentReservations.forEach(sortedContingentReservation =>
      sortedContingentReservation.contingentReservations.forEach((contingentReservation, bookingReservationIndex) => {
        if (contingentReservation.fromDate) {
          const {
            productType,
            ticketTypeId,
            ticketPersonId,
            ticketPersonTypeId,
            voucherCode,
            packageNumber,
            packageIndex
          } = sortedContingentReservation;
          const { holderUuid, duration, fromDate, toDate, isValid } = contingentReservation;
          const uniqueId = packageNumber
            ? `${ticketTypeId}_${ticketPersonTypeId}_${packageNumber}_${packageIndex}`
            : `${ticketTypeId}_${ticketPersonTypeId}`;

          const createContingentReservationMapper: CreateContingentTariffToBookingContingentReservationsMapper = {
            productType,
            holderUuid,
            duration,
            fromDate,
            toDate,
            isValid,
            bookingReservationIndex,
            ticketIndex: createContingentReservationsMappers.length,
            ticketTypeId,
            ticketPersonId,
            ticketPersonTypeId,
            voucherCode,
            packageNumber,
            packageIndex,
            uniqueId
          };

          createContingentReservationsMappers.push(createContingentReservationMapper);
        }
      })
    );

    return createContingentReservationsMappers;
  }

  mapCreateContingentMappersToCreateContingentTariffReservations(
    createContingentTariffToBookingContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[]
  ): CreateContingentTariffReservation[] {
    const createContingentTariffReservations: CreateContingentTariffReservation[] = [];

    createContingentTariffToBookingContingentReservationsMappers.forEach(contingentReservationMapper =>
      createContingentTariffReservations.push({
        day: DateTime.fromJSDate(contingentReservationMapper.fromDate).toISODate(),
        ticketPersonId: contingentReservationMapper.ticketPersonId,
        ticketIndex: contingentReservationMapper.ticketIndex,
        uniqueId: contingentReservationMapper.uniqueId
      })
    );

    return createContingentTariffReservations;
  }

  mapCreateContingentReservationResponseToSetProductBookingContingentReservations(
    selectedExhibition: ExhibitionModel,
    createContingentReservationResponse: CreateContingentReservationsResponse[],
    createContingentTariffToBookingContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[]
  ): SetProductBookingContingentReservation[] {
    const setProductBookingContingentReservations: SetProductBookingContingentReservation[] = [];

    createContingentReservationResponse.forEach(contingentReservationResponse => {
      const contingentReservationMapper = createContingentTariffToBookingContingentReservationsMappers.find(
        bookingContingentReservationMapper =>
          bookingContingentReservationMapper.ticketIndex === contingentReservationResponse.ticketIndex
      );

      if (contingentReservationMapper) {
        const {
          productType,
          ticketTypeId,
          ticketPersonId,
          ticketPersonTypeId,
          voucherCode,
          packageNumber,
          packageIndex,
          holderUuid,
          fromDate,
          toDate,
          duration,
          bookingReservationIndex
        } = contingentReservationMapper;

        const selectedExhibitionClone: ExhibitionModel = _.cloneDeep(selectedExhibition);
        const selectedExhibitionEndDate = selectedExhibitionClone.endDate as Date;
        const fromDateTime: DateTime = DateTime.fromJSDate(fromDate);
        const isMultiDayDuration = duration > 1;
        let validatedDuration = duration;
        let validatedToDate: Date = isMultiDayDuration
          ? fromDateTime.plus({ days: duration - 1 }).toJSDate()
          : fromDate;

        const isValidatedToDateGreaterThenToDate = this.helperService.isFirstDateGreaterWOTime(validatedToDate, toDate);

        if (isValidatedToDateGreaterThenToDate) {
          validatedToDate = toDate;
        }

        const isValidatedToDateGreaterThenExhibitionEndDate = this.helperService.isFirstDateGreaterWOTime(
          validatedToDate,
          selectedExhibitionEndDate
        );

        if (isValidatedToDateGreaterThenExhibitionEndDate) {
          validatedToDate = selectedExhibitionEndDate;
        }

        const areFromAndToDateEqual = this.helperService.areDatesSameWOTime(fromDate, validatedToDate);

        if (isMultiDayDuration && areFromAndToDateEqual) {
          validatedDuration = 1;
        }

        const setBookingContingentReservation: SetBookingContingentReservation = {
          holderUuid,
          fromDate,
          toDate: validatedToDate,
          duration: validatedDuration,
          isValid: contingentReservationResponse.isValid,
          bookingReservationIndex
        };

        const alreadyCreatedSetProductBookingContingentReservation = setProductBookingContingentReservations.find(
          setBookingContingentReservation =>
            setBookingContingentReservation.ticketTypeId === ticketTypeId &&
            setBookingContingentReservation.ticketPersonId === ticketPersonId &&
            setBookingContingentReservation.voucherCode === voucherCode &&
            setBookingContingentReservation.packageNumber === packageNumber &&
            setBookingContingentReservation.packageIndex === packageIndex
        );

        if (alreadyCreatedSetProductBookingContingentReservation) {
          alreadyCreatedSetProductBookingContingentReservation.contingentReservations.push(
            setBookingContingentReservation
          );
        } else {
          setProductBookingContingentReservations.push({
            productType,
            ticketTypeId,
            ticketPersonId,
            ticketPersonTypeId,
            voucherCode,
            packageNumber,
            packageIndex,
            contingentReservations: [setBookingContingentReservation]
          });
        }
      }
    });

    return setProductBookingContingentReservations;
  }

  mapRemoveContingentReservationsToRemoveProductBookingContingentReservation(
    removeContingentReservations: RemoveContingentReservation[],
    createContingentTariffToBookingContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[]
  ): RemoveProductBookingContingentReservation[] {
    const removeProductBookingContingentReservations: RemoveProductBookingContingentReservation[] = [];

    removeContingentReservations.forEach(removeContingentReservation => {
      const { ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex } = removeContingentReservation;
      const removeProductBookingMapper = createContingentTariffToBookingContingentReservationsMappers.find(
        bookingContingentReservationMapper =>
          bookingContingentReservationMapper.ticketTypeId === ticketTypeId &&
          bookingContingentReservationMapper.ticketPersonId === ticketPersonId &&
          bookingContingentReservationMapper.voucherCode === voucherCode &&
          bookingContingentReservationMapper.packageNumber === packageNumber &&
          bookingContingentReservationMapper.packageIndex === packageIndex
      );

      if (removeProductBookingMapper) {
        const removeProductBookingContingentReservation: RemoveProductBookingContingentReservation = {
          productType: removeProductBookingMapper.productType,
          ticketTypeId,
          ticketPersonId,
          voucherCode,
          packageNumber,
          packageIndex,
          removeBookingReservationIndexes: removeContingentReservation.removeBookingReservationIndexes
        };

        removeProductBookingContingentReservations.push(removeProductBookingContingentReservation);
      }
    });

    return removeProductBookingContingentReservations;
  }

  mapRemovedBookingPackageTypesToRemoveContingentReservations(
    removedBookingPackageTypes: BookingPackageType[]
  ): RemoveContingentReservation[] {
    const removeContingentReservations: RemoveContingentReservation[] = [];

    removedBookingPackageTypes.forEach(removedBookingPackageType => {
      removedBookingPackageType.productTypes.forEach(removedBookingPackageProductType => {
        if (isBookingProductTypeTariff(removedBookingPackageProductType)) {
          removedBookingPackageProductType.tariffs.forEach(removedBookingPackageTariff => {
            const {
              ticketTypeId,
              ticketPersonId,
              voucherCode,
              packageNumber,
              packageIndex,
              contingentReservations
            } = removedBookingPackageTariff;

            const contingentReservationIndexesToRemove: number[] = [];

            contingentReservations.forEach((contingentReservation, contingentReservationIndex) => {
              if (contingentReservation.fromDate) {
                contingentReservationIndexesToRemove.push(contingentReservationIndex);
              }
            });

            const removeContingentReservation: RemoveContingentReservation = {
              ticketTypeId,
              ticketPersonId,
              voucherCode,
              packageNumber,
              packageIndex,
              removeBookingReservationIndexes: contingentReservationIndexesToRemove
            };

            if (contingentReservationIndexesToRemove.length) {
              removeContingentReservations.push(removeContingentReservation);
            }
          });
        }
      });
    });

    return removeContingentReservations;
  }

  mapParkingReservationDatesToGetParkingTariffsFee(
    eventId: number,
    parkingReservationDate: SetParkingReservationDate
  ): GetParkingTariffReservationFee {
    const { ticketTypeId, ticketPersonTypeId, since, until } = parkingReservationDate;
    return {
      eventId,
      ticketTypeId,
      ticketPersonTypeId,
      since: this.helperService.toStringWithoutOffset(since),
      until: this.helperService.toStringWithoutOffset(until)
    };
  }

  mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation(
    parkingReservationDate: SetParkingReservationDate,
    parkingReservationPrice: number,
    isParkingReservationValid: boolean
  ): SetProductBookingParkingReservation {
    const {
      productType,
      ticketTypeId,
      ticketPersonId,
      voucherCode,
      packageNumber,
      packageIndex,
      bookingReservationIndex
    } = parkingReservationDate;

    return {
      productType,
      ticketTypeId,
      ticketPersonId,
      voucherCode,
      packageNumber,
      packageIndex,
      bookingReservationIndex,
      price: parkingReservationPrice,
      isValid: isParkingReservationValid
    };
  }

  /**
   * Maps booking product types to voucher countdowns
   * @param bookingProductTypes Booking product types from payload
   * @param tariffReleaseInMinutes In how many seconds does tariff release
   * @returns Array of voucher countdowns
   */
  mapBookingProductTypesToVoucherCountdowns(
    bookingProductTypes: BookingProductType[],
    tariffReleaseInMinutes: number
  ): VoucherCountdown[] {
    const anonymousBookingProductTypes: BookingProductType[] = bookingProductTypes.filter(
      bookingProductType =>
        isBookingProductTypeTariff(bookingProductType) &&
        bookingProductType.tariffs.some(bookingTariff => bookingTariff.isAnonymous)
    );
    const vouchersCountdown: VoucherCountdown[] = [];
    const filteredBookingProductTypes: BookingProductType[] = anonymousBookingProductTypes.length
      ? anonymousBookingProductTypes
      : bookingProductTypes;

    filteredBookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(bookingTariff => {
          const voucherCode: string = bookingTariff.voucherCode;
          const expiryTimestamp: number = getCountdownTimestamp(
            tariffReleaseInMinutes,
            bookingTariff.activatedTimestamp
          );
          const isAnonymous: boolean = bookingTariff.isAnonymous;
          const voucherCountdown: VoucherCountdown = { voucherCode, expiryTimestamp, isAnonymous };

          vouchersCountdown.push(voucherCountdown);
        });
      }
    });

    return vouchersCountdown;
  }

  private setHolderUuids(tariffCount: number, classification: TariffClassification): HolderUuids {
    return classification !== TariffClassification.Parking && tariffCount > 0
      ? Array(tariffCount)
          .fill(null)
          .map(getUUID)
      : [];
  }

  private setBookingContingentReservations(tariffCount: number, hasDaySellLimit: boolean, tariffHolders: HolderUuids) {
    return hasDaySellLimit && tariffCount > 0
      ? Array.from(
          Array(tariffCount),
          (_, index): BookingContingentReservation => ({
            holderUuid: tariffHolders[index],
            duration: null,
            isValid: null,
            fromDate: null,
            toDate: null
          })
        )
      : [];
  }

  private setBookingParkingReservations(tariffCount: number, isTariffClassificationParking: boolean) {
    return isTariffClassificationParking && tariffCount > 0
      ? Array.from(
          Array(tariffCount),
          (): BookingParkingReservation => ({
            isValid: false,
            since: null,
            until: null,
            price: 0
          })
        )
      : [];
  }

  private setBookingTariffPrice(price: number, isTariffClassificationParking: boolean) {
    return isTariffClassificationParking ? 0 : price;
  }

  private setPostProductBooking(
    postProductBookings: PostProductBooking[],
    postProductBookingToAdd: PostProductBooking,
    count: number
  ) {
    const postProductBookingIndex = postProductBookings.findIndex(
      existingPostProductBooking => existingPostProductBooking.ticketPersonId === postProductBookingToAdd.ticketPersonId
    );

    if (postProductBookingIndex === INDEX_NOT_FOUND) {
      postProductBookings.push(postProductBookingToAdd);
    } else {
      postProductBookings[postProductBookingIndex].count += count;
    }
  }

  private removeProductBookings(bookingProductTypes: BookingProductType[]) {
    bookingProductTypes.forEach(bookedProductType => {
      if (isBookingProductTypeTariff(bookedProductType)) {
        bookedProductType.tariffs.forEach(bookedTariff => {
          bookedTariff.count = 0;
          bookedTariff.holderUuids = [];
        });
      } else if (isBookingProductTypePackage(bookedProductType)) {
        bookedProductType.productTypes.forEach(packageProductType => {
          if (isBookingProductTypeTariff(packageProductType)) {
            packageProductType.tariffs.forEach(packageTariff => {
              packageTariff.count = 0;
              packageTariff.holderUuids = [];
            });
          }
        });
      }
    });
  }
}
