import { OnInit } from '@angular/core';
import { getBuyerInfo, getLanguage, getOrderResponse, getSelectedExhibitionId, getTicketHolderInputSets, } from '@app/app.reducer';
import { AppService } from '@app/app.service';
import { Store, select } from '@ngrx/store';
import { HelperService } from '@store/helpers/helper.service';
import { getAllBookedProductsTotalPrice, getBookedProducts } from '@store/products/booking/booking.selectors';
import { SetMultipleStepsVisibility, SetOrderResponse, setPaymentMethod } from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { combineLatest } from 'rxjs';
import { debounceTime, first, switchMap } from 'rxjs/operators';
var WebShopRecipeComponent = /** @class */ (function () {
    function WebShopRecipeComponent(store, appService, stepsFormsService, helperService) {
        this.store = store;
        this.appService = appService;
        this.stepsFormsService = stepsFormsService;
        this.helperService = helperService;
        this.loading = true;
        this.printed = null;
        this.serverError = false;
        var ticketParams = this.helperService.getTicketParams();
        !!ticketParams && this.resetShop(true, ticketParams);
    }
    WebShopRecipeComponent.prototype.ngOnInit = function () {
        var _this = this;
        // remove the order response if there is an
        this.store.dispatch(new SetOrderResponse(null));
        this.store.dispatch(new setPaymentMethod("0"));
        combineLatest([
            this.store.pipe(select(getBookedProducts)),
            this.store.pipe(select(getTicketHolderInputSets)),
            this.store.pipe(select(getAllBookedProductsTotalPrice)),
        ])
            .pipe(first(function (_a) {
            var bookedProducts = _a[0], ticketHolderInputs = _a[1];
            return !!bookedProducts && !!ticketHolderInputs;
        }), debounceTime(500))
            .subscribe(function (_a) {
            var bookedProducts = _a[0], ticketHolderInputs = _a[1], price = _a[2];
            _this.price = price;
            _this.stepsFormsService.prepareDataForSaveAndSend(bookedProducts, _this.price, ticketHolderInputs);
        });
        combineLatest([
            this.store.pipe(select(getOrderResponse)),
            this.store.pipe(select(getBuyerInfo)),
            this.store.pipe(select(getLanguage))
        ])
            .pipe(first(function (_a) {
            var orderResponse = _a[0], buyerInfo = _a[1];
            return !!orderResponse && !!buyerInfo;
        }))
            .subscribe(function (_a) {
            var orderResponse = _a[0], buyer = _a[1], language = _a[2];
            if (orderResponse.hasOwnProperty('error') && orderResponse.error !== 'Ok!' && !orderResponse.success) {
                _this.serverError = true;
            }
            _this.loading = false;
            if (!_this.serverError) {
                var paymentType = _this.price ? 2 : 1;
                var cPrintData = {
                    nType: paymentType,
                    szFirstname: buyer.list.find(function (input) { return input.key === 'firstName'; })
                        .value,
                    szLastname: buyer.list.find(function (input) { return input.key === 'lastName'; })
                        .value,
                    szCode: orderResponse.szCode,
                    nSerialNr: orderResponse.nSerialNr,
                    nProjNr: orderResponse.nProjNr,
                    nPosNr: orderResponse.nPosNr,
                    szLang: language,
                    szTicketUrl: orderResponse.selfRegistrationTicketDownloadUrl
                };
                var event_1 = new MessageEvent('CouponPrint', { data: JSON.stringify(cPrintData) });
                document.dispatchEvent(event_1);
            }
        });
        var visibilityPayload = [
            'tickets',
            'personal',
            'workshop',
            'menu',
            'questionnaire'
        ].map(function (stepKey) { return ({ stepKey: stepKey, visible: false }); });
        this.store.dispatch(new SetMultipleStepsVisibility(visibilityPayload));
    };
    WebShopRecipeComponent.prototype.resetShop = function (releaseAllVouchersAndTickets, ticketParams) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        this.store.pipe(select(getSelectedExhibitionId), first(), switchMap(function (exhibitionId) {
            return _this.appService.resetReducersWithUser(releaseAllVouchersAndTickets).map(function () { return exhibitionId; });
        })).subscribe(function (exhibitionId) {
            window.location.replace("/self-registration/" + exhibitionId + (!!ticketParams ? "/tickets" + ticketParams : ''));
            if (!!window.MSInputMethodContext && !!document.documentMode) {
                window.localStorage.clear();
                window.sessionStorage.clear();
            }
        });
    };
    return WebShopRecipeComponent;
}());
export { WebShopRecipeComponent };
