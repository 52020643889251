import { Action } from '@ngrx/store';
import { TranslationsModel } from './translation.model';

export enum ActionTypes {
  RESET_REDUCER = '[Helpers] reset reducer',
  LOAD_AND_SET_TRANSLATIONS = '[Helpers] Load translations via API',
  SET_TRANSLATIONS = '[Helpers] Set translations',
  SET_IS_TRANSLATION_LOADED = '[Helpers] Set boolean value after returning all translations'
}

export class LoadAndSetTranslations implements Action {
  readonly type = ActionTypes.LOAD_AND_SET_TRANSLATIONS;
  constructor(public payload: { eventId: number; lang: string }) {}
}

export class SetTranslations implements Action {
  readonly type = ActionTypes.SET_TRANSLATIONS;
  constructor(public payload: TranslationsModel) {}
}

export class SetIsTranslationLoaded implements Action {
  readonly type = ActionTypes.SET_IS_TRANSLATION_LOADED;
  constructor(public payload: boolean) {}
}

export type Actions = LoadAndSetTranslations | SetTranslations | SetIsTranslationLoaded;
