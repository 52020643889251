import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State, getWidgetSettings } from '@app/app.reducer';
import { TopBarComponent } from '@layout/top-bar/top-bar.component';
import { Store, select } from '@ngrx/store';
import { TicketsWidgetSettings } from '@pages/widget-admin/widget-models.interface';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { UserService } from '@store/user/user.service';
import { delay, filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-top-bar-widget',
  templateUrl: './top-bar-widget.component.html',
  styleUrls: ['./top-bar-widget.component.scss']
})
export class TopBarWidgetComponent extends TopBarComponent implements OnInit {
  widgetSettings: TicketsWidgetSettings;
  displayHeader: boolean = false;

  constructor(
    router: Router,
    userService: UserService,
    store: Store<State>,
    windowSizeService: WindowSizeService,
    customizationService: CustomizationService,
    helperService: HelperService,
    stepsFormsService: StepsFormsService
  ) {
    super(router, userService, store, windowSizeService, customizationService, helperService, stepsFormsService);
  }

  ngOnInit() {
    this.store
      .pipe(
        select(getWidgetSettings),
        filter(widgetSettings => {
          if (widgetSettings && widgetSettings.ticketsWidget) {
            return true;
          }
        }),
        delay(0),
        first()
      )
      .subscribe(({ ticketsWidget }) => {
        this.widgetSettings = ticketsWidget;
        this.displayHeader = Boolean(ticketsWidget.header);
      });
  }
}
