import * as fromRoot from '@app/app.reducer';
import * as helperActions from '../helpers/helper.actions';
import * as userActions from './user.actions';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
  BillingAddressModel,
  QuestionnaireSaveModel,
  UserModel,
  UserProfileModel
} from './user.interface';

import { AppConstants } from '@shared/app-constants';
import { ApplicationInsightsService } from '@shared/applicationInsights/applicationInsightsService';
import { QuestionnaireDataSection } from '../customization/customization.interfaces';
import { CustomizationService } from '../customization/customization.service';
import { transformeventsEmailUnsubscriptions } from '../customization/forms/newsletter-unsubscriptions-data';
import { HelperService } from '../helpers/helper.service';
import { EventsEmailUnsubscriptionsModle } from './user.interface';
import { UserService } from './user.service';

export const USER_DEBOUNCE = new InjectionToken<number>('User Debounce');

@Injectable()
export class UserEffect {
  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.LogIn>(userActions.ActionTypes.LOGIN),
    switchMap((data: any) => {
      const { email, password } = data.payload;
      // TODO update interface LoginModel
      if (!email && !password) {
        return Observable.empty();
      }

      return this.userService.login(email, password).pipe(
        map((user: UserModel) => {
          user.userType = AppConstants.getUserTypeFromJwt(user);

          if (user.hasOwnProperty('id')) {
            this._store.dispatch(new userActions.GetProfile(user.id));
            this._store.dispatch(new userActions.SetLoginTimestamp(Date.now()));
            this._applicationInsightsService.setUserId(user.id);
          }

          return new userActions.SetUser(user);
        }),
        catchError(() => of(new userActions.SetUser(null)))
      );
    })
  );

  /*   @Effect()
  loginAsGuest$: Observable<Action> = this.actions$
    .ofType(userActions.LOGIN_AS_GUEST)
    .debounceTime(this.debounce)
    .switchMap(() => {
      return this.userService
        .loginAsGuest()
        .map((user: UserModel) => new userActions.SetUser(user))
        .catch(() => of(new userActions.SetUser(null)));
    }); */

  @Effect()
  registrationQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.GetRegistrationQuestionnaire>(
      userActions.ActionTypes.GET_REGISTRATION_QUESTIONNAIRE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload;
      return this.userService.getRegistrationQuestionnaire(eventId).pipe(
        map((registrationQuestionnaire: QuestionnaireDataSection[]) => {
          const questionnaire: QuestionnaireDataSection[] = registrationQuestionnaire;
          const questionnaireInputs = this._customizationService.tansformQuestionnaireIntoInput(
            questionnaire
          );

          return new userActions.SetRegistrationQuestionnaire(
            questionnaireInputs
          );
        }),
        catchError(() => of(new userActions.SetRegistrationQuestionnaire([])))
      );
    })
  );

  @Effect()
  eventsEmailUnsubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.GetEventsEmailUnsubscriptions>(
      userActions.ActionTypes.GET_EVENTS_EMAIL_UNSUBSCRIPTIONS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const userId = data.payload;

      if (!userId) {
        return Observable.empty();
      }

      return this.userService.getEmailUnsubscriptions().pipe(
        map((emailUnsubscriptions: EventsEmailUnsubscriptionsModle[]) => {
          const eventsEmailUnsubscriptions: EventsEmailUnsubscriptionsModle[] = emailUnsubscriptions;
          const unsubscriptionInputs = transformeventsEmailUnsubscriptions(
            eventsEmailUnsubscriptions
          );

          return new userActions.SetEventsEmailUnsubscriptions(
            unsubscriptionInputs
          );
        }),
        catchError(() => of(new userActions.SetEventsEmailUnsubscriptions([])))
      );
    })
  );

  @Effect()
  getProfile$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.GetProfile>(userActions.ActionTypes.GET_PROFILE),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const userId = data.payload;

      this._store.dispatch(new userActions.ProfileLoading(true));

      if (!userId) {
        this._store.dispatch(new userActions.ProfileLoading(false));

        return Observable.empty();
      }

      return this.userService.getProfile().pipe(
        map((profile: UserProfileModel) => {
          // remove when BE will send null value for gender
          if (profile.gender === 'notSet') {
            profile.gender = null;
          }

          profile.dateOfBirth = !!profile.dateOfBirth ? this._helperService.getUTCdate(new Date(profile.dateOfBirth)) : null;
          return new userActions.SetProfile(profile);
        }),
        catchError(() => of(new userActions.SetProfile(null)))
      );
    })
  );

  @Effect()
  createProfile$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.CreateProfile>(userActions.ActionTypes.POST_PROFILE),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const user = data.payload;
      if (!user) {
        return Observable.empty();
      }

      let questionnaire: QuestionnaireSaveModel = null;

      if (user.hasOwnProperty('questionnaire')) {
        questionnaire = Object.assign({}, user.questionnaire);
        delete user.questionnaire;
      }

      return this.userService.createProfile(user).pipe(
        map((profile: UserProfileModel) => {
          if (questionnaire) {
            let questionnaireToSave = {
              userId: profile.id,
              questionnaire
            };

            setTimeout(() => {
              this._store.dispatch(
                new userActions.PostRegistrationQuestionnaire(
                  questionnaireToSave
                )
              );
            }, 0);
          }

          this._store.dispatch(
            new userActions.SetUser({
              id: profile.id,
              authToken: profile.authToken,
              userType: profile.userType
            })
          );

          this._store.dispatch(new userActions.GetProfile(profile.id));
          this._helperService.redirectAfterLogin();

          return new userActions.SetProfile(profile);
        }),
        catchError(() => of(new userActions.SetProfile(null)))
      );
    })
  );

  @Effect()
  updateProfile$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UpdateProfile>(userActions.ActionTypes.PUT_PROFILE),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const user = data.payload;

      if (!user) {
        return Observable.empty();
      }

      return this.userService.updateProfile(user).pipe(
        map((profile: UserProfileModel) => {
          return new userActions.SetProfile(profile);
        }),
        catchError(() => of(new userActions.SetProfile(null)))
      );
    })
  );

  @Effect()
  updateProfileWithoutValidation$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UpdateProfileWithoutValidation>(
      userActions.ActionTypes.PUT_PROFILE_WITHOUT_VALIDATION
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const user = data.payload;

      if (!user) {
        return Observable.empty();
      }

      return this.userService.updateProfileWithoutValidation(user).pipe(
        map((profile: UserProfileModel) => {
          return new userActions.SetProfile(profile);
        }),
        catchError(() => of(new userActions.SetProfile(null)))
      );
    })
  );

  @Effect()
  postRegistrationQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.PostRegistrationQuestionnaire>(
      userActions.ActionTypes.POST_REGISTRATION_QUESTIONNAIRE_PROFILE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const { questionnaire, userId } = data.payload;

      return this.userService
        .saveRegistrationQuestionnaire(questionnaire)
        .pipe(
          map((code: number) => {
            this._helperService.redirectAfterLogin();
            return new helperActions.SetApiCallResult({
              code,
              message: 'post registration questionnaire success'
            });
          }),
          catchError(() =>
            of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'post registration questionnaire fail'
              })
            )
          )
        );
    })
  );

  @Effect()
  getProfileBillingAddress$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.GetProfileBillingAddress>(
      userActions.ActionTypes.GET_PROFILE_BILLING_ADDRESS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const userId = data.payload;

      if (!userId) {
        return Observable.empty();
      }

      return this.userService.getProfileBillingAddress().pipe(
        map(
          (billingAddress: BillingAddressModel[]) =>
            new userActions.SetProfileBillingAddress(billingAddress)
        ),
        catchError(() => of(new userActions.SetProfileBillingAddress([])))
      );
    })
  );

  @Effect()
  putProfileBillingAddress$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.PutProfileBillingAddress>(
      userActions.ActionTypes.PUT_PROFILE_BILLING_ADDRESS
    ),
    debounceTime(this.debounce),
    switchMap((actionData: any) => {
      const { userId, addressId, data } = actionData.payload;
      if (!actionData.payload) {
        return Observable.empty();
      }

      return this.userService
        .putProfileBillingAddress(addressId, data)
        .pipe(
          map((billingAddress: BillingAddressModel) => {
            return new userActions.UpdateProfileBillingAddress(billingAddress);
          }),
          catchError(() =>
            of(new userActions.UpdateProfileBillingAddress(null))
          )
        );
    })
  );

  @Effect()
  deleteProfileBillingAddress$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.DeleteProfileBillingAddress>(
      userActions.ActionTypes.DELETE_PROFILE_BILLING_ADDRESS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { userId, addressId } = data.payload;

      return this.userService
        .deleteProfileBillingAddress(addressId)
        .pipe(
          map((deletedBillingAddress: BillingAddressModel) => {
            /* As the billng address to be removed is for sure in the list,
           the next call to redux is actually synchronous */

            let billingAddresses: BillingAddressModel[];
            this._store
              .select(fromRoot.getProfileBillingAddress)
              .subscribe(addresses => {
                billingAddresses = addresses;
              });

            const clearedAddresses = billingAddresses.filter(
              (currentAddress: BillingAddressModel) => {
                return currentAddress.id !== deletedBillingAddress.id;
              }
            );

            const addressesClone = JSON.parse(JSON.stringify(clearedAddresses));

            return new userActions.SetProfileBillingAddress(addressesClone);
          }),
          catchError(() => of(new userActions.SetProfileBillingAddress([])))
        );
    })
  );

  @Effect()
  patchEmail$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.PatchEmail>(userActions.ActionTypes.PATCH_EMAIL),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { userId, email, password, language } = data.payload;

      if (!data.payload) {
        return Observable.empty();
      }

      return this.userService
        .setNewEmail(email, password, language)
        .pipe(
          map((code: number) => new userActions.EmailChanged(200)),
          catchError(() => {
            return of(new userActions.EmailChanged(500));
          })
        );
    })
  );

  @Effect()
  patchPassword$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.PatchPassword>(userActions.ActionTypes.PATCH_PASSWORD),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const { userId, password, language } = data.payload;

      return this.userService.setNewPassword(password, language).pipe(
        map((code: number) => new userActions.PasswordChanged(code)),
        catchError(() => of(new userActions.PasswordChanged(500)))
      );
    })
  );
  @Effect()
  resetPassword$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.ResetPassword>(userActions.ActionTypes.RESET_PASSWORD),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const { hash, password, crmHash } = data.payload;

      return this.userService.resetPassword(password, hash, crmHash).pipe(
        map((code: any) => {
          return new userActions.PasswordChanged(code.status);
        }),
        catchError(err => {
          console.log('error', err);
          return of(new userActions.PasswordChanged(err.status));
        })
      );
    })
  );

  @Effect()
  verifyEmail$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.VerifyEmail>(userActions.ActionTypes.VERIFY_EMAIL),
    debounceTime(this.debounce),
    switchMap((payloadData: any) => {
      if (!payloadData) {
        return Observable.empty();
      }

      const { hash, crmHash } = payloadData.payload;

      return this.userService.verifyEmail(hash, crmHash).pipe(
        map((response: any) => {
          return new userActions.EmailVerified({
            statusCode: response.status,
            body: {
              assignedTickets: response.body.assignedTickets,
              assignedOrders: response.body.assignedOrders
            }
          });
        }),
        catchError(err => {
          console.log('error caught 3', err);
          return of(
            new userActions.EmailVerified({
              statusCode: err.status,
              body: {
                assignedTickets: null,
                assignedOrders: null
              }
            })
          );
        })
      );
    })
  );

  @Effect()
  changePassword$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.ChangePassword>(userActions.ActionTypes.CHANGE_PASSWORD),
    debounceTime(this.debounce),
    switchMap((payloadData: any) => {
      if (!payloadData) {
        return Observable.empty();
      }

      const { hash, crmHash } = payloadData.payload;

      return this.userService.changePassword(hash, crmHash).pipe(
        map((code: any) => {
          return new userActions.PasswordChanged(code.status);
        }),
        catchError(err => {
          console.log('error', err);
          return of(new userActions.PasswordChanged(err.status));
        })
      );
    })
  );

  @Effect()
  changeEmail$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.ChangeEmail>(userActions.ActionTypes.CHANGE_EMAIL),
    debounceTime(this.debounce),
    switchMap((payloadData: any) => {
      if (!payloadData) {
        return Observable.empty();
      }

      const { hash, crmHash } = payloadData.payload;

      return this.userService.changeEmail(hash, crmHash).pipe(
        map((code: any) => {
          return new userActions.EmailChanged(code.status);
        }),
        catchError(err => {
          console.log('error', err);
          return of(new userActions.EmailChanged(err.status));
        })
      );
    })
  );

  @Effect()
  requestPasswordRecovery$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.RequestPasswordRecovery>(
      userActions.ActionTypes.POST_PASSWORD_RECOVERY
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      return this.userService.forgottenPassword(data.payload).pipe(
        map(() => {
          return new userActions.RequestPasswordRecoveryResult(true);
        }),
        catchError(() =>
          of(new userActions.RequestPasswordRecoveryResult(false))
        )
      );
    })
  );

  @Effect()
  DownloadTicketById$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.DownloadTicketById>(
      userActions.ActionTypes.DOWNLOAD_TICKET_BY_ID
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { id, type, ticketId, packageIndex } = data.payload;

      if (!id) {
        return Observable.empty();
      }

      if (type === 'normalTicket') {
        return this.userService.downloadTicketById(id).pipe(
          map((blob: any) => {
            this._helperService.saveToFileSystem(blob, `ticket_${id}.pdf`);

            // if we load the user
            return new helperActions.SetApiCallResult({
              code: 200,
              message: 'ticket download successfull'
            });
          }),
          catchError(() => {
            return of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'ticket download fail'
              })
            );
          })
        );
      }

      if (type === 'invoiceTicket') {
        return this.userService.downloadInvoice(id).pipe(
          map((blob: any) => {
            this._helperService.saveToFileSystem(blob, `invoice_${id}.pdf`);

            // if we load the user
            return new helperActions.SetApiCallResult({
              code: 200,
              message: 'ticket download successfull'
            });
          }),
          catchError(() => {
            return of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'ticket download fail'
              })
            );
          })
        );
      }

      if (type === 'passBook') {
        return this.userService.downloadPassbook(id).pipe(
          map((blob: any) => {
            this._helperService.saveToFileSystem(blob, `passbook_${id}.pkpass`);

            // if we load the user
            return new helperActions.SetApiCallResult({
              code: 200,
              message: 'ticket download successfull'
            });
          }),
          catchError(() => {
            return of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'ticket download fail'
              })
            );
          })
        );
      }

      if (type === 'refundReceipt') {
        return this.userService.downloadRefundReceipt(id).pipe(
          map((blob: any) => {
            this._helperService.saveToFileSystem(blob, `refund_order_${id}.pdf`);

            // if we load the user
            return new helperActions.SetApiCallResult({
              code: 200,
              message: 'Refund receipt download successfull'
            });
          }),
          catchError(() => {
            return of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'Refund receipt download fail'
              })
            );
          })
        );
      }

      if (type === 'refundSingleTicketReceipt') {
        if (!!ticketId) {
          return this.userService.downloadTicketRefundReceipt(id, ticketId).pipe(
            map((blob: any) => {
              this._helperService.saveToFileSystem(blob, `refund_ticket_${ticketId}.pdf`);

              // if we load the user
              return new helperActions.SetApiCallResult({
                code: 200,
                message: 'Refund receipt download successfull'
              });
            }),
            catchError(() => {
              return of(
                new helperActions.SetApiCallResult({
                  code: 0,
                  message: 'Refund receipt download fail'
                })
              );
            })
          );
        }
      }

      if (type === 'refundPackageReceipt') {
        if (packageIndex > -1) {
          return this.userService.downloadPackageRefundReceipt(id, packageIndex).pipe(
            map((blob: any) => {
              this._helperService.saveToFileSystem(blob, `refund_package_${id}_${packageIndex}.pdf`);

              // if we load the user
              return new helperActions.SetApiCallResult({
                code: 200,
                message: 'Refund receipt download successfull'
              });
            }),
            catchError(() => {
              return of(
                new helperActions.SetApiCallResult({
                  code: 0,
                  message: 'Refund receipt download fail'
                })
              );
            })
          );
        }
      }

      if (type === 'parkingTicket') {
        return this.userService.downloadParkingTicketById(id).pipe(
          map((blob: any) => {
            this._helperService.saveToFileSystem(
              blob,
              `parkingTicket_${id}.pdf`
            );

            // if we load the user
            return new helperActions.SetApiCallResult({
              code: 200,
              message: 'ticket download successfull'
            });
          }),
          catchError(() => {
            return of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'ticket download fail'
              })
            );
          })
        );
      }

      if (type === 'parkingRefundReceipt') {
        if (!!ticketId) {
          return this.userService.downloadParkingRefundReceipt(id, ticketId).pipe(
            map((blob: any) => {
              this._helperService.saveToFileSystem(blob, `refund_parking_ticket_${ticketId}.pdf`);

              // if we load the user
              return new helperActions.SetApiCallResult({
                code: 200,
                message: 'Refund receipt download successfull'
              });
            }),
            catchError(() => {
              return of(
                new helperActions.SetApiCallResult({
                  code: 0,
                  message: 'Refund receipt download fail'
                })
              );
            })
          );
        }
      }
    })
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    @Optional()
    @Inject(USER_DEBOUNCE)
    private debounce: number = 50,
    private _customizationService: CustomizationService,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _applicationInsightsService: ApplicationInsightsService
  ) { }
}
