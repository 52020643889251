var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getOrderResponse, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypeTariff } from '@products/models/booking.model';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { SetProductInBookingList } from '@store/products/booking/booking.actions';
import { getAllBookedProductsCount, getBookedProducts } from '@store/products/booking/booking.selectors';
import { SetOrderResponse } from '@store/step-forms/steps-forms.actions';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
var PaymentLandingPageComponent = /** @class */ (function () {
    function PaymentLandingPageComponent(route, router, formsService, store, errorHandlingService) {
        this.route = route;
        this.router = router;
        this.formsService = formsService;
        this.store = store;
        this.errorHandlingService = errorHandlingService;
        this.orderId = '';
    }
    PaymentLandingPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .pipe(select(getSelectedExhibitionId), first())
            .subscribe(function (eventId) { return (_this.getSelectedExhibitionId = eventId); });
        combineLatest([this.route.params, this.route.queryParams])
            .pipe(first())
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.orderId = queryParams.orderid;
            if (params.response) {
                _this.response = params.response;
                // If it is fail we stay on this page and show the failure to a user in template
                // Oterwise we set the hidden payment step to be valid and send user to invoice route
                _this.store
                    .pipe(select(getOrderResponse), first())
                    .subscribe(function (orderResponse) {
                    if (_this.response !== 'fail' && _this.response !== 'error') {
                        orderResponse = orderResponse || {
                            hash: _this.response,
                            szCode: _this.orderId,
                            orderPaid: true
                        };
                        /*
                      We have the hash from the order, but just for sure.. for case that we lost localStorage data by redirect,
                      we set the hash again to be the one taken from url. Also we set 'orderPaid' to true.
                      Then we redirect to invoice page for downloads
                    */
                        orderResponse.hash = _this.response;
                        orderResponse.szCode = _this.orderId;
                        orderResponse.orderPaid = true;
                        _this.store.dispatch(new SetOrderResponse(orderResponse));
                        // there is invisible payment step, set it now to valid so we can skip it and go to invoice page
                        _this.formsService.setStepValid(['payment', 'validation']);
                        _this.router.navigate(["webshop/" + _this.getSelectedExhibitionId + "/invoice"]);
                    }
                    if (!Object.keys(queryParams).length) {
                        var errorMessageKey = '';
                        if (typeof orderResponse.errorCode === 'number') {
                            _this.removeParkingTickets(orderResponse.invalidTickets);
                            errorMessageKey = orderResponse.errorCode.toString();
                        }
                        else {
                            errorMessageKey = orderResponse.errorCode;
                        }
                        _this.translatedErrorMessage = {
                            status: 200,
                            key: errorMessageKey,
                            message: orderResponse.errorMessage
                        };
                    }
                });
                // if there was an error from BE previously, take it from queryParams
                if (Object.keys(queryParams).length) {
                    _this.translatedErrorMessage = __assign({}, queryParams);
                }
            }
        });
        // if there was error with status 409 || 404, use this error instad default and store message to queryParams (preserve it on reload)
        this.errorHandlingService.translatedErrorMessage
            .pipe(first(function (translatedErrorMessage) {
            var statusCode = translatedErrorMessage && translatedErrorMessage.status;
            if (statusCode === 409 || statusCode === 404) {
                return true;
            }
        }))
            .subscribe(function (translatedErrorMessage) {
            _this.router.navigate(['.'], {
                relativeTo: _this.route,
                queryParams: __assign({}, translatedErrorMessage)
            });
            _this.translatedErrorMessage = translatedErrorMessage;
        });
    };
    PaymentLandingPageComponent.prototype.removeParkingTickets = function (invalidTickets) {
        var _this = this;
        this.store
            .pipe(select(getBookedProducts), first())
            .subscribe(function (bookedProducts) {
            var removeBookedParkingTariffs = [];
            bookedProducts.forEach(function (bookedProduct) {
                if (isBookingProductTypeTariff(bookedProduct)) {
                    bookedProduct.tariffs.forEach(function (tariff) {
                        if (tariff.parkingReservations.length) {
                            var filteredInvalidParkingTariffs_1 = invalidTickets.filter(function (invalidTicket) {
                                return invalidTicket.ticketGroupNr === tariff.ticketTypeId &&
                                    invalidTicket.ticketPersonNr === tariff.ticketPersonTypeId;
                            });
                            if (filteredInvalidParkingTariffs_1.length) {
                                var filteredInvalidParkingReservations = tariff.parkingReservations.filter(function (parkingReservation) {
                                    return !filteredInvalidParkingTariffs_1.some(function (invalidParkingTariff) {
                                        var since = new Date(parkingReservation.since);
                                        var until = new Date(parkingReservation.until);
                                        var invalidSince = new Date(invalidParkingTariff.since);
                                        var invalidUntil = new Date(invalidParkingTariff.until);
                                        since.setSeconds(0);
                                        until.setSeconds(0);
                                        invalidSince.setSeconds(0);
                                        invalidUntil.setSeconds(0);
                                        return since.getTime() === invalidSince.getTime() && until.getTime() === invalidUntil.getTime();
                                    });
                                });
                                tariff.count = filteredInvalidParkingReservations.length;
                                tariff.parkingReservations = filteredInvalidParkingReservations;
                                removeBookedParkingTariffs.push(bookedProduct);
                            }
                        }
                    });
                }
            });
            _this.store.dispatch(new SetProductInBookingList(removeBookedParkingTariffs));
        });
    };
    PaymentLandingPageComponent.prototype.back = function () {
        var _this = this;
        this.store
            .pipe(select(getAllBookedProductsCount), first())
            .subscribe(function (productsCount) {
            return _this.router.navigate([
                "webshop/" + _this.getSelectedExhibitionId + "/" + (productsCount > 0 ? 'confirmation' : 'tickets')
            ]);
        });
    };
    return PaymentLandingPageComponent;
}());
export { PaymentLandingPageComponent };
