var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from '@store/translation/translation.actions';
export var initialState = {
    language: '',
    list: {},
    isTranslationLoaded: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.SET_TRANSLATIONS:
            var languageAndTranslationList = action.payload;
            return __assign({}, state, languageAndTranslationList);
        case ActionTypes.SET_IS_TRANSLATION_LOADED:
            var isTranslationLoaded = action.payload;
            return __assign({}, state, { isTranslationLoaded: isTranslationLoaded });
        default: {
            return state;
        }
    }
}
