import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State, getOrderUuid } from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetProductsResponse } from '@products/models/products.model';
import { RedeemVoucherResponse, RedeemedVoucher, RemoveVoucher } from '@products/models/voucher.model';
import { BookingService } from '@products/services/booking.service';
import { LoadProductService } from '@products/services/load-product.service';
import { PreferredTariffValidationService } from '@products/services/preferred-tariff-validation.service';
import { VoucherService } from '@products/services/voucher.service';
import {
  ActionTypes as ProductSelectionActionTypes,
  SetInitialProductSelectionList,
  SetPreferredProductSelectionList
} from '@store/products/product-selection/product-selection.actions';
import {
  GetPreferredProducts,
  GetProducts,
  GetWorkshopProducts,
  ActionTypes as ProductActionTypes,
  RedeemAndReleaseVoucher,
  ReleaseExpiredVoucher,
  ReleaseVoucher,
  RemoveReleasedVoucher,
  SetPreferredTariffBooking,
  SetRedeemedAndReleaseVoucher
} from '@store/products/product.actions';
import { SetInitialWorkshopProductList } from '@store/products/workshop/workshop.actions';
import { EMPTY, Observable, concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ProductEffect {
  @Effect()
  getProducts$: Observable<Action> = this.actions$.pipe(
    ofType<GetProducts>(ProductActionTypes.GET_PRODUCTS),
    switchMap(({ payload }) => {
      const { eventId, preferredTicketGroupNr, preferredTicketPersonNr } = payload;
      if (!eventId) {
        return EMPTY;
      }

      return this.loadProductService.getProducts$(eventId, preferredTicketGroupNr, preferredTicketPersonNr).pipe(
        map((productsResponse: GetProductsResponse) => {
          const products = this.loadProductService.mapGetProductResponse(productsResponse);
          return new SetInitialProductSelectionList(products);
        }),
        catchError(() => of(new SetInitialProductSelectionList({})))
      );
    })
  );

  @Effect()
  getPreferredProducts$: Observable<Action> = this.actions$.pipe(
    ofType<GetPreferredProducts>(ProductActionTypes.GET_PREFERRED_PRODUCTS),
    switchMap(({ payload }) => {
      const { eventId, preferredTicketGroupNr, preferredTicketPersonNr } = payload;
      if (!eventId) {
        return EMPTY;
      }

      return this.loadProductService.getProducts$(eventId, preferredTicketGroupNr, preferredTicketPersonNr).pipe(
        map((productsResponse: GetProductsResponse) => {
          const products = this.loadProductService.mapGetProductResponse(productsResponse);
          return new SetPreferredProductSelectionList(products);
        }),
        catchError(() => of(new SetPreferredProductSelectionList({})))
      );
    })
  );

  @Effect()
  getWorkshopProducts$: Observable<Action> = this.actions$.pipe(
    ofType<GetWorkshopProducts>(ProductActionTypes.GET_WORKSHOP_PRODUCTS),
    switchMap(({ payload }) => {
      const eventId = payload;
      if (!eventId) {
        return EMPTY;
      }

      return this.loadProductService.getWorkshopProducts$(eventId).pipe(
        map(workshopProductsResponse => new SetInitialWorkshopProductList(workshopProductsResponse)),
        catchError(() => of(new SetInitialWorkshopProductList([])))
      );
    })
  );

  @Effect()
  setPreferredProduct$: Observable<Action> = this.actions$.pipe(
    ofType<SetPreferredProductSelectionList>(ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST),
    switchMap(() => {
      const { tt, pt, amt } = this.route.snapshot.queryParams;
      if (!tt) {
        return EMPTY;
      }

      return this.preferredTariffValidationService.validatePreferredTariffCount$(+tt, +pt, +amt).pipe(
        map(
          ({ ticketTypeId, ticketPersonTypeId, validatedCount }) =>
            new SetPreferredTariffBooking({
              ticketTypeId,
              ticketPersonTypeId,
              count: validatedCount
            })
        )
      );
    })
  );

  @Effect()
  redeemVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<RedeemAndReleaseVoucher>(ProductActionTypes.REDEEM_AND_RELEASE_VOUCHER),
    switchMap(({ payload }) => {
      const { redeemVoucher, releaseVouchers, removeProductBookings } = payload;

      if (!redeemVoucher) {
        return EMPTY;
      }

      return this.voucherService.redeemVoucher$(redeemVoucher).pipe(
        switchMap((redeemVoucherResponse: RedeemVoucherResponse) => {
          if (redeemVoucherResponse.allowedWorkshopsFull) {
            return this.translateService
              .get('voucher.workshops.soldout')
              .map((voucherWorkshopSoldOutTranslation: string) => ({
                redeemVoucherResponse,
                voucherWorkshopSoldOutTranslation
              }));
          }

          return of({ redeemVoucherResponse, voucherWorkshopSoldOutTranslation: '' });
        }),
        map(({ redeemVoucherResponse, voucherWorkshopSoldOutTranslation }) => {
          if (redeemVoucherResponse.allowedWorkshopsFull) {
            this.statusBarService.setStatus(voucherWorkshopSoldOutTranslation, 'error');
          }

          const { isAnonymous } = redeemVoucher;
          const redeemedVoucher: RedeemedVoucher = !redeemVoucherResponse.allowedWorkshopsFull
            ? {
                ...redeemVoucherResponse,
                isAnonymous
              }
            : null;

          return new SetRedeemedAndReleaseVoucher({
            redeemedVoucher: redeemedVoucher,
            releaseVouchers: releaseVouchers,
            removeProductBookings: removeProductBookings
          });
        }),
        catchError(() => of(new SetRedeemedAndReleaseVoucher(null)))
      );
    })
  );

  @Effect()
  releaseVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<ReleaseVoucher>(ProductActionTypes.RELEASE_VOUCHER),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      const { eventId, orderUuid, ticketPersonId, voucherCode } = payload;
      const removeVoucher: RemoveVoucher = { eventId, orderUuid, ticketPersonId, voucherCode };

      return this.voucherService.releaseVoucher$(payload).pipe(
        map(() => new RemoveReleasedVoucher(removeVoucher)),
        catchError(() => of(new RemoveReleasedVoucher(null)))
      );
    })
  );

  @Effect()
  releaseExpiredVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<ReleaseExpiredVoucher>(ProductActionTypes.RELEASE_EXPIRED_VOUCHER),
    filter(({ payload }) => !!payload),
    concatMap(({ payload }) => {
      const removeReleasedVoucherAction$ = of(new RemoveReleasedVoucher(payload as RemoveVoucher));
      const releaseVoucher$ = this.voucherService.releaseVoucher$(payload).pipe(
        switchMap(() => EMPTY),
        catchError(() => EMPTY)
      );

      return concat(removeReleasedVoucherAction$, releaseVoucher$);
    })
  );

  @Effect({ dispatch: false })
  releaseAllContingentReservationsAndWorkshopAssignedSeats$: Observable<void> = this.actions$.pipe(
    ofType<SetInitialProductSelectionList | SetPreferredProductSelectionList | SetRedeemedAndReleaseVoucher>(
      ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST,
      ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST,
      ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER
    ),
    withLatestFrom(this.store.pipe(select(getOrderUuid))),
    switchMap(([action, orderUuid]) => {
      if (!action.payload) {
        return EMPTY;
      }

      const isNotAnonymousVoucherRedeemed =
        action.type === ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER &&
        !action.payload.redeemedVoucher.isAnonymous;

      if (isNotAnonymousVoucherRedeemed) {
        return EMPTY;
      }

      return forkJoin([
        this.bookingService.postReleaseContingentReservations$(orderUuid),
        this.bookingService.postReleaseWorkshopBookings$(orderUuid)
      ]).pipe(
        switchMap(() => EMPTY),
        catchError(() => EMPTY)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private loadProductService: LoadProductService,
    private voucherService: VoucherService,
    private translateService: TranslateService,
    private statusBarService: StatusBarService,
    private bookingService: BookingService,
    private preferredTariffValidationService: PreferredTariffValidationService
  ) {}
}
