var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { ActionTypes as WorkshopActionTypes } from '@store/products/workshop/workshop.actions';
export var initialState = {
    list: []
};
export var workshopReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case WorkshopActionTypes.SET_INITIAL_WORKSHOP_PRODUCT_LIST: {
            var workshopProductList = action.payload;
            return __assign({}, state, { list: workshopProductList });
        }
        case WorkshopActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_WORKSHOP_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
