var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddLegitimationToList, ActionTypes as LegitimationActionTypes, SetLegitimationFaxId, SetLegitimationInfoResponse, SetLegitimationStatus } from '@store/legitimation/legitimation.actions';
import { LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
var LegitimationEffect = /** @class */ (function () {
    function LegitimationEffect(legitimationDebounceTime, actions$, legitimationService) {
        var _this = this;
        this.legitimationDebounceTime = legitimationDebounceTime;
        this.actions$ = actions$;
        this.legitimationService = legitimationService;
        this.loadLegitimationList$ = this.actions$.pipe(ofType(LegitimationActionTypes.GET_LEGITIMATION_LIST), debounceTime(this.legitimationDebounceTime), switchMap(function () {
            return _this.legitimationService.getLegitimationList().pipe(map(function (legitimationList) { return new AddLegitimationToList(legitimationList); }), catchError(function () { return of(new AddLegitimationToList([])); }));
        }));
        this.loadLegitimationStatus$ = this.actions$.pipe(ofType(LegitimationActionTypes.GET_LEGITIMATION_STATUS), debounceTime(this.legitimationDebounceTime), switchMap(function (data) {
            var _a = data.payload, userId = _a.userId, eventId = _a.eventId;
            if (eventId === '' || userId === '') {
                return EMPTY;
            }
            return _this.legitimationService.getLegitimationStatus(eventId).pipe(map(function (_a) {
                var status = _a.status;
                return new SetLegitimationStatus(status);
            }), catchError(function () { return of(new SetLegitimationStatus(LegitimationStatus.OtherStatus)); }));
        }));
        this.loadLegitimationFaxId$ = this.actions$.pipe(ofType(LegitimationActionTypes.GET_LEGITIMATION_FAX_ID), debounceTime(this.legitimationDebounceTime), switchMap(function (data) {
            var _a = data.payload, eventId = _a.eventId, userId = _a.userId;
            if (eventId === null) {
                return EMPTY;
            }
            return _this.legitimationService.getLegitimationFaxId(eventId, userId).pipe(map(function (legitimationFaxInfo) { return new SetLegitimationFaxId(legitimationFaxInfo.faxId); }), catchError(function () { return of(new SetLegitimationFaxId(null)); }));
        }));
        this.postLegitimationInfo$ = this.actions$.pipe(ofType(LegitimationActionTypes.POST_LEGITIMATION_INFO), debounceTime(this.legitimationDebounceTime), switchMap(function (data) {
            if (!data.payload) {
                return EMPTY;
            }
            var _a = data.payload, feed = _a.feed, userId = _a.userId, eventId = _a.eventId;
            return _this.legitimationService.saveLegitimationInfo(feed, userId, eventId).pipe(map(function () { return new SetLegitimationInfoResponse(true); }), catchError(function () { return of(new SetLegitimationInfoResponse(false)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationFaxId$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "postLegitimationInfo$", void 0);
    return LegitimationEffect;
}());
export { LegitimationEffect };
