<ng-container *ngIf="workshopTariffHolders.length">
  <div [ngStyle]="{ visibility: bookingLoading ? 'hidden' : 'visible' }">
    <div class="select-all" (click)="setAllWorkshopBookingHolders($event)">
      <div class="decorated-checkbox">
        <input
          id="check_all_tickets"
          type="checkbox"
          [checked]="areAllWorkshopTariffHoldersAssigned"
          (click)="stopPropagation($event)"
        />
        <label for="check_all_tickets"></label>
      </div>
      <div>
        <label for="check_all_tickets">
          {{ 'ticket.assign-all' | translate }}
        </label>
      </div>
    </div>

    <div
      class="visitor"
      *ngFor="let workshopTariffHolder of workshopTariffHolders"
      (click)="setWorkshopBookingHolder($event, workshopTariffHolder)"
    >
      <div class="visitor-container">
        <div class="decorated-checkbox">
          <input
            type="checkbox"
            [id]="'visitor_workshop_' + workshop.workshopId + '_' + workshopTariffHolder.holderUuid"
            [checked]="workshopTariffHolder.isHolderAssigned"
            (click)="stopPropagation($event)"
          />
          <label [attr.for]="'visitor_workshop_' + workshop.workshopId + '_' + workshopTariffHolder.holderUuid"></label>
        </div>
        <div>
          <label [attr.for]="'visitor_workshop_' + workshop.workshopId + '_' + workshopTariffHolder.holderUuid">
            <span *ngIf="workshopTariffHolder.firstName && workshopTariffHolder.lastName">
              {{ workshopTariffHolder.firstName }} {{ workshopTariffHolder.lastName }} -
            </span>
            {{ workshopTariffHolder.ticketTypeName | translate | hideTranslation }}
            {{ workshopTariffHolder.ticketName | translate | hideTranslation }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <app-modal-window
    translateTitle="true"
    [modalWindowActive]="workshopValidationState.isWorkshopLimitPerTariffHolderReached"
    [modalWindowTitle]="'workshop.limitReached.modalWindow.title'"
  >
    <button class="button button-option" modalWindow-close (click)="closeModalWindow($event)">
      <span data-translation="workshop.modalWindow.close">
        {{ 'workshop.modalWindow.close' | translate | hideTranslation }}
      </span>
    </button>
  </app-modal-window>

  <app-modal-window
    translateTitle="true"
    [modalWindowActive]="workshopValidationState.areWorkshopsOverlapping"
    [modalWindowTitle]="'workshop.modalWindow.title'"
  >
    <button class="button button-option" modalWindow-close (click)="closeModalWindow($event)">
      <span data-translation="workshop.modalWindow.close">
        {{ 'workshop.modalWindow.close' | translate | hideTranslation }}
      </span>
    </button>
  </app-modal-window>

  <app-loader *ngIf="isWorkshopLoading" isFixed="true"></app-loader>
</ng-container>
