var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as fromRoot from '@app/app.reducer';
import * as stepsActions from '@store/step-forms/steps-forms.actions';
import { FormComponent } from './form/form.component';
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { consoleLog } from '@shared/app-utils';
import { FormsService } from '@shared/forms/forms.service';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { TextOrDropdownInputTypes } from '@store/helpers/helper.interface';
import { HelperService } from '@store/helpers/helper.service';
import { SetActiveSlide } from '@store/products/holder/holder.actions';
import { getActiveSlide, getTariffsBookingByOrder, getTicketHolderQuestionnaireInputs } from '@store/products/holder/holder.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import cloneDeep from 'lodash.clonedeep';
import { TariffStatusService } from '../../products/services/tariff-status.service';
var OrderTicketsComponent = /** @class */ (function () {
    function OrderTicketsComponent(store, route, windowSizeService, helperService, changeDetectorRef, formsService, tariffService, translateService) {
        var _this = this;
        this.store = store;
        this.route = route;
        this.windowSizeService = windowSizeService;
        this.helperService = helperService;
        this.changeDetectorRef = changeDetectorRef;
        this.formsService = formsService;
        this.tariffService = tariffService;
        this.translateService = translateService;
        this.isInputChanged$ = new Observable();
        this.isVisitorQuestionnaireShown = new EventEmitter();
        this.copyAddressChecked = false;
        this.holdersBookedTariff = [];
        this.checkedSlideIndex = null;
        this.shouldDisplayCheckbox = true;
        this.slideWithBuyerVisitor = null;
        this.shouldDisableBuyerVisitorCheckbox = false;
        this.isBuyerVisitorChecked = false;
        this.modalWindowOpen = false;
        this.AppConstants = AppConstants;
        this.isNextandPreviousButtonDisabled = false;
        this.uniqueVisitorCheckType = null;
        this.ticketHolderArray = [];
        this.canDeleteTicketInfo = false;
        this.isVisitorQuestionnaireEnabled = false;
        this.subscriptions = new Subscription();
        this.ticketHoldersNumber = 0;
        this.coppiedHolderDataIndexes = [];
        this.hasTicketHolderEmailInput = false;
        this.isTicketHolderEmailMandatory = false;
        this.hasBuyerInfoChanged = false;
        this.isBuyerInfoValid = false;
        this.hasMultipleTicketHolders = false;
        //duplicates check:
        this.needsDuplicatesCheck = true;
        this.showDuplicatesWarning = false;
        this.showDuplicatedWarningAlreadyClosed = false;
        this.inputNamesForDuplicateSearch = [['firstName', 'lastName'], ['email']];
        this.inputNamesForDuplicatesCheck = [];
        this.isDuplicatesCheckDone$ = new BehaviorSubject(false);
        //tickets over limit check:
        this.needsTicketsOverLimitCheck = true;
        this.showTicketLimitWarning = false;
        this.showTicketLimitWarningAlreadyClosed = false;
        this.inputNamesForTicketsOverlimitChecks = ['email'];
        this.isTicketsOverLimitCheckDone$ = new BehaviorSubject(false);
        //validity check results:
        this.duplicatesList = [];
        this.ticketsOverLimitList = [];
        this.triggerValidation$ = new BehaviorSubject(false);
        this.TicketSendingOptions = TicketSendingOptions;
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
        this.personaliseFormKey = this.PersonaliseFormsKeys.ticketHolder[0];
        this.ticketHolder_ = this.PersonaliseFormsKeys.ticketHolder[1];
        this.subscriptions.add(this.store.pipe(select(fromRoot.getSelectedExhibitionId)).subscribe(function (eventId) { return _this.selectedExhibitionId = eventId; }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getExhibitionSettings), filter(function (data) { return !!data; }))
            .subscribe(function (settings) {
            _this.exhibitionSettings = settings;
            _this.needsTicketsOverLimitCheck = _this.exhibitionSettings.ticketLimitPerEmail > 0 && _this.inputNamesForTicketsOverlimitChecks.length > 0;
        }));
        this.isSelfRegistrationEnabled = this.helperService.isSelfregistration();
        this.subscriptions.add(this.store.pipe(select(fromRoot.getCoppiedHoldersIndexes)).subscribe(function (item) { return _this.coppiedHolderDataIndexes = !!item ? item.slice() : []; }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getTicketHolderInputSets), 
        // only build the forms when number of tickets change. Form rerender logic is handled in every form itself
        filter(function (forms) { return !!forms && _this.ticketHoldersNumber !== forms.length; }))
            .subscribe(function (forms) {
            _this.ticketHoldersNumber = forms.length;
            _this.ticketHolderInputSets = forms;
            _this.ticketHolderArray = forms.map(function (form) {
                return form.inputSet.list.reduce(function (acc, input) {
                    acc[input.key] = !!input.value ? input.value : "";
                    acc['holderUuid'] = form.holderUuid;
                    //check if ticket holder form has e-mail input:
                    if (input.key === 'email') {
                        _this.hasTicketHolderEmailInput = true;
                        //check if ticket holder e-mail input is mandatory:
                        if (input.required) {
                            _this.isTicketHolderEmailMandatory = true;
                        }
                    }
                    return acc;
                }, {});
            });
        }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getTicketHoldersValidity), filter(function (ticketHoldersValidity) { return !!ticketHoldersValidity; }), distinctUntilChanged(function (prev, curr) { return JSON.stringify(prev) === JSON.stringify(curr); }))
            .subscribe(function (ticketHoldersValidity) {
            //if all ticket holders (and buyer info etc.) are valid the next step will be enabled:
            _this.ticketHoldersValidity = ticketHoldersValidity;
        }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getTicketHoldersFormsValidity), filter(function (ticketHoldersFormsValidity) { return !!ticketHoldersFormsValidity; }), distinctUntilChanged(function (prev, curr) { return JSON.stringify(prev) === JSON.stringify(curr); }))
            .subscribe(function (ticketHoldersFormsValidity) {
            //this tells us if ticket holder Angular forms (FormGroups) are valid:
            //(if a ticket holder form is valid and it passes all required validations (duplicates, tickets over limit) that ticket holder will be marked as valid)
            _this.ticketHoldersFormsValidity = ticketHoldersFormsValidity.slice();
            ticketHoldersFormsValidity.forEach(function (ticketHolderFormValidity, index) {
                var ticketHolderValidity = _this.ticketHoldersValidity[index];
                if (!_this.needsDuplicatesCheck && !_this.needsTicketsOverLimitCheck) {
                    //as we don't need to check/validate anything we can set ticket holders statuses in correspondence with their forms statuses:
                    //(it won't be done in forms.service)
                    if (ticketHolderFormValidity !== ticketHolderValidity) {
                        _this.setTicketHolderValidity(index, ticketHolderFormValidity);
                    }
                }
                else {
                    //if there are some invalid ticket holder forms set those ticket holders to not valid:
                    if (!ticketHolderFormValidity && ticketHolderValidity) {
                        _this.setTicketHolderValidity(index, false);
                    }
                }
            });
        }));
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this.triggerValidation$.filter(function (triggerValidation) { return triggerValidation !== null; })
        ])
            .pipe(filter(function (_a) {
            var ticketHolderInputSets = _a[0];
            return (_this.needsDuplicatesCheck || _this.needsTicketsOverLimitCheck) && !!ticketHolderInputSets && !!_this.formComponent;
        }), debounceTime(500)
        //distinctUntilChanged()
        )
            .subscribe(function (_a) {
            var ticketHolderInputSets = _a[0];
            //here we get the real value of BehaviorSubject as the value from combineLatest will not be up to date (we filter out null values)
            //true means forced validation will be performed even if no inputs were changed:
            var forceValidation = _this.triggerValidation$.getValue();
            //we don't want this change of BehaviorSubject to null to emit and return back here so we're filtering it in combineLatest function.
            forceValidation && _this.triggerValidation$.next(null);
            //perform ticket holder forms validations if needed:
            _this.checkTicketHolders(ticketHolderInputSets, forceValidation);
        }));
        this.subscriptions.add(combineLatest([
            this.isDuplicatesCheckDone$,
            this.isTicketsOverLimitCheckDone$
        ])
            .filter(function (_a) {
            var isDuplicatesCheckDone = _a[0], isTicketsOverLimitCheckDone = _a[1];
            return isDuplicatesCheckDone && isTicketsOverLimitCheckDone;
        })
            .subscribe(function () {
            //when all ticket holders checks are done set ticket holders validity (if ticket holders forms are valid):
            _this.ticketHoldersFormsValidity.forEach(function (_, index) {
                if (!_this.ticketHoldersValidity[index] && _this.areTicketHolderChecksOk(index)) {
                    //if ticket holder's form is valid and all validations have passed set ticket holder as valid:
                    _this.setTicketHolderValidity(index, true);
                }
            });
        }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getBuyerInfo), filter(function (buyerInputs) { return !!buyerInputs; }))
            .subscribe(function (buyerInputs) {
            _this.buyerInputs = buyerInputs.list;
            _this.hasBuyerInfoChanged = false;
            if (_this.needsTicketsOverLimitCheck && !!buyerInputs && !!buyerInputs.updatedInputs) {
                _this.hasBuyerInfoChanged = buyerInputs.updatedInputs.some(function (updatedInput) { return _this.inputNamesForTicketsOverlimitChecks.includes(updatedInput); });
                if (!_this.isTicketHolderEmailMandatory && _this.hasBuyerInfoChanged) {
                    //if ticket holder e-mail field is not mandatory and buyer info e-mail field has changed revalidate ticket holders:
                    //(if a ticket holder e-mail is not provided we have to take the e-mail from the buyer)
                    _this.triggerValidation$.next(true);
                }
            }
        }));
        this.subscriptions.add(this.store.pipe(select(getTariffsBookingByOrder)).subscribe(function (tariffBookingByOrder) {
            _this.holdersBookedTariff = tariffBookingByOrder;
            _this.hasMultipleTicketHolders = _this.holdersBookedTariff.length > 1;
        }));
        this.store.pipe(select(fromRoot.getAddressCheckbox), first(function (data) { return !!data; }))
            .subscribe(function (addressCheckbox) {
            var checkedSlideIndex = addressCheckbox.checkedSlideIndex, isAddressCopied = addressCheckbox.isAddressCopied;
            _this.checkedSlideIndex = checkedSlideIndex;
            var checked = _this.canDeleteTicketInfo = isAddressCopied;
            // Uncheck "Copy all data from the buyer" and set the checkedSlideIndex to null
            // if ticket holder doesn't have any fields buyer data can be copied into.
            var listLength = _this.ticketHolderInputSetsOfSlideIndexLength(_this.ticketHolderInputSets, _this.checkedSlideIndex);
            if (listLength <= 2) {
                _this.copyAddressChecked = false;
                _this.checkedSlideIndex = null;
                checked = false;
                _this.store.dispatch(new stepsActions.SetAddressCheckbox({
                    checkedSlideIndex: null,
                    isAddressCopied: false
                }));
            }
            // if first slide is checked on load, check it straight away
            if (checked && checkedSlideIndex === _this.activeSlideIndex) {
                _this.copyAddressChecked = true;
            }
            if (checked && checkedSlideIndex !== _this.activeSlideIndex) {
                _this.shouldDisplayCheckbox = false;
            }
        });
        // show duplicities warning only if enabled in admin client and if firstName and lastName or email inputs are checked as visible
        combineLatest([
            this.store.pipe(select(fromRoot.uniqueVisitorCheckType)),
            this.store.pipe(select(fromRoot.getExhibitionSettings))
        ])
            .first()
            .subscribe(function (_a) {
            var uniqueVisitorCheckType = _a[0], exhibitionSettings = _a[1];
            _this.uniqueVisitorCheckType = uniqueVisitorCheckType;
            _this.needsDuplicatesCheck = uniqueVisitorCheckType !== 'ignoreDuplicates' && _this.inputNamesForDuplicateSearch.length > 0;
            if (_this.needsDuplicatesCheck) {
                _this.inputNamesForDuplicatesCheck = [];
                var ticketOwnerSettings = exhibitionSettings.ticketOwnerSettings.fieldSettings;
                // create copy so we can iterate through local object and delete data (if needed) in class object
                var inputNamesForDupliSrcCopy = _this.inputNamesForDuplicateSearch.slice();
                for (var i = inputNamesForDupliSrcCopy.length - 1; i >= 0; i--) {
                    var currInputNameForDupliSrc = inputNamesForDupliSrcCopy[i];
                    var _loop_1 = function (j) {
                        var inputName = currInputNameForDupliSrc[j];
                        // check if there's input key in settings that matches inputName
                        var inputKey = Object.keys(ticketOwnerSettings).find(function (item) { return item.toLocaleLowerCase() === inputName.toLocaleLowerCase(); });
                        // if there's no input key matched in settings or there is input key but it's not visible, delete array from inputNamesForDuplicateSearch and break inner loop
                        if (!inputKey || !ticketOwnerSettings[inputKey].isVisible) {
                            _this.inputNamesForDuplicateSearch.splice(i, 1);
                            return "break";
                        }
                        // if all inputs from currInputNameForDupliSrc exist in settings and are visible, add them to inputNamesForDuplicatesCheck
                        if (j === currInputNameForDupliSrc.length - 1) {
                            currInputNameForDupliSrc.forEach(function (element) {
                                _this.inputNamesForDuplicatesCheck.push(element);
                            });
                        }
                    };
                    for (var j = 0; j < currInputNameForDupliSrc.length; j++) {
                        var state_1 = _loop_1(j);
                        if (state_1 === "break")
                            break;
                    }
                }
                // if there's no input key left in inputNamesForDuplicateSearch set needsDuplicatesCheck to false
                if (_this.inputNamesForDuplicateSearch.length === 0) {
                    _this.needsDuplicatesCheck = false;
                }
            }
        });
        this.subscriptions.add(this.store.pipe(select(getActiveSlide))
            .subscribe(function (index) {
            _this.activeSlideIndex = index;
            _this.copyAddressChecked = _this.checkedSlideIndex === index;
            //Don't display Checkbox if there aren't fields visible to copy the buyer data into.
            var listLength = _this.ticketHolderInputSetsOfSlideIndexLength(_this.ticketHolderInputSets, _this.activeSlideIndex);
            _this.shouldDisplayCheckbox = listLength > 2 && (_this.copyAddressChecked || _this.checkedSlideIndex === null);
            // US2870 - buyerVisitor questionnaire
            if (_this.slideWithBuyerVisitor !== null) {
                _this.isBuyerVisitorChecked = _this.slideWithBuyerVisitor === index;
                _this.shouldDisableBuyerVisitorCheckbox =
                    _this.isBuyerVisitorChecked || _this.slideWithBuyerVisitor === null;
                if (_this.activeSlideIndex === _this.slideWithBuyerVisitor) {
                    _this.isVisitorQuestionnaireShown.emit(true);
                }
                else {
                    _this.isVisitorQuestionnaireShown.emit(false);
                }
            }
            _this.triggerValidation$.next(false);
            if (_this.isBuyerInfoValid) {
                //focus on first empty ticket holder input only if buyer info form is valid,
                //otherwise we should focus on first empty buyer info form input:
                _this.focusFirstEmptyRequiredInput();
            }
        }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getBuyerInfoValidity), distinctUntilChanged(function (prev, curr) { return prev === curr; }))
            .subscribe(function (buyerInfoValidity) { return _this.isBuyerInfoValid = buyerInfoValidity; }));
    }
    ;
    OrderTicketsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.queryParams['scroll']) {
            setTimeout(function () {
                var carousel = _this.helperService.appEl.querySelector('#holder-carousel');
                _this.windowSizeService.scrollToElement(carousel, 0, 50, 0.3);
            }, 500);
        }
        this.subscriptions.add(this.helperService.voteYesNo$.filter(function (item) { return item && _this.canDeleteTicketInfo; }).subscribe(function () {
            _this.copyAddress(false, true);
            _this.copyAddressChecked = false;
        }));
        this.subscriptions.add(this.isInputChanged$.subscribe(function () {
            if (_this.canDeleteTicketInfo) {
                _this.copyAddress(false, true);
                _this.copyAddressChecked = false;
            }
        }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.getBuyerVisitorCheckbox), filter(function (data) { return !!data; }))
            .subscribe(function (buyerVisitorCheckbox) {
            var buyerVisitorCheckedSlideIndex = buyerVisitorCheckbox.buyerVisitorCheckedSlideIndex, isBuyerVisitorChecked = buyerVisitorCheckbox.isBuyerVisitorChecked;
            _this.slideWithBuyerVisitor = buyerVisitorCheckedSlideIndex;
            _this.isBuyerVisitorChecked = isBuyerVisitorChecked;
            if (_this.isBuyerVisitorChecked) {
                _this.shouldDisableBuyerVisitorCheckbox = _this.activeSlideIndex === _this.slideWithBuyerVisitor;
                _this.isVisitorQuestionnaireShown.emit(_this.shouldDisableBuyerVisitorCheckbox);
            }
        }));
        this.subscriptions.add(this.store.pipe(select(getTicketHolderQuestionnaireInputs), filter(function (data) { return !!data; }))
            .subscribe(function (ticketHolderQ) { return _this.isVisitorQuestionnaireEnabled = ticketHolderQ !== null && ticketHolderQ.length > 0 &&
            _this.ticketSelectedSendingOption === TicketSendingOptions.TicketRetrievalLink; }));
    };
    OrderTicketsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    OrderTicketsComponent.prototype.buyerVisitorAdditionalData = function (isBuyerVisitor) {
        this.isVisitorQuestionnaireShown.emit(isBuyerVisitor);
        this.modalWindowOpen = false;
        if (isBuyerVisitor) {
            this.isBuyerVisitorChecked = true;
        }
        else {
            document.querySelector('input#buyerVisitor').checked = false;
        }
        this.slideWithBuyerVisitor = isBuyerVisitor ? this.activeSlideIndex : null;
        this.store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
            buyerVisitorCheckedSlideIndex: this.slideWithBuyerVisitor,
            isBuyerVisitorChecked: isBuyerVisitor,
            showVisitorQuestionnaire: this.isBuyerVisitorChecked
        }));
    };
    OrderTicketsComponent.prototype.buyerVisitorPopup = function () {
        if (this.isBuyerVisitorChecked) {
            this.isVisitorQuestionnaireShown.emit(false);
            this.store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
                buyerVisitorCheckedSlideIndex: null,
                isBuyerVisitorChecked: false,
                showVisitorQuestionnaire: false
            }));
            this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
            this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.visitorQuestionnaire);
        }
        else {
            this.modalWindowOpen = true;
        }
    };
    //#region Event handlers for FormComponent events:
    OrderTicketsComponent.prototype.updatedTicketHolderInput = function (data) {
        var _this = this;
        setTimeout(function () {
            var inputs = data.inputs, currentIndex = data.currentIndex;
            var index = currentIndex !== null ? currentIndex : _this.activeSlideIndex;
            var updatedTicketHolderInfo = inputs.reduce(function (acc, input) {
                acc[input.key] = !!input.value ? input.value : "";
                return acc;
            }, {});
            var currentTicketHolderInfo = _this.ticketHolderArray[index];
            _this.ticketHolderArray[index] = __assign({}, currentTicketHolderInfo, updatedTicketHolderInfo);
        }, 0);
    };
    OrderTicketsComponent.prototype.handleFormValueChange = function (formComponent) {
        if (formComponent) {
            var ticketHolderArrayHelper = this.ticketHolderArray.slice();
            var form = formComponent.form;
            var index = ticketHolderArrayHelper.findIndex(function (ticketHolder) { return ticketHolder.holderUuid === formComponent.holderUuid; });
            ticketHolderArrayHelper[index] = __assign({}, form.value, { holderUuid: ticketHolderArrayHelper[index].holderUuid });
            this.ticketHolderArray = ticketHolderArrayHelper.slice();
            if (this.needsDuplicatesCheck || this.needsTicketsOverLimitCheck) {
                //if validations are required and some of the inputs required for those validations are changed we've to invalidate the current ticket holder:
                var needsDuplicatesCheck = this.needsDuplicatesCheck && this.ticketHolderNeedsRevalidation(index, this.inputNamesForDuplicatesCheck);
                var needsTicketsOverLimitCheck = this.needsTicketsOverLimitCheck && this.ticketHolderNeedsRevalidation(index, this.inputNamesForTicketsOverlimitChecks);
                if (needsDuplicatesCheck || needsTicketsOverLimitCheck) {
                    this.ticketHoldersValidity[index] && this.setTicketHolderValidity(index, false);
                }
                else {
                    if (!form.invalid && !this.ticketHoldersValidity[index] && this.areTicketHolderChecksOk(index, form)) {
                        this.setTicketHolderValidity(index, true);
                    }
                }
            }
        }
    };
    //#endregion
    //#region Ticket holder validations
    OrderTicketsComponent.prototype.checkTicketHolders = function (ticketHolderInputSets, forceValidation) {
        var _this = this;
        if (forceValidation === void 0) { forceValidation = false; }
        this.helperService.triggerCallbackOnceFormValidationIsDone(this.formComponent.form, function () {
            //callback after FormGroup validations have completed:
            _this.store.pipe(select(fromRoot.getTicketHoldersFormsValidity), first())
                .subscribe(function (ticketHoldersFormsValidity) {
                var data = { ticketHolderInputSets: ticketHolderInputSets.slice(), ticketHoldersFormsValidity: ticketHoldersFormsValidity.slice() };
                var index = _this.ticketHolderArray.findIndex(function (ticketHolder) { return ticketHolder.holderUuid === _this.formComponent.holderUuid; });
                data.ticketHoldersFormsValidity[index] = !_this.formComponent.form.invalid;
                _this.ticketHoldersFormsValidity = data.ticketHoldersFormsValidity.slice();
                if (_this.needsDuplicatesCheck || _this.needsTicketsOverLimitCheck) {
                    //define ticketHolderArray based on ticketHolderInputSets:
                    var ticketHolderArray = data.ticketHolderInputSets.map(function (form, index) {
                        return form.inputSet.list.reduce(function (acc, input) {
                            acc[input.key] = !!input.value ? input.value : "";
                            acc['holderUuid'] = form.holderUuid;
                            acc['isValid'] = data.ticketHoldersFormsValidity[index];
                            return acc;
                        }, {});
                    });
                    _this.ticketHolderArray = ticketHolderArray.slice();
                    _this.isDuplicatesCheckDone$.next(false);
                    _this.isTicketsOverLimitCheckDone$.next(false);
                    //perform duplicates check if needed:
                    if (_this.needsDuplicatesCheck &&
                        (!!forceValidation || _this.ticketHoldersNeedCheck(data, _this.inputNamesForDuplicatesCheck))) {
                        _this.checkForDuplicates(ticketHolderArray);
                    }
                    else {
                        _this.isDuplicatesCheckDone$.next(true);
                    }
                    //perform ticket over limit check if needed:
                    if (_this.needsTicketsOverLimitCheck &&
                        (!!forceValidation || (!_this.isTicketHolderEmailMandatory && _this.hasBuyerInfoChanged && _this.isBuyerInfoValid) || _this.ticketHoldersNeedCheck(data, _this.inputNamesForTicketsOverlimitChecks))) {
                        _this.checkForTicketsOverLimit(ticketHolderArray);
                    }
                    else {
                        _this.isTicketsOverLimitCheckDone$.next(true);
                    }
                    _this.hasBuyerInfoChanged = false;
                    _this.prevDataToCheck = cloneDeep(data);
                }
            });
        });
    };
    // NOTE: Displaying correct duplicity warning is dependable on identical order of combinedHoldersData and ticketHolderArray if it gets sorted at some point, this feature will probably not work
    OrderTicketsComponent.prototype.checkForDuplicates = function (ticketHolderArray) {
        var _this = this;
        this.isDuplicatesCheckDone$.next(false);
        var duplicatesList = [];
        var tmpDuplicatesList = [];
        this.inputNamesForDuplicateSearch.forEach(function (inputNames) {
            var duplicateTranslationKey = _this.duplicateKeyName(inputNames);
            // join input values of desired combination to a single string for a comparsion
            var combinedHoldersData = inputNames.reduce(function (acc, curr) {
                var inputsValues = _this.getTicketHolderInputsValue(ticketHolderArray, curr);
                acc = inputsValues.map(function (value, i) {
                    var finalValue;
                    // null if input is not filled
                    if (!value || (value && acc[i] === null)) {
                        finalValue = null;
                    }
                    else if (acc[i]) {
                        finalValue = acc[i].toString() + '_' + value.toString();
                    }
                    else {
                        finalValue = value.toString();
                    }
                    return !!finalValue ? finalValue.toLocaleLowerCase() : null;
                });
                return acc;
            }, [] // e.g. [martinlabik, martinlabik]
            );
            combinedHoldersData.forEach(function (inputVal, index) {
                var _a;
                if (!inputVal) {
                    return;
                }
                // Find if there is a duplicate or not
                if (combinedHoldersData.indexOf(inputVal, index + 1) > -1) {
                    var duplicateIndexes = combinedHoldersData.reduce(function (acc, curr, i) {
                        var valuesEqual = _this.areStringsEqual(curr, inputVal);
                        if (valuesEqual) {
                            acc.push(i);
                            duplicatesList.push(i);
                        }
                        return acc;
                    }, []);
                    var duplicate_1 = (_a = {},
                        _a[duplicateTranslationKey] = {
                            duplicateIndexes: duplicateIndexes
                        },
                        _a);
                    if (duplicateIndexes.length > 0) {
                        _this.showDuplicatesWarning = true;
                    }
                    //add duplicate information to duplicatesList:
                    duplicate_1[duplicateTranslationKey].duplicateIndexes.forEach(function (duplicateIndex) {
                        if (!!tmpDuplicatesList && !!tmpDuplicatesList[duplicateIndex]) {
                            tmpDuplicatesList[duplicateIndex] = __assign({}, tmpDuplicatesList[duplicateIndex], duplicate_1);
                        }
                        else {
                            tmpDuplicatesList[duplicateIndex] = duplicate_1;
                        }
                        if (_this.uniqueVisitorCheckType === 'mustBeUnique') {
                            _this.setTicketHolderValidity(duplicateIndex, false);
                        }
                    });
                }
            });
        });
        this.duplicatesList = tmpDuplicatesList.slice();
        this.isDuplicatesCheckDone$.next(true);
    };
    OrderTicketsComponent.prototype.checkForTicketsOverLimit = function (ticketHolderArray) {
        var _this = this;
        this.isTicketsOverLimitCheckDone$.next(false);
        var ticketHolderArrayHelper = ticketHolderArray.slice();
        var buyerEmail;
        //if e-mail isn't mandatory on ticket holders, get the one from the buyer info:
        if (!this.isTicketHolderEmailMandatory && !!this.buyerInputs && !!this.buyerInputs.find(function (input) { return input.key === 'email'; })) {
            buyerEmail = this.buyerInputs.find(function (input) { return input.key === 'email'; }).value;
        }
        var ticketsOverLimitChecks = [];
        ticketHolderArrayHelper.forEach(function (_, index) { return ticketsOverLimitChecks.push({ index: index, isChecked: false, isInvalid: false }); });
        if (this.hasTicketHolderEmailInput || !!buyerEmail) {
            var emailDataList_1 = [];
            ticketHolderArrayHelper.filter(function (ticketHolder) { return ticketHolder.isValid; }).forEach(function (ticketHolder) {
                var ticket = _this.holdersBookedTariff.find(function (holderTicket) { return holderTicket.holderUuid === ticketHolder.holderUuid; });
                var index = ticketHolderArrayHelper.findIndex(function (ticketHolderX) { return ticketHolderX.holderUuid === ticketHolder.holderUuid; });
                if (_this.hasTicketHolderEmailInput) {
                    //e-mail is present on ticket holder form:
                    if (!!ticketHolder.email) {
                        //we have ticket holder e-mail:
                        emailDataList_1.push({ index: index, email: ticketHolder.email, ticketPersonId: ticket.ticketPersonId, amount: 1 });
                    }
                    else {
                        //we don't have ticket holder e-mail
                        if (_this.isTicketHolderEmailMandatory) {
                            //ticket holder e-mail field is mandatory so we have to invalidate this ticket holder:
                            _this.setTicketHolderValidity(index, false);
                        }
                        else if (!!buyerEmail) {
                            //ticket holder e-mail field isn't mandatory so we'll take e-mail from the buyer:
                            emailDataList_1.push({ index: index, email: buyerEmail, ticketPersonId: ticket.ticketPersonId, amount: 1 });
                        }
                    }
                }
                else if (!!buyerEmail) {
                    emailDataList_1.push({ index: index, email: buyerEmail, ticketPersonId: ticket.ticketPersonId, amount: 1 });
                }
            });
            if (emailDataList_1.length) {
                var request = {
                    eventId: this.selectedExhibitionId,
                    isSelfRegistration: this.isSelfRegistrationEnabled,
                    items: emailDataList_1
                };
                consoleLog("CheckDailyTicketPerEmailLimit request: " + JSON.stringify(request));
                this.tariffService.checkDailyTicketPerEmailLimit$(request).subscribe(function (response) {
                    consoleLog("CheckDailyTicketPerEmailLimit response: " + JSON.stringify(response));
                    if (!response.isValid) {
                        //some ticket holders are invalid, over ticket limit:
                        _this.showTicketLimitWarning = true;
                        emailDataList_1.forEach(function (ticketHolder) {
                            if (!!ticketHolder.email && !!response.errors.find(function (error) { return error.values[0] === ticketHolder.email; })) {
                                _this.setTicketHolderValidity(ticketHolder.index, false);
                                ticketsOverLimitChecks[ticketHolder.index] = __assign({}, ticketsOverLimitChecks[ticketHolder.index], { isChecked: true, isInvalid: true });
                            }
                            else {
                                ticketsOverLimitChecks[ticketHolder.index] = __assign({}, ticketsOverLimitChecks[ticketHolder.index], { isChecked: true, isInvalid: false });
                            }
                        });
                    }
                    else {
                        //all ticket holders are valid, none over ticket limit:
                        emailDataList_1.forEach(function (ticketHolder) {
                            ticketsOverLimitChecks[ticketHolder.index] = __assign({}, ticketsOverLimitChecks[ticketHolder.index], { isChecked: true, isInvalid: false });
                        });
                    }
                    _this.ticketsOverLimitList = ticketsOverLimitChecks.slice();
                    _this.isTicketsOverLimitCheckDone$.next(true);
                }, function (err) {
                    consoleLog("CheckDailyTicketPerEmailLimit error: " + JSON.stringify(err));
                    _this.ticketsOverLimitList = ticketsOverLimitChecks.slice();
                    _this.isTicketsOverLimitCheckDone$.next(true);
                });
                return;
            }
        }
        this.ticketsOverLimitList = ticketsOverLimitChecks.slice();
        this.isTicketsOverLimitCheckDone$.next(true);
    };
    //helper functions:
    OrderTicketsComponent.prototype.ticketHoldersNeedCheck = function (currentValidationModel, inputNamesForChecks) {
        if (!this.prevDataToCheck) {
            return true;
        }
        var areDifferent = false;
        var ticketHolderInputSetsPrev = this.prevDataToCheck.ticketHolderInputSets.slice();
        var ticketHolderInputSetsCurr = currentValidationModel.ticketHolderInputSets.slice();
        var ticketHoldersValidityPrev = this.prevDataToCheck.ticketHoldersFormsValidity.slice();
        var ticketHoldersValidityCurr = currentValidationModel.ticketHoldersFormsValidity.slice();
        for (var indexA = 0; indexA < ticketHoldersValidityCurr.length; indexA++) {
            if (ticketHoldersValidityCurr[indexA] && !ticketHoldersValidityPrev[indexA]) {
                //ticket holder form was invalid, now it's valid, validations are needed:
                return true;
            }
        }
        for (var indexA = 0; indexA < ticketHolderInputSetsCurr.length; indexA++) {
            var ticketHolderInputSet = ticketHolderInputSetsCurr[indexA];
            var _loop_2 = function (indexB) {
                var inputName = inputNamesForChecks[indexB];
                var currentInput = ticketHolderInputSet.inputSet.list.find(function (inputs) { return inputs.key === inputName; });
                var previousInput = ticketHolderInputSetsPrev[indexA].inputSet.list.find(function (inputs) { return inputs.key === inputName; });
                if (currentInput && previousInput && !this_1.areStringsEqual(currentInput.value, previousInput.value, true)) {
                    //relevant ticket holder inputs are changed, validations are needed:
                    areDifferent = true;
                    return "break";
                }
            };
            var this_1 = this;
            for (var indexB = 0; indexB < inputNamesForChecks.length; indexB++) {
                var state_2 = _loop_2(indexB);
                if (state_2 === "break")
                    break;
            }
            if (areDifferent) {
                return true;
            }
        }
        return false;
    };
    OrderTicketsComponent.prototype.duplicateKeyName = function (inputsCombination) {
        return inputsCombination && inputsCombination.join('-');
    };
    OrderTicketsComponent.prototype.getTicketHolderInputsValue = function (ticketHolderArray, inputName) {
        var ticketHolderInputsArray = [];
        ticketHolderArray.map(function (array) {
            ticketHolderInputsArray.push(array[inputName]);
        });
        return ticketHolderInputsArray;
    };
    OrderTicketsComponent.prototype.areStringsEqual = function (string1, string2, ignoreCase) {
        if (ignoreCase === void 0) { ignoreCase = false; }
        //convert undefined and null strings into empty strings:
        string1 = !string1 ? '' : string1;
        string2 = !string2 ? '' : string2;
        if (ignoreCase) {
            //if necessary convert strings to lower case:
            string1 = string1.toLocaleLowerCase();
            string2 = string2.toLocaleLowerCase();
        }
        //compare the strings (localeCompare returns 0 if strings are equal):
        return String(string1).localeCompare(string2) === 0;
    };
    OrderTicketsComponent.prototype.areTicketHolderChecksOk = function (index, form) {
        if (form === void 0) { form = null; }
        var isTicketHolderFormValid = !!form ? !form.invalid : this.ticketHoldersFormsValidity[index];
        var isTicketHolderDuplicate = this.duplicatesList[index];
        var hasTicketsOverLimit = this.ticketsOverLimitList[index];
        return isTicketHolderFormValid &&
            (!isTicketHolderDuplicate || this.uniqueVisitorCheckType !== 'mustBeUnique') &&
            (!hasTicketsOverLimit || (hasTicketsOverLimit.isChecked && !hasTicketsOverLimit.isInvalid));
    };
    OrderTicketsComponent.prototype.setTicketHolderValidity = function (index, isValid, setFormValidity) {
        if (isValid === void 0) { isValid = false; }
        if (setFormValidity === void 0) { setFormValidity = false; }
        var holderUuid = this.ticketHolderArray[index].holderUuid;
        var ticketHolderWithUuid = this.ticketHolder_ + holderUuid;
        var stepsFormsActionName = [this.personaliseFormKey, ticketHolderWithUuid];
        this.ticketHoldersValidity[index] = isValid;
        this.formsService.setFormValidity(isValid, null, stepsFormsActionName);
        if (setFormValidity && !isValid) {
            this.formsService.setTicketHolderFormValidity(isValid, stepsFormsActionName);
        }
    };
    OrderTicketsComponent.prototype.ticketHolderNeedsRevalidation = function (index, inputNamesForChecks) {
        if (!this.prevDataToCheck) {
            return true;
        }
        var areDifferent = false;
        var currentHolder = this.ticketHolderArray[index];
        var _loop_3 = function (indexA) {
            var inputName = inputNamesForChecks[indexA];
            var currentInputValue = currentHolder[inputName];
            var previousInput = this_2.prevDataToCheck.ticketHolderInputSets[index].inputSet.list.find(function (inputs) { return inputs.key === inputName; });
            if (previousInput && !this_2.areStringsEqual(currentInputValue, previousInput.value, true)) {
                //relevant ticket holder inputs are changed so revalidation is needed:
                areDifferent = true;
                return "break";
            }
        };
        var this_2 = this;
        for (var indexA = 0; indexA < inputNamesForChecks.length; indexA++) {
            var state_3 = _loop_3(indexA);
            if (state_3 === "break")
                break;
        }
        return areDifferent;
    };
    //#endregion
    OrderTicketsComponent.prototype.copyAddress = function (copyEverythingIsChecked, buyerInfoChanged) {
        var _this = this;
        var debounceTime = copyEverythingIsChecked ? 500 : 0;
        // US2870 - if 'copy all data' with sending option being 'ticketRetrivalLink' than we immediately know that buyer is also a visitor
        if (copyEverythingIsChecked && this.isVisitorQuestionnaireEnabled && !this.isBuyerVisitorChecked) {
            this.buyerVisitorPopup();
        }
        else if (!copyEverythingIsChecked && this.isBuyerVisitorChecked) {
            this.store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
                buyerVisitorCheckedSlideIndex: null,
                isBuyerVisitorChecked: false,
                showVisitorQuestionnaire: false
            }));
            this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
            this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.visitorQuestionnaire);
        }
        // get actual state of ticketHolder forms
        this.store.pipe(select(fromRoot.getTicketHolderInputSets))
            .debounceTime(debounceTime)
            .first(function (data) { return !!data; })
            // only build the forms when number of tickets change. Form rerender logic is handled in every form itself
            .subscribe(function (ticketHolderInputSets) {
            _this.ticketHolderInputSets = ticketHolderInputSets;
            var addressMap = {
                address: 'address',
                street: 'street',
                country: 'country',
                zipCode: 'zipCode',
                company: 'company',
                city: 'city'
            };
            var userMap = {
                gender: 'gender',
                title: TextOrDropdownInputTypes.Title,
                firstName: 'firstName',
                lastName: 'lastName',
                function: TextOrDropdownInputTypes.Function,
                telephone: 'telephone',
                email: 'email',
                verifyEmail: 'verifyEmail',
                department: TextOrDropdownInputTypes.Department,
                occupationalGroup: TextOrDropdownInputTypes.OccupationalGroup,
                dateOfBirth: 'dateOfBirth'
            };
            var mergedMaps = __assign({}, addressMap, userMap);
            var checkedSlideIndex = _this.checkedSlideIndex;
            _this.checkedSlideIndex = copyEverythingIsChecked ? _this.activeSlideIndex : null;
            var holderFormId = buyerInfoChanged ? checkedSlideIndex : _this.activeSlideIndex;
            var isAddressCopied = !!copyEverythingIsChecked;
            _this.store.dispatch(new stepsActions.SetAddressCheckbox({
                checkedSlideIndex: _this.checkedSlideIndex,
                isAddressCopied: isAddressCopied
            }));
            _this.canDeleteTicketInfo = isAddressCopied;
            _this.store.pipe(select(getSelectedSendingOption), first(function (selectedTicketSendingOptions) { return !!selectedTicketSendingOptions; }))
                .subscribe(function () {
                // if copyEverythingIsChecked is false, first change data for checked slide index, otherwise validation will stay green because of
                // form.component.ts calls function toFormGroup
                if (!copyEverythingIsChecked) {
                    var buyerTicketHolder = ticketHolderInputSets[checkedSlideIndex];
                    _this.copyBuyerInfoToHolder(mergedMaps, copyEverythingIsChecked, buyerTicketHolder, checkedSlideIndex, checkedSlideIndex, buyerInfoChanged);
                }
                ticketHolderInputSets.forEach(function (_, index) {
                    var map = index === holderFormId ? mergedMaps : addressMap;
                    _this.copyBuyerInfoToHolder(map, copyEverythingIsChecked, ticketHolderInputSets[index], holderFormId, index, buyerInfoChanged);
                });
                // if buyer info changes delete indexes of tickets on which data was copied from buyer info
                if (!copyEverythingIsChecked || buyerInfoChanged) {
                    _this.store.dispatch(new stepsActions.SetCoppiedHoldersIndexes([]));
                    _this.shouldDisplayCheckbox = true;
                }
            });
        });
    };
    OrderTicketsComponent.prototype.copyBuyerInfoToHolder = function (map, copyEverythingIsChecked, filledHolderForm, holderFormId, index, buyerInfoChanged) {
        var _this = this;
        var pushedToCoppiedHolderIndexes = false;
        var coppiedData = this.coppiedHolderDataIndexes.some(function (item) { return item === index; });
        var formHasMapValues = false;
        // skip check if index is same as holder form
        if (index !== holderFormId) {
            // check if any of map values is filled in holder form, if not copy values from buyer
            Object.keys(map).forEach(function (mapKey) {
                // only check if form does not have its own value
                if (!formHasMapValues) {
                    var buyerInputValue = _this.buyerInputs.find(function (buyerInput) {
                        if (buyerInput.key === mapKey) {
                            return buyerInput.value;
                        }
                    });
                    var filledHolderFormVal = filledHolderForm.inputSet.list.find(function (holder) {
                        if (holder.key === mapKey) {
                            return holder.value;
                        }
                    });
                    formHasMapValues = buyerInputValue !== filledHolderFormVal && !!filledHolderFormVal;
                }
            });
        }
        this.buyerInputs.forEach(function (input) {
            if (map.hasOwnProperty(input.key)) {
                var rightHolderInput = filledHolderForm.inputSet.list.find(function (holderInput) {
                    return holderInput.key === input.key;
                });
                if (index === holderFormId) {
                    if (!!rightHolderInput) {
                        rightHolderInput.value = copyEverythingIsChecked ? input.value : '';
                        if (rightHolderInput.key === 'email') {
                            var verifyEmail = filledHolderForm.inputSet.list.find(function (item) {
                                return item.key === 'verifyEmail';
                            });
                            if (!!verifyEmail) {
                                verifyEmail.value = copyEverythingIsChecked ? input.value : '';
                            }
                        }
                    }
                }
                else if (buyerInfoChanged) {
                    if (coppiedData && !!rightHolderInput) {
                        rightHolderInput.value = '';
                    }
                }
                else if (!!rightHolderInput && input.value) {
                    if (!copyEverythingIsChecked) {
                        if (coppiedData) {
                            rightHolderInput.value = '';
                        }
                    }
                    else {
                        if (!!rightHolderInput) {
                            if (!formHasMapValues) {
                                rightHolderInput.value = input.value;
                                if (!_this.coppiedHolderDataIndexes.find(function (item) { return item === index; })) {
                                    _this.coppiedHolderDataIndexes.push(index);
                                    pushedToCoppiedHolderIndexes = true;
                                }
                            }
                        }
                    }
                }
            }
        });
        if (pushedToCoppiedHolderIndexes) {
            this.store.dispatch(new stepsActions.SetCoppiedHoldersIndexes(this.coppiedHolderDataIndexes.slice()));
        }
        filledHolderForm.inputSet.rerender = true;
        // if we're on index which has copy buyer data checked update inputs
        if (index === holderFormId) {
            this.updatedTicketHolderInput({ inputs: filledHolderForm.inputSet.list, currentIndex: index });
        }
        // if copy buyer data is unchecked or buyer info has changed(with buyer info copy checkbox checked) set visitor forms to not valid
        if (!copyEverythingIsChecked || buyerInfoChanged) {
            if (coppiedData || index === holderFormId) {
                this.setTicketHolderValidity(index, false, true);
                this.triggerValidation$.next(true);
            }
        }
        this.store.dispatch(new stepsActions.SetInputs(filledHolderForm));
    };
    OrderTicketsComponent.prototype.goToSlide = function (index) {
        this.activeSlideIndex = index;
        this.store.dispatch(new SetActiveSlide(this.activeSlideIndex));
    };
    OrderTicketsComponent.prototype.previousPage = function () {
        if (this.activeSlideIndex > 0) {
            this.activeSlideIndex--;
        }
        else {
            this.activeSlideIndex = this.ticketHolderInputSets.length - 1;
        }
        this.store.dispatch(new SetActiveSlide(this.activeSlideIndex));
    };
    OrderTicketsComponent.prototype.nextPage = function () {
        if (this.activeSlideIndex < this.ticketHolderInputSets.length - 1) {
            this.activeSlideIndex++;
        }
        else {
            this.activeSlideIndex = 0;
        }
        this.store.dispatch(new SetActiveSlide(this.activeSlideIndex));
    };
    OrderTicketsComponent.prototype.focusFirstEmptyRequiredInput = function () {
        var _this = this;
        this.isNextandPreviousButtonDisabled = true;
        setTimeout(function () {
            _this.store
                .pipe(select(fromRoot.getTicketHolderInputSets), first(function (data) { return !!data; }))
                .subscribe(function (ticketHolderInputSets) {
                var ticketHolderInputSet = ticketHolderInputSets[_this.activeSlideIndex];
                var firstRequiredEmptyInput = ticketHolderInputSet.inputSet.list.find(function (input) {
                    return input.required && !input.value;
                });
                if (!!firstRequiredEmptyInput) {
                    var targetFirstEmptyRequiredInputId = ticketHolderInputSet.formInfo[0] +
                        '.' +
                        ticketHolderInputSet.formInfo[1] +
                        '_' +
                        firstRequiredEmptyInput.key;
                    var targetFirstRequiredInput = document.getElementById(targetFirstEmptyRequiredInputId);
                    //TODO: const options: FocusOptions = { preventScroll: true };
                    targetFirstRequiredInput.focus();
                }
                _this.isNextandPreviousButtonDisabled = false;
            });
        }, 1100); // 1.1s is transition of the carousel
    };
    OrderTicketsComponent.prototype.closeModalWindow = function (event, type) {
        event.stopPropagation();
        switch (type) {
            case 'duplicates':
                this.showDuplicatesWarning = false;
                this.showDuplicatedWarningAlreadyClosed = true;
                break;
            case 'ticket-overlimit':
                this.showTicketLimitWarning = false;
                //if we only have one ticket holder always show tickets over limit modal window:
                if (this.hasMultipleTicketHolders) {
                    this.showTicketLimitWarningAlreadyClosed = true;
                }
                break;
            default:
                this.showDuplicatesWarning = false;
                this.showDuplicatedWarningAlreadyClosed = true;
                break;
        }
    };
    /**
     *
     * @param ticketHolderInputSets array of holders
     * @param checkedSlideIndex index of holder where copyDataFromBuyer checkbox is checked
     * @returns number of inputs of checked holder if there are any, else it returns 0
     */
    OrderTicketsComponent.prototype.ticketHolderInputSetsOfSlideIndexLength = function (ticketHolderInputSets, checkedSlideIndex) {
        if (!!ticketHolderInputSets && checkedSlideIndex != null && checkedSlideIndex >= 0) {
            if (!!ticketHolderInputSets[checkedSlideIndex]) {
                var checkedHolder = ticketHolderInputSets[checkedSlideIndex];
                if (!!checkedHolder.inputSet && !!checkedHolder.inputSet.list) {
                    return checkedHolder.inputSet.list.length;
                }
            }
        }
        return 0;
    };
    return OrderTicketsComponent;
}());
export { OrderTicketsComponent };
