<div
  class="ticket-login"
  *ngIf="
    !isRedeemedAnonymousVoucherProductInProductSelectionList &&
    (notLoggedAndLoginMandatory || (showLoginOnTicketPage$ | async) || (showLoginOnTicketAndPersonalPage$ | async))
  "
>
  <div
    class="login-message"
    data-translation="ticket-selection.login-message"
    data-translation-includes-children
    *ngIf="notLoggedAndLoginMandatory"
    [appRichText]="'ticket-selection.login-message' | translate | hideTranslation"
  ></div>
  <app-login class="login-body" [validationStepName]="registrationFormsActionName"> </app-login>
</div>
