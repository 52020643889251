<div class="container-fluid" *ngIf="profile$ | async">
  <div class="page-content">
    <div class="page-padding-wrapper">
      <div *ngIf="!isEmailVerified">
        <app-info-block [infoText]="'profile.verify-email.info-text'"></app-info-block>
        <div class="send-verify-email-button">
          <button class="col-md-4 button" (click)="resendAccountVerification()">
            <span data-translation="profile.send.verify-email">
              {{ 'profile.send.verify-email' | translate | hideTranslation }}
            </span>
          </button>
        </div>
      </div>

      <div appAdjustSideHeadline>
        <div class="section-headline">
          <h2 data-translation="profile.account.title">
            {{ 'profile.account.title' | translate | hideTranslation }}
          </h2>
          <div class="section-headline-side" data-translation="profile.account.headline">
            {{ 'profile.account.headline' | translate | hideTranslation }}
          </div>
        </div>
        <div class="section-body min-height">
          <div class="profile-body">
            <app-edit-account-form [formType]="'edit-account'"></app-edit-account-form>
          </div>
        </div>
      </div>

      <div appAdjustSideHeadline>
        <div class="section-headline">
          <h2 data-translation="profile.login.title">
            {{ 'profile.login.title' | translate | hideTranslation }}
          </h2>
          <div class="section-headline-side" data-translation="profile.login.headline">
            {{ 'profile.login.headline' | translate | hideTranslation }}
          </div>
        </div>
        <div class="section-body min-height">
          <div class="profile-body">
            <app-change-credentials></app-change-credentials>
          </div>
        </div>
      </div>
      <div *ngIf="billingAddressPresent" appAdjustSideHeadline>
        <div class="section-headline">
          <h2 data-translation="profile.billing-address">
            {{ 'profile.billing-address' | translate | hideTranslation }}
          </h2>
          <div class="section-headline-side" data-translation="profile.billing-address">
            {{ 'profile.billing-address' | translate | hideTranslation }}
          </div>
        </div>
        <div class="section-body min-height">
          <div class="profile-body">
            <app-edit-billing-address-form [isEditEnabled]="isEditEnabled"></app-edit-billing-address-form>
          </div>
        </div>
      </div>
      <!--  TODO
        <div appAdjustSideHeadline>
            <div class="section-headline">
                <h2 data-translation="profile.newsletters.title"> {{ 'profile.newsletters.title' | translate | hideTranslation }}</h2>
                <div class="section-headline-side" data-translation="profile.newsletters.headline">{{'profile.newsletters.headline' | translate | hideTranslation }}</div>
            </div>
            <div class="section-body min-height">
                <app-newsletters-unsubscriptions></app-newsletters-unsubscriptions>
            </div>
        </div>
      -->

      <div *ngIf="(legitimationList$ | async) && (legitimationList$ | async).length" appAdjustSideHeadline>
        <div class="section-headline">
          <h2 data-translation="profile.legitimation.title">
            {{ 'profile.legitimation.title' | translate | hideTranslation }}
          </h2>
          <div class="section-headline-side" data-translation="profile.legitimation.headline">
            {{ 'profile.legitimation.headline' | translate | hideTranslation }}
          </div>
        </div>
        <div class="section-body min-height">
          <div
            class="data-row black row"
            *ngFor="let legitimation of legitimationList$ | async | slice: 0:limits['legitimation']"
          >
            <div class="legitimation-item col-md-3">
              <span class="bold-text" data-translation="profile.legitimation.from">
                {{ 'profile.legitimation.from' | translate | hideTranslation }}:
              </span>
              <span>{{ legitimation.date | localizedDate: 'dd. MMM y' }}</span>
            </div>
            <div class="legitimation-item col-md-3">
              <span class="bold-text" data-translation="profile.legitimation.type">
                {{ 'profile.legitimation.type' | translate | hideTranslation }}:
              </span>
              <span>{{ legitimation.type }}</span>
            </div>
            <div class="legitimation-item col-md-3">
              <span class="bold-text" data-translation="profile.legitimation.event">
                {{ 'profile.legitimation.event' | translate | hideTranslation }}:
              </span>
              <span>{{ legitimation.event }}</span>
            </div>
            <div class="legitimation-item col-md-3 legitimation-status">
              <span class="bold-text status-text" data-translation="profile.legitimation.status">
                {{ 'profile.legitimation.status' | translate | hideTranslation }}:
              </span>
              <svg class="icon icon-checkmark" *ngIf="legitimation.status === LegitimationStatus.Approved">
                <use xlink:href="assets/defs.svg#icon-checkmark"></use>
              </svg>
              <span
                data-translation="profile.legitimation.approved"
                *ngIf="legitimation.status === LegitimationStatus.Approved"
              >
                {{ 'profile.legitimation.approved' | translate | hideTranslation }}
              </span>

              <svg class="icon icon-cross" *ngIf="legitimation.status === LegitimationStatus.Rejected">
                <use xlink:href="assets/defs.svg#icon-cross"></use>
              </svg>
              <span
                data-translation="profile.legitimation.declined"
                *ngIf="legitimation.status === LegitimationStatus.Rejected"
              >
                {{ 'profile.legitimation.declined' | translate | hideTranslation }}
              </span>
              <svg class="icon icon-clock" *ngIf="legitimation.status === LegitimationStatus.InProgress">
                <use xlink:href="assets/defs.svg#icon-clock"></use>
              </svg>
              <span
                data-translation="profile.legitimation.pending"
                *ngIf="legitimation.status === LegitimationStatus.InProgress"
              >
                {{ 'profile.legitimation.pending' | translate | hideTranslation }}
              </span>
              <svg class="icon icon-cloud-check" *ngIf="legitimation.status === LegitimationStatus.New">
                <use xlink:href="assets/defs.svg#icon-cloud-check"></use>
              </svg>
              <span data-translation="profile.legitimation.new" *ngIf="legitimation.status === LegitimationStatus.New">
                {{ 'profile.legitimation.new' | translate | hideTranslation }}
              </span>
            </div>
          </div>
          <div class="toggle-button-wrap" *ngIf="(legitimationList$ | async)?.length > 3">
            <a (click)="toggleList('legitimation')" [attr.data-translation]="limitsTranslations['legitimation']">
              {{ limitsTranslations['legitimation'] | translate | hideTranslation }}
            </a>
          </div>
        </div>
      </div>

      <app-tickets-download></app-tickets-download>

      <ng-container *ngIf="exhibitionHistoryList$ | async as exhibitionHistoryList">
        <div appAdjustSideHeadline *ngIf="exhibitionHistoryList.length">
          <div class="section-headline">
            <h2 data-translation="profile.history.title">
              {{ 'profile.history.title' | translate | hideTranslation }}
            </h2>
            <div class="section-headline-side" data-translation="profile.history.headline">
              {{ 'profile.history.headline' | translate | hideTranslation }}
            </div>
          </div>
          <div class="section-body min-height">
            <div
              class="data-row exhibition-line-wrap black"
              *ngFor="let exhibition of exhibitionHistoryList | slice: 0:limits['exhibition']"
            >
              <span class="ticket-type-wrap">
                <span class="ticket">{{ exhibition.ticketType }}</span>
                <span class="visitor-name">{{ exhibition.visitorName }}</span>
              </span>
              <span class="event-name">{{ exhibition.eventName }}</span>
              <span class="event-date">{{ exhibition.eventDate | localizedDate: 'dd. MMM y' }}</span>
            </div>
            <div class="toggle-button-wrap" *ngIf="exhibitionHistoryList?.length > 3">
              <a [attr.data-translation]="limitsTranslations['exhibition']" (click)="toggleList('exhibition')">
                {{ limitsTranslations['exhibition'] | translate | hideTranslation }}</a
              >
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
