import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { WorkshopAssignedHolders, WorkshopAvailableSeats } from '@products/models/workshop-status.model';
import { WorkshopId } from '@products/models/workshop.model';
import { BookingService } from '@products/services/booking.service';
import { State } from '@store/legitimation/legitimation.reducer';
import {
  ActionTypes as BookingActionTypes,
  Actions as BookingActions,
  RemoveWorkshopFromBooking,
  SetWorkshopInBooking
} from '@store/products/booking/booking.actions';
import {
  ActionTypes as HolderActionTypes,
  RemoveProductHolder,
  SetProductHolder
} from '@store/products/holder/holder.actions';
import { getHolderUuids } from '@store/products/holder/holder.selectors';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  SetIsWorkshopLoadingStatus,
  SetWorkshopAssignedHoldersStatus,
  SetWorkshopAvailableSeatsAndAssignedHoldersStatus,
  SetWorkshopAvailableSeatsStatus
} from '@store/products/status/workshop/workshop.actions';
import { getWorkshopsAssignedHoldersGroupedByWorkshopId } from '@store/products/status/workshop/workshop.selectors';
import {
  SetInitialWorkshopProductList,
  ActionTypes as WorkshopActionTypes
} from '@store/products/workshop/workshop.actions';
import _ from 'lodash';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class WorkshopStatusEffect {
  @Effect()
  setInitialWorkshopAvailableSeatsStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SetInitialWorkshopProductList>(WorkshopActionTypes.SET_INITIAL_WORKSHOP_PRODUCT_LIST),
    switchMap(({ payload }) => {
      const workshopList = payload;
      if (!workshopList.length) {
        return EMPTY;
      }

      const workshopAvailableSeats: WorkshopAvailableSeats = workshopList.reduce((workshopAvailableSeats, workshop) => {
        workshopAvailableSeats[workshop.workshopId] = workshop.seats;
        return workshopAvailableSeats;
      }, {});

      return of(new SetWorkshopAvailableSeatsStatus(workshopAvailableSeats));
    })
  );

  @Effect()
  setWorkshopAvailableSeatsStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SetWorkshopInBooking | RemoveWorkshopFromBooking>(
      BookingActionTypes.SET_WORKSHOP_IN_BOOKING,
      BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING
    ),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      const { workshopAvailableSeats } = payload;

      return of(new SetWorkshopAvailableSeatsStatus(workshopAvailableSeats));
    })
  );

  @Effect()
  setWorkshopAssignedHoldersStatusOnSetWorkshopBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetWorkshopInBooking>(BookingActionTypes.SET_WORKSHOP_IN_BOOKING),
    withLatestFrom(this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))),
    switchMap(([{ payload }, workshopsAssignedHoldersGroupedByWorkshopId]) => {
      if (!payload) {
        return EMPTY;
      }

      const { setBookingWorkshops } = payload;
      const workshopAssignedHolders: WorkshopAssignedHolders = {};

      setBookingWorkshops.forEach(setWorkshopBooking =>
        setWorkshopBooking.workshops.forEach(({ id, holderUuids }) => {
          const assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[id];

          if (workshopAssignedHolders[id]) {
            workshopAssignedHolders[id] = [...workshopAssignedHolders[id], ...holderUuids];
          } else if (assignedWorkshopHolders) {
            workshopAssignedHolders[id] = [...assignedWorkshopHolders, ...holderUuids];
          } else {
            workshopAssignedHolders[id] = holderUuids;
          }
        })
      );

      return of(new SetWorkshopAssignedHoldersStatus(workshopAssignedHolders));
    })
  );

  @Effect()
  setWorkshopAssignedHoldersStatusOnRemoveWorkshopBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveWorkshopFromBooking>(BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING),
    withLatestFrom(this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))),
    switchMap(([{ payload }, workshopsAssignedHoldersGroupedByWorkshopId]) => {
      if (!payload) {
        return EMPTY;
      }

      const { removeBookingWorkshops } = payload;
      const workshopAvailableSeats: WorkshopAssignedHolders = {};

      removeBookingWorkshops.forEach(removeBookingWorkshop =>
        removeBookingWorkshop.removeWorkshopsAssignedHolders.forEach(({ workshopId, removeHolderUuids }) => {
          const assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[workshopId];
          workshopAvailableSeats[workshopId] = _.difference(assignedWorkshopHolders, removeHolderUuids);
        })
      );

      return of(new SetWorkshopAssignedHoldersStatus(workshopAvailableSeats));
    }),
    catchError(error => throwError(error))
  );

  @Effect()
  releaseAssignedWorkshopHolderSeatsOnProductHolderRemoval$: Observable<Action> = this.actions$.pipe(
    ofType<SetProductHolder | RemoveProductHolder>(
      HolderActionTypes.SET_PRODUCT_HOLDER,
      HolderActionTypes.REMOVE_PRODUCT_HOLDER,
      HolderActionTypes.REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER
    ),
    withLatestFrom(
      this.store.pipe(select(getHolderUuids)),
      this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))
    ),
    concatMap(([{ type, payload }, holderUuids, workshopsAssignedHoldersGroupedByWorkshopId]) => {
      const setProductHoldersUuids = payload;
      const isFirstProductSet = holderUuids.length === 1 && type === HolderActionTypes.SET_PRODUCT_HOLDER;
      const areProductHoldersRemoved = setProductHoldersUuids.length === holderUuids.length && !isFirstProductSet;

      if (!areProductHoldersRemoved) {
        return EMPTY;
      }

      const workshopAssignedHolders: WorkshopAssignedHolders = {};
      const releaseWorkshopAssignedHolders: WorkshopAssignedHolders = {};
      const assignedHoldersWorkshopsIds: WorkshopId[] = _.keys(workshopsAssignedHoldersGroupedByWorkshopId);

      assignedHoldersWorkshopsIds.forEach(workshopId => {
        const assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[workshopId];

        workshopAssignedHolders[workshopId] = _.intersection(assignedWorkshopHolders, holderUuids);
        releaseWorkshopAssignedHolders[workshopId] = _.difference(
          assignedWorkshopHolders,
          workshopAssignedHolders[workshopId]
        );
      });

      return this.bookingService
        .postReleaseAssignedWorkshopHolderSeats$(workshopAssignedHolders, releaseWorkshopAssignedHolders)
        .pipe(
          map(
            ({ workshopAssignedHolders, workshopAvailableSeats }) =>
              new SetWorkshopAvailableSeatsAndAssignedHoldersStatus({
                availableSeats: workshopAvailableSeats,
                assignedHolders: workshopAssignedHolders
              })
          ),
          catchError(() => of(new SetWorkshopAvailableSeatsAndAssignedHoldersStatus(null)))
        );
    })
  );

  @Effect()
  isWorkshopLoadingStatus$: Observable<Action> = this.actions$.pipe(
    ofType<ProductActions | BookingActions>(
      ProductActionTypes.SET_BOOKING_WORKSHOP,
      ProductActionTypes.REMOVE_BOOKING_WORKSHOP,
      BookingActionTypes.SET_WORKSHOP_IN_BOOKING,
      BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING
    ),
    map(action => {
      const productActionTypes: string[] = [
        ProductActionTypes.SET_BOOKING_WORKSHOP,
        ProductActionTypes.REMOVE_BOOKING_WORKSHOP
      ];

      const isWorkshopLoading = productActionTypes.includes(action.type);

      return new SetIsWorkshopLoadingStatus(isWorkshopLoading);
    })
  );

  constructor(private actions$: Actions, private store: Store<State>, private bookingService: BookingService) {}
}
