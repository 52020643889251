<ng-container *ngFor="let workshop of confirmationTariff.workshops">
  <ng-container *ngIf="workshop.totalPrice">
    <div class="ticket-header">
      <span class="title black">
        {{ workshop.workshopName | translate | hideTranslation }}
      </span>
      <span class="count black"> {{ workshop.count }}x </span>
      <span class="price black">
        {{ workshop.price | formatPrice: currencySettings }}
      </span>
      <span class="per-type-price price">
        {{ workshop.totalPrice | formatPrice: currencySettings }}
      </span>
    </div>
  </ng-container>
</ng-container>
