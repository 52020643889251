/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-selection-login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/directives/rich-text/rich-text.directive";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../login/login.component.ngfactory";
import * as i6 from "../../../../login/login.component";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/router";
import * as i9 from "@ngrx/store";
import * as i10 from "../../../../login/login.service";
import * as i11 from "../../../../shared/services-with-reducers/user/user.service";
import * as i12 from "../../../../shared/services-with-reducers/helpers/helper.service";
import * as i13 from "../../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i14 from "./product-selection-login.component";
var styles_ProductSelectionLoginComponent = [i0.styles];
var RenderType_ProductSelectionLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductSelectionLoginComponent, data: {} });
export { RenderType_ProductSelectionLoginComponent as RenderType_ProductSelectionLoginComponent };
function View_ProductSelectionLoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "login-message"], ["data-translation", "ticket-selection.login-message"], ["data-translation-includes-children", ""]], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.RichTextDirective, [i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ticket-selection.login-message")))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProductSelectionLoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ticket-login"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductSelectionLoginComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-login", [["class", "login-body"]], null, null, null, i5.View_LoginComponent_0, i5.RenderType_LoginComponent)), i1.ɵdid(4, 245760, null, 0, i6.LoginComponent, [i7.FormBuilder, i8.Router, i9.Store, i3.TranslateService, i10.LoginService, i11.UserService, i12.HelperService, i8.ActivatedRoute], { validationStepName: [0, "validationStepName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notLoggedAndLoginMandatory; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.registrationFormsActionName; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProductSelectionLoginComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_ProductSelectionLoginComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isRedeemedAnonymousVoucherProductInProductSelectionList && ((_co.notLoggedAndLoginMandatory || i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.showLoginOnTicketPage$))) || i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(_co.showLoginOnTicketAndPersonalPage$)))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProductSelectionLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-selection-login", [], null, null, null, View_ProductSelectionLoginComponent_0, RenderType_ProductSelectionLoginComponent)), i1.ɵdid(1, 114688, null, 0, i14.ProductSelectionLoginComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductSelectionLoginComponentNgFactory = i1.ɵccf("app-product-selection-login", i14.ProductSelectionLoginComponent, View_ProductSelectionLoginComponent_Host_0, { isRedeemedAnonymousVoucherProductInProductSelectionList: "isRedeemedAnonymousVoucherProductInProductSelectionList", notLoggedAndLoginMandatory: "notLoggedAndLoginMandatory" }, {}, []);
export { ProductSelectionLoginComponentNgFactory as ProductSelectionLoginComponentNgFactory };
