import { Location } from '@angular/common';
import { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { getTicketHolderInputSets } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { SetSendingOptions } from '@store/products/product-selection/product-selection.actions';
import { getSendingOptions, isRedeemedAnonymousVoucherProductInProductSelectionList } from '@store/products/product-selection/product-selection.selectors';
import { SetBuyerVisitorCheckbox } from '@store/step-forms/steps-forms.actions';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { first } from 'rxjs/operators';
var SendingOptionsComponent = /** @class */ (function () {
    function SendingOptionsComponent(store, formsService, router, location) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.router = router;
        this.location = location;
        this.ticketSendingOptions = [];
        this.selectedSendingOption = '';
        this.showSendingOptions = true;
        this.subscription = new Subscription();
        this.wasAnonymousTicketTaken = false;
        this.optionSelectedBeforeAnonim = '';
        this.changedUrl = '';
        this.TicketSendingOptions = TicketSendingOptions;
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
        this.subscription.add(observableCombineLatest([
            this.store.pipe(select(getSendingOptions)),
            this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
        ])
            .filter(function (_a) {
            var getSendingOptions = _a[0];
            return !!getSendingOptions;
        })
            .subscribe(function (_a) {
            var getSendingOptions = _a[0], isRedeemedAnonymousVoucherProductInProductSelectionList = _a[1];
            var sendingOptions = getSendingOptions;
            var anonymousTicketTaken = isRedeemedAnonymousVoucherProductInProductSelectionList;
            // delete query params, change URL
            if (!(_this.router.url.includes('utm_source') ||
                _this.router.url.includes('utm_medium') ||
                _this.router.url.includes('utm_campaign') ||
                _this.router.url.includes('utm_term') ||
                _this.router.url.includes('utm_content'))) {
                _this.changedUrl = _this.router.url.split('?')[0];
                _this.location.replaceState(_this.changedUrl);
            }
            if (!anonymousTicketTaken && _this.wasAnonymousTicketTaken) {
                var anonyMobilePerOwner = sendingOptions.find(function (option) { return option.value === TicketSendingOptions.MobilePerOwner; });
                if (!!anonyMobilePerOwner) {
                    anonyMobilePerOwner.isSelected = _this.wasAnonymousTicketTaken = false;
                }
                var allToBuyer = sendingOptions.find(function (option) { return option.value === _this.optionSelectedBeforeAnonim; });
                if (!!allToBuyer) {
                    allToBuyer.isSelected = true;
                }
            }
            sendingOptions.forEach(function (option) {
                if (anonymousTicketTaken) {
                    if (option.value === TicketSendingOptions.MobilePerOwner) {
                        _this.selectedSendingOption = TicketSendingOptions.MobilePerOwner;
                        option.isSelected = _this.wasAnonymousTicketTaken = true;
                        _this.showSendingOptions = false;
                    }
                    else {
                        option.isSelected = false;
                    }
                }
                else {
                    if (option.isSelected) {
                        _this.optionSelectedBeforeAnonim = _this.selectedSendingOption = option.value;
                    }
                }
            });
            if (_this.selectedSendingOption === '') {
                var selectedSendingOption = sendingOptions.find(function (option) { return option.isEnabled; });
                if (!!selectedSendingOption) {
                    selectedSendingOption.isSelected = true;
                    _this.selectedSendingOption = selectedSendingOption.value;
                }
            }
            _this.ticketSendingOptions = _this.addOptionVisibility(sendingOptions);
            if (!anonymousTicketTaken) {
                _this.showSendingOptions =
                    _this.ticketSendingOptions.filter(function (option) { return option.isEnabled && option.isVisible && option.isBasic; })
                        .length > 1;
            }
            _this.setSendingOptionTicketName();
            if (_this.selectedSendingOption === TicketSendingOptions.TicketRetrievalLink) {
                _this.store
                    .pipe(select(getTicketHolderInputSets), first())
                    .subscribe(function (holders) {
                    holders.forEach(function (holder) {
                        var invalid = holder.inputSet.list.reduce(function (acc, curr) {
                            return acc || (['email', 'firstName', 'lastNmae'].includes(curr.key) && !curr.value);
                        }, false);
                        if (invalid) {
                            _this.formsService.setFormValidity(false, null, holder.formInfo);
                        }
                    });
                });
            }
            if (_this.selectedSendingOption === TicketSendingOptions.MobilePerOwner &&
                !!_this.exhibitionSettings &&
                _this.exhibitionSettings.emailIsMandatoryForMobileTicket) {
                _this.store
                    .pipe(select(getTicketHolderInputSets), first())
                    .subscribe(function (holders) {
                    holders.forEach(function (holder) {
                        var invalid = holder.inputSet.list.reduce(function (acc, curr) {
                            return acc || (['verifyEmail', 'email'].includes(curr.key) && !curr.value);
                        }, false);
                        if (invalid) {
                            _this.formsService.setFormValidity(false, null, holder.formInfo);
                        }
                    });
                });
            }
        }));
    }
    SendingOptionsComponent.prototype.ngOnChanges = function (changes) {
        this.setSendingOptionsOnAnonymousVoucherProductRedeem(changes);
    };
    SendingOptionsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    SendingOptionsComponent.prototype.addOptionVisibility = function (sendingOptions) {
        var optionsWithVisibility = sendingOptions.map(function (option) {
            option.isVisible = option.isEnabled;
            return option;
        });
        return optionsWithVisibility;
    };
    SendingOptionsComponent.prototype.setSendingOptionTicketName = function () {
        switch (this.selectedSendingOption) {
            case TicketSendingOptions.NormalPerOwner:
                this.sendingOptionTicketName = 'Print@Home';
                break;
            case TicketSendingOptions.MobilePerOwner:
                this.sendingOptionTicketName = 'Mobile';
                break;
            case TicketSendingOptions.TicketRetrievalLink:
                this.sendingOptionTicketName = 'Print@Home';
                break;
        }
    };
    SendingOptionsComponent.prototype.sendingOptionChanged = function () {
        var _this = this;
        var updatedSendingOptions = this.ticketSendingOptions.map(function (option) {
            option.isSelected = option.value === _this.selectedSendingOption;
            return option;
        });
        this.setSendingOptionTicketName();
        this.store.dispatch(new SetSendingOptions(updatedSendingOptions));
        if (this.selectedSendingOption !== TicketSendingOptions.TicketRetrievalLink) {
            this.store.dispatch(new SetBuyerVisitorCheckbox({
                buyerVisitorCheckedSlideIndex: null,
                isBuyerVisitorChecked: false,
                showVisitorQuestionnaire: false
            }));
            this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
            this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.visitorQuestionnaire);
        }
    };
    SendingOptionsComponent.prototype.setSendingOptionsOnAnonymousVoucherProductRedeem = function (changes) {
        var isRedeemedAnonymousVoucherProductInProductSelectionList = changes.isRedeemedAnonymousVoucherProductInProductSelectionList;
        var currentIsRedeemedAnonymousVoucherProductInProductSelectionList = isRedeemedAnonymousVoucherProductInProductSelectionList.currentValue;
        if (currentIsRedeemedAnonymousVoucherProductInProductSelectionList && this.ticketSendingOptions.length) {
            this.setSendingOptions();
        }
    };
    SendingOptionsComponent.prototype.setSendingOptions = function () {
        this.store.dispatch(new SetSendingOptions(this.ticketSendingOptions));
    };
    return SendingOptionsComponent;
}());
export { SendingOptionsComponent };
