var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { AppConstants } from '@shared/app-constants';
import { getCountdownTimestamp } from '@shared/app-utils';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';
var INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND;
var initialState = {
    list: [],
    countdown: {
        product: null,
        workshop: null,
        voucher: []
    },
    claimedTicketHash: '',
    claimedTicketHashValid: null,
    lastVoucherTicket: null,
    isAnonymousProductSentToAPI: false,
    ticketHolderSubmitResult: false
};
/**
 * Find tariff type index
 * @description Get index of specified tariff type
 * @param tariffType
 * @param bookedProductTypes
 * @returns Ticket type index or -1 if index not found
 */
var findTariffTypeIndex = function (tariffType, bookedProductTypes) {
    if (!bookedProductTypes.length) {
        return INDEX_NOT_FOUND;
    }
    var tariffProductType = tariffType.productType, productTicketTypeId = tariffType.ticketTypeId;
    var findIndexByTicketTypeId = function (bookingProductType) {
        if (!isBookingProductTypeTariff(bookingProductType)) {
            return false;
        }
        var productType = bookingProductType.productType, ticketTypeId = bookingProductType.ticketTypeId;
        return productType === tariffProductType && ticketTypeId === productTicketTypeId;
    };
    return bookedProductTypes.findIndex(findIndexByTicketTypeId);
};
var ɵ0 = findTariffTypeIndex;
/**
 * Find tariff index
 * @description Get index of specified ticket
 * @param tariff
 * @param bookedTariffTypes
 * @returns Ticket index or -1 if index not found
 */
var findTariffIndex = function (tariff, bookedTariffTypes) {
    return bookedTariffTypes.tariffs.findIndex(function (bookedTariff) {
        return bookedTariff.ticketPersonId === tariff.ticketPersonId && bookedTariff.voucherCode === tariff.voucherCode;
    });
};
var ɵ1 = findTariffIndex;
/**
 * Find package index
 * @description Get index of specified package
 * @param productType
 * @param cartProductTypes
 * @returns Package index or -1 if index not found
 */
var findPackageIndex = function (productType, bookedProductTypes) {
    if (!bookedProductTypes.length) {
        return INDEX_NOT_FOUND;
    }
    var packageProductType = productType.productType, productPackageNumber = productType.packageNumber, productPackageIndex = productType.packageIndex;
    var findIndexByPackageNumberAndPackageIndex = function (bookedProductType) {
        if (!isBookingProductTypePackage(bookedProductType)) {
            return false;
        }
        var productType = bookedProductType.productType, packageNumber = bookedProductType.packageNumber, packageIndex = bookedProductType.packageIndex;
        return (packageProductType === productType &&
            productPackageNumber === packageNumber &&
            productPackageIndex === packageIndex);
    };
    return bookedProductTypes.findIndex(findIndexByPackageNumberAndPackageIndex);
};
var ɵ2 = findPackageIndex;
/**
 * Get product type cart index
 * @description Get cart index of specified product type
 * @param productType
 * @param bookingProductTypes
 * @returns Product type cart index or -1 if index not found
 */
var findProductTypeBookingIndex = function (productType, bookedProductTypes) {
    if (!bookedProductTypes.length) {
        return INDEX_NOT_FOUND;
    }
    if (isBookingProductTypeTariff(productType)) {
        return findTariffTypeIndex(productType, bookedProductTypes);
    }
    else if (isBookingProductTypePackage(productType)) {
        return findPackageIndex(productType, bookedProductTypes);
    }
    return INDEX_NOT_FOUND;
};
var ɵ3 = findProductTypeBookingIndex;
/**
 * Validate product types
 * @description Validate if provided products have valid count
 * @param productType
 * @returns Product types or empty array
 */
var validateProductTypes = function (productType) {
    if (isBookingProductTypeTariff(productType)) {
        productType.tariffs = productType.tariffs.filter(function (addedTariff) { return addedTariff.count > 0; });
    }
    else if (isBookingProductTypePackage(productType)) {
        productType.productTypes = productType.productTypes.filter(function (addedPackageProductType) {
            if (isBookingProductTypeTariff(addedPackageProductType)) {
                addedPackageProductType.tariffs = addedPackageProductType.tariffs.filter(function (addedPackageTariff) { return addedPackageTariff.count > 0; });
                return addedPackageProductType.tariffs.length;
            }
        });
    }
    return productType;
};
var ɵ4 = validateProductTypes;
/**
 * Find booked tariff
 * @description Find booked tariff
 * @param bookedProductType
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @returns Booked Tariff
 */
var findBookedTariff = function (bookedProductType, ticketTypeId, ticketPersonId, voucherCode) {
    return bookedProductType.tariffs.find(function (bookedTariff) {
        return bookedTariff.ticketTypeId === ticketTypeId &&
            bookedTariff.ticketPersonId === ticketPersonId &&
            bookedTariff.voucherCode === voucherCode;
    });
};
var ɵ5 = findBookedTariff;
/**
 * Find booked package tariff
 * @description Find booked package tariff
 * @param bookedProductType
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Booked Tariff
 */
var findBookedPackageTariff = function (bookedPackageProductType, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return bookedPackageProductType.tariffs.find(function (bookedPackageTariff) {
        return bookedPackageTariff.ticketTypeId === ticketTypeId &&
            bookedPackageTariff.ticketPersonId === ticketPersonId &&
            bookedPackageTariff.voucherCode === voucherCode &&
            bookedPackageTariff.packageNumber === packageNumber &&
            bookedPackageTariff.packageIndex === packageIndex;
    });
};
var ɵ6 = findBookedPackageTariff;
/**
 * Check valid product type
 * @description Check if product has products or product types (if package)
 * @param productType
 * @returns Boolean valid product type
 */
var isValidProductType = function (productType) {
    var isTariffProductTypeValid = isBookingProductTypeTariff(productType) && !!productType.tariffs.length;
    var isPackageProductTypeValid = isBookingProductTypePackage(productType) && !!productType.productTypes.length;
    return isTariffProductTypeValid || isPackageProductTypeValid;
};
var ɵ7 = isValidProductType;
/**
 * Add or remove tariff
 * @description Add or remove tariff from booking tariff types
 * @param tariffIndex
 * @param isValidTariffType
 * @param validatedTariff
 * @param bookingTariffs
 */
var addOrRemoveTariff = function (index, isValidTariffType, setTariff, bookingTariffs) {
    if (index === INDEX_NOT_FOUND) {
        if (isValidTariffType) {
            bookingTariffs.push(setTariff);
        }
    }
    else {
        if (isValidTariffType) {
            // Remove holder uuid assign when holders will be assigned in personalization step
            var alreadyBookedTariff = bookingTariffs[index];
            var alreadyBookedTariffHolderUuids = alreadyBookedTariff.holderUuids;
            var alreadyBookedContingentReservations = alreadyBookedTariff.contingentReservations;
            var alreadyBookedParkingReservations = alreadyBookedTariff.parkingReservations;
            var addBooking = setTariff.count > alreadyBookedTariff.count;
            var removeBooking = setTariff.count < alreadyBookedTariff.count;
            if (addBooking) {
                setTariff.holderUuids = alreadyBookedTariffHolderUuids.concat(setTariff.holderUuids);
                setTariff.workshops = bookingTariffs[index].workshops.concat(setTariff.workshops);
                setTariff.contingentReservations = alreadyBookedContingentReservations.concat(setTariff.contingentReservations);
                setTariff.parkingReservations = alreadyBookedParkingReservations.concat(setTariff.parkingReservations);
            }
            else if (removeBooking) {
                var removeBookingsCount = alreadyBookedTariff.count - setTariff.count;
                if (alreadyBookedTariff.workshops) {
                    var removedHolderUuids_1 = _.takeRight(alreadyBookedTariffHolderUuids, removeBookingsCount);
                    alreadyBookedTariff.workshops.forEach(function (bookedWorkshop) {
                        return (bookedWorkshop.holderUuids = _.difference(bookedWorkshop.holderUuids, removedHolderUuids_1));
                    });
                    setTariff.workshops = alreadyBookedTariff.workshops.filter(function (bookedWorkshop) { return !!bookedWorkshop.holderUuids.length; });
                }
                setTariff.holderUuids = alreadyBookedTariffHolderUuids.slice(0, -removeBookingsCount);
                setTariff.contingentReservations = alreadyBookedContingentReservations.slice(0, -removeBookingsCount);
                setTariff.parkingReservations = alreadyBookedParkingReservations.slice(0, -removeBookingsCount);
            }
            else {
                setTariff.holderUuids = alreadyBookedTariffHolderUuids;
            }
            var setBookingTariffPriceToParkingReservationPriceTotal = setTariff.parkingReservations.length;
            if (setBookingTariffPriceToParkingReservationPriceTotal) {
                setTariff.price = setTariffPriceToParkingReservationTotalPrice(setTariff);
            }
            bookingTariffs[index] = setTariff;
        }
        else {
            bookingTariffs.splice(index, 1);
        }
    }
};
var ɵ8 = addOrRemoveTariff;
/**
 * Remove empty product type
 * @description Remove empty product type from booked product types
 * @param products
 * @param bookedProductTypes
 * @param index
 */
var removeEmptyProductType = function (products, bookedProductTypes, index) {
    var removeProduct = !products.length;
    if (removeProduct) {
        bookedProductTypes.splice(index, 1);
    }
};
var ɵ9 = removeEmptyProductType;
/**
 * Checks if state voucher is expired
 * @param voucherCountdown Current state voucher countdown
 * @returns True if current state voucher expired
 */
var isVoucherExpired = function (voucherCountdown) {
    var currentTimestamp = Date.now();
    return voucherCountdown && currentTimestamp > voucherCountdown.expiryTimestamp;
};
var ɵ10 = isVoucherExpired;
/**
 * Calculate booked parking tariff price
 * @description Calculate parking tariff price based on parking reservations total price
 * @param bookedTariff
 * @return Number
 */
var setTariffPriceToParkingReservationTotalPrice = function (bookedTariff) {
    return bookedTariff.parkingReservations.reduce(function (bookedParkingReservationTotalPrice, bookedParkingReservation) {
        return bookedParkingReservationTotalPrice + bookedParkingReservation.price;
    }, 0);
};
var ɵ11 = setTariffPriceToParkingReservationTotalPrice;
export var bookingReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST:
        case BookingActionTypes.SET_PREFERRED_PRODUCT_IN_BOOKING_LIST:
        case BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST:
        case BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST: {
            var cloneStateProductsList_1 = cloneDeep(state.list);
            var setProductTypes = cloneDeep(action.payload);
            if (setProductTypes.length) {
                setProductTypes.forEach(function (setProductType) {
                    var productTypeBookingIndex = findProductTypeBookingIndex(setProductType, cloneStateProductsList_1);
                    if (productTypeBookingIndex === INDEX_NOT_FOUND) {
                        var validatedProductType = validateProductTypes(setProductType);
                        if (isValidProductType(validatedProductType)) {
                            cloneStateProductsList_1.push(validatedProductType);
                        }
                    }
                    else if (isBookingProductTypeTariff(setProductType)) {
                        var foundBookedTariffType_1 = cloneStateProductsList_1[productTypeBookingIndex];
                        var foundBookedTariffs_1 = foundBookedTariffType_1.tariffs;
                        setProductType.tariffs.forEach(function (setTariff) {
                            var isValidSetTariff = setTariff.count > 0;
                            var tariffIndex = findTariffIndex(setTariff, foundBookedTariffType_1);
                            addOrRemoveTariff(tariffIndex, isValidSetTariff, setTariff, foundBookedTariffs_1);
                        });
                        removeEmptyProductType(foundBookedTariffs_1, cloneStateProductsList_1, productTypeBookingIndex);
                    }
                    else if (isBookingProductTypePackage(setProductType)) {
                        var foundBookedPackageType = cloneStateProductsList_1[productTypeBookingIndex];
                        var foundBookedPackageProductTypes_1 = foundBookedPackageType.productTypes;
                        setProductType.productTypes.forEach(function (setPackageProductType) {
                            var packageBookingTicketTypeIndex = findProductTypeBookingIndex(setPackageProductType, foundBookedPackageProductTypes_1);
                            if (packageBookingTicketTypeIndex === INDEX_NOT_FOUND) {
                                var validatedSetPackageProductType = validateProductTypes(setPackageProductType);
                                if (isValidProductType(validatedSetPackageProductType)) {
                                    foundBookedPackageProductTypes_1.push(validatedSetPackageProductType);
                                }
                            }
                            else {
                                if (isBookingProductTypeTariff(setPackageProductType)) {
                                    var foundBookingPackageTariffType_1 = foundBookedPackageProductTypes_1[packageBookingTicketTypeIndex];
                                    var bookedPackageTariffs_1 = foundBookingPackageTariffType_1.tariffs;
                                    setPackageProductType.tariffs.forEach(function (setPackageTariff) {
                                        var isValidSetPackageTariff = setPackageTariff.count > 0;
                                        var packageTariffIndex = findTariffIndex(setPackageTariff, foundBookingPackageTariffType_1);
                                        addOrRemoveTariff(packageTariffIndex, isValidSetPackageTariff, setPackageTariff, bookedPackageTariffs_1);
                                    });
                                    removeEmptyProductType(bookedPackageTariffs_1, foundBookedPackageProductTypes_1, packageBookingTicketTypeIndex);
                                }
                            }
                            removeEmptyProductType(foundBookedPackageProductTypes_1, cloneStateProductsList_1, productTypeBookingIndex);
                        });
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList_1 });
            }
            return state;
        }
        case BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST:
        case BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST:
        case BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST: {
            var cloneStateProductsList_2 = cloneDeep(state.list);
            var removedProductTypes = cloneDeep(action.payload);
            if (removedProductTypes.length) {
                removedProductTypes.forEach(function (removedBookedProductType) {
                    var productTypeBookingIndex = findProductTypeBookingIndex(removedBookedProductType, cloneStateProductsList_2);
                    if (productTypeBookingIndex !== INDEX_NOT_FOUND) {
                        if (isBookingProductTypeTariff(removedBookedProductType)) {
                            var foundBookedTariffType_2 = cloneStateProductsList_2[productTypeBookingIndex];
                            var foundBookingTariffs_1 = foundBookedTariffType_2.tariffs;
                            removedBookedProductType.tariffs.forEach(function (removedTariff) {
                                var tariffIndex = findTariffIndex(removedTariff, foundBookedTariffType_2);
                                if (tariffIndex !== INDEX_NOT_FOUND) {
                                    foundBookingTariffs_1.splice(tariffIndex, 1);
                                }
                            });
                            removeEmptyProductType(foundBookingTariffs_1, cloneStateProductsList_2, productTypeBookingIndex);
                        }
                        else if (isBookingProductTypePackage(removedBookedProductType)) {
                            var foundBookedPackageType = cloneStateProductsList_2[productTypeBookingIndex];
                            var foundBookedProductTypes_1 = foundBookedPackageType.productTypes;
                            removedBookedProductType.productTypes.forEach(function (removeBookedPackageProductType) {
                                var packageBookingTariffTypeIndex = findProductTypeBookingIndex(removeBookedPackageProductType, foundBookedProductTypes_1);
                                if (packageBookingTariffTypeIndex !== INDEX_NOT_FOUND) {
                                    var foundBookedPackageTicketType_1 = foundBookedProductTypes_1[packageBookingTariffTypeIndex];
                                    var foundBookingPackageTariffs = foundBookedPackageTicketType_1.tariffs;
                                    if (isBookingProductTypeTariff(removeBookedPackageProductType)) {
                                        removeBookedPackageProductType.tariffs.forEach(function (removeBookedPackageTariff) {
                                            var packageTariffIndex = findTariffIndex(removeBookedPackageTariff, foundBookedPackageTicketType_1);
                                            if (packageTariffIndex !== INDEX_NOT_FOUND) {
                                                foundBookedPackageTicketType_1.tariffs.splice(packageTariffIndex, 1);
                                            }
                                        });
                                        removeEmptyProductType(foundBookingPackageTariffs, foundBookedProductTypes_1, packageBookingTariffTypeIndex);
                                    }
                                }
                            });
                            removeEmptyProductType(foundBookedProductTypes_1, cloneStateProductsList_2, productTypeBookingIndex);
                        }
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList_2 });
            }
            return state;
        }
        case BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST: {
            var cloneStateProductsList_3 = cloneDeep(state.list);
            var bookedPackageTypes = cloneDeep(action.payload);
            if (bookedPackageTypes.length) {
                bookedPackageTypes.forEach(function (setProductType) {
                    if (isBookingProductTypePackage(setProductType)) {
                        var packageBookingIndex = findPackageIndex(setProductType, cloneStateProductsList_3);
                        var validatedSetPackageProductType = validateProductTypes(setProductType);
                        var isPackageProductTypeValid = isValidProductType(validatedSetPackageProductType);
                        if (packageBookingIndex === INDEX_NOT_FOUND) {
                            if (isPackageProductTypeValid) {
                                cloneStateProductsList_3.push(validatedSetPackageProductType);
                            }
                        }
                        else {
                            if (isPackageProductTypeValid) {
                                cloneStateProductsList_3[packageBookingIndex] = validatedSetPackageProductType;
                            }
                            else {
                                cloneStateProductsList_3.splice(packageBookingIndex, 1);
                            }
                        }
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList_3 });
            }
            return state;
        }
        case BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST: {
            var cloneStateProductsList_4 = cloneDeep(state.list);
            var removedProductTypes = cloneDeep(action.payload);
            if (removedProductTypes.length) {
                removedProductTypes.forEach(function (removedProductType) {
                    if (isBookingProductTypePackage(removedProductType)) {
                        var packageBookingIndex = findPackageIndex(removedProductType, cloneStateProductsList_4);
                        if (packageBookingIndex !== INDEX_NOT_FOUND) {
                            cloneStateProductsList_4.splice(packageBookingIndex, 1);
                        }
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList_4 });
            }
            return state;
        }
        case BookingActionTypes.SET_WORKSHOP_IN_BOOKING: {
            var setWorkshopBookingsAndSetSeats = cloneDeep(action.payload);
            if (!!setWorkshopBookingsAndSetSeats) {
                var setBookingWorkshops = setWorkshopBookingsAndSetSeats.setBookingWorkshops;
                if (setBookingWorkshops.length) {
                    var cloneStateProductsList_5 = cloneDeep(state.list);
                    var setWorkshop_1 = function (setBookingWorkshops, bookedTariff) {
                        setBookingWorkshops.forEach(function (setBookingWorkshop) {
                            var id = setBookingWorkshop.id, holderUuids = setBookingWorkshop.holderUuids;
                            var bookedTariffWorkshop = bookedTariff.workshops.find(function (bookedWorkshop) { return bookedWorkshop.id === id; });
                            if (bookedTariffWorkshop) {
                                holderUuids.forEach(function (setHolderUuid) { return bookedTariffWorkshop.holderUuids.push(setHolderUuid); });
                            }
                            else {
                                bookedTariff.workshops.push(setBookingWorkshop);
                            }
                        });
                    };
                    setBookingWorkshops.forEach(function (setBookingWorkshop) {
                        var productType = setBookingWorkshop.productType, ticketTypeId = setBookingWorkshop.ticketTypeId, ticketPersonId = setBookingWorkshop.ticketPersonId, voucherCode = setBookingWorkshop.voucherCode, packageNumber = setBookingWorkshop.packageNumber, packageIndex = setBookingWorkshop.packageIndex, workshops = setBookingWorkshop.workshops;
                        cloneStateProductsList_5.forEach(function (bookedProductType) {
                            if (bookedProductType.productType === productType) {
                                if (isBookingProductTypeTariff(bookedProductType)) {
                                    var bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);
                                    if (!!bookedTariff) {
                                        setWorkshop_1(workshops, bookedTariff);
                                    }
                                }
                                else if (isBookingProductTypePackage(bookedProductType)) {
                                    if (bookedProductType.packageNumber === packageNumber &&
                                        bookedProductType.packageIndex === packageIndex) {
                                        bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                            if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                                var bookedPackageTariff = findBookedPackageTariff(bookedPackageProductType, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex);
                                                if (!!bookedPackageTariff) {
                                                    setWorkshop_1(workshops, bookedPackageTariff);
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    });
                    return __assign({}, state, { list: cloneStateProductsList_5 });
                }
            }
            return state;
        }
        case BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING: {
            var removeWorkshopBookingsAndSetSeats = cloneDeep(action.payload);
            if (!!removeWorkshopBookingsAndSetSeats) {
                var removeBookingWorkshops = removeWorkshopBookingsAndSetSeats.removeBookingWorkshops;
                if (removeBookingWorkshops.length) {
                    var cloneStateProductsList_6 = cloneDeep(state.list);
                    var removeWorkshop_1 = function (removeBookingWorkshopsAssignedHolders, bookedTariff) {
                        removeBookingWorkshopsAssignedHolders.forEach(function (removeBookingWorkshopAssignedHolders) {
                            var workshopId = removeBookingWorkshopAssignedHolders.workshopId, removeHolderUuids = removeBookingWorkshopAssignedHolders.removeHolderUuids;
                            var bookedTariffWorkshopIndex = bookedTariff.workshops.findIndex(function (bookedWorkshop) { return bookedWorkshop.id === workshopId; });
                            if (bookedTariffWorkshopIndex !== INDEX_NOT_FOUND) {
                                var bookedTariffWorkshop = bookedTariff.workshops[bookedTariffWorkshopIndex];
                                bookedTariffWorkshop.holderUuids = _.difference(bookedTariffWorkshop.holderUuids, removeHolderUuids);
                                if (!bookedTariffWorkshop.holderUuids.length) {
                                    bookedTariff.workshops.splice(bookedTariffWorkshopIndex, 1);
                                }
                            }
                        });
                    };
                    removeBookingWorkshops.forEach(function (removeBookingWorkshop) {
                        var productType = removeBookingWorkshop.productType, ticketTypeId = removeBookingWorkshop.ticketTypeId, ticketPersonId = removeBookingWorkshop.ticketPersonId, voucherCode = removeBookingWorkshop.voucherCode, packageNumber = removeBookingWorkshop.packageNumber, packageIndex = removeBookingWorkshop.packageIndex, removeWorkshopsAssignedHolders = removeBookingWorkshop.removeWorkshopsAssignedHolders;
                        cloneStateProductsList_6.forEach(function (bookedProductType) {
                            if (bookedProductType.productType === productType) {
                                if (isBookingProductTypeTariff(bookedProductType)) {
                                    var bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);
                                    if (!!bookedTariff) {
                                        removeWorkshop_1(removeWorkshopsAssignedHolders, bookedTariff);
                                    }
                                }
                                else if (isBookingProductTypePackage(bookedProductType)) {
                                    if (bookedProductType.packageNumber === packageNumber &&
                                        bookedProductType.packageIndex === packageIndex) {
                                        bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                            if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                                var bookedPackageTariff = findBookedPackageTariff(bookedPackageProductType, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex);
                                                if (!!bookedPackageTariff) {
                                                    removeWorkshop_1(removeWorkshopsAssignedHolders, bookedPackageTariff);
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    });
                    return __assign({}, state, { list: cloneStateProductsList_6 });
                }
            }
            return state;
        }
        case BookingActionTypes.SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING: {
            var setContingentReservationDates = cloneDeep(action.payload);
            if (setContingentReservationDates.length) {
                var cloneStateProductsList_7 = cloneDeep(state.list);
                var setContingentReservation_1 = function (bookedTariff, index, duration, fromDate) {
                    var bookedTariffContingentReservation = bookedTariff.contingentReservations[index];
                    if (bookedTariffContingentReservation) {
                        bookedTariffContingentReservation.duration = duration;
                        bookedTariffContingentReservation.fromDate = fromDate;
                        bookedTariffContingentReservation.isValid = null;
                    }
                };
                setContingentReservationDates.forEach(function (contingentReservationDate) {
                    var productType = contingentReservationDate.productType, ticketTypeId = contingentReservationDate.ticketTypeId, ticketPersonId = contingentReservationDate.ticketPersonId, voucherCode = contingentReservationDate.voucherCode, packageNumber = contingentReservationDate.packageNumber, packageIndex = contingentReservationDate.packageIndex, duration = contingentReservationDate.duration, bookingReservationIndex = contingentReservationDate.bookingReservationIndex, day = contingentReservationDate.day;
                    cloneStateProductsList_7.forEach(function (bookedProductType) {
                        if (bookedProductType.productType === productType) {
                            if (isBookingProductTypeTariff(bookedProductType)) {
                                var bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);
                                if (!!bookedTariff) {
                                    setContingentReservation_1(bookedTariff, bookingReservationIndex, duration, day);
                                }
                            }
                            else if (isBookingProductTypePackage(bookedProductType)) {
                                if (bookedProductType.packageNumber === packageNumber &&
                                    bookedProductType.packageIndex === packageIndex) {
                                    bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                        if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                            var bookedPackageTariff = findBookedPackageTariff(bookedPackageProductType, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex);
                                            if (!!bookedPackageTariff) {
                                                setContingentReservation_1(bookedPackageTariff, bookingReservationIndex, duration, day);
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                });
                return __assign({}, state, { list: cloneStateProductsList_7 });
            }
            return state;
        }
        case BookingActionTypes.SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING: {
            var setProductBookingContingentReservations = cloneDeep(action.payload);
            if (setProductBookingContingentReservations.length) {
                var cloneStateProductsList_8 = cloneDeep(state.list);
                var setValidatedContingentReservation_1 = function (bookedTariff, contingentReservation) {
                    var bookingReservationIndex = contingentReservation.bookingReservationIndex, toDate = contingentReservation.toDate, isValid = contingentReservation.isValid;
                    var bookedTariffContingentReservation = bookedTariff.contingentReservations[bookingReservationIndex];
                    if (bookedTariffContingentReservation) {
                        bookedTariffContingentReservation.toDate = toDate;
                        bookedTariffContingentReservation.isValid = isValid;
                    }
                };
                setProductBookingContingentReservations.forEach(function (productBookingContingentReservation) {
                    var productType = productBookingContingentReservation.productType, ticketTypeId = productBookingContingentReservation.ticketTypeId, ticketPersonId = productBookingContingentReservation.ticketPersonId, voucherCode = productBookingContingentReservation.voucherCode, packageNumber = productBookingContingentReservation.packageNumber, packageIndex = productBookingContingentReservation.packageIndex, contingentReservations = productBookingContingentReservation.contingentReservations;
                    cloneStateProductsList_8.forEach(function (bookedProductType) {
                        if (bookedProductType.productType === productType) {
                            if (isBookingProductTypeTariff(bookedProductType)) {
                                var bookedTariff_1 = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);
                                if (!!bookedTariff_1) {
                                    contingentReservations.forEach(function (contingentReservation) {
                                        return setValidatedContingentReservation_1(bookedTariff_1, contingentReservation);
                                    });
                                }
                            }
                            else if (isBookingProductTypePackage(bookedProductType)) {
                                if (bookedProductType.packageNumber === packageNumber &&
                                    bookedProductType.packageIndex === packageIndex) {
                                    bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                        if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                            var bookedPackageTariff_1 = findBookedPackageTariff(bookedPackageProductType, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex);
                                            if (!!bookedPackageTariff_1) {
                                                contingentReservations.forEach(function (contingentReservation) {
                                                    return setValidatedContingentReservation_1(bookedPackageTariff_1, contingentReservation);
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                });
                return __assign({}, state, { list: cloneStateProductsList_8 });
            }
            return state;
        }
        case BookingActionTypes.SET_PARKING_RESERVATION_DATE_IN_BOOKING: {
            var setParkingReservationDate = cloneDeep(action.payload);
            if (!!setParkingReservationDate) {
                var cloneStateProductsList = cloneDeep(state.list);
                var productType_1 = setParkingReservationDate.productType, ticketTypeId_1 = setParkingReservationDate.ticketTypeId, ticketPersonId_1 = setParkingReservationDate.ticketPersonId, voucherCode_1 = setParkingReservationDate.voucherCode, packageNumber_1 = setParkingReservationDate.packageNumber, packageIndex_1 = setParkingReservationDate.packageIndex, bookingReservationIndex_1 = setParkingReservationDate.bookingReservationIndex, since_1 = setParkingReservationDate.since, until_1 = setParkingReservationDate.until;
                var setParkingReservation_1 = function (bookedTariff, index, since, until) {
                    var bookedTariffParkingReservation = bookedTariff.parkingReservations[index];
                    if (bookedTariffParkingReservation) {
                        bookedTariffParkingReservation.since = since;
                        bookedTariffParkingReservation.until = until;
                        bookedTariffParkingReservation.isValid = null;
                    }
                };
                cloneStateProductsList.forEach(function (bookedProductType) {
                    if (bookedProductType.productType === productType_1) {
                        if (isBookingProductTypeTariff(bookedProductType)) {
                            var bookedTariff = findBookedTariff(bookedProductType, ticketTypeId_1, ticketPersonId_1, voucherCode_1);
                            if (!!bookedTariff) {
                                setParkingReservation_1(bookedTariff, bookingReservationIndex_1, since_1, until_1);
                            }
                        }
                        else if (isBookingProductTypePackage(bookedProductType)) {
                            if (bookedProductType.packageNumber === packageNumber_1 &&
                                bookedProductType.packageIndex === packageIndex_1) {
                                bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                    if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                        var bookedPackageTariff = findBookedPackageTariff(bookedPackageProductType, ticketTypeId_1, ticketPersonId_1, voucherCode_1, packageNumber_1, packageIndex_1);
                                        if (!!bookedPackageTariff) {
                                            setParkingReservation_1(bookedPackageTariff, bookingReservationIndex_1, since_1, until_1);
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList });
            }
            return state;
        }
        case BookingActionTypes.SET_VALIDATED_PARKING_RESERVATION_IN_BOOKING: {
            var setProductBookingParkingReservation = cloneDeep(action.payload);
            if (!!setProductBookingParkingReservation) {
                var cloneStateProductsList = cloneDeep(state.list);
                var productType_2 = setProductBookingParkingReservation.productType, ticketTypeId_2 = setProductBookingParkingReservation.ticketTypeId, ticketPersonId_2 = setProductBookingParkingReservation.ticketPersonId, voucherCode_2 = setProductBookingParkingReservation.voucherCode, packageNumber_2 = setProductBookingParkingReservation.packageNumber, packageIndex_2 = setProductBookingParkingReservation.packageIndex, bookingReservationIndex_2 = setProductBookingParkingReservation.bookingReservationIndex, isValid_1 = setProductBookingParkingReservation.isValid, price_1 = setProductBookingParkingReservation.price;
                var setValidatedParkingReservationInBooking_1 = function (bookedTariff, isValid, price) {
                    var bookedTariffParkingReservation = bookedTariff.parkingReservations[bookingReservationIndex_2];
                    if (bookedTariffParkingReservation) {
                        bookedTariffParkingReservation.since = isValid ? bookedTariffParkingReservation.since : null;
                        bookedTariffParkingReservation.until = isValid ? bookedTariffParkingReservation.until : null;
                        bookedTariffParkingReservation.isValid = isValid;
                        bookedTariffParkingReservation.price = price;
                    }
                };
                cloneStateProductsList.forEach(function (bookedProductType) {
                    if (bookedProductType.productType === productType_2) {
                        if (isBookingProductTypeTariff(bookedProductType)) {
                            var bookedTariff = findBookedTariff(bookedProductType, ticketTypeId_2, ticketPersonId_2, voucherCode_2);
                            if (!!bookedTariff) {
                                setValidatedParkingReservationInBooking_1(bookedTariff, isValid_1, price_1);
                                bookedTariff.price = setTariffPriceToParkingReservationTotalPrice(bookedTariff);
                            }
                        }
                        else if (isBookingProductTypePackage(bookedProductType)) {
                            if (bookedProductType.packageNumber === packageNumber_2 &&
                                bookedProductType.packageIndex === packageIndex_2) {
                                bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                                    if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                        var bookedPackageTariff = findBookedPackageTariff(bookedPackageProductType, ticketTypeId_2, ticketPersonId_2, voucherCode_2, packageNumber_2, packageIndex_2);
                                        if (!!bookedPackageTariff) {
                                            setValidatedParkingReservationInBooking_1(bookedPackageTariff, isValid_1, price_1);
                                            bookedPackageTariff.price = setTariffPriceToParkingReservationTotalPrice(bookedPackageTariff);
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
                return __assign({}, state, { list: cloneStateProductsList });
            }
            return state;
        }
        case BookingActionTypes.SET_PRODUCT_HOLDER_UUID: {
            var cloneStateProductsList = cloneDeep(state.list);
            var _a = cloneDeep(action.payload), holderUuid_1 = _a.holderUuid, holderProductType_1 = _a.productType, holderTicketTypeId_1 = _a.ticketTypeId, holderTicketPersonId_1 = _a.ticketPersonId, holderPackageIndex_1 = _a.packageIndex, holderPackageNumber_1 = _a.packageNumber;
            var holderStatus_1 = {
                isHolderUuidAssigned: false,
                isExistingHolderUuidRemoved: false
            };
            cloneStateProductsList.forEach(function (bookedProductType) {
                if (holderStatus_1.isHolderUuidAssigned && holderStatus_1.isExistingHolderUuidRemoved) {
                    return;
                }
                if (isBookingProductTypeTariff(bookedProductType)) {
                    var tariffs = bookedProductType.tariffs, productType_3 = bookedProductType.productType;
                    tariffs.forEach(function (bookedTariff) {
                        if (!holderStatus_1.isExistingHolderUuidRemoved && bookedTariff.holderUuids.includes(holderUuid_1)) {
                            var indexOfTicketHolderUuid = bookedTariff.holderUuids.indexOf(holderUuid_1);
                            bookedTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
                            holderStatus_1.isExistingHolderUuidRemoved = true;
                        }
                        else if (productType_3 === holderProductType_1 &&
                            bookedTariff.ticketTypeId === holderTicketTypeId_1 &&
                            bookedTariff.ticketPersonId === holderTicketPersonId_1) {
                            bookedTariff.holderUuids.push(holderUuid_1);
                            holderStatus_1.isHolderUuidAssigned = true;
                        }
                    });
                }
                else if (isBookingProductTypePackage(bookedProductType)) {
                    var productType = bookedProductType.productType, packageNumber = bookedProductType.packageNumber, packageIndex = bookedProductType.packageIndex;
                    var isTicketHolderAssignmentPackage_1 = productType === holderProductType_1 &&
                        packageNumber === holderPackageNumber_1 &&
                        packageIndex === holderPackageIndex_1;
                    bookedProductType.productTypes.forEach(function (bookedPackageProductType) {
                        if (isBookingProductTypeTariff(bookedPackageProductType)) {
                            bookedPackageProductType.tariffs.forEach(function (bookedPackageTariff) {
                                if (!holderStatus_1.isExistingHolderUuidRemoved && bookedPackageTariff.holderUuids.includes(holderUuid_1)) {
                                    var indexOfTicketHolderUuid = bookedPackageTariff.holderUuids.indexOf(holderUuid_1);
                                    bookedPackageTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
                                    holderStatus_1.isExistingHolderUuidRemoved = true;
                                }
                                else if (isTicketHolderAssignmentPackage_1 &&
                                    bookedPackageTariff.ticketTypeId === holderTicketTypeId_1 &&
                                    bookedPackageTariff.ticketPersonId === holderTicketPersonId_1) {
                                    bookedPackageTariff.holderUuids.push(holderUuid_1);
                                    holderStatus_1.isHolderUuidAssigned = true;
                                }
                            });
                        }
                    });
                }
            });
            if (holderStatus_1.isHolderUuidAssigned) {
                return __assign({}, state, { list: cloneStateProductsList });
            }
            return state;
        }
        case BookingActionTypes.REMOVE_PRODUCT_HOLDER_UUID: {
            var cloneStateProductsList = cloneDeep(state.list);
            var _b = cloneDeep(action.payload), holderUuid_2 = _b.holderUuid, holderProductType_2 = _b.productType, holderTicketTypeId_2 = _b.ticketTypeId, holderTicketPersonId_2 = _b.ticketPersonId, holderPackageIndex_2 = _b.packageIndex, holderPackageNumber_2 = _b.packageNumber;
            var ticketHolderRemoved = cloneStateProductsList.find(function (bookedProductType) {
                if (isBookingProductTypeTariff(bookedProductType)) {
                    var tariffs = bookedProductType.tariffs, productType_4 = bookedProductType.productType;
                    return !!tariffs.find(function (bookedTariff) {
                        if (productType_4 === holderProductType_2 &&
                            bookedTariff.holderUuids.includes(holderUuid_2) &&
                            bookedTariff.ticketTypeId === holderTicketTypeId_2 &&
                            bookedTariff.ticketPersonId === holderTicketPersonId_2) {
                            var indexOfTicketHolderUuid = bookedTariff.holderUuids.indexOf(holderUuid_2);
                            bookedTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
                            return !!bookedTariff;
                        }
                    });
                }
                else if (isBookingProductTypePackage(bookedProductType)) {
                    var productType = bookedProductType.productType, packageNumber = bookedProductType.packageNumber, packageIndex = bookedProductType.packageIndex;
                    if (productType === holderProductType_2 &&
                        packageNumber === holderPackageNumber_2 &&
                        packageIndex === holderPackageIndex_2) {
                        return !!bookedProductType.productTypes.find(function (bookedPackageProductType) {
                            if (isBookingProductTypeTariff(bookedPackageProductType)) {
                                return !!bookedPackageProductType.tariffs.find(function (bookedPackageTariff) {
                                    if (bookedPackageTariff.holderUuids.includes(holderUuid_2) &&
                                        bookedPackageTariff.ticketTypeId === holderTicketTypeId_2 &&
                                        bookedPackageTariff.ticketPersonId === holderTicketPersonId_2) {
                                        var indexOfTicketHolderUuid = bookedPackageTariff.holderUuids.indexOf(holderUuid_2);
                                        bookedPackageTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
                                        return !!bookedPackageTariff;
                                    }
                                });
                            }
                        });
                    }
                }
            });
            if (!!ticketHolderRemoved) {
                return __assign({}, state, { list: cloneStateProductsList });
            }
            return state;
        }
        case BookingActionTypes.SET_PRODUCT_COUNTDOWN: {
            var expiryTimestamp = action.payload && getCountdownTimestamp(action.payload);
            return __assign({}, state, { countdown: __assign({}, state.countdown, { product: expiryTimestamp }) });
        }
        case BookingActionTypes.REMOVE_COUNTDOWNS: {
            return __assign({}, state, { countdown: cloneDeep(initialState.countdown) });
        }
        case BookingActionTypes.SET_VOUCHER_COUNTDOWN: {
            var voucherCountdowns = cloneDeep(action.payload);
            var currentAddedVoucherIsAnonymous = voucherCountdowns.some(function (voucherCountdown) { return voucherCountdown.isAnonymous; });
            var stateHasAnonymousVouchers = state.countdown.voucher.some(function (voucherCountdown) { return voucherCountdown.isAnonymous; });
            var vouchersCountdown = !stateHasAnonymousVouchers && currentAddedVoucherIsAnonymous
                ? voucherCountdowns
                : state.countdown.voucher.concat(voucherCountdowns.filter(function (voucherCountdown) {
                    return !state.countdown.voucher.some(function (stateVoucher) { return stateVoucher.voucherCode === voucherCountdown.voucherCode; });
                }));
            var productCountdown = voucherCountdowns[0].expiryTimestamp;
            return __assign({}, state, { countdown: __assign({}, state.countdown, { voucher: vouchersCountdown, product: productCountdown }) });
        }
        case BookingActionTypes.REMOVE_VOUCHER_COUNTDOWN: {
            var voucherCountdowns_1 = action.payload.voucherCountdowns;
            var tariffReleaseInMinutes = action.payload.tariffReleaseInMinutes;
            var voucherExpired_1 = false;
            var newProductCountdownState = null;
            var newVoucherCountdownState = state.countdown.voucher.filter(function (stateVoucherCountdown) {
                return !voucherCountdowns_1.some(function (voucherCountdown) {
                    var isSameVoucherCode = voucherCountdown.voucherCode === stateVoucherCountdown.voucherCode;
                    if (!voucherExpired_1 && isSameVoucherCode) {
                        voucherExpired_1 = isVoucherExpired(stateVoucherCountdown);
                    }
                    return isSameVoucherCode;
                });
            });
            if (!!state.list.length) {
                if (voucherExpired_1) {
                    newProductCountdownState = state.countdown.product;
                }
                else {
                    newProductCountdownState = getCountdownTimestamp(tariffReleaseInMinutes);
                }
            }
            return __assign({}, state, { countdown: __assign({}, state.countdown, { voucher: cloneDeep(newVoucherCountdownState), product: newProductCountdownState }) });
        }
        case BookingActionTypes.SET_IS_ANONYMOUS_PRODUCT_SENT_TO_API: {
            var isAnonymousProductSentToAPI = action.payload;
            return __assign({}, state, { isAnonymousProductSentToAPI: isAnonymousProductSentToAPI });
        }
        case BookingActionTypes.SET_CLAIMED_TICKET_HASH: {
            var claimedTicketHash = action.payload;
            return __assign({}, state, { claimedTicketHash: claimedTicketHash });
        }
        case BookingActionTypes.SET_CLAIMED_TICKET_HASH_VALID: {
            var claimedTicketHashValid = action.payload;
            return __assign({}, state, { claimedTicketHashValid: claimedTicketHashValid });
        }
        case BookingActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_BOOKING_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
