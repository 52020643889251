import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { State, getTicketHolderInputSets } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { SendingOptionModel } from '@products/models/product-selection.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { SetSendingOptions } from '@store/products/product-selection/product-selection.actions';
import {
  getSendingOptions,
  isRedeemedAnonymousVoucherProductInProductSelectionList
} from '@store/products/product-selection/product-selection.selectors';
import { SetBuyerVisitorCheckbox } from '@store/step-forms/steps-forms.actions';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { first } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-sending-options',
  templateUrl: './sending-options.component.html',
  styleUrls: ['./sending-options.component.scss']
})
export class SendingOptionsComponent implements OnChanges, OnDestroy {
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() isRedeemedAnonymousVoucherProductInProductSelectionList: boolean;
  ticketSendingOptions: SendingOptionModel[] = [];
  selectedSendingOption = '';
  sendingOptionTicketName: string;
  showSendingOptions: boolean = true;
  subscription: Subscription = new Subscription();
  wasAnonymousTicketTaken: boolean = false;
  optionSelectedBeforeAnonim: string = '';
  changedUrl: string = '';

  readonly TicketSendingOptions = TicketSendingOptions;
  private readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private router: Router,
    private location: Location
  ) {
    this.subscription.add(
      observableCombineLatest([
        this.store.pipe(select(getSendingOptions)),
        this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
      ])
        .filter(([getSendingOptions]) => !!getSendingOptions)
        .subscribe(([getSendingOptions, isRedeemedAnonymousVoucherProductInProductSelectionList]) => {
          const sendingOptions: SendingOptionModel[] = getSendingOptions;
          const anonymousTicketTaken = isRedeemedAnonymousVoucherProductInProductSelectionList;

          // delete query params, change URL
          if (
            !(
              this.router.url.includes('utm_source') ||
              this.router.url.includes('utm_medium') ||
              this.router.url.includes('utm_campaign') ||
              this.router.url.includes('utm_term') ||
              this.router.url.includes('utm_content')
            )
          ) {
            this.changedUrl = this.router.url.split('?')[0];
            this.location.replaceState(this.changedUrl);
          }

          if (!anonymousTicketTaken && this.wasAnonymousTicketTaken) {
            const anonyMobilePerOwner = sendingOptions.find(
              option => option.value === TicketSendingOptions.MobilePerOwner
            );
            if (!!anonyMobilePerOwner) {
              anonyMobilePerOwner.isSelected = this.wasAnonymousTicketTaken = false;
            }

            const allToBuyer = sendingOptions.find(option => option.value === this.optionSelectedBeforeAnonim);
            if (!!allToBuyer) {
              allToBuyer.isSelected = true;
            }
          }

          sendingOptions.forEach(option => {
            if (anonymousTicketTaken) {
              if (option.value === TicketSendingOptions.MobilePerOwner) {
                this.selectedSendingOption = TicketSendingOptions.MobilePerOwner;
                option.isSelected = this.wasAnonymousTicketTaken = true;
                this.showSendingOptions = false;
              } else {
                option.isSelected = false;
              }
            } else {
              if (option.isSelected) {
                this.optionSelectedBeforeAnonim = this.selectedSendingOption = option.value;
              }
            }
          });

          if (this.selectedSendingOption === '') {
            let selectedSendingOption = sendingOptions.find(option => option.isEnabled);
            if (!!selectedSendingOption) {
              selectedSendingOption.isSelected = true;
              this.selectedSendingOption = selectedSendingOption.value;
            }
          }

          this.ticketSendingOptions = this.addOptionVisibility(sendingOptions);

          if (!anonymousTicketTaken) {
            this.showSendingOptions =
              this.ticketSendingOptions.filter(option => option.isEnabled && option.isVisible && option.isBasic)
                .length > 1;
          }

          this.setSendingOptionTicketName();

          if (this.selectedSendingOption === TicketSendingOptions.TicketRetrievalLink) {
            this.store
              .pipe(
                select(getTicketHolderInputSets),
                first()
              )
              .subscribe(holders => {
                holders.forEach(holder => {
                  const invalid = holder.inputSet.list.reduce((acc, curr) => {
                    return acc || (['email', 'firstName', 'lastNmae'].includes(curr.key) && !curr.value);
                  }, false);
                  if (invalid) {
                    this.formsService.setFormValidity(false, null, holder.formInfo);
                  }
                });
              });
          }

          if (
            this.selectedSendingOption === TicketSendingOptions.MobilePerOwner &&
            !!this.exhibitionSettings &&
            this.exhibitionSettings.emailIsMandatoryForMobileTicket
          ) {
            this.store
              .pipe(
                select(getTicketHolderInputSets),
                first()
              )
              .subscribe(holders => {
                holders.forEach(holder => {
                  const invalid = holder.inputSet.list.reduce((acc, curr) => {
                    return acc || (['verifyEmail', 'email'].includes(curr.key) && !curr.value);
                  }, false);

                  if (invalid) {
                    this.formsService.setFormValidity(false, null, holder.formInfo);
                  }
                });
              });
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSendingOptionsOnAnonymousVoucherProductRedeem(changes);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addOptionVisibility(sendingOptions) {
    const optionsWithVisibility = sendingOptions.map(option => {
      option.isVisible = option.isEnabled;
      return option;
    });
    return optionsWithVisibility;
  }

  setSendingOptionTicketName() {
    switch (this.selectedSendingOption) {
      case TicketSendingOptions.NormalPerOwner:
        this.sendingOptionTicketName = 'Print@Home';
        break;
      case TicketSendingOptions.MobilePerOwner:
        this.sendingOptionTicketName = 'Mobile';
        break;
      case TicketSendingOptions.TicketRetrievalLink:
        this.sendingOptionTicketName = 'Print@Home';
        break;
    }
  }

  sendingOptionChanged() {
    const updatedSendingOptions = this.ticketSendingOptions.map(option => {
      option.isSelected = option.value === this.selectedSendingOption;
      return option;
    });

    this.setSendingOptionTicketName();

    this.store.dispatch(new SetSendingOptions(updatedSendingOptions));

    if (this.selectedSendingOption !== TicketSendingOptions.TicketRetrievalLink) {
      this.store.dispatch(
        new SetBuyerVisitorCheckbox({
          buyerVisitorCheckedSlideIndex: null,
          isBuyerVisitorChecked: false,
          showVisitorQuestionnaire: false
        })
      );

      this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
      this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.visitorQuestionnaire);
    }
  }

  setSendingOptionsOnAnonymousVoucherProductRedeem(changes: SimpleChanges) {
    const { isRedeemedAnonymousVoucherProductInProductSelectionList } = changes;
    const currentIsRedeemedAnonymousVoucherProductInProductSelectionList: boolean =
      isRedeemedAnonymousVoucherProductInProductSelectionList.currentValue;

    if (currentIsRedeemedAnonymousVoucherProductInProductSelectionList && this.ticketSendingOptions.length) {
      this.setSendingOptions();
    }
  }

  setSendingOptions() {
    this.store.dispatch(new SetSendingOptions(this.ticketSendingOptions));
  }
}
