import { WorkshopStatusState } from '@products/models/workshop-status.model';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  ActionTypes as WorkshopStatusActionTypes,
  Actions as WorkshopStatusActions
} from '@store/products/status/workshop/workshop.actions';

export { WorkshopStatusState as State };

export const initialState: WorkshopStatusState = {
  availableSeats: {},
  assignedHolders: {},
  isWorkshopLoading: false,
  isWorkshopDetailModalOpen: false
};

export const workshopStatusReducer = (
  state: WorkshopStatusState = initialState,
  action: WorkshopStatusActions | ProductActions
): WorkshopStatusState => {
  switch (action.type) {
    case WorkshopStatusActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_STATUS: {
      const availableSeats = action.payload;

      return {
        ...state,
        availableSeats: { ...state.availableSeats, ...availableSeats }
      };
    }

    case WorkshopStatusActionTypes.SET_WORKSHOP_ASSIGNED_HOLDERS_STATUS: {
      const assignedHolders = action.payload;

      return {
        ...state,
        assignedHolders: { ...state.assignedHolders, ...assignedHolders }
      };
    }

    case WorkshopStatusActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_AND_ASSIGNED_HOLDERS_STATUS: {
      const workshopSeatsAndHolders = action.payload;

      if (!workshopSeatsAndHolders) {
        return state;
      }

      const { assignedHolders, availableSeats } = workshopSeatsAndHolders;

      return {
        ...state,
        availableSeats: { ...state.availableSeats, ...availableSeats },
        assignedHolders: { ...state.assignedHolders, ...assignedHolders }
      };
    }

    case WorkshopStatusActionTypes.SET_IS_WORKSHOP_LOADING_STATUS: {
      const isWorkshopLoading = action.payload;

      return {
        ...state,
        isWorkshopLoading
      };
    }

    case WorkshopStatusActionTypes.SET_INITIAL_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
      return {
        ...state,
        isWorkshopDetailModalOpen: false
      };
    }

    case WorkshopStatusActionTypes.SET_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
      const isWorkshopDetailModalOpen = action.payload;

      return {
        ...state,
        isWorkshopDetailModalOpen
      };
    }

    case WorkshopStatusActionTypes.TOGGLE_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
      return {
        ...state,
        isWorkshopDetailModalOpen: !state.isWorkshopDetailModalOpen
      };
    }

    case WorkshopStatusActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
