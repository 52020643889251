import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

import { ActionsSubject, Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';

import { ofType } from '@ngrx/effects';
import { SelectOption } from '@store//exhibition/exhibition.interface';
import { SetSpinnerValue } from '@store//helpers/helper.actions';
import {
  ExhibitionSettingModel,
  OperatorsSettingsModel,
  QuestionnaireDataInput
} from '@store/customization/customization.interfaces';
import { FormInputsPayloadModel } from '@store/step-forms/step.interface';
import { SetContinueAsGuest, SetInputs } from '@store/step-forms/steps-forms.actions';
import { CreateProfile, UpdateProfile, ActionTypes as UserActionTypes } from '@store/user/user.actions';
import {
  State,
  getAllCountriesList,
  getAllDepartments,
  getAllOccupationalGroups,
  getAllProfessions,
  getAllTitles,
  getBuyerInfo,
  getBuyerInfoFromURL,
  getDepartments,
  getExhibitionSettings,
  getLanguage,
  getOccupationalGroups,
  getOperatorsSettings,
  getProfessions,
  getProfile,
  getSelectedExhibitionId,
  getSelfRegistration,
  getSpinnerValue,
  getTitles,
  isContinueAsGuest,
  isProfileLoading
} from '@app/app.reducer';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { HelperService } from '@store/helpers/helper.service';
import {
  UserProfileModel,
  UserProfileUpdateModel
} from '@store/user/user.interface';
import {
  getNewAccountForm,
  getProfileEditForm
} from './form-inputs.edit-account';
import { RegistrationQuestionnaireComponent } from './registration-questionnaire/registration-questionnaire.component';

@Component({
  moduleId: module.id,
  selector: 'app-edit-account-form',
  templateUrl: './edit-account-form.component.html',
  styleUrls: ['./edit-account-form.component.scss']
})
export class EditAccountFormComponent implements OnInit, OnDestroy {
  @ViewChild(RegistrationQuestionnaireComponent) registrationQuestionnaireComponent: RegistrationQuestionnaireComponent;

  @Input() formType: 'new-account' | 'edit-account';
  @Input() validationStepName: Array<string>;
  @Input() userUrlFormData: object;
  @Output() isProfileUpdated = new EventEmitter<Boolean>();

  isSpinnerActive$: Observable<boolean>;
  settings$: Observable<ExhibitionSettingModel>;

  buyerInfo: any;
  isContinueAsGuest: boolean;
  hideFormInputs: boolean;
  processingSubmit: boolean = false;
  isEditEnabled: boolean = false;
  validRegistrationQuestionnaire: boolean = true; // set questionare validity to true.. it is updated once added to page
  editAccountAction: string[] = ['editAccountForm'];
  listOfAllCountries: Array<any>;
  feedbackMessages = [];
  operatorsSettings: OperatorsSettingsModel;
  form: FormGroup;
  inputs: InputBase<any>[];

  private userProfile: UserProfileModel;
  private questionnaireDataToSave: Object = null;
  private unsubscribe = new Subject<void>();
  private buyerInfoActionName = ['personal', 'buyerinfo'];

  constructor(
    private store: Store<State>,
    private helperService: HelperService,
    private formsService: FormsService,
    private actionSubject: ActionsSubject
  ) {
    this.store.dispatch(new SetSpinnerValue(false));
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    const newInProfile = {};

    inputs.forEach(input => {
      newInProfile[input.key] = input.value;

      if (input.controlType === 'checkbox') {
        input.options.forEach(option => {
          newInProfile[option.key] = option.value;
        });
      }
    });

    if (this.formType === 'new-account' && rerender) {
      this.generateNewAccountForm(newInProfile, this.listOfAllCountries);
    }

    this.inputs = this.formsService.updateInputs(this.inputs, inputs);

    //because of google autocomplete we need to rebuild the form with autocomplete results
    if (rerender) {
      this.form = this.formsService.toFormGroup(this.inputs);
      this.calculateFeedbackMessages();
    }
  };

  ngOnInit() {
    this.settings$ = this.store.pipe(select(getExhibitionSettings));
    this.isSpinnerActive$ = this.store.pipe(select(getSpinnerValue));

    if (this.formType === 'edit-account') {
      // when new data for profile from API is loaded process it
      combineLatest([
        this.store.pipe(select(isProfileLoading)),
        this.store.pipe(select(getProfile))
      ])
      .pipe(
        first(([isProfileLoading, profile]) => !isProfileLoading && !!profile)
      )
      .subscribe(() => this.getProfile());
    }

    this.store.pipe(
      select(isContinueAsGuest),
      takeUntil(this.unsubscribe)
    )
    .subscribe(isContinueAsGuest => {
      this.isContinueAsGuest = isContinueAsGuest;
      const isValidationStepRegistration = this.validationStepName && this.validationStepName[1] === 'registration';

      if (isValidationStepRegistration) {
        const isValidationStepTickets: boolean = this.validationStepName[0] === 'tickets';
        const isValidationStepPersonal: boolean = this.validationStepName[0] === 'personal';

        if (isValidationStepPersonal || isValidationStepTickets) {
          if (isValidationStepPersonal) {
            this.hideFormInputs = isContinueAsGuest;
          }

          if (this.inputs) {
            this.inputs = [...this.enablePasswordInForm(!isContinueAsGuest, this.inputs)];
          }
        }
      }

      this.form = this.formsService.toFormGroup(this.inputs);
      this.calculateFeedbackMessages();
    });

    combineLatest([
      this.store.pipe(select(getAllCountriesList)),
      this.store.pipe(select(getBuyerInfoFromURL))
    ])
    .pipe(
      first(([allCountriesList]) => !!allCountriesList),
      takeUntil(this.unsubscribe)
    )
    .subscribe(([allCountriesList, buyerInfoFromURL]) => {
      this.listOfAllCountries = allCountriesList;
      const prefilledValues = {};

      if (buyerInfoFromURL) {
        Object.keys(buyerInfoFromURL).forEach(parameter =>
          prefilledValues[buyerInfoFromURL[parameter].key] = buyerInfoFromURL[parameter].value
        );
      }

      if (this.userUrlFormData && (!buyerInfoFromURL || Object.keys(buyerInfoFromURL).length < 1)) {
        Object.keys(this.userUrlFormData).forEach(key => prefilledValues[key] = this.userUrlFormData[key]);
      }

      if (this.formType === 'new-account') {
        this.isEditEnabled = true;
        this.generateNewAccountForm(prefilledValues, allCountriesList);
      }
    });

    this.actionSubject.pipe(ofType(UserActionTypes.POST_PROFILE))
      .subscribe(() => setTimeout(() => this.processingSubmit = false, 3000));

    this.store.pipe(
      select(getLanguage),
      filter(() => !!this.inputs)
    ).subscribe(() => this.form = this.formsService.toFormGroup(this.inputs));

    if (this.form){
      this.feedbackMessages = [];
      this.setAccountFormFeedbackMessages();
      this.setQuestionnaireFeedbackMessages();

      if (this.feedbackMessages.length === 0) {
        this.validRegistrationQuestionnaire = true;
      }

      this.calculateFeedbackMessages();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  isButtonDisabled() {
    return (
      this.processingSubmit ||
      !this.form.valid ||
      !this.validRegistrationQuestionnaire
    );
  }

  calculateFeedbackMessages(questionnaireChanged: boolean = false) {
    if (!this.form) return;

    if (questionnaireChanged) {
      this.feedbackMessages = [];
      this.setAccountFormFeedbackMessages();
      this.setQuestionnaireFeedbackMessages();

      if (this.feedbackMessages.length === 0) {
        this.validRegistrationQuestionnaire = true;
      }
    } else {
      this.form.valueChanges.subscribe(() => {
        this.feedbackMessages = [];
        this.setAccountFormFeedbackMessages();
        this.setQuestionnaireFeedbackMessages();

        if (this.feedbackMessages.length === 0) {
          this.validRegistrationQuestionnaire = true;
        }
      });
    }
  }

  setAccountFormFeedbackMessages() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;

      if (controlErrors != null) {
        const control = this.inputs.filter(p => p.key === key)[0];

        Object.keys(controlErrors).forEach(() =>
          this.feedbackMessages.push({
            key: key,
            label: control.label,
            translate: control.translate
          })
        );
      }
    });
  }

  setQuestionnaireFeedbackMessages() {
    if (!this.registrationQuestionnaireComponent || this.validRegistrationQuestionnaire) {
      return;
    }

    const questionnaireForm = this.registrationQuestionnaireComponent.form;

    Object.keys(questionnaireForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = questionnaireForm.get(key).errors;

      if (controlErrors != null) {
        const control = this.registrationQuestionnaireComponent.inputs.filter(p => p.key === key)[0];

        if (control.hidden) {
          return;
        }

        Object.keys(controlErrors).forEach(() =>
          this.feedbackMessages.push({
            key: key,
            label: control.label,
            translate: control.translate
          })
        );
      }
    });
  }

  enableOrDisableInputs() {
    const disabled = !this.isEditEnabled;

    this.inputs.forEach((input: InputBase<any>) => {
      if (input.controlType === 'dateInput'){
        setTimeout(() => input.disabled = disabled, 0);
      } else {
        input.disabled = disabled;
      }
    });
  }

  getProfile() {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getProfile)),
      this.store.pipe(select(getOperatorsSettings)),
      this.store.pipe(select(getAllCountriesList)),
      this.store.pipe(select(getTitles)),
      this.store.pipe(select(getAllTitles)),
      this.store.pipe(select(getProfessions)),
      this.store.pipe(select(getAllProfessions)),
      this.store.pipe(select(getDepartments)),
      this.store.pipe(select(getAllDepartments)),
      this.store.pipe(select(getOccupationalGroups)),
      this.store.pipe(select(getAllOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel,
          UserProfileModel,
          OperatorsSettingsModel,
          string[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[]
        ]) => {
          const [
            exhibitionSettings,
            profile,
            operatorSettings,
            allCountries,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups
          ] = data;

          return !!profile
            && !!operatorSettings
            && !!allCountries
            && !!allTitles
            && !!allProfessions
            && !!allDepartments
            && !!allOccupationalGroups ;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([
          settings,
          profile,
          operatorsSettings,
          allCountries,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups
        ]) => {

        this.userProfile = profile;

        const inputTitles = titles ? titles : [];
        const inputProfessions = professions ? professions : [];
        const inputDepartments = departments ? departments : [];
        const inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];

        if (profile && operatorsSettings) {
          let profileEditForm: InputBase<any>[] = [];

          let isGoogleAutocompleteEnabled = false;

          //workaround for bug #2744 ""Find address" isn't visible in your profile despite being set up in admin":
          //in case we're editing the profile from the operator level we wouldn't get the event level settings and also the isGoogleAutocompleteEnabled property
          //but that property is fixed, permanently set to true in web API code so I think it should be fine if we interpret it as true on the operator level by default.
          //isGoogleAutocompleteEnabled property only influences the visibility of the SearchAddress field which has its own isVisible property defined in operator level settings anyway.
          if (settings === null || (settings.isGoogleAutocompleteEnabled)) {
            isGoogleAutocompleteEnabled = true;
          }

          profileEditForm = getProfileEditForm(
            settings,
            operatorsSettings,
            isGoogleAutocompleteEnabled,
            inputTitles,
            inputProfessions,
            inputDepartments,
            inputOccupationalGroups
          );

          const updatedProfileEditForm = profileEditForm.map(profileItem => {
            let updatedItem;

            if (
              profile.hasOwnProperty(profileItem.key) &&
              (profile[profileItem.key] || profile[profileItem.key] === 0)
            ) {
              updatedItem = {
                ...profileItem,
                value: this.formsService.getFunctionTextValue(
                  profile,
                  profileItem,
                  inputTitles,
                  allTitles,
                  inputProfessions,
                  allProfessions,
                  inputDepartments,
                  allDepartments,
                  inputOccupationalGroups,
                  allOccupationalGroups
                )
              };

              this.formsService.resetInvalidFunctionValue(
                updatedItem,
                inputTitles,
                inputProfessions,
                inputDepartments,
                inputOccupationalGroups,
                (input: InputBase<any>) => {
                  updatedItem = {
                    ...input,
                    value: null
                  };
                }
              );
            } else {
              updatedItem = {
                ...profileItem,
                value: profileItem.key === 'newsletterChecked' ? 'false' : ''
              };
            }

            if (profileItem.controlType === 'checkbox') {
              updatedItem.options.forEach(option => {
                if (profile.hasOwnProperty(option.key)) {
                  option.value = profile[option.key];
                }
              });
            }

            return updatedItem;
          });

          this.inputs = this.formsService.updateInputs(this.inputs, updatedProfileEditForm);

          this.addCountriesToDropdown(this.inputs, allCountries);

          this.form = this.formsService.toFormGroup(updatedProfileEditForm);
          this.calculateFeedbackMessages();

          /* if profile is not valid on load, make it editable straight away */
          const validationCallback = () => {
            if (!!this.form && !this.form.valid) {
              this.isEditEnabled = true;

              Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsTouched());

              this.enableOrDisableInputs();
            }
          };

          this.helperService.triggerCallbackOnceFormValidationIsDone(this.form, validationCallback, true);
          /* END: if profile is not valid on load, make it editable straight away */

          this.enableOrDisableInputs();
        }
      });
  }

  generateNewAccountForm(prefilledValues, listOfAllCountries) {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getOperatorsSettings)),
      this.store.pipe(select(isContinueAsGuest)),
      this.store.pipe(select(getTitles)),
      this.store.pipe(select(getProfessions)),
      this.store.pipe(select(getDepartments)),
      this.store.pipe(select(getOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel,
          OperatorsSettingsModel,
          boolean,
          SelectOption[],
          SelectOption[],
          SelectOption[],
          SelectOption[]
        ]) => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([
        settings,
        operatorsSettings,
        isContinueAsGuest,
        titles,
        professions,
        departments,
        occupationalGroups
      ]) => {
        const newAccountForm = getNewAccountForm(
          settings,
          operatorsSettings,
          titles,
          professions,
          departments,
          occupationalGroups
        );

        const updatedNewAccountForm = newAccountForm.map(profileItem => {
          let updatedItem;

          if (
            prefilledValues.hasOwnProperty(profileItem.key) &&
            (prefilledValues[profileItem.key] ||
              prefilledValues[profileItem.key] === 0)
          ) {
            updatedItem = {
              ...profileItem,
              value: prefilledValues[profileItem.key]
            };
          } else {
            updatedItem = { ...profileItem, value: '' };
          }

          if (profileItem.controlType === 'checkbox') {
            updatedItem.options.forEach(option => {
              if (prefilledValues.hasOwnProperty(option.key)) {
                option.value = prefilledValues[option.key];
              }
            });
          }

          return updatedItem;
        });

        const updatedInputs = this.enablePasswordInForm(!isContinueAsGuest, updatedNewAccountForm);

        this.inputs = this.formsService.updateInputs(this.inputs, updatedInputs);

        this.addCountriesToDropdown(this.inputs, listOfAllCountries);

        this.form = this.formsService.toFormGroup(this.inputs);
        this.calculateFeedbackMessages();

        this.enableOrDisableInputs();
      });
  }

  enablePasswordInForm(
    enable: boolean,
    updatedNewAccountForm: InputBase<any>[]
  ) {
    updatedNewAccountForm.forEach(input => {
      if (input.key.endsWith('password')) {
        input.hidden = !enable;
        input.required = enable;
      }
    });

    return updatedNewAccountForm;
  }

  sumbitProfileUpdate() {
    this.isEditEnabled = false;
    this.enableOrDisableInputs();

    let userData: UserProfileUpdateModel = this.helperService.processFormValuesBeforeSave(this.form.value) as UserProfileUpdateModel;
    userData = this.profileUpdateWithAdditionalProps(userData);

    this.store.dispatch(new UpdateProfile(userData));

    this.isProfileUpdated.emit(true);
  }

  setIsAsGuest(event) {
    this.store.dispatch(new SetContinueAsGuest(event.target.checked));

    setTimeout(() => {
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });

      const questionnaireForm = this.registrationQuestionnaireComponent ? this.registrationQuestionnaireComponent.form : null;

      if (questionnaireForm) {
        questionnaireForm.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true
        });
      }
    }, 0);
  }

  submitNewAccount() {
    if (this.processingSubmit || !this.form.valid || !this.validRegistrationQuestionnaire) {
      return;
    }

    this.store.dispatch(new SetSpinnerValue(true));
    this.processingSubmit = true;

    combineLatest([
      this.store.pipe(select(getLanguage)),
      this.store.pipe(select(getSelectedExhibitionId))
    ])
      .pipe(first())
      .subscribe(([language, exhibitionId]) => {
        const userData: any = this.helperService.processFormValuesBeforeSave(this.form.value);

        delete userData.address;
        delete userData['verify-email'];
        delete userData['verify-password'];

        if (!userData.hasOwnProperty('provider')) {
          userData['provider'] = '';
        }

        if (!userData.hasOwnProperty('providerKey')) {
          userData['providerKey'] = '';
        }

        if (this.questionnaireDataToSave) {
          userData['questionnaire'] = this.questionnaireDataToSave;
        }

        userData.language = language;
        userData.EventId = exhibitionId;
        userData.isGuest = this.isContinueAsGuest;

        this.store.dispatch(new CreateProfile(userData));

        //If we have the validation step names object from parent, copy data to buyer
        if (this.validationStepName && this.isContinueAsGuest && this.inputs) {
          this.store.pipe(
            select(getBuyerInfo),
            first()
          )
          .subscribe(buyerInfo => {
            // overwrite the buyer form with updated data from the registratin form
            buyerInfo.list.forEach(element => {
              const sameInput = this.inputs.find((input: InputBase<any>) =>  input.value && input.key === element.key);

              if (sameInput) {
                element.value = sameInput.value;
              }
            });

            const buyerInfoPayload: FormInputsPayloadModel = {
              formInfo: this.buyerInfoActionName,
              inputSet: {
                rerender: true,
                list: buyerInfo.list
              }
            };

            this.store.dispatch(new SetInputs(buyerInfoPayload));
          });
        }
      });
  }

  registrationQuestionnaireIsValid(isValid) {
    this.validRegistrationQuestionnaire = isValid;
  }

  questionnaireDataChanged(formInputs: InputBase<any>[]) {
    if (formInputs) {
      formInputs.map((input: InputBase<any>) => {
        if (input.previousValueId) {
          const element = formInputs.find(
            p => p.value == input.previousValueId || (p.controlType === 'checkbox' &&
              p.options.find((option: { value: boolean; key: number }) =>
                option.value === true && option.key === input.previousValueId
              )
            )
          );

          input.hidden = !element;
        }
      });

      const questionnaireValues = this.helperService.processQuestionnaireValuesBeforeSave(formInputs);

      if (questionnaireValues.length) {
        this.store.pipe(
          select(getSelectedExhibitionId),
          first()
        )
        .subscribe((id: number) => {
          if (id === null) {
            id = -1;
          }

          this.questionnaireDataToSave = {
            eventId: id,
            values: questionnaireValues
          };
        });
      }

      this.calculateFeedbackMessages(true);
    }
  }

  onProfileEdit() {
    this.isEditEnabled = true;
    this.enableOrDisableInputs();
  }

  cancelProfileEdit() {
    this.isEditEnabled = false;
    this.enableOrDisableInputs();
  }

  addCountriesToDropdown(inputs, countryList) {
    inputs.map(input => {
      if (input.key === 'country') {
        this.formsService.translateCountries(countryList).pipe(
          takeUntil(this.unsubscribe),
          switchMap(countries => countries)
        )
        .subscribe(countries => input.options = countries);
      }
    });
  }

  profileUpdateWithAdditionalProps(
    userData: UserProfileUpdateModel
  ): UserProfileUpdateModel {
    let updatedData = { ...userData };

    combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getLanguage)),
      this.store.pipe(select(getSelfRegistration))
    ])
    .pipe(
      first(([eventId, language]) => !!(eventId && language))
    )
    .subscribe(([EventId, language, IsSelfRegistration]) =>
      updatedData = { ...updatedData, EventId, language, IsSelfRegistration }
    );

    updatedData.id = this.userProfile.id;

    delete updatedData.address;

    return updatedData;
  }
}
