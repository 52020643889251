<ng-container *ngIf="productSelectionProductsAndExhibitionState$ | async as productSelectionProductsAndExhibitionState">
  <app-loader
    *ngIf="
      !productSelectionProductsAndExhibitionState.productSelectionListProducts ||
      !productSelectionProductsAndExhibitionState.exhibitionSettings ||
      !productSelectionProductsAndExhibitionState.isUserAccountLimitChecked
    "
  ></app-loader>
  <div
    class="page-padding-wrapper"
    *ngIf="
      productSelectionProductsAndExhibitionState.productSelectionListProducts &&
      productSelectionProductsAndExhibitionState.exhibitionSettings &&
      productSelectionProductsAndExhibitionState.isUserAccountLimitChecked
    "
  >
    <ng-container *ngIf="productSelectionViewModel$ | async as productSelectionViewModel">
      <app-product-selection-login
        [isRedeemedAnonymousVoucherProductInProductSelectionList]="
          productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList
        "
        [notLoggedAndLoginMandatory]="productSelectionViewModel.notLoggedAndLoginMandatory"
      ></app-product-selection-login>

      <ng-container
        *ngIf="
          !productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList &&
          productSelectionViewModel.isVerifiedAccountRequired &&
          !productSelectionViewModel.isAccountVerified
        "
      >
        <app-web-shop-account-verification></app-web-shop-account-verification>
      </ng-container>
      <ng-container
        *ngIf="
          productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList ||
          (!productSelectionViewModel.notLoggedAndLoginMandatory &&
            !(productSelectionViewModel.isVerifiedAccountRequired && !productSelectionViewModel.isAccountVerified))
        "
      >
        <app-walk-thru-info
          class="step-walkthrough-wrap"
          *ngIf="!productSelectionViewModel.isSelfRegistration"
        ></app-walk-thru-info>

        <div
          class="top-info-box"
          data-translation="ticket-selection.top-info-text"
          data-translation-includes-children
          *ngIf="
            !('ticket-selection.top-info-text' | translate | isHiddenTranslation) &&
            !productSelectionViewModel.isSelfRegistration
          "
          [appRichText]="'ticket-selection.top-info-text' | translate"
        ></div>

        <ng-container *ngIf="productSelectionProductsStatusViewModel$ | async as productsStatusViewModel">
          <section
            class="product-selection ticket-section"
            *ngIf="
              productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList ||
              !productSelectionViewModel.notLoggedAndLoginMandatory
            "
          >
            <ng-container *ngIf="productSelectionProductsAndExhibitionState.exhibitionSettings as exhibitionSettings">
              <ng-container
                *ngIf="!productSelectionViewModel.isSelfRegistration && productsStatusViewModel.tariffStatus"
              >
                <div class="voucher-wrap" *ngIf="productSelectionViewModel.showPromoCode">
                  <app-voucher
                    [isSelfRegistration]="productSelectionViewModel.isSelfRegistration"
                    [isRedeemedAnonymousVoucherProductInProductSelectionList]="
                      productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList
                    "
                    [maxTicketLimit]="exhibitionSettings.limitBoughtTickets"
                    [limitPromoCodes]="exhibitionSettings.limitPromoCodes"
                    [limitLimitedPromoCodes]="exhibitionSettings.limitLimitedPromocodes"
                    [ticketLimitPerUserAccount]="exhibitionSettings.ticketLimitPerUserAccount"
                    [isVoucherIncludedPerUserAccountLimit]="exhibitionSettings.isVoucherIncludedPerUserAccountLimit"
                    [currentUserAccountTicketLimit]="productsStatusViewModel.tariffStatus.currentUserAccountTicketLimit"
                    [bookedProductsCount]="productsStatusViewModel.bookingStatus.bookedProductsCount"
                  ></app-voucher>
                </div>
              </ng-container>
              <ng-container *ngIf="productSelectionProductsAndExhibitionState.voucherProducts as voucherProducts">
                <ng-container *ngFor="let voucherProduct of voucherProducts; trackBy: voucherProductsTrackBy">
                  <app-product
                    [selectedExhibition]="productSelectionProductsAndExhibitionState.selectedExhibition"
                    [exhibitionSettings]="exhibitionSettings"
                    [productSelectionViewModel]="productSelectionViewModel"
                    [productsStatusViewModel]="productsStatusViewModel"
                    [product]="voucherProduct"
                  ></app-product>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList">
                <ng-container
                  *ngFor="
                    let productListItem of productSelectionProductsAndExhibitionState.productSelectionListProducts;
                    trackBy: productSelectionListTrackBy
                  "
                >
                  <ng-container *ngIf="!!productListItem.products">
                    <app-product-group
                      [selectedExhibition]="productSelectionProductsAndExhibitionState.selectedExhibition"
                      [exhibitionSettings]="exhibitionSettings"
                      [productSelectionViewModel]="productSelectionViewModel"
                      [productsStatusViewModel]="productsStatusViewModel"
                      [productGroup]="productListItem"
                    ></app-product-group>
                  </ng-container>
                </ng-container>
              </ng-container>

              <hr />

              <app-info-block
                infoText="ticket-selection.anonymous-ticket-message"
                *ngIf="productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList"
              ></app-info-block>

              <div class="confirmation-total" *ngIf="!!('ticket-selection.total' | translate | hideTranslation)">
                <h3 class="total black" data-translation="ticket-selection.total">
                  {{ 'ticket-selection.total' | translate | hideTranslation }}:
                  <span class="price">{{
                    productsStatusViewModel.bookingStatus.bookedProductsTotalPrice
                      | formatPrice: exhibitionSettings.currencySettings
                  }}</span>
                </h3>
              </div>

              <app-sending-options
                [exhibitionSettings]="exhibitionSettings"
                [isRedeemedAnonymousVoucherProductInProductSelectionList]="
                  productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList
                "
              ></app-sending-options>
              <app-step-navigation-buttons></app-step-navigation-buttons>
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
