import { Injectable } from '@angular/core';
import * as fromRoot from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { ProductService } from '@products/services/product.service';
import { consoleLog } from '@shared/app-utils';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { ResetReducer as AdditionalServicesResetReducer } from '@store/additional-services/additional-services.actions';
import {
  PartialResetReducer as CustomizationPartialResetReducer,
  ResetShoppingStartTime
} from '@store/customization/customization.actions';
import { CustomizationService } from '@store/customization/customization.service';
import { PartialResetReducer as ExhibitionPartialResetReducer } from '@store/exhibition/exhibition.actions';
import { ResetZipCodesCities } from '@store/helpers/helper.actions';
import { ResetReducer as LegitimationResetReducer } from '@store/legitimation/legitimation.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import {
  ResetBookingReducer,
  ResetHolderReducer,
  ResetProductSelectionReducer,
  ResetStatusReducer
} from '@store/products/product.actions';
import {
  PartialResetReducer as StepsFormPartialResetReducer,
  ResetReducer as StepsFormResetReducer
} from '@store/step-forms/steps-forms.actions';
import { ResetReducer as UserResetReducer } from '@store/user/user.actions';
import { fromEvent as observableFromEvent, merge as observableMerge, of as observableOf } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import { first, mapTo, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public online$: Observable<boolean>;

  constructor(
    private store: Store<fromRoot.State>,
    private customizationService: CustomizationService,
    private errorHandlingService: ErrorHandlingService,
    private formsService: FormsService,
    private bookingService: BookingService,
    private productService: ProductService
  ) {
    if (typeof navigator !== 'undefined') {
      this.online$ = observableMerge(
        observableOf(navigator.onLine),
        observableFromEvent(window, 'online').pipe(mapTo(true)),
        observableFromEvent(window, 'offline').pipe(mapTo(false))
      );
    } else {
      this.online$ = observableOf(true);
    }
  }

  resetReducersWithCallback(releaseAllVouchersAndTickets: boolean, callback: Function) {
    if (releaseAllVouchersAndTickets) {
      this.productService.releaseAllVouchersAndProductBookings();
    }

    const sharedObservable$ = this.store.pipe(select(getAllBookedTariffs)).pipe(
      first(),
      share()
    );

    sharedObservable$.subscribe(() => {
      callback();
    });

    return sharedObservable$;
  }

  resetReducers(releaseAllVouchersAndTickets: boolean = true, releaseContingents: boolean = true) {
    consoleLog('Reset reducers');
    //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
    this.errorHandlingService.clearAllErrors();
    this.formsService.resetInputsValidity();

    if (releaseContingents) {
      this.bookingService
        .releaseAllContingentReservations$()
        .subscribe(orderUuid => consoleLog(`Releasing contingent tickets for uuid: ${orderUuid}`));
    }

    //we have to make this operation synchronous as otherwise setStyles method would potentially be called before the store state has been changed
    //(e.g. the customization template wouldn't be changed):
    return this.resetReducersWithCallback(releaseAllVouchersAndTickets, () => {
      new Promise<void>(resolve => {
        this.store.dispatch(new CustomizationPartialResetReducer()); // customization reducer keeps localized images and operator settings
        this.store.dispatch(new ExhibitionPartialResetReducer());
        this.store.dispatch(new StepsFormPartialResetReducer());
        this.store.dispatch(new AdditionalServicesResetReducer());
        this.store.dispatch(new LegitimationResetReducer());
        this.store.dispatch(new ResetZipCodesCities());
        this.store.dispatch(new ResetProductSelectionReducer());
        this.store.dispatch(new ResetBookingReducer());
        this.store.dispatch(new ResetHolderReducer());
        this.store.dispatch(new ResetStatusReducer());

        resolve();
      }).then(() => {
        this.customizationService.setStyles();
      });
    });
  }

  resetReducersWithUser(releaseAllVouchersAndTickets: boolean = true, releaseContingents: boolean = true) {
    consoleLog('Reset event including user');
    //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
    this.errorHandlingService.clearAllErrors();
    this.formsService.resetInputsValidity();

    if (releaseContingents) {
      this.bookingService
        .releaseAllContingentReservations$()
        .subscribe(orderUuid => consoleLog(`Releasing contingent tickets for uuid: ${orderUuid}`));
    }

    // customization reducer is kept on logout
    return this.resetReducersWithCallback(releaseAllVouchersAndTickets, () => {
      new Promise<void>(resolve => {
        this.store.dispatch(new ResetShoppingStartTime());
        this.store.dispatch(new UserResetReducer());
        this.store.dispatch(new ExhibitionPartialResetReducer());
        this.store.dispatch(new StepsFormResetReducer());
        this.store.dispatch(new AdditionalServicesResetReducer());
        this.store.dispatch(new LegitimationResetReducer());
        this.store.dispatch(new ResetZipCodesCities());
        this.store.dispatch(new ResetProductSelectionReducer());
        this.store.dispatch(new ResetBookingReducer());
        this.store.dispatch(new ResetHolderReducer());
        this.store.dispatch(new ResetStatusReducer());

        resolve();
      }).then(() => {
        this.customizationService.setStyles();
      });
    });
  }
}
