import { Action } from '@ngrx/store';
import { Menu } from '@store/additional-services/additional-services.model';

export enum ActionTypes {
  GET_MENU = '[Additional Services] Get menu list from API',
  SET_MENU = '[Additional Services] Set menu list',
  RESET_REDUCER = '[Additional Services] reset reducer'
}

export class SetMenu implements Action {
  readonly type = ActionTypes.SET_MENU;
  constructor(public payload: Menu[]) {}
}

export class GetMenu implements Action {
  readonly type = ActionTypes.GET_MENU;
  constructor(public payload: number) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions = SetMenu | GetMenu | ResetReducer;
