import { Injectable } from '@angular/core';
import { Tariff, TariffValidationStateViewModel, TariffWarningMessage } from '@products/models/tariff.model';
import { WorkshopAvailableSeats } from '@products/models/workshop-status.model';

@Injectable({
  providedIn: 'root'
})
export class TariffCountValidationService {
  constructor() {}

  validateIsAvailableTariffsLimit(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      count,
      previousValidatedCount,
      initialNumberOfAvailableTariffs,
      currentNumberOfAvailableTariffs
    } = tariffValidationState;

    if (this.isAvailableTariffsLimitReached(tariffValidationState)) {
      tariffValidationState.isAvailableTariffsLimitReached = true;

      const maxAvailableTariffCount = previousValidatedCount + currentNumberOfAvailableTariffs;

      const maxLimitCount = Math.min(maxAvailableTariffCount, count);

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        initialNumberOfAvailableTariffs,
        TariffWarningMessage.ERROR_TICKET_WARNING
      );
    }
  }

  validatePromoCodeVoucherTariff(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
      return;
    }

    const {
      count,
      previousValidatedCount,
      numberOfBookedVouchers,
      maxVoucherLimit,
      voucherCountLimit
    } = tariffValidationState;

    if (this.isPromoCodeVoucherTariffLimitReached(tariffValidationState)) {
      const numberOfOtherBookedPromoCodeVouchers = numberOfBookedVouchers - previousValidatedCount;
      const currentPromoCodeVoucherAvailableCount = maxVoucherLimit - numberOfOtherBookedPromoCodeVouchers;
      const maxLimitCount = Math.min(currentPromoCodeVoucherAvailableCount, count);
      const validateVoucherCountLimit = voucherCountLimit < maxLimitCount;

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxVoucherLimit,
        TariffWarningMessage.ERROR_VOUCHER_WARNING,
        true,
        validateVoucherCountLimit
      );
    }
  }

  validateLimitedPromoCodeVoucherTariff(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
      return;
    }

    const {
      previousValidatedCount,
      numberOfBookedLimitedVouchers,
      maxLimitedVoucherLimit,
      voucherCountLimit
    } = tariffValidationState;

    if (this.isLimitedPromoCodeVoucherTariffLimitReached(tariffValidationState)) {
      const numberOfOtherBookedLimitedPromoCodeVouchers = numberOfBookedLimitedVouchers - previousValidatedCount;
      const currentLimitedPromoCodeVoucherAvailableCount =
        maxLimitedVoucherLimit - numberOfOtherBookedLimitedPromoCodeVouchers;
      const maxLimitCount = Math.min(currentLimitedPromoCodeVoucherAvailableCount, maxLimitedVoucherLimit);
      const validateVoucherCountLimit = voucherCountLimit < maxLimitCount;

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxLimitedVoucherLimit,
        TariffWarningMessage.VOUCHER_LIMITED_WARNING,
        true,
        validateVoucherCountLimit
      );
    }
  }

  validateOneTimeVoucherTariff(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
      return;
    }

    if (this.isOneTimeVoucherTariffLimitReached(tariffValidationState)) {
      const { previousValidatedCount, numberOfBookedOneTimeVouchers, voucherCountLimit } = tariffValidationState;

      const maxOneTimeVoucherLimit = 1;
      const currentTariffAvailableCount =
        previousValidatedCount + maxOneTimeVoucherLimit - numberOfBookedOneTimeVouchers;
      const maxLimitCount = Math.min(maxOneTimeVoucherLimit, currentTariffAvailableCount);
      const validateVoucherCountLimit = voucherCountLimit < maxLimitCount;

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxOneTimeVoucherLimit,
        TariffWarningMessage.VOUCHER_ONETIME_WARNING,
        true,
        validateVoucherCountLimit
      );
    }
  }

  validateWorkshopTariff(tariffValidationState: Partial<TariffValidationStateViewModel>): boolean {
    if (tariffValidationState.isCountOverLimit) {
      return;
    }

    const {
      count,
      previousValidatedCount,
      allowedWorkshopsSeatsAvailable,
      areAllAllowedWorkshopsAssigned
    } = tariffValidationState;

    if (this.isWorkshopTariffLimitReached(tariffValidationState)) {
      const maxLimitCount = !areAllAllowedWorkshopsAssigned
        ? Math.min(allowedWorkshopsSeatsAvailable, count)
        : previousValidatedCount;

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxLimitCount,
        TariffWarningMessage.ERROR_TICKET_WARNING
      );
    }
  }

  validateMaxTariffLimit(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (tariffValidationState.isCountOverLimit) {
      return;
    }

    const { maxTariffLimit, numberOfAllBookedTariffs, previousValidatedCount } = tariffValidationState;

    if (this.isMaxTariffLimitReached(tariffValidationState)) {
      const numberOfOtherBookedTariffs = numberOfAllBookedTariffs - previousValidatedCount;
      const currentTariffAvailableCount = maxTariffLimit - numberOfOtherBookedTariffs;
      const maxLimitCount = Math.min(maxTariffLimit, currentTariffAvailableCount);

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxTariffLimit,
        TariffWarningMessage.ERROR_TICKET_WARNING
      );
    }
  }

  validateVoucherCountLimit(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { voucherCountLimit, isVoucherLimitReached, validateVoucherCountLimit } = tariffValidationState;

    if (!validateVoucherCountLimit && isVoucherLimitReached) {
      return;
    }

    if (this.isVoucherCountLimitReached(tariffValidationState)) {
      this.setValidateCounterState(
        tariffValidationState,
        voucherCountLimit,
        voucherCountLimit,
        TariffWarningMessage.ERROR_VOUCHER_WARNING,
        true,
        true
      );
    }
  }

  validateTariffLimitPerUserAccount(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
      return;
    }

    const {
      previousValidatedCount,
      isVoucherIncludedPerUserAccountLimit,
      isVoucher,
      numberOfBookedVouchers,
      numberOfAllBookedTariffs,
      currentNumberOfAvailableTariffs: numberOfAvailableTariffs,
      currentUserAccountTariffLimit
    } = tariffValidationState;

    if (this.isTariffLimitPerUserAccountReached(tariffValidationState)) {
      tariffValidationState.isCurrentUserAccountTariffLimitReached = true;

      const maxLimit = Math.min(currentUserAccountTariffLimit, numberOfAvailableTariffs);
      const notIncludedVoucherTariffsCount =
        !isVoucher && !isVoucherIncludedPerUserAccountLimit ? numberOfBookedVouchers : 0;
      const availableAccountLimitTariffsCount = numberOfAllBookedTariffs - notIncludedVoucherTariffsCount;
      const previousAvailableUserAccountLimitTariffCount = availableAccountLimitTariffsCount - previousValidatedCount;
      const userAccountTariffMaxLimit = currentUserAccountTariffLimit - previousAvailableUserAccountLimitTariffCount;
      const maxLimitCount = Math.min(maxLimit, userAccountTariffMaxLimit);

      this.setValidateCounterState(
        tariffValidationState,
        maxLimitCount,
        maxLimit,
        TariffWarningMessage.TICKET_LIMIT_PER_USER_ACCOUNT_WARNING
      );
    }
  }

  validateInLimitCounter(count: number, tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (!tariffValidationState.isCountOverLimit) {
      tariffValidationState.validatedCount = count > 0 ? count : 0;
      tariffValidationState.tariffLimitWarningMessage = '';
      tariffValidationState.validatedMaxLimit = null;
      tariffValidationState.isVoucherLimitReached = false;
    }
  }

  validatePackageTariffLimit(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { isPackageTypeAndIsPackageNotAdded, packageSettings } = tariffValidationState;
    const isPackageAdded = !isPackageTypeAndIsPackageNotAdded;

    if (isPackageAdded && packageSettings) {
      tariffValidationState.isPackageFixedAmountLimitReached = this.isPackageFixedAmountLimitReached(
        tariffValidationState
      );
      tariffValidationState.isPackageMinAmountLimitReached = this.isPackageMinLimitReached(tariffValidationState);
      tariffValidationState.isPackageMaxAmountLimitReached = this.isPackageMaxLimitReached(tariffValidationState);

      const { amount, minAmount, maxAmount } = packageSettings;

      if (tariffValidationState.isPackageFixedAmountLimitReached) {
        tariffValidationState.validatedCount = amount;
      }

      if (tariffValidationState.isPackageMinAmountLimitReached) {
        tariffValidationState.validatedCount = minAmount;
      }

      if (tariffValidationState.isPackageMaxAmountLimitReached) {
        tariffValidationState.validatedCount = maxAmount;
      }
    }
  }

  isAvailableTariffsLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      count,
      countDifference,
      bookedCount,
      isTariffSoldOut,
      initialNumberOfAvailableTariffs,
      currentNumberOfAvailableTariffs
    } = tariffValidationState;

    if (isTariffSoldOut) {
      return true;
    }

    const isCurrentTariffCountDecreased = countDifference < 0;
    const isOnDecreaseTariffCountStateRevalidation = count < bookedCount;

    if (isCurrentTariffCountDecreased || isOnDecreaseTariffCountStateRevalidation) {
      tariffValidationState.isAvailableTariffsLimitReached = false;
      return false;
    }

    const alreadyBookedTariffsCount = initialNumberOfAvailableTariffs - currentNumberOfAvailableTariffs;
    const isInitialTariffLimitReached = countDifference + alreadyBookedTariffsCount >= initialNumberOfAvailableTariffs;
    const isCurrentAvailableTariffsLimitReached =
      currentNumberOfAvailableTariffs !== null && currentNumberOfAvailableTariffs === 0;
    const isAvailableTariffsLimitReached = isInitialTariffLimitReached || isCurrentAvailableTariffsLimitReached;

    tariffValidationState.isAvailableTariffsLimitReached = isAvailableTariffsLimitReached;

    return isAvailableTariffsLimitReached;
  }

  isPromoCodeVoucherTariffLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>): boolean {
    const { isVoucherTypePromoCode, countDifference, numberOfBookedVouchers, maxVoucherLimit } = tariffValidationState;
    const promoCodeVoucherTotalCount = countDifference + numberOfBookedVouchers;

    return isVoucherTypePromoCode && promoCodeVoucherTotalCount > maxVoucherLimit;
  }

  isLimitedPromoCodeVoucherTariffLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>): boolean {
    const {
      isVoucherTypeLimitedPromoCode,
      countDifference,
      numberOfBookedLimitedVouchers,
      maxLimitedVoucherLimit
    } = tariffValidationState;

    const limitedPromoCodeVoucherTotalCount = numberOfBookedLimitedVouchers + countDifference;

    return isVoucherTypeLimitedPromoCode && limitedPromoCodeVoucherTotalCount > maxLimitedVoucherLimit;
  }

  isOneTimeVoucherTariffLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { countDifference, numberOfBookedOneTimeVouchers, isVoucherTypeOneTimeVoucher } = tariffValidationState;

    const oneTimeVoucherTotalCount = countDifference + numberOfBookedOneTimeVouchers;

    return isVoucherTypeOneTimeVoucher && oneTimeVoucherTotalCount > 1;
  }

  isWorkshopTariffLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      count,
      previousValidatedCount,
      isWorkshopsSelectionMandatoryAndHasWorkshops,
      allowedWorkshopsSeatsAvailable,
      areAllAllowedWorkshopsAssigned
    } = tariffValidationState;

    const isCountDecreased = previousValidatedCount > count;

    if (isCountDecreased) {
      return false;
    }

    const isInvalidWorkshopCount = count > allowedWorkshopsSeatsAvailable;

    return isWorkshopsSelectionMandatoryAndHasWorkshops && (isInvalidWorkshopCount || areAllAllowedWorkshopsAssigned);
  }

  isMaxTariffLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>): boolean {
    const { countDifference, numberOfAllBookedTariffs, maxTariffLimit } = tariffValidationState;

    return countDifference + numberOfAllBookedTariffs > maxTariffLimit;
  }

  isVoucherCountLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { count, voucherCountLimit } = tariffValidationState;

    return count >= voucherCountLimit && voucherCountLimit !== 0;
  }

  isTariffLimitPerUserAccountReached(tariffValidationState: Partial<TariffValidationStateViewModel>): boolean {
    const {
      countDifference,
      tariffLimitPerUserAccount,
      isVoucherIncludedPerUserAccountLimit,
      isVoucher,
      numberOfBookedVouchers,
      numberOfAllBookedTariffs,
      currentUserAccountTariffLimit
    } = tariffValidationState;

    const isUserAccountLimitActive = !!tariffLimitPerUserAccount;
    const checkUserAccountLimit = !isVoucher || isVoucherIncludedPerUserAccountLimit;

    if (!isUserAccountLimitActive || !checkUserAccountLimit) {
      return false;
    }

    const notIncludedVoucherTariffsCount =
      !isVoucher && !isVoucherIncludedPerUserAccountLimit ? numberOfBookedVouchers : 0;
    const availableAccountLimitTariffsCount = numberOfAllBookedTariffs - notIncludedVoucherTariffsCount;

    return availableAccountLimitTariffsCount + countDifference > currentUserAccountTariffLimit;
  }

  isPackageFixedAmountLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      validatedCount,
      packageSettings: { amount, fixedAmount }
    } = tariffValidationState;

    return fixedAmount && (amount >= validatedCount || amount <= validatedCount);
  }

  isPackageMaxLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      validatedCount,
      packageSettings: { maxAmount }
    } = tariffValidationState;

    return maxAmount != null && maxAmount <= validatedCount;
  }

  isPackageMinLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const {
      validatedCount,
      packageSettings: { minAmount }
    } = tariffValidationState;

    return minAmount != null && minAmount >= validatedCount;
  }

  tariffAllowedWorkshopsStatus(
    count: number,
    tariff: Tariff,
    availableSeats: WorkshopAvailableSeats
  ): {
    allowedWorkshopsSeatsAvailable: number;
    hideWorkshopTariffCounter: boolean;
    areAllAllowedWorkshopsAssigned: boolean;
  } {
    const { allowedWorkshops, allowedWorkshopsFull } = tariff;
    const tariffHasNoWorkshops = !allowedWorkshops.length;

    if (tariffHasNoWorkshops || allowedWorkshopsFull) {
      return {
        allowedWorkshopsSeatsAvailable: 0,
        hideWorkshopTariffCounter: true,
        areAllAllowedWorkshopsAssigned: true
      };
    }

    const allowedWorkshopsSeatsAvailable = allowedWorkshops
      .map(allowedWorkshopId => availableSeats[allowedWorkshopId])
      .reduce(
        (allowedWorkshopsTotalAvailableSeats, availableSeats) => allowedWorkshopsTotalAvailableSeats + availableSeats
      );
    const hideWorkshopTariffCounter = allowedWorkshopsSeatsAvailable <= 0 && count === 0;
    const areAllAllowedWorkshopsAssigned = hideWorkshopTariffCounter || allowedWorkshopsSeatsAvailable === 0;

    return {
      allowedWorkshopsSeatsAvailable,
      hideWorkshopTariffCounter,
      areAllAllowedWorkshopsAssigned
    };
  }

  calculatePercentageOfAvailableTariffs(count: number, tariffLimit: number, numberOfAvailableTariffs: number): number {
    if (!tariffLimit || (!numberOfAvailableTariffs && !count)) {
      return 1;
    }

    return 1 - (tariffLimit - numberOfAvailableTariffs) / tariffLimit;
  }

  isTariffSoldOut(count: number, availableTariffs: number) {
    return availableTariffs !== null && availableTariffs === 0 && count === 0;
  }

  isCurrentUserAccountTariffLimitReached(ticketLimitPerUserAccount: number, currentUserAccountTicketLimit: number) {
    return !!ticketLimitPerUserAccount && currentUserAccountTicketLimit === 0;
  }

  private setValidateCounterState(
    tariffValidationState: Partial<TariffValidationStateViewModel>,
    maxLimitCount: number,
    maxLimit: number,
    tariffLimitWarningMessage: string,
    isVoucherLimitReached: boolean = false,
    validateVoucherCountLimit: boolean = false
  ) {
    tariffValidationState.isCountOverLimit = true;
    tariffValidationState.validatedCount = maxLimitCount > 0 ? maxLimitCount : 0;
    tariffValidationState.tariffLimitWarningMessage = tariffLimitWarningMessage;
    tariffValidationState.validatedMaxLimit = maxLimit;
    tariffValidationState.countDifference =
      tariffValidationState.validatedCount - tariffValidationState.previousValidatedCount;
    tariffValidationState.isVoucherLimitReached = isVoucherLimitReached;
    tariffValidationState.validateVoucherCountLimit = validateVoucherCountLimit;
  }
}
