<div class="page-padding-wrapper">
  <div *ngIf="isConfirmedEmailRequiredOnPersonalization">
    <app-web-shop-account-verification></app-web-shop-account-verification>
  </div>

  <div class="ticket-login" *ngIf="(showLoginOnPersonalPage$ | async) || (showLoginOnTicketAndPersonalPage$ | async)">
    <app-login class="login-body" [validationStepName]="PersonaliseFormsKeys.registration"></app-login>
  </div>

  <ng-container
    *ngIf="
      !isConfirmedEmailRequiredOnPersonalization &&
      !(showLoginOnPersonalPage$ | async) &&
      !(showLoginOnTicketAndPersonalPage$ | async)
    "
  >
    <div
      *ngIf="!continueAsGuest || ((showLoginOnTicketPage$ | async) && continueAsGuest) || continueAsGuest"
      [ngStyle]="buyerStyle"
      appAdjustSideHeadline
    >
      <div class="section-headline">
        <h2
          [appRichText]="'personalize.buyer-info.section-title' | translate | hideTranslation"
          data-translation="personalize.buyer-info.section-title"
        ></h2>
        <div class="section-headline-side" data-translation="personalize.buyer-info.headline">
          {{ 'personalize.buyer-info.headline' | translate | hideTranslation }}
        </div>
      </div>
      <div class="section-body">
        <app-buyer-info
          [areTicketHoldersVisible]="
            !isSelfRegistrationEnabled && (isTicketHolderVisible$ | async) && !ticketHolderInputsNotVisible
          "
          (isInputChanged)="inputChanged($event)"
        ></app-buyer-info>
      </div>
    </div>

    <ng-container
      *ngIf="(!(showLoginOnPersonalPage$ | async) && !(showLoginOnTicketAndPersonalPage$ | async)) || continueAsGuest"
    >
      <!-- BUYER QUESTIONNAIRE -->
      <app-extra-data
        *ngIf="questionnaireLoaded && !isSelfRegistrationEnabled"
        [stepsFormsActionName]="PersonaliseFormsKeys.buyerQuestionnaire"
      >
      </app-extra-data>
      <app-order-tickets
        *ngIf="!isSelfRegistrationEnabled && (isTicketHolderVisible$ | async) && !ticketHolderInputsNotVisible"
        [isInputChanged$]="isInputChanged$"
        [ticketSelectedSendingOption]="selectedSendingOption"
        (isVisitorQuestionnaireShown)="allowAdditionalDataToShow($event)"
      >
      </app-order-tickets>

      <!-- VISITOR QUESTIONNAIRE -->
      <app-ticket-holder-questionnaire
        *ngIf="showVisitorQuestionnaire && selectedSendingOption === TicketSendingOptions.TicketRetrievalLink"
        (isValid)="setVisitorQuestionnaireValidity($event)"
      ></app-ticket-holder-questionnaire>
    </ng-container>

    <div class="privacy-policy" *ngIf="checkboxesInputs && checkboxesInputs.length">
      <form class="row no-group-margin hide-input-validation" [formGroup]="checkboxesForm">
        <app-df-input
          *ngFor="let input of checkboxesInputs"
          [input]="input"
          [class]="input.cssClass"
          [inputSet]="checkboxesInputs"
          [action]="PersonaliseFormsKeys.privacy"
          [form]="checkboxesForm"
        >
        </app-df-input>
      </form>
    </div>

    <div class="external-links" *ngIf="exhibitionSettings$ | async as exhibitionSettings">
      <div class="privacy-link" *ngIf="exhibitionSettings.isPrivacyLinkVisible && isSelfRegistrationEnabled">
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <span class="clickable" (click)="openPrivacyModalWindow(exhibitionSettings.privacyUrl)">
          {{ 'personalize.privacy-link' | translate | filterPlaceholder | hideTranslation }}
        </span>
      </div>

      <div class="disclaimer-link" *ngIf="exhibitionSettings.isDisclaimerVisible">
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <a
          target="_blank"
          href="{{ exhibitionSettings.disclaimerUrl }}"
          *ngIf="!isSelfRegistrationEnabled"
          data-translation="personalize.disclaimer-link"
          (click)="openIframe($event, exhibitionSettings.disclaimerUrl)"
        >
          {{ 'personalize.disclaimer-link' | translate | hideTranslation }}
        </a>
        <span
          class="clickable"
          *ngIf="isSelfRegistrationEnabled"
          (click)="openModalWindow(exhibitionSettings.disclaimerUrl, 'personalize.disclaimer-link')"
        >
          {{ 'personalize.disclaimer-link' | translate | hideTranslation }}
        </span>
      </div>
      <div class="imprint-link" *ngIf="exhibitionSettings.isImprintVisible">
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <a
          href="{{ exhibitionSettings.imprintUrl }}"
          target="_blank"
          *ngIf="!isSelfRegistrationEnabled"
          data-translation="personalize.imprint-link"
          (click)="openIframe($event, exhibitionSettings.imprintUrl)"
        >
          {{ 'personalize.imprint-link' | translate | hideTranslation }}
        </a>

        <span
          class="clickable"
          *ngIf="isSelfRegistrationEnabled"
          (click)="openModalWindow(exhibitionSettings.imprintUrl, 'personalize.imprint-link')"
        >
          {{ 'personalize.imprint-link' | translate | hideTranslation }}
        </span>
      </div>
    </div>
  </ng-container>

  <app-step-navigation-buttons></app-step-navigation-buttons>

  <app-modal-window
    [modalWindowActive]="modalWindowOpen"
    [modalWindowTitle]="modalTitle"
    [translateTitle]="modalTranslateTitle"
    [fullWidth]="true"
    (contextmenu)="closeModalWindowOnRightClick($event)"
  >
    <div modalWindow-body>
      <iframe [src]="modalUrl | safeUrl" class="iframe"></iframe>
    </div>

    <button class="button button-option" (click)="closeModalWindow($event)" modalWindow-close>
      <span data-translation="modalWindow.close">
        {{ 'modalWindow.close' | translate | hideTranslation }}
      </span>
    </button>
  </app-modal-window>
</div>
