import * as bowser from 'bowser';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as helperActions from './helper.actions';

import { ElementRef, Injectable, Renderer } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscriber,
  combineLatest,
  of
} from 'rxjs';
import { first } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingParkingReservation, BookingTariff } from '@app/_pages/products/models/booking.model';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getAllBookedParkingReservations, getAllBookedTariffs, getClaimedTicketHash } from '@store/products/booking/booking.selectors';
import { saveAs } from 'file-saver/FileSaver';
import { environment } from '../../../../environments/environment';
import { InputBase } from '../../forms/inputs/input-base.class';
import { getTicketHolderQuestionnaireInputs } from '../products/holder/holder.selectors';
import { InputsListModel } from '../step-forms/step.interface';
import {
  EventSeriesModel,
  QuestionnaireInputResponseModel,
} from './helper.interface';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private isReloadSections: boolean = false;
  private emailRequired: boolean = false;
  private verifyEmailRequired: boolean = false;
  private showModalWindowBH$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private voteYesNoBH$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  listOfAllCountries: Array<string>;
  appEl: any;
  appRenderer: any;
  buyerQuestionnaire: InputBase<any>[] = [];
  visitorQuestionnaire: InputBase<any>[] = [];
  showModalWindow$: Observable<boolean> = this.showModalWindowBH$.asObservable();
  voteYesNo$: Observable<boolean> = this.voteYesNoBH$.asObservable();
  isStepNavigationRendered: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.store.pipe(select(fromRoot.getAllCountriesList)).subscribe(list => this.listOfAllCountries = list);
    this.isMobile();
  }

  setRefsToAppComponent(renderer: Renderer, el: ElementRef) {
    this.appRenderer = renderer;
    this.appEl = el;
  }

  setLanguage(lang: string) {
    this.store.dispatch(new helperActions.SetActiveLanguage(lang));
  }

  setSupportedLanguages(languages: string[]) {
    this.translateService.addLangs(languages);
    this.store.dispatch(new helperActions.setSupportedLanguages(languages));
  }

  redirectAfterLogin() {
    //get selected event ID and its step, if both present go to last selected step after login, otherwise go to homepage
    combineLatest([
      this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this.store.pipe(select(fromRoot.getSelectedStep)),
      this.store.pipe(select(fromRoot.isEventSeriesPage)),
      this.store.pipe(select(fromRoot.getBuyerInfo)),
      this.store.pipe(select(fromRoot.getStepsValidity)),
      this.store.pipe(select(getClaimedTicketHash))
    ])
    .pipe(first())
    .subscribe(([selectedExhibitionId, selectedStep, eventSeriesPage, buyerInfo, validations, ticketClaimedHash]) => {
      if (!!buyerInfo) {
        const isInputFilledValue: boolean = buyerInfo.list.some(buyerInfoItem => buyerInfoItem.value);

        if (isInputFilledValue) {
          this.showModalWindow(isInputFilledValue);
        };
      }

      const visibleSteps = Object.keys(validations)
        // make sure steps are ordered correctly
        .sort((a, b) => validations[a].order - validations[b].order)
        // only navigate to visible routes
        .filter(stepKey => validations[stepKey].visible);

      if (selectedExhibitionId !== null && selectedStep !== null) {
        const queryString = this.router.url.includes('?');
        const urlBase = this.isSelfregistration() ? 'self-registration' : 'webshop';

        if (selectedStep === 'payment') {
          console.log('%c currentStep ', 'background: #D46A6A; color: #fff', `/${urlBase}/${selectedExhibitionId}/${selectedStep}`);
        }

        if (!validations[selectedStep].showInStepNavigation) {
          const ticketStep = visibleSteps.find(step => step === 'tickets');
          selectedStep = validations[ticketStep].showInStepNavigation
            ? ticketStep
            : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');

          console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', `/${urlBase}/${selectedExhibitionId}/${selectedStep}`);
        }

        if (queryString) {
          // Bug 3830 - if CONTINUE is pressed for the first time on personalization step, user is redirected to tickets step instead of confirmation step
          this.route.queryParams.first().subscribe(params => {
            this.router.navigate([`${urlBase}/${selectedExhibitionId}/${selectedStep}`], {
              queryParams: params
            });
          });
        } else {
          this.router.navigate([`${urlBase}/${selectedExhibitionId}/${selectedStep}`]);
        }
      } else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
        this.router.navigate([`/series/${eventSeriesPage.eventSeriesId}`]);
      } else if (ticketClaimedHash) {
        this.router.navigate([`webshop-download/${selectedExhibitionId}`], {queryParams: {t: ticketClaimedHash}});
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  voteYesOrNo(vote: boolean) {
    this.voteYesNoBH$.next(vote);
  }

  showModalWindow(show: boolean) {
    this.showModalWindowBH$.next(show);
  }

  getListOfCountries() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/country/preferred`);
  }

  loadCountries() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/country/list`);
  }

  checkZipcodeValidity(countryCode, zipCode): Observable<any> {
    if (!zipCode) {
      return new Observable<any>((subscriber: Subscriber<any>) =>
        subscriber.next({ isValidZipCode: true })
      );
    }

    return this.http.get(`${environment.protocol}${environment.webApiUrl}/country/${countryCode}/check-zip-code/${zipCode}`);
  }

  checkHashValidity(hash): Observable<any> {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/hash/status/${hash}`);
  }

  convertDate(strDate) {
    // strDate = '03.09.1979';
    const dateParts = strDate.split('.');
    const newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    return newDate;
  }

  toStringWithoutOffset(date: Date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  }

  processFormValuesBeforeSave(formValues: any) {
    let normalizedData = {};

    Object.keys(formValues).forEach(groupKey => {
      normalizedData[groupKey] = formValues[groupKey];
      const typeOf = typeof formValues[groupKey];

      // if it is an object we are dealing with checkbox set
      if (typeOf === 'object') {
        const groupRegex = new RegExp(`^${groupKey}_`);
        const isDateOfBirth = groupKey === 'dateOfBirth';

        if (isDateOfBirth) {
          const dateOfBirth = formValues[groupKey];

          if (!!dateOfBirth) {
            formValues[groupKey] = this.getUTCdate(dateOfBirth);
            normalizedData[groupKey] = formValues[groupKey];
          }
        } else {
          Object.keys(formValues[groupKey]).forEach(checkboxId => {
            const clearedId = checkboxId.replace(groupRegex, '');
            normalizedData[clearedId] = formValues[groupKey][checkboxId];
          });
        }
      } else if (typeOf === 'string') {
        //sanitize the input value (it should already come here sanitized anyway):
        const value = this.sanitizeString(formValues[groupKey]);

        if (value !== formValues[groupKey]) {
          formValues[groupKey] = value;
          normalizedData[groupKey] = value;
        }
      }
    });

    return normalizedData;
  }

  processQuestionnaireValuesBeforeSave(
    formControls: InputBase<any>[]
  ): Array<QuestionnaireInputResponseModel> {
    let normalizedQuestionnaire = [];

    formControls.forEach(group => {
      if (
        (group.controlType === 'dropdown' || group.controlType === 'radio') &&
        group.hasOwnProperty('value') &&
        !group.hidden &&
        (group.value || group.value === 0)
      ) {
        normalizedQuestionnaire.push({
          fieldId: Number(group.key),
          valueId: Number(group.value),
          text: null
        });
      } else if (
        group.controlType === 'textbox' &&
        group.hasOwnProperty('value') &&
        !group.hidden &&
        group.value
      ) {
        const keySplited = group.key.split('_');
        normalizedQuestionnaire.push({
          fieldId: Number(keySplited[0]),
          valueId: Number(keySplited[1]),
          text: group.value
        });
      } else if (group.controlType === 'checkbox' && !group.hidden) {
        group.options.forEach(option => {
          if (option.value) {
            normalizedQuestionnaire.push({
              fieldId: Number(group.key),
              valueId: Number(option.key),
              text: null
            });
          }
        });
      }
    });

    return normalizedQuestionnaire;
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  getVoucherUrl(): string {
    let voucherURL: string;

    this.route.queryParams.pipe(first()).subscribe(params => {
      Object.keys(params).find(key => {
        if (key === "voucher") {
          return voucherURL = params.voucher;
        }
      });
    });

    return !!voucherURL ? voucherURL : '';
  }

  getTicketParams(): string {
    let ticketParams: string[] = [];

    this.route.queryParams.pipe(first()).subscribe(params => {
      let counter: number = 0;

      ticketParams = Object.keys(params).map(key => {
        if (key === 'tt' || key === 'pt' || key === 'amt') {
          return (counter++ === 0 ? '?' : '&') + `${key}=${params[key]}`;
        }
      });
    });

    return ticketParams.length > 1 ? ticketParams.join('') : null;
  }

  isMobile() {
    if (typeof navigator !== 'undefined') {
      return (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i) && true);
    }

    return false;
  }

  isMobile$(): Observable<boolean> {
    return of(this.isMobile());
  }


  stepNavigationRendered(): Subject<boolean> {
    this.isStepNavigationRendered.next(true);
    return this.isStepNavigationRendered;
  }

  browserInfo() {
    const browserInfo = bowser.getParser(window.navigator.userAgent)['parsedResult'];
    const browserName = browserInfo.browser.name + ' version: ' + browserInfo.browser.version;
    const systemInfo = browserInfo.os.name + ' version: ' + browserInfo.os.versionName;
    const platformInfo = browserInfo.platform.type;
    const completeInfo = 'Browser: ' + browserName + ', SystemInfo: ' + systemInfo + ', Platform: ' + platformInfo;

    return completeInfo;
  }

  triggerCallbackOnceFormValidationIsDone(form: FormGroup, callback: Function, isUsedWithoutTimeout?: boolean) {
    if (form.pending) {
      const interval = setInterval(() => {
        if (!form.pending) {
          callback();
          clearInterval(interval);
        }
      }, 200);
    } else {
      if (isUsedWithoutTimeout){
        callback();
      } else {
        setTimeout(() => {
          callback();
        }, 200);
      }
    }
  }

  isSelfregistration() {
    let isSelfRegistration;

    this.store.pipe(select(fromRoot.getSelfRegistration), first()).subscribe((selfRegistration: boolean) =>
     isSelfRegistration = selfRegistration);

    return isSelfRegistration;
  }

  isEventSeriesPage() {
    let isEventSeriesPage;

    this.store.pipe(
      select(fromRoot.isEventSeriesPage),
      first()
    )
    .subscribe((isEventSeriesPage: EventSeriesModel) => isEventSeriesPage = isEventSeriesPage);

    return isEventSeriesPage;
  }

  saveToFileSystem(blob, name) {
    saveAs(blob, name);
    this.store.dispatch(new helperActions.SetSpinnerValue(false));
  }

  openIframe(url) {
    let win = window.open(url, '_blank');
    win.focus();
  }

  removeQueryParam(key: string, sourceURL: string) {
    const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    let rtn = sourceURL.split('?')[0];
    let param;
    let params_arr = [];

    if (queryString !== '') {
      params_arr = queryString.split('&');

      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split('=')[0];

        if (param === key) {
          params_arr.splice(i, 1);
        }
      }

      if (params_arr.length > 0) {
        rtn = rtn + '?';
      }

      rtn = rtn + params_arr.join('&');
    }

    return rtn;
  }

  loadBuyerQuestionnaireViaApi(stepName) {
    combineLatest([
      this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this.store.pipe(select(fromRoot.getLanguage)),
      this.store.pipe(select(getAllBookedTariffs)),
      this.store.pipe(select(fromRoot.getQuestionnaire)),
      this.store.pipe(select(fromRoot.getSelfRegQuestionnaire)),
      this.store.pipe(select(fromRoot.getQuestionnaireTicketPersonIds))
    ])
    .pipe(first())
    .subscribe(([id, lang, tariffs, questionnaire, selfRegQuestionnaire, questionnaireTicketPersonIds]:
       [number, string, BookingTariff[], InputsListModel, InputsListModel, number[]]) => {
        const isSelfRegistration: boolean = this.isSelfregistration();
        const ticketPersonIds: number[] = [];

        tariffs.forEach(tariff => {
          const ticketPersonId = tariff.ticketPersonId;

          if (tariff.count > 0 && !ticketPersonIds.includes(ticketPersonId)) {
            ticketPersonIds.push(ticketPersonId);
          }
        });

        if (!isSelfRegistration) {
          if (questionnaireTicketPersonIds.length === ticketPersonIds.length) {
            return;
          }

          this.store.dispatch(new stepsActions.SetQuestionnaireTicketPersonIds(ticketPersonIds));
        }

        this.store.dispatch(
          new stepsActions.GetBuyerQuestionnaire({
            eventId: id,
            stepName,
            lang,
            ticketPersonIds,
            previousQuestionnare: isSelfRegistration ? selfRegQuestionnaire : questionnaire
          })
        );
      }
    );
  }

  applyFullHeightToAllParentElements(
    startingElement: HTMLElement,
    displayBlock?: boolean
  ) {
    let parentElement = startingElement.parentElement;

    while (parentElement) {
      parentElement.style.height = '100%';

      if (displayBlock) {
        parentElement.style.display = 'block';
      }

      parentElement = parentElement.parentElement;
    }
  }

  getParkingReservations(): BookingParkingReservation[] {
    let storedParkingReservations: BookingParkingReservation[] = [];

    this.store.pipe(
      select(getAllBookedParkingReservations),
      first(parkingReservations => !!parkingReservations)
    )
    .subscribe(parkingReservations => storedParkingReservations = parkingReservations);

    return storedParkingReservations;
  }

  checkQuestionnairesForDuplicates() {
    combineLatest([
      this.store.pipe(select(fromRoot.getStepsForms)),
      this.store.pipe(select(getTicketHolderQuestionnaireInputs))
    ])
    .pipe(first())
    .subscribe(([stepsForms, visitorQuestions]) => {
      const stepFormList = stepsForms['personal']['forms']['questionnaire'];

      if (!!stepFormList) {
        this.buyerQuestionnaire = [...stepFormList['list']];
        this.visitorQuestionnaire = visitorQuestions;
      }

      if (this.buyerQuestionnaire && this.visitorQuestionnaire) {
        Object.keys(this.buyerQuestionnaire).forEach(buyerKey => {
          const buyerQuestion = this.buyerQuestionnaire[buyerKey];

          Object.keys(this.visitorQuestionnaire).forEach((visitorKey) => {
            const visitorQuestion = this.visitorQuestionnaire[visitorKey];

            if (buyerQuestion.key === visitorQuestion.key) {
              if (visitorQuestion.value === '' || visitorQuestion.value === undefined) {
                visitorQuestion.value = buyerQuestion.value;

                visitorQuestion.options.forEach(visitorOption => {
                  const buyerOption = buyerQuestion.options.find(buyerOption => buyerOption.key === visitorOption.key);

                  if (!!buyerOption) {
                    visitorOption.value = buyerOption.value;
                  }
                });
              }
            }
          });
        });
      }
    });
  }

  //date time functions:
  //TODO: consider using moment.js
  getUTCdate(date: Date): Date {
    if (!!date) {
      if (typeof date === 'string' || date instanceof String) {
        date = new Date(date);
      }

      if (date instanceof Date) {
        return new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0
          )
        );
      }
    }
    return null;
  }

  /**
   * Removes the time part of a date object.
   * @param date
   * @returns Date object without time part.
   */
  truncateTime(date: Date): Date {
    if (!!date && date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    return date;
  }

  /**
   * Checks if both dates without their time part are the same.
   * @param date1
   * @param date2
   * @returns True if both dates are the same.
   */
  areDatesSameWOTime(date1: Date, date2: Date) : boolean {
    if (!date1 && !date2) {
      return true;
    }

    if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() === this.truncateTime(date2).valueOf()) {
      return true;
    }

    return false;
  }

  /**
   * Checks if the first date is greater than the second date.
   * @param date1
   * @param date2
   * @returns True if the first date is greater than the second one.
   */
  isFirstDateGreaterWOTime(date1: Date, date2: Date) : boolean {
    if (!!date1 && !date2) {
      return true;
    }

    if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() > this.truncateTime(date2).valueOf()) {
      return true;
    }

    return false;
  }

  setIsReloadSections(isReloadSections: boolean) {
    this.isReloadSections = isReloadSections;
  }

  getReloadSections(): boolean {
    return this.isReloadSections;
  }


  /**
   *
   * @param values array to sort
   * @returns array sorted by values ascending
   */
  sortValuesAsc(values) {
    values.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    });
    return values;
  }

  setOriginalEmailValues(emailRequired: boolean, verifyEmailRequired: boolean) {
    this.emailRequired = emailRequired;
    this.verifyEmailRequired = verifyEmailRequired;
  }

  getOriginalEmailValues(value: string): boolean {
    if (value === 'email') {
      return this.emailRequired;
    } else if (value === 'verifyEmail') {
      return this.verifyEmailRequired;
    }

    return false;
  }

  setReloadRequired(reloadRequired: boolean) {
    this.store.dispatch(new helperActions.SetReloadRequired(reloadRequired));
  }

  sanitizeInput(control: AbstractControl, event) {
    if (event && typeof event === 'object' && event.target && event.target.value && event.target.value.includes(">")) {
      const value = this.sanitizeString(event.target.value);
  
      if (event.target.value !== value) {
        event.target.value = value;

        if (control) {
          control.setValue(value);
        }
      }
    }
  }

  sanitizeString(value) {
    //DomSanitizer.sanitize also escapes characters which isn't necessary here so I won't use it:
    //return this.sanitizer.sanitize(SecurityContext.HTML, value);

    return value ? value.replace(/<[^>]+>/g, '') : value;
  }
}
