var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { ActionTypes as PackageActionTypes } from '@store/products/status/package/package.actions';
export var initialState = {
    lastPackageIndex: 0,
    isPackageLoading: false
};
export var packageStatusReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PackageActionTypes.SET_INITIAL_PACKAGE_INDEX_STATUS: {
            var initialPackageIndex = action.payload;
            return __assign({}, state, { lastPackageIndex: initialPackageIndex });
        }
        case PackageActionTypes.SET_PACKAGE_INDEX_STATUS: {
            var bookingPackageTypes = action.payload;
            if (bookingPackageTypes.length) {
                var lastPackageIndex = Math.max.apply(Math, bookingPackageTypes.map(function (bookingPackageType) { return bookingPackageType.packageIndex; }).concat([state.lastPackageIndex]));
                return __assign({}, state, { lastPackageIndex: lastPackageIndex });
            }
            return state;
        }
        case PackageActionTypes.SET_IS_PACKAGE_LOADING_STATUS: {
            var isPackageLoading = action.payload;
            return __assign({}, state, { isPackageLoading: isPackageLoading });
        }
        case PackageActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
