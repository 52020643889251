import {
  ApiCallResultModel,
  EventSeriesModel,
  ZipCodeCities
} from './helper.interface';

import { Action } from '@ngrx/store';

export enum ActionTypes {
  RESET_REDUCER = '[Helpers] reset reducer',
  GET_ALL_COUNTRIES = '[Helpers] get all countries from API',
  SET_ACTIVE_LANGUAGE = '[Helpers] set active language',
  SET_SUPPORTED_LANGUAGES = '[Helpers] set supported languages',
  ADD_ALL_COUNTRIES_TO_LIST = '[Helpers] Set all countries to select dropdown',
  SET_API_CALL_RESULT = '[Helpers] Set API call result',
  SET_SELFREGISTRATION = '[Helpers] self registration url parameter',
  SET_EVENTSERIESPAGE = '[Helpers] Event series page url parameter',
  SET_IS_WIDGET = '[Helpers] Set a flag indicating widget',
  SET_IFRAME_URL = '[Helpers] set ifrmae url',
  SET_SPINNER_VALUE = '[Helpers] set spinner boolean value',
  SET_CITIES_WITH_SAME_ZIPCODE = '[Helpers] Set all cities with same zipcode',
  RESET_ZIPCODES_CITIES = '[Helpers] Reset all cities with same zipcodes',
  SET_RELOAD_REQUIRED = '[Helpers] Set a flag indicating page reload is required'
}
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class GetAllCountriesAction implements Action {
  readonly type = ActionTypes.GET_ALL_COUNTRIES;
}

export class AddAllCountriesToListAction implements Action {
  readonly type = ActionTypes.ADD_ALL_COUNTRIES_TO_LIST;
  constructor(public payload: Array<string>) {}
}

export class SetActiveLanguage implements Action {
  readonly type = ActionTypes.SET_ACTIVE_LANGUAGE;
  constructor(public payload: string) {}
}

export class setSupportedLanguages implements Action {
  readonly type = ActionTypes.SET_SUPPORTED_LANGUAGES;
  constructor(public payload: string[]) {}
}

export class SetApiCallResult implements Action {
  readonly type = ActionTypes.SET_API_CALL_RESULT;
  constructor(public payload: ApiCallResultModel) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export class SetSelfRegistration implements Action {
  readonly type = ActionTypes.SET_SELFREGISTRATION;
  constructor(public payload: boolean) {}
}

export class SetEventSeriesPage implements Action {
  readonly type = ActionTypes.SET_EVENTSERIESPAGE;
  constructor(public payload: EventSeriesModel) {}
}

export class SetIsWidget implements Action {
  readonly type = ActionTypes.SET_IS_WIDGET;
  constructor(public payload: boolean) {}
}

export class SetIframeUrl implements Action {
  readonly type = ActionTypes.SET_IFRAME_URL;
  constructor(public payload: string) {}
}

export class SetSpinnerValue implements Action {
  readonly type = ActionTypes.SET_SPINNER_VALUE;
  constructor(public payload: boolean) {}
}

export class SetCitiesWithSameZipcode implements Action {
  readonly type = ActionTypes.SET_CITIES_WITH_SAME_ZIPCODE;
  constructor(public payload: ZipCodeCities) {}
}

export class ResetZipCodesCities implements Action {
  readonly type = ActionTypes.RESET_ZIPCODES_CITIES;
}

export class SetReloadRequired implements Action {
  readonly type = ActionTypes.SET_RELOAD_REQUIRED;
  constructor(public payload: boolean) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | ResetReducer
  | GetAllCountriesAction
  | SetActiveLanguage
  | setSupportedLanguages
  | AddAllCountriesToListAction
  | SetApiCallResult
  | SetSelfRegistration
  | SetEventSeriesPage
  | SetIsWidget
  | SetIframeUrl
  | SetSpinnerValue
  | SetCitiesWithSameZipcode
  | ResetZipCodesCities
  | SetReloadRequired;
