var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './helper.actions';
import cloneDeep from 'lodash.clonedeep';
export var initialState = {
    allCountries: null,
    supportedLanguages: [],
    activeLanguage: 'de',
    apiCallResult: null,
    selfRegistration: false,
    isEventSeriesPage: null,
    isWidget: false,
    modalIframeUrl: null,
    spinnerValue: false,
    citiesByZipcode: [],
    reloadRequired: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.ADD_ALL_COUNTRIES_TO_LIST:
            var allCountries = action.payload;
            return __assign({}, state, { allCountries: allCountries });
        case ActionTypes.SET_SELFREGISTRATION: {
            var registration = action.payload;
            return __assign({}, state, { selfRegistration: registration });
        }
        case ActionTypes.SET_EVENTSERIESPAGE: {
            var eventSeriesPage = action.payload;
            return __assign({}, state, { isEventSeriesPage: eventSeriesPage });
        }
        case ActionTypes.SET_IS_WIDGET:
            var isWidget = action.payload;
            return __assign({}, state, { isWidget: isWidget });
        case ActionTypes.SET_API_CALL_RESULT:
            var apiCallResult = action.payload;
            return __assign({}, state, { apiCallResult: apiCallResult });
        case ActionTypes.SET_ACTIVE_LANGUAGE:
            var activeLanguage = action.payload;
            return __assign({}, state, { activeLanguage: activeLanguage });
        case ActionTypes.SET_SUPPORTED_LANGUAGES:
            var supportedLanguages = action.payload;
            return __assign({}, state, { supportedLanguages: supportedLanguages });
        case ActionTypes.SET_IFRAME_URL:
            var modalIframeUrl = action.payload;
            return __assign({}, state, { modalIframeUrl: modalIframeUrl });
        case ActionTypes.SET_SPINNER_VALUE:
            var spinnerValue = action.payload;
            return __assign({}, state, { spinnerValue: spinnerValue });
        case ActionTypes.SET_CITIES_WITH_SAME_ZIPCODE:
            var storeCitiesByZipcode = cloneDeep(state.citiesByZipcode);
            var payload_1 = action.payload;
            var indexOfFormToUpdate = storeCitiesByZipcode.findIndex(function (item) { return item.formPath === payload_1.formPath; });
            // if values with form path exist in store, update that value else push new value to store
            if (indexOfFormToUpdate !== -1) {
                storeCitiesByZipcode[indexOfFormToUpdate] = payload_1;
            }
            else {
                storeCitiesByZipcode.push(payload_1);
            }
            return __assign({}, state, { citiesByZipcode: storeCitiesByZipcode });
        case ActionTypes.RESET_ZIPCODES_CITIES:
            return __assign({}, state, { citiesByZipcode: [] });
        case ActionTypes.SET_RELOAD_REQUIRED:
            var reloadRequired = action.payload;
            return __assign({}, state, { reloadRequired: reloadRequired });
        case ActionTypes.RESET_REDUCER:
            return initialState;
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getAllCountries = function (state) { return state.allCountries; };
export var getLanguage = function (state) { return state.activeLanguage; };
export var getSupportedLanguages = function (state) { return state.supportedLanguages; };
export var getSelfRegistration = function (state) { return state.selfRegistration; };
export var isEventSeriesPage = function (state) { return state.isEventSeriesPage; };
export var getIsWidget = function (state) { return state.isWidget; };
export var getModalIframeUrl = function (state) { return state.modalIframeUrl; };
export var getSpinnerValue = function (state) { return state.spinnerValue; };
export var getAllCitiesByZipCode = function (state) { return state.citiesByZipcode; };
export var getReloadRequired = function (state) { return state.reloadRequired; };
