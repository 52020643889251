import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  combineLatest as observableCombineLatest,
  of,
  Subject
} from 'rxjs';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { TranslationsService } from './_pages/translations/translations.service';
import { AppService } from './app.service';
import { AppConstants } from './shared/app-constants';
import { CssSkinModel } from './shared/services-with-reducers/customization/customization.interfaces';
import { CustomizationService } from './shared/services-with-reducers/customization/customization.service';
import { HelperService } from './shared/services-with-reducers/helpers/helper.service';
import { UserService } from './shared/services-with-reducers/user/user.service';
import { StatusBarService } from './status-bar/status-bar.service';

import { getTranslationList } from '@store/translation/translation.selectors';
import { environment } from '../environments/environment';
import * as fromRoot from './app.reducer';
import { getQueryParamsFromLocation, pageRunningInIframe } from './shared/app-utils';
import * as exhibitionActions from './shared/services-with-reducers/exhibition/exhibition.actions';
import * as helperActions from './shared/services-with-reducers/helpers/helper.actions';
import { StepsFormsService } from './shared/services-with-reducers/step-forms/steps-forms.service';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoggedInAsAdmin$: Observable<boolean>;
  public userLanguage: string;
  public backgroundImage: string;
  public isSelfRegistrationEnabled: boolean;
  public translationsLoaded = false;
  public pageRunningInIframe: boolean;
  private navigationStepsInitialBorder: number = 5 / 1.414;
  private favicon: HTMLLinkElement = document.querySelector('#favicon');

  @HostListener('window:resize', ['$event']) onResizeEvent($event) {
    setTimeout(() => {
      this.handleStepNavSticky();
    }, 300);
  }

  private wasAppDisconnected = false;
  private resetByQueryParams: Array<string | { [key: string]: string }> = [
    't',
    'u'
  ];
  private unsubscribe = new Subject<void>();
  public reloadModalWindowOpen: boolean = false;

  // we need to ensure all services globaly used initialize their singleton instance
  constructor(
    public route: ActivatedRoute,
    private element: ElementRef,
    private _translateService: TranslateService,
    private _customizationService: CustomizationService,
    private _renderer: Renderer,
    private _router: Router,
    private _translationsService: TranslationsService,
    private _store: Store<fromRoot.State>,
    private _appService: AppService,
    private _statusBarService: StatusBarService,
    private _helperService: HelperService,
    private _userService: UserService,
    private _ngZone: NgZone,
    private _http: HttpClient,
    private _stepsFormsService: StepsFormsService
  ) {

    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      this._stepsFormsService.scrollToTop();
    });

    this.resetAppWithLocationParams().then();
    // handle online / offline states
    this._appService.online$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(connection => {
        if (!connection) {
          this._statusBarService.setStatus('You are disconnected', 'error');
          this.wasAppDisconnected = true;
        }

        if (connection && this.wasAppDisconnected) {
          this._statusBarService.setStatus('You are online', 'success');
          this.wasAppDisconnected = false;
        }
      });

    this._store
      .pipe(
        select(fromRoot.getReloadRequired),
        filter(reloadRequired => reloadRequired),
        takeUntil(this.unsubscribe)
      )
      .subscribe(reloadRequired => {
        this.reloadModalWindowOpen = reloadRequired;
      });

    this._ngZone.onError.pipe(takeUntil(this.unsubscribe)).subscribe(e => {
      this._http
        .post(
          `${environment.protocol}${environment.webApiUrl}/audit-log/shop-error`,
          {
            error: e.toString(),
            stack: e.stack,
            userAgent: this._helperService.browserInfo()
          }
        )
        .pipe(
          catchError(err => of([]))
        )
        .subscribe();
    });

    // get dom component of the Angular app and save it reference for later use
    this._helperService.setRefsToAppComponent(
      this._renderer,
      this.element.nativeElement
    );

    this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
    this._store.dispatch(new helperActions.GetAllCountriesAction());

    this._store.dispatch(new exhibitionActions.GetAllTitles());
    this._store.dispatch(new exhibitionActions.GetAllProfessions());
    this._store.dispatch(new exhibitionActions.GetAllDepartments());
    this._store.dispatch(new exhibitionActions.GetAllOccupationalGroups());

    this._store
      .select(fromRoot.getLocalizedImages)
      .pipe(
        filter(images => !!images),
        takeUntil(this.unsubscribe)
      )
      .subscribe(images => {
        this.backgroundImage = images.background;
        this.favicon.href = !!images.favicon ? images.favicon : "favicon.ico";
      });

    this.handleLanguages();

    // check whether user's token is still valid, if not log him/her out
    const authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;

    let interval;

    this._store
      .select(fromRoot.getLoginTimestamp)
      .pipe(
        filter(timestamp => timestamp !== null),
        takeUntil(this.unsubscribe)
      )
      .subscribe(timestamp => {
        if (interval) {
          clearInterval(interval);
        }
        if (timestamp) {
          interval = setInterval(() => {
            const now = Date.now();
            if (timestamp + authTimespanInMs < now) {
              clearInterval(interval);
              this._userService.forcelogout();
            }
          }, 1000);
        }
      });
  }

  ngOnInit() {
    this.checkPageRunningInIframe();

    window.onpopstate = function (event) {
      const isIE = !!navigator.userAgent.match(/Trident/);

      if (!isIE) {
        document.location.href = document.location.href;
      }
    };
    this._helperService.stepNavigationRendered().subscribe(() => {
      this.handleStepNavSticky();
    });

    this.processDataForAppInit();
  }

  handleStepNavSticky() {
    const stepNavigationElement = this.element.nativeElement.querySelector("app-step-navigation");
    const navigationStepsElement = document.getElementById("navigation-steps");
    const topBarElement = this.element.nativeElement.querySelector("app-top-bar");

    if (window.innerWidth > 576 && !!stepNavigationElement && !!navigationStepsElement && !!topBarElement) {
      const top: string = `${topBarElement.offsetHeight - Math.ceil(this.navigationStepsInitialBorder)}px`;
      stepNavigationElement.style.top = top;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  processDataForAppInit() {
    this._store
      .pipe(
        select(getTranslationList),
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.translationsLoaded = true;
      });
    this._store
      .select(fromRoot.getSelfRegistration)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selfRegistration: boolean) => {
        this.isSelfRegistrationEnabled = selfRegistration;
      });

    // once styles in redux store are updated, ask customization service to add new style element with new styleset
    this._store
      .select(fromRoot.getExhibitionStyles)
      .pipe(
        filter(data => !!data && data.css !== null),
        takeUntil(this.unsubscribe)
      )
      .subscribe((skinModel: CssSkinModel) => {
        this._customizationService.addCSS(skinModel.css, 'skin');
      });

    // once we switch language get new list of exhibitions
    this._store
      .select(fromRoot.getLanguage)
      .pipe(
        filter(lang => !!lang),
        takeUntil(this.unsubscribe)
      )
      .subscribe(lang => {
        this._store.dispatch(
          new exhibitionActions.GetListOfAllExhibitions(lang)
        );
      });
  }

  handleLanguages() {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getUserLanguage)),
      this._store.pipe(select(fromRoot.getOperatorsSettings)),
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this.route.queryParams
    ])
      .pipe(
        filter(data => {
          return !!data[1] || !!data[2];
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        const [userLang, operatorSettings, eventSettings, params] = data;

        let supportedLanguages = [];

        if (!!eventSettings && eventSettings.languages.length) {
          supportedLanguages = eventSettings.languages;
        } else if (!!operatorSettings && operatorSettings.languages.length) {
          supportedLanguages = operatorSettings.languages;
        } else if (!!operatorSettings) {
          supportedLanguages.push(operatorSettings.defaultLanguage);
        }

        let langToUse = '';

        // browser preferred language
        const browserLang = this._translateService.getBrowserLang();

        // params comming from url
        let paramLang = '';

        if (params && params.lang) {
          paramLang = params.lang.toLowerCase();
        }

        const supportsUserLang = supportedLanguages.indexOf(userLang) >= 0;
        const isSelfRegistration = this._helperService.isSelfregistration();

        // set the language with priority fallback. First come first serve

        if (supportedLanguages.indexOf(paramLang) >= 0) {
          langToUse = paramLang;
          this._userService.changePreferredLanguage(langToUse);
        } else if (isSelfRegistration && userLang && supportsUserLang) {
          langToUse = userLang;
        } else if (
          isSelfRegistration &&
          supportedLanguages.indexOf('de') >= 0
        ) {
          // TODO this language ('de') should come in event settings probably (need to add option in BE admin first)
          langToUse = 'de';
        } else if (supportsUserLang) {
          langToUse = userLang;
        } else if (supportedLanguages.indexOf(browserLang) >= 0) {
          langToUse = browserLang;
        } else if (supportedLanguages.indexOf('en') >= 0) {
          langToUse = 'en';
        } else {
          langToUse = supportedLanguages[0];
        }

        // set allowed langs and activate default one
        this._helperService.setLanguage(langToUse);
        this._helperService.setSupportedLanguages(supportedLanguages);
      });
  }

  rightClick(event) {
    this.isLoggedInAsAdmin$
      .pipe(
        first(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(isAdmin => {
        if (!isAdmin) {
          return true;
        }

        const clickedElement = event.target as HTMLElement;

        if (clickedElement.attributes.hasOwnProperty('data-translation')) {
          event.preventDefault();

          const key = clickedElement.attributes['data-translation'].value;

          this._translationsService.activeTranslation = key;

          if (this._router.url !== '/translations') {
            this._router.navigate(['/translations']);
          }
        } else {
          const closest = clickedElement.closest(
            '[data-translation-includes-children]'
          );

          if (closest) {
            event.preventDefault();

            const key = closest.attributes['data-translation'].value;

            this._translationsService.activeTranslation = key;

            if (this._router.url !== '/translations') {
              this._router.navigate(['/translations']);
            }
          }
        }

        //for firefox buttons
        if (clickedElement.classList.contains('button')) {
          event.preventDefault();

          const key =
            clickedElement.firstElementChild.attributes['data-translation']
              .value;

          this._translationsService.activeTranslation = key;
          this._router.navigate(['/translations']);
        }
      });
  }

  /**
   * Reset App if location contains specific parameter.
   * We need to get the queryParams immediately (synchronously) therefore we are not taking it from ActivatedRoute,
   *  as initial value of queryParams from ActivatedRoute is an emty Object
   */
  resetAppWithLocationParams() {
    const loacationQueryParams = getQueryParamsFromLocation();

    let resolutionPromise = new Promise(resolve => {
      resolve(null);
    });
    let shouldResetAppState = false;

    for (const key in loacationQueryParams) {
      const queryParam = loacationQueryParams[key];
      const resetByQueryParam = this.resetByQueryParams.find(
        resetByQueryParam => resetByQueryParam[key]
      );
      const resetByQueryParamValue =
        resetByQueryParam && resetByQueryParam[key];

      // reset on specific queryparam value
      if (resetByQueryParamValue && resetByQueryParamValue == queryParam) {
        shouldResetAppState = true;
      }
      // reset if queryParam is present
      else if (
        this.resetByQueryParams &&
        this.resetByQueryParams.indexOf(key) !== -1
      ) {
        shouldResetAppState = true;
      }
    }

    if (shouldResetAppState) {
      resolutionPromise = this.resetAppReducers();
    }

    return resolutionPromise;
  }

  resetAppReducers() {
    const resetReducerPromise = new Promise(resolve => {
      this._appService.resetReducers().subscribe(() => resolve());
    });

    return resetReducerPromise;
  }

  checkPageRunningInIframe() {
    this.pageRunningInIframe = pageRunningInIframe();
  }

  closeModalWindow(event) {
    event.preventDefault();
    event.stopPropagation();
    this.reloadModalWindowOpen = false;

    this._appService.resetReducersWithUser().subscribe(() => {
      window.location.reload();
      return;
    });
  }
}
