import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getExhibitionSettings, getIsWidget, getLocalTime, getLoginMode, getSelectedExhibition, getSelectedExhibitionId, getSelfRegistration, getTicketLimitPerUserAccount, getWidgetSettings, isUserLoggedIn, notLoggedAndLoginMandatory, showPromoCode } from '@app/app.reducer';
import { LoginOptions } from '@app/login/login.model';
import { LoginService } from '@app/login/login.service';
import { Store, select } from '@ngrx/store';
import { isProductGroup, isProductTypeTariff } from '@products/models/product-selection.model';
import { getIsTranslationLoaded } from '@root/src/app/shared/services-with-reducers/translation/translation.selectors';
import { HelperService } from '@store/helpers/helper.service';
import { getAllBookedPackagePricesGroupedByPackageIndex, getAllBookedPackagePricesGroupedByPackageNumber, getAllBookedProductsCount, getAllBookedProductsTotalPrice, getBookedLimitedPromoCodeVoucherTariffsCount, getBookedOneTimeVoucherTariffsCount, getBookedPromoCodeVoucherTariffsCount, isBookedProductsCountValid } from '@store/products/booking/booking.selectors';
import { getAllProductSelectionListProducts, getProductSelectionListProducts, getVoucherProducts, isRedeemedAnonymousVoucherProductInProductSelectionList, isRedeemedVoucherProductInProductSelectionList } from '@store/products/product-selection/product-selection.selectors';
import { getIsPackageLoading, getLastPackageIndex } from '@store/products/status/package/package.selectors';
import { getAvailableTariffs, getIsTariffLoading, getUserAccountTariffLimit } from '@store/products/status/tariff/tariff.selectors';
import { getIsWorkshopDetailModalOpen, getIsWorkshopLoading, getWorkshopsAvailableSeatsGroupedByWorkshopId } from '@store/products/status/workshop/workshop.selectors';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "../../../login/login.service";
import * as i4 from "../../../shared/services-with-reducers/helpers/helper.service";
var ProductSelectionService = /** @class */ (function () {
    function ProductSelectionService(store, route, loginService, helperService) {
        this.store = store;
        this.route = route;
        this.loginService = loginService;
        this.helperService = helperService;
        this.destroy$ = new Subject();
    }
    ProductSelectionService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    ProductSelectionService.prototype.getProductSelectionProductsAndExhibitionState$ = function () {
        var checkTicketLimitPerUserAccount$ = combineLatest([
            this.store.pipe(select(getLoginMode)),
            this.store.pipe(select(isUserLoggedIn)),
            this.store.pipe(select(getTicketLimitPerUserAccount)),
            this.store.pipe(select(getUserAccountTariffLimit))
        ]);
        return combineLatest([
            this.store.pipe(select(getSelectedExhibition)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getProductSelectionListProducts)),
            this.store.pipe(select(getVoucherProducts)),
            checkTicketLimitPerUserAccount$
        ]).pipe(filter(function (_a) {
            var selectedExhibition = _a[0], exhibitionSettings = _a[1], productSelectionListProducts = _a[2];
            return !!selectedExhibition && !!exhibitionSettings && !!productSelectionListProducts;
        }), map(function (_a) {
            var selectedExhibition = _a[0], exhibitionSettings = _a[1], productSelectionListProducts = _a[2], voucherProducts = _a[3], _b = _a[4], loginMode = _b[0], isUserLoggedIn = _b[1], ticketLimitPerUserAccount = _b[2], userAccountTariffLimit = _b[3];
            var checkTicketLimitPerUserAccount = loginMode === LoginOptions.BeforeTicketSelection && isUserLoggedIn && !!ticketLimitPerUserAccount;
            var isUserAccountLimitChecked = !checkTicketLimitPerUserAccount || (checkTicketLimitPerUserAccount && userAccountTariffLimit !== null);
            return {
                selectedExhibition: selectedExhibition,
                exhibitionSettings: exhibitionSettings,
                productSelectionListProducts: productSelectionListProducts,
                voucherProducts: voucherProducts,
                isUserAccountLimitChecked: isUserAccountLimitChecked
            };
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getWidgetProductSelectionProductsAndExhibitionState$ = function () {
        return combineLatest([
            this.store.pipe(select(getSelectedExhibition)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getProductSelectionListProducts)),
            this.store.pipe(select(getVoucherProducts)),
            this.store.pipe(select(getWidgetSettings))
        ]).pipe(filter(function (_a) {
            var selectedExhibition = _a[0], exhibitionSettings = _a[1], productSelectionListProducts = _a[2];
            return !!selectedExhibition && !!exhibitionSettings && !!productSelectionListProducts;
        }), map(function (_a) {
            var selectedExhibition = _a[0], exhibitionSettings = _a[1], productSelectionListProducts = _a[2], voucherProducts = _a[3], widgetSettings = _a[4];
            var listOfUniqueIds = widgetSettings.ticketsWidget.tickets.split(',');
            productSelectionListProducts.forEach(function (productList) {
                if (isProductGroup(productList)) {
                    productList.products = productList.products.filter(function (product) {
                        if (isProductTypeTariff(product)) {
                            product.tariffType.tariffs = product.tariffType.tariffs.filter(function (productTariff) {
                                var uniqueId = productTariff.ticketTypeId + "_" + productTariff.ticketPersonTypeId;
                                if (listOfUniqueIds.includes(uniqueId)) {
                                    return productTariff;
                                }
                            });
                            return product.tariffType.tariffs.length;
                        }
                    });
                }
            });
            return {
                selectedExhibition: selectedExhibition,
                exhibitionSettings: exhibitionSettings,
                productSelectionListProducts: productSelectionListProducts,
                voucherProducts: voucherProducts
            };
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionViewModel$ = function () {
        var productSelectionVoucherState$ = combineLatest([
            this.store.pipe(select(isRedeemedVoucherProductInProductSelectionList)),
            this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
        ]);
        var helpersState$ = combineLatest([
            this.store.pipe(select(getSelfRegistration)),
            this.store.pipe(select(getIsWidget)),
            this.store.pipe(select(getIsTranslationLoaded)),
            this.helperService.isMobile$()
        ]);
        return combineLatest([
            productSelectionVoucherState$,
            helpersState$,
            this.store.pipe(select(notLoggedAndLoginMandatory)),
            this.store.pipe(select(showPromoCode)),
            this.store.pipe(select(getLocalTime)),
            this.loginService.checkAccountVerificationStatus$()
        ]).pipe(map(function (_a) {
            var _b = _a[0], isRedeemedVoucherProductInProductSelectionList = _b[0], isRedeemedAnonymousVoucherProductInProductSelectionList = _b[1], _c = _a[1], isSelfRegistration = _c[0], isWidget = _c[1], isTranslationLoaded = _c[2], isMobile = _c[3], notLoggedAndLoginMandatory = _a[2], showPromoCode = _a[3], localTime = _a[4], checkAccountVerificationStatus = _a[5];
            return ({
                isRedeemedVoucherProductInProductSelectionList: isRedeemedVoucherProductInProductSelectionList,
                isRedeemedAnonymousVoucherProductInProductSelectionList: isRedeemedAnonymousVoucherProductInProductSelectionList,
                isSelfRegistration: isSelfRegistration,
                isWidget: isWidget,
                isMobile: isMobile,
                notLoggedAndLoginMandatory: notLoggedAndLoginMandatory,
                showPromoCode: showPromoCode,
                isTranslationLoaded: isTranslationLoaded,
                dateToday: localTime,
                isVerifiedAccountRequired: checkAccountVerificationStatus.isVerifiedAccountRequired,
                isAccountVerified: checkAccountVerificationStatus.isAccountVerified
            });
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionTariffStatusViewModel$ = function () {
        return combineLatest([
            this.store.pipe(select(getUserAccountTariffLimit)),
            this.store.pipe(select(getAvailableTariffs)),
            this.store.pipe(select(getIsTariffLoading))
        ]).pipe(map(function (_a) {
            var userAccountTicketLimit = _a[0], availableTariffs = _a[1], isTariffLoading = _a[2];
            return ({
                currentUserAccountTicketLimit: userAccountTicketLimit,
                availableTariffs: availableTariffs,
                isTariffLoading: isTariffLoading
            });
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionPackageStatusViewModel$ = function () {
        return combineLatest([
            this.store.pipe(select(getLastPackageIndex)),
            this.store.pipe(select(getIsPackageLoading))
        ]).pipe(map(function (_a) {
            var lastPackageIndex = _a[0], isPackageLoading = _a[1];
            return ({ lastPackageIndex: lastPackageIndex, isPackageLoading: isPackageLoading });
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionBookingStatusViewModel$ = function () {
        var productBookingsStatus$ = combineLatest([
            this.store.pipe(select(getAllBookedProductsCount)),
            this.store.pipe(select(isBookedProductsCountValid)),
            this.store.pipe(select(getAllBookedProductsTotalPrice))
        ]);
        var voucherBookingsStatus$ = combineLatest([
            this.store.pipe(select(getBookedPromoCodeVoucherTariffsCount)),
            this.store.pipe(select(getBookedLimitedPromoCodeVoucherTariffsCount)),
            this.store.pipe(select(getBookedOneTimeVoucherTariffsCount))
        ]);
        var packageBookingStatus$ = combineLatest([
            this.store.pipe(select(getAllBookedPackagePricesGroupedByPackageNumber)),
            this.store.pipe(select(getAllBookedPackagePricesGroupedByPackageIndex))
        ]);
        return combineLatest([productBookingsStatus$, voucherBookingsStatus$, packageBookingStatus$]).pipe(map(function (_a) {
            var _b = _a[0], bookedProductsCount = _b[0], isBookedProductsCountValid = _b[1], bookedProductsTotalPrice = _b[2], _c = _a[1], bookedPromoCodeVouchersCount = _c[0], bookedLimitedPromoCodeVouchersCount = _c[1], bookedOnetimeVouchersCount = _c[2], _d = _a[2], bookedPackagePricesGroupedByPackageNumber = _d[0], bookedPackagePricesGroupedByPackageIndex = _d[1];
            return ({
                bookedProductsCount: bookedProductsCount,
                isBookedProductsCountValid: isBookedProductsCountValid,
                bookedProductsTotalPrice: bookedProductsTotalPrice,
                bookedPromoCodeVouchersCount: bookedPromoCodeVouchersCount,
                bookedLimitedPromoCodeVouchersCount: bookedLimitedPromoCodeVouchersCount,
                bookedOnetimeVouchersCount: bookedOnetimeVouchersCount,
                bookedPackagePricesGroupedByPackageNumber: bookedPackagePricesGroupedByPackageNumber,
                bookedPackagePricesGroupedByPackageIndex: bookedPackagePricesGroupedByPackageIndex
            });
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionWorkshopStatusViewModel$ = function () {
        return combineLatest([
            this.store.pipe(select(getWorkshopsAvailableSeatsGroupedByWorkshopId)),
            this.store.pipe(select(getIsWorkshopLoading)),
            this.store.pipe(select(getIsWorkshopDetailModalOpen))
        ]).pipe(map(function (_a) {
            var availableSeats = _a[0], isWorkshopLoading = _a[1], isWorkshopDetailModalOpen = _a[2];
            return ({
                isWorkshopLoading: isWorkshopLoading,
                availableSeats: availableSeats,
                isWorkshopDetailModalOpen: isWorkshopDetailModalOpen
            });
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getProductSelectionProductsStatusViewModel$ = function () {
        return combineLatest([
            this.getProductSelectionTariffStatusViewModel$(),
            this.getProductSelectionPackageStatusViewModel$(),
            this.getProductSelectionWorkshopStatusViewModel$(),
            this.getProductSelectionBookingStatusViewModel$()
        ]).map(function (_a) {
            var tariffStatus = _a[0], packageStatus = _a[1], workshopStatus = _a[2], bookingStatus = _a[3];
            return ({
                tariffStatus: tariffStatus,
                packageStatus: packageStatus,
                workshopStatus: workshopStatus,
                bookingStatus: bookingStatus
            });
        }, takeUntil(this.destroy$));
    };
    ProductSelectionService.prototype.getPreferredProductsAfterInitialProductsLoad$ = function () {
        return this.route.queryParams.pipe(withLatestFrom(this.store.pipe(select(getSelectedExhibitionId)), this.store.pipe(select(getAllProductSelectionListProducts))), filter(function (_a) {
            var params = _a[0], exhibitionId = _a[1], allProductSelectionListProducts = _a[2];
            return !!params.tt && !!exhibitionId && !!allProductSelectionListProducts.length;
        }), map(function (_a) {
            var _ = _a[0], exhibitionId = _a[1];
            return exhibitionId;
        }), takeUntil(this.destroy$));
    };
    ProductSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function ProductSelectionService_Factory() { return new ProductSelectionService(i0.inject(i1.Store), i0.inject(i2.ActivatedRoute), i0.inject(i3.LoginService), i0.inject(i4.HelperService)); }, token: ProductSelectionService, providedIn: "root" });
    return ProductSelectionService;
}());
export { ProductSelectionService };
