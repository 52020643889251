<ng-template #productTypeTariff let-confirmationTariff="confirmationTariff">
  <div class="ticket-header">
    <span class="title black"
      >{{ confirmationTariff.ticketName | translate | hideTranslation }}
      <span class="voucher-code" *ngIf="confirmationTariff.voucherCode">({{ confirmationTariff.voucherCode }})</span>
    </span>
    <span class="count black">{{ confirmationTariff.count }}x</span>
    <span class="price black">{{ confirmationTariff.price | formatPrice: settings.currencySettings }}</span>
    <span class="per-type-price price">{{
      confirmationTariff.totalPrice | formatPrice: settings.currencySettings
    }}</span>
  </div>

  <ng-container *ngIf="!settings.hideWorkshopPrice && confirmationTariff.workshops.length">
    <app-confirmation-workshop-prices
      [confirmationTariff]="confirmationTariff"
      [currencySettings]="settings.currencySettings"
    >
    </app-confirmation-workshop-prices>
  </ng-container>

  <div class="ticket" *ngFor="let holderInfo of confirmationTariff.holderInformation">
    <div class="ticket-data">
      <span class="name">{{ holderInfo.firstName }} {{ holderInfo.lastName }}</span>
      <span
        class="email send-to-buyer"
        *ngIf="!holderInfo || !holderInfo.sendtoowner || holderInfo.sendingOption === 'allToBuyer'; else notToBuyer"
        >{{ buyerEmail }}</span
      >
      <ng-template #notToBuyer>
        <span class="email">{{ holderInfo.email ? holderInfo.email : buyerEmail }}</span>
      </ng-template>
      <app-ticket-dropdown
        [confirmationOptions]="confirmationOptions"
        [holderInformation]="holderInfo"
        (onSelect)="dropdownChanged($event)"
      >
      </app-ticket-dropdown>
    </div>

    <ng-container *ngIf="confirmationTariff.workshops.length">
      <div class="ticket-workshops" *ngFor="let confirmationWorkshop of confirmationTariff.workshops">
        <div class="workshops-title">{{ 'confirmation.workshops' | translate | hideTranslation }}</div>
        <div class="workshop-list">
          <ng-container *ngFor="let holderUuid of confirmationWorkshop.holderUuids">
            <ng-container *ngIf="holderInfo.holderUuid === holderUuid">
              <app-confirmation-workshop [confirmationWorkshop]="confirmationWorkshop" class="workshop-by-name">
              </app-confirmation-workshop>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<div class="page-padding-wrapper" *ngIf="orderSent">
  <app-loader></app-loader>
</div>
<div class="page-padding-wrapper" *ngIf="settings && settings.currencySettings && !orderSent">
  <ng-container *ngIf="confirmationProducts.length">
    <ng-container
      *ngIf="getAllBookedPackagePricesGroupedByPackageIndex$ | async as allBookedPackagePricesGroupedByPackageIndex"
    >
      <div *ngFor="let confirmationProduct of confirmationProducts" appAdjustSideHeadline>
        <div class="section-title-price">
          <div class="section-headline">
            <h2>{{ confirmationProduct.productTypeName | translate | hideTranslation }}</h2>
            <div class="section-headline-side">←</div>
          </div>
          <div *ngIf="confirmationProduct.productType === ProductType.Package" class="confirmation-package-header">
            <span class="count black">1x</span>
            <span class="price black count-price">
              {{
                allBookedPackagePricesGroupedByPackageIndex[confirmationProduct.packageIndex]
                  | formatPrice: settings.currencySettings
              }}
            </span>
            <span class="price black">
              {{
                allBookedPackagePricesGroupedByPackageIndex[confirmationProduct.packageIndex]
                  | formatPrice: settings.currencySettings
              }}
            </span>
          </div>
        </div>
        <div class="section-body confirmation-page" *ngIf="confirmationProduct.productType === ProductType.Package">
          <div *ngFor="let packageProductType of confirmationProduct.productTypes">
            <h3>{{ packageProductType.productTypeName | translate | hideTranslation }}</h3>
            <ng-container *ngIf="packageProductType.productType === ProductType.Tariff">
              <ng-container *ngFor="let packageConfirmationTariff of packageProductType.tariffs">
                <ng-template
                  *ngTemplateOutlet="productTypeTariff; context: { confirmationTariff: packageConfirmationTariff }"
                ></ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="section-body confirmation-page" *ngIf="confirmationProduct.productType === ProductType.Tariff">
          <ng-container *ngFor="let confirmationTariff of confirmationProduct.tariffs">
            <ng-template
              *ngTemplateOutlet="productTypeTariff; context: { confirmationTariff: confirmationTariff }"
            ></ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <hr />
  <div class="row">
    <div class="col-md-8" *ngIf="checkboxesInputs?.length">
      <form class="row no-group-margin" [formGroup]="checkboxesForm">
        <app-df-input
          *ngFor="let input of checkboxesInputs"
          [input]="input"
          [class]="input.cssClass"
          [inputSet]="checkboxesInputs"
          [action]="checkboxesFormsActionName"
          [form]="checkboxesForm"
        >
        </app-df-input>
      </form>
    </div>

    <div class="confirmation-total col-md col-md-auto">
      <h2 class="total black" data-translation="confirmation.total">
        {{ 'confirmation.total' | translate | hideTranslation }}:
        <span class="price"> {{ totalPrice | formatPrice: settings.currencySettings }}</span>
      </h2>
    </div>
  </div>
  <app-step-navigation-buttons [payment]="true" [paymentValue]="totalPrice" [hasAlternativeButton]="true">
    <button
      class="button save-button"
      *ngIf="!totalPrice"
      [disabled]="isAlternativeButtonDisabled"
      (click)="paymentSubmited(AppConstants.PAYMENT_METHODS.FREE, $event)"
      alternative-submit
    >
      <span data-translation="step-navigation.continue-button">{{
        'step-navigation.continue-button' | translate | hideTranslation
      }}</span>
    </button>

    <div class="payment" *ngIf="totalPrice" [class.disabled]="isAlternativeButtonDisabled" alternative-submit>
      <div class="black" data-translation="step-navigation.payment-message">
        {{ 'step-navigation.payment-message' | translate | hideTranslation }}:
      </div>
      <div class="payment-options">
        <div
          class="payment-option"
          *ngFor="let method of settings.paymentMethods"
          (click)="paymentSubmited(method.id, $event)"
        >
          <div class="paymentImgContainer" *ngIf="paymentMethodImages[method.id]">
            <img [src]="paymentMethodImages[method.id]" [alt]="method.name" />
          </div>
          <div *ngIf="!paymentMethodImages[method.id]">{{ method.name }}</div>
        </div>
      </div>
    </div>
  </app-step-navigation-buttons>
</div>
