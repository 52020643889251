import { getLocaleDateFormat, getLocaleDayNames, getLocaleMonthNames } from '@angular/common';
import { Directive, Input, OnInit } from '@angular/core';
import { State, getLanguage } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { Calendar, LocaleSettings } from 'primeng/calendar';

@Directive({
  selector: '[calendarCustomDefaults]'
})
export class CalendarCustomDefaultsDirective implements OnInit {
  @Input() minToday: boolean;
  @Input() minDate: Date;
  locale: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: [],
    dayNamesShort: [],
    dayNamesMin: [],
    monthNames: [],
    monthNamesShort: [],
    today: null,
    clear: null
  };

  constructor(private calendar: Calendar, private store: Store<State>) {
    this.store.pipe(select(getLanguage)).subscribe(activeLanguage => {
      this.locale = {
        ...this.locale,
        monthNames: getLocaleMonthNames(activeLanguage, 0, 2),
        monthNamesShort: getLocaleMonthNames(activeLanguage, 0, 1),
        dayNames: getLocaleDayNames(activeLanguage, 0, 2),
        dayNamesShort: getLocaleDayNames(activeLanguage, 0, 3),
        dayNamesMin: getLocaleDayNames(activeLanguage, 0, 0)
      };

      this.calendar.locale = this.locale;
      this.calendar.dateFormat =
        activeLanguage === 'en' ? 'dd.mm.yy' : getLocaleDateFormat(activeLanguage, 0).toLowerCase();
    });
  }

  ngOnInit() {
    if (this.minToday && !this.minDate) {
      this.calendar.minDate = new Date();
    }
  }
}
