/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-credentials.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../info-block/info-block.component.ngfactory";
import * as i3 from "../../info-block/info-block.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../shared/forms/df-input/df-input.component.ngfactory";
import * as i6 from "../../shared/forms/df-input/df-input.component";
import * as i7 from "../../shared/forms/forms.service";
import * as i8 from "../../shared/services-with-reducers/helpers/helper.service";
import * as i9 from "@ngrx/store";
import * as i10 from "../../shared/validation/validation.service";
import * as i11 from "@angular/forms";
import * as i12 from "@angular/common";
import * as i13 from "../../shared/pipes/is-hidden-translation/is-hidden-translation.pipe";
import * as i14 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i15 from "./change-credentials.component";
import * as i16 from "../../status-bar/status-bar.service";
import * as i17 from "../../shared/window-size/window-size.service";
var styles_ChangeCredentialsComponent = [i0.styles];
var RenderType_ChangeCredentialsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeCredentialsComponent, data: {} });
export { RenderType_ChangeCredentialsComponent as RenderType_ChangeCredentialsComponent };
function View_ChangeCredentialsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-block", [], null, null, null, i2.View_InfoBlockComponent_0, i2.RenderType_InfoBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.InfoBlockComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var currVal_0 = "profile.missing.email.info"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChangeCredentialsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-block", [], null, null, null, i2.View_InfoBlockComponent_0, i2.RenderType_InfoBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.InfoBlockComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var currVal_0 = "profile.pending.email.info"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChangeCredentialsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row edit-button-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text-data"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.emailChangeActive = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["data-translation", "profile.login.email-change-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.email; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("profile.login.email-change-btn")))); _ck(_v, 7, 0, currVal_1); }); }
function View_ChangeCredentialsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i5.View_DfInputComponent_0, i5.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i6.DfInputComponent, [i7.FormsService, i8.HelperService, i9.Store, i1.ElementRef, i1.Renderer2, i10.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.emailInputs; var currVal_3 = _co.changeEmailAction; var currVal_4 = _co.emailForm; var currVal_5 = _co.emailFormSaveCallback; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_ChangeCredentialsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i11.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 540672, null, 0, i11.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i11.ControlContainer, null, [i11.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i11.NgControlStatusGroup, [[4, i11.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_6)), i1.ɵdid(7, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 14, "div", [["class", "row justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "button edit-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitEmailChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["data-translation", "profile.save"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(15, 1), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "button", [["class", "button edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.emailChangeActive = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "span", [["data-translation", "profile.cancel"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(22, 1)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.emailForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.emailInputs; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (!_co.emailForm.valid || (_co.emailForm.controls.email.value === _co.profile.email)); _ck(_v, 11, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 13, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("profile.save")))); _ck(_v, 13, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 20, 0, _ck(_v, 22, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("profile.cancel")))); _ck(_v, 20, 0, currVal_11); }); }
function View_ChangeCredentialsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "form-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_3)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_4)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_5)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.isEmailChangePending; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.emailChangeActive === false); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.emailChangeActive; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ChangeCredentialsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-block", [], null, null, null, i2.View_InfoBlockComponent_0, i2.RenderType_InfoBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.InfoBlockComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var currVal_0 = "profile.pending.password.info"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChangeCredentialsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row edit-button-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text-data"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ********* "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.passwordChangeActive = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["data-translation", "profile.login.password-change-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("profile.login.password-change-btn")))); _ck(_v, 7, 0, currVal_0); }); }
function View_ChangeCredentialsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i5.View_DfInputComponent_0, i5.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i6.DfInputComponent, [i7.FormsService, i8.HelperService, i9.Store, i1.ElementRef, i1.Renderer2, i10.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.passwordInputs; var currVal_3 = _co.changePasswordAction; var currVal_4 = _co.passwordForm; var currVal_5 = _co.passwordFormSaveCallback; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_ChangeCredentialsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "form", [["class", "row"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i11.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 540672, null, 0, i11.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i11.ControlContainer, null, [i11.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i11.NgControlStatusGroup, [[4, i11.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_11)), i1.ɵdid(6, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "col-md-6 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "button edit-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitPasswordChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["data-translation", "profile.save"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "col-md-6 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "button edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.passwordChangeActive = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "span", [["data-translation", "profile.cancel"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(20, 1)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.passwordForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.passwordInputs; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = !_co.passwordForm.valid; _ck(_v, 9, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("profile.save")))); _ck(_v, 11, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 18, 0, _ck(_v, 20, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("profile.cancel")))); _ck(_v, 18, 0, currVal_11); }); }
function View_ChangeCredentialsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "form-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_8)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_9)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_10)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.isPasswordChangePending; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.passwordChangeActive === false); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.passwordChangeActive; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ChangeCredentialsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.IsHiddenTranslationPipe, []), i1.ɵpid(0, i14.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_ChangeCredentialsComponent_1)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_2)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCredentialsComponent_7)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.profile.email && !i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("profile.missing.email.info"))))); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.emailForm && ((_co.emailInputs == null) ? null : _co.emailInputs.length)); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.passwordForm && ((_co.passwordInputs == null) ? null : _co.passwordInputs.length)) && _co.profile.email); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ChangeCredentialsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-credentials", [], null, null, null, View_ChangeCredentialsComponent_0, RenderType_ChangeCredentialsComponent)), i1.ɵdid(1, 4440064, null, 0, i15.ChangeCredentialsComponent, [i9.Store, i7.FormsService, i16.StatusBarService, i17.WindowSizeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeCredentialsComponentNgFactory = i1.ɵccf("app-change-credentials", i15.ChangeCredentialsComponent, View_ChangeCredentialsComponent_Host_0, {}, {}, []);
export { ChangeCredentialsComponentNgFactory as ChangeCredentialsComponentNgFactory };
