import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { State, getExhibitionSettings, getProfile } from '../app.reducer';
import { AccountVerificationStatus, LoginOptions } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {
  activeTab$: Subject<'login' | 'registration'> = new Subject();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setActiveTab(tab: 'login' | 'registration') {
    this.activeTab$.next(tab);
  }

  checkAccountVerificationStatus$(): Observable<AccountVerificationStatus> {
    return combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getProfile))]).pipe(
      filter(([exhibitionSettings]) => !!exhibitionSettings),
      map(([exhibitionSettings, profile]) => ({
        isVerifiedAccountRequired:
          exhibitionSettings.loginMode === LoginOptions.BeforeTicketSelectionWithRequiredEmailConfirmation,
        isAccountVerified: !!profile ? profile.isEmailVerified : false
      })),
      takeUntil(this.destroy$)
    );
  }
}
