import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import { ElementRef, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppConstants } from '../shared/app-constants';
import { getAppVersion, getLocalStorageString } from '../shared/app-utils';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { getAllBookedProductsTotalPrice, isAnonymousProductBooked } from '../shared/services-with-reducers/products/booking/booking.selectors';
var StepNavigationComponent = /** @class */ (function () {
    function StepNavigationComponent(_store, _el, _customizationService, _helperService) {
        var _this = this;
        this._store = _store;
        this._el = _el;
        this._customizationService = _customizationService;
        this._helperService = _helperService;
        this.totalTicketPrice = 0;
        this.anonymousTicketTaken = false;
        this.subscriptions = new Subscription();
        this.appVersion = getAppVersion();
        this.exhibitionSettings$ = this._store.pipe(select(fromRoot.getExhibitionSettings));
        this.subscriptions.add(this._store
            .select(fromRoot.getOrderedStepsValidityArray)
            .pipe(filter(function (data) { return !!data; }), debounceTime(50))
            .subscribe(function (newSteps) {
            if (!_this.stepList || _this.stepList.length !== newSteps.length) {
                _this.stepList = JSON.parse(JSON.stringify(newSteps));
                return true;
            }
            _this.stepList.forEach(function (step) {
                // mutate the step with new data (this is needed to enable css transition animation)
                newSteps.forEach(function (newStep) {
                    if (newStep.key === step.key) {
                        step.value.visible = newStep.value.visible;
                        step.value.disabled = newStep.value.disabled;
                        step.value.order = newStep.value.order;
                    }
                });
                //reorder updated
                _this.stepList.sort(function (a, b) {
                    return a.value.order - b.value.order;
                });
            });
            _this.refitStepNavigation();
            _this._helperService.stepNavigationRendered();
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getOrderedStepsValidityArray)
            .pipe(filter(function (data) { return !!data; }), debounceTime(500) //the following code may be a bit more delayed as we're only detecting a new version of the application there
        )
            .subscribe(function () {
            var oldAppVersion = getLocalStorageString(AppConstants.appVersionReducer);
            if (!(oldAppVersion && oldAppVersion === _this.appVersion)) {
                //if a new application version is detected show the force reload message to the user:
                _this._helperService.setReloadRequired(true);
            }
        }));
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getSelectedStep), this._store.select(fromRoot.getOrderResponse), this._store.select(isAnonymousProductBooked))
            .pipe(filter(function (data) { return !!data[0]; }))
            .subscribe(function (_a) {
            var stepName = _a[0], orderResponse = _a[1], isAnonymousProductBooked = _a[2];
            _this.anonymousTicketTaken = isAnonymousProductBooked;
            var orderId = '';
            if (orderResponse !== null && orderResponse.szCode && orderResponse.szCode !== null) {
                orderId = orderResponse.szCode.toString();
            }
            else {
                orderId = (Math.random() * 10000000000000).toFixed(0).toString();
            }
            observableCombineLatest(_this._store.select(fromRoot.getDoubleClickScripts), _this._store.select(fromRoot.getGenericScripts))
                .pipe(first(function (data) { return data.every(function (item) { return !!item; }); }))
                .subscribe(function (_a) {
                var doubleClickScripts = _a[0], genericScripts = _a[1];
                _this.refitStepNavigation();
                _this.subscriptions.add(_this._store.select(getAllBookedProductsTotalPrice).subscribe(function (price) { return _this.totalTicketPrice = price; }));
                if (doubleClickScripts.hasOwnProperty(stepName) &&
                    doubleClickScripts[stepName]) {
                    var scriptUrl = doubleClickScripts[stepName];
                    if (_this.isURL(scriptUrl)) {
                        _this._customizationService.addIframe(scriptUrl, 'dblclick-script');
                    }
                }
                if (doubleClickScripts.hasOwnProperty('allpagesheader') &&
                    doubleClickScripts['allpagesheader']) {
                    var scriptUrl = doubleClickScripts['allpagesheader'];
                    if (_this.isURL(scriptUrl)) {
                        _this._customizationService.addIframe(scriptUrl, 'dblclick-script-all-page');
                    }
                }
                if (genericScripts.hasOwnProperty(stepName) &&
                    genericScripts[stepName]) {
                    var script = genericScripts[stepName];
                    try {
                        _this._customizationService.injectTrackingScript(script, 'generic-script', _this.totalTicketPrice.toString(), orderId);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
                if (genericScripts.hasOwnProperty('allpagesheader') &&
                    genericScripts['allpagesheader']) {
                    var script = genericScripts['allpagesheader'];
                    try {
                        _this._customizationService.injectTrackingScript(script, 'generic-script-all-page', null, null);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            });
        }));
    }
    StepNavigationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    StepNavigationComponent.prototype.isURL = function (str) {
        var regexp = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(str);
    };
    StepNavigationComponent.prototype.stepSelected = function (stepKey) {
        this._store.dispatch(new stepsFormsActions.SetSelectedStep(stepKey));
    };
    StepNavigationComponent.prototype.refitStepNavigation = function () {
        var _this = this;
        // in case we are in angular universal
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                if (_this._el.nativeElement.children.length) {
                    var steps_1 = [].slice.call(_this._el.nativeElement.children[0].children);
                    var activeStep = steps_1.forEach(function (step) {
                        if (step.children[0].classList.contains('active-link')) {
                            step.classList.add('active-step');
                            var i = steps_1.findIndex(function (x) { return x == step; });
                            _this.currentStepIndex = i % 2 == 1 ? i + 1 : i;
                            for (var j = 0; j < i; j++) {
                                steps_1[j].children[0].classList.add('notActive');
                            }
                        }
                        else {
                            step.classList.remove('active-step');
                        }
                    });
                }
            }, 50);
        }
    };
    return StepNavigationComponent;
}());
export { StepNavigationComponent };
