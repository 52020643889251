import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@src/app-shared/shared.module';
import { WidgetBannerComponent } from '@widget/widget-components-without-routes';
import { WidgetComponentsWithRoutes, WidgetRoutingModule } from '@widget/widget-routing.module';

@NgModule({
 imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetRoutingModule
  ],
  declarations: [WidgetComponentsWithRoutes, WidgetBannerComponent],
  exports: [WidgetBannerComponent]
})
export class WidgetModule {}
