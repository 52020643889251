import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { WidgetService } from '@shared/services-with-reducers/widget/widget.service';

import {
  EventWidgetListItem,
  WidgetSelectionTariffType,
  WidgetSettingType,
  WidgetSettingTypeKeyname,
  WidgetSettings
} from './widget-models.interface';

import { State, getSelectedExhibitionId } from '@app/app.reducer';
import { AppConstants } from '@shared/app-constants';
import { getLocalStorageObject, setLocalStorageObject } from '@shared/app-utils';
import { WidgetAdminService } from './widget-admin.service';

@Component({
  selector: 'app-widget-admin',
  templateUrl: './widget-admin.component.html',
  styleUrls: ['./widget-admin.component.scss']
})
export class WidgetAdminComponent implements OnInit, OnDestroy {
  @ViewChild('iframe')
  iframe: ElementRef;

  public eventId = null;
  public widgetSelectionTariffTypes$: Observable<WidgetSelectionTariffType[]>;
  public widgetSettings: WidgetSettings;
  public eventWidgets: EventWidgetListItem[];
  public tabs = [
    {
      id: 'ticketsWidget',
      title: 'Tickets'
    },
    {
      id: 'bannerWidget',
      title: 'Banner'
    }
  ];
  public activeTab = getLocalStorageObject(AppConstants.activeWidgetTabReducer);
  public loading: boolean = false;

  private unsubscribe = new Subject();

  constructor(
    private store: Store<State>,
    private widgetService: WidgetService,
    private widgetAdminService: WidgetAdminService
  ) {}

  ngOnInit() {
    this.setCurrentEventId();
    this.getAllWidgets();
    this.widgetSelectionTariffTypes$ = this.widgetAdminService.getWidgetSelectionTariffTypes$();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createNewWidget(settings: { type: WidgetSettingTypeKeyname; settings: WidgetSettingType }) {
    this.loading = true;

    this.widgetService.createWidgetForEvent(this.eventId, settings.settings).subscribe(
      res => {
        this.widgetSettings = {
          ...this.widgetSettings,
          [settings.type]: res
        };

        this.loading = false;
      },
      err => (this.loading = false)
    );
  }

  updateWidget(settings: { type: WidgetSettingTypeKeyname; settings: WidgetSettingType }) {
    if (settings.type !== 'ticketsWidget') {
      this.loading = true;

      this.widgetService.updateWidgetSettings(this.widgetSettings[settings.type].id, settings.settings).subscribe(
        res => {
          this.widgetSettings = {
            ...this.widgetSettings,
            [settings.type]: res
          };

          this.loading = false;
        },
        err => (this.loading = false)
      );
    }
  }

  loadWidgetSettings(eventData: { type: WidgetSettingTypeKeyname; widgetId: string }) {
    const widgetId = Number(eventData.widgetId);

    this.loading = true;

    this.widgetService.getWidgetById(widgetId).subscribe(
      res => {
        this.widgetSettings = { ...this.widgetSettings, [eventData.type]: res };

        this.loading = false;
      },
      err => (this.loading = false)
    );
  }

  getAllWidgets() {
    this.widgetService.getWidgetsForEvent(this.eventId).subscribe((eventWidgets: EventWidgetListItem[]) => {
      this.eventWidgets = eventWidgets;
    });
  }

  setCurrentEventId() {
    this.store
      .pipe(select(getSelectedExhibitionId))
      .pipe(first(eventId => !!eventId))
      .subscribe(eventId => {
        this.eventId = eventId;
      });
  }

  onTabChange(tabName) {
    this.activeTab = tabName;
    setLocalStorageObject(AppConstants.activeWidgetTabReducer, tabName);
  }
}
