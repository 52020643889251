import { Injectable } from '@angular/core';
import { BookingTariff } from '@products/models/booking.model';
import { HolderUuids } from '@products/models/holder.model';
import {
  WorkshopProduct,
  WorkshopProductList,
  WorkshopTariffHolder,
  WorkshopValidationState
} from '@products/models/workshop.model';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WorkshopValidationService {
  private workshopValidationState: WorkshopValidationState = {
    validatedWorkshopTariffHolders: [],
    isWorkshopAvailableSeatsLimitReached: false,
    isWorkshopLimitPerTariffHolderReached: false,
    areWorkshopsOverlapping: false
  };

  constructor() {}

  validateWorkshop(
    workshopTariffHolders: WorkshopTariffHolder[],
    workshopProductList: WorkshopProductList,
    availableSeats: number,
    workshopLimitPerTariffHolder: number,
    enableWorkshopSelectionOverlapping: boolean
  ) {
    this.resetWorkshopValidationState();

    this.validateAvailableSeats(availableSeats);

    if (!this.workshopValidationState.isWorkshopAvailableSeatsLimitReached) {
      this.validateWorkshopsHolders(workshopTariffHolders);
      this.validateWorkshopLimitPerTariffHolder(workshopLimitPerTariffHolder);
      this.validateAreWorkshopsOverlapping(workshopProductList, enableWorkshopSelectionOverlapping);
    }

    return this.workshopValidationState;
  }

  haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
    bookedTariffs: BookingTariff[],
    limitWorkshopPerTicket: number
  ): boolean {
    const assignedWorkshopsGroupedByHolderUuid: { [holderUuid: string]: number } = {};
    let areAllBookedTariffHoldersAssignedToWorkshops = true;

    bookedTariffs.forEach(bookedTariff => {
      if (!areAllBookedTariffHoldersAssignedToWorkshops) {
        return;
      }

      bookedTariff.workshops.forEach(({ holderUuids }) => {
        holderUuids.forEach(holderUuid => {
          if (!assignedWorkshopsGroupedByHolderUuid[holderUuid]) {
            assignedWorkshopsGroupedByHolderUuid[holderUuid] = 1;
          } else {
            assignedWorkshopsGroupedByHolderUuid[holderUuid] += 1;
          }
        });
      });

      const allAssignedWorkshopHolderUuids: HolderUuids = _.keys(assignedWorkshopsGroupedByHolderUuid);
      const assignedWorkshopHoldersFromCurrentBookedTariff: HolderUuids = _.intersection(
        bookedTariff.holderUuids,
        allAssignedWorkshopHolderUuids
      );

      areAllBookedTariffHoldersAssignedToWorkshops =
        assignedWorkshopHoldersFromCurrentBookedTariff.length === bookedTariff.holderUuids.length;
    });

    if (areAllBookedTariffHoldersAssignedToWorkshops) {
      return _.values(assignedWorkshopsGroupedByHolderUuid).every(
        (assignedHolderWorkshopsCount: number) => assignedHolderWorkshopsCount <= limitWorkshopPerTicket
      );
    }

    return false;
  }

  haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
    bookedTariffs: BookingTariff[],
    limitWorkshopPerTicket: number
  ): boolean {
    const assignedZeroPriceTariffWorkshopsGroupedByHolderUuid: { [holderUuid: string]: number } = {};
    let areAllZeroPricedBookedTariffHoldersAssignedToWorkshops = true;

    bookedTariffs.forEach(bookedTariff => {
      if (!areAllZeroPricedBookedTariffHoldersAssignedToWorkshops || bookedTariff.price > 0) {
        return;
      }

      bookedTariff.workshops.forEach(({ holderUuids }) => {
        holderUuids.forEach(holderUuid => {
          if (!assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid]) {
            assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid] = 1;
          } else {
            assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid] += 1;
          }
        });
      });

      const allAssignedZeroPricedTariffWorkshopHolderUuids: HolderUuids = _.keys(
        assignedZeroPriceTariffWorkshopsGroupedByHolderUuid
      );
      if (!allAssignedZeroPricedTariffWorkshopHolderUuids) {
        return true;
      }

      const assignedWorkshopHoldersFromCurrentBookedTariff: HolderUuids = _.intersection(
        bookedTariff.holderUuids,
        allAssignedZeroPricedTariffWorkshopHolderUuids
      );

      areAllZeroPricedBookedTariffHoldersAssignedToWorkshops =
        assignedWorkshopHoldersFromCurrentBookedTariff.length === bookedTariff.holderUuids.length;
    });

    if (areAllZeroPricedBookedTariffHoldersAssignedToWorkshops) {
      return _.values(assignedZeroPriceTariffWorkshopsGroupedByHolderUuid).every(
        (assignedHolderWorkshopsCount: number) => assignedHolderWorkshopsCount <= limitWorkshopPerTicket
      );
    }

    return false;
  }

  private resetWorkshopValidationState() {
    this.workshopValidationState = {
      validatedWorkshopTariffHolders: [],
      isWorkshopAvailableSeatsLimitReached: false,
      isWorkshopLimitPerTariffHolderReached: false,
      areWorkshopsOverlapping: false
    };
  }

  private validateAvailableSeats(availableSeats: number) {
    this.workshopValidationState.isWorkshopAvailableSeatsLimitReached = availableSeats === 0;
  }

  private filterAlreadyAssignedHolders(workshopTariffHolders: WorkshopTariffHolder[]): WorkshopTariffHolder[] {
    return workshopTariffHolders.filter(workshopTariffHolder => !workshopTariffHolder.isHolderAssigned);
  }

  private validateWorkshopsHolders(workshopTariffHolders: WorkshopTariffHolder[]) {
    this.workshopValidationState.validatedWorkshopTariffHolders = this.filterAlreadyAssignedHolders(
      workshopTariffHolders
    );
  }

  private validateWorkshopLimitPerTariffHolder(workshopLimitPerTariffHolder: number) {
    this.workshopValidationState.isWorkshopLimitPerTariffHolderReached = this.workshopValidationState.validatedWorkshopTariffHolders.some(
      workshopTariffHolder => workshopTariffHolder.assignedWorkshops.length >= workshopLimitPerTariffHolder
    );
  }

  private validateAreWorkshopsOverlapping(
    workshopProductList: WorkshopProductList,
    enableWorkshopSelectionOverlapping: boolean
  ) {
    if (enableWorkshopSelectionOverlapping) {
      this.workshopValidationState.areWorkshopsOverlapping = false;
      return;
    }

    this.workshopValidationState.validatedWorkshopTariffHolders.forEach(workshopTariffHolder => {
      if (this.workshopValidationState.areWorkshopsOverlapping) {
        return;
      }

      const selectedTariffHolderWorkshop = workshopProductList.find(
        workshopProduct => workshopProduct.workshopId === workshopTariffHolder.workshopId
      );
      const alreadyAssignedTariffHolderWorkshops = workshopProductList.filter(workshopProduct =>
        workshopTariffHolder.assignedWorkshops.includes(workshopProduct.workshopId)
      );

      this.workshopValidationState.areWorkshopsOverlapping = alreadyAssignedTariffHolderWorkshops.reduce(
        (areWorkshopsOverlapping, alreadyAssignedTariffHolderWorkshop) =>
          areWorkshopsOverlapping ||
          this.areWorkshopsOverleaping(alreadyAssignedTariffHolderWorkshop, selectedTariffHolderWorkshop),
        false
      );
    });
  }

  private areWorkshopsOverleaping(workshopA: WorkshopProduct, workshopB: WorkshopProduct) {
    if (workshopA && workshopB && workshopA !== workshopB) {
      const currentDate = new Date().toDateString();
      const dateWorkshopA = this.getWorkshopFullDate(workshopA.date);
      const dateWorkshopB = this.getWorkshopFullDate(workshopB.date);

      if (dateWorkshopA === dateWorkshopB) {
        const startWorkshopA = new Date(currentDate + ' ' + new Date(workshopA.start).toTimeString());
        const startWorkshopB = new Date(currentDate + ' ' + new Date(workshopB.start).toTimeString());
        const endWorkshopA = new Date(currentDate + ' ' + new Date(workshopA.end).toTimeString());
        const endWorkshopB = new Date(currentDate + ' ' + new Date(workshopB.end).toTimeString());

        const isWorkshopAOverleapingWorkshopB =
          (startWorkshopA >= startWorkshopB && startWorkshopA < endWorkshopB) ||
          (endWorkshopA <= endWorkshopB && endWorkshopA > startWorkshopB);
        const isWorkshopBOverleapingWorkshopA =
          (startWorkshopB >= startWorkshopA && startWorkshopB < endWorkshopA) ||
          (endWorkshopB <= endWorkshopA && endWorkshopB > startWorkshopA);

        if (isWorkshopAOverleapingWorkshopB || isWorkshopBOverleapingWorkshopA) {
          return true;
        }
      }
    }

    return false;
  }

  private getWorkshopFullDate(date: string) {
    const newDate = new Date(date);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return day + '/' + month + '/' + year;
  }
}
