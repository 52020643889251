/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./package-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../shared/directives/numbers/numbers.directive";
import * as i4 from "@angular/common";
import * as i5 from "./package-counter.component";
var styles_PackageCounterComponent = [i0.styles];
var RenderType_PackageCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PackageCounterComponent, data: {} });
export { RenderType_PackageCounterComponent as RenderType_PackageCounterComponent };
function View_PackageCounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "button-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_PackageCounterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "input", [["appNumbersOnly", ""], ["class", "counter-input"], ["type", "number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 2).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("keydown" === en)) {
        var pd_7 = (i1.ɵnov(_v, 7).onKeyDown($event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = ((_co.count = $event) !== false);
        ad = (pd_8 && ad);
    } if (("change" === en)) {
        var pd_9 = (_co.onCountChange($event.target.value) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_bd, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.ɵangular_packages_forms_forms_bd]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 16384, null, 0, i3.NumbersOnlyDirective, [i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = true; var currVal_8 = _co.count; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PackageCounterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "counter-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "counter-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDecrease() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "is-package-loading": 0, "is-workshop-detail-modal-open": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "selector-value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageCounterComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageCounterComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "counter-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onIncrease() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "is-package-loading": 0, "is-workshop-detail-modal-open": 1 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "counter-button"; var currVal_2 = _ck(_v, 4, 0, _co.isPackageLoading, _co.isWorkshopDetailModalOpen); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.isPackageLoading; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.isPackageLoading; _ck(_v, 11, 0, currVal_4); var currVal_6 = "counter-button"; var currVal_7 = _ck(_v, 14, 0, _co.isPackageLoading, _co.isWorkshopDetailModalOpen); _ck(_v, 13, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.count; _ck(_v, 2, 0, currVal_0); var currVal_5 = _co.packageValidationViewModel.packageLimitWarningMessage; _ck(_v, 12, 0, currVal_5); }); }
export function View_PackageCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-package-counter", [], null, null, null, View_PackageCounterComponent_0, RenderType_PackageCounterComponent)), i1.ɵdid(1, 638976, null, 0, i5.PackageCounterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PackageCounterComponentNgFactory = i1.ɵccf("app-package-counter", i5.PackageCounterComponent, View_PackageCounterComponent_Host_0, { packageValidationViewModel: "packageValidationViewModel", isPackageLoading: "isPackageLoading", isWorkshopDetailModalOpen: "isWorkshopDetailModalOpen" }, { updatePackageTypeCountEvent: "updatePackageTypeCountEvent" }, []);
export { PackageCounterComponentNgFactory as PackageCounterComponentNgFactory };
