<ng-container *ngIf="product.productType === ProductType.Tariff">
  <app-tariff-type
    [selectedExhibition]="selectedExhibition"
    [exhibitionSettings]="exhibitionSettings"
    [productSelectionViewModel]="productSelectionViewModel"
    [tariffStatusViewModel]="productsStatusViewModel.tariffStatus"
    [workshopStatusViewModel]="productsStatusViewModel.workshopStatus"
    [bookingStatusViewModel]="productsStatusViewModel.bookingStatus"
    [productGroup]="productGroup"
    [tariffType]="product.tariffType"
  ></app-tariff-type>
</ng-container>
<ng-container *ngIf="product.productType === ProductType.Package">
  <app-package-type
    [selectedExhibition]="selectedExhibition"
    [exhibitionSettings]="exhibitionSettings"
    [productSelectionViewModel]="productSelectionViewModel"
    [tariffStatusViewModel]="productsStatusViewModel.tariffStatus"
    [workshopStatusViewModel]="productsStatusViewModel.workshopStatus"
    [packageStatusViewModel]="productsStatusViewModel.packageStatus"
    [bookingStatusViewModel]="productsStatusViewModel.bookingStatus"
    [productGroup]="productGroup"
    [packageType]="product.packageType"
  ></app-package-type>
</ng-container>
