<div class="counter">
  <div class="counter-wrapper">
    <button
      class="counter-button"
      type="button"
      [ngClass]="{ 'is-package-loading': isPackageLoading, 'is-workshop-detail-modal-open': isWorkshopDetailModalOpen }"
      [disabled]="!count"
      (click)="onDecrease()"
    >
      <span>-</span>
    </button>
    <div class="selector-value">
      <div *ngIf="isPackageLoading" class="button-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <input
        class="counter-input"
        type="number"
        appNumbersOnly
        *ngIf="!isPackageLoading"
        [(ngModel)]="count"
        [disabled]="true"
        (change)="onCountChange($event.target.value)"
      />
    </div>
    <button
      class="counter-button"
      type="button"
      [ngClass]="{ 'is-package-loading': isPackageLoading, 'is-workshop-detail-modal-open': isWorkshopDetailModalOpen }"
      [disabled]="packageValidationViewModel.packageLimitWarningMessage"
      (click)="onIncrease()"
    >
      <span>+</span>
    </button>
  </div>
</div>
