var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { WidgetAdminService } from '../widget-admin.service';
import { WidgetService } from './../../../shared/services-with-reducers/widget/widget.service';
import cloneDeep from 'lodash.clonedeep';
import { pairwise, startWith } from 'rxjs/operators';
var BannerWidgetAdminComponent = /** @class */ (function () {
    function BannerWidgetAdminComponent(fb, widgetAdminService, widgetService) {
        var _this = this;
        this.fb = fb;
        this.widgetAdminService = widgetAdminService;
        this.widgetService = widgetService;
        this.createWidgetSettings = new EventEmitter();
        this.updateWidgetSettings = new EventEmitter();
        this.loadWidget = new EventEmitter();
        this.settingsForm = this.fb.group({
            width: [null],
            height: [null],
            title: [null],
            titleColor: [null],
            description: [null],
            descriptionColor: [null],
            file: [null],
            button: this.fb.group({
                text: ['Buy Tickets'],
                color: [null],
                textColor: [null]
            }),
            preSelectedTicket: [null],
            preSelectedVoucher: [null],
            language: ['en']
        });
        this.iframeEmbedCode = null;
        this.webshopOriginURL = window.location.protocol + "//" + window.location.host;
        this.iframePath = '/widget/banner';
        this.iframeURI = this.webshopOriginURL + this.iframePath;
        this.iframeAttributes = {
            width: '500',
            height: '200'
        };
        this.saveSettingsProcessor = {
            preSelectedTicket: function (ticketUniqueId, settings) {
                var _a = ticketUniqueId ? ticketUniqueId.split('_') : [], _b = _a[0], ticketGroupNr = _b === void 0 ? null : _b, _c = _a[1], ticketPersonNr = _c === void 0 ? null : _c;
                settings['preSelectedTicket'] = { ticketGroupNr: ticketGroupNr, ticketPersonNr: ticketPersonNr };
            }
        };
        this.loadSettingsProcessor = {
            width: function (width) { return (_this.iframeAttributes['width'] = width || '500'); },
            height: function (height) { return (_this.iframeAttributes['height'] = height || '200'); }
        };
        this.loadSettingsToFormProcessor = {
            preSelectedTicket: function (setting, processedSettings) {
                var ticketGroupNr = setting.ticketGroupNr, ticketPersonNr = setting.ticketPersonNr;
                if (ticketGroupNr && ticketPersonNr) {
                    processedSettings['preSelectedTicket'] = ticketGroupNr + "_" + ticketPersonNr;
                }
            }
        };
        this.settingsForm.valueChanges
            .pipe(startWith(null), pairwise())
            .subscribe(function (_a) {
            var prevValue = _a[0], currentValue = _a[1];
            var prevValueString = JSON.stringify(prevValue);
            var currValueString = JSON.stringify(currentValue);
            var baseSettingsString = JSON.stringify(_this.baseSettingsFormValue);
            setTimeout(function () {
                if (prevValueString !== currValueString && _this.settingsForm.touched) {
                    _this.settingsForm.markAsDirty();
                }
                if (baseSettingsString === currValueString) {
                    _this.settingsForm.markAsPristine();
                }
            });
        });
    }
    BannerWidgetAdminComponent.prototype.ngOnInit = function () { };
    BannerWidgetAdminComponent.prototype.ngOnChanges = function (changes) {
        if (changes.widgetSettings && !changes.widgetSettings.isFirstChange()) {
            this.loadSavedSettings();
        }
    };
    BannerWidgetAdminComponent.prototype.readFile = function (event) {
        var background = event.target.files[0];
        var fileControl = this.settingsForm.get('file');
        fileControl.patchValue(background);
        fileControl.updateValueAndValidity();
        fileControl.markAsDirty();
    };
    BannerWidgetAdminComponent.prototype.processWidgetSettingsForSave = function (value) {
        var formValue = value || this.settingsForm.value;
        var clonedFormValue = cloneDeep(formValue);
        if (formValue) {
            this.widgetAdminService.processWidgetSettingsForSave(clonedFormValue, this.saveSettingsProcessor);
            return clonedFormValue;
        }
    };
    BannerWidgetAdminComponent.prototype.createNewWidget = function () {
        var settings = this.processWidgetSettingsForSave();
        this.createWidgetSettings.emit({
            type: 'bannerWidget',
            settings: settings
        });
    };
    BannerWidgetAdminComponent.prototype.updateWidget = function (formValue) {
        var settings = this.processWidgetSettingsForSave(formValue);
        this.updateWidgetSettings.emit({ type: 'bannerWidget', settings: settings });
    };
    BannerWidgetAdminComponent.prototype.loadSavedSettings = function () {
        var _this = this;
        var bannerWidget = this.widgetSettings;
        var processedLoadedSettingsForForm = this.widgetAdminService.processWidgetSettingForAdminForm(bannerWidget, this.loadSettingsToFormProcessor, this.settingsForm);
        this.widgetService.processWidgetSettingsLoad(this.widgetSettings, this.loadSettingsProcessor);
        // reset URI to force reload iframe in next change cycle
        this.iframeURI = null;
        this.settingsForm.reset();
        this.settingsForm.get('language').setValue('en');
        // merge settings with emptied settingsForm.value to prevent "Missing control" errors
        this.settingsForm.patchValue(__assign({}, processedLoadedSettingsForForm));
        // store base loaded settings for comparsion (because it was very important to hide Update button if the settings havent changed)
        this.baseSettingsFormValue = this.settingsForm.value;
        var eventId = bannerWidget.id;
        var iframePathWithEventId = this.iframePath + "/" + eventId;
        // force update iframe in next change
        setTimeout(function () {
            _this.iframeURI = _this.webshopOriginURL + iframePathWithEventId;
            _this.iframeEmbedCode = _this.widgetAdminService.createIframeEmbedCode(_this.iframeURI, _this.iframeAttributes);
        });
    };
    BannerWidgetAdminComponent.prototype.onWidgetChange = function (widgetId) {
        if (widgetId == '-1') {
            this.settingsForm.reset();
            return;
        }
        this.loadWidget.emit({ type: 'bannerWidget', widgetId: widgetId });
    };
    BannerWidgetAdminComponent.prototype.colorPickerChange = function (event, controlPath) {
        if (event == '') {
            event = null;
        }
        this.settingsForm.get(controlPath).setValue(event);
    };
    BannerWidgetAdminComponent.prototype.deselectRadio = function (formControlName, value) {
        var radioFormControl = this.settingsForm.get(formControlName);
        var currentControlValue = radioFormControl && radioFormControl.value;
        if (currentControlValue == value) {
            radioFormControl.setValue(null);
            this.settingsForm.markAsTouched();
        }
        this.settingsForm.get('preSelectedTicket').setValue(value);
    };
    return BannerWidgetAdminComponent;
}());
export { BannerWidgetAdminComponent };
