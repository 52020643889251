<div *ngIf="confirmationWorkshop">
  <div class="name">
    {{ confirmationWorkshop.workshopName | translate | hideTranslation }}
  </div>
  <div class="dates">
    {{ confirmationWorkshop.roomName | translate | hideTranslation }} |
    <span *ngIf="!hideWorkshopDate"> {{ confirmationWorkshop.date | localizedDate: 'MMM d, yyyy' }} | </span>
    {{ confirmationWorkshop.start | localizedDate: 'shortTime' }} -
    {{ confirmationWorkshop.end | localizedDate: 'shortTime' }}
  </div>
</div>
