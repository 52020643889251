import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  State,
  getExhibitionSettings,
  getIsWidget,
  getLocalTime,
  getLoginMode,
  getSelectedExhibition,
  getSelectedExhibitionId,
  getSelfRegistration,
  getTicketLimitPerUserAccount,
  getWidgetSettings,
  isUserLoggedIn,
  notLoggedAndLoginMandatory,
  showPromoCode
} from '@app/app.reducer';
import { LoginOptions } from '@app/login/login.model';
import { LoginService } from '@app/login/login.service';
import { Store, select } from '@ngrx/store';
import {
  ProductListItem,
  ProductSelectionBookingStatusViewModel,
  ProductSelectionPackageStatusViewModel,
  ProductSelectionProductsStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel,
  VoucherProducts,
  isProductGroup,
  isProductTypeTariff
} from '@products/models/product-selection.model';
import { getIsTranslationLoaded } from '@root/src/app/shared/services-with-reducers/translation/translation.selectors';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import {
  getAllBookedPackagePricesGroupedByPackageIndex,
  getAllBookedPackagePricesGroupedByPackageNumber,
  getAllBookedProductsCount,
  getAllBookedProductsTotalPrice,
  getBookedLimitedPromoCodeVoucherTariffsCount,
  getBookedOneTimeVoucherTariffsCount,
  getBookedPromoCodeVoucherTariffsCount,
  isBookedProductsCountValid
} from '@store/products/booking/booking.selectors';
import {
  getAllProductSelectionListProducts,
  getProductSelectionListProducts,
  getVoucherProducts,
  isRedeemedAnonymousVoucherProductInProductSelectionList,
  isRedeemedVoucherProductInProductSelectionList
} from '@store/products/product-selection/product-selection.selectors';
import { getIsPackageLoading, getLastPackageIndex } from '@store/products/status/package/package.selectors';
import {
  getAvailableTariffs,
  getIsTariffLoading,
  getUserAccountTariffLimit
} from '@store/products/status/tariff/tariff.selectors';
import {
  getIsWorkshopDetailModalOpen,
  getIsWorkshopLoading,
  getWorkshopsAvailableSeatsGroupedByWorkshopId
} from '@store/products/status/workshop/workshop.selectors';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectionService implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private helperService: HelperService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getProductSelectionProductsAndExhibitionState$(): Observable<{
    selectedExhibition: ExhibitionModel;
    exhibitionSettings: ExhibitionSettingModel;
    productSelectionListProducts: ProductListItem[];
    voucherProducts: VoucherProducts;
    isUserAccountLimitChecked: boolean;
  }> {
    const checkTicketLimitPerUserAccount$ = combineLatest([
      this.store.pipe(select(getLoginMode)),
      this.store.pipe(select(isUserLoggedIn)),
      this.store.pipe(select(getTicketLimitPerUserAccount)),
      this.store.pipe(select(getUserAccountTariffLimit))
    ]);

    return combineLatest([
      this.store.pipe(select(getSelectedExhibition)),
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getProductSelectionListProducts)),
      this.store.pipe(select(getVoucherProducts)),
      checkTicketLimitPerUserAccount$
    ]).pipe(
      filter(
        ([selectedExhibition, exhibitionSettings, productSelectionListProducts]) =>
          !!selectedExhibition && !!exhibitionSettings && !!productSelectionListProducts
      ),
      map(
        ([
          selectedExhibition,
          exhibitionSettings,
          productSelectionListProducts,
          voucherProducts,
          [loginMode, isUserLoggedIn, ticketLimitPerUserAccount, userAccountTariffLimit]
        ]) => {
          const checkTicketLimitPerUserAccount =
            loginMode === LoginOptions.BeforeTicketSelection && isUserLoggedIn && !!ticketLimitPerUserAccount;
          const isUserAccountLimitChecked =
            !checkTicketLimitPerUserAccount || (checkTicketLimitPerUserAccount && userAccountTariffLimit !== null);

          return {
            selectedExhibition,
            exhibitionSettings,
            productSelectionListProducts,
            voucherProducts,
            isUserAccountLimitChecked
          };
        }
      ),
      takeUntil(this.destroy$)
    );
  }

  getWidgetProductSelectionProductsAndExhibitionState$(): Observable<{
    selectedExhibition: ExhibitionModel;
    exhibitionSettings: ExhibitionSettingModel;
    productSelectionListProducts: ProductListItem[];
    voucherProducts: VoucherProducts;
  }> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibition)),
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getProductSelectionListProducts)),
      this.store.pipe(select(getVoucherProducts)),
      this.store.pipe(select(getWidgetSettings))
    ]).pipe(
      filter(
        ([selectedExhibition, exhibitionSettings, productSelectionListProducts]) =>
          !!selectedExhibition && !!exhibitionSettings && !!productSelectionListProducts
      ),
      map(([selectedExhibition, exhibitionSettings, productSelectionListProducts, voucherProducts, widgetSettings]) => {
        const listOfUniqueIds = widgetSettings.ticketsWidget.tickets.split(',');

        productSelectionListProducts.forEach(productList => {
          if (isProductGroup(productList)) {
            productList.products = productList.products.filter(product => {
              if (isProductTypeTariff(product)) {
                product.tariffType.tariffs = product.tariffType.tariffs.filter(productTariff => {
                  const uniqueId = `${productTariff.ticketTypeId}_${productTariff.ticketPersonTypeId}`;

                  if (listOfUniqueIds.includes(uniqueId)) {
                    return productTariff;
                  }
                });

                return product.tariffType.tariffs.length;
              }
            });
          }
        });

        return {
          selectedExhibition,
          exhibitionSettings,
          productSelectionListProducts,
          voucherProducts
        };
      }),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionViewModel$(): Observable<ProductSelectionViewModel> {
    const productSelectionVoucherState$ = combineLatest([
      this.store.pipe(select(isRedeemedVoucherProductInProductSelectionList)),
      this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
    ]);

    const helpersState$ = combineLatest([
      this.store.pipe(select(getSelfRegistration)),
      this.store.pipe(select(getIsWidget)),
      this.store.pipe(select(getIsTranslationLoaded)),
      this.helperService.isMobile$()
    ]);

    return combineLatest([
      productSelectionVoucherState$,
      helpersState$,
      this.store.pipe(select(notLoggedAndLoginMandatory)),
      this.store.pipe(select(showPromoCode)),
      this.store.pipe(select(getLocalTime)),
      this.loginService.checkAccountVerificationStatus$()
    ]).pipe(
      map(
        ([
          [isRedeemedVoucherProductInProductSelectionList, isRedeemedAnonymousVoucherProductInProductSelectionList],
          [isSelfRegistration, isWidget, isTranslationLoaded, isMobile],
          notLoggedAndLoginMandatory,
          showPromoCode,
          localTime,
          checkAccountVerificationStatus
        ]) => ({
          isRedeemedVoucherProductInProductSelectionList,
          isRedeemedAnonymousVoucherProductInProductSelectionList,
          isSelfRegistration,
          isWidget,
          isMobile,
          notLoggedAndLoginMandatory,
          showPromoCode,
          isTranslationLoaded,
          dateToday: localTime,
          isVerifiedAccountRequired: checkAccountVerificationStatus.isVerifiedAccountRequired,
          isAccountVerified: checkAccountVerificationStatus.isAccountVerified
        })
      ),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionTariffStatusViewModel$(): Observable<ProductSelectionTariffStatusViewModel> {
    return combineLatest([
      this.store.pipe(select(getUserAccountTariffLimit)),
      this.store.pipe(select(getAvailableTariffs)),
      this.store.pipe(select(getIsTariffLoading))
    ]).pipe(
      map(([userAccountTicketLimit, availableTariffs, isTariffLoading]) => ({
        currentUserAccountTicketLimit: userAccountTicketLimit,
        availableTariffs,
        isTariffLoading
      })),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionPackageStatusViewModel$(): Observable<ProductSelectionPackageStatusViewModel> {
    return combineLatest([
      this.store.pipe(select(getLastPackageIndex)),
      this.store.pipe(select(getIsPackageLoading))
    ]).pipe(
      map(([lastPackageIndex, isPackageLoading]) => ({ lastPackageIndex, isPackageLoading })),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionBookingStatusViewModel$(): Observable<ProductSelectionBookingStatusViewModel> {
    const productBookingsStatus$ = combineLatest([
      this.store.pipe(select(getAllBookedProductsCount)),
      this.store.pipe(select(isBookedProductsCountValid)),
      this.store.pipe(select(getAllBookedProductsTotalPrice))
    ]);

    const voucherBookingsStatus$ = combineLatest([
      this.store.pipe(select(getBookedPromoCodeVoucherTariffsCount)),
      this.store.pipe(select(getBookedLimitedPromoCodeVoucherTariffsCount)),
      this.store.pipe(select(getBookedOneTimeVoucherTariffsCount))
    ]);

    const packageBookingStatus$ = combineLatest([
      this.store.pipe(select(getAllBookedPackagePricesGroupedByPackageNumber)),
      this.store.pipe(select(getAllBookedPackagePricesGroupedByPackageIndex))
    ]);

    return combineLatest([productBookingsStatus$, voucherBookingsStatus$, packageBookingStatus$]).pipe(
      map(
        ([
          [bookedProductsCount, isBookedProductsCountValid, bookedProductsTotalPrice],
          [bookedPromoCodeVouchersCount, bookedLimitedPromoCodeVouchersCount, bookedOnetimeVouchersCount],
          [bookedPackagePricesGroupedByPackageNumber, bookedPackagePricesGroupedByPackageIndex]
        ]) => ({
          bookedProductsCount,
          isBookedProductsCountValid,
          bookedProductsTotalPrice,
          bookedPromoCodeVouchersCount,
          bookedLimitedPromoCodeVouchersCount,
          bookedOnetimeVouchersCount,
          bookedPackagePricesGroupedByPackageNumber,
          bookedPackagePricesGroupedByPackageIndex
        })
      ),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionWorkshopStatusViewModel$(): Observable<ProductSelectionWorkshopStatusViewModel> {
    return combineLatest([
      this.store.pipe(select(getWorkshopsAvailableSeatsGroupedByWorkshopId)),
      this.store.pipe(select(getIsWorkshopLoading)),
      this.store.pipe(select(getIsWorkshopDetailModalOpen))
    ]).pipe(
      map(([availableSeats, isWorkshopLoading, isWorkshopDetailModalOpen]) => ({
        isWorkshopLoading,
        availableSeats,
        isWorkshopDetailModalOpen
      })),
      takeUntil(this.destroy$)
    );
  }

  getProductSelectionProductsStatusViewModel$(): Observable<ProductSelectionProductsStatusViewModel> {
    return combineLatest([
      this.getProductSelectionTariffStatusViewModel$(),
      this.getProductSelectionPackageStatusViewModel$(),
      this.getProductSelectionWorkshopStatusViewModel$(),
      this.getProductSelectionBookingStatusViewModel$()
    ]).map(
      ([tariffStatus, packageStatus, workshopStatus, bookingStatus]) => ({
        tariffStatus,
        packageStatus,
        workshopStatus,
        bookingStatus
      }),
      takeUntil(this.destroy$)
    );
  }

  getPreferredProductsAfterInitialProductsLoad$(): Observable<number> {
    return this.route.queryParams.pipe(
      withLatestFrom(
        this.store.pipe(select(getSelectedExhibitionId)),
        this.store.pipe(select(getAllProductSelectionListProducts))
      ),
      filter(
        ([params, exhibitionId, allProductSelectionListProducts]) =>
          !!params.tt && !!exhibitionId && !!allProductSelectionListProducts.length
      ),
      map(([_, exhibitionId]) => exhibitionId),
      takeUntil(this.destroy$)
    );
  }
}
