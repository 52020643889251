import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatest as observableCombineLatest,
  Subject,
  Subscription
} from 'rxjs';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import { LoginService } from '../login/login.service';
import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { SetActiveSlide } from '../shared/services-with-reducers/products/holder/holder.actions';
import { StepsFormsService } from '../shared/services-with-reducers/step-forms/steps-forms.service';
import { WindowSizeService } from '../shared/window-size/window-size.service';

@Component({
  moduleId: module.id,
  selector: 'app-step-navigation-buttons',
  templateUrl: './step-navigation-buttons.component.html',
  styleUrls: ['./step-navigation-buttons.component.scss']
})
export class StepNavigationButtonsComponent implements OnDestroy {
  @Input()
  payment: boolean;
  @Input()
  hideButton: boolean;
  @Input()
  paymentValue: number;
  @Input()
  hasAlternativeButton: string;
  @Input()
  set feedbackChanged(val: boolean) {
    this.feedbackSubject.next();
  }

  public isButtonDisabled: boolean;
  public isContinueAsGuest: boolean;
  public showBackButton: boolean;
  public anyBuyerMessageHidden: boolean;
  public feedbackMessages = [];

  private feedbackSubject = new Subject();
  private subscriptions = new Subscription();
  private emptyInputs: HTMLInputElement[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromRoot.State>,
    private _formService: FormsService,
    private _windowSizeService: WindowSizeService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService,
    private _loginService: LoginService
  ) {
    this.subscriptions.add(
      this._store
        .select(fromRoot.getStepsValidity)
        .pipe(debounceTime(100))
        .subscribe(validations => {
          const pathKey = this._route.snapshot.url[0].path;

          this.isButtonDisabled = !validations[pathKey].valid;

          const activeStepKey = this._route.snapshot.url[0].path;

          const visibleSteps = Object.keys(validations)
            // make sure steps are ordered correctly
            .sort((a, b) => {
              return validations[a].order - validations[b].order;
            })
            // only navigate to visible routes
            .filter(stepKey => {
              return validations[stepKey].visible;
            });
          const indexOfStep = visibleSteps.indexOf(activeStepKey);
          this.showBackButton = !!indexOfStep;
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSelectedStep),
        this._store.select(fromRoot.getStepsValidity),
        this._store.select(fromRoot.isBuyerOnPersonalPageHidden),
        this.feedbackSubject.pipe(startWith(true)),
        this._store.select(fromRoot.isContinueAsGuest),
        this._formService.validationFeedbacksUpdated
      )
        .pipe(
          debounceTime(10),
          filter(data => {
            return !!data[0] && !!data[1];
          })
        )
        .subscribe(data => {
          const [
            stepKeyName,
            stepsValidity,
            isBuyerHidden,
            subj,
            isContinueAsGuest,
            validationFeedbacksUpdated
          ] = data;

          const pathKey = this._route.snapshot.url[0].path;
          this.anyBuyerMessageHidden = false;
          this.isContinueAsGuest = isContinueAsGuest;

          if (stepKeyName.toLowerCase() === pathKey.toLowerCase() && this._formService.inputsValidity.hasOwnProperty(stepKeyName)) {
            this.feedbackMessages = Object.keys(
              this._formService.inputsValidity[stepKeyName]
            )
              .map(key => {
                return {
                  ...this._formService.inputsValidity[stepKeyName][key],
                  key
                };
              })
              .filter(inputMessage => {
                // dont return buyer info messages on personal page if buyer is hidden there
                const isHidden =
                  stepKeyName === 'personal' &&
                  isBuyerHidden &&
                  inputMessage.key.startsWith('buyerinfo_');

                this.anyBuyerMessageHidden =
                  isHidden || this.anyBuyerMessageHidden;
                return !isHidden;
              });

            // instead
            if (this.anyBuyerMessageHidden) {
              let continueFeedbackMessage = {
                label: 'personalize.any.buyer.message.hidden',
                translate: true,
                key: 'anyBuyerMessageHidden'
              };

              this.feedbackMessages = [];

              if (this.isContinueAsGuest) {
                continueFeedbackMessage.label =
                  'personalize.any.buyer.message.guest';
              }

              this.feedbackMessages.unshift(continueFeedbackMessage);
            } else {
              this.feedbackMessages = this.feedbackMessages.filter(message => {
                return message.key !== 'anyBuyerMessageHidden';
              });
            }
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onBackClick(): void {
    this._stepsFormsService.navigateRelativeTo(-1, this._router);
  }

  onContinueClick(event?) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isButtonDisabled) {
      // in case that guest feature form is not filled switch login form tab to registration
      if (this.isContinueAsGuest && this.anyBuyerMessageHidden) {
        this._loginService.setActiveTab('registration');
      }

      // wait in case that tab changes to create DOM Elements
      setTimeout(() => {
        this.fixFirstInvalidInput();
      }, 250);

      return false;
    }

    this.isButtonDisabled = true;

    // wait until the click is registered and all form data saved, then go to next step
    setTimeout(() => {
      this._stepsFormsService.navigateRelativeTo(1, this._router);
    }, 250); // delay 250 ms as form input to store has 100ms debounce on it
  }

  fixFirstInvalidInput() {
    if (!this.isButtonDisabled) {
      if (!this.payment) {
        // if user has to pay on confirmation page there is no continue button... this will prevent the 404 redirect issue
        this.onContinueClick();
      }
    } else {
      this.emptyInputs = (this._helperService.appEl.querySelectorAll(
        'select.ng-invalid, input.ng-invalid, .checkbox-button-row.ng-invalid, .decorated-checkbox.ng-invalid, .ng-custom-invalid'
      ) as any) as HTMLInputElement[];

      const filteredEmptyInputs = [];

      Object.keys(this.emptyInputs).map(element => {
        if (!this.emptyInputs[element].classList.contains('login-input')) {
          filteredEmptyInputs.push(this.emptyInputs[element]);
        }
      });

      if (filteredEmptyInputs && filteredEmptyInputs.length) {
        const firstEmptyInput: HTMLInputElement = filteredEmptyInputs[0];
        const timeToScroll = 0.2;
        const focusTimeout = 1000;

        // dont scroll to hidden fields
        if (firstEmptyInput.offsetParent === null) {
          return;
        }

        this._windowSizeService.scrollToElement(
          firstEmptyInput,
          100,
          0,
          timeToScroll
        );

        setTimeout(() => {
          firstEmptyInput.focus();
        }, timeToScroll * focusTimeout);
      }
    }
  }

  loadNextInvalidTicketHolderForm() {
    if (document.querySelector('.validity-list')) {
      const ticketHolderFormsList = Array.from(
        document.querySelector('.validity-list').children
      );

      const findFirstInvalidTicketHolderForm =
        ticketHolderFormsList.findIndex(
          element => !element.classList.contains('valid')
        );

      if (findFirstInvalidTicketHolderForm > -1) {
        if (this._formService.inputsValidity.hasOwnProperty('personal')) {
          const hasInvalidVisitorQuestionnaire = Object.keys(this._formService.inputsValidity['personal']).some(key => {
            if (key.startsWith('visitorQuestionnaire_')) {
              return true;
            }
          });

          if (hasInvalidVisitorQuestionnaire) {
            //if the current ticket holder has an invalid visitor questionnaire skip navigating to the next invalid ticket holder:
            return;
          }
        }

        this._store.dispatch(new SetActiveSlide(findFirstInvalidTicketHolderForm));
      }
    }
  }
}
