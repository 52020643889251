import { AppConstants } from "./app-constants";
import { BrowserSession } from "./services-with-reducers/helpers/helper.interface";

export function pageRunningInIframe(): boolean {
  let inIframe: boolean = false;

  try {
    inIframe = window.self !== window.top;
  } catch (e) {
    inIframe = false;
  }

  return inIframe;
}

/**
 * Get query parameters from URL
 * @param {String} qs - location.search
 * @returns {Object} - {param: paramValue}
 */
export function getQueryParamsFromLocation(
  qs = window.location.search
): { [key: string]: string } {
  qs = qs.split('+').join(' ');

  let params = {};
  let re = /[?&]?([^=]+)=([^&]*)/g;
  let tokens: RegExpExecArray;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export function updateQueryStringParameter(
  uri: string,
  key: string,
  value: any
) {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';

  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

export function createFormData(
  object: Object,
  form?: FormData,
  namespace?: string
): FormData {
  const formData = form || new FormData();
  for (let property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (
      typeof object[property] === 'object' &&
      !(object[property] instanceof File)
    ) {
      createFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
}

export function highlightDay(day: any, start: string, end: string) {
  const startMonth = new Date(start).getMonth();
  const startDate = new Date(start).getDate();
  const endMonth = new Date(end).getMonth();
  const endDate = new Date(end).getDate();

  if (startMonth === endMonth && day.month === startMonth) {
    return startDate <= day.day && endDate >= day.day;
  } else {
    if (startMonth === day.month) {
      return startDate <= day.day;
    }
    if (endMonth === day.month) {
      return endDate >= day.day;
    }
  }

  return false;
}

/**
 * Gets countdown timestamp
 * @param tariffReleaseInMinutes Time in minutes to add to calculation
 * @param activatedTimestamp Optional (timestamp), if added it will be added to calculation of timestamp, default Date.now()
 * @returns Countdown timestamp in milliseconds
 */
export function getCountdownTimestamp(tariffReleaseInMinutes: number, activatedTimestamp: number = Date.now()): number {
  return tariffReleaseInMinutes * 60 * 1000 + activatedTimestamp;
}

export function getUUID(): string {
  let uuid = '',
    i,
    random;
  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i == 8 || i == 12 || i == 16 || i == 20) {
      uuid += '-';
    }
    uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
}

export function getAppVersion(): string {
  return document.querySelector('#app-version').getAttribute('data-version');
}

export function addMinutesToDate(date: Date, minutes: number) : Date {
  if (!!date) {
    return new Date(date.getTime() + minutes * 60000);
  }

  return date;
}

/**
 * Converts a string in a specified format (or default 'yyyy-mm-dd') to a date in the current timezone.
 * @param strDate
 * @param format
 * @returns
 */
export function convertStringToDate(strDate: string, format: string = 'yyyy-mm-dd') : Date {
  if (!strDate || !format) {
    return null;
  }

  format = format.toLowerCase();
  const separator = format.includes('-') ? '-' : (format.includes('.') ? '.' : (format.includes('/') ? '/' : '.'));
  const splitDate = strDate.split(separator);
  const splitFormat = format.split(separator);

  if (splitDate.length >= 3 && splitFormat.length >= 3) {
    let yearIdx: number = null, monthIdx: number = null, dayIdx: number = null;

    splitFormat.forEach((x, idx) => {
      if (x.startsWith('y')) {
        yearIdx = idx;
      } else if (x.startsWith('m')) {
        monthIdx = idx;
      } else if (x.startsWith('d')) {
        dayIdx = idx;
      }
    });

    if (yearIdx !== null && monthIdx !== null && dayIdx !== null) {
      return new Date(+splitDate[yearIdx], +splitDate[monthIdx] - 1, +splitDate[dayIdx], 0, 0, 0);
    }
  }

  return null;
}

//#region session functions
/**
 * Fetches an existing (but valid) or a new sessionId. All session specific localStorage keys will be prefixed by that sessionId.
 * @returns Existing or new sessionId
 */
export function getOrCreateSessionId(): string {
  //check application version:
  const appVersion = getAppVersion();
  const oldAppVersion = getLocalStorageString(AppConstants.appVersionReducer);

  if (!(oldAppVersion && oldAppVersion === appVersion)) {
    setLocalStorageString(AppConstants.appVersionReducer, appVersion);
    consoleLog('New application version: ' + appVersion);
  }

  let sessionId = getSessionStorageString("sessionId");
  let browserSessions: BrowserSession[] = getLocalStorageObject(AppConstants.browserSessionsReducer);

  if (sessionId) {
    consoleLog("Initial sessionId from session storage: " + sessionId);
  }

  if (browserSessions) {
    //try to find an existing inactive session that corresponds to the sessionId stored in the sessionStorage:
    var validSession = sessionId && browserSessions.find(session => session.sessionId === sessionId && !session.active && session.appVersion === appVersion);

    if (validSession) {
      //and use that sessionId:
      browserSessions[browserSessions.indexOf(validSession)] = {
        sessionId,
        active: true,
        created: new Date(),
        appVersion
      };
    } else {
      //or try to find an existing inactive session:
      var sessionIdx = findNewestInactiveSessionIdx(browserSessions);

      if (sessionIdx !== null) {
        //and use that sessionId:
        sessionId = browserSessions[sessionIdx].sessionId;

        browserSessions[sessionIdx] = {
          sessionId,
          active: true,
          created: new Date(),
          appVersion
        };
      } else {
        //or create a new sessionId (don't reuse the one from the sessionStorage):
        sessionId = getUUID();

        browserSessions = [
          ...browserSessions,
          {
            sessionId: sessionId,
            active: true,
            created: new Date(),
            appVersion
          }
        ];
      }
    }
  } else {
    //create a new sessionId (don't reuse the one from the sessionStorage):
    sessionId = getUUID();

    browserSessions = [
      {
        sessionId: sessionId,
        active: true,
        created: new Date(),
        appVersion
      }
    ];
  }

  setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
  setSessionStorageString("sessionId", sessionId);
  consoleLog("SessionId for this tab: " + sessionId);
  return sessionId;
}

/**
 * Deactivates the current sessionId.
 */
export function deactivateCurrentSession() {
  const appVersion = getAppVersion();
  const sessionId = getSessionStorageString("sessionId");
  let browserSessions: BrowserSession[] = getLocalStorageObject(AppConstants.browserSessionsReducer);

  if (sessionId && browserSessions) {
    var sessionIdx = browserSessions.findIndex(session => session.sessionId === sessionId && session.active);

    if (sessionIdx > -1) {
      browserSessions[sessionIdx] = {
        sessionId,
        active: false,
        created: new Date(),
        appVersion
      };

      setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
    }
  }
}

/**
 * Deletes all old, unused sessionIds from the localStorage.
 */
export function deleteInactiveSessions() {
  let browserSessions: BrowserSession[] = getLocalStorageObject(AppConstants.browserSessionsReducer);
  const appVersion = getAppVersion();

  if (browserSessions) {
    let deleted = false;

    for (let idx: number = browserSessions.length - 1; idx >= 0; idx--) {
      const session = browserSessions[idx];

      if ((!session.active && (new Date(session.created) < addMinutesToDate(new Date(), -120) || session.appVersion < appVersion)) || new Date(session.created) < addMinutesToDate(new Date(), -180)) {
        consoleLog(`Removing inactive session: ${session.sessionId}`);
        browserSessions.splice(idx, 1);
        removeLocalStorageItemsForSession(session.sessionId);
        deleted = true;
      }
    }

    if (deleted) {
      setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
    }
  }

  //delete old localStorage keys without sessionId prefixes:
  if (localStorage.getItem(AppConstants.ticketsReducer)) {
    for (let key in localStorage) {
      if (AppConstants.allSyncedSessionReducers.includes(key)) {
        localStorage.removeItem(key);
      }
    };
  }

  //delete localStorage keys with sessionId prefixes but without a valid session:
  const sessionIdLength = getUUID().length;

  for (let key in localStorage) {
    if (key.includes('_')) {
      const sessionId = key.split('_')[0];

      if (sessionId && sessionId.length === sessionIdLength && !browserSessions.find(session => session.sessionId === sessionId)) {
        consoleLog(`Removing invalid session: ${sessionId}`);
        removeLocalStorageItemsForSession(sessionId);
      }
    }
  }
}

function findNewestInactiveSessionIdx(browserSessions): number {
  var sessionIdx: number = null;
  var date: Date = null;
  const appVersion = getAppVersion();

  browserSessions.forEach((session, idx) => {
    if (!session.active && session.appVersion === appVersion && (!date || new Date(session.created) > date)) {
      sessionIdx = idx;
      date = new Date(session.created);
    }
  });

  return sessionIdx;
}
//#endregion

//#region localStorage functions
/**
 * Gets a localStorage object by the given key. If the localStorage value is not null it will be parsed into an object.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without the sessionId prefix
 * @returns Parsed localStorage object
 */
export function getLocalStorageObject(key: string): any {
  const sessionPrefix = getLocalStorageSessionPrefix(key);
  const item = localStorage.getItem(sessionPrefix + key);
  return item && JSON.parse(item);
}

/**
 * Gets a localStorage string by the given key. The localStorage value WILL NOT be parsed into an object.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @returns Unparsed localStorage string value
 */
export function getLocalStorageString(key: string): string {
  const sessionPrefix = getLocalStorageSessionPrefix(key);
  return localStorage.getItem(sessionPrefix + key);
}

/**
 * Sets a localStorage object by the given key and object value. The value object will be serialized (stringified) into a string.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @param value Parsed object to be set
 */
export function setLocalStorageObject(key: string, value: any) {
  const sessionPrefix = getLocalStorageSessionPrefix(key);
  localStorage.setItem(sessionPrefix + key, value && JSON.stringify(value));
}

/**
 * Sets a localStorage string by the given key and serialized string value. The value string WILL NOT be serialized (stringified).
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @param value String value to be set
 */
export function setLocalStorageString(key: string, value: string) {
  const sessionPrefix = getLocalStorageSessionPrefix(key);
  localStorage.setItem(sessionPrefix + key, value);
}

/**
 * Removes a localStorage item by the given key.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 */
export function removeLocalStorageItem(key: string) {
  const sessionPrefix = getLocalStorageSessionPrefix(key);
  localStorage.removeItem(sessionPrefix + key);
}

/**
 * Removes all session related localStorage keys dedicated to the provided sessionId.
 * @param sessionId
 * @returns -
 */
export function removeLocalStorageItemsForSession(sessionId: string) {
  if (!sessionId) {
    return;
  }

  for (let key in localStorage) {
    if (key.startsWith(`${sessionId}_`)) {
      localStorage.removeItem(key);
    }
  };
}

/**
 * Removes all localStorage keys.
 */
export function clearLocalStorage() {
  localStorage.clear();
}

function getLocalStorageSessionPrefix(key: string): string {
  let sessionPrefix = "";
  const sessionId =
    (AppConstants.allSyncedSessionReducers.includes(key) || AppConstants.syncedUserSessionReducers.includes(key) || AppConstants.syncedAdminSessionReducers.includes(key))
      && getSessionStorageString("sessionId");

  if (sessionId) {
    sessionPrefix = `${sessionId}_`;
  }

  return sessionPrefix;
}
//#endregion

//#region sessionStorage functions
export function getSessionStorageObject(key: string): any {
  const item = sessionStorage.getItem(key);
  return item && JSON.parse(item);
}

export function getSessionStorageString(key: string): string {
  return sessionStorage.getItem(key);
}

export function setSessionStorageObject(key: string, value: any) {
  sessionStorage.setItem(key, value && JSON.stringify(value));
}

export function setSessionStorageString(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function removeSessionStorageItem(key: string) {
  sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
  sessionStorage.clear();
}
//#endregion

//#region consoleLog
export enum ConsoleLogLevel {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Debug = 'Debug'
}

/**
 * Logs a message in the console log under a defined log level or 'info' if no log level has been chosen.
 * The original message will be prefixed by the date time in UTC and the defined log level,
 * e.g. [2021-02-15T11:41:06.944Z] INFO: Releasing contingent tickets for uuid: 353224ac-1f58-433a-a6c2-22c90ca6bceb
 * @param message The message to be logged
 * @param logLevel The log level under which the message should be logged (default: info)
 */
export function consoleLog(message: string, logLevel: ConsoleLogLevel = ConsoleLogLevel.Info) {
  const currentTime = new Date().toISOString();
  const currentMessage = `[${currentTime}] ${logLevel.toUpperCase()}: ${message}`;

  switch (logLevel) {
    case ConsoleLogLevel.Error:
      console.error(currentMessage);
      break;

    case ConsoleLogLevel.Warning:
      console.warn(currentMessage);
      break;

    case ConsoleLogLevel.Info:
      console.info(currentMessage);
      break;

    case ConsoleLogLevel.Debug:
      console.debug(currentMessage);
      break;

    default:
      console.log(currentMessage);
      break;
  }
}
//#endregion
