import { getAllDepartments, getAllOccupationalGroups, getAllProfessions, getAllTitles, getDepartments, getExhibitionSettings, getOccupationalGroups, getProfessions, getTitles } from '@app/app.reducer';
import { GetAllCountriesAction } from '@store/helpers/helper.actions';
import { SetTicketHolderInputs } from '@store/products/holder/holder.actions';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HolderService } from '@products/services/holder.service';
import { FormsService } from '@shared/forms/forms.service';
import { HelperService } from '@store/helpers/helper.service';
import { getTicketHolder, getTicketHolderInputs } from '@store/products/holder/holder.selectors';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { prepareTicketHolderDownloadData, prepareTicketHolderPersonalizationData } from './form-inputs.ticket-holder-download';
var DownloadTicketHolderProfileEditComponent = /** @class */ (function () {
    function DownloadTicketHolderProfileEditComponent(store, formsService, helperService, translateService, holderService) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.helperService = helperService;
        this.translateService = translateService;
        this.holderService = holderService;
        this.isValid = new EventEmitter();
        this.actionName = ['ticket-holder-download-data'];
        this.loaded = false;
        this.subscriptions = new Subscription();
        this.inputsChanged = function (inputs, rerender) {
            _this.inputs = _this.formsService.updateInputs(_this.inputs, inputs);
            _this.store.dispatch(new SetTicketHolderInputs(_this.inputs));
            _this.isValid.emit({
                formName: 'ticketholder',
                valid: !_this.form.invalid,
                inputs: _this.inputs,
                form: _this.form
            });
            if (rerender) {
                _this.form = _this.formsService.toFormGroup(inputs, _this.actionName);
            }
        };
        this.store.dispatch(new GetAllCountriesAction());
    }
    DownloadTicketHolderProfileEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        observableCombineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getTicketHolder)),
            this.store.pipe(select(getTitles)),
            this.store.pipe(select(getAllTitles)),
            this.store.pipe(select(getProfessions)),
            this.store.pipe(select(getAllProfessions)),
            this.store.pipe(select(getDepartments)),
            this.store.pipe(select(getAllDepartments)),
            this.store.pipe(select(getOccupationalGroups)),
            this.store.pipe(select(getAllOccupationalGroups)),
        ])
            .pipe(first(function (_a) {
            var settings = _a[0], ticketHolder = _a[1], titles = _a[2], allTitles = _a[3], professions = _a[4], allProfessions = _a[5], departments = _a[6], allDepartments = _a[7], occupationalGroups = _a[8], allOccupationalGroups = _a[9];
            return !!titles
                && !!allTitles
                && !!professions
                && !!allProfessions
                && !!departments
                && !!allDepartments
                && !!occupationalGroups
                && !!allOccupationalGroups;
        }))
            .subscribe(function (_a) {
            var settings = _a[0], ticketHolder = _a[1], titles = _a[2], allTitles = _a[3], professions = _a[4], allProfessions = _a[5], departments = _a[6], allDepartments = _a[7], occupationalGroups = _a[8], allOccupationalGroups = _a[9];
            if (!_this.ticket) {
                var $inputs = prepareTicketHolderDownloadData(settings, ticketHolder, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups, _this.translateService);
                $inputs.subscribe(function (inputs) {
                    _this.store.dispatch(new SetTicketHolderInputs(inputs));
                    _this.loaded = true;
                });
            }
            else {
                _this.subscriptions.add(_this.holderService.getVisitorFieldsForPersonalization(_this.ticket.id).subscribe(function (result) {
                    var $inputs = prepareTicketHolderPersonalizationData(result.fieldSettings, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups, _this.translateService);
                    $inputs.subscribe(function (inputs) {
                        _this.form = _this.formsService.toFormGroup(inputs, _this.actionName);
                        // check the validity on first time and set it
                        var validationCallback = function () {
                            _this.isValid.emit({
                                formName: 'ticketholder',
                                valid: !_this.form.invalid,
                                inputs: _this.inputs,
                                form: _this.form
                            });
                        };
                        _this.helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback);
                        _this.store.dispatch(new SetTicketHolderInputs(inputs));
                        _this.loaded = true;
                    });
                }));
            }
        });
        this.subscriptions.add(this.store
            .pipe(select(getTicketHolderInputs))
            .subscribe(function (inputs) {
            _this.inputs = inputs;
            _this.form = _this.formsService.toFormGroup(inputs, _this.actionName);
            // check the validity on first time and set it
            var validationCallback = function () {
                _this.isValid.emit({
                    formName: 'ticketholder',
                    valid: !_this.form.invalid,
                    inputs: _this.inputs,
                    form: _this.form
                });
            };
            _this.helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback);
        }));
    };
    DownloadTicketHolderProfileEditComponent.prototype.cancelProfileEdit = function () {
        this.ticket.expanded = false;
    };
    DownloadTicketHolderProfileEditComponent.prototype.sumbitProfileUpdate = function () {
        var _this = this;
        var data = { ticketId: this.ticket.id };
        for (var field in this.form.controls) {
            if (field === 'dateOfBirth' && !!this.form.get(field).value) {
                this.form.controls[field].setValue(this.helperService.getUTCdate(this.form.get(field).value));
            }
            var control = this.form.get(field);
            data[field] = control.value;
            if (typeof (control.value) === 'string') {
                var value = this.helperService.sanitizeString(control.value);
                if (value !== control.value) {
                    data[field] = value;
                }
            }
        }
        this.subscriptions.add(this.holderService.postVisitorFieldsFromPersonalization(this.ticket.id, data).subscribe(function () {
            _this.ticket.expanded = false;
            _this.ticket.firstName = _this.form.get('firstName').value;
            _this.ticket.lastName = _this.form.get('lastName').value;
            _this.ticket.email = _this.form.get('email').value;
        }));
    };
    DownloadTicketHolderProfileEditComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return DownloadTicketHolderProfileEditComponent;
}());
export { DownloadTicketHolderProfileEditComponent };
