import { BookingProductType } from '@products/models/booking.model';
import { TariffClassification } from '@products/models/tariff.model';

export enum VoucherWarningMessage {
  VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING = 'ticket-selection.regular-ticket-after-anonymous-ticket-message',
  VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING = 'ticket-selection.anonymous-priced-ticket-message'
}

export enum VoucherType {
  LimiterPromoCode = 'limitedPromoCode',
  PromoCode = 'promoCode',
  OneTimeVoucher = 'oneTimeVoucher'
}

export type VoucherCode = string;

export interface VoucherSponsor {
  name: string;
  logo: string;
  link: string;
  eventBanner?: string;
  sponsorBanner?: string;
  styles?: {
    BaseThemeColor: string;
    BaseTxColor: string;
    ButtonBgColor: string;
    ButtonTxColor: string;
    AreaBlockBgActive: string;
  };
}

export interface VoucherContingentState {
  hasDaySellLimit: boolean;
  durationInDays: number;
  validFrom: string;
  validTill: string;
  shouldCalendarBeDisplayed: boolean;
}

export interface VoucherTypeState {
  initialNumberOfAvailableTariffs: number;
  isMandatory: boolean;
  isAnonymous: boolean;
  isAnonymousWithPrice: boolean;
}

export interface VoucherStateViewModel {
  maxTicketLimit: number;
  limitPromoCodes: number;
  limitLimitedPromoCodes: number;
  ticketLimitPerUserAccount: number;
  currentUserAccountTicketLimit: number;
  isVoucherIncludedPerUserAccountLimit: boolean;
  voucherCode: VoucherCode;
  isLoading: boolean;
  isSelfRegistration: boolean;
  barcode: string;
  voucherTypingActive: boolean;
  ticketLimitWarning: string;
  bookedProductsCount: number;
  isRedeemedAnonymousVoucherProductInProductSelectionList: boolean;
  isCurrentVoucherTypeMandatory: boolean;
  isCurrentVoucherTypeAnonymous: boolean;
  voucherWarning: string;
  invalidVoucherWarningStatus: string;
}

export interface VoucherDetail {
  eventId: number;
  ticketTypeId: number;
  ticketPersonTypeId: number;
  ticketPersonId: number;
  voucherCode: VoucherCode;
  voucherType: VoucherType;
}

export interface GetVoucherDetail {
  eventId: number;
  voucherCode: VoucherCode;
}

/*******************
 * VOUCHER RESPONSE
 *******************/
export interface VoucherDetailResponse {
  groupId: number;
  ticketPersonId: number;
  ticketTypeId: number;
  voucherCode: VoucherCode;
  voucherType: VoucherType;
}

export interface RedeemVoucherResponse {
  allowedWorkshops: number[];
  allowedWorkshopsFull: boolean;
  availableTickets: number;
  classification: TariffClassification;
  groupId: number;
  groupName: string;
  info: string;
  infoExpanded: string;
  price: number;
  quantity: number;
  releasedInMinutes: number;
  sponsors: VoucherSponsor[];
  ticketCount: number;
  ticketLimit?: number;
  ticketName: string;
  ticketPersonId: number;
  ticketTypeId: number;
  ticketTypeOrder: number;
  voucherCode: VoucherCode;
  voucherType: VoucherType;
}

export interface RedeemedVoucher extends RedeemVoucherResponse {
  isAnonymous: boolean;
}

/******************
 * ACTION PAYLOADS
 ******************/
export interface BaseVoucher {
  eventId: number;
  orderUuid: string;
  voucherCode: VoucherCode;
  countryCode: string;
}

export interface RedeemVoucher extends BaseVoucher {
  isAnonymous: boolean;
  totalTicketCount: number;
}

export interface ReleaseVoucher extends BaseVoucher {
  ticketPersonId: number;
  remainingTicketCount: number;
}

export interface RemoveVoucher {
  eventId: number;
  orderUuid: string;
  ticketPersonId: number;
  voucherCode: VoucherCode;
}

export interface RemoveVoucherTariffBooking {
  ticketPersonId: number;
  voucherCode: VoucherCode;
}

export interface SetVoucherPayload {
  redeemVoucher: RedeemVoucher;
  releaseVouchers: ReleaseVoucher[];
  removeProductBookings: BookingProductType[];
}
