var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { GetMenu, SetMenu } from '@store/additional-services/additional-services.actions';
import { getMenuList } from '@store/additional-services/additional-services.selectors';
import _ from 'lodash';
import { first } from 'rxjs/operators';
var WebShopMenuComponent = /** @class */ (function () {
    function WebShopMenuComponent(store) {
        var _this = this;
        this.store = store;
        this.store
            .pipe(select(getMenuList), first(function (data) {
            // if there are no data for this exhibition yet, load them
            // only go for the first one with data
            if (data.length === 0) {
                _this.loadMenuViaApi();
            }
            return data.length > 0;
        }))
            .subscribe(function (menuListData) {
            _this.menuListForDisplay = menuListData;
            _this.menuListToSave = _.cloneDeep(menuListData);
        });
    }
    WebShopMenuComponent.prototype.loadMenuViaApi = function () {
        var _this = this;
        this.store
            .pipe(select(getSelectedExhibitionId), first())
            .subscribe(function (eventId) {
            _this.store.dispatch(new GetMenu(eventId));
        });
    };
    WebShopMenuComponent.prototype.onMenuSelection = function (menu, item) {
        this.menuListToSave = this.menuListToSave.map(function (saveMenu) {
            var id = saveMenu.id;
            if (item.id === id) {
                return __assign({}, saveMenu, { selectedMenu: menu.id });
            }
            else {
                return saveMenu;
            }
        });
        this.store.dispatch(new SetMenu(this.menuListToSave));
    };
    return WebShopMenuComponent;
}());
export { WebShopMenuComponent };
