import { Injectable } from '@angular/core';
import { State } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LoadProductService } from '@products/services/load-product.service';
import { ActionTypes as BookingActionTypes, SetPackageInBookingList } from '@store/products/booking/booking.actions';
import {
  ActionTypes as ProductSelectionActionTypes,
  SetInitialProductSelectionList,
  SetPreferredProductSelectionList
} from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes, SetPackageBooking } from '@store/products/product.actions';
import {
  SetInitialPackageIndexStatus,
  SetIsPackageLoadingStatus,
  SetPackageIndexStatus
} from '@store/products/status/package/package.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PackageStatusEffect {
  @Effect()
  setInitialPackageIndexStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SetInitialProductSelectionList | SetPreferredProductSelectionList>(
      ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST,
      ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST
    ),
    map(() => {
      const latestAddedInitialPackageIndex = this.loadProductService.getAndResetLastAddedInitialPackageIndex();
      return new SetInitialPackageIndexStatus(latestAddedInitialPackageIndex);
    })
  );

  @Effect()
  setPackageIndexStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SetPackageInBookingList>(BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST),
    map(({ payload }) => {
      return new SetPackageIndexStatus(payload);
    })
  );

  @Effect()
  isPackageLoadingStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SetPackageBooking | SetPackageInBookingList>(
      ProductActionTypes.SET_PACKAGE_BOOKING,
      ProductActionTypes.REMOVE_PACKAGE_BOOKING,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST
    ),
    map(action => {
      const productActionTypes: string[] = [
        ProductActionTypes.SET_PACKAGE_BOOKING,
        ProductActionTypes.REMOVE_PACKAGE_BOOKING
      ];

      const isPackageLoading = productActionTypes.includes(action.type);

      return new SetIsPackageLoadingStatus(isPackageLoading);
    })
  );

  constructor(private actions$: Actions, private store: Store<State>, private loadProductService: LoadProductService) {}
}
