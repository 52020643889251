var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { ActionTypes as WorkshopStatusActionTypes } from '@store/products/status/workshop/workshop.actions';
export var initialState = {
    availableSeats: {},
    assignedHolders: {},
    isWorkshopLoading: false,
    isWorkshopDetailModalOpen: false
};
export var workshopStatusReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case WorkshopStatusActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_STATUS: {
            var availableSeats = action.payload;
            return __assign({}, state, { availableSeats: __assign({}, state.availableSeats, availableSeats) });
        }
        case WorkshopStatusActionTypes.SET_WORKSHOP_ASSIGNED_HOLDERS_STATUS: {
            var assignedHolders = action.payload;
            return __assign({}, state, { assignedHolders: __assign({}, state.assignedHolders, assignedHolders) });
        }
        case WorkshopStatusActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_AND_ASSIGNED_HOLDERS_STATUS: {
            var workshopSeatsAndHolders = action.payload;
            if (!workshopSeatsAndHolders) {
                return state;
            }
            var assignedHolders = workshopSeatsAndHolders.assignedHolders, availableSeats = workshopSeatsAndHolders.availableSeats;
            return __assign({}, state, { availableSeats: __assign({}, state.availableSeats, availableSeats), assignedHolders: __assign({}, state.assignedHolders, assignedHolders) });
        }
        case WorkshopStatusActionTypes.SET_IS_WORKSHOP_LOADING_STATUS: {
            var isWorkshopLoading = action.payload;
            return __assign({}, state, { isWorkshopLoading: isWorkshopLoading });
        }
        case WorkshopStatusActionTypes.SET_INITIAL_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
            return __assign({}, state, { isWorkshopDetailModalOpen: false });
        }
        case WorkshopStatusActionTypes.SET_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
            var isWorkshopDetailModalOpen = action.payload;
            return __assign({}, state, { isWorkshopDetailModalOpen: isWorkshopDetailModalOpen });
        }
        case WorkshopStatusActionTypes.TOGGLE_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS: {
            return __assign({}, state, { isWorkshopDetailModalOpen: !state.isWorkshopDetailModalOpen });
        }
        case WorkshopStatusActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
