import { createSelector } from '@ngrx/store';
import { AvailableTariffs, TariffStatusState } from '@products/models/tariff-status.model';
import { getProductStatusState } from '@store/products/product.selectors';

/**
 * Get tariff status
 * @description Get status tariff object
 * @param state
 * @returns Tariff status object
 */
export const getTariffStatus = createSelector(
  getProductStatusState,
  (productStatusState): TariffStatusState => productStatusState.tariff
);

/**
 * Get user account tariff limit
 * @description Get status of current user account tariff limit
 * @returns Number
 */
export const getUserAccountTariffLimit = createSelector(
  getTariffStatus,
  (tariffStatus): number => tariffStatus.userAccountTicketLimit
);

/**
 * Get available tariffs
 * @description Get available tariffs count by ticketPersonId
 * @return Available tariffs object
 */
export const getAvailableTariffs = createSelector(
  getTariffStatus,
  (tariffStatus): AvailableTariffs => tariffStatus.availableTariffs
);

/**
 * Get available tariffs by ticket person id
 * @description Get available tariffs count by ticketPersonId
 * @return Number
 */
export const getAvailableTariffsByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getTariffStatus,
    (tariffStatus): number => tariffStatus.availableTariffs[ticketPersonId]
  );

/**
 * Get is tariff loading
 * @description Get status if tariff is loading
 * @returns Boolean
 */
export const getIsTariffLoading = createSelector(
  getTariffStatus,
  (tariffStatus): boolean => tariffStatus.isTariffLoading
);

/**
 * Get is create contingent reservation loading
 * @description Get status if is creating contingent reservations
 * @returns Boolean
 */
export const getIsCreateContingentReservationLoading = createSelector(
  getTariffStatus,
  (tariffStatus): boolean => tariffStatus.isCreateContingentReservationLoading
);
