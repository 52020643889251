<div class="page-padding-wrapper">
  <div *ngIf="!userProfile">
    <div
      class="login-message"
      [innerHTML]="'legitimation.login-message' | translate"
      data-translation-includes-children
      data-translation="legitimation.login-message"
      *ngIf="!('legitimation.login-message' | translate | isHiddenTranslation)"
    ></div>
    <app-login class="login-body"></app-login>
  </div>

  <!-- Case for email is because they can import user in admin without email address... -->
  <div *ngIf="showProfileForm" class="section-body">
    <app-info-block
      [infoText]="!userProfile.email ? 'legitimation.missing.email' : 'legitimation.missing.profile-info'"
    ></app-info-block>

    <app-edit-account-form
      *ngIf="userProfile.email"
      (isProfileUpdated)="profileUpdated($event)"
      [formType]="'edit-account'"
    ></app-edit-account-form>
  </div>

  <div *ngIf="!showProfileForm">
    <div *ngIf="userProfile">
      <div *ngIf="legitimationStatus === LegitimationStatus.InProgress">
        <div class="basic-header black">
          <h2 class="basic-title" data-translation="legitimation.pending.title">
            {{ 'legitimation.pending.title' | translate | hideTranslation }}
          </h2>
          <span [innerHTML]="'legitimation.pending.subtitle' | translate"></span>
          <div class="legitimation-pending-buttons">
            <button
              *ngIf="!legitimationForNewAccount || doTariffsNeedLegitimation"
              class="button "
              (click)="onBackClick($event)"
            >
              <span data-translation="step-navigation.back-button">{{
                'step-navigation.back-button' | translate | hideTranslation
              }}</span>
            </button>
            <button class="button " (click)="goToEvents($event)">
              <span data-translation="'navigation.back-to-event'">{{
                'navigation.back-to-event' | translate | hideTranslation
              }}</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="legitimationStatus === LegitimationStatus.New || legitimationStatus === LegitimationStatus.Rejected">
        <div class="basic-header black">
          <h2 class="basic-title" data-translation="legitimation.title">
            {{ 'legitimation.title' | translate | hideTranslation }}
          </h2>
          <span
            data-translation="legitimation.subtitle"
            data-translation-includes-children
            [innerHTML]="'legitimation.subtitle' | translate"
          ></span>
        </div>

        <div class="legitimation-body" [ngClass]="{ 'ng-custom-invalid': isButtonDisabled }">
          <div class="row">
            <div class="col-lg-12 legitimation-option-box" *ngIf="eventSetting.isLegitimationWithFileAllowed">
              <div class="legitimation-option recomended-option">
                <label class="decorated-radio-button" (click)="selectOption('file')">
                  <input type="radio" name="legitimation" value="file" [checked]="selectedOption === 'file'" />
                  <span class="radio-button"></span>
                  <p-fileUpload
                    #fileupload
                    accept="{{ allowedFileTypes }}"
                    [url]="customUrl"
                    (onClear)="onClear($event)"
                    (onSelect)="onFilesSelected($event, fileupload)"
                    (onBeforeUpload)="beforeUpload()"
                    (onUpload)="uploadLegitimationDone($event)"
                    (onError)="uploadLegitimationError($event)"
                    customUpload="true"
                    (uploadHandler)="myUploader($event, fileupload)"
                    [attr.data-translation]="'legitimation.upload.choose-button'"
                    [chooseLabel]="'legitimation.upload.choose-button' | translate"
                    [previewWidth]="150"
                    maxFileSize="{{ maxFileUploadSize }}"
                    [multiple]="true"
                    class="legitimation-upload-wrapper"
                    [disabled]="isUploading"
                    [invalidFileSizeMessageSummary]="'upload.error.invalidFileSizeMessageSummary' | translate"
                    [invalidFileSizeMessageDetail]="'upload.error.invalidFileSizeMessageDetail' | translate"
                    [invalidFileTypeMessageSummary]="'upload.error.invalidFileTypeMessageSummary' | translate"
                    [invalidFileTypeMessageDetail]="'upload.error.invalidFileTypeMessageDetail' | translate"
                  >
                    <ng-template let-file let-i="index" pTemplate="file" *ngIf="!isUploading">
                      <div class="file-row" *ngIf="!isUploading">
                        <div class="preview-img-wrap">
                          <img class="upload-img-preview" *ngIf="file.objectURL" [src]="file.objectURL" alt="" />
                          <svg class="icon icon-pdf" *ngIf="!file.objectURL">
                            <use xlink:href="assets/defs.svg#icon-file-pdf"></use>
                          </svg>
                        </div>
                        <div class="file-info-wrap">
                          <div class="file-type-select-wrap input-wrapper">
                            <select name="" id="" (change)="selectTypeChange($event, i)">
                              <option value="corporateBookExtract" data-translation="legitimation.select-type.trade"
                                >{{ 'legitimation.select-type.trade' | translate | hideTranslation }}
                              </option>
                              <option value="commercialRegister" data-translation="legitimation.select-type.commercial"
                                >{{ 'legitimation.select-type.commercial' | translate | hideTranslation }}
                              </option>
                            </select>
                          </div>
                          <div class="file-info">
                            <span class="file-text">
                              {{ file.name }} -
                              {{ (file.size / binaryConversionConstant).toFixed(2) }}
                            </span>
                            <svg class="icon icon-cross" (click)="remove(fileupload, $event, i)">
                              <use xlink:href="assets/defs.svg#icon-cross"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="content">
                      <app-loader *ngIf="isUploading"></app-loader>
                      <div *ngIf="!isUploading" class="upload-area" data-translation="legitimation.upload-area">
                        {{ 'legitimation.upload-area' | translate | hideTranslation }}
                      </div>
                    </ng-template>
                  </p-fileUpload>
                </label>
                <app-info-block
                  *ngIf="eventSetting.isLegitimationWithFileAllowed"
                  [infoText]="'upload.file-info'"
                  [translateVariables]="{ allowedFileFormats: allowedFileTypes, fileMaxSize: convertBytes() }"
                ></app-info-block>
                <div class="upload-bar" *ngIf="fileupload.files.length && selectedOption === 'file'">
                  <button
                    (click)="triggerUpload(fileupload)"
                    class="button"
                    data-translation="legitimation.upload.upload-button"
                  >
                    <span>{{ 'legitimation.upload.upload-button' | translate | hideTranslation }}</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-12 or-hr" *ngIf="eventSetting.isLegitimationWithFileAllowed">
              <span class="line"></span>
              <span data-translation="legitimation.line-text"
                >{{ 'legitimation.line-text' | translate | uppercase }}
              </span>
              <span class="line"></span>
            </div>

            <div class="col-lg-12 legitimation-option-box" *ngIf="eventSetting.isLegitimationWithUrlAllowed">
              <div class="legitimation-option">
                <label class="decorated-radio-button" (click)="selectOption('url')">
                  <input type="radio" name="legitimation" value="url" [checked]="selectedOption === 'url'" />
                  <span class="radio-button"></span>
                  <div class="option-content">
                    <span class="radio-label" data-translation="legitimation.options.url">{{
                      'legitimation.options.url' | translate | hideTranslation
                    }}</span>
                    <input
                      #urlinput="ngModel"
                      type="url"
                      maxlength="500"
                      class="url-input"
                      pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                      (keyup)="onUrlChange($event)"
                      (input)="_helperService.sanitizeInput(urlinput.control, $event)"
                      [(ngModel)]="legitimationUrl"
                      required
                    />
                  </div>
                </label>
                <div *ngIf="urlinput?.value?.length === 500">
                  {{
                    'input.validation.maxlength'
                      | translate
                        : {
                            number: urlinput.value.length
                          }
                      | hideTranslation
                  }}
                </div>
              </div>
            </div>

            <div class="col-lg-6 legitimation-option-box" *ngIf="eventSetting.isLegitimationWithFaxAllowed">
              <div class="legitimation-option">
                <label class="decorated-radio-button" (click)="selectOption('fax')">
                  <input type="radio" name="legitimation" value="fax" [checked]="selectedOption === 'fax'" />
                  <span class="radio-button"></span>
                  <div class="option-content">
                    <span class="radio-label" data-translation="legitimation.options.fax">{{
                      'legitimation.options.fax' | translate | hideTranslation
                    }}</span>
                    <button class="get-fax button" (click)="showFax()">
                      <svg class="icon icon-printer">
                        <use xlink:href="assets/defs.svg#icon-printer"></use>
                      </svg>
                      <span data-translation="legitimation.options.fax-template">{{
                        'legitimation.options.fax-template' | translate | hideTranslation
                      }}</span>
                    </button>
                  </div>
                </label>
              </div>
            </div>

            <div
              class="col-lg-6 legitimation-option-box"
              *ngIf="eventSetting.isLegitimationWithCommentAllowed || eventSetting.isOptionalLegitimationCommentAllowed"
            >
              <div class="legitimation-option">
                <label class="decorated-radio-button no-height" (click)="selectOption('noFile')">
                  <input type="radio" name="legitimation" value="noFile" [checked]="selectedOption === 'noFile'" />
                  <span class="radio-button"></span>
                  <div class="option-content radio-label" data-translation="legitimation.options.no-legitimation">
                    {{ 'legitimation.options.no-legitimation' | translate | hideTranslation }}
                  </div>
                </label>
                <app-info-block
                  *ngIf="
                    !eventSetting.isOptionalLegitimationCommentAllowed &&
                    selectedOption === 'noFile' &&
                    !('legitimation.options.comment-info-box' | translate | isHiddenTranslation)
                  "
                  [infoText]="'legitimation.options.comment-info-box'"
                  class="comment-info-box"
                ></app-info-block>
              </div>
            </div>
          </div>
        </div>

        <div
          class="legitimation-comment-wrapper"
          *ngIf="eventSetting.isLegitimationWithCommentAllowed || eventSetting.isOptionalLegitimationCommentAllowed"
        >
          <div class="legitimation-comment-title" data-translation="legitimation.comment-title">
            {{ 'legitimation.comment-title' | translate | hideTranslation }}
          </div>
          <div
            *ngIf="
              selectedOption === 'noFile' &&
              this.eventSetting.isLegitimationWithCommentAllowed &&
              !eventSetting.isOptionalLegitimationCommentAllowed
            "
            data-translation="legitimation.comment-validation-info"
          >
            {{ 'legitimation.comment-validation-info' | translate | hideTranslation }}
          </div>
          <textarea
            class="legitimation-comment-text-area"
            #textarea="ngModel"
            maxlength="1000"
            data-translation="legitimation.comment-placeholder"
            placeholder="{{ 'legitimation.comment-placeholder' | translate | hideTranslation }}..."
            [(ngModel)]="legitimationComment"
            (keyup)="onCommentChange()"
            (input)="_helperService.sanitizeInput(textarea.control, $event)"
            >
          </textarea>

          <div *ngIf="textarea?.value?.length === 1000">
            {{ 'input.validation.maxlength' | translate: { number: textarea.value.length } | hideTranslation }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="legitimation-button-wrapper"
      *ngIf="legitimationStatus !== LegitimationStatus.InProgress && userProfile"
    >
      <app-step-navigation-buttons
        [feedbackChanged]="!isButtonDisabled"
        [hasAlternativeButton]="
          legitimationStatus === LegitimationStatus.New || legitimationStatus === LegitimationStatus.Rejected
        "
      >
        <button
          alternative-submit
          class="button save-button"
          (click)="submitLegitimation()"
          [disabled]="isButtonDisabled || responsePending"
          [hidden]="
            !(
              (legitimationStatus === LegitimationStatus.New || legitimationStatus === LegitimationStatus.Rejected) &&
              this.selectedOption !== 'file'
            )
          "
        >
          <span data-translation="legitimation.save-button"
            >{{ 'legitimation.save-button' | translate | hideTranslation }}
          </span>
        </button>
      </app-step-navigation-buttons>
    </div>
  </div>

  <div *ngIf="showFaxInfo && eventSetting && userProfile && activeExhibition" class="fax-print-overlay">
    <div class="fax-print-block">
      <svg class="icon close-fax-template" (click)="closeFaxInfo()">
        <use xlink:href="assets/defs.svg#icon-cross"></use>
      </svg>

      <app-modal-window
        [modalWindowActive]="modalWindowActive"
        translateTitle="true"
        [modalWindowTitle]="'legitimation.faxTemplate.modalWindow.title'"
      >
        <button modalWindow-submit (click)="modalWindowLegitimate($event)" class="button">
          <span data-translation="legitimation.fax-template-legitimate">{{
            'legitimation.fax-template-legitimate' | translate | hideTranslation
          }}</span>
        </button>
        <button class="button button-option" modalWindow-close (click)="leaveFaxTemplate($event)">
          <span data-translation="legitimation.fax-template-leave">{{
            'legitimation.fax-template-leave' | translate | hideTranslation
          }}</span>
        </button>
      </app-modal-window>

      <div #printableFax class="printable-area">
        <div>
          <span style="font-weight: bold" data-translation="legitimation.fax-template.title">
            {{ 'legitimation.fax-template.title' | translate | hideTranslation }}:</span
          >
          <br />
          <span style="font-weight: bold">{{ activeExhibition.eventName }}</span>
          <br />
          <span data-translation="legitimation.fax-template.fax-number"
            >{{ 'legitimation.fax-template.fax-number' | translate | uppercase }}:</span
          >
          {{ eventSetting.faxNumber }}
        </div>
        <div style="margin: 30px 0px 10px 0px;">
          {{ userProfile.company }} <br />
          {{ userProfile.street }} <br />
          {{ userProfile.zipCode }} <br />
          <span *ngIf="userProfile.country">{{ 'country.' + userProfile.country | translate | hideTranslation }}</span>
        </div>

        <div style="margin: 20px 0px 10px 0px;" [attr.data-translation]="'gender.fax.' + userProfile.gender">
          {{ 'gender.fax.' + userProfile.gender | translate | hideTranslation }},
        </div>

        <div
          data-translation="legitimation.fax-template.text"
          data-translation-includes-children
          [innerHTML]="
            'legitimation.fax-template.text' | translate: { eventName: eventSetting.eventSerie } | hideTranslation
          "
        ></div>
        <span
          data-translation="legitimation.fax-template.legitimation-number"
          data-translation-includes-children
          [innerHTML]="'legitimation.fax-template.legitimation-number' | translate | hideTranslation"
        >
        </span
        >: <span id="fax-id">{{ faxId }}</span>

        <div style="margin: 10px 0px 10px 0px;">
          <table style="width:100%">
            <tr *ngIf="userProfile.company">
              <td style="width:30%" data-translation="legitimation.fax-template.company">
                {{ 'legitimation.fax-template.company' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.company }}</td>
            </tr>
            <tr>
              <td data-translation="legitimation.fax-template.street">
                {{ 'legitimation.fax-template.street' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.street }}</td>
            </tr>
            <tr>
              <td data-translation="legitimation.fax-template.zip-code">
                {{ 'legitimation.fax-template.zip-code' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.zipCode }}</td>
            </tr>
            <tr *ngIf="userProfile.country">
              <td data-translation="legitimation.fax-template.country">
                {{ 'legitimation.fax-template.country' | translate | hideTranslation }}:
              </td>
              <td>
                {{ 'country.' + userProfile.country | translate | hideTranslation }}
              </td>
            </tr>
            <tr>
              <td data-translation="legitimation.fax-template.first-name">
                {{ 'legitimation.fax-template.first-name' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.firstName }}</td>
            </tr>
            <tr>
              <td data-translation="legitimation.fax-template.last-name">
                {{ 'legitimation.fax-template.last-name' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.lastName }}</td>
            </tr>
            <tr>
              <td data-translation="legitimation.fax-template.email">
                {{ 'legitimation.fax-template.email' | translate | hideTranslation }}:
              </td>
              <td>{{ userProfile.email }}</td>
            </tr>
          </table>
        </div>
        <div
          style="margin: 0px 0px 20px 0px;"
          data-translation="legitimation.fax-template.bottom-text"
          data-translation-includes-children
          [innerHTML]="
            'legitimation.fax-template.bottom-text'
              | translate: { eventName: eventSetting.eventSerie }
              | hideTranslation
          "
        ></div>
        <div style="margin: 0px 0px 20px 0px;" data-translation="legitimation.fax-template.signature">
          {{ 'legitimation.fax-template.signature' | translate | hideTranslation }}
        </div>
        <div>
          _____________________________________ <br />{{ userProfile.lastName }}
          <br />
        </div>
      </div>
      <div class="fax-template-buttons">
        <button
          type="button"
          class="button print-button"
          (click)="printTemplate(printableFax)"
          [disabled]="isPrintWindowOpen"
        >
          <span data-translation="legitimation.fax-template.print">{{
            'legitimation.fax-template.print' | translate | hideTranslation
          }}</span>
        </button>

        <button
          *ngIf="afterFaxPrint"
          type="button"
          class="button print-button"
          (click)="submitLegitimation()"
          [disabled]="isPrintWindowOpen"
        >
          <span data-translation="legitimation.fax-template.done-printing">{{
            'legitimation.fax-template.done-printing' | translate | hideTranslation
          }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
