import { filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../app.reducer';
import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FormsService } from '../../../../shared/forms/forms.service';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { AppConstants } from '../../../../shared/app-constants';
var FormComponent = /** @class */ (function () {
    function FormComponent(formsService, store) {
        this.formsService = formsService;
        this.store = store;
        this.updatedTicketHolderInputs = new EventEmitter();
        this.handleFormValueChange = new EventEmitter();
        this.unsubscribe = new Subject();
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.holderUuid = !!this.formPath && this.formPath.length > 1 && this.formPath[1].includes(this.PersonaliseFormsKeys.ticketHolder[1])
            ? this.formPath[1].replace(this.PersonaliseFormsKeys.ticketHolder[1], '')
            : '';
        this.store
            .pipe(select(fromRoot.getAllCountriesList), first()).subscribe(function (list) {
            _this.formsService.translateCountries(list).subscribe(function (subs) {
                subs.subscribe(function (countries) {
                    _this.countries = countries;
                });
            });
        });
        combineLatest([
            this.store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this.store.pipe(select(fromRoot.uniqueVisitorCheckType)),
            this.store.pipe(select(fromRoot.getSkipHoldersCheck))
        ])
            .pipe(takeUntil(this.unsubscribe), filter(function (_a) {
            var ticketHolderInputSets = _a[0], uniqueVisitorCheckType = _a[1], skipHoldersCheck = _a[2];
            return !!ticketHolderInputSets && !!uniqueVisitorCheckType && !skipHoldersCheck;
        }))
            .subscribe(function (_a) {
            var ticketHolderInputSets = _a[0];
            var thisFormInputs = ticketHolderInputSets.find(function (inputSet) {
                return inputSet.formInfo.join('.') === _this.formPath.join('.');
            });
            if (thisFormInputs &&
                thisFormInputs.inputSet &&
                thisFormInputs.inputSet.list.length) {
                if (!!_this.countries) {
                    thisFormInputs.inputSet.list.forEach(function (item) {
                        if (item.key === "country") {
                            item.options = _this.countries;
                        }
                    });
                }
                _this.inputs = _this.formsService.updateInputs(_this.inputs, thisFormInputs.inputSet.list);
                _this.updatedTicketHolderInputs.emit({ inputs: _this.inputs, currentIndex: ticketHolderInputSets.indexOf(thisFormInputs) });
                if (thisFormInputs.inputSet.rerender || !_this.form) {
                    _this.form = _this.formsService.toFormGroup(thisFormInputs.inputSet.list, _this.formPath);
                }
            }
            if (_this.formChangeSubscribe) {
                _this.formChangeSubscribe.unsubscribe();
                _this.formChangeSubscribe = null;
            }
            _this.formChangeSubscribe = _this.form.valueChanges
                .pipe(takeUntil(_this.unsubscribe))
                .subscribe(function () { return _this.handleFormValueChange.emit(_this); });
        });
    };
    FormComponent.prototype.ngOnDestroy = function () {
        // once removed from screen, remove the validation
        this.formsService.removeFormsValidationFeedback(this.formPath);
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    return FormComponent;
}());
export { FormComponent };
