import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { getExhibitionSettings, getOrderUuid, getSelectedExhibitionId } from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  BookingProductType,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { isProductTypeTariff } from '@products/models/product-selection.model';
import { Tariff, TariffWarningMessage } from '@products/models/tariff.model';
import {
  GetVoucherDetail,
  RedeemVoucher,
  RedeemVoucherResponse,
  ReleaseVoucher,
  SetVoucherPayload,
  VoucherCode,
  VoucherDetail,
  VoucherDetailResponse,
  VoucherStateViewModel,
  VoucherType,
  VoucherWarningMessage
} from '@products/models/voucher.model';
import { VoucherValidationService } from '@products/services/voucher-validation.service';
import { AppConstants } from '@shared/app-constants';
import { environment } from '@src/environments/environment';
import { SetExhibitionSettings, SetRandomSponsor } from '@store/customization/customization.actions';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { CustomizationService } from '@store/customization/customization.service';
import {
  getBookedProducts,
  getRemainingTicketCountForVoucherTariff,
  getTotalTicketCountPerVoucherTariffByTicketPersonId
} from '@store/products/booking/booking.selectors';
import {
  getActiveVoucherProducts,
  getActiveVoucherTariffs,
  getVoucherTypeState,
  isRedeemedAnonymousVoucherProductInProductSelectionList,
  isVoucherCodeRedeemed
} from '@store/products/product-selection/product-selection.selectors';
import {
  RedeemAndReleaseVoucher,
  ReleaseExpiredVoucher,
  ReleaseVoucher as ReleaseVoucherAction
} from '@store/products/product.actions';
import { getAvailableTariffsByTicketPersonId } from '@store/products/status/tariff/tariff.selectors';
import { getIsTranslationLoaded } from '@store/translation/translation.selectors';
import cloneDeep from 'lodash.clonedeep';
import { Observable, Subject, combineLatest, forkJoin, iif, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

const { INDEX_NOT_FOUND, VOUCHER_PRODUCT_DEFAULT_AMOUNT } = AppConstants;
const { ERROR_TICKET_WARNING, VOUCHER_LIMITED_WARNING, TICKET_LIMIT_PER_USER_ACCOUNT_WARNING } = TariffWarningMessage;
const {
  VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING,
  VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING
} = VoucherWarningMessage;

@Injectable({
  providedIn: 'root'
})
export class VoucherService implements OnDestroy {
  voucherViewState$: Observable<VoucherStateViewModel>;

  private voucherServiceState: VoucherStateViewModel;
  private readonly destroy$ = new Subject<void>();
  private readonly _voucherViewState$: BehaviorSubject<VoucherStateViewModel> = new BehaviorSubject({
    maxTicketLimit: null,
    limitPromoCodes: null,
    limitLimitedPromoCodes: null,
    ticketLimitPerUserAccount: null,
    currentUserAccountTicketLimit: null,
    isVoucherIncludedPerUserAccountLimit: null,
    voucherCode: null,
    isLoading: null,
    isSelfRegistration: null,
    barcode: null,
    voucherTypingActive: null,
    ticketLimitWarning: null,
    bookedProductsCount: null,
    isRedeemedAnonymousVoucherProductInProductSelectionList: null,
    isCurrentVoucherTypeMandatory: null,
    isCurrentVoucherTypeAnonymous: null,
    voucherWarning: null,
    invalidVoucherWarningStatus: null
  });

  constructor(
    private store: Store<{}>,
    private http: HttpClient,
    private voucherValidationService: VoucherValidationService,
    private translateService: TranslateService,
    private statusBarService: StatusBarService,
    private customizationService: CustomizationService
  ) {
    this.voucherViewState$ = this._voucherViewState$.asObservable().distinctUntilChanged();
    this.voucherViewState$.pipe(takeUntil(this.destroy$)).subscribe(voucherViewState => {
      this.voucherServiceState = { ...this.voucherServiceState, ...voucherViewState };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initState(voucherState: VoucherStateViewModel) {
    this.clearTicketLimitWarning(voucherState);

    this._voucherViewState$.next(voucherState);
  }

  updateState(voucherState: Partial<VoucherStateViewModel>) {
    this.clearTicketLimitWarning(voucherState);

    this._voucherViewState$.next({ ...this.voucherServiceState, ...voucherState });
  }

  getState() {
    return this.voucherServiceState;
  }

  geStateProperty(propertyName: string) {
    return propertyName in this.voucherServiceState ? this.voucherServiceState[propertyName] : null;
  }

  getVoucherDetails$(voucherDetail: GetVoucherDetail): Observable<VoucherDetailResponse> {
    let params: HttpParams = new HttpParams();
    params = params.append('sr', `${this.voucherServiceState.isSelfRegistration}`);

    return this.http.get<VoucherDetailResponse>(
      `${environment.protocol}${environment.webApiUrl}/voucher/voucher-details/${voucherDetail.eventId}/${voucherDetail.voucherCode}`,
      { params }
    );
  }

  redeemVoucher$(voucherRedeem: RedeemVoucher): Observable<RedeemVoucherResponse> {
    let params: HttpParams = new HttpParams();
    params = params.append('sr', `${this.voucherServiceState.isSelfRegistration}`);
    params = params.append('uuid', `${voucherRedeem.orderUuid}`);
    params = params.append('totalTicketCount', `${voucherRedeem.totalTicketCount}`);

    return this.http.get<RedeemVoucherResponse>(
      `${environment.protocol}${environment.webApiUrl}/voucher/redeem-voucher/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}`,
      { params }
    );
  }

  releaseVoucher$(releaseVoucher: ReleaseVoucher): Observable<{}> {
    let body: HttpParams = new HttpParams();
    body = body.set('sr', `${this.voucherServiceState.isSelfRegistration}`);
    body = body.set('uuid', `${releaseVoucher.orderUuid}`);

    if (releaseVoucher.ticketPersonId) {
      body = body.set('ticketPersonId', `${releaseVoucher.ticketPersonId}`);
      body = body.set('remainingTicketCount', `${releaseVoucher.remainingTicketCount}`);
    }

    return this.http.post<{}>(
      `${environment.protocol}${environment.webApiUrl}/voucher/reset-event-voucher/${releaseVoucher.eventId}/${releaseVoucher.voucherCode}`,
      body
    );
  }

  releaseAllVouchers$() {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getActiveVoucherTariffs))
    ]).pipe(
      first(([eventId, orderUuid]) => !!eventId && !!orderUuid),
      switchMap(([eventId, orderUuid, activeVoucherTariffs]) => {
        const releaseVouchers = this.mapActiveVoucherTariffsToReleaseVoucher(activeVoucherTariffs, eventId, orderUuid);

        return this.releaseMultipleVouchers$(releaseVouchers);
      })
    );
  }

  private prepareAndValidateVoucherDetailRequestData$(voucherCode: VoucherCode): Observable<GetVoucherDetail> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(isVoucherCodeRedeemed(voucherCode)))
    ]).pipe(
      take(1),
      filter(([eventId, isVoucherCodeNotRedeemed]) => !!eventId && !isVoucherCodeNotRedeemed),
      map(([eventId]) => ({
        eventId,
        voucherCode
      }))
    );
  }

  private prepareRedeemVoucherPayloadData$(voucherDetail: VoucherDetail): Observable<SetVoucherPayload> {
    const { eventId, ticketPersonId, voucherCode } = voucherDetail;

    return combineLatest([
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getBookedProducts)),
      this.store.pipe(select(getActiveVoucherTariffs)),
      this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList)),
      this.store.pipe(select(getTotalTicketCountPerVoucherTariffByTicketPersonId(ticketPersonId))),
      this.store.pipe(select(getRemainingTicketCountForVoucherTariff(ticketPersonId, voucherCode)))
    ]).pipe(
      first(([orderUuid]) => !!orderUuid),
      map(
        ([
          orderUuid,
          bookedProducts,
          activeVoucherTariffs,
          isRedeemedAnonymousVoucherProductInProductSelectionList,
          totalTicketCount,
          remainingTicketCount
        ]) => {
          const countryCode = this.translateService.currentLang;
          const isAnonymous = this.voucherServiceState.isCurrentVoucherTypeAnonymous;
          const setVoucherPayload: SetVoucherPayload = {
            redeemVoucher: {
              eventId,
              orderUuid,
              voucherCode,
              countryCode,
              isAnonymous,
              totalTicketCount: totalTicketCount + VOUCHER_PRODUCT_DEFAULT_AMOUNT
            },
            releaseVouchers: [],
            removeProductBookings: []
          };

          if (
            this.voucherServiceState.isSelfRegistration ||
            (isAnonymous && !isRedeemedAnonymousVoucherProductInProductSelectionList)
          ) {
            activeVoucherTariffs.forEach(({ ticketPersonId, voucherCode }) => {
              const releaseVoucher: ReleaseVoucher = {
                eventId,
                orderUuid,
                ticketPersonId,
                voucherCode,
                countryCode,
                remainingTicketCount
              };

              setVoucherPayload.releaseVouchers.push(releaseVoucher);
            });

            const cloneBookedProducts: BookingProductType[] = cloneDeep(bookedProducts);

            cloneBookedProducts.forEach((bookedProduct: BookingProductType) => {
              if (isBookingProductTypeTariff(bookedProduct)) {
                bookedProduct.tariffs.forEach(bookedTariff => {
                  bookedTariff.count = 0;
                  bookedTariff.holderUuids = [];
                });

                const isBookingTariffTypeInRemoveProductBookings = setVoucherPayload.removeProductBookings.some(
                  removeProductBooking => {
                    if (isBookingProductTypeTariff(removeProductBooking)) {
                      return removeProductBooking.ticketTypeId === bookedProduct.ticketTypeId;
                    }
                  }
                );

                if (!isBookingTariffTypeInRemoveProductBookings) {
                  setVoucherPayload.removeProductBookings.push(bookedProduct);
                }
              } else if (isBookingProductTypePackage(bookedProduct)) {
                bookedProduct.productTypes.forEach(bookedPackageProduct => {
                  if (isBookingProductTypeTariff(bookedPackageProduct)) {
                    bookedPackageProduct.tariffs.forEach(bookedPackageTariff => {
                      bookedPackageTariff.count = 0;
                      bookedPackageTariff.holderUuids = [];
                    });
                  }
                });

                setVoucherPayload.removeProductBookings.push(bookedProduct);
              }
            });
          }

          return setVoucherPayload;
        }
      )
    );
  }

  private prepareReleaseVoucherPayloadData$(
    ticketPersonId: number,
    voucherCode: VoucherCode
  ): Observable<ReleaseVoucher> {
    return combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getRemainingTicketCountForVoucherTariff(ticketPersonId, voucherCode)))
    ]).pipe(
      first(([eventId, orderUuid]) => !!eventId && !!orderUuid),
      map(([eventId, orderUuid, remainingTicketCount]) => ({
        eventId,
        orderUuid,
        ticketPersonId,
        voucherCode,
        countryCode: this.translateService.currentLang,
        remainingTicketCount
      }))
    );
  }

  private mapVoucherDetailResponseToVoucherDetail(
    voucherDetailResponse: VoucherDetailResponse,
    eventId: number
  ): VoucherDetail {
    const { groupId, ticketTypeId, ticketPersonId, voucherCode, voucherType } = voucherDetailResponse;

    return {
      eventId,
      ticketTypeId: groupId,
      ticketPersonTypeId: ticketTypeId,
      ticketPersonId,
      voucherCode,
      voucherType
    };
  }

  private mapActiveVoucherTariffsToReleaseVoucher(
    activeVoucherTariff: Tariff[],
    eventId: number,
    orderUuid: string
  ): ReleaseVoucher[] {
    const releaseVouchers: ReleaseVoucher[] = [];
    const countryCode = this.translateService.currentLang;

    activeVoucherTariff.forEach(({ ticketPersonId, voucherCode }) => {
      const releaseVoucher: ReleaseVoucher = {
        eventId,
        orderUuid,
        ticketPersonId,
        voucherCode,
        countryCode,
        remainingTicketCount: 0
      };

      releaseVouchers.push(releaseVoucher);
    });

    return releaseVouchers;
  }

  private isValidVoucherDetail$(voucherDetail: VoucherDetail) {
    const { ticketPersonId, voucherType } = voucherDetail;

    return this.store.pipe(
      select(getVoucherTypeState(ticketPersonId)),
      first(voucherTypeState => {
        if (!voucherTypeState) {
          this.showInvalidVoucherStatusWarningMessage();
          return false;
        }

        return true;
      }),
      switchMap(voucherTypeState => {
        const { initialNumberOfAvailableTariffs, isMandatory, isAnonymous, isAnonymousWithPrice } = voucherTypeState;

        return combineLatest([
          this.isAvailableTariffsLimitReached$(ticketPersonId, initialNumberOfAvailableTariffs),
          this.isVoucherTypeLimitReached$(voucherType, isAnonymous),
          this.isAnonymousProductBookedAndCurrentVoucherMandatory$(isMandatory, isAnonymous, isAnonymousWithPrice)
        ]).pipe(
          first(validatedVoucherDetails =>
            validatedVoucherDetails.every(isInvalidVoucherDetail => !isInvalidVoucherDetail)
          )
        );
      })
    );
  }

  private isAvailableTariffsLimitReached$(ticketPersonId: number, initialNumberOfAvailableTariffs: number) {
    return this.store.pipe(
      select(getAvailableTariffsByTicketPersonId(ticketPersonId)),
      switchMap(currentNumberOfAvailableTariffs =>
        iif(
          () => currentNumberOfAvailableTariffs > 0,
          of(''),
          this.getInvalidVoucherAvailableTariffsWarningMessageTranslation$(initialNumberOfAvailableTariffs)
        ).pipe(
          map(availableTariffsLimitWarningMessage => ({
            currentNumberOfAvailableTariffs,
            availableTariffsLimitWarningMessage
          }))
        )
      ),
      first(),
      tap(({ availableTariffsLimitWarningMessage }) => {
        if (availableTariffsLimitWarningMessage) {
          this.showInvalidVoucherStatusWarningMessage(availableTariffsLimitWarningMessage);
        }
      }),
      map(({ currentNumberOfAvailableTariffs }) => !currentNumberOfAvailableTariffs)
    );
  }

  private isVoucherTypeLimitReached$(voucherType: VoucherType, isAnonymous: boolean): Observable<boolean> {
    return this.voucherValidationService
      .validateRedeemedVoucherTypeCount$(voucherType, VOUCHER_PRODUCT_DEFAULT_AMOUNT, isAnonymous)
      .pipe(
        switchMap(voucherTypeValidatedCount =>
          iif(
            () => voucherTypeValidatedCount > 0,
            of(''),
            this.getInvalidVoucherTypeWarningMessageTranslation$(voucherType)
          ).pipe(
            map(voucherTypeLimitWarningMessage => ({
              voucherTypeValidatedCount,
              voucherTypeLimitWarningMessage
            }))
          )
        ),
        first(),
        tap(({ voucherTypeLimitWarningMessage }) => {
          if (voucherTypeLimitWarningMessage) {
            this.showInvalidVoucherStatusWarningMessage(voucherTypeLimitWarningMessage);
          }
        }),
        map(({ voucherTypeValidatedCount }) => !voucherTypeValidatedCount)
      );
  }

  private isAnonymousProductBookedAndCurrentVoucherMandatory$(
    isMandatory: boolean,
    isAnonymous: boolean,
    isAnonymousWithPrice: boolean
  ): Observable<boolean> {
    this.updateState({
      isCurrentVoucherTypeMandatory: isMandatory,
      isCurrentVoucherTypeAnonymous: isAnonymous
    });

    if (isAnonymousWithPrice) {
      this.updateState({
        isRedeemedAnonymousVoucherProductInProductSelectionList: isAnonymous,
        voucherWarning: VOUCHER_ANONYMOUS_PRICED_TICKET_WARNING
      });

      return of(true);
    }

    if (this.voucherServiceState.isRedeemedAnonymousVoucherProductInProductSelectionList && isMandatory) {
      this.updateState({
        voucherWarning: VOUCHER_REGULAR_AFTER_ANONYMOUS_TICKET_WARNING
      });

      return of(true);
    }

    return of(false);
  }

  private getInvalidVoucherAvailableTariffsWarningMessageTranslation$(
    initialNumberOfAvailableTariffs: number
  ): Observable<string> {
    return this.getStatusWarningMessageTranslation$(ERROR_TICKET_WARNING, {
      maxTicketLimit: initialNumberOfAvailableTariffs
    });
  }

  private getInvalidVoucherTypeWarningMessageTranslation$(voucherType: VoucherType): Observable<string> {
    const { limitPromoCodes, limitLimitedPromoCodes } = this.voucherServiceState;

    return this.getStatusWarningMessageTranslation$(VOUCHER_LIMITED_WARNING, {
      maxTicketLimit: voucherType === VoucherType.PromoCode ? limitPromoCodes : limitLimitedPromoCodes
    });
  }

  private getStatusWarningMessageTranslation$(key: string | string[], params?: object): Observable<string> {
    return combineLatest([
      this.store.pipe(select(getIsTranslationLoaded)),
      this.translateService.stream(key, params)
    ]).pipe(
      first(([isTranslationLoaded, statusWarningMessage]) => isTranslationLoaded && statusWarningMessage),
      map(([_, statusWarningMessage]) => statusWarningMessage)
    );
  }

  private showInvalidVoucherStatusWarningMessage(invalidVoucherWarningStatus?: string) {
    const warningStatusMessage = invalidVoucherWarningStatus || this.voucherServiceState.invalidVoucherWarningStatus;

    this.statusBarService.setStatus(warningStatusMessage, 'error', null, `[${this.statusMessageDateTime()}]`);
  }

  private statusMessageDateTime() {
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(Date.now() - tzOffset).toISOString().slice(0, -1);
    return localISOTime.substring(0, localISOTime.indexOf('.')).replace('T', ' ');
  }

  private isValidTariffLimit(): boolean {
    if (!!this.voucherServiceState.ticketLimitPerUserAccount) {
      if (this.voucherServiceState.isVoucherIncludedPerUserAccountLimit) {
        if (
          this.voucherServiceState.currentUserAccountTicketLimit !== undefined &&
          this.voucherServiceState.bookedProductsCount !== undefined &&
          this.voucherServiceState.bookedProductsCount === this.voucherServiceState.currentUserAccountTicketLimit
        ) {
          this.updateState({ ticketLimitWarning: TICKET_LIMIT_PER_USER_ACCOUNT_WARNING });
          return false;
        }
      }
    }

    if (
      this.voucherServiceState.maxTicketLimit !== undefined &&
      this.voucherServiceState.bookedProductsCount !== undefined &&
      this.voucherServiceState.bookedProductsCount === this.voucherServiceState.maxTicketLimit
    ) {
      this.updateState({ ticketLimitWarning: ERROR_TICKET_WARNING });
      return false;
    }

    return true;
  }

  getVoucherWarningTranslation() {
    this.getStatusWarningMessageTranslation$('response.voucherNotValid')
      .pipe(takeUntil(this.destroy$))
      .subscribe(translatedInvalidVoucherWarning =>
        this.updateState({ invalidVoucherWarningStatus: translatedInvalidVoucherWarning })
      );
  }

  redeemVoucher(voucherCode: VoucherCode) {
    if (!voucherCode || !this.isValidTariffLimit()) {
      return;
    }

    this.prepareAndValidateVoucherDetailRequestData$(voucherCode.trim())
      .pipe(
        tap(() => {
          this.updateState({ isLoading: true });
          setTimeout(() => {
            this.updateState({ isLoading: false });
          }, 2000);
        }),
        switchMap((getVoucherDetail: GetVoucherDetail) => {
          return this.getVoucherDetails$(getVoucherDetail).pipe(
            switchMap((voucherDetailResponse: VoucherDetailResponse) => {
              const voucherDetail = this.mapVoucherDetailResponseToVoucherDetail(
                voucherDetailResponse,
                getVoucherDetail.eventId
              );

              return this.isValidVoucherDetail$(voucherDetail).pipe(
                switchMap(() => this.prepareRedeemVoucherPayloadData$(voucherDetail))
              );
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        setVoucherPayload => {
          this.clearVoucherInputAndWarning();

          this.store.dispatch(new RedeemAndReleaseVoucher(setVoucherPayload));
        },
        () => this.updateState({ isLoading: false })
      );
  }

  releaseMultipleVouchers$(releaseVouchers: ReleaseVoucher[]) {
    if (!releaseVouchers.length) {
      return of([]);
    }

    return forkJoin(
      releaseVouchers.map(releaseVoucher => {
        return this.releaseVoucher$(releaseVoucher);
      })
    );
  }

  releaseVoucher(ticketPersonId: number, voucherCode: VoucherCode) {
    if (!ticketPersonId || !voucherCode) {
      return;
    }

    this.clearVoucherInputAndWarning();
    this.clearVoucherWarning();

    this.prepareReleaseVoucherPayloadData$(ticketPersonId, voucherCode).subscribe(releaseVoucher =>
      this.store.dispatch(new ReleaseVoucherAction(releaseVoucher))
    );
  }

  releaseExpiredVoucher(ticketPersonId: number, voucherCode: VoucherCode) {
    if (!ticketPersonId || !voucherCode) {
      return;
    }

    this.clearVoucherInputAndWarning();
    this.clearVoucherWarning();

    this.prepareReleaseVoucherPayloadData$(ticketPersonId, voucherCode).subscribe(releaseVoucher =>
      this.store.dispatch(new ReleaseExpiredVoucher(releaseVoucher))
    );
  }

  scanVoucher(e: KeyboardEvent) {
    const code = e.keyCode ? e.keyCode : e.which;
    this.updateState({
      isCurrentVoucherTypeAnonymous: false,
      ticketLimitWarning: '',
      voucherWarning: ''
    });

    if (code === 16 || code === 17) return; // don't add shift && ctrl to barcode!

    const voucherBarcode = this.voucherServiceState.barcode;

    if (e.ctrlKey && code === 66) {
      this.updateState({ voucherTypingActive: true });
    } else if (e.ctrlKey && code === 74) {
      this.updateState({
        voucherCode: voucherBarcode,
        barcode: '',
        voucherTypingActive: false
      });
      this.redeemVoucher(voucherBarcode);
    } else if (this.voucherServiceState.voucherTypingActive) {
      if (e.shiftKey) {
        this.updateState({
          barcode: voucherBarcode + String.fromCharCode(code).toUpperCase()
        });
      } else {
        this.updateState({
          barcode: voucherBarcode + String.fromCharCode(code).toLowerCase()
        });
      }
    }
  }

  clearVoucherWarning() {
    const isAnonymousProductNotBooked = !this.voucherServiceState
      .isRedeemedAnonymousVoucherProductInProductSelectionList;
    const isCurrentVoucherTypeNotMandatory = !this.voucherServiceState.isCurrentVoucherTypeMandatory;

    if (isAnonymousProductNotBooked && isCurrentVoucherTypeNotMandatory) {
      this.updateState({ voucherWarning: '' });
    }
  }

  clearTicketLimitWarning(newVoucherState: Partial<VoucherStateViewModel>) {
    if (newVoucherState.bookedProductsCount < newVoucherState.maxTicketLimit) {
      newVoucherState.ticketLimitWarning = '';
    }
  }

  clearVoucherInputAndWarning() {
    this.updateState({
      ticketLimitWarning: '',
      voucherWarning: '',
      voucherCode: ''
    });
  }

  setSponsorAndExhibitionBanner() {
    combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getActiveVoucherProducts))])
      .pipe(first())
      .subscribe(([exhibitionSettings, activeVoucherProducts]) => {
        if (!this.voucherServiceState.voucherWarning) {
          this.updateState({ voucherCode: '' });
        }

        this.updateState({ isLoading: false });

        const voucherTariffTypeIndex = activeVoucherProducts.findIndex(isProductTypeTariff);
        if (voucherTariffTypeIndex === INDEX_NOT_FOUND) {
          return;
        }

        const voucherTariffType = activeVoucherProducts[voucherTariffTypeIndex].tariffType;
        if (!voucherTariffType.tariffs.length) {
          return;
        }

        const voucherTariff = voucherTariffType.tariffs[0];

        if (voucherTariff.sponsors && voucherTariff.sponsors.length) {
          const voucherTariffSponsor = voucherTariff.sponsors[0];

          if (!!voucherTariffSponsor.styles) {
            this.customizationService.setVoucherStyles(voucherTariffSponsor.styles);
          }

          if (!!voucherTariffSponsor.sponsorBanner) {
            this.store.dispatch(new SetRandomSponsor({ sponsorBanner: voucherTariffSponsor.sponsorBanner }));
          }

          if (!!voucherTariffSponsor.eventBanner) {
            const modifiedExhibitionSettings: ExhibitionSettingModel = {
              ...exhibitionSettings,
              eventBanner: voucherTariffSponsor.eventBanner
            };

            this.store.dispatch(new SetExhibitionSettings(modifiedExhibitionSettings));
          }
        }
      });
  }
}
