import { AppConstants } from "./app-constants";
export function pageRunningInIframe() {
    var inIframe = false;
    try {
        inIframe = window.self !== window.top;
    }
    catch (e) {
        inIframe = false;
    }
    return inIframe;
}
/**
 * Get query parameters from URL
 * @param {String} qs - location.search
 * @returns {Object} - {param: paramValue}
 */
export function getQueryParamsFromLocation(qs) {
    if (qs === void 0) { qs = window.location.search; }
    qs = qs.split('+').join(' ');
    var params = {};
    var re = /[?&]?([^=]+)=([^&]*)/g;
    var tokens;
    while ((tokens = re.exec(qs))) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}
export function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    }
    else {
        return uri + separator + key + '=' + value;
    }
}
export function createFormData(object, form, namespace) {
    var formData = form || new FormData();
    for (var property in object) {
        if (!object.hasOwnProperty(property) || !object[property]) {
            continue;
        }
        var formKey = namespace ? namespace + "[" + property + "]" : property;
        if (object[property] instanceof Date) {
            formData.append(formKey, object[property].toISOString());
        }
        else if (typeof object[property] === 'object' &&
            !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        }
        else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
}
export function highlightDay(day, start, end) {
    var startMonth = new Date(start).getMonth();
    var startDate = new Date(start).getDate();
    var endMonth = new Date(end).getMonth();
    var endDate = new Date(end).getDate();
    if (startMonth === endMonth && day.month === startMonth) {
        return startDate <= day.day && endDate >= day.day;
    }
    else {
        if (startMonth === day.month) {
            return startDate <= day.day;
        }
        if (endMonth === day.month) {
            return endDate >= day.day;
        }
    }
    return false;
}
/**
 * Gets countdown timestamp
 * @param tariffReleaseInMinutes Time in minutes to add to calculation
 * @param activatedTimestamp Optional (timestamp), if added it will be added to calculation of timestamp, default Date.now()
 * @returns Countdown timestamp in milliseconds
 */
export function getCountdownTimestamp(tariffReleaseInMinutes, activatedTimestamp) {
    if (activatedTimestamp === void 0) { activatedTimestamp = Date.now(); }
    return tariffReleaseInMinutes * 60 * 1000 + activatedTimestamp;
}
export function getUUID() {
    var uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = (Math.random() * 16) | 0;
        if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += '-';
        }
        uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
}
export function getAppVersion() {
    return document.querySelector('#app-version').getAttribute('data-version');
}
export function addMinutesToDate(date, minutes) {
    if (!!date) {
        return new Date(date.getTime() + minutes * 60000);
    }
    return date;
}
/**
 * Converts a string in a specified format (or default 'yyyy-mm-dd') to a date in the current timezone.
 * @param strDate
 * @param format
 * @returns
 */
export function convertStringToDate(strDate, format) {
    if (format === void 0) { format = 'yyyy-mm-dd'; }
    if (!strDate || !format) {
        return null;
    }
    format = format.toLowerCase();
    var separator = format.includes('-') ? '-' : (format.includes('.') ? '.' : (format.includes('/') ? '/' : '.'));
    var splitDate = strDate.split(separator);
    var splitFormat = format.split(separator);
    if (splitDate.length >= 3 && splitFormat.length >= 3) {
        var yearIdx_1 = null, monthIdx_1 = null, dayIdx_1 = null;
        splitFormat.forEach(function (x, idx) {
            if (x.startsWith('y')) {
                yearIdx_1 = idx;
            }
            else if (x.startsWith('m')) {
                monthIdx_1 = idx;
            }
            else if (x.startsWith('d')) {
                dayIdx_1 = idx;
            }
        });
        if (yearIdx_1 !== null && monthIdx_1 !== null && dayIdx_1 !== null) {
            return new Date(+splitDate[yearIdx_1], +splitDate[monthIdx_1] - 1, +splitDate[dayIdx_1], 0, 0, 0);
        }
    }
    return null;
}
//#region session functions
/**
 * Fetches an existing (but valid) or a new sessionId. All session specific localStorage keys will be prefixed by that sessionId.
 * @returns Existing or new sessionId
 */
export function getOrCreateSessionId() {
    //check application version:
    var appVersion = getAppVersion();
    var oldAppVersion = getLocalStorageString(AppConstants.appVersionReducer);
    if (!(oldAppVersion && oldAppVersion === appVersion)) {
        setLocalStorageString(AppConstants.appVersionReducer, appVersion);
        consoleLog('New application version: ' + appVersion);
    }
    var sessionId = getSessionStorageString("sessionId");
    var browserSessions = getLocalStorageObject(AppConstants.browserSessionsReducer);
    if (sessionId) {
        consoleLog("Initial sessionId from session storage: " + sessionId);
    }
    if (browserSessions) {
        //try to find an existing inactive session that corresponds to the sessionId stored in the sessionStorage:
        var validSession = sessionId && browserSessions.find(function (session) { return session.sessionId === sessionId && !session.active && session.appVersion === appVersion; });
        if (validSession) {
            //and use that sessionId:
            browserSessions[browserSessions.indexOf(validSession)] = {
                sessionId: sessionId,
                active: true,
                created: new Date(),
                appVersion: appVersion
            };
        }
        else {
            //or try to find an existing inactive session:
            var sessionIdx = findNewestInactiveSessionIdx(browserSessions);
            if (sessionIdx !== null) {
                //and use that sessionId:
                sessionId = browserSessions[sessionIdx].sessionId;
                browserSessions[sessionIdx] = {
                    sessionId: sessionId,
                    active: true,
                    created: new Date(),
                    appVersion: appVersion
                };
            }
            else {
                //or create a new sessionId (don't reuse the one from the sessionStorage):
                sessionId = getUUID();
                browserSessions = browserSessions.concat([
                    {
                        sessionId: sessionId,
                        active: true,
                        created: new Date(),
                        appVersion: appVersion
                    }
                ]);
            }
        }
    }
    else {
        //create a new sessionId (don't reuse the one from the sessionStorage):
        sessionId = getUUID();
        browserSessions = [
            {
                sessionId: sessionId,
                active: true,
                created: new Date(),
                appVersion: appVersion
            }
        ];
    }
    setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
    setSessionStorageString("sessionId", sessionId);
    consoleLog("SessionId for this tab: " + sessionId);
    return sessionId;
}
/**
 * Deactivates the current sessionId.
 */
export function deactivateCurrentSession() {
    var appVersion = getAppVersion();
    var sessionId = getSessionStorageString("sessionId");
    var browserSessions = getLocalStorageObject(AppConstants.browserSessionsReducer);
    if (sessionId && browserSessions) {
        var sessionIdx = browserSessions.findIndex(function (session) { return session.sessionId === sessionId && session.active; });
        if (sessionIdx > -1) {
            browserSessions[sessionIdx] = {
                sessionId: sessionId,
                active: false,
                created: new Date(),
                appVersion: appVersion
            };
            setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
        }
    }
}
/**
 * Deletes all old, unused sessionIds from the localStorage.
 */
export function deleteInactiveSessions() {
    var browserSessions = getLocalStorageObject(AppConstants.browserSessionsReducer);
    var appVersion = getAppVersion();
    if (browserSessions) {
        var deleted = false;
        for (var idx = browserSessions.length - 1; idx >= 0; idx--) {
            var session = browserSessions[idx];
            if ((!session.active && (new Date(session.created) < addMinutesToDate(new Date(), -120) || session.appVersion < appVersion)) || new Date(session.created) < addMinutesToDate(new Date(), -180)) {
                consoleLog("Removing inactive session: " + session.sessionId);
                browserSessions.splice(idx, 1);
                removeLocalStorageItemsForSession(session.sessionId);
                deleted = true;
            }
        }
        if (deleted) {
            setLocalStorageObject(AppConstants.browserSessionsReducer, browserSessions);
        }
    }
    //delete old localStorage keys without sessionId prefixes:
    if (localStorage.getItem(AppConstants.ticketsReducer)) {
        for (var key in localStorage) {
            if (AppConstants.allSyncedSessionReducers.includes(key)) {
                localStorage.removeItem(key);
            }
        }
        ;
    }
    //delete localStorage keys with sessionId prefixes but without a valid session:
    var sessionIdLength = getUUID().length;
    var _loop_1 = function (key) {
        if (key.includes('_')) {
            var sessionId_1 = key.split('_')[0];
            if (sessionId_1 && sessionId_1.length === sessionIdLength && !browserSessions.find(function (session) { return session.sessionId === sessionId_1; })) {
                consoleLog("Removing invalid session: " + sessionId_1);
                removeLocalStorageItemsForSession(sessionId_1);
            }
        }
    };
    for (var key in localStorage) {
        _loop_1(key);
    }
}
function findNewestInactiveSessionIdx(browserSessions) {
    var sessionIdx = null;
    var date = null;
    var appVersion = getAppVersion();
    browserSessions.forEach(function (session, idx) {
        if (!session.active && session.appVersion === appVersion && (!date || new Date(session.created) > date)) {
            sessionIdx = idx;
            date = new Date(session.created);
        }
    });
    return sessionIdx;
}
//#endregion
//#region localStorage functions
/**
 * Gets a localStorage object by the given key. If the localStorage value is not null it will be parsed into an object.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without the sessionId prefix
 * @returns Parsed localStorage object
 */
export function getLocalStorageObject(key) {
    var sessionPrefix = getLocalStorageSessionPrefix(key);
    var item = localStorage.getItem(sessionPrefix + key);
    return item && JSON.parse(item);
}
/**
 * Gets a localStorage string by the given key. The localStorage value WILL NOT be parsed into an object.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @returns Unparsed localStorage string value
 */
export function getLocalStorageString(key) {
    var sessionPrefix = getLocalStorageSessionPrefix(key);
    return localStorage.getItem(sessionPrefix + key);
}
/**
 * Sets a localStorage object by the given key and object value. The value object will be serialized (stringified) into a string.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @param value Parsed object to be set
 */
export function setLocalStorageObject(key, value) {
    var sessionPrefix = getLocalStorageSessionPrefix(key);
    localStorage.setItem(sessionPrefix + key, value && JSON.stringify(value));
}
/**
 * Sets a localStorage string by the given key and serialized string value. The value string WILL NOT be serialized (stringified).
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 * @param value String value to be set
 */
export function setLocalStorageString(key, value) {
    var sessionPrefix = getLocalStorageSessionPrefix(key);
    localStorage.setItem(sessionPrefix + key, value);
}
/**
 * Removes a localStorage item by the given key.
 * This function will automatically add a sessionId prefix for the predefined session related localStorage keys (see: AppConstants.allSyncedSessionReducers).
 * @param key LocalStorage key without a sessionId prefix
 */
export function removeLocalStorageItem(key) {
    var sessionPrefix = getLocalStorageSessionPrefix(key);
    localStorage.removeItem(sessionPrefix + key);
}
/**
 * Removes all session related localStorage keys dedicated to the provided sessionId.
 * @param sessionId
 * @returns -
 */
export function removeLocalStorageItemsForSession(sessionId) {
    if (!sessionId) {
        return;
    }
    for (var key in localStorage) {
        if (key.startsWith(sessionId + "_")) {
            localStorage.removeItem(key);
        }
    }
    ;
}
/**
 * Removes all localStorage keys.
 */
export function clearLocalStorage() {
    localStorage.clear();
}
function getLocalStorageSessionPrefix(key) {
    var sessionPrefix = "";
    var sessionId = (AppConstants.allSyncedSessionReducers.includes(key) || AppConstants.syncedUserSessionReducers.includes(key) || AppConstants.syncedAdminSessionReducers.includes(key))
        && getSessionStorageString("sessionId");
    if (sessionId) {
        sessionPrefix = sessionId + "_";
    }
    return sessionPrefix;
}
//#endregion
//#region sessionStorage functions
export function getSessionStorageObject(key) {
    var item = sessionStorage.getItem(key);
    return item && JSON.parse(item);
}
export function getSessionStorageString(key) {
    return sessionStorage.getItem(key);
}
export function setSessionStorageObject(key, value) {
    sessionStorage.setItem(key, value && JSON.stringify(value));
}
export function setSessionStorageString(key, value) {
    sessionStorage.setItem(key, value);
}
export function removeSessionStorageItem(key) {
    sessionStorage.removeItem(key);
}
export function clearSessionStorage() {
    sessionStorage.clear();
}
//#endregion
//#region consoleLog
export var ConsoleLogLevel;
(function (ConsoleLogLevel) {
    ConsoleLogLevel["Error"] = "Error";
    ConsoleLogLevel["Warning"] = "Warning";
    ConsoleLogLevel["Info"] = "Info";
    ConsoleLogLevel["Debug"] = "Debug";
})(ConsoleLogLevel || (ConsoleLogLevel = {}));
/**
 * Logs a message in the console log under a defined log level or 'info' if no log level has been chosen.
 * The original message will be prefixed by the date time in UTC and the defined log level,
 * e.g. [2021-02-15T11:41:06.944Z] INFO: Releasing contingent tickets for uuid: 353224ac-1f58-433a-a6c2-22c90ca6bceb
 * @param message The message to be logged
 * @param logLevel The log level under which the message should be logged (default: info)
 */
export function consoleLog(message, logLevel) {
    if (logLevel === void 0) { logLevel = ConsoleLogLevel.Info; }
    var currentTime = new Date().toISOString();
    var currentMessage = "[" + currentTime + "] " + logLevel.toUpperCase() + ": " + message;
    switch (logLevel) {
        case ConsoleLogLevel.Error:
            console.error(currentMessage);
            break;
        case ConsoleLogLevel.Warning:
            console.warn(currentMessage);
            break;
        case ConsoleLogLevel.Info:
            console.info(currentMessage);
            break;
        case ConsoleLogLevel.Debug:
            console.debug(currentMessage);
            break;
        default:
            console.log(currentMessage);
            break;
    }
}
//#endregion
