import { HttpClient, HttpParams } from '@angular/common/http';
import { getUserAccountTicketLimitCheck } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { getBookedVoucherTariffByVoucherCode } from '@store/products/booking/booking.selectors';
import { GetUserAccountTariffsLimitStatus } from '@store/products/status/tariff/tariff.actions';
import { of } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../shared/services-with-reducers/helpers/helper.service";
var TariffStatusService = /** @class */ (function () {
    function TariffStatusService(store, http, helperService) {
        this.store = store;
        this.http = http;
        this.helperService = helperService;
    }
    TariffStatusService.prototype.checkDailyTicketPerEmailLimit$ = function (validateDailyTicketPerEmailLimitBody) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/order/validators/daily-ticket-per-email-limit", validateDailyTicketPerEmailLimitBody);
    };
    TariffStatusService.prototype.getTicketLimitPerUserAccount$ = function (eventId) {
        var params = new HttpParams();
        params = params.append('sr', "" + this.helperService.isSelfregistration());
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/tickets/" + eventId + "/ticket-limit-per-user-account", { params: params });
    };
    TariffStatusService.prototype.getAndSetUserAccountTariffLimit = function () {
        var _this = this;
        this.store
            .pipe(select(getUserAccountTicketLimitCheck), first(function (_a) {
            var checkUserAccountTicketLimit = _a.checkUserAccountTicketLimit;
            return checkUserAccountTicketLimit;
        }))
            .subscribe(function (_a) {
            var exhibitionId = _a.exhibitionId;
            return _this.store.dispatch(new GetUserAccountTariffsLimitStatus(exhibitionId));
        });
    };
    TariffStatusService.prototype.mapTariffsToAvailableTariffs = function (tariffs) {
        var availableTariffs = {};
        tariffs.forEach(function (_a) {
            var ticketPersonId = _a.ticketPersonId, availableTickets = _a.availableTickets;
            return (availableTariffs[ticketPersonId] = availableTickets);
        });
        return availableTariffs;
    };
    TariffStatusService.prototype.mapBookedProductTypesToAvailableTariffs = function (bookingProductTypes) {
        var availableTariffs = {};
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (bookingTariff) { return (availableTariffs[bookingTariff.ticketPersonId] = bookingTariff.availableTickets); });
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (bookingPackageTariff) {
                            return (availableTariffs[bookingPackageTariff.ticketPersonId] = bookingPackageTariff.availableTickets);
                        });
                    }
                });
            }
        });
        return availableTariffs;
    };
    TariffStatusService.prototype.mapRemoveVoucherToAddAvailableTariffs$ = function (removeVoucher) {
        return of(removeVoucher).pipe(withLatestFrom(this.store.pipe(select(getBookedVoucherTariffByVoucherCode(removeVoucher.voucherCode)))), map(function (_a) {
            var _ = _a[0], bookedVoucherTariff = _a[1];
            var _b;
            return !!bookedVoucherTariff ? (_b = {}, _b[bookedVoucherTariff.ticketPersonId] = bookedVoucherTariff.count, _b) : {};
        }));
    };
    TariffStatusService.prototype.mapRemovedBookingProductTypesToAvailableTariffsAfterAnonymousVoucherRedeem = function (removedBookingProductTypes, bookedTariffs, currentAvailableTariffs) {
        var availableTariffs = {};
        var setAvailableTariffs = function (removedBookingTariff) {
            var bookedTariffRemoved = bookedTariffs.find(function (_a) {
                var ticketTypeId = _a.ticketTypeId, ticketPersonId = _a.ticketPersonId, voucherCode = _a.voucherCode, packageNumber = _a.packageNumber, packageIndex = _a.packageIndex;
                return removedBookingTariff.ticketTypeId === ticketTypeId &&
                    removedBookingTariff.ticketPersonId === ticketPersonId &&
                    removedBookingTariff.voucherCode === voucherCode &&
                    removedBookingTariff.packageNumber === packageNumber &&
                    removedBookingTariff.packageIndex === packageIndex;
            });
            if (bookedTariffRemoved) {
                var ticketPersonId = bookedTariffRemoved.ticketPersonId, count = bookedTariffRemoved.count;
                if (!availableTariffs[ticketPersonId]) {
                    availableTariffs[ticketPersonId] = currentAvailableTariffs[ticketPersonId] + count;
                }
                else {
                    availableTariffs[ticketPersonId] += count;
                }
            }
        };
        removedBookingProductTypes.forEach(function (removedBookingProductType) {
            if (isBookingProductTypeTariff(removedBookingProductType)) {
                removedBookingProductType.tariffs.forEach(setAvailableTariffs);
            }
            else if (isBookingProductTypePackage(removedBookingProductType)) {
                removedBookingProductType.productTypes.forEach(function (bookedPackageProductType) {
                    if (isBookingProductTypeTariff(bookedPackageProductType)) {
                        bookedPackageProductType.tariffs.forEach(setAvailableTariffs);
                    }
                });
            }
        });
        return availableTariffs;
    };
    TariffStatusService.ngInjectableDef = i0.defineInjectable({ factory: function TariffStatusService_Factory() { return new TariffStatusService(i0.inject(i1.Store), i0.inject(i2.HttpClient), i0.inject(i3.HelperService)); }, token: TariffStatusService, providedIn: "root" });
    return TariffStatusService;
}());
export { TariffStatusService };
