import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Package, PackageType } from '@products/models/package.model';
import {
  ProductGroup,
  ProductSelectionBookingStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel
} from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageComponent implements OnInit {
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() workshopStatusViewModel: ProductSelectionWorkshopStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() productGroup: ProductGroup;
  @Input() packageType: PackageType;
  @Input() package: Package;

  readonly ProductType = ProductType;

  constructor() {}

  ngOnInit() {}

  packageProductsTrackBy(index: number) {
    return index;
  }
}
