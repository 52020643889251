import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookingContingentReservation } from '@products/models/booking.model';
import { PackageType } from '@products/models/package.model';
import {
  ProductSelectionBookingStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel
} from '@products/models/product-selection.model';
import { Tariff, TariffType } from '@products/models/tariff.model';
import { VoucherCode } from '@products/models/voucher.model';
import { ContingentTariffService } from '@products/services/contingent-tariff.service';
import { highlightDay } from '@shared/app-utils';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { getBookedTariffContingentReservations } from '@store/products/booking/booking.selectors';
import { getIsCreateContingentReservationLoading } from '@store/products/status/tariff/tariff.selectors';
import { Observable, Subject, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contingent-tariff',
  providers: [ContingentTariffService],
  templateUrl: './contingent-tariff.component.html',
  styleUrls: ['./contingent-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContingentTariffComponent implements OnInit, OnDestroy {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() packageType: PackageType;
  @Input() tariffType: TariffType;
  @Input() tariff: Tariff;
  @Input() voucherCodeReleased$: Observable<VoucherCode>;
  contingentReservations$: Observable<BookingContingentReservation[]>;
  contingentReservationForm: FormGroup = new FormGroup({});
  contingentReservationShowLoader: { [contingentReservationIndex: number]: boolean } = {};

  readonly highlightDay = highlightDay;
  private readonly destroy$ = new Subject<void>();
  private readonly synchronousFormControlInitializationDelay = 0;

  constructor(private store: Store<State>, private contingentTariffService: ContingentTariffService) {}

  ngOnInit() {
    this.contingentReservations$ = this.store.pipe(
      select(
        getBookedTariffContingentReservations(
          this.tariff.ticketTypeId,
          this.tariff.ticketPersonId,
          this.tariff.voucherCode,
          this.tariff.packageNumber,
          this.tariff.packageIndex
        )
      ),
      delay(this.synchronousFormControlInitializationDelay)
    );

    this.initContingentTariffServiceState();
    this.initSetContingentReservationDayFormControls();
    this.initSetContingentReservationLoaders();
    this.initReleaseContingentReservationsOnVoucherCodeRelease();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initContingentTariffServiceState() {
    this.contingentTariffService.initState(
      this.tariff,
      this.contingentReservationForm,
      this.contingentReservationShowLoader
    );
  }

  initSetContingentReservationDayFormControls() {
    this.contingentReservations$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(contingentReservations => {
        this.contingentTariffService.setContingentReservationDayFormControls(contingentReservations);
      });
  }

  initSetContingentReservationLoaders() {
    combineLatest([this.store.pipe(select(getIsCreateContingentReservationLoading)), this.contingentReservations$])
      .pipe(
        filter(
          ([isCreateContingentReservationLoading, bookingContingentReservations]) =>
            !isCreateContingentReservationLoading ||
            bookingContingentReservations.some(bookingContingentReservation => !bookingContingentReservation.isValid)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([isCreateContingentReservationLoading, contingentReservations]) =>
        this.contingentTariffService.setOrResetContingentReservationLoaders(
          isCreateContingentReservationLoading,
          contingentReservations
        )
      );
  }

  initReleaseContingentReservationsOnVoucherCodeRelease() {
    this.voucherCodeReleased$
      .pipe(
        filter(voucherCode => this.tariff.voucherCode === voucherCode),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.contingentTariffService.setContingentReservationDayFormControls([]));
  }

  onContingentReservationSelect(contingentReservationIndex: number) {
    this.contingentTariffService.setContingentReservations([contingentReservationIndex]);
  }

  contingentReservationTrackBy(index: number) {
    return index;
  }
}
