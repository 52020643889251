import { Injectable, OnDestroy } from '@angular/core';
import { TariffValidationStateViewModel, TariffWarningMessage } from '@products/models/tariff.model';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class TariffValidationService implements OnDestroy {
  tariffValidationState$: Observable<TariffValidationStateViewModel>;

  private tariffValidationServiceState: TariffValidationStateViewModel;
  private readonly destroy$ = new Subject<void>();
  private readonly _tariffValidationState$: BehaviorSubject<TariffValidationStateViewModel> = new BehaviorSubject({
    count: 0,
    countDifference: 0,
    validatedCount: 0,
    previousValidatedCount: 0,
    bookedCount: 0,
    validatedMaxLimit: 0,
    isCountOverLimit: false,
    isTariffSoldOut: false,
    isAvailableTariffsLimitReached: false,
    numberOfAllBookedTariffs: null,
    initialNumberOfAvailableTariffs: null,
    currentNumberOfAvailableTariffs: null,
    percentageOfAvailableTariffs: 0,
    tariffLimit: null,
    maxTariffLimit: null,
    tariffLimitPerUserAccount: null,
    currentUserAccountTariffLimit: null,
    isCurrentUserAccountTariffLimitReached: false,
    allowedWorkshopsSeatsAvailable: null,
    voucherCountLimit: null,
    validateVoucherCountLimit: true,
    maxVoucherLimit: null,
    maxLimitedVoucherLimit: null,
    numberOfBookedVouchers: 0,
    numberOfBookedLimitedVouchers: 0,
    numberOfBookedOneTimeVouchers: 0,
    isTariffClassificationNormal: false,
    isTariffClassificationParking: false,
    hideWorkshopTariffCounter: false,
    areAllAllowedWorkshopsAssigned: false,
    isWorkshopsSelectionMandatoryAndHasWorkshops: false,
    isVoucher: false,
    isVoucherTypePromoCode: false,
    isVoucherTypeLimitedPromoCode: false,
    isVoucherTypeOneTimeVoucher: false,
    isVoucherIncludedPerUserAccountLimit: false,
    isVoucherLimitReached: false,
    isPackageFixedAmountLimitReached: false,
    isPackageMinAmountLimitReached: false,
    isPackageMaxAmountLimitReached: false,
    isPackageTypeAndIsPackageNotAdded: false,
    packageSettings: null,
    packageTooltipMessage: '',
    showMobilePackageTooltipMessage: false,
    tariffLimitWarningMessage: ''
  });

  constructor(private tariffCountValidationService: TariffCountValidationService) {
    this.tariffValidationState$ = this._tariffValidationState$.asObservable().distinctUntilChanged();
    this.tariffValidationState$.pipe(takeUntil(this.destroy$)).subscribe(tariffValidationState => {
      this.tariffValidationServiceState = { ...this.tariffValidationServiceState, ...tariffValidationState };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initAndValidateState(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const initialTariffValidationState: TariffValidationStateViewModel = {
      ...this.tariffValidationServiceState,
      ...tariffValidationState
    };

    this.validate(initialTariffValidationState);

    this._tariffValidationState$.next(initialTariffValidationState);
  }

  updateState(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    this._tariffValidationState$.next({ ...this.tariffValidationServiceState, ...tariffValidationState });
  }

  validateState(count: number, tariffValidationState: Partial<TariffValidationStateViewModel>) {
    tariffValidationState.count = count;

    this.validate(tariffValidationState);

    this._tariffValidationState$.next({ ...this.tariffValidationServiceState, ...tariffValidationState });
  }

  getValidatedState() {
    return this.tariffValidationServiceState;
  }

  geValidatedStateProperty(propertyName: string) {
    return propertyName in this.tariffValidationServiceState ? this.tariffValidationServiceState[propertyName] : null;
  }

  resetValidatedState(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    tariffValidationState.countDifference = 0;
    tariffValidationState.isCountOverLimit = false;
  }

  revalidateDisabledCounter(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { tariffLimitWarningMessage, isVoucherLimitReached, initialNumberOfAvailableTariffs } = tariffValidationState;

    if (tariffLimitWarningMessage || isVoucherLimitReached) {
      const resetValidationWarningMessage = [
        this.tariffCountValidationService.isTariffLimitPerUserAccountReached(tariffValidationState),
        this.tariffCountValidationService.isVoucherCountLimitReached(tariffValidationState),
        this.tariffCountValidationService.isPromoCodeVoucherTariffLimitReached(tariffValidationState),
        this.tariffCountValidationService.isLimitedPromoCodeVoucherTariffLimitReached(tariffValidationState),
        this.tariffCountValidationService.isOneTimeVoucherTariffLimitReached(tariffValidationState),
        this.tariffCountValidationService.isMaxTariffLimitReached(tariffValidationState)
      ].every(isLimitReached => !isLimitReached);

      const isAvailableTariffsLimitReached = this.tariffCountValidationService.isAvailableTariffsLimitReached(
        tariffValidationState
      );

      if (resetValidationWarningMessage) {
        tariffValidationState.tariffLimitWarningMessage = '';
        tariffValidationState.isVoucherLimitReached = false;
        tariffValidationState.isCurrentUserAccountTariffLimitReached = false;
      }

      if (isAvailableTariffsLimitReached) {
        tariffValidationState.tariffLimitWarningMessage = TariffWarningMessage.ERROR_TICKET_WARNING;
        tariffValidationState.validatedMaxLimit = initialNumberOfAvailableTariffs;
        tariffValidationState.isAvailableTariffsLimitReached = true;
      }

      if (resetValidationWarningMessage || isAvailableTariffsLimitReached)
        this._tariffValidationState$.next({
          ...this.tariffValidationServiceState,
          ...tariffValidationState
        });
    }
  }

  revalidateIsAvailableTariffsLimitReached(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    if (this.tariffCountValidationService.isAvailableTariffsLimitReached(tariffValidationState)) {
      tariffValidationState.tariffLimitWarningMessage = TariffWarningMessage.ERROR_TICKET_WARNING;
      tariffValidationState.validatedMaxLimit = tariffValidationState.initialNumberOfAvailableTariffs;
    }

    this._tariffValidationState$.next({ ...this.tariffValidationServiceState, ...tariffValidationState });
  }

  private validate(tariffValidationState: Partial<TariffValidationStateViewModel>) {
    const { count, validatedCount } = tariffValidationState;

    tariffValidationState.countDifference = count - validatedCount;
    tariffValidationState.previousValidatedCount = validatedCount;

    this.tariffCountValidationService.validateIsAvailableTariffsLimit(tariffValidationState);
    this.tariffCountValidationService.validateTariffLimitPerUserAccount(tariffValidationState);
    this.tariffCountValidationService.validatePromoCodeVoucherTariff(tariffValidationState);
    this.tariffCountValidationService.validateLimitedPromoCodeVoucherTariff(tariffValidationState);
    this.tariffCountValidationService.validateOneTimeVoucherTariff(tariffValidationState);
    /*
      TODO: Workshop tariff max limit validation required shared booking tariff count selector of allowed workshops, because change made in update workshop available seats logic
      Workshop tariff limit validation because workshop validation is set by workshop validation service. If user selects tariffs that are exceeding
      allowed workshops available seats limit and workshop selection is mandatory then workshop validation for step form will disable the next step until
      the user removes tariff count to same or less than allowed workshops available seats count.
    */
    // this.tariffCountValidationService.validateWorkshopTariff(tariffValidationState);
    this.tariffCountValidationService.validateMaxTariffLimit(tariffValidationState);
    this.tariffCountValidationService.validateVoucherCountLimit(tariffValidationState);

    this.tariffCountValidationService.validateInLimitCounter(count, tariffValidationState);

    this.tariffCountValidationService.validatePackageTariffLimit(tariffValidationState);

    this.resetValidatedState(tariffValidationState);
  }
}
