import { Injectable } from '@angular/core';
import { State, getSelfRegistration } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import {
  ActionTypes as BookingActionTypes,
  RemovePackageFromBookingList,
  RemoveReleasedVoucherProductFromBookingList,
  SetPackageInBookingList,
  SetVoucherForAnonymousProductInBookingList,
  SetVoucherProductInBookingList
} from '@store/products/booking/booking.actions';
import {
  RemovePackageFromSelectionList,
  RemoveVoucherCodeProductFromSelectionList,
  RemoveVoucherProductFromSelectionList,
  SetPackageInSelectionList,
  SetVoucherProductInSelectionList
} from '@store/products/product-selection/product-selection.actions';
import {
  ActionTypes as ProductActionTypes,
  RemoveReleasedAndRemovedProductBookingVoucher
} from '@store/products/product.actions';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ProductSelectionEffect {
  @Effect()
  addVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<SetVoucherProductInBookingList | SetVoucherForAnonymousProductInBookingList>(
      BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST
    ),
    withLatestFrom(this.store.pipe(select(getSelfRegistration))),
    map(
      ([{ payload }, isSelfRegistration]) =>
        new SetVoucherProductInSelectionList({ bookingProductTypes: payload, isSelfRegistration })
    )
  );

  @Effect()
  removeVoucherByVoucherCode$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveReleasedAndRemovedProductBookingVoucher>(
      ProductActionTypes.REMOVE_RELEASED_AND_REMOVED_BOOKING_VOUCHER
    ),
    map(({ payload }) => new RemoveVoucherCodeProductFromSelectionList(payload))
  );

  @Effect()
  removeVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveReleasedVoucherProductFromBookingList>(
      BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST
    ),
    map(({ payload }) => new RemoveVoucherProductFromSelectionList(payload))
  );

  @Effect()
  addPackage$: Observable<Action> = this.actions$.pipe(
    ofType<SetPackageInBookingList>(BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST),
    map(({ payload }) => new SetPackageInSelectionList(payload))
  );

  @Effect()
  removePackage$: Observable<Action> = this.actions$.pipe(
    ofType<RemovePackageFromBookingList>(BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST),
    map(({ payload }) => new RemovePackageFromSelectionList(payload))
  );

  constructor(private actions$: Actions, private store: Store<State>) {}
}
