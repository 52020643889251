import { createSelector } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { VoucherType } from '@products/models/voucher.model';
import { getProductBookingState } from '@store/products/product.selectors';
import cloneDeep from 'lodash.clonedeep';
/**
 * Get booked products list
 * @description Lists all booked products
 * @returns Booking or empty array
 */
export var getBookedProducts = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.list; });
/**
 * Get if anonymous product is sent to api
 * @description Gets is anonymous product sent to API via prepareDataForSaveAndSend method
 * @returns True if anonymous product was sent with prepareDataForSaveAndSend method, otherwise false
 */
export var isAnonymousProductSentToAPI = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.isAnonymousProductSentToAPI; });
/**
 * Get claimed ticket hash
 * @description Gets claimed ticket hash
 * @returns Claimed ticket hash
 */
export var getClaimedTicketHash = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.claimedTicketHash; });
/**
 * Gets if claimed ticket hash is valid
 * @description Gets if claimed ticket hash is valid
 * @returns true if claimed ticket hash is valid
 */
export var getClaimedTicketHashValid = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.claimedTicketHashValid; });
/**
 * Get product countdown
 * @description Gets countdown for products
 * @returns number which represents timestamp of when last product was booked
 */
export var getProductCountdown = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.countdown.product; });
/**
 * Get workshop countdown
 * @description Gets countdown for workshops
 * @returns number which represents timestamp of when last workshop was booked
 */
export var getWorkshopCountdown = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.countdown.workshop; });
/**
 * Get vouchers countdown
 * @description Get countdowns for vouchers
 * @returns array of booked vouchers which contains voucher code and timestamp
 */
export var getVouchersCountdown = createSelector(getProductBookingState, function (productBookingState) { return productBookingState.countdown.voucher; });
/**
 * Get all tariff types from bookings
 * @description Get all tariff types from bookings (package tariff types not included)
 * @return Tariff types or empty array
 */
export var getBookedTariffTypes = createSelector(getBookedProducts, function (bookedProductTypes) {
    var bookedTariffTypes = [];
    bookedProductTypes.forEach(function (bookedProductType) {
        if (isBookingProductTypeTariff(bookedProductType)) {
            bookedTariffTypes.push(bookedProductType);
        }
    });
    return bookedTariffTypes;
});
/**
 * Get specified booked tariff type
 * @description Get booked tariff by specified ticketTypeId (package tariff types not included)
 * @param ticketTypeId
 * @returns Tariff type or null
 */
export var getBookedTariffTypeByTicketTypeId = function (ticketTypeId) {
    return createSelector(getBookedTariffTypes, function (bookedTariffTypes) {
        if (!bookedTariffTypes.length) {
            return null;
        }
        return bookedTariffTypes.find(function (bookedTariffType) { return bookedTariffType.ticketTypeId === ticketTypeId; }) || null;
    });
};
/**
 * Get booked tariffs
 * @description Get bookings tariff (package tariff not included)
 * @returns Booked tariff or empty array
 */
export var getBookedTariffs = createSelector(getBookedTariffTypes, function (bookedTariffTypes) {
    var bookedTariffs = [];
    bookedTariffTypes.forEach(function (bookedTariffType) {
        bookedTariffType.tariffs.forEach(function (bookedTariff) { return bookedTariffs.push(bookedTariff); });
    });
    return bookedTariffs;
});
/**
 * Get booked tariff by ticket person id
 * @description Get bookings tariff by specified ticketPersonId (package tariff not included)
 * @param ticketPersonId
 * @returns Booked tariff or null
 */
export var getBookedTariffByTicketPersonId = function (ticketPersonId) {
    return createSelector(getBookedTariffs, function (bookedTariffs) {
        if (!bookedTariffs.length) {
            return null;
        }
        return bookedTariffs.find(function (bookingTicket) { return bookingTicket.ticketPersonId === ticketPersonId; }) || null;
    });
};
/**
 * Get booked tariff product type by ticket person id
 * @description Get booked tariff product type by specified ticketPersonId (package tariff not included)
 * @param ticketPersonId
 * @returns Booked tariff or empty booking tariff type
 */
export var getBookedTariffTypeAndTariffByTicketPersonId = function (ticketPersonId) {
    return createSelector(getBookedTariffTypes, function (bookedTariffTypes) {
        var bookedTariffType = bookedTariffTypes.find(function (bookedTariffType) {
            return bookedTariffType.tariffs.some(function (bookingTariff) { return bookingTariff.ticketPersonId === ticketPersonId; });
        });
        if (!bookedTariffType) {
            return null;
        }
        var bookedTariffTypeClone = cloneDeep(bookedTariffType);
        bookedTariffTypeClone.tariffs = bookedTariffTypeClone.tariffs.filter(function (bookedTariff) { return bookedTariff.ticketPersonId === ticketPersonId; });
        return bookedTariffTypeClone;
    });
};
/**
 * Get booked tariff product type by ticket person id and voucher code
 * @description Get booked tariff product type by specified ticketPersonId (package tariff not included) and voucher code (not required)
 * @param ticketPersonId
 * @returns Booked tariff or empty booking tariff type
 */
export var getBookedTariffTypeAndTariffByTicketPersonIdAndVoucherCode = function (ticketPersonId, voucherCode) {
    return createSelector(getBookedTariffTypes, function (bookedTariffTypes) {
        var bookedTariffType = bookedTariffTypes.find(function (bookedTariffType) {
            return bookedTariffType.tariffs.some(function (bookingTariff) { return bookingTariff.ticketPersonId === ticketPersonId; });
        });
        if (!bookedTariffType) {
            return null;
        }
        var bookedTariffTypeClone = cloneDeep(bookedTariffType);
        bookedTariffTypeClone.tariffs = bookedTariffTypeClone.tariffs.filter(function (bookedTariff) { return bookedTariff.ticketPersonId === ticketPersonId && bookedTariff.voucherCode === voucherCode; });
        return bookedTariffTypeClone;
    });
};
/**
 * Get booked package tariff types
 * @description Get booked packages tariff types by ticket type id
 * @param ticketTypeId
 * @returns Booked tariff types or empty array
 */
var getBookedPackageTariffTypesByTicketTypeId = function (ticketTypeId) {
    return createSelector(getBookedProducts, function (bookedProductTypes) {
        var bookedTariffTypes = [];
        bookedProductTypes.forEach(function (bookedProductType) {
            if (isBookingProductTypePackage(bookedProductType)) {
                bookedProductType.productTypes.forEach(function (packageProductType) {
                    if (isBookingProductTypeTariff(packageProductType)) {
                        if (packageProductType.ticketTypeId === ticketTypeId) {
                            bookedTariffTypes.push(packageProductType);
                        }
                    }
                });
            }
        });
        return bookedTariffTypes;
    });
};
var ɵ0 = getBookedPackageTariffTypesByTicketTypeId;
/**
 * Get all booked packages
 * @description Get all booked packages
 * @returns Booked package types or empty array
 */
export var getBookedPackages = createSelector(getBookedProducts, function (bookedProductTypes) {
    var bookedPackageTypes = [];
    bookedProductTypes.forEach(function (bookedProductType) {
        if (isBookingProductTypePackage(bookedProductType)) {
            bookedPackageTypes.push(bookedProductType);
        }
    });
    return bookedPackageTypes;
});
/**
 * Get booked packages by package number
 * @description Get all booked package types by specified package number
 * @param packageNumber
 * @returns Booked package types or empty array
 */
export var getBookedPackagesByPackageNumber = function (packageNumber) {
    return createSelector(getBookedPackages, function (bookingPackageTypes) {
        return bookingPackageTypes.filter(function (bookedPackageType) { return bookedPackageType.packageNumber === packageNumber; }) || [];
    });
};
/**
 * Get booked package by package number and index
 * @description Get all booked packages by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package type or null
 */
export var getBookedPackageByPackageNumberAndIndex = function (packageNumber, packageIndex) {
    return createSelector(getBookedPackagesByPackageNumber(packageNumber), function (bookedPackageTypes) {
        return bookedPackageTypes.find(function (bookedPackageType) { return bookedPackageType.packageIndex === packageIndex; }) || null;
    });
};
/**
 * Get booked packages by package number and indexes
 * @description Get booked packages with specified package indexes
 * @param removePackageBooking
 * @returns Booked package types or empty array
 */
export var getBookedPackageTypeByPackageNumberAndIndexes = function (removePackageBooking) {
    return createSelector(getBookedPackagesByPackageNumber(removePackageBooking.packageNumber), function (bookedPackages) {
        return bookedPackages.filter(function (bookedPackage) { return removePackageBooking.packageIndexes.includes(bookedPackage.packageIndex); });
    });
};
/**
 * Get package product type by package and ticketPersonId
 * @description Get package product type by package number, index and ticketPersonId in package tariff
 * @param packageNumber
 * @param packageIndex
 * @param ticketPersonId
 * @returns Booked package or null
 */
export var getBookedPackageTypeByPackageAndTicketPersonId = function (packageNumber, packageIndex, ticketTypeId, ticketPersonId) {
    return createSelector(getBookedPackageByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackage) {
        if (!bookedPackage) {
            return null;
        }
        var bookedPackageClone = cloneDeep(bookedPackage);
        var bookedPackageTariffType = bookedPackageClone.productTypes.find(function (bookedProductType) {
            if (isBookingProductTypeTariff(bookedProductType)) {
                return bookedProductType.tariffs.some(function (packageTariff) {
                    return packageTariff.ticketTypeId === ticketTypeId && packageTariff.ticketPersonId === ticketPersonId;
                });
            }
        });
        if (isBookingProductTypeTariff(bookedPackageTariffType)) {
            bookedPackageTariffType.tariffs = bookedPackageTariffType.tariffs.filter(function (bookedPackageTariff) { return bookedPackageTariff.ticketPersonId === ticketPersonId; });
            bookedPackageClone.productTypes = [bookedPackageTariffType];
        }
        else {
            return null;
        }
        return bookedPackageClone;
    });
};
/**
 * Get booked package product types
 * @description Get package product types by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package product types or empty array
 */
export var getBookedPackageProductTypesByPackageNumberAndIndex = function (packageNumber, packageIndex) {
    return createSelector(getBookedPackageByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackage) {
        if (!bookedPackage) {
            return [];
        }
        return bookedPackage.productTypes;
    });
};
/**
 * Get booked package tariff types
 * @description Get booked package tariff types by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked package tariff types or empty array
 */
export var getBookedPackageTariffTypesByPackageNumberAndIndex = function (packageNumber, packageIndex) {
    return createSelector(getBookedPackageProductTypesByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackageProductTypes) {
        if (!bookedPackageProductTypes.length) {
            return [];
        }
        var bookedPackageTariffTypes = [];
        bookedPackageProductTypes
            .filter(isBookingProductTypeTariff)
            .forEach(function (bookedPackageTariffType) { return bookedPackageTariffTypes.push(bookedPackageTariffType); });
        return bookedPackageTariffTypes;
    });
};
/**
 * Get booked package tariffs
 * @description Get booked package tariffs by specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Booked tariffs or empty array
 */
export var getBookedPackageTariffsByPackageNumberAndIndex = function (packageNumber, packageIndex) {
    return createSelector(getBookedPackageTariffTypesByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackageTariffTypes) {
        var bookedPackageTariffs = [];
        bookedPackageTariffTypes.forEach(function (bookedPackageTariffType) {
            return bookedPackageTariffType.tariffs.forEach(function (bookedPackageTariff) { return bookedPackageTariffs.push(bookedPackageTariff); });
        });
        return bookedPackageTariffs;
    });
};
/**
 * Get booked package ticket
 * @description Get booked package tariff by specified package number, index and ticket person id
 * @param packageNumber
 * @param packageIndex
 * @param ticketPersonId
 * @returns Booked tariff or null
 */
export var getBookedPackageTariffByTicketPersonId = function (packageNumber, packageIndex, ticketPersonId) {
    return createSelector(getBookedPackageTariffsByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackageTariffs) {
        return bookedPackageTariffs.find(function (bookedPackageTariff) { return bookedPackageTariff.ticketPersonId === ticketPersonId; }) || null;
    });
};
/**
 * Get booked package tariffs
 * @description Get package booking tariffs (regular tariff not included)
 * @returns Booked package tariff or empty array
 */
export var getBookedPackageTariffs = createSelector(getBookedPackages, function (bookedPackageTypes) {
    var bookedTariffs = [];
    bookedPackageTypes.forEach(function (bookedPackageType) {
        bookedPackageType.productTypes.forEach(function (bookedPackageProductType) {
            if (isBookingProductTypeTariff(bookedPackageProductType)) {
                bookedPackageProductType.tariffs.forEach(function (bookedPackageTariff) { return bookedTariffs.push(bookedPackageTariff); });
            }
        });
    });
    return bookedTariffs;
});
/**
 * Get all booked tariffs
 * @description Get all tariffs from bookings
 * @returns Booked tariff or empty array
 */
export var getAllBookedTariffs = createSelector(getBookedTariffs, getBookedPackageTariffs, function (bookedTariffs, bookedPackageTariffs) {
    return bookedTariffs.concat(bookedPackageTariffs);
});
/**
 * Get all booked tariff types
 * @description Get all booked tariff types by specified ticket type id
 * @param ticketTypeId
 * @returns Booked tariff types or empty array
 */
export var getAllBookedTariffTypesByTicketTypeId = function (ticketTypeId) {
    return createSelector(getBookedTariffTypeByTicketTypeId(ticketTypeId), getBookedPackageTariffTypesByTicketTypeId(ticketTypeId), function (bookedTariffType, bookedPackageTariffTypes) { return [bookedTariffType].concat(bookedPackageTariffTypes); });
};
/**
 * Get all booked tariffs
 * @description Get all cart tariffs by ticket person id
 * @param ticketTypeId
 * @param ticketPersonId
 * @returns Booked tariffs or empty array
 */
export var getAllBookedTariffsByTicketPersonId = function (ticketPersonId) {
    return createSelector(getAllBookedTariffs, function (allBookedTariffs) {
        var bookedTariffs = [];
        allBookedTariffs.forEach(function (bookedTariff) {
            if (bookedTariff.ticketPersonId === ticketPersonId) {
                bookedTariffs.push(bookedTariff);
            }
        });
        return bookedTariffs;
    });
};
/**
 * Get booked voucher tariffs
 * @description Get all booked vouchers form product bookings
 * @returns Booked voucher tariffs or empty array
 */
export var getVoucherTariffBookings = createSelector(getAllBookedTariffs, function (bookedTariffs) { return bookedTariffs.filter(function (bookedTariff) { return !!bookedTariff.voucherCode; }); });
/**
 * Get booked promo code vouchers
 * @description Get booked promo code vouchers from product bookings
 * @returns Booked promo code voucher tariffs or empty array
 */
export var getBookedPromoCodeVoucherTariffs = createSelector(getVoucherTariffBookings, function (bookedVoucherTariffs) {
    return bookedVoucherTariffs.filter(function (bookedVoucherTariff) { return bookedVoucherTariff.voucherType === VoucherType.PromoCode; });
});
/**
 * Get booked promo code vouchers count
 * @description Get booked promo code vouchers count from product bookings
 * @returns Booked promo code voucher tariffs count
 */
export var getBookedPromoCodeVoucherTariffsCount = createSelector(getBookedPromoCodeVoucherTariffs, function (bookedPromoCodeVoucherTariffs) {
    return bookedPromoCodeVoucherTariffs.reduce(function (numberOfBookedPromoCodeVoucherTariffs, bookedPromoCodeVoucherTariff) {
        return numberOfBookedPromoCodeVoucherTariffs + bookedPromoCodeVoucherTariff.count;
    }, 0);
});
/**
 * Get booked limited promo code vouchers
 * @description Get booked limited promo code vouchers from product bookings
 * @returns Booked limited promo code voucher tariffs or empty array
 */
export var getBookedLimitedPromoCodeVoucherTariffs = createSelector(getVoucherTariffBookings, function (bookedVoucherTariffs) {
    return bookedVoucherTariffs.filter(function (bookedVoucherTariff) { return bookedVoucherTariff.voucherType === VoucherType.LimiterPromoCode; });
});
/**
 * Get booked limited promo code vouchers count
 * @description Get booked limited promo code vouchers count from product bookings
 * @returns booked limited promo code voucher tariffs count
 */
export var getBookedLimitedPromoCodeVoucherTariffsCount = createSelector(getBookedLimitedPromoCodeVoucherTariffs, function (bookedLimitedPromoCodeVoucherTariffs) {
    return bookedLimitedPromoCodeVoucherTariffs.reduce(function (numberOfBookedLimitedPromoCodeVoucherTariffs, bookedLimitedPromoCodeVoucherTariff) {
        return numberOfBookedLimitedPromoCodeVoucherTariffs + bookedLimitedPromoCodeVoucherTariff.count;
    }, 0);
});
/**
 * Get booked one time vouchers
 * @description Get booked one time vouchers from product bookings
 * @returns Booked one time voucher tariffs or empty array
 */
export var getBookedOneTimeVoucherTariffs = createSelector(getVoucherTariffBookings, function (bookedVoucherTariffs) {
    return bookedVoucherTariffs.filter(function (bookedVoucherTariff) { return bookedVoucherTariff.voucherType === VoucherType.OneTimeVoucher; });
});
/**
 * Get booked one time vouchers count
 * @description Get booked one time vouchers count from product bookings
 * @returns booked one time voucher tariffs count
 */
export var getBookedOneTimeVoucherTariffsCount = createSelector(getBookedOneTimeVoucherTariffs, function (bookedOneTimeVoucherTariffs) {
    return bookedOneTimeVoucherTariffs.reduce(function (numberOfBookedOneTimeVoucherTariffs, bookedOneTimeVoucherTariff) {
        return numberOfBookedOneTimeVoucherTariffs + bookedOneTimeVoucherTariff.count;
    }, 0);
});
/**
 * Get booked voucher count sorted by voucher type
 * @description Get booked voucher count sorted by voucher type from product bookings
 * @returns Object of voucher type keys and count as value
 */
export var getBookedVoucherCountSortedByVoucherType = createSelector(getBookedPromoCodeVoucherTariffsCount, getBookedLimitedPromoCodeVoucherTariffsCount, getBookedOneTimeVoucherTariffsCount, function (bookedPromoCodeVoucherTariffsCount, bookedLimitedPromoCodeVoucherTariffsCount, bookedOneTimeVoucherTariffsCount) {
    var _a;
    return (_a = {},
        _a[VoucherType.PromoCode] = bookedPromoCodeVoucherTariffsCount,
        _a[VoucherType.LimiterPromoCode] = bookedLimitedPromoCodeVoucherTariffsCount,
        _a[VoucherType.OneTimeVoucher] = bookedOneTimeVoucherTariffsCount,
        _a);
});
/**
 * Get booked boucher tariff by voucher code
 * @description Get booked voucher tariff by provided voucher code
 * @param voucherCode
 * @returns Booked voucher tariff or null
 */
export var getBookedVoucherTariffByVoucherCode = function (voucherCode) {
    return createSelector(getVoucherTariffBookings, function (bookedTariffs) {
        return bookedTariffs.find(function (bookedTariff) { return bookedTariff.voucherCode === voucherCode; }) || null;
    });
};
/**
 * Get booked voucher tariff by ticket person id
 * @description Get booked voucher tariff by provided ticket person id
 * @param ticketPersonId
 * @returns Booked voucher tariffs or empty array
 */
export var getVoucherTariffsBookingsByTicketPersonId = function (ticketPersonId) {
    return createSelector(getVoucherTariffBookings, function (bookedTariffs) {
        return bookedTariffs.filter(function (bookedTariff) { return bookedTariff.ticketPersonId === ticketPersonId; }) || [];
    });
};
/**
 * Get booked total ticket count per voucher tariff by ticket person id
 * @description Get booked total ticket count per voucher tariff by ticket person id
 * @param ticketPersonId
 * @returns Number of booked voucher tariffs
 */
export var getTotalTicketCountPerVoucherTariffByTicketPersonId = function (ticketPersonId) {
    return createSelector(getVoucherTariffsBookingsByTicketPersonId(ticketPersonId), function (bookedTariffs) {
        return bookedTariffs.reduce(function (totalCount, bookedTariff) { return totalCount + bookedTariff.count; }, 0);
    });
};
/**
 * Get remaining ticket count for voucher tariff
 * @description Get remaining voucher tickets count after removing voucher codes ticket count
 * @param voucherCode
 * @param ticketPersonId
 * @returns Number of booked tickets for voucher tariff
 */
export var getRemainingTicketCountForVoucherTariff = function (ticketPersonId, voucherCode) {
    return createSelector(getBookedVoucherTariffByVoucherCode(voucherCode), getTotalTicketCountPerVoucherTariffByTicketPersonId(ticketPersonId), function (bookedVoucherTariffByVoucherCode, totalTicketCountPerVoucherTariffByTicketPersonId) {
        if (!bookedVoucherTariffByVoucherCode) {
            return totalTicketCountPerVoucherTariffByTicketPersonId;
        }
        return totalTicketCountPerVoucherTariffByTicketPersonId - bookedVoucherTariffByVoucherCode.count;
    });
};
/**
 * Get booked anonymous tariffs
 * @description Get all booked anonymous tariffs from tariffs, vouchers and packages
 * @returns Tariffs or empty array
 */
export var getAnonymousTariffs = createSelector(getVoucherTariffBookings, function (bookedTariffs) { return bookedTariffs.filter(function (bookedTariff) { return bookedTariff.isAnonymous; }); });
/**
 * Check if anonymous product is booked
 * @description Check if anonymous product is booked from tariffs, vouchers and packages
 * @returns Boolean
 */
export var isAnonymousProductBooked = createSelector(getAnonymousTariffs, function (bookedAnonymousTariffs) { return !!bookedAnonymousTariffs.length; });
/**
 * Get if anonymous products should be send to API
 * @description Checks if there's anonymous product booked and
 * if it was not sent to API via prepareDataForSaveAndSend method
 * @returns True if anonymous product should be send to API, otherwise false
 */
export var shouldSendAnonymousProductToAPI = createSelector(isAnonymousProductBooked, isAnonymousProductSentToAPI, function (isAnonymousProductBooked, isAnonymousProductSentToAPI) {
    return isAnonymousProductBooked && !isAnonymousProductSentToAPI;
});
/**
 * Get all holder uuids from booked products
 * @description Get all holder uuids in booked products
 * @returns Holder uuids or empty array
 */
export var getAllHolderUuidsFromBookedProducts = createSelector(getAllBookedTariffs, function (bookedTariffs) {
    var bookedHolderUuids = [];
    bookedTariffs.forEach(function (bookedTariff) { return bookedHolderUuids.push.apply(bookedHolderUuids, bookedTariff.holderUuids); });
    return bookedHolderUuids;
});
/**
 * Get tariff by ticket holder uuid
 * @description Get tariff by assigned ticket holder uuid
 * @param holderUuid
 * @returns Booked tariff or null
 */
export var getBookedTariffByHolderUuid = function (holderUuid) {
    return createSelector(getAllBookedTariffs, function (bookedTariffs) {
        return bookedTariffs.find(function (bookedTariff) { return bookedTariff.holderUuids.includes(holderUuid); }) || null;
    });
};
/**
 * Get booked tariff
 * @description Get booked tariff
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Booked tariff or null
 */
export var getBookedTariff = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getAllBookedTariffs, function (bookedTariffs) {
        return bookedTariffs.find(function (bookedTariff) {
            return bookedTariff.ticketTypeId === ticketTypeId &&
                bookedTariff.ticketPersonId === ticketPersonId &&
                bookedTariff.voucherCode === voucherCode &&
                bookedTariff.packageNumber === packageNumber &&
                bookedTariff.packageIndex === packageIndex;
        }) || null;
    });
};
/**
 * Get booked tariff holder uuids
 * @description Get booked tariff holder uuids
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Holder uuids or empty array
 */
export var getBookedTariffHolderUuids = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) { return (bookedTariff ? bookedTariff.holderUuids : []); });
};
/**
 * Get booked tariff count
 * @description Get number of booked tariffs
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export var getBookedTariffCount = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) { return (!!bookedTariff ? bookedTariff.count : 0); });
};
/**
 * Get booked tariff price
 * @description Get price of booked tariff
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export var getBookedTariffPrice = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) { return (!!bookedTariff ? bookedTariff.price : 0); });
};
/**
 * Get booked tariff workshops
 * @description Get booked tariff assigned workshops
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Workshops or empty array
 */
export var getBookedTariffWorkshops = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (tariff) { return tariff.workshops.length || []; });
};
/**
 * Get booked tariff workshop validity
 * @description Get is booked tariff workshop valid
 * @param isWorkshopsSelectionMandatory
 * @param isWorkshopsSelectionMandatoryForZeroPriceTickets
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export var areBookedTariffWorkshopsValid = function (isWorkshopsSelectionMandatory, isWorkshopsSelectionMandatoryForZeroPriceTickets, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) {
        if (!bookedTariff) {
            return true;
        }
        return false;
    });
};
/**
 * Gets all booked tariffs with workshops
 * @description Get all booked tariffs which have booked at least one workshop
 * @returns Tariffs which have booked at least one workshop
 */
export var getAllBookedWorkshopTariffs = createSelector(getAllBookedTariffs, function (bookedTariffs) { return bookedTariffs.filter(function (bookedTariff) { return bookedTariff.workshops.length; }) || []; });
/**
 * Get all assigned workshop holder uuids
 * @description Get all assigned workshop holder uuids by provided workshop id
 * @param workshopId
 * @returns Holder uuids or empty array
 */
export var getAssignedWorkshopHolderUuidsByWorkshopId = function (workshopId) {
    return createSelector(getAllBookedWorkshopTariffs, function (bookedTariffs) {
        var assignedWorkshopHolderUuids = [];
        bookedTariffs.forEach(function (bookedTariff) {
            bookedTariff.workshops.forEach(function (bookedTariffWorkshop) {
                if (bookedTariffWorkshop.id === workshopId) {
                    bookedTariffWorkshop.holderUuids.forEach(function (workshopHolderUuid) {
                        return assignedWorkshopHolderUuids.push(workshopHolderUuid);
                    });
                }
            });
        });
        return assignedWorkshopHolderUuids;
    });
};
/**
 * Get all booked contingent tariffs
 * @description Get all booked contingent tariffs with contingent reservation
 * @return Booked tariff or empty array
 */
export var getAllBookedContingentTariffs = createSelector(getAllBookedTariffs, function (bookedTariffs) {
    var contingentBookedTariffs = [];
    bookedTariffs.forEach(function (bookedTariff) {
        if (bookedTariff.contingentReservations.length) {
            contingentBookedTariffs.push(bookedTariff);
        }
    });
    return contingentBookedTariffs;
});
/**
 * Get all booked contingent reservations
 * @description Get all booked contingent reservations
 * @return Contingent reservation or empty array
 */
export var getAllBookedContingentReservations = createSelector(getAllBookedContingentTariffs, function (bookedTariffs) {
    var bookingContingentReservations = [];
    bookedTariffs.forEach(function (bookedTariff) {
        return bookedTariff.contingentReservations.forEach(function (contingentReservation) {
            return bookingContingentReservations.push(contingentReservation);
        });
    });
    return bookingContingentReservations;
});
/**
 * Are all booked contingent reservations valid
 * @description Check if all booked contingent reservations are valid
 * @returns Boolean
 */
export var areBookedContingentReservationsValid = createSelector(getAllBookedContingentReservations, function (bookingContingentReservations) {
    return bookingContingentReservations.every(function (bookingContingentReservation) { return bookingContingentReservation.isValid; });
});
/**
 * Is contingent reservation from date picked and invalid
 * @description Check if contingent reservation from date is picked and invalid
 * @return Boolean
 */
export var isBookedContingentReservationFromDateSetAndInvalid = createSelector(getAllBookedContingentReservations, function (bookingContingentReservations) {
    return bookingContingentReservations.some(function (bookingContingentReservation) { return bookingContingentReservation.fromDate && !bookingContingentReservation.isValid; });
});
/**
 * Get booked tariff contingent reservations
 * @description Get booked tariff contingent reservations array
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Contingent reservation or empty array
 */
export var getBookedTariffContingentReservations = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) {
        return !!bookedTariff ? bookedTariff.contingentReservations : [];
    });
};
/**
 * Are booked tariff contingent reservations valid
 * @description Check if booked tariff contingent reservations are valid
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export var areBookedTariffContingentReservationsValid = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariffContingentReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookingContingentReservations) {
        return bookingContingentReservations.every(function (bookingContingentReservation) { return bookingContingentReservation.isValid; });
    });
};
/**
 * Get booked tariff contingent reservations with selected date
 * @description Get booked tariff contingent reservations array with selected date
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Contingent reservation or empty array
 */
export var getBookedTariffContingentReservationsWithSelectedDate = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariffContingentReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedContingentReservations) {
        return bookedContingentReservations.filter(function (contingentReservation) { return contingentReservation.fromDate; });
    });
};
/**
 * Get all booked parking tariffs
 * @description Get all booked parking tariffs with parking reservation
 * @return Booked tariff or empty array
 */
export var getAllBookedParkingTariffs = createSelector(getAllBookedTariffs, function (bookedTariffs) {
    var parkingBookedTariffs = [];
    bookedTariffs.forEach(function (bookedTariff) {
        if (bookedTariff.parkingReservations.length) {
            parkingBookedTariffs.push(bookedTariff);
        }
    });
    return parkingBookedTariffs;
});
/**
 * Get all booked parking reservations
 * @description Get all booked parking reservations by ticketIndex returned from API response
 * @return Parking reservation or empty array
 */
export var getAllBookedParkingReservations = createSelector(getAllBookedParkingTariffs, function (bookedTariffs) {
    var bookingParkingReservations = [];
    bookedTariffs.forEach(function (bookedTariff) {
        return bookedTariff.parkingReservations.forEach(function (parkingReservation) {
            return bookingParkingReservations.push(parkingReservation);
        });
    });
    return bookingParkingReservations;
});
/**
 * Are all booked parking reservations valid
 * @description Check if all booked parking reservations are valid
 * @returns Boolean
 */
export var areBookedParkingReservationsValid = createSelector(getAllBookedParkingReservations, function (bookingParkingReservations) {
    return bookingParkingReservations.every(function (bookingParkingReservation) { return bookingParkingReservation.isValid; });
});
/**
 * Get booked tariff parking reservations
 * @description Get booked tariff parking reservations array
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Parking reservation or empty array
 */
export var getBookedTariffParkingReservations = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariff(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedTariff) { return (!!bookedTariff ? bookedTariff.parkingReservations : []); });
};
/**
 * Are booked tariff Parking reservations valid
 * @description Check if booked tariff contingent reservations are valid
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export var areBookedTariffParkingReservationsValid = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariffParkingReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookingParkingReservations) {
        return bookingParkingReservations.every(function (bookingParkingReservation) { return bookingParkingReservation.isValid; });
    });
};
/**
 * Get booked tariff parking reservations with selected date
 * @description Get booked tariff parking reservations array with selected date
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Parking reservation or empty array
 */
export var getBookedTariffParkingReservationsWithSelectedDate = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariffParkingReservations(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedParkingReservations) {
        return bookedParkingReservations.filter(function (parkingReservation) { return parkingReservation.since; });
    });
};
/**
 * Get booked tariff parking reservation total price
 * @description Get booked tariff parking reservation total price
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Number
 */
export var getBookedTariffParkingReservationTotalPrice = function (ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(getBookedTariffParkingReservationsWithSelectedDate(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (bookedParkingReservations) {
        return bookedParkingReservations.reduce(function (bookedParkingReservationTotalPrice, bookedParkingReservation) {
            return bookedParkingReservationTotalPrice + bookedParkingReservation.price;
        }, 0);
    });
};
/**
 * Are booked tariff reservations valid
 * @description Check if booked tariff workshop, contingent and parking reservations are valid
 * @param isWorkshopsSelectionMandatory
 * @param isWorkshopsSelectionMandatoryForZeroPriceTickets
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Boolean
 */
export var areBookedTariffReservationsValid = function (isWorkshopsSelectionMandatory, isWorkshopsSelectionMandatoryForZeroPriceTickets, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex) {
    return createSelector(areBookedTariffWorkshopsValid(isWorkshopsSelectionMandatory, isWorkshopsSelectionMandatoryForZeroPriceTickets, ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), areBookedTariffContingentReservationsValid(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), areBookedTariffParkingReservationsValid(ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex), function (areBookedTariffWorkshopsValid, areBookedTariffContingentReservationsValid, areBookedTariffParkingReservationsValid) {
        return areBookedTariffWorkshopsValid &&
            areBookedTariffContingentReservationsValid &&
            areBookedTariffParkingReservationsValid;
    });
};
/**
 * Get booked packages count by package number
 * @description Get booked packages in package type array by package number
 * @param packageNumber
 * @returns Number of booked packages
 */
export var getBookedPackagesCount = function (packageNumber) {
    return createSelector(getBookedPackagesByPackageNumber(packageNumber), function (bookedPackages) { return bookedPackages.length; });
};
/**
 * Get unique bookings with total cunt
 * @description Get unique bookings with accumulated count
 * @return Booked tariff or empty array
 */
export var getUniqueBookedTariffsWithTotalCount = createSelector(getAllBookedTariffs, function (bookedTariffs) {
    return bookedTariffs.reduce(function (reducedTariffs, bookedTariff) {
        var reducedTariff = reducedTariffs.find(function (reducedTariff) { return reducedTariff.ticketPersonId === bookedTariff.ticketPersonId; });
        if (reducedTariff) {
            reducedTariff.count += bookedTariff.count;
            reducedTariff.holderUuids = reducedTariff.holderUuids.concat(bookedTariff.holderUuids);
        }
        else {
            reducedTariffs.push(bookedTariff);
        }
        return reducedTariffs;
    }, []);
});
/**
 * Get unique bookings for release
 * @description Get unique bookings with count set to 0
 * @return Booked tariff or empty array
 */
export var getUniqueBookedTariffsForRelease = createSelector(getAllBookedTariffs, function (bookedTariffs) {
    return bookedTariffs.reduce(function (reducedTariffs, bookedTariff) {
        var reducedTariff = reducedTariffs.find(function (reducedTariff) { return reducedTariff.ticketPersonId === bookedTariff.ticketPersonId; });
        if (reducedTariff) {
            reducedTariff.count = 0;
            reducedTariff.holderUuids = reducedTariff.holderUuids.concat(bookedTariff.holderUuids);
        }
        else {
            bookedTariff.count = 0;
            reducedTariffs.push(bookedTariff);
        }
        return reducedTariffs;
    }, []);
});
/**
 * Get shared booked ticketPersonId tariffs total count
 * @description Get shared booked ticketPersonId tariffs total count sorted by ticketPersonId (current added tariff(s) count not included)
 * @returns Shared booked tariff count object sorted by ticketPersonId
 */
export var getSharedBookedPersonIdTariffsTotalCount = function (bookingProductTypes) {
    return createSelector(getAllBookedTariffs, function (bookedTariffs) {
        var bookedTariffsCount = {};
        bookedTariffs.forEach(function (bookedTariff) {
            if (bookedTariffsCount[bookedTariff.ticketPersonId]) {
                bookedTariffsCount[bookedTariff.ticketPersonId] += bookedTariff.count;
            }
            else {
                bookedTariffsCount[bookedTariff.ticketPersonId] = bookedTariff.count;
            }
        });
        var decreaseBookingPersonIdTariffCount = function (bookingTariff) {
            var bookedTariff = bookedTariffs.find(function (bookedTariff) {
                return bookedTariff.ticketPersonId === bookingTariff.ticketPersonId &&
                    bookedTariff.voucherCode === bookingTariff.voucherCode &&
                    bookedTariff.packageNumber == bookingTariff.packageNumber &&
                    bookedTariff.packageIndex === bookingTariff.packageIndex;
            });
            if (bookedTariff) {
                bookedTariffsCount[bookedTariff.ticketPersonId] -= bookedTariff.count;
            }
        };
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(decreaseBookingPersonIdTariffCount);
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(decreaseBookingPersonIdTariffCount);
                    }
                });
            }
        });
        return bookedTariffsCount;
    });
};
/**
 * Get single package price summed up
 * @description Get booked package tariffs by index and number and sum their price * count
 * @param packageNumber
 * @param packageIndex
 * @returns Package price summed up
 */
export var getBookedPackagePriceByPackageNumberAndPackageIndex = function (packageNumber, packageIndex) {
    return createSelector(getBookedPackageTariffsByPackageNumberAndIndex(packageNumber, packageIndex), function (bookedPackageTariffs) {
        return bookedPackageTariffs.reduce(function (packageTotal, bookedPackageTariff) { return packageTotal + bookedPackageTariff.count * bookedPackageTariff.price; }, 0);
    });
};
/**
 * Get total price from all parkings
 * @description Get booked parking price
 * @returns Number which represents total price amount of all booked parkings
 */
export var getAllBookedParkingTariffsCount = createSelector(getAllBookedTariffs, function (allProductsTotalPrice) {
    return allProductsTotalPrice.filter(function (bookedTariff) { return bookedTariff.parkingReservations.length > 0; });
});
/**
 * Get total count from all booked products
 * @description Get booked products count - both single tariffs and also tariffs from packages with each single count
 * @returns Number which represents total count of all booked products
 */
export var getAllBookedProductsCount = createSelector(getAllBookedTariffs, function (allBookedTariffs) {
    return allBookedTariffs.reduce(function (totalCount, bookedTariff) { return totalCount + bookedTariff.count; }, 0);
});
/**
 * Is product count valid
 * @description Check if product is added to product booking list
 * @return Boolean
 */
export var isBookedProductsCountValid = createSelector(getAllBookedProductsCount, function (allBookedProductsCount) { return !!allBookedProductsCount; });
/**
 * Get sorted basket products
 * @description Sorts basket products to have packages, normal tariffs and parking tickets consecutively
 * @returns Array of sorted products
 */
export var getSortedBasketProducts = createSelector(getBookedProducts, function (bookedProductTypes) {
    return bookedProductTypes.sort(function (currentBookedProductType, nextBookedProductType) {
        if (isBookingProductTypePackage(currentBookedProductType)) {
            if (isBookingProductTypePackage(nextBookedProductType)) {
                return 0;
            }
            return -1;
        }
        if (isBookingProductTypeTariff(currentBookedProductType)) {
            if (isBookingProductTypePackage(nextBookedProductType)) {
                return 1;
            }
            if (isBookingProductTypeTariff(nextBookedProductType)) {
                var hasCurrentBookedTariffTypeParkingReservations = currentBookedProductType.tariffs.some(function (bookedTariff) { return !!bookedTariff.parkingReservations.length; });
                var hasNextBookedTariffTypeParkingReservations = nextBookedProductType.tariffs.some(function (bookedTariff) { return !!bookedTariff.parkingReservations.length; });
                if (hasCurrentBookedTariffTypeParkingReservations && !hasNextBookedTariffTypeParkingReservations) {
                    return 1;
                }
                if (!hasCurrentBookedTariffTypeParkingReservations && hasNextBookedTariffTypeParkingReservations) {
                    return -1;
                }
            }
        }
    });
});
/**
 * Get total price from all booked products
 * @description Get booked products price - both single tariffs and also tariffs from packages
 * @returns Number which represents total price amount of all booked products
 */
export var getAllBookedProductsTotalPrice = createSelector(getAllBookedTariffs, function (allProductsTotalPrice) {
    return allProductsTotalPrice.reduce(function (totalPrice, bookedTariff) {
        var price = bookedTariff.price, count = bookedTariff.count, workshops = bookedTariff.workshops;
        if (bookedTariff.parkingReservations.length) {
            return totalPrice + price;
        }
        workshops.forEach(function (_a) {
            var holderUuids = _a.holderUuids, workshopPrice = _a.price;
            if (workshopPrice > 0) {
                totalPrice += holderUuids.length * workshopPrice;
            }
        });
        return totalPrice + price * count;
    }, 0);
});
/**
 * Get all grouped package total price
 * @description Get all packages total price grouped by package index
 * @returns Object with key as package index and value as package total price
 */
export var getAllBookedPackagePricesGroupedByPackageIndex = createSelector(getBookedPackageTariffs, function (allBookedPackageTariffs) {
    var packagePricesByPackageIndex = {};
    allBookedPackageTariffs.forEach(function (packageTariff) {
        var packageIndex = packageTariff.packageIndex, count = packageTariff.count, price = packageTariff.price, workshops = packageTariff.workshops;
        var packageTariffTotalPrice = count * price;
        if (!packagePricesByPackageIndex[packageIndex]) {
            packagePricesByPackageIndex[packageIndex] = packageTariffTotalPrice;
        }
        else {
            packagePricesByPackageIndex[packageIndex] += packageTariffTotalPrice;
        }
        packagePricesByPackageIndex[packageIndex] = workshops.reduce(function (packageTotalPrice, _a) {
            var holderUuids = _a.holderUuids, workshopPrice = _a.price;
            return packageTotalPrice + holderUuids.length * workshopPrice;
        }, packagePricesByPackageIndex[packageIndex]);
    });
    return packagePricesByPackageIndex;
});
/**
 * Get all grouped packages subtotal price
 * @description Get all packages subtotal total price grouped by package number
 * @returns Object with key as package index and value as package subtotal price
 */
export var getAllBookedPackagePricesGroupedByPackageNumber = createSelector(getBookedPackageTariffs, function (allBookedPackageTariffs) {
    var packagePricesByPackageNumber = {};
    allBookedPackageTariffs.forEach(function (packageTariff) {
        var packageNumber = packageTariff.packageNumber, count = packageTariff.count, price = packageTariff.price, workshops = packageTariff.workshops;
        var packageTariffTotalPrice = count * price;
        if (!packagePricesByPackageNumber[packageNumber]) {
            packagePricesByPackageNumber[packageNumber] = packageTariffTotalPrice;
        }
        else {
            packagePricesByPackageNumber[packageNumber] += packageTariffTotalPrice;
        }
        packagePricesByPackageNumber[packageNumber] = workshops.reduce(function (packageTotalPrice, _a) {
            var holderUuids = _a.holderUuids, workshopPrice = _a.price;
            return packageTotalPrice + holderUuids.length * workshopPrice;
        }, packagePricesByPackageNumber[packageNumber]);
    });
    return packagePricesByPackageNumber;
});
export { ɵ0 };
