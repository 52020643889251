/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-bar-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../age-rating/age-rating.component.ngfactory";
import * as i3 from "../../age-rating/age-rating.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i7 from "./top-bar-widget.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/services-with-reducers/user/user.service";
import * as i10 from "@ngrx/store";
import * as i11 from "../../../shared/window-size/window-size.service";
import * as i12 from "../../../shared/services-with-reducers/customization/customization.service";
import * as i13 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i14 from "../../../shared/services-with-reducers/step-forms/steps-forms.service";
var styles_TopBarWidgetComponent = [i0.styles];
var RenderType_TopBarWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarWidgetComponent, data: {} });
export { RenderType_TopBarWidgetComponent as RenderType_TopBarWidgetComponent };
function View_TopBarWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "operator-logo"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.env.friedrichshafen, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TopBarWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-age-rating", [], null, null, null, i2.View_AgeRatingComponent_0, i2.RenderType_AgeRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.AgeRatingComponent, [], { rating: [0, "rating"], adjustPositions: [1, "adjustPositions"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { top: 0, right: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.ageRating$)); var currVal_1 = _ck(_v, 3, 0, "10px", "25px"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TopBarWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "navigation-wrapper menu-button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "menu-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenuButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "menu-button_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "nav-icon"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("navigation.menu")))); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isMenuOpen; _ck(_v, 6, 0, currVal_1); }); }
function View_TopBarWidgetComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.changeLanguage(_v.context.$implicit);
        var pd_0 = (_co.closeMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_TopBarWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "logo-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWidgetComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TopBarWidgetComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWidgetComponent_4)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 10, "nav", [["class", "navigation-wrapper menu-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [["class", "navigation-content language"]], [[2, "open", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togleSubmenuButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, ":svg:svg", [["class", "icon icon-earth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/defs.svg#icon-earth"], ["href", "assets/defs.svg#icon-earth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "toggle-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "lang-submenu-wrapper"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TopBarWidgetComponent_5)), i1.ɵdid(20, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.ageRating$)); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.viewport <= _co.mediumBreakpoin); _ck(_v, 10, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform(_co.supportedLanguages$)); _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isLangMenuOpen; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.currentLanguage; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.isLangMenuOpen; _ck(_v, 17, 0, currVal_5); }); }
export function View_TopBarWidgetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWidgetComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayHeader; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TopBarWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-bar-widget", [], null, null, null, View_TopBarWidgetComponent_0, RenderType_TopBarWidgetComponent)), i1.ɵdid(1, 245760, null, 0, i7.TopBarWidgetComponent, [i8.Router, i9.UserService, i10.Store, i11.WindowSizeService, i12.CustomizationService, i13.HelperService, i14.StepsFormsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopBarWidgetComponentNgFactory = i1.ɵccf("app-top-bar-widget", i7.TopBarWidgetComponent, View_TopBarWidgetComponent_Host_0, {}, {}, []);
export { TopBarWidgetComponentNgFactory as TopBarWidgetComponentNgFactory };
