import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State, getOrderResponse, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookingParkingReservation,
  BookingProductType,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { OrderResponseModel } from '@products/models/order.model';
import { ErrorHandlingService, TranslatedErrorMessage } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { SetProductInBookingList } from '@store/products/booking/booking.actions';
import { getAllBookedProductsCount, getBookedProducts } from '@store/products/booking/booking.selectors';
import { SetOrderResponse } from '@store/step-forms/steps-forms.actions';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-payment-landing-page',
  templateUrl: './payment-landing-page.component.html',
  styleUrls: ['./payment-landing-page.component.scss']
})
export class PaymentLandingPageComponent implements OnInit {
  response: string;
  orderId: string = '';
  translatedErrorMessage: TranslatedErrorMessage;

  private getSelectedExhibitionId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formsService: FormsService,
    private store: Store<State>,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit() {
    this.store
      .pipe(
        select(getSelectedExhibitionId),
        first()
      )
      .subscribe(eventId => (this.getSelectedExhibitionId = eventId));

    combineLatest([this.route.params, this.route.queryParams])
      .pipe(first())
      .subscribe(([params, queryParams]) => {
        this.orderId = queryParams.orderid;

        if (params.response) {
          this.response = params.response;
          // If it is fail we stay on this page and show the failure to a user in template

          // Oterwise we set the hidden payment step to be valid and send user to invoice route
          this.store
            .pipe(
              select(getOrderResponse),
              first()
            )
            .subscribe((orderResponse: OrderResponseModel) => {
              if (this.response !== 'fail' && this.response !== 'error') {
                orderResponse = orderResponse || {
                  hash: this.response,
                  szCode: this.orderId,
                  orderPaid: true
                };

                /*
              We have the hash from the order, but just for sure.. for case that we lost localStorage data by redirect,
              we set the hash again to be the one taken from url. Also we set 'orderPaid' to true.
              Then we redirect to invoice page for downloads
            */
                orderResponse.hash = this.response;
                orderResponse.szCode = this.orderId;
                orderResponse.orderPaid = true;

                this.store.dispatch(new SetOrderResponse(orderResponse));

                // there is invisible payment step, set it now to valid so we can skip it and go to invoice page
                this.formsService.setStepValid(['payment', 'validation']);
                this.router.navigate([`webshop/${this.getSelectedExhibitionId}/invoice`]);
              }

              if (!Object.keys(queryParams).length) {
                let errorMessageKey = '';

                if (typeof orderResponse.errorCode === 'number') {
                  this.removeParkingTickets(orderResponse.invalidTickets);
                  errorMessageKey = orderResponse.errorCode.toString();
                } else {
                  errorMessageKey = orderResponse.errorCode;
                }

                this.translatedErrorMessage = {
                  status: 200,
                  key: errorMessageKey,
                  message: orderResponse.errorMessage
                };
              }
            });

          // if there was an error from BE previously, take it from queryParams
          if (Object.keys(queryParams).length) {
            this.translatedErrorMessage = {
              ...(queryParams as TranslatedErrorMessage)
            };
          }
        }
      });

    // if there was error with status 409 || 404, use this error instad default and store message to queryParams (preserve it on reload)
    this.errorHandlingService.translatedErrorMessage
      .pipe(
        first(translatedErrorMessage => {
          const statusCode = translatedErrorMessage && translatedErrorMessage.status;

          if (statusCode === 409 || statusCode === 404) {
            return true;
          }
        })
      )
      .subscribe(translatedErrorMessage => {
        this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: { ...translatedErrorMessage }
        });

        this.translatedErrorMessage = translatedErrorMessage;
      });
  }

  removeParkingTickets(invalidTickets) {
    this.store
      .pipe(
        select(getBookedProducts),
        first()
      )
      .subscribe(bookedProducts => {
        const removeBookedParkingTariffs: BookingProductType[] = [];

        bookedProducts.forEach(bookedProduct => {
          if (isBookingProductTypeTariff(bookedProduct)) {
            bookedProduct.tariffs.forEach(tariff => {
              if (tariff.parkingReservations.length) {
                const filteredInvalidParkingTariffs = invalidTickets.filter(
                  invalidTicket =>
                    invalidTicket.ticketGroupNr === tariff.ticketTypeId &&
                    invalidTicket.ticketPersonNr === tariff.ticketPersonTypeId
                );

                if (filteredInvalidParkingTariffs.length) {
                  const filteredInvalidParkingReservations: BookingParkingReservation[] = tariff.parkingReservations.filter(
                    parkingReservation =>
                      !filteredInvalidParkingTariffs.some(invalidParkingTariff => {
                        const since = new Date(parkingReservation.since);
                        const until = new Date(parkingReservation.until);
                        const invalidSince = new Date(invalidParkingTariff.since);
                        const invalidUntil = new Date(invalidParkingTariff.until);

                        since.setSeconds(0);
                        until.setSeconds(0);
                        invalidSince.setSeconds(0);
                        invalidUntil.setSeconds(0);

                        return since.getTime() === invalidSince.getTime() && until.getTime() === invalidUntil.getTime();
                      })
                  );

                  tariff.count = filteredInvalidParkingReservations.length;
                  tariff.parkingReservations = filteredInvalidParkingReservations;
                  removeBookedParkingTariffs.push(bookedProduct);
                }
              }
            });
          }
        });

        this.store.dispatch(new SetProductInBookingList(removeBookedParkingTariffs));
      });
  }

  back() {
    this.store
      .pipe(
        select(getAllBookedProductsCount),
        first()
      )
      .subscribe(productsCount =>
        this.router.navigate([
          `webshop/${this.getSelectedExhibitionId}/${productsCount > 0 ? 'confirmation' : 'tickets'}`
        ])
      );
  }
}
