import { Component, OnInit } from '@angular/core';
import { State, getBuyerInfo, getLanguage, getOrderResponse, getSelectedExhibitionId, getTicketHolderInputSets, } from '@app/app.reducer';
import { AppService } from '@app/app.service';
import { Store, select } from '@ngrx/store';
import { HelperService } from '@store/helpers/helper.service';
import { getAllBookedProductsTotalPrice, getBookedProducts } from '@store/products/booking/booking.selectors';
import { VisibilityPayloadModel } from '@store/step-forms/step.interface';
import { SetMultipleStepsVisibility, SetOrderResponse, setPaymentMethod } from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { combineLatest } from 'rxjs';
import { debounceTime, first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-web-shop-recipe',
  templateUrl: './webshop-recipe.component.html',
  styleUrls: ['./webshop-recipe.component.scss']
})
export class WebShopRecipeComponent implements OnInit {
  loading: boolean = true;
  price: number;
  printed: boolean = null;
  serverError = false;

  constructor(
    private store: Store<State>,
    private appService: AppService,
    private stepsFormsService: StepsFormsService,
    private helperService: HelperService
  ) {
    const ticketParams: string = this.helperService.getTicketParams();
    !!ticketParams && this.resetShop(true, ticketParams);
  }

  ngOnInit() {
    // remove the order response if there is an
    this.store.dispatch(new SetOrderResponse(null));
    this.store.dispatch(new setPaymentMethod("0"));

    combineLatest([
      this.store.pipe(select(getBookedProducts)),
      this.store.pipe(select(getTicketHolderInputSets)),
      this.store.pipe(select(getAllBookedProductsTotalPrice)),
    ])
    .pipe(
      first(([bookedProducts, ticketHolderInputs]) => !!bookedProducts && !!ticketHolderInputs),
      debounceTime(500), // wait for late data in reducer (there is an issue when leaving page and google autocomplete is being filled)
    )
    .subscribe(([bookedProducts, ticketHolderInputs, price]) => {
      this.price = price;
      this.stepsFormsService.prepareDataForSaveAndSend(bookedProducts, this.price, ticketHolderInputs);
    });

    combineLatest([
      this.store.pipe(select(getOrderResponse)),
      this.store.pipe(select(getBuyerInfo)),
      this.store.pipe(select(getLanguage))
    ])
    .pipe(
      first(([orderResponse, buyerInfo]) => !!orderResponse && !!buyerInfo)
    )
    .subscribe(([orderResponse, buyer, language]) => {
      if (orderResponse.hasOwnProperty('error') && orderResponse.error !== 'Ok!' && !orderResponse.success) {
        this.serverError = true;
      }

      this.loading = false;

      if (!this.serverError) {
        const paymentType = this.price ? 2 : 1;

        const cPrintData = {
          nType: paymentType, // 1 (1 = Ticket.. tickets cost zero, 2 = Order .. you need to pay)
          szFirstname: buyer.list.find(input => input.key === 'firstName')
            .value,
          szLastname: buyer.list.find(input => input.key === 'lastName')
            .value,
          szCode: orderResponse.szCode,
          nSerialNr: orderResponse.nSerialNr,
          nProjNr: orderResponse.nProjNr,
          nPosNr: orderResponse.nPosNr,
          szLang: language,
          szTicketUrl: orderResponse.selfRegistrationTicketDownloadUrl
        };

        const event = new MessageEvent('CouponPrint', { data: JSON.stringify(cPrintData)});
        document.dispatchEvent(event);
      }
    });

    const visibilityPayload: VisibilityPayloadModel[] = [
      'tickets',
      'personal',
      'workshop',
      'menu',
      'questionnaire'
    ].map(stepKey => ({ stepKey, visible: false }));

    this.store.dispatch(new SetMultipleStepsVisibility(visibilityPayload));
  }

  resetShop(releaseAllVouchersAndTickets: boolean = true, ticketParams?: string) {
    this.store.pipe(
      select(getSelectedExhibitionId),
      first(),
      switchMap(exhibitionId =>
        this.appService.resetReducersWithUser(releaseAllVouchersAndTickets).map(() => exhibitionId)
      )
    ).subscribe(exhibitionId => {
      window.location.replace(`/self-registration/${exhibitionId}` + (!!ticketParams ? `/tickets${ticketParams}` : ''));

      if (!!(window as any).MSInputMethodContext &&!!(document as any).documentMode) {
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
    });
  }
}
