var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as fromRoot from '@app/app.reducer';
import * as stepsActions from '@store/step-forms/steps-forms.actions';
import * as userActions from '@store/user/user.actions';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TariffStatusService } from '@products/services/tariff-status.service';
import { setTimeout } from 'core-js/library/web/timers';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '@shared/app-constants';
import { consoleLog } from '@shared/app-utils';
import { FormsService } from '@shared/forms/forms.service';
import { HelperService } from '@store/helpers/helper.service';
import { getTariffsBookingByOrder } from '@store/products/holder/holder.selectors';
import * as stepsFormsActions from '@store/step-forms/steps-forms.actions';
import { TextOrDropdownInputTypes } from '@store/helpers/helper.interface';
import { readOnlyModelFactory } from './buyer-info.read-only-model';
var BuyerInfoComponent = /** @class */ (function () {
    function BuyerInfoComponent(store, formsService, helperService, tariffStatusService, translateService) {
        this.store = store;
        this.formsService = formsService;
        this.helperService = helperService;
        this.tariffStatusService = tariffStatusService;
        this.translateService = translateService;
        this.isInputChanged = new EventEmitter();
        this.isTicketsOverLimitCheckDone$ = new BehaviorSubject(false);
        this.isFormValidationDone$ = new BehaviorSubject(null);
        this.subscription = new Subscription();
        this.formLoadedAndValid = false;
        this.differentBillingAddressCheckbox = false;
        this.isFormReady = false;
        this.anyDisabled = true;
        this.anyEnabled = true;
        this.showTicketLimitWarning = false;
        this.showTicketLimitWarningAlreadyClosed = false;
        this.modalWindowOpen = false;
        this.allTitlesForReadOnly = [];
        this.allProfessionsForReadOnly = [];
        this.allDepartmentsForReadOnly = [];
        this.allOccupationalGroupsForReadOnly = [];
        this.readOnlyAddress = readOnlyModelFactory();
        this.confirmationCheckboxes = ['newsletter', 'different-billing-address', 'fairCatalogue'];
        this.buyerFormAlreadyFilled = false;
        this.firstTimeRender = true;
        this.isLoggedInOnInitLoad = false;
        this.copyDataYesNo = null;
        this.holdersBookedTariff = [];
        this.hasOnlyParkingTickets = false;
        this.isBuyerInfoFormValid = false;
        //tickets over limit check:
        this.needsTicketsOverLimitCheck = true;
        this.ticketsOverLimitRequests = [];
        //validity check results:
        this.ticketsOverLimitCheckResult = false;
        this.isFairCatalogueCheckedByDefault = false;
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
    }
    BuyerInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isSelfRegistrationEnabled = this.helperService.isSelfregistration();
        this.buyerInfo$ = this.store.pipe(select(fromRoot.getBuyerInfo));
        this.profile$ = this.store.pipe(select(fromRoot.getProfile));
        this.isReadonlyBuyer$ = this.store.pipe(select(fromRoot.isReadonlyBuyer));
        this.subscription.add(this.store.pipe(select(fromRoot.getExhibitionSettings), filter(function (data) { return !!data; }))
            .subscribe(function (settings) {
            _this.exhibitionSettings = settings;
            _this.needsTicketsOverLimitCheck = settings.ticketLimitPerEmail > 0;
            _this.isFairCatalogueCheckedByDefault = settings.buyerSettings.fairCatalogDefaultValue;
        }));
        this.subscription.add(this.store.pipe(select(fromRoot.getLanguage), filter(function () { return !!_this.inputs; }))
            .subscribe(function () { return _this.form = _this.formsService.toFormGroup(_this.inputs, _this.PersonaliseFormsKeys.buyerInfo); }));
        this.profile$.pipe(first()).subscribe(function (profile) { return _this.isLoggedInOnInitLoad = !!profile; });
        this.subscription.add(combineLatest([
            this.buyerInfo$,
            this.isReadonlyBuyer$,
            this.profile$,
            this.store.pipe(select(fromRoot.getTitles)),
            this.store.pipe(select(fromRoot.getAllTitles)),
            this.store.pipe(select(fromRoot.getProfessions)),
            this.store.pipe(select(fromRoot.getAllProfessions)),
            this.store.pipe(select(fromRoot.getDepartments)),
            this.store.pipe(select(fromRoot.getAllDepartments)),
            this.store.pipe(select(fromRoot.getOccupationalGroups)),
            this.store.pipe(select(fromRoot.getAllOccupationalGroups))
        ])
            .pipe(filter(function (_a) {
            var buyerInfo = _a[0], isReadOnlyBuyer = _a[1], profile = _a[2], titles = _a[3], allTitles = _a[4], professions = _a[5], allProfessions = _a[6], departments = _a[7], allDepartments = _a[8], occupationalGroups = _a[9], allOccupationalGroups = _a[10];
            return !!buyerInfo
                && isReadOnlyBuyer !== null
                && !!titles
                && !!allTitles
                && !!professions
                && !!allProfessions
                && !!departments
                && !!allDepartments
                && !!occupationalGroups
                && !!allOccupationalGroups;
        }))
            .subscribe(function (_a) {
            var buyerInfo = _a[0], isReadonlyBuyer = _a[1], profile = _a[2], inputTitles = _a[3], allTitles = _a[4], inputProfessions = _a[5], allProfessions = _a[6], inputDepartments = _a[7], allDepartments = _a[8], inputOccupationalGroups = _a[9], allOccupationalGroups = _a[10];
            _this.buyerInfo = buyerInfo;
            _this.isReadonlyBuyer = isReadonlyBuyer;
            _this.profile = profile;
            _this.inputTitles = inputTitles;
            _this.allTitles = allTitles;
            _this.inputProfessions = inputProfessions;
            _this.allProfessions = allProfessions;
            _this.inputDepartments = inputDepartments;
            _this.allDepartments = allDepartments;
            _this.inputOccupationalGroups = inputOccupationalGroups;
            _this.allOccupationalGroups = allOccupationalGroups;
            _this.fillBuyerInfoWithProfileData();
        }));
        this.subscription.add(combineLatest([
            this.helperService.voteYesNo$,
            this.isFormValidationDone$,
            this.profile$
        ])
            .pipe(filter(function (_a) {
            var voteYesNo = _a[0], isFormValidationDone = _a[1], profile = _a[2];
            return !!voteYesNo && isFormValidationDone && !!profile;
        }))
            .subscribe(function (_a) {
            var voteYesNo = _a[0], isFormValidationDone = _a[1], profile = _a[2];
            _this.copyDataYesNo = voteYesNo;
            _this.profile = profile;
            if (!!_this.copyDataYesNo && isFormValidationDone) {
                if (_this.isReadonlyBuyer) {
                    //make read only address disappear by making all values empty:
                    _this.readOnlyAddress = readOnlyModelFactory();
                }
                _this.fillBuyerInfoWithProfileData();
            }
        }));
        this.subscription.add(this.store.pipe(select(fromRoot.getSelectedExhibitionId)).subscribe(function (eventId) { return _this.selectedExhibitionId = eventId; }));
        this.subscription.add(this.store.pipe(select(getTariffsBookingByOrder)).subscribe(function (tariffBookingByOrder) {
            _this.holdersBookedTariff = tariffBookingByOrder;
            // if getTariffsBookingByOrder returns empty array it means we only have parking tickets
            _this.hasOnlyParkingTickets = !tariffBookingByOrder.length;
        }));
        this.subscription.add(combineLatest([
            this.buyerInfo$,
            this.store.pipe(select(fromRoot.isBuyerInfoFormValid))
        ])
            .pipe(filter(function (_a) {
            var buyerInfo = _a[0];
            return !!buyerInfo && !!buyerInfo.list && !!buyerInfo.list.find(function (item) { return item.key === 'email'; });
        }), distinctUntilChanged(function (prev, curr) {
            var prevBuyerInfo = prev[0], prevIsBuyerInfoFormValid = prev[1];
            var currBuyerInfo = curr[0], currIsBuyerInfoFormValid = curr[1];
            var prevEmail = prevBuyerInfo.list.find(function (item) { return item.key === 'email'; }).value;
            var currEmail = currBuyerInfo.list.find(function (item) { return item.key === 'email'; }).value;
            //only go inside if there was a change of the buyer e-mail or a change in buyer info form validity
            return prevEmail === currEmail && prevIsBuyerInfoFormValid === currIsBuyerInfoFormValid;
        }))
            .subscribe(function (_a) {
            var isBuyerInfoFormValid = _a[1];
            _this.isBuyerInfoFormValid = isBuyerInfoFormValid;
            //if the buyer info form is invalid or we don't have to validate tickets over limit here, set buyer info form validity
            //(it will not be done inside of the forms.service):
            if (!_this.needsTicketsOverLimitCheck || _this.areTicketHoldersVisible || !isBuyerInfoFormValid || _this.hasOnlyParkingTickets) {
                _this.formsService.setFormValidity(isBuyerInfoFormValid, _this.form, _this.PersonaliseFormsKeys.buyerInfo);
                return;
            }
            //otherwise invalidate the form and check for tickets over limit:
            _this.formsService.setFormValidity(false, _this.form, _this.PersonaliseFormsKeys.buyerInfo);
            _this.checkForTicketsOverLimit();
        }));
        this.subscription.add(this.isTicketsOverLimitCheckDone$
            .pipe(filter(function (isTicketsOverLimitCheckDone) { return isTicketsOverLimitCheckDone; }))
            .subscribe(function () {
            //when all checks are done set buyer info validity:
            return _this.formsService.setFormValidity(_this.isBuyerInfoFormValid && _this.ticketsOverLimitCheckResult, _this.form, _this.PersonaliseFormsKeys.buyerInfo);
        }));
    };
    BuyerInfoComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        this.inputs = [];
        this.form = null;
        this.formLoadedAndValid = false;
        this.differentBillingAddressCheckbox = false;
        this.firstTimeRender = true;
        this.isLoggedInOnInitLoad = false;
    };
    BuyerInfoComponent.prototype.fillBuyerInfoWithUrlParameters = function (buyerInfo) {
        this.store.pipe(select(fromRoot.getBuyerInfoFromURL), first(function (parameters) { return !!parameters; }))
            .subscribe(function (parameters) {
            Object.keys(parameters).forEach(function (parameter) {
                var buyerInfoParameter = buyerInfo.find(function (p) { return p.key === parameters[parameter].key; });
                if (buyerInfoParameter && !buyerInfoParameter.value) {
                    buyerInfoParameter.value = parameters[parameter].value;
                }
            });
        });
        this.store.dispatch(new stepsFormsActions.ResetBuyerInfoFromURL());
        this.saveForm(false);
    };
    BuyerInfoComponent.prototype.fillBuyerInfoWithProfileData = function () {
        var _this = this;
        this.allTitlesForReadOnly = this.allTitles;
        this.allProfessionsForReadOnly = this.allProfessions;
        this.allDepartmentsForReadOnly = this.allDepartments;
        this.allOccupationalGroupsForReadOnly = this.allOccupationalGroups;
        if (!!this.inputs) {
            for (var i = 0; i < this.buyerInfo.list.length; i++) {
                var buyerInfoData = this.buyerInfo.list[i];
                var inputDataValue = this.inputs[i].value;
                var inputControlType = buyerInfoData.controlType.toLowerCase();
                if (inputControlType !== "checkbox" && inputControlType !== "radio" && inputControlType !== "formButton" && inputControlType !== "dateinput") {
                    if (inputDataValue !== buyerInfoData.value) {
                        this.isInputChanged.emit(true);
                        break;
                    }
                }
            }
        }
        ;
        if (this.buyerInfo && this.buyerInfo.list.length) {
            this.buyerInfo.list.forEach(function (buyerOption) {
                if (buyerOption.key === 'fairCatalogue' && buyerOption.options.length > 0) {
                    buyerOption.value = buyerOption.options[0].value;
                }
            });
            this.inputs = this.formsService.updateInputs(this.inputs, this.buyerInfo.list);
            if (this.firstTimeRender && this.isReadonlyBuyer && this.profile) {
                //first enable al disabled inputs, so we can again decide whether to disable them based on their validity
                this.inputs = this.inputs.map(function (input) {
                    input.disabled = false;
                    return input;
                });
            }
            ;
            var createAccountCheckbox = this.inputs.find(function (x) { return x.key === 'createAccount'; });
            var isCreateAccountChecked = false;
            if (createAccountCheckbox) {
                isCreateAccountChecked = createAccountCheckbox.options[0].value;
            }
            //when user logs in, all buyer info input fields are filled with profile data,
            //create account checkbox, button and password input fields are hidden and not required.
            if (!!this.profile) {
                // check if the values are empty
                if (this.copyDataYesNo) {
                    this.buyerFormAlreadyFilled = false;
                }
                else {
                    this.buyerFormAlreadyFilled = this.inputs.some(function (buyerInfoItem) {
                        return buyerInfoItem.key === 'fairCatalogue' ? !_this.isFairCatalogueCheckedByDefault && buyerInfoItem.value : buyerInfoItem.value;
                    });
                }
                // In case the buyer form is empty prefill data from profile
                if (!this.modalWindowOpen && this.profile && !this.buyerFormAlreadyFilled) {
                    var verifyEmail_1 = '';
                    this.inputs.forEach(function (buyerInfoItem) {
                        var updatedBuyerInfoItem;
                        if (_this.profile.hasOwnProperty(buyerInfoItem.key)) {
                            if (_this.copyDataYesNo) {
                                if (_this.profile[buyerInfoItem.key] === null || _this.profile[buyerInfoItem.key] === '') {
                                    _this.profile[buyerInfoItem.key] = buyerInfoItem.value;
                                }
                            }
                            updatedBuyerInfoItem = __assign({}, buyerInfoItem, { value: _this.formsService.getFunctionTextValue(_this.profile, buyerInfoItem, _this.inputTitles, _this.allTitles, _this.inputProfessions, _this.allProfessions, _this.inputDepartments, _this.allDepartments, _this.inputOccupationalGroups, _this.allOccupationalGroups) });
                            _this.formsService.resetInvalidFunctionValue(updatedBuyerInfoItem, _this.inputTitles, _this.inputProfessions, _this.inputDepartments, _this.inputOccupationalGroups, function (input) {
                                updatedBuyerInfoItem = __assign({}, input, { value: null });
                            });
                        }
                        else {
                            updatedBuyerInfoItem = __assign({}, buyerInfoItem, { value: '' });
                            if (buyerInfoItem.key === 'Newsletter' &&
                                (buyerInfoItem.controlType === 'checkbox' || buyerInfoItem.controlType === 'radio')) {
                                if (buyerInfoItem.value === 'false') {
                                    _this.profile.newsletterChecked = false;
                                }
                                else if (buyerInfoItem.value === 'true') {
                                    _this.profile.newsletterChecked = true;
                                }
                                updatedBuyerInfoItem = __assign({}, buyerInfoItem, { value: _this.profile.newsletterChecked.toString() });
                            }
                        }
                        var listOfInputKeys = [updatedBuyerInfoItem.key];
                        // if we filled in an email with some value also prefill it to verify email
                        if (buyerInfoItem.key === 'email' && _this.profile[buyerInfoItem.key]) {
                            verifyEmail_1 = updatedBuyerInfoItem.value;
                        }
                        _this.setInputsPropertyValues(_this.inputs, listOfInputKeys, 'value', updatedBuyerInfoItem.value);
                    });
                    if (verifyEmail_1) {
                        this.setInputsPropertyValues(this.inputs, ['verifyEmail'], 'value', verifyEmail_1);
                    }
                }
                this.setInputsPropertyValues(this.inputs, ['createAccount'], 'hidden', true);
                this.setInputsPropertyValues(this.inputs, ['createAccount', 'password', 'verifyPassword'], 'required', false);
                if (createAccountCheckbox) {
                    isCreateAccountChecked = createAccountCheckbox.options[0].value = false;
                }
            }
            // depending if create account checkbox is checked or not we display/hide the password input fields (requirement) and create account button
            this.setInputsPropertyValues(this.inputs, ['createAccountButton', 'password', 'verifyPassword'], 'hidden', !isCreateAccountChecked);
            this.setInputsPropertyValues(this.inputs, ['password', 'verifyPassword'], 'required', isCreateAccountChecked);
            this.setBillAddressFormValid(this.inputs);
            if (this.copyDataYesNo || this.buyerInfo.rerender || !this.form || (!this.isLoggedInOnInitLoad && !!this.profile)) {
                setTimeout(function () {
                    // ensure that the above condition will not pass again next time
                    if (!_this.isLoggedInOnInitLoad && !!_this.profile) {
                        _this.isLoggedInOnInitLoad = true;
                    }
                    _this.fillBuyerInfoWithUrlParameters(_this.inputs);
                    _this.form = _this.formsService.toFormGroup(_this.inputs, _this.PersonaliseFormsKeys.buyerInfo);
                    // we need to check manually if the form is valid or not in case 'readOnlyMode' is set in backoffice
                    // if it is set and form is not valid, we need to enable edit temporarily
                    if (_this.firstTimeRender && _this.isReadonlyBuyer && !!_this.profile) {
                        _this.firstTimeRender = false;
                        var validationCallback = function () {
                            _this.readOnlyFormReady(!!_this.profile, _this.allTitles, _this.allProfessions, _this.allDepartments, _this.allOccupationalGroups);
                        };
                        _this.helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback);
                    }
                    else {
                        _this.isFormReady = true;
                        _this.anyDisabled = _this.checkIfAnyInputIsDisabled();
                        _this.anyEnabled = _this.checkIfAnyInputIsEnabled();
                        if (!_this.buyerFormAlreadyFilled && !!_this.profile) {
                            _this.saveForm(true);
                        }
                    }
                    _this.helperService.triggerCallbackOnceFormValidationIsDone(_this.form, function () { return _this.isFormValidationDone$.next(true); });
                }, 0);
            }
            else {
                this.anyDisabled = this.checkIfAnyInputIsDisabled();
                this.anyEnabled = this.checkIfAnyInputIsEnabled();
            }
        }
        if (!!this.copyDataYesNo) {
            this.copyDataYesNo = null;
            this.helperService.voteYesOrNo(null);
        }
    };
    BuyerInfoComponent.prototype.setInputsPropertyValues = function (inputs, keys, propName, value) {
        inputs.forEach(function (input) {
            if (keys.includes(input.key)) {
                input[propName] = value;
            }
        });
    };
    BuyerInfoComponent.prototype.readOnlyFormReady = function (isLoggedIn, allTitles, allProfessions, allDepartments, allOccupationalGroups) {
        var _this = this;
        var _a;
        var allTextOrDropdownInputTypes = (_a = {},
            _a[TextOrDropdownInputTypes.Title] = allTitles,
            _a[TextOrDropdownInputTypes.Function] = allProfessions,
            _a[TextOrDropdownInputTypes.Department] = allDepartments,
            _a[TextOrDropdownInputTypes.OccupationalGroup] = allOccupationalGroups,
            _a);
        var textOrDropdownInputTypes = Object.keys(TextOrDropdownInputTypes).map(function (typeKey) { return TextOrDropdownInputTypes[typeKey]; });
        this.isFormReady = true;
        this.inputs = this.inputs.map(function (input) {
            var inputIsDisabled = false;
            if (_this.form.controls[input.key].valid) {
                inputIsDisabled = true;
            }
            _this.readOnlyAddress.forEach(function (line) {
                var index = line.contains.indexOf(input.key);
                if (index >= 0 && inputIsDisabled) {
                    line.data[index] = {};
                    if (input.key === 'gender') {
                        if (input.value) {
                            line.data[index].title = "gender." + input.value;
                            line.data[index].translate = true;
                        }
                        else {
                            line.data[index].title = null;
                        }
                    }
                    else if (input.key === 'country') {
                        line.data[index].title = "country." + input.value;
                        line.data[index].translate = true;
                    }
                    else if (textOrDropdownInputTypes.includes((input.key))) {
                        var inputType_1 = input.key;
                        if (!isNaN(input.value) && !!input.value && allTextOrDropdownInputTypes[inputType_1].length) {
                            var inputData = allTextOrDropdownInputTypes[inputType_1].find(function (type) { return type.id === Number(input.value); });
                            if (inputData) {
                                _this.subscription.add(_this.translateService.get(inputData.text).subscribe(function (translation) { return line.data[index][inputType_1] = translation; }));
                            }
                        }
                    }
                    else {
                        line.data[index].title = input.value;
                        line.data[index].translate = false;
                    }
                    var containsMultiple = line.contains.length > 1;
                    var hideLabel = !input.required && !input.value && !line.alwaysVisible && !containsMultiple;
                    // when input is not required and empty we don't need to show it in template;
                    if (hideLabel) {
                        line.label = '';
                    }
                }
            });
            if (_this.confirmationCheckboxes.includes(input.key)) {
                input.disabled = false;
            }
            else {
                input.disabled = inputIsDisabled;
            }
            return input;
        });
        this.anyDisabled = this.checkIfAnyInputIsDisabled();
        this.anyEnabled = this.checkIfAnyInputIsEnabled();
        /* rerender the form to see disabled fields */
        this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo);
        // we filled the data so we need to save the form to redux store
        if (!this.buyerFormAlreadyFilled && isLoggedIn) {
            this.saveForm(true);
        }
    };
    BuyerInfoComponent.prototype.checkIfAnyInputIsDisabled = function () {
        var _this = this;
        return this.inputs.some(function (input) { return input.disabled && !input.hidden && !_this.confirmationCheckboxes.includes(input.key); });
    };
    BuyerInfoComponent.prototype.checkIfAnyInputIsEnabled = function () {
        var _this = this;
        return this.inputs.some(function (input) { return !input.disabled && !input.hidden && !_this.confirmationCheckboxes.includes(input.key); });
    };
    BuyerInfoComponent.prototype.editReadOnly = function () {
        if (this.anyDisabled) {
            this.inputs = JSON.parse(JSON.stringify(this.inputs.map(function (input) {
                input.disabled = false;
                return input;
            })));
            // make read only address dissapeared by making all values empty
            this.readOnlyAddress = readOnlyModelFactory();
            this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo);
            // we filled the data so we need to save the form to redux store
            this.saveForm(true);
        }
    };
    BuyerInfoComponent.prototype.saveEditReadOnly = function () {
        var _this = this;
        /* if user edited input and clicked to Confirm button straight away,
        we need first save the change (input is updated to redux).
        Then we can rebuild the read only info. Thats why we need the setTimeout here */
        setTimeout(function () {
            // set buyerFormAlreadyFilled to false so we enforce save of the form and its complete reload with propper disabled inputs
            _this.buyerFormAlreadyFilled = false;
            _this.readOnlyFormReady(true, _this.allTitles, _this.allProfessionsForReadOnly, _this.allDepartmentsForReadOnly, _this.allOccupationalGroupsForReadOnly);
        }, 300);
    };
    BuyerInfoComponent.prototype.saveForm = function (rerender) {
        var clonedBuyerInputs = cloneDeep(this.inputs);
        var buyerInfoPayload = {
            formInfo: this.PersonaliseFormsKeys.buyerInfo,
            inputSet: {
                rerender: rerender,
                list: clonedBuyerInputs
            }
        };
        this.store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));
    };
    // If billing address is not shown, set billing address form to valid
    BuyerInfoComponent.prototype.setBillAddressFormValid = function (buyerInputs) {
        var differentBillingAddressInput = buyerInputs.find(function (x) { return x.key === 'different-billing-address'; });
        if (differentBillingAddressInput) {
            this.differentBillingAddressCheckbox = differentBillingAddressInput.options[0].value;
        }
        else {
            // if there is no billing address enabled from backoffice, then mark it for disabling the validation
            this.differentBillingAddressCheckbox = false;
        }
        // if no different billing address is used set the form and inputs to be valid
        if (!this.differentBillingAddressCheckbox) {
            this.formsService.setStepValid(this.PersonaliseFormsKeys.billingAddress);
        }
    };
    BuyerInfoComponent.prototype.formSubmited = function (inputKey) {
        var _this = this;
        this.store.pipe(select(fromRoot.getUserLanguage)).subscribe(function (language) {
            if (inputKey === 'createAccountButton') {
                var userData = _this.helperService.processFormValuesBeforeSave(_this.form.value);
                delete userData.address;
                delete userData['verifyEmail'];
                delete userData['verifyPassword'];
                userData.language = language;
                _this.store.dispatch(new userActions.CreateProfile(userData));
            }
        });
    };
    BuyerInfoComponent.prototype.inputChanged = function (input) {
        if (this.needsTicketsOverLimitCheck && !this.areTicketHoldersVisible && this.isBuyerInfoFormValid && !this.hasOnlyParkingTickets && input === 'email') {
            this.ticketsOverLimitCheckResult = false;
            this.formsService.setFormValidity(false, this.form, this.PersonaliseFormsKeys.buyerInfo);
        }
    };
    BuyerInfoComponent.prototype.checkForTicketsOverLimit = function () {
        var _this = this;
        this.isTicketsOverLimitCheckDone$.next(false);
        this.ticketsOverLimitCheckResult = false;
        var emailDataList = [];
        var buyerEMail = this.inputs.find(function (input) { return input.key === 'email'; }).value;
        if (!buyerEMail) {
            //e-mail is mandatory and so is the check for daily ticket limit per e-mail, as we don't have an e-mail we have to invalidate buyer info form:
            this.formsService.setFormValidity(false, null, this.PersonaliseFormsKeys.buyerInfo);
            return;
        }
        this.holdersBookedTariff.forEach(function (ticket) { return emailDataList.push({ index: 0, email: buyerEMail, ticketPersonId: ticket.ticketPersonId, amount: 1 }); });
        if (emailDataList.length) {
            var request = {
                eventId: this.selectedExhibitionId,
                isSelfRegistration: this.isSelfRegistrationEnabled,
                items: emailDataList
            };
            var thisRequestTime_1 = new Date();
            this.ticketsOverLimitRequests.push(thisRequestTime_1);
            consoleLog("CheckDailyTicketPerEmailLimit: requestTime=" + thisRequestTime_1.toISOString() + ", request=" + JSON.stringify(request));
            this.tariffStatusService.checkDailyTicketPerEmailLimit$(request).subscribe(function (response) {
                consoleLog("CheckDailyTicketPerEmailLimit: requestTime=" + thisRequestTime_1.toISOString() + ", response=" + JSON.stringify(response));
                var findIndex = _this.ticketsOverLimitRequests.indexOf(thisRequestTime_1);
                if (findIndex > -1) {
                    if (findIndex === _this.ticketsOverLimitRequests.length - 1) {
                        if (!response.isValid) {
                            //the buyer is over ticket limit:
                            _this.ticketsOverLimitCheckResult = false;
                            _this.showTicketLimitWarning = true;
                        }
                        else {
                            //the buyer isn't over ticket limit:
                            _this.ticketsOverLimitCheckResult = true;
                        }
                        _this.isTicketsOverLimitCheckDone$.next(true);
                    }
                    else {
                        consoleLog("CheckDailyTicketPerEmailLimit: skipping response from requestTime=" + thisRequestTime_1.toISOString() + "...");
                    }
                    _this.ticketsOverLimitRequests.splice(findIndex, 1);
                }
            }, function (err) {
                consoleLog("CheckDailyTicketPerEmailLimit: requestTime=" + thisRequestTime_1.toISOString() + ", ERROR=" + JSON.stringify(err));
                var findIndex = _this.ticketsOverLimitRequests.indexOf(thisRequestTime_1);
                if (findIndex > -1) {
                    if (findIndex === _this.ticketsOverLimitRequests.length - 1) {
                        _this.ticketsOverLimitCheckResult = false;
                        _this.isTicketsOverLimitCheckDone$.next(true);
                    }
                    _this.ticketsOverLimitRequests.splice(findIndex, 1);
                }
            });
        }
    };
    BuyerInfoComponent.prototype.closeModalWindow = function (event) {
        event.stopPropagation();
        this.showTicketLimitWarning = false;
    };
    return BuyerInfoComponent;
}());
export { BuyerInfoComponent };
