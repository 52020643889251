<ng-container *ngIf="contingentReservations$ | async as contingentReservations" [formGroup]="contingentReservationForm">
  <div
    class="container contingent-container"
    formGroupName="{{ [tariff.ticketPersonId] }}_{{ [contingentReservationIndex] }}"
    *ngFor="
      let contingentReservation of contingentReservations;
      trackBy: contingentReservationTrackBy;
      index as contingentReservationIndex
    "
    [ngClass]="{ 'self-registration-contingent-container': productSelectionViewModel.isSelfRegistration }"
  >
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="input-wrapper form-wrapper">
          <div
            class="contingent-information"
            *ngIf="contingentReservation.fromDate && contingentReservation.isValid; else contingentReservationElseBlock"
          >
            <div class="contingent-calendar-wrapper">
              <p-calendar
                id="day_{{ [tariff.ticketPersonId] }}_{{ [contingentReservationIndex] }}"
                class="hasvalue"
                #day
                formControlName="day"
                calendarCustomDefaults
                [minDate]="
                  selectedExhibition?.startDate > productSelectionViewModel.dateToday
                    ? selectedExhibition.startDate
                    : productSelectionViewModel.dateToday
                "
                [defaultDate]="
                  selectedExhibition?.startDate > productSelectionViewModel.dateToday
                    ? selectedExhibition.startDate
                    : productSelectionViewModel.dateToday
                "
                [maxDate]="selectedExhibition?.endDate"
                [showIcon]="true"
                [selectOtherMonths]="true"
                [readonlyInput]="true"
                (onSelect)="onContingentReservationSelect(contingentReservationIndex)"
              >
                <ng-template pTemplate="date" let-date>
                  <span
                    [class.contingent-highlighted]="
                      highlightDay(date, contingentReservation.fromDate, contingentReservation.toDate)
                    "
                  >
                    {{ date.day }}
                  </span>
                </ng-template>
              </p-calendar>
              <label
                class="placeholder"
                for="day_{{ [tariff.ticketPersonId] }}_{{ [contingentReservationIndex] }}"
                data-translation="ticket.start-day"
                [innerHTML]="'ticket.start-day' | translate | hideTranslation"
              ></label>
            </div>
            <div
              class="dot-pulse-loader-wrapper"
              *ngIf="
                contingentReservationShowLoader[contingentReservationIndex];
                else showSelectedReservationDateElseBlock
              "
            >
              <div class="dot-pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <ng-template #showSelectedReservationDateElseBlock>
              <div *ngIf="contingentReservation">
                <div *ngIf="contingentReservation.duration === 1">
                  <span
                    data-translation="ticket.end-one-day"
                    [innerHTML]="
                      'ticket.end-one-day'
                        | translate: { day: contingentReservation.toDate | localizedDate: 'dd.MM.y' }
                        | hideTranslation
                    "
                  ></span>
                </div>
                <div
                  *ngIf="
                    contingentReservation.duration > 1 && contingentReservation.fromDate && contingentReservation.toDate
                  "
                >
                  <span
                    data-translation="ticket.end-multi-day"
                    [innerHTML]="
                      'ticket.end-multi-day'
                        | translate
                          : {
                              start: contingentReservation.fromDate | localizedDate: 'dd.MM.y',
                              end: contingentReservation.toDate | localizedDate: 'dd.MM.y'
                            }
                        | hideTranslation
                    "
                  ></span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <ng-template #contingentReservationElseBlock>
          <div class="contingent-information">
            <div class="contingent-calendar-wrapper">
              <p-calendar
                id="day_{{ [tariff.ticketPersonId] }}_{{ [contingentReservationIndex] }}"
                class="hasvalue"
                #day
                formControlName="day"
                calendarCustomDefaults
                [minDate]="
                  selectedExhibition?.startDate > productSelectionViewModel.dateToday
                    ? selectedExhibition.startDate
                    : productSelectionViewModel.dateToday
                "
                [defaultDate]="
                  selectedExhibition?.startDate > productSelectionViewModel.dateToday
                    ? selectedExhibition.startDate
                    : productSelectionViewModel.dateToday
                "
                [maxDate]="selectedExhibition?.endDate"
                [showIcon]="true"
                [selectOtherMonths]="true"
                [readonlyInput]="true"
                (onSelect)="onContingentReservationSelect(contingentReservationIndex)"
              >
              </p-calendar>
              <label
                class="placeholder"
                for="day_{{ [tariff.ticketPersonId] }}_{{ [contingentReservationIndex] }}"
                data-translation="ticket.start-day"
                [innerHTML]="'ticket.start-day' | translate | hideTranslation"
              ></label>
            </div>
            <div
              class="dot-pulse-loader-wrapper"
              *ngIf="
                contingentReservationShowLoader[contingentReservationIndex];
                else contingentReservationShowInvalidBlock
              "
            >
              <div class="dot-pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <ng-template #contingentReservationShowInvalidBlock>
              <div *ngIf="contingentReservation">
                <div *ngIf="contingentReservation.isValid != null">
                  <span
                    data-translation="ticket.invalid"
                    [innerHTML]="'ticket.invalid' | translate | hideTranslation"
                  ></span>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
