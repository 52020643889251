import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PackageType } from '@products/models/package.model';
import {
  ProductGroup,
  ProductSelectionBookingStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel
} from '@products/models/product-selection.model';
import { Tariff, TariffClassification, TariffType } from '@products/models/tariff.model';
import { VoucherCode } from '@products/models/voucher.model';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tariff-type',
  templateUrl: './tariff-type.component.html',
  styleUrls: ['./tariff-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TariffTypeComponent implements OnInit {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() workshopStatusViewModel: ProductSelectionWorkshopStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() productGroup: ProductGroup;
  @Input() packageType: PackageType;
  @Input() tariffType: TariffType;

  readonly TariffClassification = TariffClassification;
  readonly voucherCodeReleased$ = new Subject<VoucherCode>();

  constructor() {}

  ngOnInit() {}

  tariffTrackBy(index: number, tariff: Tariff) {
    const isActiveVoucher = tariff.isVoucher && tariff.isVisible;
    if (isActiveVoucher) {
      return tariff;
    }

    return index;
  }
}
