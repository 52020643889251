import { combineLatest, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { getBuyerActiveBillingAddressId, getExhibitionSettings, getProfile, getProfileBillingAddress, getSelectedBillingAddressId } from '@app/app.reducer';
import { DeleteProfileBillingAddress, GetProfileBillingAddress, PutProfileBillingAddress, SetSelectedBillingAddress } from '@store/user/user.actions';
import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SetBuyerActiveBillingAddressId } from '@store/step-forms/steps-forms.actions';
import { FormsService } from '@shared/forms/forms.service';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { getProfileBillingAddressEditForm } from './form-inputs.edit-billing-address';
var EditBillingAddressFormComponent = /** @class */ (function () {
    function EditBillingAddressFormComponent(customizationService, store, formsService, helperService) {
        var _this = this;
        this.customizationService = customizationService;
        this.store = store;
        this.formsService = formsService;
        this.helperService = helperService;
        this.selectedAddressId = 0;
        this.editAccountAction = ['editAccountAddressForm'];
        this.subscriptions = new Subscription();
        this.formSaveCallback = function (inputs, rerender) {
            _this.inputs = _this.formsService.updateInputs(_this.inputs, inputs);
            if (rerender) {
                _this.form = _this.formsService.toFormGroup(_this.inputs);
            }
        };
        this.store.dispatch(new SetSelectedBillingAddress(0));
        this.store.pipe(select(getProfile), first(function (profile) { return !!profile; }))
            .subscribe(function (user) { return _this.userId = user.id; });
    }
    EditBillingAddressFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // initial billing address was loaded from API in profile component
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(getProfileBillingAddress)),
            this.store.pipe(select(getSelectedBillingAddressId)),
            this.store.pipe(select(getExhibitionSettings))
        ])
            .pipe(
        // settings are not necessary as user can access profile without exhibition selected
        filter(function (_a) {
            var profileBillingAddress = _a[0];
            return !!profileBillingAddress.length;
        }))
            .subscribe(function (_a) {
            var billingAddressList = _a[0], selectedBillingAddressId = _a[1], settings = _a[2];
            return setTimeout(function () {
                _this.billingAddressList = billingAddressList;
                _this.selectedAddressId = selectedBillingAddressId;
                if (_this.billingAddressList.length) {
                    var billingAddress = _this.billingAddressList[selectedBillingAddressId];
                    var isGoogleAutocompleteEnabled = settings && settings.isGoogleAutocompleteEnabled ? true : false;
                    var profileEditForm = getProfileBillingAddressEditForm(isGoogleAutocompleteEnabled, _this.customizationService, billingAddress);
                    _this.inputs = _this.formsService.updateInputs(_this.inputs, profileEditForm);
                    _this.form = _this.formsService.toFormGroup(profileEditForm);
                    _this.enableOrDisableInputs(_this.isEditEnabled);
                }
            });
        }));
    };
    EditBillingAddressFormComponent.prototype.ngOnDestroy = function () {
        // initial billing address was loaded from API in profile component
        this.subscriptions.unsubscribe();
    };
    EditBillingAddressFormComponent.prototype.loadBillingAddress = function () {
        this.store.dispatch(new GetProfileBillingAddress(this.userId));
    };
    EditBillingAddressFormComponent.prototype.deleteBillingAddress = function (addressId) {
        var _this = this;
        var addressToDelete = this.billingAddressList[addressId];
        this.store.dispatch(new DeleteProfileBillingAddress({
            userId: this.userId,
            addressId: addressToDelete.id
        }));
        if (this.billingAddressList.length) {
            this.store.dispatch(new SetSelectedBillingAddress(0));
        }
        this.store.pipe(select(getBuyerActiveBillingAddressId), first(function (selectedBuyerBillAddress) { return selectedBuyerBillAddress === addressToDelete.id; }))
            .subscribe(function () { return _this.store.dispatch(new SetBuyerActiveBillingAddressId(null)); });
    };
    EditBillingAddressFormComponent.prototype.saveBillingAddress = function (addressId) {
        var dataToSave = (this.helperService.processFormValuesBeforeSave(this.form.value));
        var databseAddressId = this.billingAddressList[addressId].id;
        dataToSave.address = dataToSave.address || '';
        this.store.dispatch(new PutProfileBillingAddress({
            userId: this.userId,
            addressId: databseAddressId,
            data: dataToSave
        }));
        this.enableOrDisableInputs(this.isEditEnabled = false);
    };
    EditBillingAddressFormComponent.prototype.cancelEdit = function () {
        this.loadBillingAddress();
        this.enableOrDisableInputs(this.isEditEnabled = false);
    };
    EditBillingAddressFormComponent.prototype.beginEdit = function () {
        this.enableOrDisableInputs(this.isEditEnabled = true);
    };
    // on change selected billing address
    EditBillingAddressFormComponent.prototype.onChange = function (event) {
        this.store.dispatch(new SetSelectedBillingAddress(event.target.value));
    };
    EditBillingAddressFormComponent.prototype.enableOrDisableInputs = function (enable) {
        if (enable === undefined) {
            enable = false;
        }
        this.inputs.forEach(function (input) { return input.disabled = !enable; });
    };
    return EditBillingAddressFormComponent;
}());
export { EditBillingAddressFormComponent };
