<ng-container *ngIf="eventWidgets?.length > 0">
  <div class="row">
    <div class="col-lg-6 ">
      <br /><br />

      <div class="input-wrapper form-wrapper">
        <select
          (change)="onWidgetChange($event.target.value)"
          id="eventWidgets"
          class="hasvalue"
        >
          <option [value]="-1"></option>

          <option
            *ngFor="let eventWidget of eventWidgets"
            [value]="eventWidget.id"
            >{{ eventWidget.title }}
          </option>
        </select>

        <label
          class="placeholder"
          for="eventWidgets"
          data-translation="widget.select"
          >{{ 'widget.select' | translate }}</label
        >
      </div>
    </div>
  </div>
</ng-container>

<form [formGroup]="settingsForm">
  <div class="section-headline">
    <h2 data-translation="widget.appearance">
      {{ 'widget.appearance' | translate }}
    </h2>

    <div class="section-headline-side">←</div>
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              formControlName="width"
              id="widget.width"
              type="text"
              class="hasvalue"
            />
            <label
              class="placeholder"
              for="widget.width"
              data-translation="widget.width"
              >{{ 'widget.width' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              formControlName="height"
              id="widget.height"
              type="text"
              class="hasvalue"
            />
            <label
              class="placeholder"
              for="widget.height"
              data-translation="widget.height"
              >{{ 'widget.height' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              class="hasvalue"
              id="widget.background"
              type="file"
              (change)="readFile($event)"
            />
            <label
              class="placeholder"
              data-translation="widget.background"
              for="widget.background"
              >{{ 'widget.background' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              class="hasvalue"
              id="widget.title"
              type="text"
              formControlName="title"
              required
            />
            <label
              class="placeholder"
              data-translation="widget.title"
              for="widget.title"
              >{{ 'widget.title' | translate }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              #cpTitle
              class="hasvalue"
              id="widget.titleColor"
              type="text"
              formControlName="titleColor"
              [style.background]="settingsForm.get('titleColor').value"
              [colorPicker]="settingsForm.get('titleColor').value"
              (colorPickerChange)="colorPickerChange($event, 'titleColor')"
              (change)="colorPickerChange(cpTitle.value, 'titleColor')"
              [cpPosition]="'auto'"
              [style.background]="cpTitle.value"
            />

            <label
              class="placeholder"
              data-translation="widget.titleColor"
              for="widget.titleColor"
              >{{ 'widget.titleColor' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              class="hasvalue"
              id="widget.description"
              type="text"
              formControlName="description"
            />
            <label
              class="placeholder"
              data-translation="widget.description"
              for="widget.description"
              >{{ 'widget.description' | translate }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              #cpDesc
              class="hasvalue"
              id="widget.descriptionColor"
              type="text"
              formControlName="descriptionColor"
              [style.background]="settingsForm.get('descriptionColor').value"
              [colorPicker]="settingsForm.get('descriptionColor').value"
              (colorPickerChange)="
                colorPickerChange($event, 'descriptionColor')
              "
              (change)="colorPickerChange(cpDesc.value, 'descriptionColor')"
              [cpPosition]="'auto'"
              [style.background]="cpDesc.value"
            />

            <label
              class="placeholder"
              data-translation="widget.descriptionColor"
              for="widget.descriptionColor"
              >{{ 'widget.descriptionColor' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" formGroupName="button">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              class="hasvalue"
              id="widget.buttonText"
              type="text"
              formControlName="text"
            />
            <label
              class="placeholder"
              data-translation="widget.buttonText"
              for="widget.buttonText"
              >{{ 'widget.bannerButtonText' | translate }}</label
            >
          </div>
        </div>
      </div>

      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              #cpButtonText
              class="hasvalue"
              id="widget.buttonTextColor"
              type="text"
              formControlName="textColor"
              [style.background]="settingsForm.get('button.textColor').value"
              [colorPicker]="settingsForm.get('button.textColor').value"
              (colorPickerChange)="
                colorPickerChange($event, 'button.textColor')
              "
              (change)="
                colorPickerChange(cpButtonText.value, 'button.textColor')
              "
              [cpPosition]="'auto'"
              [style.background]="cpButtonText.value"
            />
            <label
              class="placeholder"
              data-translation="widget.buttonTextColor"
              for="widget.buttonTextColor"
              >{{ 'widget.buttonTextColor' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" formGroupName="button">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              #cpButton
              class="hasvalue"
              id="widget.buttonColor"
              type="text"
              formControlName="color"
              [style.background]="settingsForm.get('button.color').value"
              [colorPicker]="settingsForm.get('button.color').value"
              (colorPickerChange)="colorPickerChange($event, 'button.color')"
              (change)="colorPickerChange(cpButton.value, 'button.color')"
              [cpPosition]="'auto'"
              [style.background]="cpButton.value"
            />
            <label
              class="placeholder"
              data-translation="widget.buttonColor"
              for="widget.buttonColor"
              >{{ 'widget.buttonColor' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-headline">
    <h2 data-translation="widget.widget-pointing-to-ticket">
      {{ 'widget.widget-pointing-to-ticket' | translate }}
    </h2>
    <div class="section-headline-side">←</div>
  </div>

  <div class="section-body">
    <div class="row">
      <div class="col-lg-6 ">
        <div class="input-wrapper form-wrapper">
          <div class="position-relative">
            <input
              class="hasvalue"
              id="widget.voucher"
              type="text"
              formControlName="preSelectedVoucher"
            />
            <label
              class="placeholder"
              data-translation="widget.voucher"
              for="widget.voucher"
              >{{ 'widget.widget-pointing-to-voucher' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <br />

    <ng-container *ngFor="let widgetSelectionTariffType of widgetSelectionTariffTypes">
      <h4 class="title">
        {{ widgetSelectionTariffType.ticketTypeName | translate }}
      </h4>
      
      <ng-container *ngFor="let tariff of widgetSelectionTariffType.tariffs">
        <div class="row">
          <label
            class="decorated-radio-button col-lg-12"
            [for]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"
          >
            <input
              [id]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"
              formControlName="preSelectedTicket"
              [value]="widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId"
              type="radio"
              (click)="deselectRadio('preSelectedTicket', widgetSelectionTariffType.ticketTypeId + '_' + tariff.ticketPersonTypeId)"
            />

            <span class="radio-button"></span>

            <span class="black radio-label"
              >{{ tariff.name | translate }}
            </span>
          </label>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div>
    <br /><br />

    <button
      type="button"
      [disabled]="loading || settingsForm.invalid"
      (click)="createNewWidget()"
    >
      <span
        class="continue-button-text"
        data-translation="widget.new-from-settings"
        >{{ 'widget.new-from-settings' | translate }}</span
      >
    </button>

    <button
      *ngIf="settingsForm.dirty && widgetSettings?.id"
      type="button"
      [disabled]="loading || settingsForm.invalid"
      (click)="updateWidget()"
    >
      <span
        class="continue-button-text"
        data-translation="widget.update-settings"
        >{{ 'widget.update-settings' | translate }}</span
      >
    </button>

    <br /><br />
  </div>

  <ng-container *ngIf="!loading && widgetSettings?.id">
    <div class="section-headline">
      <h2 data-translation="widget.embed">{{ 'widget.embed' | translate }}</h2>
      <div class="section-headline-side">←</div>
    </div>

    <div class="section-body">
      <ng-container *ngIf="iframeEmbedCode">
        <h4 class="title">Iframe</h4>

        <p class="black">{{ iframeEmbedCode }}</p>
      </ng-container>

      <ng-container *ngIf="iframeURI">
        <h4 class="title">URL</h4>

        <p class="black">{{ iframeURI }}</p>
      </ng-container>
    </div>

    <div class="section-headline">
      <h2 data-translation="widget.preview">
        {{ 'widget.preview' | translate }}
      </h2>
      <div class="section-headline-side">←</div>
    </div>

    <div class="section-body">
      <div class="iframe-wrapper">
        <iframe
          [width]="iframeAttributes?.width"
          [height]="iframeAttributes?.height"
          [src]="iframeURI | safeResource"
        ></iframe>
      </div>
    </div>
  </ng-container>
</form>
