<div class="container-fluid h-100-p">
  <div class="page-content h-100-p">
    <div class="page-padding-wrapper h-100-p">
      <h4 class="title">
        {{ 'widget.chooseWidgetTitle' | translate }}
      </h4>

      <app-navigation-tabs
        type="normal"
        [tabs]="tabs"
        [activeTab]="activeTab"
        (activeTabChanged)="onTabChange($event)"
      ></app-navigation-tabs>

      <ng-container *ngIf="widgetSelectionTariffTypes$ | async as widgetSelectionTariffTypes; else isLoading">
        <ng-container *ngIf="activeTab === 'ticketsWidget'">
          <app-tickets-widget-admin [eventId]="eventId" [widgetSelectionTariffTypes]="widgetSelectionTariffTypes">
          </app-tickets-widget-admin>
        </ng-container>

        <ng-container *ngIf="activeTab === 'bannerWidget'">
          <app-banner-widget-admin-component
            [widgetSettings]="widgetSettings?.bannerWidget"
            [eventWidgets]="eventWidgets"
            [widgetSelectionTariffTypes]="widgetSelectionTariffTypes"
            [loading]="loading"
            (createNewWidget)="createNewWidget($event)"
            (updateWidget)="updateWidget($event)"
            (loadWidget)="loadWidgetSettings($event)"
          >
          </app-banner-widget-admin-component>
        </ng-container>
      </ng-container>

      <ng-template #isLoading>
        <app-loader></app-loader>
      </ng-template>
    </div>
  </div>
</div>
