import {
  combineLatest,
  Observable,
  Subscription
} from 'rxjs';

import { emailChangeStatus, getLanguage, getProfile, passChangeStatus, State } from '@app/app.reducer';
import { PasswordChanged, PatchEmail, PatchPassword } from '@store/user/user.actions';
import { first } from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {
  getEmailChangeForm,
  getPasswordChangeForm
} from '../form-inputs.edit-account';

import { StatusBarService } from '@app/status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { UserProfileModel } from '@store/user/user.interface';

@Component({
  selector: 'app-change-credentials',
  templateUrl: './change-credentials.component.html',
  styleUrls: ['./change-credentials.component.scss']
})
export class ChangeCredentialsComponent implements OnInit, OnDestroy, AfterViewInit {
  emailChangeActive: boolean = false;
  passwordChangeActive: boolean = false;

  changeEmailAction: string[] = ['changeEmailForm'];
  changePasswordAction: string[] = ['changePasswordForm'];
  emailForm: FormGroup;
  passwordForm: FormGroup;
  emailInputs: InputBase<any>[];
  passwordInputs: InputBase<any>[];
  profile: UserProfileModel;
  profileAndLanguage$: Observable<[UserProfileModel, string]>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private statusBarService: StatusBarService,
    private windowSizeService: WindowSizeService
  ) {
    // set password status change to null so we can react on it later when we got real response
    this.store.dispatch(new PasswordChanged(null));
  }

  ngOnInit() {
    this.profileAndLanguage$ = combineLatest([
      this.store.pipe(select(getProfile)),
      this.store.pipe(select(getLanguage))
    ])
    .pipe(first(profileAndLanguage => profileAndLanguage.every(item => !!item)));

    this.subscriptions.add(
      this.store.pipe(select(passChangeStatus)).subscribe(statusCode => {
        if (statusCode === 204) {
          this.statusBarService.setStatus('Password changed successfully', 'success');
        }

        if (statusCode === 200) {
          this.profile['isPasswordChangePending'] = true;
        }
      })
    );

    this.subscriptions.add(
      this.store.pipe(select(getProfile)).subscribe(profile => {
        this.profile = { ...profile };
        this.emailInputs = getEmailChangeForm();
        this.emailForm = this.formsService.toFormGroup(this.emailInputs, this.changeEmailAction);
      })
    );

    this.passwordInputs = getPasswordChangeForm();
    this.passwordForm = this.formsService.toFormGroup(this.passwordInputs, this.changePasswordAction);
  }

  ngAfterViewInit() {
    if (!this.profile.email) {
      const emailBlock = document.querySelector('.form-block') as HTMLElement;

      this.windowSizeService.scrollToElement(emailBlock, 50, 50, 0.02);

      setTimeout(() => {
        emailBlock.focus();
        this.emailChangeActive = true;
      }, 100);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public emailFormSaveCallback = (inputs: InputBase<any>[]) => {
    this.emailInputs = this.formsService.updateInputs(this.emailInputs, inputs);
  };

  public passwordFormSaveCallback = (inputs: InputBase<any>[]) => {
    this.passwordInputs = this.formsService.updateInputs(this.passwordInputs, inputs);
  };

  submitEmailChange() {
    setTimeout(() => {
      this.profileAndLanguage$.switchMap(([user, language]) => {
        this.store.dispatch(
          new PatchEmail({
            email: this.emailInputs[0].value,
            password: this.emailInputs[2].value,
            userId: user.id,
            language
          })
        );
        return this.store.pipe(select(emailChangeStatus));
      })
      .subscribe(code => {
        if (code && code >= 200 && code < 300) {
          this.profile['isEmailChangePending'] = true;
        }

        this.emailChangeActive = false;
      });
    }, 100);
  }

  submitPasswordChange() {
    this.profileAndLanguage$.subscribe(([user, language]) => {
      this.profile['isPasswordChangePending'] = true;

      this.store.dispatch(
        new PatchPassword({
          password: this.passwordInputs[0].value,
          userId: user.id,
          language
        })
      );

      this.passwordChangeActive = false;
    });
  }
}
