import { Action } from '@ngrx/store';
import { BookingPackageType, BookingProductType } from '@products/models/booking.model';
import { ProductList, SendingOptionModel } from '@products/models/product-selection.model';

export enum ActionTypes {
  SET_INITIAL_PRODUCT_SELECTION_LIST = '[Products API] Set initial product selection list',
  SET_PREFERRED_PRODUCT_SELECTION_LIST = '[Products API] Set preferred product selection list',
  SET_VOUCHER_PRODUCT_IN_SELECTION_LIST = '[Voucher/Booking API/Voucher] Set voucher product in selection list',
  REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST = '[Voucher/Booking API/Voucher] Remove voucher product from selection list',
  REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST = '[Product Booking/Voucher] Remove voucher code product from selection list',
  SET_PACKAGE_IN_SELECTION_LIST = '[Booking API/Package] Set package in selection list',
  REMOVE_PACKAGE_FROM_SELECTION_LIST = '[Booking API/Package] Remove package from selection list',
  INIT_SENDING_OPTIONS = '[Exhibition Change] Init sending options',
  SET_SENDING_OPTIONS = '[Product Selection/SendingOptions] Set sending options',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class SetInitialProductSelectionList implements Action {
  readonly type = ActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST;
  constructor(public payload: ProductList) {}
}

export class SetPreferredProductSelectionList implements Action {
  readonly type = ActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST;
  constructor(public payload: ProductList) {}
}

export class SetVoucherProductInSelectionList implements Action {
  readonly type = ActionTypes.SET_VOUCHER_PRODUCT_IN_SELECTION_LIST;
  constructor(public payload: { bookingProductTypes: BookingProductType[]; isSelfRegistration: boolean }) {}
}

export class RemoveVoucherProductFromSelectionList implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class RemoveVoucherCodeProductFromSelectionList implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST;
  constructor(public payload: string) {}
}

export class SetPackageInSelectionList implements Action {
  readonly type = ActionTypes.SET_PACKAGE_IN_SELECTION_LIST;
  constructor(public payload: BookingPackageType[]) {}
}

export class RemovePackageFromSelectionList implements Action {
  readonly type = ActionTypes.REMOVE_PACKAGE_FROM_SELECTION_LIST;
  constructor(public payload: BookingPackageType[]) {}
}

export class InitSendingOptions implements Action {
  readonly type = ActionTypes.INIT_SENDING_OPTIONS;
  constructor(public payload: SendingOptionModel[]) {}
}

export class SetSendingOptions implements Action {
  readonly type = ActionTypes.SET_SENDING_OPTIONS;
  constructor(public payload: SendingOptionModel[]) {}
}

export class ResetProductSelection implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | SetInitialProductSelectionList
  | SetPreferredProductSelectionList
  | SetVoucherProductInSelectionList
  | RemoveVoucherProductFromSelectionList
  | RemoveVoucherCodeProductFromSelectionList
  | SetPackageInSelectionList
  | RemovePackageFromSelectionList
  | InitSendingOptions
  | SetSendingOptions
  | ResetProductSelection;
