import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { ProductService } from '@products/services/product.service';
import { consoleLog } from '@shared/app-utils';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { ResetReducer as AdditionalServicesResetReducer } from '@store/additional-services/additional-services.actions';
import { PartialResetReducer as CustomizationPartialResetReducer, ResetShoppingStartTime } from '@store/customization/customization.actions';
import { CustomizationService } from '@store/customization/customization.service';
import { PartialResetReducer as ExhibitionPartialResetReducer } from '@store/exhibition/exhibition.actions';
import { ResetZipCodesCities } from '@store/helpers/helper.actions';
import { ResetReducer as LegitimationResetReducer } from '@store/legitimation/legitimation.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { ResetBookingReducer, ResetHolderReducer, ResetProductSelectionReducer, ResetStatusReducer } from '@store/products/product.actions';
import { PartialResetReducer as StepsFormPartialResetReducer, ResetReducer as StepsFormResetReducer } from '@store/step-forms/steps-forms.actions';
import { ResetReducer as UserResetReducer } from '@store/user/user.actions';
import { fromEvent as observableFromEvent, merge as observableMerge, of as observableOf } from 'rxjs';
import { first, mapTo, share } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./shared/services-with-reducers/customization/customization.service";
import * as i3 from "./shared/error-handling/error-handling.service";
import * as i4 from "./shared/forms/forms.service";
import * as i5 from "./_pages/products/services/booking.service";
import * as i6 from "./_pages/products/services/product.service";
var AppService = /** @class */ (function () {
    function AppService(store, customizationService, errorHandlingService, formsService, bookingService, productService) {
        this.store = store;
        this.customizationService = customizationService;
        this.errorHandlingService = errorHandlingService;
        this.formsService = formsService;
        this.bookingService = bookingService;
        this.productService = productService;
        if (typeof navigator !== 'undefined') {
            this.online$ = observableMerge(observableOf(navigator.onLine), observableFromEvent(window, 'online').pipe(mapTo(true)), observableFromEvent(window, 'offline').pipe(mapTo(false)));
        }
        else {
            this.online$ = observableOf(true);
        }
    }
    AppService.prototype.resetReducersWithCallback = function (releaseAllVouchersAndTickets, callback) {
        if (releaseAllVouchersAndTickets) {
            this.productService.releaseAllVouchersAndProductBookings();
        }
        var sharedObservable$ = this.store.pipe(select(getAllBookedTariffs)).pipe(first(), share());
        sharedObservable$.subscribe(function () {
            callback();
        });
        return sharedObservable$;
    };
    AppService.prototype.resetReducers = function (releaseAllVouchersAndTickets, releaseContingents) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        if (releaseContingents === void 0) { releaseContingents = true; }
        consoleLog('Reset reducers');
        //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
        this.errorHandlingService.clearAllErrors();
        this.formsService.resetInputsValidity();
        if (releaseContingents) {
            this.bookingService
                .releaseAllContingentReservations$()
                .subscribe(function (orderUuid) { return consoleLog("Releasing contingent tickets for uuid: " + orderUuid); });
        }
        //we have to make this operation synchronous as otherwise setStyles method would potentially be called before the store state has been changed
        //(e.g. the customization template wouldn't be changed):
        return this.resetReducersWithCallback(releaseAllVouchersAndTickets, function () {
            new Promise(function (resolve) {
                _this.store.dispatch(new CustomizationPartialResetReducer()); // customization reducer keeps localized images and operator settings
                _this.store.dispatch(new ExhibitionPartialResetReducer());
                _this.store.dispatch(new StepsFormPartialResetReducer());
                _this.store.dispatch(new AdditionalServicesResetReducer());
                _this.store.dispatch(new LegitimationResetReducer());
                _this.store.dispatch(new ResetZipCodesCities());
                _this.store.dispatch(new ResetProductSelectionReducer());
                _this.store.dispatch(new ResetBookingReducer());
                _this.store.dispatch(new ResetHolderReducer());
                _this.store.dispatch(new ResetStatusReducer());
                resolve();
            }).then(function () {
                _this.customizationService.setStyles();
            });
        });
    };
    AppService.prototype.resetReducersWithUser = function (releaseAllVouchersAndTickets, releaseContingents) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        if (releaseContingents === void 0) { releaseContingents = true; }
        consoleLog('Reset event including user');
        //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
        this.errorHandlingService.clearAllErrors();
        this.formsService.resetInputsValidity();
        if (releaseContingents) {
            this.bookingService
                .releaseAllContingentReservations$()
                .subscribe(function (orderUuid) { return consoleLog("Releasing contingent tickets for uuid: " + orderUuid); });
        }
        // customization reducer is kept on logout
        return this.resetReducersWithCallback(releaseAllVouchersAndTickets, function () {
            new Promise(function (resolve) {
                _this.store.dispatch(new ResetShoppingStartTime());
                _this.store.dispatch(new UserResetReducer());
                _this.store.dispatch(new ExhibitionPartialResetReducer());
                _this.store.dispatch(new StepsFormResetReducer());
                _this.store.dispatch(new AdditionalServicesResetReducer());
                _this.store.dispatch(new LegitimationResetReducer());
                _this.store.dispatch(new ResetZipCodesCities());
                _this.store.dispatch(new ResetProductSelectionReducer());
                _this.store.dispatch(new ResetBookingReducer());
                _this.store.dispatch(new ResetHolderReducer());
                _this.store.dispatch(new ResetStatusReducer());
                resolve();
            }).then(function () {
                _this.customizationService.setStyles();
            });
        });
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.Store), i0.inject(i2.CustomizationService), i0.inject(i3.ErrorHandlingService), i0.inject(i4.FormsService), i0.inject(i5.BookingService), i0.inject(i6.ProductService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
