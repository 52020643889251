import { Action } from '@ngrx/store';
import { HolderUuids, TicketHolder, TicketHolderForm } from '@products/models/holder.model';
import { InputBase } from '@shared/forms/inputs/input-base.class';

export enum ActionTypes {
  CREATE_PRODUCT_HOLDER = '[Products Holder] Create product holders',
  SET_PRODUCT_HOLDER = '[Booking API/Holder] Set product holders',
  REMOVE_PRODUCT_HOLDER = '[Products Holder] Remove product holders',
  REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER = '[Products Holder] Remove all product holders and set anonymous holder',
  SET_ACTIVE_SLIDE = '[Products Holder] Set active holder slide index (personalisation carousel)',
  GET_QUESTIONNAIRE_INPUTS = '[Products Holder] Get questionnaire inputs',
  SET_QUESTIONNAIRE_INPUTS = '[Questionnaire API/Holder] Set questionnaire inputs',
  LOAD_CLAIMING_TICKET_HOLDER = '[Holder/Tariff] Load ticket holder',
  SET_TICKET_HOLDER = '[Holder] Set ticket holder',
  POST_TICKET_HOLDER_FORM = '[Holder/Tariff] Post ticket holder form - secretary function',
  SET_TICKET_HOLDER_FORM_SUBMIT_RESULT = '[Holder/Tariff/API] Set result of Post call submitting ticket holder form - secretary function',
  DOWNLOAD_TICKET = '[Holder/Ticket] Download ticket',
  DOWNLOAD_MOBILE_TICKET = '[Holder/Ticket] Download mobile ticket',
  DOWNLOAD_PASS_BOOK = '[Holder/Ticket] Download pass book',
  SET_TICKET_HOLDER_INPUTS = '[Holder/Ticket] Set ticket holder inputs',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class CreateProductHolder implements Action {
  readonly type = ActionTypes.CREATE_PRODUCT_HOLDER;
  constructor(public payload: number) {}
}

export class SetProductHolder implements Action {
  readonly type = ActionTypes.SET_PRODUCT_HOLDER;
  constructor(public payload: HolderUuids) {}
}

export class RemoveProductHolder implements Action {
  readonly type = ActionTypes.REMOVE_PRODUCT_HOLDER;
  constructor(public payload: HolderUuids) {}
}

export class RemoveProductHoldersAndSetAnonymousHolder implements Action {
  readonly type = ActionTypes.REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER;
  constructor(public payload: HolderUuids) {}
}

export class SetActiveSlide implements Action {
  readonly type = ActionTypes.SET_ACTIVE_SLIDE;
  constructor(public payload: number) {}
}

export class GetQuestionnaireInputs implements Action {
  readonly type = ActionTypes.GET_QUESTIONNAIRE_INPUTS;
  constructor(public payload: { eventId: number; ticketPersonIds: number[] }) {}
}

export class SetQuestionnaireInputs implements Action {
  readonly type = ActionTypes.SET_QUESTIONNAIRE_INPUTS;
  constructor(public payload: InputBase<any>[]) {}
}

export class LoadTicketHolder implements Action {
  readonly type = ActionTypes.LOAD_CLAIMING_TICKET_HOLDER;
  constructor(public payload: string) {}
}

export class SetTicketHolder implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER;
  constructor(public payload: TicketHolder) {}
}

export class PostTicketHolderForm implements Action {
  readonly type = ActionTypes.POST_TICKET_HOLDER_FORM;
  constructor(public payload: TicketHolderForm) {}
}

export class SetPostTicketHolderFormResult implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_FORM_SUBMIT_RESULT;
  constructor(public payload: boolean) {}
}

export class DownloadTicket implements Action {
  readonly type = ActionTypes.DOWNLOAD_TICKET;
  constructor(public payload: string) {}
}

export class DownloadMobileTicket implements Action {
  readonly type = ActionTypes.DOWNLOAD_MOBILE_TICKET;
  constructor(public payload: string) {}
}

export class DownloadPassBook implements Action {
  readonly type = ActionTypes.DOWNLOAD_PASS_BOOK;
  constructor(public payload: string) {}
}

export class SetTicketHolderInputs implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_INPUTS;
  constructor(public payload: InputBase<any>[]) {}
}

export class ResetHolder implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | CreateProductHolder
  | SetProductHolder
  | RemoveProductHolder
  | RemoveProductHoldersAndSetAnonymousHolder
  | SetActiveSlide
  | GetQuestionnaireInputs
  | LoadTicketHolder
  | SetTicketHolder
  | SetPostTicketHolderFormResult
  | SetTicketHolderInputs
  | SetQuestionnaireInputs
  | ResetHolder;
