import { Observable, combineLatest as observableCombineLatest, Subscription } from 'rxjs';

import { debounceTime, filter, first } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';

import { Component, ElementRef, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { AppConstants } from '../shared/app-constants';
import { getAppVersion, getLocalStorageString } from '../shared/app-utils';
import { ExhibitionSettingModel } from '../shared/services-with-reducers/customization/customization.interfaces';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { getAllBookedProductsTotalPrice, isAnonymousProductBooked } from '../shared/services-with-reducers/products/booking/booking.selectors';

@Component({
  moduleId: module.id,
  selector: 'app-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss']
})
export class StepNavigationComponent implements OnDestroy {
  public stepList: Array<any>;
  public totalTicketPrice: number = 0;
  public currentStepIndex: number;
  public anonymousTicketTaken: boolean = false;
  public exhibitionSettings$: Observable<ExhibitionSettingModel>;
  private appVersion: string;
  private subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _el: ElementRef,
    private _customizationService: CustomizationService,
    private _helperService: HelperService
  ) {
    this.appVersion = getAppVersion();

    this.exhibitionSettings$ = this._store.pipe(select(fromRoot.getExhibitionSettings));

    this.subscriptions.add(
      this._store
        .select(fromRoot.getOrderedStepsValidityArray)
        .pipe(
          filter(data => !!data),
          debounceTime(50)
        )
        .subscribe(newSteps => {
          if (!this.stepList || this.stepList.length !== newSteps.length) {
            this.stepList = JSON.parse(JSON.stringify(newSteps));
            return true;
          }

          this.stepList.forEach(step => {
            // mutate the step with new data (this is needed to enable css transition animation)
            newSteps.forEach(newStep => {
              if (newStep.key === step.key) {
                step.value.visible = newStep.value.visible;
                step.value.disabled = newStep.value.disabled;
                step.value.order = newStep.value.order;
              }
            });

            //reorder updated
            this.stepList.sort((a, b) => {
              return a.value.order - b.value.order;
            });
          });

          this.refitStepNavigation();
          this._helperService.stepNavigationRendered();
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getOrderedStepsValidityArray)
        .pipe(
          filter(data => !!data),
          debounceTime(500) //the following code may be a bit more delayed as we're only detecting a new version of the application there
        )
        .subscribe(() => {
          const oldAppVersion = getLocalStorageString(AppConstants.appVersionReducer);

          if (!(oldAppVersion && oldAppVersion === this.appVersion)) {
            //if a new application version is detected show the force reload message to the user:
            this._helperService.setReloadRequired(true);
          }
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSelectedStep),
        this._store.select(fromRoot.getOrderResponse),
        this._store.select(isAnonymousProductBooked)
      )
        .pipe(filter(data => !!data[0]))
        .subscribe(([stepName, orderResponse, isAnonymousProductBooked]) => {
          this.anonymousTicketTaken = isAnonymousProductBooked;
          let orderId = '';

          if (orderResponse !== null && orderResponse.szCode && orderResponse.szCode !== null) {
            orderId = orderResponse.szCode.toString();
          } else {
            orderId = (Math.random() * 10000000000000).toFixed(0).toString();
          }

          observableCombineLatest(
            this._store.select(fromRoot.getDoubleClickScripts),
            this._store.select(fromRoot.getGenericScripts)
          )
            .pipe(
              first(data => data.every(item => !!item)),
            )
            .subscribe(([doubleClickScripts, genericScripts]) => {
              this.refitStepNavigation();

              this.subscriptions.add(
                this._store.select(getAllBookedProductsTotalPrice).subscribe(price => this.totalTicketPrice = price)
              );

              if (
                doubleClickScripts.hasOwnProperty(stepName) &&
                doubleClickScripts[stepName]
              ) {
                const scriptUrl = doubleClickScripts[stepName];

                if (this.isURL(scriptUrl)) {
                  this._customizationService.addIframe(
                    scriptUrl,
                    'dblclick-script'
                  );
                }
              }

              if (
                doubleClickScripts.hasOwnProperty('allpagesheader') &&
                doubleClickScripts['allpagesheader']
              ) {
                const scriptUrl = doubleClickScripts['allpagesheader'];

                if (this.isURL(scriptUrl)) {
                  this._customizationService.addIframe(
                    scriptUrl,
                    'dblclick-script-all-page'
                  );
                }
              }

              if (
                genericScripts.hasOwnProperty(stepName) &&
                genericScripts[stepName]
              ) {
                const script = genericScripts[stepName];
                try {
                  this._customizationService.injectTrackingScript(
                    script,
                    'generic-script',
                    this.totalTicketPrice.toString(),
                    orderId
                  );
                } catch (e) {
                  console.log(e);
                }
              }

              if (
                genericScripts.hasOwnProperty('allpagesheader') &&
                genericScripts['allpagesheader']
              ) {
                const script = genericScripts['allpagesheader'];
                try {
                  this._customizationService.injectTrackingScript(
                    script,
                    'generic-script-all-page',
                    null,
                    null
                  );
                } catch (e) {
                  console.log(e);
                }
              }
            });
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isURL(str) {
    var regexp = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  }

  stepSelected(stepKey) {
    this._store.dispatch(new stepsFormsActions.SetSelectedStep(stepKey));
  }

  refitStepNavigation() {
    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (this._el.nativeElement.children.length) {
          const steps = [].slice.call(
            this._el.nativeElement.children[0].children
          );

          const activeStep = steps.forEach(step => {
            if (step.children[0].classList.contains('active-link')) {
              step.classList.add('active-step');
              let i = steps.findIndex(x => x == step);
              this.currentStepIndex = i % 2 == 1 ? i + 1 : i;
              for (let j = 0; j < i; j++) {
                steps[j].children[0].classList.add('notActive');
              }
            } else {
              step.classList.remove('active-step');
            }
          });
        }
      }, 50);
    }
  }
}
