var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, skip, switchMap, takeUntil } from 'rxjs/operators';
import { FormsService } from '../../shared/forms/forms.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { AppConstants } from '../../../app/shared/app-constants';
import { getExhibitionSettings, getLanguage, getPrivacyInput, getProfile, getSelectedExhibitionId, getSpinnerValue } from '../../app.reducer';
import { prepareDisclaimerCheckboxes } from '../../shared/services-with-reducers/customization/forms/disclaimer-checkboxes-data';
import { PartialResetReducer, SelectAction } from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import { SetSpinnerValue } from '../../shared/services-with-reducers/helpers/helper.actions';
import { SetClaimedTicketHash, SetTicketClaimedHashValid } from '../../shared/services-with-reducers/products/booking/booking.actions';
import { getClaimedTicketHash, getClaimedTicketHashValid } from '../../shared/services-with-reducers/products/booking/booking.selectors';
import { DownloadMobileTicket, DownloadPassBook, DownloadTicket, GetQuestionnaireInputs, LoadTicketHolder, PostTicketHolderForm, SetPostTicketHolderFormResult, SetTicketHolder } from '../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolder, getTicketHolderFormSubmitResult, getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';
import { SetInputs } from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
var WebShopDownloadComponent = /** @class */ (function () {
    function WebShopDownloadComponent(route, formsService, location, customizationService, store, translate, helperService) {
        var _this = this;
        this.route = route;
        this.formsService = formsService;
        this.location = location;
        this.customizationService = customizationService;
        this.store = store;
        this.translate = translate;
        this.helperService = helperService;
        this.allFormsValid = false;
        this.hasValidTicketHash = false;
        this.isMobile = false;
        this.loading = true;
        this.isQuestionnaireRequired = true;
        this.downloadTicketButton = '';
        this.checkboxesFormsActionName = ['ticket-download-policy'];
        this.feedbackControlObject = {};
        this.feedbackMessages = [{ translate: false, label: 'helo' }];
        this.childForms = {};
        this.validationControll = {
            policy: false,
            questionnaire: false,
            ticketholder: false
        };
        this.unsubscribe = new Subject();
        /* rerender MUST NOT BE REMOVED because this function is used as validationCallBack function
        if rerender is removed function will stop working properly */
        this.setPolicy = function (inputs, rerender) {
            // get updated inputs, now we need to everwrite the old set with updated ones
            if (!_this.exhibitorSettings) {
                return;
            }
            _this.checkboxesInputs = inputs;
            var arePrivacyPolicyInputsValid = _this.checkboxesInputs.filter(function (input) {
                return (input.options[0].value &&
                    input.required) ||
                    !input.required;
            }).length === _this.checkboxesInputs.length;
            var validityData = {
                formName: 'policy',
                valid: arePrivacyPolicyInputsValid,
                inputs: _this.checkboxesInputs,
                form: _this.checkboxesForm,
            };
            _this.setValidity(validityData);
        };
        this.isMobile = this.helperService.isMobile();
        // small reset on page reload
        this.store.dispatch(new PartialResetReducer());
        this.store.dispatch(new SetTicketHolder(null));
        this.store.dispatch(new SetSpinnerValue(false));
        combineLatest([
            this.route.params,
            this.route.queryParams
        ])
            .pipe(filter(function (_a) {
            var params = _a[0];
            return !!params;
        }), takeUntil(this.unsubscribe))
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.store.dispatch(new SelectAction(params.id));
            _this.eventId = params.id;
            if (queryParams.t) {
                _this.loading = true;
                // set validity of hash to null as we cannot say now whether it is valid or not
                _this.store.dispatch(new SetTicketClaimedHashValid(null));
                // load the ticket holder based on the hash comming in URL
                _this.store.dispatch(new LoadTicketHolder(queryParams.t));
                _this.store.dispatch(new SetClaimedTicketHash(queryParams.t));
                _this.location.replaceState('/webshop-download/' + params.id);
            }
        });
        // ensure we have right translation for privacy checkbox
        this.store.pipe(select(getExhibitionSettings), first(function (settings) { return !!settings; }), switchMap(function (settings) {
            _this.exhibitorSettings = settings;
            _this.customizationService.setStyles();
            return _this.translate.stream([
                'personalize.privacy-link',
                'personalize.privacy-link.text',
                'personalize.privacy-optional',
                'personalize.privacy-optional.text',
            ]); // stream function is triggered again on language change
        }), takeUntil(this.unsubscribe))
            .subscribe(function (termsTranslations) {
            var confirmationCheckboxes = prepareDisclaimerCheckboxes(_this.exhibitorSettings, termsTranslations, _this.helperService.isSelfregistration(), false, false);
            var privacyPayload = {
                formInfo: AppConstants.PersonaliseFormsKeys.privacy,
                inputSet: {
                    rerender: false,
                    list: confirmationCheckboxes
                }
            };
            // trigger initial validation for privacy and policy
            _this.setPolicy(confirmationCheckboxes);
            _this.store.dispatch(new SetInputs(privacyPayload));
        });
    }
    WebShopDownloadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinnerValue$ = this.store.pipe(select(getSpinnerValue));
        this.store.pipe(select(getClaimedTicketHash), filter(function (hash) { return !!hash; }), switchMap(function (hash) {
            _this.store.dispatch(new LoadTicketHolder(hash));
            return _this.store.pipe(select(getTicketHolder), filter(function (holder) { return !!holder; }));
        }), takeUntil(this.unsubscribe))
            .subscribe(function (holder) {
            _this.isQuestionnaireRequired = holder.isQuestionnaireRequired;
            // perform reset only if questionnaire is not required - as it is asynchronous, sometimes it can reset filled questionnaire and therefore user cannot download ticket
            if (!holder.isQuestionnaireRequired) {
                _this.setValidity({
                    formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
                    valid: true,
                    inputs: null,
                    form: null
                });
            }
            else {
                _this.store.pipe(select(getTicketHolderQuestionnaireInputs), first())
                    .subscribe(function (data) {
                    if (data === null || !data.length) {
                        var ticketPersonIds = holder.tickets.map(function (p) { return p.ticketPersonId; });
                        _this.store.dispatch(new GetQuestionnaireInputs({
                            eventId: _this.eventId,
                            ticketPersonIds: ticketPersonIds
                        }));
                    }
                });
            }
        });
        this.store.pipe(select(getClaimedTicketHashValid), filter(function (isValidHash) { return isValidHash !== null; }), takeUntil(this.unsubscribe))
            .subscribe(function (isValidHash) {
            _this.loading = false;
            _this.hasValidTicketHash = isValidHash;
        });
        this.store.pipe(select(getPrivacyInput), takeUntil(this.unsubscribe))
            .subscribe(function (checkboxes) {
            if (checkboxes && checkboxes.list.length) {
                if (checkboxes || !_this.checkboxesForm) {
                    _this.checkboxesInputs = checkboxes.list.slice(0);
                    _this.checkboxesForm = _this.formsService.toFormGroup(checkboxes.list);
                    // for validation set the policy value to false as well
                    _this.checkboxesInputs.map(function (item) { return item; });
                    if (_this.checkboxesInputs[0].required === false) {
                        _this.validationControll.policy = true;
                    }
                    else {
                        var validationCallback = function () {
                            _this.setPolicy(checkboxes.list);
                        };
                        _this.helperService.triggerCallbackOnceFormValidationIsDone(_this.checkboxesForm, validationCallback);
                    }
                }
            }
            else {
                // if checkbox for privacy and policy is disabled in backoffice, mark it as valid
                _this.validationControll.policy = true;
            }
        });
    };
    WebShopDownloadComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    WebShopDownloadComponent.prototype.setValidity = function (validityData) {
        var _this = this;
        var formName = validityData.formName, valid = validityData.valid, inputs = validityData.inputs, form = validityData.form;
        this.childForms[formName] = validityData;
        if (inputs && form) {
            // update object holding invalid inputs
            this.feedbackControlObject = this.formsService.generateValidationFeedback(inputs, form, formName, this.feedbackControlObject);
            this.feedbackMessages = Object.keys(this.feedbackControlObject).map(function (key) { return _this.feedbackControlObject[key]; });
        }
        // set form validity and then check if all fotsm in page are valid
        this.validationControll[formName] = valid;
        this.allFormsValid = Object.keys(this.validationControll).reduce(function (acc, curr) { return acc && _this.validationControll[curr]; }, true);
    };
    WebShopDownloadComponent.prototype.downloadTicket = function (ticketType) {
        var _this = this;
        this.downloadTicketButton = ticketType;
        this.store.dispatch(new SetPostTicketHolderFormResult(false));
        this.store.dispatch(new SetSpinnerValue(true));
        // wait for response but ignore the first one already in store
        this.store.pipe(select(getTicketHolderFormSubmitResult), skip(1), first(function (success) { return !!success; }))
            .switchMap(function () { return _this.store.pipe(select(getClaimedTicketHash), first()); })
            .subscribe(function (hash) {
            if (ticketType === 'normalTicket') {
                _this.store.dispatch(new DownloadTicket(hash));
            }
            else if (ticketType === 'mobileTicket') {
                _this.store.dispatch(new DownloadMobileTicket(hash));
            }
            else if (ticketType === 'passBook') {
                _this.store.dispatch(new DownloadPassBook(hash));
            }
        });
        combineLatest([
            this.store.pipe(select(getClaimedTicketHash)),
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getLanguage)),
            this.store.pipe(select(getProfile))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var hash = _a[0], eventId = _a[1], language = _a[2], profile = _a[3];
            var ticketHolder;
            // if there is a newsletter check box we use that value
            // if there is no newsletter checkbox and there is profile take the value from there
            // if nothing from above we just use false value
            if (_this.childForms.ticketholder.form.value.newsletter) {
                var value = _this.childForms.ticketholder.form.value.newsletter;
                var newsletterObjectValue = value.newsletter_Newsletter;
                if (newsletterObjectValue !== undefined && value !== null) {
                    value = newsletterObjectValue;
                }
                ticketHolder = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: value });
            }
            else if (!!profile) {
                ticketHolder = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: profile.newsletterChecked });
            }
            else {
                ticketHolder = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: false });
            }
            var ticketHolderEmail = _this.childForms.ticketholder.inputs.find(function (input) { return input.key === 'email'; });
            if (ticketHolderEmail) {
                ticketHolder['email'] = ticketHolderEmail.value;
            }
            var policyForm = _this.childForms.policy;
            var policyInput = policyForm && policyForm.inputs.find(function (input) { return input.key === 'disclaimer'; });
            var privacyPolicyOption = policyInput && policyInput.options.find(function (option) { return option.key === 'disclaimerConfirmation'; });
            if (privacyPolicyOption) {
                ticketHolder['PrivacyPolicyAccepted'] = privacyPolicyOption.value;
            }
            var policyOptionalInput = policyForm && policyForm.inputs.find(function (input) { return input.key === 'disclaimerOptional'; });
            var privacyPolicyOptionalOption = policyOptionalInput && policyOptionalInput.options.find(function (option) { return option.key === 'disclaimerOptionalConfirmation'; });
            if (privacyPolicyOptionalOption) {
                ticketHolder['PrivacyPolicyOptionalAccepted'] = privacyPolicyOptionalOption.value;
            }
            delete ticketHolder['newsletter'];
            var questionnaireFormInputs = _this.childForms.questionnaire.inputs;
            var questionnaire = {
                eventId: eventId,
                values: []
            };
            if (_this.childForms.questionnaire.inputs) {
                questionnaire.values = _this.helperService.processQuestionnaireValuesBeforeSave(questionnaireFormInputs);
            }
            ticketHolder.dateOfBirth = _this.helperService.getUTCdate(ticketHolder.dateOfBirth);
            Object.keys(ticketHolder).forEach(function (key) {
                if (typeof ticketHolder[key] === 'string') {
                    var value = _this.helperService.sanitizeString(ticketHolder[key]);
                    if (value !== ticketHolder[key]) {
                        ticketHolder[key] = value;
                    }
                }
            });
            var dataToSave = {
                hash: hash,
                ticketHolder: ticketHolder,
                questionnaire: questionnaire,
                language: language
            };
            _this.store.dispatch(new PostTicketHolderForm(dataToSave));
        });
    };
    return WebShopDownloadComponent;
}());
export { WebShopDownloadComponent };
