import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PackageType } from '@products/models/package.model';
import {
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel
} from '@products/models/product-selection.model';
import { Tariff, TariffType } from '@products/models/tariff.model';
import { Workshop, WorkshopDetailViewModel, WorkshopsByDay } from '@products/models/workshop.model';
import { WorkshopStatusService } from '@products/services/workshop-status.service';
import { WorkshopService } from '@products/services/workshop.service';
import { environment } from '@src/environments/environment';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import cloneDeep from 'lodash.clonedeep';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-workshop-tariff',
  templateUrl: './workshop-tariff.component.html',
  styleUrls: ['./workshop-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkshopTariffComponent implements OnInit {
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() workshopStatusViewModel: ProductSelectionWorkshopStatusViewModel;
  @Input() packageType: PackageType;
  @Input() tariffType: TariffType;
  @Input() tariff: Tariff;
  workshopDetailViewModel$: Observable<WorkshopDetailViewModel>;
  workshopDetail: Workshop;
  workshopsByDay: WorkshopsByDay[];
  origin: string;
  workshopModalWindowYOffset: number;

  constructor(private workshopService: WorkshopService, private workshopStatusService: WorkshopStatusService) {}

  ngOnInit() {
    this.workshopsByDay = cloneDeep(this.tariff.workshopsByDay);
    this.origin = environment.protocol + environment.origin;
    this.origin = this.origin || window.location.origin;
  }

  toggleIsWorkshopByDayExpanded($event: MouseEvent, clickedWorkshopsByDay: WorkshopsByDay) {
    $event.stopPropagation();

    clickedWorkshopsByDay.expanded = !clickedWorkshopsByDay.expanded;
  }

  convertWorkshopFromDate(text: string): Date {
    return new Date(`${text}T00:00:00.000Z`);
  }

  toggleWorkshopDetailModal($event: MouseEvent, activeWorkshopId?: number): void {
    $event.stopPropagation();

    if (this.workshopStatusViewModel.isWorkshopDetailModalOpen && activeWorkshopId) {
      return;
    }

    this.workshopStatusService.toggleWorkshopDetailModalOpenState();

    if (!activeWorkshopId) {
      this.workshopDetail = null;
      return;
    }

    const workshopsByDay = this.tariff.workshopsByDay.find(workshopsByDay =>
      workshopsByDay.workshops.some(workshop => workshop.id === activeWorkshopId)
    );

    if (workshopsByDay) {
      const workshopDetail = workshopsByDay.workshops.find(workshop => workshop.id === activeWorkshopId);

      this.workshopDetail = cloneDeep(workshopDetail);

      if (this.workshopDetail) {
        this.workshopDetail.start = new Date().toISOString();
        this.workshopDetail.end = new Date().toISOString();

        this.workshopModalWindowYOffset = window.pageYOffset + 100;

        this.workshopDetailViewModel$ = this.workshopService.getWorkshopDetailViewModel$(workshopDetail.id);
      }
    }
  }
}
