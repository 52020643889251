<div>
  <app-skeleton-loader *ngIf="!isTranslationLoaded"></app-skeleton-loader>
  <h4
    *ngIf="isTranslationLoaded"
    class="title"
    data-translation-includes-children
    data-translation="step-walktrough.title"
    [appRichText]="'step-walktrough.title' | translate | hideTranslation"
  ></h4>

  <div class="list">
    <span *ngFor="let step of shoppingStepList; let index = index">
      <app-skeleton-loader *ngIf="!isTranslationLoaded"></app-skeleton-loader>
      <div
        *ngIf="!!('step-walktrough.' + step | translate | hideTranslation) && isTranslationLoaded"
        class="black"
        data-translation-includes-children
        [attr.data-translation]="'step-walktrough.' + step"
        [appRichText]="index + 1 + '. ' + ('step-walktrough.' + step | translate | hideTranslation)"
      ></div>
    </span>
  </div>
</div>
