import { HolderUuid, HolderUuids } from '@products/models/holder.model';
import { ProductType } from '@products/models/products.model';
import { Tariff } from '@products/models/tariff.model';
import { VoucherCode, VoucherType } from '@products/models/voucher.model';
import { WorkshopAvailableSeats } from '@products/models/workshop-status.model';

export interface BookingState {
  list: BookedProducts;
  countdown: BookingCountdown;
  claimedTicketHash: string;
  claimedTicketHashValid: boolean;
  ticketHolderSubmitResult: boolean;
  lastVoucherTicket: Tariff;
  isAnonymousProductSentToAPI: boolean;
}

export interface BookingCountdown {
  product?: number;
  voucher?: VoucherCountdown[];
  workshop?: number;
}

export type BookedProducts = BookingProductType[];

export type BookingProductType = BookingTariffType | BookingPackageType | BookingArticleType;

export interface BookingTariffType {
  productType: ProductType.Tariff;
  productTypeName: string;
  ticketTypeId: number;
  tariffs: BookingTariff[];
}

export interface BookingPackageType {
  productType: ProductType.Package;
  productTypeName: string;
  packageNumber: number;
  packageIndex: number;
  productTypes: BookingProductType[];
}

export interface BookingArticleType {
  productType: ProductType.Article;
  productTypeName: string;
}

export interface BookingWorkshop {
  id: number;
  name: string;
  price: number;
  holderUuids: HolderUuids;
}

export interface BookingContingentReservation {
  holderUuid: HolderUuid;
  duration: number;
  isValid: boolean;
  fromDate: Date;
  toDate: Date;
}

export interface BookingParkingReservation {
  isValid: boolean;
  since: Date;
  until: Date;
  price: number;
}

export interface SetBookingTariff {
  ticketPersonTypeId: number;
  tariffNumber: number;
  ticketTypeName: string;
  ticketName: string;
  packageNumber: number;
  packageIndex: number;
  holderUuids: HolderUuids;
  availableTickets: number;
  ticketLimit: number;
  count: number;
  price: number;
  voucherCode: VoucherCode;
  voucherType: VoucherType;
  voucherCountLimit?: number;
  activatedTimestamp?: number;
  isAnonymous: boolean;
  workshops: BookingWorkshop[];
  contingentReservations: BookingContingentReservation[];
  parkingReservations: BookingParkingReservation[];
}

export interface BookingTariff extends SetBookingTariff {
  ticketTypeId: number;
  ticketPersonId: number;
}

export interface BookingArticle {}

export interface RemoveProductCartBooking {
  ticketTypeId: number;
  ticketPersonId: number;
  packageId?: number;
  packageIndex?: number;
}

export interface SetBookingContingentReservation extends BookingContingentReservation {
  bookingReservationIndex: number;
}

export interface SetProductBookingContingentReservation {
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  ticketPersonTypeId: number;
  voucherCode?: VoucherCode;
  packageNumber?: number;
  packageIndex?: number;
  contingentReservations: SetBookingContingentReservation[];
}

export interface RemoveProductBookingContingentReservation {
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  voucherCode?: VoucherCode;
  packageNumber?: number;
  packageIndex?: number;
  removeBookingReservationIndexes: number[];
}

export interface SetProductBookingParkingReservation {
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  voucherCode?: VoucherCode;
  packageNumber?: number;
  packageIndex?: number;
  bookingReservationIndex: number;
  price: number;
  isValid: boolean;
}

export interface ProductHolderUuid {
  holderUuid: HolderUuid;
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  packageNumber?: number;
  packageIndex?: number;
}

export interface PostProductBooking {
  ticketPersonId: number;
  ticketTypeId: number;
  groupId: number;
  eventId: number;
  count: number;
  uuid: string;
  voucherCode: VoucherCode;
}

export interface PostProductBookingResponse {
  groupId: number;
  ticketTypeId: number;
  availableTickets: number;
  sellLimit: number;
  ticketPersonId: number;
}

export interface PostWorkshopBooking {
  eventId: number;
  workshopId: number;
  seats: number;
  uuid: string;
  holderUuid?: string;
}

export interface PostWorkshopResponse {
  workshopId: number;
  seats: number;
}

export interface SetBookingWorkshop {
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  voucherCode?: VoucherCode;
  packageNumber?: number;
  packageIndex?: number;
  workshops: BookingWorkshop[];
}

export interface SetWorkshopBookingsAndSetSeats {
  setBookingWorkshops: SetBookingWorkshop[];
  workshopAvailableSeats: WorkshopAvailableSeats;
}

export interface RemoveBookingWorkshopAssignedHolders {
  workshopId: number;
  removeHolderUuids: HolderUuids;
}

export interface RemoveBookingWorkshop {
  productType: ProductType;
  ticketTypeId: number;
  ticketPersonId: number;
  voucherCode?: VoucherCode;
  packageNumber?: number;
  packageIndex?: number;
  removeWorkshopsAssignedHolders: RemoveBookingWorkshopAssignedHolders[];
}

export interface RemoveWorkshopBookingsAndSetSeats {
  removeBookingWorkshops: RemoveBookingWorkshop[];
  workshopAvailableSeats: WorkshopAvailableSeats;
}

export interface BookedVoucherCountSortedByVoucherType {
  [VoucherType.PromoCode]: number;
  [VoucherType.LimiterPromoCode]: number;
  [VoucherType.OneTimeVoucher]: number;
}

export interface SharedBookedPersonIdTariffsTotalCount {
  [ticketPersonId: number]: number;
}

export interface BookedPackageTotalPriceGroupedByPackageNumber {
  [packageNumber: number]: number;
}

export interface BookedPackageTotalPriceGroupedByPackageIndex {
  [packageIndex: number]: number;
}

export interface VoucherCountdown {
  voucherCode: string;
  expiryTimestamp: number;
  isAnonymous?: boolean;
}

/**************
 * TYPE GUARDS
 **************/
export const isBookingProductTypeTariff = (
  bookingProductType: BookingProductType
): bookingProductType is BookingTariffType => {
  const tariffProductType = bookingProductType as BookingTariffType;
  return tariffProductType.productType === ProductType.Tariff;
};

export const isBookingTariffVoucher = (bookedTariff: BookingTariff) => {
  return !!bookedTariff.voucherCode;
};

export const isBookingProductTypePackage = (
  bookingProductType: BookingProductType
): bookingProductType is BookingPackageType => {
  const packageProductType = bookingProductType as BookingPackageType;
  return packageProductType.productType === ProductType.Package;
};

export const isBookingProductTypeArticle = (
  bookingProductType: BookingProductType
): bookingProductType is BookingArticleType => {
  const articleProductType = bookingProductType as BookingArticleType;
  return articleProductType.productType === ProductType.Article;
};
