import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  State,
  getExhibitionSettings,
  getSelectedExhibitionId,
  getSelectedStep,
  getStepsValidity
} from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookedPackageTotalPriceGroupedByPackageIndex, BookedProducts } from '@products/models/booking.model';
import { ProductType } from '@products/models/products.model';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import {
  getAllBookedPackagePricesGroupedByPackageIndex,
  getAllBookedProductsTotalPrice,
  getSortedBasketProducts
} from '@store/products/booking/booking.selectors';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {
  @Output() closeBasket = new EventEmitter<Boolean>();
  selectedEventId: number;
  isNextButtonDisabled = true;
  settings: ExhibitionSettingModel;
  ProductType = ProductType;
  selectedStepName$: Observable<string>;
  getSortedBookedProducts$: Observable<BookedProducts>;
  getAllBookedProductsTotalPrice$: Observable<number>;
  getAllBookedPackagePricesGroupedByPackageIndex$: Observable<BookedPackageTotalPriceGroupedByPackageIndex>;
  private subscriptions = new Subscription();

  constructor(private store: Store<State>, private router: Router, private stepsFormsService: StepsFormsService) {}

  ngOnInit() {
    this.selectedStepName$ = this.store.pipe(select(getSelectedStep));
    this.getSortedBookedProducts$ = this.store.pipe(select(getSortedBasketProducts));
    this.getAllBookedProductsTotalPrice$ = this.store.pipe(select(getAllBookedProductsTotalPrice));
    this.getAllBookedPackagePricesGroupedByPackageIndex$ = this.store.pipe(
      select(getAllBookedPackagePricesGroupedByPackageIndex)
    );

    this.subscriptions.add(
      this.store
        .pipe(
          select(getExhibitionSettings),
          filter(data => !!data)
        )
        .subscribe(settings => (this.settings = settings))
    );

    this.subscriptions.add(
      this.store.pipe(select(getSelectedExhibitionId)).subscribe(eventId => (this.selectedEventId = eventId))
    );

    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getStepsValidity)), this.store.pipe(select(getSelectedStep))])
        .pipe(
          filter(data => !!data[0] && !!data[1]),
          debounceTime(100)
        )
        .subscribe(data => {
          const [validations, selectedStepKey] = data;
          this.isNextButtonDisabled = !validations[selectedStepKey].valid;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  gotoTicketSelection() {
    this.closeBasket.emit(true);
    this.router.navigate([`/webshop/${this.selectedEventId}/tickets`]);
  }

  toNextStep() {
    this.closeBasket.emit(true);
    this.stepsFormsService.navigateToLastNotDisabledPage();
  }
}
