import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdditionalServicesState } from '@store/additional-services/additional-services.model';

export const getAdditionalServicesState = createFeatureSelector<
  AdditionalServicesState
>('additionalServices');

/**
 * Get menu menu
 * @description Get menu list
 * @returns List of menus
 */
export const getMenuList = createSelector(
  getAdditionalServicesState,
  additionalServicesState => additionalServicesState.menu
);
