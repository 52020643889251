import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookingParkingReservation } from '@products/models/booking.model';
import { PackageType } from '@products/models/package.model';
import {
  ProductSelectionBookingStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel
} from '@products/models/product-selection.model';
import { Tariff, TariffType } from '@products/models/tariff.model';
import { ParkingTariffService } from '@products/services/parking-tariff.service';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { getBookedTariffParkingReservations } from '@store/products/booking/booking.selectors';
import { Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-parking-tariff',
  providers: [ParkingTariffService],
  templateUrl: './parking-tariff.component.html',
  styleUrls: ['./parking-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingTariffComponent implements OnInit {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() packageType: PackageType;
  @Input() tariffType: TariffType;
  @Input() tariff: Tariff;
  parkingReservations$: Observable<BookingParkingReservation[]>;
  parkingReservationForm: FormGroup = new FormGroup({});

  private readonly destroy$ = new Subject<void>();
  private readonly synchronousFormControlInitializationDelay = 0;

  constructor(private store: Store<State>, private parkingTariffService: ParkingTariffService) {}

  ngOnInit() {
    this.parkingReservations$ = this.store.pipe(
      select(
        getBookedTariffParkingReservations(
          this.tariff.ticketTypeId,
          this.tariff.ticketPersonId,
          this.tariff.voucherCode,
          this.tariff.packageNumber,
          this.tariff.packageIndex
        )
      ),
      delay(this.synchronousFormControlInitializationDelay)
    );

    this.initParkingTariffServiceState();
    this.initParkingReservationFormControls();
  }

  initParkingTariffServiceState() {
    this.parkingTariffService.initState(this.tariff, this.parkingReservationForm);
  }

  initParkingReservationFormControls() {
    this.parkingReservations$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(parkingReservations => {
        this.parkingTariffService.setParkingReservationFormControls(parkingReservations);
      });
  }

  onParkingReservationSelect(parkingReservationIndex: number) {
    this.parkingTariffService.setParkingReservations(parkingReservationIndex);
  }

  parkingReservationTrackBy(index: number, parkingReservation: BookingParkingReservation) {
    return index;
  }
}
