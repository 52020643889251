import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TariffStatusService } from '@products/services/tariff-status.service';
import { State } from '@root/src/app/app.reducer';
import {
  ActionTypes as BookingActionTypes,
  Actions as BookingActions,
  RemovePackageFromBookingList,
  RemoveProductFromBookingList,
  RemoveVoucherProductFromBookingList,
  SetPackageInBookingList,
  SetProductInBookingList,
  SetValidatedContingentReservationInBooking,
  SetVoucherProductInBookingList
} from '@store/products/booking/booking.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import {
  ActionTypes as ProductSelectionActionTypes,
  Actions as ProductSelectionActions,
  SetInitialProductSelectionList,
  SetPreferredProductSelectionList
} from '@store/products/product-selection/product-selection.actions';
import { getAllTariffs } from '@store/products/product-selection/product-selection.selectors';
import {
  ActionTypes as ProductActionTypes,
  Actions as ProductActions,
  RemoveContingentBookingReservation,
  RemoveReleasedVoucher,
  SetContingentBookingReservation,
  SetRedeemedAndReleaseVoucher
} from '@store/products/product.actions';
import {
  GetUserAccountTariffsLimitStatus,
  SetAvailableTariffsStatus,
  SetIsCreateContingentReservationLoadingStatus,
  SetIsTariffLoadingStatus,
  SetReleasedVoucherAvailableTariffStatus,
  SetUserAccountTariffsLimitStatus,
  ActionTypes as TariffActionTypes
} from '@store/products/status/tariff/tariff.actions';
import { getAvailableTariffs } from '@store/products/status/tariff/tariff.selectors';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class TariffStatusEffect {
  @Effect()
  getTariffsUserAccountLimitStatus$: Observable<Action> = this.actions$.pipe(
    ofType<GetUserAccountTariffsLimitStatus>(TariffActionTypes.GET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS),
    switchMap(({ payload }: GetUserAccountTariffsLimitStatus) => {
      const eventId = payload;
      if (!eventId) {
        return EMPTY;
      }

      return this.tariffStatusService.getTicketLimitPerUserAccount$(eventId).pipe(
        map((userAccountTicketLimit: number) => new SetUserAccountTariffsLimitStatus(userAccountTicketLimit)),
        catchError(() => of(new SetUserAccountTariffsLimitStatus(null)))
      );
    })
  );

  @Effect()
  setInitialAvailableTariffsStatus$: Observable<SetAvailableTariffsStatus> = this.actions$.pipe(
    ofType<SetInitialProductSelectionList | SetPreferredProductSelectionList>(
      ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST,
      ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST
    ),
    withLatestFrom(this.store.pipe(select(getAllTariffs))),
    map(([_, tariffs]) => {
      const availableTariffs = this.tariffStatusService.mapTariffsToAvailableTariffs(tariffs);

      return new SetAvailableTariffsStatus(availableTariffs);
    })
  );

  @Effect()
  setAvailableTariffsStatus$: Observable<SetAvailableTariffsStatus> = this.actions$.pipe(
    ofType<
      | SetProductInBookingList
      | SetVoucherProductInBookingList
      | SetPackageInBookingList
      | RemoveProductFromBookingList
      | RemoveVoucherProductFromBookingList
      | RemovePackageFromBookingList
    >(
      BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST,
      BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST
    ),
    map(({ payload }) => {
      const availableTariffs = this.tariffStatusService.mapBookedProductTypesToAvailableTariffs(payload);

      return new SetAvailableTariffsStatus(availableTariffs);
    })
  );

  @Effect()
  setReleasedVoucherAvailableTariffStatus$: Observable<SetReleasedVoucherAvailableTariffStatus> = this.actions$.pipe(
    ofType<RemoveReleasedVoucher>(ProductActionTypes.REMOVE_RELEASED_VOUCHER),
    concatMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      return this.tariffStatusService
        .mapRemoveVoucherToAddAvailableTariffs$(payload)
        .map(addAvailableTariffs => new SetReleasedVoucherAvailableTariffStatus(addAvailableTariffs));
    })
  );

  @Effect()
  setAvailableTariffsStatusAfterAnonymousVoucherProductRedeem$: Observable<
    SetAvailableTariffsStatus
  > = this.actions$.pipe(
    ofType<SetRedeemedAndReleaseVoucher>(ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER),
    filter(({ payload }) => payload && payload.redeemedVoucher && payload.redeemedVoucher.isAnonymous),
    withLatestFrom(this.store.pipe(select(getAllBookedTariffs)), this.store.pipe(select(getAvailableTariffs))),
    concatMap(([{ payload }, bookedTariffs, currentAvailableTariffs]) => {
      const removedProductBookings = payload.removeProductBookings;
      if (!removedProductBookings.length) {
        return EMPTY;
      }

      const availableTariffs = this.tariffStatusService.mapRemovedBookingProductTypesToAvailableTariffsAfterAnonymousVoucherRedeem(
        removedProductBookings,
        bookedTariffs,
        currentAvailableTariffs
      );

      return of(new SetAvailableTariffsStatus(availableTariffs));
    })
  );

  @Effect()
  isTariffLoadingStatus$: Observable<Action> = this.actions$.pipe(
    ofType<ProductActions | ProductSelectionActions | BookingActions>(
      ProductActionTypes.SET_TARIFF_BOOKING,
      ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING,
      ProductActionTypes.SET_PACKAGE_BOOKING,
      ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING,
      ProductActionTypes.REMOVE_PRODUCT_BOOKING,
      ProductActionTypes.REMOVE_TARIFF_BOOKING,
      ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING,
      ProductActionTypes.REMOVE_PACKAGE_BOOKING,
      ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING,
      BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST,
      BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST,
      ProductSelectionActionTypes.SET_PACKAGE_IN_SELECTION_LIST,
      ProductSelectionActionTypes.REMOVE_PACKAGE_FROM_SELECTION_LIST
    ),
    map(action => {
      const productActionTypes: string[] = [
        ProductActionTypes.SET_TARIFF_BOOKING,
        ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING,
        ProductActionTypes.SET_PACKAGE_BOOKING,
        ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING,
        ProductActionTypes.REMOVE_PRODUCT_BOOKING,
        ProductActionTypes.REMOVE_TARIFF_BOOKING,
        ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING,
        ProductActionTypes.REMOVE_PACKAGE_BOOKING,
        ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING
      ];

      const isTariffLoading = productActionTypes.includes(action.type);

      return new SetIsTariffLoadingStatus(isTariffLoading);
    })
  );

  @Effect()
  setCreateContingentReservationLoadingStatus$: Observable<Action> = this.actions$.pipe(
    ofType<
      SetContingentBookingReservation | SetValidatedContingentReservationInBooking | RemoveContingentBookingReservation
    >(
      ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION,
      BookingActionTypes.SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING,
      ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION
    ),
    map(({ type }) => {
      const isCreateContingentBookingReservationLoading =
        type === ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION ||
        type === ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION;

      return new SetIsCreateContingentReservationLoadingStatus(isCreateContingentBookingReservationLoading);
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private tariffStatusService: TariffStatusService
  ) {}
}
