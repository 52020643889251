import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import { AppConstants } from '../../shared/app-constants';
import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { GtmService } from '../../shared/gtm/gtmService';
import { FilterPlaceholderPipe } from '../../shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { GetQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';
import { getSelectedSendingOption } from '../../shared/services-with-reducers/products/product-selection/product-selection.selectors';
import { InputsListModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import { TicketSendingOptions } from '../products/models/tariff.model';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-personalize',
  templateUrl: './web-shop-personalize.component.html',
  styleUrls: ['./web-shop-personalize.component.scss']
})
export class WebShopPersonalizeComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly TicketSendingOptions = TicketSendingOptions;
  readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;

  exhibitionSettings$: Observable<ExhibitionSettingModel>;
  isTicketHolderVisible$: Observable<boolean>;
  showLoginOnPersonalPage$: Observable<boolean>;
  showLoginOnTicketAndPersonalPage$: Observable<boolean>;
  showLoginOnTicketPage$: Observable<boolean>;
  selectedSendingOption$: Observable<string>;
  showLoginOnTicketOrTicketAndPersonalPage$: Observable<[boolean, boolean]>;
  showLoginFormOrValidationFeedbackMessages: Observable<[boolean, [boolean, boolean]]>;
  readonly isInputChanged$: Subject<boolean> = new Subject<boolean>();
  readonly subscriptions = new Subscription();

  checkboxesInputs: InputBase<any>[];
  checkboxesForm: FormGroup;
  isSelfRegistrationEnabled: boolean;
  buyerStyle = null;
  ticketHolderInputsNotVisible: boolean = false;
  modalWindowOpen: boolean = false;
  selectedSendingOption: string;
  questionnaireLoaded = false;
  continueAsGuest: boolean;
  modalUrl: string;
  modalTitle: string;
  modalTranslateTitle: boolean;
  isConfirmedEmailRequiredOnPersonalization: boolean;
  showVisitorQuestionnaire: boolean = false;
  feedbackControlObject: Object = {};

  constructor(
    private store: Store<fromRoot.State>,
    private formsService: FormsService,
    private helperService: HelperService,
    private gtmService: GtmService,
    private translateService: TranslateService,
    private filterPlaceholderPipe: FilterPlaceholderPipe
  ) {}

  ngOnInit() {
    this.isSelfRegistrationEnabled = this.helperService.isSelfregistration();

    this.exhibitionSettings$ = this.store.pipe(select(fromRoot.getExhibitionSettings));
    this.isTicketHolderVisible$ = this.store.pipe(select(fromRoot.isTicketHolderVisible));
    this.showLoginOnPersonalPage$ = this.store.pipe(select(fromRoot.showLoginOnPersonalPage));
    this.showLoginOnTicketAndPersonalPage$ = this.store.pipe(select(fromRoot.showLoginOnTicketAndPersonalPage));
    this.showLoginOnTicketPage$ = this.store.pipe(select(fromRoot.showLoginOnTicketPage));
    this.selectedSendingOption$ = this.store.pipe(select(getSelectedSendingOption));
    this.showLoginOnTicketOrTicketAndPersonalPage$ = combineLatest([
      this.showLoginOnPersonalPage$,
      this.showLoginOnTicketAndPersonalPage$
    ]);
    this.showLoginFormOrValidationFeedbackMessages = combineLatest([
      this.store.pipe(select(fromRoot.isConfirmedEmailRequiredOnPersonalization)),
      this.showLoginOnTicketOrTicketAndPersonalPage$
    ]);

    this.subscriptions.add(
      this.showLoginFormOrValidationFeedbackMessages.subscribe(
        ([isConfirmedEmailRequiredOnPersonalization, [showLoginOnPersonalPage, showLoginOnTicketAndPersonalPage]]) => {
          this.isConfirmedEmailRequiredOnPersonalization = isConfirmedEmailRequiredOnPersonalization;
          const showLogin =
            isConfirmedEmailRequiredOnPersonalization || showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage;

          if (showLogin) {
            this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.registration);

            if (!isConfirmedEmailRequiredOnPersonalization) {
              this.formsService.addStepValidationFeedback(
                this.PersonaliseFormsKeys.registration,
                'anyBuyerMessageHidden',
                'personalize.any.buyer.message.hidden'
              );
            }
          } else {
            this.formsService.removeStepValidationFeedback(
              this.PersonaliseFormsKeys.registration,
              'anyBuyerMessageHidden'
            );
          }

          this.formsService.setFormValidity(!showLogin, null, this.PersonaliseFormsKeys.registration);
        }
      )
    );

    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
        this.store.pipe(select(getTicketHolderQuestionnaireInputs)),
        this.selectedSendingOption$
      ])
        .pipe(
          first(
            ([_, ticketHolderQuestionnaireInputs, sendingOption]) =>
              (ticketHolderQuestionnaireInputs === null || !ticketHolderQuestionnaireInputs.length) &&
              sendingOption === TicketSendingOptions.TicketRetrievalLink
          )
        )
        .subscribe(([currentEventId]) => {
          this.store.dispatch(new GetQuestionnaireInputs({ eventId: currentEventId, ticketPersonIds: [] }));
        })
    );

    combineLatest([
      this.store.pipe(select(fromRoot.getTicketHolderInputSets)),
      this.isTicketHolderVisible$,
      this.exhibitionSettings$
    ])
      .first()
      .subscribe(([ticketHolderInputSets, isTicketHolderVisible, settings]) => {
        if (!isTicketHolderVisible && ticketHolderInputSets.length) {
          this.formsService.setStepValid(ticketHolderInputSets[0].formInfo);
        }

        if (ticketHolderInputSets && ticketHolderInputSets.length === 0) {
          this.ticketHolderInputsNotVisible = true;
          return;
        }

        this.ticketHolderInputsNotVisible = Object.keys(settings.ticketOwnerSettings.fieldSettings).every(
          (ticketHolderInputSetting: string) =>
            settings.ticketOwnerSettings.fieldSettings[ticketHolderInputSetting].isVisible === false
        );

        // Make ticketHolderInputs Visible if email or verifyEmail Fields are mandatory on any of the ticket holders
        if (this.ticketHolderInputsNotVisible) {
          this.ticketHolderInputsNotVisible = !ticketHolderInputSets.some(element => {
            if (!!element.inputSet && !!element.inputSet.list) {
              const holderList = element.inputSet.list;
              const emailField = holderList.find(item => item.key === 'email');
              const verifyEmailField = holderList.find(item => item.key === 'verifyEmail');
              const emailRequired = !!emailField ? emailField.required : false;
              const verifyEmailRequired = !!verifyEmailField ? verifyEmailField.required : false;

              return emailRequired || verifyEmailRequired;
            }
          });
        }
      });

    this.subscriptions.add(
      this.store
        .pipe(select(fromRoot.isContinueAsGuest))
        .subscribe(isContinueAsGuest => (this.continueAsGuest = isContinueAsGuest))
    );

    this.subscriptions.add(
      this.store
        .pipe(
          select(fromRoot.getQuestionnaire),
          filter((data: InputsListModel) => {
            // if there are no data for questionnaire, load them
            if (data.list === null) {
              this.helperService.loadBuyerQuestionnaireViaApi('personal');
              return false;
            }

            const questionnaireLoaded = data.list.length > 0;
            if (!questionnaireLoaded || this.helperService.isSelfregistration()) {
              this.formsService.setStepValid(this.PersonaliseFormsKeys.buyerQuestionnaire);
            }

            return questionnaireLoaded;
          })
        )
        .subscribe(() => (this.questionnaireLoaded = true))
    );

    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(fromRoot.getPrivacyInput)),
        this.showLoginFormOrValidationFeedbackMessages
      ]).subscribe(
        ([
          checkboxes,
          [isConfirmedEmailRequiredOnPersonalization, [showLoginOnPersonalPage, showLoginOnTicketAndPersonalPage]]
        ]) => {
          if (
            !isConfirmedEmailRequiredOnPersonalization &&
            !showLoginOnPersonalPage &&
            !showLoginOnTicketAndPersonalPage &&
            checkboxes &&
            checkboxes.list.length
          ) {
            if (checkboxes || !this.checkboxesForm) {
              this.checkboxesInputs = checkboxes.list.slice(0);
              this.checkboxesForm = this.formsService.toFormGroup(checkboxes.list, this.PersonaliseFormsKeys.privacy);
            }
          } else {
            // if there is no privacy input to show we need to mark the form as valid
            this.formsService.setFormValidity(true, null, this.PersonaliseFormsKeys.privacy);
          }
        }
      )
    );

    this.selectedSendingOption$.subscribe((selectedOption: string) => (this.selectedSendingOption = selectedOption));

    this.subscriptions.add(
      combineLatest([
        this.showLoginOnTicketOrTicketAndPersonalPage$,
        this.store.pipe(select(fromRoot.isUserLoggedIn))
      ]).subscribe(([[showLoginOnPersonalPage, showLoginOnTicketAndPersonalPage], isLogged]) => {
        if (!isLogged && (showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage)) {
          this.buyerStyle = { display: 'none' };
        } else {
          this.buyerStyle = null;
        }
      })
    );

    // US2870 - set initial
    this.subscriptions.add(
      this.store
        .pipe(
          select(fromRoot.getBuyerVisitorCheckbox),
          filter(data => !!data)
        )
        .subscribe(({ showVisitorQuestionnaire }) => (this.showVisitorQuestionnaire = showVisitorQuestionnaire))
    );
  }

  ngAfterViewInit() {
    this.gtmService.pushPersonalization();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setVisitorQuestionnaireValidity(validityData) {
    const { formName, valid, inputs, form } = validityData;
    this.formsService.setFormValidity(valid, form, this.PersonaliseFormsKeys.visitorQuestionnaire);

    if (inputs && form) {
      // update object holding invalid inputs
      this.feedbackControlObject = this.formsService.generateValidationFeedback(
        inputs,
        form,
        formName,
        this.feedbackControlObject
      );

      // US2870 - first remove all previous feedback messages
      this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);

      // US2870 - add feedback messages for those fields which are required
      Object.keys(this.feedbackControlObject).forEach(key => {
        const feedbackObject = this.feedbackControlObject[key];

        this.formsService.addStepValidationFeedback(
          this.PersonaliseFormsKeys.visitorQuestionnaire,
          feedbackObject.label,
          feedbackObject.label
        );
      });
    }
  }

  allowAdditionalDataToShow(isAdditionalData) {
    this.showVisitorQuestionnaire = isAdditionalData;

    if (isAdditionalData) {
      this.helperService.checkQuestionnairesForDuplicates();
    }
  }

  inputChanged(event) {
    this.isInputChanged$.next(event);
  }

  openIframe($event, url) {
    $event.preventDefault();
    this.helperService.openIframe(url);
  }

  openPrivacyModalWindow(url: string) {
    const translatedValue = this.translateService.instant('personalize.privacy-link');
    const title = this.filterPlaceholderPipe.transform(translatedValue);
    this.openModalWindow(url, title, false);
  }

  openModalWindow(url: string, title: string, translateTitle: boolean = true) {
    this.modalWindowOpen = true;
    this.modalUrl = url;
    this.modalTitle = title;
    this.modalTranslateTitle = translateTitle;
  }

  closeModalWindow(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
  }

  closeModalWindowOnRightClick(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
  }
}
