import { combineLatest as observableCombineLatest } from 'rxjs';

import { filter, first } from 'rxjs/operators';
import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { ValidationService } from '../../shared/validation/validation.service';

@Component({
  moduleId: module.id,
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public forgottenPasswordForm: FormGroup;
  public email: AbstractControl;
  public name: AbstractControl;
  public isLinkSuccessfullySent: boolean;
  public alsoName: boolean;
  public emailNotSend = false;
  public isRetrievalInProgress: boolean = false;

  constructor(
    private _location: Location,
    private _store: Store<fromRoot.State>,
    // private _userService: UserService,
    private formBuilder: FormBuilder
  ) {
    this._store.dispatch(new customizationActions.GetOperatorsSettings());
    const group: any = {};

    this._subscriptions.add(
      this._store
        .select(fromRoot.isLastNameForForgottenPasswordRequired)
        .subscribe((required: boolean) => {
          if (required) {
            this.alsoName = true;
            group.name = ['', Validators.required];
          }

          group.email = [
            '',
            [ValidationService.emailValidator(true), Validators.maxLength(50)]
          ];

          this.forgottenPasswordForm = formBuilder.group(group);
          this.email = this.forgottenPasswordForm.controls['email'];
          this.name = this.forgottenPasswordForm.controls['name'];
        })
    );
  }

  ngOnInit() {
    this.isLinkSuccessfullySent = false;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onSubmit(form: any): void {
    this.isLinkSuccessfullySent = false;

    observableCombineLatest(
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getLanguage)
    )
      .pipe(first())
      .subscribe(data => {
        const [eventId, lang] = data;
        const { email, name } = form;
        const lastName = name || '';
        let requestData = {
          email: email.toLowerCase(),
          lastName,
          lang,
          eventId: eventId === null ? -1 : eventId
        };

        this.isRetrievalInProgress = true;

        //be sure the response is false before asking for sending
        this._store.dispatch(
          new userActions.RequestPasswordRecoveryResult(null)
        );

        this.emailNotSend = false;

        this._store.dispatch(
          new userActions.RequestPasswordRecovery(requestData)
        );

        this._store
          .select(fromRoot.getPassRecoverySuccess)
          .pipe(
            filter(success => success !== null),
            first()
          )
          .subscribe(success => {
            if (success) {
              this.isLinkSuccessfullySent = true;
            } else {
              this.emailNotSend = true;
            }

            this.isRetrievalInProgress = false;
          });
      });
  }

  navigateBack() {
    this._location.back();
  }

  navigateHome() {
    this._location.replaceState('/login');
  }
}
