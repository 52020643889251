var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { ActionTypes as HolderActionTypes } from '@store/products/holder/holder.actions';
import { getHolderUuids } from '@store/products/holder/holder.selectors';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { SetIsWorkshopLoadingStatus, SetWorkshopAssignedHoldersStatus, SetWorkshopAvailableSeatsAndAssignedHoldersStatus, SetWorkshopAvailableSeatsStatus } from '@store/products/status/workshop/workshop.actions';
import { getWorkshopsAssignedHoldersGroupedByWorkshopId } from '@store/products/status/workshop/workshop.selectors';
import { ActionTypes as WorkshopActionTypes } from '@store/products/workshop/workshop.actions';
import _ from 'lodash';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
var WorkshopStatusEffect = /** @class */ (function () {
    function WorkshopStatusEffect(actions$, store, bookingService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.bookingService = bookingService;
        this.setInitialWorkshopAvailableSeatsStatus$ = this.actions$.pipe(ofType(WorkshopActionTypes.SET_INITIAL_WORKSHOP_PRODUCT_LIST), switchMap(function (_a) {
            var payload = _a.payload;
            var workshopList = payload;
            if (!workshopList.length) {
                return EMPTY;
            }
            var workshopAvailableSeats = workshopList.reduce(function (workshopAvailableSeats, workshop) {
                workshopAvailableSeats[workshop.workshopId] = workshop.seats;
                return workshopAvailableSeats;
            }, {});
            return of(new SetWorkshopAvailableSeatsStatus(workshopAvailableSeats));
        }));
        this.setWorkshopAvailableSeatsStatus$ = this.actions$.pipe(ofType(BookingActionTypes.SET_WORKSHOP_IN_BOOKING, BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING), switchMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            var workshopAvailableSeats = payload.workshopAvailableSeats;
            return of(new SetWorkshopAvailableSeatsStatus(workshopAvailableSeats));
        }));
        this.setWorkshopAssignedHoldersStatusOnSetWorkshopBooking$ = this.actions$.pipe(ofType(BookingActionTypes.SET_WORKSHOP_IN_BOOKING), withLatestFrom(this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))), switchMap(function (_a) {
            var payload = _a[0].payload, workshopsAssignedHoldersGroupedByWorkshopId = _a[1];
            if (!payload) {
                return EMPTY;
            }
            var setBookingWorkshops = payload.setBookingWorkshops;
            var workshopAssignedHolders = {};
            setBookingWorkshops.forEach(function (setWorkshopBooking) {
                return setWorkshopBooking.workshops.forEach(function (_a) {
                    var id = _a.id, holderUuids = _a.holderUuids;
                    var assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[id];
                    if (workshopAssignedHolders[id]) {
                        workshopAssignedHolders[id] = workshopAssignedHolders[id].concat(holderUuids);
                    }
                    else if (assignedWorkshopHolders) {
                        workshopAssignedHolders[id] = assignedWorkshopHolders.concat(holderUuids);
                    }
                    else {
                        workshopAssignedHolders[id] = holderUuids;
                    }
                });
            });
            return of(new SetWorkshopAssignedHoldersStatus(workshopAssignedHolders));
        }));
        this.setWorkshopAssignedHoldersStatusOnRemoveWorkshopBooking$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING), withLatestFrom(this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))), switchMap(function (_a) {
            var payload = _a[0].payload, workshopsAssignedHoldersGroupedByWorkshopId = _a[1];
            if (!payload) {
                return EMPTY;
            }
            var removeBookingWorkshops = payload.removeBookingWorkshops;
            var workshopAvailableSeats = {};
            removeBookingWorkshops.forEach(function (removeBookingWorkshop) {
                return removeBookingWorkshop.removeWorkshopsAssignedHolders.forEach(function (_a) {
                    var workshopId = _a.workshopId, removeHolderUuids = _a.removeHolderUuids;
                    var assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[workshopId];
                    workshopAvailableSeats[workshopId] = _.difference(assignedWorkshopHolders, removeHolderUuids);
                });
            });
            return of(new SetWorkshopAssignedHoldersStatus(workshopAvailableSeats));
        }), catchError(function (error) { return throwError(error); }));
        this.releaseAssignedWorkshopHolderSeatsOnProductHolderRemoval$ = this.actions$.pipe(ofType(HolderActionTypes.SET_PRODUCT_HOLDER, HolderActionTypes.REMOVE_PRODUCT_HOLDER, HolderActionTypes.REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER), withLatestFrom(this.store.pipe(select(getHolderUuids)), this.store.pipe(select(getWorkshopsAssignedHoldersGroupedByWorkshopId))), concatMap(function (_a) {
            var _b = _a[0], type = _b.type, payload = _b.payload, holderUuids = _a[1], workshopsAssignedHoldersGroupedByWorkshopId = _a[2];
            var setProductHoldersUuids = payload;
            var isFirstProductSet = holderUuids.length === 1 && type === HolderActionTypes.SET_PRODUCT_HOLDER;
            var areProductHoldersRemoved = setProductHoldersUuids.length === holderUuids.length && !isFirstProductSet;
            if (!areProductHoldersRemoved) {
                return EMPTY;
            }
            var workshopAssignedHolders = {};
            var releaseWorkshopAssignedHolders = {};
            var assignedHoldersWorkshopsIds = _.keys(workshopsAssignedHoldersGroupedByWorkshopId);
            assignedHoldersWorkshopsIds.forEach(function (workshopId) {
                var assignedWorkshopHolders = workshopsAssignedHoldersGroupedByWorkshopId[workshopId];
                workshopAssignedHolders[workshopId] = _.intersection(assignedWorkshopHolders, holderUuids);
                releaseWorkshopAssignedHolders[workshopId] = _.difference(assignedWorkshopHolders, workshopAssignedHolders[workshopId]);
            });
            return _this.bookingService
                .postReleaseAssignedWorkshopHolderSeats$(workshopAssignedHolders, releaseWorkshopAssignedHolders)
                .pipe(map(function (_a) {
                var workshopAssignedHolders = _a.workshopAssignedHolders, workshopAvailableSeats = _a.workshopAvailableSeats;
                return new SetWorkshopAvailableSeatsAndAssignedHoldersStatus({
                    availableSeats: workshopAvailableSeats,
                    assignedHolders: workshopAssignedHolders
                });
            }), catchError(function () { return of(new SetWorkshopAvailableSeatsAndAssignedHoldersStatus(null)); }));
        }));
        this.isWorkshopLoadingStatus$ = this.actions$.pipe(ofType(ProductActionTypes.SET_BOOKING_WORKSHOP, ProductActionTypes.REMOVE_BOOKING_WORKSHOP, BookingActionTypes.SET_WORKSHOP_IN_BOOKING, BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING), map(function (action) {
            var productActionTypes = [
                ProductActionTypes.SET_BOOKING_WORKSHOP,
                ProductActionTypes.REMOVE_BOOKING_WORKSHOP
            ];
            var isWorkshopLoading = productActionTypes.includes(action.type);
            return new SetIsWorkshopLoadingStatus(isWorkshopLoading);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "setInitialWorkshopAvailableSeatsStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "setWorkshopAvailableSeatsStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "setWorkshopAssignedHoldersStatusOnSetWorkshopBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "setWorkshopAssignedHoldersStatusOnRemoveWorkshopBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "releaseAssignedWorkshopHolderSeatsOnProductHolderRemoval$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WorkshopStatusEffect.prototype, "isWorkshopLoadingStatus$", void 0);
    return WorkshopStatusEffect;
}());
export { WorkshopStatusEffect };
