import { ArticleStatusState } from '@products/models/article.model';
import { BookingState, BookingTariff } from '@products/models/booking.model';
import { HolderState } from '@products/models/holder.model';
import { PackageStatusState } from '@products/models/package-status.model';
import { SelectionState } from '@products/models/product-selection.model';
import { TariffStatusState } from '@products/models/tariff-status.model';
import {
  Tariff,
  TariffClassification,
  TariffPersonalizationType,
  TicketSendingOptions
} from '@products/models/tariff.model';
import { WorkshopStatusState } from '@products/models/workshop-status.model';
import { WorkshopProductList, WorkshopState, WorkshopTariffHolder } from '@products/models/workshop.model';

export enum ProductType {
  Tariff = 'tariff',
  Package = 'package',
  Article = 'article'
}

export interface ProductStatusState {
  tariff: TariffStatusState;
  package: PackageStatusState;
  article: ArticleStatusState;
  workshop: WorkshopStatusState;
}

export interface ProductsState {
  selection: SelectionState;
  booking: BookingState;
  workshop: WorkshopState;
  holder: HolderState;
  status: ProductStatusState;
}

/**************************
 * PRODUCTS GROUP RESPONSE
 **************************/
export type GetProductsResponse = ProductGroupResponse[];

export interface ProductGroupResponse {
  groupId?: number;
  groupName?: string;
  groupDescription?: string;
  items: ProductResponse[];
  expanded: boolean;
}

export type ProductResponse = {
  ticketType?: TariffTypeResponse;
  article?: ArticleResponse;
  package?: PackageResponse;
  itemType: ProductType;
};

export interface TariffTypeResponse {
  ticketTypeId: number;
  ticketTypeName: string;
  preferredTicketType?: string;
  tickets: TariffResponse[];
}

/******************
 * TICKET RESPONSE
 ******************/
export interface TariffResponse {
  ticketPersonId: number;
  info: string;
  infoExpanded: string;
  isVoucher: boolean;
  isVisible: boolean;
  personTypeId: number;
  ticketName: string;
  tariffNr: number;
  price: number;
  requiresLegitimation: boolean;
  allowedWorkshops: number[];
  workshopsByDay: WorkshopsByDayResponse[];
  allowedWorkshopsFull: boolean;
  availableTickets: number;
  ticketLimit?: number;
  hasDaySellLimit: boolean;
  durationInDays: number;
  classification: TariffClassification;
  sendingOptions: TicketSendingOptions[];
  personalizationType: TariffPersonalizationType;
  validFrom?: string;
  validTill?: string;
  tageOffset: number;
  packageSettings?: TariffPackageSettingsResponse;
}

export interface WorkshopsByDayResponse {
  day: string;
  workshops: WorkshopResponse[];
}

export interface WorkshopResponse {
  id: number;
  capacity: number;
  description: string;
  end: string;
  image: string;
  language: string;
  name: string;
  pdf: string;
  price: number;
  start: string;
  expanded: boolean;
}

export interface TariffPackageSettingsResponse {
  itemFromPackage: boolean;
  useTariffSheetPrice: boolean;
  fixedAmount: boolean;
  amount: number;
  minAmount?: number;
  maxAmount?: number;
  packageItemPrice?: number;
}

/*****************************
 * WORKSHOP PRODUCTS RESPONSE
 *****************************/
export type WorkshopProductListResponse = WorkshopProductList;

/*******************
 * ARTICLE RESPONSE
 *******************/
export interface ArticleResponse {
  articleNumber: number;
  name: string;
  tariff: number;
  provision: number;
  provisionPercent: number;
  vatPercent: number;
  variablePrice: boolean;
  tariffSheetNumber: number;
  tariffSheetValidNumber: number;
  info: string;
  packageSettings: ArticlePackageSettingsResponse;
}

export interface ArticlePackageSettingsResponse {}

/*******************
 * PACKAGE RESPONSE
 *******************/
export interface PackageResponse {
  packageNumber: number;
  name: string;
  tariff?: string;
  variablePrice: boolean;
  packageTariffSheetNumber: number;
  packageTariffSheetValidNumber: number;
  info: string;
  items: ProductResponse[];
}

/******************
 * ACTION PAYLOADS
 ******************/
export interface GetProducts {
  eventId: number;
  preferredTicketGroupNr?: number;
  preferredTicketPersonNr?: number;
}

export interface SetPreferredTariffBooking {
  ticketTypeId: number;
  ticketPersonTypeId: number;
  count: number;
}

export interface RemoveTariffBooking {
  ticketTypeId: number;
  ticketPersonId: number;
}

export interface RemovePackageTariffBooking extends RemoveTariffBooking {
  packageNumber: number;
  packageIndex: number;
}

export interface RemovePackageBooking {
  packageNumber: number;
  packageIndexes: number[];
}

export const getProductType = (tariff: Tariff | BookingTariff | WorkshopTariffHolder): ProductType => {
  if (tariff.packageNumber) {
    return ProductType.Package;
  }

  return ProductType.Tariff;
};
