<div>
  <div class="scroll-button scroll-up-button" (click)="scrollUp()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M15 12l-5-5-5 5z" />
    </svg>
  </div>

  <div class="scroll-button scroll-down-button" (click)="scrollDown()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M5 8l5 5 5-5z" />
    </svg>
  </div>
</div>

<div class="page-padding-wrapper workshop-section" appAdjustSideHeadline>
  <div class="section-headline">
    <h2 data-translation="workshop.title" [appRichText]="'workshop.title' | translate | hideTranslation"></h2>
    <div class="section-headline-side" data-translation="workshop.headline">
      {{ 'workshop.headline' | translate | hideTranslation }}
    </div>
  </div>

  <div class="page-padding-wrapper" *ngIf="!workshopsLoaded">
    <app-loader></app-loader>
  </div>

  <div class="section-body" *ngIf="workshopsLoaded">
    <span
      data-translation="workshop.infoText"
      data-translation-includes-children
      [appRichText]="'workshop.infoText' | translate | hideTranslation"
    >
    </span>
    <app-step-navigation-buttons></app-step-navigation-buttons>

    <!-- workshop detail for wide screens -->
    <div
      *ngIf="toggleDetailWindow && (!isMobile || settings.workshopViewType === 'list')"
      class="workshop-detail-window"
      [style.top]="toggledWorkshop.top + 'px'"
    >
      <div class="workshop-details">
        <span class="workshop-name">
          {{ toggledWorkshop.workshopName | translate | hideTranslation }}
        </span>

        <span class="price" *ngIf="toggledWorkshop.price > 0 && !settings.hideWorkshopPrice">
          {{ toggledWorkshop.price | formatPrice: settings.currencySettings }}
        </span>

        <div class="workshop-time-wrap">
          <span *ngIf="!hideDateAndTime" class="workshop-time">
            {{ toggledWorkshop.start | localizedDate: 'shortTime' }}
            - {{ toggledWorkshop.end | localizedDate: 'shortTime' }}
          </span>
          <span class="workshop-toggle" (click)="toggleDetail()">
            <button class="button desktop-close-button">
              <span data-translation="workshop.close">{{ 'workshop.close' | translate | hideTranslation }}</span>
            </button>
          </span>
        </div>
      </div>
      <div class="row m-auto">
        <div class="col-4" *ngIf="toggledWorkshop.image">
          <img
            class="img-responsive schedule-image"
            (click)="toggleDetail(toggledWorkshop.workshopId)"
            [src]="origin + '/webapi' + toggledWorkshop.image"
          />
        </div>
        <div class="col">
          <div class="schedule-text">
            <div class="schedule-text-inner">
              <span *ngIf="toggledWorkshop.description" [innerHTML]="toggledWorkshop.description | translate"></span>
            </div>
            <a
              target="_blank"
              *ngIf="toggledWorkshop.pdf"
              href="{{ origin }}/webapi{{ toggledWorkshop.pdf }}"
              class="pdf-download"
              data-translation="toggledWorkshop.pdf"
            >
              {{ 'workshop.pdf' | translate | hideTranslation }}
            </a>
          </div>
        </div>
      </div>

      <ng-container *ngIf="workshopStatusViewModel$ | async as workshopStatusViewModel">
        <ng-container *ngIf="workshopDetailViewModel$ | async as workshopDetailViewModel">
          <app-workshop-visitors-count
            [ngClass]="{
              'border-bottom': !(
                workshopDetailViewModel.workshopTariffHolders &&
                workshopStatusViewModel.availableSeats[toggledWorkshop.workshopId]
              )
            }"
            [workshop]="workshopDetailViewModel.workshopProduct"
            [availableSeats]="workshopStatusViewModel.availableSeats[toggledWorkshop.workshopId]"
            [showAmountOfAvailableWorkshops]="showAmountOfAvailableWorkshops"
            (click)="toggleDetail(toggledWorkshop.workshopId)"
          >
          </app-workshop-visitors-count>
          <app-workshops-visitors
            *ngIf="workshopDetailViewModel.workshopTariffHolders && !workshopDetailViewModel.workshopProduct.disabled"
            [ngClass]="{
              'border-bottom': !(
                workshopDetailViewModel.workshopTariffHolders &&
                workshopStatusViewModel.availableSeats[toggledWorkshop.workshopId]
              )
            }"
            [workshop]="workshopDetailViewModel.workshopProduct"
            [workshopsList]="workshopDetailViewModel.workshopProductList"
            [workshopTariffHolders]="workshopDetailViewModel.workshopTariffHolders"
            [areAllWorkshopTariffHoldersAssigned]="workshopDetailViewModel.areAllWorkshopTariffHoldersAssigned"
            [availableSeats]="workshopStatusViewModel.availableSeats[toggledWorkshop.workshopId]"
            [isWorkshopLoading]="workshopStatusViewModel.isWorkshopLoading"
            [limitWorkshopPerTicket]="limitWorkshopPerTicket"
            [enableWorkshopSelectionOverlapping]="enableWorkshopSelectionOverlapping"
          >
          </app-workshops-visitors>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="settings.workshopViewType === 'list'">
      <div class="table-responsive workshop-table" *ngFor="let workshopByDay of workshopsByDatesArray; let i = index">
        <table class="table" style="width: 97%;">
          <thead class="workshop-table-header" (click)="toggleWorkshopAccordion($event, workshopByDay)">
            <tr>
              <th class="column-big workshop-day-header" [class.workshop-day-header-expanded]="workshopByDay.expanded">
                {{ workshopByDay.date | localizedDate: 'longDate' }}
              </th>
              <ng-container *ngIf="workshopByDay.expanded">
                <th data-translation="workshop-start" class="column-small column-centered">
                  {{ 'workshop-start' | translate }}
                </th>
                <th data-translation="workshop-end" class="column-small column-centered">
                  {{ 'workshop-end' | translate }}
                </th>
                <th
                  data-translation="workshop-price"
                  class="column-small column-centered"
                  *ngIf="!settings.hideWorkshopPrice"
                >
                  {{ 'workshop-price' | translate }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody *ngIf="workshopByDay.expanded">
            <tr
              class="workshop-row"
              (click)="toggleDetail(workshop.workshopId)"
              *ngFor="let workshop of workshopByDay.todayWorkshops"
            >
              <td>
                <img
                  *ngIf="!isMobile && workshop.image"
                  class="img-responsive work-shop-image"
                  [src]="origin + '/webapi' + workshop.image"
                />
                <div *ngIf="!workshop.image" [class.image-placeholder]="!isMobile"></div>
                <p>
                  {{ workshop.workshopName | translate }}
                </p>
              </td>
              <td class="column-centered">
                {{ convertFromTime(workshop.start) | localizedDate: 'HH:mm':null:'GMT' }}
              </td>
              <td class="column-centered">
                {{ convertFromTime(workshop.end) | localizedDate: 'HH:mm':null:'GMT' }}
              </td>
              <td class="column-centered" *ngIf="!settings.hideWorkshopPrice">
                <ng-container *ngIf="isMobile">{{
                  workshop.price | formatPrice: settings.currencySettings:false
                }}</ng-container>
                <ng-container *ngIf="!isMobile">{{
                  workshop.price | formatPrice: settings.currencySettings
                }}</ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-container *ngIf="settings.workshopViewType === 'calendar'">
      <div class="workshop-day" *ngFor="let day of workshopsByDatesArray; let i = index">
        <h3 *ngIf="!hideDateAndTime && !hideWorkshopDate" class="day-title black" id="day{{ i }}">
          <button class="continue-button button" (click)="navigateToDay(i - 1)" *ngIf="i > 0">
            <span class="continue-button-text">&lt;</span>
          </button>

          {{ day.date | localizedDate: 'fullDate' }}

          <button
            class="continue-button button"
            (click)="navigateToDay(i + 1)"
            *ngIf="workshopsByDatesArray.length > i + 1"
          >
            <span class="continue-button-text">&gt;</span>
          </button>
        </h3>

        <div id="fakeScrollbarWrapper{{ i }}">
          <div
            #fakeScrollbar
            class="fake-scrollbar"
            id="fakeScrollbar{{ i }}"
            (scroll)="scrollWorkshopRoomWithFakeScrollbar(fakeScrollbar, room)"
          >
            <div class="fake-scrollbar-content"></div>
          </div>
        </div>

        <div class="relative-box">
          <div class="workshop-wrapper" id="workshopWrapper{{ i }}">
            <div class="time-column"></div>

            <!-- TODO: ROOMS -->

            <div #room class="rooms" id="rooms{{ i }}" (scroll)="scrollFakeScrollBar(fakeScrollbar, room)">
              <div *ngFor="let workshopRoom of day.rooms; let i = index" class="room" [style.width]="roomWidth + 'px'">
                <div class="header">
                  {{ workshopRoom.roomName | translate }}
                </div>
                <span *ngFor="let workshop of workshopRoom.workshops">
                  <div class="schedule" [ngStyle]="workshop.styles">
                    <div class="schedule-inner-wrapper">
                      <div class="workshop-details border-top border-left border-right">
                        <span class="workshop-name">
                          {{ workshop.workshopName | translate | hideTranslation }}
                        </span>

                        <span class="price" *ngIf="workshop.price > 0 && !settings.hideWorkshopPrice">
                          {{ workshop.price | formatPrice: settings.currencySettings }}
                        </span>

                        <div class="workshop-time-wrap">
                          <span *ngIf="!hideDateAndTime" class="workshop-time">
                            {{ workshop.start | localizedDate: 'shortTime' }}
                            -
                            {{ workshop.end | localizedDate: 'shortTime' }}
                            <div class="date-mobile" *ngIf="!hideWorkshopDate">
                              {{ day.date | localizedDate: 'fullDate' }}
                            </div>
                          </span>
                          <span class="workshop-toggle" (click)="toggleDetail(workshop.workshopId)">
                            <span
                              *ngIf="workshop.workshopId === activeWorkshopId"
                              data-translation="workshop.close"
                              class="mobile-close-button"
                              >{{ 'workshop.close' | translate | hideTranslation }}</span
                            >
                            <span *ngIf="workshop.workshopId !== activeWorkshopId" data-translation="workshop.detail">{{
                              'workshop.detail' | translate | hideTranslation
                            }}</span>
                          </span>
                        </div>
                      </div>

                      <div class="schedule-data border-left border-right">
                        <div
                          *ngIf="workshop.image"
                          class="schedule-image"
                          (click)="toggleDetail(workshop.workshopId)"
                          [style.background-image]="'url(' + origin + '/webapi' + workshop.image + ')'"
                        ></div>
                        <div
                          *ngIf="!workshop.image"
                          class="schedule-image"
                          (click)="toggleDetail(workshop.workshopId)"
                        ></div>
                      </div>

                      <ng-container *ngIf="workshopStatusViewModel$ | async as workshopStatusViewModel">
                        <ng-container *ngIf="workshopDetailViewModel$ | async as workshopDetailViewModel">
                          <app-workshop-visitors-count
                            [ngClass]="{
                              'border-bottom': !(
                                workshop.workshopId === activeWorkshopId &&
                                workshopDetailViewModel.workshopTariffHolders &&
                                workshopStatusViewModel.availableSeats[workshop.workshopId]
                              )
                            }"
                            [workshop]="workshopDetailViewModel.workshopProduct"
                            [availableSeats]="workshopStatusViewModel.availableSeats[workshop.workshopId]"
                            [showAmountOfAvailableWorkshops]="showAmountOfAvailableWorkshops"
                            (click)="toggleDetail(workshop.workshopId)"
                          >
                          </app-workshop-visitors-count>
                          <app-workshops-visitors
                            *ngIf="
                              isMobile &&
                              workshop.workshopId === activeWorkshopId &&
                              workshopDetailViewModel.workshopTariffHolders &&
                              !workshopDetailViewModel.workshopProduct.disabled
                            "
                            [ngClass]="{
                              'border-bottom':
                                isMobile &&
                                workshop.workshopId === activeWorkshopId &&
                                workshopDetailViewModel.workshopTariffHolders &&
                                workshopStatusViewModel.availableSeats[workshop.workshopId]
                            }"
                            [workshop]="workshopDetailViewModel.workshopProduct"
                            [workshopsList]="workshopDetailViewModel.workshopProductList"
                            [workshopTariffHolders]="workshopDetailViewModel.workshopTariffHolders"
                            [areAllWorkshopTariffHoldersAssigned]="
                              workshopDetailViewModel.areAllWorkshopTariffHoldersAssigned
                            "
                            [availableSeats]="workshopStatusViewModel.availableSeats[workshop.workshopId]"
                            [isWorkshopLoading]="workshopStatusViewModel.isWorkshopLoading"
                            [limitWorkshopPerTicket]="limitWorkshopPerTicket"
                            [enableWorkshopSelectionOverlapping]="enableWorkshopSelectionOverlapping"
                          >
                          </app-workshops-visitors>
                        </ng-container>
                      </ng-container>

                      <div
                        style="padding: 5px;"
                        *ngIf="
                          workshop.workshopId === activeWorkshopId &&
                            isMobile &&
                            workshopDetailViewModel.workshopTariffHolders | async
                        "
                      >
                        <span *ngIf="workshop.description" [innerHTML]="workshop.description | translate"></span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <!-- TODO: HOURS -->

          <div *ngIf="viewport >= mediumBreakpoint" [ngStyle]="{ height: day.timeLineWrapHeight + 'px' }" class="hours">
            <div
              class="time-line"
              *ngFor="let timeLineItem of day.timeLineList"
              [ngStyle]="{ height: timeLineItem.hourHeight + 'px' }"
            >
              <div *ngIf="!hideDateAndTime" class="hour black">
                <span>{{ timeLineItem.hourStart }}:00 </span>
                <span *ngIf="timeLineItem.displayEndTime">{{ timeLineItem.hourEnd }}:00</span>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <app-step-navigation-buttons></app-step-navigation-buttons>
</div>
