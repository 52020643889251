<app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
<ng-container *ngIf="productSelectionViewModel.isTranslationLoaded">
  <h3 data-translation="confirmation.workshops" *ngIf="!!workshopsByDay.length">
    {{ 'confirmation.workshops' | translate | hideTranslation }}:
  </h3>
  <div class="table-responsive workshop-table-container" *ngFor="let workshopByDay of workshopsByDay">
    <table class="workshop-table">
      <thead class="workshop-table-header" (click)="toggleIsWorkshopByDayExpanded($event, workshopByDay)">
        <tr>
          <th class="column-big workshop-day" [ngClass]="{ 'workshop-day-expanded': workshopByDay.expanded }">
            {{ convertWorkshopFromDate(workshopByDay.day) | localizedDate: 'mediumDate':null:'GMT' }}
          </th>
          <ng-container *ngIf="workshopByDay.expanded">
            <th class="column-small column-centered" data-translation="workshop-start">
              {{ 'workshop-start' | translate | hideTranslation }}
            </th>
            <th class="column-small column-centered" data-translation="workshop-end">
              {{ 'workshop-end' | translate | hideTranslation }}
            </th>
            <th
              class="column-small column-centered"
              data-translation="workshop-capacity"
              *ngIf="!productSelectionViewModel.isMobile"
            >
              {{ 'workshop-capacity' | translate | hideTranslation }}
            </th>
            <th
              class="column-small column-centered"
              data-translation="workshop-language"
              *ngIf="!productSelectionViewModel.isMobile"
            >
              {{ 'workshop-language' | translate | hideTranslation }}
            </th>
            <th
              class="column-small column-centered"
              data-translation="workshop-price"
              *ngIf="!exhibitionSettings.hideWorkshopPrice"
            >
              {{ 'workshop-price' | translate | hideTranslation }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="workshopByDay.expanded">
        <tr
          class="workshop-row"
          *ngFor="let workshop of workshopByDay.workshops"
          (click)="toggleWorkshopDetailModal($event, workshop.id)"
        >
          <td>
            <img
              class="img-responsive workshop-image"
              *ngIf="!productSelectionViewModel.isMobile && workshop.image"
              [src]="origin + '/webapi' + workshop.image"
            />
            <div *ngIf="!workshop.image" [ngClass]="{ 'image-placeholder': !productSelectionViewModel.isMobile }"></div>
            <svg class="workshops-link" *ngIf="productSelectionViewModel.isMobile">
              <use xlink:href="assets/defs.svg#icon-zoom-in"></use>
            </svg>
            <p>{{ workshop.name | translate | hideTranslation }}</p>
          </td>
          <td class="column-centered">{{ workshop.start }}</td>
          <td class="column-centered">{{ workshop.end }}</td>
          <td class="column-centered" *ngIf="!productSelectionViewModel.isMobile">
            {{ workshop.capacity > 99 ? '99+' : workshop.capacity }}
          </td>
          <td class="column-centered" *ngIf="!productSelectionViewModel.isMobile">
            {{ workshop.language }}
          </td>
          <td class="column-centered" *ngIf="!exhibitionSettings.hideWorkshopPrice">
            <ng-container *ngIf="productSelectionViewModel.isMobile">{{
              workshop.price | formatPrice: exhibitionSettings.currencySettings:false
            }}</ng-container>
            <ng-container *ngIf="!productSelectionViewModel.isMobile">{{
              workshop.price | formatPrice: exhibitionSettings.currencySettings
            }}</ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<!-- workshop detail for wide screens -->
<div class="workshop-detail-window" *ngIf="workshopDetail" [ngStyle]="{ top: workshopModalWindowYOffset + 'px' }">
  <div class="workshop-details">
    <span class="workshop-name">
      {{ workshopDetail.name | translate | hideTranslation }}
    </span>
    <span class="price" *ngIf="!!workshopDetail.price && !exhibitionSettings.hideWorkshopPrice">
      {{ workshopDetail.price | formatPrice: exhibitionSettings.currencySettings }}
    </span>

    <div class="workshop-time-wrap">
      <span class="workshop-time">
        {{ workshopDetail.start | localizedDate: 'shortTime' }}
        - {{ workshopDetail.end | localizedDate: 'shortTime' }}
      </span>
      <span class="workshop-toggle" (click)="toggleWorkshopDetailModal($event)">
        <button class="button desktop-close-button">
          <span data-translation="workshop.close">
            {{ 'workshop.close' | translate | hideTranslation }}
          </span>
        </button>
      </span>
    </div>
  </div>
  <div class="row m-auto">
    <div class="col-4" *ngIf="workshopDetail.imagePath">
      <img
        class="img-responsive schedule-image"
        (click)="toggleWorkshopDetailModal($event, workshopDetail.id)"
        [src]="origin + '/webapi' + workshopDetail.imagePath"
      />
    </div>
    <div class="col">
      <div class="schedule-text">
        <div class="schedule-text-inner">
          <span
            class="word-breakage"
            *ngIf="workshopDetail.description"
            [innerHTML]="workshopDetail.description | translate | hideTranslation"
          ></span>
        </div>
        <a
          class="pdf-download"
          href="{{ origin }}/webapi{{ workshopDetail.pdfPath }}"
          target="_blank"
          data-translation="workshop.pdf"
          *ngIf="workshopDetail.pdfPath"
        >
          {{ 'workshop.pdf' | translate | hideTranslation }}
        </a>
      </div>
    </div>
  </div>
  <ng-container *ngIf="workshopDetailViewModel$ | async as workshopDetailViewModel">
    <app-workshop-visitors-count
      [ngClass]="{
        'border-bottom': !(
          workshopDetailViewModel.workshopTariffHolders && workshopStatusViewModel.availableSeats[workshopDetail.id]
        )
      }"
      (click)="toggleWorkshopDetailModal($event, workshopDetail.id)"
      [workshop]="workshopDetailViewModel.workshopProduct"
      [availableSeats]="workshopStatusViewModel.availableSeats[workshopDetail.id]"
      [showAmountOfAvailableWorkshops]="exhibitionSettings.showAmountOfAvailableWorkshops"
    >
    </app-workshop-visitors-count>
    <app-workshops-visitors
      *ngIf="workshopDetailViewModel.workshopTariffHolders && !workshopDetailViewModel.workshopProduct.disabled"
      [ngClass]="{
        'border-bottom': !(
          workshopDetailViewModel.workshopTariffHolders && workshopStatusViewModel.availableSeats[workshopDetail.id]
        )
      }"
      [workshop]="workshopDetailViewModel.workshopProduct"
      [workshopsList]="workshopDetailViewModel.workshopProductList"
      [workshopTariffHolders]="workshopDetailViewModel.workshopTariffHolders"
      [areAllWorkshopTariffHoldersAssigned]="workshopDetailViewModel.areAllWorkshopTariffHoldersAssigned"
      [availableSeats]="workshopStatusViewModel.availableSeats[workshopDetail.id]"
      [isWorkshopLoading]="workshopStatusViewModel.isWorkshopLoading"
      [limitWorkshopPerTicket]="exhibitionSettings.workshopsPerTicket"
      [enableWorkshopSelectionOverlapping]="exhibitionSettings.enableWorkshopSelectionOverlapping"
    >
    </app-workshops-visitors>
  </ng-container>
</div>
