var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy } from '@angular/core';
import { PackageWarningMessage } from '@products/models/package.model';
import { isProductTypeTariff } from '@products/models/product-selection.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var PackageValidationService = /** @class */ (function () {
    function PackageValidationService() {
        var _this = this;
        this.destroy$ = new Subject();
        this._packageValidationState$ = new BehaviorSubject({
            count: 0,
            package: null,
            validatedCount: 0,
            previousValidatedCount: 0,
            isCountOverLimit: false,
            isNextCountOverLimit: false,
            packageMinimalTariffCount: null,
            numberOfAllBookedTariffs: null,
            bookedTariffsTotalCountSortedByTicketPersonId: null,
            availableTariffsSortedByTicketPersonId: null,
            maxTariffLimit: null,
            tariffLimitPerUserAccount: null,
            currentUserAccountTariffLimit: null,
            packageLimitWarningMessage: ''
        });
        this.packageValidationState$ = this._packageValidationState$.asObservable().distinctUntilChanged();
        this.packageValidationState$.pipe(takeUntil(this.destroy$)).subscribe(function (packageValidationState) {
            _this.packageValidationServiceState = __assign({}, _this.packageValidationServiceState, packageValidationState);
        });
    }
    PackageValidationService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    PackageValidationService.prototype.initState = function (packageValidationState) {
        this.calculatePackageMinimalTariffCount(packageValidationState);
        this._packageValidationState$.next(packageValidationState);
    };
    PackageValidationService.prototype.updateState = function (packageValidationState) {
        this._packageValidationState$.next(__assign({}, this.packageValidationServiceState, packageValidationState));
    };
    PackageValidationService.prototype.validateState = function (count, packageValidationState) {
        packageValidationState.count = count;
        this.validate(packageValidationState);
        this._packageValidationState$.next(__assign({}, this.packageValidationServiceState, packageValidationState));
    };
    PackageValidationService.prototype.getValidatedState = function () {
        return this.packageValidationServiceState;
    };
    PackageValidationService.prototype.geValidatedStateProperty = function (propertyName) {
        return propertyName in this.packageValidationServiceState ? this.packageValidationServiceState[propertyName] : null;
    };
    PackageValidationService.prototype.validate = function (packageValidationState) {
        var count = packageValidationState.count, validatedCount = packageValidationState.validatedCount;
        var isPackageRemoved = count < validatedCount;
        if (isPackageRemoved) {
            this.resetValidatedState(packageValidationState);
        }
        else {
            this.validateAvailableTariffsCount(packageValidationState);
            this.validateTariffLimitPerUserAccount(packageValidationState);
            this.validateMaxTariffLimit(packageValidationState);
        }
        this.setValidateCounterState(packageValidationState);
    };
    PackageValidationService.prototype.setValidateCounterState = function (packageValidationState) {
        var count = packageValidationState.count, validatedCount = packageValidationState.validatedCount, previousValidatedCount = packageValidationState.previousValidatedCount, isCountOverLimit = packageValidationState.isCountOverLimit, isNextCountOverLimit = packageValidationState.isNextCountOverLimit;
        if (isCountOverLimit || isNextCountOverLimit) {
            packageValidationState.validatedCount = isCountOverLimit && isNextCountOverLimit ? validatedCount : count;
            packageValidationState.previousValidatedCount = isNextCountOverLimit ? validatedCount : previousValidatedCount;
            packageValidationState.packageLimitWarningMessage = PackageWarningMessage.ERROR_PACKAGE_LIMIT_WARNING;
        }
        else {
            packageValidationState.validatedCount = count;
            packageValidationState.previousValidatedCount = validatedCount;
            packageValidationState.packageLimitWarningMessage = '';
        }
    };
    PackageValidationService.prototype.resetValidatedState = function (packageValidationState) {
        packageValidationState.isCountOverLimit = false;
        packageValidationState.isNextCountOverLimit = false;
    };
    PackageValidationService.prototype.revalidateDisabledCounter = function (packageValidationState) {
        var validatedCount = packageValidationState.validatedCount, packageLimitWarningMessage = packageValidationState.packageLimitWarningMessage;
        if (packageLimitWarningMessage) {
            this._packageValidationState$.next(__assign({}, this.packageValidationServiceState, packageValidationState, { previousValidatedCount: validatedCount, isCountOverLimit: false, isNextCountOverLimit: false, packageLimitWarningMessage: '' }));
        }
    };
    // TODO: What should happen when there is a fixed number of tariffs in package, the tariff has workshops that are sold out, and workshop selection is required? Currently there is no logic for this kind of validation.
    PackageValidationService.prototype.validateAvailableTariffsCount = function (packageValidationState) {
        var availableTariffsSortedByTicketPersonId = packageValidationState.availableTariffsSortedByTicketPersonId;
        packageValidationState.package.products.forEach(function (packageProductType) {
            if (isProductTypeTariff(packageProductType)) {
                packageProductType.tariffType.tariffs.forEach(function (packageTariff) {
                    var ticketPersonId = packageTariff.ticketPersonId, _a = packageTariff.packageSettings, fixedAmount = _a.fixedAmount, minAmount = _a.minAmount, amount = _a.amount;
                    var validatedAmount = fixedAmount ? amount : minAmount;
                    if (validatedAmount === 0 || packageValidationState.isCountOverLimit) {
                        return;
                    }
                    var availableTariffsCount = availableTariffsSortedByTicketPersonId[ticketPersonId];
                    var isPackageTariffSoldOut = availableTariffsCount !== null && availableTariffsCount === 0;
                    packageValidationState.isCountOverLimit = isPackageTariffSoldOut || validatedAmount > availableTariffsCount;
                    packageValidationState.isNextCountOverLimit =
                        isPackageTariffSoldOut || validatedAmount * 2 > availableTariffsCount;
                });
            }
        });
    };
    PackageValidationService.prototype.validateTariffLimitPerUserAccount = function (packageValidationState) {
        var isCountOverLimit = packageValidationState.isCountOverLimit, isNextCountOverLimit = packageValidationState.isNextCountOverLimit, currentUserAccountTariffLimit = packageValidationState.currentUserAccountTariffLimit, numberOfAllBookedTariffs = packageValidationState.numberOfAllBookedTariffs, packageMinimalTariffCount = packageValidationState.packageMinimalTariffCount, tariffLimitPerUserAccount = packageValidationState.tariffLimitPerUserAccount;
        if (!isCountOverLimit && !isNextCountOverLimit) {
            var isUserAccountLimitActive = !!tariffLimitPerUserAccount;
            if (isUserAccountLimitActive) {
                packageValidationState.isCountOverLimit =
                    numberOfAllBookedTariffs + packageMinimalTariffCount > currentUserAccountTariffLimit;
                packageValidationState.isNextCountOverLimit =
                    numberOfAllBookedTariffs + packageMinimalTariffCount * 2 > currentUserAccountTariffLimit;
            }
        }
    };
    PackageValidationService.prototype.validateMaxTariffLimit = function (packageValidationState) {
        var isCountOverLimit = packageValidationState.isCountOverLimit, isNextCountOverLimit = packageValidationState.isNextCountOverLimit, maxTariffLimit = packageValidationState.maxTariffLimit, numberOfAllBookedTariffs = packageValidationState.numberOfAllBookedTariffs, packageMinimalTariffCount = packageValidationState.packageMinimalTariffCount;
        if (!isCountOverLimit && !isNextCountOverLimit) {
            var checkMaxTariffLimit = maxTariffLimit !== null;
            if (checkMaxTariffLimit) {
                packageValidationState.isCountOverLimit = numberOfAllBookedTariffs + packageMinimalTariffCount > maxTariffLimit;
                packageValidationState.isNextCountOverLimit =
                    numberOfAllBookedTariffs + packageMinimalTariffCount * 2 > maxTariffLimit;
            }
        }
    };
    PackageValidationService.prototype.calculatePackageMinimalTariffCount = function (packageValidationState) {
        packageValidationState.package.products.forEach(function (packageProductType) {
            if (isProductTypeTariff(packageProductType)) {
                packageProductType.tariffType.tariffs.forEach(function (packageTariff) {
                    var _a = packageTariff.packageSettings, minAmount = _a.minAmount, amount = _a.amount;
                    packageValidationState.packageMinimalTariffCount += minAmount != null ? minAmount : amount;
                });
            }
        });
    };
    return PackageValidationService;
}());
export { PackageValidationService };
