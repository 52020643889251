var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ProductType } from '@products/models/products.model';
import { PackageValidationService } from '@products/services/package-validation.service';
import { PackageService } from '@products/services/package.service';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import cloneDeep from 'lodash.clonedeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var PackageTypeComponent = /** @class */ (function () {
    function PackageTypeComponent(actions$, packageService, packageValidationService) {
        this.actions$ = actions$;
        this.packageService = packageService;
        this.packageValidationService = packageValidationService;
        this.ProductType = ProductType;
        this.destroy$ = new Subject();
    }
    PackageTypeComponent.prototype.ngOnInit = function () {
        this.initPackageValidationServiceState();
        this.initPackageValidationServiceSubscription();
        this.initFailedBookingValidationStateRevalidationSubscription();
    };
    PackageTypeComponent.prototype.ngOnChanges = function (changes) {
        this.updatePackageValidationState();
        this.revalidateCounterOnBookingCountDecreaseStateChange(changes);
    };
    PackageTypeComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    PackageTypeComponent.prototype.initPackageValidationServiceState = function () {
        var validatePackage = cloneDeep(this.packageType.packages[0]);
        var _a = this.exhibitionSettings, limitBoughtTickets = _a.limitBoughtTickets, ticketLimitPerUserAccount = _a.ticketLimitPerUserAccount;
        var _b = this.tariffStatusViewModel, currentUserAccountTicketLimit = _b.currentUserAccountTicketLimit, availableTariffs = _b.availableTariffs;
        var bookedProductsCount = this.bookingStatusViewModel.bookedProductsCount;
        this.packageValidationService.initState({
            count: this.packageType.numberOfAddedPackages,
            package: validatePackage,
            validatedCount: this.packageType.numberOfAddedPackages,
            previousValidatedCount: this.packageType.numberOfAddedPackages,
            isCountOverLimit: false,
            isNextCountOverLimit: false,
            packageMinimalTariffCount: 0,
            numberOfAllBookedTariffs: bookedProductsCount,
            availableTariffsSortedByTicketPersonId: availableTariffs,
            maxTariffLimit: limitBoughtTickets,
            tariffLimitPerUserAccount: ticketLimitPerUserAccount,
            currentUserAccountTariffLimit: currentUserAccountTicketLimit,
            packageLimitWarningMessage: ''
        });
    };
    PackageTypeComponent.prototype.initPackageValidationServiceSubscription = function () {
        var _this = this;
        this.packageValidationService.packageValidationState$
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (packageValidationViewModel) {
            _this.packageValidationViewModel = packageValidationViewModel;
            _this.updateCount();
        });
    };
    PackageTypeComponent.prototype.initFailedBookingValidationStateRevalidationSubscription = function () {
        var _this = this;
        this.actions$
            .pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST_ERROR, BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR), takeUntil(this.destroy$))
            .subscribe(function () {
            var revalidateValidationStateAfterFailedBooking = _this.packageValidationViewModel.validatedCount !== _this.packageType.numberOfAddedPackages;
            if (revalidateValidationStateAfterFailedBooking) {
                _this.revalidateCountAfterFailedBooking();
            }
        });
    };
    PackageTypeComponent.prototype.updatePackageValidationState = function () {
        if (this.packageValidationViewModel && this.tariffStatusViewModel && this.bookingStatusViewModel) {
            var _a = this.tariffStatusViewModel, currentUserAccountTicketLimit = _a.currentUserAccountTicketLimit, availableTariffs = _a.availableTariffs;
            var bookedProductsCount = this.bookingStatusViewModel.bookedProductsCount;
            var updatePackageValidationViewModel = {
                availableTariffsSortedByTicketPersonId: availableTariffs,
                numberOfAllBookedTariffs: bookedProductsCount,
                currentUserAccountTariffLimit: currentUserAccountTicketLimit
            };
            this.packageValidationViewModel = __assign({}, this.packageValidationViewModel, updatePackageValidationViewModel);
        }
    };
    PackageTypeComponent.prototype.revalidateCounterOnBookingCountDecreaseStateChange = function (changes) {
        var bookingStatusViewModel = changes.bookingStatusViewModel;
        if (bookingStatusViewModel) {
            var currentChangedBookingStatusViewModel = bookingStatusViewModel.currentValue;
            var previousChangedBookingStatusViewModel = bookingStatusViewModel.previousValue;
            if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
                return;
            }
            var isBookedProductsCountDecreased = previousChangedBookingStatusViewModel.bookedProductsCount >
                currentChangedBookingStatusViewModel.bookedProductsCount;
            if (isBookedProductsCountDecreased) {
                this.packageValidationService.revalidateDisabledCounter(this.packageValidationViewModel);
            }
        }
    };
    PackageTypeComponent.prototype.revalidateCountAfterFailedBooking = function () {
        var numberOfAddedPackages = this.packageType.numberOfAddedPackages;
        this.packageValidationViewModel.validatedCount = numberOfAddedPackages;
        this.validateCount(numberOfAddedPackages);
    };
    PackageTypeComponent.prototype.validateCount = function (updatePackageCount) {
        this.packageValidationService.validateState(updatePackageCount, this.packageValidationViewModel);
    };
    PackageTypeComponent.prototype.updateCount = function () {
        var lastPackageIndex = this.packageStatusViewModel.lastPackageIndex;
        var _a = this.packageValidationViewModel, validatedCount = _a.validatedCount, previousValidatedCount = _a.previousValidatedCount;
        var isRevalidatedDisabledCountOnTariffCounterDecrease = validatedCount === previousValidatedCount;
        if (isRevalidatedDisabledCountOnTariffCounterDecrease) {
            return;
        }
        if (validatedCount >= 0) {
            if (validatedCount > previousValidatedCount) {
                this.packageService.addPackage(this.packageType, lastPackageIndex, previousValidatedCount, validatedCount);
            }
            else if (validatedCount < previousValidatedCount) {
                this.packageService.removePackage(this.packageType, previousValidatedCount, validatedCount);
            }
        }
    };
    PackageTypeComponent.prototype.packagesTrackBy = function (_, currentPackage) {
        return currentPackage.trackByAddedPackage;
    };
    return PackageTypeComponent;
}());
export { PackageTypeComponent };
