import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeRu from '@angular/common/locales/ru';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { metaReducers, reducers } from '@app/app.reducer';
import { Routing } from '@app/app.routes';
import { BasketComponent } from '@app/basket/basket.component';
import { BigLoaderComponent } from '@app/big-loader/big-loader.component';
import { BrowserCheckComponent } from '@app/browser-check/browser-check.component';
import { CarouselComponent } from '@app/carousel/carousel.component';
import { ColorizerComponent } from '@app/colorizer/colorizer.component';
import { ColorizerEffect } from '@app/colorizer/colorizer.effects';
import { CookiesPopupComponent } from '@app/cookies-popup/cookies-popup.component';
import { CountdownComponent } from '@app/countdown/countdown.component';
import { ChangeCredentialsComponent } from '@app/edit-account-form/change-credentials/change-credentials.component';
import { ChangeTicketDayComponent } from '@app/edit-account-form/change-ticket-day/change-ticket-day.component';
import { EditAccountFormComponent } from '@app/edit-account-form/edit-account-form.component';
import { EditBillingAddressFormComponent } from '@app/edit-account-form/edit-billing-address-form/edit-billing-address-form.component';
import { RegistrationQuestionnaireComponent } from '@app/edit-account-form/registration-questionnaire/registration-questionnaire.component';
import { EventPassedComponent } from '@app/event-passed/event-passed.component';
import { ExhibitionTileComponent } from '@app/exhibition-tile/exhibition-tile.component';
import { FeedbackMessageComponent } from '@app/feedback-message/feedback-message.component';
import { IframeModalComponent } from '@app/iframe-modal/iframe-modal.component';
import { LoginComponent } from '@app/login/login.component';
import { LoginService } from '@app/login/login.service';
import { LogoutComponent } from '@app/logout/logout.component';
import { NewslettersUnsubscriptionsComponent } from '@app/newsletters-unsubscriptions/newsletters-unsubscriptions.component';
import { PressNewsEffect } from '@app/press-news/press-news.effects';
import { ResetButtonComponent } from '@app/reset-button/reset-button.component';
import { SelfRegTimeoutComponent } from '@app/self-reg-timeout/self-reg-timeout.component';
import { SpinnerComponent } from '@app/spinner/spinner.component';
import { SponsorListComponent } from '@app/sponsor-list/sponsor-list.component';
import { StatusBarComponent } from '@app/status-bar/status-bar.component';
import { StepNavigationButtonsComponent } from '@app/step-navigation-buttons/step-navigation-buttons.component';
import { StepNavigationComponent } from '@app/step-navigation/step-navigation.component';
import { TicketDropdownComponent } from '@app/ticket-dropdown/ticket-dropdown.component';
import { WebSelfRegistrationComponent } from '@app/web-self-registration/web-self-registration.component';
import { WebShopBuyerQuestionnaireComponent } from '@app/web-shop-buyer-questionnaire/web-shop-buyer-questionnaire.component';
import { WebShopComponent } from '@app/web-shop/web-shop.component';
import { AgeRatingComponent } from '@layout/age-rating/age-rating.component';
import { BottomStickyComponent } from '@layout/bottom-sticky/bottom-sticky.component';
import { FooterComponent } from '@layout/footer/footer.component';
import { TopBarWidgetComponent } from '@layout/top-bar/top-bar-widget/top-bar-widget.component';
import { TopBarComponent } from '@layout/top-bar/top-bar.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ChangePasswordComponent } from '@pages/change-password/change-password.component';
import { DataProtectionPageComponent } from '@pages/data-protection-page/data-protection-page.component';
import { EventSeriesComponent } from '@pages/event-series/event-series.component';
import { ForgottenPasswordComponent } from '@pages/forgotten-password/forgotten-password.component';
import { FourOFourComponent } from '@pages/four-o-four/four-o-four.component';
import { HomeComponent } from '@pages/home/home.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { MaintenencePageComponent } from '@pages/maintenence-page/maintenence-page.component';
import { NewAccountComponent } from '@pages/new-account/new-account.component';
import { PressNewsDetailComponent } from '@pages/press-news-detail/press-news-detail.component';
import { VoucherComponent } from '@pages/products/components/voucher/voucher.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { TicketsDownloadComponent } from '@pages/profile/tickets-download/tickets-download.component';
import { ResetEmailComponent } from '@pages/reset-email/reset-email.component';
import { ResetPasswordComponent } from '@pages/reset-password/reset-password.component';
import { TranslationInputComponent } from '@pages/translations/translation-input/translation-input.component';
import { TranslationsComponent } from '@pages/translations/translations.component';
import { VerifyEmailComponent } from '@pages/verify-email/verify-email.component';
import { WebShopAccountVerificationComponent } from '@pages/web-shop-account-verification/web-shop-account-verification.component';
import { ConfirmationWorkshopComponent } from '@pages/web-shop-confirmation/cofirmation-workshop/cofirmation-workshop.component';
import { ConfirmationWorkshopPricesComponent } from '@pages/web-shop-confirmation/confirmation-workshop-prices/confirmation-workshop-prices.component';
import { PaymentLandingPageComponent } from '@pages/web-shop-confirmation/payment-landing-page/payment-landing-page.component';
import { WebShopConfirmationComponent } from '@pages/web-shop-confirmation/web-shop-confirmation.component';
import { DownloadTicketHolderProfileEditComponent } from '@pages/web-shop-download/download-ticket-holder-profile-edit/download-ticket-holder-profile-edit.component';
import { DownloadTicketHolderComponent } from '@pages/web-shop-download/download-ticket-holder/download-ticket-holder.component';
import { TicketHolderQuestionnaireComponent } from '@pages/web-shop-download/ticket-holder-questionnaire/ticket-holder-questionnaire.component';
import { WebShopDownloadComponent } from '@pages/web-shop-download/web-shop-download.component';
import { WebShopInvoiceComponent } from '@pages/web-shop-invoice/web-shop-invoice.component';
import { WebShopLegitimationComponent } from '@pages/web-shop-legitimation/web-shop-legitimation.component';
import { WebShopMenuComponent } from '@pages/web-shop-menu/web-shop-menu.component';
import { BillingAddressComponent } from '@pages/web-shop-personalize/billing-address/billing-address.component';
import { BuyerInfoComponent } from '@pages/web-shop-personalize/buyer-info/buyer-info.component';
import { ExtraDataComponent } from '@pages/web-shop-personalize/extra-data/extra-data.component';
import { FormComponent } from '@pages/web-shop-personalize/order-tickets/form/form.component';
import { OrderTicketsComponent } from '@pages/web-shop-personalize/order-tickets/order-tickets.component';
import { WebShopPersonalizeComponent } from '@pages/web-shop-personalize/web-shop-personalize.component';
import { WebShopWorkshopComponent } from '@pages/web-shop-workshop/web-shop-workshop.component';
import { WebShopRecipeComponent } from '@pages/webshop-recipe/webshop-recipe.component';
import { BannerWidgetAdminComponent } from '@pages/widget-admin/banner-widget-admin/banner-widget-admin.component';
import { TicketsWidgetAdminComponent } from '@pages/widget-admin/tickets-widget-admin/tickets-widget-admin.component';
import { WidgetAdminComponent } from '@pages/widget-admin/widget-admin.component';
import { ProductSelectionLoginComponent } from '@products/components/product-selection-login/product-selection-login.component';
import { SelfRegistrationProductSelectionComponent } from '@products/pages/self-registration-product-selection/self-registration-product-selection.component';
import { WebShopProductSelectionComponent } from '@products/pages/web-shop-product-selection/web-shop-product-selection.component';
import { TranslationsEffects } from '@root/src/app/shared/services-with-reducers/translation/translations.effects';
import { ApplicationInsightsService } from '@shared/applicationInsights/applicationInsightsService';
import { DfInputComponent } from '@shared/forms/df-input/df-input.component';
import { AuthInterceptor } from '@shared/interceptors/auth-interceptor';
import { ErrorInterceptor } from '@shared/interceptors/error-interceptor';
import { HeaderInterceptor } from '@shared/interceptors/header-interceptor';
import { MissingTranslation } from '@shared/missing-translation/missing-translation';
import { MissingTranslationService } from '@shared/missing-translation/missing-translation.service';
import { MultipleTabsComponent } from '@shared/multiple-tabs/multiple-tabs.component';
import { FilterPlaceholderPipe } from '@shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { KeysPipe } from '@shared/pipes/keys/keys.pipe';
import { SafeUrlPipe } from '@shared/pipes/safe-url/safe-url.pipe';
import { SortByPipe } from '@shared/pipes/sort-by/sort-by.pipe';
import { LoginGuardAdmin } from '@shared/route-guards/admin-permission-guard';
import { MaintenancePageGuard } from '@shared/route-guards/maintenance-page-guard';
import { StepPermissionGuard } from '@shared/route-guards/step-permission-guard';
import { LoginGuardSignedOut } from '@shared/route-guards/user-logout-guard';
import { LoginGuardUser } from '@shared/route-guards/user-permission-guard';
import { CustomizationEffect } from '@shared/services-with-reducers/customization/customization.effects';
import { ExhibitionEffect } from '@shared/services-with-reducers/exhibition/exhibition.effects';
import { HelperEffect } from '@shared/services-with-reducers/helpers/helper.effects';
import { LegitimationEffect } from '@shared/services-with-reducers/legitimation/legitimation.effects';
import { StepsFormsEffect } from '@shared/services-with-reducers/step-forms/steps-forms.effects';
import { UserEffect } from '@shared/services-with-reducers/user/user.effects';
import { APP_REDUCER_TOKEN } from '@shared/tokens';
import { CustomParser } from '@shared/translate-parser/translate-parser';
import { SharedModule } from '@src/app-shared/shared.module';
import { environment } from '@src/environments/environment';
import { FileUploadModule } from '@src/imports/primeng-selected';
import { BookingEffect } from '@store/products/booking/booking.effects';
import { HolderEffect } from '@store/products/holder/holder.effects';
import { ProductSelectionEffect } from '@store/products/product-selection/product-selection.effects';
import { ProductEffect } from '@store/products/product.effects';
import { ArticleStatusEffect } from '@store/products/status/article/article.effects';
import { PackageStatusEffect } from '@store/products/status/package/package.effects';
import { TariffStatusEffect } from '@store/products/status/tariff/tariff.effects';
import { WorkshopStatusEffect } from '@store/products/status/workshop/workshop.effects';
import { WorkshopEffect } from '@store/products/workshop/workshop.effects';
import { WidgetModule } from '@widget/widget.module';
import { QuillModule } from 'ngx-quill';
import { CalendarModule } from 'primeng/calendar';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    BasketComponent,
    BuyerInfoComponent,
    ChangeCredentialsComponent,
    ChangeTicketDayComponent,
    CarouselComponent,
    ColorizerComponent,
    ExhibitionTileComponent,
    StatusBarComponent,
    ExtraDataComponent,
    FooterComponent,
    ForgottenPasswordComponent,
    FormComponent,
    FourOFourComponent,
    HomeComponent,
    KeysPipe,
    LoginComponent,
    SafeUrlPipe,
    WebShopMenuComponent,
    NewAccountComponent,
    OrderTicketsComponent,
    PressNewsDetailComponent,
    ProfileComponent,
    MultipleTabsComponent,
    StepNavigationButtonsComponent,
    StepNavigationComponent,
    TopBarComponent,
    TopBarWidgetComponent,
    VoucherComponent,
    WebShopComponent,
    WebSelfRegistrationComponent,
    WebShopConfirmationComponent,
    WebShopInvoiceComponent,
    WebShopLegitimationComponent,
    WebShopAccountVerificationComponent,
    WebShopPersonalizeComponent,
    EditAccountFormComponent,
    LoginPageComponent,
    DfInputComponent,
    WidgetAdminComponent,
    TicketsWidgetAdminComponent,
    BannerWidgetAdminComponent,
    TranslationsComponent,
    TranslationInputComponent,
    TicketHolderQuestionnaireComponent,
    RegistrationQuestionnaireComponent,
    NewslettersUnsubscriptionsComponent,
    WebShopWorkshopComponent,
    TicketDropdownComponent,
    BillingAddressComponent,
    EditBillingAddressFormComponent,
    WebShopDownloadComponent,
    DownloadTicketHolderComponent,
    DownloadTicketHolderProfileEditComponent,
    FeedbackMessageComponent,
    ResetPasswordComponent,
    ResetEmailComponent,
    ChangePasswordComponent,
    ConfirmationWorkshopComponent,
    ConfirmationWorkshopPricesComponent,
    PaymentLandingPageComponent,
    VerifyEmailComponent,
    CountdownComponent,
    LogoutComponent,
    SponsorListComponent,
    WebShopRecipeComponent,
    SortByPipe,
    SelfRegTimeoutComponent,
    ResetButtonComponent,
    IframeModalComponent,
    BigLoaderComponent,
    EventPassedComponent,
    WebShopBuyerQuestionnaireComponent,
    BrowserCheckComponent,
    CookiesPopupComponent,
    BottomStickyComponent,
    DataProtectionPageComponent,
    EventSeriesComponent,
    SpinnerComponent,
    MaintenencePageComponent,
    AgeRatingComponent,
    TicketsDownloadComponent,
    WebShopProductSelectionComponent,
    SelfRegistrationProductSelectionComponent,
    ProductSelectionLoginComponent
  ],
  imports: [
    /* Add .withServerTransition() to support Universal rendering.
     * The application ID can be any identifier which is unique on
     * the page.
     */
    SharedModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button
          ['link']
        ]
      },
      theme: 'snow'
    }),
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    !environment.serverRendered
      ? StoreModule.forRoot(APP_REDUCER_TOKEN, {
          metaReducers
        })
      : [],
    environment.serverRendered ? StoreModule.forRoot(reducers, {}) : [],

    EffectsModule.forRoot([
      CustomizationEffect,
      ExhibitionEffect,
      HelperEffect,
      LegitimationEffect,
      PressNewsEffect,
      UserEffect,
      StepsFormsEffect,
      ColorizerEffect,
      ProductSelectionEffect,
      WorkshopEffect,
      BookingEffect,
      HolderEffect,
      WorkshopEffect,
      ProductSelectionEffect,
      ArticleStatusEffect,
      PackageStatusEffect,
      TariffStatusEffect,
      WorkshopStatusEffect,
      ProductEffect,
      TranslationsEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      features: {
        pause: true, // start/pause recording of dispatched actions
        lock: true, // lock/unlock dispatching actions and side effects
        persist: true, // persist states on page reloading
        jump: true, // jump back and forth (time traveling)
        skip: true, // skip (cancel) actions
        dispatch: true // dispatch custom actions or action creators
      }
    }),
    FileUploadModule,
    CalendarModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    Routing,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      parser: { provide: TranslateParser, useClass: CustomParser },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslation,
        deps: [MissingTranslationService]
      }
    }),
    WidgetModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_REDUCER_TOKEN, useValue: reducers },
    LoginGuardUser,
    LoginGuardSignedOut,
    MaintenancePageGuard,
    StepPermissionGuard,
    LoginGuardAdmin,
    LoginService,
    DatePipe,
    ApplicationInsightsService,
    FilterPlaceholderPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
