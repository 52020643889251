import { HttpClient } from '@angular/common/http';
import { getLanguage, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { environment } from '@src/environments/environment';
import { LoadAndSetTranslations, SetIsTranslationLoaded } from '@store/translation/translation.actions';
import { combineLatest } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/error-handling/error-handling.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@ngrx/store";
var TranslationsService = /** @class */ (function () {
    function TranslationsService(http, errorHandlingService, translateService, store) {
        var _this = this;
        this.http = http;
        this.errorHandlingService = errorHandlingService;
        this.translateService = translateService;
        this.store = store;
        combineLatest([this.store.pipe(select(getLanguage)), this.store.pipe(select(getSelectedExhibitionId))])
            .pipe(filter(function (_a) {
            var lang = _a[0];
            return !!lang;
        }))
            .subscribe(function (_a) {
            var lang = _a[0], eventId = _a[1];
            if (eventId === null) {
                eventId = -1;
            }
            setTimeout(function () {
                _this.store.dispatch(new LoadAndSetTranslations({ eventId: eventId, lang: lang }));
            });
        });
    }
    /* rest api */
    TranslationsService.prototype.getDefaultTranslations = function () {
        var origin = environment.protocol + environment.origin;
        //origin = origin || window.location.origin;
        //let origin = window.location.origin;
        return (this.http
            // `${environment.protocol}${environment.origin}/assets/i18n/default.json`,
            .get(origin + '/assets/i18n/default.json'));
    };
    TranslationsService.prototype.getLangTranslations = function (lang, eventId) {
        var _this = this;
        var event = -1;
        if (eventId !== 'default') {
            event = eventId;
        }
        return this.http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + event + "/translations/admin/" + lang, {
            observe: 'response'
        })
            .pipe(map(function (resp) {
            return resp.body.frontend;
        }), catchError(function (e) {
            return _this.errorHandlingService.errorHandler(e);
        }));
    };
    TranslationsService.prototype.saveTranslations = function (activeLang, eventId, translations) {
        var event = -1;
        if (eventId !== 'default') {
            event = eventId;
        }
        return this.http.put("" + environment.protocol + environment.webApiUrl + "/event/" + event + "/translations/" + activeLang, translations);
    };
    /**
     * Get translation for position Dropdown and handle it with callback
     * @param input - InputBase holding option settings
     * @param positionData - select option data
     * @param handleCallback - function handling translation
     */
    TranslationsService.prototype.getPositionTranslationForOption = function (input, positionData, handleCallback) {
        var translationKey = positionData.value;
        if (translationKey) {
            this.translateService.get(translationKey).subscribe(function (translation) {
                handleCallback(translation);
            });
        }
        else {
            handleCallback(positionData.value);
        }
    };
    TranslationsService.prototype.loadAndMergeTranslations = function (lang, eventId) {
        var _this = this;
        this.setIsTranslationLoadedStatus(false);
        return this.http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/translations/" + lang, {
            observe: 'response'
        })
            .pipe(map(function (resp) {
            if (resp.body.backend) {
                return Object.assign(resp.body.backend, resp.body.frontend);
            }
            else {
                return resp.body.frontend;
            }
        }), catchError(function (e) {
            _this.setIsTranslationLoadedStatus(true);
            return _this.errorHandlingService.errorHandler(e);
        }));
    };
    TranslationsService.prototype.setIsTranslationLoadedStatus = function (isTranslationLoaded) {
        this.store.dispatch(new SetIsTranslationLoaded(isTranslationLoaded));
    };
    TranslationsService.prototype.setTranslationsIntoTranslateService = function (translations) {
        var language = translations.language, list = translations.list;
        this.translateService.resetLang(this.translateService.currentLang);
        this.translateService.setTranslation(language, list);
        this.translateService.use(language);
        this.translateService.setDefaultLang(language);
    };
    TranslationsService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationsService_Factory() { return new TranslationsService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlingService), i0.inject(i3.TranslateService), i0.inject(i4.Store)); }, token: TranslationsService, providedIn: "root" });
    return TranslationsService;
}());
export { TranslationsService };
