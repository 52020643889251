import { Location } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  State,
  getExhibitionSettings,
  getProfile,
  getSelectedExhibition,
  getSelectedExhibitionId,
  getSelfRegistration,
  isFirstLoad,
  isLoggedInAsAdmin
} from '@app/app.reducer';
import { AppService } from '@app/app.service';
import { Store, select } from '@ngrx/store';
import { FormsService } from '@shared/forms/forms.service';
import { CustomizationService } from '@store/customization/customization.service';
import {
  FirstLoadDone,
  GetDoubleClickScripts,
  GetGenericScripts,
  SelectAction
} from '@store/exhibition/exhibition.actions';
import { SetEventSeriesPage } from '@store/helpers/helper.actions';
import { HelperService } from '@store/helpers/helper.service';
import { GetLegitimationStatus } from '@store/legitimation/legitimation.actions';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetBuyerInfoFromURL, SetSelectedStep } from '@store/step-forms/steps-forms.actions';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-web-shop',
  templateUrl: './web-shop.component.html',
  styleUrls: ['./web-shop.component.scss']
})
export class WebShopComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'flex-grow-column';
  subscriptions = new Subscription();
  eventIsOver = false;
  isLoading = true;
  modalWindowOpen: boolean = false;
  isSelfRegistrationEnabled: boolean = false;

  private exhibitionId: number;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private customizationService: CustomizationService,
    private appService: AppService,
    private formsService: FormsService,
    private location: Location,
    private helperService: HelperService,
    private legitimationService: LegitimationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.helperService.showModalWindow(false);
  }
  ngOnInit() {
    this.subscriptions.add(
      this.helperService.showModalWindow$.subscribe(item => {
        if (item) {
          this.modalWindowOpen = true;
        }
      })
    );
    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getProfile)), this.store.pipe(select(getSelectedExhibitionId))])
        .pipe(filter(data => !!data[0] && data[1] !== null))
        .subscribe(data => {
          const [user, eventId] = data;
          this.exhibitionId = eventId;

          this.store.dispatch(
            new GetLegitimationStatus({
              userId: user.id,
              eventId
            })
          );
        })
    );

    // #3863 - IE not supported
    this.store
      .pipe(select(getSelfRegistration))
      .pipe(first())
      .subscribe((selfRegistration: boolean) => {
        this.isSelfRegistrationEnabled = selfRegistration;
      });

    this.subscriptions.add(
      this.route.queryParamMap.subscribe(data => {
        let buyerInfoFromUrl = this.createBuyerInfoFromUrl(data);
        this.store.dispatch(new SetBuyerInfoFromURL(buyerInfoFromUrl));
      })
    );

    let oldExhibitionId = null;
    this.subscriptions.add(
      this.store.pipe(select(getSelectedExhibitionId)).subscribe(id => {
        oldExhibitionId = id;
      })
    );

    this.isLoading = true;
    this.subscriptions.add(
      this.route.params.subscribe(data => {
        // not using strict compare because id is stored as number but its a string in queryParamz
        if (data.id != oldExhibitionId) {
          this.appService.resetReducers(true, false).subscribe(() => {
            this.store.dispatch(new SelectAction(data.id));
            this.store.dispatch(new GetDoubleClickScripts(data.id));
            this.store.dispatch(new GetGenericScripts(data.id));
            this.goToTickets(); // when we change event via url we go to ticket page always;

            this.setStyles();
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(getSelectedExhibition)),
        this.store.pipe(select(isFirstLoad)),
        this.store.pipe(select(isLoggedInAsAdmin))
      ])
        .pipe(
          filter(data => {
            const hasSelectedExhibition = data[0];
            const anyFirstLoadValue = data[1] || !data[1]; // because isFirstLoad is a boolean and typescript throws error for unknown type

            return hasSelectedExhibition && anyFirstLoadValue;
          })
        )
        .subscribe(data => {
          this.eventIsOver = false;
          const [exhibition, isFirstLoad, isAdmin] = data;
          const locationAtExhibitionID = '/webshop/' + exhibition.id;

          // when browser back button is pressed on ticket page we redirect user to homepage
          window.addEventListener('popstate', () => {
            if (this.location.path() === locationAtExhibitionID) {
              this.navigateToRoot();
            }
          });

          // when programatically navigated back on ticket page we redirect user to homepage
          this.location.subscribe(location => {
            if (location.pop) {
              if (location.url === locationAtExhibitionID) {
                this.navigateToRoot();
              }
            }
          });

          if ((!exhibition || exhibition.isOver) && !isAdmin) {
            this.eventIsOver = true;
            setTimeout(() => {
              this.router.navigate(['./is-over'], {
                relativeTo: this.route
              });
            }, 200);
          } else {
            // if you come to webshop page without any subpage decision to which step to go is made based on backoffice settings
            if (!this.route.children.length || isFirstLoad) {
              this.getSettingsAndRedirect();
            }

            this.route.queryParams.subscribe(params => {
              if (params.theme) {
                this.customizationService.setColors(params.theme);
              }

              if (params.reset) {
                this.getSettingsAndRedirect();
              }
            });
          }
        })
    );
  }

  modalWindowVote(copyDataYesNo: boolean) {
    this.modalWindowOpen = false;
    if (copyDataYesNo) {
      this.router.navigate([`webshop/${this.exhibitionId}/personal`]);
    }

    this.helperService.voteYesOrNo(copyDataYesNo);
  }

  // Possible parameters:
  // email, gender, title, firstName, lastName, company, function, country, street, city, telephone, zipCode
  createBuyerInfoFromUrl(params: ParamMap) {
    let translations = new Map<string, string>();
    let buyerInfoUrlModel = [];
    // DE
    translations.set('besucher_firma', 'company');
    translations.set('besucher_br_anrede_id', 'gender');
    translations.set('besucher_titel', 'title');
    translations.set('besucher_vorname', 'firstName');
    translations.set('besucher_name', 'lastName');
    translations.set('besucher_funktion', 'function');
    translations.set('besucher_strasse', 'street');
    translations.set('besucher_plz', 'zipCode');
    translations.set('besucher_ort', 'city');
    translations.set('besucher_country', 'country');
    translations.set('besucher_email', 'email');
    translations.set('besucher_telefon', 'telephone');

    params.keys.forEach(parameterKey => {
      if (!translations.has(parameterKey)) return;
      if (translations.get(parameterKey) === 'gender') {
        buyerInfoUrlModel.push({
          key: translations.get(parameterKey),
          value:
            params.get(parameterKey) === '0' ? 'male' : '1' ? 'female' : '2' ? 'diverse' : '3' ? 'notSpecified' : null
        });
      } else {
        if (translations.get(parameterKey) === 'email') {
          buyerInfoUrlModel.push({
            key: 'verify-email',
            value: params.get(parameterKey)
          });
        }

        buyerInfoUrlModel.push({
          key: translations.get(parameterKey),
          value: params.get(parameterKey)
        });
      }
    });
    return buyerInfoUrlModel;
  }

  setStyles() {
    this.subscriptions.add(
      this.store
        .pipe(
          select(getExhibitionSettings),
          filter(settings => !!settings)
        )
        .subscribe(() => {
          this.customizationService.setStyles();
        })
    );
  }

  goToLegitimation() {
    this.store.dispatch(new SetSelectedStep('legitimation'));
    this.router.navigate(['./legitimation'], {
      relativeTo: this.route,
      preserveQueryParams: true
    });
  }

  goToTickets() {
    this.store.dispatch(new SetSelectedStep('tickets'));
    //if the tickets are not in url yet, navigate there

    if (
      !(
        this.route.snapshot.firstChild &&
        this.route.snapshot.firstChild.url &&
        this.route.snapshot.firstChild.url[0].path === 'tickets'
      )
    ) {
      return this.router.navigate(['./tickets'], {
        relativeTo: this.route,
        queryParams: this.route.snapshot.queryParams
      });
    }
  }

  getSettingsAndRedirect() {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(isFirstLoad)),
      this.legitimationService.isLegitimationRequiredForBookedProducts$()
    ])
      .pipe(first(([settings]) => !!settings))
      .subscribe(([settings, isFirstLoad, isLegitimationRequired]) => {
        if (settings.goToLegitimationForNewAccount && isLegitimationRequired) {
          this.formsService.setFormValidity(false, null, ['legitimation', 'validation']);
          this.goToLegitimation();
        } else if (
          isFirstLoad &&
          this.route.snapshot.firstChild &&
          this.route.snapshot.firstChild.url[0].path !== 'invoice' // if local storage is disabled for IE and we come from payment page to invoice page we need this condition
        ) {
          this.goToTickets();
        } else if (!this.route.snapshot.firstChild) {
          this.goToTickets();
        }

        this.store.dispatch(new SetEventSeriesPage(null)); // when we come to event reset event page info
        this.store.dispatch(new FirstLoadDone());
      });
  }

  navigateToRoot() {
    window.location.replace('/');
  }
}
