var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { combineLatest, Subscription } from 'rxjs';
import { emailChangeStatus, getLanguage, getProfile, passChangeStatus } from '@app/app.reducer';
import { PasswordChanged, PatchEmail, PatchPassword } from '@store/user/user.actions';
import { first } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { getEmailChangeForm, getPasswordChangeForm } from '../form-inputs.edit-account';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { FormsService } from '@shared/forms/forms.service';
import { WindowSizeService } from '@shared/window-size/window-size.service';
var ChangeCredentialsComponent = /** @class */ (function () {
    function ChangeCredentialsComponent(store, formsService, statusBarService, windowSizeService) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.statusBarService = statusBarService;
        this.windowSizeService = windowSizeService;
        this.emailChangeActive = false;
        this.passwordChangeActive = false;
        this.changeEmailAction = ['changeEmailForm'];
        this.changePasswordAction = ['changePasswordForm'];
        this.subscriptions = new Subscription();
        this.emailFormSaveCallback = function (inputs) {
            _this.emailInputs = _this.formsService.updateInputs(_this.emailInputs, inputs);
        };
        this.passwordFormSaveCallback = function (inputs) {
            _this.passwordInputs = _this.formsService.updateInputs(_this.passwordInputs, inputs);
        };
        // set password status change to null so we can react on it later when we got real response
        this.store.dispatch(new PasswordChanged(null));
    }
    ChangeCredentialsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileAndLanguage$ = combineLatest([
            this.store.pipe(select(getProfile)),
            this.store.pipe(select(getLanguage))
        ])
            .pipe(first(function (profileAndLanguage) { return profileAndLanguage.every(function (item) { return !!item; }); }));
        this.subscriptions.add(this.store.pipe(select(passChangeStatus)).subscribe(function (statusCode) {
            if (statusCode === 204) {
                _this.statusBarService.setStatus('Password changed successfully', 'success');
            }
            if (statusCode === 200) {
                _this.profile['isPasswordChangePending'] = true;
            }
        }));
        this.subscriptions.add(this.store.pipe(select(getProfile)).subscribe(function (profile) {
            _this.profile = __assign({}, profile);
            _this.emailInputs = getEmailChangeForm();
            _this.emailForm = _this.formsService.toFormGroup(_this.emailInputs, _this.changeEmailAction);
        }));
        this.passwordInputs = getPasswordChangeForm();
        this.passwordForm = this.formsService.toFormGroup(this.passwordInputs, this.changePasswordAction);
    };
    ChangeCredentialsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.profile.email) {
            var emailBlock_1 = document.querySelector('.form-block');
            this.windowSizeService.scrollToElement(emailBlock_1, 50, 50, 0.02);
            setTimeout(function () {
                emailBlock_1.focus();
                _this.emailChangeActive = true;
            }, 100);
        }
    };
    ChangeCredentialsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ChangeCredentialsComponent.prototype.submitEmailChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.profileAndLanguage$.switchMap(function (_a) {
                var user = _a[0], language = _a[1];
                _this.store.dispatch(new PatchEmail({
                    email: _this.emailInputs[0].value,
                    password: _this.emailInputs[2].value,
                    userId: user.id,
                    language: language
                }));
                return _this.store.pipe(select(emailChangeStatus));
            })
                .subscribe(function (code) {
                if (code && code >= 200 && code < 300) {
                    _this.profile['isEmailChangePending'] = true;
                }
                _this.emailChangeActive = false;
            });
        }, 100);
    };
    ChangeCredentialsComponent.prototype.submitPasswordChange = function () {
        var _this = this;
        this.profileAndLanguage$.subscribe(function (_a) {
            var user = _a[0], language = _a[1];
            _this.profile['isPasswordChangePending'] = true;
            _this.store.dispatch(new PatchPassword({
                password: _this.passwordInputs[0].value,
                userId: user.id,
                language: language
            }));
            _this.passwordChangeActive = false;
        });
    };
    return ChangeCredentialsComponent;
}());
export { ChangeCredentialsComponent };
