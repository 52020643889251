var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TariffStatusService } from '@products/services/tariff-status.service';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { ActionTypes as ProductSelectionActionTypes } from '@store/products/product-selection/product-selection.actions';
import { getAllTariffs } from '@store/products/product-selection/product-selection.selectors';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { SetAvailableTariffsStatus, SetIsCreateContingentReservationLoadingStatus, SetIsTariffLoadingStatus, SetReleasedVoucherAvailableTariffStatus, SetUserAccountTariffsLimitStatus, ActionTypes as TariffActionTypes } from '@store/products/status/tariff/tariff.actions';
import { getAvailableTariffs } from '@store/products/status/tariff/tariff.selectors';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
var TariffStatusEffect = /** @class */ (function () {
    function TariffStatusEffect(actions$, store, tariffStatusService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.tariffStatusService = tariffStatusService;
        this.getTariffsUserAccountLimitStatus$ = this.actions$.pipe(ofType(TariffActionTypes.GET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS), switchMap(function (_a) {
            var payload = _a.payload;
            var eventId = payload;
            if (!eventId) {
                return EMPTY;
            }
            return _this.tariffStatusService.getTicketLimitPerUserAccount$(eventId).pipe(map(function (userAccountTicketLimit) { return new SetUserAccountTariffsLimitStatus(userAccountTicketLimit); }), catchError(function () { return of(new SetUserAccountTariffsLimitStatus(null)); }));
        }));
        this.setInitialAvailableTariffsStatus$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST, ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST), withLatestFrom(this.store.pipe(select(getAllTariffs))), map(function (_a) {
            var _ = _a[0], tariffs = _a[1];
            var availableTariffs = _this.tariffStatusService.mapTariffsToAvailableTariffs(tariffs);
            return new SetAvailableTariffsStatus(availableTariffs);
        }));
        this.setAvailableTariffsStatus$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST, BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            var availableTariffs = _this.tariffStatusService.mapBookedProductTypesToAvailableTariffs(payload);
            return new SetAvailableTariffsStatus(availableTariffs);
        }));
        this.setReleasedVoucherAvailableTariffStatus$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_RELEASED_VOUCHER), concatMap(function (_a) {
            var payload = _a.payload;
            if (!payload) {
                return EMPTY;
            }
            return _this.tariffStatusService
                .mapRemoveVoucherToAddAvailableTariffs$(payload)
                .map(function (addAvailableTariffs) { return new SetReleasedVoucherAvailableTariffStatus(addAvailableTariffs); });
        }));
        this.setAvailableTariffsStatusAfterAnonymousVoucherProductRedeem$ = this.actions$.pipe(ofType(ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER), filter(function (_a) {
            var payload = _a.payload;
            return payload && payload.redeemedVoucher && payload.redeemedVoucher.isAnonymous;
        }), withLatestFrom(this.store.pipe(select(getAllBookedTariffs)), this.store.pipe(select(getAvailableTariffs))), concatMap(function (_a) {
            var payload = _a[0].payload, bookedTariffs = _a[1], currentAvailableTariffs = _a[2];
            var removedProductBookings = payload.removeProductBookings;
            if (!removedProductBookings.length) {
                return EMPTY;
            }
            var availableTariffs = _this.tariffStatusService.mapRemovedBookingProductTypesToAvailableTariffsAfterAnonymousVoucherRedeem(removedProductBookings, bookedTariffs, currentAvailableTariffs);
            return of(new SetAvailableTariffsStatus(availableTariffs));
        }));
        this.isTariffLoadingStatus$ = this.actions$.pipe(ofType(ProductActionTypes.SET_TARIFF_BOOKING, ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING, ProductActionTypes.SET_PACKAGE_BOOKING, ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING, ProductActionTypes.REMOVE_PRODUCT_BOOKING, ProductActionTypes.REMOVE_TARIFF_BOOKING, ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING, ProductActionTypes.REMOVE_PACKAGE_BOOKING, ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING, BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST, BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST, ProductSelectionActionTypes.SET_PACKAGE_IN_SELECTION_LIST, ProductSelectionActionTypes.REMOVE_PACKAGE_FROM_SELECTION_LIST), map(function (action) {
            var productActionTypes = [
                ProductActionTypes.SET_TARIFF_BOOKING,
                ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING,
                ProductActionTypes.SET_PACKAGE_BOOKING,
                ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING,
                ProductActionTypes.REMOVE_PRODUCT_BOOKING,
                ProductActionTypes.REMOVE_TARIFF_BOOKING,
                ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING,
                ProductActionTypes.REMOVE_PACKAGE_BOOKING,
                ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING
            ];
            var isTariffLoading = productActionTypes.includes(action.type);
            return new SetIsTariffLoadingStatus(isTariffLoading);
        }));
        this.setCreateContingentReservationLoadingStatus$ = this.actions$.pipe(ofType(ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION, BookingActionTypes.SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING, ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION), map(function (_a) {
            var type = _a.type;
            var isCreateContingentBookingReservationLoading = type === ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION ||
                type === ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION;
            return new SetIsCreateContingentReservationLoadingStatus(isCreateContingentBookingReservationLoading);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "getTariffsUserAccountLimitStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "setInitialAvailableTariffsStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "setAvailableTariffsStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "setReleasedVoucherAvailableTariffStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "setAvailableTariffsStatusAfterAnonymousVoucherProductRedeem$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "isTariffLoadingStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TariffStatusEffect.prototype, "setCreateContingentReservationLoadingStatus$", void 0);
    return TariffStatusEffect;
}());
export { TariffStatusEffect };
