export enum LoginOptions {
  HideTopBarLogin = 'hideTopBarLogin',
  OptionalPersonalLogin = 'optionalPersonalLogin',
  OptionalTicketLogin = 'optionalTicketLogin',
  OptionalTicketAndPersonalLogin = 'optionalTicketAndPersonalLogin',
  PersonalizationWithRequiredEmailConfirmation = 'personalizationWithRequiredEmailConfirmation',
  BeforeTicketSelection = 'beforeTicketSelection',
  BeforeTicketSelectionWithRequiredEmailConfirmation = 'beforeTicketSelectionWithRequiredEmailConfirmation'
}

export interface AccountVerificationStatus {
  isVerifiedAccountRequired: boolean;
  isAccountVerified: boolean;
}
