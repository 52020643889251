<ng-container *ngIf="loaded; else loading">
  <form [formGroup]="form" class="row" novalidate *ngIf="form">
    <app-df-input
      *ngFor="let input of inputs"
      [saveFormCallback]="inputsChanged"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [action]="actionName"
      [form]="form"
    >
    </app-df-input>
  </form>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
