import { Injectable } from '@angular/core';
import { State } from '@app/app.reducer';
import { Store } from '@ngrx/store';
import { BookingPackageType, BookingProductType, BookingTariffType } from '@products/models/booking.model';
import { Package, PackageType } from '@products/models/package.model';
import { isProductTypeTariff } from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';
import { TariffPackageSettings } from '@products/models/tariff.model';
import { BookingService } from '@products/services/booking.service';
import { GtmService } from '@root/src/app/shared/gtm/gtmService';
import { RemovePackageBooking, SetPackageBooking } from '@store/products/product.actions';

@Injectable()
export class PackageService {
  constructor(private store: Store<State>, private bookingService: BookingService, private gtmService: GtmService) {}

  addPackage(packageType: PackageType, lastPackageIndex: number, count: number, updatedCount: number) {
    const isFirstPackagedAdded = packageType.packages.length === 1 && count === 0;
    const bookingPackageTypes: BookingPackageType[] = [];

    let numberOfPackagesToAdd: number = updatedCount - count;

    if (isFirstPackagedAdded) {
      const bookingPackageType: BookingPackageType = this.mapPackageTypeToBookingPackageType(
        packageType,
        packageType.packages[0].packageIndex
      );

      numberOfPackagesToAdd--;
      this.gtmService.pushAddToCart(bookingPackageType);
      bookingPackageTypes.push(bookingPackageType);
    }

    for (let addPackage = 1; addPackage <= numberOfPackagesToAdd; addPackage++) {
      const bookingPackageType: BookingPackageType = this.mapPackageTypeToBookingPackageType(
        packageType,
        lastPackageIndex + addPackage
      );

      this.gtmService.pushAddToCart(bookingPackageType);
      bookingPackageTypes.push(bookingPackageType);
    }

    this.store.dispatch(new SetPackageBooking(bookingPackageTypes));
  }

  removePackage(packageType: PackageType, count: number, updatedCount: number) {
    const numberOfPackagesToRemove = count - updatedCount;
    const packageIndexesToRemove = packageType.packages
      .slice(-numberOfPackagesToRemove)
      .map(packageToRemove => packageToRemove.packageIndex);

    packageIndexesToRemove.forEach(packageIndexToRemove => {
      const bookingPackageType: BookingPackageType = this.mapPackageTypeToBookingPackageType(
        packageType,
        packageIndexToRemove
      );

      this.gtmService.pushRemoveFromCart(bookingPackageType);
    });

    this.store.dispatch(
      new RemovePackageBooking({
        packageNumber: packageType.packageNumber,
        packageIndexes: packageIndexesToRemove
      })
    );
  }

  public setValidatedPackageTariffAmount(packageSettings: TariffPackageSettings, previousCount: number) {
    const minAmount = packageSettings.minAmount;
    const maxAmount = packageSettings.maxAmount;
    const defaultAmount = minAmount != null ? minAmount : packageSettings.amount;
    const amount = previousCount != null && previousCount ? previousCount : defaultAmount;
    const shouldSetMinTicketAmount = minAmount != null && minAmount > amount;
    const shouldSetMaxTicketAmount = maxAmount != null && maxAmount < amount;

    let ticketAmount = amount;

    if (shouldSetMaxTicketAmount) {
      ticketAmount = maxAmount;
    }

    if (shouldSetMinTicketAmount) {
      ticketAmount = minAmount;
    }

    return ticketAmount;
  }

  mapPackageTypeToBookingPackageType(packageType: PackageType, newPackageIndex: number): BookingPackageType {
    const bookingProductTypes: BookingProductType[] = [];
    const initialPackage: Package = packageType.packages[0];

    initialPackage.products.forEach(packageProduct => {
      if (isProductTypeTariff(packageProduct)) {
        const bookingTariffType: BookingTariffType = {
          productType: ProductType.Tariff,
          productTypeName: packageProduct.tariffType.ticketTypeName,
          ticketTypeId: packageProduct.tariffType.ticketTypeId,
          tariffs: packageProduct.tariffType.tariffs.map(packageTariff =>
            this.bookingService.mapTariffToBookingTariff(
              packageTariff,
              0,
              this.setValidatedPackageTariffAmount(packageTariff.packageSettings, 0),
              newPackageIndex
            )
          )
        };

        bookingProductTypes.push(bookingTariffType);
      }
    });

    const bookingPackageTypes: BookingPackageType = {
      productType: ProductType.Package,
      productTypeName: packageType.name,
      packageNumber: packageType.packageNumber,
      packageIndex: newPackageIndex,
      productTypes: bookingProductTypes
    };

    return bookingPackageTypes;
  }
}
