<div class="account-verification-padding-wrapper">
  <div *ngIf="!(userProfile$ | async); else accountVerificationElseBlock">
    <div
      class="login-message"
      data-translation-includes-children
      data-translation="account-verification.login-message"
      *ngIf="!('account-verification.login-message' | translate | isHiddenTranslation)"
      [innerHTML]="'account-verification.login-message' | translate"
    ></div>
    <app-login class="login-body"></app-login>
  </div>
  <ng-template #accountVerificationElseBlock>
    <app-info-block [infoText]="'account-verification.unverified-email'"></app-info-block>
  </ng-template>
</div>
