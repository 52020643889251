<div class="auth-wrap" *ngIf="tabs$ | async as tabs">
  <app-navigation-tabs
    *ngIf="tabs.length"
    [tabs]="tabs"
    (activeTabChanged)="onTabChange($event)"
    type="wide"
    [activeTab]="activeTab"
  ></app-navigation-tabs>

  <div class="tabs-related-content">
    <div class="registration" *ngIf="activeTab && activeTab === 'registration'">
      <app-edit-account-form
        [validationStepName]="validationStepName"
        [formType]="'new-account'"
        [userUrlFormData]="userUrlFormData"
      ></app-edit-account-form>
    </div>
    <div
      class="row login"
      *ngIf="activeTab && activeTab === 'login'"
      [class.center-login]="availableSocialLogins.length === 0"
    >
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit(loginForm.value)"
        class="login-form col-md-7 col-sm-12"
      >
        <div class="login-body-wrapper">
          <div class="login-input-wrapper">
            <svg class="icon icon-at">
              <use xlink:href="assets/defs.svg#icon-at"></use>
            </svg>
            <input
              [formControl]="email"
              name="email"
              id="login-input"
              class="login-input"
              type="email"
              data-translation="login.name"
              placeholder="{{ 'login.name' | translate | hideTranslation }}"
              required
            />
          </div>
          <div
            class="login-input-error-message"
            *ngIf="email.errors && email.touched"
          >
            <small data-translation="login.name-validation">
              {{ 'login.name-validation' | translate | hideTranslation }}
            </small>
          </div>
          <div class="login-input-wrapper">
            <svg class="icon icon-key">
              <use xlink:href="assets/defs.svg#icon-key"></use>
            </svg>
            <input
              [formControl]="password"
              name="password"
              id="password-input"
              class="login-input"
              type="password"
              data-translation="login.password"
              placeholder="{{ 'login.password' | translate | hideTranslation }}"
              [showPassword]="passwordEye"
              required
            />
            <svg class="icon icon-key" #passwordEye>
              <use xlink:href="assets/defs.svg#icon-eye"></use>
            </svg>
          </div>
          <div
            class="login-input-error-message"
            *ngIf="password.errors && password.touched"
          >
            <small data-translation="login.password_characters">
              {{ 'login.password-validation' | translate | hideTranslation }}
            </small>
          </div>

          <div class="login-links-wrapper">
            <a
              routerLink="/forgotten"
              class="login-link"
              data-translation="login.forgotten"
            >
              {{ 'login.forgotten' | translate | hideTranslation }}
            </a>
            <!-- <a routerLink="/new-account" class="login-link-border login-link" data-translation="login.register">{{'login.register' | translate | hideTranslation }}</a> -->
          </div>
        </div>

        <div class="login-button-wrapper">
          <button
            [disabled]="!password.valid || !email.valid || !loginForm.valid"
            type="submit"
            class="button"
          >
            <span data-translation="login.submit">
              {{ 'login.submit' | translate | hideTranslation }}
            </span>
          </button>
        </div>
      </form>
      <div
        *ngIf="availableSocialLogins.length > 0"
        class="social-login-wrapper col-md-5 col-sm-12"
      >
        <div
          *ngFor="let socialLogin of availableSocialLogins"
          class="social-button"
          (click)="loginWith(socialLogin)"
        >
          <svg
            class="social-icon"
            [ngClass]="'icon-' + socialLogin.toLowerCase()"
          >
            <use
              [attr.xlink:href]="
                'assets/defs.svg#icon-' + socialLogin.toLowerCase()
              "
            ></use>
          </svg>
          <span class="social-title black">{{ socialLogin }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
