import { Component, Input, OnInit } from '@angular/core';
import { State, showLoginOnTicketAndPersonalPage, showLoginOnTicketPage } from '@app/app.reducer';
import { AppConstants } from '@app/shared/app-constants';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-selection-login',
  templateUrl: './product-selection-login.component.html',
  styleUrls: ['./product-selection-login.component.scss']
})
export class ProductSelectionLoginComponent implements OnInit {
  @Input() isRedeemedAnonymousVoucherProductInProductSelectionList: boolean;
  @Input() notLoggedAndLoginMandatory: boolean;
  showLoginOnTicketPage$: Observable<boolean>;
  showLoginOnTicketAndPersonalPage$: Observable<boolean>;

  readonly registrationFormsActionName = AppConstants.registrationFormsActionName;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.showLoginOnTicketPage$ = this.store.pipe(select(showLoginOnTicketPage));
    this.showLoginOnTicketAndPersonalPage$ = this.store.pipe(select(showLoginOnTicketAndPersonalPage));
  }
}
