<div class="input-wrapper form-wrapper" *ngIf="billingAddressList.length">
  <select
    id="bilAddressSelect"
    [ngClass]="{ hasvalue: selectedBuyerBillAddressId >= 0 }"
    (change)="onChange($event.target.value)"
  >
    <option value="-1"></option>
    <option
      *ngFor="let address of billingAddressList; let i = index"
      [value]="i"
      [selected]="address.id == selectedBuyerBillAddressId"
    >
      {{ address.city }} - {{ address.street }} - {{ address.zipCode }}
    </option>
  </select>
  <label
    class="placeholder"
    for="bilAddressSelect"
    data-translation="personalize.buyer-info.billing-address.input-placeholder"
  >
    {{ 'personalize.buyer-info.billing-address.input-placeholder' | translate | hideTranslation }}
  </label>
</div>

<form class="row hide-read-only" *ngIf="form" [ngClass]="{ readonly: !isEditEnabled }" [formGroup]="form" novalidate>
  <app-df-input
    *ngFor="let input of inputs"
    [input]="input"
    [class]="input.cssClass"
    [inputSet]="inputs"
    [action]="PersonaliseFormsKeys.billingAddress"
    [form]="form"
    [saveFormCallback]="formSaveCallback"
    [ngClass]="{ 'input-disabled': input.hidden }"
  >
  </app-df-input>
</form>
