import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  State,
  getBuyerActiveBillingAddressId,
  getBuyerVisitorCheckbox,
  getExhibitionSettings,
  getLanguage,
  getOrderUuid,
  getOrderedStepsValidityArray,
  getPaymentMethod,
  getProfile,
  getSelectedExhibitionId,
  getSelectedStep,
  getShoppingStartTime,
  getSteps,
  getStepsValidity,
  isDifferenBillingAddressUsed,
  isTicketHolderVisible
} from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookedProducts,
  BookingTariff,
  BookingTariffType,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { WorkshopId } from '@products/models/workshop.model';
import { HolderService } from '@products/services/holder.service';
import { WorkshopService } from '@products/services/workshop.service';
import { OrderModel, OrderPayloadModel, OrderTicketModel } from '@root/src/app/_pages/products/models/order.model';
import { TicketSendingOptions } from '@root/src/app/_pages/products/models/tariff.model';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { environment } from '@src/environments/environment';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { HelperService } from '@store/helpers/helper.service';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetIsAnonymousProductSentToAPI } from '@store/products/booking/booking.actions';
import { getTicketHolderQuestionnaireInputs } from '@store/products/holder/holder.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import {
  BuyerVisitorCheckboxModel,
  FormInputsPayloadModel,
  InputsListModel,
  VisibilityPayloadModel
} from '@store/step-forms/step.interface';
import { SetSelectedStep, SetStepOrder, SetStepsVisibility, sendOrder } from '@store/step-forms/steps-forms.actions';
import { UserProfileModel } from '@store/user/user.interface';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StepsFormsService {
  workshopsOnTicketSelection: boolean;
  isLegitimationRequired$ = new BehaviorSubject<boolean>(null);

  private readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<State>,
    private helperService: HelperService,
    private formsService: FormsService,
    private errorHandlingService: ErrorHandlingService,
    private legitimationService: LegitimationService,
    private workshopService: WorkshopService,
    private holderService: HolderService
  ) {
    // react on need for legitimation and hide/show the legitimation step
    this.setLegitimationStepVisibility();
    this.setLegitimationStepOrder();

    // react on need for workshops and hide/show the workshop step
    this.setWorkshopStepVisibility();
  }

  setLegitimationStepVisibility() {
    this.legitimationService
      .isLegitimationRequiredForBookedProducts$()
      .pipe(
        tap(isLegitimationRequired => this.isLegitimationRequired$.next(isLegitimationRequired)),
        takeUntil(this.destroy$)
      )
      .subscribe(isLegitimationRequired => {
        const visibilityPayload: VisibilityPayloadModel = {
          stepKey: 'legitimation',
          visible: isLegitimationRequired
        };

        this.store.dispatch(new SetStepsVisibility([visibilityPayload]));
      });
  }

  setLegitimationStepOrder() {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.legitimationService.isTariffWithRequiredLegitimationBooked$()
    ])
      .pipe(
        filter(([exhibitionSettings]) => !!exhibitionSettings),
        takeUntil(this.destroy$)
      )
      .subscribe(([exhibitionSettings, isTariffWithRequiredLegitimationBooked]) => {
        this.workshopsOnTicketSelection = exhibitionSettings.workshopsOnTicketSelection;

        const orderPayload: OrderPayloadModel = {
          stepKey: 'legitimation',
          newOrder: exhibitionSettings.goToLegitimationForNewAccount && !isTariffWithRequiredLegitimationBooked ? 1 : 3
        };

        this.store.dispatch(new SetStepOrder(orderPayload));
      });
  }

  setWorkshopStepVisibility() {
    this.workshopService
      .getBookedProductSelectionWorkshopTariffs$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(bookedProductSelectionWorkshopTariffs => {
        const visibilityPayload: VisibilityPayloadModel = {
          stepKey: 'workshop',
          visible: this.workshopsOnTicketSelection ? false : !!bookedProductSelectionWorkshopTariffs.length
        };

        if (!this.helperService.isSelfregistration()) {
          // as a fast solution we hide workshops on self registration (later this should come from event settings)
          this.store.dispatch(new SetStepsVisibility([visibilityPayload]));
        }
      });
  }

  sendFinalOrder(orderData: OrderModel) {
    let selfReg = '';
    if (this.helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    //clear all previously received errors from the API (so we don't interpret errors that occured earlier in the shopping process):
    this.errorHandlingService.clearAllErrors();

    return this.http.post(
      //`${environment.apiUrl}/buyer-questionnaire/${eventId}`,
      `${environment.protocol}${environment.webApiUrl}/order/save-order${selfReg}`,
      orderData,
      { observe: 'response' }
    );
  }

  getBuyerQuestionnaire(eventId, args: number[]) {
    let selfReg = '';
    if (this.helperService.isSelfregistration()) {
      selfReg = '&sr=true';
    }

    const ticketPersonIds = args.reduce((acc, curr) => {
      return acc + `&ticketPersonIds=${curr}`;
    }, '');

    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/buyer-questionnaire?${selfReg}${ticketPersonIds}`
    );
  }

  navigateRelativeTo(x: number, router) {
    combineLatest([
      this.store.pipe(select(getStepsValidity)),
      this.store.pipe(select(getSelectedStep)),
      this.store.pipe(select(getSelectedExhibitionId))
    ])
      .pipe(
        filter(([validations, activeStepKey, selectedEventId]) => {
          return !!validations && !!activeStepKey && selectedEventId !== null;
        }),
        first()
      )
      .subscribe(([validations, activeStepKey, selectedEventId]) => {
        const visibleSteps = Object.keys(validations)
          // make sure steps are ordered correctly
          .sort((a, b) => {
            return validations[a].order - validations[b].order;
          })
          // only navigate to visible routes
          .filter(stepKey => {
            return validations[stepKey].visible;
          });

        const indexOfStep = visibleSteps.indexOf(activeStepKey);

        if ((x < 0 && indexOfStep > 0) || (x > 0 && indexOfStep < visibleSteps.length - 1)) {
          this.checkAndRedirectToNextStep(
            validations,
            visibleSteps,
            selectedEventId,
            indexOfStep,
            x,
            router,
            activeStepKey
          );
        }
      });
  }

  checkAndRedirectToNextStep(validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey) {
    const urlBase = this.helperService.isSelfregistration() ? 'self-registration' : 'webshop';

    let navigateTo = visibleSteps[indexOfStep + x];

    if (navigateTo === 'payment') {
      console.log(
        '%c currentStep ',
        'background: #5AAC56; color: #fff',
        `/${urlBase}/${selectedEventId}/${activeStepKey}`
      );
      console.log(
        x === -1 ? '%c "back button" URL ' : '%c "continue button" URL ',
        'background: #D46A6A; color: #fff',
        `/${urlBase}/${selectedEventId}/${navigateTo}`
      );
    }

    if (!validations[navigateTo].showInStepNavigation) {
      const ticketStep = visibleSteps.find(step => step === 'tickets');
      navigateTo = validations[ticketStep].showInStepNavigation
        ? ticketStep
        : validations[visibleSteps[0]].showInStepNavigation
        ? visibleSteps[0]
        : 'tickets';

      console.log(
        '%c re-navigated URL ',
        'background: #6BA099; color: #fff',
        `/${urlBase}/${selectedEventId}/${navigateTo}`
      );
    }

    this.store.dispatch(new SetSelectedStep(navigateTo));
    router.navigate([`/${urlBase}/${selectedEventId}/${navigateTo}`]);
  }

  navigateToLastNotDisabledPage() {
    combineLatest([
      this.store.pipe(select(getOrderedStepsValidityArray)),
      this.store.pipe(select(getSelectedExhibitionId))
    ])
      .first()
      .subscribe(data => {
        const [orderedStepsValidityArray, eventId] = data;
        const lastNotDisabledPage = orderedStepsValidityArray
          .slice(0)
          .reverse()
          .find(step => {
            return step.value.disabled === false && step.value.visible && step.value && step.value.showInStepNavigation;
          });

        this.router.navigate([`/webshop/${eventId}/${lastNotDisabledPage.key}`]);
      });
  }

  //scroll to top
  scrollToTop() {
    window.scroll(0, 0);
  }

  removeAttributes(obj: Object, attributes: Array<string>) {
    let clearedObject = Object.assign({}, obj);
    attributes.forEach(attr => {
      if (clearedObject.hasOwnProperty(attr)) {
        delete clearedObject[attr];
      }
    });

    return clearedObject;
  }

  renameAttributes(obj: Object, attributes: Array<any>) {
    let renamedObject = Object.assign({}, obj);
    attributes.forEach(attr => {
      if (renamedObject.hasOwnProperty(attr.from)) {
        renamedObject[attr.to] = renamedObject[attr.from];
        delete renamedObject[attr.from];
      }
    });

    return renamedObject;
  }

  separateAddress(obj) {
    let clearedObject = Object.assign({}, obj);
    const toSeparate = ['company', 'street', 'city', 'zipCode', 'country'];

    clearedObject.address = {};
    toSeparate.forEach(key => {
      if (clearedObject.hasOwnProperty(key)) {
        clearedObject.address[key] = clearedObject[key];
      } else {
        // TODO once BE can accept only data which are really present delete else statement
        clearedObject.address[key] = '';
      }
    });

    clearedObject = this.removeAttributes(clearedObject, toSeparate);
    return clearedObject;
  }

  // TODO: This needs to be refactored sometime because of nested subscription
  prepareDataForSaveAndSend(
    bookedProducts: BookedProducts,
    totalCost: number,
    ticketHolderInputSets?: FormInputsPayloadModel[],
    anonymousTicket?: any
  ) {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getPaymentMethod)),
      this.store.pipe(select(isTicketHolderVisible)),
      this.store.pipe(select(getOrderUuid)),
      this.store.pipe(select(getShoppingStartTime))
    ])
      .pipe(
        filter((data: [ExhibitionSettingModel, string, boolean, string, Date]) => {
          const [settings, paymentMethod] = data;

          return anonymousTicket ? !!data : !!settings && paymentMethod !== null;
        }),
        first()
      )
      .subscribe(([_, paymentMethod, isTicketHolderVisible, uuid, shoppingStartTime]) => {
        combineLatest([
          this.store.pipe(select(getSteps)),
          this.store.pipe(select(getProfile)),
          this.store.pipe(select(getSelectedExhibitionId)),
          this.store.pipe(select(getBuyerActiveBillingAddressId)),
          this.store.pipe(select(getLanguage)),
          this.store.pipe(select(getSelectedSendingOption)),
          this.store.pipe(select(getBuyerVisitorCheckbox)),
          this.store.pipe(select(getTicketHolderQuestionnaireInputs))
        ])
          .pipe(
            filter((data: [any, UserProfileModel, number, number, string, string, BuyerVisitorCheckboxModel, any]) => {
              const [steps, profile, eventId] = data;

              return !!steps && !!eventId;
            }),
            first()
          )
          .subscribe(
            ([
              steps,
              profile,
              eventId,
              billingaddressId,
              language,
              selectedSendingOption,
              buyerVisitorCheckbox,
              ticketHolderQuestionnaireInputs
            ]) => {
              let dataToSave: OrderModel = {
                paymentProvider: paymentMethod,
                userAgent: this.helperService.browserInfo(),
                shoppingStartTime: shoppingStartTime,
                shoppingEndTime: new Date(),
                order: {
                  userId: profile ? profile.id : null
                },
                day: new Date() // xgebi Date should be chosen same as amano parking
              };

              dataToSave.order['eventId'] = eventId;
              dataToSave.order['uuid'] = uuid;
              dataToSave.order['parkingTickets'] = [];

              for (const stepKey in steps) {
                const forms: InputsListModel[] = steps[stepKey] ? steps[stepKey].forms : null;

                if (forms) {
                  for (const formKey in forms) {
                    let form: InputsListModel = forms[formKey];

                    if (form && form.list) {
                      // we need to ensure that we get values of all inputs, even those which are disabled
                      form.list.forEach((input: InputBase<any>) => {
                        input.disabled = false;
                      });

                      const formObj = this.formsService.toFormGroup(form.list, [formKey]);

                      // if it is not a ticket holder (ticket holder is comming together with tickets) add to to dataToSave
                      if (formObj && !(formKey.substring(0, 13) === 'ticketHolder_')) {
                        switch (formKey) {
                          // transform data from buyer questionnaire
                          case 'questionnaire': {
                            dataToSave.order.questionnaireFields = this.helperService.processQuestionnaireValuesBeforeSave(
                              form.list
                            );
                            break;
                          }

                          case 'billingaddress': {
                            dataToSave.order.billingAddress = this.helperService.processFormValuesBeforeSave(
                              formObj.value
                            );
                            break;
                          }

                          case 'buyerinfo': {
                            let buyer = this.helperService.processFormValuesBeforeSave(formObj.value);

                            const privacyForm = forms['privacy'];

                            // set privacy policy value
                            if (privacyForm) {
                              const privacyPolicyBase = privacyForm.list.find(
                                (inputBase: InputBase<any>) => inputBase.key === 'disclaimer'
                              );

                              const privacyPolicyOption =
                                privacyPolicyBase &&
                                privacyPolicyBase.options.find(option => option.key === 'disclaimerConfirmation');

                              if (privacyPolicyOption) {
                                buyer['PrivacyPolicyAccepted'] = privacyPolicyOption.value;
                              }

                              const privacyPolicyOptionalBase = privacyForm.list.find(
                                (inputBase: InputBase<any>) => inputBase.key === 'disclaimerOptional'
                              );

                              const privacyPolicyOptionalOption =
                                privacyPolicyOptionalBase &&
                                privacyPolicyOptionalBase.options.find(
                                  option => option.key === 'disclaimerOptionalConfirmation'
                                );

                              if (privacyPolicyOptionalOption) {
                                buyer['PrivacyPolicyOptionalAccepted'] = privacyPolicyOptionalOption.value;
                              }
                            }

                            buyer = this.removeAttributes(buyer, [
                              'password',
                              'verifyPassword',
                              'CreateAccount',
                              'DifferentBillingAddress',
                              'verifyEmail',
                              'createAccountButton',
                              'address'
                            ]);

                            buyer = this.renameAttributes(buyer, [
                              {
                                from: 'FairCatalogue',
                                to: 'isFairCatalogChecked'
                              },
                              { from: 'Newsletter', to: 'isNewsletterChecked' }
                            ]);

                            buyer = this.separateAddress(buyer);

                            dataToSave.order['buyer'] = buyer;

                            break;
                          }

                          // privacy policy (value processed in buyer)
                          case 'privacy':
                            break;

                          // terms and conditions
                          case 'checkboxes':
                            const termsBase = form.list.find((term: InputBase<any>) => term.key === 'terms');

                            const tradeConditionOption =
                              termsBase && termsBase.options.find(option => option.key === 'tradeConditions');

                            if (tradeConditionOption) {
                              dataToSave.order['TermsAndConditionsAccepted'] = tradeConditionOption.value;
                            }
                            break;

                          // transform any other data
                          default: {
                            dataToSave.order[formKey] = this.helperService.processFormValuesBeforeSave(formObj.value);
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }

              // check if billing address is needed at all
              this.store
                .pipe(
                  select(isDifferenBillingAddressUsed),
                  first()
                )
                .subscribe(isDifferentBillingAddressUsed => {
                  if (!isDifferentBillingAddressUsed) {
                    delete dataToSave.order.billingAddress;
                  }
                });

              const ticketsWithHolders: OrderTicketModel[] = [];

              bookedProducts.forEach(bookedProduct => {
                if (isBookingProductTypeTariff(bookedProduct)) {
                  this.addBookedTariffsAndParkingReservationsToSaveOrder(
                    bookedProduct,
                    ticketHolderInputSets,
                    buyerVisitorCheckbox,
                    ticketsWithHolders,
                    ticketHolderQuestionnaireInputs,
                    isTicketHolderVisible,
                    dataToSave
                  );
                } else if (isBookingProductTypePackage(bookedProduct)) {
                  bookedProduct.productTypes.forEach(packageProductType => {
                    if (isBookingProductTypeTariff(packageProductType)) {
                      this.addBookedTariffsAndParkingReservationsToSaveOrder(
                        packageProductType,
                        ticketHolderInputSets,
                        buyerVisitorCheckbox,
                        ticketsWithHolders,
                        ticketHolderQuestionnaireInputs,
                        isTicketHolderVisible,
                        dataToSave
                      );
                    }
                  });
                }
              });

              dataToSave.order.tickets = ticketsWithHolders;
              dataToSave.order.orderPrice = +totalCost.toFixed(2);
              dataToSave.order.orderTicketSendingMode = selectedSendingOption;
              dataToSave.order.userLanguage = language;

              /* remove unwanted properties */

              if (dataToSave.order.hasOwnProperty('billingAddress')) {
                delete dataToSave.order.billingAddress.address;

                // in case buyer used billing address from profile of logged in user add it to billing address
                if (billingaddressId !== null) {
                  dataToSave.order.billingAddress.id = billingaddressId;
                }
              }

              if (dataToSave.order.hasOwnProperty('buyerinfo')) {
                delete dataToSave.order['buyerinfo']['address'];
                delete dataToSave.order['buyerinfo']['createAccountButton'];
              }

              if (anonymousTicket) {
                dataToSave.order.buyer.isNewsletterChecked = false;
                dataToSave.paymentProvider = 'FREE';
                this.store.dispatch(new SetIsAnonymousProductSentToAPI(true));
              }

              this.store.dispatch(new sendOrder(dataToSave));

              console.log(
                JSON.stringify(dataToSave)
                  .replace(/“([^“]*?)“:/g, '$1:')
                  .replace(/“/g, "'")
              );
            }
          );
      });
  }

  addBookedTariffsToSaveOrder(
    bookedTariff: BookingTariff,
    ticketHolderInputSets: FormInputsPayloadModel[],
    buyerVisitorCheckbox: BuyerVisitorCheckboxModel,
    clearedTicketsWithHolders: OrderTicketModel[],
    ticketHolderQuestionnaireInputs: InputBase<any>[],
    isTicketHolderVisible: boolean,
    buyerInfoEmail: string
  ) {
    const {
      ticketTypeId,
      ticketPersonTypeId,
      ticketPersonId,
      voucherCode,
      packageNumber,
      packageIndex,
      holderUuids,
      contingentReservations,
      workshops
    } = bookedTariff;

    holderUuids.forEach(holderUuid => {
      let ticketHolder = this.holderService.getHolderDataForSaveOrder(
        ticketHolderInputSets,
        holderUuid,
        buyerInfoEmail
      );

      let sendingOption = ticketHolder.sendingOption;

      if (ticketHolder) {
        if (buyerVisitorCheckbox && buyerVisitorCheckbox.buyerVisitorCheckedSlideIndex === ticketHolder.ticketIndex) {
          ticketHolder.isBuyerAVisitor = buyerVisitorCheckbox.isBuyerVisitorChecked;
          ticketHolder.visitorQuestionnaire = this.helperService.processQuestionnaireValuesBeforeSave(
            ticketHolderQuestionnaireInputs
          );
        }

        const tickerToOwner = ticketHolder.sendtoowner;

        ticketHolder = this.removeAttributes(ticketHolder, ['ticketIndex', 'address', 'sendtoowner', 'sendingOption']);
        ticketHolder = this.separateAddress(ticketHolder);

        if (sendingOption === TicketSendingOptions.MobilePerOwner && !tickerToOwner) {
          sendingOption = TicketSendingOptions.MobilePerBuyer;
        }

        if (sendingOption === TicketSendingOptions.TicketRetrievalLink && !tickerToOwner) {
          sendingOption = TicketSendingOptions.TicketRetrievalLinkBuyer;
        }

        const contingentReservation = contingentReservations.find(
          contingentReservation => contingentReservation.holderUuid === holderUuid
        );

        const workshopIds: WorkshopId[] = [];

        workshops.forEach(({ id, holderUuids }) => {
          const bookedWorkshopTariff = holderUuids.some(
            bookedWorkshopHolderUuid => bookedWorkshopHolderUuid === holderUuid
          );

          if (bookedWorkshopTariff) {
            workshopIds.push(id);
          }
        });

        const orderTicket: OrderTicketModel = {
          groupId: ticketTypeId,
          ticketTypeId: ticketPersonTypeId,
          ticketPersonId: ticketPersonId,
          ticketToOwner: tickerToOwner,
          ticketSendingMode: sendingOption,
          ticketHolder: isTicketHolderVisible ? ticketHolder : null,
          asMobileTicket:
            sendingOption === TicketSendingOptions.MobilePerOwner ||
            sendingOption === TicketSendingOptions.MobilePerBuyer,
          workshops: workshopIds,
          day: contingentReservation ? DateTime.fromJSDate(contingentReservation.fromDate).toISODate() : null
        };

        if (packageNumber) {
          orderTicket.packageNumber = packageNumber;
          orderTicket.shoppingCartPkgIndex = packageIndex;
        }

        if (voucherCode) {
          orderTicket.voucherCode = voucherCode;
        }

        clearedTicketsWithHolders.push(orderTicket);
      }
    });
  }

  private addBookedTariffsAndParkingReservationsToSaveOrder(
    bookedTariffType: BookingTariffType,
    ticketHolderInputSets: FormInputsPayloadModel[],
    buyerVisitorCheckbox: BuyerVisitorCheckboxModel,
    ticketsWithHolders: OrderTicketModel[],
    ticketHolderQuestionnaireInputs: InputBase<any>[],
    isTicketHolderVisible: boolean,
    dataToSave: OrderModel
  ) {
    bookedTariffType.tariffs.forEach(bookedTariff => {
      const { ticketTypeId, ticketPersonTypeId } = bookedTariff;

      bookedTariff.parkingReservations.forEach(parking => {
        const { since, until } = parking;

        dataToSave.order.parkingTickets.push({
          groupId: ticketTypeId,
          ticketTypeId: ticketPersonTypeId,
          since: this.helperService.toStringWithoutOffset(since),
          until: this.helperService.toStringWithoutOffset(until)
        });
      });

      this.addBookedTariffsToSaveOrder(
        bookedTariff,
        ticketHolderInputSets,
        buyerVisitorCheckbox,
        ticketsWithHolders,
        ticketHolderQuestionnaireInputs,
        isTicketHolderVisible,
        dataToSave.order.buyer.email
      );
    });
  }
}
