import { getExhibitionSettings } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { VoucherType } from '@products/models/voucher.model';
import { getBookedVoucherCountSortedByVoucherType } from '@store/products/booking/booking.selectors';
import { isRedeemedAnonymousVoucherProductInProductSelectionList } from '@store/products/product-selection/product-selection.selectors';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var VoucherValidationService = /** @class */ (function () {
    function VoucherValidationService(store) {
        this.store = store;
    }
    VoucherValidationService.prototype.validateRedeemedVoucherTypeCount$ = function (redeemedVoucherType, redeemedVoucherCount, isRedeemedVoucherAnonymous) {
        return combineLatest([
            this.store.pipe(select(getBookedVoucherCountSortedByVoucherType)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
        ]).pipe(first(function (_a) {
            var bookedVoucherCountSortedByVoucherType = _a[0], exhibitionSettings = _a[1];
            return !!bookedVoucherCountSortedByVoucherType && !!exhibitionSettings;
        }), map(function (_a) {
            var bookedVoucherCountSortedByVoucherType = _a[0], exhibitionSettings = _a[1], isRedeemedAnonymousVoucherProductInProductSelectionList = _a[2];
            var _b;
            if (isRedeemedVoucherAnonymous && !isRedeemedAnonymousVoucherProductInProductSelectionList) {
                return redeemedVoucherCount;
            }
            var voucherTypeLimits = (_b = {},
                _b[VoucherType.PromoCode] = exhibitionSettings.limitPromoCodes,
                _b[VoucherType.LimiterPromoCode] = exhibitionSettings.limitLimitedPromocodes,
                _b[VoucherType.OneTimeVoucher] = Infinity,
                _b);
            var bookedAndRedeemedVoucherTypeCount = bookedVoucherCountSortedByVoucherType[redeemedVoucherType] + redeemedVoucherCount;
            var isVoucherTypeCountOverLimit = bookedAndRedeemedVoucherTypeCount > voucherTypeLimits[redeemedVoucherType];
            if (isVoucherTypeCountOverLimit) {
                var voucherTypeOverLimitCount = bookedAndRedeemedVoucherTypeCount - voucherTypeLimits[redeemedVoucherType];
                var voucherTypeValidatedCount = redeemedVoucherCount - voucherTypeOverLimitCount;
                return voucherTypeValidatedCount;
            }
            return redeemedVoucherCount;
        }));
    };
    VoucherValidationService.ngInjectableDef = i0.defineInjectable({ factory: function VoucherValidationService_Factory() { return new VoucherValidationService(i0.inject(i1.Store)); }, token: VoucherValidationService, providedIn: "root" });
    return VoucherValidationService;
}());
export { VoucherValidationService };
