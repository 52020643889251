import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import { AppConstants } from '../../shared/app-constants';
import { FormsService } from '../../shared/forms/forms.service';
import { GtmService } from '../../shared/gtm/gtmService';
import { FilterPlaceholderPipe } from '../../shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { GetQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';
import { getSelectedSendingOption } from '../../shared/services-with-reducers/products/product-selection/product-selection.selectors';
import { TicketSendingOptions } from '../products/models/tariff.model';
var WebShopPersonalizeComponent = /** @class */ (function () {
    function WebShopPersonalizeComponent(store, formsService, helperService, gtmService, translateService, filterPlaceholderPipe) {
        this.store = store;
        this.formsService = formsService;
        this.helperService = helperService;
        this.gtmService = gtmService;
        this.translateService = translateService;
        this.filterPlaceholderPipe = filterPlaceholderPipe;
        this.TicketSendingOptions = TicketSendingOptions;
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
        this.isInputChanged$ = new Subject();
        this.subscriptions = new Subscription();
        this.buyerStyle = null;
        this.ticketHolderInputsNotVisible = false;
        this.modalWindowOpen = false;
        this.questionnaireLoaded = false;
        this.showVisitorQuestionnaire = false;
        this.feedbackControlObject = {};
    }
    WebShopPersonalizeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isSelfRegistrationEnabled = this.helperService.isSelfregistration();
        this.exhibitionSettings$ = this.store.pipe(select(fromRoot.getExhibitionSettings));
        this.isTicketHolderVisible$ = this.store.pipe(select(fromRoot.isTicketHolderVisible));
        this.showLoginOnPersonalPage$ = this.store.pipe(select(fromRoot.showLoginOnPersonalPage));
        this.showLoginOnTicketAndPersonalPage$ = this.store.pipe(select(fromRoot.showLoginOnTicketAndPersonalPage));
        this.showLoginOnTicketPage$ = this.store.pipe(select(fromRoot.showLoginOnTicketPage));
        this.selectedSendingOption$ = this.store.pipe(select(getSelectedSendingOption));
        this.showLoginOnTicketOrTicketAndPersonalPage$ = combineLatest([
            this.showLoginOnPersonalPage$,
            this.showLoginOnTicketAndPersonalPage$
        ]);
        this.showLoginFormOrValidationFeedbackMessages = combineLatest([
            this.store.pipe(select(fromRoot.isConfirmedEmailRequiredOnPersonalization)),
            this.showLoginOnTicketOrTicketAndPersonalPage$
        ]);
        this.subscriptions.add(this.showLoginFormOrValidationFeedbackMessages.subscribe(function (_a) {
            var isConfirmedEmailRequiredOnPersonalization = _a[0], _b = _a[1], showLoginOnPersonalPage = _b[0], showLoginOnTicketAndPersonalPage = _b[1];
            _this.isConfirmedEmailRequiredOnPersonalization = isConfirmedEmailRequiredOnPersonalization;
            var showLogin = isConfirmedEmailRequiredOnPersonalization || showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage;
            if (showLogin) {
                _this.formsService.removeAllStepValidationFeedbacks(_this.PersonaliseFormsKeys.registration);
                if (!isConfirmedEmailRequiredOnPersonalization) {
                    _this.formsService.addStepValidationFeedback(_this.PersonaliseFormsKeys.registration, 'anyBuyerMessageHidden', 'personalize.any.buyer.message.hidden');
                }
            }
            else {
                _this.formsService.removeStepValidationFeedback(_this.PersonaliseFormsKeys.registration, 'anyBuyerMessageHidden');
            }
            _this.formsService.setFormValidity(!showLogin, null, _this.PersonaliseFormsKeys.registration);
        }));
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this.store.pipe(select(getTicketHolderQuestionnaireInputs)),
            this.selectedSendingOption$
        ])
            .pipe(first(function (_a) {
            var _ = _a[0], ticketHolderQuestionnaireInputs = _a[1], sendingOption = _a[2];
            return (ticketHolderQuestionnaireInputs === null || !ticketHolderQuestionnaireInputs.length) &&
                sendingOption === TicketSendingOptions.TicketRetrievalLink;
        }))
            .subscribe(function (_a) {
            var currentEventId = _a[0];
            _this.store.dispatch(new GetQuestionnaireInputs({ eventId: currentEventId, ticketPersonIds: [] }));
        }));
        combineLatest([
            this.store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this.isTicketHolderVisible$,
            this.exhibitionSettings$
        ])
            .first()
            .subscribe(function (_a) {
            var ticketHolderInputSets = _a[0], isTicketHolderVisible = _a[1], settings = _a[2];
            if (!isTicketHolderVisible && ticketHolderInputSets.length) {
                _this.formsService.setStepValid(ticketHolderInputSets[0].formInfo);
            }
            if (ticketHolderInputSets && ticketHolderInputSets.length === 0) {
                _this.ticketHolderInputsNotVisible = true;
                return;
            }
            _this.ticketHolderInputsNotVisible = Object.keys(settings.ticketOwnerSettings.fieldSettings).every(function (ticketHolderInputSetting) {
                return settings.ticketOwnerSettings.fieldSettings[ticketHolderInputSetting].isVisible === false;
            });
            // Make ticketHolderInputs Visible if email or verifyEmail Fields are mandatory on any of the ticket holders
            if (_this.ticketHolderInputsNotVisible) {
                _this.ticketHolderInputsNotVisible = !ticketHolderInputSets.some(function (element) {
                    if (!!element.inputSet && !!element.inputSet.list) {
                        var holderList = element.inputSet.list;
                        var emailField = holderList.find(function (item) { return item.key === 'email'; });
                        var verifyEmailField = holderList.find(function (item) { return item.key === 'verifyEmail'; });
                        var emailRequired = !!emailField ? emailField.required : false;
                        var verifyEmailRequired = !!verifyEmailField ? verifyEmailField.required : false;
                        return emailRequired || verifyEmailRequired;
                    }
                });
            }
        });
        this.subscriptions.add(this.store
            .pipe(select(fromRoot.isContinueAsGuest))
            .subscribe(function (isContinueAsGuest) { return (_this.continueAsGuest = isContinueAsGuest); }));
        this.subscriptions.add(this.store
            .pipe(select(fromRoot.getQuestionnaire), filter(function (data) {
            // if there are no data for questionnaire, load them
            if (data.list === null) {
                _this.helperService.loadBuyerQuestionnaireViaApi('personal');
                return false;
            }
            var questionnaireLoaded = data.list.length > 0;
            if (!questionnaireLoaded || _this.helperService.isSelfregistration()) {
                _this.formsService.setStepValid(_this.PersonaliseFormsKeys.buyerQuestionnaire);
            }
            return questionnaireLoaded;
        }))
            .subscribe(function () { return (_this.questionnaireLoaded = true); }));
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(fromRoot.getPrivacyInput)),
            this.showLoginFormOrValidationFeedbackMessages
        ]).subscribe(function (_a) {
            var checkboxes = _a[0], _b = _a[1], isConfirmedEmailRequiredOnPersonalization = _b[0], _c = _b[1], showLoginOnPersonalPage = _c[0], showLoginOnTicketAndPersonalPage = _c[1];
            if (!isConfirmedEmailRequiredOnPersonalization &&
                !showLoginOnPersonalPage &&
                !showLoginOnTicketAndPersonalPage &&
                checkboxes &&
                checkboxes.list.length) {
                if (checkboxes || !_this.checkboxesForm) {
                    _this.checkboxesInputs = checkboxes.list.slice(0);
                    _this.checkboxesForm = _this.formsService.toFormGroup(checkboxes.list, _this.PersonaliseFormsKeys.privacy);
                }
            }
            else {
                // if there is no privacy input to show we need to mark the form as valid
                _this.formsService.setFormValidity(true, null, _this.PersonaliseFormsKeys.privacy);
            }
        }));
        this.selectedSendingOption$.subscribe(function (selectedOption) { return (_this.selectedSendingOption = selectedOption); });
        this.subscriptions.add(combineLatest([
            this.showLoginOnTicketOrTicketAndPersonalPage$,
            this.store.pipe(select(fromRoot.isUserLoggedIn))
        ]).subscribe(function (_a) {
            var _b = _a[0], showLoginOnPersonalPage = _b[0], showLoginOnTicketAndPersonalPage = _b[1], isLogged = _a[1];
            if (!isLogged && (showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage)) {
                _this.buyerStyle = { display: 'none' };
            }
            else {
                _this.buyerStyle = null;
            }
        }));
        // US2870 - set initial
        this.subscriptions.add(this.store
            .pipe(select(fromRoot.getBuyerVisitorCheckbox), filter(function (data) { return !!data; }))
            .subscribe(function (_a) {
            var showVisitorQuestionnaire = _a.showVisitorQuestionnaire;
            return (_this.showVisitorQuestionnaire = showVisitorQuestionnaire);
        }));
    };
    WebShopPersonalizeComponent.prototype.ngAfterViewInit = function () {
        this.gtmService.pushPersonalization();
    };
    WebShopPersonalizeComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    WebShopPersonalizeComponent.prototype.setVisitorQuestionnaireValidity = function (validityData) {
        var _this = this;
        var formName = validityData.formName, valid = validityData.valid, inputs = validityData.inputs, form = validityData.form;
        this.formsService.setFormValidity(valid, form, this.PersonaliseFormsKeys.visitorQuestionnaire);
        if (inputs && form) {
            // update object holding invalid inputs
            this.feedbackControlObject = this.formsService.generateValidationFeedback(inputs, form, formName, this.feedbackControlObject);
            // US2870 - first remove all previous feedback messages
            this.formsService.removeAllStepValidationFeedbacks(this.PersonaliseFormsKeys.visitorQuestionnaire);
            // US2870 - add feedback messages for those fields which are required
            Object.keys(this.feedbackControlObject).forEach(function (key) {
                var feedbackObject = _this.feedbackControlObject[key];
                _this.formsService.addStepValidationFeedback(_this.PersonaliseFormsKeys.visitorQuestionnaire, feedbackObject.label, feedbackObject.label);
            });
        }
    };
    WebShopPersonalizeComponent.prototype.allowAdditionalDataToShow = function (isAdditionalData) {
        this.showVisitorQuestionnaire = isAdditionalData;
        if (isAdditionalData) {
            this.helperService.checkQuestionnairesForDuplicates();
        }
    };
    WebShopPersonalizeComponent.prototype.inputChanged = function (event) {
        this.isInputChanged$.next(event);
    };
    WebShopPersonalizeComponent.prototype.openIframe = function ($event, url) {
        $event.preventDefault();
        this.helperService.openIframe(url);
    };
    WebShopPersonalizeComponent.prototype.openPrivacyModalWindow = function (url) {
        var translatedValue = this.translateService.instant('personalize.privacy-link');
        var title = this.filterPlaceholderPipe.transform(translatedValue);
        this.openModalWindow(url, title, false);
    };
    WebShopPersonalizeComponent.prototype.openModalWindow = function (url, title, translateTitle) {
        if (translateTitle === void 0) { translateTitle = true; }
        this.modalWindowOpen = true;
        this.modalUrl = url;
        this.modalTitle = title;
        this.modalTranslateTitle = translateTitle;
    };
    WebShopPersonalizeComponent.prototype.closeModalWindow = function (event) {
        event.stopPropagation();
        this.modalWindowOpen = false;
    };
    WebShopPersonalizeComponent.prototype.closeModalWindowOnRightClick = function (event) {
        event.stopPropagation();
        this.modalWindowOpen = false;
    };
    return WebShopPersonalizeComponent;
}());
export { WebShopPersonalizeComponent };
