export interface LegitimationListModel {
  id: number;
  date: number;
  type: string;
  event: string;
  isActive: boolean;
  location: string;
}

export enum LegitimationStatus {
  New = 'new',
  InProgress = 'inProgress',
  Approved = 'approved',
  Rejected = 'rejected',
  OtherStatus = 'otherStatus'
}

export interface LegitimationStatusResponse {
  status: LegitimationStatus;
}

export interface LegitimationFaxInfoModel {
  faxId: number;
}

export type legitimationType = 'file' | 'fax' | 'url' | 'noFile';

export interface LegitimationPostModel {
  userLanguage: string;
  legitimationType: legitimationType;
  comment: string;
  url: string;
  fileId: Array<number>;
  faxId: number;
}
