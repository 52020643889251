import { filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../app.reducer';

import { FormGroup } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter
} from '@angular/core';
import { FormInputsPayloadModel } from '../../../../shared/services-with-reducers/step-forms/step.interface';

import { FormsService } from '../../../../shared/forms/forms.service';
import { InputBase } from '../../../../shared/forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { AppConstants } from '../../../../shared/app-constants';

@Component({
  moduleId: module.id,
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() formPath: string[];
  @Output() updatedTicketHolderInputs = new EventEmitter<{ inputs: InputBase<any>[], currentIndex: number }>();
  @Output() handleFormValueChange = new EventEmitter<any>();

  formChangeSubscribe: Subscription;
  inputs: InputBase<any>[];
  form: FormGroup;
  holderUuid: string;
  countries;

  readonly unsubscribe = new Subject<void>();
  readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;

  constructor(
    private formsService: FormsService,
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.holderUuid = !!this.formPath && this.formPath.length > 1 && this.formPath[1].includes(this.PersonaliseFormsKeys.ticketHolder[1])
      ? this.formPath[1].replace(this.PersonaliseFormsKeys.ticketHolder[1], '')
      : '';

    this.store
      .pipe(select(fromRoot.getAllCountriesList), first()).subscribe(list => {
        this.formsService.translateCountries(list).subscribe(subs => {
          subs.subscribe(countries => {
            this.countries = countries;
          });
        });
      });

    combineLatest([
      this.store.pipe(select(fromRoot.getTicketHolderInputSets)),
      this.store.pipe(select(fromRoot.uniqueVisitorCheckType)),
      this.store.pipe(select(fromRoot.getSkipHoldersCheck))
    ])
    .pipe(
      takeUntil(this.unsubscribe),
      filter(
        ([ticketHolderInputSets, uniqueVisitorCheckType, skipHoldersCheck]) =>
          !!ticketHolderInputSets && !!uniqueVisitorCheckType && !skipHoldersCheck
      )
    )
    .subscribe(
      ([ticketHolderInputSets]: [FormInputsPayloadModel[], string, boolean]) => {
        const thisFormInputs = ticketHolderInputSets.find(inputSet => {
          return inputSet.formInfo.join('.') === this.formPath.join('.');
        });

        if (
          thisFormInputs &&
          thisFormInputs.inputSet &&
          thisFormInputs.inputSet.list.length
        ) {

          if (!!this.countries) {
            thisFormInputs.inputSet.list.forEach(item => {
              if (item.key === "country") {
                item.options = this.countries;
              }
            });
          }

          this.inputs = this.formsService.updateInputs(
            this.inputs,
            thisFormInputs.inputSet.list
          );

          this.updatedTicketHolderInputs.emit({inputs: this.inputs, currentIndex: ticketHolderInputSets.indexOf(thisFormInputs)});
          if (thisFormInputs.inputSet.rerender || !this.form) {
            this.form = this.formsService.toFormGroup(
              thisFormInputs.inputSet.list,
              this.formPath
            );
          }
        }

        if (this.formChangeSubscribe) {
          this.formChangeSubscribe.unsubscribe();
          this.formChangeSubscribe = null;
        }

        this.formChangeSubscribe = this.form.valueChanges
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => this.handleFormValueChange.emit(this));
      }
    );
  }

  ngOnDestroy() {
    // once removed from screen, remove the validation
    this.formsService.removeFormsValidationFeedback(this.formPath);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
