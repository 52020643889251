import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { BannerComponent } from '@app/banner/banner.component';
import { InfoBlockComponent } from '@app/info-block/info-block.component';
import { LoaderComponent } from '@app/loader/loader.component';
import { MissingTranslationHandler, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { WorkshopVisitorsCountComponent } from '@pages/web-shop-workshop/workshop-visitors-count/workshop-visitors-count.component';
import { WorkshopsVisitorsComponent } from '@pages/web-shop-workshop/workshops-visitors/workshops-visitors.component';
import { ProductGroupComponent } from '@products/components/product-group/product-group.component';
import { PackageCounterComponent } from '@products/components/product/package-type/package-counter/package-counter.component';
import { PackageTypeComponent } from '@products/components/product/package-type/package-type.component';
import { PackageComponent } from '@products/components/product/package-type/package/package.component';
import { ProductComponent } from '@products/components/product/product.component';
import { TariffTypeComponent } from '@products/components/product/tariff-type/tariff-type.component';
import { ContingentTariffComponent } from '@products/components/product/tariff-type/tariff/contingent-tariff/contingent-tariff.component';
import { ParkingTariffComponent } from '@products/components/product/tariff-type/tariff/parking-tariff/parking-tariff.component';
import { TariffCounterComponent } from '@products/components/product/tariff-type/tariff/tariff-counter/tariff-counter.component';
import { TariffComponent } from '@products/components/product/tariff-type/tariff/tariff.component';
import { WorkshopTariffComponent } from '@products/components/product/tariff-type/tariff/workshop-tariff/workshop-tariff.component';
import { SendingOptionsComponent } from '@products/components/sending-options/sending-options.component';
import { WalkThruInfoComponent } from '@products/components/walk-thru-info/walk-thru-info.component';
import { FormatPricePipe } from '@root/src/app/shared/pipes/format-price/format-price.pipe';
import { AddressAutocompleteDirective } from '@shared/directives/address-autocomplete/address-autocomplete.directive';
import { AdjustSideHeadlineDirective } from '@shared/directives/adjust-side-headline/adjust-side-headline.directive';
import { CalendarCustomDefaultsDirective } from '@shared/directives/calendar-custom-defaults/calendar-custom-defaults.directive';
import { ClickOutsideDirective } from '@shared/directives/click-outside/click-outside.directive';
import { NumbersOnlyDirective } from '@shared/directives/numbers/numbers.directive';
import { OneTimeDirective } from '@shared/directives/one-time/one-time.directive';
import { RichTextDirective } from '@shared/directives/rich-text/rich-text.directive';
import { ShowPasswordDirective } from '@shared/directives/show-password/show-password.directive';
import { MissingTranslation } from '@shared/missing-translation/missing-translation';
import { MissingTranslationService } from '@shared/missing-translation/missing-translation.service';
import { ModalWindowComponent } from '@shared/modal-window/modal-window.component';
import { FilterPlaceholderPipe } from '@shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { HideTranslationPipe } from '@shared/pipes/hide-translation/hide-translation.pipe';
import { HtmlInjectorPipe } from '@shared/pipes/html-injector/html-injector.pipe';
import { IsHiddenTranslationPipe } from '@shared/pipes/is-hidden-translation/is-hidden-translation.pipe';
import { LocalizedDatePipe } from '@shared/pipes/localized-date/localized-date.pipe';
import { SafeResourcePipe } from '@shared/pipes/safe-resource/safe-resource.pipe';
import { SkeletonLoaderComponent } from '@shared/skeleton-loader/skeleton-loader.component';
import { CustomParser } from '@shared/translate-parser/translate-parser';
import { NavigationTabsComponent } from '@src/app-shared/navigation-tabs/navigation-tabs.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    MatExpansionModule,
    TranslateModule.forChild({
      parser: { provide: TranslateParser, useClass: CustomParser },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslation,
        deps: [MissingTranslationService]
      }
    }),
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  declarations: [
    BannerComponent,
    NavigationTabsComponent,
    InfoBlockComponent,
    SendingOptionsComponent,
    LoaderComponent,
    HtmlInjectorPipe,
    HideTranslationPipe,
    IsHiddenTranslationPipe,
    FormatPricePipe,
    LocalizedDatePipe,
    SafeResourcePipe,
    FilterPlaceholderPipe,
    ProductGroupComponent,
    SkeletonLoaderComponent,
    ProductComponent,
    TariffTypeComponent,
    PackageTypeComponent,
    TariffComponent,
    ContingentTariffComponent,
    ParkingTariffComponent,
    WorkshopTariffComponent,
    PackageCounterComponent,
    PackageComponent,
    TariffCounterComponent,
    WorkshopVisitorsCountComponent,
    WorkshopsVisitorsComponent,
    ModalWindowComponent,
    WalkThruInfoComponent,
    AddressAutocompleteDirective,
    AdjustSideHeadlineDirective,
    CalendarCustomDefaultsDirective,
    RichTextDirective,
    ClickOutsideDirective,
    NumbersOnlyDirective,
    OneTimeDirective,
    ShowPasswordDirective
  ],
  providers: [],
  exports: [
    TranslateModule,
    ColorPickerModule,
    MatExpansionModule,
    BannerComponent,
    NavigationTabsComponent,
    InfoBlockComponent,
    SendingOptionsComponent,
    LoaderComponent,
    HtmlInjectorPipe,
    HideTranslationPipe,
    IsHiddenTranslationPipe,
    FormatPricePipe,
    LocalizedDatePipe,
    SafeResourcePipe,
    FilterPlaceholderPipe,
    MatTooltipModule,
    ProductGroupComponent,
    SkeletonLoaderComponent,
    ProductComponent,
    TariffTypeComponent,
    PackageTypeComponent,
    TariffComponent,
    ContingentTariffComponent,
    ParkingTariffComponent,
    WorkshopTariffComponent,
    PackageCounterComponent,
    PackageComponent,
    TariffCounterComponent,
    WorkshopVisitorsCountComponent,
    WorkshopsVisitorsComponent,
    ModalWindowComponent,
    WalkThruInfoComponent,
    AddressAutocompleteDirective,
    AdjustSideHeadlineDirective,
    CalendarCustomDefaultsDirective,
    RichTextDirective,
    ClickOutsideDirective,
    NumbersOnlyDirective,
    OneTimeDirective,
    ShowPasswordDirective
  ]
})
export class SharedModule {}
