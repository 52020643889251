import { OnDestroy } from '@angular/core';
import { getExhibitionSettings, getSelfRegistration } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { WorkshopValidationService } from '@products/services/workshop-validation.service';
import { WorkshopService } from '@products/services/workshop.service';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { HelperService } from '@store/helpers/helper.service';
import { areBookedContingentReservationsValid, areBookedParkingReservationsValid, getAllBookedTariffs, isBookedContingentReservationFromDateSetAndInvalid, isBookedProductsCountValid } from '@store/products/booking/booking.selectors';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i3 from "../../../shared/forms/forms.service";
import * as i4 from "./workshop.service";
import * as i5 from "./workshop-validation.service";
var ProductSelectionValidationService = /** @class */ (function () {
    function ProductSelectionValidationService(store, helperService, formsService, workshopService, workshopValidationService) {
        this.store = store;
        this.helperService = helperService;
        this.formsService = formsService;
        this.workshopService = workshopService;
        this.workshopValidationService = workshopValidationService;
        this.validationState = {
            isBookedProductsCountValid: false,
            areBookedContingentReservationsValid: false,
            areBookedParkingReservationsValid: false,
            isWorkshopsMandatoryValid: false,
            isWorkshopsZeroPriceMandatoryValid: false
        };
        this.destroy$ = new Subject();
    }
    ProductSelectionValidationService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    ProductSelectionValidationService.prototype.setBuyerQuestionnaireAndFormValidity$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSelfRegistration)),
            this.store.pipe(select(getAllBookedTariffs))
        ]).pipe(skip(1), map(function (_a) {
            var isSelfRegistration = _a[0];
            var buyerQuestionnaire = AppConstants.PersonaliseFormsKeys.buyerQuestionnaire;
            var personal = buyerQuestionnaire[0], questionnaire = buyerQuestionnaire[1];
            var buyerQuestionnaireStepName = isSelfRegistration ? questionnaire : personal;
            _this.formsService.setFormValidity(false, null, buyerQuestionnaire);
            _this.helperService.loadBuyerQuestionnaireViaApi(buyerQuestionnaireStepName);
        }));
    };
    ProductSelectionValidationService.prototype.setProductSelectionValidationState$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(isBookedProductsCountValid)),
            this.store.pipe(select(isBookedContingentReservationFromDateSetAndInvalid)),
            this.store.pipe(select(areBookedContingentReservationsValid)),
            this.store.pipe(select(areBookedParkingReservationsValid)),
            this.workshopService.getBookedProductSelectionWorkshopTariffs$()
        ]).pipe(filter(function (_a) {
            var getExhibitionSettings = _a[0];
            return !!getExhibitionSettings;
        }), map(function (_a) {
            var getExhibitionSettings = _a[0], isBookedProductsCountValid = _a[1], isBookedContingentReservationFromDateSetAndInvalid = _a[2], areBookedContingentReservationsValid = _a[3], areBookedParkingReservationsValid = _a[4], bookedProductSelectionWorkshopTariffs = _a[5];
            var workshopsOnTicketSelection = getExhibitionSettings.workshopsOnTicketSelection, isWorkshopsSelectionMandatory = getExhibitionSettings.isWorkshopsSelectionMandatory, workshopMandatoryForZeroPriceTickets = getExhibitionSettings.workshopMandatoryForZeroPriceTickets, workshopsPerTicket = getExhibitionSettings.workshopsPerTicket;
            _this.setValidation(isBookedProductsCountValid);
            _this.setValidation(!isBookedContingentReservationFromDateSetAndInvalid, [
                'contingent',
                'steps.contingent.sold-out'
            ]);
            _this.setValidation(areBookedContingentReservationsValid, ['contingent', '']);
            _this.setValidation(areBookedParkingReservationsValid, ['parking', '']);
            var workshopValidationState = {
                isTariffWithWorkshopsTaken: !!bookedProductSelectionWorkshopTariffs.length,
                haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit: true,
                haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit: true
            };
            if (workshopsOnTicketSelection) {
                if (isWorkshopsSelectionMandatory) {
                    workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = _this.workshopValidationService.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(bookedProductSelectionWorkshopTariffs, workshopsPerTicket);
                }
                if (workshopMandatoryForZeroPriceTickets) {
                    workshopValidationState.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = _this.workshopValidationService.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(bookedProductSelectionWorkshopTariffs, workshopsPerTicket);
                }
                _this.setValidation(workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit, ['workshop', 'workshop.not-selected']);
                _this.setValidation(!workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit ||
                    workshopValidationState.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit, ['workshop-zero', 'workshop.not-zero-selected']);
            }
            else if (!workshopsOnTicketSelection ||
                (!isWorkshopsSelectionMandatory && !workshopMandatoryForZeroPriceTickets) ||
                workshopValidationState.isTariffWithWorkshopsTaken ||
                !isBookedProductsCountValid) {
                _this.setValidation(true, ['workshop', 'workshop.not-selected']);
                _this.setValidation(true, ['workshop-zero', 'workshop.not-zero-selected']);
            }
            return _this.isValidationStateValid;
        }));
    };
    ProductSelectionValidationService.prototype.setValidation = function (isValid, validationFeedback) {
        if (validationFeedback === void 0) { validationFeedback = ['counters', 'steps.missing-input.ticket-counter']; }
        var stepsFormsActionName = ['tickets', 'ticketSelection'];
        var key = validationFeedback[0], messageTranslationKey = validationFeedback[1];
        switch (key) {
            case 'counters':
                this.validationState.isBookedProductsCountValid = isValid;
                break;
            case 'parking':
                this.validationState.areBookedParkingReservationsValid = isValid;
                break;
            case 'contingent':
                this.validationState.areBookedContingentReservationsValid = isValid;
                break;
            case 'workshop':
                this.validationState.isWorkshopsMandatoryValid = isValid;
                break;
            case 'workshop-zero':
                this.validationState.isWorkshopsZeroPriceMandatoryValid = isValid;
                break;
            default:
                break;
        }
        var _a = this.validationState, isBookedProductsCountValid = _a.isBookedProductsCountValid, areBookedParkingReservationsValid = _a.areBookedParkingReservationsValid, areBookedContingentReservationsValid = _a.areBookedContingentReservationsValid, isWorkshopsMandatoryValid = _a.isWorkshopsMandatoryValid, isWorkshopsZeroPriceMandatoryValid = _a.isWorkshopsZeroPriceMandatoryValid;
        var allStepValidationFieldsValid = isBookedProductsCountValid &&
            areBookedContingentReservationsValid &&
            areBookedParkingReservationsValid &&
            isWorkshopsMandatoryValid &&
            isWorkshopsZeroPriceMandatoryValid;
        if (isValid) {
            if (key === 'counters' || key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
                //parking and contingent tickets share the same validation/translation key as the ticket counter ('counters'):
                if (isBookedProductsCountValid && areBookedContingentReservationsValid && areBookedParkingReservationsValid) {
                    this.formsService.removeStepValidationFeedback(stepsFormsActionName, 'counters');
                }
            }
            else {
                //other steps (i.e. currently only workshops) have their own key:
                this.formsService.removeStepValidationFeedback(stepsFormsActionName, key);
            }
        }
        else {
            if (key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
                key = 'counters';
                messageTranslationKey = 'steps.missing-input.ticket-counter';
            }
            this.formsService.addStepValidationFeedback(stepsFormsActionName, key, messageTranslationKey);
        }
        //even though we've received an info that the ticket selection is valid it can only be valid if all step validation fields are valid:
        if (isValid && !allStepValidationFieldsValid) {
            isValid = false;
        }
        this.formsService.setFormValidity(isValid, null, stepsFormsActionName);
        this.isValidationStateValid = isValid;
    };
    ProductSelectionValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ProductSelectionValidationService_Factory() { return new ProductSelectionValidationService(i0.inject(i1.Store), i0.inject(i2.HelperService), i0.inject(i3.FormsService), i0.inject(i4.WorkshopService), i0.inject(i5.WorkshopValidationService)); }, token: ProductSelectionValidationService, providedIn: "root" });
    return ProductSelectionValidationService;
}());
export { ProductSelectionValidationService };
