import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  State,
  getBuyerInfo,
  getBuyerVisitorCheckbox,
  getConfirmationCheckboxesInputs,
  getExhibitionSettings,
  getOrderResponse,
  getSelectedExhibitionId,
  getStepsValidity,
  getTicketHolderAdditionalData,
  getTicketHolderInputSets,
  isTicketHolderVisible
} from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookedPackageTotalPriceGroupedByPackageIndex,
  BookedProducts,
  BookingTariff,
  BookingWorkshop,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import {
  ConfirmationPackage,
  ConfirmationProducts,
  ConfirmationTariff,
  ConfirmationTariffType,
  ConfirmationWorkshop,
  HolderInformation
} from '@products/models/confirmation.model';
import { HolderUuids } from '@products/models/holder.model';
import { OrderResponseModel, OrderTicketModel } from '@products/models/order.model';
import { SendingOptionModel } from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { WorkshopProductList } from '@products/models/workshop.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { GtmService } from '@shared/gtm/gtmService';
import { ExhibitionSettingModel } from '@shared/services-with-reducers/customization/customization.interfaces';
import { CustomizationService } from '@shared/services-with-reducers/customization/customization.service';
import {
  getAllBookedPackagePricesGroupedByPackageIndex,
  getAllBookedProductsTotalPrice,
  getSortedBasketProducts
} from '@shared/services-with-reducers/products/booking/booking.selectors';
import { getSendingOptions } from '@shared/services-with-reducers/products/product-selection/product-selection.selectors';
import {
  BuyerVisitorCheckboxModel,
  FormInputsPayloadModel,
  InputsListModel,
  TicketHolderAdditionalDataModel
} from '@shared/services-with-reducers/step-forms/step.interface';
import {
  SetInputs,
  SetOrderResponse,
  setPaymentMethod
} from '@shared/services-with-reducers/step-forms/steps-forms.actions';
import { StepsFormsService } from '@shared/services-with-reducers/step-forms/steps-forms.service';
import { UserService } from '@shared/services-with-reducers/user/user.service';
import { getWorkshopProductList } from '@store/products/workshop/workshop.selectors';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-confirmation',
  templateUrl: './web-shop-confirmation.component.html',
  styleUrls: ['./web-shop-confirmation.component.scss']
})
export class WebShopConfirmationComponent implements OnInit, OnDestroy, AfterViewInit {
  checkboxesInputs: InputBase<any>[];
  checkboxesForm: FormGroup;
  checkboxesFormsActionName = ['confirmation', 'checkboxes'];
  ticketHolderInputSets: FormInputsPayloadModel[];
  totalPrice: number = 0;
  confirmationOptions;
  buyerEmail: string;
  workshops: WorkshopProductList = [];
  isAlternativeButtonDisabled = false;
  AppConstants = AppConstants;
  twintPayment = false;
  settings: ExhibitionSettingModel;
  orderSent = false;
  ProductType = ProductType;
  ticketsFromBookings: BookedProducts;
  confirmationProducts: ConfirmationProducts;
  getAllBookedPackagePricesGroupedByPackageIndex$: Observable<BookedPackageTotalPriceGroupedByPackageIndex>;
  paymentMethodImages = {
    'Saferpay#All': '../../assets/payment-options-logo/saferpay.png',
    'SaferpayApi#TWINT': '../../assets/payment-options-logo/twint-logo-quer.png',
    'SaferpayApi#VISA': '../../assets/payment-options-logo/visa.png',
    'SaferpayApi#ALIPAY': '../../assets/payment-options-logo/alipay.png',
    'SaferpayApi#BANCONTACT': '../../assets/payment-options-logo/bancontact.png',
    'SaferpayApi#BONUS': '../../assets/payment-options-logo/bonuscard.png',
    'SaferpayApi#DIRECTDEBIT': '../../assets/payment-options-logo/directdebit.png',
    'SaferpayApi#EPRZELEWY': '../../assets/payment-options-logo/eprzelewy.png',
    'SaferpayApi#EPS': '../../assets/payment-options-logo/eps.png',
    'SaferpayApi#GIROPAY': '../../assets/payment-options-logo/giropay.png',
    'SaferpayApi#IDEAL': '../../assets/payment-options-logo/ideal.png',
    // 'SaferpayApi#INVOICE': '../../assets/payment-options-logo/american_express.png', logo needs to be added per customer (language/currency)
    'SaferpayApi#JCB': '../../assets/payment-options-logo/jcb.png',
    'SaferpayApi#MYONE': '../../assets/payment-options-logo/myone.png',
    'SaferpayApi#PAYDIREKT': '../../assets/payment-options-logo/paydirekt.png',
    'SaferpayApi#POSTCARD': '../../assets/payment-options-logo/postfinance.png',
    'SaferpayApi#POSTFINANCE': '../../assets/payment-options-logo/postfinance.png',
    'SaferpayApi#SAFERPAYTEST': '../../assets/payment-options-logo/saferpaytest.png',
    'SaferpayApi#SOFORT': '../../assets/payment-options-logo/klarna.svg',
    'SaferpayApi#UNIONPAY': '../../assets/payment-options-logo/unionpay.png',
    'SaferpayApi#VPAY': '../../assets/payment-options-logo/vpay.png',
    'SaferpayApi#AllMethods': '../../assets/payment-options-logo/saferpay.png',
    'SaferpayApi#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'SaferpayApi#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
    'SaferpayApi#AMEX': '../../assets/payment-options-logo/american_express.png',
    'SaferpayApi#DINERS': '../../assets/payment-options-logo/diners.png',
    'SaferpayApi#MAESTRO': '../../assets/payment-options-logo/maestro.png',
    'IPayment#cc': '../../assets/payment-options-logo/credit_card.png',
    'IPayment#elv': '../../assets/payment-options-logo/sepa_logo.png',
    'IPayment#paypal': '../../assets/payment-options-logo/pay_pal.png',
    'Yandex#All': '../../assets/payment-options-logo/yandex-money.png',
    'Sberbank#All': '../../assets/payment-options-logo/sberbank.png',
    'Ingenico#AllMethods': '../../assets/payment-options-logo/saferpay.png',
    'Ingenico#CreditCard##MasterCard': '../../assets/payment-options-logo/mastercard.png',
    'Ingenico#PAYPAL##PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'PayOne#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
    'PayOne#VISA': '../../assets/payment-options-logo/visa.png',
    'PayOne#GIROPAY': '../../assets/payment-options-logo/giropay.png',
    'PayOne#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'PayOne#AMEX': '../../assets/payment-options-logo/american_express.png',
    'Verifone#AllMethods': '../../assets/payment-options-logo/verifone.png'
  };

  private subscriptions = new Subscription();

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private stepsFormsService: StepsFormsService,
    private customizationService: CustomizationService,
    private userService: UserService,
    public router: Router,
    private el: ElementRef,
    private gtmService: GtmService
  ) {}

  ngOnInit() {
    // remove the order response if there is an
    this.store.dispatch(new SetOrderResponse(null));
    this.store.dispatch(new setPaymentMethod(null));

    this.getAllBookedPackagePricesGroupedByPackageIndex$ = this.store.pipe(
      select(getAllBookedPackagePricesGroupedByPackageIndex)
    );

    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getAllBookedProductsTotalPrice)), this.getConfirmationProducts$()])
        .pipe(filter(([_, { sortedBookedProducts }]) => !!sortedBookedProducts.length))
        .subscribe(([totalPrice, { sortedBookedProducts, confirmationProducts }]) => {
          this.ticketsFromBookings = sortedBookedProducts;
          this.totalPrice = totalPrice;
          this.confirmationProducts = confirmationProducts;
        })
    );

    this.subscriptions.add(
      this.store
        .pipe(
          select(getExhibitionSettings),
          filter(data => !!data)
        )
        .subscribe(settings => {
          this.settings = settings;
        })
    );

    this.subscriptions.add(
      this.store
        .pipe(
          select(getBuyerInfo),
          filter(data => !!data)
        )
        .subscribe((inputs: InputsListModel) => {
          if (inputs.list.length) {
            const email = inputs.list.find(input => {
              return input.key === 'email';
            });
            this.buyerEmail = email.value;
          }
        })
    );

    // in case we already piad the order, go to invoice page for downlaod

    combineLatest([
      this.store.pipe(select(getOrderResponse)),
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getTicketHolderAdditionalData))
    ])
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1] && !!data[2];
        }),
        first()
      )
      .subscribe((data: [OrderResponseModel, number, TicketHolderAdditionalDataModel]) => {
        const [orderResponse, eventId, ticketHolderAdditionalData] = data;

        if (orderResponse.orderPaid || this.totalPrice === 0) {
          this.router.navigate([`webshop/${eventId}/invoice`]);
        }
      });

    this.subscriptions.add(
      this.store.pipe(select(getExhibitionSettings)).subscribe(settings => {
        if (settings && settings.twintPayment) {
          this.twintPayment = true;
        }
      })
    );

    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getOrderResponse)), this.store.pipe(select(getSelectedExhibitionId))])
        .pipe(
          filter(data => {
            return !!data[0] && !!data[1];
          })
        )
        .subscribe((data: [OrderResponseModel, number]) => {
          const [orderResponse, eventId] = data;

          if (!orderResponse.orderPaid) {
            if (orderResponse.payUrl) {
              window.open(orderResponse.payUrl, '_self');
              // in case there is no redirec url go to payment landing page
            } else if (orderResponse.payForm) {
              const form = document.createElement('div');
              form.innerHTML = orderResponse.payForm;
              this.el.nativeElement.appendChild(form);

              if (form.firstChild.nodeName === 'FORM') {
                const formElement = <HTMLFormElement>form.firstChild;
                formElement.submit();
              }
            } else if (orderResponse.hash) {
              this.router.navigate([`webshop/${eventId}/confirmation/${orderResponse.hash}`]);
              //if order response success is false we redirect user to error page
            } else if (!orderResponse.success) {
              this.router.navigate([`webshop/${eventId}/confirmation/error`]);
            }
          }
        })
    );

    // check validity of the step in order to set alternative payment button to disabled/enabled

    this.subscriptions.add(
      this.store.pipe(select(getStepsValidity)).subscribe(stepsValidity => {
        this.isAlternativeButtonDisabled = !stepsValidity.confirmation.valid;
      })
    );

    this.subscriptions.add(
      this.store
        .pipe(select(getConfirmationCheckboxesInputs))
        .pipe(filter(data => !!data))
        .subscribe((checkboxes: InputsListModel) => {
          if (checkboxes && checkboxes.list.length) {
            this.checkboxesInputs = checkboxes.list.slice(0);
            this.checkboxesForm =
              this.checkboxesForm || this.formsService.toFormGroup(checkboxes.list, this.checkboxesFormsActionName);
          }
        })
    );

    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(getSendingOptions)),
        this.store.pipe(select(getExhibitionSettings)),
        this.store.pipe(select(isTicketHolderVisible)),
        this.store.pipe(select(getTicketHolderInputSets))
      ])
      .pipe(
        filter(data => !!data),
        switchMap(([sendingOptions, exhibitionSettings, isTicketHolderVisible, ticketHolderInputSets]) =>
          combineLatest([
            this.store.pipe(select(getBuyerVisitorCheckbox)),
            this.store.pipe(select(getTicketHolderQuestionnaireInputs))
          ]).map(([buyerVisitorCheckbox, ticketHolderQuestionnaireInputs]) =>
            [sendingOptions, exhibitionSettings, isTicketHolderVisible,
            ticketHolderInputSets, buyerVisitorCheckbox, ticketHolderQuestionnaireInputs]
          )
        )
      )

        .subscribe((data: [SendingOptionModel[], ExhibitionSettingModel, boolean, FormInputsPayloadModel[], BuyerVisitorCheckboxModel, InputBase<any>[]] ) => {
          this.confirmationOptions = [];
          const [confirmationOptions, _, isTicketHolderVisible, ticketHolderInputSets, buyerVisitorCheckbox, ticketHolderQuestionnaireInputs] = data;

          this.ticketHolderInputSets = ticketHolderInputSets;
          const ticketsWithHolders: OrderTicketModel[] = [];

          this.ticketsFromBookings.forEach(bookedProduct => {
              if (isBookingProductTypeTariff(bookedProduct)) {
                bookedProduct.tariffs.forEach(tariff => {
                  this.stepsFormsService.addBookedTariffsToSaveOrder(
                    tariff,
                    ticketHolderInputSets,
                    buyerVisitorCheckbox,
                    ticketsWithHolders,
                    ticketHolderQuestionnaireInputs,
                    isTicketHolderVisible,
                    this.buyerEmail
                  );
                })
              }
              else if (isBookingProductTypePackage(bookedProduct)) {
                bookedProduct.productTypes.forEach(packageProductType => {
                  if (isBookingProductTypeTariff(packageProductType)) {
                    packageProductType.tariffs.forEach(tariff => {
                      this.stepsFormsService.addBookedTariffsToSaveOrder(
                        tariff,
                        ticketHolderInputSets,
                        buyerVisitorCheckbox,
                        ticketsWithHolders,
                        ticketHolderQuestionnaireInputs,
                        isTicketHolderVisible,
                        this.buyerEmail
                      );
                    });
                  }
                })
              }
          })
          this.confirmationOptions = confirmationOptions.filter(option => {
            return option.isEnabled;
          });
        })
    );
  }

  ngAfterViewInit() {
    this.gtmService.pushConfirmation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  paymentSubmited(paymentMethod: string, event) {
    event.stopPropagation();
    this.orderSent = true;

    this.store.dispatch(new setPaymentMethod(paymentMethod));

    this.stepsFormsService.prepareDataForSaveAndSend(
      this.ticketsFromBookings,
      this.totalPrice,
      this.ticketHolderInputSets
    );

    //this.formsService.setFormValidity(true, null, ['payment', 'validation']);

    /* compare ticket buyer and logged in user,
       if buyer has more credentials than user use the buyers information and add them to user
    */

    this.userService.updateUserWithBuyer();

    this.gtmService.pushCheckoutOption(paymentMethod.toString());
  }

  dropdownChanged(data) {
    let selectedSendingOption = data.sendingOption;

    combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getSendingOptions))])
      .first()
      .subscribe(settings => {
        const [exhibitionSettings, allSendingOptions] = settings;

        const ticketHolderform: FormInputsPayloadModel = this.ticketHolderInputSets.find(
          inputSet => data.holderInformation.holderUuid === inputSet.holderUuid
        );

        let sendToOwner: boolean = true;
        let sendToOwnerHidden: boolean = true;

        const isMobilePerBuyer: boolean = selectedSendingOption === TicketSendingOptions.MobilePerBuyer;
        const isTicketRetrivalLinkBuyer: boolean =
          selectedSendingOption === TicketSendingOptions.TicketRetrievalLinkBuyer;
        const isAllToBuyer: boolean = selectedSendingOption === TicketSendingOptions.AllToBuyer;
        const isTicketRetrivalLink: boolean = selectedSendingOption === TicketSendingOptions.TicketRetrievalLink;
        const isMobilePerOwner: boolean = selectedSendingOption === TicketSendingOptions.MobilePerOwner;
        const isNormalPerOwner: boolean = selectedSendingOption === TicketSendingOptions.NormalPerOwner;

        if (!isAllToBuyer && !isNormalPerOwner) {
          if (isMobilePerBuyer) {
            sendToOwner = false;
            selectedSendingOption = TicketSendingOptions.MobilePerOwner;
          } else if (isTicketRetrivalLinkBuyer) {
            sendToOwner = false;
            selectedSendingOption = TicketSendingOptions.TicketRetrievalLink;
          }

          for (let i = 0; i < allSendingOptions.length; i++) {
            const sendingOption = allSendingOptions[i];
            if (sendingOption.isEnabled) {
              if (isMobilePerBuyer && sendingOption.value === TicketSendingOptions.MobilePerOwner) {
                sendToOwner = sendToOwnerHidden = false;
                break;
              } else if (isMobilePerOwner && sendingOption.value === TicketSendingOptions.MobilePerBuyer) {
                sendToOwnerHidden = !sendToOwner;
                break;
              } else if (
                isTicketRetrivalLinkBuyer &&
                sendingOption.value === TicketSendingOptions.TicketRetrievalLink
              ) {
                sendToOwner = sendToOwnerHidden = false;
                break;
              } else if (
                isTicketRetrivalLink &&
                sendingOption.value === TicketSendingOptions.TicketRetrievalLinkBuyer
              ) {
                sendToOwnerHidden = !sendToOwner;
                break;
              }
            }
          }
        }

        if (isAllToBuyer) {
          sendToOwner = false;
        }

        const sendingOptions = ticketHolderform.inputSet.list.find(input => input.key === 'sendingOption');
        const sendtoowner = ticketHolderform.inputSet.list.find(input => input.key === 'sendtoowner');

        sendingOptions.value = selectedSendingOption;
        sendtoowner.hidden = sendToOwnerHidden;
        sendtoowner.options[0].value = sendToOwner;
        sendtoowner.options[0].label = `personalize.sending-options.${selectedSendingOption.toLowerCase()}`;
        sendtoowner.options[0].icon = selectedSendingOption;

        const chackValidityAndRedirect = true;

        this.customizationService.setRequiredHoldersFiledsBasedOnSendinOption(
          ticketHolderform,
          exhibitionSettings,
          selectedSendingOption,
          chackValidityAndRedirect
        );

        this.store.dispatch(new SetInputs(ticketHolderform));
      });
  }

  private setConfirmationTariffWorkshops(workshops: BookingWorkshop[], workshopProductList: WorkshopProductList) {
    const confirmationWorkshops: ConfirmationWorkshop[] = [];

    workshops.forEach(({ holderUuids, price, id }) => {
      const workshopProduct = workshopProductList.find(workshopProduct => workshopProduct.workshopId === id);

      if (workshopProduct) {
        const { date, end, start, roomName, workshopName } = workshopProduct;
        const workshopCount = holderUuids.length;
        const confirmationWorkshop: ConfirmationWorkshop = {
          holderUuids,
          count: workshopCount,
          date,
          end,
          start,
          roomName,
          workshopName,
          price,
          totalPrice: price * workshopCount
        };

        confirmationWorkshops.push(confirmationWorkshop);
      }
    });

    return confirmationWorkshops;
  }

  private setConfirmationTariffHolderInformation(
    holderUuids: HolderUuids,
    ticketHolderInputs: FormInputsPayloadModel[],
    confirmationTariff: ConfirmationTariff
  ) {
    holderUuids.forEach(holderUuid => {
      const ticketHolderInputSet = ticketHolderInputs.find(singleInput => singleInput.holderUuid.includes(holderUuid));

      if (!!ticketHolderInputSet) {
        const holderInformation: Partial<HolderInformation> = { holderUuid };
        const inputsInfo = ['email', 'firstName', 'lastName', 'sendingOption', 'sendtoowner'];

        ticketHolderInputSet.inputSet.list.forEach(input => {
          if (inputsInfo.includes(input.key)) {
            holderInformation[input.key] = input.key === 'sendtoowner' ? input.options[0].value : input.value;
          }
        });

        confirmationTariff.holderInformation.push(holderInformation);
      }
    });
  }

  private setConfirmationTariff(
    bookedTariff: BookingTariff,
    confirmationTariffs: ConfirmationTariff[],
    ticketHolderInputs: FormInputsPayloadModel[],
    workshopProductList: WorkshopProductList
  ) {
    const { count, price, ticketName, voucherCode, holderUuids, workshops } = bookedTariff;

    const confirmationTariff: ConfirmationTariff = {
      count,
      price,
      ticketName,
      totalPrice: count * price,
      voucherCode,
      holderInformation: [],
      workshops: this.setConfirmationTariffWorkshops(workshops, workshopProductList)
    };

    this.setConfirmationTariffHolderInformation(holderUuids, ticketHolderInputs, confirmationTariff);

    confirmationTariffs.push(confirmationTariff);
  }

  /**
   * Get confirmation products
   * @description Sorts products to have packages, normal tariffs and parking tickets consecutively
   * @returns Array of products on confirmation screen
   */
  private getConfirmationProducts$(): Observable<{
    sortedBookedProducts: BookedProducts;
    confirmationProducts: ConfirmationProducts;
  }> {
    return combineLatest([
      this.store.pipe(select(getSortedBasketProducts)),
      this.store.pipe(select(getTicketHolderInputSets)),
      this.store.pipe(select(getWorkshopProductList))
    ]).pipe(
      map(([sortedBookedProducts, ticketHolderInputs, workshopProductList]) => {
        const confirmationProducts: ConfirmationProducts = [];

        sortedBookedProducts.forEach(bookedProduct => {
          const { productType, productTypeName } = bookedProduct;

          if (isBookingProductTypeTariff(bookedProduct)) {
            const { tariffs } = bookedProduct;
            const confirmationTariffs: ConfirmationTariff[] = [];

            tariffs.forEach(bookedTariff => {
              this.setConfirmationTariff(bookedTariff, confirmationTariffs, ticketHolderInputs, workshopProductList);
            });

            const confirmationTariffType: ConfirmationTariffType = {
              productType: ProductType.Tariff,
              productTypeName,
              tariffs: confirmationTariffs
            };

            confirmationProducts.push(confirmationTariffType);
          } else if (isBookingProductTypePackage(bookedProduct)) {
            const { packageNumber, packageIndex, productTypes } = bookedProduct;
            const confirmationPackage: ConfirmationPackage = {
              productType,
              productTypeName,
              packageNumber,
              packageIndex,
              productTypes: []
            };

            productTypes.forEach(packageProductType => {
              if (isBookingProductTypeTariff(packageProductType)) {
                const { productType, productTypeName } = packageProductType;
                const confirmationTariffs: ConfirmationTariff[] = [];

                packageProductType.tariffs.forEach(bookedPackageTariff => {
                  this.setConfirmationTariff(
                    bookedPackageTariff,
                    confirmationTariffs,
                    ticketHolderInputs,
                    workshopProductList
                  );
                });

                const confirmationPackageTariffType: ConfirmationTariffType = {
                  productType,
                  productTypeName,
                  tariffs: confirmationTariffs
                };

                confirmationPackage.productTypes.push(confirmationPackageTariffType);
              }
            });

            confirmationProducts.push(confirmationPackage);
          }
        });

        return { sortedBookedProducts, confirmationProducts };
      })
    );
  }
}
