import {
  combineLatest,
  Subscription
} from 'rxjs';

import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../../shared/services-with-reducers/user/user.actions';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormInputsPayloadModel } from '../../../shared/services-with-reducers/step-forms/step.interface';

import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '../../../shared/app-constants';
import { FormsService } from '../../../shared/forms/forms.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { InputsListModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { UserProfileModel } from '../../../shared/services-with-reducers/user/user.interface';

@Component({
  moduleId: module.id,
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnDestroy, OnInit {
  inputs: InputBase<any>[];
  form: FormGroup;
  billingAddressList = [];
  selectedBuyerBillAddressId: number = -1;
  isEditEnabled: boolean = true;
  isLoggedIn: boolean;

  readonly subscriptions = new Subscription();
  readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;

  constructor(
    private store: Store<fromRoot.State>,
    private formsService: FormsService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.store.pipe(select(fromRoot.getBuyerActiveBillingAddressId)).subscribe(selectedBuyerBillAddressId => this.selectedBuyerBillAddressId = selectedBuyerBillAddressId)
    );

    this.subscriptions.add(
      this.store.pipe(select(fromRoot.isUserLoggedIn)).subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn)
    );

    this.store.pipe(select(fromRoot.getProfile), first(profile => !!profile)).subscribe((user: UserProfileModel) => {
      this.isLoggedIn = true;
      this.store.dispatch(new userActions.GetProfileBillingAddress(user.id));
    });

    // Load list of billing addresses via API request
    this.store.pipe(select(fromRoot.getProfileBillingAddress)).subscribe(billingAddressList => this.billingAddressList = billingAddressList);

    // once Addresses are loaded via API we can subscribe to them from redux here
    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(fromRoot.getBillingAddressForm), filter(data => !!data)),
        this.store.pipe(select(fromRoot.isUserLoggedIn))
      ]).subscribe(([billingAddress, isLoggedIn]: [InputsListModel, boolean]) => {
        if (billingAddress.list.length) {
          const input = billingAddress.list.find(input => input.key === 'save-address');
          input.hidden = !isLoggedIn;

          if (!isLoggedIn) {
            input.options[0].value = false;
          }
          // update is needed for changes like browser address autocomplete or google address API autocomplete

          if (!this.form) {
            this.inputs = this.formsService.updateInputs(
              this.inputs,
              billingAddress.list
            );
            const indexOfSelectedBillingAddress: number = Object.keys(this.billingAddressList)
              .findIndex(key => this.billingAddressList[key].id === this.selectedBuyerBillAddressId);

            this.inputs.some(item => !!item.value) ? null : this.onChange(indexOfSelectedBillingAddress);
            this.rerenderForm(this.inputs);
          }
        }
      })
    );
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);

    const billingAddressPayload: FormInputsPayloadModel = {
      formInfo: this.PersonaliseFormsKeys.billingAddress,
      inputSet: {
        rerender: true,
        list: this.inputs
      }
    };

    this.store.dispatch(new stepsActions.SetInputs(billingAddressPayload, true));

    if (rerender) {
      this.form = this.formsService.toFormGroup(
        this.inputs,
        this.PersonaliseFormsKeys.billingAddress
      );
    }
  };

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onChange(value) {
    let selectedBillingAddressId = null;
    if (value >= 0) {
      selectedBillingAddressId = this.billingAddressList[value].id;

      // Change values and readonly attribute of billing address after selecting saved address
      this.inputs.forEach(input => {
        input.value = this.billingAddressList[value][input.key];
        input.disabled = true;

        if (input.key === 'save-address') {
          input.hidden = true;
          input.options[0].value = false;
        }
      });

      this.isEditEnabled = false;
    } else {
      this.inputs.forEach(input => {
        input.value = undefined;
        input.disabled = false;
        if (input.key === 'save-address') {
          input.hidden = false;
        }
      });
      this.isEditEnabled = true;
    }

    this.store.dispatch(new stepsActions.SetBuyerActiveBillingAddressId(selectedBillingAddressId));
    this.rerenderForm(this.inputs);
  }

  rerenderForm(inputs) {
    //make sure we are not prerendering with references
    const clonedInputs = cloneDeep(inputs);

    this.form = this.formsService.toFormGroup(
      clonedInputs,
      this.PersonaliseFormsKeys.billingAddress
    );

    this.inputs = clonedInputs;
  }
}
