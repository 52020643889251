import { createSelector } from '@ngrx/store';
import { PackageStatusState } from '@products/models/package-status.model';
import { getProductStatusState } from '@store/products/product.selectors';

/**
 * Get package status
 * @description Get package status object
 * @returns Package status object
 */
export const getPackageStatus = createSelector(
  getProductStatusState,
  (productStatusState): PackageStatusState => productStatusState.package
);

/**
 * Get last package index
 * @description Get latest added package index
 * @returns Package index
 */
export const getLastPackageIndex = createSelector(
  getPackageStatus,
  (packageStatus): number => packageStatus.lastPackageIndex
);

/**
 * Get package loading status
 * @description Get status if package is loading
 * @returns Boolean
 */
export const getIsPackageLoading = createSelector(
  getPackageStatus,
  (packageStatus): boolean => packageStatus.isPackageLoading
);
