import { Action } from '@ngrx/store';
import {
  BookingPackageType,
  BookingProductType,
  BookingTariffType,
  RemoveBookingWorkshop as RemoveBookingWorkshopPayload,
  SetBookingWorkshop as SetBookingWorkshopPayload
} from '@products/models/booking.model';
import { RemoveContingentReservation, SetContingentReservationDate } from '@products/models/contingent.model';
import { SetParkingReservationDate } from '@products/models/parking.model';
import {
  GetProducts as GetProductsPayload,
  RemovePackageBooking as RemovePackageBookingPayload,
  RemovePackageTariffBooking as RemovePackageTariffBookingPayload,
  RemoveTariffBooking as RemoveTariffBookingPayload,
  SetPreferredTariffBooking as SetPreferredTariffBookingPayload
} from '@products/models/products.model';
import {
  RedeemedVoucher,
  ReleaseVoucher as ReleaseVoucherPayload,
  RemoveVoucher as RemoveVoucherPayload,
  RemoveVoucherTariffBooking as RemoveVoucherTariffBookingPayload,
  SetVoucherPayload
} from '@products/models/voucher.model';

export enum ActionTypes {
  GET_PRODUCTS = '[Exhibition Change/Service] Get products',
  GET_PREFERRED_PRODUCTS = '[Exhibition Change/Service] Get preferred products',
  GET_WORKSHOP_PRODUCTS = '[Exhibition Change/Service] Get workshop products',
  REMOVE_PRODUCT_BOOKING = '[Product Selection] Remove product bookings',
  SET_TARIFF_BOOKING = '[Product Selection/Tariff] Set tariff booking',
  SET_PREFERRED_TARIFF_BOOKING = '[Product Selection/Tariff] Set preferred tariff booking',
  SET_SELF_REGISTRATION_TARIFF_BOOKINGS = '[Product Selection] Set self registration tariff bookings',
  REMOVE_TARIFF_BOOKING = '[Product Selection/Tariff] Remove tariff booking',
  REDEEM_AND_RELEASE_VOUCHER = '[Voucher API/Voucher] Redeem and release voucher',
  SET_REDEEMED_AND_RELEASE_VOUCHER = '[Voucher API/Products] Set redeemed and release vouchers in product bookings and product selection',
  RELEASE_VOUCHER = '[Product Selection/Voucher] Release voucher',
  RELEASE_EXPIRED_VOUCHER = '[Countdown/Voucher] Release expired voucher',
  REMOVE_RELEASED_VOUCHER = '[Voucher API/Product] Remove released voucher',
  REMOVE_RELEASED_AND_REMOVED_BOOKING_VOUCHER = '[Product Booking/Voucher] Remove released and removed booking voucher from product selection',
  SET_VOUCHER_TARIFF_BOOKING = '[Product Selection/Voucher tariff] Set voucher tariff booking',
  REMOVE_VOUCHER_TARIFF_BOOKING = '[Product Selection/Tariff] Remove Voucher tariff booking',
  SET_BOOKING_WORKSHOP = '[Product Selection/Workshop/Tariff] Set booking workshop',
  REMOVE_BOOKING_WORKSHOP = '[Product Selection/Workshop/Tariff] Remove booking workshop',
  RELEASE_ASSIGNED_WORKSHOP_HOLDER_SEATS = '[Product Selection/Workshop/Tariff] Released removed product booking assigned workshop holder seats',
  SET_CONTINGENT_BOOKING_RESERVATION = '[Product Selection/Tariff] Set contingent booking reservation',
  REMOVE_CONTINGENT_BOOKING_RESERVATION = '[Product Selection/Tariff] Remove contingent booking reservation',
  SET_PARKING_BOOKING_RESERVATION = '[Product Selection/Tariff] Set parking booking reservation',
  REMOVE_PARKING_BOOKING_RESERVATION = '[Product Selection/Tariff] Remove parking booking reservation',
  SET_PACKAGE_BOOKING = '[Product Selection/PackageType] Set package booking',
  REMOVE_PACKAGE_BOOKING = '[Product Selection/PackageType] Remove package booking',
  SET_PACKAGE_TARIFF_BOOKING = '[Product Selection/Package/Tariff] Set package tariff booking',
  REMOVE_PACKAGE_TARIFF_BOOKING = '[Product Selection/Package/Tariff] Remove package tariff booking',
  PRODUCTS_RESET_PRODUCT_SELECTION_REDUCER = '[Shop Reset] Reset product selection reducer',
  PRODUCTS_RESET_BOOKING_REDUCER = '[Shop Reset] Reset booking reducer',
  PRODUCTS_RESET_WORKSHOP_REDUCER = '[Shop Reset] Reset workshop reducer',
  PRODUCTS_RESET_HOLDER_REDUCER = '[Shop Reset] Reset holder reducer',
  PRODUCTS_RESET_STATUS_REDUCER = '[Shop Reset] Reset status reducer'
}

export class GetProducts implements Action {
  readonly type = ActionTypes.GET_PRODUCTS;
  constructor(public payload: GetProductsPayload) {}
}

export class GetPreferredProducts implements Action {
  readonly type = ActionTypes.GET_PREFERRED_PRODUCTS;
  constructor(public payload: GetProductsPayload) {}
}

export class RemoveProductBooking implements Action {
  readonly type = ActionTypes.REMOVE_PRODUCT_BOOKING;
  constructor(public payload: BookingProductType[]) {}
}

export class SetTariffBooking implements Action {
  readonly type = ActionTypes.SET_TARIFF_BOOKING;
  constructor(public payload: BookingTariffType[]) {}
}

export class SetPreferredTariffBooking implements Action {
  readonly type = ActionTypes.SET_PREFERRED_TARIFF_BOOKING;
  constructor(public payload: SetPreferredTariffBookingPayload) {}
}

export class SetSelfRegistrationTariffBookings implements Action {
  readonly type = ActionTypes.SET_SELF_REGISTRATION_TARIFF_BOOKINGS;
  constructor(public payload: BookingProductType[]) {}
}

export class RemoveTariffBooking implements Action {
  readonly type = ActionTypes.REMOVE_TARIFF_BOOKING;
  constructor(public payload: RemoveTariffBookingPayload) {}
}

export class RedeemAndReleaseVoucher implements Action {
  readonly type = ActionTypes.REDEEM_AND_RELEASE_VOUCHER;
  constructor(public payload: SetVoucherPayload) {}
}

export class SetRedeemedAndReleaseVoucher implements Action {
  readonly type = ActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER;
  constructor(
    public payload: {
      redeemedVoucher?: RedeemedVoucher;
      releaseVouchers: ReleaseVoucherPayload[];
      removeProductBookings: BookingProductType[];
    }
  ) {}
}

export class SetVoucherTariffBooking implements Action {
  readonly type = ActionTypes.SET_VOUCHER_TARIFF_BOOKING;
  constructor(public payload: BookingTariffType[]) {}
}

export class ReleaseVoucher implements Action {
  readonly type = ActionTypes.RELEASE_VOUCHER;
  constructor(public payload: ReleaseVoucherPayload) {}
}

export class ReleaseExpiredVoucher implements Action {
  readonly type = ActionTypes.RELEASE_EXPIRED_VOUCHER;
  constructor(public payload: ReleaseVoucherPayload) {}
}

export class RemoveVoucherTariffBooking implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING;
  constructor(public payload: RemoveVoucherTariffBookingPayload) {}
}

export class RemoveReleasedVoucher implements Action {
  readonly type = ActionTypes.REMOVE_RELEASED_VOUCHER;
  constructor(public payload: RemoveVoucherPayload) {}
}

export class RemoveReleasedAndRemovedProductBookingVoucher implements Action {
  readonly type = ActionTypes.REMOVE_RELEASED_AND_REMOVED_BOOKING_VOUCHER;
  constructor(public payload: string) {}
}

export class SetBookingWorkshop implements Action {
  readonly type = ActionTypes.SET_BOOKING_WORKSHOP;
  constructor(public payload: SetBookingWorkshopPayload[]) {}
}

export class RemoveBookingWorkshop implements Action {
  readonly type = ActionTypes.REMOVE_BOOKING_WORKSHOP;
  constructor(public payload: RemoveBookingWorkshopPayload[]) {}
}

export class ReleaseAssignedWorkshopHolderSeats implements Action {
  readonly type = ActionTypes.RELEASE_ASSIGNED_WORKSHOP_HOLDER_SEATS;
  constructor(public payload: RemoveBookingWorkshopPayload[]) {}
}

export class SetContingentBookingReservation implements Action {
  readonly type = ActionTypes.SET_CONTINGENT_BOOKING_RESERVATION;
  constructor(public payload: SetContingentReservationDate[]) {}
}

export class RemoveContingentBookingReservation implements Action {
  readonly type = ActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION;
  constructor(public payload: RemoveContingentReservation[]) {}
}

export class SetParkingBookingReservation implements Action {
  readonly type = ActionTypes.SET_PARKING_BOOKING_RESERVATION;
  constructor(public payload: SetParkingReservationDate) {}
}

export class SetPackageBooking implements Action {
  readonly type = ActionTypes.SET_PACKAGE_BOOKING;
  constructor(public payload: BookingPackageType[]) {}
}

export class RemovePackageBooking implements Action {
  readonly type = ActionTypes.REMOVE_PACKAGE_BOOKING;
  constructor(public payload: RemovePackageBookingPayload) {}
}

export class SetPackageTariffBooking implements Action {
  readonly type = ActionTypes.SET_PACKAGE_TARIFF_BOOKING;
  constructor(public payload: BookingPackageType[]) {}
}

export class RemovePackageTariffBooking implements Action {
  readonly type = ActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING;
  constructor(public payload: RemovePackageTariffBookingPayload) {}
}

export class GetWorkshopProducts implements Action {
  readonly type = ActionTypes.GET_WORKSHOP_PRODUCTS;
  constructor(public payload: number) {}
}

export class ResetProductSelectionReducer implements Action {
  readonly type = ActionTypes.PRODUCTS_RESET_PRODUCT_SELECTION_REDUCER;
}

export class ResetBookingReducer implements Action {
  readonly type = ActionTypes.PRODUCTS_RESET_BOOKING_REDUCER;
}

export class ResetWorkshopReducer implements Action {
  readonly type = ActionTypes.PRODUCTS_RESET_WORKSHOP_REDUCER;
}

export class ResetHolderReducer implements Action {
  readonly type = ActionTypes.PRODUCTS_RESET_HOLDER_REDUCER;
}

export class ResetStatusReducer implements Action {
  readonly type = ActionTypes.PRODUCTS_RESET_STATUS_REDUCER;
}

export type Actions =
  | GetProducts
  | GetPreferredProducts
  | RemoveProductBooking
  | SetTariffBooking
  | SetPreferredTariffBooking
  | SetSelfRegistrationTariffBookings
  | RemoveTariffBooking
  | RedeemAndReleaseVoucher
  | SetRedeemedAndReleaseVoucher
  | ReleaseVoucher
  | ReleaseExpiredVoucher
  | RemoveVoucherTariffBooking
  | RemoveReleasedVoucher
  | RemoveReleasedAndRemovedProductBookingVoucher
  | SetBookingWorkshop
  | RemoveBookingWorkshop
  | SetContingentBookingReservation
  | RemoveContingentBookingReservation
  | SetPackageBooking
  | RemovePackageBooking
  | SetPackageTariffBooking
  | RemovePackageTariffBooking
  | ResetBookingReducer
  | ResetWorkshopReducer
  | ResetHolderReducer
  | ResetProductSelectionReducer
  | ResetStatusReducer;
