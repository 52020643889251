<app-loader *ngIf="!isFormReady"></app-loader>
<div class="read-only row" *ngIf="isFormReady && (isReadonlyBuyer$ | async)">
  <div class="col-md-9">
    <div *ngFor="let line of readOnlyAddress | keys">
      <div class="row" *ngIf="line.value.data.length">
        <div class="col-sm-5 col-md-3 col-lg-2" *ngIf="line.value.label">
          {{ line.value.label | translate | hideTranslation }}:
        </div>
        <div class="col-sm-7 col-md-9 col-lg-10">
          <span *ngFor="let data of line.value.data">
            <span *ngIf="data?.translate && data?.title">{{ data?.title | translate | hideTranslation }}&#32;</span>
            <span *ngIf="!data?.translate && data?.title">{{ data?.title }}&#32;</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3 edit-read-only-buyer-wrap">
    <button class="button" *ngIf="anyDisabled && (profile$ | async)" (click)="editReadOnly()">
      <span data-translation="personalize.buyer-info.edit-buyer">
        {{ 'personalize.buyer-info.edit-buyer' | translate | hideTranslation }}
      </span>
    </button>
  </div>
  <div class="col-sm-12 fill-missing-info" *ngIf="anyDisabled && anyEnabled && (profile$ | async)">
    {{ 'personalize.buyer-info.fill-missing-inputs' | translate | hideTranslation }}
  </div>
</div>
<form
  class="row"
  *ngIf="form && isFormReady"
  [ngClass]="{ 'hide-read-only': isReadonlyBuyer$ | async }"
  [formGroup]="form"
  novalidate
>
  <ng-container *ngFor="let input of inputs">
    <app-df-input
      *ngIf="!confirmationCheckboxes.includes(input.key)"
      [ngClass]="{ 'input-disabled': form.controls[input.key].disabled }"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [action]="PersonaliseFormsKeys.buyerInfo"
      [form]="form"
      (onSubmit)="formSubmited($event)"
      (onInputChanged)="inputChanged($event)"
    >
    </app-df-input>
  </ng-container>

  <div class="edit-read-only-buyer-wrap col-md-12 with-margin-top-15">
    <button
      class="button"
      *ngIf="anyEnabled && !anyDisabled && (isReadonlyBuyer$ | async) && (profile$ | async)"
      (click)="saveEditReadOnly()"
    >
      <span data-translation="personalize.buyer-info.edit-buyer-confirm">
        {{ 'personalize.buyer-info.edit-buyer-confirm' | translate | hideTranslation }}
      </span>
    </button>
  </div>

  <ng-container *ngFor="let input of inputs">
    <app-df-input
      *ngIf="confirmationCheckboxes.includes(input.key)"
      [ngClass]="{ 'input-disabled': form.controls[input.key].disabled }"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [action]="PersonaliseFormsKeys.buyerInfo"
      [form]="form"
      (onSubmit)="formSubmited($event)"
      (onInputChanged)="inputChanged($event)"
    >
    </app-df-input>
  </ng-container>
</form>

<app-billing-address
  class="checkbox-button-row billing-address"
  *ngIf="differentBillingAddressCheckbox"
></app-billing-address>

<app-modal-window
  [modalWindowActive]="showTicketLimitWarning && !showTicketLimitWarningAlreadyClosed"
  [translateTitle]="false"
  [isTitleWithParamTranslation]="true"
>
  <span
    [attr.data-translation]="'order.ticket-overlimit-warning.modalWindow.title'"
    modalWindow-titleWithParamTranslation
  >
    {{
      'order.ticket-overlimit-warning.modalWindow.title'
        | translate: { ticketLimitPerEmail: exhibitionSettings.ticketLimitPerEmail }
    }}
  </span>
  <button class="button button-option" (click)="closeModalWindow($event)" modalWindow-close>
    <span data-translation="order.ticket-overlimit-warning.modalWindow.close">
      {{ 'order.ticket-overlimit-warning.modalWindow.close' | translate | hideTranslation }}
    </span>
  </button>
</app-modal-window>
