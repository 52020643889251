import { WorkshopProductList } from '@products/models/workshop.model';

import { Action } from '@ngrx/store';

export enum ActionTypes {
  SET_INITIAL_WORKSHOP_PRODUCT_LIST = '[Workshop API] Set initial workshop product list',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class SetInitialWorkshopProductList implements Action {
  readonly type = ActionTypes.SET_INITIAL_WORKSHOP_PRODUCT_LIST;
  constructor(public payload: WorkshopProductList) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions = SetInitialWorkshopProductList | ResetReducer;
