var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import { LoginService } from '../login/login.service';
import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { SetActiveSlide } from '../shared/services-with-reducers/products/holder/holder.actions';
import { StepsFormsService } from '../shared/services-with-reducers/step-forms/steps-forms.service';
import { WindowSizeService } from '../shared/window-size/window-size.service';
var StepNavigationButtonsComponent = /** @class */ (function () {
    function StepNavigationButtonsComponent(_route, _router, _store, _formService, _windowSizeService, _stepsFormsService, _helperService, _loginService) {
        var _this = this;
        this._route = _route;
        this._router = _router;
        this._store = _store;
        this._formService = _formService;
        this._windowSizeService = _windowSizeService;
        this._stepsFormsService = _stepsFormsService;
        this._helperService = _helperService;
        this._loginService = _loginService;
        this.feedbackMessages = [];
        this.feedbackSubject = new Subject();
        this.subscriptions = new Subscription();
        this.emptyInputs = [];
        this.subscriptions.add(this._store
            .select(fromRoot.getStepsValidity)
            .pipe(debounceTime(100))
            .subscribe(function (validations) {
            var pathKey = _this._route.snapshot.url[0].path;
            _this.isButtonDisabled = !validations[pathKey].valid;
            var activeStepKey = _this._route.snapshot.url[0].path;
            var visibleSteps = Object.keys(validations)
                // make sure steps are ordered correctly
                .sort(function (a, b) {
                return validations[a].order - validations[b].order;
            })
                // only navigate to visible routes
                .filter(function (stepKey) {
                return validations[stepKey].visible;
            });
            var indexOfStep = visibleSteps.indexOf(activeStepKey);
            _this.showBackButton = !!indexOfStep;
        }));
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getSelectedStep), this._store.select(fromRoot.getStepsValidity), this._store.select(fromRoot.isBuyerOnPersonalPageHidden), this.feedbackSubject.pipe(startWith(true)), this._store.select(fromRoot.isContinueAsGuest), this._formService.validationFeedbacksUpdated)
            .pipe(debounceTime(10), filter(function (data) {
            return !!data[0] && !!data[1];
        }))
            .subscribe(function (data) {
            var stepKeyName = data[0], stepsValidity = data[1], isBuyerHidden = data[2], subj = data[3], isContinueAsGuest = data[4], validationFeedbacksUpdated = data[5];
            var pathKey = _this._route.snapshot.url[0].path;
            _this.anyBuyerMessageHidden = false;
            _this.isContinueAsGuest = isContinueAsGuest;
            if (stepKeyName.toLowerCase() === pathKey.toLowerCase() && _this._formService.inputsValidity.hasOwnProperty(stepKeyName)) {
                _this.feedbackMessages = Object.keys(_this._formService.inputsValidity[stepKeyName])
                    .map(function (key) {
                    return __assign({}, _this._formService.inputsValidity[stepKeyName][key], { key: key });
                })
                    .filter(function (inputMessage) {
                    // dont return buyer info messages on personal page if buyer is hidden there
                    var isHidden = stepKeyName === 'personal' &&
                        isBuyerHidden &&
                        inputMessage.key.startsWith('buyerinfo_');
                    _this.anyBuyerMessageHidden =
                        isHidden || _this.anyBuyerMessageHidden;
                    return !isHidden;
                });
                // instead
                if (_this.anyBuyerMessageHidden) {
                    var continueFeedbackMessage = {
                        label: 'personalize.any.buyer.message.hidden',
                        translate: true,
                        key: 'anyBuyerMessageHidden'
                    };
                    _this.feedbackMessages = [];
                    if (_this.isContinueAsGuest) {
                        continueFeedbackMessage.label =
                            'personalize.any.buyer.message.guest';
                    }
                    _this.feedbackMessages.unshift(continueFeedbackMessage);
                }
                else {
                    _this.feedbackMessages = _this.feedbackMessages.filter(function (message) {
                        return message.key !== 'anyBuyerMessageHidden';
                    });
                }
            }
        }));
    }
    Object.defineProperty(StepNavigationButtonsComponent.prototype, "feedbackChanged", {
        set: function (val) {
            this.feedbackSubject.next();
        },
        enumerable: true,
        configurable: true
    });
    StepNavigationButtonsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    StepNavigationButtonsComponent.prototype.onBackClick = function () {
        this._stepsFormsService.navigateRelativeTo(-1, this._router);
    };
    StepNavigationButtonsComponent.prototype.onContinueClick = function (event) {
        var _this = this;
        if (event) {
            event.stopPropagation();
        }
        if (this.isButtonDisabled) {
            // in case that guest feature form is not filled switch login form tab to registration
            if (this.isContinueAsGuest && this.anyBuyerMessageHidden) {
                this._loginService.setActiveTab('registration');
            }
            // wait in case that tab changes to create DOM Elements
            setTimeout(function () {
                _this.fixFirstInvalidInput();
            }, 250);
            return false;
        }
        this.isButtonDisabled = true;
        // wait until the click is registered and all form data saved, then go to next step
        setTimeout(function () {
            _this._stepsFormsService.navigateRelativeTo(1, _this._router);
        }, 250); // delay 250 ms as form input to store has 100ms debounce on it
    };
    StepNavigationButtonsComponent.prototype.fixFirstInvalidInput = function () {
        var _this = this;
        if (!this.isButtonDisabled) {
            if (!this.payment) {
                // if user has to pay on confirmation page there is no continue button... this will prevent the 404 redirect issue
                this.onContinueClick();
            }
        }
        else {
            this.emptyInputs = this._helperService.appEl.querySelectorAll('select.ng-invalid, input.ng-invalid, .checkbox-button-row.ng-invalid, .decorated-checkbox.ng-invalid, .ng-custom-invalid');
            var filteredEmptyInputs_1 = [];
            Object.keys(this.emptyInputs).map(function (element) {
                if (!_this.emptyInputs[element].classList.contains('login-input')) {
                    filteredEmptyInputs_1.push(_this.emptyInputs[element]);
                }
            });
            if (filteredEmptyInputs_1 && filteredEmptyInputs_1.length) {
                var firstEmptyInput_1 = filteredEmptyInputs_1[0];
                var timeToScroll = 0.2;
                var focusTimeout = 1000;
                // dont scroll to hidden fields
                if (firstEmptyInput_1.offsetParent === null) {
                    return;
                }
                this._windowSizeService.scrollToElement(firstEmptyInput_1, 100, 0, timeToScroll);
                setTimeout(function () {
                    firstEmptyInput_1.focus();
                }, timeToScroll * focusTimeout);
            }
        }
    };
    StepNavigationButtonsComponent.prototype.loadNextInvalidTicketHolderForm = function () {
        if (document.querySelector('.validity-list')) {
            var ticketHolderFormsList = Array.from(document.querySelector('.validity-list').children);
            var findFirstInvalidTicketHolderForm = ticketHolderFormsList.findIndex(function (element) { return !element.classList.contains('valid'); });
            if (findFirstInvalidTicketHolderForm > -1) {
                if (this._formService.inputsValidity.hasOwnProperty('personal')) {
                    var hasInvalidVisitorQuestionnaire = Object.keys(this._formService.inputsValidity['personal']).some(function (key) {
                        if (key.startsWith('visitorQuestionnaire_')) {
                            return true;
                        }
                    });
                    if (hasInvalidVisitorQuestionnaire) {
                        //if the current ticket holder has an invalid visitor questionnaire skip navigating to the next invalid ticket holder:
                        return;
                    }
                }
                this._store.dispatch(new SetActiveSlide(findFirstInvalidTicketHolderForm));
            }
        }
    };
    return StepNavigationButtonsComponent;
}());
export { StepNavigationButtonsComponent };
