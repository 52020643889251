import * as fromRoot from '@app/app.reducer';
import * as stepsActions from '@store/step-forms/steps-forms.actions';
import * as userActions from '@store/user/user.actions';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subscription
} from 'rxjs';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EmailData, ValidateDailyTicketPerEmailLimitBody, ValidateDailyTicketPerEmailLimitResult } from '@products/models/tariff-status.model';
import { TariffStatusService } from '@products/services/tariff-status.service';
import { setTimeout } from 'core-js/library/web/timers';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '@shared/app-constants';
import { consoleLog } from '@shared/app-utils';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { ExhibitionSettingModel, QuestionnaireDataInput } from '@store/customization/customization.interfaces';
import { SelectOption } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import { getTariffsBookingByOrder } from '@store/products/holder/holder.selectors';
import { FormInputsPayloadModel, InputsListModel } from '@store/step-forms/step.interface';
import * as stepsFormsActions from '@store/step-forms/steps-forms.actions';
import { UserProfileModel } from '@store/user/user.interface';
import { HolderTariff } from '@products/models/holder.model';
import { TextOrDropdownInputTypes } from '@store/helpers/helper.interface';
import { buyerInfoReadOnlyModel, readOnlyModelFactory } from './buyer-info.read-only-model';

@Component({
  moduleId: module.id,
  selector: 'app-buyer-info',
  templateUrl: './buyer-info.component.html',
  styleUrls: ['./buyer-info.component.scss']
})
export class BuyerInfoComponent implements OnInit, OnDestroy {
  @Input() areTicketHoldersVisible: boolean;
  @Output() isInputChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  buyerInfo$: Observable<InputsListModel>;
  profile$: Observable<UserProfileModel>;
  isReadonlyBuyer$: Observable<boolean>;
  isTicketsOverLimitCheckDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isFormValidationDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  subscription: Subscription = new Subscription();

  inputs: InputBase<any>[];
  form: FormGroup;
  formLoadedAndValid = false;
  differentBillingAddressCheckbox: boolean = false;
  isFormReady = false;
  anyDisabled = true;
  anyEnabled = true;
  showTicketLimitWarning: boolean = false;
  showTicketLimitWarningAlreadyClosed = false;
  modalWindowOpen: boolean = false;
  exhibitionSettings: ExhibitionSettingModel;
  allTitlesForReadOnly = [];
  allProfessionsForReadOnly = [];
  allDepartmentsForReadOnly = [];
  allOccupationalGroupsForReadOnly = [];
  readOnlyAddress: buyerInfoReadOnlyModel[] = readOnlyModelFactory();
  confirmationCheckboxes = ['newsletter', 'different-billing-address', 'fairCatalogue' ];

  buyerFormAlreadyFilled = false;
  firstTimeRender = true;
  isLoggedInOnInitLoad = false;
  buyerInfo: InputsListModel;
  isReadonlyBuyer: boolean;
  profile: UserProfileModel;
  inputTitles: SelectOption[];
  allTitles: QuestionnaireDataInput[];
  inputProfessions: SelectOption[];
  allProfessions: QuestionnaireDataInput[];
  inputDepartments: SelectOption[];
  allDepartments: QuestionnaireDataInput[];
  inputOccupationalGroups: SelectOption[];
  allOccupationalGroups: QuestionnaireDataInput[];
  copyDataYesNo: boolean = null;
  selectedExhibitionId: number;
  holdersBookedTariff: HolderTariff[] = [];
  isSelfRegistrationEnabled: boolean;
  hasOnlyParkingTickets: boolean = false;
  isBuyerInfoFormValid: boolean = false;

  //tickets over limit check:
  needsTicketsOverLimitCheck: boolean = true;
  ticketsOverLimitRequests: Array<Date> = [];

  //validity check results:
  ticketsOverLimitCheckResult: boolean = false;
  isFairCatalogueCheckedByDefault: boolean = false;

  readonly PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;

  constructor(
    private store: Store<fromRoot.State>,
    private formsService: FormsService,
    private helperService: HelperService,
    private tariffStatusService: TariffStatusService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.isSelfRegistrationEnabled = this.helperService.isSelfregistration();

    this.buyerInfo$ = this.store.pipe(select(fromRoot.getBuyerInfo));
    this.profile$ = this.store.pipe(select(fromRoot.getProfile));
    this.isReadonlyBuyer$ = this.store.pipe(select(fromRoot.isReadonlyBuyer));

    this.subscription.add(
      this.store.pipe(
        select(fromRoot.getExhibitionSettings),
        filter(data => !!data)
      )
      .subscribe(settings => {
        this.exhibitionSettings = settings;
        this.needsTicketsOverLimitCheck = settings.ticketLimitPerEmail > 0;
        this.isFairCatalogueCheckedByDefault = settings.buyerSettings.fairCatalogDefaultValue;
      })
    );

    this.subscription.add(
      this.store.pipe(
        select(fromRoot.getLanguage),
        filter(() => !!this.inputs)
      )
      .subscribe(() => this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo))
    );

    this.profile$.pipe(first()).subscribe(profile => this.isLoggedInOnInitLoad = !!profile);

    this.subscription.add(
      combineLatest([
        this.buyerInfo$,
        this.isReadonlyBuyer$,
        this.profile$,
        this.store.pipe(select(fromRoot.getTitles)),
        this.store.pipe(select(fromRoot.getAllTitles)),
        this.store.pipe(select(fromRoot.getProfessions)),
        this.store.pipe(select(fromRoot.getAllProfessions)),
        this.store.pipe(select(fromRoot.getDepartments)),
        this.store.pipe(select(fromRoot.getAllDepartments)),
        this.store.pipe(select(fromRoot.getOccupationalGroups)),
        this.store.pipe(select(fromRoot.getAllOccupationalGroups))
      ])
      .pipe(
        filter((
          [
            buyerInfo,
            isReadOnlyBuyer,
            profile,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups
          ]) =>
          !!buyerInfo
          && isReadOnlyBuyer !== null
          && !!titles
          && !!allTitles
          && !!professions
          && !!allProfessions
          && !!departments
          && !!allDepartments
          && !!occupationalGroups
          && !!allOccupationalGroups
        )
      )
      .subscribe(
        ([
          buyerInfo,
          isReadonlyBuyer,
          profile,
          inputTitles,
          allTitles,
          inputProfessions,
          allProfessions,
          inputDepartments,
          allDepartments,
          inputOccupationalGroups,
          allOccupationalGroups
        ]:
        [
          InputsListModel,
          boolean,
          UserProfileModel,
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[]
        ]) => {
          this.buyerInfo = buyerInfo;
          this.isReadonlyBuyer = isReadonlyBuyer;
          this.profile = profile;
          this.inputTitles = inputTitles;
          this.allTitles = allTitles;
          this.inputProfessions = inputProfessions;
          this.allProfessions = allProfessions;
          this.inputDepartments = inputDepartments;
          this.allDepartments = allDepartments;
          this.inputOccupationalGroups = inputOccupationalGroups;
          this.allOccupationalGroups = allOccupationalGroups;
          this.fillBuyerInfoWithProfileData();
      })
    );

    this.subscription.add(
      combineLatest([
        this.helperService.voteYesNo$,
        this.isFormValidationDone$,
        this.profile$
      ])
      .pipe(filter(([voteYesNo, isFormValidationDone, profile]) => !!voteYesNo && isFormValidationDone && !!profile))
      .subscribe(([voteYesNo, isFormValidationDone, profile]) => {
        this.copyDataYesNo = voteYesNo;
        this.profile = profile;

        if (!!this.copyDataYesNo && isFormValidationDone) {
          if (this.isReadonlyBuyer) {
            //make read only address disappear by making all values empty:
            this.readOnlyAddress = readOnlyModelFactory();
          }

          this.fillBuyerInfoWithProfileData();
        }
      })
    );

    this.subscription.add(
      this.store.pipe(select(fromRoot.getSelectedExhibitionId)).subscribe(eventId => this.selectedExhibitionId = eventId)
    );

    this.subscription.add(
      this.store.pipe(select(getTariffsBookingByOrder)).subscribe(tariffBookingByOrder => {
        this.holdersBookedTariff = tariffBookingByOrder;
        // if getTariffsBookingByOrder returns empty array it means we only have parking tickets
        this.hasOnlyParkingTickets = !tariffBookingByOrder.length;
      })
    );

    this.subscription.add(
      combineLatest([
        this.buyerInfo$,
        this.store.pipe(select(fromRoot.isBuyerInfoFormValid))
      ])
      .pipe(
        filter(([buyerInfo]) => !!buyerInfo && !!buyerInfo.list && !!buyerInfo.list.find(item => item.key === 'email')),
        distinctUntilChanged((prev, curr) => {
          const [prevBuyerInfo, prevIsBuyerInfoFormValid] = prev;
          const [currBuyerInfo, currIsBuyerInfoFormValid] = curr;
          const prevEmail: string = prevBuyerInfo.list.find(item => item.key === 'email').value;
          const currEmail: string = currBuyerInfo.list.find(item => item.key === 'email').value;

          //only go inside if there was a change of the buyer e-mail or a change in buyer info form validity
          return prevEmail === currEmail && prevIsBuyerInfoFormValid === currIsBuyerInfoFormValid;
        })
      )
      .subscribe(([, isBuyerInfoFormValid]) => {
        this.isBuyerInfoFormValid = isBuyerInfoFormValid;

        //if the buyer info form is invalid or we don't have to validate tickets over limit here, set buyer info form validity
        //(it will not be done inside of the forms.service):
        if (!this.needsTicketsOverLimitCheck || this.areTicketHoldersVisible || !isBuyerInfoFormValid || this.hasOnlyParkingTickets) {
          this.formsService.setFormValidity(isBuyerInfoFormValid, this.form, this.PersonaliseFormsKeys.buyerInfo);
          return;
        }

        //otherwise invalidate the form and check for tickets over limit:
        this.formsService.setFormValidity(false, this.form, this.PersonaliseFormsKeys.buyerInfo);
        this.checkForTicketsOverLimit();
      })
    );

    this.subscription.add(
      this.isTicketsOverLimitCheckDone$
      .pipe(filter((isTicketsOverLimitCheckDone) => isTicketsOverLimitCheckDone))
      .subscribe(() =>
        //when all checks are done set buyer info validity:
        this.formsService.setFormValidity(this.isBuyerInfoFormValid && this.ticketsOverLimitCheckResult, this.form, this.PersonaliseFormsKeys.buyerInfo)
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.inputs = [];
    this.form = null;
    this.formLoadedAndValid = false;
    this.differentBillingAddressCheckbox = false;
    this.firstTimeRender = true;
    this.isLoggedInOnInitLoad = false;
  }

  fillBuyerInfoWithUrlParameters(buyerInfo: any) {
    this.store.pipe(
      select(fromRoot.getBuyerInfoFromURL),
      first(parameters => !!parameters)
    )
    .subscribe(parameters => {
      Object.keys(parameters).forEach(parameter => {
        let buyerInfoParameter = buyerInfo.find(
          (p: { key: string }) => p.key === parameters[parameter].key
        );

        if (buyerInfoParameter && !buyerInfoParameter.value) {
          buyerInfoParameter.value = parameters[parameter].value;
        }
      });
    });

    this.store.dispatch(new stepsFormsActions.ResetBuyerInfoFromURL());
    this.saveForm(false);
  }

  fillBuyerInfoWithProfileData() {
    this.allTitlesForReadOnly = this.allTitles;
    this.allProfessionsForReadOnly = this.allProfessions;
    this.allDepartmentsForReadOnly = this.allDepartments;
    this.allOccupationalGroupsForReadOnly = this.allOccupationalGroups;

    if (!!this.inputs) {
      for (let i = 0; i < this.buyerInfo.list.length; i++) {
        const buyerInfoData = this.buyerInfo.list[i];
        const inputDataValue = this.inputs[i].value;
        const inputControlType = buyerInfoData.controlType.toLowerCase();

        if (inputControlType !== "checkbox" && inputControlType !== "radio" && inputControlType !== "formButton" && inputControlType !== "dateinput") {
          if (inputDataValue !== buyerInfoData.value){
            this.isInputChanged.emit(true);
            break;
          }
        }
      }
    };

    if (this.buyerInfo && this.buyerInfo.list.length) {
      this.buyerInfo.list.forEach(buyerOption => {
        if (buyerOption.key === 'fairCatalogue' && buyerOption.options.length > 0) {
          buyerOption.value = buyerOption.options[0].value;
        }
      });
      this.inputs = this.formsService.updateInputs(this.inputs, this.buyerInfo.list);

      if (this.firstTimeRender && this.isReadonlyBuyer && this.profile) {
        //first enable al disabled inputs, so we can again decide whether to disable them based on their validity
        this.inputs = this.inputs.map(input => {
          input.disabled = false;
          return input;
        });
      };

      const createAccountCheckbox = this.inputs.find(x => x.key === 'createAccount');
      let isCreateAccountChecked = false;

      if (createAccountCheckbox) {
        isCreateAccountChecked = createAccountCheckbox.options[0].value;
      }

      //when user logs in, all buyer info input fields are filled with profile data,
      //create account checkbox, button and password input fields are hidden and not required.
      if (!!this.profile) {
        // check if the values are empty
        if (this.copyDataYesNo) {
          this.buyerFormAlreadyFilled = false;
        } else {
          this.buyerFormAlreadyFilled = this.inputs.some(buyerInfoItem =>
            buyerInfoItem.key === 'fairCatalogue' ? !this.isFairCatalogueCheckedByDefault && buyerInfoItem.value : buyerInfoItem.value
          );
        }

        // In case the buyer form is empty prefill data from profile
        if (!this.modalWindowOpen && this.profile && !this.buyerFormAlreadyFilled) {
          let verifyEmail = '';

          this.inputs.forEach(buyerInfoItem => {
            let updatedBuyerInfoItem;
            if (this.profile.hasOwnProperty(buyerInfoItem.key)) {
              if (this.copyDataYesNo) {
                if (this.profile[buyerInfoItem.key] === null || this.profile[buyerInfoItem.key] === ''){
                  this.profile[buyerInfoItem.key] = buyerInfoItem.value;
                }
              }

              updatedBuyerInfoItem = {
                ...buyerInfoItem,
                value: this.formsService.getFunctionTextValue(
                  this.profile,
                  buyerInfoItem,
                  this.inputTitles,
                  this.allTitles,
                  this.inputProfessions,
                  this.allProfessions,
                  this.inputDepartments,
                  this.allDepartments,
                  this.inputOccupationalGroups,
                  this.allOccupationalGroups
                )
              };

              this.formsService.resetInvalidFunctionValue(
                updatedBuyerInfoItem,
                this.inputTitles,
                this.inputProfessions,
                this.inputDepartments,
                this.inputOccupationalGroups,
                (input: InputBase<any>) => {
                  updatedBuyerInfoItem = {
                    ...input,
                    value: null
                  };
                }
              );
            } else {
              updatedBuyerInfoItem = {
                ...buyerInfoItem,
                value: ''
              };

              if (
                buyerInfoItem.key === 'Newsletter' &&
                (buyerInfoItem.controlType === 'checkbox' || buyerInfoItem.controlType === 'radio')
                ) {
                  if (buyerInfoItem.value === 'false') {
                    this.profile.newsletterChecked = false;
                  } else if (buyerInfoItem.value === 'true') {
                    this.profile.newsletterChecked = true;
                  }
                updatedBuyerInfoItem = {
                  ...buyerInfoItem,
                  value: this.profile.newsletterChecked.toString()
                };

              }
            }
            const listOfInputKeys = [updatedBuyerInfoItem.key];

            // if we filled in an email with some value also prefill it to verify email

            if (buyerInfoItem.key === 'email' && this.profile[buyerInfoItem.key]) {
              verifyEmail = updatedBuyerInfoItem.value;
            }

            this.setInputsPropertyValues(this.inputs, listOfInputKeys, 'value', updatedBuyerInfoItem.value);
          });

          if (verifyEmail) {
            this.setInputsPropertyValues(this.inputs, ['verifyEmail'], 'value', verifyEmail);
          }
        }

        this.setInputsPropertyValues(this.inputs, ['createAccount'], 'hidden', true);
        this.setInputsPropertyValues(this.inputs, ['createAccount', 'password', 'verifyPassword'], 'required', false);

        if (createAccountCheckbox) {
          isCreateAccountChecked = createAccountCheckbox.options[0].value = false;
        }
      }

      // depending if create account checkbox is checked or not we display/hide the password input fields (requirement) and create account button
      this.setInputsPropertyValues(this.inputs, ['createAccountButton', 'password', 'verifyPassword'], 'hidden', !isCreateAccountChecked);
      this.setInputsPropertyValues(this.inputs, ['password', 'verifyPassword'], 'required', isCreateAccountChecked);
      this.setBillAddressFormValid(this.inputs);

      if (this.copyDataYesNo || this.buyerInfo.rerender || !this.form || (!this.isLoggedInOnInitLoad && !!this.profile)) {
        setTimeout(() => {
          // ensure that the above condition will not pass again next time
          if (!this.isLoggedInOnInitLoad && !!this.profile) {
            this.isLoggedInOnInitLoad = true;
          }

          this.fillBuyerInfoWithUrlParameters(this.inputs);
          this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo);

          // we need to check manually if the form is valid or not in case 'readOnlyMode' is set in backoffice
          // if it is set and form is not valid, we need to enable edit temporarily
          if (this.firstTimeRender && this.isReadonlyBuyer && !!this.profile) {
            this.firstTimeRender = false;

            const validationCallback = () => {
              this.readOnlyFormReady(!!this.profile, this.allTitles, this.allProfessions, this.allDepartments, this.allOccupationalGroups);
            };

            this.helperService.triggerCallbackOnceFormValidationIsDone(this.form, validationCallback);
          } else {
            this.isFormReady = true;
            this.anyDisabled = this.checkIfAnyInputIsDisabled();
            this.anyEnabled = this.checkIfAnyInputIsEnabled();

            if (!this.buyerFormAlreadyFilled && !!this.profile) {
              this.saveForm(true);
            }
          }

          this.helperService.triggerCallbackOnceFormValidationIsDone(this.form, () => this.isFormValidationDone$.next(true));
        }, 0);
      } else {
        this.anyDisabled = this.checkIfAnyInputIsDisabled();
        this.anyEnabled = this.checkIfAnyInputIsEnabled();
      }
    }

    if (!!this.copyDataYesNo) {
      this.copyDataYesNo = null;
      this.helperService.voteYesOrNo(null);
    }
  }

  setInputsPropertyValues(
    inputs: InputBase<any>[],
    keys: Array<string>,
    propName: string,
    value: boolean | string
  ) {
    inputs.forEach((input: InputBase<any>) => {
      if (keys.includes(input.key)) {
        input[propName] = value;
      }
    });
  }

  readOnlyFormReady(
    isLoggedIn: boolean,
    allTitles: QuestionnaireDataInput[],
    allProfessions: QuestionnaireDataInput[],
    allDepartments: QuestionnaireDataInput[],
    allOccupationalGroups: QuestionnaireDataInput[]
  ) {
    const allTextOrDropdownInputTypes = {
      [TextOrDropdownInputTypes.Title]: allTitles,
      [TextOrDropdownInputTypes.Function]: allProfessions,
      [TextOrDropdownInputTypes.Department]: allDepartments,
      [TextOrDropdownInputTypes.OccupationalGroup]: allOccupationalGroups
    };
    const textOrDropdownInputTypes: string[] = Object.keys(TextOrDropdownInputTypes).map(typeKey => TextOrDropdownInputTypes[typeKey]);

    this.isFormReady = true;
    this.inputs = this.inputs.map(input => {
      let inputIsDisabled = false;

      if (this.form.controls[input.key].valid) {
        inputIsDisabled = true;
      }

      this.readOnlyAddress.forEach((line: buyerInfoReadOnlyModel) => {
        const index = line.contains.indexOf(input.key);

        if (index >= 0 && inputIsDisabled) {
          line.data[index] = {};

          if (input.key === 'gender') {
            if (input.value) {
              line.data[index].title = `gender.${input.value}`;
              line.data[index].translate = true;
            } else {
              line.data[index].title = null;
            }
          } else if (input.key === 'country') {
            line.data[index].title = `country.${input.value}`;
            line.data[index].translate = true;
          } else if (textOrDropdownInputTypes.includes((input.key))) {
            const inputType = input.key;

            if (!isNaN(<number>input.value) && !!input.value && allTextOrDropdownInputTypes[inputType].length) {
              const inputData = allTextOrDropdownInputTypes[inputType].find((type: QuestionnaireDataInput) => type.id === Number(input.value))

              if (inputData) {
                this.subscription.add(
                  this.translateService.get(inputData.text).subscribe(translation => line.data[index][inputType] = translation)
                );
              }
            }
          } else {
            line.data[index].title = input.value;
            line.data[index].translate = false;
          }

          const containsMultiple: boolean = line.contains.length > 1;
          const hideLabel: boolean = !input.required && !input.value && !line.alwaysVisible && !containsMultiple;

          // when input is not required and empty we don't need to show it in template;
          if (hideLabel) {
            line.label = '';
          }
        }
      });

      if (this.confirmationCheckboxes.includes(input.key)) {
        input.disabled = false;
      } else {
        input.disabled = inputIsDisabled;
      }

      return input;
    });

    this.anyDisabled = this.checkIfAnyInputIsDisabled();
    this.anyEnabled = this.checkIfAnyInputIsEnabled();

    /* rerender the form to see disabled fields */

    this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo);

    // we filled the data so we need to save the form to redux store
    if (!this.buyerFormAlreadyFilled && isLoggedIn) {
      this.saveForm(true);
    }
  }

  checkIfAnyInputIsDisabled() {
    return this.inputs.some(input => input.disabled && !input.hidden && !this.confirmationCheckboxes.includes(input.key));
  }

  checkIfAnyInputIsEnabled() {
    return this.inputs.some(input => !input.disabled && !input.hidden && !this.confirmationCheckboxes.includes(input.key));
  }

  editReadOnly() {
    if (this.anyDisabled) {
      this.inputs = JSON.parse(
        JSON.stringify(
          this.inputs.map(input => {
            input.disabled = false;
            return input;
          })
        )
      );

      // make read only address dissapeared by making all values empty
      this.readOnlyAddress = readOnlyModelFactory();
      this.form = this.formsService.toFormGroup(this.inputs, this.PersonaliseFormsKeys.buyerInfo);

      // we filled the data so we need to save the form to redux store
      this.saveForm(true);
    }
  }

  saveEditReadOnly() {
    /* if user edited input and clicked to Confirm button straight away,
    we need first save the change (input is updated to redux).
    Then we can rebuild the read only info. Thats why we need the setTimeout here */
    setTimeout(() => {
      // set buyerFormAlreadyFilled to false so we enforce save of the form and its complete reload with propper disabled inputs
      this.buyerFormAlreadyFilled = false;
      this.readOnlyFormReady(true, this.allTitles, this.allProfessionsForReadOnly, this.allDepartmentsForReadOnly, this.allOccupationalGroupsForReadOnly);
    }, 300);
  }

  saveForm(rerender: boolean) {
    const clonedBuyerInputs: InputBase<any>[] = cloneDeep(this.inputs);

    const buyerInfoPayload: FormInputsPayloadModel = {
      formInfo: this.PersonaliseFormsKeys.buyerInfo,
      inputSet: {
        rerender: rerender,
        list: clonedBuyerInputs
      }
    };

    this.store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));
  }

  // If billing address is not shown, set billing address form to valid
  setBillAddressFormValid(buyerInputs: InputBase<any>[]) {
    const differentBillingAddressInput = buyerInputs.find(x => x.key === 'different-billing-address');

    if (differentBillingAddressInput) {
      this.differentBillingAddressCheckbox = differentBillingAddressInput.options[0].value;
    } else {
      // if there is no billing address enabled from backoffice, then mark it for disabling the validation
      this.differentBillingAddressCheckbox = false;
    }

    // if no different billing address is used set the form and inputs to be valid
    if (!this.differentBillingAddressCheckbox) {
      this.formsService.setStepValid(this.PersonaliseFormsKeys.billingAddress);
    }
  }

  formSubmited(inputKey) {
    this.store.pipe(select(fromRoot.getUserLanguage)).subscribe(language => {
      if (inputKey === 'createAccountButton') {
        let userData: any = this.helperService.processFormValuesBeforeSave(this.form.value);
        delete userData.address;
        delete userData['verifyEmail'];
        delete userData['verifyPassword'];

        userData.language = language;

        this.store.dispatch(new userActions.CreateProfile(userData));
      }
    });
  }

  inputChanged(input) {
    if (this.needsTicketsOverLimitCheck && !this.areTicketHoldersVisible && this.isBuyerInfoFormValid && !this.hasOnlyParkingTickets && input === 'email') {
      this.ticketsOverLimitCheckResult = false;
      this.formsService.setFormValidity(false, this.form, this.PersonaliseFormsKeys.buyerInfo);
    }
  }

  checkForTicketsOverLimit() {
    this.isTicketsOverLimitCheckDone$.next(false);
    this.ticketsOverLimitCheckResult = false;

    let emailDataList: EmailData[] = [];
    const buyerEMail: string = this.inputs.find(input => input.key === 'email').value;

    if (!buyerEMail) {
      //e-mail is mandatory and so is the check for daily ticket limit per e-mail, as we don't have an e-mail we have to invalidate buyer info form:
      this.formsService.setFormValidity(false, null, this.PersonaliseFormsKeys.buyerInfo);
      return;
    }

    this.holdersBookedTariff.forEach(ticket => emailDataList.push({ index: 0, email: buyerEMail, ticketPersonId: ticket.ticketPersonId, amount: 1 }));

    if (emailDataList.length) {
      const request: ValidateDailyTicketPerEmailLimitBody = {
        eventId: this.selectedExhibitionId,
        isSelfRegistration: this.isSelfRegistrationEnabled,
        items: emailDataList
      };

      const thisRequestTime: Date = new Date();
      this.ticketsOverLimitRequests.push(thisRequestTime);
      consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, request=${JSON.stringify(request)}`);

      this.tariffStatusService.checkDailyTicketPerEmailLimit$(request).subscribe(
        (response: ValidateDailyTicketPerEmailLimitResult) => {
          consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, response=${JSON.stringify(response)}`);
          const findIndex: number = this.ticketsOverLimitRequests.indexOf(thisRequestTime);

          if (findIndex > -1) {
            if (findIndex === this.ticketsOverLimitRequests.length - 1) {
              if (!response.isValid) {
                //the buyer is over ticket limit:
                this.ticketsOverLimitCheckResult = false;
                this.showTicketLimitWarning = true;
              } else {
                //the buyer isn't over ticket limit:
                this.ticketsOverLimitCheckResult = true;
              }

              this.isTicketsOverLimitCheckDone$.next(true);
            } else {
              consoleLog(`CheckDailyTicketPerEmailLimit: skipping response from requestTime=${thisRequestTime.toISOString()}...`);
            }

            this.ticketsOverLimitRequests.splice(findIndex, 1);
          }
        },
        (err) => {
          consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, ERROR=${JSON.stringify(err)}`);
          const findIndex: number = this.ticketsOverLimitRequests.indexOf(thisRequestTime);

          if (findIndex > -1) {
            if (findIndex === this.ticketsOverLimitRequests.length - 1) {
              this.ticketsOverLimitCheckResult = false;
              this.isTicketsOverLimitCheckDone$.next(true);
            }

            this.ticketsOverLimitRequests.splice(findIndex, 1);
          }
        }
      );
    }
  }

  closeModalWindow(event) {
    event.stopPropagation();
    this.showTicketLimitWarning = false;
  }
}
