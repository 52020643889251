import { VoucherCode, VoucherSponsor, VoucherType } from '@products/models/voucher.model';
import { WorkshopsByDay } from '@products/models/workshop.model';

export enum TariffWarningMessage {
  TARIFF_SOLD_OUT = 'ticket.counter.ticket.slodout',
  WORKSHOPS_SOLD_OUT = 'ticket.counter.workshops.slodout',
  ERROR_TICKET_WARNING = 'ticket-selection.warning',
  ERROR_VOUCHER_WARNING = 'ticket-selection.voucher-warning',
  VOUCHER_LIMITED_WARNING = 'ticket-selection.limited-voucher-warning',
  VOUCHER_ONETIME_WARNING = 'ticket-selection.one-time-voucher-warning',
  TICKET_LIMIT_PER_USER_ACCOUNT_WARNING = 'ticket-selection.ticket-limit-per-user-account-warning'
}

export enum TariffInfoExpandedTranslationResponse {
  True = 'true',
  False = 'false'
}

export enum TariffCurrencyCodes {
  CHF = 'CHF'
}

export enum TariffClassification {
  Normal = 'normal',
  Parking = 'parking',
  SelfRegistration = 'selfRegistrationOnly'
}

export enum TicketSendingOptions {
  AllToBuyer = 'allToBuyer',
  MobilePerBuyer = 'mobilePerBuyer',
  NormalPerOwner = 'normalPerOwner',
  MobilePerOwner = 'mobilePerOwner',
  TicketRetrievalLink = 'ticketRetrivalLink',
  TicketRetrievalLinkBuyer = 'ticketRetrivalLinkBuyer'
}

export enum TariffPersonalizationType {
  Anonymous = 'anonymous',
  Mandatory = 'mandatory'
}

export interface TariffType {
  ticketTypeId: number;
  ticketTypeName: string;
  preferredTicketType?: string;
  isVisible: boolean;
  tariffs: Tariff[];
}

export interface Tariff {
  id: number;
  name: string;
  info: string;
  infoExpanded: string;
  price: number;
  ticketPersonTypeId: number;
  ticketPersonId: number;
  ticketTypeId: number;
  ticketTypeName: string;
  tariffNumber: number;
  durationInDays: number;
  availableTickets?: number;
  ticketLimit?: number;
  requiresLegitimation: boolean;
  personalizationType: TariffPersonalizationType;
  classification: TariffClassification;
  preferredTicket?: boolean;
  isVisible: boolean;
  isVoucher: boolean;
  sponsors?: VoucherSponsor[];
  voucherCode?: VoucherCode;
  voucherType?: VoucherType;
  voucherCountLimit?: number;
  activatedTimestamp?: number;
  releasedInMinutes?: number;
  allowedWorkshops?: number[];
  allowedWorkshopsFull?: boolean;
  workshopsByDay: WorkshopsByDay[];
  parking?: ParkingReservation[];
  hasDaySellLimit?: boolean;
  validFrom?: string;
  validTill?: string;
  daysOffset: number;
  shouldCalendarBeDisplayed: boolean;
  days?: string[];
  packageSettings?: TariffPackageSettings;
  packageNumber?: number;
  packageIndex?: number;
}

export interface ParkingReservation {
  since: string;
  until: string;
}

export interface TariffPackageSettings {
  useTariffSheetPrice: boolean;
  fixedAmount: boolean;
  amount: number;
  minAmount?: number;
  maxAmount?: number;
  packageItemPrice?: number;
}

export interface TariffValidationStateViewModel {
  count: number;
  countDifference: number;
  validatedCount: number;
  previousValidatedCount: number;
  bookedCount: number;
  validatedMaxLimit: number;
  isCountOverLimit: boolean;
  isTariffSoldOut: boolean;
  isAvailableTariffsLimitReached: boolean;
  numberOfAllBookedTariffs: number;
  initialNumberOfAvailableTariffs: number;
  currentNumberOfAvailableTariffs: number;
  percentageOfAvailableTariffs: number;
  tariffLimit: number;
  maxTariffLimit: number;
  tariffLimitPerUserAccount: number;
  currentUserAccountTariffLimit: number;
  isCurrentUserAccountTariffLimitReached: boolean;
  voucherCountLimit: number;
  validateVoucherCountLimit: boolean;
  maxVoucherLimit: number;
  maxLimitedVoucherLimit: number;
  numberOfBookedVouchers: number;
  numberOfBookedLimitedVouchers: number;
  numberOfBookedOneTimeVouchers: number;
  isTariffClassificationNormal: boolean;
  isTariffClassificationParking: boolean;
  allowedWorkshopsSeatsAvailable: number;
  hideWorkshopTariffCounter: boolean;
  areAllAllowedWorkshopsAssigned: boolean;
  isWorkshopsSelectionMandatoryAndHasWorkshops: boolean;
  isVoucher: boolean;
  isVoucherTypePromoCode: boolean;
  isVoucherTypeLimitedPromoCode: boolean;
  isVoucherTypeOneTimeVoucher: boolean;
  isVoucherIncludedPerUserAccountLimit: boolean;
  isVoucherLimitReached: boolean;
  isPackageFixedAmountLimitReached: boolean;
  isPackageMinAmountLimitReached: boolean;
  isPackageMaxAmountLimitReached: boolean;
  isPackageTypeAndIsPackageNotAdded: boolean;
  packageSettings: TariffPackageSettings;
  packageTooltipMessage: string;
  showMobilePackageTooltipMessage: boolean;
  tariffLimitWarningMessage: string;
}

export interface PreferredTariffValidationState extends TariffValidationStateViewModel {
  ticketTypeId: number;
  ticketPersonTypeId: number;
}
