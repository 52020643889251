import { getTicketHolderInputSets } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { getAllTariffsWithWorkshops } from '@store/products/product-selection/product-selection.selectors';
import { RemoveBookingWorkshop, SetBookingWorkshop } from '@store/products/product.actions';
import { getWorkshopProductList } from '@store/products/workshop/workshop.selectors';
import _ from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./booking.service";
var WorkshopService = /** @class */ (function () {
    function WorkshopService(store, bookingService) {
        this.store = store;
        this.bookingService = bookingService;
    }
    WorkshopService.prototype.getWorkshopDetailViewModel$ = function (workshopId) {
        return combineLatest([
            this.store.pipe(select(getWorkshopProductList)),
            this.getBookedProductSelectionWorkshopDetailTariffHolders$(workshopId)
        ]).pipe(filter(function (_a) {
            var workshopProductList = _a[0];
            return !!workshopProductList.length;
        }), map(function (_a) {
            var workshopProductList = _a[0], workshopTariffHolders = _a[1];
            return ({
                workshopProduct: workshopProductList.find(function (workshopProduct) { return workshopProduct.workshopId === workshopId; }) || null,
                workshopProductList: workshopProductList,
                workshopTariffHolders: workshopTariffHolders,
                areAllWorkshopTariffHoldersAssigned: workshopTariffHolders.every(function (workshopTariffHolder) { return workshopTariffHolder.isHolderAssigned; })
            });
        }));
    };
    WorkshopService.prototype.getBookedProductSelectionWorkshopTariffs$ = function () {
        return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(map(function (_a) {
            var workshopTariffs = _a.workshopTariffs, bookedTariffs = _a.bookedTariffs;
            return bookedTariffs.filter(function (bookedTariff) {
                var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonId = bookedTariff.ticketPersonId, voucherCode = bookedTariff.voucherCode, packageNumber = bookedTariff.packageNumber, packageIndex = bookedTariff.packageIndex;
                return workshopTariffs.some(function (workshopTariff) {
                    return workshopTariff.ticketTypeId === ticketTypeId &&
                        workshopTariff.ticketPersonId === ticketPersonId &&
                        workshopTariff.voucherCode === voucherCode &&
                        workshopTariff.packageNumber === packageNumber &&
                        workshopTariff.packageIndex === packageIndex;
                });
            });
        }));
    };
    WorkshopService.prototype.getBookedProductsAllowedWorkshopIds$ = function () {
        return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(map(function (_a) {
            var workshopTariffs = _a.workshopTariffs, bookedTariffs = _a.bookedTariffs;
            var bookedProductsAllowedWorkshopIds = [];
            bookedTariffs.forEach(function (_a) {
                var ticketPersonId = _a.ticketPersonId;
                var workshopTariff = workshopTariffs.find(function (workshopTariff) { return workshopTariff.ticketPersonId === ticketPersonId; });
                if (workshopTariff) {
                    workshopTariff.allowedWorkshops.forEach(function (allowedWorkshopId) {
                        return bookedProductsAllowedWorkshopIds.push(allowedWorkshopId);
                    });
                }
            });
            return _.uniq(bookedProductsAllowedWorkshopIds);
        }));
    };
    WorkshopService.prototype.setWorkshopBookingHolder = function (workshopTariffHolder, workshopProductList) {
        if (!workshopTariffHolder.isHolderAssigned) {
            var bookingWorkshops = this.bookingService.mapWorkshopTariffHoldersToSetBookingWorkshops([workshopTariffHolder], workshopProductList);
            this.store.dispatch(new SetBookingWorkshop(bookingWorkshops));
        }
        else {
            var removeWorkshops = this.bookingService.mapWorkshopTariffHoldersToRemoveBookingWorkshops([
                workshopTariffHolder
            ]);
            this.store.dispatch(new RemoveBookingWorkshop(removeWorkshops));
        }
    };
    WorkshopService.prototype.setAllWorkshopBookingHolders = function (areAllWorkshopTariffHoldersAssigned, workshopTariffHolders, workshopProductList) {
        if (!areAllWorkshopTariffHoldersAssigned) {
            var bookingWorkshops = this.bookingService.mapWorkshopTariffHoldersToSetBookingWorkshops(workshopTariffHolders, workshopProductList);
            this.store.dispatch(new SetBookingWorkshop(bookingWorkshops));
        }
        else {
            var removeWorkshops = this.bookingService.mapWorkshopTariffHoldersToRemoveBookingWorkshops(workshopTariffHolders);
            this.store.dispatch(new RemoveBookingWorkshop(removeWorkshops));
        }
    };
    WorkshopService.prototype.getSortedBookedProductSelectionBookedWorkshopTariffs$ = function () {
        return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(map(function (_a) {
            var workshopTariffs = _a.workshopTariffs, bookedTariffs = _a.bookedTariffs;
            var sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder = [];
            workshopTariffs.forEach(function (workshopTariff) {
                var ticketTypeId = workshopTariff.ticketTypeId, ticketPersonId = workshopTariff.ticketPersonId, voucherCode = workshopTariff.voucherCode, packageNumber = workshopTariff.packageNumber, packageIndex = workshopTariff.packageIndex;
                var bookedWorkshopTariff = bookedTariffs.find(function (bookedTariff) {
                    return bookedTariff.ticketTypeId === ticketTypeId &&
                        bookedTariff.ticketPersonId === ticketPersonId &&
                        bookedTariff.voucherCode === voucherCode &&
                        bookedTariff.packageNumber === packageNumber &&
                        bookedTariff.packageIndex === packageIndex;
                });
                if (bookedWorkshopTariff) {
                    sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder.push(bookedWorkshopTariff);
                }
            });
            return sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder;
        }));
    };
    WorkshopService.prototype.getProductSelectionWorkshopTariffsAndAllBookedTariffs$ = function () {
        return combineLatest([
            this.store.pipe(select(getAllTariffsWithWorkshops)),
            this.store.pipe(select(getAllBookedTariffs))
        ]).pipe(map(function (_a) {
            var workshopTariffs = _a[0], bookedTariffs = _a[1];
            return ({ workshopTariffs: workshopTariffs, bookedTariffs: bookedTariffs });
        }));
    };
    WorkshopService.prototype.getBookedWorkshopTariffHolderUuids$ = function () {
        return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(map(function (_a) {
            var workshopTariffs = _a.workshopTariffs, bookedTariffs = _a.bookedTariffs;
            var workshopTariffHolderUuidsGroupedByWorkshopIds = {};
            bookedTariffs.forEach(function (bookedTariff) {
                var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonId = bookedTariff.ticketPersonId, voucherCode = bookedTariff.voucherCode, packageNumber = bookedTariff.packageNumber, packageIndex = bookedTariff.packageIndex;
                var workshopTariff = workshopTariffs.find(function (workshopTariff) {
                    return workshopTariff.ticketTypeId === ticketTypeId &&
                        workshopTariff.ticketPersonId === ticketPersonId &&
                        workshopTariff.voucherCode === voucherCode &&
                        workshopTariff.packageNumber === packageNumber &&
                        workshopTariff.packageIndex === packageIndex;
                });
                if (workshopTariff) {
                    workshopTariff.allowedWorkshops.forEach(function (allowedWorkshopId) {
                        var bookedWorkshopTariffHolderUuids = workshopTariffHolderUuidsGroupedByWorkshopIds[allowedWorkshopId] || [];
                        workshopTariffHolderUuidsGroupedByWorkshopIds[allowedWorkshopId] = bookedWorkshopTariffHolderUuids.concat(bookedTariff.holderUuids);
                    });
                }
            });
            return workshopTariffHolderUuidsGroupedByWorkshopIds;
        }));
    };
    WorkshopService.prototype.getBookedProductSelectionWorkshopDetailTariffHolders$ = function (workshopId) {
        var _this = this;
        return combineLatest([
            this.getSortedBookedProductSelectionBookedWorkshopTariffs$(),
            this.getBookedWorkshopTariffHolderUuids$(),
            this.store.pipe(select(getTicketHolderInputSets))
        ]).pipe(map(function (_a) {
            var sortedBookedWorkshopTariffs = _a[0], bookedTariffWorkshopHolderUuids = _a[1], tariffHolderInputSets = _a[2];
            var allWorkshopHolderUuids = bookedTariffWorkshopHolderUuids[workshopId];
            var workshopTariffHolders = [];
            sortedBookedWorkshopTariffs.forEach(function (bookedTariff) {
                bookedTariff.holderUuids.forEach(function (bookedTariffHolderUuid) {
                    if (allWorkshopHolderUuids.includes(bookedTariffHolderUuid)) {
                        var workshopTariffHolder_1 = workshopTariffHolders.find(function (workshopTariffHolder) { return workshopTariffHolder.holderUuid === bookedTariffHolderUuid; });
                        if (!workshopTariffHolder_1) {
                            var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonId = bookedTariff.ticketPersonId, voucherCode = bookedTariff.voucherCode, packageNumber = bookedTariff.packageNumber, packageIndex = bookedTariff.packageIndex, ticketTypeName = bookedTariff.ticketTypeName, ticketName = bookedTariff.ticketName;
                            var _a = _this.mapTariffHolderInputsToWorkshopHolder(tariffHolderInputSets, bookedTariffHolderUuid), holderUuid = _a.holderUuid, firstName = _a.firstName, lastName = _a.lastName;
                            workshopTariffHolder_1 = {
                                holderUuid: holderUuid,
                                firstName: firstName,
                                lastName: lastName,
                                ticketTypeId: ticketTypeId,
                                ticketPersonId: ticketPersonId,
                                voucherCode: voucherCode,
                                packageNumber: packageNumber,
                                packageIndex: packageIndex,
                                ticketTypeName: ticketTypeName,
                                ticketName: ticketName,
                                workshopId: workshopId,
                                isHolderAssigned: false,
                                assignedWorkshops: []
                            };
                        }
                        bookedTariff.workshops.forEach(function (_a) {
                            var id = _a.id, holderUuids = _a.holderUuids;
                            if (holderUuids.includes(bookedTariffHolderUuid)) {
                                if (!workshopTariffHolder_1.isHolderAssigned) {
                                    workshopTariffHolder_1.isHolderAssigned = id == workshopId;
                                }
                                workshopTariffHolder_1.assignedWorkshops.push(id);
                            }
                        });
                        workshopTariffHolders.push(workshopTariffHolder_1);
                    }
                });
            });
            return workshopTariffHolders;
        }));
    };
    WorkshopService.prototype.mapTariffHolderInputsToWorkshopHolder = function (tariffHolderInputSets, holderUuid) {
        var workshopHolder = { holderUuid: '', firstName: '', lastName: '' };
        var mapHolderFormInputs = Object.keys(workshopHolder);
        tariffHolderInputSets.forEach(function (tariffHolderInputSet) {
            if (workshopHolder.firstName && workshopHolder.lastName) {
                return;
            }
            if (tariffHolderInputSet.holderUuid === holderUuid) {
                workshopHolder.holderUuid = holderUuid;
                tariffHolderInputSet.inputSet.list.forEach(function (holderInput) {
                    if (mapHolderFormInputs.includes(holderInput.key)) {
                        workshopHolder[holderInput.key] = holderInput.value || '';
                    }
                });
            }
        });
        return workshopHolder;
    };
    WorkshopService.ngInjectableDef = i0.defineInjectable({ factory: function WorkshopService_Factory() { return new WorkshopService(i0.inject(i1.Store), i0.inject(i2.BookingService)); }, token: WorkshopService, providedIn: "root" });
    return WorkshopService;
}());
export { WorkshopService };
