import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySettingsModel } from '@store/customization/customization.interfaces';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {
  transform(
    price: number,
    currencySettings: CurrencySettingsModel,
    showCurrencySymbol: boolean = true,
    addDecimalPlaces = true,
    numberOfDecimalPlaces: number = 2
  ): string {
    if (isNaN(price)) {
      price = 0;
    }

    const { currencyCode, decimalMark, thousandsSeparator } = currencySettings;

    const priceString = addDecimalPlaces ? price.toFixed(numberOfDecimalPlaces) : price.toString();

    const numberParts = priceString.split('.');
    numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    const formattedPrice = numberParts.join(decimalMark);

    return showCurrencySymbol ? `${formattedPrice} ${currencyCode}` : formattedPrice;
  }
}
