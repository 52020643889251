import { Actions, ActionTypes } from './customization.actions';

import cloneDeep from 'lodash.clonedeep';
import { createSelector } from 'reselect';
import { WidgetSettings } from '../../../_pages/widget-admin/widget-models.interface';
import {
  CssSkinModel,
  ExhibitionSettingModel,
  LocalizedImagesModel,
  OperatorsSettingsModel,
  SponsorBannerModel
} from './customization.interfaces';

export const themeDefault = require('../../../../assets/admin/theme-default.json');

export interface State {
  exhibitionSettings: ExhibitionSettingModel;
  operatorsSettings: OperatorsSettingsModel;
  sponsorBanner: SponsorBannerModel;
  exhibitionStyles: CssSkinModel;
  localizedImages: LocalizedImagesModel;
  orderUuid: string;
  widgetSettings: WidgetSettings;
  shoppingStartTime: Date;
}

export const initialState: State = {
  exhibitionSettings: null,
  operatorsSettings: null,
  sponsorBanner: null,
  exhibitionStyles: {
    priority: null,
    css: null,
    variables: null
  },
  localizedImages: null,
  orderUuid: null,
  widgetSettings: null,
  shoppingStartTime: null
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_EXHIBITION_SETTINGS:
      return {
        ...state,
        exhibitionSettings: action.payload
      };

    case ActionTypes.SET_EXHIBITION_STYLES:
      const oldStyles = state.exhibitionStyles;
      let { cssSkin, isAdmin } = action.payload;

      // in case the old skin has higher priority than the new one, keep the old skin
      // in case skin doesnt have any colors defined (variables) we use different one in priority
      // Admin always change the colors
      if (
        !oldStyles ||
        !oldStyles.priority ||
        cssSkin.priority > oldStyles.priority ||
        isAdmin
      ) {
        return {
          ...state,
          exhibitionStyles: {
            ...cssSkin,
            variables: { ...themeDefault, ...cssSkin.variables }
          }
        };
      }

      return state;

    case ActionTypes.SET_OPERATORS_SETTINGS:
      return {
        ...state,
        operatorsSettings: action.payload
      };

    case ActionTypes.SET_RANDOM_SPONSOR:
      return {
        ...state,
        sponsorBanner: action.payload
      };

    case ActionTypes.SET_LOCALIZED_IMAGES:
      return {
        ...state,
        localizedImages: action.payload
      };

    case ActionTypes.SET_ORDER_UUID:
      return {
        ...state,
        orderUuid: action.payload
      };

    case ActionTypes.SET_SHOPPING_START_TIME: {
      if (state.shoppingStartTime) return state;

      return {
        ...state,
        shoppingStartTime: new Date()
      };
    }

    case ActionTypes.RESET_SHOPPING_START_TIME: {
      return {
        ...state,
        shoppingStartTime: null
      };
    }

    case ActionTypes.SET_WIDGET_SETTINGS:
      const widgetType = action.payload.type;
      const widgetSettings = action.payload.settings;
      const updatedSettings = { [widgetType]: widgetSettings };

      return {
        ...state,
        widgetSettings: { ...state.widgetSettings, ...updatedSettings }
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    case ActionTypes.PARTIAL_RESET_REDUCER:
      return {
        ...clonedInitialState,
        localizedImages: state.localizedImages,
        operatorsSettings: state.operatorsSettings,
        shoppingStartTime: state.shoppingStartTime
      };

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getExhibitionSettings = (state: State) => state.exhibitionSettings;
export const getTariffReleaseInMinutes = (state: State) => state.exhibitionSettings ? state.exhibitionSettings.ticketReleaseInMinutes : 0;
export const getOperatorsSettings = (state: State) => state.operatorsSettings;
export const getWidgetSettings = (state: State) => state.widgetSettings;
export const getSponsorBanner = (state: State) => state.sponsorBanner;
export const getExhibitionStyles = (state: State) => state.exhibitionStyles;
export const getLocalizedImages = (state: State) => state.localizedImages;
export const getOrderUuid = (state: State) => state.orderUuid;
export const getShoppingStartTime = (state: State) => state.shoppingStartTime;
export const ageRating = (stateSlice: ExhibitionSettingModel) =>
  stateSlice && stateSlice.ageRating;
export const uniqueVisitorCheckType = (stateSlice: ExhibitionSettingModel) =>
  stateSlice && stateSlice.uniqueVisitorCheckType;
export const isDownloadTicketButtonVisible = (
  stateSlice: ExhibitionSettingModel
) => stateSlice && stateSlice.isDownloadTicketButtonVisible;
export const getTicketLimitPerUserAccount = (stateSlice: ExhibitionSettingModel) =>
  stateSlice && stateSlice.ticketLimitPerUserAccount;

export const isReadonlyBuyer = createSelector(
  getExhibitionSettings,
  settings => {
    if (settings) {
      return settings.buyerSettings.isReadonlyBuyer;
    }
    return null;
  }
);

export const getLoginMode = createSelector(
  getExhibitionSettings,
  getOperatorsSettings,
  (eventSettings, operatorSettings) => {
    if (eventSettings) {
      let result = eventSettings.loginMode; // "loginMode": "none", "beforeTicketSelection", "optionalTicketLogin", "optionalPersonalLogin", "optionalTicketAndPersonalLogin", "hideTopBarLogin"
      if (result === 'none') {
        result = '';
      }
      return result;
    } else if (
      operatorSettings &&
      operatorSettings.hasOwnProperty('loginMode')
    ) {
      let result = operatorSettings.loginMode;
      if (result === 'none') {
        result = '';
      }
      return result;
    }

    return '';
  }
);
