import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State, getProfile } from '../../app.reducer';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';

@Component({
  selector: 'app-web-shop-account-verification',
  templateUrl: './web-shop-account-verification.component.html',
  styleUrls: ['./web-shop-account-verification.component.scss']
})
export class WebShopAccountVerificationComponent implements OnInit {
  userProfile$: Observable<UserProfileModel>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.userProfile$ = this.store.pipe(select(getProfile));
  }
}
