/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-holder-questionnaire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/forms/df-input/df-input.component.ngfactory";
import * as i3 from "../../../shared/forms/df-input/df-input.component";
import * as i4 from "../../../shared/forms/forms.service";
import * as i5 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../shared/validation/validation.service";
import * as i8 from "../../../shared/directives/adjust-side-headline/adjust-side-headline.directive";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/common";
import * as i12 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i13 from "./ticket-holder-questionnaire.component";
var styles_TicketHolderQuestionnaireComponent = [i0.styles];
var RenderType_TicketHolderQuestionnaireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketHolderQuestionnaireComponent, data: {} });
export { RenderType_TicketHolderQuestionnaireComponent as RenderType_TicketHolderQuestionnaireComponent };
function View_TicketHolderQuestionnaireComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i2.View_DfInputComponent_0, i2.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i3.DfInputComponent, [i4.FormsService, i5.HelperService, i6.Store, i1.ElementRef, i1.Renderer2, i7.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.inputs; var currVal_3 = _co.customAction; var currVal_4 = _co.form; var currVal_5 = _co.inputsChanged; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_TicketHolderQuestionnaireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["appAdjustSideHeadline", ""]], null, null, null, null, null)), i1.ɵdid(1, 4210688, null, 0, i8.AdjustSideHeadlineDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "section-headline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h2", [["data-translation", "personalize.ticket-holder.section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "section-headline-side"], ["data-translation", "personalize.ticket-holder.headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "section-body visitor-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "form", [["class", "row"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i10.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(14, 540672, null, 0, i10.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i10.ControlContainer, null, [i10.FormGroupDirective]), i1.ɵdid(16, 16384, null, 0, i10.NgControlStatusGroup, [[4, i10.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketHolderQuestionnaireComponent_2)), i1.ɵdid(18, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.form; _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.inputs; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("personalize.ticket-holder.section-title")))); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("personalize.ticket-holder.headline")))); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 16).ngClassValid; var currVal_7 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TicketHolderQuestionnaireComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketHolderQuestionnaireComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputs && _co.inputs.length); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TicketHolderQuestionnaireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-holder-questionnaire", [], null, null, null, View_TicketHolderQuestionnaireComponent_0, RenderType_TicketHolderQuestionnaireComponent)), i1.ɵdid(1, 245760, null, 0, i13.TicketHolderQuestionnaireComponent, [i6.Store, i4.FormsService, i5.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketHolderQuestionnaireComponentNgFactory = i1.ɵccf("app-ticket-holder-questionnaire", i13.TicketHolderQuestionnaireComponent, View_TicketHolderQuestionnaireComponent_Host_0, {}, { isValid: "isValid" }, []);
export { TicketHolderQuestionnaireComponentNgFactory as TicketHolderQuestionnaireComponentNgFactory };
