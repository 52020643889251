import { Injectable } from '@angular/core';
import { State } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookingTariff,
  SetBookingContingentReservation,
  SetProductBookingContingentReservation
} from '@products/models/booking.model';
import { RemoveContingentReservation } from '@products/models/contingent.model';
import { getProductType } from '@products/models/products.model';
import { Tariff } from '@products/models/tariff.model';
import { BookingService } from '@products/services//booking.service';
import { VoucherService } from '@products/services/voucher.service';
import { convertStringToDate } from '@shared/app-utils';
import { getAllBookedContingentTariffs } from '@store/products/booking/booking.selectors';
import { getAllTariffs } from '@store/products/product-selection/product-selection.selectors';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(
    private store: Store<State>,
    private bookingService: BookingService,
    private voucherService: VoucherService
  ) {}

  getSortedBookingContingentReservationsByProductSelectionListOrder$(
    removeContingentReservations: RemoveContingentReservation[]
  ): Observable<SetProductBookingContingentReservation[]> {
    return combineLatest([
      this.store.pipe(select(getAllTariffs)),
      this.store.pipe(select(getAllBookedContingentTariffs))
    ]).pipe(
      first(([allTariffs, allBookedContingentTariffs]) => !!allTariffs.length && !!allBookedContingentTariffs),
      map(([productSelectionTariffs, bookingContingentTariffs]) => {
        const sortedSetProductBookingContingentReservationsByProductSelectionListOrder: SetProductBookingContingentReservation[] = [];

        productSelectionTariffs.forEach((tariff: Tariff) => {
          const { ticketTypeId, ticketPersonId, ticketPersonTypeId, voucherCode, packageNumber, packageIndex } = tariff;

          const bookedTariff: BookingTariff = bookingContingentTariffs.find(
            bookedTariff =>
              bookedTariff.ticketTypeId === ticketTypeId &&
              bookedTariff.ticketPersonId === ticketPersonId &&
              bookedTariff.voucherCode === voucherCode &&
              bookedTariff.packageNumber === packageNumber &&
              bookedTariff.packageIndex === packageIndex
          );

          if (!!bookedTariff) {
            const removeCurrentBookingTariffContingentReservation = removeContingentReservations.find(
              removeContingentReservation =>
                removeContingentReservation.ticketTypeId === ticketTypeId &&
                removeContingentReservation.ticketPersonId === ticketPersonId &&
                removeContingentReservation.voucherCode === voucherCode &&
                removeContingentReservation.packageNumber === packageNumber &&
                removeContingentReservation.packageIndex === packageIndex
            );

            const setProductBookingContingentReservation: SetProductBookingContingentReservation = {
              productType: getProductType(bookedTariff),
              ticketTypeId,
              ticketPersonId,
              ticketPersonTypeId,
              voucherCode,
              packageNumber,
              packageIndex,
              contingentReservations: []
            };

            bookedTariff.contingentReservations.forEach((contingentReservation, bookingReservationIndex) => {
              if (
                removeCurrentBookingTariffContingentReservation &&
                removeCurrentBookingTariffContingentReservation.removeBookingReservationIndexes.includes(
                  bookingReservationIndex
                )
              ) {
                return;
              }

              const setBookingContingentReservation: SetBookingContingentReservation = {
                ...contingentReservation,
                duration: tariff.durationInDays,
                toDate: convertStringToDate(tariff.validTill),
                bookingReservationIndex
              };

              setProductBookingContingentReservation.contingentReservations.push(setBookingContingentReservation);
            });

            sortedSetProductBookingContingentReservationsByProductSelectionListOrder.push(
              setProductBookingContingentReservation
            );
          }
        });

        return sortedSetProductBookingContingentReservationsByProductSelectionListOrder;
      })
    );
  }

  releaseAllVouchersAndProductBookings() {
    this.bookingService
      .removeAllProductBookings$()
      .pipe(
        first(),
        switchMap(({ removePostProductBookings }) => {
          return forkJoin([
            this.bookingService.postProductBookings$(removePostProductBookings),
            this.voucherService.releaseAllVouchers$()
          ]);
        })
      )
      .subscribe();
  }
}
