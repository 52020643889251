import { Component, Input, OnInit } from '@angular/core';
import { State } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { WorkshopProduct } from '@products/models/workshop.model';
import {
  getAllBookedProductsCount,
  getAssignedWorkshopHolderUuidsByWorkshopId
} from '@store/products/booking/booking.selectors';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-workshop-visitors-count',
  templateUrl: './workshop-visitors-count.component.html',
  styleUrls: ['./workshop-visitors-count.component.scss']
})
export class WorkshopVisitorsCountComponent implements OnInit {
  @Input() workshop: WorkshopProduct;
  @Input() availableSeats: number;
  @Input() showAmountOfAvailableWorkshops: boolean;
  workshopVisitorsCountViewModel$: Observable<{
    numberOfAssignedWorkshopHolderUuids: number;
    isLastSeatWithSingleAssignedTariffBooking: boolean;
  }>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.workshopVisitorsCountViewModel$ = combineLatest([
      this.store.pipe(select(getAllBookedProductsCount)),
      this.store.pipe(select(getAssignedWorkshopHolderUuidsByWorkshopId(this.workshop.workshopId)))
    ]).pipe(
      map(([allBookedProductsCount, assignedWorkshopHolderUuids]) => ({
        numberOfAssignedWorkshopHolderUuids: assignedWorkshopHolderUuids.length,
        isLastSeatWithSingleAssignedTariffBooking:
          this.availableSeats === 0 && allBookedProductsCount === 1 && assignedWorkshopHolderUuids.length === 1
      }))
    );
  }
}
