/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workshops-visitors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../loader/loader.component.ngfactory";
import * as i5 from "../../../loader/loader.component";
import * as i6 from "../../../shared/modal-window/modal-window.component.ngfactory";
import * as i7 from "../../../shared/modal-window/modal-window.component";
import * as i8 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i9 from "./workshops-visitors.component";
import * as i10 from "../../../status-bar/status-bar.service";
import * as i11 from "../../products/services/workshop.service";
import * as i12 from "../../products/services/workshop-validation.service";
var styles_WorkshopsVisitorsComponent = [i0.styles];
var RenderType_WorkshopsVisitorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkshopsVisitorsComponent, data: {} });
export { RenderType_WorkshopsVisitorsComponent as RenderType_WorkshopsVisitorsComponent };
function View_WorkshopsVisitorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " - "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.firstName; var currVal_1 = _v.parent.context.$implicit.lastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WorkshopsVisitorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "visitor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setWorkshopBookingHolder($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "visitor-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "decorated-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopPropagation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "label", [], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "label", [], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsVisitorsComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, null, [" ", " ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 1), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1)], function (_ck, _v) { var currVal_4 = (_v.context.$implicit.firstName && _v.context.$implicit.lastName); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("visitor_workshop_" + _co.workshop.workshopId) + "_") + _v.context.$implicit.holderUuid); var currVal_1 = _v.context.$implicit.isHolderAssigned; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ((("visitor_workshop_" + _co.workshop.workshopId) + "_") + _v.context.$implicit.holderUuid); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((("visitor_workshop_" + _co.workshop.workshopId) + "_") + _v.context.$implicit.holderUuid); _ck(_v, 6, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_v.context.$implicit.ticketTypeName)))); var currVal_6 = i1.ɵunv(_v, 9, 1, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 12).transform(_v.context.$implicit.ticketName)))); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
function View_WorkshopsVisitorsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [["isFixed", "true"]], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoaderComponent, [i1.ElementRef], { isFixed: [0, "isFixed"] }, null)], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_WorkshopsVisitorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { visibility: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "select-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setAllWorkshopBookingHolders($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "decorated-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["id", "check_all_tickets"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopPropagation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "label", [["for", "check_all_tickets"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "label", [["for", "check_all_tickets"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsVisitorsComponent_2)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 6, "app-modal-window", [["translateTitle", "true"]], null, null, null, i6.View_ModalWindowComponent_0, i6.RenderType_ModalWindowComponent)), i1.ɵdid(15, 49152, null, 0, i7.ModalWindowComponent, [], { modalWindowActive: [0, "modalWindowActive"], modalWindowTitle: [1, "modalWindowTitle"], translateTitle: [2, "translateTitle"] }, null), (_l()(), i1.ɵeld(16, 0, null, 3, 4, "button", [["class", "button button-option"], ["modalWindow-close", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalWindow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "span", [["data-translation", "workshop.modalWindow.close"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(20, 1), (_l()(), i1.ɵeld(21, 0, null, null, 6, "app-modal-window", [["translateTitle", "true"]], null, null, null, i6.View_ModalWindowComponent_0, i6.RenderType_ModalWindowComponent)), i1.ɵdid(22, 49152, null, 0, i7.ModalWindowComponent, [], { modalWindowActive: [0, "modalWindowActive"], modalWindowTitle: [1, "modalWindowTitle"], translateTitle: [2, "translateTitle"] }, null), (_l()(), i1.ɵeld(23, 0, null, 3, 4, "button", [["class", "button button-option"], ["modalWindow-close", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalWindow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "span", [["data-translation", "workshop.modalWindow.close"]], null, null, null, null, null)), (_l()(), i1.ɵted(25, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(27, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsVisitorsComponent_4)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.bookingLoading ? "hidden" : "visible")); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.workshopTariffHolders; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.workshopValidationState.isWorkshopLimitPerTariffHolderReached; var currVal_5 = "workshop.limitReached.modalWindow.title"; var currVal_6 = "true"; _ck(_v, 15, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.workshopValidationState.areWorkshopsOverlapping; var currVal_9 = "workshop.modalWindow.title"; var currVal_10 = "true"; _ck(_v, 22, 0, currVal_8, currVal_9, currVal_10); var currVal_12 = _co.isWorkshopLoading; _ck(_v, 29, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.areAllWorkshopTariffHoldersAssigned; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("ticket.assign-all")); _ck(_v, 10, 0, currVal_2); var currVal_7 = i1.ɵunv(_v, 18, 0, _ck(_v, 20, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("workshop.modalWindow.close")))); _ck(_v, 18, 0, currVal_7); var currVal_11 = i1.ɵunv(_v, 25, 0, _ck(_v, 27, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform("workshop.modalWindow.close")))); _ck(_v, 25, 0, currVal_11); }); }
export function View_WorkshopsVisitorsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsVisitorsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workshopTariffHolders.length; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WorkshopsVisitorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workshops-visitors", [], null, null, null, View_WorkshopsVisitorsComponent_0, RenderType_WorkshopsVisitorsComponent)), i1.ɵdid(1, 245760, null, 0, i9.WorkshopsVisitorsComponent, [i3.TranslateService, i10.StatusBarService, i11.WorkshopService, i12.WorkshopValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkshopsVisitorsComponentNgFactory = i1.ɵccf("app-workshops-visitors", i9.WorkshopsVisitorsComponent, View_WorkshopsVisitorsComponent_Host_0, { workshop: "workshop", workshopsList: "workshopsList", workshopTariffHolders: "workshopTariffHolders", areAllWorkshopTariffHoldersAssigned: "areAllWorkshopTariffHoldersAssigned", availableSeats: "availableSeats", isWorkshopLoading: "isWorkshopLoading", limitWorkshopPerTicket: "limitWorkshopPerTicket", enableWorkshopSelectionOverlapping: "enableWorkshopSelectionOverlapping" }, {}, []);
export { WorkshopsVisitorsComponentNgFactory as WorkshopsVisitorsComponentNgFactory };
