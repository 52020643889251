import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '../../_pages/products/models/booking.model';
import { VoucherService } from '../../_pages/products/services/voucher.service';
import { getExhibitionSettings, getOrderUuid } from '../../app.reducer';
import { getAllBookedTariffs, getBookedTariffs } from '../services-with-reducers/products/booking/booking.selectors';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ngrx/store";
import * as i3 from "../../_pages/products/services/voucher.service";
var EventName;
(function (EventName) {
    EventName["AddToCart"] = "addToCart";
    EventName["RemoveFromCart"] = "removeFromCart";
    EventName["ProductDetail"] = "productDetail";
    EventName["Checkout"] = "checkout";
    EventName["BeginCheckout"] = "begin_checkout";
    EventName["CheckoutOption"] = "checkoutOption";
    EventName["Purchase"] = "purchase";
})(EventName || (EventName = {}));
var GtmService = /** @class */ (function () {
    function GtmService(translateService, store, voucherService) {
        this.translateService = translateService;
        this.store = store;
        this.voucherService = voucherService;
        this.uaPrefix = 'eec';
        this.ga4Prefix = 'eecga4';
    }
    GtmService.prototype.pushProductDetail = function () {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.ProductDetail,
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'Ticketauswahl'
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.ProductDetail,
            ecommerce: {
                item_list_name: 'Ticketauswahl',
                action: 'detail'
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushAddToCart = function (bookingPackageOrTariff) {
        var _this = this;
        var bookingTariffs = this.mapBookingProductToBookingTariffs(bookingPackageOrTariff);
        this.store
            .pipe(select(getExhibitionSettings), first(function (data) { return !!data; }))
            .subscribe(function (settings) {
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.AddToCart,
                ecommerce: {
                    add: {
                        actionField: {
                            list: 'Shopping cart'
                        },
                        products: _this.ticketsToProducts(settings.eventId, bookingTariffs)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.AddToCart,
                ecommerce: {
                    item_list_name: 'Shopping cart',
                    items: _this.ticketsToItems(settings.eventId, bookingTariffs)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushRemoveFromCart = function (bookingPackageOrTariff) {
        var _this = this;
        var bookingTariffs = this.mapBookingProductToBookingTariffs(bookingPackageOrTariff);
        this.store
            .pipe(select(getExhibitionSettings), first(function (data) { return !!data; }))
            .subscribe(function (settings) {
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.RemoveFromCart,
                ecommerce: {
                    remove: {
                        actionField: {
                            list: 'Shopping cart'
                        },
                        products: _this.ticketsToProducts(settings.eventId, bookingTariffs)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.RemoveFromCart,
                ecommerce: {
                    item_list_name: 'Shopping cart',
                    items: _this.ticketsToItems(settings.eventId, bookingTariffs)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushCheckout = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getAllBookedTariffs))
        ])
            .pipe(first(function (data) { return data.every(function (item) { return !!item; }); }))
            .subscribe(function (_a) {
            var settings = _a[0], bookedTariffs = _a[1];
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Checkout,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        },
                        products: _this.ticketsToProducts(settings.eventId, bookedTariffs)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.BeginCheckout,
                ecommerce: {
                    step: 1,
                    items: _this.ticketsToItems(settings.eventId, bookedTariffs)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushPersonalization = function () {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.Checkout,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 2
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.BeginCheckout,
            ecommerce: {
                step: 2
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushConfirmation = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getAllBookedTariffs)),
            this.store.pipe(select(getOrderUuid))
        ])
            .pipe(first(function (data) { return data.every(function (item) { return !!item; }); }))
            .subscribe(function (_a) {
            var exhibitionSettings = _a[0], tariffs = _a[1], orderUuid = _a[2];
            var products = _this.ticketsToProducts(exhibitionSettings.eventId, tariffs);
            var revenue = products.reduce(function (revenue, product) { return revenue + product.price * 100 * product.quantity; }, 0) / 100;
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Checkout,
                ecommerce: {
                    currencyCode: exhibitionSettings.currencySettings.currencyCode,
                    checkout: {
                        actionField: {
                            id: orderUuid,
                            revenue: revenue,
                            step: 3
                        },
                        products: products
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.BeginCheckout,
                ecommerce: {
                    transaction_id: orderUuid,
                    value: revenue,
                    currency: exhibitionSettings.currencySettings.currencyCode,
                    step: 3,
                    items: _this.ticketsToItems(exhibitionSettings.eventId, tariffs)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushCheckoutOption = function (paymentOption) {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.CheckoutOption,
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 3,
                        option: paymentOption
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.CheckoutOption,
            ecommerce: {
                step: 3,
                action: 'checkout_option',
                option: paymentOption
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushPurchase = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getBookedTariffs)),
            this.store.pipe(select(getOrderUuid))
        ])
            .pipe(first(function (data) { return data.every(function (item) { return !!item; }); }))
            .subscribe(function (_a) {
            var exhibitionSettings = _a[0], tariffs = _a[1], orderUuid = _a[2];
            var products = _this.ticketsToProducts(exhibitionSettings.eventId, tariffs);
            var revenue = products.reduce(function (revenue, product) { return revenue + product.price * 100 * product.quantity; }, 0) / 100;
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Purchase,
                ecommerce: {
                    currencyCode: exhibitionSettings.currencySettings.currencyCode,
                    checkout: {
                        actionField: {
                            id: orderUuid,
                            revenue: revenue
                        },
                        products: products
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.Purchase,
                ecommerce: {
                    transaction_id: orderUuid,
                    value: revenue,
                    currency: exhibitionSettings.currencySettings.currencyCode,
                    items: _this.ticketsToItems(exhibitionSettings.eventId, tariffs)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.ticketsToProducts = function (eventId, tariffs) {
        var _this = this;
        return tariffs.map(function (tariff) {
            var name = _this.translateService.instant(tariff.ticketName);
            var category = tariff.ticketTypeName ? _this.translateService.instant(tariff.ticketTypeName) : '';
            return {
                id: tariff.ticketPersonTypeId,
                name: name,
                category: category,
                quantity: tariff.count,
                brand: eventId,
                coupon: tariff.voucherCode,
                price: tariff.price
            };
        });
    };
    GtmService.prototype.ticketsToItems = function (eventId, tariffs) {
        var _this = this;
        return tariffs.map(function (tariff) {
            var name = _this.translateService.instant(tariff.ticketName);
            var category = tariff.ticketTypeName ? _this.translateService.instant(tariff.ticketTypeName) : '';
            return {
                item_id: tariff.ticketPersonTypeId,
                item_name: name,
                item_category: category,
                quantity: tariff.count,
                item_brand: eventId,
                coupon: tariff.voucherCode,
                price: tariff.price
            };
        });
    };
    GtmService.prototype.pushToDataLayer = function (data) {
        window['dataLayer'] = window['dataLayer'] || [];
        //clearing the ecommerce object
        //it's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other:
        window['dataLayer'].push({ ecommerce: null });
        //push new data:
        window['dataLayer'].push(data);
    };
    GtmService.prototype.mapBookingProductToBookingTariffs = function (bookingPackageOrTariff) {
        if (isBookingProductTypePackage(bookingPackageOrTariff)) {
            var bookingTariffs_1 = [];
            bookingPackageOrTariff.productTypes.forEach(function (bookingProductType) {
                if (isBookingProductTypeTariff(bookingProductType)) {
                    bookingTariffs_1.push.apply(bookingTariffs_1, bookingProductType.tariffs);
                }
            });
            return bookingTariffs_1;
        }
        else if (isBookingProductTypeTariff(bookingPackageOrTariff)) {
            return this.getFirstBookingTariff(bookingPackageOrTariff.tariffs);
        }
    };
    GtmService.prototype.getFirstBookingTariff = function (bookingTariffs) {
        return [bookingTariffs.length && bookingTariffs[0]];
    };
    GtmService.ngInjectableDef = i0.defineInjectable({ factory: function GtmService_Factory() { return new GtmService(i0.inject(i1.TranslateService), i0.inject(i2.Store), i0.inject(i3.VoucherService)); }, token: GtmService, providedIn: "root" });
    return GtmService;
}());
export { GtmService };
