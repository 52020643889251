var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './customization.actions';
import cloneDeep from 'lodash.clonedeep';
import { createSelector } from 'reselect';
export var themeDefault = require('../../../../assets/admin/theme-default.json');
export var initialState = {
    exhibitionSettings: null,
    operatorsSettings: null,
    sponsorBanner: null,
    exhibitionStyles: {
        priority: null,
        css: null,
        variables: null
    },
    localizedImages: null,
    orderUuid: null,
    widgetSettings: null,
    shoppingStartTime: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_EXHIBITION_SETTINGS:
            return __assign({}, state, { exhibitionSettings: action.payload });
        case ActionTypes.SET_EXHIBITION_STYLES:
            var oldStyles = state.exhibitionStyles;
            var _b = action.payload, cssSkin = _b.cssSkin, isAdmin = _b.isAdmin;
            // in case the old skin has higher priority than the new one, keep the old skin
            // in case skin doesnt have any colors defined (variables) we use different one in priority
            // Admin always change the colors
            if (!oldStyles ||
                !oldStyles.priority ||
                cssSkin.priority > oldStyles.priority ||
                isAdmin) {
                return __assign({}, state, { exhibitionStyles: __assign({}, cssSkin, { variables: __assign({}, themeDefault, cssSkin.variables) }) });
            }
            return state;
        case ActionTypes.SET_OPERATORS_SETTINGS:
            return __assign({}, state, { operatorsSettings: action.payload });
        case ActionTypes.SET_RANDOM_SPONSOR:
            return __assign({}, state, { sponsorBanner: action.payload });
        case ActionTypes.SET_LOCALIZED_IMAGES:
            return __assign({}, state, { localizedImages: action.payload });
        case ActionTypes.SET_ORDER_UUID:
            return __assign({}, state, { orderUuid: action.payload });
        case ActionTypes.SET_SHOPPING_START_TIME: {
            if (state.shoppingStartTime)
                return state;
            return __assign({}, state, { shoppingStartTime: new Date() });
        }
        case ActionTypes.RESET_SHOPPING_START_TIME: {
            return __assign({}, state, { shoppingStartTime: null });
        }
        case ActionTypes.SET_WIDGET_SETTINGS:
            var widgetType = action.payload.type;
            var widgetSettings = action.payload.settings;
            var updatedSettings = (_a = {}, _a[widgetType] = widgetSettings, _a);
            return __assign({}, state, { widgetSettings: __assign({}, state.widgetSettings, updatedSettings) });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        case ActionTypes.PARTIAL_RESET_REDUCER:
            return __assign({}, clonedInitialState, { localizedImages: state.localizedImages, operatorsSettings: state.operatorsSettings, shoppingStartTime: state.shoppingStartTime });
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getExhibitionSettings = function (state) { return state.exhibitionSettings; };
export var getTariffReleaseInMinutes = function (state) { return state.exhibitionSettings ? state.exhibitionSettings.ticketReleaseInMinutes : 0; };
export var getOperatorsSettings = function (state) { return state.operatorsSettings; };
export var getWidgetSettings = function (state) { return state.widgetSettings; };
export var getSponsorBanner = function (state) { return state.sponsorBanner; };
export var getExhibitionStyles = function (state) { return state.exhibitionStyles; };
export var getLocalizedImages = function (state) { return state.localizedImages; };
export var getOrderUuid = function (state) { return state.orderUuid; };
export var getShoppingStartTime = function (state) { return state.shoppingStartTime; };
export var ageRating = function (stateSlice) {
    return stateSlice && stateSlice.ageRating;
};
export var uniqueVisitorCheckType = function (stateSlice) {
    return stateSlice && stateSlice.uniqueVisitorCheckType;
};
export var isDownloadTicketButtonVisible = function (stateSlice) { return stateSlice && stateSlice.isDownloadTicketButtonVisible; };
export var getTicketLimitPerUserAccount = function (stateSlice) {
    return stateSlice && stateSlice.ticketLimitPerUserAccount;
};
export var isReadonlyBuyer = createSelector(getExhibitionSettings, function (settings) {
    if (settings) {
        return settings.buyerSettings.isReadonlyBuyer;
    }
    return null;
});
export var getLoginMode = createSelector(getExhibitionSettings, getOperatorsSettings, function (eventSettings, operatorSettings) {
    if (eventSettings) {
        var result = eventSettings.loginMode; // "loginMode": "none", "beforeTicketSelection", "optionalTicketLogin", "optionalPersonalLogin", "optionalTicketAndPersonalLogin", "hideTopBarLogin"
        if (result === 'none') {
            result = '';
        }
        return result;
    }
    else if (operatorSettings &&
        operatorSettings.hasOwnProperty('loginMode')) {
        var result = operatorSettings.loginMode;
        if (result === 'none') {
            result = '';
        }
        return result;
    }
    return '';
});
