import { ActionTypes, Actions } from '@store/translation/translation.actions';
import { TranslationsState } from '@store/translation/translation.model';

export { TranslationsState as State };

export const initialState: TranslationsState = {
  language: '',
  list: {},
  isTranslationLoaded: false
};

export function reducer(state = initialState, action: Actions): TranslationsState {
  switch (action.type) {
    case ActionTypes.SET_TRANSLATIONS:
      const languageAndTranslationList = action.payload;

      return {
        ...state,
        ...languageAndTranslationList
      };

    case ActionTypes.SET_IS_TRANSLATION_LOADED:
      const isTranslationLoaded = action.payload;

      return {
        ...state,
        isTranslationLoaded
      };

    default: {
      return state;
    }
  }
}
