import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getBuyerInfo, getBuyerVisitorCheckbox, getConfirmationCheckboxesInputs, getExhibitionSettings, getOrderResponse, getSelectedExhibitionId, getStepsValidity, getTicketHolderAdditionalData, getTicketHolderInputSets, isTicketHolderVisible } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { ProductType } from '@products/models/products.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { GtmService } from '@shared/gtm/gtmService';
import { CustomizationService } from '@shared/services-with-reducers/customization/customization.service';
import { getAllBookedPackagePricesGroupedByPackageIndex, getAllBookedProductsTotalPrice, getSortedBasketProducts } from '@shared/services-with-reducers/products/booking/booking.selectors';
import { getSendingOptions } from '@shared/services-with-reducers/products/product-selection/product-selection.selectors';
import { SetInputs, SetOrderResponse, setPaymentMethod } from '@shared/services-with-reducers/step-forms/steps-forms.actions';
import { StepsFormsService } from '@shared/services-with-reducers/step-forms/steps-forms.service';
import { UserService } from '@shared/services-with-reducers/user/user.service';
import { getWorkshopProductList } from '@store/products/workshop/workshop.selectors';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';
var WebShopConfirmationComponent = /** @class */ (function () {
    function WebShopConfirmationComponent(store, formsService, stepsFormsService, customizationService, userService, router, el, gtmService) {
        this.store = store;
        this.formsService = formsService;
        this.stepsFormsService = stepsFormsService;
        this.customizationService = customizationService;
        this.userService = userService;
        this.router = router;
        this.el = el;
        this.gtmService = gtmService;
        this.checkboxesFormsActionName = ['confirmation', 'checkboxes'];
        this.totalPrice = 0;
        this.workshops = [];
        this.isAlternativeButtonDisabled = false;
        this.AppConstants = AppConstants;
        this.twintPayment = false;
        this.orderSent = false;
        this.ProductType = ProductType;
        this.paymentMethodImages = {
            'Saferpay#All': '../../assets/payment-options-logo/saferpay.png',
            'SaferpayApi#TWINT': '../../assets/payment-options-logo/twint-logo-quer.png',
            'SaferpayApi#VISA': '../../assets/payment-options-logo/visa.png',
            'SaferpayApi#ALIPAY': '../../assets/payment-options-logo/alipay.png',
            'SaferpayApi#BANCONTACT': '../../assets/payment-options-logo/bancontact.png',
            'SaferpayApi#BONUS': '../../assets/payment-options-logo/bonuscard.png',
            'SaferpayApi#DIRECTDEBIT': '../../assets/payment-options-logo/directdebit.png',
            'SaferpayApi#EPRZELEWY': '../../assets/payment-options-logo/eprzelewy.png',
            'SaferpayApi#EPS': '../../assets/payment-options-logo/eps.png',
            'SaferpayApi#GIROPAY': '../../assets/payment-options-logo/giropay.png',
            'SaferpayApi#IDEAL': '../../assets/payment-options-logo/ideal.png',
            // 'SaferpayApi#INVOICE': '../../assets/payment-options-logo/american_express.png', logo needs to be added per customer (language/currency)
            'SaferpayApi#JCB': '../../assets/payment-options-logo/jcb.png',
            'SaferpayApi#MYONE': '../../assets/payment-options-logo/myone.png',
            'SaferpayApi#PAYDIREKT': '../../assets/payment-options-logo/paydirekt.png',
            'SaferpayApi#POSTCARD': '../../assets/payment-options-logo/postfinance.png',
            'SaferpayApi#POSTFINANCE': '../../assets/payment-options-logo/postfinance.png',
            'SaferpayApi#SAFERPAYTEST': '../../assets/payment-options-logo/saferpaytest.png',
            'SaferpayApi#SOFORT': '../../assets/payment-options-logo/klarna.svg',
            'SaferpayApi#UNIONPAY': '../../assets/payment-options-logo/unionpay.png',
            'SaferpayApi#VPAY': '../../assets/payment-options-logo/vpay.png',
            'SaferpayApi#AllMethods': '../../assets/payment-options-logo/saferpay.png',
            'SaferpayApi#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
            'SaferpayApi#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
            'SaferpayApi#AMEX': '../../assets/payment-options-logo/american_express.png',
            'SaferpayApi#DINERS': '../../assets/payment-options-logo/diners.png',
            'SaferpayApi#MAESTRO': '../../assets/payment-options-logo/maestro.png',
            'IPayment#cc': '../../assets/payment-options-logo/credit_card.png',
            'IPayment#elv': '../../assets/payment-options-logo/sepa_logo.png',
            'IPayment#paypal': '../../assets/payment-options-logo/pay_pal.png',
            'Yandex#All': '../../assets/payment-options-logo/yandex-money.png',
            'Sberbank#All': '../../assets/payment-options-logo/sberbank.png',
            'Ingenico#AllMethods': '../../assets/payment-options-logo/saferpay.png',
            'Ingenico#CreditCard##MasterCard': '../../assets/payment-options-logo/mastercard.png',
            'Ingenico#PAYPAL##PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
            'PayOne#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
            'PayOne#VISA': '../../assets/payment-options-logo/visa.png',
            'PayOne#GIROPAY': '../../assets/payment-options-logo/giropay.png',
            'PayOne#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
            'PayOne#AMEX': '../../assets/payment-options-logo/american_express.png',
            'Verifone#AllMethods': '../../assets/payment-options-logo/verifone.png'
        };
        this.subscriptions = new Subscription();
    }
    WebShopConfirmationComponent.prototype.ngOnInit = function () {
        var _this = this;
        // remove the order response if there is an
        this.store.dispatch(new SetOrderResponse(null));
        this.store.dispatch(new setPaymentMethod(null));
        this.getAllBookedPackagePricesGroupedByPackageIndex$ = this.store.pipe(select(getAllBookedPackagePricesGroupedByPackageIndex));
        this.subscriptions.add(combineLatest([this.store.pipe(select(getAllBookedProductsTotalPrice)), this.getConfirmationProducts$()])
            .pipe(filter(function (_a) {
            var _ = _a[0], sortedBookedProducts = _a[1].sortedBookedProducts;
            return !!sortedBookedProducts.length;
        }))
            .subscribe(function (_a) {
            var totalPrice = _a[0], _b = _a[1], sortedBookedProducts = _b.sortedBookedProducts, confirmationProducts = _b.confirmationProducts;
            _this.ticketsFromBookings = sortedBookedProducts;
            _this.totalPrice = totalPrice;
            _this.confirmationProducts = confirmationProducts;
        }));
        this.subscriptions.add(this.store
            .pipe(select(getExhibitionSettings), filter(function (data) { return !!data; }))
            .subscribe(function (settings) {
            _this.settings = settings;
        }));
        this.subscriptions.add(this.store
            .pipe(select(getBuyerInfo), filter(function (data) { return !!data; }))
            .subscribe(function (inputs) {
            if (inputs.list.length) {
                var email = inputs.list.find(function (input) {
                    return input.key === 'email';
                });
                _this.buyerEmail = email.value;
            }
        }));
        // in case we already piad the order, go to invoice page for downlaod
        combineLatest([
            this.store.pipe(select(getOrderResponse)),
            this.store.pipe(select(getSelectedExhibitionId)),
            this.store.pipe(select(getTicketHolderAdditionalData))
        ])
            .pipe(filter(function (data) {
            return !!data[0] && !!data[1] && !!data[2];
        }), first())
            .subscribe(function (data) {
            var orderResponse = data[0], eventId = data[1], ticketHolderAdditionalData = data[2];
            if (orderResponse.orderPaid || _this.totalPrice === 0) {
                _this.router.navigate(["webshop/" + eventId + "/invoice"]);
            }
        });
        this.subscriptions.add(this.store.pipe(select(getExhibitionSettings)).subscribe(function (settings) {
            if (settings && settings.twintPayment) {
                _this.twintPayment = true;
            }
        }));
        this.subscriptions.add(combineLatest([this.store.pipe(select(getOrderResponse)), this.store.pipe(select(getSelectedExhibitionId))])
            .pipe(filter(function (data) {
            return !!data[0] && !!data[1];
        }))
            .subscribe(function (data) {
            var orderResponse = data[0], eventId = data[1];
            if (!orderResponse.orderPaid) {
                if (orderResponse.payUrl) {
                    window.open(orderResponse.payUrl, '_self');
                    // in case there is no redirec url go to payment landing page
                }
                else if (orderResponse.payForm) {
                    var form = document.createElement('div');
                    form.innerHTML = orderResponse.payForm;
                    _this.el.nativeElement.appendChild(form);
                    if (form.firstChild.nodeName === 'FORM') {
                        var formElement = form.firstChild;
                        formElement.submit();
                    }
                }
                else if (orderResponse.hash) {
                    _this.router.navigate(["webshop/" + eventId + "/confirmation/" + orderResponse.hash]);
                    //if order response success is false we redirect user to error page
                }
                else if (!orderResponse.success) {
                    _this.router.navigate(["webshop/" + eventId + "/confirmation/error"]);
                }
            }
        }));
        // check validity of the step in order to set alternative payment button to disabled/enabled
        this.subscriptions.add(this.store.pipe(select(getStepsValidity)).subscribe(function (stepsValidity) {
            _this.isAlternativeButtonDisabled = !stepsValidity.confirmation.valid;
        }));
        this.subscriptions.add(this.store
            .pipe(select(getConfirmationCheckboxesInputs))
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (checkboxes) {
            if (checkboxes && checkboxes.list.length) {
                _this.checkboxesInputs = checkboxes.list.slice(0);
                _this.checkboxesForm =
                    _this.checkboxesForm || _this.formsService.toFormGroup(checkboxes.list, _this.checkboxesFormsActionName);
            }
        }));
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(getSendingOptions)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(isTicketHolderVisible)),
            this.store.pipe(select(getTicketHolderInputSets))
        ])
            .pipe(filter(function (data) { return !!data; }), switchMap(function (_a) {
            var sendingOptions = _a[0], exhibitionSettings = _a[1], isTicketHolderVisible = _a[2], ticketHolderInputSets = _a[3];
            return combineLatest([
                _this.store.pipe(select(getBuyerVisitorCheckbox)),
                _this.store.pipe(select(getTicketHolderQuestionnaireInputs))
            ]).map(function (_a) {
                var buyerVisitorCheckbox = _a[0], ticketHolderQuestionnaireInputs = _a[1];
                return [sendingOptions, exhibitionSettings, isTicketHolderVisible,
                    ticketHolderInputSets, buyerVisitorCheckbox, ticketHolderQuestionnaireInputs];
            });
        }))
            .subscribe(function (data) {
            _this.confirmationOptions = [];
            var confirmationOptions = data[0], _ = data[1], isTicketHolderVisible = data[2], ticketHolderInputSets = data[3], buyerVisitorCheckbox = data[4], ticketHolderQuestionnaireInputs = data[5];
            _this.ticketHolderInputSets = ticketHolderInputSets;
            var ticketsWithHolders = [];
            _this.ticketsFromBookings.forEach(function (bookedProduct) {
                if (isBookingProductTypeTariff(bookedProduct)) {
                    bookedProduct.tariffs.forEach(function (tariff) {
                        _this.stepsFormsService.addBookedTariffsToSaveOrder(tariff, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, _this.buyerEmail);
                    });
                }
                else if (isBookingProductTypePackage(bookedProduct)) {
                    bookedProduct.productTypes.forEach(function (packageProductType) {
                        if (isBookingProductTypeTariff(packageProductType)) {
                            packageProductType.tariffs.forEach(function (tariff) {
                                _this.stepsFormsService.addBookedTariffsToSaveOrder(tariff, ticketHolderInputSets, buyerVisitorCheckbox, ticketsWithHolders, ticketHolderQuestionnaireInputs, isTicketHolderVisible, _this.buyerEmail);
                            });
                        }
                    });
                }
            });
            _this.confirmationOptions = confirmationOptions.filter(function (option) {
                return option.isEnabled;
            });
        }));
    };
    WebShopConfirmationComponent.prototype.ngAfterViewInit = function () {
        this.gtmService.pushConfirmation();
    };
    WebShopConfirmationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    WebShopConfirmationComponent.prototype.paymentSubmited = function (paymentMethod, event) {
        event.stopPropagation();
        this.orderSent = true;
        this.store.dispatch(new setPaymentMethod(paymentMethod));
        this.stepsFormsService.prepareDataForSaveAndSend(this.ticketsFromBookings, this.totalPrice, this.ticketHolderInputSets);
        //this.formsService.setFormValidity(true, null, ['payment', 'validation']);
        /* compare ticket buyer and logged in user,
           if buyer has more credentials than user use the buyers information and add them to user
        */
        this.userService.updateUserWithBuyer();
        this.gtmService.pushCheckoutOption(paymentMethod.toString());
    };
    WebShopConfirmationComponent.prototype.dropdownChanged = function (data) {
        var _this = this;
        var selectedSendingOption = data.sendingOption;
        combineLatest([this.store.pipe(select(getExhibitionSettings)), this.store.pipe(select(getSendingOptions))])
            .first()
            .subscribe(function (settings) {
            var exhibitionSettings = settings[0], allSendingOptions = settings[1];
            var ticketHolderform = _this.ticketHolderInputSets.find(function (inputSet) { return data.holderInformation.holderUuid === inputSet.holderUuid; });
            var sendToOwner = true;
            var sendToOwnerHidden = true;
            var isMobilePerBuyer = selectedSendingOption === TicketSendingOptions.MobilePerBuyer;
            var isTicketRetrivalLinkBuyer = selectedSendingOption === TicketSendingOptions.TicketRetrievalLinkBuyer;
            var isAllToBuyer = selectedSendingOption === TicketSendingOptions.AllToBuyer;
            var isTicketRetrivalLink = selectedSendingOption === TicketSendingOptions.TicketRetrievalLink;
            var isMobilePerOwner = selectedSendingOption === TicketSendingOptions.MobilePerOwner;
            var isNormalPerOwner = selectedSendingOption === TicketSendingOptions.NormalPerOwner;
            if (!isAllToBuyer && !isNormalPerOwner) {
                if (isMobilePerBuyer) {
                    sendToOwner = false;
                    selectedSendingOption = TicketSendingOptions.MobilePerOwner;
                }
                else if (isTicketRetrivalLinkBuyer) {
                    sendToOwner = false;
                    selectedSendingOption = TicketSendingOptions.TicketRetrievalLink;
                }
                for (var i = 0; i < allSendingOptions.length; i++) {
                    var sendingOption = allSendingOptions[i];
                    if (sendingOption.isEnabled) {
                        if (isMobilePerBuyer && sendingOption.value === TicketSendingOptions.MobilePerOwner) {
                            sendToOwner = sendToOwnerHidden = false;
                            break;
                        }
                        else if (isMobilePerOwner && sendingOption.value === TicketSendingOptions.MobilePerBuyer) {
                            sendToOwnerHidden = !sendToOwner;
                            break;
                        }
                        else if (isTicketRetrivalLinkBuyer &&
                            sendingOption.value === TicketSendingOptions.TicketRetrievalLink) {
                            sendToOwner = sendToOwnerHidden = false;
                            break;
                        }
                        else if (isTicketRetrivalLink &&
                            sendingOption.value === TicketSendingOptions.TicketRetrievalLinkBuyer) {
                            sendToOwnerHidden = !sendToOwner;
                            break;
                        }
                    }
                }
            }
            if (isAllToBuyer) {
                sendToOwner = false;
            }
            var sendingOptions = ticketHolderform.inputSet.list.find(function (input) { return input.key === 'sendingOption'; });
            var sendtoowner = ticketHolderform.inputSet.list.find(function (input) { return input.key === 'sendtoowner'; });
            sendingOptions.value = selectedSendingOption;
            sendtoowner.hidden = sendToOwnerHidden;
            sendtoowner.options[0].value = sendToOwner;
            sendtoowner.options[0].label = "personalize.sending-options." + selectedSendingOption.toLowerCase();
            sendtoowner.options[0].icon = selectedSendingOption;
            var chackValidityAndRedirect = true;
            _this.customizationService.setRequiredHoldersFiledsBasedOnSendinOption(ticketHolderform, exhibitionSettings, selectedSendingOption, chackValidityAndRedirect);
            _this.store.dispatch(new SetInputs(ticketHolderform));
        });
    };
    WebShopConfirmationComponent.prototype.setConfirmationTariffWorkshops = function (workshops, workshopProductList) {
        var confirmationWorkshops = [];
        workshops.forEach(function (_a) {
            var holderUuids = _a.holderUuids, price = _a.price, id = _a.id;
            var workshopProduct = workshopProductList.find(function (workshopProduct) { return workshopProduct.workshopId === id; });
            if (workshopProduct) {
                var date = workshopProduct.date, end = workshopProduct.end, start = workshopProduct.start, roomName = workshopProduct.roomName, workshopName = workshopProduct.workshopName;
                var workshopCount = holderUuids.length;
                var confirmationWorkshop = {
                    holderUuids: holderUuids,
                    count: workshopCount,
                    date: date,
                    end: end,
                    start: start,
                    roomName: roomName,
                    workshopName: workshopName,
                    price: price,
                    totalPrice: price * workshopCount
                };
                confirmationWorkshops.push(confirmationWorkshop);
            }
        });
        return confirmationWorkshops;
    };
    WebShopConfirmationComponent.prototype.setConfirmationTariffHolderInformation = function (holderUuids, ticketHolderInputs, confirmationTariff) {
        holderUuids.forEach(function (holderUuid) {
            var ticketHolderInputSet = ticketHolderInputs.find(function (singleInput) { return singleInput.holderUuid.includes(holderUuid); });
            if (!!ticketHolderInputSet) {
                var holderInformation_1 = { holderUuid: holderUuid };
                var inputsInfo_1 = ['email', 'firstName', 'lastName', 'sendingOption', 'sendtoowner'];
                ticketHolderInputSet.inputSet.list.forEach(function (input) {
                    if (inputsInfo_1.includes(input.key)) {
                        holderInformation_1[input.key] = input.key === 'sendtoowner' ? input.options[0].value : input.value;
                    }
                });
                confirmationTariff.holderInformation.push(holderInformation_1);
            }
        });
    };
    WebShopConfirmationComponent.prototype.setConfirmationTariff = function (bookedTariff, confirmationTariffs, ticketHolderInputs, workshopProductList) {
        var count = bookedTariff.count, price = bookedTariff.price, ticketName = bookedTariff.ticketName, voucherCode = bookedTariff.voucherCode, holderUuids = bookedTariff.holderUuids, workshops = bookedTariff.workshops;
        var confirmationTariff = {
            count: count,
            price: price,
            ticketName: ticketName,
            totalPrice: count * price,
            voucherCode: voucherCode,
            holderInformation: [],
            workshops: this.setConfirmationTariffWorkshops(workshops, workshopProductList)
        };
        this.setConfirmationTariffHolderInformation(holderUuids, ticketHolderInputs, confirmationTariff);
        confirmationTariffs.push(confirmationTariff);
    };
    /**
     * Get confirmation products
     * @description Sorts products to have packages, normal tariffs and parking tickets consecutively
     * @returns Array of products on confirmation screen
     */
    WebShopConfirmationComponent.prototype.getConfirmationProducts$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getSortedBasketProducts)),
            this.store.pipe(select(getTicketHolderInputSets)),
            this.store.pipe(select(getWorkshopProductList))
        ]).pipe(map(function (_a) {
            var sortedBookedProducts = _a[0], ticketHolderInputs = _a[1], workshopProductList = _a[2];
            var confirmationProducts = [];
            sortedBookedProducts.forEach(function (bookedProduct) {
                var productType = bookedProduct.productType, productTypeName = bookedProduct.productTypeName;
                if (isBookingProductTypeTariff(bookedProduct)) {
                    var tariffs = bookedProduct.tariffs;
                    var confirmationTariffs_1 = [];
                    tariffs.forEach(function (bookedTariff) {
                        _this.setConfirmationTariff(bookedTariff, confirmationTariffs_1, ticketHolderInputs, workshopProductList);
                    });
                    var confirmationTariffType = {
                        productType: ProductType.Tariff,
                        productTypeName: productTypeName,
                        tariffs: confirmationTariffs_1
                    };
                    confirmationProducts.push(confirmationTariffType);
                }
                else if (isBookingProductTypePackage(bookedProduct)) {
                    var packageNumber = bookedProduct.packageNumber, packageIndex = bookedProduct.packageIndex, productTypes = bookedProduct.productTypes;
                    var confirmationPackage_1 = {
                        productType: productType,
                        productTypeName: productTypeName,
                        packageNumber: packageNumber,
                        packageIndex: packageIndex,
                        productTypes: []
                    };
                    productTypes.forEach(function (packageProductType) {
                        if (isBookingProductTypeTariff(packageProductType)) {
                            var productType_1 = packageProductType.productType, productTypeName_1 = packageProductType.productTypeName;
                            var confirmationTariffs_2 = [];
                            packageProductType.tariffs.forEach(function (bookedPackageTariff) {
                                _this.setConfirmationTariff(bookedPackageTariff, confirmationTariffs_2, ticketHolderInputs, workshopProductList);
                            });
                            var confirmationPackageTariffType = {
                                productType: productType_1,
                                productTypeName: productTypeName_1,
                                tariffs: confirmationTariffs_2
                            };
                            confirmationPackage_1.productTypes.push(confirmationPackageTariffType);
                        }
                    });
                    confirmationProducts.push(confirmationPackage_1);
                }
            });
            return { sortedBookedProducts: sortedBookedProducts, confirmationProducts: confirmationProducts };
        }));
    };
    return WebShopConfirmationComponent;
}());
export { WebShopConfirmationComponent };
