var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { isBookingProductTypeTariff } from '@products/models/booking.model';
import { isProductGroup, isProductTypePackage, isProductTypeTariff } from '@products/models/product-selection.model';
import { TariffPersonalizationType } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { ActionTypes as ProductSelectionActionTypes } from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';
var INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND;
export var initialState = {
    list: {},
    ticketSendingOptions: []
};
/**
 * Add booked voucher tariff
 * @description Add and sort voucher tariff in product selection list
 * @param bookedVoucherProduct
 * @param voucherProductsListItems
 */
var setBookedVoucherTariff = function (bookedVoucherProduct, voucherProductsListItems) {
    bookedVoucherProduct.tariffs.forEach(function (bookedVoucherTariff) {
        var ticketPersonId = bookedVoucherTariff.ticketPersonId, voucherCode = bookedVoucherTariff.voucherCode, voucherType = bookedVoucherTariff.voucherType, voucherCountLimit = bookedVoucherTariff.voucherCountLimit;
        var voucherProductIndex = voucherProductsListItems.findIndex(function (voucherProduct) {
            if (isProductTypeTariff(voucherProduct)) {
                return voucherProduct.tariffType.tariffs.some(function (voucherTariff) {
                    return voucherTariff.ticketPersonId === ticketPersonId &&
                        (voucherTariff.voucherCode === voucherCode || !voucherTariff.isVisible);
                });
            }
        });
        if (voucherProductIndex === INDEX_NOT_FOUND) {
            return;
        }
        var voucherProductListItem = voucherProductsListItems[voucherProductIndex];
        var voucherProductListItemTariffType = voucherProductListItem.tariffType;
        var voucherProductListItemTariffs = voucherProductListItemTariffType.tariffs;
        var isRedeemedVoucher = voucherProductListItemTariffs.every(function (voucherTariff) { return voucherTariff.voucherCode !== voucherCode; });
        if (isRedeemedVoucher) {
            voucherProductsListItems.splice(voucherProductIndex, 1);
            voucherProductsListItems.unshift(voucherProductListItem);
            voucherProductListItemTariffType.isVisible = true;
            var voucherTariffIndex = voucherProductListItemTariffs.findIndex(function (voucherTariff) { return voucherTariff.ticketPersonId === ticketPersonId && !voucherTariff.isVisible; });
            var voucherProductListItemTariffCloneToAdd = cloneDeep(voucherProductListItemTariffs[voucherTariffIndex]);
            voucherProductListItemTariffCloneToAdd.isVisible = true;
            voucherProductListItemTariffCloneToAdd.voucherCode = voucherCode;
            voucherProductListItemTariffCloneToAdd.voucherType = voucherType;
            voucherProductListItemTariffCloneToAdd.voucherCountLimit = voucherCountLimit;
            voucherProductListItemTariffs.push(voucherProductListItemTariffCloneToAdd);
            voucherProductListItemTariffs.reverse();
        }
    });
};
var ɵ0 = setBookedVoucherTariff;
/**
 * Remove voucher tariff
 * @description Add and sort voucher tariff in product selection list
 * @param bookedVoucherProduct
 * @param voucherProductsListItems
 */
var removeUnbookedVoucherTariff = function (bookedVoucherProduct, voucherProductsListItems) {
    bookedVoucherProduct.tariffs.forEach(function (bookedVoucherTariff) {
        var findVoucherTariff = function (voucherTariff) {
            return voucherTariff.ticketPersonId === bookedVoucherTariff.ticketPersonId &&
                voucherTariff.voucherCode === bookedVoucherTariff.voucherCode;
        };
        var voucherProductIndex = voucherProductsListItems.findIndex(function (voucherProduct) {
            if (isProductTypeTariff(voucherProduct)) {
                return voucherProduct.tariffType.tariffs.some(findVoucherTariff);
            }
        });
        if (voucherProductIndex === INDEX_NOT_FOUND) {
            return;
        }
        var voucherProductListItem = voucherProductsListItems[voucherProductIndex];
        var voucherProductListItemTariffType = voucherProductListItem.tariffType;
        var voucherProductListItemTariffs = voucherProductListItemTariffType.tariffs;
        var voucherProductListItemTariffIndex = voucherProductListItemTariffs.findIndex(findVoucherTariff);
        voucherProductListItemTariffs.splice(voucherProductListItemTariffIndex, 1);
        var areAllActiveVoucherTariffsRemovedFromTariffType = voucherProductListItemTariffs.every(function (voucherTariff) { return !voucherTariff.isVisible && !voucherTariff.voucherCode; });
        if (areAllActiveVoucherTariffsRemovedFromTariffType) {
            voucherProductListItemTariffType.isVisible = false;
        }
    });
};
var ɵ1 = removeUnbookedVoucherTariff;
/**
 * Remove all unbooked vouchers from product selection list
 * @description Remove all unbooked vouchers in self registration product selection list
 * @param voucherProductListItems
 */
var removeUnbookedVoucherProductsIfIsSelfRegistration = function (voucherProductListItems) {
    voucherProductListItems.forEach(function (voucherProduct) {
        if (isProductTypeTariff(voucherProduct)) {
            voucherProduct.tariffType.tariffs = voucherProduct.tariffType.tariffs.filter(function (voucherTariff) { return !voucherTariff.isVisible && !voucherTariff.voucherCode; });
            var areAllActiveVoucherTariffsRemovedFromTariffType = voucherProduct.tariffType.tariffs.every(function (voucherTariff) { return !voucherTariff.isVisible && !voucherTariff.voucherCode; });
            if (areAllActiveVoucherTariffsRemovedFromTariffType) {
                voucherProduct.tariffType.isVisible = false;
            }
        }
    });
};
var ɵ2 = removeUnbookedVoucherProductsIfIsSelfRegistration;
/**
 * Remove unbooked regular tariffs on anonymous voucher product redeem
 * @description Removes voucher tariffs in product selection list when anonymous product is redeemed
 * @param voucherProductListItems
 */
var removeUnbookedRegularTariffVouchersIfAnonymousVoucherProductIsRedeemed = function (voucherProductListItems) {
    voucherProductListItems.forEach(function (voucherProduct) {
        if (isProductTypeTariff(voucherProduct)) {
            voucherProduct.tariffType.tariffs = voucherProduct.tariffType.tariffs.filter(function (voucherTariff) {
                return !(voucherTariff.voucherCode && voucherTariff.personalizationType !== TariffPersonalizationType.Anonymous);
            });
            var areAllActiveVoucherTariffsRemovedFromTariffType = voucherProduct.tariffType.tariffs.every(function (voucherTariff) { return !voucherTariff.isVisible && !voucherTariff.voucherCode; });
            if (areAllActiveVoucherTariffsRemovedFromTariffType) {
                voucherProduct.tariffType.isVisible = false;
            }
        }
    });
};
var ɵ3 = removeUnbookedRegularTariffVouchersIfAnonymousVoucherProductIsRedeemed;
/**
 * Remove unbooked packages on anonymous voucher product redeem
 * @description Removes all active packages from product selection list when anonymous voucher product is redeemed
 * @param productListItems
 */
var removeUnbookedPackagesIfAnonymousVoucherProductIsRedeemed = function (productListItems) {
    Object.keys(productListItems).forEach(function (listItemKey) {
        var productListItem = productListItems[listItemKey];
        if (isProductGroup(productListItem)) {
            productListItem.products.forEach(function (product) {
                if (isProductTypePackage(product)) {
                    var packageType = product.packageType;
                    var numberOfPackagesToRemove = packageType.packages.length - 1;
                    packageType.numberOfAddedPackages = 0;
                    packageType.packages[0].trackByAddedPackage = 0;
                    if (numberOfPackagesToRemove > 0) {
                        packageType.packages.splice(-numberOfPackagesToRemove);
                    }
                }
            });
        }
    });
};
var ɵ4 = removeUnbookedPackagesIfAnonymousVoucherProductIsRedeemed;
/**
 * Increase package product indexes
 * @description Change package product index newly added product in product selection list
 * @param currentPackage
 * @param newPackageIndex
 */
var increasePackageProductIndexes = function (currentPackage, newPackageIndex) {
    currentPackage.packageIndex = newPackageIndex;
    currentPackage.products.forEach(function (packageProduct) {
        if (isProductTypeTariff(packageProduct)) {
            packageProduct.tariffType.tariffs.forEach(function (packageTariff) {
                packageTariff.packageIndex = newPackageIndex;
            });
        }
    });
};
var ɵ5 = increasePackageProductIndexes;
/**
 * Add package
 * @description Add package to product selection list
 * @param product
 * @param bookedPackages
 */
var addPackages = function (product, bookedPackages) {
    var packageType = product.packageType;
    var currentPackageTypeBookedPackages = bookedPackages.filter(function (bookingPackage) { return bookingPackage.packageNumber === packageType.packageNumber; });
    var fistPackage = packageType.packages[0];
    var isOnlyFirstPackageAdded = packageType.packages.length === 1 &&
        packageType.numberOfAddedPackages === 0 &&
        currentPackageTypeBookedPackages.length === 1;
    if (isOnlyFirstPackageAdded) {
        packageType.numberOfAddedPackages++;
        fistPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
        return;
    }
    var areMultiplePackagesWithFistPackagedAdded = packageType.packages.length === 1 &&
        packageType.numberOfAddedPackages === 0 &&
        currentPackageTypeBookedPackages.length > 1;
    if (areMultiplePackagesWithFistPackagedAdded) {
        packageType.numberOfAddedPackages++;
        fistPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
        currentPackageTypeBookedPackages.splice(0, 1);
    }
    if (currentPackageTypeBookedPackages.length) {
        currentPackageTypeBookedPackages.forEach(function (currentProductBookingPackage) {
            var newPackage = cloneDeep(fistPackage);
            increasePackageProductIndexes(newPackage, currentProductBookingPackage.packageIndex);
            packageType.numberOfAddedPackages++;
            newPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
            packageType.packages.push(newPackage);
        });
    }
};
var ɵ6 = addPackages;
/**
 * Remove package
 * @description Remove package from product selection list
 * @param product
 * @param bookedPackages
 */
var removePackages = function (product, removedBookedPackages) {
    var packageIndexesToRemove = removedBookedPackages.map(function (removedBookedPackage) { return removedBookedPackage.packageIndex; });
    var newPackageType = cloneDeep(product.packageType);
    product.packageType.packages.reverse().forEach(function (currentPackage) {
        var removePackage = packageIndexesToRemove.includes(currentPackage.packageIndex);
        if (removePackage) {
            var isLastPackage = newPackageType.numberOfAddedPackages === 1;
            newPackageType.numberOfAddedPackages--;
            if (!isLastPackage) {
                var newPackageToRemoveIndex = newPackageType.packages.findIndex(function (newPackageToRemove) { return newPackageToRemove.packageIndex === currentPackage.packageIndex; });
                newPackageType.packages.splice(newPackageToRemoveIndex, 1);
            }
            else {
                newPackageType.packages[0].trackByAddedPackage = newPackageType.numberOfAddedPackages;
            }
        }
    });
    product.packageType = newPackageType;
};
var ɵ7 = removePackages;
export var productSelectionReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var cloneState = cloneDeep(state);
    switch (action.type) {
        case ProductActionTypes.GET_PREFERRED_PRODUCTS: {
            return __assign({}, state, { list: {} });
        }
        case ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST:
        case ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST: {
            var productList = cloneDeep(action.payload);
            if (Object.keys(productList)) {
                return __assign({}, state, { list: productList });
            }
            return state;
        }
        case ProductSelectionActionTypes.SET_VOUCHER_PRODUCT_IN_SELECTION_LIST: {
            var _a = cloneDeep(action.payload), bookingProductTypes = _a.bookingProductTypes, isSelfRegistration = _a.isSelfRegistration;
            if (!bookingProductTypes.length) {
                return state;
            }
            var cloneProductList = cloneState.list;
            var cloneVoucherProductsListItem_1 = cloneProductList.vouchers;
            var isAnonymousProductBooked = bookingProductTypes.some(function (bookedVoucherProduct) {
                if (isBookingProductTypeTariff(bookedVoucherProduct)) {
                    return bookedVoucherProduct.tariffs.some(function (bookedVoucherTariff) { return !!bookedVoucherTariff.voucherCode && bookedVoucherTariff.isAnonymous; });
                }
            });
            if (isSelfRegistration) {
                removeUnbookedVoucherProductsIfIsSelfRegistration(cloneVoucherProductsListItem_1);
            }
            bookingProductTypes.forEach(function (bookedVoucherProduct) {
                if (isBookingProductTypeTariff(bookedVoucherProduct)) {
                    setBookedVoucherTariff(bookedVoucherProduct, cloneVoucherProductsListItem_1);
                }
            });
            var updateRemovedVoucherProductsState = isAnonymousProductBooked || isSelfRegistration;
            if (updateRemovedVoucherProductsState) {
                var removeProductsOnAnonymousProductRedeem = isAnonymousProductBooked && !isSelfRegistration;
                if (removeProductsOnAnonymousProductRedeem) {
                    removeUnbookedRegularTariffVouchersIfAnonymousVoucherProductIsRedeemed(cloneVoucherProductsListItem_1);
                    removeUnbookedPackagesIfAnonymousVoucherProductIsRedeemed(cloneProductList);
                }
                return __assign({}, state, { list: cloneProductList });
            }
            return __assign({}, state, { list: __assign({}, state.list, { vouchers: cloneVoucherProductsListItem_1 }) });
        }
        case ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST: {
            var removeBookedVoucherProducts = cloneDeep(action.payload);
            if (!removeBookedVoucherProducts.length) {
                return state;
            }
            var cloneVoucherProductsListItem_2 = cloneState.list.vouchers;
            removeBookedVoucherProducts.forEach(function (removeBookedVoucherProduct) {
                if (isBookingProductTypeTariff(removeBookedVoucherProduct)) {
                    removeUnbookedVoucherTariff(removeBookedVoucherProduct, cloneVoucherProductsListItem_2);
                }
            });
            return __assign({}, state, { list: __assign({}, state.list, { vouchers: cloneVoucherProductsListItem_2 }) });
        }
        case ProductSelectionActionTypes.REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST: {
            var removeVoucherCode_1 = action.payload;
            if (!removeVoucherCode_1) {
                return state;
            }
            var cloneVoucherProductsListItem = cloneState.list.vouchers;
            cloneVoucherProductsListItem.forEach(function (voucherProductListItem) {
                if (isProductTypeTariff(voucherProductListItem)) {
                    var voucherProductListItemTariffType = voucherProductListItem.tariffType;
                    var voucherProductListItemTariffs = voucherProductListItem.tariffType.tariffs;
                    var voucherProductListItemTariffIndex = voucherProductListItemTariffs.findIndex(function (voucherTariff) { return voucherTariff.voucherCode === removeVoucherCode_1; });
                    if (voucherProductListItemTariffIndex === INDEX_NOT_FOUND) {
                        return;
                    }
                    voucherProductListItemTariffs.splice(voucherProductListItemTariffIndex, 1);
                    var areAllActiveVoucherTariffsRemovedFromTariffType = voucherProductListItemTariffs.every(function (voucherTariff) { return !voucherTariff.isVisible && !voucherTariff.voucherCode; });
                    if (areAllActiveVoucherTariffsRemovedFromTariffType) {
                        voucherProductListItemTariffType.isVisible = false;
                    }
                }
            });
            return __assign({}, state, { list: __assign({}, state.list, { vouchers: cloneVoucherProductsListItem }) });
        }
        case ProductSelectionActionTypes.SET_PACKAGE_IN_SELECTION_LIST: {
            var bookedPackages_1 = cloneDeep(action.payload);
            if (!bookedPackages_1.length) {
                return state;
            }
            Object.keys(cloneState.list).forEach(function (listItemKey) {
                var productListItem = cloneState.list[listItemKey];
                if (isProductGroup(productListItem)) {
                    productListItem.products.forEach(function (product) {
                        if (isProductTypePackage(product)) {
                            addPackages(product, bookedPackages_1);
                        }
                    });
                }
            });
            return __assign({}, state, { list: __assign({}, state.list, cloneState.list) });
        }
        case ProductSelectionActionTypes.REMOVE_PACKAGE_FROM_SELECTION_LIST: {
            var removedBookedPackages_1 = cloneDeep(action.payload);
            if (!removedBookedPackages_1.length) {
                return state;
            }
            Object.keys(cloneState.list).forEach(function (listItemKey) {
                var productListItem = cloneState.list[listItemKey];
                if (isProductGroup(productListItem)) {
                    productListItem.products.forEach(function (product) {
                        if (isProductTypePackage(product)) {
                            removePackages(product, removedBookedPackages_1);
                        }
                    });
                }
            });
            return __assign({}, state, { list: cloneState.list });
        }
        case ProductSelectionActionTypes.INIT_SENDING_OPTIONS:
        case ProductSelectionActionTypes.SET_SENDING_OPTIONS: {
            var sendingOptions = cloneDeep(action.payload);
            if (!sendingOptions.length) {
                return state;
            }
            return __assign({}, state, { ticketSendingOptions: sendingOptions });
        }
        case ProductSelectionActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_PRODUCT_SELECTION_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
