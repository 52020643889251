import { Action } from '@ngrx/store';
import {
  BookingPackageType,
  BookingProductType,
  ProductHolderUuid,
  RemoveWorkshopBookingsAndSetSeats,
  SetProductBookingContingentReservation,
  SetProductBookingParkingReservation,
  SetWorkshopBookingsAndSetSeats,
  VoucherCountdown
} from '@products/models/booking.model';
import { SetContingentReservationDate } from '@products/models/contingent.model';
import { SetParkingReservationDate } from '@products/models/parking.model';

export enum ActionTypes {
  SET_PRODUCT_IN_BOOKING_LIST = '[Booking API/Product] Set product in booking list',
  SET_PREFERRED_PRODUCT_IN_BOOKING_LIST = '[Booking API/Product] Remove booked products and set preferred product in booking list',
  REMOVE_PRODUCT_FROM_BOOKING_LIST = '[Booking API/Product] Remove product from booking list',
  SET_VOUCHER_PRODUCT_IN_BOOKING_LIST = '[Voucher/Booking API/Voucher] Set voucher product in booking list',
  SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST = '[Voucher/Booking API/Voucher] Set voucher for anonymous product in booking list',
  REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST = '[Voucher/Booking API/Voucher] Remove voucher product from booking list',
  REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST = '[Voucher/Booking API/Voucher] Remove released voucher product from booking list',
  SET_PACKAGE_IN_BOOKING_LIST = '[Booking API/Package] Set package in booking list',
  REMOVE_PACKAGE_FROM_BOOKING_LIST = '[Booking API/Package] Remove package from booking list',
  SET_PRODUCT_IN_BOOKING_LIST_ERROR = '[Booking API/Product] Set product in booking list error',
  REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR = '[Booking API/Product] Remove product from booking list error',
  SET_PRODUCT_HOLDER_UUID = '[Booking/Personalization/Holder] Set product holder uuid',
  REMOVE_PRODUCT_HOLDER_UUID = '[Booking/Personalization/Holder] Remove product holder uuid',
  SET_WORKSHOP_IN_BOOKING = '[Workshop/Workshop API] Set workshop in booking',
  REMOVE_WORKSHOP_FROM_BOOKING = '[Workshop/Workshop API] Remove workshop from booking',
  SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING = '[Contingent/Booking API/Tariff] Set contingent date in booking',
  SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING = '[Contingent/Booking API/Tariff] Set validated contingent reservation in booking',
  SET_PARKING_RESERVATION_DATE_IN_BOOKING = '[Parking/Booking API/Tariff] Set parking date in booking',
  SET_VALIDATED_PARKING_RESERVATION_IN_BOOKING = '[Parking/Booking API/Tariff] Set validated parking reservation in booking',
  SET_PRODUCT_COUNTDOWN = '[Booking API/Product] Set product countdown',
  SET_VOUCHER_COUNTDOWN = '[Booking API/Voucher] Set voucher countdown',
  REMOVE_VOUCHER_COUNTDOWN = '[Booking API/Voucher] Remove voucher countdown',
  SET_CLAIMED_TICKET_HASH_VALID = '[Holder API] Set claimed ticket hash valid',
  SET_IS_ANONYMOUS_PRODUCT_SENT_TO_API = '[Booking/Invoice/AnonymousProduct] Set anonymous product sent to API',
  SET_CLAIMED_TICKET_HASH = '[Download tariff] Set claimed ticket hash',
  REMOVE_COUNTDOWNS = '[Booking] Remove countdowns',
  RESET_REDUCER = '[Shop Reset] Reset product bookings'
}

export class SetProductInBookingList implements Action {
  readonly type = ActionTypes.SET_PRODUCT_IN_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class SetPreferredProductInBookingList implements Action {
  readonly type = ActionTypes.SET_PREFERRED_PRODUCT_IN_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class RemoveProductFromBookingList implements Action {
  readonly type = ActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}
export class SetVoucherProductInBookingList implements Action {
  readonly type = ActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class SetVoucherForAnonymousProductInBookingList implements Action {
  readonly type = ActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class RemoveVoucherProductFromBookingList implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class RemoveReleasedVoucherProductFromBookingList implements Action {
  readonly type = ActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST;
  constructor(public payload: BookingProductType[]) {}
}

export class SetPackageInBookingList implements Action {
  readonly type = ActionTypes.SET_PACKAGE_IN_BOOKING_LIST;
  constructor(public payload: BookingPackageType[]) {}
}

export class RemovePackageFromBookingList implements Action {
  readonly type = ActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST;
  constructor(public payload: BookingPackageType[]) {}
}

export class SetProductInBookingListError implements Action {
  readonly type = ActionTypes.SET_PRODUCT_IN_BOOKING_LIST_ERROR;
}

export class RemoveProductFromBookingListError implements Action {
  readonly type = ActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR;
}

export class SetProductHolderUuid implements Action {
  readonly type = ActionTypes.SET_PRODUCT_HOLDER_UUID;
  constructor(public payload: ProductHolderUuid) {}
}

export class RemoveProductHolderUuid implements Action {
  readonly type = ActionTypes.REMOVE_PRODUCT_HOLDER_UUID;
  constructor(public payload: ProductHolderUuid) {}
}

export class SetWorkshopInBooking implements Action {
  readonly type = ActionTypes.SET_WORKSHOP_IN_BOOKING;
  constructor(public payload: SetWorkshopBookingsAndSetSeats) {}
}

export class RemoveWorkshopFromBooking implements Action {
  readonly type = ActionTypes.REMOVE_WORKSHOP_FROM_BOOKING;
  constructor(public payload: RemoveWorkshopBookingsAndSetSeats) {}
}

export class SetContingentReservationDateInBooking implements Action {
  readonly type = ActionTypes.SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING;
  constructor(public payload: SetContingentReservationDate[]) {}
}

export class SetValidatedContingentReservationInBooking implements Action {
  readonly type = ActionTypes.SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING;
  constructor(public payload: SetProductBookingContingentReservation[]) {}
}

export class SetParkingReservationDateInBooking implements Action {
  readonly type = ActionTypes.SET_PARKING_RESERVATION_DATE_IN_BOOKING;
  constructor(public payload: SetParkingReservationDate) {}
}

export class SetValidatedParkingReservationInBooking implements Action {
  readonly type = ActionTypes.SET_VALIDATED_PARKING_RESERVATION_IN_BOOKING;
  constructor(public payload: SetProductBookingParkingReservation) {}
}

export class SetProductCountdown implements Action {
  readonly type = ActionTypes.SET_PRODUCT_COUNTDOWN;
  constructor(public payload: number) {}
}

export class SetVoucherCountdown implements Action {
  readonly type = ActionTypes.SET_VOUCHER_COUNTDOWN;
  constructor(public payload: VoucherCountdown[]) {}
}

export class RemoveVoucherCountdown implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER_COUNTDOWN;
  constructor(public payload: { voucherCountdowns: VoucherCountdown[]; tariffReleaseInMinutes: number }) {}
}

export class SetIsAnonymousProductSentToAPI implements Action {
  readonly type = ActionTypes.SET_IS_ANONYMOUS_PRODUCT_SENT_TO_API;
  constructor(public payload: boolean) {}
}

export class SetClaimedTicketHash implements Action {
  readonly type = ActionTypes.SET_CLAIMED_TICKET_HASH;
  constructor(public payload: string) {}
}

export class SetTicketClaimedHashValid implements Action {
  readonly type = ActionTypes.SET_CLAIMED_TICKET_HASH_VALID;
  constructor(public payload: boolean) {}
}

export class RemoveCountdowns implements Action {
  readonly type = ActionTypes.REMOVE_COUNTDOWNS;
}

export class ResetProductBookings implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | SetProductInBookingList
  | SetPreferredProductInBookingList
  | RemoveProductFromBookingList
  | SetVoucherProductInBookingList
  | SetVoucherForAnonymousProductInBookingList
  | RemoveVoucherProductFromBookingList
  | RemoveReleasedVoucherProductFromBookingList
  | SetPackageInBookingList
  | RemovePackageFromBookingList
  | SetProductInBookingListError
  | RemoveProductFromBookingListError
  | SetProductHolderUuid
  | RemoveProductHolderUuid
  | SetWorkshopInBooking
  | RemoveWorkshopFromBooking
  | SetContingentReservationDateInBooking
  | SetValidatedContingentReservationInBooking
  | SetParkingReservationDateInBooking
  | SetValidatedParkingReservationInBooking
  | SetProductCountdown
  | SetVoucherCountdown
  | RemoveVoucherCountdown
  | SetIsAnonymousProductSentToAPI
  | SetClaimedTicketHash
  | SetTicketClaimedHashValid
  | RemoveCountdowns
  | ResetProductBookings;
