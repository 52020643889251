/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./package.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../tariff-type/tariff-type.component.ngfactory";
import * as i3 from "../../tariff-type/tariff-type.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../shared/pipes/format-price/format-price.pipe";
import * as i6 from "./package.component";
var styles_PackageComponent = [i0.styles];
var RenderType_PackageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PackageComponent, data: {} });
export { RenderType_PackageComponent as RenderType_PackageComponent };
function View_PackageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tariff-type", [], null, null, null, i2.View_TariffTypeComponent_0, i2.RenderType_TariffTypeComponent)), i1.ɵdid(2, 114688, null, 0, i3.TariffTypeComponent, [], { exhibitionSettings: [0, "exhibitionSettings"], productSelectionViewModel: [1, "productSelectionViewModel"], tariffStatusViewModel: [2, "tariffStatusViewModel"], workshopStatusViewModel: [3, "workshopStatusViewModel"], bookingStatusViewModel: [4, "bookingStatusViewModel"], productGroup: [5, "productGroup"], packageType: [6, "packageType"], tariffType: [7, "tariffType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exhibitionSettings; var currVal_1 = _co.productSelectionViewModel; var currVal_2 = _co.tariffStatusViewModel; var currVal_3 = _co.workshopStatusViewModel; var currVal_4 = _co.bookingStatusViewModel; var currVal_5 = _co.productGroup; var currVal_6 = _co.packageType; var currVal_7 = _v.parent.parent.context.$implicit.tariffType; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_PackageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "package-tariff-types"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.productType === _co.ProductType.Tariff); _ck(_v, 3, 0, currVal_0); }, null); }
function View_PackageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.productType === _co.ProductType.Tariff) && _v.context.$implicit.tariffType.isVisible) || (_v.context.$implicit.productType === _co.ProductType.Package)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PackageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "package-price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), (_co.bookingStatusViewModel.bookedPackagePricesGroupedByPackageIndex[_co.package.packageIndex] || 0), _co.exhibitionSettings.currencySettings)); _ck(_v, 1, 0, currVal_0); }); }
export function View_PackageComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.FormatPricePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.package.products; var currVal_1 = _co.packageProductsTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.packageType.numberOfAddedPackages > 1); _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PackageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-package", [], null, null, null, View_PackageComponent_0, RenderType_PackageComponent)), i1.ɵdid(1, 114688, null, 0, i6.PackageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PackageComponentNgFactory = i1.ɵccf("app-package", i6.PackageComponent, View_PackageComponent_Host_0, { exhibitionSettings: "exhibitionSettings", productSelectionViewModel: "productSelectionViewModel", tariffStatusViewModel: "tariffStatusViewModel", workshopStatusViewModel: "workshopStatusViewModel", bookingStatusViewModel: "bookingStatusViewModel", productGroup: "productGroup", packageType: "packageType", package: "package" }, {}, []);
export { PackageComponentNgFactory as PackageComponentNgFactory };
