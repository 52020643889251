import { combineLatest, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../../shared/services-with-reducers/user/user.actions';
import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '../../../shared/app-constants';
import { FormsService } from '../../../shared/forms/forms.service';
var BillingAddressComponent = /** @class */ (function () {
    function BillingAddressComponent(store, formsService) {
        var _this = this;
        this.store = store;
        this.formsService = formsService;
        this.billingAddressList = [];
        this.selectedBuyerBillAddressId = -1;
        this.isEditEnabled = true;
        this.subscriptions = new Subscription();
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
        this.formSaveCallback = function (inputs, rerender) {
            _this.inputs = _this.formsService.updateInputs(_this.inputs, inputs);
            var billingAddressPayload = {
                formInfo: _this.PersonaliseFormsKeys.billingAddress,
                inputSet: {
                    rerender: true,
                    list: _this.inputs
                }
            };
            _this.store.dispatch(new stepsActions.SetInputs(billingAddressPayload, true));
            if (rerender) {
                _this.form = _this.formsService.toFormGroup(_this.inputs, _this.PersonaliseFormsKeys.billingAddress);
            }
        };
    }
    BillingAddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.store.pipe(select(fromRoot.getBuyerActiveBillingAddressId)).subscribe(function (selectedBuyerBillAddressId) { return _this.selectedBuyerBillAddressId = selectedBuyerBillAddressId; }));
        this.subscriptions.add(this.store.pipe(select(fromRoot.isUserLoggedIn)).subscribe(function (isLoggedIn) { return _this.isLoggedIn = isLoggedIn; }));
        this.store.pipe(select(fromRoot.getProfile), first(function (profile) { return !!profile; })).subscribe(function (user) {
            _this.isLoggedIn = true;
            _this.store.dispatch(new userActions.GetProfileBillingAddress(user.id));
        });
        // Load list of billing addresses via API request
        this.store.pipe(select(fromRoot.getProfileBillingAddress)).subscribe(function (billingAddressList) { return _this.billingAddressList = billingAddressList; });
        // once Addresses are loaded via API we can subscribe to them from redux here
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(fromRoot.getBillingAddressForm), filter(function (data) { return !!data; })),
            this.store.pipe(select(fromRoot.isUserLoggedIn))
        ]).subscribe(function (_a) {
            var billingAddress = _a[0], isLoggedIn = _a[1];
            if (billingAddress.list.length) {
                var input = billingAddress.list.find(function (input) { return input.key === 'save-address'; });
                input.hidden = !isLoggedIn;
                if (!isLoggedIn) {
                    input.options[0].value = false;
                }
                // update is needed for changes like browser address autocomplete or google address API autocomplete
                if (!_this.form) {
                    _this.inputs = _this.formsService.updateInputs(_this.inputs, billingAddress.list);
                    var indexOfSelectedBillingAddress = Object.keys(_this.billingAddressList)
                        .findIndex(function (key) { return _this.billingAddressList[key].id === _this.selectedBuyerBillAddressId; });
                    _this.inputs.some(function (item) { return !!item.value; }) ? null : _this.onChange(indexOfSelectedBillingAddress);
                    _this.rerenderForm(_this.inputs);
                }
            }
        }));
    };
    BillingAddressComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    BillingAddressComponent.prototype.onChange = function (value) {
        var _this = this;
        var selectedBillingAddressId = null;
        if (value >= 0) {
            selectedBillingAddressId = this.billingAddressList[value].id;
            // Change values and readonly attribute of billing address after selecting saved address
            this.inputs.forEach(function (input) {
                input.value = _this.billingAddressList[value][input.key];
                input.disabled = true;
                if (input.key === 'save-address') {
                    input.hidden = true;
                    input.options[0].value = false;
                }
            });
            this.isEditEnabled = false;
        }
        else {
            this.inputs.forEach(function (input) {
                input.value = undefined;
                input.disabled = false;
                if (input.key === 'save-address') {
                    input.hidden = false;
                }
            });
            this.isEditEnabled = true;
        }
        this.store.dispatch(new stepsActions.SetBuyerActiveBillingAddressId(selectedBillingAddressId));
        this.rerenderForm(this.inputs);
    };
    BillingAddressComponent.prototype.rerenderForm = function (inputs) {
        //make sure we are not prerendering with references
        var clonedInputs = cloneDeep(inputs);
        this.form = this.formsService.toFormGroup(clonedInputs, this.PersonaliseFormsKeys.billingAddress);
        this.inputs = clonedInputs;
    };
    return BillingAddressComponent;
}());
export { BillingAddressComponent };
