/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromColorizer from '@app/colorizer/colorizer.reducer';
import * as fromPressNews from '@app/press-news/press-news.reducer';
import * as fromAdditionalServices from '@store/additional-services/additional-services.reducer';
import * as fromCustomization from '@store/customization/customization.reducer';
import * as fromExhibition from '@store/exhibition/exhibition.reducer';
import * as fromHelper from '@store/helpers/helper.reducer';
import * as fromLegitimation from '@store/legitimation/legitimation.reducer';
import { reducer as productsReducer, State as productsState } from '@store/products/product.reducer';
import * as fromStepsForms from '@store/step-forms/steps-forms.reducer';
import { reducer as translationReducer, State as translationState } from '@store/translation/translation.reducer';
import * as fromUser from '@store/user/user.reducer';

import { ActionReducer, ActionReducerMap, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { UserModel } from '@store/user/user.interface';

// import models used in selectors
import { environment } from '@src/environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { LoginOptions } from '@app/login/login.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import {
  consoleLog,
  deactivateCurrentSession,
  deleteInactiveSessions,
  getLocalStorageObject,
  getOrCreateSessionId,
  pageRunningInIframe,
  removeLocalStorageItem,
  removeLocalStorageItemsForSession
} from '@shared/app-utils';
import { PromoCodeVisibility } from '@store/customization/customization.interfaces';

import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  additionalServices: fromAdditionalServices.State;
  exhibitions: fromExhibition.State;
  user: fromUser.State;
  customization: fromCustomization.State;
  translation: translationState;
  legitimation: fromLegitimation.State;
  stepsForms: fromStepsForms.State;
  pressnews: fromPressNews.State;
  helper: fromHelper.State;
  colorizer: fromColorizer.State;
  products: productsState;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */

let loggedInUser: UserModel;
const isIE = !!navigator.userAgent.match(/Trident/);

if (!!getLocalStorageObject(AppConstants.userReducer)) {
  loggedInUser = getLocalStorageObject(AppConstants.userReducer).user;
}

window.onbeforeunload = () => {
  deactivateCurrentSession();
};

window.onunload = () => {
  //we need to do this in onuload event since Safari browser on iOS doesn't support onbeforeunload event:
  deactivateCurrentSession();
};

window.onpagehide = () => {
  //we need to do this in onuload event since Safari browser on iOS doesn't support onbeforeunload event:
  deactivateCurrentSession();
};

function storageSyncEnabled() {
  let syncEnabled = !pageRunningInIframe();

  return syncEnabled;
}

/**
 * A list of all "global" reducers for which all data is stored into localStorage under the same global key (without a specific sessionId prefix).
 * Here we should only add reducers containing data which should be shared between browser tabs/windows on the same domain/subdomain.
 * @returns List of all "global" reducers
 */
export const synchronizedGlobalReducers = () => {
  if (storageSyncEnabled()) {
    return AppConstants.syncedGlobalReducers;
  }

  return [];
};

/**
 * A list of all "session specific" reducers for which all data is stored into localStorage under a key prefixed by a specific sessionId.
 * Here we should add reducers containing data which should NOT be shared between browser tabs/windows on the same domain/subdomain.
 * @returns List of all "session specific" reducers
 */
export const synchronizedSessionReducers = () => {
  const isAdmin = loggedInUser && AppConstants.getUserTypeFromJwt(loggedInUser) === 'admin';

  if (storageSyncEnabled()) {
    if (isAdmin || isIE) {
      return AppConstants.syncedAdminSessionReducers;
    } else {
      return AppConstants.syncedUserSessionReducers;
    }
  }

  return [];
};

export const reducers: ActionReducerMap<State> = {
  additionalServices: fromAdditionalServices.reducer,
  exhibitions: fromExhibition.reducer,
  user: fromUser.reducer,
  customization: fromCustomization.reducer,
  translation: translationReducer,
  legitimation: fromLegitimation.reducer,
  stepsForms: fromStepsForms.reducer,
  pressnews: fromPressNews.reducer,
  helper: fromHelper.reducer,
  colorizer: fromColorizer.reducer,
  products: productsReducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
  return function(state: State, action: any): State {
    return reducer(state, action);
  };
}

export function localStorageSyncGlobalReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: synchronizedGlobalReducers(),
    rehydrate: true
  })(reducer);
}

export function localStorageSyncSessionReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const sessionId = getOrCreateSessionId();
  const helperReducer = getLocalStorageObject(AppConstants.helperReducer);

  if (helperReducer && helperReducer.reloadRequired) {
    consoleLog('Page reset and reload was requested, clearing localStorage for the current session...');
    //remove session specific settings in localStorage:
    removeLocalStorageItemsForSession(sessionId);

    //remove global settings in localStorage (not session specific):
    removeLocalStorageItem(AppConstants.userReducer);
    removeLocalStorageItem(AppConstants.hideDataProtectionOnHomePageReducer);
    removeLocalStorageItem(AppConstants.hideDataProtectionOnEventReducer);
  }

  deleteInactiveSessions();

  return localStorageSync({
    keys: synchronizedSessionReducers(),
    rehydrate: true,
    //these keys MUST be prefixed by sessionId:
    storageKeySerializer: key => `${sessionId}_${key}`
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = !environment.production
  ? [logger, localStorageSyncGlobalReducer, localStorageSyncSessionReducer]
  : [localStorageSyncGlobalReducer, localStorageSyncSessionReducer];

/*   export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: ['todos']})(reducer);
  } */

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
                                SELECTORS
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getState = (state: State) => state;

//#region exhibition
export const getExhibitionState = createFeatureSelector<fromExhibition.State>('exhibitions');

export const getAllExhibitionsCollection = createSelector(
  getExhibitionState,
  fromExhibition.getAllExhibitions
);
export const getSelectedExhibition = createSelector(
  getExhibitionState,
  fromExhibition.getSelected
);
export const getSelectedExhibitionId = createSelector(
  getExhibitionState,
  fromExhibition.getSelectedExhibitionId
);
export const getExhibitionHistoryList = createSelector(
  getExhibitionState,
  fromExhibition.getExhibitionHistoryList
);
export const getDoubleClickScripts = createSelector(
  getExhibitionState,
  fromExhibition.getDoubleClickScripts
);
export const getGenericScripts = createSelector(
  getExhibitionState,
  fromExhibition.getGenericScripts
);
export const isFirstLoad = createSelector(
  getExhibitionState,
  fromExhibition.isFirstLoad
);
export const getTitles = createSelector(
  getExhibitionState,
  fromExhibition.getTitles
);
export const getProfessions = createSelector(
  getExhibitionState,
  fromExhibition.getProfessions
);
export const getDepartments = createSelector(
  getExhibitionState,
  fromExhibition.getDepartments
);
export const getOccupationalGroups = createSelector(
  getExhibitionState,
  fromExhibition.getOccupationalGroups
);
export const getAllTitles = createSelector(
  getExhibitionState,
  fromExhibition.getAllTitles
);
export const getAllProfessions = createSelector(
  getExhibitionState,
  fromExhibition.getAllProfessions
);
export const getAllDepartments = createSelector(
  getExhibitionState,
  fromExhibition.getAllDepartments
);
export const getAllOccupationalGroups = createSelector(
  getExhibitionState,
  fromExhibition.getAllOccupationalGroups
);
//#endregion

//#region helpers
export const getHelpersState = createFeatureSelector<fromHelper.State>('helper');
export const getAllCountriesList = createSelector(
  getHelpersState,
  fromHelper.getAllCountries
);

export const getLanguage = createSelector(
  getHelpersState,
  fromHelper.getLanguage
);
export const getSupportedLanguages = createSelector(
  getHelpersState,
  fromHelper.getSupportedLanguages
);

export const getSelfRegistration = createSelector(
  getHelpersState,
  fromHelper.getSelfRegistration
);

export const isEventSeriesPage = createSelector(
  getHelpersState,
  fromHelper.isEventSeriesPage
);

export const getModalIframeUrl = createSelector(
  getHelpersState,
  fromHelper.getModalIframeUrl
);

export const getSpinnerValue = createSelector(
  getHelpersState,
  fromHelper.getSpinnerValue
);

export const getAllCitiesByZipCode = createSelector(
  getHelpersState,
  fromHelper.getAllCitiesByZipCode
);

export const getReloadRequired = createSelector(
  getHelpersState,
  fromHelper.getReloadRequired
);

export const getIsWidget = createSelector(
  getHelpersState,
  fromHelper.getIsWidget
);
//#endregion

//#region users
export const getUserState = createFeatureSelector<fromUser.State>('user');

export const getUser = createSelector(
  getUserState,
  fromUser.getUser
);
export const getLoginTimestamp = createSelector(
  getUserState,
  fromUser.getLoginTimestamp
);
export const isProfileLoading = createSelector(
  getUserState,
  fromUser.isProfileLoading
);
export const getProfile = createSelector(
  getUserState,
  fromUser.getProfile
);
export const getUserLanguage = createSelector(
  getUserState,
  fromUser.getLanguage
);

export const isUserLoggedIn = createSelector(
  getUserState,
  fromUser.isUserLoggedIn
);
export const isLoggedInAsAdmin = createSelector(
  getUserState,
  fromUser.isLoggedInAsAdmin
);
export const getRegistrationQuestionnaire = createSelector(
  getUserState,
  fromUser.getRegistrationQuestionnaire
);
export const getEventsEmailUnsubscriptions = createSelector(
  getUserState,
  fromUser.getEventsEmailUnsubscriptions
);
export const getProfileBillingAddress = createSelector(
  getUserState,
  fromUser.getProfileBillingAddress
);
export const getSelectedBillingAddressId = createSelector(
  getUserState,
  fromUser.getSelectedBillingAddressId
);
export const getPassRecoverySuccess = createSelector(
  getUserState,
  fromUser.getPassRecoverySuccess
);
export const passChangeStatus = createSelector(
  getUserState,
  fromUser.passChangeStatus
);

export const emailChangeStatus = createSelector(
  getUserState,
  fromUser.emailChangeStatus
);

export const emailVerifyStatus = createSelector(
  getUserState,
  fromUser.emailVerifyStatus
);
//#endregion

//#region customization
export const getCustomizationState = createFeatureSelector<fromCustomization.State>('customization');
export const getOperatorsSettings = createSelector(
  getCustomizationState,
  fromCustomization.getOperatorsSettings
);
export const getWidgetSettings = createSelector(
  getCustomizationState,
  fromCustomization.getWidgetSettings
);
export const getExhibitionStyles = createSelector(
  getCustomizationState,
  fromCustomization.getExhibitionStyles
);
export const getSponsorBanner = createSelector(
  getCustomizationState,
  fromCustomization.getSponsorBanner
);
export const getExhibitionSettings = createSelector(
  getCustomizationState,
  fromCustomization.getExhibitionSettings
);
export const getLocalTime = createSelector(
  getExhibitionSettings,
  settings => {
    if (settings) {
      return new Date(settings.localTime);
    }
    return null;
  }
);
export const isReadonlyBuyer = createSelector(
  getCustomizationState,
  fromCustomization.isReadonlyBuyer
);
export const getLoginMode = createSelector(
  getCustomizationState,
  fromCustomization.getLoginMode
);
export const getLocalizedImages = createSelector(
  getCustomizationState,
  fromCustomization.getLocalizedImages
);
export const getOrderUuid = createSelector(
  getCustomizationState,
  fromCustomization.getOrderUuid
);
export const getShoppingStartTime = createSelector(
  getCustomizationState,
  fromCustomization.getShoppingStartTime
);
export const ageRating = createSelector(
  getExhibitionSettings,
  fromCustomization.ageRating
);
export const uniqueVisitorCheckType = createSelector(
  getExhibitionSettings,
  fromCustomization.uniqueVisitorCheckType
);
export const isDownloadTicketButtonVisible = createSelector(
  getExhibitionSettings,
  fromCustomization.isDownloadTicketButtonVisible
);
export const getTicketLimitPerUserAccount = createSelector(
  getExhibitionSettings,
  fromCustomization.getTicketLimitPerUserAccount
);
export const getTariffReleaseInMinutes = createSelector(
  getCustomizationState,
  fromCustomization.getTariffReleaseInMinutes
);
//#endregion

//#region legitimation
export const getLegitimationState = createFeatureSelector<fromLegitimation.State>('legitimation');
export const getLegitimationList = createSelector(
  getLegitimationState,
  fromLegitimation.getLegitimationList
);
export const getLegitimationStatus = createSelector(
  getLegitimationState,
  fromLegitimation.getLegitimationStatus
);
export const getLegitimationFaxId = createSelector(
  getLegitimationState,
  fromLegitimation.getLegitimationFaxId
);
export const getLegitimationPostResponse = createSelector(
  getLegitimationState,
  fromLegitimation.getLegitimationPostResponse
);
//#endregion

//#region steps forms
export const getStepsForms = createFeatureSelector<fromStepsForms.State>('stepsForms');
export const getAddressCheckbox = createSelector(
  getStepsForms,
  fromStepsForms.getAddressCheckbox
);
export const getBuyerVisitorCheckbox = createSelector(
  getStepsForms,
  fromStepsForms.getBuyerVisitorCheckbox
);
export const getCoppiedHoldersIndexes = createSelector(
  getStepsForms,
  fromStepsForms.getCoppiedHoldersIndexes
);
export const getSteps = createSelector(
  getStepsForms,
  fromStepsForms.getSteps
);
export const getSelectedStep = createSelector(
  getStepsForms,
  fromStepsForms.getSelectedStep
);
export const getStepsValidity = createSelector(
  getStepsForms,
  fromStepsForms.getStepsValidity
);
export const getTicketHoldersValidity = createSelector(
  getStepsForms,
  fromStepsForms.getTicketHoldersValidity
);
export const getBuyerInfoValidity = createSelector(
  getStepsForms,
  fromStepsForms.getBuyerInfoValidity
);
export const getQuestionnaire = createSelector(
  getStepsForms,
  fromStepsForms.getQuestionnaire
);
export const getSelfRegQuestionnaire = createSelector(
  getStepsForms,
  fromStepsForms.getSelfRegQuestionnaire
);
export const getBuyerInfo = createSelector(
  getStepsForms,
  fromStepsForms.getBuyerInfo
);
export const getBuyerInfoFromURL = createSelector(
  getStepsForms,
  fromStepsForms.getBuyerInfoFromURL
);
export const getConfirmationCheckboxesInputs = createSelector(
  getStepsForms,
  fromStepsForms.getConfirmationCheckboxesInputs
);
export const getPrivacyInput = createSelector(
  getStepsForms,
  fromStepsForms.getPrivacyInput
);
export const getTicketHolderInputSets = createSelector(
  getStepsForms,
  fromStepsForms.getTicketHolderInputSets
);

export const getSkipHoldersCheck = createSelector(
  getStepsForms,
  fromStepsForms.getSkipHoldersCheck
);

export const getBillingAddressForm = createSelector(
  getStepsForms,
  fromStepsForms.getBillingAddressForm
);
export const getBuyerActiveBillingAddressId = createSelector(
  getStepsForms,
  fromStepsForms.getBuyerActiveBillingAddressId
);
export const isContinueAsGuest = createSelector(
  getStepsForms,
  fromStepsForms.isContinueAsGuest
);

export const getOrderedStepsValidityArray = createSelector(
  getStepsValidity,
  stepsValidity => {
    const stepList = [];

    for (const key in stepsValidity) {
      if (stepsValidity.hasOwnProperty(key)) {
        stepList.push({
          key: key,
          value: stepsValidity[key]
        });
      }
    }

    stepList.sort((a, b) => {
      return a.value.order - b.value.order;
    });

    return stepList;
  }
);

export const getTicketHolderAdditionalData = createSelector(
  getStepsForms,
  fromStepsForms.getTicketHolderAdditionalData
);

export const isDifferenBillingAddressUsed = createSelector(
  getStepsForms,
  fromStepsForms.isDifferenBillingAddressUsed
);

export const getOrderResponse = createSelector(
  getStepsForms,
  fromStepsForms.getOrderResponse
);

export const getPaymentMethod = createSelector(
  getStepsForms,
  fromStepsForms.getPaymentMethod
);

export const getTicketHoldersFormsValidity = createSelector(
  getStepsForms,
  fromStepsForms.getTicketHoldersFormsValidity
);

export const isBuyerInfoFormValid = createSelector(
  getStepsForms,
  fromStepsForms.isBuyerInfoFormValid
);

export const getAnonymousHiddenSteps = createSelector(
  getStepsForms,
  fromStepsForms.getAnonymousHiddenSteps
);

export const getQuestionnaireTicketPersonIds = createSelector(
  getStepsForms,
  fromStepsForms.getQuestionnaireTicketPersonIds
);

export const getTicketHolderInputsKeys = createSelector(
  getStepsForms,
  fromStepsForms.getTicketHolderInputsKeys
);
//#endregion

//#region press news
export const getPressNewsState = createFeatureSelector<fromPressNews.State>('pressnews');
export const getPressNewsCollection = createSelector(
  getPressNewsState,
  fromPressNews.getAll
);
export const getSelectedPressNewsId = createSelector(
  getPressNewsState,
  fromPressNews.getSelectedPressNewsId
);
export const getPressNewsDetail = createSelector(
  getPressNewsState,
  fromPressNews.getPressNewsDetail
);
//#endregion

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
                            COLORIZER
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const getColorizerState = createFeatureSelector<fromColorizer.State>('colorizer');

export const getAllStyles = createSelector(
  getColorizerState,
  fromColorizer.allStyleTemplates
);

export const getSelectedStyle = createSelector(
  getColorizerState,
  fromColorizer.getStyle
);

export const getStyleId = createSelector(
  getColorizerState,
  fromColorizer.getStyleId
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
                            COMBINED SELECTORS
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const showPromoCode = createSelector(
  isUserLoggedIn,
  getExhibitionSettings,
  (isLoggedIn, settings) => {
    if (settings) {
      return (
        settings.promoCodeVisible === PromoCodeVisibility.AlwaysVisible ||
        (settings.promoCodeVisible === PromoCodeVisibility.AfterLogin && isLoggedIn)
      );
    }
    return true;
  }
);

export const isTicketHolderVisible = createSelector(
  getTicketHolderInputSets,
  getSelectedSendingOption,
  getExhibitionSettings,
  (ticketHolderInputSets, selectedSendingOption, settings) =>
    settings &&
    ticketHolderInputSets &&
    selectedSendingOption &&
    !(!settings.isTicketHolderVisible && selectedSendingOption === TicketSendingOptions.AllToBuyer)
);

export const showLoginOnTicketPage = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result = loginMode === LoginOptions.OptionalTicketLogin && !isLoggedIn;
    return result;
  }
);

export const isLastNameForForgottenPasswordRequired = createSelector(
  getExhibitionSettings,
  getOperatorsSettings,
  (exhibition, operator) => {
    if (exhibition && exhibition.enableLastNameForForgottenPassword) {
      return true;
    }
    if (operator && operator.enableLastNameForForgottenPassword) {
      return true;
    }
    return false;
  }
);

export const notLoggedAndLoginMandatory = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result = loginMode === LoginOptions.BeforeTicketSelection && !isLoggedIn;
    return result;
  }
);

export const showLoginOnPersonalPage = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result = loginMode === LoginOptions.OptionalPersonalLogin && !isLoggedIn;
    return result;
  }
);

export const showLoginOnTicketAndPersonalPage = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result = loginMode === LoginOptions.OptionalTicketAndPersonalLogin && !isLoggedIn;
    return result;
  }
);

export const isBuyerOnPersonalPageHidden = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result =
      (loginMode === LoginOptions.OptionalPersonalLogin ||
        loginMode === LoginOptions.OptionalTicketAndPersonalLogin ||
        loginMode === LoginOptions.PersonalizationWithRequiredEmailConfirmation) &&
      !isLoggedIn;
    return result;
  }
);

export const hideTopBarLogin = createSelector(
  getLoginMode,
  isUserLoggedIn,
  (loginMode, isLoggedIn) => {
    const result = loginMode === LoginOptions.HideTopBarLogin && !isLoggedIn;
    return result;
  }
);

export const isUserLoggedInAndVerified = createSelector(
  isUserLoggedIn,
  getProfile,
  (isLoggedIn, userProfile) => {
    return isLoggedIn && userProfile && userProfile.isEmailVerified;
  }
);

/**
 * Returns true if loginMode is 'personalizationWithRequiredEmailConfirmation' and
 * the user isn't logged in or his/her e-mail isn't verified yet.
 */
export const isConfirmedEmailRequiredOnPersonalization = createSelector(
  getLoginMode,
  isUserLoggedInAndVerified,
  (loginMode, isUserLoggedInAndVerified) => {
    return loginMode === LoginOptions.PersonalizationWithRequiredEmailConfirmation && !isUserLoggedInAndVerified;
  }
);

export const getUserAccountTicketLimitCheck = createSelector(
  getLoginMode,
  isUserLoggedIn,
  getSelectedExhibitionId,
  getTicketLimitPerUserAccount,
  (loginMode, isUserLoggedIn, exhibitionId, ticketLimitPerUserAccount) => {
    return {
      checkUserAccountTicketLimit:
        loginMode === LoginOptions.BeforeTicketSelection &&
        isUserLoggedIn &&
        !!exhibitionId &&
        !!ticketLimitPerUserAccount,
      exhibitionId
    };
  }
);
