import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { State } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OrderResponseModel } from '@products/models/order.model';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { QuestionnaireDataSection } from '@store/customization/customization.interfaces';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { FormInputsPayloadModel, InputsListModel, VisibilityPayloadModel } from '@store/step-forms/step.interface';
import {
  ActionTypes,
  GetBuyerQuestionnaire,
  SetInputs,
  SetOrderResponse,
  SetStepsVisibility,
  sendOrder
} from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

export const USER_DEBOUNCE = new InjectionToken<number>('User Debounce');

@Injectable()
export class StepsFormsEffect {
  @Effect()
  buyerQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<GetBuyerQuestionnaire>(ActionTypes.GET_BUYER_QUESTIONNAIRE),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { eventId, stepName, lang, ticketPersonIds, previousQuestionnare } = data.payload;
      if (eventId === null) {
        return EMPTY;
      }

      let questionnairePayload: FormInputsPayloadModel = {
        formInfo: [stepName, 'questionnaire'],
        inputSet: {
          rerender: false,
          list: []
        }
      };

      return this._stepsFormsService.getBuyerQuestionnaire(eventId, ticketPersonIds).pipe(
        map((buyerQuestionnaire: QuestionnaireDataSection[]) => {
          /* Questionnaire */
          const questionnaires = this.customizationService.tansformQuestionnaireIntoInput(buyerQuestionnaire);

          questionnairePayload.inputSet.list = questionnaires;

          this.mergeQuestionnares(previousQuestionnare, questionnairePayload.inputSet);

          const visibilityPayload: VisibilityPayloadModel = {
            stepKey: 'questionnaire',
            visible: questionnairePayload.inputSet.list.length > 0 && this.helperService.isSelfregistration()
          };

          this.store.dispatch(new SetStepsVisibility([visibilityPayload]));

          return new SetInputs(questionnairePayload);
        }),
        catchError(() => of(new SetInputs(questionnairePayload)))
      );
    })
  );

  @Effect()
  sendOrder$: Observable<Action> = this.actions$.pipe(
    ofType<sendOrder>(ActionTypes.SEND_ORDER),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return EMPTY;
      }

      const orderData = data.payload;
      return this._stepsFormsService.sendFinalOrder(orderData).pipe(
        map((orderResponseData: any) => {
          const orderResponse = <OrderResponseModel>{
            ...orderResponseData.body,
            orderPaid: false
          };

          if (!orderResponse.success) {
            this.errorHandlingService.errorHandler(orderResponse);
          }

          this.customizationService.resetShoppingStartTime();
          return new SetOrderResponse(orderResponse);
        }),
        catchError(() => of(new SetOrderResponse({ error: 'fail' })))
      );
    })
  );

  mergeQuestionnares(previousQuestionnare: InputsListModel, currentQuestionnare: InputsListModel) {
    if (!previousQuestionnare || !previousQuestionnare.list) {
      return;
    }

    currentQuestionnare.list.map((current: InputBase<any>) => {
      let previousElement = previousQuestionnare.list.find(previous => previous.key === current.key);
      if (!previousElement) return;

      if (current.controlType === 'checkbox' && previousElement.controlType === 'checkbox') {
        previousElement.options.map(previousOption => {
          let currentOption = current.options.find(currentOption => currentOption.key === previousOption.key);

          if (!currentOption) return;

          currentOption.value = previousOption.value;
        });
      }
      current.value = previousElement.value;
    });
  }

  constructor(
    private actions$: Actions,
    private _stepsFormsService: StepsFormsService,
    @Optional()
    @Inject(USER_DEBOUNCE)
    private debounce: number = 50,
    private customizationService: CustomizationService,
    private store: Store<State>,
    private helperService: HelperService,
    private errorHandlingService: ErrorHandlingService
  ) {}
}
