var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { getSelfRegistration } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { RemovePackageFromSelectionList, RemoveVoucherCodeProductFromSelectionList, RemoveVoucherProductFromSelectionList, SetPackageInSelectionList, SetVoucherProductInSelectionList } from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
var ProductSelectionEffect = /** @class */ (function () {
    function ProductSelectionEffect(actions$, store) {
        this.actions$ = actions$;
        this.store = store;
        this.addVoucher$ = this.actions$.pipe(ofType(BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST), withLatestFrom(this.store.pipe(select(getSelfRegistration))), map(function (_a) {
            var payload = _a[0].payload, isSelfRegistration = _a[1];
            return new SetVoucherProductInSelectionList({ bookingProductTypes: payload, isSelfRegistration: isSelfRegistration });
        }));
        this.removeVoucherByVoucherCode$ = this.actions$.pipe(ofType(ProductActionTypes.REMOVE_RELEASED_AND_REMOVED_BOOKING_VOUCHER), map(function (_a) {
            var payload = _a.payload;
            return new RemoveVoucherCodeProductFromSelectionList(payload);
        }));
        this.removeVoucher$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            return new RemoveVoucherProductFromSelectionList(payload);
        }));
        this.addPackage$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            return new SetPackageInSelectionList(payload);
        }));
        this.removePackage$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            return new RemovePackageFromSelectionList(payload);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductSelectionEffect.prototype, "addVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductSelectionEffect.prototype, "removeVoucherByVoucherCode$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductSelectionEffect.prototype, "removeVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductSelectionEffect.prototype, "addPackage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductSelectionEffect.prototype, "removePackage$", void 0);
    return ProductSelectionEffect;
}());
export { ProductSelectionEffect };
