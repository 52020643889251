import { OrderPayloadModel, OrderResponseModel } from '@products/models/order.model';
import {
  AddressCheckboxModel,
  BuyerVisitorCheckboxModel,
  FormInputsPayloadModel,
  FormStatusPayloadModel,
  InputsListModel,
  OneStepModel,
  TicketHolderAdditionalDataModel,
  ValidityModel,
  VisibilityPayloadModel
} from './step.interface';
import { ActionTypes, Actions } from './steps-forms.actions';

import cloneDeep from 'lodash.clonedeep';
import { createSelector } from 'reselect';

export interface State {
  tickets: OneStepModel;
  personal: OneStepModel;
  questionnaire: OneStepModel;
  workshop: OneStepModel;
  menu: OneStepModel;
  legitimation: OneStepModel;
  confirmation: OneStepModel;
  recipe: OneStepModel;
  payment: OneStepModel;
  invoice: OneStepModel;
  validity: ValidityModel;
  selectedStep: string;
  addressCheckbox: AddressCheckboxModel;
  buyerVisitorCheckbox: BuyerVisitorCheckboxModel;
  coppiedHoldersDataIndexes: number[];
  ticketHolderAdditionalData: TicketHolderAdditionalDataModel;
  buyerActiveBillingAddressId: number;
  isContinueAsGuest: boolean;
  orderResponse: OrderResponseModel;
  paymentMethod: string;
  buyerInfoFromURL: {};
  ticketHoldersFormsValidity: boolean[];
  isBuyerInfoFormValid: boolean;
  anonymousHiddenSteps: string[];
  skipHoldersCheck: boolean;
  questionnaireTicketPersonIds: number[];
}

export const initialState: State = {
  tickets: {
    forms: null
  },
  personal: {
    forms: {
      buyerinfo: {
        rerender: true,
        list: []
      },
      billingaddress: {
        rerender: true,
        list: []
      },
      questionnaire: {
        rerender: true,
        list: null
      },
      privacy: {
        rerender: true,
        list: []
      }
    }
  },
  questionnaire: {
    forms: {
      questionnaire: {
        rerender: true,
        list: null
      }
    }
  },
  workshop: {
    forms: null
  },
  menu: {
    forms: null
  },
  legitimation: {
    forms: null
  },
  confirmation: {
    forms: {
      checkboxes: {
        rerender: true,
        list: []
      }
    }
  },
  recipe: {
    forms: null
  },
  payment: {
    forms: null
  },
  invoice: {
    forms: null
  },
  validity: {
    tickets: {
      valid: true,
      disabled: false,
      forms: {
        login: false,
        ticketSelection: false
      },
      order: 2,
      visible: true,
      showInStepNavigation: true
    },
    legitimation: {
      valid: false,
      disabled: true,
      order: 4,
      visible: true,
      forms: {
        validation: false
      },
      showInStepNavigation: true
    },
    personal: {
      valid: false,
      disabled: true,
      forms: {
        buyerinfo: false,
        billingaddress: false,
        questionnaire: false,
        privacy: false
      },
      order: 8,
      visible: true,
      showInStepNavigation: true
    },
    questionnaire: {
      valid: false,
      disabled: true,
      forms: {
        questionnaire: false
      },
      order: 9,
      visible: false,
      showInStepNavigation: true
    },

    workshop: {
      valid: false,
      disabled: true,
      order: 10,
      visible: false,
      forms: {
        validation: false
      },
      showInStepNavigation: true
    },
    menu: {
      valid: true,
      disabled: false,
      order: 12,
      visible: false,
      showInStepNavigation: true
    },
    confirmation: {
      valid: false,
      disabled: true,
      order: 14,
      visible: true,
      forms: {
        checkboxes: false
      },
      showInStepNavigation: true
    },
    recipe: {
      valid: false,
      disabled: true,
      order: 15,
      visible: false,
      showInStepNavigation: true
    },
    payment: {
      valid: false,
      disabled: true,
      order: 16,
      visible: true,
      forms: {
        validation: false
      },
      showInStepNavigation: false
    },
    invoice: {
      valid: false,
      disabled: true,
      order: 17,
      visible: true,
      showInStepNavigation: true
    }
  },
  ticketHoldersFormsValidity: [],
  isBuyerInfoFormValid: false,
  selectedStep: null,
  addressCheckbox: null,
  buyerVisitorCheckbox: null,
  coppiedHoldersDataIndexes: [],
  ticketHolderAdditionalData: {},
  buyerActiveBillingAddressId: null,
  isContinueAsGuest: false,
  orderResponse: null,
  paymentMethod: null,
  buyerInfoFromURL: null,
  anonymousHiddenSteps: [],
  skipHoldersCheck: false,
  questionnaireTicketPersonIds: []
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_SELECTED_STEP:
      return {
        ...state,
        selectedStep: action.payload
      };

    case ActionTypes.SET_FORM_INPUTS: {
      const inputsPayload = action.payload as FormInputsPayloadModel;
      const skipHoldersCheck = action.skipHoldersCheck || false;
      if (inputsPayload) {
        const stepClone = { ...state[inputsPayload.formInfo[0]] };

        stepClone.forms[inputsPayload.formInfo[1]] = inputsPayload.inputSet;
        return {
          ...state,
          [inputsPayload.formInfo[0]]: stepClone,
          skipHoldersCheck: skipHoldersCheck
        };
      } else {
        return state;
      }
    }
    case ActionTypes.REMOVE_FORM: {
      const formActionName = action.payload;
      const stepClone = cloneDeep(state[formActionName[0]]);
      delete stepClone.forms[formActionName[1]];

      return {
        ...state,
        [formActionName[0]]: stepClone
      };
    }
    case ActionTypes.SET_STEP_VISIBILITY: {
      const visibilityPayload = action.payload as VisibilityPayloadModel[];
      const stateValidityClone = cloneDeep(state.validity);
      visibilityPayload.map(item => {
        stateValidityClone[item.stepKey].visible = item.visible;
      });

      return {
        ...state,
        validity: stateValidityClone
      };
    }

    case ActionTypes.SET_MULTIPLE_STEPS_VISIBILITY: {
      const visibilityPayload = action.payload as VisibilityPayloadModel[];
      const stateValidityClone = cloneDeep(state.validity);
      visibilityPayload.forEach((step, index: number) => {
        stateValidityClone[step.stepKey].visible = step.visible;
      });

      return {
        ...state,
        validity: stateValidityClone
      };
    }

    case ActionTypes.SET_STEP_ORDER: {
      const orderPayload = action.payload as OrderPayloadModel;
      const stateValidityClone = cloneDeep(state.validity);
      stateValidityClone[orderPayload.stepKey].order = orderPayload.newOrder;

      return {
        ...state,
        validity: stateValidityClone
      };
    }

    case ActionTypes.SET_FORM_VALIDITY: {
      const validityPayload = action.payload as FormStatusPayloadModel;
      const validityClone = cloneDeep(state.validity);
      validityClone[validityPayload.formInfo[0]].forms[validityPayload.formInfo[1]] = validityPayload.valid;

      const wholeFormValid = Object.keys(validityClone[validityPayload.formInfo[0]].forms)
        .map(formName => {
          return validityClone[validityPayload.formInfo[0]].forms[formName];
        })
        .reduce((agr, current: boolean) => {
          return current && agr;
        });

      validityClone[validityPayload.formInfo[0]].valid = wholeFormValid;

      // once validity is set for all steps, we can tell which steps are disabled
      Object.keys(validityClone)
        .sort((a, b) => {
          return validityClone[a].order - validityClone[b].order;
        })
        .filter(stepKey => {
          return validityClone[stepKey].visible;
        })
        .reduce(
          (lastStep, currentKey) => {
            validityClone[currentKey].disabled = lastStep.disabled || !lastStep.valid;
            return {
              valid: validityClone[currentKey].valid,
              disabled: validityClone[currentKey].disabled
            };
          },
          { valid: true, disabled: false }
        );

      return {
        ...state,
        validity: validityClone
      };
    }

    case ActionTypes.REMOVE_FORMS_VALIDITY: {
      const validityPayloads = action.payload as FormStatusPayloadModel[];
      if (validityPayloads.length) {
        const validityClone = cloneDeep(state.validity);

        validityPayloads.forEach(validityPayload => {
          delete validityClone[validityPayload.formInfo[0]].forms[validityPayload.formInfo[1]];
        });

        const validityPayloadFormName = validityPayloads[0].formInfo[0];

        const wholeFormValid = Object.keys(validityClone[validityPayloadFormName].forms)
          .map(formName => {
            return validityClone[validityPayloadFormName].forms[formName];
          })
          .reduce((agr, current: boolean) => {
            return current && agr;
          });

        validityClone[validityPayloadFormName].valid = wholeFormValid;

        // once validity is set for all steps, we can tell which steps are disabled
        Object.keys(validityClone)
          .sort((a, b) => {
            return validityClone[a].order - validityClone[b].order;
          })
          .filter(stepKey => {
            return validityClone[stepKey].visible;
          })
          .reduce(
            (lastStep, currentKey) => {
              validityClone[currentKey].disabled = lastStep.disabled || !lastStep.valid;
              return {
                valid: validityClone[currentKey].valid,
                disabled: validityClone[currentKey].disabled
              };
            },
            { valid: true, disabled: false }
          );

        return {
          ...state,
          validity: validityClone
        };
      } else {
        return state;
      }
    }

    case ActionTypes.RESET_BUYERINFO_FROM_URL:
      return {
        ...state,
        buyerInfoFromURL: []
      };

    case ActionTypes.SET_BUYERINFO_FROM_URL:
      const buyerInfoURL = action.payload;

      if (Array.isArray(buyerInfoURL) && buyerInfoURL.length === 0) {
        return state;
      }

      return {
        ...state,
        buyerInfoFromURL: buyerInfoURL
      };

    case ActionTypes.SET_ADDRESS_CHECBOX:
      const addressCheckbox = action.payload;
      return {
        ...state,
        addressCheckbox: addressCheckbox
      };

    case ActionTypes.SET_BUYERVISITOR_CHECKBOX:
      const buyerVisitorCheckbox = action.payload;
      return {
        ...state,
        buyerVisitorCheckbox: buyerVisitorCheckbox
      };

    case ActionTypes.SET_COPPIED_HOLDERS_INDEXES:
      const coppiedHoldersDataIndexes = action.payload;
      return {
        ...state,
        coppiedHoldersDataIndexes: coppiedHoldersDataIndexes
      };

    case ActionTypes.SET_TICKET_HOLDER_ADDITIONAL_DATA: {
      const status = action.payload;

      return {
        ...state,
        ticketHolderAdditionalData: status
      };
    }

    case ActionTypes.SET_BUYER_ACTIVE_BILLINGADDRESS_ID:
      const activeId = action.payload;
      return {
        ...state,
        buyerActiveBillingAddressId: activeId
      };

    case ActionTypes.SET_CONTINUE_AS_GUEST:
      const isContinueAsGuest = action.payload;
      return {
        ...state,
        isContinueAsGuest
      };

    case ActionTypes.SET_ORDER_RESPONSE:
      const orderResponse = action.payload;
      return {
        ...state,
        orderResponse
      };

    case ActionTypes.SET_PAYMENT_METHOD:
      const paymentMethod = action.payload;
      return {
        ...state,
        paymentMethod
      };

    case ActionTypes.SET_TICKETHOLDER_FORM_VALIDITY:
      const formValidity = action.payload;
      return {
        ...state,
        ticketHoldersFormsValidity: {
          ...state.ticketHoldersFormsValidity,
          [formValidity.formInfo[1]]: formValidity.valid
        }
      };

    case ActionTypes.REMOVE_TICKETHOLDER_FORM:
      const formInfo = action.payload;
      let formsValidityClone = cloneDeep(state.ticketHoldersFormsValidity);
      !!formsValidityClone && delete formsValidityClone[formInfo[1]];

      return {
        ...state,
        ticketHoldersFormsValidity: formsValidityClone
      };

    case ActionTypes.SET_BUYERINFO_FORM_VALIDITY:
      const isValid = action.payload;
      return {
        ...state,
        isBuyerInfoFormValid: isValid
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    case ActionTypes.PARTIAL_RESET_REDUCER:
      return {
        ...clonedInitialState,
        buyerInfoFromURL: state.buyerInfoFromURL
      };

    case ActionTypes.SET_ANONYMOUS_HIDDEN_STEPS: {
      return {
        ...state,
        anonymousHiddenSteps: action.payload
      };
    }

    case ActionTypes.SET_QUESTIONNAIRE_TICKET_PERSON_IDS: {
      const questionnaireTicketPersonIds = action.payload;
      return {
        ...state,
        questionnaireTicketPersonIds
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getTicketsStep = (state: State) => state.tickets;
export const getPersonalStep = (state: State) => state.personal;
export const getWorkshopStep = (state: State) => state.workshop;
export const getMenuStep = (state: State) => state.menu;
export const getlLegitimationStep = (state: State) => state.legitimation;
export const getConfirmationStep = (state: State) => state.confirmation;
export const getRecipeStep = (state: State) => state.recipe;
export const getPaymentStep = (state: State) => state.payment;
export const getInvoiceStep = (state: State) => state.invoice;
export const getQuestionnaireStep = (state: State) => state.questionnaire;

export const getSelectedStep = (state: State) => state.selectedStep;

export const getStepsValidity = (state: State) => state.validity;
export const getQuestionnaire = (state: State) => state.personal.forms.questionnaire;
export const getSelfRegQuestionnaire = (state: State) => state.questionnaire.forms.questionnaire;
export const getBuyerInfo = (state: State) => state.personal.forms.buyerinfo;
export const getBuyerInfoFromURL = (state: State) => state.buyerInfoFromURL;
export const getConfirmationCheckboxesInputs = (state: State) => state.confirmation.forms.checkboxes;
export const getPrivacyInput = (state: State) => state.personal.forms.privacy;
export const getAddressCheckbox = (state: State) => state.addressCheckbox;
export const getBuyerVisitorCheckbox = (state: State) => state.buyerVisitorCheckbox;
export const getCoppiedHoldersIndexes = (state: State) => state.coppiedHoldersDataIndexes;
export const getBillingAddressForm = (state: State) => state.personal.forms.billingaddress;

export const getTicketHolderAdditionalData = (state: State) => state.ticketHolderAdditionalData;
export const getBuyerActiveBillingAddressId = (state: State) => state.buyerActiveBillingAddressId;
export const isContinueAsGuest = (state: State) => state.isContinueAsGuest;
export const getOrderResponse = (state: State) => state.orderResponse;
export const getPaymentMethod = (state: State) => state.paymentMethod;
export const getTicketHoldersFormsValidityData = (state: State) => state.ticketHoldersFormsValidity;
export const isBuyerInfoFormValid = (state: State) => state.isBuyerInfoFormValid;
export const getAnonymousHiddenSteps = (state: State) => state.anonymousHiddenSteps;
export const getSkipHoldersCheck = (state: State) => state.skipHoldersCheck;
export const getQuestionnaireTicketPersonIds = (state: State) => state.questionnaireTicketPersonIds;

export const getTicketHolderInputSets = createSelector(
  getPersonalStep,
  (personal): FormInputsPayloadModel[] => {
    const sets = Object.keys(personal.forms)
      .filter(setKey => setKey.match(/^ticketHolder_/))
      .map(
        (setKey): FormInputsPayloadModel => {
          const holderUuid: string = setKey.replace('ticketHolder_', '');
          return {
            holderUuid,
            formInfo: ['personal', setKey],
            inputSet: personal.forms[setKey] as InputsListModel
          };
        }
      );
    return sets;
  }
);

export const getTicketHolderInputsKeys = createSelector(
  getPersonalStep,
  (personal): string[] => Object.keys(personal.forms).filter(key => key.startsWith('ticketHolder_'))
);

export const isDifferenBillingAddressUsed = createSelector(
  getPersonalStep,
  (personal): FormInputsPayloadModel[] => {
    const billingAddressUsed = personal.forms.buyerinfo.list.find(x => x.key === 'different-billing-address');

    if (billingAddressUsed) {
      return billingAddressUsed.options[0].value;
    } else {
      false;
    }
  }
);

export const getTicketHoldersValidity = createSelector(
  getTicketHolderInputSets,
  getStepsValidity,
  (getTicketHolderInputSetsData, getStepsValidityData) => {
    return getTicketHolderInputSetsData.map(input => {
      return getStepsValidityData.personal.forms[input.formInfo[1]];
    });
  }
);

export const getTicketHoldersFormsValidity = createSelector(
  getTicketHolderInputSets,
  getTicketHoldersFormsValidityData,
  (getTicketHolderInputSetsData, getTicketHoldersFormsValidityData) => {
    return getTicketHolderInputSetsData.map(input => {
      return getTicketHoldersFormsValidityData[input.formInfo[1]] as boolean;
    });
  }
);

export const getBuyerInfoValidity = createSelector(
  getStepsValidity,
  (stepsValidity): boolean => {
    return (
      !!stepsValidity &&
      !!stepsValidity['personal'] &&
      !!stepsValidity['personal'].forms &&
      !!stepsValidity['personal'].forms['buyerinfo']
    );
  }
);

export const getSteps = createSelector(
  getTicketsStep,
  getPersonalStep,
  getWorkshopStep,
  getMenuStep,
  getlLegitimationStep,
  getConfirmationStep,
  getRecipeStep,
  getPaymentStep,
  getInvoiceStep,
  getBuyerInfoFromURL,
  getQuestionnaireStep,
  (
    tickets,
    personal,
    workshop,
    menu,
    legitimation,
    confirmation,
    recipe,
    payment,
    invoice,
    buyerInfoFromUrl,
    questionnaire
  ) => {
    const allSteps = {
      tickets,
      personal,
      workshop,
      menu,
      legitimation,
      confirmation,
      recipe,
      payment,
      invoice,
      buyerInfoFromUrl,
      questionnaire
    };

    return allSteps;
  }
);
