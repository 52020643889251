var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as customizationActions from './customization.actions';
import { InjectionToken } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ActionTypes as ProductBookingActionType } from '../products/booking/booking.actions';
import { CustomizationService } from './customization.service';
export var CUSTOMIZATION_DEBOUNCE = new InjectionToken('Customization Debounce');
var CustomizationEffect = /** @class */ (function () {
    function CustomizationEffect(actions$, customizationService, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.customizationService = customizationService;
        this.debounce = debounce;
        this.loadCustomSettings$ = this.actions$.pipe(ofType(customizationActions.ActionTypes.GET_EXHIBITION_SETTINGS), switchMap(function (data) {
            var eventId = data.payload;
            if (eventId === '') {
                return Observable.empty();
            }
            return _this.customizationService.getCustomSettings(eventId).pipe(map(function (customSettings) {
                var sendingOptions = Object.keys(customSettings.ticketSelectionDeliveryTypes).map(function (key) {
                    var isBasic = (key !== 'mobilePerBuyer' && key !== 'ticketRetrivalLinkBuyer') || false;
                    var isBuyer = key === 'mobilePerBuyer' || key === 'ticketRetrivalLinkBuyer' || key === 'allToBuyer' || false;
                    return {
                        value: key,
                        isEnabled: customSettings.ticketSelectionDeliveryTypes[key],
                        isBasic: isBasic,
                        isBuyer: isBuyer
                    };
                });
                // TODO add currency if missing in API
                if (!customSettings.hasOwnProperty('currency')) {
                    customSettings.currency = environment.currency;
                }
                customSettings.ticketSelectionDeliveryTypes = sendingOptions;
                return new customizationActions.SetExhibitionSettings(customSettings);
            }), catchError(function (error) {
                console.log(error);
                return of(new customizationActions.SetExhibitionSettings(null));
            }));
        }));
        /* @Effect()
        loadCustomLinks$: Observable<Action> = this.actions$
          .ofType(customizationActions.GET_EXHIBITION_LINKS)
          .debounceTime(this.debounce)
          .switchMap(event => {
            if (event.exhibitionId === '' && event.exhibitionLanguage === '') {
              return empty();
            }
      
            return this.customizationService
              .getCustomLinks(event)
              .map(
                (customLinks: ExhibitionLinksModel) =>
                  new customizationActions.SetExhibitionLinks(customLinks)
              )
              .catch(error => {
                console.log(error);
                return of(new customizationActions.SetExhibitionLinks(null));
              });
          }); */
        this.loadRandomSponsor$ = this.actions$.pipe(ofType(customizationActions.ActionTypes.GET_RANDOM_SPONSOR), switchMap(function (data) {
            var payload = data.payload;
            if (payload.eventId === '') {
                return Observable.empty();
            }
            var eventId = payload.eventId, countryCode = payload.countryCode;
            return _this.customizationService.getRandomSponsor(eventId, countryCode).pipe(map(function (randomSponsor) { return new customizationActions.SetRandomSponsor(randomSponsor); }), catchError(function (error) {
                console.log(error);
                return of(new customizationActions.SetRandomSponsor(null));
            }));
        }));
        this.loadLocalizedImages$ = this.actions$.pipe(ofType(customizationActions.ActionTypes.GET_LOCALIZED_IMAGES), mergeMap(function (data) {
            var _a = data.payload, eventId = _a.eventId, countryCode = _a.countryCode;
            return _this.customizationService.getLocalizedImages(eventId, countryCode).pipe(map(function (localizedImages) {
                return new customizationActions.SetLocalizedImages(localizedImages);
            }), catchError(function (error) {
                console.log(error);
                return of(new customizationActions.SetLocalizedImages(null));
            }));
        }));
        this.loadOperatorSettings$ = this.actions$.pipe(ofType(customizationActions.ActionTypes.GET_OPERATORS_SETTINGS), switchMap(function () {
            return _this.customizationService.getOperatorSettings().pipe(map(function (operatorSettings) { return new customizationActions.SetOperatorsSettings(operatorSettings); }), catchError(function (error) {
                console.log(error);
                return of(new customizationActions.SetOperatorsSettings(null));
            }));
        }));
        this.setShoppingStartTime$ = this.actions$.pipe(ofType(ProductBookingActionType.SET_PRODUCT_IN_BOOKING_LIST, ProductBookingActionType.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST, ProductBookingActionType.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST, ProductBookingActionType.SET_PACKAGE_IN_BOOKING_LIST), tap(function () { return _this.customizationService.setShoppingStartTime(); }), catchError(function (error) { return throwError(error); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CustomizationEffect.prototype, "loadCustomSettings$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CustomizationEffect.prototype, "loadRandomSponsor$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CustomizationEffect.prototype, "loadLocalizedImages$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CustomizationEffect.prototype, "loadOperatorSettings$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], CustomizationEffect.prototype, "setShoppingStartTime$", void 0);
    return CustomizationEffect;
}());
export { CustomizationEffect };
