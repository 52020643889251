/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-landing-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./payment-landing-page.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../shared/forms/forms.service";
import * as i8 from "@ngrx/store";
import * as i9 from "../../../shared/error-handling/error-handling.service";
var styles_PaymentLandingPageComponent = [i0.styles];
var RenderType_PaymentLandingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentLandingPageComponent, data: {} });
export { RenderType_PaymentLandingPageComponent as RenderType_PaymentLandingPageComponent };
function View_PaymentLandingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "basic-header black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "basic-title"], ["data-translation", "payment.fail.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["data-translation", "payment.fail.subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("payment.fail.title")))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("payment.fail.subtitle")))); _ck(_v, 6, 0, currVal_1); }); }
function View_PaymentLandingPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[1, "data-translation", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("response." + _co.translatedErrorMessage.key); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.translatedErrorMessage.message; _ck(_v, 1, 0, currVal_1); }); }
function View_PaymentLandingPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["data-translation", "payment.error.subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("payment.error.subtitle")))); _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentLandingPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "basic-header black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "basic-title"], ["data-translation", "payment.error.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentLandingPageComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentLandingPageComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.translatedErrorMessage && (_co.translatedErrorMessage.status !== 409)); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.translatedErrorMessage; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("payment.error.title")))); _ck(_v, 2, 0, currVal_0); }); }
export function View_PaymentLandingPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentLandingPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentLandingPageComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "payment-button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "button back-button"], ["data-translation", "step-navigation.back-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.response === "fail"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.response === "error"); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("step-navigation.back-button")))); _ck(_v, 9, 0, currVal_2); }); }
export function View_PaymentLandingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-landing-page", [], null, null, null, View_PaymentLandingPageComponent_0, RenderType_PaymentLandingPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentLandingPageComponent, [i6.ActivatedRoute, i6.Router, i7.FormsService, i8.Store, i9.ErrorHandlingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentLandingPageComponentNgFactory = i1.ɵccf("app-payment-landing-page", i5.PaymentLandingPageComponent, View_PaymentLandingPageComponent_Host_0, {}, {}, []);
export { PaymentLandingPageComponentNgFactory as PaymentLandingPageComponentNgFactory };
