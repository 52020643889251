import { Injectable } from '@angular/core';
import { State, getTicketHolderInputSets } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { BookingTariff } from '@products/models/booking.model';
import { HolderUuid, HolderUuids } from '@products/models/holder.model';
import { Tariff } from '@products/models/tariff.model';
import {
  WorkshopDetailViewModel,
  WorkshopHolder,
  WorkshopId,
  WorkshopProductList,
  WorkshopTariffHolder,
  WorkshopTariffHolderUuidsGroupedByWorkshopIds
} from '@products/models/workshop.model';
import { BookingService } from '@products/services/booking.service';
import { FormInputsPayloadModel } from '@root/src/app/shared/services-with-reducers/step-forms/step.interface';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { getAllTariffsWithWorkshops } from '@store/products/product-selection/product-selection.selectors';
import { RemoveBookingWorkshop, SetBookingWorkshop } from '@store/products/product.actions';
import { getWorkshopProductList } from '@store/products/workshop/workshop.selectors';
import _ from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {
  constructor(private store: Store<State>, private bookingService: BookingService) {}

  getWorkshopDetailViewModel$(workshopId: WorkshopId): Observable<WorkshopDetailViewModel> {
    return combineLatest([
      this.store.pipe(select(getWorkshopProductList)),
      this.getBookedProductSelectionWorkshopDetailTariffHolders$(workshopId)
    ]).pipe(
      filter(([workshopProductList]) => !!workshopProductList.length),
      map(([workshopProductList, workshopTariffHolders]) => ({
        workshopProduct: workshopProductList.find(workshopProduct => workshopProduct.workshopId === workshopId) || null,
        workshopProductList,
        workshopTariffHolders,
        areAllWorkshopTariffHoldersAssigned: workshopTariffHolders.every(
          workshopTariffHolder => workshopTariffHolder.isHolderAssigned
        )
      }))
    );
  }

  getBookedProductSelectionWorkshopTariffs$(): Observable<BookingTariff[]> {
    return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(
      map(({ workshopTariffs, bookedTariffs }) =>
        bookedTariffs.filter(bookedTariff => {
          const { ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex } = bookedTariff;

          return workshopTariffs.some(
            workshopTariff =>
              workshopTariff.ticketTypeId === ticketTypeId &&
              workshopTariff.ticketPersonId === ticketPersonId &&
              workshopTariff.voucherCode === voucherCode &&
              workshopTariff.packageNumber === packageNumber &&
              workshopTariff.packageIndex === packageIndex
          );
        })
      )
    );
  }

  getBookedProductsAllowedWorkshopIds$(): Observable<number[]> {
    return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(
      map(({ workshopTariffs, bookedTariffs }) => {
        const bookedProductsAllowedWorkshopIds: number[] = [];

        bookedTariffs.forEach(({ ticketPersonId }) => {
          const workshopTariff = workshopTariffs.find(
            workshopTariff => workshopTariff.ticketPersonId === ticketPersonId
          );

          if (workshopTariff) {
            workshopTariff.allowedWorkshops.forEach(allowedWorkshopId =>
              bookedProductsAllowedWorkshopIds.push(allowedWorkshopId)
            );
          }
        });

        return _.uniq(bookedProductsAllowedWorkshopIds);
      })
    );
  }

  setWorkshopBookingHolder(workshopTariffHolder: WorkshopTariffHolder, workshopProductList: WorkshopProductList) {
    if (!workshopTariffHolder.isHolderAssigned) {
      const bookingWorkshops = this.bookingService.mapWorkshopTariffHoldersToSetBookingWorkshops(
        [workshopTariffHolder],
        workshopProductList
      );

      this.store.dispatch(new SetBookingWorkshop(bookingWorkshops));
    } else {
      const removeWorkshops = this.bookingService.mapWorkshopTariffHoldersToRemoveBookingWorkshops([
        workshopTariffHolder
      ]);

      this.store.dispatch(new RemoveBookingWorkshop(removeWorkshops));
    }
  }

  setAllWorkshopBookingHolders(
    areAllWorkshopTariffHoldersAssigned: boolean,
    workshopTariffHolders: WorkshopTariffHolder[],
    workshopProductList: WorkshopProductList
  ) {
    if (!areAllWorkshopTariffHoldersAssigned) {
      const bookingWorkshops = this.bookingService.mapWorkshopTariffHoldersToSetBookingWorkshops(
        workshopTariffHolders,
        workshopProductList
      );

      this.store.dispatch(new SetBookingWorkshop(bookingWorkshops));
    } else {
      const removeWorkshops = this.bookingService.mapWorkshopTariffHoldersToRemoveBookingWorkshops(
        workshopTariffHolders
      );

      this.store.dispatch(new RemoveBookingWorkshop(removeWorkshops));
    }
  }

  private getSortedBookedProductSelectionBookedWorkshopTariffs$(): Observable<BookingTariff[]> {
    return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(
      map(({ workshopTariffs, bookedTariffs }) => {
        const sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder: BookingTariff[] = [];

        workshopTariffs.forEach(workshopTariff => {
          const { ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex } = workshopTariff;

          const bookedWorkshopTariff = bookedTariffs.find(
            bookedTariff =>
              bookedTariff.ticketTypeId === ticketTypeId &&
              bookedTariff.ticketPersonId === ticketPersonId &&
              bookedTariff.voucherCode === voucherCode &&
              bookedTariff.packageNumber === packageNumber &&
              bookedTariff.packageIndex === packageIndex
          );

          if (bookedWorkshopTariff) {
            sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder.push(bookedWorkshopTariff);
          }
        });

        return sortedBookedWorkshopTariffsByWorkshopProductSelectionTariffsOrder;
      })
    );
  }

  private getProductSelectionWorkshopTariffsAndAllBookedTariffs$(): Observable<{
    workshopTariffs: Tariff[];
    bookedTariffs: BookingTariff[];
  }> {
    return combineLatest([
      this.store.pipe(select(getAllTariffsWithWorkshops)),
      this.store.pipe(select(getAllBookedTariffs))
    ]).pipe(map(([workshopTariffs, bookedTariffs]) => ({ workshopTariffs, bookedTariffs })));
  }

  private getBookedWorkshopTariffHolderUuids$(): Observable<WorkshopTariffHolderUuidsGroupedByWorkshopIds> {
    return this.getProductSelectionWorkshopTariffsAndAllBookedTariffs$().pipe(
      map(({ workshopTariffs, bookedTariffs }) => {
        const workshopTariffHolderUuidsGroupedByWorkshopIds: WorkshopTariffHolderUuidsGroupedByWorkshopIds = {};

        bookedTariffs.forEach(bookedTariff => {
          const { ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex } = bookedTariff;

          const workshopTariff = workshopTariffs.find(
            workshopTariff =>
              workshopTariff.ticketTypeId === ticketTypeId &&
              workshopTariff.ticketPersonId === ticketPersonId &&
              workshopTariff.voucherCode === voucherCode &&
              workshopTariff.packageNumber === packageNumber &&
              workshopTariff.packageIndex === packageIndex
          );

          if (workshopTariff) {
            workshopTariff.allowedWorkshops.forEach(allowedWorkshopId => {
              const bookedWorkshopTariffHolderUuids =
                workshopTariffHolderUuidsGroupedByWorkshopIds[allowedWorkshopId] || [];

              workshopTariffHolderUuidsGroupedByWorkshopIds[allowedWorkshopId] = [
                ...bookedWorkshopTariffHolderUuids,
                ...bookedTariff.holderUuids
              ];
            });
          }
        });

        return workshopTariffHolderUuidsGroupedByWorkshopIds;
      })
    );
  }

  private getBookedProductSelectionWorkshopDetailTariffHolders$(
    workshopId: WorkshopId
  ): Observable<WorkshopTariffHolder[]> {
    return combineLatest([
      this.getSortedBookedProductSelectionBookedWorkshopTariffs$(),
      this.getBookedWorkshopTariffHolderUuids$(),
      this.store.pipe(select(getTicketHolderInputSets))
    ]).pipe(
      map(([sortedBookedWorkshopTariffs, bookedTariffWorkshopHolderUuids, tariffHolderInputSets]) => {
        const allWorkshopHolderUuids: HolderUuids = bookedTariffWorkshopHolderUuids[workshopId];
        const workshopTariffHolders: WorkshopTariffHolder[] = [];

        sortedBookedWorkshopTariffs.forEach(bookedTariff => {
          bookedTariff.holderUuids.forEach(bookedTariffHolderUuid => {
            if (allWorkshopHolderUuids.includes(bookedTariffHolderUuid)) {
              let workshopTariffHolder = workshopTariffHolders.find(
                workshopTariffHolder => workshopTariffHolder.holderUuid === bookedTariffHolderUuid
              );

              if (!workshopTariffHolder) {
                const {
                  ticketTypeId,
                  ticketPersonId,
                  voucherCode,
                  packageNumber,
                  packageIndex,
                  ticketTypeName,
                  ticketName
                } = bookedTariff;
                const { holderUuid, firstName, lastName } = this.mapTariffHolderInputsToWorkshopHolder(
                  tariffHolderInputSets,
                  bookedTariffHolderUuid
                );

                workshopTariffHolder = {
                  holderUuid,
                  firstName,
                  lastName,
                  ticketTypeId,
                  ticketPersonId,
                  voucherCode,
                  packageNumber,
                  packageIndex,
                  ticketTypeName,
                  ticketName,
                  workshopId,
                  isHolderAssigned: false,
                  assignedWorkshops: []
                };
              }

              bookedTariff.workshops.forEach(({ id, holderUuids }) => {
                if (holderUuids.includes(bookedTariffHolderUuid)) {
                  if (!workshopTariffHolder.isHolderAssigned) {
                    workshopTariffHolder.isHolderAssigned = id == workshopId;
                  }

                  workshopTariffHolder.assignedWorkshops.push(id);
                }
              });

              workshopTariffHolders.push(workshopTariffHolder);
            }
          });
        });

        return workshopTariffHolders;
      })
    );
  }

  private mapTariffHolderInputsToWorkshopHolder(
    tariffHolderInputSets: FormInputsPayloadModel[],
    holderUuid: HolderUuid
  ): WorkshopHolder {
    const workshopHolder: WorkshopHolder = { holderUuid: '', firstName: '', lastName: '' };
    const mapHolderFormInputs = Object.keys(workshopHolder);

    tariffHolderInputSets.forEach(tariffHolderInputSet => {
      if (workshopHolder.firstName && workshopHolder.lastName) {
        return;
      }

      if (tariffHolderInputSet.holderUuid === holderUuid) {
        workshopHolder.holderUuid = holderUuid;

        tariffHolderInputSet.inputSet.list.forEach(holderInput => {
          if (mapHolderFormInputs.includes(holderInput.key)) {
            workshopHolder[holderInput.key] = holderInput.value || '';
          }
        });
      }
    });

    return workshopHolder;
  }
}
