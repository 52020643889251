var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getWidgetSettings } from '@app/app.reducer';
import { TopBarComponent } from '@layout/top-bar/top-bar.component';
import { Store, select } from '@ngrx/store';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { UserService } from '@store/user/user.service';
import { delay, filter, first } from 'rxjs/operators';
var TopBarWidgetComponent = /** @class */ (function (_super) {
    __extends(TopBarWidgetComponent, _super);
    function TopBarWidgetComponent(router, userService, store, windowSizeService, customizationService, helperService, stepsFormsService) {
        var _this = _super.call(this, router, userService, store, windowSizeService, customizationService, helperService, stepsFormsService) || this;
        _this.displayHeader = false;
        return _this;
    }
    TopBarWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .pipe(select(getWidgetSettings), filter(function (widgetSettings) {
            if (widgetSettings && widgetSettings.ticketsWidget) {
                return true;
            }
        }), delay(0), first())
            .subscribe(function (_a) {
            var ticketsWidget = _a.ticketsWidget;
            _this.widgetSettings = ticketsWidget;
            _this.displayHeader = Boolean(ticketsWidget.header);
        });
    };
    return TopBarWidgetComponent;
}(TopBarComponent));
export { TopBarWidgetComponent };
