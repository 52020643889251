<ng-container *ngIf="productSelectionProductsAndExhibitionState$ | async as productSelectionProductsAndExhibitionState">
  <app-loader
    *ngIf="
      !productSelectionProductsAndExhibitionState.productSelectionListProducts ||
      !productSelectionProductsAndExhibitionState.exhibitionSettings ||
      !productSelectionProductsAndExhibitionState.isUserAccountLimitChecked
    "
  ></app-loader>
  <div
    class="page-padding-wrapper"
    *ngIf="
      productSelectionProductsAndExhibitionState.productSelectionListProducts &&
      productSelectionProductsAndExhibitionState.exhibitionSettings &&
      productSelectionProductsAndExhibitionState.isUserAccountLimitChecked
    "
  >
    <ng-container *ngIf="productSelectionViewModel$ | async as productSelectionViewModel">
      <app-product-selection-login
        [isRedeemedAnonymousVoucherProductInProductSelectionList]="
          productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList
        "
        [notLoggedAndLoginMandatory]="productSelectionViewModel.notLoggedAndLoginMandatory"
      ></app-product-selection-login>

      <ng-container
        *ngIf="
          !productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList &&
          productSelectionViewModel.isVerifiedAccountRequired &&
          !productSelectionViewModel.isAccountVerified
        "
      >
        <app-web-shop-account-verification></app-web-shop-account-verification>
      </ng-container>
      <ng-container
        *ngIf="
          productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList ||
          (!productSelectionViewModel.notLoggedAndLoginMandatory &&
            !(productSelectionViewModel.isVerifiedAccountRequired && !productSelectionViewModel.isAccountVerified))
        "
      >
        <ng-container *ngIf="productSelectionProductsStatusViewModel$ | async as productsStatusViewModel">
          <section
            class="product-selection ticket-section"
            *ngIf="
              productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList ||
              !productSelectionViewModel.notLoggedAndLoginMandatory
            "
          >
            <div
              class="ticket-info-text"
              data-translation="ticket.selfregistration.info-text"
              data-translation-includes-children
              [innerHTML]="'ticket.selfregistration.info-text' | translate | hideTranslation"
            ></div>
            <ng-container *ngIf="productSelectionProductsAndExhibitionState.exhibitionSettings as exhibitionSettings">
              <ng-container
                *ngIf="productSelectionViewModel.isSelfRegistration && productsStatusViewModel.tariffStatus"
              >
                <div class="row">
                  <div class="col-md-5 border-right" *ngIf="productSelectionViewModel.showPromoCode">
                    <h2 class="subtitle" data-translation="ticket.selfregistration.voucher.title">
                      {{ 'ticket.selfregistration.voucher.title' | translate | hideTranslation }}
                    </h2>
                    <app-voucher
                      [isSelfRegistration]="productSelectionViewModel.isSelfRegistration"
                      [isRedeemedAnonymousVoucherProductInProductSelectionList]="
                        productSelectionViewModel.isRedeemedAnonymousVoucherProductInProductSelectionList
                      "
                      [maxTicketLimit]="exhibitionSettings.limitBoughtTickets"
                      [limitPromoCodes]="exhibitionSettings.limitPromoCodes"
                      [limitLimitedPromoCodes]="exhibitionSettings.limitLimitedPromocodes"
                      [ticketLimitPerUserAccount]="exhibitionSettings.ticketLimitPerUserAccount"
                      [isVoucherIncludedPerUserAccountLimit]="exhibitionSettings.isVoucherIncludedPerUserAccountLimit"
                      [currentUserAccountTicketLimit]="
                        productsStatusViewModel.tariffStatus.currentUserAccountTicketLimit
                      "
                      [bookedProductsCount]="productsStatusViewModel.bookingStatus.bookedProductsCount"
                    ></app-voucher>
                  </div>
                  <div
                    [ngClass]="{
                      'col-md-7': productSelectionViewModel.showPromoCode,
                      'col-md-12': !productSelectionViewModel.showPromoCode
                    }"
                  >
                    <h2 class="subtitle" data-translation="ticket.selfregistration.ticket.title">
                      {{ 'ticket.selfregistration.ticket.title' | translate | hideTranslation }}
                    </h2>

                    <ng-container *ngIf="productSelectionProductsAndExhibitionState.voucherProducts as voucherProducts">
                      <ng-container *ngFor="let voucherProduct of voucherProducts; trackBy: voucherProductsTrackBy">
                        <app-product
                          [selectedExhibition]="productSelectionProductsAndExhibitionState.selectedExhibition"
                          [exhibitionSettings]="exhibitionSettings"
                          [productSelectionViewModel]="productSelectionViewModel"
                          [productsStatusViewModel]="productsStatusViewModel"
                          [product]="voucherProduct"
                        ></app-product>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!productSelectionViewModel.isRedeemedVoucherProductInProductSelectionList">
                      <ng-container
                        *ngFor="
                          let productListItem of productSelectionProductsAndExhibitionState.productSelectionListProducts;
                          trackBy: productSelectionListTrackBy
                        "
                      >
                        <ng-container *ngIf="!!productListItem.products">
                          <app-product-group
                            [selectedExhibition]="productSelectionProductsAndExhibitionState.selectedExhibition"
                            [exhibitionSettings]="exhibitionSettings"
                            [productSelectionViewModel]="productSelectionViewModel"
                            [productsStatusViewModel]="productsStatusViewModel"
                            [productGroup]="productListItem"
                          ></app-product-group>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <app-step-navigation-buttons></app-step-navigation-buttons>
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
