<ng-container *ngIf="!productSelectionViewModel.isSelfRegistration">
  <div *ngIf="tariffType.isVisible" [ngClass]="{ 'tariff-type-full-width': !packageType }">
    <div
      class="section-headline section-headline-no-border"
      *ngIf="tariffType.ticketTypeName"
      [ngClass]="{
        'section-headline-with-margin': productGroup && productGroup.groupName,
        'package-tariff-type': packageType
      }"
    >
      <h2
        class="package-tariff-type__ticket-group-name"
        *ngIf="!packageType"
        innerText="{{
          (productSelectionViewModel.isTranslationLoaded
            ? tariffType.ticketTypeName
            : 'translations.loading.ticket-group')
            | translate
            | hideTranslation
        }}"
        [ngClass]="{
          'package-tariff-type--translation-loading': !productSelectionViewModel.isTranslationLoaded,
          'ng-custom-invalid': !bookingStatusViewModel.isBookedProductsCountValid
        }"
      ></h2>
      <h3
        class="package-tariff-type-name"
        *ngIf="packageType"
        innerText="{{
          (productSelectionViewModel.isTranslationLoaded
            ? tariffType.ticketTypeName
            : 'translations.loading.package-group')
            | translate
            | hideTranslation
        }}"
        [ngClass]="{ 'package-tariff-type--translation-loading': !productSelectionViewModel.isTranslationLoaded }"
      ></h3>
      <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
    </div>

    <ng-container *ngFor="let tariff of tariffType.tariffs; trackBy: tariffTrackBy">
      <app-tariff
        [exhibitionSettings]="exhibitionSettings"
        [productSelectionViewModel]="productSelectionViewModel"
        [tariffStatusViewModel]="tariffStatusViewModel"
        [workshopStatusViewModel]="workshopStatusViewModel"
        [bookingStatusViewModel]="bookingStatusViewModel"
        [packageType]="packageType"
        [tariffType]="tariffType"
        [tariff]="tariff"
        [voucherCodeReleased$]="voucherCodeReleased$"
      >
        <app-contingent-tariff
          *ngIf="tariff.hasDaySellLimit"
          [selectedExhibition]="selectedExhibition"
          [productSelectionViewModel]="productSelectionViewModel"
          [tariffStatusViewModel]="tariffStatusViewModel"
          [bookingStatusViewModel]="bookingStatusViewModel"
          [packageType]="packageType"
          [tariffType]="tariffType"
          [tariff]="tariff"
          [voucherCodeReleased$]="voucherCodeReleased$"
          webShopContingentTariff
        ></app-contingent-tariff>
        <app-parking-tariff
          *ngIf="tariff.classification === TariffClassification.Parking"
          [selectedExhibition]="selectedExhibition"
          [productSelectionViewModel]="productSelectionViewModel"
          [tariffStatusViewModel]="tariffStatusViewModel"
          [bookingStatusViewModel]="bookingStatusViewModel"
          [packageType]="packageType"
          [tariffType]="tariffType"
          [tariff]="tariff"
          webShopParkingTariff
        ></app-parking-tariff>
        <app-workshop-tariff
          *ngIf="exhibitionSettings.workshopsOnTicketSelection && tariff.allowedWorkshops?.length"
          [exhibitionSettings]="exhibitionSettings"
          [productSelectionViewModel]="productSelectionViewModel"
          [workshopStatusViewModel]="workshopStatusViewModel"
          [packageType]="packageType"
          [tariffType]="tariffType"
          [tariff]="tariff"
          webShopWorkshopTariff
        ></app-workshop-tariff>
      </app-tariff>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="productSelectionViewModel.isSelfRegistration">
  <div class="self-registration-tariff-type" *ngIf="tariffType.isVisible">
    <div class="self-registration-tariff-type__headline">
      <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
      <h2 *ngIf="productSelectionViewModel.isTranslationLoaded">
        {{ tariffType.ticketTypeName | translate | hideTranslation }}
      </h2>
    </div>
    <div class="self-registration-tariff-type__body">
      <ng-container *ngFor="let tariff of tariffType.tariffs; trackBy: tariffTrackBy">
        <app-tariff
          [exhibitionSettings]="exhibitionSettings"
          [productSelectionViewModel]="productSelectionViewModel"
          [tariffStatusViewModel]="tariffStatusViewModel"
          [workshopStatusViewModel]="workshopStatusViewModel"
          [bookingStatusViewModel]="bookingStatusViewModel"
          [packageType]="packageType"
          [tariffType]="tariffType"
          [tariff]="tariff"
          [voucherCodeReleased$]="voucherCodeReleased$"
        >
          <app-contingent-tariff
            *ngIf="tariff.hasDaySellLimit"
            [selectedExhibition]="selectedExhibition"
            [productSelectionViewModel]="productSelectionViewModel"
            [tariffStatusViewModel]="tariffStatusViewModel"
            [bookingStatusViewModel]="bookingStatusViewModel"
            [packageType]="packageType"
            [tariffType]="tariffType"
            [tariff]="tariff"
            [voucherCodeReleased$]="voucherCodeReleased$"
            selfRegistrationContingentTariff
          ></app-contingent-tariff>
        </app-tariff>
      </ng-container>
    </div>
  </div>
</ng-container>
