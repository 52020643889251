import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { BookingPackageType, BookingTariff, BookingTariffType, isBookingProductTypePackage, isBookingProductTypeTariff } from '../../_pages/products/models/booking.model';
import { VoucherService } from '../../_pages/products/services/voucher.service';
import { State, getExhibitionSettings, getOrderUuid } from '../../app.reducer';
import { getAllBookedTariffs, getBookedTariffs } from '../services-with-reducers/products/booking/booking.selectors';

enum EventName {
  AddToCart = 'addToCart',
  RemoveFromCart = 'removeFromCart',
  ProductDetail = 'productDetail',
  Checkout = 'checkout',
  BeginCheckout = 'begin_checkout',
  CheckoutOption = 'checkoutOption',
  Purchase = 'purchase'
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private uaPrefix: string = 'eec';
  private ga4Prefix: string = 'eecga4';

  constructor(
    private translateService: TranslateService,
    private store: Store<State>,
    private voucherService: VoucherService
  ) {}

  pushProductDetail(): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.ProductDetail}`,
      ecommerce: {
        detail: {
          actionField: {
            list: 'Ticketauswahl'
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.ProductDetail}`,
      ecommerce: {
        item_list_name: 'Ticketauswahl',
        action: 'detail'
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushAddToCart(bookingPackageOrTariff: BookingPackageType | BookingTariffType): void {
    const bookingTariffs = this.mapBookingProductToBookingTariffs(bookingPackageOrTariff);

    this.store
      .pipe(
        select(getExhibitionSettings),
        first(data => !!data),
      )
      .subscribe(settings => {
        const dataUA: Object = {
          event: `${this.uaPrefix}.${EventName.AddToCart}`,
          ecommerce: {
            add: {
              actionField: {
                list: 'Shopping cart'
              },
              products: this.ticketsToProducts(settings.eventId, bookingTariffs)
            }
          }
        };
        this.pushToDataLayer(dataUA);

        const dataGA4: Object = {
          event: `${this.ga4Prefix}.${EventName.AddToCart}`,
          ecommerce: {
            item_list_name: 'Shopping cart',
            items: this.ticketsToItems(settings.eventId, bookingTariffs)
          }
        };
        this.pushToDataLayer(dataGA4);
      });
  }

  pushRemoveFromCart(bookingPackageOrTariff: BookingPackageType | BookingTariffType): void {
    const bookingTariffs = this.mapBookingProductToBookingTariffs(bookingPackageOrTariff);

    this.store
      .pipe(
        select(getExhibitionSettings),
        first(data => !!data),
      )
      .subscribe(settings => {
        const dataUA: Object = {
          event: `${this.uaPrefix}.${EventName.RemoveFromCart}`,
          ecommerce: {
            remove: {
              actionField: {
                list: 'Shopping cart'
              },
              products: this.ticketsToProducts(settings.eventId, bookingTariffs)
            }
          }
        };
        this.pushToDataLayer(dataUA);

        const dataGA4: Object = {
          event: `${this.ga4Prefix}.${EventName.RemoveFromCart}`,
          ecommerce: {
            item_list_name: 'Shopping cart',
            items: this.ticketsToItems(settings.eventId, bookingTariffs)
          }
        };
        this.pushToDataLayer(dataGA4);
      });
  }

  pushCheckout(): void {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getAllBookedTariffs))
    ])
    .pipe(
      first(data => data.every(item => !!item)),
    )
    .subscribe(([settings, bookedTariffs]) => {
      const dataUA: Object = {
        event: `${this.uaPrefix}.${EventName.Checkout}`,
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: this.ticketsToProducts(settings.eventId, bookedTariffs)
          }
        }
      };
      this.pushToDataLayer(dataUA);

      const dataGA4: Object = {
        event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
        ecommerce: {
          step: 1,
          items: this.ticketsToItems(settings.eventId, bookedTariffs)
        }
      };
      this.pushToDataLayer(dataGA4);
    });
  }

  pushPersonalization(): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.Checkout}`,
      ecommerce: {
        checkout: {
          actionField: {
            step: 2
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
      ecommerce: {
        step: 2
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushConfirmation(): void {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getAllBookedTariffs)),
      this.store.pipe(select(getOrderUuid))
    ])
    .pipe(
      first(data => data.every(item => !!item)),
    )
    .subscribe(([exhibitionSettings, tariffs, orderUuid]) => {
      const products = this.ticketsToProducts(
        exhibitionSettings.eventId,
        tariffs
      );

      const revenue = products.reduce((revenue, product) => revenue + product.price * 100 * product.quantity, 0) / 100;

      const dataUA: Object = {
        event: `${this.uaPrefix}.${EventName.Checkout}`,
        ecommerce: {
          currencyCode: exhibitionSettings.currencySettings.currencyCode,
          checkout: {
            actionField: {
              id: orderUuid,
              revenue,
              step: 3
            },
            products
          }
        }
      };
      this.pushToDataLayer(dataUA);

      const dataGA4: Object = {
        event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
        ecommerce: {
          transaction_id: orderUuid,
          value: revenue,
          currency: exhibitionSettings.currencySettings.currencyCode,
          step: 3,
          items: this.ticketsToItems(exhibitionSettings.eventId, tariffs)
        }
      };
      this.pushToDataLayer(dataGA4);
    });
  }

  pushCheckoutOption(paymentOption: string): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.CheckoutOption}`,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 3,
            option: paymentOption
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.CheckoutOption}`,
      ecommerce: {
        step: 3,
        action: 'checkout_option',
        option: paymentOption
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushPurchase(): void {
    combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getBookedTariffs)),
      this.store.pipe(select(getOrderUuid))
    ])
    .pipe(
      first(data => data.every(item => !!item)),
    )
    .subscribe(([exhibitionSettings, tariffs, orderUuid]) => {
      const products = this.ticketsToProducts(
        exhibitionSettings.eventId,
        tariffs
      );

      const revenue = products.reduce((revenue, product) => revenue + product.price * 100 * product.quantity, 0) / 100;

      const dataUA: Object = {
        event: `${this.uaPrefix}.${EventName.Purchase}`,
        ecommerce: {
          currencyCode: exhibitionSettings.currencySettings.currencyCode,
          checkout: {
            actionField: {
              id: orderUuid,
              revenue
            },
            products
          }
        }
      };
      this.pushToDataLayer(dataUA);

      const dataGA4: Object = {
        event: `${this.ga4Prefix}.${EventName.Purchase}`,
        ecommerce: {
          transaction_id: orderUuid,
          value: revenue,
          currency: exhibitionSettings.currencySettings.currencyCode,
          items: this.ticketsToItems(exhibitionSettings.eventId, tariffs)
        }
      };
      this.pushToDataLayer(dataGA4);
    });
  }

  private ticketsToProducts(eventId: number, tariffs: BookingTariff[]) {
    return tariffs.map(tariff => {
      const name = this.translateService.instant(tariff.ticketName);
      const category = tariff.ticketTypeName ? this.translateService.instant(tariff.ticketTypeName) : '';

      return {
        id: tariff.ticketPersonTypeId,
        name: name,
        category: category,
        quantity: tariff.count,
        brand: eventId,
        coupon: tariff.voucherCode,
        price: tariff.price
      };
    });
  }

  private ticketsToItems(eventId: number, tariffs: BookingTariff[]) {
    return tariffs.map((tariff: BookingTariff) => {
      const name = this.translateService.instant(tariff.ticketName);
      const category = tariff.ticketTypeName ? this.translateService.instant(tariff.ticketTypeName) : '';

      return {
        item_id: tariff.ticketPersonTypeId,
        item_name: name,
        item_category: category,
        quantity: tariff.count,
        item_brand: eventId,
        coupon: tariff.voucherCode,
        price: tariff.price
      };
    });
  }

  private pushToDataLayer(data: Object): void {
    window['dataLayer'] = window['dataLayer'] || [];

    //clearing the ecommerce object
    //it's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other:
    window['dataLayer'].push({ ecommerce: null });

    //push new data:
    window['dataLayer'].push(data);
  }

  private mapBookingProductToBookingTariffs(bookingPackageOrTariff: BookingPackageType | BookingTariffType): BookingTariff[] {
    if (isBookingProductTypePackage(bookingPackageOrTariff)) {
      const bookingTariffs: BookingTariff[] = [];

      bookingPackageOrTariff.productTypes.forEach(bookingProductType => {
        if (isBookingProductTypeTariff(bookingProductType)) {
          bookingTariffs.push(...bookingProductType.tariffs);
        }
      });

      return bookingTariffs;
    } else if (isBookingProductTypeTariff(bookingPackageOrTariff)) {
      return this.getFirstBookingTariff(bookingPackageOrTariff.tariffs);
    }
  }


  private getFirstBookingTariff(bookingTariffs: BookingTariff[]): BookingTariff[] {
    return [bookingTariffs.length && bookingTariffs[0]];
  }
}
