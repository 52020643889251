import { HolderState, HolderUuids, TicketHolder } from '@products/models/holder.model';
import { getUUID } from '@shared/app-utils';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { ActionTypes as HolderActionTypes, Actions as HolderActions } from '@store/products/holder/holder.actions';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';

export { HolderState as State };

export const initialState: HolderState = {
  list: [],
  ticketHolder: null,
  ticketHolderFormSubmitResult: null,
  inputs: [],
  questionnaireInputs: [],
  activeSlide: 0
};

export const holderReducer = (
  state: HolderState = initialState,
  action: HolderActions | ProductActions
): HolderState => {
  switch (action.type) {
    case HolderActionTypes.CREATE_PRODUCT_HOLDER: {
      const numberOfHoldersToAdd = action.payload || 0;
      const addedHolderUuidsList: HolderUuids = Array(numberOfHoldersToAdd)
        .fill(null)
        .map(getUUID);

      return {
        ...state,
        list: [...state.list, ...addedHolderUuidsList]
      };
    }

    // Uncomment when holders will be assigned in personalization step
    // case HolderActionTypes.SET_PRODUCT_HOLDER: {
    //   const newProductHolderUuids: HolderUuids = action.payload;

    //   cloneState.list = newProductHolderUuids;

    //   return cloneState;
    // }

    // case HolderActionTypes.REMOVE_PRODUCT_HOLDER: {
    //   const holderUuidsToDelete: HolderUuids = action.payload;

    //   if (holderUuidsToDelete.length) {
    //     cloneState.list = cloneState.list.filter(holderUuid => !holderUuidsToDelete.includes(holderUuid)) || [];
    //   }

    //   return cloneState;
    // }

    case HolderActionTypes.SET_PRODUCT_HOLDER: {
      const addedProductHolderUuids: HolderUuids = action.payload;

      return {
        ...state,
        list: [...state.list, ...addedProductHolderUuids]
      };
    }

    case HolderActionTypes.REMOVE_PRODUCT_HOLDER: {
      const bookedHolderUuidsToKeep: HolderUuids = action.payload;

      if (!bookedHolderUuidsToKeep.length) {
        return {
          ...state,
          list: []
        };
      }

      return {
        ...state,
        list: cloneDeep(state.list.filter(holderUuid => bookedHolderUuidsToKeep.includes(holderUuid))) || []
      };
    }

    case HolderActionTypes.REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER: {
      const list: HolderUuids = action.payload;

      return {
        ...state,
        list
      };
    }

    case HolderActionTypes.SET_ACTIVE_SLIDE: {
      return {
        ...state,
        activeSlide: state.list.length > 1 ? action.payload : 0
      };
    }

    case HolderActionTypes.SET_QUESTIONNAIRE_INPUTS: {
      const questionnaireInputs: InputBase<any>[] = action.payload;

      return {
        ...state,
        questionnaireInputs
      };
    }

    case HolderActionTypes.SET_TICKET_HOLDER: {
      const ticketHolder: TicketHolder = action.payload;

      return {
        ...state,
        ticketHolder
      };
    }

    case HolderActionTypes.SET_TICKET_HOLDER_FORM_SUBMIT_RESULT: {
      const ticketHolderFormSubmitResult: boolean = action.payload;

      return {
        ...state,
        ticketHolderFormSubmitResult
      };
    }

    case HolderActionTypes.SET_TICKET_HOLDER_INPUTS: {
      const inputs: InputBase<any>[] = action.payload;

      return {
        ...state,
        inputs
      };
    }

    case HolderActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_HOLDER_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
