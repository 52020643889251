import { WorkshopProductList, WorkshopState } from '@products/models/workshop.model';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  ActionTypes as WorkshopActionTypes,
  Actions as WorkshopActions
} from '@store/products/workshop/workshop.actions';

export { WorkshopState as State };

export const initialState: WorkshopState = {
  list: []
};

export const workshopReducer = (
  state: WorkshopState = initialState,
  action: WorkshopActions | ProductActions
): WorkshopState => {
  switch (action.type) {
    case WorkshopActionTypes.SET_INITIAL_WORKSHOP_PRODUCT_LIST: {
      const workshopProductList: WorkshopProductList = action.payload;

      return {
        ...state,
        list: workshopProductList
      };
    }

    case WorkshopActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_WORKSHOP_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
