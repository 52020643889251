import { Injectable, OnDestroy } from '@angular/core';
import { PackageValidationStateViewModel, PackageWarningMessage } from '@products/models/package.model';
import { isProductTypeTariff } from '@products/models/product-selection.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class PackageValidationService implements OnDestroy {
  packageValidationState$: Observable<PackageValidationStateViewModel>;

  private packageValidationServiceState: PackageValidationStateViewModel;
  private readonly destroy$ = new Subject<void>();
  private readonly _packageValidationState$: BehaviorSubject<PackageValidationStateViewModel> = new BehaviorSubject({
    count: 0,
    package: null,
    validatedCount: 0,
    previousValidatedCount: 0,
    isCountOverLimit: false,
    isNextCountOverLimit: false,
    packageMinimalTariffCount: null,
    numberOfAllBookedTariffs: null,
    bookedTariffsTotalCountSortedByTicketPersonId: null,
    availableTariffsSortedByTicketPersonId: null,
    maxTariffLimit: null,
    tariffLimitPerUserAccount: null,
    currentUserAccountTariffLimit: null,
    packageLimitWarningMessage: ''
  });

  constructor() {
    this.packageValidationState$ = this._packageValidationState$.asObservable().distinctUntilChanged();
    this.packageValidationState$.pipe(takeUntil(this.destroy$)).subscribe(packageValidationState => {
      this.packageValidationServiceState = { ...this.packageValidationServiceState, ...packageValidationState };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initState(packageValidationState: PackageValidationStateViewModel) {
    this.calculatePackageMinimalTariffCount(packageValidationState);

    this._packageValidationState$.next(packageValidationState);
  }

  updateState(packageValidationState: Partial<PackageValidationStateViewModel>) {
    this._packageValidationState$.next({ ...this.packageValidationServiceState, ...packageValidationState });
  }

  validateState(count: number, packageValidationState: Partial<PackageValidationStateViewModel>) {
    packageValidationState.count = count;

    this.validate(packageValidationState);

    this._packageValidationState$.next({ ...this.packageValidationServiceState, ...packageValidationState });
  }

  getValidatedState() {
    return this.packageValidationServiceState;
  }

  geValidatedStateProperty(propertyName: string) {
    return propertyName in this.packageValidationServiceState ? this.packageValidationServiceState[propertyName] : null;
  }

  validate(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const { count, validatedCount } = packageValidationState;
    const isPackageRemoved = count < validatedCount;

    if (isPackageRemoved) {
      this.resetValidatedState(packageValidationState);
    } else {
      this.validateAvailableTariffsCount(packageValidationState);
      this.validateTariffLimitPerUserAccount(packageValidationState);
      this.validateMaxTariffLimit(packageValidationState);
    }

    this.setValidateCounterState(packageValidationState);
  }

  setValidateCounterState(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const {
      count,
      validatedCount,
      previousValidatedCount,
      isCountOverLimit,
      isNextCountOverLimit
    } = packageValidationState;

    if (isCountOverLimit || isNextCountOverLimit) {
      packageValidationState.validatedCount = isCountOverLimit && isNextCountOverLimit ? validatedCount : count;
      packageValidationState.previousValidatedCount = isNextCountOverLimit ? validatedCount : previousValidatedCount;
      packageValidationState.packageLimitWarningMessage = PackageWarningMessage.ERROR_PACKAGE_LIMIT_WARNING;
    } else {
      packageValidationState.validatedCount = count;
      packageValidationState.previousValidatedCount = validatedCount;
      packageValidationState.packageLimitWarningMessage = '';
    }
  }

  resetValidatedState(packageValidationState: Partial<PackageValidationStateViewModel>) {
    packageValidationState.isCountOverLimit = false;
    packageValidationState.isNextCountOverLimit = false;
  }

  revalidateDisabledCounter(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const { validatedCount, packageLimitWarningMessage } = packageValidationState;

    if (packageLimitWarningMessage) {
      this._packageValidationState$.next({
        ...this.packageValidationServiceState,
        ...packageValidationState,
        previousValidatedCount: validatedCount,
        isCountOverLimit: false,
        isNextCountOverLimit: false,
        packageLimitWarningMessage: ''
      });
    }
  }

  // TODO: What should happen when there is a fixed number of tariffs in package, the tariff has workshops that are sold out, and workshop selection is required? Currently there is no logic for this kind of validation.
  private validateAvailableTariffsCount(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const { availableTariffsSortedByTicketPersonId } = packageValidationState;

    packageValidationState.package.products.forEach(packageProductType => {
      if (isProductTypeTariff(packageProductType)) {
        packageProductType.tariffType.tariffs.forEach(packageTariff => {
          const {
            ticketPersonId,
            packageSettings: { fixedAmount, minAmount, amount }
          } = packageTariff;

          const validatedAmount = fixedAmount ? amount : minAmount;

          if (validatedAmount === 0 || packageValidationState.isCountOverLimit) {
            return;
          }

          const availableTariffsCount = availableTariffsSortedByTicketPersonId[ticketPersonId];
          const isPackageTariffSoldOut = availableTariffsCount !== null && availableTariffsCount === 0;

          packageValidationState.isCountOverLimit = isPackageTariffSoldOut || validatedAmount > availableTariffsCount;
          packageValidationState.isNextCountOverLimit =
            isPackageTariffSoldOut || validatedAmount * 2 > availableTariffsCount;
        });
      }
    });
  }

  private validateTariffLimitPerUserAccount(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const {
      isCountOverLimit,
      isNextCountOverLimit,
      currentUserAccountTariffLimit,
      numberOfAllBookedTariffs,
      packageMinimalTariffCount,
      tariffLimitPerUserAccount
    } = packageValidationState;

    if (!isCountOverLimit && !isNextCountOverLimit) {
      const isUserAccountLimitActive = !!tariffLimitPerUserAccount;

      if (isUserAccountLimitActive) {
        packageValidationState.isCountOverLimit =
          numberOfAllBookedTariffs + packageMinimalTariffCount > currentUserAccountTariffLimit;
        packageValidationState.isNextCountOverLimit =
          numberOfAllBookedTariffs + packageMinimalTariffCount * 2 > currentUserAccountTariffLimit;
      }
    }
  }

  private validateMaxTariffLimit(packageValidationState: Partial<PackageValidationStateViewModel>) {
    const {
      isCountOverLimit,
      isNextCountOverLimit,
      maxTariffLimit,
      numberOfAllBookedTariffs,
      packageMinimalTariffCount
    } = packageValidationState;

    if (!isCountOverLimit && !isNextCountOverLimit) {
      const checkMaxTariffLimit = maxTariffLimit !== null;

      if (checkMaxTariffLimit) {
        packageValidationState.isCountOverLimit = numberOfAllBookedTariffs + packageMinimalTariffCount > maxTariffLimit;
        packageValidationState.isNextCountOverLimit =
          numberOfAllBookedTariffs + packageMinimalTariffCount * 2 > maxTariffLimit;
      }
    }
  }

  private calculatePackageMinimalTariffCount(packageValidationState: Partial<PackageValidationStateViewModel>) {
    packageValidationState.package.products.forEach(packageProductType => {
      if (isProductTypeTariff(packageProductType)) {
        packageProductType.tariffType.tariffs.forEach(packageTariff => {
          const {
            packageSettings: { minAmount, amount }
          } = packageTariff;
          packageValidationState.packageMinimalTariffCount += minAmount != null ? minAmount : amount;
        });
      }
    });
  }
}
