<ng-container *ngIf="workshopVisitorsCountViewModel$ | async as workshopVisitorsCountViewModel">
  <div
    class="workshop-available"
    [ngClass]="{
      'sold-out':
        (!availableSeats && !workshopVisitorsCountViewModel.isLastSeatWithSingleAssignedTariffBooking) ||
        workshop.disabled
    }"
  >
    <span
      class="availability-text"
      data-translation="workshop.sold"
      *ngIf="!availableSeats && !workshopVisitorsCountViewModel.isLastSeatWithSingleAssignedTariffBooking"
    >
      {{ 'workshop.sold' | translate | hideTranslation }}
    </span>

    <span
      class="availability-text"
      data-translation="workshop.available"
      *ngIf="
        (availableSeats && !workshop.disabled) ||
        (!availableSeats && workshopVisitorsCountViewModel.isLastSeatWithSingleAssignedTariffBooking)
      "
    >
      <span *ngIf="showAmountOfAvailableWorkshops">
        {{ availableSeats }} {{ 'workshop.available' | translate | hideTranslation }}
      </span>
    </span>

    <span
      class="availability-text"
      data-translation="workshop.not-accessible"
      *ngIf="
        (!!availableSeats ||
          (!availableSeats && workshopVisitorsCountViewModel.isLastSeatWithSingleAssignedTariffBooking)) &&
        workshop.disabled
      "
    >
      {{ 'workshop.not-accessible' | translate | hideTranslation }}
    </span>
    <div class="visitors-count" *ngIf="workshopVisitorsCountViewModel.numberOfAssignedWorkshopHolderUuids">
      <span>{{ workshopVisitorsCountViewModel.numberOfAssignedWorkshopHolderUuids }}</span>
    </div>
  </div>
</ng-container>
