import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getExhibitionSettings, getProfile, getSelectedExhibition, getSelectedExhibitionId, getSelfRegistration, isFirstLoad, isLoggedInAsAdmin } from '@app/app.reducer';
import { AppService } from '@app/app.service';
import { Store, select } from '@ngrx/store';
import { FormsService } from '@shared/forms/forms.service';
import { CustomizationService } from '@store/customization/customization.service';
import { FirstLoadDone, GetDoubleClickScripts, GetGenericScripts, SelectAction } from '@store/exhibition/exhibition.actions';
import { SetEventSeriesPage } from '@store/helpers/helper.actions';
import { HelperService } from '@store/helpers/helper.service';
import { GetLegitimationStatus } from '@store/legitimation/legitimation.actions';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetBuyerInfoFromURL, SetSelectedStep } from '@store/step-forms/steps-forms.actions';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
var WebShopComponent = /** @class */ (function () {
    function WebShopComponent(route, router, store, customizationService, appService, formsService, location, helperService, legitimationService) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.customizationService = customizationService;
        this.appService = appService;
        this.formsService = formsService;
        this.location = location;
        this.helperService = helperService;
        this.legitimationService = legitimationService;
        this.hostClasses = 'flex-grow-column';
        this.subscriptions = new Subscription();
        this.eventIsOver = false;
        this.isLoading = true;
        this.modalWindowOpen = false;
        this.isSelfRegistrationEnabled = false;
    }
    WebShopComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this.helperService.showModalWindow(false);
    };
    WebShopComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.helperService.showModalWindow$.subscribe(function (item) {
            if (item) {
                _this.modalWindowOpen = true;
            }
        }));
        this.subscriptions.add(combineLatest([this.store.pipe(select(getProfile)), this.store.pipe(select(getSelectedExhibitionId))])
            .pipe(filter(function (data) { return !!data[0] && data[1] !== null; }))
            .subscribe(function (data) {
            var user = data[0], eventId = data[1];
            _this.exhibitionId = eventId;
            _this.store.dispatch(new GetLegitimationStatus({
                userId: user.id,
                eventId: eventId
            }));
        }));
        // #3863 - IE not supported
        this.store
            .pipe(select(getSelfRegistration))
            .pipe(first())
            .subscribe(function (selfRegistration) {
            _this.isSelfRegistrationEnabled = selfRegistration;
        });
        this.subscriptions.add(this.route.queryParamMap.subscribe(function (data) {
            var buyerInfoFromUrl = _this.createBuyerInfoFromUrl(data);
            _this.store.dispatch(new SetBuyerInfoFromURL(buyerInfoFromUrl));
        }));
        var oldExhibitionId = null;
        this.subscriptions.add(this.store.pipe(select(getSelectedExhibitionId)).subscribe(function (id) {
            oldExhibitionId = id;
        }));
        this.isLoading = true;
        this.subscriptions.add(this.route.params.subscribe(function (data) {
            // not using strict compare because id is stored as number but its a string in queryParamz
            if (data.id != oldExhibitionId) {
                _this.appService.resetReducers(true, false).subscribe(function () {
                    _this.store.dispatch(new SelectAction(data.id));
                    _this.store.dispatch(new GetDoubleClickScripts(data.id));
                    _this.store.dispatch(new GetGenericScripts(data.id));
                    _this.goToTickets(); // when we change event via url we go to ticket page always;
                    _this.setStyles();
                    _this.isLoading = false;
                });
            }
            else {
                _this.isLoading = false;
            }
        }));
        this.subscriptions.add(combineLatest([
            this.store.pipe(select(getSelectedExhibition)),
            this.store.pipe(select(isFirstLoad)),
            this.store.pipe(select(isLoggedInAsAdmin))
        ])
            .pipe(filter(function (data) {
            var hasSelectedExhibition = data[0];
            var anyFirstLoadValue = data[1] || !data[1]; // because isFirstLoad is a boolean and typescript throws error for unknown type
            return hasSelectedExhibition && anyFirstLoadValue;
        }))
            .subscribe(function (data) {
            _this.eventIsOver = false;
            var exhibition = data[0], isFirstLoad = data[1], isAdmin = data[2];
            var locationAtExhibitionID = '/webshop/' + exhibition.id;
            // when browser back button is pressed on ticket page we redirect user to homepage
            window.addEventListener('popstate', function () {
                if (_this.location.path() === locationAtExhibitionID) {
                    _this.navigateToRoot();
                }
            });
            // when programatically navigated back on ticket page we redirect user to homepage
            _this.location.subscribe(function (location) {
                if (location.pop) {
                    if (location.url === locationAtExhibitionID) {
                        _this.navigateToRoot();
                    }
                }
            });
            if ((!exhibition || exhibition.isOver) && !isAdmin) {
                _this.eventIsOver = true;
                setTimeout(function () {
                    _this.router.navigate(['./is-over'], {
                        relativeTo: _this.route
                    });
                }, 200);
            }
            else {
                // if you come to webshop page without any subpage decision to which step to go is made based on backoffice settings
                if (!_this.route.children.length || isFirstLoad) {
                    _this.getSettingsAndRedirect();
                }
                _this.route.queryParams.subscribe(function (params) {
                    if (params.theme) {
                        _this.customizationService.setColors(params.theme);
                    }
                    if (params.reset) {
                        _this.getSettingsAndRedirect();
                    }
                });
            }
        }));
    };
    WebShopComponent.prototype.modalWindowVote = function (copyDataYesNo) {
        this.modalWindowOpen = false;
        if (copyDataYesNo) {
            this.router.navigate(["webshop/" + this.exhibitionId + "/personal"]);
        }
        this.helperService.voteYesOrNo(copyDataYesNo);
    };
    // Possible parameters:
    // email, gender, title, firstName, lastName, company, function, country, street, city, telephone, zipCode
    WebShopComponent.prototype.createBuyerInfoFromUrl = function (params) {
        var translations = new Map();
        var buyerInfoUrlModel = [];
        // DE
        translations.set('besucher_firma', 'company');
        translations.set('besucher_br_anrede_id', 'gender');
        translations.set('besucher_titel', 'title');
        translations.set('besucher_vorname', 'firstName');
        translations.set('besucher_name', 'lastName');
        translations.set('besucher_funktion', 'function');
        translations.set('besucher_strasse', 'street');
        translations.set('besucher_plz', 'zipCode');
        translations.set('besucher_ort', 'city');
        translations.set('besucher_country', 'country');
        translations.set('besucher_email', 'email');
        translations.set('besucher_telefon', 'telephone');
        params.keys.forEach(function (parameterKey) {
            if (!translations.has(parameterKey))
                return;
            if (translations.get(parameterKey) === 'gender') {
                buyerInfoUrlModel.push({
                    key: translations.get(parameterKey),
                    value: params.get(parameterKey) === '0' ? 'male' : '1' ? 'female' : '2' ? 'diverse' : '3' ? 'notSpecified' : null
                });
            }
            else {
                if (translations.get(parameterKey) === 'email') {
                    buyerInfoUrlModel.push({
                        key: 'verify-email',
                        value: params.get(parameterKey)
                    });
                }
                buyerInfoUrlModel.push({
                    key: translations.get(parameterKey),
                    value: params.get(parameterKey)
                });
            }
        });
        return buyerInfoUrlModel;
    };
    WebShopComponent.prototype.setStyles = function () {
        var _this = this;
        this.subscriptions.add(this.store
            .pipe(select(getExhibitionSettings), filter(function (settings) { return !!settings; }))
            .subscribe(function () {
            _this.customizationService.setStyles();
        }));
    };
    WebShopComponent.prototype.goToLegitimation = function () {
        this.store.dispatch(new SetSelectedStep('legitimation'));
        this.router.navigate(['./legitimation'], {
            relativeTo: this.route,
            preserveQueryParams: true
        });
    };
    WebShopComponent.prototype.goToTickets = function () {
        this.store.dispatch(new SetSelectedStep('tickets'));
        //if the tickets are not in url yet, navigate there
        if (!(this.route.snapshot.firstChild &&
            this.route.snapshot.firstChild.url &&
            this.route.snapshot.firstChild.url[0].path === 'tickets')) {
            return this.router.navigate(['./tickets'], {
                relativeTo: this.route,
                queryParams: this.route.snapshot.queryParams
            });
        }
    };
    WebShopComponent.prototype.getSettingsAndRedirect = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(isFirstLoad)),
            this.legitimationService.isLegitimationRequiredForBookedProducts$()
        ])
            .pipe(first(function (_a) {
            var settings = _a[0];
            return !!settings;
        }))
            .subscribe(function (_a) {
            var settings = _a[0], isFirstLoad = _a[1], isLegitimationRequired = _a[2];
            if (settings.goToLegitimationForNewAccount && isLegitimationRequired) {
                _this.formsService.setFormValidity(false, null, ['legitimation', 'validation']);
                _this.goToLegitimation();
            }
            else if (isFirstLoad &&
                _this.route.snapshot.firstChild &&
                _this.route.snapshot.firstChild.url[0].path !== 'invoice' // if local storage is disabled for IE and we come from payment page to invoice page we need this condition
            ) {
                _this.goToTickets();
            }
            else if (!_this.route.snapshot.firstChild) {
                _this.goToTickets();
            }
            _this.store.dispatch(new SetEventSeriesPage(null)); // when we come to event reset event page info
            _this.store.dispatch(new FirstLoadDone());
        });
    };
    WebShopComponent.prototype.navigateToRoot = function () {
        window.location.replace('/');
    };
    return WebShopComponent;
}());
export { WebShopComponent };
