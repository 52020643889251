import { Injectable } from '@angular/core';
import { State, getExhibitionSettings } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { VoucherType } from '@products/models/voucher.model';
import { getBookedVoucherCountSortedByVoucherType } from '@store/products/booking/booking.selectors';
import { isRedeemedAnonymousVoucherProductInProductSelectionList } from '@store/products/product-selection/product-selection.selectors';
import { Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VoucherValidationService {
  constructor(private store: Store<State>) {}

  validateRedeemedVoucherTypeCount$(
    redeemedVoucherType: VoucherType,
    redeemedVoucherCount: number,
    isRedeemedVoucherAnonymous: boolean
  ): Observable<number> {
    return combineLatest([
      this.store.pipe(select(getBookedVoucherCountSortedByVoucherType)),
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(isRedeemedAnonymousVoucherProductInProductSelectionList))
    ]).pipe(
      first(
        ([bookedVoucherCountSortedByVoucherType, exhibitionSettings]) =>
          !!bookedVoucherCountSortedByVoucherType && !!exhibitionSettings
      ),
      map(
        ([
          bookedVoucherCountSortedByVoucherType,
          exhibitionSettings,
          isRedeemedAnonymousVoucherProductInProductSelectionList
        ]) => {
          if (isRedeemedVoucherAnonymous && !isRedeemedAnonymousVoucherProductInProductSelectionList) {
            return redeemedVoucherCount;
          }

          const voucherTypeLimits = {
            [VoucherType.PromoCode]: exhibitionSettings.limitPromoCodes,
            [VoucherType.LimiterPromoCode]: exhibitionSettings.limitLimitedPromocodes,
            [VoucherType.OneTimeVoucher]: Infinity
          };

          const bookedAndRedeemedVoucherTypeCount =
            bookedVoucherCountSortedByVoucherType[redeemedVoucherType] + redeemedVoucherCount;

          const isVoucherTypeCountOverLimit =
            bookedAndRedeemedVoucherTypeCount > voucherTypeLimits[redeemedVoucherType];

          if (isVoucherTypeCountOverLimit) {
            const voucherTypeOverLimitCount =
              bookedAndRedeemedVoucherTypeCount - voucherTypeLimits[redeemedVoucherType];
            const voucherTypeValidatedCount = redeemedVoucherCount - voucherTypeOverLimitCount;

            return voucherTypeValidatedCount;
          }

          return redeemedVoucherCount;
        }
      )
    );
  }
}
