import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  Subscription,
  combineLatest
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { State, getLanguage, getSelectedExhibitionId } from '../../../app.reducer';
import { AppConstants } from '../../../shared/app-constants';
import { FormsService } from '../../../shared/forms/forms.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { SetQuestionnaireInputs } from '../../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolderQuestionnaireInputs } from '../../../shared/services-with-reducers/products/holder/holder.selectors';

@Component({
  moduleId: module.id,
  selector: 'app-ticket-holder-questionnaire',
  templateUrl: './ticket-holder-questionnaire.component.html',
  styleUrls: ['./ticket-holder-questionnaire.component.scss']
})
export class TicketHolderQuestionnaireComponent implements OnInit, OnDestroy {
  @Output() isValid = new EventEmitter<Object>();

  customAction = ['ticketHolderQuestionaireForm'];

  form: FormGroup;
  inputs: InputBase<any>[];

  private readonly subscriptions = new Subscription();

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(getTicketHolderQuestionnaireInputs)),
        this.store.pipe(select(getSelectedExhibitionId)),
        this.store.pipe(select(getLanguage))
      ])
      .pipe(
        filter(([ticketHolderQuestionnaireInputs, eventId, lang]) => {
          if ((!!ticketHolderQuestionnaireInputs && !ticketHolderQuestionnaireInputs.length) ||(Number(eventId) === 90 && lang === 'en')) {
            this.isValid.emit({
              formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
              valid: true,
              inputs: null,
              form: null
            });

            this.inputs = null;
            return false;
          }

          return !!ticketHolderQuestionnaireInputs && !!ticketHolderQuestionnaireInputs.length;
        })
      )
      .subscribe(([ticketHolderQuestionnaireInputs]) => {
        this.inputs = this.formsService.updateInputs(this.inputs, ticketHolderQuestionnaireInputs);
        this.setupForm();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setInputsVisibility() {
    this.inputs.map((input: InputBase<any>) => {
      if (input.previousValueId) {
        let element = this.inputs.find(
          p =>
            p.value == input.previousValueId ||
            (p.controlType === 'checkbox' &&
              p.options.find(
                (option: { value: boolean; key: number }) =>
                  option.value === true && option.key === input.previousValueId
              ))
        );

        input.hidden = !element;
      }
    });
  }

  public inputsChanged = (inputs: InputBase<any>[]) => {
    // get updated inputs, now we need to everwrite the old set with updated ones
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);
    this.store.dispatch(new SetQuestionnaireInputs(this.inputs));
    this.setupForm();
  };

  setupForm() {
    this.setInputsVisibility();

    const visibleInputs = this.inputs.filter(p => !p.hidden);
    this.form = this.formsService.toFormGroup(visibleInputs);

    this.helperService.triggerCallbackOnceFormValidationIsDone(
      this.form,
      this.validationCallback
    );

    this.subscriptions.add(this.form.statusChanges.subscribe(this.validationCallback));
  }

  validationCallback = () => {
    this.isValid.emit({
      formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
      valid: !this.form.invalid,
      inputs: this.inputs,
      form: this.form
    });
  };
}
