import { Component } from '@angular/core';
import { State, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { GetMenu, SetMenu } from '@store/additional-services/additional-services.actions';
import { Meal, Menu, MenuList } from '@store/additional-services/additional-services.model';
import { getMenuList } from '@store/additional-services/additional-services.selectors';
import _ from 'lodash';
import { first } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-menu',
  templateUrl: './web-shop-menu.component.html',
  styleUrls: ['./web-shop-menu.component.scss']
})
export class WebShopMenuComponent {
  public menuListForDisplay: MenuList;
  public menuListToSave: MenuList;

  constructor(private store: Store<State>) {
    this.store
      .pipe(
        select(getMenuList),
        first(data => {
          // if there are no data for this exhibition yet, load them
          // only go for the first one with data
          if (data.length === 0) {
            this.loadMenuViaApi();
          }
          return data.length > 0;
        })
      )
      .subscribe(menuListData => {
        this.menuListForDisplay = menuListData;
        this.menuListToSave = _.cloneDeep(menuListData);
      });
  }

  loadMenuViaApi() {
    this.store
      .pipe(
        select(getSelectedExhibitionId),
        first()
      )
      .subscribe(eventId => {
        this.store.dispatch(new GetMenu(eventId));
      });
  }

  onMenuSelection(menu: Menu, item: Meal) {
    this.menuListToSave = this.menuListToSave.map(saveMenu => {
      const { id } = saveMenu;

      if (item.id === id) {
        return {
          ...saveMenu,
          selectedMenu: menu.id
        };
      } else {
        return saveMenu;
      }
    });

    this.store.dispatch(new SetMenu(this.menuListToSave));
  }
}
