<app-info-block
  *ngIf="!profile.email && !('profile.missing.email.info' | translate | isHiddenTranslation)"
  [infoText]="'profile.missing.email.info'"
></app-info-block>

<div class="form-block" *ngIf="emailForm && emailInputs?.length">
  <app-info-block *ngIf="profile.isEmailChangePending" [infoText]="'profile.pending.email.info'"></app-info-block>
  <div class="row edit-button-row" *ngIf="emailChangeActive === false">
    <div class="col-sm-8">
      <div class="text-data">
        {{ profile.email }}
      </div>
    </div>
    <div class="col-sm-4">
      <button class="button" (click)="emailChangeActive = true">
        <span data-translation="profile.login.email-change-btn">
          {{ 'profile.login.email-change-btn' | translate | hideTranslation }}
        </span>
      </button>
    </div>
  </div>
  <form *ngIf="emailChangeActive" [formGroup]="emailForm">
    <div class="row">
      <app-df-input
        *ngFor="let input of emailInputs"
        [input]="input"
        [class]="input.cssClass"
        [inputSet]="emailInputs"
        [form]="emailForm"
        [action]="changeEmailAction"
        [saveFormCallback]="emailFormSaveCallback"
      ></app-df-input>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-3">
        <div class="input-wrapper">
          <button
            class="button edit-button"
            [disabled]="!emailForm.valid || emailForm.controls.email.value === profile.email"
            (click)="submitEmailChange()"
          >
            <span data-translation="profile.save">
              {{ 'profile.save' | translate | hideTranslation }}
            </span>
          </button>
        </div>
      </div>

      <div class="col-md-3">
        <div class="input-wrapper">
          <button class="button edit-button" (click)="emailChangeActive = false">
            <span data-translation="profile.cancel">
              {{ 'profile.cancel' | translate | hideTranslation }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="form-block" *ngIf="passwordForm && passwordInputs?.length && profile.email">
  <app-info-block *ngIf="profile.isPasswordChangePending" [infoText]="'profile.pending.password.info'"></app-info-block>
  <div class="row edit-button-row" *ngIf="passwordChangeActive === false">
    <div class="col-sm-8">
      <div class="text-data">
        *********
      </div>
    </div>
    <div class="col-sm-4">
      <button class="button" (click)="passwordChangeActive = true">
        <span data-translation="profile.login.password-change-btn">
          {{ 'profile.login.password-change-btn' | translate | hideTranslation }}
        </span>
      </button>
    </div>
  </div>

  <form class="row" *ngIf="passwordChangeActive" [formGroup]="passwordForm">
    <app-df-input
      *ngFor="let input of passwordInputs"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="passwordInputs"
      [form]="passwordForm"
      [action]="changePasswordAction"
      [saveFormCallback]="passwordFormSaveCallback"
    ></app-df-input>

    <div class="col-md-6 col-lg-3">
      <div class="input-wrapper">
        <button class="button edit-button" [disabled]="!passwordForm.valid" (click)="submitPasswordChange()">
          <span data-translation="profile.save">
            {{ 'profile.save' | translate | hideTranslation }}
          </span>
        </button>
      </div>
    </div>

    <div class="col-md-6 col-lg-3">
      <div class="input-wrapper">
        <button class="button edit-button" (click)="passwordChangeActive = false">
          <span data-translation="profile.cancel">
            {{ 'profile.cancel' | translate | hideTranslation }}
          </span>
        </button>
      </div>
    </div>
  </form>
</div>
