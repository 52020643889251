import { Action } from '@ngrx/store';
import { BookingPackageType } from '@products/models/booking.model';

export enum ActionTypes {
  SET_INITIAL_PACKAGE_INDEX_STATUS = '[Package Status] Set initial package index status',
  SET_PACKAGE_INDEX_STATUS = '[Package Status] Set package index status',
  SET_IS_PACKAGE_LOADING_STATUS = '[Package Status] Set is package loading status',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class SetInitialPackageIndexStatus implements Action {
  readonly type = ActionTypes.SET_INITIAL_PACKAGE_INDEX_STATUS;
  constructor(public payload: number) {}
}

export class SetPackageIndexStatus implements Action {
  readonly type = ActionTypes.SET_PACKAGE_INDEX_STATUS;
  constructor(public payload: BookingPackageType[]) {}
}

export class SetIsPackageLoadingStatus implements Action {
  readonly type = ActionTypes.SET_IS_PACKAGE_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export class ResetPackageStatus implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | SetInitialPackageIndexStatus
  | SetPackageIndexStatus
  | SetIsPackageLoadingStatus
  | ResetPackageStatus;
