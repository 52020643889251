import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { ExhibitionSettingModel } from '@shared/services-with-reducers/customization/customization.interfaces';

import { State } from '@app/app.reducer';
import {
  ProductListItem,
  ProductSelectionProductsStatusViewModel,
  ProductSelectionViewModel,
  VoucherProducts
} from '@products/models/product-selection.model';
import { ProductSelectionService } from '@products/services/product-selection.service';
import { setLocalStorageObject } from '@shared/app-utils';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';

@Component({
  selector: 'app-widget-ticket-select',
  templateUrl: './widget-ticket-select.component.html',
  styleUrls: ['./widget-ticket-select.component.scss']
})
export class WidgetTicketSelectComponent implements OnInit, OnDestroy {
  productSelectionProductsAndExhibitionState$: Observable<{
    selectedExhibition: ExhibitionModel;
    exhibitionSettings: ExhibitionSettingModel;
    productSelectionListProducts: ProductListItem[];
    voucherProducts: VoucherProducts;
  }>;
  productSelectionViewModel$: Observable<ProductSelectionViewModel>;
  productSelectionProductsStatusViewModel$: Observable<ProductSelectionProductsStatusViewModel>;

  private unsubscribe = new Subject();

  constructor(private store: Store<State>, private productSelectionService: ProductSelectionService) {}

  ngOnInit() {
    this.initComponentStoreSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initComponentStoreSelectors() {
    this.productSelectionProductsAndExhibitionState$ = this.productSelectionService.getWidgetProductSelectionProductsAndExhibitionState$();
    this.productSelectionViewModel$ = this.productSelectionService.getProductSelectionViewModel$();
    this.productSelectionProductsStatusViewModel$ = this.productSelectionService.getProductSelectionProductsStatusViewModel$();
  }

  onContinueClick($event: MouseEvent, eventId: number) {
    $event.stopPropagation();
    $event.preventDefault();

    const webshopOriginURL = `${window.location.protocol}//${window.location.host}`;
    const webshopPagePathname = `/webshop/${eventId}/tickets`;
    const toWebshop = webshopOriginURL + webshopPagePathname;

    this.store.pipe(first()).subscribe(store => {
      try {
        for (const key in store) {
          const storeItem = store[key];

          setLocalStorageObject(key, storeItem);
        }
      } catch (error) {
        console.log(error);
      }
    });

    window.open(toWebshop);
  }

  productSelectionListTrackBy(productListItemIndex: number) {
    return productListItemIndex;
  }
}
