import { Injectable } from '@angular/core';
import { State } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  SetInitialIsWorkshopDetailModalOpenStatus,
  ToggleIsWorkshopDetailModalOpenStatus
} from '@store/products/status/workshop/workshop.actions';
import { getIsWorkshopDetailModalOpen } from '@store/products/status/workshop/workshop.selectors';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkshopStatusService {
  constructor(private store: Store<State>) {}

  setInitialWorkshopDetailModalOpenState() {
    const setInitialIsWorkshopDetailModalOpenStatus$ = this.store
      .pipe(select(getIsWorkshopDetailModalOpen))
      .pipe(take(1))
      .subscribe(isWorkshopDetailModalOpen => {
        if (isWorkshopDetailModalOpen) {
          this.store.dispatch(new SetInitialIsWorkshopDetailModalOpenStatus());
        }
      });

    setInitialIsWorkshopDetailModalOpenStatus$.unsubscribe();
  }

  toggleWorkshopDetailModalOpenState() {
    this.store.dispatch(new ToggleIsWorkshopDetailModalOpenStatus());
  }
}
