import { ActionTypes, Actions } from '@store/additional-services/additional-services.actions';
import { AdditionalServicesState } from '@store/additional-services/additional-services.model';
import _ from 'lodash';

export const initialState: AdditionalServicesState = {
  menu: []
};

export type State = AdditionalServicesState;

export function reducer(state = initialState, action: Actions): AdditionalServicesState {
  const clonedInitialState: AdditionalServicesState = _.cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_MENU:
      const menuList = action.payload;

      return {
        ...state,
        menu: menuList
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}
