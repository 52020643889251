import _ from 'lodash';
import * as i0 from "@angular/core";
var WorkshopValidationService = /** @class */ (function () {
    function WorkshopValidationService() {
        this.workshopValidationState = {
            validatedWorkshopTariffHolders: [],
            isWorkshopAvailableSeatsLimitReached: false,
            isWorkshopLimitPerTariffHolderReached: false,
            areWorkshopsOverlapping: false
        };
    }
    WorkshopValidationService.prototype.validateWorkshop = function (workshopTariffHolders, workshopProductList, availableSeats, workshopLimitPerTariffHolder, enableWorkshopSelectionOverlapping) {
        this.resetWorkshopValidationState();
        this.validateAvailableSeats(availableSeats);
        if (!this.workshopValidationState.isWorkshopAvailableSeatsLimitReached) {
            this.validateWorkshopsHolders(workshopTariffHolders);
            this.validateWorkshopLimitPerTariffHolder(workshopLimitPerTariffHolder);
            this.validateAreWorkshopsOverlapping(workshopProductList, enableWorkshopSelectionOverlapping);
        }
        return this.workshopValidationState;
    };
    WorkshopValidationService.prototype.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = function (bookedTariffs, limitWorkshopPerTicket) {
        var assignedWorkshopsGroupedByHolderUuid = {};
        var areAllBookedTariffHoldersAssignedToWorkshops = true;
        bookedTariffs.forEach(function (bookedTariff) {
            if (!areAllBookedTariffHoldersAssignedToWorkshops) {
                return;
            }
            bookedTariff.workshops.forEach(function (_a) {
                var holderUuids = _a.holderUuids;
                holderUuids.forEach(function (holderUuid) {
                    if (!assignedWorkshopsGroupedByHolderUuid[holderUuid]) {
                        assignedWorkshopsGroupedByHolderUuid[holderUuid] = 1;
                    }
                    else {
                        assignedWorkshopsGroupedByHolderUuid[holderUuid] += 1;
                    }
                });
            });
            var allAssignedWorkshopHolderUuids = _.keys(assignedWorkshopsGroupedByHolderUuid);
            var assignedWorkshopHoldersFromCurrentBookedTariff = _.intersection(bookedTariff.holderUuids, allAssignedWorkshopHolderUuids);
            areAllBookedTariffHoldersAssignedToWorkshops =
                assignedWorkshopHoldersFromCurrentBookedTariff.length === bookedTariff.holderUuids.length;
        });
        if (areAllBookedTariffHoldersAssignedToWorkshops) {
            return _.values(assignedWorkshopsGroupedByHolderUuid).every(function (assignedHolderWorkshopsCount) { return assignedHolderWorkshopsCount <= limitWorkshopPerTicket; });
        }
        return false;
    };
    WorkshopValidationService.prototype.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = function (bookedTariffs, limitWorkshopPerTicket) {
        var assignedZeroPriceTariffWorkshopsGroupedByHolderUuid = {};
        var areAllZeroPricedBookedTariffHoldersAssignedToWorkshops = true;
        bookedTariffs.forEach(function (bookedTariff) {
            if (!areAllZeroPricedBookedTariffHoldersAssignedToWorkshops || bookedTariff.price > 0) {
                return;
            }
            bookedTariff.workshops.forEach(function (_a) {
                var holderUuids = _a.holderUuids;
                holderUuids.forEach(function (holderUuid) {
                    if (!assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid]) {
                        assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid] = 1;
                    }
                    else {
                        assignedZeroPriceTariffWorkshopsGroupedByHolderUuid[holderUuid] += 1;
                    }
                });
            });
            var allAssignedZeroPricedTariffWorkshopHolderUuids = _.keys(assignedZeroPriceTariffWorkshopsGroupedByHolderUuid);
            if (!allAssignedZeroPricedTariffWorkshopHolderUuids) {
                return true;
            }
            var assignedWorkshopHoldersFromCurrentBookedTariff = _.intersection(bookedTariff.holderUuids, allAssignedZeroPricedTariffWorkshopHolderUuids);
            areAllZeroPricedBookedTariffHoldersAssignedToWorkshops =
                assignedWorkshopHoldersFromCurrentBookedTariff.length === bookedTariff.holderUuids.length;
        });
        if (areAllZeroPricedBookedTariffHoldersAssignedToWorkshops) {
            return _.values(assignedZeroPriceTariffWorkshopsGroupedByHolderUuid).every(function (assignedHolderWorkshopsCount) { return assignedHolderWorkshopsCount <= limitWorkshopPerTicket; });
        }
        return false;
    };
    WorkshopValidationService.prototype.resetWorkshopValidationState = function () {
        this.workshopValidationState = {
            validatedWorkshopTariffHolders: [],
            isWorkshopAvailableSeatsLimitReached: false,
            isWorkshopLimitPerTariffHolderReached: false,
            areWorkshopsOverlapping: false
        };
    };
    WorkshopValidationService.prototype.validateAvailableSeats = function (availableSeats) {
        this.workshopValidationState.isWorkshopAvailableSeatsLimitReached = availableSeats === 0;
    };
    WorkshopValidationService.prototype.filterAlreadyAssignedHolders = function (workshopTariffHolders) {
        return workshopTariffHolders.filter(function (workshopTariffHolder) { return !workshopTariffHolder.isHolderAssigned; });
    };
    WorkshopValidationService.prototype.validateWorkshopsHolders = function (workshopTariffHolders) {
        this.workshopValidationState.validatedWorkshopTariffHolders = this.filterAlreadyAssignedHolders(workshopTariffHolders);
    };
    WorkshopValidationService.prototype.validateWorkshopLimitPerTariffHolder = function (workshopLimitPerTariffHolder) {
        this.workshopValidationState.isWorkshopLimitPerTariffHolderReached = this.workshopValidationState.validatedWorkshopTariffHolders.some(function (workshopTariffHolder) { return workshopTariffHolder.assignedWorkshops.length >= workshopLimitPerTariffHolder; });
    };
    WorkshopValidationService.prototype.validateAreWorkshopsOverlapping = function (workshopProductList, enableWorkshopSelectionOverlapping) {
        var _this = this;
        if (enableWorkshopSelectionOverlapping) {
            this.workshopValidationState.areWorkshopsOverlapping = false;
            return;
        }
        this.workshopValidationState.validatedWorkshopTariffHolders.forEach(function (workshopTariffHolder) {
            if (_this.workshopValidationState.areWorkshopsOverlapping) {
                return;
            }
            var selectedTariffHolderWorkshop = workshopProductList.find(function (workshopProduct) { return workshopProduct.workshopId === workshopTariffHolder.workshopId; });
            var alreadyAssignedTariffHolderWorkshops = workshopProductList.filter(function (workshopProduct) {
                return workshopTariffHolder.assignedWorkshops.includes(workshopProduct.workshopId);
            });
            _this.workshopValidationState.areWorkshopsOverlapping = alreadyAssignedTariffHolderWorkshops.reduce(function (areWorkshopsOverlapping, alreadyAssignedTariffHolderWorkshop) {
                return areWorkshopsOverlapping ||
                    _this.areWorkshopsOverleaping(alreadyAssignedTariffHolderWorkshop, selectedTariffHolderWorkshop);
            }, false);
        });
    };
    WorkshopValidationService.prototype.areWorkshopsOverleaping = function (workshopA, workshopB) {
        if (workshopA && workshopB && workshopA !== workshopB) {
            var currentDate = new Date().toDateString();
            var dateWorkshopA = this.getWorkshopFullDate(workshopA.date);
            var dateWorkshopB = this.getWorkshopFullDate(workshopB.date);
            if (dateWorkshopA === dateWorkshopB) {
                var startWorkshopA = new Date(currentDate + ' ' + new Date(workshopA.start).toTimeString());
                var startWorkshopB = new Date(currentDate + ' ' + new Date(workshopB.start).toTimeString());
                var endWorkshopA = new Date(currentDate + ' ' + new Date(workshopA.end).toTimeString());
                var endWorkshopB = new Date(currentDate + ' ' + new Date(workshopB.end).toTimeString());
                var isWorkshopAOverleapingWorkshopB = (startWorkshopA >= startWorkshopB && startWorkshopA < endWorkshopB) ||
                    (endWorkshopA <= endWorkshopB && endWorkshopA > startWorkshopB);
                var isWorkshopBOverleapingWorkshopA = (startWorkshopB >= startWorkshopA && startWorkshopB < endWorkshopA) ||
                    (endWorkshopB <= endWorkshopA && endWorkshopB > startWorkshopA);
                if (isWorkshopAOverleapingWorkshopB || isWorkshopBOverleapingWorkshopA) {
                    return true;
                }
            }
        }
        return false;
    };
    WorkshopValidationService.prototype.getWorkshopFullDate = function (date) {
        var newDate = new Date(date);
        var day = newDate.getDate();
        var month = newDate.getMonth() + 1;
        var year = newDate.getFullYear();
        return day + '/' + month + '/' + year;
    };
    WorkshopValidationService.ngInjectableDef = i0.defineInjectable({ factory: function WorkshopValidationService_Factory() { return new WorkshopValidationService(); }, token: WorkshopValidationService, providedIn: "root" });
    return WorkshopValidationService;
}());
export { WorkshopValidationService };
