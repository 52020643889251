import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Package, PackageType, PackageValidationStateViewModel } from '@products/models/package.model';
import {
  ProductGroup,
  ProductSelectionBookingStatusViewModel,
  ProductSelectionPackageStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel
} from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';
import { PackageValidationService } from '@products/services/package-validation.service';
import { PackageService } from '@products/services/package.service';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import {
  ActionTypes as BookingActionTypes,
  RemoveProductFromBookingListError,
  SetProductInBookingListError
} from '@store/products/booking/booking.actions';
import cloneDeep from 'lodash.clonedeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-package-type',
  providers: [PackageService, PackageValidationService],
  templateUrl: './package-type.component.html',
  styleUrls: ['./package-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageTypeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() workshopStatusViewModel: ProductSelectionWorkshopStatusViewModel;
  @Input() packageStatusViewModel: ProductSelectionPackageStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() productGroup: ProductGroup;
  @Input() packageType: PackageType;
  packageValidationViewModel: PackageValidationStateViewModel;
  packageWarningMessage: string;

  readonly ProductType = ProductType;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private packageService: PackageService,
    private packageValidationService: PackageValidationService
  ) {}

  ngOnInit() {
    this.initPackageValidationServiceState();
    this.initPackageValidationServiceSubscription();
    this.initFailedBookingValidationStateRevalidationSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePackageValidationState();
    this.revalidateCounterOnBookingCountDecreaseStateChange(changes);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initPackageValidationServiceState() {
    const validatePackage: Package = cloneDeep(this.packageType.packages[0]);
    const { limitBoughtTickets, ticketLimitPerUserAccount } = this.exhibitionSettings;
    const { currentUserAccountTicketLimit, availableTariffs } = this.tariffStatusViewModel;
    const { bookedProductsCount } = this.bookingStatusViewModel;

    this.packageValidationService.initState({
      count: this.packageType.numberOfAddedPackages,
      package: validatePackage,
      validatedCount: this.packageType.numberOfAddedPackages,
      previousValidatedCount: this.packageType.numberOfAddedPackages,
      isCountOverLimit: false,
      isNextCountOverLimit: false,
      packageMinimalTariffCount: 0,
      numberOfAllBookedTariffs: bookedProductsCount,
      availableTariffsSortedByTicketPersonId: availableTariffs,
      maxTariffLimit: limitBoughtTickets,
      tariffLimitPerUserAccount: ticketLimitPerUserAccount,
      currentUserAccountTariffLimit: currentUserAccountTicketLimit,
      packageLimitWarningMessage: ''
    });
  }

  initPackageValidationServiceSubscription() {
    this.packageValidationService.packageValidationState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(packageValidationViewModel => {
        this.packageValidationViewModel = packageValidationViewModel;

        this.updateCount();
      });
  }

  initFailedBookingValidationStateRevalidationSubscription() {
    this.actions$
      .pipe(
        ofType<SetProductInBookingListError | RemoveProductFromBookingListError>(
          BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST_ERROR,
          BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const revalidateValidationStateAfterFailedBooking =
          this.packageValidationViewModel.validatedCount !== this.packageType.numberOfAddedPackages;

        if (revalidateValidationStateAfterFailedBooking) {
          this.revalidateCountAfterFailedBooking();
        }
      });
  }

  updatePackageValidationState() {
    if (this.packageValidationViewModel && this.tariffStatusViewModel && this.bookingStatusViewModel) {
      const { currentUserAccountTicketLimit, availableTariffs } = this.tariffStatusViewModel;
      const { bookedProductsCount } = this.bookingStatusViewModel;

      const updatePackageValidationViewModel = {
        availableTariffsSortedByTicketPersonId: availableTariffs,
        numberOfAllBookedTariffs: bookedProductsCount,
        currentUserAccountTariffLimit: currentUserAccountTicketLimit
      };

      this.packageValidationViewModel = { ...this.packageValidationViewModel, ...updatePackageValidationViewModel };
    }
  }

  revalidateCounterOnBookingCountDecreaseStateChange(changes: SimpleChanges) {
    const { bookingStatusViewModel } = changes;

    if (bookingStatusViewModel) {
      const currentChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.currentValue;
      const previousChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.previousValue;

      if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
        return;
      }

      const isBookedProductsCountDecreased =
        previousChangedBookingStatusViewModel.bookedProductsCount >
        currentChangedBookingStatusViewModel.bookedProductsCount;

      if (isBookedProductsCountDecreased) {
        this.packageValidationService.revalidateDisabledCounter(this.packageValidationViewModel);
      }
    }
  }

  revalidateCountAfterFailedBooking() {
    const { numberOfAddedPackages } = this.packageType;

    this.packageValidationViewModel.validatedCount = numberOfAddedPackages;

    this.validateCount(numberOfAddedPackages);
  }

  validateCount(updatePackageCount: number) {
    this.packageValidationService.validateState(updatePackageCount, this.packageValidationViewModel);
  }

  updateCount() {
    const { lastPackageIndex } = this.packageStatusViewModel;
    const { validatedCount, previousValidatedCount } = this.packageValidationViewModel;
    const isRevalidatedDisabledCountOnTariffCounterDecrease = validatedCount === previousValidatedCount;

    if (isRevalidatedDisabledCountOnTariffCounterDecrease) {
      return;
    }

    if (validatedCount >= 0) {
      if (validatedCount > previousValidatedCount) {
        this.packageService.addPackage(this.packageType, lastPackageIndex, previousValidatedCount, validatedCount);
      } else if (validatedCount < previousValidatedCount) {
        this.packageService.removePackage(this.packageType, previousValidatedCount, validatedCount);
      }
    }
  }

  packagesTrackBy(_: number, currentPackage: Package) {
    return currentPackage.trackByAddedPackage;
  }
}
