/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web-shop-account-verification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../login/login.component.ngfactory";
import * as i5 from "../../login/login.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/router";
import * as i8 from "@ngrx/store";
import * as i9 from "../../login/login.service";
import * as i10 from "../../shared/services-with-reducers/user/user.service";
import * as i11 from "../../shared/services-with-reducers/helpers/helper.service";
import * as i12 from "../../info-block/info-block.component.ngfactory";
import * as i13 from "../../info-block/info-block.component";
import * as i14 from "../../shared/pipes/is-hidden-translation/is-hidden-translation.pipe";
import * as i15 from "./web-shop-account-verification.component";
var styles_WebShopAccountVerificationComponent = [i0.styles];
var RenderType_WebShopAccountVerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebShopAccountVerificationComponent, data: {} });
export { RenderType_WebShopAccountVerificationComponent as RenderType_WebShopAccountVerificationComponent };
function View_WebShopAccountVerificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "login-message"], ["data-translation", "account-verification.login-message"], ["data-translation-includes-children", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("account-verification.login-message")); _ck(_v, 0, 0, currVal_0); }); }
function View_WebShopAccountVerificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_WebShopAccountVerificationComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-login", [["class", "login-body"]], null, null, null, i4.View_LoginComponent_0, i4.RenderType_LoginComponent)), i1.ɵdid(6, 245760, null, 0, i5.LoginComponent, [i6.FormBuilder, i7.Router, i8.Store, i2.TranslateService, i9.LoginService, i10.UserService, i11.HelperService, i7.ActivatedRoute], null, null)], function (_ck, _v) { var currVal_0 = !i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("account-verification.login-message")))); _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); }, null); }
function View_WebShopAccountVerificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-block", [], null, null, null, i12.View_InfoBlockComponent_0, i12.RenderType_InfoBlockComponent)), i1.ɵdid(1, 49152, null, 0, i13.InfoBlockComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var currVal_0 = "account-verification.unverified-email"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WebShopAccountVerificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i14.IsHiddenTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "account-verification-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WebShopAccountVerificationComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["accountVerificationElseBlock", 2]], null, 0, null, View_WebShopAccountVerificationComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.userProfile$)); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_WebShopAccountVerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-shop-account-verification", [], null, null, null, View_WebShopAccountVerificationComponent_0, RenderType_WebShopAccountVerificationComponent)), i1.ɵdid(1, 114688, null, 0, i15.WebShopAccountVerificationComponent, [i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebShopAccountVerificationComponentNgFactory = i1.ɵccf("app-web-shop-account-verification", i15.WebShopAccountVerificationComponent, View_WebShopAccountVerificationComponent_Host_0, {}, {}, []);
export { WebShopAccountVerificationComponentNgFactory as WebShopAccountVerificationComponentNgFactory };
