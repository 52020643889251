var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy } from '@angular/core';
import { getExhibitionSettings, notLoggedAndLoginMandatory } from '@app/app.reducer';
import { LoginService } from '@app/login/login.service';
import { Store, select } from '@ngrx/store';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { AppConstants } from '@shared/app-constants';
import { getAllBookedProductsCount, getBookedPromoCodeVoucherTariffsCount, getBookedTariffCount } from '@store/products/booking/booking.selectors';
import { getPreferredTariff } from '@store/products/product-selection/product-selection.selectors';
import { getAvailableTariffs, getUserAccountTariffLimit } from '@store/products/status/tariff/tariff.selectors';
import { getWorkshopsAvailableSeatsGroupedByWorkshopId } from '@store/products/status/workshop/workshop.selectors';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../../login/login.service";
import * as i3 from "./tariff-count-validation.service";
var PreferredTariffValidationService = /** @class */ (function () {
    function PreferredTariffValidationService(store, loginService, tariffCountValidationService) {
        var _this = this;
        this.store = store;
        this.loginService = loginService;
        this.tariffCountValidationService = tariffCountValidationService;
        this.destroy$ = new Subject();
        this._preferredTariffValidationState$ = new BehaviorSubject({
            ticketTypeId: null,
            ticketPersonTypeId: null,
            count: 0,
            countDifference: 0,
            validatedCount: 0,
            previousValidatedCount: 0,
            validatedMaxLimit: 0,
            isCountOverLimit: false,
            isTariffSoldOut: false,
            isAvailableTariffsLimitReached: false,
            numberOfBookedVouchers: null,
            numberOfAllBookedTariffs: null,
            initialNumberOfAvailableTariffs: null,
            currentNumberOfAvailableTariffs: null,
            tariffLimit: null,
            maxTariffLimit: null,
            tariffLimitPerUserAccount: null,
            currentUserAccountTariffLimit: null,
            isCurrentUserAccountTariffLimitReached: false,
            hideWorkshopTariffCounter: false,
            allowedWorkshopsSeatsAvailable: null,
            areAllAllowedWorkshopsAssigned: false,
            isWorkshopsSelectionMandatoryAndHasWorkshops: false,
            isVoucher: false,
            isVoucherIncludedPerUserAccountLimit: false,
            tariffLimitWarningMessage: ''
        });
        this.preferredTariffValidationState$ = this._preferredTariffValidationState$.asObservable().distinctUntilChanged();
        this.preferredTariffValidationState$.pipe(takeUntil(this.destroy$)).subscribe(function (tariffValidationState) {
            _this.preferredTariffValidationServiceState = __assign({}, _this.preferredTariffValidationServiceState, tariffValidationState);
        });
    }
    PreferredTariffValidationService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    PreferredTariffValidationService.prototype.validatePreferredTariffCount$ = function (ticketTypeId, ticketPersonTypeId, count) {
        var _this = this;
        if (!count) {
            return of({
                ticketTypeId: ticketTypeId,
                ticketPersonTypeId: ticketPersonTypeId,
                validatedCount: AppConstants.PREFERRED_PRODUCT_DEFAULT_AMOUNT
            });
        }
        var bookingAndStatusState$ = combineLatest([
            this.store.pipe(select(getWorkshopsAvailableSeatsGroupedByWorkshopId)),
            this.store.pipe(select(getUserAccountTariffLimit)),
            this.store.pipe(select(getAvailableTariffs)),
            this.store.pipe(select(getAllBookedProductsCount)),
            this.store.pipe(select(getBookedPromoCodeVoucherTariffsCount))
        ]);
        return combineLatest([
            this.store.pipe(select(notLoggedAndLoginMandatory)),
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getPreferredTariff(ticketTypeId, ticketPersonTypeId))),
            bookingAndStatusState$
        ]).pipe(first(function (_a) {
            var notLoggedAndLoginMandatory = _a[0], exhibitionSettings = _a[1], tariff = _a[2], _b = _a[3], workshopAvailableSeats = _b[0], userAccountTariffLimit = _b[1], currentAvailableTariffs = _b[2];
            var isValid = !notLoggedAndLoginMandatory &&
                !!tariff &&
                !!exhibitionSettings &&
                !!workshopAvailableSeats &&
                !!currentAvailableTariffs;
            var isUserAccountTariffLimitRequired = !notLoggedAndLoginMandatory && exhibitionSettings && exhibitionSettings.ticketLimitPerUserAccount > 0;
            if (isUserAccountTariffLimitRequired) {
                var isCurrentUserAccountTariffLimitSet = userAccountTariffLimit !== null;
                return isValid && isCurrentUserAccountTariffLimitSet;
            }
            return isValid;
        }), switchMap(function (_a) {
            var _ = _a[0], exhibitionSettings = _a[1], tariff = _a[2], _b = _a[3], workshopAvailableSeats = _b[0], userAccountTicketLimit = _b[1], currentAvailableTariffs = _b[2], bookedProductsCount = _b[3], numberOfBookedVouchers = _b[4];
            return of(tariff).pipe(withLatestFrom(_this.store.pipe(select(getBookedTariffCount(tariff.ticketTypeId, tariff.ticketPersonId, tariff.voucherCode, tariff.packageNumber, tariff.packageIndex)))), map(function (_a) {
                var tariff = _a[0], currentBookedTariffCount = _a[1];
                return ({
                    tariff: tariff,
                    exhibitionSettings: exhibitionSettings,
                    numberOfBookedVouchers: numberOfBookedVouchers,
                    bookedProductsCount: bookedProductsCount,
                    currentUserAccountTicketLimit: userAccountTicketLimit,
                    workshopAvailableSeats: workshopAvailableSeats,
                    currentAvailableTariffs: currentAvailableTariffs,
                    currentBookedTariffCount: currentBookedTariffCount
                });
            }));
        }), map(function (_a) {
            var tariff = _a.tariff, exhibitionSettings = _a.exhibitionSettings, numberOfBookedVouchers = _a.numberOfBookedVouchers, bookedProductsCount = _a.bookedProductsCount, currentUserAccountTicketLimit = _a.currentUserAccountTicketLimit, currentAvailableTariffs = _a.currentAvailableTariffs, workshopAvailableSeats = _a.workshopAvailableSeats, currentBookedTariffCount = _a.currentBookedTariffCount;
            var ticketPersonId = tariff.ticketPersonId, isVoucher = tariff.isVoucher, availableTickets = tariff.availableTickets, ticketLimit = tariff.ticketLimit, allowedWorkshops = tariff.allowedWorkshops;
            if (isVoucher) {
                return {
                    ticketTypeId: ticketTypeId,
                    ticketPersonTypeId: ticketPersonTypeId,
                    validatedCount: 0
                };
            }
            var limitBoughtTickets = exhibitionSettings.limitBoughtTickets, ticketLimitPerUserAccount = exhibitionSettings.ticketLimitPerUserAccount, isWorkshopsSelectionMandatory = exhibitionSettings.isWorkshopsSelectionMandatory, isVoucherIncludedPerUserAccountLimit = exhibitionSettings.isVoucherIncludedPerUserAccountLimit;
            var _b = _this.tariffCountValidationService.tariffAllowedWorkshopsStatus(count, tariff, workshopAvailableSeats), allowedWorkshopsSeatsAvailable = _b.allowedWorkshopsSeatsAvailable, hideWorkshopTariffCounter = _b.hideWorkshopTariffCounter, areAllAllowedWorkshopsAssigned = _b.areAllAllowedWorkshopsAssigned;
            _this.initAndValidateState({
                ticketTypeId: ticketTypeId,
                ticketPersonTypeId: ticketPersonTypeId,
                count: count,
                validatedCount: currentBookedTariffCount,
                previousValidatedCount: currentBookedTariffCount,
                isTariffSoldOut: _this.tariffCountValidationService.isTariffSoldOut(currentBookedTariffCount, availableTickets),
                isAvailableTariffsLimitReached: _this.tariffCountValidationService.isTariffSoldOut(currentBookedTariffCount, currentAvailableTariffs[ticketPersonId]),
                numberOfBookedVouchers: numberOfBookedVouchers,
                numberOfAllBookedTariffs: bookedProductsCount,
                initialNumberOfAvailableTariffs: availableTickets,
                currentNumberOfAvailableTariffs: currentAvailableTariffs[ticketPersonId],
                percentageOfAvailableTariffs: _this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(count, ticketLimit, currentAvailableTariffs[ticketPersonId]),
                tariffLimit: ticketLimit,
                maxTariffLimit: limitBoughtTickets,
                tariffLimitPerUserAccount: ticketLimitPerUserAccount,
                currentUserAccountTariffLimit: currentUserAccountTicketLimit,
                isCurrentUserAccountTariffLimitReached: _this.tariffCountValidationService.isCurrentUserAccountTariffLimitReached(ticketLimitPerUserAccount, currentUserAccountTicketLimit),
                hideWorkshopTariffCounter: hideWorkshopTariffCounter,
                allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
                areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
                isWorkshopsSelectionMandatoryAndHasWorkshops: isWorkshopsSelectionMandatory && allowedWorkshops.length > 0,
                isVoucher: isVoucher,
                isVoucherIncludedPerUserAccountLimit: isVoucherIncludedPerUserAccountLimit,
                tariffLimitWarningMessage: ''
            });
            return {
                ticketTypeId: ticketTypeId,
                ticketPersonTypeId: ticketPersonTypeId,
                validatedCount: _this.preferredTariffValidationServiceState.validatedCount
            };
        }));
    };
    PreferredTariffValidationService.prototype.initAndValidateState = function (tariffValidationState) {
        this.validate(tariffValidationState);
        this._preferredTariffValidationState$.next(__assign({}, this.preferredTariffValidationServiceState, tariffValidationState));
    };
    PreferredTariffValidationService.prototype.updateState = function (tariffValidationState) {
        this._preferredTariffValidationState$.next(__assign({}, this.preferredTariffValidationServiceState, tariffValidationState));
    };
    PreferredTariffValidationService.prototype.validateState = function (count, tariffValidationState) {
        tariffValidationState.count = count;
        this.validate(tariffValidationState);
        this._preferredTariffValidationState$.next(__assign({}, this.preferredTariffValidationServiceState, tariffValidationState));
    };
    PreferredTariffValidationService.prototype.getValidatedState = function () {
        return this.preferredTariffValidationServiceState;
    };
    PreferredTariffValidationService.prototype.geValidatedStateProperty = function (propertyName) {
        return propertyName in this.preferredTariffValidationServiceState
            ? this.preferredTariffValidationServiceState[propertyName]
            : null;
    };
    PreferredTariffValidationService.prototype.validate = function (tariffValidationState) {
        var count = tariffValidationState.count, validatedCount = tariffValidationState.validatedCount;
        tariffValidationState.countDifference = count - validatedCount;
        tariffValidationState.previousValidatedCount = validatedCount;
        this.tariffCountValidationService.validateIsAvailableTariffsLimit(tariffValidationState);
        this.tariffCountValidationService.validateTariffLimitPerUserAccount(tariffValidationState);
        // this.tariffCountValidationService.validateWorkshopTariff(tariffValidationState);
        this.tariffCountValidationService.validateMaxTariffLimit(tariffValidationState);
        this.tariffCountValidationService.validateInLimitCounter(count, tariffValidationState);
    };
    PreferredTariffValidationService.ngInjectableDef = i0.defineInjectable({ factory: function PreferredTariffValidationService_Factory() { return new PreferredTariffValidationService(i0.inject(i1.Store), i0.inject(i2.LoginService), i0.inject(i3.TariffCountValidationService)); }, token: PreferredTariffValidationService, providedIn: "root" });
    return PreferredTariffValidationService;
}());
export { PreferredTariffValidationService };
