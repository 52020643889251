/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tariff-type/tariff-type.component.ngfactory";
import * as i3 from "./tariff-type/tariff-type.component";
import * as i4 from "./package-type/package-type.component.ngfactory";
import * as i5 from "../../services/package.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../services/booking.service";
import * as i8 from "../../../../shared/gtm/gtmService";
import * as i9 from "../../services/package-validation.service";
import * as i10 from "./package-type/package-type.component";
import * as i11 from "@ngrx/effects";
import * as i12 from "@angular/common";
import * as i13 from "./product.component";
var styles_ProductComponent = [i0.styles];
var RenderType_ProductComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductComponent, data: {} });
export { RenderType_ProductComponent as RenderType_ProductComponent };
function View_ProductComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tariff-type", [], null, null, null, i2.View_TariffTypeComponent_0, i2.RenderType_TariffTypeComponent)), i1.ɵdid(2, 114688, null, 0, i3.TariffTypeComponent, [], { selectedExhibition: [0, "selectedExhibition"], exhibitionSettings: [1, "exhibitionSettings"], productSelectionViewModel: [2, "productSelectionViewModel"], tariffStatusViewModel: [3, "tariffStatusViewModel"], workshopStatusViewModel: [4, "workshopStatusViewModel"], bookingStatusViewModel: [5, "bookingStatusViewModel"], productGroup: [6, "productGroup"], tariffType: [7, "tariffType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedExhibition; var currVal_1 = _co.exhibitionSettings; var currVal_2 = _co.productSelectionViewModel; var currVal_3 = _co.productsStatusViewModel.tariffStatus; var currVal_4 = _co.productsStatusViewModel.workshopStatus; var currVal_5 = _co.productsStatusViewModel.bookingStatus; var currVal_6 = _co.productGroup; var currVal_7 = _co.product.tariffType; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_ProductComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-package-type", [], null, null, null, i4.View_PackageTypeComponent_0, i4.RenderType_PackageTypeComponent)), i1.ɵprd(512, null, i5.PackageService, i5.PackageService, [i6.Store, i7.BookingService, i8.GtmService]), i1.ɵprd(131584, null, i9.PackageValidationService, i9.PackageValidationService, []), i1.ɵdid(4, 770048, null, 0, i10.PackageTypeComponent, [i11.Actions, i5.PackageService, i9.PackageValidationService], { selectedExhibition: [0, "selectedExhibition"], exhibitionSettings: [1, "exhibitionSettings"], productSelectionViewModel: [2, "productSelectionViewModel"], tariffStatusViewModel: [3, "tariffStatusViewModel"], workshopStatusViewModel: [4, "workshopStatusViewModel"], packageStatusViewModel: [5, "packageStatusViewModel"], bookingStatusViewModel: [6, "bookingStatusViewModel"], productGroup: [7, "productGroup"], packageType: [8, "packageType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedExhibition; var currVal_1 = _co.exhibitionSettings; var currVal_2 = _co.productSelectionViewModel; var currVal_3 = _co.productsStatusViewModel.tariffStatus; var currVal_4 = _co.productsStatusViewModel.workshopStatus; var currVal_5 = _co.productsStatusViewModel.packageStatus; var currVal_6 = _co.productsStatusViewModel.bookingStatus; var currVal_7 = _co.productGroup; var currVal_8 = _co.product.packageType; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_ProductComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductComponent_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.product.productType === _co.ProductType.Tariff); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.product.productType === _co.ProductType.Package); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product", [], null, null, null, View_ProductComponent_0, RenderType_ProductComponent)), i1.ɵdid(1, 114688, null, 0, i13.ProductComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductComponentNgFactory = i1.ɵccf("app-product", i13.ProductComponent, View_ProductComponent_Host_0, { selectedExhibition: "selectedExhibition", exhibitionSettings: "exhibitionSettings", productSelectionViewModel: "productSelectionViewModel", productsStatusViewModel: "productsStatusViewModel", productGroup: "productGroup", product: "product" }, {}, []);
export { ProductComponentNgFactory as ProductComponentNgFactory };
