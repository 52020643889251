import { RouterModule } from "@angular/router";
import { ChangePasswordComponent } from "./_pages/change-password/change-password.component";
import { DataProtectionPageComponent } from "./_pages/data-protection-page/data-protection-page.component";
import { EventPassedComponent } from "./event-passed/event-passed.component";
import { EventSeriesComponent } from "./_pages/event-series/event-series.component";
import { ForgottenPasswordComponent } from "./_pages/forgotten-password/forgotten-password.component";
import { FourOFourComponent } from "./_pages/four-o-four/four-o-four.component";
import { HomeComponent } from "./_pages/home/home.component";
import { LoginGuardAdmin } from "./shared/route-guards/admin-permission-guard";
import { LoginGuardSignedOut } from "./shared/route-guards/user-logout-guard";
import { LoginGuardUser } from "./shared/route-guards/user-permission-guard";
import { LoginPageComponent } from "./_pages/login-page/login-page.component";
import { LogoutComponent } from "./logout/logout.component";
import { MaintenencePageComponent } from "./_pages/maintenence-page/maintenence-page.component";
import { MaintenancePageGuard } from "./shared/route-guards/maintenance-page-guard";
import { NewAccountComponent } from "./_pages/new-account/new-account.component";
import { PaymentLandingPageComponent } from "./_pages/web-shop-confirmation/payment-landing-page/payment-landing-page.component";
import { PressNewsDetailComponent } from "./_pages/press-news-detail/press-news-detail.component";
import { ProfileComponent } from "./_pages/profile/profile.component";
import { ResetEmailComponent } from "./_pages/reset-email/reset-email.component";
import { ResetPasswordComponent } from "./_pages/reset-password/reset-password.component";
import { StepPermissionGuard } from "./shared/route-guards/step-permission-guard";
import { TranslationsComponent } from "./_pages/translations/translations.component";
import { WidgetAdminComponent } from "./_pages/widget-admin/widget-admin.component";
import { VerifyEmailComponent } from "./_pages/verify-email/verify-email.component";
import { WebSelfRegistrationComponent } from "./web-self-registration/web-self-registration.component";
import { WebShopBuyerQuestionnaireComponent } from "./web-shop-buyer-questionnaire/web-shop-buyer-questionnaire.component";
import { WebShopComponent } from "./web-shop/web-shop.component";
import { WebShopConfirmationComponent } from "./_pages/web-shop-confirmation/web-shop-confirmation.component";
import { WebShopDownloadComponent } from "./_pages/web-shop-download/web-shop-download.component";
import { WebShopInvoiceComponent } from "./_pages/web-shop-invoice/web-shop-invoice.component";
import { WebShopLegitimationComponent } from "./_pages/web-shop-legitimation/web-shop-legitimation.component";
import { WebShopMenuComponent } from "./_pages/web-shop-menu/web-shop-menu.component";
import { WebShopPersonalizeComponent } from "./_pages/web-shop-personalize/web-shop-personalize.component";
import { WebShopRecipeComponent } from "./_pages/webshop-recipe/webshop-recipe.component";
import { WebShopWorkshopComponent } from "./_pages/web-shop-workshop/web-shop-workshop.component";
import { WebShopProductSelectionComponent } from '@products/pages/web-shop-product-selection/web-shop-product-selection.component';
import { SelfRegistrationProductSelectionComponent } from '@products/pages/self-registration-product-selection/self-registration-product-selection.component';
var ɵ0 = { type: "login" };
var appRoutes = [
    {
        path: "",
        component: HomeComponent,
        pathMatch: "full",
        canActivate: [MaintenancePageGuard]
    },
    {
        path: "translations",
        component: TranslationsComponent,
        canActivate: [LoginGuardAdmin],
        pathMatch: "full"
    },
    {
        path: "widget-admin",
        component: WidgetAdminComponent,
        canActivate: [LoginGuardAdmin],
        pathMatch: "full"
    },
    {
        path: "data-protection",
        component: DataProtectionPageComponent,
        pathMatch: "full"
    },
    {
        path: "translations/data-protection",
        component: DataProtectionPageComponent,
        canActivate: [LoginGuardAdmin],
        pathMatch: "full"
    },
    {
        path: "data-protection/:id",
        component: DataProtectionPageComponent,
        pathMatch: "full"
    },
    {
        path: "translations/data-protection/:id",
        component: DataProtectionPageComponent,
        canActivate: [LoginGuardAdmin],
        pathMatch: "full"
    },
    {
        path: "admin",
        loadChildren: "../admin/admin.module#AdminModule",
        canActivate: [LoginGuardAdmin]
    },
    {
        path: "login",
        component: LoginPageComponent,
        data: ɵ0,
        pathMatch: "full",
        canActivate: [LoginGuardSignedOut, MaintenancePageGuard]
    },
    {
        path: "logout",
        component: LogoutComponent,
        pathMatch: "full"
    },
    {
        path: "forgotten",
        component: ForgottenPasswordComponent,
        pathMatch: "full",
        canActivate: [LoginGuardSignedOut, MaintenancePageGuard]
    },
    {
        path: "maintenance",
        component: MaintenencePageComponent,
        pathMatch: "full"
    },
    {
        path: "press-news/:id",
        component: PressNewsDetailComponent,
        pathMatch: "full" /* ,
        canActivate: [LoginGuardUser] */
    },
    {
        path: "webshop-download/:id",
        component: WebShopDownloadComponent,
        pathMatch: "full"
    },
    {
        path: "webshop/:id",
        component: WebShopComponent,
        canActivate: [MaintenancePageGuard],
        children: [
            // {
            //   path: "tickets",
            //   component: WebShopTicketsComponent,
            //   canActivate: [StepPermissionGuard, MaintenancePageGuard]
            // },
            {
                path: 'tickets',
                component: WebShopProductSelectionComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard],
            },
            {
                path: "workshop",
                component: WebShopWorkshopComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "menu",
                component: WebShopMenuComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "personal",
                component: WebShopPersonalizeComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "legitimation",
                component: WebShopLegitimationComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "confirmation",
                component: WebShopConfirmationComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "recipe",
                component: WebShopRecipeComponent,
                canActivate: [StepPermissionGuard, MaintenancePageGuard]
            },
            {
                path: "confirmation/:response",
                component: PaymentLandingPageComponent /* ,
                canActivate: [StepPermissionGuard] */
            },
            {
                path: "invoice",
                component: WebShopInvoiceComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "is-over",
                component: EventPassedComponent
            }
        ]
    },
    {
        path: "series/:id",
        component: EventSeriesComponent,
        pathMatch: "full"
    },
    {
        path: "self-registration/:id",
        component: WebSelfRegistrationComponent,
        children: [
            {
                path: "tickets",
                component: SelfRegistrationProductSelectionComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "workshop",
                component: WebShopWorkshopComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "menu",
                component: WebShopMenuComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "personal",
                component: WebShopPersonalizeComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "questionnaire",
                component: WebShopBuyerQuestionnaireComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "legitimation",
                component: WebShopLegitimationComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "confirmation",
                component: WebShopConfirmationComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "recipe",
                component: WebShopRecipeComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "confirmation/:response",
                component: PaymentLandingPageComponent /* ,
                canActivate: [StepPermissionGuard] */
            },
            {
                path: "invoice",
                component: WebShopInvoiceComponent,
                canActivate: [StepPermissionGuard]
            },
            {
                path: "is-over",
                component: EventPassedComponent
            }
        ]
    },
    {
        path: "profile",
        component: ProfileComponent,
        pathMatch: "full",
        canActivate: [LoginGuardUser]
    },
    {
        path: "new-account",
        component: NewAccountComponent,
        pathMatch: "full"
    },
    {
        path: "reset-password/:hash",
        component: ResetPasswordComponent,
        pathMatch: "full"
    },
    {
        path: "email-change/:hash",
        component: ResetEmailComponent,
        pathMatch: "full"
    },
    {
        path: "change-password/:hash",
        component: ChangePasswordComponent,
        pathMatch: "full"
    },
    {
        path: "email-verify/:hash",
        component: VerifyEmailComponent,
        pathMatch: "full"
    },
    {
        path: "widget",
        loadChildren: "../widget/widget.module#WidgetModule"
    },
    // 404 page
    {
        path: "404",
        component: FourOFourComponent
    },
    {
        path: "**",
        component: FourOFourComponent
    }
];
export var Routing = RouterModule.forRoot(appRoutes);
export { ɵ0 };
