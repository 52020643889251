import { OnDestroy, OnInit } from '@angular/core';
import { DownloadTicketById } from '@store/user/user.actions';
import { getSelectedExhibition } from '@app/app.reducer';
import { combineLatest, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { HelperService } from '@shared/services-with-reducers/helpers/helper.service';
import { UserService } from '@shared/services-with-reducers/user/user.service';
var TicketsDownloadComponent = /** @class */ (function () {
    function TicketsDownloadComponent(store, userService, helperService) {
        this.store = store;
        this.userService = userService;
        this.helperService = helperService;
        this.modalWindowOpen = false;
        this.showLoader = false;
        this.isSingleTicketRefund = false;
        this.isParkingTicketRefund = false;
        this.isPackageRefund = false;
        this.isMobile = false;
        this.eventWithTickets = [];
        this.subscriptions = new Subscription();
        this.isMobile = this.helperService.isMobile();
    }
    TicketsDownloadComponent.prototype.ngOnInit = function () {
        this.getTicketHistoryByUser();
    };
    TicketsDownloadComponent.prototype.getTicketHistoryByUser = function () {
        var _this = this;
        this.subscriptions.add(combineLatest([
            this.userService.getTicketsByUser(),
            this.store.pipe(select(getSelectedExhibition))
        ])
            .subscribe(function (_a) {
            var tickets = _a[0], selectedExhibition = _a[1];
            _this.eventWithTickets = tickets.map(function (event) {
                event.eventCalendar = "" + environment.protocol + environment.webApiUrl + "/event/" + event.eventId + "/calendar" + event.eventId + ".ics";
                event.isAccordionOpen = !!selectedExhibition && event.eventId === selectedExhibition.id;
                return event;
            });
        }));
    };
    TicketsDownloadComponent.prototype.refundPackagePopup = function (orderId, packageIndex, packageEntryTickets) {
        this.refundPackageOrderId = orderId;
        this.refundPackageIndex = packageIndex;
        this.refundPackageEntryTickets = packageEntryTickets;
        this.isPackageRefund = true;
        this.modalWindowOpen = true;
    };
    TicketsDownloadComponent.prototype.refundPackage = function (isPackageRefund) {
        var _this = this;
        this.modalWindowOpen = false;
        if (isPackageRefund) {
            this.showLoader = true;
            var refundPackageBody = {
                reason: 'Refund from user profile',
                ids: []
            };
            this.userService.refundPackage(this.refundPackageOrderId, this.refundPackageIndex, refundPackageBody).subscribe(function () {
                // After package refund, call back ticket history
                _this.getTicketHistoryByUser();
                _this.showLoader = false;
            }, function () {
                // if there is an error in order package cancellation, loader should stop his pittyful existence
                _this.showLoader = false;
            });
        }
        this.isPackageRefund = false;
        this.refundPackageOrderId = null;
    };
    TicketsDownloadComponent.prototype.refundOrderPopup = function (orderId) {
        this.refundOrderId = orderId;
        this.modalWindowOpen = true;
    };
    TicketsDownloadComponent.prototype.refundOrder = function (isCancelOrder) {
        var _this = this;
        this.modalWindowOpen = false;
        // if on modal window user press affirmative answer then the logic is as below
        if (isCancelOrder) {
            this.showLoader = true;
            this.userService.refundOrder(this.refundOrderId).subscribe(function () {
                // After order refund, call back ticket history
                _this.getTicketHistoryByUser();
                _this.showLoader = false;
            }, function () {
                // if there is an error in order cancellation, loader should stop his pittyful existence
                _this.showLoader = false;
            });
        }
        this.refundOrderId = null;
    };
    TicketsDownloadComponent.prototype.refundTicketPopup = function (orderId, ticketId) {
        this.refundOrderId = orderId;
        this.refundTicketId = ticketId;
        this.isSingleTicketRefund = true;
        this.modalWindowOpen = true;
    };
    TicketsDownloadComponent.prototype.refundTicket = function (isRefundTicket) {
        var _this = this;
        this.modalWindowOpen = false;
        // if on modal window user press affirmative answer then the logic is as below
        if (isRefundTicket) {
            this.showLoader = true;
            this.userService.refundSingleTicket(this.refundOrderId, this.refundTicketId).subscribe(function () {
                // After every ticket refund, call back ticket history
                _this.getTicketHistoryByUser();
                _this.showLoader = false;
            }, function () {
                // if there is an error in ticket cancellation, loader should stop his pittyful existence
                _this.showLoader = false;
            });
        }
        this.isSingleTicketRefund = false;
        this.refundOrderId = null;
        this.refundTicketId = null;
    };
    TicketsDownloadComponent.prototype.refundParkingPopup = function (orderId, ticketId) {
        this.refundOrderId = orderId;
        this.refundTicketId = ticketId;
        this.isSingleTicketRefund = true;
        this.isParkingTicketRefund = true;
        this.modalWindowOpen = true;
    };
    TicketsDownloadComponent.prototype.refundParkingTicket = function (isRefundTicket) {
        var _this = this;
        this.modalWindowOpen = false;
        // if on modal window user press affirmative answer then the logic is as below
        if (isRefundTicket) {
            this.showLoader = true;
            this.userService.refundParkingTicket(this.refundOrderId, this.refundTicketId).subscribe(function () {
                // After parking ticket refund, call back ticket history
                _this.getTicketHistoryByUser();
                _this.showLoader = false;
            }, function () {
                // if there is an error in ticket cancellation, loader should stop his pittyful existence
                _this.showLoader = false;
            });
        }
        this.isSingleTicketRefund = false;
        this.isParkingTicketRefund = false;
        this.refundOrderId = null;
        this.refundTicketId = null;
    };
    TicketsDownloadComponent.prototype.downloadTicket = function (id, type, ticketId, packageIndex) {
        this.store.dispatch(new DownloadTicketById({ id: id, type: type, ticketId: ticketId, packageIndex: packageIndex }));
    };
    TicketsDownloadComponent.prototype.closeAllTicketsExcept = function (ticket) {
        this.eventWithTickets.forEach(function (event) {
            event.orders.forEach(function (order) {
                order.packages.forEach(function (orderPackage) {
                    orderPackage.entryTickets.forEach(function (packageTicket) {
                        packageTicket.expanded = false;
                        packageTicket.changeDateExpanded = false;
                    });
                });
                order.entryTickets.forEach(function (ticket) {
                    ticket.expanded = false;
                    ticket.changeDateExpanded = false;
                });
            });
        });
        ticket.expanded = ticket.expanded !== true;
    };
    TicketsDownloadComponent.prototype.closeAllTicketsDayChangeExcept = function (ticket) {
        this.eventWithTickets.forEach(function (event) {
            event.orders.forEach(function (order) {
                order.entryTickets.forEach(function (ticket) {
                    ticket.expanded = false;
                    ticket.changeDateExpanded = false;
                });
            });
        });
        ticket.changeDateExpanded = ticket.changeDateExpanded !== true;
    };
    TicketsDownloadComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return TicketsDownloadComponent;
}());
export { TicketsDownloadComponent };
