<div class="page-padding-wrapper">
  <div class="basic-header black" *ngIf="response === 'fail'">
    <h2 class="basic-title" data-translation="payment.fail.title">
      {{ 'payment.fail.title' | translate | hideTranslation }}
    </h2>

    <span data-translation="payment.fail.subtitle">
      {{ 'payment.fail.subtitle' | translate | hideTranslation }}
    </span>
  </div>

  <div class="basic-header black" *ngIf="response === 'error'">
    <h2 class="basic-title" data-translation="payment.error.title">
      {{ 'payment.error.title' | translate | hideTranslation }}
    </h2>

    <span
      *ngIf="translatedErrorMessage && translatedErrorMessage.status !== 409"
      [attr.data-translation]="'response.' + translatedErrorMessage.key"
    >
      {{ translatedErrorMessage.message }}
    </span>

    <span *ngIf="!translatedErrorMessage" data-translation="payment.error.subtitle">
      {{ 'payment.error.subtitle' | translate | hideTranslation }}
    </span>
  </div>

  <div class="payment-button-wrapper">
    <button class="button back-button" type="button" (click)="back()" data-translation="step-navigation.back-button">
      <span>
        {{ 'step-navigation.back-button' | translate | hideTranslation }}
      </span>
    </button>
  </div>
</div>
