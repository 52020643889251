import { Action } from '@ngrx/store';
import { AddAvailableTariffs, AvailableTariffs } from '@products/models/tariff-status.model';

export enum ActionTypes {
  GET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS = '[Product Selection] Get user account tariffs limit status',
  SET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS = '[Tariff Status API] Set user account tariffs limit status',
  SET_AVAILABLE_TARIFFS_STATUS = '[Load Products API/Booking API] Set available tariffs status',
  SET_RELEASED_VOUCHER_AVAILABLE_TARIFFS_STATUS = '[Tariff Status] Set released voucher available tariffs status',
  SET_IS_TARIFF_LOADING_STATUS = '[Tariff Status] Set is tariff loading status',
  SET_IS_CREATE_CONTINGENT_RESERVATION_LOADING_STATUS = '[Tariff Status] Set is create contingent reservation loading status',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class GetUserAccountTariffsLimitStatus implements Action {
  readonly type = ActionTypes.GET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS;
  constructor(public payload: number) {}
}

export class SetUserAccountTariffsLimitStatus implements Action {
  readonly type = ActionTypes.SET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS;
  constructor(public payload: number) {}
}

export class SetAvailableTariffsStatus implements Action {
  readonly type = ActionTypes.SET_AVAILABLE_TARIFFS_STATUS;
  constructor(public payload: AvailableTariffs) {}
}

export class SetReleasedVoucherAvailableTariffStatus implements Action {
  readonly type = ActionTypes.SET_RELEASED_VOUCHER_AVAILABLE_TARIFFS_STATUS;
  constructor(public payload: AddAvailableTariffs) {}
}

export class SetIsTariffLoadingStatus implements Action {
  readonly type = ActionTypes.SET_IS_TARIFF_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export class SetIsCreateContingentReservationLoadingStatus implements Action {
  readonly type = ActionTypes.SET_IS_CREATE_CONTINGENT_RESERVATION_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export class ResetTariffStatus implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | GetUserAccountTariffsLimitStatus
  | SetUserAccountTariffsLimitStatus
  | SetAvailableTariffsStatus
  | SetReleasedVoucherAvailableTariffStatus
  | SetIsTariffLoadingStatus
  | SetIsCreateContingentReservationLoadingStatus
  | ResetTariffStatus;
