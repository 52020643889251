import { ArticleType } from '@products/models/article.model';
import {
  BookedPackageTotalPriceGroupedByPackageIndex,
  BookedPackageTotalPriceGroupedByPackageNumber
} from '@products/models/booking.model';
import { PackageType } from '@products/models/package.model';
import { ProductType } from '@products/models/products.model';
import { AvailableTariffs } from '@products/models/tariff-status.model';
import { TariffType, TicketSendingOptions } from '@products/models/tariff.model';
import { WorkshopAvailableSeats } from '@products/models/workshop-status.model';

export enum ProductTypeKeys {
  TariffType = 'tariffType',
  PackageType = 'packageType',
  ArticleType = 'articleType'
}

export interface SelectionState {
  list: ProductList;
  ticketSendingOptions: SendingOptionModel[];
}

export interface SendingOptionModel {
  value: TicketSendingOptions;
  isSelected: boolean;
  isVisible: boolean;
  isEnabled: boolean;
  isBasic: boolean;
  isBuyer: boolean;
}

export type ProductListItem = VoucherProducts | ProductGroup | Product;

export type ProductList = { [key: string]: ProductListItem };

export type Products = Product[];

export type VoucherProducts = Product[];

export interface ProductGroup {
  groupId?: number;
  groupName?: string;
  groupDescription?: string;
  products: Products;
  expanded: boolean;
}

export type MapProductTypeKeys = {
  [ProductTypeKeys.TariffType]: TariffType;
  [ProductTypeKeys.ArticleType]: ArticleType;
  [ProductTypeKeys.PackageType]: PackageType;
};

export type Product = {
  [productTypeKey in ProductTypeKeys]?: MapProductTypeKeys[productTypeKey];
} & {
  productType: ProductType;
};

/******************
 * MAPPED PRODUCT
 ******************/
export interface MappedProduct {
  isVoucher: boolean;
  product: Product;
}

/*****************
 * COMPONENT DATA
 *****************/
export interface ProductSelectionViewModel {
  isRedeemedVoucherProductInProductSelectionList: boolean;
  isRedeemedAnonymousVoucherProductInProductSelectionList: boolean;
  notLoggedAndLoginMandatory: boolean;
  isSelfRegistration: boolean;
  showPromoCode: boolean;
  isVerifiedAccountRequired: boolean;
  isTranslationLoaded: boolean;
  dateToday: Date;
  isAccountVerified: boolean;
  isMobile: boolean;
}

export interface ProductSelectionTariffStatusViewModel {
  currentUserAccountTicketLimit: number;
  availableTariffs: AvailableTariffs;
  isTariffLoading: boolean;
}

export interface ProductSelectionPackageStatusViewModel {
  lastPackageIndex: number;
  isPackageLoading: boolean;
}

export interface ProductSelectionWorkshopStatusViewModel {
  availableSeats: WorkshopAvailableSeats;
  isWorkshopLoading: boolean;
  isWorkshopDetailModalOpen: boolean;
}

export interface ProductSelectionBookingStatusViewModel {
  bookedProductsCount: number;
  isBookedProductsCountValid: boolean;
  bookedProductsTotalPrice: number;
  bookedPromoCodeVouchersCount: number;
  bookedLimitedPromoCodeVouchersCount: number;
  bookedOnetimeVouchersCount: number;
  bookedPackagePricesGroupedByPackageNumber: BookedPackageTotalPriceGroupedByPackageNumber;
  bookedPackagePricesGroupedByPackageIndex: BookedPackageTotalPriceGroupedByPackageIndex;
}

export interface ProductSelectionProductsStatusViewModel {
  tariffStatus: ProductSelectionTariffStatusViewModel;
  packageStatus: ProductSelectionPackageStatusViewModel;
  workshopStatus: ProductSelectionWorkshopStatusViewModel;
  bookingStatus: ProductSelectionBookingStatusViewModel;
}

export interface ValidationState {
  isBookedProductsCountValid: boolean;
  areBookedContingentReservationsValid: boolean;
  areBookedParkingReservationsValid: boolean;
  isWorkshopsMandatoryValid: boolean;
  isWorkshopsZeroPriceMandatoryValid: boolean;
}

/**************
 * TYPE GUARDS
 **************/
export const isVoucherProducts = (listItem: ProductListItem): listItem is VoucherProducts => {
  const voucherProductsListItem = listItem as VoucherProducts;
  return !!voucherProductsListItem && !!voucherProductsListItem.length;
};

export const isProductGroup = (listItem: ProductListItem): listItem is ProductGroup => {
  const productGroupListItem = listItem as ProductGroup;
  return !!productGroupListItem && productGroupListItem.groupId !== undefined;
};

export const isProduct = (listItem: ProductListItem): listItem is Product => {
  const productListItem = listItem as Product;
  return !!productListItem && !!productListItem.productType;
};

export const isProductTypeTariff = (product: Product): boolean => {
  return product.productType === ProductType.Tariff && !!product.tariffType;
};

export const isProductTypePackage = (product: Product): boolean => {
  return product.productType === ProductType.Package && !!product.packageType;
};

export const isProductTypeArticle = (product: Product): boolean => {
  return product.productType === ProductType.Article && !!product.packageType;
};
