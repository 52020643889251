/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-ticket-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app/_pages/products/components/product-group/product-group.component.ngfactory";
import * as i3 from "../../../app/_pages/products/components/product-group/product-group.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../app/loader/loader.component.ngfactory";
import * as i7 from "../../../app/loader/loader.component";
import * as i8 from "../../../app/shared/pipes/hide-translation/hide-translation.pipe";
import * as i9 from "../../../app/banner/banner.component.ngfactory";
import * as i10 from "../../../app/banner/banner.component";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
import * as i13 from "../../../app/shared/services-with-reducers/customization/customization.service";
import * as i14 from "./widget-ticket-select.component";
import * as i15 from "../../../app/_pages/products/services/product-selection.service";
var styles_WidgetTicketSelectComponent = [i0.styles];
var RenderType_WidgetTicketSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetTicketSelectComponent, data: {} });
export { RenderType_WidgetTicketSelectComponent as RenderType_WidgetTicketSelectComponent };
function View_WidgetTicketSelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-product-group", [], null, null, null, i2.View_ProductGroupComponent_0, i2.RenderType_ProductGroupComponent)), i1.ɵdid(2, 114688, null, 0, i3.ProductGroupComponent, [], { selectedExhibition: [0, "selectedExhibition"], exhibitionSettings: [1, "exhibitionSettings"], productSelectionViewModel: [2, "productSelectionViewModel"], productsStatusViewModel: [3, "productsStatusViewModel"], productGroup: [4, "productGroup"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.parent.context.ngIf.selectedExhibition; var currVal_1 = _v.parent.parent.parent.parent.context.ngIf.exhibitionSettings; var currVal_2 = _v.parent.parent.parent.context.ngIf; var currVal_3 = _v.parent.parent.context.ngIf; var currVal_4 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_WidgetTicketSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WidgetTicketSelectComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !!_v.context.$implicit.products; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WidgetTicketSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WidgetTicketSelectComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.ngIf.productSelectionListProducts; var currVal_1 = _co.productSelectionListTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_WidgetTicketSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WidgetTicketSelectComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.productSelectionProductsStatusViewModel$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_WidgetTicketSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WidgetTicketSelectComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "ticket-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "feedback-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "button", [["class", "continue-button button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContinueClick($event, _v.context.ngIf.selectedExhibition.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "feedback-button-disabled": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["class", "continue-button-text"], ["data-translation", "step-navigation.continue-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.productSelectionViewModel$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = "continue-button button"; var currVal_2 = _ck(_v, 9, 0, _co.isButtonDisabled); _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("step-navigation.continue-button")))); _ck(_v, 11, 0, currVal_3); }); }
function View_WidgetTicketSelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i6.View_LoaderComponent_0, i6.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i7.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
export function View_WidgetTicketSelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-banner", [], null, null, null, i9.View_BannerComponent_0, i9.RenderType_BannerComponent)), i1.ɵdid(2, 245760, null, 0, i10.BannerComponent, [i11.Store, i12.ActivatedRoute, i13.CustomizationService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "page-content widget-product-selection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WidgetTicketSelectComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loader", 2]], null, 0, null, View_WidgetTicketSelectComponent_6))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.productSelectionProductsAndExhibitionState$)); var currVal_1 = i1.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_WidgetTicketSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-ticket-select", [], null, null, null, View_WidgetTicketSelectComponent_0, RenderType_WidgetTicketSelectComponent)), i1.ɵdid(1, 245760, null, 0, i14.WidgetTicketSelectComponent, [i11.Store, i15.ProductSelectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetTicketSelectComponentNgFactory = i1.ɵccf("app-widget-ticket-select", i14.WidgetTicketSelectComponent, View_WidgetTicketSelectComponent_Host_0, {}, {}, []);
export { WidgetTicketSelectComponentNgFactory as WidgetTicketSelectComponentNgFactory };
