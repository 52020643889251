/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tickets-widget-admin/tickets-widget-admin.component.ngfactory";
import * as i3 from "./tickets-widget-admin/tickets-widget-admin.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../shared/services-with-reducers/helpers/helper.service";
import * as i6 from "./widget-admin.service";
import * as i7 from "./banner-widget-admin/banner-widget-admin.component.ngfactory";
import * as i8 from "./banner-widget-admin/banner-widget-admin.component";
import * as i9 from "../../shared/services-with-reducers/widget/widget.service";
import * as i10 from "@angular/common";
import * as i11 from "../../loader/loader.component.ngfactory";
import * as i12 from "../../loader/loader.component";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../../../app-shared/navigation-tabs/navigation-tabs.component.ngfactory";
import * as i15 from "../../../app-shared/navigation-tabs/navigation-tabs.component";
import * as i16 from "./widget-admin.component";
import * as i17 from "@ngrx/store";
var styles_WidgetAdminComponent = [i0.styles];
var RenderType_WidgetAdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetAdminComponent, data: {} });
export { RenderType_WidgetAdminComponent as RenderType_WidgetAdminComponent };
function View_WidgetAdminComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tickets-widget-admin", [], null, null, null, i2.View_TicketsWidgetAdminComponent_0, i2.RenderType_TicketsWidgetAdminComponent)), i1.ɵdid(2, 245760, null, 0, i3.TicketsWidgetAdminComponent, [i4.FormBuilder, i5.HelperService, i6.WidgetAdminService], { eventId: [0, "eventId"], widgetSelectionTariffTypes: [1, "widgetSelectionTariffTypes"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventId; var currVal_1 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_WidgetAdminComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-banner-widget-admin-component", [], null, [[null, "createNewWidget"], [null, "updateWidget"], [null, "loadWidget"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("createNewWidget" === en)) {
        var pd_0 = (_co.createNewWidget($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateWidget" === en)) {
        var pd_1 = (_co.updateWidget($event) !== false);
        ad = (pd_1 && ad);
    } if (("loadWidget" === en)) {
        var pd_2 = (_co.loadWidgetSettings($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_BannerWidgetAdminComponent_0, i7.RenderType_BannerWidgetAdminComponent)), i1.ɵdid(2, 638976, null, 0, i8.BannerWidgetAdminComponent, [i4.FormBuilder, i6.WidgetAdminService, i9.WidgetService], { widgetSettings: [0, "widgetSettings"], eventWidgets: [1, "eventWidgets"], widgetSelectionTariffTypes: [2, "widgetSelectionTariffTypes"], loading: [3, "loading"] }, { createWidgetSettings: "createNewWidget", updateWidgetSettings: "updateWidget", loadWidget: "loadWidget" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.widgetSettings == null) ? null : _co.widgetSettings.bannerWidget); var currVal_1 = _co.eventWidgets; var currVal_2 = _v.parent.context.ngIf; var currVal_3 = _co.loading; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_WidgetAdminComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WidgetAdminComponent_2)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WidgetAdminComponent_3)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeTab === "ticketsWidget"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.activeTab === "bannerWidget"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_WidgetAdminComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i11.View_LoaderComponent_0, i11.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i12.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
export function View_WidgetAdminComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { iframe: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container-fluid h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "page-content h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "page-padding-wrapper h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h4", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-navigation-tabs", [["type", "normal"]], null, [[null, "activeTabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeTabChanged" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_NavigationTabsComponent_0, i14.RenderType_NavigationTabsComponent)), i1.ɵdid(8, 49152, null, 0, i15.NavigationTabsComponent, [], { tabs: [0, "tabs"], type: [1, "type"], activeTab: [2, "activeTab"] }, { activeTabChanged: "activeTabChanged" }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WidgetAdminComponent_1)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["isLoading", 2]], null, 0, null, View_WidgetAdminComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabs; var currVal_2 = "normal"; var currVal_3 = _co.activeTab; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.widgetSelectionTariffTypes$)); var currVal_5 = i1.ɵnov(_v, 12); _ck(_v, 10, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("widget.chooseWidgetTitle")); _ck(_v, 5, 0, currVal_0); }); }
export function View_WidgetAdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-admin", [], null, null, null, View_WidgetAdminComponent_0, RenderType_WidgetAdminComponent)), i1.ɵdid(1, 245760, null, 0, i16.WidgetAdminComponent, [i17.Store, i9.WidgetService, i6.WidgetAdminService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetAdminComponentNgFactory = i1.ɵccf("app-widget-admin", i16.WidgetAdminComponent, View_WidgetAdminComponent_Host_0, {}, {}, []);
export { WidgetAdminComponentNgFactory as WidgetAdminComponentNgFactory };
