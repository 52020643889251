import { Component, OnDestroy } from '@angular/core';
import {
  State,
  getExhibitionHistoryList,
  getLanguage,
  getLegitimationList,
  getProfile,
  getProfileBillingAddress
} from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetExhibitionHistoryList, GetListOfAllExhibitions } from '@store/exhibition/exhibition.actions';
import { ExhibitionHistoryListModel } from '@store/exhibition/exhibition.interface';
import { GetLegitimationList } from '@store/legitimation/legitimation.actions';
import { LegitimationListModel, LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { GetProfile, GetProfileBillingAddress } from '@store/user/user.actions';
import { UserProfileModel } from '@store/user/user.interface';
import { UserService } from '@store/user/user.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy {
  legitimationList$: Observable<LegitimationListModel[]>;
  profile$: Observable<UserProfileModel>;
  exhibitionHistoryList$: Observable<ExhibitionHistoryListModel[]>;
  lowLimit: number = 3;
  hiLimit: number = 10000;
  billingAddressPresent: boolean = false;
  isEmailVerified: boolean = true;
  isProfileLoaded: boolean = false;
  limits = {
    legitimation: this.lowLimit,
    ticket: this.lowLimit,
    exhibition: this.lowLimit
  };
  limitsTranslations = {
    legitimation: 'profile.toggle.more',
    ticket: 'profile.toggle.more',
    exhibition: 'profile.toggle.more'
  };
  readonly LegitimationStatus = LegitimationStatus;

  private subscriptions = new Subscription();

  constructor(
    private store: Store<State>,
    private userService: UserService,
    private statusBarService: StatusBarService,
    private translateService: TranslateService
  ) {
    this.legitimationList$ = this.store.pipe(select(getLegitimationList));
    this.profile$ = this.store.pipe(select(getProfile));
    this.exhibitionHistoryList$ = this.store.pipe(select(getExhibitionHistoryList));

    this.subscriptions.add(
      this.store
        .pipe(
          select(getLanguage),
          filter(lang => !!lang)
        )
        .subscribe(lang => this.store.dispatch(new GetListOfAllExhibitions(lang)))
    );

    this.store
      .pipe(
        select(getProfile),
        first(profile => !!profile)
      )
      .subscribe((user: UserProfileModel) => {
        this.store.dispatch(new GetProfileBillingAddress(user.id));
        this.store.dispatch(new GetLegitimationList(user.id));
      });

    this.subscriptions.add(
      this.store
        .pipe(
          select(getProfileBillingAddress),
          filter(profileBillingAddress => !!profileBillingAddress)
        )
        .subscribe(profileBillingAddress => (this.billingAddressPresent = !!profileBillingAddress.length))
    );

    this.profile$.pipe(filter(profile => !!profile)).subscribe(profile => {
      if (profile.hasOwnProperty('isEmailVerified')) {
        this.isEmailVerified = profile.isEmailVerified;
      }

      if (!this.isProfileLoaded) {
        this.isProfileLoaded = true;
        // always reload profile on refresh
        this.store.dispatch(new GetProfile(profile.id));
      } else {
        this.store.dispatch(new GetExhibitionHistoryList(profile.id));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  resendAccountVerification() {
    combineLatest([this.store.pipe(select(getLanguage)), this.store.pipe(select(getProfile))])
      .first(([language, profile]) => !!language && !!profile)
      .switchMap(([language]) =>
        this.userService
          .sendVerifyEmail(language)
          .switchMap(({ status }) =>
            this.translateService
              .get(`profile.verify-email.${status === 200 ? 'send' : 'notSend'}`)
              .map(translation => ({ translation, status: status === 200 ? 'success' : 'error' }))
          )
      )
      .subscribe(({ translation, status }) => {
        this.statusBarService.setStatus(translation, status);
      });
  }

  toggleList(section: 'ticket' | 'exhibition' | 'legitimation') {
    if (this.lowLimit === this.limits[section]) {
      this.limits[section] = this.hiLimit;
      this.limitsTranslations[section] = 'profile.toggle.less';
    } else {
      this.limits[section] = this.lowLimit;
      this.limitsTranslations[section] = 'profile.toggle.more';
    }
  }
}
