import { HttpClient, HttpParams } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { getDepartments, getExhibitionSettings, getOccupationalGroups, getProfessions, getTicketHolderInputsKeys, getTitles } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { isBookingProductTypePackage, isBookingProductTypeTariff } from '@products/models/booking.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { environment } from '@src/environments/environment';
import { prepareTicketHolderData } from '@store/customization/forms/tickets-holder-data';
import { HelperService } from '@store/helpers/helper.service';
import { getAllBookedProductsCount, getAllBookedTariffs, getAllHolderUuidsFromBookedProducts } from '@store/products/booking/booking.selectors';
import { SetActiveSlide } from '@store/products/holder/holder.actions';
import { getHolderUuids } from '@store/products/holder/holder.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import { RemoveForm, RemoveTicketHolderForm, SetAddressCheckbox, SetBuyerVisitorCheckbox, SetInputs, SetTicketHolderFormValidity } from '@store/step-forms/steps-forms.actions';
import { Subject, combineLatest, of } from 'rxjs';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i4 from "../../../shared/forms/forms.service";
var HolderService = /** @class */ (function () {
    function HolderService(http, store, helperService, formsService) {
        this.http = http;
        this.store = store;
        this.helperService = helperService;
        this.formsService = formsService;
        this.destroy$ = new Subject();
        this.PersonaliseFormsKeys = AppConstants.PersonaliseFormsKeys;
        this.personaliseFormKey = this.PersonaliseFormsKeys.ticketHolder[0];
        this.ticketHolder_ = this.PersonaliseFormsKeys.ticketHolder[1];
    }
    HolderService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    /**
     * Adds ticket holders inputs and sets its validity
     * @param holderUuids holder uuids to add inputs to
     */
    HolderService.prototype.addHoldersInputs = function (holderUuids) {
        var _this = this;
        combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getTitles)),
            this.store.pipe(select(getProfessions)),
            this.store.pipe(select(getDepartments)),
            this.store.pipe(select(getOccupationalGroups)),
            this.store.pipe(select(getAllBookedTariffs)),
            this.store.pipe(select(getSelectedSendingOption)),
            this.store.pipe(select(getAllBookedProductsCount))
        ])
            .first(function (_a) {
            var settings = _a[0], titles = _a[1], professions = _a[2], departments = _a[3], occupationalGroups = _a[4], allBookedTariffs = _a[5], selectedTicketSendingOptions = _a[6], allBookedProductsCount = _a[7];
            return !!settings &&
                !!titles &&
                !!professions &&
                !!departments &&
                !!occupationalGroups &&
                !!allBookedTariffs &&
                !!selectedTicketSendingOptions &&
                allBookedProductsCount !== null;
        })
            .subscribe(function (_a) {
            var settings = _a[0], titles = _a[1], professions = _a[2], departments = _a[3], occupationalGroups = _a[4], allBookedTariffs = _a[5], selectedTicketSendingOptions = _a[6], allBookedProductsCount = _a[7];
            // remove holderUuids of parking tickets, we don't add holder inputs for parking tickets
            var filteredHolderUuids = holderUuids.filter(function (uuid) {
                return allBookedTariffs.some(function (tariff) { return tariff.holderUuids.find(function (holderUuid) { return holderUuid === uuid; }) && !tariff.parkingReservations.length; });
            });
            filteredHolderUuids.forEach(function (holderUuid) {
                var ticketHolderWithUuid = _this.ticketHolder_ + holderUuid;
                var formInfo = [_this.personaliseFormKey, ticketHolderWithUuid];
                var formValid = false;
                // new forms to be created (create new ticket holder)
                var list = prepareTicketHolderData(settings, selectedTicketSendingOptions, titles, professions, departments, occupationalGroups);
                if (_this.helperService.isSelfregistration() ||
                    !settings.isTicketHolderVisible ||
                    (allBookedProductsCount === 1 && selectedTicketSendingOptions === TicketSendingOptions.MobilePerOwner)) {
                    formValid = true;
                }
                var anyFieldRequired = list.some(function (input) { return input.required && !input.hidden && !input.disabled; }) ||
                    selectedTicketSendingOptions === TicketSendingOptions.TicketRetrievalLink;
                formValid = !anyFieldRequired || formValid;
                var holderInputs = {
                    formInfo: formInfo,
                    holderUuid: holderUuid,
                    inputSet: {
                        rerender: false,
                        list: list
                    }
                };
                _this.store.dispatch(new SetInputs(holderInputs));
                _this.store.dispatch(new SetTicketHolderFormValidity({ formInfo: formInfo, valid: false }));
                _this.formsService.setFormValidity(formValid, null, formInfo);
            });
        });
    };
    /**
     * Gets all holderInputsKeys and compares it to input parameter holderUuids to remove inputs and its validations
     * from holderUuids which no longer exist
     * @param holderUuids holders uuids which are not being removed
     */
    HolderService.prototype.removeHoldersInputs = function (holderUuids) {
        var _this = this;
        this.store
            .pipe(select(getTicketHolderInputsKeys), first())
            .subscribe(function (ticketHolderInputsKeys) {
            var removedHolderInputsUuids = ticketHolderInputsKeys
                .map(function (key) { return key.replace(_this.ticketHolder_, ''); })
                .filter(function (uuid) { return !holderUuids.includes(uuid); });
            removedHolderInputsUuids.forEach(function (holderUuid) {
                var ticketHolderWithUuid = _this.ticketHolder_ + holderUuid;
                var formName = [_this.personaliseFormKey, ticketHolderWithUuid];
                // remove the form and its input sets
                _this.store.dispatch(new RemoveForm(formName));
                _this.store.dispatch(new RemoveTicketHolderForm(formName));
                _this.formsService.removeStepValidation(formName);
            });
            var isNonParkingTicketRemoved = !!removedHolderInputsUuids.length;
            if (isNonParkingTicketRemoved) {
                /* be sure after removing a ticket to start the carousel on position one
                otherwise it can happen that it wants start on slide which no longer exist */
                _this.store.dispatch(new SetActiveSlide(0));
                // User Story 3466: When we remove ticket if checked slide index is same as value we are decreasing to we uncheck it
                _this.store.dispatch(new SetAddressCheckbox({
                    checkedSlideIndex: null,
                    isAddressCopied: false
                }));
                _this.store.dispatch(new SetBuyerVisitorCheckbox({
                    buyerVisitorCheckedSlideIndex: null,
                    isBuyerVisitorChecked: false,
                    showVisitorQuestionnaire: false
                }));
                _this.formsService.removeAllStepValidationFeedbacks(_this.PersonaliseFormsKeys.visitorQuestionnaire);
                _this.formsService.setFormValidity(true, null, _this.PersonaliseFormsKeys.visitorQuestionnaire);
            }
        });
    };
    HolderService.prototype.getTariffHolderQuestionnaire$ = function (eventId, ticketPersonIds) {
        var params = new HttpParams();
        params = params.append('sr', this.helperService.isSelfregistration());
        ticketPersonIds.forEach(function (ticketPersonId) { return (params = params.append('ticketPersonIds', "" + ticketPersonId)); });
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/visitor-questionnaire", {
            params: params
        });
    };
    HolderService.prototype.calculateNumberOfHolderUuidsToCreate$ = function (bookingProductTypes, bookedHolderUuids) {
        var numberOfHolderBookings = 0;
        bookingProductTypes.forEach(function (bookingProductType) {
            if (isBookingProductTypeTariff(bookingProductType)) {
                bookingProductType.tariffs.forEach(function (bookedTariff) {
                    if (!bookedTariff.parkingReservations.length) {
                        numberOfHolderBookings += bookedTariff.count;
                    }
                });
            }
            else if (isBookingProductTypePackage(bookingProductType)) {
                bookingProductType.productTypes.forEach(function (bookingPackageProductType) {
                    if (isBookingProductTypeTariff(bookingPackageProductType)) {
                        bookingPackageProductType.tariffs.forEach(function (bookingPackageTariff) {
                            if (!bookingPackageTariff.parkingReservations.length) {
                                numberOfHolderBookings += bookingPackageTariff.count;
                            }
                        });
                    }
                });
            }
        });
        var bookingHolderDifference = numberOfHolderBookings - bookedHolderUuids.length;
        var bookingHoldersToCreate = bookingHolderDifference > 0 ? bookingHolderDifference : 0;
        return of(bookingHoldersToCreate);
    };
    /**
     * Adds or removes product inputs
     * @returns Object of booked holder uuids and was product removed
     */
    HolderService.prototype.addOrRemoveProductHoldersInputs$ = function () {
        var _this = this;
        return combineLatest([
            this.store.pipe(select(getAllHolderUuidsFromBookedProducts)),
            this.store.pipe(select(getHolderUuids))
        ])
            .pipe(first())
            .map(function (_a) {
            var bookedHolderUuids = _a[0], holderUuids = _a[1];
            var isProductBookingRemoved = bookedHolderUuids.length < holderUuids.length;
            var holderUuidsToAddOrRemove = isProductBookingRemoved
                ? holderUuids.filter(function (holderUuid) { return bookedHolderUuids.includes(holderUuid); })
                : bookedHolderUuids.filter(function (bookedHolderUuid) { return !holderUuids.includes(bookedHolderUuid); });
            isProductBookingRemoved
                ? _this.removeHoldersInputs(holderUuidsToAddOrRemove)
                : _this.addHoldersInputs(holderUuidsToAddOrRemove);
            return { holderUuidsToAddOrRemove: holderUuidsToAddOrRemove, isProductBookingRemoved: isProductBookingRemoved };
        });
    };
    HolderService.prototype.getHolderDataForSaveOrder = function (ticketHolderInputSets, ticketUuid, buyerInfoEmail) {
        var inputMap = {};
        var ticketHolderIndex = ticketHolderInputSets.findIndex(function (formInputsGroup) { return formInputsGroup.holderUuid === ticketUuid; });
        if (ticketHolderIndex === AppConstants.INDEX_NOT_FOUND) {
            return null;
        }
        var ticketHolderInputSet = ticketHolderInputSets[ticketHolderIndex];
        if (ticketHolderInputSet && ticketHolderInputSet.hasOwnProperty('inputSet')) {
            ticketHolderInputSet.inputSet.list.forEach(function (input) {
                var key = input.key, value = input.value, options = input.options;
                // assign input value to the ticketOwner model
                inputMap[key] = value;
                // if ticket holder email is not mandatory and missing we use buyers email
                // in case of send to owner, we need to assign the value from option number zero, as sets of checkboxes do not have any common value
                if (key === 'email' && !value) {
                    inputMap[key] = input.value = buyerInfoEmail;
                }
                else if (key === 'sendtoowner' && options && options.length) {
                    inputMap[key] = options[0].value;
                }
            });
            inputMap['ticketIndex'] = ticketHolderIndex;
            return inputMap;
        }
        return null;
    };
    HolderService.prototype.getTicketByHolder = function (hash) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/person/ticket-by-holder/" + hash);
    };
    HolderService.prototype.postTicketHolderForm = function (data) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/person/visitors-form" + (this.helperService.isSelfregistration() ? '?sr=true' : ''), data);
    };
    HolderService.prototype.downloadTicket = function (hash) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/person/download-ticket/" + hash, {
            responseType: 'blob'
        });
    };
    HolderService.prototype.downloadMobileTicket = function (hash) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/person/download-mobile-ticket/" + hash, {
            responseType: 'blob'
        });
    };
    HolderService.prototype.downloadPassBook = function (hash) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/person/download-passbook/" + hash, {
            responseType: 'blob'
        });
    };
    HolderService.prototype.getVisitorFieldsForPersonalization = function (ticketId) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId);
    };
    HolderService.prototype.postVisitorFieldsFromPersonalization = function (ticketId, data) {
        return this.http.post("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId, data);
    };
    HolderService.ngInjectableDef = i0.defineInjectable({ factory: function HolderService_Factory() { return new HolderService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i3.HelperService), i0.inject(i4.FormsService)); }, token: HolderService, providedIn: "root" });
    return HolderService;
}());
export { HolderService };
