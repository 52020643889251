import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  combineLatest,
  Observable,
  Subject
} from 'rxjs';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, skip, switchMap, takeUntil } from 'rxjs/operators';

import { FormsService } from '../../shared/forms/forms.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

import { AppConstants } from '../../../app/shared/app-constants';
import {
  getExhibitionSettings,
  getLanguage,
  getPrivacyInput,
  getProfile,
  getSelectedExhibitionId,
  getSpinnerValue,
  State
} from '../../app.reducer';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { prepareDisclaimerCheckboxes } from '../../shared/services-with-reducers/customization/forms/disclaimer-checkboxes-data';
import { PartialResetReducer, SelectAction } from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import { SetSpinnerValue } from '../../shared/services-with-reducers/helpers/helper.actions';
import { SetClaimedTicketHash, SetTicketClaimedHashValid } from '../../shared/services-with-reducers/products/booking/booking.actions';
import { getClaimedTicketHash, getClaimedTicketHashValid } from '../../shared/services-with-reducers/products/booking/booking.selectors';
import {
  DownloadMobileTicket,
  DownloadPassBook,
  DownloadTicket,
  GetQuestionnaireInputs,
  LoadTicketHolder,
  PostTicketHolderForm,
  SetPostTicketHolderFormResult,
  SetTicketHolder
} from '../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolder, getTicketHolderFormSubmitResult, getTicketHolderQuestionnaireInputs } from '../../shared/services-with-reducers/products/holder/holder.selectors';
import { FormInputsPayloadModel, InputsListModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import { SetInputs } from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import { TicketHolder, TicketHolderForm } from '../products/models/holder.model';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-download',
  templateUrl: './web-shop-download.component.html',
  styleUrls: ['./web-shop-download.component.scss']
})
export class WebShopDownloadComponent implements OnInit, OnDestroy {
  spinnerValue$: Observable<boolean>;

  allFormsValid: boolean = false;
  hasValidTicketHash: boolean = false;
  isMobile: boolean = false;
  loading: boolean = true;
  isQuestionnaireRequired: boolean = true;
  eventId: number;
  downloadTicketButton: string = '';

  checkboxesInputs: InputBase<any>[];
  checkboxesForm: FormGroup;
  checkboxesFormsActionName = ['ticket-download-policy'];
  feedbackControlObject: Object = {};
  feedbackMessages = [{ translate: false, label: 'helo' }];
  exhibitorSettings: ExhibitionSettingModel;

  private childForms: {
    ticketholder?: any;
    questionnaire?: any;
    policy?: any;
  } = {};
  private validationControll = {
    policy: false,
    questionnaire: false,
    ticketholder: false
  };
  private readonly unsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private location: Location,
    private customizationService: CustomizationService,
    private store: Store<State>,
    private translate: TranslateService,
    private helperService: HelperService
  ) {
    this.isMobile = this.helperService.isMobile();

    // small reset on page reload
    this.store.dispatch(new PartialResetReducer());
    this.store.dispatch(new SetTicketHolder(null));
    this.store.dispatch(new SetSpinnerValue(false));

    combineLatest([
      this.route.params,
      this.route.queryParams
    ])
    .pipe(
      filter(([params]) => !!params),
      takeUntil(this.unsubscribe)
    )
    .subscribe(([params, queryParams]) => {
      this.store.dispatch(new SelectAction(params.id));
      this.eventId = params.id;

      if (queryParams.t) {
        this.loading = true;

        // set validity of hash to null as we cannot say now whether it is valid or not
        this.store.dispatch(new SetTicketClaimedHashValid(null));
        // load the ticket holder based on the hash comming in URL
        this.store.dispatch(new LoadTicketHolder(queryParams.t));
        this.store.dispatch(new SetClaimedTicketHash(queryParams.t));

        this.location.replaceState('/webshop-download/' + params.id);
      }
    });

    // ensure we have right translation for privacy checkbox

    this.store.pipe(
      select(getExhibitionSettings),
      first(settings => !!settings),
      switchMap(settings => {
        this.exhibitorSettings = settings;
        this.customizationService.setStyles();

        return this.translate.stream(
          [
            'personalize.privacy-link',
            'personalize.privacy-link.text',
            'personalize.privacy-optional',
            'personalize.privacy-optional.text',
          ]
        )// stream function is triggered again on language change
      }),
      takeUntil(this.unsubscribe)
    )
    .subscribe(termsTranslations => {
      const confirmationCheckboxes = prepareDisclaimerCheckboxes(
        this.exhibitorSettings,
        termsTranslations,
        this.helperService.isSelfregistration(),
        false,
        false
      );
      const privacyPayload: FormInputsPayloadModel = {
        formInfo: AppConstants.PersonaliseFormsKeys.privacy,
        inputSet: {
          rerender: false,
          list: confirmationCheckboxes
        }
      };
      // trigger initial validation for privacy and policy
      this.setPolicy(confirmationCheckboxes);

      this.store.dispatch(new SetInputs(privacyPayload));
    });
  }

  ngOnInit() {
    this.spinnerValue$ = this.store.pipe(select(getSpinnerValue));

    this.store.pipe(
      select(getClaimedTicketHash),
      filter(hash => !!hash),
      switchMap(hash => {
        this.store.dispatch(new LoadTicketHolder(hash));

        return this.store.pipe(select(getTicketHolder), filter(holder => !!holder));
      }),
      takeUntil(this.unsubscribe)
    )
    .subscribe((holder: TicketHolder) => {
      this.isQuestionnaireRequired = holder.isQuestionnaireRequired;

      // perform reset only if questionnaire is not required - as it is asynchronous, sometimes it can reset filled questionnaire and therefore user cannot download ticket
      if (!holder.isQuestionnaireRequired) {
        this.setValidity({
          formName: AppConstants.PersonaliseFormsKeys.buyerQuestionnaire[1],
          valid: true,
          inputs: null,
          form: null
        });
      } else {
        this.store.pipe(
          select(getTicketHolderQuestionnaireInputs),
          first()
        )
        .subscribe(data => {
          if (data === null || !data.length) {
            const ticketPersonIds: number[] = holder.tickets.map(p => p.ticketPersonId);

            this.store.dispatch(
              new GetQuestionnaireInputs({
                eventId: this.eventId,
                ticketPersonIds: ticketPersonIds
              })
            );
          }
        });
      }
    });

    this.store.pipe(
      select(getClaimedTicketHashValid),
      filter(isValidHash => isValidHash !== null),
      takeUntil(this.unsubscribe)
    )
    .subscribe((isValidHash: boolean) => {
      this.loading = false;
      this.hasValidTicketHash = isValidHash;
    });

    this.store.pipe(
      select(getPrivacyInput),
      takeUntil(this.unsubscribe)
    )
    .subscribe((checkboxes: InputsListModel) => {
      if (checkboxes && checkboxes.list.length) {
        if (checkboxes || !this.checkboxesForm) {
          this.checkboxesInputs = checkboxes.list.slice(0);
          this.checkboxesForm = this.formsService.toFormGroup(checkboxes.list);

          // for validation set the policy value to false as well
          this.checkboxesInputs.map(item => item);

          if (this.checkboxesInputs[0].required === false) {
            this.validationControll.policy = true;
          } else {
            const validationCallback = () => {
              this.setPolicy(checkboxes.list);
            };

            this.helperService.triggerCallbackOnceFormValidationIsDone(
              this.checkboxesForm,
              validationCallback
            );
          }
        }
      } else {
        // if checkbox for privacy and policy is disabled in backoffice, mark it as valid
        this.validationControll.policy = true;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /* rerender MUST NOT BE REMOVED because this function is used as validationCallBack function
  if rerender is removed function will stop working properly */
  public setPolicy = (inputs: InputBase<any>[], rerender?: boolean) => {
    // get updated inputs, now we need to everwrite the old set with updated ones
    if (!this.exhibitorSettings) {
      return;
    }

    this.checkboxesInputs = inputs;

    const arePrivacyPolicyInputsValid = this.checkboxesInputs.filter(input =>
      (input.options[0].value &&
        input.required) ||
        !input.required
    ).length === this.checkboxesInputs.length;

    const validityData = {
      formName: 'policy',
      valid: arePrivacyPolicyInputsValid,
      inputs: this.checkboxesInputs,
      form: this.checkboxesForm,
    };

    this.setValidity(validityData);
  };

  setValidity(validityData) {
    const { formName, valid, inputs, form } = validityData;
    this.childForms[formName] = validityData;

    if (inputs && form) {
      // update object holding invalid inputs
      this.feedbackControlObject = this.formsService.generateValidationFeedback(
        inputs,
        form,
        formName,
        this.feedbackControlObject
      );

      this.feedbackMessages = Object.keys(this.feedbackControlObject).map(key => this.feedbackControlObject[key]);
    }

    // set form validity and then check if all fotsm in page are valid
    this.validationControll[formName] = valid;
    this.allFormsValid = Object.keys(this.validationControll).reduce(
      (acc, curr) => acc && this.validationControll[curr], true
    );
  }

  downloadTicket(ticketType: string) {
    this.downloadTicketButton = ticketType;
    this.store.dispatch(new SetPostTicketHolderFormResult(false));
    this.store.dispatch(new SetSpinnerValue(true));

    // wait for response but ignore the first one already in store
    this.store.pipe(
      select(getTicketHolderFormSubmitResult),
      skip(1),
      first(success => !!success)
    )
    .switchMap(() => this.store.pipe(select(getClaimedTicketHash), first()))
    .subscribe(hash => {
      if (ticketType === 'normalTicket') {
        this.store.dispatch(new DownloadTicket(hash));
      } else if (ticketType === 'mobileTicket') {
        this.store.dispatch(new DownloadMobileTicket(hash));
      } else if (ticketType === 'passBook') {
        this.store.dispatch(new DownloadPassBook(hash));
      }
    });

    combineLatest([
      this.store.pipe(select(getClaimedTicketHash)),
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getLanguage)),
      this.store.pipe(select(getProfile))
    ])
    .pipe(first())
    .subscribe(([hash, eventId, language, profile]) => {
      let ticketHolder: TicketHolder;

      // if there is a newsletter check box we use that value
      // if there is no newsletter checkbox and there is profile take the value from there
      // if nothing from above we just use false value

      if (this.childForms.ticketholder.form.value.newsletter) {
        let value = this.childForms.ticketholder.form.value.newsletter;
        const newsletterObjectValue = value.newsletter_Newsletter;

        if (newsletterObjectValue !== undefined && value !== null) {
          value = newsletterObjectValue;
        }

        ticketHolder = {
          ...this.childForms.ticketholder.form.value,
          hasNewsletter: value
        };
      } else if (!!profile) {
        ticketHolder = {
          ...this.childForms.ticketholder.form.value,
          hasNewsletter: profile.newsletterChecked
        };
      } else {
        ticketHolder = {
          ...this.childForms.ticketholder.form.value,
          hasNewsletter: false
        };
      }

      const ticketHolderEmail = this.childForms.ticketholder.inputs.find(input => input.key === 'email');

      if (ticketHolderEmail) {
        ticketHolder['email'] = ticketHolderEmail.value;
      }

      const policyForm = this.childForms.policy;
      const policyInput = policyForm && policyForm.inputs.find(input => input.key === 'disclaimer');
      const privacyPolicyOption = policyInput && policyInput.options.find(option => option.key === 'disclaimerConfirmation');

      if (privacyPolicyOption) {
        ticketHolder['PrivacyPolicyAccepted'] = privacyPolicyOption.value;
      }

      const policyOptionalInput = policyForm && policyForm.inputs.find(input => input.key === 'disclaimerOptional');
      const privacyPolicyOptionalOption = policyOptionalInput && policyOptionalInput.options.find(option => option.key === 'disclaimerOptionalConfirmation');

      if (privacyPolicyOptionalOption) {
        ticketHolder['PrivacyPolicyOptionalAccepted'] = privacyPolicyOptionalOption.value;
      }

      delete ticketHolder['newsletter'];

      const questionnaireFormInputs = this.childForms.questionnaire.inputs;
      const questionnaire = {
        eventId: eventId,
        values: []
      };

      if (this.childForms.questionnaire.inputs) {
        questionnaire.values = this.helperService.processQuestionnaireValuesBeforeSave(
          questionnaireFormInputs
        );
      }

      ticketHolder.dateOfBirth = this.helperService.getUTCdate(ticketHolder.dateOfBirth);

      Object.keys(ticketHolder).forEach(key => {
        if (typeof ticketHolder[key] === 'string') {
          const value = this.helperService.sanitizeString(ticketHolder[key]);

          if (value !== ticketHolder[key]) {
            ticketHolder[key] = value;
          }
        }
      });

      const dataToSave: TicketHolderForm = {
        hash,
        ticketHolder,
        questionnaire,
        language
      };

      this.store.dispatch(new PostTicketHolderForm(dataToSave));
    });
  }
}
