import { createSelector } from '@ngrx/store';
import { BookingContingentReservation, BookingTariff } from '@products/models/booking.model';
import { HolderTariff, HolderUuids, TicketHolder } from '@products/models/holder.model';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { getAllBookedTariffs, getAnonymousTariffs } from '@store/products/booking/booking.selectors';
import { getProductHolderState } from '@store/products/product.selectors';

/**
 * Get product holder Uuids list
 * @description Lists all products holder Uuids
 * @returns Products holders or empty array
 */
export const getHolderUuids = createSelector(
  getProductHolderState,
  (productHolderState): HolderUuids => productHolderState.list
);

/**
 * Get index of active holder slide on personalisation step
 * @returns Active holder slide
 */
export const getActiveSlide = createSelector(
  getProductHolderState,
  (productHolderState): number => productHolderState.activeSlide
);

/**
 * Get ticket holder questionnaire inputs
 * @returns Ticket holder questionnaire inputs
 */
export const getTicketHolderQuestionnaireInputs = createSelector(
  getProductHolderState,
  (productHolderState): InputBase<any>[] => productHolderState.questionnaireInputs
);

/**
 * Get ticket holder
 * @description Gets ticket holder
 * @returns Ticket holder
 */
export const getTicketHolder = createSelector(
  getProductHolderState,
  (productHolderState): TicketHolder => productHolderState.ticketHolder
);

/**
 * Get if ticket holder form was successfully submitted
 * @description Gets ticket holder submitted form result
 * @returns True if ticket holder was successfully submitted
 */
export const getTicketHolderFormSubmitResult = createSelector(
  getProductHolderState,
  (productHolderState): boolean => productHolderState.ticketHolderFormSubmitResult
);

/**
 * Gets ticket holder inputs
 * @description Gets ticket holder inputs
 * @returns Ticket holder inputs
 */
export const getTicketHolderInputs = createSelector(
  getProductHolderState,
  (productHolderState): InputBase<any>[] => productHolderState.inputs
);

/**
 * Gets tariffs by order they were booked
 * @returns Holder tariffs array or empty array if there are only parking tariffs taken
 */
export const getTariffsBookingByOrder = createSelector(
  getAllBookedTariffs,
  getHolderUuids,
  (allBookedTariffs, holderUuids): HolderTariff[] => {
    const holdersTicket: HolderTariff[] = [];

    holderUuids.forEach(holderUuid => {
      const bookedTariff: BookingTariff = allBookedTariffs.find(bookedTariff =>
        bookedTariff.holderUuids.includes(holderUuid)
      );

      if (!!bookedTariff) {
        const { ticketName: ticketPersonName, ticketPersonId, ticketTypeName } = bookedTariff;
        const bookingContingentReservation: BookingContingentReservation =
          bookedTariff.contingentReservations[bookedTariff.holderUuids.indexOf(holderUuid)] || null;
        const workshopNames: string[] = [];

        bookedTariff.workshops.forEach(bookedWorkshop => {
          if (bookedWorkshop.holderUuids.some(bookedWorkshopHolderUuid => bookedWorkshopHolderUuid === holderUuid)) {
            workshopNames.push(bookedWorkshop.name);
          }
        });

        const holderTicket: HolderTariff = {
          holderUuid,
          ticketTypeName,
          ticketPersonName,
          ticketPersonId,
          workshopNames,
          bookingContingentReservation
        };

        holdersTicket.push(holderTicket);
      }
    });

    return holdersTicket;
  }
);

/**
 * Get all anonymous holder Uuids from holder list
 * @description Get all anonymous holder uuids holder list
 * @returns Anonymous holder Uuids or empty array
 * (will not return all holder Uuids from booked tariffs, since they're not yet pushed to holder list)
 */
export const getAllAnonymousHolderUuids = createSelector(
  getAnonymousTariffs,
  getHolderUuids,
  (bookedTariffs: BookingTariff[], holderUuids: HolderUuids): HolderUuids => {
    const bookedHolderUuids: HolderUuids = [];

    bookedTariffs.forEach(
      bookedTariff =>
        bookedTariff.isAnonymous &&
        holderUuids.some(holderUuid =>
          bookedTariff.holderUuids.some(bookedHolderUuid => bookedHolderUuid === holderUuid)
        ) &&
        bookedHolderUuids.push(...bookedTariff.holderUuids)
    );

    return bookedHolderUuids;
  }
);
