import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  ProductGroup,
  ProductSelectionProductsStatusViewModel,
  ProductSelectionViewModel
} from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';

@Component({
  selector: 'app-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupComponent implements OnInit {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() productsStatusViewModel: ProductSelectionProductsStatusViewModel;
  @Input() productGroup: ProductGroup;
  isExpanded: boolean = false;

  readonly ProductType = ProductType;

  constructor() {}

  ngOnInit() {
    this.isExpanded = this.productGroup.expanded;
  }

  toggleGroup() {
    this.isExpanded = !this.isExpanded;
  }

  productGroupProductsTrackBy(productIndex: number) {
    return productIndex;
  }
}
