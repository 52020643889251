import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, getLanguage, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from '@shared/error-handling/error-handling.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { environment } from '@src/environments/environment';
import { SelectOption } from '@store/exhibition/exhibition.interface';
import { LoadAndSetTranslations, SetIsTranslationLoaded } from '@store/translation/translation.actions';
import { TranslationsModel } from '@store/translation/translation.model';
import { Observable, combineLatest } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  public activeTranslation: string;

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService,
    private store: Store<State>
  ) {
    combineLatest([this.store.pipe(select(getLanguage)), this.store.pipe(select(getSelectedExhibitionId))])
      .pipe(filter(([lang]) => !!lang))
      .subscribe(([lang, eventId]) => {
        if (eventId === null) {
          eventId = -1;
        }

        setTimeout(() => {
          this.store.dispatch(new LoadAndSetTranslations({ eventId, lang }));
        });
      });
  }

  /* rest api */

  getDefaultTranslations(): Observable<any> {
    let origin = environment.protocol + environment.origin;
    //origin = origin || window.location.origin;

    //let origin = window.location.origin;

    return (
      this.http
        // `${environment.protocol}${environment.origin}/assets/i18n/default.json`,
        .get(origin + '/assets/i18n/default.json')
    );
  }

  getLangTranslations(lang, eventId): Observable<any> {
    let event = -1;
    if (eventId !== 'default') {
      event = eventId;
    }

    return this.http
      .get(`${environment.protocol}${environment.webApiUrl}/event/${event}/translations/admin/${lang}`, {
        observe: 'response'
      })
      .pipe(
        map((resp: any) => {
          return resp.body.frontend;
        }),
        catchError(e => {
          return this.errorHandlingService.errorHandler(e);
        })
      );
  }

  saveTranslations(activeLang, eventId, translations) {
    let event = -1;
    if (eventId !== 'default') {
      event = eventId;
    }

    return this.http.put(
      `${environment.protocol}${environment.webApiUrl}/event/${event}/translations/${activeLang}`,
      translations
    );
  }

  /**
   * Get translation for position Dropdown and handle it with callback
   * @param input - InputBase holding option settings
   * @param positionData - select option data
   * @param handleCallback - function handling translation
   */
  getPositionTranslationForOption(input: InputBase<any>, positionData: SelectOption, handleCallback: Function) {
    let translationKey: string = positionData.value;

    if (translationKey) {
      this.translateService.get(translationKey).subscribe(translation => {
        handleCallback(translation);
      });
    } else {
      handleCallback(positionData.value);
    }
  }

  loadAndMergeTranslations(lang: string, eventId: number): Observable<any> {
    this.setIsTranslationLoadedStatus(false);

    return this.http
      .get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/translations/${lang}`, {
        observe: 'response'
      })
      .pipe(
        map((resp: any) => {
          if (resp.body.backend) {
            return Object.assign(resp.body.backend, resp.body.frontend);
          } else {
            return resp.body.frontend;
          }
        }),
        catchError(e => {
          this.setIsTranslationLoadedStatus(true);
          return this.errorHandlingService.errorHandler(e);
        })
      );
  }

  setIsTranslationLoadedStatus(isTranslationLoaded) {
    this.store.dispatch(new SetIsTranslationLoaded(isTranslationLoaded));
  }

  setTranslationsIntoTranslateService(translations: TranslationsModel) {
    const { language, list } = translations;

    this.translateService.resetLang(this.translateService.currentLang);
    this.translateService.setTranslation(language, list);
    this.translateService.use(language);
    this.translateService.setDefaultLang(language);
  }
}
