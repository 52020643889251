<ng-container *ngIf="parkingReservations$ | async as parkingReservations" [formGroup]="parkingReservationForm">
  <div
    class="container parking-container"
    *ngFor="
      let parkingReservation of parkingReservations;
      trackBy: parkingReservationTrackBy;
      index as parkingReservationIndex
    "
    formGroupName="{{ [tariff.ticketPersonId] }}_{{ [parkingReservationIndex] }}"
  >
    <div class="row">
      <label class="col-12 placeholder" data-translation="ticket.parking">
        {{ 'ticket.parking' | translate | hideTranslation }}
        {{ parkingReservationIndex + 1 }}.
      </label>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="input-wrapper form-wrapper">
          <div class="parking-calendar-wrapper">
            <p-calendar
              id="since_{{ [tariff.ticketPersonId] }}_{{ [parkingReservationIndex] }}"
              class="hasvalue"
              #since
              formControlName="since"
              calendarCustomDefaults
              [minDate]="productSelectionViewModel.dateToday"
              [maxDate]="selectedExhibition?.endDate"
              [showIcon]="true"
              [selectOtherMonths]="true"
              [readonlyInput]="true"
              [showTime]="true"
              (onSelect)="onParkingReservationSelect(parkingReservationIndex)"
            >
            </p-calendar>
            <label
              class="placeholder"
              data-translation="ticket.parking.since"
              for="since_{{ [tariff.ticketPersonId] }}_{{ [parkingReservationIndex] }}"
            >
              {{ 'ticket.parking.since' | translate | hideTranslation }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="input-wrapper form-wrapper">
          <div class="parking-calendar-wrapper">
            <p-calendar
              id="until_{{ [tariff.ticketPersonId] }}_{{ [parkingReservationIndex] }}"
              class="hasvalue"
              #until
              formControlName="until"
              minToday="true"
              calendarCustomDefaults
              [minDate]="parkingReservation.since"
              [maxDate]="selectedExhibition?.endDate"
              [showIcon]="true"
              [selectOtherMonths]="true"
              [readonlyInput]="true"
              [showTime]="true"
              [disabled]="!parkingReservation.since"
              (onSelect)="onParkingReservationSelect(parkingReservationIndex)"
            >
            </p-calendar>
            <label
              class="placeholder"
              data-translation="ticket.parking.until"
              for="until_{{ [tariff.ticketPersonId] }}_{{ [parkingReservationIndex] }}"
            >
              {{ 'ticket.parking.until' | translate | hideTranslation }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
