import * as bowser from 'bowser';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as helperActions from './helper.actions';
import { ElementRef, Renderer } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getAllBookedParkingReservations, getAllBookedTariffs, getClaimedTicketHash } from '@store/products/booking/booking.selectors';
import { saveAs } from 'file-saver/FileSaver';
import { environment } from '../../../../environments/environment';
import { getTicketHolderQuestionnaireInputs } from '../products/holder/holder.selectors';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
var HelperService = /** @class */ (function () {
    function HelperService(http, store, translateService, router, route) {
        var _this = this;
        this.http = http;
        this.store = store;
        this.translateService = translateService;
        this.router = router;
        this.route = route;
        this.isReloadSections = false;
        this.emailRequired = false;
        this.verifyEmailRequired = false;
        this.showModalWindowBH$ = new BehaviorSubject(null);
        this.voteYesNoBH$ = new BehaviorSubject(null);
        this.buyerQuestionnaire = [];
        this.visitorQuestionnaire = [];
        this.showModalWindow$ = this.showModalWindowBH$.asObservable();
        this.voteYesNo$ = this.voteYesNoBH$.asObservable();
        this.isStepNavigationRendered = new Subject();
        this.store.pipe(select(fromRoot.getAllCountriesList)).subscribe(function (list) { return _this.listOfAllCountries = list; });
        this.isMobile();
    }
    HelperService.prototype.setRefsToAppComponent = function (renderer, el) {
        this.appRenderer = renderer;
        this.appEl = el;
    };
    HelperService.prototype.setLanguage = function (lang) {
        this.store.dispatch(new helperActions.SetActiveLanguage(lang));
    };
    HelperService.prototype.setSupportedLanguages = function (languages) {
        this.translateService.addLangs(languages);
        this.store.dispatch(new helperActions.setSupportedLanguages(languages));
    };
    HelperService.prototype.redirectAfterLogin = function () {
        var _this = this;
        //get selected event ID and its step, if both present go to last selected step after login, otherwise go to homepage
        combineLatest([
            this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this.store.pipe(select(fromRoot.getSelectedStep)),
            this.store.pipe(select(fromRoot.isEventSeriesPage)),
            this.store.pipe(select(fromRoot.getBuyerInfo)),
            this.store.pipe(select(fromRoot.getStepsValidity)),
            this.store.pipe(select(getClaimedTicketHash))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var selectedExhibitionId = _a[0], selectedStep = _a[1], eventSeriesPage = _a[2], buyerInfo = _a[3], validations = _a[4], ticketClaimedHash = _a[5];
            if (!!buyerInfo) {
                var isInputFilledValue = buyerInfo.list.some(function (buyerInfoItem) { return buyerInfoItem.value; });
                if (isInputFilledValue) {
                    _this.showModalWindow(isInputFilledValue);
                }
                ;
            }
            var visibleSteps = Object.keys(validations)
                // make sure steps are ordered correctly
                .sort(function (a, b) { return validations[a].order - validations[b].order; })
                // only navigate to visible routes
                .filter(function (stepKey) { return validations[stepKey].visible; });
            if (selectedExhibitionId !== null && selectedStep !== null) {
                var queryString = _this.router.url.includes('?');
                var urlBase_1 = _this.isSelfregistration() ? 'self-registration' : 'webshop';
                if (selectedStep === 'payment') {
                    console.log('%c currentStep ', 'background: #D46A6A; color: #fff', "/" + urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep);
                }
                if (!validations[selectedStep].showInStepNavigation) {
                    var ticketStep = visibleSteps.find(function (step) { return step === 'tickets'; });
                    selectedStep = validations[ticketStep].showInStepNavigation
                        ? ticketStep
                        : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');
                    console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', "/" + urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep);
                }
                if (queryString) {
                    // Bug 3830 - if CONTINUE is pressed for the first time on personalization step, user is redirected to tickets step instead of confirmation step
                    _this.route.queryParams.first().subscribe(function (params) {
                        _this.router.navigate([urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep], {
                            queryParams: params
                        });
                    });
                }
                else {
                    _this.router.navigate([urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep]);
                }
            }
            else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
                _this.router.navigate(["/series/" + eventSeriesPage.eventSeriesId]);
            }
            else if (ticketClaimedHash) {
                _this.router.navigate(["webshop-download/" + selectedExhibitionId], { queryParams: { t: ticketClaimedHash } });
            }
            else {
                _this.router.navigate(['/']);
            }
        });
    };
    HelperService.prototype.voteYesOrNo = function (vote) {
        this.voteYesNoBH$.next(vote);
    };
    HelperService.prototype.showModalWindow = function (show) {
        this.showModalWindowBH$.next(show);
    };
    HelperService.prototype.getListOfCountries = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/country/preferred");
    };
    HelperService.prototype.loadCountries = function () {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/country/list");
    };
    HelperService.prototype.checkZipcodeValidity = function (countryCode, zipCode) {
        if (!zipCode) {
            return new Observable(function (subscriber) {
                return subscriber.next({ isValidZipCode: true });
            });
        }
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/country/" + countryCode + "/check-zip-code/" + zipCode);
    };
    HelperService.prototype.checkHashValidity = function (hash) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/hash/status/" + hash);
    };
    HelperService.prototype.convertDate = function (strDate) {
        // strDate = '03.09.1979';
        var dateParts = strDate.split('.');
        var newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        return newDate;
    };
    HelperService.prototype.toStringWithoutOffset = function (date) {
        return date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2) + "T" + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
    };
    HelperService.prototype.processFormValuesBeforeSave = function (formValues) {
        var _this = this;
        var normalizedData = {};
        Object.keys(formValues).forEach(function (groupKey) {
            normalizedData[groupKey] = formValues[groupKey];
            var typeOf = typeof formValues[groupKey];
            // if it is an object we are dealing with checkbox set
            if (typeOf === 'object') {
                var groupRegex_1 = new RegExp("^" + groupKey + "_");
                var isDateOfBirth = groupKey === 'dateOfBirth';
                if (isDateOfBirth) {
                    var dateOfBirth = formValues[groupKey];
                    if (!!dateOfBirth) {
                        formValues[groupKey] = _this.getUTCdate(dateOfBirth);
                        normalizedData[groupKey] = formValues[groupKey];
                    }
                }
                else {
                    Object.keys(formValues[groupKey]).forEach(function (checkboxId) {
                        var clearedId = checkboxId.replace(groupRegex_1, '');
                        normalizedData[clearedId] = formValues[groupKey][checkboxId];
                    });
                }
            }
            else if (typeOf === 'string') {
                //sanitize the input value (it should already come here sanitized anyway):
                var value = _this.sanitizeString(formValues[groupKey]);
                if (value !== formValues[groupKey]) {
                    formValues[groupKey] = value;
                    normalizedData[groupKey] = value;
                }
            }
        });
        return normalizedData;
    };
    HelperService.prototype.processQuestionnaireValuesBeforeSave = function (formControls) {
        var normalizedQuestionnaire = [];
        formControls.forEach(function (group) {
            if ((group.controlType === 'dropdown' || group.controlType === 'radio') &&
                group.hasOwnProperty('value') &&
                !group.hidden &&
                (group.value || group.value === 0)) {
                normalizedQuestionnaire.push({
                    fieldId: Number(group.key),
                    valueId: Number(group.value),
                    text: null
                });
            }
            else if (group.controlType === 'textbox' &&
                group.hasOwnProperty('value') &&
                !group.hidden &&
                group.value) {
                var keySplited = group.key.split('_');
                normalizedQuestionnaire.push({
                    fieldId: Number(keySplited[0]),
                    valueId: Number(keySplited[1]),
                    text: group.value
                });
            }
            else if (group.controlType === 'checkbox' && !group.hidden) {
                group.options.forEach(function (option) {
                    if (option.value) {
                        normalizedQuestionnaire.push({
                            fieldId: Number(group.key),
                            valueId: Number(option.key),
                            text: null
                        });
                    }
                });
            }
        });
        return normalizedQuestionnaire;
    };
    HelperService.prototype.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    HelperService.prototype.getVoucherUrl = function () {
        var voucherURL;
        this.route.queryParams.pipe(first()).subscribe(function (params) {
            Object.keys(params).find(function (key) {
                if (key === "voucher") {
                    return voucherURL = params.voucher;
                }
            });
        });
        return !!voucherURL ? voucherURL : '';
    };
    HelperService.prototype.getTicketParams = function () {
        var ticketParams = [];
        this.route.queryParams.pipe(first()).subscribe(function (params) {
            var counter = 0;
            ticketParams = Object.keys(params).map(function (key) {
                if (key === 'tt' || key === 'pt' || key === 'amt') {
                    return (counter++ === 0 ? '?' : '&') + (key + "=" + params[key]);
                }
            });
        });
        return ticketParams.length > 1 ? ticketParams.join('') : null;
    };
    HelperService.prototype.isMobile = function () {
        if (typeof navigator !== 'undefined') {
            return (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i) && true);
        }
        return false;
    };
    HelperService.prototype.isMobile$ = function () {
        return of(this.isMobile());
    };
    HelperService.prototype.stepNavigationRendered = function () {
        this.isStepNavigationRendered.next(true);
        return this.isStepNavigationRendered;
    };
    HelperService.prototype.browserInfo = function () {
        var browserInfo = bowser.getParser(window.navigator.userAgent)['parsedResult'];
        var browserName = browserInfo.browser.name + ' version: ' + browserInfo.browser.version;
        var systemInfo = browserInfo.os.name + ' version: ' + browserInfo.os.versionName;
        var platformInfo = browserInfo.platform.type;
        var completeInfo = 'Browser: ' + browserName + ', SystemInfo: ' + systemInfo + ', Platform: ' + platformInfo;
        return completeInfo;
    };
    HelperService.prototype.triggerCallbackOnceFormValidationIsDone = function (form, callback, isUsedWithoutTimeout) {
        if (form.pending) {
            var interval_1 = setInterval(function () {
                if (!form.pending) {
                    callback();
                    clearInterval(interval_1);
                }
            }, 200);
        }
        else {
            if (isUsedWithoutTimeout) {
                callback();
            }
            else {
                setTimeout(function () {
                    callback();
                }, 200);
            }
        }
    };
    HelperService.prototype.isSelfregistration = function () {
        var isSelfRegistration;
        this.store.pipe(select(fromRoot.getSelfRegistration), first()).subscribe(function (selfRegistration) {
            return isSelfRegistration = selfRegistration;
        });
        return isSelfRegistration;
    };
    HelperService.prototype.isEventSeriesPage = function () {
        var isEventSeriesPage;
        this.store.pipe(select(fromRoot.isEventSeriesPage), first())
            .subscribe(function (isEventSeriesPage) { return isEventSeriesPage = isEventSeriesPage; });
        return isEventSeriesPage;
    };
    HelperService.prototype.saveToFileSystem = function (blob, name) {
        saveAs(blob, name);
        this.store.dispatch(new helperActions.SetSpinnerValue(false));
    };
    HelperService.prototype.openIframe = function (url) {
        var win = window.open(url, '_blank');
        win.focus();
    };
    HelperService.prototype.removeQueryParam = function (key, sourceURL) {
        var queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
        var rtn = sourceURL.split('?')[0];
        var param;
        var params_arr = [];
        if (queryString !== '') {
            params_arr = queryString.split('&');
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split('=')[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr.length > 0) {
                rtn = rtn + '?';
            }
            rtn = rtn + params_arr.join('&');
        }
        return rtn;
    };
    HelperService.prototype.loadBuyerQuestionnaireViaApi = function (stepName) {
        var _this = this;
        combineLatest([
            this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this.store.pipe(select(fromRoot.getLanguage)),
            this.store.pipe(select(getAllBookedTariffs)),
            this.store.pipe(select(fromRoot.getQuestionnaire)),
            this.store.pipe(select(fromRoot.getSelfRegQuestionnaire)),
            this.store.pipe(select(fromRoot.getQuestionnaireTicketPersonIds))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var id = _a[0], lang = _a[1], tariffs = _a[2], questionnaire = _a[3], selfRegQuestionnaire = _a[4], questionnaireTicketPersonIds = _a[5];
            var isSelfRegistration = _this.isSelfregistration();
            var ticketPersonIds = [];
            tariffs.forEach(function (tariff) {
                var ticketPersonId = tariff.ticketPersonId;
                if (tariff.count > 0 && !ticketPersonIds.includes(ticketPersonId)) {
                    ticketPersonIds.push(ticketPersonId);
                }
            });
            if (!isSelfRegistration) {
                if (questionnaireTicketPersonIds.length === ticketPersonIds.length) {
                    return;
                }
                _this.store.dispatch(new stepsActions.SetQuestionnaireTicketPersonIds(ticketPersonIds));
            }
            _this.store.dispatch(new stepsActions.GetBuyerQuestionnaire({
                eventId: id,
                stepName: stepName,
                lang: lang,
                ticketPersonIds: ticketPersonIds,
                previousQuestionnare: isSelfRegistration ? selfRegQuestionnaire : questionnaire
            }));
        });
    };
    HelperService.prototype.applyFullHeightToAllParentElements = function (startingElement, displayBlock) {
        var parentElement = startingElement.parentElement;
        while (parentElement) {
            parentElement.style.height = '100%';
            if (displayBlock) {
                parentElement.style.display = 'block';
            }
            parentElement = parentElement.parentElement;
        }
    };
    HelperService.prototype.getParkingReservations = function () {
        var storedParkingReservations = [];
        this.store.pipe(select(getAllBookedParkingReservations), first(function (parkingReservations) { return !!parkingReservations; }))
            .subscribe(function (parkingReservations) { return storedParkingReservations = parkingReservations; });
        return storedParkingReservations;
    };
    HelperService.prototype.checkQuestionnairesForDuplicates = function () {
        var _this = this;
        combineLatest([
            this.store.pipe(select(fromRoot.getStepsForms)),
            this.store.pipe(select(getTicketHolderQuestionnaireInputs))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var stepsForms = _a[0], visitorQuestions = _a[1];
            var stepFormList = stepsForms['personal']['forms']['questionnaire'];
            if (!!stepFormList) {
                _this.buyerQuestionnaire = stepFormList['list'].slice();
                _this.visitorQuestionnaire = visitorQuestions;
            }
            if (_this.buyerQuestionnaire && _this.visitorQuestionnaire) {
                Object.keys(_this.buyerQuestionnaire).forEach(function (buyerKey) {
                    var buyerQuestion = _this.buyerQuestionnaire[buyerKey];
                    Object.keys(_this.visitorQuestionnaire).forEach(function (visitorKey) {
                        var visitorQuestion = _this.visitorQuestionnaire[visitorKey];
                        if (buyerQuestion.key === visitorQuestion.key) {
                            if (visitorQuestion.value === '' || visitorQuestion.value === undefined) {
                                visitorQuestion.value = buyerQuestion.value;
                                visitorQuestion.options.forEach(function (visitorOption) {
                                    var buyerOption = buyerQuestion.options.find(function (buyerOption) { return buyerOption.key === visitorOption.key; });
                                    if (!!buyerOption) {
                                        visitorOption.value = buyerOption.value;
                                    }
                                });
                            }
                        }
                    });
                });
            }
        });
    };
    //date time functions:
    //TODO: consider using moment.js
    HelperService.prototype.getUTCdate = function (date) {
        if (!!date) {
            if (typeof date === 'string' || date instanceof String) {
                date = new Date(date);
            }
            if (date instanceof Date) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
            }
        }
        return null;
    };
    /**
     * Removes the time part of a date object.
     * @param date
     * @returns Date object without time part.
     */
    HelperService.prototype.truncateTime = function (date) {
        if (!!date && date instanceof Date) {
            date.setHours(0, 0, 0, 0);
            return date;
        }
        return date;
    };
    /**
     * Checks if both dates without their time part are the same.
     * @param date1
     * @param date2
     * @returns True if both dates are the same.
     */
    HelperService.prototype.areDatesSameWOTime = function (date1, date2) {
        if (!date1 && !date2) {
            return true;
        }
        if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() === this.truncateTime(date2).valueOf()) {
            return true;
        }
        return false;
    };
    /**
     * Checks if the first date is greater than the second date.
     * @param date1
     * @param date2
     * @returns True if the first date is greater than the second one.
     */
    HelperService.prototype.isFirstDateGreaterWOTime = function (date1, date2) {
        if (!!date1 && !date2) {
            return true;
        }
        if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() > this.truncateTime(date2).valueOf()) {
            return true;
        }
        return false;
    };
    HelperService.prototype.setIsReloadSections = function (isReloadSections) {
        this.isReloadSections = isReloadSections;
    };
    HelperService.prototype.getReloadSections = function () {
        return this.isReloadSections;
    };
    /**
     *
     * @param values array to sort
     * @returns array sorted by values ascending
     */
    HelperService.prototype.sortValuesAsc = function (values) {
        values.sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        return values;
    };
    HelperService.prototype.setOriginalEmailValues = function (emailRequired, verifyEmailRequired) {
        this.emailRequired = emailRequired;
        this.verifyEmailRequired = verifyEmailRequired;
    };
    HelperService.prototype.getOriginalEmailValues = function (value) {
        if (value === 'email') {
            return this.emailRequired;
        }
        else if (value === 'verifyEmail') {
            return this.verifyEmailRequired;
        }
        return false;
    };
    HelperService.prototype.setReloadRequired = function (reloadRequired) {
        this.store.dispatch(new helperActions.SetReloadRequired(reloadRequired));
    };
    HelperService.prototype.sanitizeInput = function (control, event) {
        if (event && typeof event === 'object' && event.target && event.target.value && event.target.value.includes(">")) {
            var value = this.sanitizeString(event.target.value);
            if (event.target.value !== value) {
                event.target.value = value;
                if (control) {
                    control.setValue(value);
                }
            }
        }
    };
    HelperService.prototype.sanitizeString = function (value) {
        //DomSanitizer.sanitize also escapes characters which isn't necessary here so I won't use it:
        //return this.sanitizer.sanitize(SecurityContext.HTML, value);
        return value ? value.replace(/<[^>]+>/g, '') : value;
    };
    HelperService.ngInjectableDef = i0.defineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i3.TranslateService), i0.inject(i4.Router), i0.inject(i4.ActivatedRoute)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
export { HelperService };
