import { HttpClient } from '@angular/common/http';
import { getExhibitionSettings, getLegitimationStatus, getSelfRegistration } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import { getAllLegitimationTariffs } from '@store/products/product-selection/product-selection.selectors';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var LegitimationService = /** @class */ (function () {
    function LegitimationService(http, store) {
        this.http = http;
        this.store = store;
        this.destroy$ = new Subject();
    }
    LegitimationService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    LegitimationService.prototype.saveLegitimationInfo = function (data, userId, eventId) {
        return (this.http.post("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/legitimation/" + userId, data));
    };
    LegitimationService.prototype.getLegitimationList = function () {
        return (this.http.get("" + environment.protocol + environment.webApiUrl + "/user/legitimation-history"));
    };
    LegitimationService.prototype.getLegitimationStatus = function (eventId) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/user/event/" + eventId + "/legitimation-status");
    };
    LegitimationService.prototype.getLegitimationFaxId = function (eventId, userId) {
        return (this.http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/fax-legitimation-id/" + userId));
    };
    LegitimationService.prototype.isTariffWithRequiredLegitimationBooked$ = function () {
        return combineLatest([
            this.store.pipe(select(getAllLegitimationTariffs)),
            this.store.pipe(select(getAllBookedTariffs))
        ]).pipe(map(function (_a) {
            var allLegitimationTariffs = _a[0], bookedTariffs = _a[1];
            return bookedTariffs.some(function (bookedTariff) {
                var ticketTypeId = bookedTariff.ticketTypeId, ticketPersonId = bookedTariff.ticketPersonId, voucherCode = bookedTariff.voucherCode, packageNumber = bookedTariff.packageNumber, packageIndex = bookedTariff.packageIndex;
                return allLegitimationTariffs.some(function (legitimationTariff) {
                    return legitimationTariff.ticketTypeId === ticketTypeId &&
                        legitimationTariff.ticketPersonId === ticketPersonId &&
                        legitimationTariff.voucherCode === voucherCode &&
                        legitimationTariff.packageNumber === packageNumber &&
                        legitimationTariff.packageIndex === packageIndex;
                });
            });
        }), takeUntil(this.destroy$));
    };
    LegitimationService.prototype.isLegitimationRequiredForBookedProducts$ = function () {
        return combineLatest([
            this.store.pipe(select(getExhibitionSettings)),
            this.store.pipe(select(getSelfRegistration)),
            this.store.pipe(select(getLegitimationStatus)),
            this.isTariffWithRequiredLegitimationBooked$()
        ]).pipe(filter(function (_a) {
            var exhibitionSettings = _a[0], isSelfRegistration = _a[1];
            return !!exhibitionSettings && !isSelfRegistration;
        }), map(function (_a) {
            var exhibitionSettings = _a[0], _ = _a[1], legitimationStatus = _a[2], isTariffWithRequiredLegitimationBooked = _a[3];
            var isLegitimationRequired = exhibitionSettings.tradeVisitorLegitimation || isTariffWithRequiredLegitimationBooked;
            if (legitimationStatus) {
                var isLegitimationApproved = legitimationStatus === LegitimationStatus.Approved;
                if (isLegitimationApproved) {
                    isLegitimationRequired = false;
                }
            }
            return isLegitimationRequired;
        }), takeUntil(this.destroy$));
    };
    LegitimationService.ngInjectableDef = i0.defineInjectable({ factory: function LegitimationService_Factory() { return new LegitimationService(i0.inject(i1.HttpClient), i0.inject(i2.Store)); }, token: LegitimationService, providedIn: "root" });
    return LegitimationService;
}());
export { LegitimationService };
