import { Component, OnDestroy, OnInit } from '@angular/core';
import { getStepsValidity, State } from '@app/app.reducer';
import { select, Store } from '@ngrx/store';
import { getIsTranslationLoaded } from '@store/translation/translation.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-walk-thru-info',
  templateUrl: './walk-thru-info.component.html',
  styleUrls: ['./walk-thru-info.component.scss']
})
export class WalkThruInfoComponent implements OnInit, OnDestroy  {
  public shoppingStepList = [];
  public isTranslationLoaded: boolean = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<State>) {
    this.store.pipe(select(getStepsValidity)).subscribe(steps => {
      this.shoppingStepList = Object.keys(steps)
        .sort((a, b) => {
          return steps[a].order - steps[b].order;
        })
        // only navigate to visible routes
        .filter(stepKey => {
          return steps[stepKey].visible && steps[stepKey].showInStepNavigation;
        });
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store.pipe(select(getIsTranslationLoaded)).subscribe(isTranslationLoaded => {
        this.isTranslationLoaded = isTranslationLoaded;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
