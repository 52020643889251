<ng-template #bookedProductTariffsTemplate let-tariffs="tariffs">
  <div *ngFor="let tariff of tariffs" class="ticket-list ticket-list-tickets">
    <ng-container *ngIf="!tariff.parkingReservations.length; else parkingTariff">
      <span class="basket-product-text-color">
        {{ tariff.ticketName | translate | hideTranslation }}
        <span class="voucher-code" *ngIf="tariff.voucherCode"> ({{ tariff.voucherCode }})</span>
      </span>
      <span class="basket-product-text-color">
        {{ tariff.count }}x {{ tariff.price | formatPrice: settings.currencySettings }}
      </span>
    </ng-container>
    <ng-template #parkingTariff>
      <div class="basket-parking-tickets">
        <div *ngFor="let parkingTicket of tariff.parkingReservations" class="ticket-list">
          <span class="basket-product-text-color">{{ tariff.ticketName | translate | hideTranslation }}</span>
          <span class="basket-product-text-color">{{
            parkingTicket.price | formatPrice: settings.currencySettings
          }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-container *ngIf="getSortedBookedProducts$ | async as sortedBookedProducts">
  <div *ngIf="!!sortedBookedProducts && settings" class="basket-wrap">
    <ng-container
      *ngFor="let bookedProduct of sortedBookedProducts; let nextBookedProductIndex = &quot;index + 1&quot;"
    >
      <ng-container *ngIf="bookedProduct.productType === ProductType.Package">
        <div class="ticket-group package-ticket-group">
          <div class="package-topbar">
            <div class="package-name">{{ bookedProduct.productTypeName | translate | hideTranslation }}</div>
            <div>
              {{
                (getAllBookedPackagePricesGroupedByPackageIndex$ | async)[bookedProduct.packageIndex]
                  | formatPrice: settings.currencySettings
              }}
            </div>
          </div>
          <div *ngFor="let bookedProductType of bookedProduct.productTypes" class="ticket-list ticket-list-packages">
            <h4>{{ bookedProductType.productTypeName | translate | hideTranslation }}</h4>
            <ng-template
              *ngTemplateOutlet="bookedProductTariffsTemplate; context: { tariffs: bookedProductType.tariffs }"
            ></ng-template>
          </div>
        </div>
        <hr
          *ngIf="
            !!sortedBookedProducts[nextBookedProductIndex] &&
            sortedBookedProducts[nextBookedProductIndex].productType === ProductType.Tariff
          "
          class="separator"
        />
      </ng-container>

      <ng-container *ngIf="bookedProduct.productType === ProductType.Tariff">
        <div class="ticket-group">
          <div class="ticket-type-group-name">{{ bookedProduct.productTypeName | translate | hideTranslation }}</div>
          <ng-template
            *ngTemplateOutlet="bookedProductTariffsTemplate; context: { tariffs: bookedProduct.tariffs }"
          ></ng-template>
        </div>
      </ng-container>
    </ng-container>

    <div
      [attr.data-translation]="'confirmation.total'"
      class="separator separator-bold"
      data-translation="confirmation.total"
    >
      {{ 'confirmation.total' | translate | hideTranslation }}:
      <span>{{ getAllBookedProductsTotalPrice$ | async | formatPrice: settings.currencySettings }}</span>
    </div>
    <div *ngIf="(selectedStepName$ | async) !== 'invoice'" class="basket-buttons">
      <button (click)="gotoTicketSelection()" class="button button-option">
        <span [attr.data-translation]="'step-navigation.tickets'">{{
          'step-navigation.tickets' | translate | hideTranslation
        }}</span>
      </button>
      <button [disabled]="isNextButtonDisabled" (click)="toNextStep()" class="button">
        <span [attr.data-translation]="'basket.checkout'">{{ 'basket.checkout' | translate | hideTranslation }}</span>
      </button>
    </div>
  </div>
</ng-container>
