import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '@products/services/booking.service';

import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { StatusBarService } from '../status-bar/status-bar.service';

import { ExhibitionSettingModel } from '../shared/services-with-reducers/customization/customization.interfaces';

import { BookingTariff, VoucherCountdown } from '../_pages/products/models/booking.model';
import { HolderService } from '../_pages/products/services/holder.service';
import { VoucherService } from '../_pages/products/services/voucher.service';
import * as fromRoot from '../app.reducer';
import { AppConstants } from '../shared/app-constants';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import {
  getAllBookedTariffs,
  getAllBookedWorkshopTariffs,
  getBookedVoucherTariffByVoucherCode,
  getProductCountdown,
  getVouchersCountdown,
  getWorkshopCountdown
} from '../shared/services-with-reducers/products/booking/booking.selectors';
import { getVoucherTariffByVoucherCode } from '../shared/services-with-reducers/products/product-selection/product-selection.selectors';
import { HelperService } from '@store/helpers/helper.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  showLoginCountdown = false;
  loggedOutIn = '';
  timeToReleaseWorkshop = '';
  timeToReleaseTicket = '';

  nextExpiringVoucher: {
    code: string;
    remaining: number;
    showTime: string;
  };
  ticketBookingTimeoutModalOpen: boolean = false;

  private loginInterval;
  private vouchersInterval;
  private workshopInterval;
  private ticketInterval;
  private orderId: string;
  private urlBase: string = 'webshop';
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private holderService: HolderService,
    private router: Router,
    private statusBarService: StatusBarService,
    private appService: AppService,
    private customizationService: CustomizationService,
    private voucherService: VoucherService,
    private bookingService: BookingService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    const authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;
    this.urlBase = this.helperService.isSelfregistration() ? 'self-registration' : 'webshop';

    this.subscription.add(
      this.store.pipe(select(fromRoot.getLoginTimestamp)).subscribe(timestamp => {
        if (this.loginInterval) {
          clearInterval(this.loginInterval);
          this.loginInterval = null;
        }

        if (timestamp) {
          this.loginInterval = setInterval(() => {
            const now = Date.now();
            if (timestamp + authTimespanInMs - 30 * 60 * 1000 < now) {
              this.showLoginCountdown = true;
              const loggedOutInSecTotal =
                (timestamp + authTimespanInMs - now) / 1000;

              this.loggedOutIn = this.getCountdownTime(loggedOutInSecTotal);
            } else {
              this.showLoginCountdown = false;
            }
          }, 1000);
        } else {
          this.showLoginCountdown = false;
        }
      })
    );

    /* PRODUCT COUNTDOWN */

    this.subscription.add(
      combineLatest([
        this.store.pipe(select(getAllBookedTariffs)),
        this.store.pipe(select(fromRoot.getExhibitionSettings)),
        this.store.pipe(select(fromRoot.getSelectedStep)),
        this.store.pipe(select(getProductCountdown))
      ])
      .pipe(filter(([allBookedTariffs]) => !!allBookedTariffs))
      .subscribe(([_, eventSettings, selectedStep, productCountdown]: [BookingTariff[], ExhibitionSettingModel, string, number]) => {
        if (!eventSettings) {
          this.clearTicketInterval();
          return;
        }

        if (this.ticketInterval) {
          clearInterval(this.ticketInterval);
          this.ticketInterval = null;
        }


        if (productCountdown) {
          // if ticket timeout is created and there is workshop timeout we remove it
          clearInterval(this.workshopInterval);
          this.workshopInterval = null;
          this.timeToReleaseWorkshop = '';

          if (selectedStep === 'invoice') {
            this.clearTicketInterval();
            return;
          }

          this.ticketInterval = setInterval(() => {
            const now = Date.now();
            this.timeToReleaseTicket = this.getCountdownTime((productCountdown - now) / 1000);

            if (productCountdown < now) {
              // release all tickets. Bookings are released automatically on BE, so we just reset the reducer without user
              this.ticketBookingTimeoutModalOpen = true;

              this.clearTicketInterval();

              clearInterval(this.workshopInterval);
              this.workshopInterval = null;
              this.timeToReleaseWorkshop = '';
            }
          }, 1000);
        } else {
          this.clearTicketInterval();
          return;
        }
      })
    );

    /* WORKSHOPS COUNTDOWN */
    this.subscription.add(
      combineLatest([
        this.store.pipe(select(getAllBookedWorkshopTariffs)),
        this.store.pipe(select(fromRoot.getExhibitionSettings)),
        this.store.pipe(select(fromRoot.getSelectedStep)),
        this.store.pipe(select(getWorkshopCountdown))
      ])
      .pipe(
        filter(([workshopBookings]) => !!workshopBookings.length)
      )
      .subscribe(([bookedTariffs, eventSettings, selectedStep, workshopCountdown]: [BookingTariff[], ExhibitionSettingModel, string, number]) => {
          if (!eventSettings) {
            clearInterval(this.workshopInterval);

            this.workshopInterval = null;
            this.timeToReleaseWorkshop = '';

            // reset bookings on BE
            if (this.orderId) {
              this.bookingService.postReleaseWorkshopBookings$(this.orderId).subscribe();

              // reset orderId to prevent reset each time
              this.orderId = null;
            }

            return;
          }

          this.store.pipe(
            select(fromRoot.getOrderUuid),
            first()
          )
          .subscribe(uuid => this.orderId = uuid);

          if (this.workshopInterval) {
            clearInterval(this.workshopInterval);

            this.workshopInterval = null;
          }

          const latestBookingTimestamp = workshopCountdown;

          if (latestBookingTimestamp) {
            // const releaseOn = getCountdownTimestamp(latestBookingTimestamp,eventSettings.workshopSeatsReleaseInMinutes);

            const noBookings = bookedTariffs.every(booking =>
              booking.workshops.some(workshop => !workshop.holderUuids.length)
            );

            //if there is a ticket interval we don't need workshop interval !
            if (
              noBookings ||
              selectedStep === 'invoice' ||
              this.ticketInterval
            ) {
              clearInterval(this.workshopInterval);

              this.workshopInterval = null;
              this.timeToReleaseWorkshop = '';

              return;
            }
          }
        }
      )
    );

    /* VOUCHERS COUNTDOWN */
    this.subscription.add(
      this.store.pipe(select(getVouchersCountdown)).subscribe((vouchersCountdown: VoucherCountdown[]) => {
        clearInterval(this.vouchersInterval);
        this.vouchersInterval = null;

        if (vouchersCountdown.length) {
          // otherwise check if there is interval already, if not, create new one
          this.vouchersInterval = setInterval(() => {
            const now = Date.now();
            const initialValues = {
              code: '',
              remaining: Infinity,
              showTime: ''
            };

            this.nextExpiringVoucher = vouchersCountdown.reduce(
              (result, currVoucher) => {
                // we only relase limited wouchers
                const timeWhenEnds = currVoucher.expiryTimestamp;

                if (timeWhenEnds > now) {
                  // if the voucher is still valid get its time
                  if (timeWhenEnds - now < result.remaining) {
                    result.remaining = timeWhenEnds - now;
                    result.code = currVoucher.voucherCode;
                  }

                  return result;
                } else {
                  /* At this time the voucher was released by backend, so we need to remove it from FE as well */
                  combineLatest([
                    this.store.pipe(select(getBookedVoucherTariffByVoucherCode(currVoucher.voucherCode))),
                    this.store.pipe(select(getVoucherTariffByVoucherCode(currVoucher.voucherCode)))
                  ])
                  .pipe(
                    first(),
                    mergeMap(([bookedVoucherTariff, voucherTariff]) => {
                      // now remove holder forms related to this voucher ticket if there is any booked voucher tariff
                      if (bookedVoucherTariff) {
                        this.holderService.removeHoldersInputs(bookedVoucherTariff.holderUuids);
                      }

                      const ticketPersonId = bookedVoucherTariff ? bookedVoucherTariff.ticketPersonId : voucherTariff.ticketPersonId;
                      this.voucherService.releaseExpiredVoucher(ticketPersonId, currVoucher.voucherCode);

                      return combineLatest([
                        this.store.pipe(select(fromRoot.getSelectedExhibition)),
                        this.store.pipe(select(fromRoot.getSelectedStep)),
                        this.store.pipe(select(getAllBookedTariffs))
                      ])
                      .pipe(
                        first(([selectedExhibition, selectedStep, getAllBookedTariffs]) =>
                          !getAllBookedTariffs.some(bookedTariff => bookedTariff.voucherCode === currVoucher.voucherCode) && !!selectedExhibition && !!selectedStep
                        ),
                        mergeMap(([selectedExhibition, selectedStep, allBookedTariffs]) => {
                          if (!allBookedTariffs.length && selectedStep !== 'tickets') {
                            this.router.navigate([`${this.urlBase}/${selectedExhibition.id}/tickets`]);
                          }

                          return this.translateService.get('voucher.timer-ranOut');
                        })
                      );
                    })
                  )
                  .subscribe(translation => this.statusBarService.setStatus(translation, 'error'));

                  return result;
                }
              },
              initialValues
            );

            this.nextExpiringVoucher.showTime = this.getCountdownTime(this.nextExpiringVoucher.remaining / 1000);
          }, 1000);
        } else {
          this.nextExpiringVoucher = null;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.loginInterval);
    clearInterval(this.vouchersInterval);
    clearInterval(this.workshopInterval);

    this.loginInterval = null;
    this.vouchersInterval = null;
  }

  getCountdownTime(secondsTotal) {
    const minRemaining = Math.floor(secondsTotal / 60);
    const secRemaining = Math.floor(secondsTotal - minRemaining * 60);
    const secRemainingWithTwoDigits = secRemaining < 10 ? `0${secRemaining}` : secRemaining;

    return `${minRemaining}:${secRemainingWithTwoDigits}`;
  }

  closeModalWindow($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.ticketBookingTimeoutModalOpen = false;

    this.store.pipe(
      select(fromRoot.getSelectedExhibitionId),
      first()
    ).switchMap(eventId => {
      this.customizationService.resetShoppingStartTime();
      return this.appService.resetReducers().map(() => eventId);
    })
    .subscribe(eventId => window.location.replace(`${eventId ? `/${this.urlBase}/${eventId}` : '/'}`));
  }

  closeModalWindowOnRightClick($event: MouseEvent) {
    $event.stopPropagation();

    this.store.pipe(
      select(fromRoot.isLoggedInAsAdmin),
      first(isLoggedInAsAdmin => !!isLoggedInAsAdmin)
    )
    .subscribe(() => {
      this.ticketBookingTimeoutModalOpen = false;
    });
  }

  clearTicketInterval() {
    clearInterval(this.ticketInterval);
    this.ticketInterval = null;
    this.timeToReleaseTicket = '';
  }
}
