import * as fromRoot from '../../../app.reducer';
import * as legitimationActions from '../legitimation/legitimation.actions';
import * as userActions from './user.actions';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { DOCUMENT } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { ApplicationInsightsService } from '../../applicationInsights/applicationInsightsService';
import { InputBase } from '../../forms/inputs/input-base.class';
import { HelperService } from '../helpers/helper.service';
import { getClaimedTicketHash } from '../products/booking/booking.selectors';
import { InputsListModel } from '../step-forms/step.interface';
import { BillingAddressModel, UserProfileModel, UserProfileUpdateModel } from './user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  _loggedInId: number;
  loggedInUserId: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private router: Router,
    private store: Store<fromRoot.State>,
    private helperService: HelperService,
    private appService: AppService,
    private applicationInsightsService: ApplicationInsightsService
  ) {}

  forgottenPassword(data) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/forgotten-password`,
      data
    );
  }

  logout() {
    const webShopDownload: string = 'webshop-download';
    const urlBase = this.helperService.isSelfregistration()
      ? 'self-registration'
      : window.location.pathname.includes(webShopDownload) ? webShopDownload : 'webshop';
    combineLatest([
      this.store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this.store.pipe(select(fromRoot.isEventSeriesPage)),
      this.store.pipe(select(getClaimedTicketHash))
    ])
    .pipe(first())
    .subscribe(([exhibitionId, eventSeriesPage, claimedTicketHash]) => {
      this.appService.resetReducersWithUser().subscribe(() => {
        this.applicationInsightsService.clearUserId();

        if (exhibitionId) {
          const toWebShopDownload: string = (urlBase.includes(webShopDownload) ? `?t=${claimedTicketHash}` : '');
          window.location.replace(`/${urlBase}/${exhibitionId}` + toWebShopDownload);
        } else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
          window.location.replace(`/series/${eventSeriesPage.eventSeriesId}`);
        } else {
          window.location.replace(`/`);
        }
      });
    });
  }

  forcelogout() {
    this.store.dispatch(new userActions.ResetReducer());
    this.store.dispatch(new legitimationActions.ResetReducer());

    this.applicationInsightsService.clearUserId();

    this.router.navigate(['/logout']);
  }

  changePreferredLanguage(lang) {
    this.store.dispatch(new userActions.SetLanguage(lang));
  }

  login(username, password) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/login`,
      JSON.stringify({ username, password }),
      httpOptions
    );
  }

  getRegistrationQuestionnaire(eventId) {
    let selfReg = '';

    if (this.helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/registration-questionnaire${selfReg}`
    );
  }

  saveRegistrationQuestionnaire(questionnarireData) {
    let selfReg = '';

    if (this.helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/questionnaire${selfReg}`,
      questionnarireData
    );
  }

  downloadTicketById(ticketId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/download-ticket/${ticketId}`,
      { responseType: 'blob' }
    );
  }

  downloadInvoice(orderId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/invoice-download`,
      { responseType: 'blob' }
      );
  }

  downloadPassbook(ticketId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}/passbook`,
      { responseType: 'blob' }
      );
  }

  downloadRefundReceipt(orderId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/refund-receipt-download`,
      { responseType: 'blob' }
      );
  }

  refundOrder(orderId) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/refund`,
      { }
      );
  }

  refundSingleTicket(orderId: number, ticketId: number) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/entry-tickets/${ticketId}/refund`,
      { }
      );
  }

  downloadTicketRefundReceipt(orderId, ticketId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/entry-tickets/${ticketId}/refund-receipt-download`,
      { responseType: 'blob' }
      );
  }

  downloadPackageRefundReceipt(orderId, packageIndex) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/packages/${packageIndex}/refund-package-receipt-download`,
      { responseType: 'blob' }
      );
  }

  refundPackage(orderId, packageIndex, cancelOrderBody) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/packages/${packageIndex}/refund-package`,
      cancelOrderBody
      );
  }

  refundParkingTicket(orderId: number, ticketId: number) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/parking-tickets/${ticketId}/refund`,
      { }
      );
  }

  downloadParkingRefundReceipt(orderId, ticketId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/orders/${orderId}/parking-tickets/${ticketId}/refund-receipt-download`,
      { responseType: 'blob' }
      );
  }

  downloadParkingTicketById(parkingTicketId) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/download-parking-ticket/${parkingTicketId}`,
      { responseType: 'blob' }
    );
  }

  getTicketsByUser() {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/ticket-history`
    );
  }

  getEmailUnsubscriptions() {
    return this.http.get(`${environment.webApiUrl}/unsubscriptions`);
  }

  getProfile() {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user`
    );
  }

  createProfile(user: UserProfileModel) {
    let selfReg = '';

    if (this.helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user${selfReg}`,
      user
    );
  }

  updateProfile(user: UserProfileUpdateModel) {
    return this.http.patch(
      `${environment.protocol}${environment.webApiUrl}/user`,
      user
    );
  }

  updateProfileWithoutValidation(user: UserProfileUpdateModel) {
    return this.http.patch(
      `${environment.protocol}${environment.webApiUrl}/user/novalidate`,
      user
    );
  }

  getProfileBillingAddress() {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/billing-address`
    );
  }

  putProfileBillingAddress(
    addressId: number,
    data: BillingAddressModel
  ) {
    return this.http.put(
      `${environment.protocol}${environment.webApiUrl}/user/billing-address/${addressId}`,
      data
    );
  }

  postProfileBillingAddress(data: BillingAddressModel) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/billing-address`,
      data
    );
  }

  deleteProfileBillingAddress(addressId: number) {
    return this.http.delete(
      `${environment.protocol}${environment.webApiUrl}/user/billing-address/${addressId}`
    );
  }

  setNewPassword(password, language) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/password/${language}`,
      {
        password
      }
    );
  }

  resetPassword(password, hash, crmHash) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/password/reset`,
      {
        password,
        hash,
        crmHash
      },
      { observe: 'response' }
    );
  }

  setNewEmail(email, password, language) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/email/${language}`,
      {
        email,
        password
      }
    );
  }

  verifyEmail(hash, crmHash) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/verify-email`,
      {
        hash,
        crmHash
      },
      { observe: 'response' }
    );
  }

  sendVerifyEmail(language) {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/verify-email/${language}`,
      { observe: 'response' }
    );
  }

  changePassword(hash, crmHash) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/password`,
      {
        hash,
        crmHash
      },
      { observe: 'response' }
    );
  }

  changeEmail(hash, crmHash) {
    return this.http.post(
      `${environment.protocol}${environment.webApiUrl}/user/email`,
      {
        hash,
        crmHash
      },
      { observe: 'response' }
    );
  }

  getListOfSocialLogins() {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/external-login/available`
    );
  }

  getListOfSocialShares() {
    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/user/external-login/available-for-share`
    );
  }

  updateUserWithBuyer() {
    combineLatest([
      this.store.pipe(select(fromRoot.getProfile)),
      this.store.pipe(select(fromRoot.getBuyerInfo))
    ])
    .pipe(first(([profile, buyerInfo]) => !!profile && !!buyerInfo))
    .subscribe(([profile, buyerInfo]: [UserProfileModel, InputsListModel]) => {
      const updatedProfile: UserProfileUpdateModel = Object.assign({}, profile);
      let hasValuesToUpdate = false;

      buyerInfo.list.forEach((input: InputBase<any>) => {
        if (
          input.controlType !== 'checkbox' && // there are no checkboxes we want to copy to profile
          updatedProfile.hasOwnProperty(input.key) && // there is property to update
          !updatedProfile[input.key] && // the property to update has no value to far
          input.value // we have some value to update with
        ) {
          updatedProfile[input.key] = input.value;
          hasValuesToUpdate = true;
        }
      });

      if (hasValuesToUpdate) {
        this.store.dispatch(new userActions.UpdateProfileWithoutValidation(updatedProfile));
      }
    });
  }
}
