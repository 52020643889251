var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { WidgetService } from '@shared/services-with-reducers/widget/widget.service';
import { getSelectedExhibitionId } from '@app/app.reducer';
import { AppConstants } from '@shared/app-constants';
import { getLocalStorageObject, setLocalStorageObject } from '@shared/app-utils';
import { WidgetAdminService } from './widget-admin.service';
var WidgetAdminComponent = /** @class */ (function () {
    function WidgetAdminComponent(store, widgetService, widgetAdminService) {
        this.store = store;
        this.widgetService = widgetService;
        this.widgetAdminService = widgetAdminService;
        this.eventId = null;
        this.tabs = [
            {
                id: 'ticketsWidget',
                title: 'Tickets'
            },
            {
                id: 'bannerWidget',
                title: 'Banner'
            }
        ];
        this.activeTab = getLocalStorageObject(AppConstants.activeWidgetTabReducer);
        this.loading = false;
        this.unsubscribe = new Subject();
    }
    WidgetAdminComponent.prototype.ngOnInit = function () {
        this.setCurrentEventId();
        this.getAllWidgets();
        this.widgetSelectionTariffTypes$ = this.widgetAdminService.getWidgetSelectionTariffTypes$();
    };
    WidgetAdminComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    WidgetAdminComponent.prototype.createNewWidget = function (settings) {
        var _this = this;
        this.loading = true;
        this.widgetService.createWidgetForEvent(this.eventId, settings.settings).subscribe(function (res) {
            var _a;
            _this.widgetSettings = __assign({}, _this.widgetSettings, (_a = {}, _a[settings.type] = res, _a));
            _this.loading = false;
        }, function (err) { return (_this.loading = false); });
    };
    WidgetAdminComponent.prototype.updateWidget = function (settings) {
        var _this = this;
        if (settings.type !== 'ticketsWidget') {
            this.loading = true;
            this.widgetService.updateWidgetSettings(this.widgetSettings[settings.type].id, settings.settings).subscribe(function (res) {
                var _a;
                _this.widgetSettings = __assign({}, _this.widgetSettings, (_a = {}, _a[settings.type] = res, _a));
                _this.loading = false;
            }, function (err) { return (_this.loading = false); });
        }
    };
    WidgetAdminComponent.prototype.loadWidgetSettings = function (eventData) {
        var _this = this;
        var widgetId = Number(eventData.widgetId);
        this.loading = true;
        this.widgetService.getWidgetById(widgetId).subscribe(function (res) {
            var _a;
            _this.widgetSettings = __assign({}, _this.widgetSettings, (_a = {}, _a[eventData.type] = res, _a));
            _this.loading = false;
        }, function (err) { return (_this.loading = false); });
    };
    WidgetAdminComponent.prototype.getAllWidgets = function () {
        var _this = this;
        this.widgetService.getWidgetsForEvent(this.eventId).subscribe(function (eventWidgets) {
            _this.eventWidgets = eventWidgets;
        });
    };
    WidgetAdminComponent.prototype.setCurrentEventId = function () {
        var _this = this;
        this.store
            .pipe(select(getSelectedExhibitionId))
            .pipe(first(function (eventId) { return !!eventId; }))
            .subscribe(function (eventId) {
            _this.eventId = eventId;
        });
    };
    WidgetAdminComponent.prototype.onTabChange = function (tabName) {
        this.activeTab = tabName;
        setLocalStorageObject(AppConstants.activeWidgetTabReducer, tabName);
    };
    return WidgetAdminComponent;
}());
export { WidgetAdminComponent };
