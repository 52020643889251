import { ArticleStatusState } from '@products/models/article.model';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  ActionTypes as ArticleActionTypes,
  Actions as ArticleActions
} from '@store/products/status/article/article.actions';

export { ArticleStatusState as State };

export const initialState: ArticleStatusState = null;

export const articleStatusReducer = (
  state: ArticleStatusState = initialState,
  action: ProductActions | ArticleActions
): ArticleStatusState => {
  switch (action.type) {
    case ArticleActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
