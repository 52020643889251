import { Injectable, OnDestroy } from '@angular/core';
import { State, getExhibitionSettings, getSelfRegistration } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { ValidationState } from '@products/models/product-selection.model';
import { WorkshopValidationService } from '@products/services/workshop-validation.service';
import { WorkshopService } from '@products/services/workshop.service';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { HelperService } from '@store/helpers/helper.service';
import {
  areBookedContingentReservationsValid,
  areBookedParkingReservationsValid,
  getAllBookedTariffs,
  isBookedContingentReservationFromDateSetAndInvalid,
  isBookedProductsCountValid
} from '@store/products/booking/booking.selectors';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectionValidationService implements OnDestroy {
  private validationState: ValidationState = {
    isBookedProductsCountValid: false,
    areBookedContingentReservationsValid: false,
    areBookedParkingReservationsValid: false,
    isWorkshopsMandatoryValid: false,
    isWorkshopsZeroPriceMandatoryValid: false
  };
  private isValidationStateValid: boolean;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<State>,
    private helperService: HelperService,
    private formsService: FormsService,
    private workshopService: WorkshopService,
    private workshopValidationService: WorkshopValidationService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setBuyerQuestionnaireAndFormValidity$(): Observable<void> {
    return combineLatest([
      this.store.pipe(select(getSelfRegistration)),
      this.store.pipe(select(getAllBookedTariffs))
    ]).pipe(
      skip(1),
      map(([isSelfRegistration]) => {
        const { buyerQuestionnaire } = AppConstants.PersonaliseFormsKeys;
        const [personal, questionnaire] = buyerQuestionnaire;
        const buyerQuestionnaireStepName = isSelfRegistration ? questionnaire : personal;

        this.formsService.setFormValidity(false, null, buyerQuestionnaire);
        this.helperService.loadBuyerQuestionnaireViaApi(buyerQuestionnaireStepName);
      })
    );
  }

  setProductSelectionValidationState$() {
    return combineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(isBookedProductsCountValid)),
      this.store.pipe(select(isBookedContingentReservationFromDateSetAndInvalid)),
      this.store.pipe(select(areBookedContingentReservationsValid)),
      this.store.pipe(select(areBookedParkingReservationsValid)),
      this.workshopService.getBookedProductSelectionWorkshopTariffs$()
    ]).pipe(
      filter(([getExhibitionSettings]) => !!getExhibitionSettings),
      map(
        ([
          getExhibitionSettings,
          isBookedProductsCountValid,
          isBookedContingentReservationFromDateSetAndInvalid,
          areBookedContingentReservationsValid,
          areBookedParkingReservationsValid,
          bookedProductSelectionWorkshopTariffs
        ]) => {
          const {
            workshopsOnTicketSelection,
            isWorkshopsSelectionMandatory,
            workshopMandatoryForZeroPriceTickets,
            workshopsPerTicket
          } = getExhibitionSettings;

          this.setValidation(isBookedProductsCountValid);
          this.setValidation(!isBookedContingentReservationFromDateSetAndInvalid, [
            'contingent',
            'steps.contingent.sold-out'
          ]);
          this.setValidation(areBookedContingentReservationsValid, ['contingent', '']);
          this.setValidation(areBookedParkingReservationsValid, ['parking', '']);

          const workshopValidationState = {
            isTariffWithWorkshopsTaken: !!bookedProductSelectionWorkshopTariffs.length,
            haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit: true,
            haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit: true
          };

          if (workshopsOnTicketSelection) {
            if (isWorkshopsSelectionMandatory) {
              workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = this.workshopValidationService.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
                bookedProductSelectionWorkshopTariffs,
                workshopsPerTicket
              );
            }

            if (workshopMandatoryForZeroPriceTickets) {
              workshopValidationState.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = this.workshopValidationService.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
                bookedProductSelectionWorkshopTariffs,
                workshopsPerTicket
              );
            }

            this.setValidation(
              workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit,
              ['workshop', 'workshop.not-selected']
            );
            this.setValidation(
              !workshopValidationState.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit ||
                workshopValidationState.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit,
              ['workshop-zero', 'workshop.not-zero-selected']
            );
          } else if (
            !workshopsOnTicketSelection ||
            (!isWorkshopsSelectionMandatory && !workshopMandatoryForZeroPriceTickets) ||
            workshopValidationState.isTariffWithWorkshopsTaken ||
            !isBookedProductsCountValid
          ) {
            this.setValidation(true, ['workshop', 'workshop.not-selected']);
            this.setValidation(true, ['workshop-zero', 'workshop.not-zero-selected']);
          }

          return this.isValidationStateValid;
        }
      )
    );
  }

  private setValidation(
    isValid: boolean,
    validationFeedback: [string, string] = ['counters', 'steps.missing-input.ticket-counter']
  ) {
    const stepsFormsActionName = ['tickets', 'ticketSelection'];
    let [key, messageTranslationKey] = validationFeedback;

    switch (key) {
      case 'counters':
        this.validationState.isBookedProductsCountValid = isValid;
        break;

      case 'parking':
        this.validationState.areBookedParkingReservationsValid = isValid;
        break;

      case 'contingent':
        this.validationState.areBookedContingentReservationsValid = isValid;
        break;

      case 'workshop':
        this.validationState.isWorkshopsMandatoryValid = isValid;
        break;

      case 'workshop-zero':
        this.validationState.isWorkshopsZeroPriceMandatoryValid = isValid;
        break;

      default:
        break;
    }

    const {
      isBookedProductsCountValid,
      areBookedParkingReservationsValid,
      areBookedContingentReservationsValid,
      isWorkshopsMandatoryValid,
      isWorkshopsZeroPriceMandatoryValid
    } = this.validationState;

    const allStepValidationFieldsValid: boolean =
      isBookedProductsCountValid &&
      areBookedContingentReservationsValid &&
      areBookedParkingReservationsValid &&
      isWorkshopsMandatoryValid &&
      isWorkshopsZeroPriceMandatoryValid;

    if (isValid) {
      if (key === 'counters' || key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
        //parking and contingent tickets share the same validation/translation key as the ticket counter ('counters'):
        if (isBookedProductsCountValid && areBookedContingentReservationsValid && areBookedParkingReservationsValid) {
          this.formsService.removeStepValidationFeedback(stepsFormsActionName, 'counters');
        }
      } else {
        //other steps (i.e. currently only workshops) have their own key:
        this.formsService.removeStepValidationFeedback(stepsFormsActionName, key);
      }
    } else {
      if (key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
        key = 'counters';
        messageTranslationKey = 'steps.missing-input.ticket-counter';
      }

      this.formsService.addStepValidationFeedback(stepsFormsActionName, key, messageTranslationKey);
    }

    //even though we've received an info that the ticket selection is valid it can only be valid if all step validation fields are valid:
    if (isValid && !allStepValidationFieldsValid) {
      isValid = false;
    }

    this.formsService.setFormValidity(isValid, null, stepsFormsActionName);
    this.isValidationStateValid = isValid;
  }
}
