import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { State } from '@app/app.reducer';
import { Store } from '@ngrx/store';
import { BookingContingentReservation } from '@products/models/booking.model';
import { RemoveContingentReservation, SetContingentReservationDate } from '@products/models/contingent.model';
import { getProductType } from '@products/models/products.model';
import { Tariff } from '@products/models/tariff.model';
import { convertStringToDate } from '@shared/app-utils';
import { RemoveContingentBookingReservation, SetContingentBookingReservation } from '@store/products/product.actions';
import _ from 'lodash';

@Injectable()
export class ContingentTariffService {
  private tariff: Tariff;
  private contingentReservationForm: FormGroup;
  private contingentReservationShowLoader: { [contingentReservationIndex: number]: boolean } = {};

  constructor(private store: Store<State>) {}

  initState(
    tariff: Tariff,
    contingentReservationForm: FormGroup,
    contingentReservationShowLoader: { [contingentReservationIndex: number]: boolean }
  ) {
    this.tariff = tariff;
    this.contingentReservationForm = contingentReservationForm;
    this.contingentReservationShowLoader = contingentReservationShowLoader;
  }

  setContingentReservationDayFormControls(contingentReservations: BookingContingentReservation[]) {
    const numberOfContingentReservations = contingentReservations.length;
    const beforeAddingFormControlNames = Object.keys(this.contingentReservationForm.controls);
    const beforeAddingNumberOfAddedFormControls = beforeAddingFormControlNames.length;
    const addControl = numberOfContingentReservations > beforeAddingNumberOfAddedFormControls;
    const removeControl = numberOfContingentReservations < beforeAddingNumberOfAddedFormControls;

    if (addControl) {
      this.addContingentReservationDayFormControls(contingentReservations);

      const isComponentReload = !contingentReservations.length && !beforeAddingNumberOfAddedFormControls;
      if (isComponentReload) {
        return;
      }

      this.setCalendarDate(beforeAddingNumberOfAddedFormControls);
    } else if (removeControl) {
      const numberOfFormControlsToRemove = beforeAddingNumberOfAddedFormControls - numberOfContingentReservations;
      this.removeContingentReservationDayFormControls(numberOfFormControlsToRemove);
    }
  }

  addContingentReservationDayFormControls(contingentReservations: BookingContingentReservation[]) {
    contingentReservations.forEach((contingentReservation, contingentReservationIndex) => {
      const formControlName = `${this.tariff.ticketPersonId}_${contingentReservationIndex}`;
      let day = contingentReservation.fromDate ? contingentReservation.fromDate : null;

      if (!this.tariff.shouldCalendarBeDisplayed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const validFromDate = convertStringToDate(this.tariff.validFrom);
        day = validFromDate.getTime() < today.getTime() ? today : validFromDate;
      }

      const contingentReservationDaysFormGroup = new FormGroup({
        day: new FormControl({ value: day, disabled: !this.tariff.shouldCalendarBeDisplayed }, Validators.required)
      });

      this.contingentReservationForm.addControl(formControlName, contingentReservationDaysFormGroup);
    });
  }

  removeContingentReservationDayFormControls(numberOfFormControlsToRemove: number) {
    const formControlNames = Object.keys(this.contingentReservationForm.controls);
    const removeFormControlNamesIndexes = _.range(
      formControlNames.length - numberOfFormControlsToRemove,
      formControlNames.length
    );

    formControlNames
      .slice(-numberOfFormControlsToRemove)
      .forEach(formControlName => this.contingentReservationForm.removeControl(formControlName));

    const removeContingentReservation: RemoveContingentReservation = {
      ticketTypeId: this.tariff.ticketTypeId,
      ticketPersonId: this.tariff.ticketPersonId,
      voucherCode: this.tariff.voucherCode,
      packageNumber: this.tariff.packageNumber,
      packageIndex: this.tariff.packageIndex,
      removeBookingReservationIndexes: removeFormControlNamesIndexes
    };

    this.store.dispatch(new RemoveContingentBookingReservation([removeContingentReservation]));
  }

  setCalendarDate(beforeAddingNumberOfAddedFormControls: number) {
    if (!this.tariff.shouldCalendarBeDisplayed) {
      const afterAddingFormControlNames = Object.keys(this.contingentReservationForm.controls);
      const afterAddingNumberOfAddedFormControls = afterAddingFormControlNames.length;
      const differenceBetweenBeforeAddingControlsAndAfterAddingControls =
        afterAddingNumberOfAddedFormControls - beforeAddingNumberOfAddedFormControls;
      const startingIndexRange =
        afterAddingNumberOfAddedFormControls - differenceBetweenBeforeAddingControlsAndAfterAddingControls;
      const endingIndexRange = afterAddingNumberOfAddedFormControls;
      const newlyAddedFormControlNamesIndexes = _.range(startingIndexRange, endingIndexRange);

      this.setContingentReservations(newlyAddedFormControlNamesIndexes);
    }
  }

  setContingentReservations(contingentReservationIndexes: number[]) {
    const setContingentReservationSelectedDates: SetContingentReservationDate[] = [];

    contingentReservationIndexes.forEach(contingentReservationIndex => {
      this.showReservationLoader(contingentReservationIndex);

      const formControlName = `${this.tariff.ticketPersonId}_${contingentReservationIndex}`;
      const contingentReservationSelectedDate: Date = this.contingentReservationForm.get(formControlName).value.day;
      const setContingentReservationSelectedDate: SetContingentReservationDate = {
        productType: getProductType(this.tariff),
        ticketTypeId: this.tariff.ticketTypeId,
        ticketPersonId: this.tariff.ticketPersonId,
        voucherCode: this.tariff.voucherCode,
        packageNumber: this.tariff.packageNumber,
        packageIndex: this.tariff.packageIndex,
        duration: this.tariff.durationInDays,
        bookingReservationIndex: contingentReservationIndex,
        day: contingentReservationSelectedDate
      };

      setContingentReservationSelectedDates.push(setContingentReservationSelectedDate);
    });

    this.store.dispatch(new SetContingentBookingReservation(setContingentReservationSelectedDates));
  }

  setContingentReservationLoaders(contingentReservations: BookingContingentReservation[]) {
    contingentReservations.forEach((contingentReservation, contingentReservationIndex) => {
      const { fromDate, isValid } = contingentReservation;

      if (!!fromDate && !isValid) {
        this.showReservationLoader(contingentReservationIndex);
      }
    });
  }

  resetContingentReservationLoaders(contingentReservations: BookingContingentReservation[]) {
    contingentReservations.forEach((_, contingentReservationIndex) => {
      this.hideReservationLoader(contingentReservationIndex);
    });
  }

  setOrResetContingentReservationLoaders(
    isCreateContingentReservationLoading: boolean,
    contingentReservations: BookingContingentReservation[]
  ) {
    if (isCreateContingentReservationLoading) {
      this.setContingentReservationLoaders(contingentReservations);
    } else {
      this.resetContingentReservationLoaders(contingentReservations);
    }
  }

  showReservationLoader(contingentReservationIndex: number) {
    this.contingentReservationShowLoader[contingentReservationIndex] = true;
  }

  hideReservationLoader(contingentReservationIndex: number) {
    this.contingentReservationShowLoader[contingentReservationIndex] = false;
  }
}
