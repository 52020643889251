import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  State,
  getAllDepartments,
  getAllOccupationalGroups,
  getAllProfessions,
  getAllTitles,
  getDepartments,
  getExhibitionSettings,
  getLanguage,
  getLegitimationFaxId,
  getLegitimationPostResponse,
  getLegitimationStatus,
  getOccupationalGroups,
  getOperatorsSettings,
  getProfessions,
  getProfile,
  getSelectedExhibition,
  getSelectedExhibitionId,
  getTitles,
  getUser,
  isUserLoggedIn
} from '@app/app.reducer';
import { getProfileEditForm } from '@app/edit-account-form/form-inputs.edit-account';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { environment } from '@src/environments/environment';
import {
  ExhibitionSettingModel,
  OperatorsSettingsModel,
  QuestionnaireDataInput
} from '@store/customization/customization.interfaces';
import { ExhibitionModel, SelectOption } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import {
  GetLegitimationFaxId,
  GetLegitimationStatus,
  PostLegitimationInfo,
  SetLegitimationInfoResponse,
  SetLegitimationStatus
} from '@store/legitimation/legitimation.actions';
import { LegitimationStatus, legitimationType } from '@store/legitimation/legitimation.interface';
import { LegitimationService } from '@store/legitimation/legitimation.service';
import { SetSelectedStep } from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { GetProfile } from '@store/user/user.actions';
import { UserProfileModel } from '@store/user/user.interface';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first, skip } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-legitimation',
  templateUrl: './web-shop-legitimation.component.html',
  styleUrls: ['./web-shop-legitimation.component.scss']
})
export class WebShopLegitimationComponent implements OnInit, OnDestroy {
  customUrl = ''; //`${environment.apiUrl}/event/${eventId}/user/${userId}legitimation-file?legitimationType=corporateBookExtract`;
  //http://expo-demo.teamaxess.com/WebShop/api/event/eventId/user/userId/legitimation-file/legitimationType
  legitimationComment = '';
  legitimationUrl = 'http://';
  legitimationStatus: LegitimationStatus;
  responsePending: boolean = false;
  faxId: number = null;
  showFaxInfo: boolean;
  previewFile: string;
  isButtonDisabled = true;
  selectedOption: legitimationType = null;
  optionsValidity = {
    file: false,
    fax: false,
    url: false,
    noFile: false
  };
  isLoggedIn$: Observable<boolean>;
  eventSetting: ExhibitionSettingModel;
  userProfile: UserProfileModel;
  activeExhibition: ExhibitionModel;
  isUploading: boolean = false;
  showProfileForm: boolean = false;
  isProfileUpdated: boolean = false;
  afterFaxPrint: boolean = false;
  modalWindowActive: boolean = false;
  isLegitimationSubmitted: boolean = false;
  isPrintWindowOpen: boolean = false;
  legitimationForNewAccount: boolean = false;
  doTariffsNeedLegitimation: boolean = false;
  fileTypes: string[] = [];
  allowedFileTypes: string = '.gif,.jpg,.jpeg,.png,.pdf';
  maxFileUploadSize: number = 3500000;
  readonly LegitimationStatus = LegitimationStatus;

  private subscriptions = new Subscription();
  private accountForm;
  private uploadedFilesIds: Array<number> = null;
  private binaryConversionConstant: number = 1024 * 1024;

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private router: Router,
    private route: ActivatedRoute,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService,
    private legitimationService: LegitimationService
  ) {
    this.isLoggedIn$ = this.store.pipe(select(isUserLoggedIn));
    let firstLoad = true;

    this.subscriptions.add(
      this.legitimationService
        .isTariffWithRequiredLegitimationBooked$()
        .subscribe(doTariffsNeedLegitimation => (this.doTariffsNeedLegitimation = doTariffsNeedLegitimation))
    );

    this.store
      .pipe(
        select(getProfile),
        filter(data => !!data),
        first()
      )
      .subscribe(profile => {
        this.store.dispatch(new GetProfile(profile.id));
      });

    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(getProfile)),
        this.store.pipe(select(getSelectedExhibition)),
        this.store.pipe(select(getExhibitionSettings)),
        this.store.pipe(select(getLegitimationStatus)),
        this.store.pipe(select(getLegitimationFaxId)),
        this.store.pipe(select(getOperatorsSettings)),
        this.store.pipe(select(getAllTitles)),
        this.store.pipe(select(getTitles)),
        this.store.pipe(select(getAllProfessions)),
        this.store.pipe(select(getProfessions)),
        this.store.pipe(select(getAllDepartments)),
        this.store.pipe(select(getDepartments)),
        this.store.pipe(select(getAllOccupationalGroups)),
        this.store.pipe(select(getOccupationalGroups))
      ])
        .pipe(
          skip(1),
          filter(([profile, exhibition, eventSetting, legitimation]) => {
            return !!profile && !!exhibition && !!eventSetting && !!legitimation;
          })
        )
        .subscribe(
          async (
            data: [
              UserProfileModel,
              ExhibitionModel,
              ExhibitionSettingModel,
              LegitimationStatus,
              number,
              OperatorsSettingsModel,
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[]
            ]
          ) => {
            const [
              profile,
              exhibition,
              eventSetting,
              legitimationStatus,
              faxId,
              operatorSettings,
              allTitles,
              titles,
              allProfessions,
              professions,
              allDepartments,
              departments,
              allOccupationalGroups,
              occupationalGroups
            ] = data;

            this.userProfile = profile;
            this.activeExhibition = exhibition;
            this.eventSetting = eventSetting;
            this.legitimationStatus = legitimationStatus;
            this.faxId = faxId;
            this.legitimationForNewAccount = eventSetting.goToLegitimationForNewAccount;

            // when user does not have profile complete we hide the legitimation and display profile form
            if (legitimationStatus !== LegitimationStatus.InProgress) {
              const validationCallback = () => {
                if (this.isProfileUpdated) {
                  this.showProfileForm = false;
                  firstLoad = false;
                } else if (!this.accountForm.valid) {
                  this.showProfileForm = true;
                  firstLoad = false;
                } else if (this.accountForm.valid && !this.isProfileUpdated && firstLoad) {
                  this.showProfileForm = false;
                } else if (this.accountForm.valid && !this.isProfileUpdated && !firstLoad) {
                  this.showProfileForm = true;
                }
              };

              this.accountForm = this.formsService.toFormGroup(
                this.createProfileEditForm(
                  operatorSettings,
                  titles,
                  allTitles,
                  professions,
                  allProfessions,
                  departments,
                  allDepartments,
                  occupationalGroups,
                  allOccupationalGroups
                )
              );

              this._helperService.triggerCallbackOnceFormValidationIsDone(this.accountForm, validationCallback);
            }

            let legitimationValid = legitimationStatus === LegitimationStatus.Approved;

            this.formsService.setFormValidity(legitimationValid, null, ['legitimation', 'validation']);
          }
        )
    );

    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getSelectedExhibitionId)), this.store.pipe(select(getProfile))])
        .pipe(filter(data => !!data[0] && !!data[1]))
        .subscribe(data => {
          const [eventId, profile] = data;
          this.customUrl = `${environment.webApiUrl}/event/${eventId}/user/${profile.id}/legitimation-file`;

          this.store.dispatch(
            new GetLegitimationStatus({
              userId: profile.id,
              eventId
            })
          );
        })
    );
  }

  // converts maximum file upload size(size in bytes) to correct value size
  convertBytes(): string {
    if (this.maxFileUploadSize === 0) {
      return '0 B';
    }
    var k = 1000,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(this.maxFileUploadSize) / Math.log(k));
    return (parseFloat((this.maxFileUploadSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]).toString();
  }

  createProfileEditForm(
    operatorSettings: OperatorsSettingsModel,
    titles: SelectOption[],
    allTitles: QuestionnaireDataInput[],
    professions: SelectOption[],
    allProfessions: QuestionnaireDataInput[],
    departments: SelectOption[],
    allDepartments: QuestionnaireDataInput[],
    occupationalGroups: SelectOption[],
    allOccupationalGroups: QuestionnaireDataInput[]
  ) {
    const profileEditForm = getProfileEditForm(this.eventSetting, operatorSettings, false, [], [], [], []);
    const inputTitles = titles ? titles : [];
    const inputProfessions = professions ? professions : [];
    const inputDepartments = departments ? departments : [];
    const inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];

    const updatedProfileEditForm = profileEditForm.map(profileItem => {
      let updatedItem: InputBase<any>;

      if (
        this.userProfile.hasOwnProperty(profileItem.key) &&
        (this.userProfile[profileItem.key] || this.userProfile[profileItem.key] === 0)
      ) {
        updatedItem = {
          ...profileItem,
          value: this.formsService.getFunctionTextValue(
            this.userProfile,
            profileItem,
            inputTitles,
            allTitles,
            inputProfessions,
            allProfessions,
            inputDepartments,
            allDepartments,
            inputOccupationalGroups,
            allOccupationalGroups
          )
        };

        this.formsService.resetInvalidFunctionValue(
          updatedItem,
          inputTitles,
          inputProfessions,
          inputDepartments,
          inputOccupationalGroups,
          (input: InputBase<any>) => {
            updatedItem = {
              ...input,
              value: null
            };
          }
        );
      } else {
        updatedItem = { ...profileItem, value: '' };

        if (profileItem.key === 'newsletterChecked' && profileItem.controlType === 'radio') {
          updatedItem = {
            ...profileItem,
            value: this.userProfile.newsletterChecked.toString()
          };
        }
      }

      if (profileItem.controlType === 'checkbox') {
        updatedItem.options.forEach(option => {
          if (this.userProfile.hasOwnProperty(option.key)) {
            option.value = this.userProfile[option.key];
          }
        });
      }

      return updatedItem;
    });

    return updatedProfileEditForm;
  }

  profileUpdated(event) {
    this.isProfileUpdated = event;
  }

  ngOnInit() {
    this.setLegitimationValidity();
    this.subscriptions.add(
      this._stepsFormsService.isLegitimationRequired$
        .pipe(filter(isLegitimationRequired => !isLegitimationRequired))
        .subscribe(() => {
          this.store.dispatch(new SetSelectedStep('tickets'));

          this.router.navigate(['./tickets'], {
            relativeTo: this.route.parent,
            preserveQueryParams: true
          });
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showFax() {
    /* Ask for legitimation ID */

    this.showFaxInfo = true;
    this.afterFaxPrint = false;

    this.subscriptions.add(
      combineLatest([this.store.pipe(select(getSelectedExhibitionId)), this.store.pipe(select(getProfile))])
        .pipe(filter(([eventId, profile]) => !!eventId && !!profile))
        .subscribe(([eventId, profile]) => {
          this.store.dispatch(
            new GetLegitimationFaxId({
              eventId,
              userId: profile.id
            })
          );
        })
    );
  }

  closeFaxInfo() {
    //if user closed print window and did not legitimate we show him pop up window before he closes the fax template
    this.modalWindowActive = this.afterFaxPrint && !this.isLegitimationSubmitted;
    if (!this.modalWindowActive) {
      this.showFaxInfo = false;
    }
  }

  printTemplate(printableFax) {
    this.store
      .pipe(
        select(getLegitimationFaxId),
        first(faxId => faxId !== null)
      )
      .subscribe(faxId => {
        this.isPrintWindowOpen = true;
        this.faxId = faxId;
        this.optionsValidity.fax = true;
        this.setLegitimationValidity();

        setTimeout(() => {
          const myWindow = window.open('', '', 'width=600,height=800');
          myWindow.document.write(printableFax.innerHTML);
          myWindow.document.close();
          myWindow.focus();
          myWindow.print();
          myWindow.close();
          this.afterFaxPrint = true;
          this.isPrintWindowOpen = false;
        }, 50);
      });
  }

  modalWindowLegitimate(event) {
    event.stopPropagation();
    this.submitLegitimation();
    this.modalWindowActive = false;
    this.showFaxInfo = false;
  }

  leaveFaxTemplate(event) {
    event.stopPropagation();
    this.modalWindowActive = false;
    this.showFaxInfo = false;
  }

  /* selectFile() {
    (<HTMLInputElement>document.querySelector('.ui-fileupload-choose input')).click();
  }*/

  selectTypeChange(event, index) {
    this.fileTypes[index] = event.target.value;
  }

  remove(fileupload, event, index) {
    fileupload.remove(event, index);
    this.fileTypes.splice(index, 1);
  }

  onFilesSelected(event) {
    this.fileTypes.push(...Object.keys(event.files).map(() => 'corporateBookExtract'));
  }

  uploadLegitimationDone(event) {
    this.optionsValidity.file = true;

    if (<any>JSON.parse(event.xhr.response).hasOwnProperty('filesId')) {
      this.uploadedFilesIds = (<any>JSON.parse(event.xhr.response)).filesId;
    }
    this.isUploading = false;
    this.setLegitimationValidity();
    this.submitLegitimation();
  }

  onClear() {
    this.fileTypes.length = 0;
  }

  uploadLegitimationError() {
    this.isUploading = false;
    this.fileTypes.length = 0;
    this._translateService.get('upload.error').subscribe((msg: any) => {
      this._statusBarService.setStatus(msg, 'error');
    });
  }

  onUrlChange(event) {
    const isUrlValid = event.target.validity.valid;
    this.optionsValidity.url = isUrlValid;
    this.setLegitimationValidity();
  }

  onCommentChange() {
    const { isOptionalLegitimationCommentAllowed, isLegitimationWithCommentAllowed } = this.eventSetting;

    if (!isOptionalLegitimationCommentAllowed && isLegitimationWithCommentAllowed) {
      const isValid = this.legitimationComment.length >= 50 || false;
      this.optionsValidity.noFile = isValid;
      this.setLegitimationValidity();
    }
  }

  setLegitimationValidity() {
    // if optional comment is used make legitimation step valid
    if (
      this.eventSetting &&
      this.eventSetting.isOptionalLegitimationCommentAllowed &&
      this.selectedOption === 'noFile'
    ) {
      this.optionsValidity.noFile = true;
    }

    this.isButtonDisabled = !this.optionsValidity[this.selectedOption];

    const stepsFormsActionName = ['legitimation', 'validation'];
    if (this.isButtonDisabled) {
      this.formsService.addStepValidationFeedback(stepsFormsActionName, 'block', 'steps.missing-input.legitimation');
    } else {
      this.formsService.removeStepValidationFeedback(stepsFormsActionName, 'block');
    }
  }

  selectOption(option) {
    this.selectedOption = option;
    this.setLegitimationValidity();
  }

  submitLegitimation() {
    this.responsePending = true;
    this.isLegitimationSubmitted = true;
    this.showFaxInfo = false;
    //set the response to negative, so we know once it changes
    this.store.dispatch(new SetLegitimationInfoResponse(false));

    combineLatest([
      this.store.pipe(select(getSelectedExhibitionId)),
      this.store.pipe(select(getProfile)),
      this.store.pipe(select(getLanguage))
    ])
      .pipe(first())
      .subscribe(data => {
        const eventId = data[0];
        const userId = data[1].id;
        const currentLang = data[2];

        //check what was the post message response
        this.store
          .pipe(
            select(getLegitimationPostResponse),
            filter(response => {
              if (!response) {
                // there was a failure, so let the user resend (reenable the send button)
                this.responsePending = false;
              }
              return response;
            }), // it has boolean value, and we are iterested only in true (success) value
            first()
          )
          .subscribe(() => {
            this.store.dispatch(new SetLegitimationStatus(LegitimationStatus.InProgress));
          });

        this.store.dispatch(
          new PostLegitimationInfo({
            feed: {
              userLanguage: currentLang,
              legitimationType: this.selectedOption,
              comment: this.legitimationComment,
              url: this.legitimationUrl,
              faxId: this.faxId,
              fileId: this.uploadedFilesIds
            },
            userId,
            eventId
          })
        );
      });
  }

  beforeUpload() {
    this.isUploading = true;
  }

  triggerUpload(uploader) {
    uploader.upload();
  }

  myUploader(event, form) {
    let authToken = null;

    combineLatest([this.store.pipe(select(getUser)), this.store.pipe(select(getProfile))])
      .pipe(
        filter(data => !!data[0] || (data[1] && data[1].hasOwnProperty('authToken'))),
        first()
      )
      .subscribe(data => {
        const [user, profile] = data;
        if (user) {
          authToken = user.authToken;
        } else if (profile.hasOwnProperty('authToken')) {
          authToken = profile.authToken;
        }

        const files = event.files;
        let xhr = new XMLHttpRequest(),
          formData = new FormData();

        form.onBeforeUpload.emit({
          xhr: xhr,
          formData: formData
        });

        for (let i = 0; i < files.length; i++) {
          formData.append(this.fileTypes[i], files[i], files[i].name);
        }

        xhr.upload.addEventListener(
          'progress',
          (e: ProgressEvent) => {
            if (e.lengthComputable) {
              form.progress = Math.round((e.loaded * 100) / e.total);
            }

            form.onProgress.emit({ originalEvent: e, progress: form.progress });
          },
          false
        );

        xhr.onreadystatechange = () => {
          if (xhr.readyState == 4) {
            form.progress = 0;

            if (xhr.status >= 200 && xhr.status < 300) form.onUpload.emit({ xhr: xhr, files: files });
            else form.onError.emit({ xhr: xhr, files: files });

            form.clear();
          }
        };

        xhr.open(form.method, form.url, true);

        if (authToken) {
          xhr.setRequestHeader('Authorization', authToken);
        }

        form.onBeforeSend.emit({
          xhr: xhr,
          formData: formData
        });

        xhr.withCredentials = form.withCredentials;

        xhr.send(formData);
      });
  }

  onBackClick(): void {
    this._stepsFormsService.navigateRelativeTo(-1, this.router);
  }

  goToEvents() {
    this.router.navigate(['/']);
  }
}
