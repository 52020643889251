import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  WorkshopProduct,
  WorkshopProductList,
  WorkshopTariffHolder,
  WorkshopValidationState
} from '@products/models/workshop.model';
import { WorkshopValidationService } from '@products/services/workshop-validation.service';
import { WorkshopService } from '@products/services/workshop.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-workshops-visitors',
  templateUrl: './workshops-visitors.component.html',
  styleUrls: ['./workshops-visitors.component.scss']
})
export class WorkshopsVisitorsComponent implements OnInit, OnDestroy {
  @Input() workshop: WorkshopProduct;
  @Input() workshopsList: WorkshopProductList;
  @Input() workshopTariffHolders: WorkshopTariffHolder[];
  @Input() areAllWorkshopTariffHoldersAssigned: boolean;
  @Input() availableSeats: number;
  @Input() isWorkshopLoading: boolean;
  @Input() limitWorkshopPerTicket: number;
  @Input() enableWorkshopSelectionOverlapping: boolean;
  workshopValidationState: WorkshopValidationState & { isWorkshopValidatedStateValid: boolean };

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private statusBarService: StatusBarService,
    private workshopService: WorkshopService,
    private workshopValidationService: WorkshopValidationService
  ) {}

  ngOnInit() {
    this.initWorkshopValidationState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setWorkshopBookingHolder($event: MouseEvent, workshopTariffHolder: WorkshopTariffHolder) {
    $event.stopPropagation();

    this.workshopValidationState.validatedWorkshopTariffHolders = [workshopTariffHolder];
    this.workshopValidationState.isWorkshopValidatedStateValid = true;

    if (!workshopTariffHolder.isHolderAssigned) {
      this.checkWorkshopValidationSate([workshopTariffHolder]);
    }

    if (this.workshopValidationState.isWorkshopValidatedStateValid) {
      this.workshopService.setWorkshopBookingHolder(
        this.workshopValidationState.validatedWorkshopTariffHolders[0],
        this.workshopsList
      );
    }
  }

  setAllWorkshopBookingHolders($event: MouseEvent) {
    $event.stopPropagation();

    this.workshopValidationState.validatedWorkshopTariffHolders = this.workshopTariffHolders;
    this.workshopValidationState.isWorkshopValidatedStateValid = true;

    if (!this.areAllWorkshopTariffHoldersAssigned) {
      this.checkWorkshopValidationSate(this.workshopTariffHolders);
    }

    if (this.workshopValidationState.isWorkshopValidatedStateValid) {
      this.workshopService.setAllWorkshopBookingHolders(
        this.areAllWorkshopTariffHoldersAssigned,
        this.workshopValidationState.validatedWorkshopTariffHolders,
        this.workshopsList
      );
    }
  }

  initWorkshopValidationState() {
    this.workshopValidationState = {
      validatedWorkshopTariffHolders: [],
      isWorkshopAvailableSeatsLimitReached: false,
      areWorkshopsOverlapping: false,
      isWorkshopLimitPerTariffHolderReached: false,
      isWorkshopValidatedStateValid: true
    };
  }

  checkWorkshopValidationSate(workshopTariffHolders: WorkshopTariffHolder[]) {
    const {
      validatedWorkshopTariffHolders,
      isWorkshopAvailableSeatsLimitReached,
      isWorkshopLimitPerTariffHolderReached,
      areWorkshopsOverlapping
    } = this.workshopValidationService.validateWorkshop(
      workshopTariffHolders,
      this.workshopsList,
      this.availableSeats,
      this.limitWorkshopPerTicket,
      this.enableWorkshopSelectionOverlapping
    );

    const isWorkshopValidatedStateValid =
      !!validatedWorkshopTariffHolders.length &&
      !isWorkshopAvailableSeatsLimitReached &&
      !isWorkshopLimitPerTariffHolderReached &&
      !areWorkshopsOverlapping;

    this.workshopValidationState = {
      validatedWorkshopTariffHolders,
      isWorkshopAvailableSeatsLimitReached,
      isWorkshopLimitPerTariffHolderReached,
      areWorkshopsOverlapping,
      isWorkshopValidatedStateValid
    };

    this.setWorkshopSoldOutWarning();
  }

  setWorkshopSoldOutWarning() {
    if (this.workshopValidationState.isWorkshopAvailableSeatsLimitReached) {
      this.translateService.get('response.workshopFull').subscribe((workshopFullTranslation: string) => {
        this.statusBarService.setStatus(workshopFullTranslation, 'error');
      });
    }
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  closeModalWindow($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.workshopValidationState.isWorkshopLimitPerTariffHolderReached = false;
    this.workshopValidationState.areWorkshopsOverlapping = false;
  }
}
