<form
  *ngIf="form && inputs?.length && !hideFormInputs"
  [formGroup]="form"
  [ngClass]="{ readonly: !isEditEnabled && formType === 'edit-account' }"
  class="row"
>
  <app-df-input
    *ngFor="let input of inputs"
    [input]="input"
    [class]="input.cssClass"
    [inputSet]="inputs"
    [form]="form"
    [action]="editAccountAction"
    [saveFormCallback]="formSaveCallback"
    [autoFillEmail]="input.key === 'email'"
    [showCalendarButton]="!(!isEditEnabled && formType === 'edit-account')"
  >
  </app-df-input>
</form>

<app-buyer-info *ngIf="formType === 'new-account' && hideFormInputs"></app-buyer-info>

<div *ngIf="validationStepName && (settings$ | async).isContinueAsGuestEnabled" class="as-guest-checkbox">
  <div class="decorated-checkbox">
    <input id="asGuest" type="checkbox" (change)="setIsAsGuest($event)" [checked]="isContinueAsGuest" />
    <label for="asGuest"></label>
  </div>
  <div>
    <label class="black checkbox-label" for="asGuest">
      <span [attr.data-translation]="'profile.continue-as-guest'">
        {{ 'profile.continue-as-guest' | translate | hideTranslation }}
      </span>
    </label>
  </div>
</div>

<app-registration-questionnaire
  *ngIf="formType === 'new-account' && !isContinueAsGuest"
  (questionnaireChanged)="questionnaireDataChanged($event)"
  (isValid)="registrationQuestionnaireIsValid($event)"
></app-registration-questionnaire>

<div *ngIf="formType === 'edit-account'" class="col-sm-12">
  <div class="row">
    <div class="col-sm-4 col-md-4 offset-md-8">
      <button class="button edit-button" *ngIf="!isEditEnabled" (click)="onProfileEdit()">
        <span data-translation="profile.edit">
          {{ 'profile.edit' | translate | hideTranslation }}
        </span>
      </button>
    </div>
    <div class="col-sm-12 col-md-4 btn-cancel">
      <button
        (click)="cancelProfileEdit()"
        class="button reject-button"
        *ngIf="isEditEnabled"
        data-translation="profile.cancel"
      >
        {{ 'profile.cancel' | translate | hideTranslation }}
      </button>
    </div>
    <div class="feedback-button col-sm-12 col-md-4 offset-md-4 btn-save">
      <button class="button" *ngIf="isEditEnabled" [disabled]="!form.valid" (click)="sumbitProfileUpdate()">
        <span data-translation="profile.save">
          {{ 'profile.save' | translate | hideTranslation }}
        </span>
      </button>
      <div class="feedback-text" *ngIf="isEditEnabled && feedbackMessages && feedbackMessages.length">
        <app-feedback-message [feedbackMessages]="feedbackMessages" class="feedback-messages"></app-feedback-message>
      </div>
    </div>
  </div>
</div>
<div *ngIf="formType === 'new-account'" class="col-sm-12 col-md-4 offset-md-8">
  <!--Because firefox doesnt trigger events on disabled buttons we removed the disabled attribute from the button and added one parent div before it, to handle the click event. -->
  <div class="feedback-button">
    <button class="col-sm-12 button" type="submit" [disabled]="isButtonDisabled()" (click)="submitNewAccount($event)">
      <app-spinner *ngIf="isSpinnerActive$ | async" class="download-spinner"></app-spinner>
      <span
        class="profile.create-account"
        data-translation="step-navigation.continue-button"
        *ngIf="!isContinueAsGuest"
      >
        {{ 'profile.create-account' | translate | hideTranslation }}
      </span>

      <span *ngIf="isContinueAsGuest" data-translation="profile.guest.button">
        {{ 'profile.guest.button' | translate | hideTranslation }}
      </span>
    </button>
    <div class="feedback-text" *ngIf="isButtonDisabled() && feedbackMessages && feedbackMessages.length">
      <app-feedback-message [feedbackMessages]="feedbackMessages" class="feedback-messages"></app-feedback-message>
    </div>
  </div>
</div>
