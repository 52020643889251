import cloneDeep from 'lodash.clonedeep';
import { Actions, ActionTypes } from './legitimation.actions';
import { LegitimationListModel, LegitimationStatus } from './legitimation.interface';

export interface State {
  legitimations: LegitimationListModel[];
  legitimationStatus: LegitimationStatus;
  legitimationFaxId: number;
  legitimationPostResponse: boolean;
}

export const initialState: State = {
  legitimations: [],
  legitimationStatus: null,
  legitimationFaxId: null,
  legitimationPostResponse: false
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.ADD_LEGITIMATION_TO_LIST:
      return {
        ...state,
        legitimations: action.payload
      };

    case ActionTypes.SET_LEGITIMATION_STATUS:
      return {
        ...state,
        legitimationStatus: action.payload
      };

    case ActionTypes.SET_LEGITIMATION_FAX_ID:
      return {
        ...state,
        legitimationFaxId: action.payload
      };

    case ActionTypes.SET_LEGITIMATION_INFO_RESPONSE:
      return {
        ...state,
        legitimationPostResponse: action.payload
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}

export const getLegitimationList = (state: State) => state.legitimations;
export const getLegitimationStatus = (state: State) => state.legitimationStatus;
export const getLegitimationFaxId = (state: State) => state.legitimationFaxId;
export const getLegitimationPostResponse = (state: State) => state.legitimationPostResponse;
