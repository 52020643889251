import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/app.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PackageTooltip, PackageType } from '@products/models/package.model';
import {
  ProductSelectionBookingStatusViewModel,
  ProductSelectionTariffStatusViewModel,
  ProductSelectionViewModel,
  ProductSelectionWorkshopStatusViewModel
} from '@products/models/product-selection.model';
import {
  Tariff,
  TariffClassification,
  TariffCurrencyCodes,
  TariffInfoExpandedTranslationResponse,
  TariffType,
  TariffValidationStateViewModel,
  TariffWarningMessage
} from '@products/models/tariff.model';
import { VoucherCode, VoucherType } from '@products/models/voucher.model';
import { PreferredTariffValidationService } from '@products/services/preferred-tariff-validation.service';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { TariffValidationService } from '@products/services/tariff-validation.service';
import { TariffService } from '@products/services/tariff.service';
import { VoucherService } from '@products/services/voucher.service';
import { AppConstants } from '@shared/app-constants';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import {
  ActionTypes as BookingActionTypes,
  RemoveProductFromBookingListError,
  SetProductInBookingListError
} from '@store/products/booking/booking.actions';
import {
  areBookedTariffReservationsValid,
  getBookedTariffCount,
  getBookedTariffParkingReservationTotalPrice
} from '@store/products/booking/booking.selectors';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tariff',
  providers: [TariffService, TariffValidationService],
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TariffComponent implements OnInit, OnChanges, OnDestroy {
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() tariffStatusViewModel: ProductSelectionTariffStatusViewModel;
  @Input() workshopStatusViewModel: ProductSelectionWorkshopStatusViewModel;
  @Input() bookingStatusViewModel: ProductSelectionBookingStatusViewModel;
  @Input() packageType: PackageType;
  @Input() tariffType: TariffType;
  @Input() tariff: Tariff;
  @Input() voucherCodeReleased$: Subject<VoucherCode>;
  tariffValidationViewModel: TariffValidationStateViewModel;
  count: number;
  isInfoExpandable: boolean;
  isInfoExpanded: boolean;
  showMobilePackageTooltipMessageTimeout: NodeJS.Timer;
  areBookedTariffReservationsValid$: Observable<Boolean>;
  parkingReservationTotalPrice$: Observable<number>;

  readonly TariffCurrencyCodes = TariffCurrencyCodes;
  readonly TariffWarningMessage = TariffWarningMessage;
  readonly TariffClassification = TariffClassification;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private router: ActivatedRoute,
    private store: Store<State>,
    private translateService: TranslateService,
    private tariffService: TariffService,
    private voucherService: VoucherService,
    private tariffValidationService: TariffValidationService,
    private tariffCountValidationService: TariffCountValidationService,
    private preferredTariffValidationService: PreferredTariffValidationService
  ) {}

  ngOnInit() {
    this.initTariffCounterSubscription();
    this.initBookedTariffReservationsValidityCheck();
    this.initBookedTariffParkingReservationsTotalPrice();
    this.initTariffValidationServiceState();
    this.initTariffValidationServiceSubscription();
    this.initPreferredTariffBookingValidationSubscription();
    this.initWidgetBannerTariffBookingValidationSubscription();
    this.initFailedBookingValidationStateRevalidationSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateTariffInfoState();
    this.updateTariffValidationState();
    this.revalidateCounterOnBookingCountDecreaseStateChange(changes);
    this.revalidateIsTariffSoldOutOnBookingCountIncreaseStateChange(changes);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initTariffCounterSubscription() {
    this.store
      .pipe(
        select(
          getBookedTariffCount(
            this.tariff.ticketTypeId,
            this.tariff.ticketPersonId,
            this.tariff.voucherCode,
            this.tariff.packageNumber,
            this.tariff.packageIndex
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(count => {
        this.updateCountOnTariffBookingCountChange(count);
        this.clearVoucherInputAndWarning();
      });
  }

  initBookedTariffReservationsValidityCheck() {
    this.areBookedTariffReservationsValid$ = this.store.pipe(
      select(
        areBookedTariffReservationsValid(
          this.exhibitionSettings.isWorkshopsSelectionMandatory,
          this.exhibitionSettings.workshopMandatoryForZeroPriceTickets,
          this.tariff.ticketTypeId,
          this.tariff.ticketPersonId,
          this.tariff.voucherCode,
          this.tariff.packageNumber,
          this.tariff.packageIndex
        )
      )
    );
  }

  initBookedTariffParkingReservationsTotalPrice() {
    this.parkingReservationTotalPrice$ = this.store.pipe(
      select(
        getBookedTariffParkingReservationTotalPrice(
          this.tariff.ticketTypeId,
          this.tariff.ticketPersonId,
          this.tariff.voucherCode,
          this.tariff.packageNumber,
          this.tariff.packageIndex
        )
      )
    );
  }

  initTariffValidationServiceState() {
    const {
      ticketPersonId,
      availableTickets,
      ticketLimit,
      classification,
      allowedWorkshops,
      isVoucher,
      voucherType,
      voucherCountLimit,
      packageSettings
    } = this.tariff;

    const {
      limitBoughtTickets,
      ticketLimitPerUserAccount,
      limitPromoCodes,
      limitLimitedPromocodes,
      isWorkshopsSelectionMandatory,
      isVoucherIncludedPerUserAccountLimit
    } = this.exhibitionSettings;

    const { currentUserAccountTicketLimit, availableTariffs } = this.tariffStatusViewModel;

    const {
      bookedProductsCount,
      bookedPromoCodeVouchersCount,
      bookedLimitedPromoCodeVouchersCount,
      bookedOnetimeVouchersCount
    } = this.bookingStatusViewModel;

    const {
      allowedWorkshopsSeatsAvailable,
      hideWorkshopTariffCounter,
      areAllAllowedWorkshopsAssigned
    } = this.tariffCountValidationService.tariffAllowedWorkshopsStatus(
      this.count,
      this.tariff,
      this.workshopStatusViewModel.availableSeats
    );

    this.tariffValidationService.initAndValidateState({
      count: this.count,
      validatedCount: this.count,
      previousValidatedCount: this.count,
      bookedCount: this.count,
      validatedMaxLimit: this.count,
      isTariffSoldOut: this.tariffCountValidationService.isTariffSoldOut(this.count, availableTickets),
      isAvailableTariffsLimitReached: this.tariffCountValidationService.isTariffSoldOut(
        this.count,
        availableTariffs[ticketPersonId]
      ),
      numberOfAllBookedTariffs: bookedProductsCount,
      initialNumberOfAvailableTariffs: availableTickets,
      currentNumberOfAvailableTariffs: availableTariffs[ticketPersonId],
      percentageOfAvailableTariffs: this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(
        this.count,
        ticketLimit,
        availableTariffs[ticketPersonId]
      ),
      tariffLimit: ticketLimit,
      maxTariffLimit: limitBoughtTickets,
      tariffLimitPerUserAccount: ticketLimitPerUserAccount,
      currentUserAccountTariffLimit: currentUserAccountTicketLimit,
      isCurrentUserAccountTariffLimitReached: this.tariffCountValidationService.isCurrentUserAccountTariffLimitReached(
        ticketLimitPerUserAccount,
        currentUserAccountTicketLimit
      ),
      voucherCountLimit: voucherCountLimit,
      maxVoucherLimit: limitPromoCodes,
      maxLimitedVoucherLimit: limitLimitedPromocodes,
      numberOfBookedVouchers: bookedPromoCodeVouchersCount,
      numberOfBookedLimitedVouchers: bookedLimitedPromoCodeVouchersCount,
      numberOfBookedOneTimeVouchers: bookedOnetimeVouchersCount,
      isTariffClassificationNormal: classification === TariffClassification.Normal,
      isTariffClassificationParking: classification === TariffClassification.Parking,
      hideWorkshopTariffCounter: hideWorkshopTariffCounter,
      allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
      areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
      isWorkshopsSelectionMandatoryAndHasWorkshops: isWorkshopsSelectionMandatory && allowedWorkshops.length > 0,
      isVoucher: isVoucher,
      isVoucherTypePromoCode: voucherType === VoucherType.PromoCode,
      isVoucherTypeLimitedPromoCode: voucherType === VoucherType.LimiterPromoCode,
      isVoucherTypeOneTimeVoucher: voucherType === VoucherType.OneTimeVoucher,
      isVoucherIncludedPerUserAccountLimit: isVoucherIncludedPerUserAccountLimit,
      isPackageTypeAndIsPackageNotAdded: this.isPackageTypeAndIsPackageNotAdded(),
      packageSettings: packageSettings,
      packageTooltipMessage: this.showPackageTooltipMessage()
    });
  }

  initTariffValidationServiceSubscription() {
    this.tariffValidationService.tariffValidationState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(tariffValidationState => {
        this.tariffValidationViewModel = tariffValidationState;

        this.updateCount();
      });
  }

  initPreferredTariffBookingValidationSubscription() {
    this.preferredTariffValidationService.preferredTariffValidationState$
      .pipe(
        filter(
          ({ ticketTypeId, ticketPersonTypeId }) =>
            ticketTypeId === this.tariff.ticketTypeId &&
            ticketPersonTypeId === this.tariff.ticketPersonTypeId &&
            !this.tariff.voucherCode &&
            !this.tariff.packageNumber &&
            !this.tariff.packageIndex
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ validatedMaxLimit, tariffLimitWarningMessage }) => {
        this.tariffValidationViewModel.validatedMaxLimit = validatedMaxLimit;
        this.tariffValidationViewModel.tariffLimitWarningMessage = tariffLimitWarningMessage;
      });
  }

  initWidgetBannerTariffBookingValidationSubscription() {
    this.router.queryParams
      .pipe(
        filter(queryParams => {
          if (queryParams.ticket) {
            const [ticketTypeId, ticketPersonTypeId] = queryParams.ticket.split('_');

            return (
              +ticketTypeId === this.tariff.ticketTypeId &&
              +ticketPersonTypeId === this.tariff.ticketPersonTypeId &&
              !this.tariff.packageNumber &&
              !this.tariff.packageIndex
            );
          }

          return false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.validateCount(AppConstants.WIDGET_BANNER_PRODUCT_DEFAULT_AMOUNT);
      });
  }

  initFailedBookingValidationStateRevalidationSubscription() {
    this.actions$
      .pipe(
        ofType<SetProductInBookingListError | RemoveProductFromBookingListError>(
          BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST_ERROR,
          BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const { validatedCount, bookedCount } = this.tariffValidationViewModel;
        const revalidateValidationStateAfterFailedBooking = validatedCount !== bookedCount;

        if (revalidateValidationStateAfterFailedBooking) {
          this.validateCount(bookedCount);
        }
      });
  }

  updateTariffInfoState() {
    const { info, infoExpanded } = this.tariff;

    if (info && infoExpanded) {
      this.isInfoExpanded = this.translateService.instant(infoExpanded) === TariffInfoExpandedTranslationResponse.True;
      this.isInfoExpandable = this.translateService.instant(info) !== AppConstants.MISSING_TRANSLATION;
    }
  }

  updateTariffValidationState() {
    if (this.tariffValidationViewModel && this.tariffStatusViewModel && this.bookingStatusViewModel) {
      const { ticketPersonId, ticketLimit } = this.tariff;
      const { currentUserAccountTicketLimit, availableTariffs } = this.tariffStatusViewModel;
      const {
        bookedProductsCount,
        bookedPromoCodeVouchersCount,
        bookedLimitedPromoCodeVouchersCount,
        bookedOnetimeVouchersCount
      } = this.bookingStatusViewModel;

      const {
        allowedWorkshopsSeatsAvailable,
        hideWorkshopTariffCounter,
        areAllAllowedWorkshopsAssigned
      } = this.tariffCountValidationService.tariffAllowedWorkshopsStatus(
        this.count,
        this.tariff,
        this.workshopStatusViewModel.availableSeats
      );

      const updateTariffValidationViewModel: Partial<TariffValidationStateViewModel> = {
        count: this.count,
        validatedCount: this.count,
        currentUserAccountTariffLimit: currentUserAccountTicketLimit,
        numberOfAllBookedTariffs: bookedProductsCount,
        numberOfBookedVouchers: bookedPromoCodeVouchersCount,
        numberOfBookedLimitedVouchers: bookedLimitedPromoCodeVouchersCount,
        numberOfBookedOneTimeVouchers: bookedOnetimeVouchersCount,
        currentNumberOfAvailableTariffs: availableTariffs[ticketPersonId],
        hideWorkshopTariffCounter: hideWorkshopTariffCounter,
        allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
        areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
        isPackageTypeAndIsPackageNotAdded: this.isPackageTypeAndIsPackageNotAdded(),
        packageTooltipMessage: this.showPackageTooltipMessage(),
        percentageOfAvailableTariffs: this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(
          this.count,
          ticketLimit,
          availableTariffs[ticketPersonId]
        )
      };

      this.tariffValidationViewModel = { ...this.tariffValidationViewModel, ...updateTariffValidationViewModel };
    }
  }

  revalidateCounterOnBookingCountDecreaseStateChange(changes: SimpleChanges) {
    const { bookingStatusViewModel } = changes;

    if (bookingStatusViewModel) {
      const currentChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.currentValue;
      const previousChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.previousValue;

      if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
        return;
      }

      const isBookedProductsCountDecreased =
        previousChangedBookingStatusViewModel.bookedProductsCount >
        currentChangedBookingStatusViewModel.bookedProductsCount;

      if (isBookedProductsCountDecreased) {
        this.tariffValidationService.revalidateDisabledCounter(this.tariffValidationViewModel);
        this.tariffValidationService.revalidateIsAvailableTariffsLimitReached(this.tariffValidationViewModel);
      }
    }
  }

  revalidateIsTariffSoldOutOnBookingCountIncreaseStateChange(changes: SimpleChanges) {
    const { bookingStatusViewModel, tariffStatusViewModel } = changes;

    if (bookingStatusViewModel && tariffStatusViewModel) {
      const currentTariffStatusViewModel: ProductSelectionTariffStatusViewModel = tariffStatusViewModel.currentValue;
      const isTariffNotSoldOut = currentTariffStatusViewModel.availableTariffs[this.tariff.ticketPersonId];

      if (isTariffNotSoldOut) {
        return;
      }

      const currentChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.currentValue;
      const previousChangedBookingStatusViewModel: ProductSelectionBookingStatusViewModel =
        bookingStatusViewModel.previousValue;

      if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
        return;
      }

      const isBookedProductsCountIncreased =
        currentChangedBookingStatusViewModel.bookedProductsCount >
        previousChangedBookingStatusViewModel.bookedProductsCount;

      if (isBookedProductsCountIncreased) {
        this.tariffValidationService.revalidateIsAvailableTariffsLimitReached(this.tariffValidationViewModel);
      }
    }
  }

  toggleTariffInfo($event: MouseEvent) {
    $event.stopPropagation();

    if (!this.isInfoExpandable) {
      return;
    }

    this.isInfoExpanded = !this.isInfoExpanded;
  }

  isPackageTypeAndIsPackageNotAdded(): boolean {
    return this.packageType && this.packageType.numberOfAddedPackages === 0;
  }

  showPackageTooltipMessage(): string {
    return this.isPackageTypeAndIsPackageNotAdded() ? PackageTooltip.PACKAGE_COUNTER_TOOLTIP_MESSAGE : '';
  }

  showMobilePackageTooltipMessage() {
    this.tariffValidationViewModel.showMobilePackageTooltipMessage =
      this.productSelectionViewModel.isMobile && this.isPackageTypeAndIsPackageNotAdded();

    clearTimeout(this.showMobilePackageTooltipMessageTimeout);

    this.showMobilePackageTooltipMessageTimeout = setTimeout(() => {
      this.tariffValidationViewModel.showMobilePackageTooltipMessage = false;
    }, PackageTooltip.PACKAGE_COUNTER_TOOLTIP_TIMEOUT);
  }

  updateCountOnTariffBookingCountChange(count: number) {
    this.count = count;

    if (this.tariffValidationViewModel) {
      this.tariffValidationViewModel.count = count;
      this.tariffValidationViewModel.validatedCount = count;
      this.tariffValidationViewModel.bookedCount = count;
    }
  }

  onClickSelfRegistrationTariff($event: MouseEvent) {
    $event.stopPropagation();

    if (this.tariffValidationViewModel.isTariffSoldOut) {
      return;
    }

    this.tariffService.setSelfRegistrationTariff(this.tariffType, this.tariff, this.count);
  }

  validateCount(updateTariffCount: number) {
    this.tariffValidationService.validateState(updateTariffCount, this.tariffValidationViewModel);
  }

  updateCount() {
    const { validatedCount, previousValidatedCount, bookedCount } = this.tariffValidationViewModel;
    const isCountUnchanged = validatedCount === previousValidatedCount;
    const isStateRevalidationAfterFailedBooking = validatedCount === bookedCount;

    if (isCountUnchanged || isStateRevalidationAfterFailedBooking) {
      return;
    }

    if (this.packageType) {
      this.tariffService.updatePackageTariffCount(
        this.packageType,
        this.tariffType,
        this.tariff,
        previousValidatedCount,
        validatedCount
      );
    } else {
      this.tariffService.updateTariffCount(this.tariffType, this.tariff, previousValidatedCount, validatedCount);
    }
  }

  releaseVoucher($event: MouseEvent) {
    $event.stopPropagation();

    this.voucherService.releaseVoucher(this.tariff.ticketPersonId, this.tariff.voucherCode);
    this.voucherCodeReleased$.next(this.tariff.voucherCode);
  }

  clearVoucherInputAndWarning() {
    if (this.tariff.isVoucher) {
      this.voucherService.clearVoucherInputAndWarning();
    }
  }
}
