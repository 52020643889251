<ng-container *ngIf="!productSelectionViewModel.isSelfRegistration && tariff.isVisible">
  <div
    [ngClass]="{
      'has-voucher': tariff.voucherCode,
      'has-ticketAddedByUrl': !tariff.voucherCode && tariffType.preferredTicket && count,
      'parking-ticket': tariff.classification === TariffClassification.Parking || tariff.hasDaySellLimit,
      'ng-custom-invalid':
        !(areBookedTariffReservationsValid$ | async) || tariffValidationViewModel.tariffLimitWarningMessage,
      'ticket-type': !packageType,
      'package-ticket-type': !!packageType
    }"
    (click)="showMobilePackageTooltipMessage()"
  >
    <ng-container *ngIf="productSelectionViewModel.isMobile">
      <div class="ticket-text">
        <span class="ticket-accordion-icon" *ngIf="isInfoExpandable">
          <svg class="icon" (click)="toggleTariffInfo($event)">
            <use xlink:href="assets/defs.svg#icon-info"></use>
          </svg>
        </span>
        <span
          class="title black"
          [ngClass]="{ 'clickable-ticket-version': isInfoExpandable, 'package-title-black': !!packageType }"
          (click)="toggleTariffInfo($event)"
        >
          {{ tariff.name | translate | hideTranslation }}
        </span>
        <svg class="workshops-icon" *ngIf="tariff.allowedWorkshops?.length">
          <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
        </svg>
      </div>
      <div class="row col-sm-12 ticket-description" *ngIf="isInfoExpanded">
        <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
        <span
          *ngIf="productSelectionViewModel.isTranslationLoaded"
          [appRichText]="tariff.info | translate | hideTranslation"
        ></span>
      </div>
    </ng-container>

    <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>

    <div class="ticket-type-row" *ngIf="productSelectionViewModel.isTranslationLoaded">
      <div class="ticket-info">
        <div class="ticket-text ticket-text-grow" *ngIf="!productSelectionViewModel.isMobile">
          <span class="ticket-accordion-icon" *ngIf="isInfoExpandable">
            <svg class="icon" (click)="toggleTariffInfo($event)">
              <use xlink:href="assets/defs.svg#icon-info"></use>
            </svg>
          </span>
          <span
            class="title black"
            [ngClass]="{ 'package-title-black': !!packageType, 'clickable-ticket-version': isInfoExpandable }"
            (click)="toggleTariffInfo($event)"
          >
            {{ tariff.name | translate | hideTranslation }}
          </span>
          <svg class="workshops-icon" *ngIf="tariff.allowedWorkshops?.length">
            <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
          </svg>
        </div>
        <div class="ticket-text ticket-price">
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode === TariffCurrencyCodes.CHF" class="price">
            <span *ngIf="tariff.classification === TariffClassification.Parking">
              {{ parkingReservationTotalPrice$ | async | formatPrice: exhibitionSettings.currencySettings }}
            </span>
            <span *ngIf="tariff.classification !== TariffClassification.Parking">
              {{ tariff.price | formatPrice: exhibitionSettings.currencySettings:true:false }}
            </span>
          </span>
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode !== TariffCurrencyCodes.CHF" class="price">
            <span *ngIf="tariff.classification === TariffClassification.Parking">
              {{ parkingReservationTotalPrice$ | async | formatPrice: exhibitionSettings.currencySettings }}
            </span>
            <span *ngIf="tariff.classification !== TariffClassification.Parking">
              {{ tariff.price | formatPrice: exhibitionSettings.currencySettings }}
            </span>
          </span>
          <div class="voucher-list" *ngIf="tariff.voucherCode">
            <span class="voucher">
              {{ tariff.voucherCode }}
              <svg class="remove-voucher" (click)="releaseVoucher($event)">
                <use :xlink:href="assets/defs.svg#icon-cross" href="assets/defs.svg#icon-cross"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <app-tariff-counter
        [tariffValidationViewModel]="tariffValidationViewModel"
        [isTariffLoading]="tariffStatusViewModel.isTariffLoading"
        [isWorkshopDetailModalOpen]="workshopStatusViewModel.isWorkshopDetailModalOpen"
        [matTooltip]="
          (packageType && packageType.numberOfAddedPackages === 0 ? 'packages.package-counter-message' : '')
            | translate
            | hideTranslation
        "
        (validateTariffCountEvent)="validateCount($event)"
      ></app-tariff-counter>

      <div class="package-mobile-tooltip-wrapper" *ngIf="tariffValidationViewModel.showMobilePackageTooltipMessage">
        <div class="package-mobile-tooltip-message">
          <span>{{ tariffValidationViewModel.packageTooltipMessage | translate | hideTranslation }}</span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!productSelectionViewModel.isWidget">
      <ng-content select="[webShopContingentTariff]"></ng-content>
      <ng-content select="[webShopParkingTariff]"></ng-content>
    </ng-container>

    <div class="row col-sm-12 ticket-description" *ngIf="!productSelectionViewModel.isMobile && isInfoExpanded">
      <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
      <span
        *ngIf="productSelectionViewModel.isTranslationLoaded"
        [appRichText]="tariff.info | translate | hideTranslation"
      ></span>
    </div>

    <ng-container *ngIf="!productSelectionViewModel.isWidget">
      <ng-content select="[webShopWorkshopTariff]"></ng-content>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="productSelectionViewModel.isSelfRegistration && tariff.isVisible">
  <div
    class="self-registration-ticket-type"
    [ngClass]="{
      'has-voucher': tariff.voucherCode,
      'has-ticketAddedByUrl': !tariff.voucherCode && tariffType.preferredTicket && count,
      'parking-ticket': tariff.classification === TariffClassification.Parking || tariff.hasDaySellLimit,
      'ng-custom-invalid': !(areBookedTariffReservationsValid$ | async),
      'ticket-type': !packageType,
      'package-ticket-type': !!packageType
    }"
  >
    <div class="ticket-info" (click)="onClickSelfRegistrationTariff($event)">
      <div class="row">
        <div
          class="ticket-text"
          [ngClass]="{
            'col-sm-9': !tariff.voucherCode,
            'col-sm-7': tariff.voucherCode
          }"
        >
          <div class="gap-10"></div>
          <svg class="icon-checked icon" *ngIf="count">
            <use xlink:href="assets/defs.svg#icon-checkmark"></use>
          </svg>
          <svg class="icon-add icon" *ngIf="!count">
            <use
              xlink:href="assets/defs.svg#icon-add"
              [ngClass]="{
                'icon-sold-out': tariffValidationViewModel.isTariffSoldOut
              }"
            ></use>
          </svg>
          <span class="title black">
            {{ tariff.name | translate | hideTranslation }}
          </span>
          <svg class="workshops-icon" *ngIf="tariff.allowedWorkshops?.length">
            <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
          </svg>

          <div
            class="sold-out"
            *ngIf="tariffValidationViewModel.isTariffSoldOut"
            [attr.data-translation]="TariffWarningMessage.TARIFF_SOLD_OUT"
          >
            {{ TariffWarningMessage.TARIFF_SOLD_OUT | translate | hideTranslation }}
          </div>
        </div>
        <div
          class="ticket-text text-right"
          [ngClass]="{
            'col-sm-3': !tariff.voucherCode,
            'col-sm-5': tariff.voucherCode
          }"
        >
          <span class="price" *ngIf="exhibitionSettings.currencySettings.currencyCode === TariffCurrencyCodes.CHF">
            {{ tariff.price | formatPrice: exhibitionSettings.currencySettings:true:false }}
          </span>
          <span class="price" *ngIf="exhibitionSettings.currencySettings.currencyCode !== TariffCurrencyCodes.CHF">
            {{ tariff.price | formatPrice: exhibitionSettings.currencySettings }}
          </span>
          <div class="voucher-list" *ngIf="tariff.voucherCode">
            <span class="voucher">
              {{ tariff.voucherCode }}
              <svg class="remove-voucher" (click)="releaseVoucher($event)">
                <use :xlink:href="assets/defs.svg#icon-cross" href="assets/defs.svg#icon-cross"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-content select="[selfRegistrationContingentTariff]"></ng-content>
</ng-container>
