var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { getUUID } from '@shared/app-utils';
import { ActionTypes as HolderActionTypes } from '@store/products/holder/holder.actions';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';
export var initialState = {
    list: [],
    ticketHolder: null,
    ticketHolderFormSubmitResult: null,
    inputs: [],
    questionnaireInputs: [],
    activeSlide: 0
};
export var holderReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HolderActionTypes.CREATE_PRODUCT_HOLDER: {
            var numberOfHoldersToAdd = action.payload || 0;
            var addedHolderUuidsList = Array(numberOfHoldersToAdd)
                .fill(null)
                .map(getUUID);
            return __assign({}, state, { list: state.list.concat(addedHolderUuidsList) });
        }
        // Uncomment when holders will be assigned in personalization step
        // case HolderActionTypes.SET_PRODUCT_HOLDER: {
        //   const newProductHolderUuids: HolderUuids = action.payload;
        //   cloneState.list = newProductHolderUuids;
        //   return cloneState;
        // }
        // case HolderActionTypes.REMOVE_PRODUCT_HOLDER: {
        //   const holderUuidsToDelete: HolderUuids = action.payload;
        //   if (holderUuidsToDelete.length) {
        //     cloneState.list = cloneState.list.filter(holderUuid => !holderUuidsToDelete.includes(holderUuid)) || [];
        //   }
        //   return cloneState;
        // }
        case HolderActionTypes.SET_PRODUCT_HOLDER: {
            var addedProductHolderUuids = action.payload;
            return __assign({}, state, { list: state.list.concat(addedProductHolderUuids) });
        }
        case HolderActionTypes.REMOVE_PRODUCT_HOLDER: {
            var bookedHolderUuidsToKeep_1 = action.payload;
            if (!bookedHolderUuidsToKeep_1.length) {
                return __assign({}, state, { list: [] });
            }
            return __assign({}, state, { list: cloneDeep(state.list.filter(function (holderUuid) { return bookedHolderUuidsToKeep_1.includes(holderUuid); })) || [] });
        }
        case HolderActionTypes.REMOVE_ALL_PRODUCT_HOLDERS_AND_SET_ANONYMOUS_HOLDER: {
            var list = action.payload;
            return __assign({}, state, { list: list });
        }
        case HolderActionTypes.SET_ACTIVE_SLIDE: {
            return __assign({}, state, { activeSlide: state.list.length > 1 ? action.payload : 0 });
        }
        case HolderActionTypes.SET_QUESTIONNAIRE_INPUTS: {
            var questionnaireInputs = action.payload;
            return __assign({}, state, { questionnaireInputs: questionnaireInputs });
        }
        case HolderActionTypes.SET_TICKET_HOLDER: {
            var ticketHolder = action.payload;
            return __assign({}, state, { ticketHolder: ticketHolder });
        }
        case HolderActionTypes.SET_TICKET_HOLDER_FORM_SUBMIT_RESULT: {
            var ticketHolderFormSubmitResult = action.payload;
            return __assign({}, state, { ticketHolderFormSubmitResult: ticketHolderFormSubmitResult });
        }
        case HolderActionTypes.SET_TICKET_HOLDER_INPUTS: {
            var inputs = action.payload;
            return __assign({}, state, { inputs: inputs });
        }
        case HolderActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_HOLDER_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
