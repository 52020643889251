import { combineLatest, Subscription } from 'rxjs';

import { filter, first } from 'rxjs/operators';
import {
  getBuyerActiveBillingAddressId,
  getExhibitionSettings,
  getProfile,
  getProfileBillingAddress,
  getSelectedBillingAddressId,
  State
} from '@app/app.reducer';
import {
  DeleteProfileBillingAddress,
  GetProfileBillingAddress,
  PutProfileBillingAddress,
  SetSelectedBillingAddress
} from '@store/user/user.actions';

import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { SetBuyerActiveBillingAddressId } from '@store/step-forms/steps-forms.actions';
import { BillingAddressModel, UserProfileModel } from '@store/user/user.interface';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { getProfileBillingAddressEditForm } from './form-inputs.edit-billing-address';

@Component({
  moduleId: module.id,
  selector: 'app-edit-billing-address-form',
  templateUrl: './edit-billing-address-form.component.html',
  styleUrls: ['./edit-billing-address-form.component.scss']
})
export class EditBillingAddressFormComponent implements OnInit, OnDestroy {
  @Input() isEditEnabled: boolean;

  selectedAddressId: number = 0;
  editAccountAction: string[] = ['editAccountAddressForm'];
  form: FormGroup;
  inputs: InputBase<any>[];
  billingAddressList: Array<BillingAddressModel>;

  private userId: number;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private customizationService: CustomizationService,
    private store: Store<State>,
    private formsService: FormsService,
    private helperService: HelperService
  ) {
    this.store.dispatch(new SetSelectedBillingAddress(0));

    this.store.pipe(
      select(getProfile),
      first(profile => !!profile)
    )
    .subscribe((user: UserProfileModel) => this.userId = user.id);
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);

    if (rerender) {
      this.form = this.formsService.toFormGroup(this.inputs);
    }
  };

  ngOnInit() {
    // initial billing address was loaded from API in profile component
    this.subscriptions.add(
      combineLatest([
        this.store.pipe(select(getProfileBillingAddress)),
        this.store.pipe(select(getSelectedBillingAddressId)),
        this.store.pipe(select(getExhibitionSettings))
      ])
      .pipe(
        // settings are not necessary as user can access profile without exhibition selected
        filter(([profileBillingAddress]) => !!profileBillingAddress.length)
      )
      .subscribe(([billingAddressList, selectedBillingAddressId, settings]) =>
        setTimeout(() => {
          this.billingAddressList = billingAddressList;
          this.selectedAddressId = selectedBillingAddressId;

          if (this.billingAddressList.length) {
            const billingAddress: BillingAddressModel = this.billingAddressList[selectedBillingAddressId];
            const isGoogleAutocompleteEnabled = settings && settings.isGoogleAutocompleteEnabled ? true : false;
            const profileEditForm = getProfileBillingAddressEditForm(
              isGoogleAutocompleteEnabled,
              this.customizationService,
              billingAddress
            );

            this.inputs = this.formsService.updateInputs(this.inputs, profileEditForm);
            this.form = this.formsService.toFormGroup(profileEditForm);

            this.enableOrDisableInputs(this.isEditEnabled);
          }
        })
      )
    );
  }

  ngOnDestroy() {
    // initial billing address was loaded from API in profile component
    this.subscriptions.unsubscribe();
  }

  loadBillingAddress() {
    this.store.dispatch(new GetProfileBillingAddress(this.userId));
  }

  deleteBillingAddress(addressId) {
    const addressToDelete = this.billingAddressList[addressId];

    this.store.dispatch(
      new DeleteProfileBillingAddress({
        userId: this.userId,
        addressId: addressToDelete.id
      })
    );

    if (this.billingAddressList.length) {
      this.store.dispatch(new SetSelectedBillingAddress(0));
    }

    this.store.pipe(
      select(getBuyerActiveBillingAddressId),
      first(selectedBuyerBillAddress => selectedBuyerBillAddress === addressToDelete.id)
    )
    .subscribe(() => this.store.dispatch(new SetBuyerActiveBillingAddressId(null)))
  }

  saveBillingAddress(addressId) {
    const dataToSave = <BillingAddressModel>(this.helperService.processFormValuesBeforeSave(this.form.value));
    const databseAddressId = this.billingAddressList[addressId].id;
    dataToSave.address = dataToSave.address || '';

    this.store.dispatch(
      new PutProfileBillingAddress({
        userId: this.userId,
        addressId: databseAddressId,
        data: dataToSave
      })
    );

    this.enableOrDisableInputs(this.isEditEnabled = false);
  }

  cancelEdit() {
    this.loadBillingAddress();
    this.enableOrDisableInputs(this.isEditEnabled = false);
  }

  beginEdit() {
    this.enableOrDisableInputs(this.isEditEnabled = true);
  }

  // on change selected billing address
  onChange(event) {
    this.store.dispatch(new SetSelectedBillingAddress(event.target.value));
  }

  enableOrDisableInputs(enable: boolean): void {
    if (enable === undefined) {
      enable = false;
    }

    this.inputs.forEach((input: InputBase<any>) => input.disabled = !enable);
  }
}
