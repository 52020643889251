<ng-container *ngIf="voucherViewModel$ | async as voucherViewModel">
  <div class="input-wrapper voucher-box" [ngClass]="{ 'self-reg': voucherViewModel.isSelfRegistration }">
    <label
      *ngIf="!voucherViewModel.isSelfRegistration"
      for="voucher-code"
      class="voucher-label"
      data-translation="ticket-selection.voucher.title"
    >
      {{ 'ticket-selection.voucher.title' | translate | hideTranslation }}
    </label>
    <div class="scan" *ngIf="voucherViewModel.isSelfRegistration">
      <img class="scan-image" [src]="scanImageUrl" />
      <div class="scan-text-box">
        <div class="scan-text">
          <span data-translation="ticket.selfregistration.voucher.scan-text">{{
            'ticket.selfregistration.voucher.scan-text' | translate | hideTranslation
          }}</span>
        </div>
        <div class="scan-arrow"></div>
      </div>
    </div>

    <div class="voucher-form">
      <form [formGroup]="voucherForm" (submit)="onRedeem(voucherCode)">
        <div class="voucher-input-wrapper">
          <input
            [formControl]="voucherInput"
            type="text"
            autocomplete="off"
            id="voucher-code"
            [(ngModel)]="voucherCode"
            name="voucherCode"
            class="voucher-input"
            (input)="helperService.sanitizeInput(voucherInput, $event)"
          />
          <div class="voucher-input-error-message" *ngIf="voucherInput.errors">
            <small
              data-translation="ticket-selection.voucher.errors.maxlength"
              [translate]="'ticket-selection.voucher.errors.maxlength'"
              [translateParams]="{
                voucherMaxLength: voucherInput.errors.maxlength['requiredLength']
              }"
            >
            </small>
          </div>
        </div>
        <button type="submit" class="button voucher-button" [disabled]="!voucherInput.valid || !voucherCode">
          <span data-translation="ticket-selection.voucher.redeem">{{
            'ticket-selection.voucher.redeem' | translate | hideTranslation
          }}</span>
        </button>
        <div
          class="ticket-selection-warning"
          *ngIf="voucherViewModel.ticketLimitWarning"
          [attr.data-translation]="voucherViewModel.ticketLimitWarning"
          [translate]="voucherViewModel.ticketLimitWarning"
          [translateParams]="{
            maxTicketLimit: voucherViewModel.maxTicketLimit,
            ticketLimitPerUserAccount: voucherViewModel.ticketLimitPerUserAccount
          }"
        ></div>
      </form>
    </div>

    <app-loader *ngIf="voucherViewModel.isLoading" class="loader"></app-loader>

    <div class="voucher-input-error-message anonymous-css" *ngIf="voucherViewModel.voucherWarning">
      <small [attr.data-translation]="voucherViewModel.voucherWarning">
        {{ voucherViewModel.voucherWarning | translate | hideTranslation }}
      </small>
    </div>
  </div>

  <app-sponsor-list></app-sponsor-list>
</ng-container>
