import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { StepNavigationService } from '@app/step-navigation/step-navigation.service';
import {
  ProductListItem,
  ProductSelectionProductsStatusViewModel,
  ProductSelectionViewModel,
  VoucherProducts
} from '@products/models/product-selection.model';
import { ProductSelectionValidationService } from '@products/services/product-selection-validation.service';
import { ProductSelectionService } from '@products/services/product-selection.service';
import { TariffStatusService } from '@products/services/tariff-status.service';
import { WorkshopStatusService } from '@products/services/workshop-status.service';
import { GtmService } from '@shared/gtm/gtmService';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './self-registration-product-selection.component.html',
  styleUrls: ['./self-registration-product-selection.component.scss']
})
export class SelfRegistrationProductSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  productSelectionProductsAndExhibitionState$: Observable<{
    selectedExhibition: ExhibitionModel;
    exhibitionSettings: ExhibitionSettingModel;
    productSelectionListProducts: ProductListItem[];
    voucherProducts: VoucherProducts;
    isUserAccountLimitChecked: boolean;
  }>;
  productSelectionViewModel$: Observable<ProductSelectionViewModel>;
  productSelectionProductsStatusViewModel$: Observable<ProductSelectionProductsStatusViewModel>;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private stepNavigationService: StepNavigationService,
    private productSelectionService: ProductSelectionService,
    private productSelectionValidationService: ProductSelectionValidationService,
    private tariffStatusService: TariffStatusService,
    private workshopStatusService: WorkshopStatusService,
    private gtmService: GtmService
  ) {}

  ngOnInit() {
    this.initComponentState();
    this.initComponentStoreSelectors();
    this.initComponentSubscriptions();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();

    this.gtmService.pushProductDetail();
  }

  ngOnDestroy(): void {
    this.gtmService.pushCheckout();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initComponentState() {
    this.tariffStatusService.getAndSetUserAccountTariffLimit();
    this.workshopStatusService.setInitialWorkshopDetailModalOpenState();
  }

  initComponentStoreSelectors() {
    this.productSelectionProductsAndExhibitionState$ = this.productSelectionService.getProductSelectionProductsAndExhibitionState$();
    this.productSelectionViewModel$ = this.productSelectionService.getProductSelectionViewModel$();
    this.productSelectionProductsStatusViewModel$ = this.productSelectionService.getProductSelectionProductsStatusViewModel$();
  }

  initComponentSubscriptions() {
    this.productSelectionViewModel$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ isRedeemedAnonymousVoucherProductInProductSelectionList }) =>
        this.stepNavigationService.setStepsVisibility(!isRedeemedAnonymousVoucherProductInProductSelectionList)
      );

    this.productSelectionValidationService
      .setProductSelectionValidationState$()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.productSelectionValidationService
      .setBuyerQuestionnaireAndFormValidity$()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  voucherProductsTrackBy(voucherProductIndex: number) {
    return voucherProductIndex;
  }

  productSelectionListTrackBy(productListItemIndex: number) {
    return productListItemIndex;
  }
}
