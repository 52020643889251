import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { State, getExhibitionSettings } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { ConfirmationWorkshop } from '@products/models/confirmation.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-workshop',
  templateUrl: './cofirmation-workshop.component.html',
  styleUrls: ['./cofirmation-workshop.component.scss']
})
export class ConfirmationWorkshopComponent implements OnInit, OnDestroy {
  @Input() confirmationWorkshop: ConfirmationWorkshop;
  hideWorkshopDate: boolean = true;
  formattedStartTime: string;
  formattedEndTime: string;

  private _subscriptions = new Subscription();

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this._subscriptions.add(
      this.store
        .pipe(
          select(getExhibitionSettings),
          filter(data => !!data)
        )
        .subscribe(settings => (this.hideWorkshopDate = settings.hideWorkshopDate))
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
