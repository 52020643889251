import {
  State,
  getAllDepartments,
  getAllOccupationalGroups,
  getAllProfessions,
  getAllTitles,
  getDepartments,
  getExhibitionSettings,
  getOccupationalGroups,
  getProfessions,
  getTitles
} from '@app/app.reducer';
import { GetAllCountriesAction } from '@store/helpers/helper.actions';
import { SetTicketHolderInputs } from '@store/products/holder/holder.actions';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { first } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TicketHolder } from '@products/models/holder.model';
import { TariffRecordOrdersTariffModel } from '@products/models/order.model';
import { HolderService } from '@products/services/holder.service';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';
import { ExhibitionSettingModel, QuestionnaireDataInput } from '@store/customization/customization.interfaces';
import { SelectOption } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import { getTicketHolder, getTicketHolderInputs } from '@store/products/holder/holder.selectors';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import {
  prepareTicketHolderDownloadData,
  prepareTicketHolderPersonalizationData
} from './form-inputs.ticket-holder-download';

@Component({
  moduleId: module.id,
  selector: 'app-download-ticket-holder-profile-edit',
  templateUrl: './download-ticket-holder-profile-edit.component.html',
  styleUrls: ['./download-ticket-holder-profile-edit.component.scss']
})
export class DownloadTicketHolderProfileEditComponent implements OnInit, OnDestroy {
  @Input() ticket: TariffRecordOrdersTariffModel;
  @Output() isValid = new EventEmitter<Object>();


  actionName = ['ticket-holder-download-data'];
  loaded: boolean = false;

  form: FormGroup;
  inputs: InputBase<any>[];

  private readonly subscriptions = new Subscription();

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private holderService: HolderService
  ) {
    this.store.dispatch(new GetAllCountriesAction());
  }

  ngOnInit() {
    observableCombineLatest([
      this.store.pipe(select(getExhibitionSettings)),
      this.store.pipe(select(getTicketHolder)),
      this.store.pipe(select(getTitles)),
      this.store.pipe(select(getAllTitles)),
      this.store.pipe(select(getProfessions)),
      this.store.pipe(select(getAllProfessions)),
      this.store.pipe(select(getDepartments)),
      this.store.pipe(select(getAllDepartments)),
      this.store.pipe(select(getOccupationalGroups)),
      this.store.pipe(select(getAllOccupationalGroups)),
    ])
      .pipe(
        first(([
          settings,
          ticketHolder,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups]
        ) =>
          !!titles
          && !!allTitles
          && !!professions
          && !!allProfessions
          && !!departments
          && !!allDepartments
          && !!occupationalGroups
          && !!allOccupationalGroups
        )
      )
      .subscribe(([
          settings,
          ticketHolder,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups
        ]:[
          ExhibitionSettingModel,
          TicketHolder,
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[]
        ]) => {

        if (!this.ticket) {
          const $inputs = prepareTicketHolderDownloadData(
            settings,
            ticketHolder,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups,
            this.translateService
          );

          $inputs.subscribe(inputs => {
            this.store.dispatch(new SetTicketHolderInputs(inputs));

            this.loaded = true;
          });
        } else {
          this.subscriptions.add(
            this.holderService.getVisitorFieldsForPersonalization(this.ticket.id).subscribe((result: any) => {
              const $inputs = prepareTicketHolderPersonalizationData(
                result.fieldSettings,
                titles,
                allTitles,
                professions,
                allProfessions,
                departments,
                allDepartments,
                occupationalGroups,
                allOccupationalGroups,
                this.translateService
              );

              $inputs.subscribe(inputs => {
                this.form = this.formsService.toFormGroup(inputs, this.actionName);

                // check the validity on first time and set it
                const validationCallback = () => {
                  this.isValid.emit({
                    formName: 'ticketholder',
                    valid: !this.form.invalid,
                    inputs: this.inputs,
                    form: this.form
                  });
                };

                this.helperService.triggerCallbackOnceFormValidationIsDone(
                  this.form,
                  validationCallback
                );

                this.store.dispatch(new SetTicketHolderInputs(inputs));

                this.loaded = true;
              });
            })
          );
        }
    });

    this.subscriptions.add(
      this.store
        .pipe(select(getTicketHolderInputs))
        .subscribe((inputs: InputBase<any>[]) => {
          this.inputs = inputs;
          this.form = this.formsService.toFormGroup(inputs, this.actionName);

          // check the validity on first time and set it
          const validationCallback = () => {
            this.isValid.emit({
              formName: 'ticketholder',
              valid: !this.form.invalid,
              inputs: this.inputs,
              form: this.form
            });
          };
          this.helperService.triggerCallbackOnceFormValidationIsDone(this.form, validationCallback);
        })
    );
  }

  inputsChanged = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);
    this.store.dispatch(new SetTicketHolderInputs(this.inputs));

    this.isValid.emit({
      formName: 'ticketholder',
      valid: !this.form.invalid,
      inputs: this.inputs,
      form: this.form
    });

    if (rerender) {
      this.form = this.formsService.toFormGroup(inputs, this.actionName);
    }
  };

  cancelProfileEdit(): void {
    this.ticket.expanded = false;
  }

  sumbitProfileUpdate(): void {
    const data = { ticketId: this.ticket.id };

    for (const field in this.form.controls) {
      if (field === 'dateOfBirth' && !!this.form.get(field).value) {
        this.form.controls[field].setValue(this.helperService.getUTCdate(this.form.get(field).value));
      }
      const control = this.form.get(field);
      data[field] = control.value;

      if (typeof(control.value) === 'string') {
        const value = this.helperService.sanitizeString(control.value);

        if (value !== control.value) {
          data[field] = value;
        }
      }
    }

    this.subscriptions.add(
      this.holderService.postVisitorFieldsFromPersonalization(this.ticket.id, data).subscribe(() => {
        this.ticket.expanded = false;
        this.ticket.firstName = this.form.get('firstName').value;
        this.ticket.lastName = this.form.get('lastName').value;
        this.ticket.email = this.form.get('email').value;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
