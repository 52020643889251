import { createSelector } from '@ngrx/store';
import { Package, PackageType, Packages } from '@products/models/package.model';
import {
  Product,
  ProductListItem,
  SendingOptionModel,
  VoucherProducts,
  isProduct,
  isProductGroup,
  isProductTypeArticle,
  isProductTypePackage,
  isProductTypeTariff,
  isVoucherProducts
} from '@products/models/product-selection.model';
import { Tariff, TariffPersonalizationType, TariffType, TicketSendingOptions } from '@products/models/tariff.model';
import { VoucherContingentState, VoucherTypeState } from '@products/models/voucher.model';
import { getProductSelectionState } from '@store/products/product.selectors';
import cloneDeep from 'lodash.clonedeep';

const findAndSetVoucherProducts = (
  voucherProducts: Product[],
  setVoucherProducts: Product[],
  findBy: (tariff: Tariff) => boolean
) => {
  const cloneVoucherProducts: Product[] = cloneDeep(voucherProducts);
  cloneVoucherProducts.forEach(voucherProduct => {
    if (isProductTypeTariff(voucherProduct)) {
      voucherProduct.tariffType.tariffs = voucherProduct.tariffType.tariffs.filter(findBy);

      if (voucherProduct.tariffType.tariffs.length) {
        setVoucherProducts.push(voucherProduct);
      }
    } else if (isProductTypePackage(voucherProduct)) {
    } else if (isProductTypeArticle(voucherProduct)) {
    }
  });
};

/**
 * Get all product selection list products
 * @description Lists all products
 * @returns Products array
 */
export const getAllProductSelectionListProducts = createSelector(
  getProductSelectionState,
  (productSelectionState): ProductListItem[] => {
    const productList: ProductListItem[] = [];
    Object.keys(productSelectionState.list).forEach(listItemKey => {
      const productListItem = productSelectionState.list[listItemKey];
      if (isProductGroup(productListItem)) {
        productList.push(productListItem);
      } else {
        (productSelectionState.list.vouchers as VoucherProducts).forEach(voucherProduct =>
          productList.push(voucherProduct)
        );
      }
    });

    return productList;
  }
);

/**
 * Get product selection list
 * @description Lists all products
 * @returns Products array
 */
export const getProductSelectionListProducts = createSelector(
  getProductSelectionState,
  (productSelectionState): ProductListItem[] => {
    const productList: ProductListItem[] = [];
    Object.keys(productSelectionState.list).forEach(listItemKey => {
      const productListItem = productSelectionState.list[listItemKey];
      if (isProductGroup(productListItem)) {
        productList.push(productListItem);
      }
    });

    return productList;
  }
);

/**
 * Get product selection sending options
 * @param state
 * @returns Sending options
 */
export const getSendingOptions = createSelector(
  getProductSelectionState,
  (productSelectionState): SendingOptionModel[] => productSelectionState.ticketSendingOptions
);

/**
 * Get voucher products
 * @description Get all voucher products
 * @param productList
 * @returns Voucher products or empty array
 */
export const getVoucherProducts = createSelector(
  getProductSelectionState,
  (productSelectionState): Product[] => {
    if (
      !!Object.keys(productSelectionState.list).length &&
      productSelectionState.list.vouchers &&
      isVoucherProducts(productSelectionState.list.vouchers)
    ) {
      const voucherProducts = productSelectionState.list.vouchers as VoucherProducts;
      return voucherProducts;
    }

    return [];
  }
);

/**
 * Get initial voucher products
 * @description Gets initial invisible vouchers products
 * @return Vouchers products or empty array
 */
export const getInitialVoucherProducts = createSelector(
  getVoucherProducts,
  (voucherProducts): Product[] => {
    const initialVouchersProducts: Product[] = [];

    findAndSetVoucherProducts(voucherProducts, initialVouchersProducts, tariff => !tariff.isVisible);

    return initialVouchersProducts;
  }
);

/**
 * Get voucher type state
 * @description Get validation state of voucher details in product selection voucher tariff before redeem on backend
 * @param ticketPersonId
 * @returns Object
 */
export const getVoucherTypeState = (ticketPersonId: number) =>
  createSelector(
    getInitialVoucherProducts,
    (voucherProducts): VoucherTypeState => {
      const findVoucherTariff = (voucherTariff: Tariff) =>
        voucherTariff.ticketPersonId === ticketPersonId && voucherTariff.isVoucher && !voucherTariff.isVisible;

      const voucherProduct: Product = voucherProducts.find(voucherProduct => {
        if (isProductTypeTariff(voucherProduct)) {
          return voucherProduct.tariffType.tariffs.some(findVoucherTariff);
        }
      });

      if (!voucherProduct) {
        return null;
      }

      const { availableTickets, personalizationType, price } = voucherProduct.tariffType.tariffs.find(
        findVoucherTariff
      );
      const initialNumberOfAvailableTariffs = availableTickets;
      const isMandatory = personalizationType === TariffPersonalizationType.Mandatory;
      const isAnonymous = personalizationType === TariffPersonalizationType.Anonymous;
      const isAnonymousWithPrice = isAnonymous && price > 0;

      return {
        initialNumberOfAvailableTariffs,
        isMandatory,
        isAnonymous,
        isAnonymousWithPrice
      };
    }
  );

/**
 * Get initial voucher tariff by ticker person id
 * @description Get initial voucher tariff form product selection by ticket person id
 * @param ticketPersonId
 * @returns Voucher tariff or null
 */
export const getInitialVoucherTariffByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getInitialVoucherProducts,
    (voucherProducts): Tariff => {
      const findVoucherTariff = (voucherTariff: Tariff) =>
        voucherTariff.ticketPersonId === ticketPersonId && !voucherTariff.isVisible;

      const voucherProduct: Product = voucherProducts.find(voucherProduct => {
        if (isProductTypeTariff(voucherProduct)) {
          return voucherProduct.tariffType.tariffs.some(findVoucherTariff);
        }
      });

      if (!voucherProduct) {
        return null;
      }

      const voucherTariff = voucherProduct.tariffType.tariffs.find(findVoucherTariff);

      return voucherTariff;
    }
  );

/**
 * Get voucher tariff personalization type
 * @description Get voucher tariff personalization type by provided ticket person id
 * @param ticketPersonId
 * @returns Voucher tariff personalization type or null
 */
export const getVoucherTariffPersonalizationTypeByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getInitialVoucherTariffByTicketPersonId(ticketPersonId),
    (voucherTariff): TariffPersonalizationType => {
      if (!voucherTariff) {
        return null;
      }

      return voucherTariff.personalizationType;
    }
  );

/**
 * Get voucher day sell limit
 * @description Get voucher tariff day sell limit
 * @param ticketPersonId
 * @returns Voucher day sell limit boolean
 */
export const getVoucherTariffDaySellLimit = (ticketPersonId: number) =>
  createSelector(
    getInitialVoucherTariffByTicketPersonId(ticketPersonId),
    (voucherTariff): boolean => voucherTariff.hasDaySellLimit
  );

/**
 * Get voucher contingent reservation state
 * @description Check if voucher has contingent reservation state by ticket person id
 * @param ticketPersonId
 * @returns Voucher tariff contingent reservation state  or null
 */
export const getVoucherTariffContingentReservationStateByTicketPersonId = (ticketPersonId: number) =>
  createSelector(
    getInitialVoucherTariffByTicketPersonId(ticketPersonId),
    (voucherTariff): VoucherContingentState => {
      if (!voucherTariff) {
        return null;
      }

      return {
        hasDaySellLimit: voucherTariff.hasDaySellLimit,
        durationInDays: voucherTariff.durationInDays,
        validFrom: voucherTariff.validFrom,
        validTill: voucherTariff.validTill,
        shouldCalendarBeDisplayed: voucherTariff.shouldCalendarBeDisplayed
      };
    }
  );

/**
 * Get active voucher products
 * @description Get all active voucher products
 * @returns Voucher products or empty array
 */
export const getActiveVoucherProducts = createSelector(
  getVoucherProducts,
  (voucherProducts): Product[] => {
    const activeVouchersProducts: Product[] = [];

    findAndSetVoucherProducts(
      voucherProducts,
      activeVouchersProducts,
      tariff => tariff.isVisible && !!tariff.voucherCode
    );

    return activeVouchersProducts;
  }
);

/**
 * Get active voucher tariff types
 * @description Get active voucher tariff type products from product selection
 * @returns Tariff types or empty array
 */
export const getActiveVoucherTariffTypes = createSelector(
  getActiveVoucherProducts,
  (activeVoucherProducts): TariffType[] => {
    const activeVoucherTariffTypes: TariffType[] = [];

    activeVoucherProducts.forEach(activeVoucherProduct => {
      if (isProductTypeTariff(activeVoucherProduct)) {
        activeVoucherTariffTypes.push(activeVoucherProduct.tariffType);
      }
    });

    return activeVoucherTariffTypes;
  }
);

/**
 * Get active voucher tariff
 * @description Get active voucher tariffs from product selection
 * @return Tariffs or empty array
 */
export const getActiveVoucherTariffs = createSelector(
  getActiveVoucherTariffTypes,
  (activeVoucherTariffTypes): Tariff[] => {
    const activeVoucherTariffs: Tariff[] = [];

    activeVoucherTariffTypes.forEach(activeVoucherTariffType => {
      activeVoucherTariffType.tariffs.forEach(activeVoucherTariff => activeVoucherTariffs.push(activeVoucherTariff));
    });

    return activeVoucherTariffs;
  }
);

/**
 * Get voucher tariff by voucher code
 * @description Get voucher tariff by provided voucher code form product selection
 * @param voucherCode
 * @returns Tariff of null
 */
export const getVoucherTariffByVoucherCode = (voucherCode: string) =>
  createSelector(
    getActiveVoucherTariffs,
    (activeVoucherTariffs): Tariff =>
      activeVoucherTariffs.find(activeVoucherTariffs => activeVoucherTariffs.voucherCode === voucherCode) || null
  );

/**
 * Is voucher code redeemed
 * @description Check if voucher code is redeemed in product selection
 * @param voucherCode
 * @returns Boolean
 */
export const isVoucherCodeRedeemed = (voucherCode: string) =>
  createSelector(
    getVoucherTariffByVoucherCode(voucherCode),
    (voucherTariffByVoucherCode): boolean => !!voucherTariffByVoucherCode
  );

/**
 * Is redeemed voucher product in product selection list
 * @description Check if redeemed voucher product is in product selection list
 * @returns Boolean
 */
export const isRedeemedVoucherProductInProductSelectionList = createSelector(
  getActiveVoucherTariffs,
  (activeVoucherTariffs): boolean => !!activeVoucherTariffs.length
);

/**
 * Is redeemed anonymous voucher product in product selection list
 * @description Check if redeemed anonymous voucher product is in product selection list
 * @returns Boolean
 */
export const isRedeemedAnonymousVoucherProductInProductSelectionList = createSelector(
  getActiveVoucherTariffs,
  (activeVoucherTariffs): boolean =>
    activeVoucherTariffs.some(
      activeVoucherTariff => activeVoucherTariff.personalizationType === TariffPersonalizationType.Anonymous
    )
);

/**
 * Get all tariffs
 * @description Get all tariffs
 * @returns Tariffs or empty array
 */
export const getAllTariffs = createSelector(
  getAllProductSelectionListProducts,
  (productList): Tariff[] => {
    const tariffs: Tariff[] = [];

    productList.forEach(listItem => {
      if (isProduct(listItem)) {
        if (isProductTypeTariff(listItem)) {
          listItem.tariffType.tariffs.forEach(tariff => tariffs.push(tariff));
        } else if (isProductTypePackage(listItem)) {
          listItem.packageType.packages.forEach(currentPackage =>
            currentPackage.products.forEach(packageProduct => {
              if (isProductTypeTariff(packageProduct)) {
                packageProduct.tariffType.tariffs.forEach(packageTariff => tariffs.push(packageTariff));
              }
            })
          );
        }
      } else if (isProductGroup(listItem)) {
        listItem.products.forEach(product => {
          if (isProductTypeTariff(product)) {
            product.tariffType.tariffs.forEach(tariff => tariffs.push(tariff));
          } else if (isProductTypePackage(product)) {
            product.packageType.packages.forEach(currentPackage =>
              currentPackage.products.forEach(packageProduct => {
                if (isProductTypeTariff(packageProduct)) {
                  packageProduct.tariffType.tariffs.forEach(packageTariff => tariffs.push(packageTariff));
                }
              })
            );
          }
        });
      }
    });

    return tariffs;
  }
);

/**
 * Get all tariffs with workshops
 * @description Get all product selection tariffs with workshops
 * @returns Tariffs or empty array
 */
export const getAllTariffsWithWorkshops = createSelector(
  getAllTariffs,
  (tariffs: Tariff[]) => tariffs.filter(tariff => tariff.allowedWorkshops.length)
);

/**
 * Get all legitimation tariffs
 * @description Get all product selection legitimation tariffs
 * @returns Tariffs or empty array
 */
export const getAllLegitimationTariffs = createSelector(
  getAllTariffs,
  (tariffs: Tariff[]) => tariffs.filter(tariff => tariff.requiresLegitimation)
);

/**
 * Get all tariffs from outside of the packages
 * @description Get all tariffs from outside of the packages
 * @returns Tariffs outside of packages or empty array
 */
export const getAllTariffProductTypeTariffs = createSelector(
  getAllTariffs,
  (tariffs: Tariff[]) => tariffs.filter(tariff => !tariff.packageNumber)
);

/**
 * Get all tariffs from packages
 * @description Get all tariffs from packages
 * @returns Tariffs inside packages or empty array
 */
export const getAllPackageProductTypeTariffs = createSelector(
  getAllTariffs,
  (tariffs: Tariff[]) => tariffs.filter(tariff => tariff.packageNumber)
);

/**
 * Get tariff types by id
 * @description Checks ticket types that are not in article, package and vouchers array
 * @param ticketTypeId
 * @returns Tariff types or empty array
 */
export const getTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getAllProductSelectionListProducts,
    (productList): TariffType[] => {
      const tariffTypes: TariffType[] = [];

      productList.forEach(listItem => {
        if (isProduct(listItem) && isProductTypeTariff(listItem) && listItem.tariffType.ticketTypeId === ticketTypeId) {
          tariffTypes.push(listItem.tariffType);
        } else if (isProduct(listItem) && isProductTypePackage(listItem)) {
          listItem.packageType.packages.forEach(currentPackage =>
            currentPackage.products.forEach(packageProduct => {
              if (isProductTypeTariff(packageProduct)) {
                if (packageProduct.tariffType.ticketTypeId === ticketTypeId) {
                  tariffTypes.push(packageProduct.tariffType);
                }
              }
            })
          );
        } else if (isProductGroup(listItem)) {
          listItem.products.forEach(product => {
            if (isProductTypeTariff(product) && product.tariffType.ticketTypeId === ticketTypeId) {
              tariffTypes.push(product.tariffType);
            } else if (isProductTypePackage(product)) {
              product.packageType.packages.forEach(currentPackage =>
                currentPackage.products.forEach(packageProduct => {
                  if (isProductTypeTariff(packageProduct)) {
                    if (packageProduct.tariffType.ticketTypeId === ticketTypeId) {
                      tariffTypes.push(packageProduct.tariffType);
                    }
                  }
                })
              );
            }
          });
        }
      });

      return tariffTypes;
    }
  );

/**
 * Get tariff with specified ticket type and ticket person id
 * @description Checks tickets that are not in article, package and vouchers array
 * @param ticketTypeId
 * @param ticketPersonId
 * @returns Tariff or null
 */
export const getTariffByTicketPersonId = (ticketTypeId: number, ticketPersonId: number) =>
  createSelector(
    getTariffTypesByTicketTypeId(ticketTypeId),
    (tariffTypes): Tariff => {
      const tariffTypeWithTicket = tariffTypes.find(
        tariffType => !!tariffType.tariffs.find(tariff => tariff.ticketPersonId === ticketPersonId)
      );

      return !!tariffTypeWithTicket
        ? tariffTypeWithTicket.tariffs.find(tariff => tariff.ticketPersonId === ticketPersonId)
        : null;
    }
  );

/**
 * Get voucher tariff types by id
 * @description Get voucher ticket types with specified ticket type id
 * @param ticketTypeId
 * @returns Tariff types or empty array
 */
export const getVoucherTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getVoucherProducts,
    (vouchers): TariffType[] => {
      const tariffTypes: TariffType[] = [];

      vouchers.forEach(voucherProduct => {
        if (isProductTypeTariff(voucherProduct) && voucherProduct.tariffType.ticketTypeId === ticketTypeId) {
          tariffTypes.push(voucherProduct.tariffType);
        }
      });

      return tariffTypes;
    }
  );

/**
 * Get voucher tariff by id
 * @description Get voucher tariff with specified ticket type id and ticket person id
 * @param ticketTypeId
 * @param ticketPersonId
 * @returns Tariff or null
 */
export const getVoucherTariffByTicketPersonId = (ticketTypeId: number, ticketPersonId: number) =>
  createSelector(
    getVoucherTariffTypesByTicketTypeId(ticketTypeId),
    voucherTariffTypes => {
      if (!voucherTariffTypes.length) {
        return null;
      }

      const voucherTariffType = voucherTariffTypes.find(tariffType =>
        tariffType.tariffs.some(tariff => tariff.ticketPersonId === ticketPersonId)
      );

      return !!voucherTariffType
        ? voucherTariffType.tariffs.find(tariff => tariff.ticketPersonId === ticketPersonId)
        : null;
    }
  );

/**
 * Get package tariff types by id
 * @description Get package ticket types with specified ticket type id
 * @param ticketTypeId
 * @returns Tariff types or empty array
 */
export const getPackageTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getAllProductSelectionListProducts,
    (productList): TariffType[] => {
      const tariffTypes: TariffType[] = [];

      productList.forEach(listItem => {
        if (isProduct(listItem) && isProductTypePackage(listItem)) {
          const packages = listItem.packageType.packages;
          Object.keys(packages).forEach(packageIndex => {
            const currentPackage = packages[packageIndex];
            currentPackage.products.forEach(packageProduct => {
              if (isProductTypeTariff(packageProduct) && packageProduct.tariffType.ticketTypeId === ticketTypeId) {
                tariffTypes.push(packageProduct.tariffType);
              }
            });
          });
        } else if (isProductGroup(listItem)) {
          Object.keys(listItem.products).forEach(productKey => {
            const product = listItem.products[productKey];
            if (isProductTypePackage(product)) {
              const packages = product.packageType.packages;
              Object.keys(packages).forEach(packageIndex => {
                const currentPackage = packages[packageIndex];
                currentPackage.products.forEach(packageProduct => {
                  if (isProductTypeTariff(packageProduct) && packageProduct.tariffType.ticketTypeId === ticketTypeId) {
                    tariffTypes.push(packageProduct.tariffType);
                  }
                });
              });
            }
          });
        }
      });

      return tariffTypes;
    }
  );

/**
 * Get all tariff types by id
 * @description Get all ticket types with specified ticket type id
 * @param ticketTypeId
 * @returns All tariff types or empty array
 */
export const getAllTariffTypesByTicketTypeId = (ticketTypeId: number) =>
  createSelector(
    getTariffTypesByTicketTypeId(ticketTypeId),
    getVoucherTariffTypesByTicketTypeId(ticketTypeId),
    getPackageTariffTypesByTicketTypeId(ticketTypeId),
    (tariffTypes, voucherTariffTypes, packageTariffTypes): TariffType[] => [
      ...tariffTypes,
      ...voucherTariffTypes,
      ...packageTariffTypes
    ]
  );

/**
 * Get preferred tariff type
 * @description Get preferred tariff type by ticket type id set as url query parameters
 * @param ticketTypeId
 * @returns Tariff type or null
 */
export const getPreferredTariffType = (ticketTypeId: number) =>
  createSelector(
    getAllTariffTypesByTicketTypeId(ticketTypeId),
    (allTariffTypes: TariffType[]) => allTariffTypes.find(tariffType => !!tariffType.preferredTicketType) || null
  );

/**
 * Get preferred tariff type with tariff
 * @description Get preferred tariff type and tariff by ticket type id and ticket person id set as url query parameters
 * @param ticketTypeId
 * @param ticketPersonTypeId
 * @returns Tariff type with preferred tariff or null
 */
export const getPreferredTariffTypeWithTariff = (ticketTypeId: number, ticketPersonTypeId: number) =>
  createSelector(
    getPreferredTariffType(ticketTypeId),
    (tariffType: TariffType): TariffType => {
      if (!tariffType) {
        return null;
      }

      tariffType.tariffs = tariffType.tariffs.filter(tariff => tariff.ticketPersonTypeId === ticketPersonTypeId);

      return !!tariffType.tariffs.length ? tariffType : null;
    }
  );

/**
 * Get preferred tariff
 * @description Get preferred tariff by ticket type id and ticket person id set as url query parameters
 * @param ticketTypeId
 * @param ticketPersonTypeId
 * @returns Tariff or null
 */
export const getPreferredTariff = (ticketTypeId: number, ticketPersonTypeId: number) =>
  createSelector(
    getPreferredTariffTypeWithTariff(ticketTypeId, ticketPersonTypeId),
    (tariffType: TariffType): Tariff => {
      if (!tariffType || !tariffType.tariffs.length) {
        return null;
      }

      return tariffType.tariffs[0];
    }
  );

/**
 * Get tariffs by ticket person id from all products
 * @description Get all tariffs with specified ticket type id and ticket person id
 * @param ticketTypeId
 * @param ticketPersonId
 * @returns Tariffs or empty array
 */
export const getAllTariffsByTicketPersonId = (ticketTypeId: number, ticketPersonId: number) =>
  createSelector(
    getAllTariffTypesByTicketTypeId(ticketTypeId),
    (allProductsTariffTypes): Tariff[] => {
      const tariffs: Tariff[] = [];

      allProductsTariffTypes.forEach(productTariffType => {
        productTariffType.tariffs.forEach(tariff => {
          if (tariff.ticketPersonId === ticketPersonId) {
            tariffs.push(tariff);
          }
        });
      });

      return tariffs;
    }
  );

/**
 * Get all package types
 * @description Gets all package types
 * @param productList
 * @return Package types or empty array
 */
export const getAllPackageTypes = createSelector(
  getAllProductSelectionListProducts,
  (productList): PackageType[] => {
    const packageTypes: PackageType[] = [];

    productList.forEach(listItem => {
      if (isProduct(listItem) && isProductTypePackage(listItem)) {
        packageTypes.push(listItem.packageType);
      } else if (isProductGroup(listItem)) {
        Object.keys(listItem.products).forEach(productKey => {
          const product = listItem.products[productKey];
          if (isProduct(product) && isProductTypePackage(product)) {
            packageTypes.push(product.packageType);
          }
        });
      }
    });

    return packageTypes;
  }
);

/**
 * Get package type with specified package number
 * @description Get package type with specified package number
 * @param packageNumber
 * @returns Package type or null
 */
export const getPackageTypeByPackageNumber = (packageNumber: number) =>
  createSelector(
    getAllPackageTypes,
    (packageTypes): PackageType => packageTypes.find(packageType => packageType.packageNumber === packageNumber) || null
  );

/**
 * Get package count by package number
 * @description Get package count by provided package number
 * @param packageNumber
 * @returns Package count
 */
export const getPackageCountByPackageNumber = (packageNumber: number) =>
  createSelector(
    getPackageTypeByPackageNumber(packageNumber),
    (packageType: PackageType): number => (!!packageType ? packageType.numberOfAddedPackages : 0)
  );

/**
 * Get packages with specified package number
 * @description Get packages with specified package number
 * @param packageNumber
 * @returns Packages object or null
 */
export const getAllPackagesByPackageNumber = (packageNumber: number) =>
  createSelector(
    getPackageTypeByPackageNumber(packageNumber),
    (packageType: PackageType): Packages => {
      if (!packageType) {
        return null;
      }

      return packageType.packages;
    }
  );

/**
 * Get package with specified package number and index
 * @description Get package with specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Package or null
 */
export const getPackageByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getAllPackagesByPackageNumber(packageNumber),
    (packageList): Package => packageList[packageIndex] || null
  );

/**
 * Get products fom package with specified package number and index
 * @description Get products fom package with specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Products or empty array
 */
export const getPackageProductsByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getPackageByPackageNumberAndIndex(packageNumber, packageIndex),
    (selectedPackage): Product[] => {
      if (!selectedPackage) {
        return [];
      }

      return selectedPackage.products;
    }
  );

/**
 * Get tariff types fom package with specified package number and index
 * @description Get ticket types fom package with specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Tariff types or empty array
 */
export const getPackageTariffTypesByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getPackageProductsByPackageNumberAndIndex(packageNumber, packageIndex),
    (packageProducts): TariffType[] => {
      const packageTariffTypes: TariffType[] = [];

      packageProducts.filter(isProductTypeTariff).forEach(product => packageTariffTypes.push(product.tariffType));

      return packageTariffTypes;
    }
  );

/**
 * Get package tariffs by package number and index
 * @description Get package tickets from package with specified package number and index
 * @param packageNumber
 * @param packageIndex
 * @returns Tariffs or empty array
 */
export const getPackageTicketsByPackageNumberAndIndex = (packageNumber: number, packageIndex: number) =>
  createSelector(
    getPackageTariffTypesByPackageNumberAndIndex(packageNumber, packageIndex),
    (tariffTypes): Tariff[] => {
      const packageTariffs: Tariff[] = [];

      tariffTypes.forEach(tariffType => tariffType.tariffs.forEach(tariff => packageTariffs.push(tariff)));

      return packageTariffs;
    }
  );

/**
 * Get package tariff by package number and index
 * @description Get tariff from package with specified package number, index and ticket person id
 * @param packageNumber
 * @param packageIndex
 * @param ticketPersonId
 * @returns Tariff or null
 */
export const getPackageTariffByTicketPersonId = (packageNumber: number, packageIndex: number, ticketPersonId: number) =>
  createSelector(
    getPackageTicketsByPackageNumberAndIndex(packageNumber, packageIndex),
    (tariffs): Tariff => tariffs.find(tariff => tariff.ticketPersonId === ticketPersonId) || null
  );

/**
 * Gets selected sending option
 * @description Get selected sending option on product selection page
 * @returns Sending option string or null
 */
export const getSelectedSendingOption = createSelector(
  getSendingOptions,
  (sendingOptions): TicketSendingOptions => {
    const selectedSendingOption = sendingOptions.find(sendingOption => sendingOption.isSelected);
    return selectedSendingOption ? selectedSendingOption.value : null;
  }
);

export const getAllWorkshopTariffs = createSelector(
  getAllTariffs,
  (tariffs: Tariff[]): Tariff[] => tariffs.filter(tariff => tariff.allowedWorkshops.length)
);
