import { TariffWarningMessage } from '@products/models/tariff.model';
import * as i0 from "@angular/core";
var TariffCountValidationService = /** @class */ (function () {
    function TariffCountValidationService() {
    }
    TariffCountValidationService.prototype.validateIsAvailableTariffsLimit = function (tariffValidationState) {
        var count = tariffValidationState.count, previousValidatedCount = tariffValidationState.previousValidatedCount, initialNumberOfAvailableTariffs = tariffValidationState.initialNumberOfAvailableTariffs, currentNumberOfAvailableTariffs = tariffValidationState.currentNumberOfAvailableTariffs;
        if (this.isAvailableTariffsLimitReached(tariffValidationState)) {
            tariffValidationState.isAvailableTariffsLimitReached = true;
            var maxAvailableTariffCount = previousValidatedCount + currentNumberOfAvailableTariffs;
            var maxLimitCount = Math.min(maxAvailableTariffCount, count);
            this.setValidateCounterState(tariffValidationState, maxLimitCount, initialNumberOfAvailableTariffs, TariffWarningMessage.ERROR_TICKET_WARNING);
        }
    };
    TariffCountValidationService.prototype.validatePromoCodeVoucherTariff = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
            return;
        }
        var count = tariffValidationState.count, previousValidatedCount = tariffValidationState.previousValidatedCount, numberOfBookedVouchers = tariffValidationState.numberOfBookedVouchers, maxVoucherLimit = tariffValidationState.maxVoucherLimit, voucherCountLimit = tariffValidationState.voucherCountLimit;
        if (this.isPromoCodeVoucherTariffLimitReached(tariffValidationState)) {
            var numberOfOtherBookedPromoCodeVouchers = numberOfBookedVouchers - previousValidatedCount;
            var currentPromoCodeVoucherAvailableCount = maxVoucherLimit - numberOfOtherBookedPromoCodeVouchers;
            var maxLimitCount = Math.min(currentPromoCodeVoucherAvailableCount, count);
            var validateVoucherCountLimit = voucherCountLimit < maxLimitCount;
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxVoucherLimit, TariffWarningMessage.ERROR_VOUCHER_WARNING, true, validateVoucherCountLimit);
        }
    };
    TariffCountValidationService.prototype.validateLimitedPromoCodeVoucherTariff = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
            return;
        }
        var previousValidatedCount = tariffValidationState.previousValidatedCount, numberOfBookedLimitedVouchers = tariffValidationState.numberOfBookedLimitedVouchers, maxLimitedVoucherLimit = tariffValidationState.maxLimitedVoucherLimit, voucherCountLimit = tariffValidationState.voucherCountLimit;
        if (this.isLimitedPromoCodeVoucherTariffLimitReached(tariffValidationState)) {
            var numberOfOtherBookedLimitedPromoCodeVouchers = numberOfBookedLimitedVouchers - previousValidatedCount;
            var currentLimitedPromoCodeVoucherAvailableCount = maxLimitedVoucherLimit - numberOfOtherBookedLimitedPromoCodeVouchers;
            var maxLimitCount = Math.min(currentLimitedPromoCodeVoucherAvailableCount, maxLimitedVoucherLimit);
            var validateVoucherCountLimit = voucherCountLimit < maxLimitCount;
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxLimitedVoucherLimit, TariffWarningMessage.VOUCHER_LIMITED_WARNING, true, validateVoucherCountLimit);
        }
    };
    TariffCountValidationService.prototype.validateOneTimeVoucherTariff = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
            return;
        }
        if (this.isOneTimeVoucherTariffLimitReached(tariffValidationState)) {
            var previousValidatedCount = tariffValidationState.previousValidatedCount, numberOfBookedOneTimeVouchers = tariffValidationState.numberOfBookedOneTimeVouchers, voucherCountLimit = tariffValidationState.voucherCountLimit;
            var maxOneTimeVoucherLimit = 1;
            var currentTariffAvailableCount = previousValidatedCount + maxOneTimeVoucherLimit - numberOfBookedOneTimeVouchers;
            var maxLimitCount = Math.min(maxOneTimeVoucherLimit, currentTariffAvailableCount);
            var validateVoucherCountLimit = voucherCountLimit < maxLimitCount;
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxOneTimeVoucherLimit, TariffWarningMessage.VOUCHER_ONETIME_WARNING, true, validateVoucherCountLimit);
        }
    };
    TariffCountValidationService.prototype.validateWorkshopTariff = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit) {
            return;
        }
        var count = tariffValidationState.count, previousValidatedCount = tariffValidationState.previousValidatedCount, allowedWorkshopsSeatsAvailable = tariffValidationState.allowedWorkshopsSeatsAvailable, areAllAllowedWorkshopsAssigned = tariffValidationState.areAllAllowedWorkshopsAssigned;
        if (this.isWorkshopTariffLimitReached(tariffValidationState)) {
            var maxLimitCount = !areAllAllowedWorkshopsAssigned
                ? Math.min(allowedWorkshopsSeatsAvailable, count)
                : previousValidatedCount;
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxLimitCount, TariffWarningMessage.ERROR_TICKET_WARNING);
        }
    };
    TariffCountValidationService.prototype.validateMaxTariffLimit = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit) {
            return;
        }
        var maxTariffLimit = tariffValidationState.maxTariffLimit, numberOfAllBookedTariffs = tariffValidationState.numberOfAllBookedTariffs, previousValidatedCount = tariffValidationState.previousValidatedCount;
        if (this.isMaxTariffLimitReached(tariffValidationState)) {
            var numberOfOtherBookedTariffs = numberOfAllBookedTariffs - previousValidatedCount;
            var currentTariffAvailableCount = maxTariffLimit - numberOfOtherBookedTariffs;
            var maxLimitCount = Math.min(maxTariffLimit, currentTariffAvailableCount);
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxTariffLimit, TariffWarningMessage.ERROR_TICKET_WARNING);
        }
    };
    TariffCountValidationService.prototype.validateVoucherCountLimit = function (tariffValidationState) {
        var voucherCountLimit = tariffValidationState.voucherCountLimit, isVoucherLimitReached = tariffValidationState.isVoucherLimitReached, validateVoucherCountLimit = tariffValidationState.validateVoucherCountLimit;
        if (!validateVoucherCountLimit && isVoucherLimitReached) {
            return;
        }
        if (this.isVoucherCountLimitReached(tariffValidationState)) {
            this.setValidateCounterState(tariffValidationState, voucherCountLimit, voucherCountLimit, TariffWarningMessage.ERROR_VOUCHER_WARNING, true, true);
        }
    };
    TariffCountValidationService.prototype.validateTariffLimitPerUserAccount = function (tariffValidationState) {
        if (tariffValidationState.isCountOverLimit && !tariffValidationState.isAvailableTariffsLimitReached) {
            return;
        }
        var previousValidatedCount = tariffValidationState.previousValidatedCount, isVoucherIncludedPerUserAccountLimit = tariffValidationState.isVoucherIncludedPerUserAccountLimit, isVoucher = tariffValidationState.isVoucher, numberOfBookedVouchers = tariffValidationState.numberOfBookedVouchers, numberOfAllBookedTariffs = tariffValidationState.numberOfAllBookedTariffs, numberOfAvailableTariffs = tariffValidationState.currentNumberOfAvailableTariffs, currentUserAccountTariffLimit = tariffValidationState.currentUserAccountTariffLimit;
        if (this.isTariffLimitPerUserAccountReached(tariffValidationState)) {
            tariffValidationState.isCurrentUserAccountTariffLimitReached = true;
            var maxLimit = Math.min(currentUserAccountTariffLimit, numberOfAvailableTariffs);
            var notIncludedVoucherTariffsCount = !isVoucher && !isVoucherIncludedPerUserAccountLimit ? numberOfBookedVouchers : 0;
            var availableAccountLimitTariffsCount = numberOfAllBookedTariffs - notIncludedVoucherTariffsCount;
            var previousAvailableUserAccountLimitTariffCount = availableAccountLimitTariffsCount - previousValidatedCount;
            var userAccountTariffMaxLimit = currentUserAccountTariffLimit - previousAvailableUserAccountLimitTariffCount;
            var maxLimitCount = Math.min(maxLimit, userAccountTariffMaxLimit);
            this.setValidateCounterState(tariffValidationState, maxLimitCount, maxLimit, TariffWarningMessage.TICKET_LIMIT_PER_USER_ACCOUNT_WARNING);
        }
    };
    TariffCountValidationService.prototype.validateInLimitCounter = function (count, tariffValidationState) {
        if (!tariffValidationState.isCountOverLimit) {
            tariffValidationState.validatedCount = count > 0 ? count : 0;
            tariffValidationState.tariffLimitWarningMessage = '';
            tariffValidationState.validatedMaxLimit = null;
            tariffValidationState.isVoucherLimitReached = false;
        }
    };
    TariffCountValidationService.prototype.validatePackageTariffLimit = function (tariffValidationState) {
        var isPackageTypeAndIsPackageNotAdded = tariffValidationState.isPackageTypeAndIsPackageNotAdded, packageSettings = tariffValidationState.packageSettings;
        var isPackageAdded = !isPackageTypeAndIsPackageNotAdded;
        if (isPackageAdded && packageSettings) {
            tariffValidationState.isPackageFixedAmountLimitReached = this.isPackageFixedAmountLimitReached(tariffValidationState);
            tariffValidationState.isPackageMinAmountLimitReached = this.isPackageMinLimitReached(tariffValidationState);
            tariffValidationState.isPackageMaxAmountLimitReached = this.isPackageMaxLimitReached(tariffValidationState);
            var amount = packageSettings.amount, minAmount = packageSettings.minAmount, maxAmount = packageSettings.maxAmount;
            if (tariffValidationState.isPackageFixedAmountLimitReached) {
                tariffValidationState.validatedCount = amount;
            }
            if (tariffValidationState.isPackageMinAmountLimitReached) {
                tariffValidationState.validatedCount = minAmount;
            }
            if (tariffValidationState.isPackageMaxAmountLimitReached) {
                tariffValidationState.validatedCount = maxAmount;
            }
        }
    };
    TariffCountValidationService.prototype.isAvailableTariffsLimitReached = function (tariffValidationState) {
        var count = tariffValidationState.count, countDifference = tariffValidationState.countDifference, bookedCount = tariffValidationState.bookedCount, isTariffSoldOut = tariffValidationState.isTariffSoldOut, initialNumberOfAvailableTariffs = tariffValidationState.initialNumberOfAvailableTariffs, currentNumberOfAvailableTariffs = tariffValidationState.currentNumberOfAvailableTariffs;
        if (isTariffSoldOut) {
            return true;
        }
        var isCurrentTariffCountDecreased = countDifference < 0;
        var isOnDecreaseTariffCountStateRevalidation = count < bookedCount;
        if (isCurrentTariffCountDecreased || isOnDecreaseTariffCountStateRevalidation) {
            tariffValidationState.isAvailableTariffsLimitReached = false;
            return false;
        }
        var alreadyBookedTariffsCount = initialNumberOfAvailableTariffs - currentNumberOfAvailableTariffs;
        var isInitialTariffLimitReached = countDifference + alreadyBookedTariffsCount >= initialNumberOfAvailableTariffs;
        var isCurrentAvailableTariffsLimitReached = currentNumberOfAvailableTariffs !== null && currentNumberOfAvailableTariffs === 0;
        var isAvailableTariffsLimitReached = isInitialTariffLimitReached || isCurrentAvailableTariffsLimitReached;
        tariffValidationState.isAvailableTariffsLimitReached = isAvailableTariffsLimitReached;
        return isAvailableTariffsLimitReached;
    };
    TariffCountValidationService.prototype.isPromoCodeVoucherTariffLimitReached = function (tariffValidationState) {
        var isVoucherTypePromoCode = tariffValidationState.isVoucherTypePromoCode, countDifference = tariffValidationState.countDifference, numberOfBookedVouchers = tariffValidationState.numberOfBookedVouchers, maxVoucherLimit = tariffValidationState.maxVoucherLimit;
        var promoCodeVoucherTotalCount = countDifference + numberOfBookedVouchers;
        return isVoucherTypePromoCode && promoCodeVoucherTotalCount > maxVoucherLimit;
    };
    TariffCountValidationService.prototype.isLimitedPromoCodeVoucherTariffLimitReached = function (tariffValidationState) {
        var isVoucherTypeLimitedPromoCode = tariffValidationState.isVoucherTypeLimitedPromoCode, countDifference = tariffValidationState.countDifference, numberOfBookedLimitedVouchers = tariffValidationState.numberOfBookedLimitedVouchers, maxLimitedVoucherLimit = tariffValidationState.maxLimitedVoucherLimit;
        var limitedPromoCodeVoucherTotalCount = numberOfBookedLimitedVouchers + countDifference;
        return isVoucherTypeLimitedPromoCode && limitedPromoCodeVoucherTotalCount > maxLimitedVoucherLimit;
    };
    TariffCountValidationService.prototype.isOneTimeVoucherTariffLimitReached = function (tariffValidationState) {
        var countDifference = tariffValidationState.countDifference, numberOfBookedOneTimeVouchers = tariffValidationState.numberOfBookedOneTimeVouchers, isVoucherTypeOneTimeVoucher = tariffValidationState.isVoucherTypeOneTimeVoucher;
        var oneTimeVoucherTotalCount = countDifference + numberOfBookedOneTimeVouchers;
        return isVoucherTypeOneTimeVoucher && oneTimeVoucherTotalCount > 1;
    };
    TariffCountValidationService.prototype.isWorkshopTariffLimitReached = function (tariffValidationState) {
        var count = tariffValidationState.count, previousValidatedCount = tariffValidationState.previousValidatedCount, isWorkshopsSelectionMandatoryAndHasWorkshops = tariffValidationState.isWorkshopsSelectionMandatoryAndHasWorkshops, allowedWorkshopsSeatsAvailable = tariffValidationState.allowedWorkshopsSeatsAvailable, areAllAllowedWorkshopsAssigned = tariffValidationState.areAllAllowedWorkshopsAssigned;
        var isCountDecreased = previousValidatedCount > count;
        if (isCountDecreased) {
            return false;
        }
        var isInvalidWorkshopCount = count > allowedWorkshopsSeatsAvailable;
        return isWorkshopsSelectionMandatoryAndHasWorkshops && (isInvalidWorkshopCount || areAllAllowedWorkshopsAssigned);
    };
    TariffCountValidationService.prototype.isMaxTariffLimitReached = function (tariffValidationState) {
        var countDifference = tariffValidationState.countDifference, numberOfAllBookedTariffs = tariffValidationState.numberOfAllBookedTariffs, maxTariffLimit = tariffValidationState.maxTariffLimit;
        return countDifference + numberOfAllBookedTariffs > maxTariffLimit;
    };
    TariffCountValidationService.prototype.isVoucherCountLimitReached = function (tariffValidationState) {
        var count = tariffValidationState.count, voucherCountLimit = tariffValidationState.voucherCountLimit;
        return count >= voucherCountLimit && voucherCountLimit !== 0;
    };
    TariffCountValidationService.prototype.isTariffLimitPerUserAccountReached = function (tariffValidationState) {
        var countDifference = tariffValidationState.countDifference, tariffLimitPerUserAccount = tariffValidationState.tariffLimitPerUserAccount, isVoucherIncludedPerUserAccountLimit = tariffValidationState.isVoucherIncludedPerUserAccountLimit, isVoucher = tariffValidationState.isVoucher, numberOfBookedVouchers = tariffValidationState.numberOfBookedVouchers, numberOfAllBookedTariffs = tariffValidationState.numberOfAllBookedTariffs, currentUserAccountTariffLimit = tariffValidationState.currentUserAccountTariffLimit;
        var isUserAccountLimitActive = !!tariffLimitPerUserAccount;
        var checkUserAccountLimit = !isVoucher || isVoucherIncludedPerUserAccountLimit;
        if (!isUserAccountLimitActive || !checkUserAccountLimit) {
            return false;
        }
        var notIncludedVoucherTariffsCount = !isVoucher && !isVoucherIncludedPerUserAccountLimit ? numberOfBookedVouchers : 0;
        var availableAccountLimitTariffsCount = numberOfAllBookedTariffs - notIncludedVoucherTariffsCount;
        return availableAccountLimitTariffsCount + countDifference > currentUserAccountTariffLimit;
    };
    TariffCountValidationService.prototype.isPackageFixedAmountLimitReached = function (tariffValidationState) {
        var validatedCount = tariffValidationState.validatedCount, _a = tariffValidationState.packageSettings, amount = _a.amount, fixedAmount = _a.fixedAmount;
        return fixedAmount && (amount >= validatedCount || amount <= validatedCount);
    };
    TariffCountValidationService.prototype.isPackageMaxLimitReached = function (tariffValidationState) {
        var validatedCount = tariffValidationState.validatedCount, maxAmount = tariffValidationState.packageSettings.maxAmount;
        return maxAmount != null && maxAmount <= validatedCount;
    };
    TariffCountValidationService.prototype.isPackageMinLimitReached = function (tariffValidationState) {
        var validatedCount = tariffValidationState.validatedCount, minAmount = tariffValidationState.packageSettings.minAmount;
        return minAmount != null && minAmount >= validatedCount;
    };
    TariffCountValidationService.prototype.tariffAllowedWorkshopsStatus = function (count, tariff, availableSeats) {
        var allowedWorkshops = tariff.allowedWorkshops, allowedWorkshopsFull = tariff.allowedWorkshopsFull;
        var tariffHasNoWorkshops = !allowedWorkshops.length;
        if (tariffHasNoWorkshops || allowedWorkshopsFull) {
            return {
                allowedWorkshopsSeatsAvailable: 0,
                hideWorkshopTariffCounter: true,
                areAllAllowedWorkshopsAssigned: true
            };
        }
        var allowedWorkshopsSeatsAvailable = allowedWorkshops
            .map(function (allowedWorkshopId) { return availableSeats[allowedWorkshopId]; })
            .reduce(function (allowedWorkshopsTotalAvailableSeats, availableSeats) { return allowedWorkshopsTotalAvailableSeats + availableSeats; });
        var hideWorkshopTariffCounter = allowedWorkshopsSeatsAvailable <= 0 && count === 0;
        var areAllAllowedWorkshopsAssigned = hideWorkshopTariffCounter || allowedWorkshopsSeatsAvailable === 0;
        return {
            allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
            hideWorkshopTariffCounter: hideWorkshopTariffCounter,
            areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned
        };
    };
    TariffCountValidationService.prototype.calculatePercentageOfAvailableTariffs = function (count, tariffLimit, numberOfAvailableTariffs) {
        if (!tariffLimit || (!numberOfAvailableTariffs && !count)) {
            return 1;
        }
        return 1 - (tariffLimit - numberOfAvailableTariffs) / tariffLimit;
    };
    TariffCountValidationService.prototype.isTariffSoldOut = function (count, availableTariffs) {
        return availableTariffs !== null && availableTariffs === 0 && count === 0;
    };
    TariffCountValidationService.prototype.isCurrentUserAccountTariffLimitReached = function (ticketLimitPerUserAccount, currentUserAccountTicketLimit) {
        return !!ticketLimitPerUserAccount && currentUserAccountTicketLimit === 0;
    };
    TariffCountValidationService.prototype.setValidateCounterState = function (tariffValidationState, maxLimitCount, maxLimit, tariffLimitWarningMessage, isVoucherLimitReached, validateVoucherCountLimit) {
        if (isVoucherLimitReached === void 0) { isVoucherLimitReached = false; }
        if (validateVoucherCountLimit === void 0) { validateVoucherCountLimit = false; }
        tariffValidationState.isCountOverLimit = true;
        tariffValidationState.validatedCount = maxLimitCount > 0 ? maxLimitCount : 0;
        tariffValidationState.tariffLimitWarningMessage = tariffLimitWarningMessage;
        tariffValidationState.validatedMaxLimit = maxLimit;
        tariffValidationState.countDifference =
            tariffValidationState.validatedCount - tariffValidationState.previousValidatedCount;
        tariffValidationState.isVoucherLimitReached = isVoucherLimitReached;
        tariffValidationState.validateVoucherCountLimit = validateVoucherCountLimit;
    };
    TariffCountValidationService.ngInjectableDef = i0.defineInjectable({ factory: function TariffCountValidationService_Factory() { return new TariffCountValidationService(); }, token: TariffCountValidationService, providedIn: "root" });
    return TariffCountValidationService;
}());
export { TariffCountValidationService };
