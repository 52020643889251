var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BookingService } from '@products/services/booking.service';
import { HolderService } from '@products/services/holder.service';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { ActionTypes as BookingActionTypes, SetTicketClaimedHashValid } from '@store/products/booking/booking.actions';
import { getAllHolderUuidsFromBookedProducts } from '@store/products/booking/booking.selectors';
import { ActionTypes as HolderActionTypes, RemoveProductHolder, RemoveProductHoldersAndSetAnonymousHolder, SetPostTicketHolderFormResult, SetProductHolder, SetQuestionnaireInputs, SetTicketHolder } from '@store/products/holder/holder.actions';
import { getAllAnonymousHolderUuids } from '@store/products/holder/holder.selectors';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap, withLatestFrom } from 'rxjs/operators';
var HolderEffect = /** @class */ (function () {
    function HolderEffect(holderDebounceTime, actions$, store, bookingService, holderService, customizationService, helperService) {
        var _this = this;
        this.holderDebounceTime = holderDebounceTime;
        this.actions$ = actions$;
        this.store = store;
        this.bookingService = bookingService;
        this.holderService = holderService;
        this.customizationService = customizationService;
        this.helperService = helperService;
        // @Effect()
        // createHolder$: Observable<Action> = this.actions$.pipe(
        //   ofType<SetProductInBookingList | SetPackageInBookingList>(
        //     BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
        //     BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST
        //   ),
        //   withLatestFrom(this.store.pipe(select(getAllHolderUuids))),
        //   switchMap(([bookingAction, bookedHolderUuids]) => {
        //     const { payload: currentBookedOrRemovedProducts } = bookingAction;
        //     return this.holderService
        //       .calculateNumberOfHolderUuidsToCreate$(currentBookedOrRemovedProducts, bookedHolderUuids)
        //       .pipe(
        //         filter(numberOfHoldersToCreate => numberOfHoldersToCreate > 0),
        //         map(numberOfHoldersToCreate => new CreateProductHolder(numberOfHoldersToCreate))
        //       );
        //   }),
        //   catchError(error => throwError(error))
        // );
        this.setHolders$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST, BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST), switchMap(function () {
            return _this.holderService
                .addOrRemoveProductHoldersInputs$()
                .pipe(map(function (_a) {
                var holderUuidsToAddOrRemove = _a.holderUuidsToAddOrRemove, isProductBookingRemoved = _a.isProductBookingRemoved;
                return isProductBookingRemoved
                    ? new RemoveProductHolder(holderUuidsToAddOrRemove)
                    : new SetProductHolder(holderUuidsToAddOrRemove);
            }));
        }), catchError(function (error) { return throwError(error); }));
        this.setHolderForAnonymousProduct$ = this.actions$.pipe(ofType(BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST), withLatestFrom(this.store.pipe(select(getAllAnonymousHolderUuids))), map(function (_a) {
            var bookingAction = _a[0], bookedHolderUuids = _a[1];
            var currentBookedOrRemovedProducts = bookingAction.payload;
            var latestBookedHolderUuids = _this.bookingService.getSetProductBookingsHolderUuids(currentBookedOrRemovedProducts);
            var isFirstAnonymousVoucher = !bookedHolderUuids.length;
            if (isFirstAnonymousVoucher) {
                _this.holderService.removeHoldersInputs(bookedHolderUuids);
            }
            _this.holderService.addHoldersInputs(latestBookedHolderUuids);
            return isFirstAnonymousVoucher
                ? new RemoveProductHoldersAndSetAnonymousHolder(latestBookedHolderUuids)
                : new SetProductHolder(latestBookedHolderUuids);
        }), catchError(function (error) { return throwError(error); }));
        this.removeHolders$ = this.actions$.pipe(ofType(BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST, BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST), withLatestFrom(this.store.pipe(select(getAllHolderUuidsFromBookedProducts))), map(function (_a) {
            var _ = _a[0], bookedHolderUuids = _a[1];
            _this.holderService.removeHoldersInputs(bookedHolderUuids);
            return new RemoveProductHolder(bookedHolderUuids);
        }), catchError(function (error) { return throwError(error); }));
        this.setHolderQuestionnaireInputs$ = this.actions$.pipe(ofType(HolderActionTypes.GET_QUESTIONNAIRE_INPUTS), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var _b = _a.payload, eventId = _b.eventId, ticketPersonIds = _b.ticketPersonIds;
            if (!eventId) {
                return EMPTY;
            }
            return _this.holderService.getTariffHolderQuestionnaire$(eventId, ticketPersonIds).pipe(map(function (tariffHolderQuestionnaire) {
                return new SetQuestionnaireInputs(_this.customizationService.tansformQuestionnaireIntoInput(tariffHolderQuestionnaire));
            }), catchError(function () { return of(new SetQuestionnaireInputs([])); }));
        }));
        this.getClaimingTicketHolder$ = this.actions$.pipe(ofType(HolderActionTypes.LOAD_CLAIMING_TICKET_HOLDER), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var hash = _a.payload;
            if (!hash) {
                return EMPTY;
            }
            return _this.holderService.getTicketByHolder(hash).pipe(map(function (ticketHolder) {
                // if we load the user
                if (ticketHolder.hasOwnProperty('id')) {
                    _this.store.dispatch(new SetTicketClaimedHashValid(true));
                    return new SetTicketHolder(ticketHolder);
                }
                else {
                    // else if the has is not valid, we need to give some feedback
                    _this.store.dispatch(new SetTicketClaimedHashValid(false));
                    return new SetTicketHolder(null);
                }
            }), catchError(function () {
                _this.store.dispatch(new SetTicketClaimedHashValid(false));
                return of(new SetTicketHolder(null));
            }));
        }));
        this.postTariffHolderQuestionnaire$ = this.actions$.pipe(ofType(HolderActionTypes.POST_TICKET_HOLDER_FORM), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var ticketHolderForm = _a.payload;
            if (ticketHolderForm === null) {
                return EMPTY;
            }
            return _this.holderService.postTicketHolderForm(ticketHolderForm).pipe(map(function () {
                return new SetPostTicketHolderFormResult(true);
            }), catchError(function () { return of(new SetPostTicketHolderFormResult(false)); }));
        }));
        this.downloadTicket$ = this.actions$.pipe(ofType(HolderActionTypes.DOWNLOAD_TICKET), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var hash = _a.payload;
            if (!hash) {
                return EMPTY;
            }
            return _this.holderService.downloadTicket(hash).pipe(map(function (blob) {
                _this.helperService.saveToFileSystem(blob, 'ticket.pdf');
                // if we load the user
                return new SetTicketHolder(null);
            }), catchError(function () {
                return of(new SetTicketHolder(null));
            }));
        }));
        this.downloadMobileTicket$ = this.actions$.pipe(ofType(HolderActionTypes.DOWNLOAD_MOBILE_TICKET), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var hash = _a.payload;
            if (!hash) {
                return EMPTY;
            }
            return _this.holderService.downloadMobileTicket(hash).pipe(map(function (blob) {
                _this.helperService.saveToFileSystem(blob, 'MobileTicket.pdf');
                // if we load the user
                return new SetTicketHolder(null);
            }), catchError(function () {
                return of(new SetTicketHolder(null));
            }));
        }));
        this.downloadPassBook$ = this.actions$.pipe(ofType(HolderActionTypes.DOWNLOAD_PASS_BOOK), debounceTime(this.holderDebounceTime), switchMap(function (_a) {
            var hash = _a.payload;
            if (!hash) {
                return EMPTY;
            }
            return _this.holderService.downloadPassBook(hash).pipe(map(function (blob) {
                _this.helperService.saveToFileSystem(blob, 'Passbook.pkpass');
                // if we load the user
                return new SetTicketHolder(null);
            }), catchError(function () {
                return of(new SetTicketHolder(null));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "setHolders$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "setHolderForAnonymousProduct$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "removeHolders$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "setHolderQuestionnaireInputs$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "getClaimingTicketHolder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "postTariffHolderQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "downloadTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "downloadMobileTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], HolderEffect.prototype, "downloadPassBook$", void 0);
    return HolderEffect;
}());
export { HolderEffect };
