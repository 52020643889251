import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/app.reducer';
import { Store } from '@ngrx/store';
import { BookingTariffType } from '@products/models/booking.model';
import { PackageType } from '@products/models/package.model';
import { Tariff, TariffType } from '@products/models/tariff.model';
import { BookingService } from '@products/services/booking.service';
import { GtmService } from '@root/src/app/shared/gtm/gtmService';
import {
  RemovePackageTariffBooking,
  RemoveTariffBooking,
  RemoveVoucherTariffBooking,
  SetPackageTariffBooking,
  SetSelfRegistrationTariffBookings,
  SetTariffBooking,
  SetVoucherTariffBooking
} from '@store/products/product.actions';

@Injectable()
export class TariffService {
  constructor(
    private store: Store<State>,
    private bookingService: BookingService,
    private gtmService: GtmService,
    private http: HttpClient
  ) {}

  updateTariffCount(tariffType: TariffType, tariff: Tariff, previousCount: number, updatedCount: number) {
    const bookingTariffType = this.bookingService.mapTariffTypeToBookingTariffType(
      tariffType,
      tariff,
      previousCount,
      updatedCount
    );

    if (updatedCount > 0) {
      this.setTariff(tariff.isVoucher, bookingTariffType);
      this.gtmService.pushAddToCart(bookingTariffType);
    } else if (updatedCount >= 0) {
      this.removeTariff(tariff);
      this.gtmService.pushRemoveFromCart(bookingTariffType);
    }
  }

  updatePackageTariffCount(
    packageType: PackageType,
    tariffType: TariffType,
    tariff: Tariff,
    previousCount: number,
    updatedCount: number
  ) {
    const bookingPackageProductType = this.bookingService.mapPackageTypeTariffToBookingPackageTypeTariff(
      packageType,
      tariffType,
      tariff,
      previousCount,
      updatedCount
    );

    if (updatedCount > 0) {
      this.store.dispatch(new SetPackageTariffBooking([bookingPackageProductType]));
      this.gtmService.pushAddToCart(bookingPackageProductType);
    } else {
      this.store.dispatch(
        new RemovePackageTariffBooking({
          packageNumber: tariff.packageNumber,
          packageIndex: tariff.packageIndex,
          ticketTypeId: tariff.ticketTypeId,
          ticketPersonId: tariff.ticketPersonId
        })
      );
      this.gtmService.pushRemoveFromCart(bookingPackageProductType);
    }
  }

  setTariff(isVoucherTariff: boolean, bookingTariffType: BookingTariffType) {
    if (isVoucherTariff) {
      this.store.dispatch(new SetVoucherTariffBooking([bookingTariffType]));
    } else {
      this.store.dispatch(new SetTariffBooking([bookingTariffType]));
    }
  }

  removeTariff(tariff: Tariff) {
    if (tariff.isVoucher) {
      this.store.dispatch(
        new RemoveVoucherTariffBooking({
          ticketPersonId: tariff.ticketPersonId,
          voucherCode: tariff.voucherCode
        })
      );
    } else {
      this.store.dispatch(
        new RemoveTariffBooking({
          ticketTypeId: tariff.ticketTypeId,
          ticketPersonId: tariff.ticketPersonId
        })
      );
    }
  }

  setSelfRegistrationTariff(tariffType: TariffType, tariff: Tariff, count: number) {
    const selfRegistrationTariffCount = !count ? 1 : 0;
    const removeCurrentTariffBooking = selfRegistrationTariffCount === 0;
    const setTariffBookings = selfRegistrationTariffCount === 1;

    if (setTariffBookings) {
      const bookingTariffType = this.bookingService.mapTariffTypeToBookingTariffType(
        tariffType,
        tariff,
        0,
        selfRegistrationTariffCount
      );

      this.store.dispatch(new SetSelfRegistrationTariffBookings([bookingTariffType]));
    } else if (removeCurrentTariffBooking) {
      this.removeTariff(tariff);
    }
  }
}
