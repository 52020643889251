var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy } from '@angular/core';
import { TariffWarningMessage } from '@products/models/tariff.model';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var TariffValidationService = /** @class */ (function () {
    function TariffValidationService(tariffCountValidationService) {
        var _this = this;
        this.tariffCountValidationService = tariffCountValidationService;
        this.destroy$ = new Subject();
        this._tariffValidationState$ = new BehaviorSubject({
            count: 0,
            countDifference: 0,
            validatedCount: 0,
            previousValidatedCount: 0,
            bookedCount: 0,
            validatedMaxLimit: 0,
            isCountOverLimit: false,
            isTariffSoldOut: false,
            isAvailableTariffsLimitReached: false,
            numberOfAllBookedTariffs: null,
            initialNumberOfAvailableTariffs: null,
            currentNumberOfAvailableTariffs: null,
            percentageOfAvailableTariffs: 0,
            tariffLimit: null,
            maxTariffLimit: null,
            tariffLimitPerUserAccount: null,
            currentUserAccountTariffLimit: null,
            isCurrentUserAccountTariffLimitReached: false,
            allowedWorkshopsSeatsAvailable: null,
            voucherCountLimit: null,
            validateVoucherCountLimit: true,
            maxVoucherLimit: null,
            maxLimitedVoucherLimit: null,
            numberOfBookedVouchers: 0,
            numberOfBookedLimitedVouchers: 0,
            numberOfBookedOneTimeVouchers: 0,
            isTariffClassificationNormal: false,
            isTariffClassificationParking: false,
            hideWorkshopTariffCounter: false,
            areAllAllowedWorkshopsAssigned: false,
            isWorkshopsSelectionMandatoryAndHasWorkshops: false,
            isVoucher: false,
            isVoucherTypePromoCode: false,
            isVoucherTypeLimitedPromoCode: false,
            isVoucherTypeOneTimeVoucher: false,
            isVoucherIncludedPerUserAccountLimit: false,
            isVoucherLimitReached: false,
            isPackageFixedAmountLimitReached: false,
            isPackageMinAmountLimitReached: false,
            isPackageMaxAmountLimitReached: false,
            isPackageTypeAndIsPackageNotAdded: false,
            packageSettings: null,
            packageTooltipMessage: '',
            showMobilePackageTooltipMessage: false,
            tariffLimitWarningMessage: ''
        });
        this.tariffValidationState$ = this._tariffValidationState$.asObservable().distinctUntilChanged();
        this.tariffValidationState$.pipe(takeUntil(this.destroy$)).subscribe(function (tariffValidationState) {
            _this.tariffValidationServiceState = __assign({}, _this.tariffValidationServiceState, tariffValidationState);
        });
    }
    TariffValidationService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    TariffValidationService.prototype.initAndValidateState = function (tariffValidationState) {
        var initialTariffValidationState = __assign({}, this.tariffValidationServiceState, tariffValidationState);
        this.validate(initialTariffValidationState);
        this._tariffValidationState$.next(initialTariffValidationState);
    };
    TariffValidationService.prototype.updateState = function (tariffValidationState) {
        this._tariffValidationState$.next(__assign({}, this.tariffValidationServiceState, tariffValidationState));
    };
    TariffValidationService.prototype.validateState = function (count, tariffValidationState) {
        tariffValidationState.count = count;
        this.validate(tariffValidationState);
        this._tariffValidationState$.next(__assign({}, this.tariffValidationServiceState, tariffValidationState));
    };
    TariffValidationService.prototype.getValidatedState = function () {
        return this.tariffValidationServiceState;
    };
    TariffValidationService.prototype.geValidatedStateProperty = function (propertyName) {
        return propertyName in this.tariffValidationServiceState ? this.tariffValidationServiceState[propertyName] : null;
    };
    TariffValidationService.prototype.resetValidatedState = function (tariffValidationState) {
        tariffValidationState.countDifference = 0;
        tariffValidationState.isCountOverLimit = false;
    };
    TariffValidationService.prototype.revalidateDisabledCounter = function (tariffValidationState) {
        var tariffLimitWarningMessage = tariffValidationState.tariffLimitWarningMessage, isVoucherLimitReached = tariffValidationState.isVoucherLimitReached, initialNumberOfAvailableTariffs = tariffValidationState.initialNumberOfAvailableTariffs;
        if (tariffLimitWarningMessage || isVoucherLimitReached) {
            var resetValidationWarningMessage = [
                this.tariffCountValidationService.isTariffLimitPerUserAccountReached(tariffValidationState),
                this.tariffCountValidationService.isVoucherCountLimitReached(tariffValidationState),
                this.tariffCountValidationService.isPromoCodeVoucherTariffLimitReached(tariffValidationState),
                this.tariffCountValidationService.isLimitedPromoCodeVoucherTariffLimitReached(tariffValidationState),
                this.tariffCountValidationService.isOneTimeVoucherTariffLimitReached(tariffValidationState),
                this.tariffCountValidationService.isMaxTariffLimitReached(tariffValidationState)
            ].every(function (isLimitReached) { return !isLimitReached; });
            var isAvailableTariffsLimitReached = this.tariffCountValidationService.isAvailableTariffsLimitReached(tariffValidationState);
            if (resetValidationWarningMessage) {
                tariffValidationState.tariffLimitWarningMessage = '';
                tariffValidationState.isVoucherLimitReached = false;
                tariffValidationState.isCurrentUserAccountTariffLimitReached = false;
            }
            if (isAvailableTariffsLimitReached) {
                tariffValidationState.tariffLimitWarningMessage = TariffWarningMessage.ERROR_TICKET_WARNING;
                tariffValidationState.validatedMaxLimit = initialNumberOfAvailableTariffs;
                tariffValidationState.isAvailableTariffsLimitReached = true;
            }
            if (resetValidationWarningMessage || isAvailableTariffsLimitReached)
                this._tariffValidationState$.next(__assign({}, this.tariffValidationServiceState, tariffValidationState));
        }
    };
    TariffValidationService.prototype.revalidateIsAvailableTariffsLimitReached = function (tariffValidationState) {
        if (this.tariffCountValidationService.isAvailableTariffsLimitReached(tariffValidationState)) {
            tariffValidationState.tariffLimitWarningMessage = TariffWarningMessage.ERROR_TICKET_WARNING;
            tariffValidationState.validatedMaxLimit = tariffValidationState.initialNumberOfAvailableTariffs;
        }
        this._tariffValidationState$.next(__assign({}, this.tariffValidationServiceState, tariffValidationState));
    };
    TariffValidationService.prototype.validate = function (tariffValidationState) {
        var count = tariffValidationState.count, validatedCount = tariffValidationState.validatedCount;
        tariffValidationState.countDifference = count - validatedCount;
        tariffValidationState.previousValidatedCount = validatedCount;
        this.tariffCountValidationService.validateIsAvailableTariffsLimit(tariffValidationState);
        this.tariffCountValidationService.validateTariffLimitPerUserAccount(tariffValidationState);
        this.tariffCountValidationService.validatePromoCodeVoucherTariff(tariffValidationState);
        this.tariffCountValidationService.validateLimitedPromoCodeVoucherTariff(tariffValidationState);
        this.tariffCountValidationService.validateOneTimeVoucherTariff(tariffValidationState);
        /*
          TODO: Workshop tariff max limit validation required shared booking tariff count selector of allowed workshops, because change made in update workshop available seats logic
          Workshop tariff limit validation because workshop validation is set by workshop validation service. If user selects tariffs that are exceeding
          allowed workshops available seats limit and workshop selection is mandatory then workshop validation for step form will disable the next step until
          the user removes tariff count to same or less than allowed workshops available seats count.
        */
        // this.tariffCountValidationService.validateWorkshopTariff(tariffValidationState);
        this.tariffCountValidationService.validateMaxTariffLimit(tariffValidationState);
        this.tariffCountValidationService.validateVoucherCountLimit(tariffValidationState);
        this.tariffCountValidationService.validateInLimitCounter(count, tariffValidationState);
        this.tariffCountValidationService.validatePackageTariffLimit(tariffValidationState);
        this.resetValidatedState(tariffValidationState);
    };
    return TariffValidationService;
}());
export { TariffValidationService };
