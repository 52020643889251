<div *ngIf="hasSponsors" class="voucher-info-wrapper">
  <h4 class="sponsors-headline" data-translation="ticket-selection.voucher.subtitle">
    {{ 'ticket-selection.voucher.subtitle' | translate | hideTranslation }}
  </h4>
  <div *ngFor="let voucher of vouchers">
    <div *ngFor="let sponsor of voucher.sponsors" class="voucher-info col-6 col-sm-3 col-md-2">
      <a target="_blank" [href]="sponsor.link">
        <img class="voucher-logo" [src]="sponsor.logo" *ngIf="sponsor.logo" />
        <span class="sponsor-name black"> {{ sponsor.name }} </span>
      </a>
    </div>
  </div>
</div>
