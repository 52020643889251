<div class="counter">
  <div
    class="counter-info tickets-left"
    data-translation="'ticket-selection.tickets-left'"
    *ngIf="
      tariffValidationViewModel.isTariffClassificationNormal &&
      tariffValidationViewModel.percentageOfAvailableTariffs < AppConstants.SHOW_AVAILABLE_TARIFF_PERCENTAGE &&
      !(
        tariffValidationViewModel.isWorkshopsSelectionMandatoryAndHasWorkshops &&
        tariffValidationViewModel.allowedWorkshopsSeatsAvailable <= count
      )
    "
  >
    {{
      'ticket-selection.tickets-left'
        | translate: { availableTickets: tariffValidationViewModel.currentNumberOfAvailableTariffs }
        | hideTranslation
    }}
  </div>
  <div
    class="counter-info sold-out"
    *ngIf="
      tariffValidationViewModel.isTariffSoldOut &&
      tariffValidationViewModel.isTariffClassificationNormal &&
      !tariffValidationViewModel.isTariffClassificationParking
    "
    [attr.data-translation]="TariffWarningMessage.TARIFF_SOLD_OUT"
  >
    {{ TariffWarningMessage.TARIFF_SOLD_OUT | translate | hideTranslation }}
  </div>

  <div
    class="counter-wrapper"
    *ngIf="
      tariffValidationViewModel.isTariffClassificationParking ||
      (!(
        tariffValidationViewModel.isWorkshopsSelectionMandatoryAndHasWorkshops &&
        tariffValidationViewModel.hideWorkshopTariffCounter
      ) &&
        !tariffValidationViewModel.isTariffSoldOut &&
        tariffValidationViewModel.isTariffClassificationNormal)
    "
  >
    <button
      class="counter-button"
      type="button"
      [ngClass]="{ 'is-tariff-loading': isTariffLoading, 'is-workshop-detail-modal-open': isWorkshopDetailModalOpen }"
      [disabled]="
        !count ||
        tariffValidationViewModel.isPackageFixedAmountLimitReached ||
        tariffValidationViewModel.isPackageMinAmountLimitReached
      "
      (click)="onDecrease()"
    >
      <span>-</span>
    </button>
    <div class="selector-value">
      <div *ngIf="isTariffLoading" class="button-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <input
        class="counter-input"
        type="number"
        *ngIf="!isTariffLoading"
        appNumbersOnly
        [ngClass]="{ 'is-workshop-detail-modal-open': isWorkshopDetailModalOpen }"
        [(ngModel)]="count"
        [disabled]="
          (!count &&
            (tariffValidationViewModel.isVoucherLimitReached ||
              tariffValidationViewModel.tariffLimitWarningMessage ||
              (tariffValidationViewModel.isAvailableTariffsLimitReached &&
                tariffValidationViewModel.isTariffClassificationNormal))) ||
          tariffValidationViewModel.isPackageTypeAndIsPackageNotAdded ||
          tariffValidationViewModel.isPackageFixedAmountLimitReached
        "
        (change)="onCountChange($event.target.value)"
      />
    </div>

    <button
      class="counter-button"
      type="button"
      [ngClass]="{ 'is-tariff-loading': isTariffLoading, 'is-workshop-detail-modal-open': isWorkshopDetailModalOpen }"
      [disabled]="
        (tariffValidationViewModel.isWorkshopsSelectionMandatoryAndHasWorkshops &&
          tariffValidationViewModel.areAllAllowedWorkshopsAssigned) ||
        tariffValidationViewModel.isVoucherLimitReached ||
        tariffValidationViewModel.tariffLimitWarningMessage ||
        (tariffValidationViewModel.isAvailableTariffsLimitReached &&
          tariffValidationViewModel.isTariffClassificationNormal) ||
        tariffValidationViewModel.isPackageTypeAndIsPackageNotAdded ||
        tariffValidationViewModel.isPackageFixedAmountLimitReached ||
        tariffValidationViewModel.isPackageMaxAmountLimitReached
      "
      (click)="onIncrease()"
    >
      <span>+</span>
    </button>
  </div>
</div>

<div
  class="ticket-selection-warning"
  *ngIf="
    !tariffValidationViewModel.isTariffSoldOut &&
    tariffValidationViewModel.tariffLimitWarningMessage &&
    !(
      !tariffValidationViewModel.isAvailableTariffsLimitReached &&
      tariffValidationViewModel.isWorkshopsSelectionMandatoryAndHasWorkshops &&
      tariffValidationViewModel.areAllAllowedWorkshopsAssigned
    )
  "
  [attr.data-translation]="tariffValidationViewModel.tariffLimitWarningMessage"
>
  {{
    tariffValidationViewModel.tariffLimitWarningMessage
      | translate
        : {
            maxTicketLimit: tariffValidationViewModel.validatedMaxLimit,
            ticketLimitPerUserAccount: tariffValidationViewModel.tariffLimitPerUserAccount
          }
      | hideTranslation
  }}
</div>
<div
  *ngIf="
    !tariffValidationViewModel.isTariffSoldOut &&
    tariffValidationViewModel.isWorkshopsSelectionMandatoryAndHasWorkshops &&
    tariffValidationViewModel.areAllAllowedWorkshopsAssigned
  "
  [attr.data-translation]="TariffWarningMessage.WORKSHOPS_SOLD_OUT"
>
  {{ TariffWarningMessage.WORKSHOPS_SOLD_OUT | translate | hideTranslation }}
</div>
