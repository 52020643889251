import { Action } from '@ngrx/store';

import { WorkshopAssignedHolders, WorkshopAvailableSeats } from '@products/models/workshop-status.model';

export enum ActionTypes {
  SET_WORKSHOP_AVAILABLE_SEATS_STATUS = '[Workshop Status] Set workshop available seats status',
  SET_WORKSHOP_ASSIGNED_HOLDERS_STATUS = '[Workshop Status] Set workshop assigned holders status',
  SET_WORKSHOP_AVAILABLE_SEATS_AND_ASSIGNED_HOLDERS_STATUS = '[Workshop Status] Set workshop available seats and assigned holders status',
  SET_IS_WORKSHOP_LOADING_STATUS = '[Workshop Status] Set is workshop loading status',
  SET_INITIAL_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS = '[Workshop Status] Set initial is workshop detail modal open status',
  SET_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS = '[Workshop Status] Set is workshop detail modal open status',
  TOGGLE_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS = '[Workshop Status] Toggle is workshop detail modal open status',
  RESET_REDUCER = '[Shop Reset] Reset reducer'
}

export class SetWorkshopAvailableSeatsStatus implements Action {
  readonly type = ActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_STATUS;
  constructor(public payload: WorkshopAvailableSeats) {}
}

export class SetWorkshopAssignedHoldersStatus implements Action {
  readonly type = ActionTypes.SET_WORKSHOP_ASSIGNED_HOLDERS_STATUS;
  constructor(public payload: WorkshopAssignedHolders) {}
}

export class SetWorkshopAvailableSeatsAndAssignedHoldersStatus implements Action {
  readonly type = ActionTypes.SET_WORKSHOP_AVAILABLE_SEATS_AND_ASSIGNED_HOLDERS_STATUS;
  constructor(
    public payload: {
      availableSeats: WorkshopAvailableSeats;
      assignedHolders: WorkshopAssignedHolders;
    }
  ) {}
}

export class SetIsWorkshopLoadingStatus implements Action {
  readonly type = ActionTypes.SET_IS_WORKSHOP_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export class SetInitialIsWorkshopDetailModalOpenStatus implements Action {
  readonly type = ActionTypes.SET_INITIAL_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS;
}

export class SetIsWorkshopDetailModalOpenStatus implements Action {
  readonly type = ActionTypes.SET_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS;
  constructor(public payload: boolean) {}
}

export class ToggleIsWorkshopDetailModalOpenStatus implements Action {
  readonly type = ActionTypes.TOGGLE_IS_WORKSHOP_DETAIL_MODAL_OPEN_STATUS;
}

export class ResetWorkshopStatus implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | SetWorkshopAssignedHoldersStatus
  | SetWorkshopAvailableSeatsStatus
  | SetWorkshopAvailableSeatsAndAssignedHoldersStatus
  | SetIsWorkshopLoadingStatus
  | SetInitialIsWorkshopDetailModalOpenStatus
  | SetIsWorkshopDetailModalOpenStatus
  | ToggleIsWorkshopDetailModalOpenStatus
  | ResetWorkshopStatus;
