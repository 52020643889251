import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  State,
  ageRating,
  getExhibitionSettings,
  getLanguage,
  getLegitimationStatus,
  getLocalizedImages,
  getOperatorsSettings,
  getProfile,
  getSelectedExhibitionId,
  getSelectedStep,
  getSelfRegistration,
  getSupportedLanguages,
  hideTopBarLogin,
  isEventSeriesPage,
  isLoggedInAsAdmin,
  isUserLoggedIn
} from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { AppConstants } from '@shared/app-constants';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { environment } from '@src/environments/environment';
import { GetOperatorsSettings } from '@store/customization/customization.actions';
import { ExhibitionSettingModel, OperatorsSettingsModel } from '@store/customization/customization.interfaces';
import { CustomizationService } from '@store/customization/customization.service';
import { HelperService } from '@store/helpers/helper.service';
import { LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { getAllBookedProductsCount } from '@store/products/booking/booking.selectors';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { UserProfileModel } from '@store/user/user.interface';
import { UserService } from '@store/user/user.service';
import { Observable, Subscription, fromEvent as observableFromEvent, of as observableOf } from 'rxjs';
import { delay, filter, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  env: any = environment;
  breakpoints: {};
  mediumBreakpoint: {};
  isMenuOpen: boolean;
  isLangMenuOpen: boolean;
  viewport: number;
  modalWindowOpen = false;
  isEventSelected: boolean = null;
  activeUser: any;
  currentLanguage: string;
  isLoggedInAsAdmin: boolean;
  profile: UserProfileModel;
  getOperatorsSettings: OperatorsSettingsModel;
  basketOpened = false;
  ageRating: number;
  logoUrl: string;
  isSelfRegistrationEnabled: boolean;
  isEventSeriesPage: boolean = false;
  totalCount: number = 0;
  displayBackToEvent = false;
  displayOnMaintenancePage = false;
  displayBactToSelfReg = false;
  selectedStep: string;
  isMobile: boolean = false;
  exhibitionSettings: ExhibitionSettingModel;
  isLoggedIn$: Observable<boolean>;
  isLoggedInAsAdmin$: Observable<boolean>;
  profile$: Observable<UserProfileModel>;
  legitimationStatus$: Observable<LegitimationStatus>;
  language$: Observable<string>;
  supportedLanguages$: Observable<string[]>;
  hideTopBarLogin$: Observable<boolean>;
  bookedProductsCount$: Observable<number>;
  readonly LegitimationStatus = LegitimationStatus;

  private getSelectedExhibitionId: number;
  private result;
  private windowResize: {};
  private readonly _subscriptions: Subscription = new Subscription();

  constructor(
    public router: Router,
    public userService: UserService,
    public store: Store<State>,
    private windowSizeService: WindowSizeService,
    private customizationService: CustomizationService,
    private helperService: HelperService,
    private stepsFormsService: StepsFormsService
  ) {
    this.isMobile = this.helperService.isMobile();

    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      const resize = observableFromEvent(window, 'resize');
      this.result = resize.pipe(throttleTime(100));
    } else {
      this.result = observableOf(null);
    }

    this._subscriptions.add(
      this.router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationEnd) {
          const currentUrl = this.router.url;

          this.displayBackToEvent = this.shouldDisplayBackToEvent(currentUrl);
          this.displayOnMaintenancePage = this.shouldDisplayOnMaintenancePage(currentUrl);
          this.displayBactToSelfReg = this.shouldDisplayBactToSelfReg(currentUrl);
        }
      })
    );

    this._subscriptions.add(
      this.store.pipe(select(getSelfRegistration)).subscribe(selfRegistration => {
        this.isSelfRegistrationEnabled = selfRegistration;
      })
    );

    this._subscriptions.add(
      this.store.pipe(select(isEventSeriesPage)).subscribe(eventSeriesPage => {
        if (!!eventSeriesPage) {
          this.isEventSeriesPage = eventSeriesPage.isEventSeries;
        } else {
          this.isEventSeriesPage = false;
        }
      })
    );

    this.breakpoints = AppConstants.BREAKPOINTS;
    this.mediumBreakpoint = this.breakpoints['md'];
    this.isLoggedIn$ = this.store.pipe(select(isUserLoggedIn));
    this.legitimationStatus$ = this.store.pipe(select(getLegitimationStatus));
    this.isLoggedInAsAdmin$ = this.store.pipe(select(isLoggedInAsAdmin));
    this.profile$ = this.store.pipe(select(getProfile));
    this.language$ = this.store.pipe(select(getLanguage));
    this.supportedLanguages$ = this.store.pipe(select(getSupportedLanguages));
    this.hideTopBarLogin$ = this.store.pipe(select(hideTopBarLogin));
    this.store
      .pipe(
        select(ageRating),
        delay(0)
      )
      .subscribe(ageRating => (this.ageRating = ageRating));

    this._subscriptions.add(
      this.store.pipe(select(getSelectedStep)).subscribe(selectedStep => {
        this.selectedStep = selectedStep;
      })
    );

    // ensure that any page with top bar has operator settings
    this.store.dispatch(new GetOperatorsSettings());

    this._subscriptions.add(
      this.store.pipe(select(getSelectedExhibitionId)).subscribe(eventId => {
        this.getSelectedExhibitionId = eventId;
        this.isEventSelected = eventId && true;
      })
    );

    this._subscriptions.add(
      this.store
        .pipe(
          select(getLocalizedImages),
          filter(images => !!images)
        )
        .subscribe(images => {
          this.logoUrl = images.logo;
        })
    );

    this._subscriptions.add(
      this.store
        .pipe(
          select(getOperatorsSettings),
          filter(settings => !!settings)
        )
        .subscribe(operatorsSettings => {
          customizationService.setStyles();
          this.getOperatorsSettings = operatorsSettings;
        })
    );

    this._subscriptions.add(
      this.profile$.subscribe(profile => {
        this.profile = profile;
      })
    );

    this._subscriptions.add(
      this.isLoggedInAsAdmin$.subscribe(isAdmin => {
        this.isLoggedInAsAdmin = isAdmin;
        if (!isAdmin) {
          this.helperService.appEl.classList.remove('admin');
          return false;
        }
        this.helperService.appEl.classList.add('admin');
      })
    );

    this._subscriptions.add(
      this.language$.pipe(filter(lang => !!lang)).subscribe(lang => {
        this.currentLanguage = lang;
      })
    );

    this._subscriptions.add(this.supportedLanguages$.pipe(filter(langs => !!langs)).subscribe(langs => {}));
  }

  ngOnInit() {
    this.isMenuOpen = false;
    this.isLangMenuOpen = false;
    this.windowResize = this.result.startWith(null).subscribe(() => {
      this.viewport = this.windowSizeService.viewportWidth();
    });

    this._subscriptions.add(
      this.store.pipe(select(getExhibitionSettings)).subscribe(exhibitionSettings => {
        this.exhibitionSettings = exhibitionSettings;
      })
    );

    this.bookedProductsCount$ = this.store.pipe(select(getAllBookedProductsCount));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  closeBasket(closed: Boolean) {
    this.basketOpened = !closed;
  }

  toggleBasket() {
    this.basketOpened = !this.basketOpened;
    if (this.basketOpened) {
      this.isLangMenuOpen = false;
    }
  }

  toggleMenuButton() {
    return (this.isMenuOpen = !this.isMenuOpen);
  }

  togleSubmenuButton() {
    return (this.isLangMenuOpen = !this.isLangMenuOpen);
  }

  closeMenu() {
    return (this.isMenuOpen = false);
  }

  shouldDisplayOnMaintenancePage(currentUrl) {
    const webshopUrlReg = '/maintenance';

    if (currentUrl !== '/') {
      if (!this.isLoggedInAsAdmin) {
        const urlMatch = currentUrl.match(webshopUrlReg);
        return urlMatch && true;
      } else {
        return false;
      }
    }
  }

  shouldDisplayBackToEvent(currentUrl) {
    const webshopUrlReg = '/webshop.+';

    if (currentUrl !== '/') {
      const urlMatch = currentUrl.match(webshopUrlReg);
      return urlMatch && true;
    }
  }

  shouldDisplayBactToSelfReg(currentUrl) {
    const webshopUrlReg = '/self-registration.+';
    const urlMatch = !currentUrl.match(webshopUrlReg);
    return urlMatch && true;
  }

  changeLanguage(lang) {
    if (this.currentLanguage !== lang) {
      this.userService.changePreferredLanguage(lang);
      this.helperService.setLanguage(lang);
    }

    this.isLangMenuOpen = false;
  }

  openModalWindow() {
    this.modalWindowOpen = true;
  }

  backToShop() {
    this.router.navigate([
      `webshop/${this.getSelectedExhibitionId}/${!!this.selectedStep ? this.selectedStep : 'tickets'}`
    ]);
  }

  backToSelfReg() {
    this.router.navigate([`self-registration/${this.getSelectedExhibitionId}/${this.selectedStep}`]);
  }

  submitModalWindow(event$: MouseEvent) {
    event$.stopPropagation();
    this.router.navigate(['/']);
    this.modalWindowOpen = false;
  }

  closeModalWindow(event$: MouseEvent) {
    event$.stopPropagation();
    this.modalWindowOpen = false;
  }
  closeModalWindowOnRightClick(event$: MouseEvent) {
    event$.stopPropagation();
    this.modalWindowOpen = false;
  }
}
