var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PackageTooltip } from '@products/models/package.model';
import { TariffClassification, TariffCurrencyCodes, TariffInfoExpandedTranslationResponse, TariffWarningMessage } from '@products/models/tariff.model';
import { VoucherType } from '@products/models/voucher.model';
import { PreferredTariffValidationService } from '@products/services/preferred-tariff-validation.service';
import { TariffCountValidationService } from '@products/services/tariff-count-validation.service';
import { TariffValidationService } from '@products/services/tariff-validation.service';
import { TariffService } from '@products/services/tariff.service';
import { VoucherService } from '@products/services/voucher.service';
import { AppConstants } from '@shared/app-constants';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { areBookedTariffReservationsValid, getBookedTariffCount, getBookedTariffParkingReservationTotalPrice } from '@store/products/booking/booking.selectors';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
var TariffComponent = /** @class */ (function () {
    function TariffComponent(actions$, router, store, translateService, tariffService, voucherService, tariffValidationService, tariffCountValidationService, preferredTariffValidationService) {
        this.actions$ = actions$;
        this.router = router;
        this.store = store;
        this.translateService = translateService;
        this.tariffService = tariffService;
        this.voucherService = voucherService;
        this.tariffValidationService = tariffValidationService;
        this.tariffCountValidationService = tariffCountValidationService;
        this.preferredTariffValidationService = preferredTariffValidationService;
        this.TariffCurrencyCodes = TariffCurrencyCodes;
        this.TariffWarningMessage = TariffWarningMessage;
        this.TariffClassification = TariffClassification;
        this.destroy$ = new Subject();
    }
    TariffComponent.prototype.ngOnInit = function () {
        this.initTariffCounterSubscription();
        this.initBookedTariffReservationsValidityCheck();
        this.initBookedTariffParkingReservationsTotalPrice();
        this.initTariffValidationServiceState();
        this.initTariffValidationServiceSubscription();
        this.initPreferredTariffBookingValidationSubscription();
        this.initWidgetBannerTariffBookingValidationSubscription();
        this.initFailedBookingValidationStateRevalidationSubscription();
    };
    TariffComponent.prototype.ngOnChanges = function (changes) {
        this.updateTariffInfoState();
        this.updateTariffValidationState();
        this.revalidateCounterOnBookingCountDecreaseStateChange(changes);
        this.revalidateIsTariffSoldOutOnBookingCountIncreaseStateChange(changes);
    };
    TariffComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    };
    TariffComponent.prototype.initTariffCounterSubscription = function () {
        var _this = this;
        this.store
            .pipe(select(getBookedTariffCount(this.tariff.ticketTypeId, this.tariff.ticketPersonId, this.tariff.voucherCode, this.tariff.packageNumber, this.tariff.packageIndex)), takeUntil(this.destroy$))
            .subscribe(function (count) {
            _this.updateCountOnTariffBookingCountChange(count);
            _this.clearVoucherInputAndWarning();
        });
    };
    TariffComponent.prototype.initBookedTariffReservationsValidityCheck = function () {
        this.areBookedTariffReservationsValid$ = this.store.pipe(select(areBookedTariffReservationsValid(this.exhibitionSettings.isWorkshopsSelectionMandatory, this.exhibitionSettings.workshopMandatoryForZeroPriceTickets, this.tariff.ticketTypeId, this.tariff.ticketPersonId, this.tariff.voucherCode, this.tariff.packageNumber, this.tariff.packageIndex)));
    };
    TariffComponent.prototype.initBookedTariffParkingReservationsTotalPrice = function () {
        this.parkingReservationTotalPrice$ = this.store.pipe(select(getBookedTariffParkingReservationTotalPrice(this.tariff.ticketTypeId, this.tariff.ticketPersonId, this.tariff.voucherCode, this.tariff.packageNumber, this.tariff.packageIndex)));
    };
    TariffComponent.prototype.initTariffValidationServiceState = function () {
        var _a = this.tariff, ticketPersonId = _a.ticketPersonId, availableTickets = _a.availableTickets, ticketLimit = _a.ticketLimit, classification = _a.classification, allowedWorkshops = _a.allowedWorkshops, isVoucher = _a.isVoucher, voucherType = _a.voucherType, voucherCountLimit = _a.voucherCountLimit, packageSettings = _a.packageSettings;
        var _b = this.exhibitionSettings, limitBoughtTickets = _b.limitBoughtTickets, ticketLimitPerUserAccount = _b.ticketLimitPerUserAccount, limitPromoCodes = _b.limitPromoCodes, limitLimitedPromocodes = _b.limitLimitedPromocodes, isWorkshopsSelectionMandatory = _b.isWorkshopsSelectionMandatory, isVoucherIncludedPerUserAccountLimit = _b.isVoucherIncludedPerUserAccountLimit;
        var _c = this.tariffStatusViewModel, currentUserAccountTicketLimit = _c.currentUserAccountTicketLimit, availableTariffs = _c.availableTariffs;
        var _d = this.bookingStatusViewModel, bookedProductsCount = _d.bookedProductsCount, bookedPromoCodeVouchersCount = _d.bookedPromoCodeVouchersCount, bookedLimitedPromoCodeVouchersCount = _d.bookedLimitedPromoCodeVouchersCount, bookedOnetimeVouchersCount = _d.bookedOnetimeVouchersCount;
        var _e = this.tariffCountValidationService.tariffAllowedWorkshopsStatus(this.count, this.tariff, this.workshopStatusViewModel.availableSeats), allowedWorkshopsSeatsAvailable = _e.allowedWorkshopsSeatsAvailable, hideWorkshopTariffCounter = _e.hideWorkshopTariffCounter, areAllAllowedWorkshopsAssigned = _e.areAllAllowedWorkshopsAssigned;
        this.tariffValidationService.initAndValidateState({
            count: this.count,
            validatedCount: this.count,
            previousValidatedCount: this.count,
            bookedCount: this.count,
            validatedMaxLimit: this.count,
            isTariffSoldOut: this.tariffCountValidationService.isTariffSoldOut(this.count, availableTickets),
            isAvailableTariffsLimitReached: this.tariffCountValidationService.isTariffSoldOut(this.count, availableTariffs[ticketPersonId]),
            numberOfAllBookedTariffs: bookedProductsCount,
            initialNumberOfAvailableTariffs: availableTickets,
            currentNumberOfAvailableTariffs: availableTariffs[ticketPersonId],
            percentageOfAvailableTariffs: this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(this.count, ticketLimit, availableTariffs[ticketPersonId]),
            tariffLimit: ticketLimit,
            maxTariffLimit: limitBoughtTickets,
            tariffLimitPerUserAccount: ticketLimitPerUserAccount,
            currentUserAccountTariffLimit: currentUserAccountTicketLimit,
            isCurrentUserAccountTariffLimitReached: this.tariffCountValidationService.isCurrentUserAccountTariffLimitReached(ticketLimitPerUserAccount, currentUserAccountTicketLimit),
            voucherCountLimit: voucherCountLimit,
            maxVoucherLimit: limitPromoCodes,
            maxLimitedVoucherLimit: limitLimitedPromocodes,
            numberOfBookedVouchers: bookedPromoCodeVouchersCount,
            numberOfBookedLimitedVouchers: bookedLimitedPromoCodeVouchersCount,
            numberOfBookedOneTimeVouchers: bookedOnetimeVouchersCount,
            isTariffClassificationNormal: classification === TariffClassification.Normal,
            isTariffClassificationParking: classification === TariffClassification.Parking,
            hideWorkshopTariffCounter: hideWorkshopTariffCounter,
            allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
            areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
            isWorkshopsSelectionMandatoryAndHasWorkshops: isWorkshopsSelectionMandatory && allowedWorkshops.length > 0,
            isVoucher: isVoucher,
            isVoucherTypePromoCode: voucherType === VoucherType.PromoCode,
            isVoucherTypeLimitedPromoCode: voucherType === VoucherType.LimiterPromoCode,
            isVoucherTypeOneTimeVoucher: voucherType === VoucherType.OneTimeVoucher,
            isVoucherIncludedPerUserAccountLimit: isVoucherIncludedPerUserAccountLimit,
            isPackageTypeAndIsPackageNotAdded: this.isPackageTypeAndIsPackageNotAdded(),
            packageSettings: packageSettings,
            packageTooltipMessage: this.showPackageTooltipMessage()
        });
    };
    TariffComponent.prototype.initTariffValidationServiceSubscription = function () {
        var _this = this;
        this.tariffValidationService.tariffValidationState$
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (tariffValidationState) {
            _this.tariffValidationViewModel = tariffValidationState;
            _this.updateCount();
        });
    };
    TariffComponent.prototype.initPreferredTariffBookingValidationSubscription = function () {
        var _this = this;
        this.preferredTariffValidationService.preferredTariffValidationState$
            .pipe(filter(function (_a) {
            var ticketTypeId = _a.ticketTypeId, ticketPersonTypeId = _a.ticketPersonTypeId;
            return ticketTypeId === _this.tariff.ticketTypeId &&
                ticketPersonTypeId === _this.tariff.ticketPersonTypeId &&
                !_this.tariff.voucherCode &&
                !_this.tariff.packageNumber &&
                !_this.tariff.packageIndex;
        }), takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var validatedMaxLimit = _a.validatedMaxLimit, tariffLimitWarningMessage = _a.tariffLimitWarningMessage;
            _this.tariffValidationViewModel.validatedMaxLimit = validatedMaxLimit;
            _this.tariffValidationViewModel.tariffLimitWarningMessage = tariffLimitWarningMessage;
        });
    };
    TariffComponent.prototype.initWidgetBannerTariffBookingValidationSubscription = function () {
        var _this = this;
        this.router.queryParams
            .pipe(filter(function (queryParams) {
            if (queryParams.ticket) {
                var _a = queryParams.ticket.split('_'), ticketTypeId = _a[0], ticketPersonTypeId = _a[1];
                return (+ticketTypeId === _this.tariff.ticketTypeId &&
                    +ticketPersonTypeId === _this.tariff.ticketPersonTypeId &&
                    !_this.tariff.packageNumber &&
                    !_this.tariff.packageIndex);
            }
            return false;
        }), takeUntil(this.destroy$))
            .subscribe(function () {
            _this.validateCount(AppConstants.WIDGET_BANNER_PRODUCT_DEFAULT_AMOUNT);
        });
    };
    TariffComponent.prototype.initFailedBookingValidationStateRevalidationSubscription = function () {
        var _this = this;
        this.actions$
            .pipe(ofType(BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST_ERROR, BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST_ERROR), takeUntil(this.destroy$))
            .subscribe(function () {
            var _a = _this.tariffValidationViewModel, validatedCount = _a.validatedCount, bookedCount = _a.bookedCount;
            var revalidateValidationStateAfterFailedBooking = validatedCount !== bookedCount;
            if (revalidateValidationStateAfterFailedBooking) {
                _this.validateCount(bookedCount);
            }
        });
    };
    TariffComponent.prototype.updateTariffInfoState = function () {
        var _a = this.tariff, info = _a.info, infoExpanded = _a.infoExpanded;
        if (info && infoExpanded) {
            this.isInfoExpanded = this.translateService.instant(infoExpanded) === TariffInfoExpandedTranslationResponse.True;
            this.isInfoExpandable = this.translateService.instant(info) !== AppConstants.MISSING_TRANSLATION;
        }
    };
    TariffComponent.prototype.updateTariffValidationState = function () {
        if (this.tariffValidationViewModel && this.tariffStatusViewModel && this.bookingStatusViewModel) {
            var _a = this.tariff, ticketPersonId = _a.ticketPersonId, ticketLimit = _a.ticketLimit;
            var _b = this.tariffStatusViewModel, currentUserAccountTicketLimit = _b.currentUserAccountTicketLimit, availableTariffs = _b.availableTariffs;
            var _c = this.bookingStatusViewModel, bookedProductsCount = _c.bookedProductsCount, bookedPromoCodeVouchersCount = _c.bookedPromoCodeVouchersCount, bookedLimitedPromoCodeVouchersCount = _c.bookedLimitedPromoCodeVouchersCount, bookedOnetimeVouchersCount = _c.bookedOnetimeVouchersCount;
            var _d = this.tariffCountValidationService.tariffAllowedWorkshopsStatus(this.count, this.tariff, this.workshopStatusViewModel.availableSeats), allowedWorkshopsSeatsAvailable = _d.allowedWorkshopsSeatsAvailable, hideWorkshopTariffCounter = _d.hideWorkshopTariffCounter, areAllAllowedWorkshopsAssigned = _d.areAllAllowedWorkshopsAssigned;
            var updateTariffValidationViewModel = {
                count: this.count,
                validatedCount: this.count,
                currentUserAccountTariffLimit: currentUserAccountTicketLimit,
                numberOfAllBookedTariffs: bookedProductsCount,
                numberOfBookedVouchers: bookedPromoCodeVouchersCount,
                numberOfBookedLimitedVouchers: bookedLimitedPromoCodeVouchersCount,
                numberOfBookedOneTimeVouchers: bookedOnetimeVouchersCount,
                currentNumberOfAvailableTariffs: availableTariffs[ticketPersonId],
                hideWorkshopTariffCounter: hideWorkshopTariffCounter,
                allowedWorkshopsSeatsAvailable: allowedWorkshopsSeatsAvailable,
                areAllAllowedWorkshopsAssigned: areAllAllowedWorkshopsAssigned,
                isPackageTypeAndIsPackageNotAdded: this.isPackageTypeAndIsPackageNotAdded(),
                packageTooltipMessage: this.showPackageTooltipMessage(),
                percentageOfAvailableTariffs: this.tariffCountValidationService.calculatePercentageOfAvailableTariffs(this.count, ticketLimit, availableTariffs[ticketPersonId])
            };
            this.tariffValidationViewModel = __assign({}, this.tariffValidationViewModel, updateTariffValidationViewModel);
        }
    };
    TariffComponent.prototype.revalidateCounterOnBookingCountDecreaseStateChange = function (changes) {
        var bookingStatusViewModel = changes.bookingStatusViewModel;
        if (bookingStatusViewModel) {
            var currentChangedBookingStatusViewModel = bookingStatusViewModel.currentValue;
            var previousChangedBookingStatusViewModel = bookingStatusViewModel.previousValue;
            if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
                return;
            }
            var isBookedProductsCountDecreased = previousChangedBookingStatusViewModel.bookedProductsCount >
                currentChangedBookingStatusViewModel.bookedProductsCount;
            if (isBookedProductsCountDecreased) {
                this.tariffValidationService.revalidateDisabledCounter(this.tariffValidationViewModel);
                this.tariffValidationService.revalidateIsAvailableTariffsLimitReached(this.tariffValidationViewModel);
            }
        }
    };
    TariffComponent.prototype.revalidateIsTariffSoldOutOnBookingCountIncreaseStateChange = function (changes) {
        var bookingStatusViewModel = changes.bookingStatusViewModel, tariffStatusViewModel = changes.tariffStatusViewModel;
        if (bookingStatusViewModel && tariffStatusViewModel) {
            var currentTariffStatusViewModel = tariffStatusViewModel.currentValue;
            var isTariffNotSoldOut = currentTariffStatusViewModel.availableTariffs[this.tariff.ticketPersonId];
            if (isTariffNotSoldOut) {
                return;
            }
            var currentChangedBookingStatusViewModel = bookingStatusViewModel.currentValue;
            var previousChangedBookingStatusViewModel = bookingStatusViewModel.previousValue;
            if (!currentChangedBookingStatusViewModel || !previousChangedBookingStatusViewModel) {
                return;
            }
            var isBookedProductsCountIncreased = currentChangedBookingStatusViewModel.bookedProductsCount >
                previousChangedBookingStatusViewModel.bookedProductsCount;
            if (isBookedProductsCountIncreased) {
                this.tariffValidationService.revalidateIsAvailableTariffsLimitReached(this.tariffValidationViewModel);
            }
        }
    };
    TariffComponent.prototype.toggleTariffInfo = function ($event) {
        $event.stopPropagation();
        if (!this.isInfoExpandable) {
            return;
        }
        this.isInfoExpanded = !this.isInfoExpanded;
    };
    TariffComponent.prototype.isPackageTypeAndIsPackageNotAdded = function () {
        return this.packageType && this.packageType.numberOfAddedPackages === 0;
    };
    TariffComponent.prototype.showPackageTooltipMessage = function () {
        return this.isPackageTypeAndIsPackageNotAdded() ? PackageTooltip.PACKAGE_COUNTER_TOOLTIP_MESSAGE : '';
    };
    TariffComponent.prototype.showMobilePackageTooltipMessage = function () {
        var _this = this;
        this.tariffValidationViewModel.showMobilePackageTooltipMessage =
            this.productSelectionViewModel.isMobile && this.isPackageTypeAndIsPackageNotAdded();
        clearTimeout(this.showMobilePackageTooltipMessageTimeout);
        this.showMobilePackageTooltipMessageTimeout = setTimeout(function () {
            _this.tariffValidationViewModel.showMobilePackageTooltipMessage = false;
        }, PackageTooltip.PACKAGE_COUNTER_TOOLTIP_TIMEOUT);
    };
    TariffComponent.prototype.updateCountOnTariffBookingCountChange = function (count) {
        this.count = count;
        if (this.tariffValidationViewModel) {
            this.tariffValidationViewModel.count = count;
            this.tariffValidationViewModel.validatedCount = count;
            this.tariffValidationViewModel.bookedCount = count;
        }
    };
    TariffComponent.prototype.onClickSelfRegistrationTariff = function ($event) {
        $event.stopPropagation();
        if (this.tariffValidationViewModel.isTariffSoldOut) {
            return;
        }
        this.tariffService.setSelfRegistrationTariff(this.tariffType, this.tariff, this.count);
    };
    TariffComponent.prototype.validateCount = function (updateTariffCount) {
        this.tariffValidationService.validateState(updateTariffCount, this.tariffValidationViewModel);
    };
    TariffComponent.prototype.updateCount = function () {
        var _a = this.tariffValidationViewModel, validatedCount = _a.validatedCount, previousValidatedCount = _a.previousValidatedCount, bookedCount = _a.bookedCount;
        var isCountUnchanged = validatedCount === previousValidatedCount;
        var isStateRevalidationAfterFailedBooking = validatedCount === bookedCount;
        if (isCountUnchanged || isStateRevalidationAfterFailedBooking) {
            return;
        }
        if (this.packageType) {
            this.tariffService.updatePackageTariffCount(this.packageType, this.tariffType, this.tariff, previousValidatedCount, validatedCount);
        }
        else {
            this.tariffService.updateTariffCount(this.tariffType, this.tariff, previousValidatedCount, validatedCount);
        }
    };
    TariffComponent.prototype.releaseVoucher = function ($event) {
        $event.stopPropagation();
        this.voucherService.releaseVoucher(this.tariff.ticketPersonId, this.tariff.voucherCode);
        this.voucherCodeReleased$.next(this.tariff.voucherCode);
    };
    TariffComponent.prototype.clearVoucherInputAndWarning = function () {
        if (this.tariff.isVoucher) {
            this.voucherService.clearVoucherInputAndWarning();
        }
    };
    return TariffComponent;
}());
export { TariffComponent };
