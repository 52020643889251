import * as customizationActions from './customization.actions';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LocalizedImagesModel, OperatorsSettingsModel, SponsorBannerModel } from './customization.interfaces';

import { Action } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import {
    ActionTypes as ProductBookingActionType,
    SetPackageInBookingList,
    SetProductInBookingList,
    SetVoucherForAnonymousProductInBookingList,
    SetVoucherProductInBookingList
} from '../products/booking/booking.actions';
import { CustomizationService } from './customization.service';

export const CUSTOMIZATION_DEBOUNCE = new InjectionToken<number>('Customization Debounce');

@Injectable()
export class CustomizationEffect {
  @Effect()
  loadCustomSettings$: Observable<Action> = this.actions$.pipe(
    ofType<customizationActions.GetExhibitionSettings>(customizationActions.ActionTypes.GET_EXHIBITION_SETTINGS),
    switchMap((data: any) => {
      const eventId = data.payload;
      if (eventId === '') {
        return Observable.empty();
      }

      return this.customizationService.getCustomSettings(eventId).pipe(
        map(customSettings => {
          const sendingOptions = Object.keys(customSettings.ticketSelectionDeliveryTypes).map(key => {
            const isBasic = (key !== 'mobilePerBuyer' && key !== 'ticketRetrivalLinkBuyer') || false;
            const isBuyer =
              key === 'mobilePerBuyer' || key === 'ticketRetrivalLinkBuyer' || key === 'allToBuyer' || false;

            return {
              value: key,
              isEnabled: customSettings.ticketSelectionDeliveryTypes[key],
              isBasic,
              isBuyer
            };
          });

          // TODO add currency if missing in API
          if (!customSettings.hasOwnProperty('currency')) {
            customSettings.currency = environment.currency;
          }

          customSettings.ticketSelectionDeliveryTypes = sendingOptions;

          return new customizationActions.SetExhibitionSettings(customSettings);
        }),
        catchError(error => {
          console.log(error);
          return of(new customizationActions.SetExhibitionSettings(null));
        })
      );
    })
  );

  /* @Effect()
  loadCustomLinks$: Observable<Action> = this.actions$
    .ofType(customizationActions.GET_EXHIBITION_LINKS)
    .debounceTime(this.debounce)
    .switchMap(event => {
      if (event.exhibitionId === '' && event.exhibitionLanguage === '') {
        return empty();
      }

      return this.customizationService
        .getCustomLinks(event)
        .map(
          (customLinks: ExhibitionLinksModel) =>
            new customizationActions.SetExhibitionLinks(customLinks)
        )
        .catch(error => {
          console.log(error);
          return of(new customizationActions.SetExhibitionLinks(null));
        });
    }); */

  @Effect()
  loadRandomSponsor$: Observable<Action> = this.actions$.pipe(
    ofType<customizationActions.GetRandomSponsor>(customizationActions.ActionTypes.GET_RANDOM_SPONSOR),
    switchMap((data: any) => {
      const payload = data.payload;
      if (payload.eventId === '') {
        return Observable.empty();
      }
      const { eventId, countryCode } = payload;
      return this.customizationService.getRandomSponsor(eventId, countryCode).pipe(
        map((randomSponsor: SponsorBannerModel) => new customizationActions.SetRandomSponsor(randomSponsor)),
        catchError(error => {
          console.log(error);
          return of(new customizationActions.SetRandomSponsor(null));
        })
      );
    })
  );

  @Effect()
  loadLocalizedImages$: Observable<Action> = this.actions$.pipe(
    ofType<customizationActions.GetLocalizedImages>(customizationActions.ActionTypes.GET_LOCALIZED_IMAGES),
    mergeMap((data: any) => {
      const { eventId, countryCode } = data.payload;
      return this.customizationService.getLocalizedImages(eventId, countryCode).pipe(
        map((localizedImages: LocalizedImagesModel) => {
          return new customizationActions.SetLocalizedImages(localizedImages);
        }),
        catchError(error => {
          console.log(error);
          return of(new customizationActions.SetLocalizedImages(null));
        })
      );
    })
  );

  @Effect()
  loadOperatorSettings$: Observable<Action> = this.actions$.pipe(
    ofType<customizationActions.GetOperatorsSettings>(customizationActions.ActionTypes.GET_OPERATORS_SETTINGS),
    switchMap(() => {
      return this.customizationService.getOperatorSettings().pipe(
        map(
          (operatorSettings: OperatorsSettingsModel) => new customizationActions.SetOperatorsSettings(operatorSettings)
        ),
        catchError(error => {
          console.log(error);
          return of(new customizationActions.SetOperatorsSettings(null));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  setShoppingStartTime$: Observable<Action> = this.actions$.pipe(
    ofType<SetProductInBookingList | SetVoucherProductInBookingList | SetVoucherForAnonymousProductInBookingList | SetPackageInBookingList>(
      ProductBookingActionType.SET_PRODUCT_IN_BOOKING_LIST,
      ProductBookingActionType.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST,
      ProductBookingActionType.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST,
      ProductBookingActionType.SET_PACKAGE_IN_BOOKING_LIST
    ),
    tap(() => this.customizationService.setShoppingStartTime()),
    catchError(error => throwError(error))
  );

  constructor(
    private actions$: Actions,
    private customizationService: CustomizationService,
    @Optional()
    @Inject(CUSTOMIZATION_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
