var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from '@store/additional-services/additional-services.actions';
import _ from 'lodash';
export var initialState = {
    menu: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = _.cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_MENU:
            var menuList = action.payload;
            return __assign({}, state, { menu: menuList });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
