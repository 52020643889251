import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PackageValidationStateViewModel } from '@products/models/package.model';

@Component({
  selector: 'app-package-counter',
  templateUrl: './package-counter.component.html',
  styleUrls: ['./package-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageCounterComponent implements OnInit, OnChanges {
  @Input() packageValidationViewModel: PackageValidationStateViewModel;
  @Input() isPackageLoading: boolean;
  @Input() isWorkshopDetailModalOpen: boolean;
  @Output() updatePackageTypeCountEvent = new EventEmitter<number>();
  count: number;

  constructor() {}

  ngOnInit() {
    this.count =
      this.packageValidationViewModel.validatedCount >= 0 ? this.packageValidationViewModel.validatedCount : 0;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.count = this.packageValidationViewModel.validatedCount;
  }

  onIncrease() {
    const increasedCount: number = this.count + 1;

    this.count = increasedCount >= 0 ? increasedCount : this.count;

    this.updatePackageTypeCountEvent.emit(this.count);
  }

  onDecrease() {
    const decreasedCount: number = this.count - 1;

    this.count = decreasedCount >= 0 ? decreasedCount : 0;

    this.updatePackageTypeCountEvent.emit(this.count);
  }

  onCountChange(count: number) {
    const changedCount = +count;

    this.count = changedCount >= 0 ? changedCount : 0;

    this.updatePackageTypeCountEvent.emit(this.count);
  }
}
