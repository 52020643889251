import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { State } from '@app/app.reducer';
import { Store } from '@ngrx/store';
import { BookingParkingReservation } from '@products/models/booking.model';
import { SetParkingReservationDate } from '@products/models/parking.model';
import { getProductType } from '@products/models/products.model';
import { Tariff } from '@products/models/tariff.model';
import { SetParkingBookingReservation } from '@store/products/product.actions';

@Injectable()
export class ParkingTariffService {
  private tariff: Tariff;
  private parkingReservationForm: FormGroup;

  constructor(private store: Store<State>) {}

  initState(tariff: Tariff, parkingReservationForm: FormGroup) {
    this.tariff = tariff;
    this.parkingReservationForm = parkingReservationForm;
  }

  setParkingReservationFormControls(parkingReservations: BookingParkingReservation[]) {
    const numberOfParkingReservations = parkingReservations.length;
    const beforeAddingFormControlNames = Object.keys(this.parkingReservationForm.controls);
    const beforeAddingNumberOfAddedFormControls = beforeAddingFormControlNames.length;
    const addControl = numberOfParkingReservations > beforeAddingNumberOfAddedFormControls;
    const removeControl = numberOfParkingReservations < beforeAddingNumberOfAddedFormControls;

    if (addControl) {
      this.addParkingReservationDayFormControls(parkingReservations);
    } else if (removeControl) {
      const numberOfFormControlsToRemove = beforeAddingNumberOfAddedFormControls - numberOfParkingReservations;
      this.removeParkingReservationDayFormControls(numberOfFormControlsToRemove);
    } else {
      this.resetInvalidParkingReservationCalendarDates(parkingReservations);
    }
  }

  addParkingReservationDayFormControls(parkingReservations: BookingParkingReservation[]) {
    parkingReservations.forEach((parkingReservation, parkingReservationIndex) => {
      const formControlName = `${this.tariff.ticketPersonId}_${parkingReservationIndex}`;
      const since = parkingReservation.since;
      const until = parkingReservation.until;
      const parkingReservationFormGroup = new FormGroup({
        since: new FormControl(since, Validators.required),
        until: new FormControl(until, Validators.required)
      });

      this.parkingReservationForm.addControl(formControlName, parkingReservationFormGroup);
    });
  }

  removeParkingReservationDayFormControls(numberOfFormControlsToRemove: number) {
    const formControlNames = Object.keys(this.parkingReservationForm.controls);

    formControlNames
      .slice(-numberOfFormControlsToRemove)
      .forEach(formControlName => this.parkingReservationForm.removeControl(formControlName));
  }

  setParkingReservations(parkingReservationIndex: number) {
    const formControlName = `${this.tariff.ticketPersonId}_${parkingReservationIndex}`;
    const formControlValue = this.parkingReservationForm.get(formControlName).value;
    const isUntilDateSelected = formControlValue.until !== null;
    const parkingReservationSinceDate: Date = formControlValue.since;
    const parkingReservationUntilDate: Date = isUntilDateSelected
      ? this.setValidatedParkingReservationUntilDate(formControlName, formControlValue)
      : formControlValue.until;

    const { ticketTypeId, ticketPersonId, ticketPersonTypeId, voucherCode, packageNumber, packageIndex } = this.tariff;
    const setParkingReservationSelectedDate: SetParkingReservationDate = {
      productType: getProductType(this.tariff),
      ticketTypeId,
      ticketPersonId,
      ticketPersonTypeId,
      voucherCode,
      packageNumber,
      packageIndex,
      bookingReservationIndex: parkingReservationIndex,
      since: parkingReservationSinceDate,
      until: parkingReservationUntilDate,
      isValid: false
    };

    this.store.dispatch(new SetParkingBookingReservation(setParkingReservationSelectedDate));
  }

  setValidatedParkingReservationUntilDate(formControlName: string, formControlValue: { since: string; until: string }) {
    const { since, until } = formControlValue;
    const untilFormControl = this.parkingReservationForm.get(`${formControlName}.until`);

    if (until <= since) {
      const sinceDate = new Date(since);
      const validatedTimestamp = sinceDate.setHours(sinceDate.getHours() + 1);
      const validatedUntilDate = new Date(validatedTimestamp);
      untilFormControl.setValue(validatedUntilDate);
    }

    return untilFormControl.value;
  }

  resetInvalidParkingReservationCalendarDates(parkingReservations: BookingParkingReservation[]) {
    parkingReservations.forEach((parkingReservation, parkingReservationIndex) => {
      const { isValid, since, until } = parkingReservation;
      const isValidatedAndResetBookingParkingReservation = !isValid && !since && !until;

      if (isValidatedAndResetBookingParkingReservation) {
        const formControlName = `${this.tariff.ticketPersonId}_${parkingReservationIndex}`;
        const sinceFormControl = this.parkingReservationForm.get(`${formControlName}.since`);
        const untilFormControl = this.parkingReservationForm.get(`${formControlName}.until`);
        sinceFormControl.setValue(null);
        untilFormControl.setValue(null);
      }
    });
  }
}
