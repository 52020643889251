import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExhibitionSettingModel } from '@store/customization/customization.interfaces';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';

import {
  Product,
  ProductGroup,
  ProductSelectionProductsStatusViewModel,
  ProductSelectionViewModel
} from '@products/models/product-selection.model';
import { ProductType } from '@products/models/products.model';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductComponent implements OnInit {
  @Input() selectedExhibition: ExhibitionModel;
  @Input() exhibitionSettings: ExhibitionSettingModel;
  @Input() productSelectionViewModel: ProductSelectionViewModel;
  @Input() productsStatusViewModel: ProductSelectionProductsStatusViewModel;
  @Input() productGroup: ProductGroup;
  @Input() product: Product;

  readonly ProductType = ProductType;

  constructor() {}

  ngOnInit() {}
}
