import { Inject, Injectable, Optional } from '@angular/core';
import { State, getSelfRegistration, getTariffReleaseInMinutes } from '@app/app.reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import {
  BookingPackageType,
  BookingProductType,
  PostProductBookingResponse,
  SetProductBookingContingentReservation,
  SetProductBookingParkingReservation,
  VoucherCountdown
} from '@products/models/booking.model';
import {
  CreateContingentTariffToBookingContingentReservationsMapper,
  PostCreateContingentReservations,
  RemoveContingentReservation,
  SetContingentReservationDate
} from '@products/models/contingent.model';
import { SetParkingReservationDate } from '@products/models/parking.model';
import { BookingService } from '@products/services/booking.service';
import { ProductService } from '@products/services/product.service';
import { VoucherValidationService } from '@products/services/voucher-validation.service';
import { VoucherService } from '@products/services/voucher.service';
import { BOOKING_DEBOUNCE_TIME } from '@shared/tokens';
import { SetShoppingStartTime } from '@store/customization/customization.actions';
import {
  ActionTypes as BookingActionTypes,
  RemovePackageFromBookingList,
  RemoveProductFromBookingList,
  RemoveProductFromBookingListError,
  RemoveReleasedVoucherProductFromBookingList,
  RemoveVoucherCountdown,
  RemoveVoucherProductFromBookingList,
  RemoveWorkshopFromBooking,
  SetContingentReservationDateInBooking,
  SetPackageInBookingList,
  SetParkingReservationDateInBooking,
  SetPreferredProductInBookingList,
  SetProductCountdown,
  SetProductInBookingList,
  SetProductInBookingListError,
  SetValidatedContingentReservationInBooking,
  SetValidatedParkingReservationInBooking,
  SetVoucherCountdown,
  SetVoucherForAnonymousProductInBookingList,
  SetVoucherProductInBookingList,
  SetWorkshopInBooking
} from '@store/products/booking/booking.actions';
import { getAllBookedTariffs } from '@store/products/booking/booking.selectors';
import {
  ActionTypes as ProductSelectionActionTypes,
  RemoveVoucherCodeProductFromSelectionList,
  RemoveVoucherProductFromSelectionList,
  SetVoucherProductInSelectionList
} from '@store/products/product-selection/product-selection.actions';
import { getInitialVoucherTariffByTicketPersonId } from '@store/products/product-selection/product-selection.selectors';
import {
  ActionTypes as ProductActionTypes,
  RemoveBookingWorkshop,
  RemoveContingentBookingReservation,
  RemovePackageBooking,
  RemovePackageTariffBooking,
  RemoveProductBooking,
  RemoveReleasedAndRemovedProductBookingVoucher,
  RemoveReleasedVoucher,
  RemoveTariffBooking,
  RemoveVoucherTariffBooking,
  SetBookingWorkshop,
  SetContingentBookingReservation,
  SetPackageBooking,
  SetPackageTariffBooking,
  SetParkingBookingReservation,
  SetPreferredTariffBooking,
  SetRedeemedAndReleaseVoucher,
  SetSelfRegistrationTariffBookings,
  SetTariffBooking,
  SetVoucherTariffBooking
} from '@store/products/product.actions';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

@Injectable()
export class BookingEffect {
  @Effect()
  setProductBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetTariffBooking | SetVoucherTariffBooking | SetPackageTariffBooking>(
      ProductActionTypes.SET_TARIFF_BOOKING,
      ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING,
      ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING
    ),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload.length) {
        return EMPTY;
      }

      return this.bookingService.setProductBookings$(payload).pipe(
        switchMap(postProductBookings => {
          return this.bookingService.postProductBookings$(postProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                payload,
                postProductBookingsResponse
              );

              return new SetProductInBookingList(products);
            })
          );
        }),
        catchError(() => of(new SetProductInBookingList([])))
      );
    })
  );

  @Effect()
  setPreferredProductBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetPreferredTariffBooking>(ProductActionTypes.SET_PREFERRED_TARIFF_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      const { ticketTypeId, ticketPersonTypeId, count } = payload;
      if (!ticketTypeId || !ticketPersonTypeId || !count) {
        return EMPTY;
      }

      return this.bookingService.setPreferredProductBookings$(ticketTypeId, ticketPersonTypeId, count).pipe(
        switchMap(({ preferredBookingAndRemovedBookingProductTypes, postProductBookings }) => {
          return this.bookingService.postProductBookings$(postProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                preferredBookingAndRemovedBookingProductTypes,
                postProductBookingsResponse
              );

              return new SetPreferredProductInBookingList(products);
            })
          );
        }),
        catchError(() => of(new SetPreferredProductInBookingList([])))
      );
    })
  );

  @Effect()
  setSelfRegistrationTariffBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelfRegistrationTariffBookings>(ProductActionTypes.SET_SELF_REGISTRATION_TARIFF_BOOKINGS),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      return combineLatest([
        this.bookingService.setProductBookings$(payload),
        this.bookingService.removeAllProductBookings$()
      ]).pipe(
        switchMap(([addPostProductBookings, { removeBookingProductTypes, removePostProductBookings }]) => {
          const addBookingProductTypes: BookingProductType[] = payload;
          if (!addBookingProductTypes.length) {
            return EMPTY;
          }

          const postProductBookings = [...addPostProductBookings, ...removePostProductBookings];

          return this.bookingService.postProductBookings$(postProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const bookingProductTypes = [...addBookingProductTypes, ...removeBookingProductTypes];
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                bookingProductTypes,
                postProductBookingsResponse
              );

              return new SetProductInBookingList(products);
            })
          );
        }),
        catchError(() => of(new SetProductInBookingList([])))
      );
    })
  );

  @Effect()
  removeTariffBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveTariffBooking>(ProductActionTypes.REMOVE_TARIFF_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      return this.bookingService.removeTariffBookings$(payload).pipe(
        switchMap(({ bookingTariffTypes, removePostProductBookings }) => {
          return this.bookingService.postProductBookings$(removePostProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                bookingTariffTypes,
                postProductBookingsResponse
              );

              return new RemoveProductFromBookingList(products);
            })
          );
        }),
        catchError(() => of(new RemoveProductFromBookingList([])))
      );
    })
  );

  @Effect()
  setVoucherTariffBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetRedeemedAndReleaseVoucher>(ProductActionTypes.SET_REDEEMED_AND_RELEASE_VOUCHER),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      const { redeemedVoucher, releaseVouchers, removeProductBookings } = payload;
      if (!redeemedVoucher) {
        return EMPTY;
      }

      const { voucherType, ticketPersonId, ticketCount, isAnonymous } = redeemedVoucher;

      return this.voucherService.releaseMultipleVouchers$(releaseVouchers).pipe(
        switchMap(() => {
          return this.voucherValidationService
            .validateRedeemedVoucherTypeCount$(voucherType, ticketCount, isAnonymous)
            .pipe(
              withLatestFrom(this.store.pipe(select(getInitialVoucherTariffByTicketPersonId(ticketPersonId)))),
              map(([validatedVoucherCount, voucherTariff]) => {
                const redeemedVoucherProductBooking: BookingProductType = this.bookingService.mapRedeemedVoucherToBookingTariffType(
                  redeemedVoucher,
                  voucherTariff,
                  validatedVoucherCount
                );
                const updatedProductBookings: BookingProductType[] = [
                  redeemedVoucherProductBooking,
                  ...removeProductBookings
                ];

                if (isAnonymous) {
                  return new SetVoucherForAnonymousProductInBookingList(updatedProductBookings);
                }

                return new SetVoucherProductInBookingList(updatedProductBookings);
              })
            );
        }),
        catchError(() => {
          if (isAnonymous) {
            return of(new SetVoucherForAnonymousProductInBookingList([]));
          }

          return of(new SetVoucherProductInBookingList([]));
        })
      );
    })
  );

  @Effect()
  removeReleasedVoucherTariffBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveReleasedVoucher>(ProductActionTypes.REMOVE_RELEASED_VOUCHER),
    concatMap(({ payload }) => {
      const { ticketPersonId, voucherCode } = payload;
      if (!ticketPersonId || !voucherCode) {
        return EMPTY;
      }

      return this.bookingService.removeReleasedVoucherTariffBookings$(payload).pipe(
        map(({ bookedVoucherTariffTypes, voucherCode }) => {
          if (!!bookedVoucherTariffTypes.length) {
            return new RemoveReleasedVoucherProductFromBookingList(bookedVoucherTariffTypes);
          }

          return new RemoveReleasedAndRemovedProductBookingVoucher(voucherCode);
        })
      );
    })
  );

  @Effect()
  removeVoucherTariffBooking$: Observable<any> = this.actions$.pipe(
    ofType<RemoveVoucherTariffBooking>(ProductActionTypes.REMOVE_VOUCHER_TARIFF_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      return this.bookingService.removeVoucherTariffBookings$(payload).pipe(
        switchMap(({ bookingTariffTypes, removePostProductBookings }) => {
          return this.bookingService.postProductBookings$(removePostProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                bookingTariffTypes,
                postProductBookingsResponse
              );

              return new RemoveVoucherProductFromBookingList(products);
            })
          );
        }),
        catchError(() => of(new RemoveVoucherProductFromBookingList([])))
      );
    })
  );

  @Effect()
  removePackageTariffBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemovePackageTariffBooking>(ProductActionTypes.REMOVE_PACKAGE_TARIFF_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      return this.bookingService.removePackageTariffBookings$(payload).pipe(
        switchMap(({ bookedPackageTypes, removePostProductBookings }) => {
          return this.bookingService.postProductBookings$(removePostProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingProductType[] = this.bookingService.mapPostProductBookingsResponseToBookingProductTypes(
                bookedPackageTypes,
                postProductBookingsResponse
              );

              return new RemoveProductFromBookingList(products);
            })
          );
        }),
        catchError(() => of(new RemoveProductFromBookingList([])))
      );
    })
  );

  @Effect()
  setPackageBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetPackageBooking>(ProductActionTypes.SET_PACKAGE_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload.length) {
        return EMPTY;
      }

      return this.bookingService.setProductBookings$(payload, true).pipe(
        switchMap(postProductBookings => {
          return this.bookingService.postProductBookings$(postProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products = this.bookingService.mapPostProductBookingsResponseToBookingPackageTypes(
                payload,
                postProductBookingsResponse
              );

              return new SetPackageInBookingList(products);
            })
          );
        }),
        catchError(() => of(new SetPackageInBookingList([])))
      );
    })
  );

  @Effect()
  removePackageBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemovePackageBooking>(ProductActionTypes.REMOVE_PACKAGE_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload.packageIndexes || !payload.packageIndexes.length) {
        return EMPTY;
      }

      return this.bookingService.removePackageBookings$(payload).pipe(
        switchMap(({ bookedPackageTypes, removePostProductBookings }) => {
          return this.bookingService.postProductBookings$(removePostProductBookings).pipe(
            map((postProductBookingsResponse: PostProductBookingResponse[]) => {
              const products: BookingPackageType[] = this.bookingService.mapPostProductBookingsResponseToBookingPackageTypes(
                bookedPackageTypes,
                postProductBookingsResponse
              );

              return new RemovePackageFromBookingList(products);
            })
          );
        }),
        catchError(() => of(new RemovePackageFromBookingList([])))
      );
    })
  );

  @Effect()
  removeProductBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveProductBooking>(ProductActionTypes.REMOVE_PRODUCT_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      const productBookingsToRemove: BookingProductType[] = payload;
      if (!productBookingsToRemove.length) {
        return EMPTY;
      }

      return this.bookingService.removeProductBookings$(payload).pipe(
        switchMap(removePostProductBookings => {
          return this.bookingService
            .postProductBookings$(removePostProductBookings)
            .pipe(map(() => new RemoveProductFromBookingList(productBookingsToRemove)));
        }),
        catchError(() => of(new RemoveProductFromBookingList([])))
      );
    })
  );

  @Effect()
  setProductBookingError$: Observable<Action> = this.actions$.pipe(
    ofType<
      | SetProductInBookingList
      | SetPreferredProductInBookingList
      | SetVoucherProductInBookingList
      | SetVoucherForAnonymousProductInBookingList
      | SetPackageInBookingList
    >(
      BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PREFERRED_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST
    ),
    filter(({ payload }) => !payload.length),
    switchMap(() => of(new SetProductInBookingListError()))
  );

  @Effect()
  removeProductBookingError$: Observable<Action> = this.actions$.pipe(
    ofType<
      | RemoveProductFromBookingList
      | RemoveVoucherProductFromBookingList
      | RemoveReleasedVoucherProductFromBookingList
      | RemovePackageFromBookingList
    >(
      BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST
    ),
    filter(({ payload }) => !payload.length),
    switchMap(() => of(new RemoveProductFromBookingListError()))
  );

  @Effect()
  setBookingWorkshop$: Observable<Action> = this.actions$.pipe(
    ofType<SetBookingWorkshop>(ProductActionTypes.SET_BOOKING_WORKSHOP),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload.length) {
        return EMPTY;
      }

      return this.bookingService.setWorkshopBookings$(payload).pipe(
        switchMap(({ setBookingWorkshops, postWorkshopBookings }) =>
          this.bookingService.postAvailableWorkshopBookings$(postWorkshopBookings).pipe(
            this.bookingService.removeUnassignedHoldersInSetBookingWorkshopsPipe(
              setBookingWorkshops,
              postWorkshopBookings
            ),
            map(setWorkshopBookingsAndSetSeats => new SetWorkshopInBooking(setWorkshopBookingsAndSetSeats))
          )
        ),
        catchError(() => of(new SetWorkshopInBooking(null)))
      );
    })
  );

  @Effect()
  removeBookingWorkshop$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveBookingWorkshop>(ProductActionTypes.REMOVE_BOOKING_WORKSHOP),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      if (!payload.length) {
        return EMPTY;
      }

      return this.bookingService.removeWorkshopBookings$(payload).pipe(
        switchMap(({ removeBookingWorkshops, postWorkshopBookings }) =>
          this.bookingService
            .postRemoveMultipleWorkshopBookings$(removeBookingWorkshops, postWorkshopBookings)
            .pipe(
              map(removeWorkshopBookingsAndSetSeats => new RemoveWorkshopFromBooking(removeWorkshopBookingsAndSetSeats))
            )
        ),
        catchError(error => of(new RemoveWorkshopFromBooking(null)))
      );
    })
  );

  @Effect()
  setContingentReservationDateInBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetContingentBookingReservation>(ProductActionTypes.SET_CONTINGENT_BOOKING_RESERVATION),
    mergeMap(({ payload }) => {
      const contingentReservationDates: SetContingentReservationDate[] = payload;

      if (!contingentReservationDates.length) {
        return EMPTY;
      }

      return of(new SetContingentReservationDateInBooking(contingentReservationDates));
    })
  );

  @Effect()
  setParkingReservationDateInBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetParkingBookingReservation>(ProductActionTypes.SET_PARKING_BOOKING_RESERVATION),
    switchMap(({ payload }) => {
      const parkingReservationDate: SetParkingReservationDate = payload;

      if (!parkingReservationDate) {
        return EMPTY;
      }

      return of(new SetParkingReservationDateInBooking(parkingReservationDate));
    })
  );

  @Effect()
  setValidatedParkingReservationDateInBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetParkingReservationDateInBooking>(BookingActionTypes.SET_PARKING_RESERVATION_DATE_IN_BOOKING),
    switchMap(({ payload }) => {
      const parkingReservationDate: SetParkingReservationDate = payload;
      const { since, until } = parkingReservationDate;

      if (!since || !until) {
        return EMPTY;
      }

      return this.bookingService.setParkingReservation$(parkingReservationDate).pipe(
        switchMap(parkingTariffReservationFee => {
          return this.bookingService.getParkingTariffFee$(parkingTariffReservationFee).pipe(
            map(response => {
              const parkingReservationPrice: number = response.body as number;
              const setProductBookingParkingReservation: SetProductBookingParkingReservation = this.bookingService.mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation(
                parkingReservationDate,
                parkingReservationPrice,
                true
              );

              return new SetValidatedParkingReservationInBooking(setProductBookingParkingReservation);
            }),
            catchError(() => {
              const setProductBookingParkingReservation: SetProductBookingParkingReservation = this.bookingService.mapParkingReservationTariffPriceResponseToSetProductBookingParkingReservation(
                parkingReservationDate,
                0,
                false
              );

              return of(new SetValidatedParkingReservationInBooking(setProductBookingParkingReservation));
            })
          );
        })
      );
    })
  );

  @Effect()
  setValidatedContingentReservationInBooking$: Observable<Action> = this.actions$.pipe(
    ofType<SetContingentReservationDateInBooking>(BookingActionTypes.SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING),
    debounceTime(this.bookingDebounceTime),
    switchMap(() => {
      return this.productService.getSortedBookingContingentReservationsByProductSelectionListOrder$([]).pipe(
        switchMap(sortedContingentReservations => {
          const createContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[] = this.bookingService.mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers(
            sortedContingentReservations
          );

          return this.bookingService.setContingentReservations$(createContingentReservationsMappers).pipe(
            switchMap(setContingentReservations => {
              const { orderUuid, selectedExhibition, reservations } = setContingentReservations;

              const postCreateContingentReservations: PostCreateContingentReservations = {
                eventId: selectedExhibition.id,
                uuid: orderUuid,
                tickets: reservations
              };

              return this.bookingService.postCreateContingentReservations$(postCreateContingentReservations).pipe(
                map(contingentReservationResponse => {
                  const setProductBookingContingentReservations: SetProductBookingContingentReservation[] = this.bookingService.mapCreateContingentReservationResponseToSetProductBookingContingentReservations(
                    selectedExhibition,
                    contingentReservationResponse,
                    createContingentReservationsMappers
                  );

                  return new SetValidatedContingentReservationInBooking(setProductBookingContingentReservations);
                })
              );
            }),
            catchError(() => of(new SetValidatedContingentReservationInBooking([])))
          );
        })
      );
    })
  );

  @Effect()
  removeAndRevalidateContingentReservationsBooking$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveContingentBookingReservation>(ProductActionTypes.REMOVE_CONTINGENT_BOOKING_RESERVATION),
    debounceTime(this.bookingDebounceTime),
    switchMap(({ payload }) => {
      const removeContingentReservations: RemoveContingentReservation[] = payload;

      if (!removeContingentReservations.length) {
        return EMPTY;
      }

      return this.productService
        .getSortedBookingContingentReservationsByProductSelectionListOrder$(removeContingentReservations)
        .pipe(
          switchMap(sortedContingentReservations => {
            const createContingentReservationsMappers: CreateContingentTariffToBookingContingentReservationsMapper[] = this.bookingService.mapSortedProductBookingContingentReservationsToCreateContingentReservationMappers(
              sortedContingentReservations
            );

            return this.bookingService.setContingentReservations$(createContingentReservationsMappers).pipe(
              switchMap(setContingentReservations => {
                const { selectedExhibition, orderUuid, reservations } = setContingentReservations;

                if (!reservations.length) {
                  return this.bookingService
                    .postReleaseContingentReservations$(setContingentReservations.orderUuid)
                    .pipe(map(() => new SetValidatedContingentReservationInBooking([])));
                }

                const postCreateContingentReservations: PostCreateContingentReservations = {
                  eventId: selectedExhibition.id,
                  uuid: orderUuid,
                  tickets: reservations
                };

                return this.bookingService.postCreateContingentReservations$(postCreateContingentReservations).pipe(
                  map(contingentReservationResponse => {
                    const setProductBookingContingentReservations: SetProductBookingContingentReservation[] = this.bookingService.mapCreateContingentReservationResponseToSetProductBookingContingentReservations(
                      selectedExhibition,
                      contingentReservationResponse,
                      createContingentReservationsMappers
                    );

                    return new SetValidatedContingentReservationInBooking(setProductBookingContingentReservations);
                  })
                );
              }),
              catchError(() => of(new SetValidatedContingentReservationInBooking([])))
            );
          })
        );
    })
  );

  @Effect()
  revalidateContingentReservationsBookingOnPackageRemoval$: Observable<Action> = this.actions$.pipe(
    ofType<RemovePackageFromBookingList>(BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST),
    map(({ payload }) => {
      const removeContingentReservations: RemoveContingentReservation[] = this.bookingService.mapRemovedBookingPackageTypesToRemoveContingentReservations(
        payload
      );

      return new RemoveContingentBookingReservation(removeContingentReservations);
    })
  );

  @Effect()
  setShoppingStartTime$: Observable<Action> = this.actions$.pipe(
    ofType<SetTariffBooking | SetVoucherTariffBooking | SetPackageTariffBooking | SetPackageBooking>(
      ProductActionTypes.SET_TARIFF_BOOKING,
      ProductActionTypes.SET_VOUCHER_TARIFF_BOOKING,
      ProductActionTypes.SET_PACKAGE_TARIFF_BOOKING,
      ProductActionTypes.SET_PACKAGE_BOOKING
    ),
    map(() => new SetShoppingStartTime())
  );

  @Effect()
  setProductCountdown$: Observable<Action> = this.actions$.pipe(
    ofType<SetPackageInBookingList | SetProductInBookingList>(
      BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST,
      BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST
    ),
    withLatestFrom(this.store.pipe(select(getTariffReleaseInMinutes)), this.store.pipe(select(getSelfRegistration))),
    filter(([_, tariffReleaseInMinutes, isSelfRegistration]) => !!tariffReleaseInMinutes && !isSelfRegistration),
    switchMap(([{ payload, type }, tariffReleaseInMinutes]) => {
      if (
        !payload.length ||
        (type === BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST &&
          !this.bookingService.isPackageProductAddedInProductBookingList(payload))
      ) {
        return EMPTY;
      }

      return of(new SetProductCountdown(tariffReleaseInMinutes));
    })
  );

  @Effect()
  removeProductCountdown$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveProductFromBookingList | RemovePackageFromBookingList | RemoveVoucherProductFromBookingList>(
      BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST,
      BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST
    ),
    withLatestFrom(
      this.store.pipe(select(getAllBookedTariffs)),
      this.store.pipe(select(getTariffReleaseInMinutes)),
      this.store.pipe(select(getSelfRegistration))
    ),
    filter(([_, __, tariffReleaseInMinutes, isSelfRegistration]) => !!tariffReleaseInMinutes && !isSelfRegistration),
    map(
      ([_, allBookedTariffs, tariffReleaseInMinutes]) =>
        new SetProductCountdown(allBookedTariffs.length ? tariffReleaseInMinutes : null)
    )
  );

  @Effect()
  setVoucherCountdown$: Observable<Action> = this.actions$.pipe(
    ofType<SetVoucherProductInSelectionList>(ProductSelectionActionTypes.SET_VOUCHER_PRODUCT_IN_SELECTION_LIST),
    withLatestFrom(
      this.store.pipe(
        select(getTariffReleaseInMinutes),
        first(tariffReleaseInMinutes => !!tariffReleaseInMinutes)
      )
    ),
    map(([{ payload }, tariffReleaseInMinutes]) => {
      const { bookingProductTypes } = payload;
      const voucherCountdowns: VoucherCountdown[] = this.bookingService.mapBookingProductTypesToVoucherCountdowns(
        bookingProductTypes,
        tariffReleaseInMinutes
      );

      return new SetVoucherCountdown(voucherCountdowns);
    })
  );

  @Effect()
  removeVoucherCountdown$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveVoucherProductFromSelectionList | RemoveVoucherCodeProductFromSelectionList>(
      ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST,
      ProductSelectionActionTypes.REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST
    ),
    withLatestFrom(
      this.store.pipe(
        select(getTariffReleaseInMinutes),
        first(tariffReleaseInMinutes => !!tariffReleaseInMinutes)
      )
    ),
    map(([{ type, payload }, tariffReleaseInMinutes]) => {
      let voucherCountdowns: VoucherCountdown[] = [];

      if (type === ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST) {
        const bookingProductTypes = payload as BookingProductType[];
        voucherCountdowns = this.bookingService.mapBookingProductTypesToVoucherCountdowns(
          bookingProductTypes,
          tariffReleaseInMinutes
        );
      } else {
        const voucherCode = payload as string;
        voucherCountdowns = [{ voucherCode, expiryTimestamp: 0 }];
      }

      return new RemoveVoucherCountdown({ voucherCountdowns, tariffReleaseInMinutes });
    })
  );

  constructor(
    @Optional() @Inject(BOOKING_DEBOUNCE_TIME) private bookingDebounceTime: number,
    private actions$: Actions,
    private bookingService: BookingService,
    private voucherService: VoucherService,
    private voucherValidationService: VoucherValidationService,
    private productService: ProductService,
    private store: Store<State>
  ) {}
}
