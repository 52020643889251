import { first, startWith } from 'rxjs/operators';
import { State, getRegistrationQuestionnaire, getSelectedExhibitionId } from '@app/app.reducer';
import { GetRegistrationQuestionnaire } from '@store/user/user.actions';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { FormsService } from '@shared/forms/forms.service';
import { InputBase } from '@shared/forms/inputs/input-base.class';

@Component({
  moduleId: module.id,
  selector: 'app-registration-questionnaire',
  templateUrl: './registration-questionnaire.component.html',
  styleUrls: ['./registration-questionnaire.component.scss']
})
export class RegistrationQuestionnaireComponent implements OnInit {
  @Output() isValid = new EventEmitter<boolean>();
  @Output() questionnaireChanged = new EventEmitter<any>();

  form: FormGroup = null;
  inputs: InputBase<any>[] = [];
  customAction = ['registrationQuestionaireForm'];

  constructor(
    private store: Store<State>,
    private formsService: FormsService
  ) {
    this.store
    .pipe(
        select(getSelectedExhibitionId),
        first()
      )
      .subscribe(eventId => {
        // if eventId is null then it loads operator, otherwise it loads events questionnaire
        if (eventId === null) {
          eventId = -1;
        }

        this.store.dispatch(new GetRegistrationQuestionnaire(eventId));
      });
  }

  inputChangeCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    // once the form is changed get the updated input sets and build new form from it
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);

    this.questionnaireChanged.emit(this.inputs);
  };

  ngOnInit() {
    this.store.pipe(
      select(getRegistrationQuestionnaire)
    ).subscribe(questionaireInputs => {
      if (questionaireInputs && questionaireInputs.length) {
        this.form = this.formsService.toFormGroup(questionaireInputs);
        this.inputs = questionaireInputs;

        this.form.statusChanges
          .pipe(startWith(this.form.valid))
          .subscribe(() => {
            this.isValid.emit(this.form.valid);
          });
      } else {
        this.form = null;
        this.inputs = [];
        this.isValid.emit(true);
      }

      // emit the questionnaire values after load, so in case the user dont edit questionnaire and submit registration, the registration still know about it
      this.questionnaireChanged.emit(this.inputs);
    });
  }
}
