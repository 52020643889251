var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { ActionTypes as TariffActionTypes } from '@store/products/status/tariff/tariff.actions';
import _ from 'lodash';
export var initialState = {
    userAccountTicketLimit: null,
    availableTariffs: {},
    isTariffLoading: false,
    isCreateContingentReservationLoading: false
};
export var tariffStatusReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TariffActionTypes.SET_USER_ACCOUNT_TARIFFS_LIMIT_STATUS:
            var userAccountTicketLimit = action.payload;
            return __assign({}, state, { userAccountTicketLimit: userAccountTicketLimit });
        case TariffActionTypes.SET_AVAILABLE_TARIFFS_STATUS: {
            var availableTariffs = _.cloneDeep(action.payload);
            return __assign({}, state, { availableTariffs: __assign({}, state.availableTariffs, availableTariffs) });
        }
        case TariffActionTypes.SET_RELEASED_VOUCHER_AVAILABLE_TARIFFS_STATUS: {
            var addAvailableTariffs_1 = _.cloneDeep(action.payload);
            var clonedAvailableTariffs_1 = _.cloneDeep(state.availableTariffs);
            _.keys(addAvailableTariffs_1).forEach(function (ticketPersonId) { return (clonedAvailableTariffs_1[ticketPersonId] += addAvailableTariffs_1[ticketPersonId]); });
            return __assign({}, state, { availableTariffs: __assign({}, state.availableTariffs, clonedAvailableTariffs_1) });
        }
        case TariffActionTypes.SET_IS_TARIFF_LOADING_STATUS: {
            var isTariffLoading = action.payload;
            return __assign({}, state, { isTariffLoading: isTariffLoading });
        }
        case TariffActionTypes.SET_IS_CREATE_CONTINGENT_RESERVATION_LOADING_STATUS:
            var isCreateContingentReservationLoading = action.payload;
            return __assign({}, state, { isCreateContingentReservationLoading: isCreateContingentReservationLoading });
        case TariffActionTypes.RESET_REDUCER:
        case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
