import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '@shared/services-with-reducers/translation/translation.reducer';

export const getTranslationState = createFeatureSelector<State>('translation');

/**
 * Get translation language
 * @description Get active translation language
 * @return String
 */
export const getTranslationLanguage = createSelector(
  getTranslationState,
  translation => translation.language
);

/**
 * Get translation list
 * @description Get key value pair translations for current language
 * @return Translations object
 */
export const getTranslationList = createSelector(
  getTranslationState,
  translation => translation.list
);

/**
 * Get is translation
 */
export const getIsTranslationLoaded = createSelector(
  getTranslationState,
  translation => translation.isTranslationLoaded
);
