import { Component, OnDestroy, OnInit } from '@angular/core';

import { DownloadTicketById } from '@store/user/user.actions';
import { getSelectedExhibition, State } from '@app/app.reducer';

import {
  CancelOrderBody,
  TariffRecordModel,
  TariffRecordOrdersTariffModel
} from '@products/models/order.model';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { combineLatest, Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { HelperService } from '@shared/services-with-reducers/helpers/helper.service';
import { UserService } from '@shared/services-with-reducers/user/user.service';

@Component({
  selector: 'app-tickets-download',
  templateUrl: './tickets-download.component.html',
  styleUrls: ['./tickets-download.component.scss']
})
export class TicketsDownloadComponent implements OnInit, OnDestroy {
  refundOrderId: number;
  refundTicketId: number;
  refundPackageOrderId: number;
  refundPackageIndex: number;
  refundPackageEntryTickets: any;
  allowDownloadInvoice: boolean;
  modalWindowOpen: boolean = false;
  showLoader: boolean = false;
  isSingleTicketRefund: boolean = false;
  isParkingTicketRefund: boolean = false;
  isPackageRefund: boolean = false;
  isMobile: boolean = false;
  eventWithTickets: TariffRecordModel[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private userService: UserService,
    private helperService: HelperService
  ) {
    this.isMobile = this.helperService.isMobile();
  }

  ngOnInit() {
    this.getTicketHistoryByUser();
  }

  getTicketHistoryByUser() {
    this.subscriptions.add(
      combineLatest([
        this.userService.getTicketsByUser(),
        this.store.pipe(select(getSelectedExhibition))
      ])
      .subscribe(([tickets, selectedExhibition]: [Array<TariffRecordModel>, ExhibitionModel]) => {
        this.eventWithTickets = tickets.map(event => {
          event.eventCalendar = `${environment.protocol}${environment.webApiUrl}/event/${event.eventId}/calendar${event.eventId}.ics`;
          event.isAccordionOpen = !!selectedExhibition && event.eventId === selectedExhibition.id;
          return event;
        });
      })
    );
  }

  refundPackagePopup(orderId, packageIndex, packageEntryTickets) {
    this.refundPackageOrderId = orderId;
    this.refundPackageIndex = packageIndex;
    this.refundPackageEntryTickets = packageEntryTickets;
    this.isPackageRefund = true;
    this.modalWindowOpen = true;
  }

  refundPackage(isPackageRefund: boolean) {
    this.modalWindowOpen = false;

    if (isPackageRefund) {
      this.showLoader = true;

      const refundPackageBody: CancelOrderBody = {
        reason: 'Refund from user profile',
        ids: []
      };

      this.userService.refundPackage(this.refundPackageOrderId, this.refundPackageIndex, refundPackageBody).subscribe(() => {
        // After package refund, call back ticket history
        this.getTicketHistoryByUser();
        this.showLoader = false;
      },
      () => {
        // if there is an error in order package cancellation, loader should stop his pittyful existence
        this.showLoader = false;
      });
    }

    this.isPackageRefund = false;
    this.refundPackageOrderId = null;
  }

  refundOrderPopup(orderId) {
    this.refundOrderId = orderId;
    this.modalWindowOpen = true;
  }

  refundOrder(isCancelOrder: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isCancelOrder) {
      this.showLoader = true;

      this.userService.refundOrder(this.refundOrderId).subscribe(() => {
        // After order refund, call back ticket history
        this.getTicketHistoryByUser();
        this.showLoader = false;
      },
      () => {
        // if there is an error in order cancellation, loader should stop his pittyful existence
        this.showLoader = false;
      });
    }

    this.refundOrderId = null;
  }

  refundTicketPopup(orderId, ticketId) {
    this.refundOrderId = orderId;
    this.refundTicketId = ticketId;
    this.isSingleTicketRefund = true;
    this.modalWindowOpen = true;
  }

  refundTicket(isRefundTicket: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isRefundTicket) {
      this.showLoader = true;
      this.userService.refundSingleTicket(this.refundOrderId, this.refundTicketId).subscribe(() => {
        // After every ticket refund, call back ticket history
        this.getTicketHistoryByUser();
        this.showLoader = false;
      },
      () => {
        // if there is an error in ticket cancellation, loader should stop his pittyful existence
        this.showLoader = false;
      });
    }

    this.isSingleTicketRefund = false;
    this.refundOrderId = null;
    this.refundTicketId = null;
  }

  refundParkingPopup(orderId, ticketId) {
    this.refundOrderId = orderId;
    this.refundTicketId = ticketId;
    this.isSingleTicketRefund = true;
    this.isParkingTicketRefund = true;
    this.modalWindowOpen = true;
  }

  refundParkingTicket(isRefundTicket: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isRefundTicket) {
      this.showLoader = true;

      this.userService.refundParkingTicket(this.refundOrderId, this.refundTicketId).subscribe(() => {
        // After parking ticket refund, call back ticket history
        this.getTicketHistoryByUser();
        this.showLoader = false;
      },
      () => {
        // if there is an error in ticket cancellation, loader should stop his pittyful existence
        this.showLoader = false;
      });
    }

    this.isSingleTicketRefund = false;
    this.isParkingTicketRefund = false;
    this.refundOrderId = null;
    this.refundTicketId = null;
  }

  downloadTicket(id: number, type: string, ticketId?: number, packageIndex?: number) {
    this.store.dispatch(new DownloadTicketById({ id, type, ticketId, packageIndex }));
  }

  closeAllTicketsExcept(ticket: TariffRecordOrdersTariffModel): void {
    this.eventWithTickets.forEach(event => {
      event.orders.forEach(order => {
        order.packages.forEach(orderPackage => {
          orderPackage.entryTickets.forEach(packageTicket => {
            packageTicket.expanded = false;
            packageTicket.changeDateExpanded = false;
          });
        });
        order.entryTickets.forEach(ticket => {
          ticket.expanded = false;
          ticket.changeDateExpanded = false;
        });
      });
    });

    ticket.expanded = ticket.expanded !== true;
  }

  closeAllTicketsDayChangeExcept(ticket: TariffRecordOrdersTariffModel): void {
    this.eventWithTickets.forEach(event => {
      event.orders.forEach(order => {
        order.entryTickets.forEach(ticket => {
          ticket.expanded = false;
          ticket.changeDateExpanded = false;
        });
      });
    });

    ticket.changeDateExpanded = ticket.changeDateExpanded !== true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
