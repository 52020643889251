import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  State,
  getOrderResponse,
  getSelectedExhibitionId,
  getTicketHolderInputSets,
  isDownloadTicketButtonVisible
} from '@app/app.reducer';
import { AppService } from '@app/app.service';
import { Store, select } from '@ngrx/store';
import { OrderResponseModel } from '@products/models/order.model';
import { TicketSendingOptions } from '@products/models/tariff.model';
import { GtmService } from '@shared/gtm/gtmService';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { RemoveCountdowns } from '@store/products/booking/booking.actions';
import { getBookedProducts, shouldSendAnonymousProductToAPI } from '@store/products/booking/booking.selectors';
import { getSelectedSendingOption } from '@store/products/product-selection/product-selection.selectors';
import { VisibilityPayloadModel } from '@store/step-forms/step.interface';
import { SetMultipleStepsVisibility, SetSelectedStep } from '@store/step-forms/steps-forms.actions';
import { StepsFormsService } from '@store/step-forms/steps-forms.service';
import { UserService } from '@store/user/user.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-invoice',
  templateUrl: './web-shop-invoice.component.html',
  styleUrls: ['./web-shop-invoice.component.scss']
})
export class WebShopInvoiceComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedSendingOption: string;
  isMobile: boolean;
  invoiceDownloadUrl: string;
  ticketsDownloadUrl: string;
  ticketsMobileDownloadUrl: string;
  hasMobileTicket: boolean;
  socialMediaShares: Array<string> = [];
  isDownloadTicketButtonVisible: boolean;
  isAnonymousTicketLoading: boolean = false;
  isAnonymous: boolean = false;

  private allTicketsTicketRetrievalLink: boolean = false;
  private exhibitionId$: Observable<number>;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private appService: AppService,
    private helperService: HelperService,
    private store: Store<State>,
    private userService: UserService,
    private gtmService: GtmService,
    private stepsFormsService: StepsFormsService
  ) {
    this.exhibitionId$ = this.store.pipe(
      select(getSelectedExhibitionId),
      first()
    );

    const voucherURL: string = this.helperService.getVoucherUrl();
    const ticketParams: string = this.helperService.getTicketParams();
    !!voucherURL && this.onResetShop(true, voucherURL);
    !!ticketParams && this.onResetShop(true, null, ticketParams);

    combineLatest([
      this.store.pipe(select(shouldSendAnonymousProductToAPI)),
      this.store.pipe(select(getBookedProducts)),
      this.store.pipe(select(getTicketHolderInputSets))
    ])
      .pipe(first(([shouldSendAnonymousProductToAPI]) => !!shouldSendAnonymousProductToAPI))
      .subscribe(([_, bookedProducts, ticketHolderInputSets]) =>
        this.stepsFormsService.prepareDataForSaveAndSend(
          bookedProducts,
          0,
          ticketHolderInputSets,
          (this.isAnonymous = this.isAnonymousTicketLoading = true)
        )
      );

    const visibilityPayload: VisibilityPayloadModel[] = [
      {
        stepKey: 'tickets',
        visible: false
      },
      {
        stepKey: 'personal',
        visible: false
      },
      {
        stepKey: 'workshop',
        visible: false
      },
      {
        stepKey: 'menu',
        visible: false
      },
      {
        stepKey: 'legitimation',
        visible: false
      },
      {
        stepKey: 'confirmation',
        visible: false
      }
    ];

    this.store.dispatch(new SetMultipleStepsVisibility(visibilityPayload));
  }

  ngOnInit() {
    this.isMobile = this.helperService.isMobile();

    this.store.dispatch(new RemoveCountdowns());

    this.subscription.add(
      this.store.pipe(select(getTicketHolderInputSets)).subscribe(holders => {
        this.hasMobileTicket = holders.some(holder => {
          const sendingOption = holder.inputSet.list.find(input => input.key === 'sendingOption');
          return (
            sendingOption.value === TicketSendingOptions.MobilePerOwner ||
            sendingOption.value === TicketSendingOptions.TicketRetrievalLink
          );
        });

        //check if there are any parking tickets as then we need to show the ticket download button even if all other tickets are ticketRetrievalLink:
        const storedParkingTickets = this.helperService.getParkingReservations();
        const hasParkingTickets = !!storedParkingTickets.length;

        //check if all tickets are ticketRetrievalLink but only if we don't have any parking tickets:
        this.allTicketsTicketRetrievalLink =
          !hasParkingTickets &&
          holders.length > 0 &&
          !holders.some(ticketHolder => {
            const sendingOption = ticketHolder.inputSet.list.find(input => input.key === 'sendingOption');
            return !!sendingOption && sendingOption.value !== TicketSendingOptions.TicketRetrievalLink;
          });
      })
    );

    this.userService
      .getListOfSocialShares()
      .pipe(first(socialMediaShares => !!socialMediaShares))
      .subscribe((socialMediaShares: Array<string>) => (this.socialMediaShares = socialMediaShares));

    this.exhibitionId$.subscribe(() => this.store.dispatch(new SetSelectedStep('invoice')));

    this.store
      .pipe(
        select(getSelectedSendingOption),
        first()
      )
      .subscribe(sendingOption => (this.selectedSendingOption = sendingOption));

    combineLatest([this.store.pipe(select(isDownloadTicketButtonVisible)), this.store.pipe(select(getOrderResponse))])
      .pipe(first())
      .subscribe(
        ([isDownloadTicketButtonVisible, orderResponse]) =>
          (this.isDownloadTicketButtonVisible =
            this.allTicketsTicketRetrievalLink && !orderResponse.hasValidVisitorQuestionnaire
              ? false
              : isDownloadTicketButtonVisible)
      );

    this.store
      .pipe(
        select(getOrderResponse),
        first(orderResponse => !!orderResponse)
      )
      .subscribe((orderResponse: OrderResponseModel) => {
        this.invoiceDownloadUrl =
          (orderResponse.showInvoiceDownload === null || orderResponse.showInvoiceDownload) && orderResponse.hash
            ? `${environment.protocol}${environment.webApiUrl}/person/download-invoice/${orderResponse.hash}`
            : '';
        this.ticketsDownloadUrl = orderResponse.hash
          ? `${environment.protocol}${environment.webApiUrl}/person/download-all-tickets/${orderResponse.hash}`
          : '';
        this.ticketsMobileDownloadUrl = orderResponse.hash
          ? `${environment.protocol}${environment.webApiUrl}/person/download-all-mobile-tickets/${orderResponse.hash}`
          : '';
        this.isAnonymousTicketLoading = false;
      });
  }

  ngAfterViewInit() {
    this.gtmService.pushPurchase();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  urlEncoding(url) {
    return encodeURIComponent(url);
  }

  socialmediaShare(socialMedia) {
    let domain = environment.protocol + environment.origin;
    domain = domain || window.location.origin;

    this.exhibitionId$.subscribe(exhibitionId => {
      const encodedUrl = `${this.urlEncoding(domain)}/webshop/${exhibitionId}`;
      let url = '';

      switch (socialMedia) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${encodedUrl}&quote=I will attend this event`;
          break;

        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=I will attend this event`;
          break;

        case 'linkedin':
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=I will attend this event`;
          break;

        case 'xing':
          url = `https://www.xing.com/spi/shares/new?url=${encodedUrl}`;
          break;

        case 'vkontakte':
          url = `https://vk.com/share.php?url=${encodedUrl}`;
          break;

        case 'whatsapp':
          url = `https://api.whatsapp.com/send?text=I will attend this event ${encodedUrl}`;
          break;
      }
      window.open(url, '', 'height=700,width=700,scrollbars=yes,toolbar=no,status=yes');
    });
  }

  onResetShop(releaseAllVouchersAndTickets: boolean = true, voucherId?: string, ticketParams?: string) {
    this.exhibitionId$.subscribe(exhibitionId =>
      this.appService
        .resetReducers(releaseAllVouchersAndTickets)
        .subscribe(() =>
          window.location.replace(
            `/webshop/${exhibitionId}` +
              (!!voucherId ? `/tickets?voucher=${voucherId}` : '') +
              (!!ticketParams ? `/tickets${ticketParams}` : '')
          )
        )
    );
  }
}
