var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslationsService } from '@pages/translations/translations.service';
import { SetIsTranslationLoaded, SetTranslations, ActionTypes as TranslationActionTypes } from '@store/translation/translation.actions';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
var TranslationsEffects = /** @class */ (function () {
    function TranslationsEffects(actions$, debounce, translationsService) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.debounce = debounce;
        this.translationsService = translationsService;
        this.loadAndSetTranslations$ = this.actions$.pipe(ofType(TranslationActionTypes.LOAD_AND_SET_TRANSLATIONS), debounceTime(this.debounce), switchMap(function (_a) {
            var payload = _a.payload;
            var lang = payload.lang, eventId = payload.eventId;
            return _this.translationsService.loadAndMergeTranslations(lang, eventId).pipe(map(function (translations) {
                return new SetTranslations({
                    language: lang,
                    list: translations
                });
            }), catchError(function () { return of(new SetTranslations(null)); }));
        }));
        this.setIsTranslationLoaded$ = this.actions$.pipe(ofType(TranslationActionTypes.SET_TRANSLATIONS), debounceTime(this.debounce), tap(function (_a) {
            var payload = _a.payload;
            return _this.translationsService.setTranslationsIntoTranslateService(payload);
        }), switchMap(function () { return of(new SetIsTranslationLoaded(true)); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TranslationsEffects.prototype, "loadAndSetTranslations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TranslationsEffects.prototype, "setIsTranslationLoaded$", void 0);
    return TranslationsEffects;
}());
export { TranslationsEffects };
