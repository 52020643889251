import { State } from '../app.reducer';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Product, isProductTypeTariff } from '../_pages/products/models/product-selection.model';
import { Tariff } from '../_pages/products/models/tariff.model';
import { getActiveVoucherProducts } from '../shared/services-with-reducers/products/product-selection/product-selection.selectors';

@Component({
  selector: 'app-sponsor-list',
  templateUrl: './sponsor-list.component.html',
  styleUrls: ['./sponsor-list.component.scss']
})
export class SponsorListComponent implements OnInit, OnDestroy {
  hasSponsors = false;
  vouchers: Tariff[] = [];

  private subscriptions = new Subscription();

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.subscriptions.add(
      this.store.pipe(select(getActiveVoucherProducts)).subscribe((products: Product[]) => {
        this.hasSponsors = products.some(
          product =>
            isProductTypeTariff(product) &&
            product.tariffType.tariffs.some(tariff => !!tariff.sponsors && !!tariff.sponsors.length)
        );

        products.forEach(product => isProductTypeTariff(product) && this.vouchers.push(...product.tariffType.tariffs));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
