import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { VoucherCode, VoucherStateViewModel } from '@products/models/voucher.model';
import { VoucherService } from '@products/services/voucher.service';
import { HelperService } from '@store/helpers/helper.service';
import { environment } from '@src/environments/environment';
import { getIsTranslationLoaded } from '@store/translation/translation.selectors';
import { Observable, Subject, combineLatest } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isSelfRegistration: boolean;
  @Input() isRedeemedAnonymousVoucherProductInProductSelectionList: boolean;
  @Input() maxTicketLimit: number;
  @Input() limitPromoCodes: number;
  @Input() limitLimitedPromoCodes: number;
  @Input() ticketLimitPerUserAccount: number;
  @Input() currentUserAccountTicketLimit: number;
  @Input() isVoucherIncludedPerUserAccountLimit: boolean;
  @Input() bookedProductsCount: number;
  voucherCode: VoucherCode;
  voucherForm: FormGroup;
  voucherInput: AbstractControl;
  scanImageUrl: string = 'assets/scan.png';
  voucherViewModel$: Observable<VoucherStateViewModel>;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private voucherService: VoucherService,
    private helperService: HelperService, //used in markup
  ) {}

  @HostListener('document:keydown', ['$event'])
  scan(e) {
    this.voucherService.scanVoucher(e);
  }

  ngOnInit() {
    this.voucherService.initState({
      maxTicketLimit: this.maxTicketLimit,
      limitPromoCodes: this.limitPromoCodes,
      limitLimitedPromoCodes: this.limitLimitedPromoCodes,
      ticketLimitPerUserAccount: this.ticketLimitPerUserAccount,
      currentUserAccountTicketLimit: this.currentUserAccountTicketLimit,
      isVoucherIncludedPerUserAccountLimit: this.isVoucherIncludedPerUserAccountLimit,
      voucherCode: this.voucherCode,
      isLoading: false,
      isSelfRegistration: this.isSelfRegistration,
      barcode: '',
      voucherTypingActive: false,
      ticketLimitWarning: '',
      bookedProductsCount: this.bookedProductsCount,
      isRedeemedAnonymousVoucherProductInProductSelectionList: this
        .isRedeemedAnonymousVoucherProductInProductSelectionList,
      isCurrentVoucherTypeMandatory: false,
      isCurrentVoucherTypeAnonymous: false,
      voucherWarning: '',
      invalidVoucherWarningStatus: ''
    });

    this.initVoucherServiceState();
    this.initVoucherFormInput();
    this.checkVoucherUrlParam();
    this.getScanImageUrl();

    this.voucherService.getVoucherWarningTranslation();
    this.voucherService.setSponsorAndExhibitionBanner();
  }

  ngOnChanges() {
    this.voucherService.updateState({
      bookedProductsCount: this.bookedProductsCount,
      isRedeemedAnonymousVoucherProductInProductSelectionList: this
        .isRedeemedAnonymousVoucherProductInProductSelectionList,
      currentUserAccountTicketLimit: this.currentUserAccountTicketLimit
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initVoucherServiceState() {
    this.voucherViewModel$ = this.voucherService.voucherViewState$;
    this.voucherViewModel$.pipe(takeUntil(this.destroy$)).subscribe(({ voucherCode }) => {
      this.voucherCode = voucherCode;
    });
  }

  initVoucherFormInput() {
    this.voucherForm = this.formBuilder.group({ voucherInput: ['', Validators.maxLength(50)] });
    this.voucherInput = this.voucherForm.controls['voucherInput'];
    this.voucherInput.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(voucherCode => {
      this.voucherService.updateState({ voucherCode });
      this.voucherService.clearVoucherWarning();
    });
  }

  checkVoucherUrlParam() {
    combineLatest([this.store.pipe(select(getIsTranslationLoaded)), this.route.queryParams])
      .pipe(
        first(([isTranslationLoaded, params]) => isTranslationLoaded && params.voucher),
        map(([_, params]) => params.voucher),
        takeUntil(this.destroy$)
      )
      .subscribe(voucherCode => {
        this.onRedeem(voucherCode);
      });
  }

  getScanImageUrl() {
    this.store
      .pipe(
        select(getSelectedExhibitionId),
        takeUntil(this.destroy$)
      )
      .subscribe(eventId => {
        this.scanImageUrl = environment.protocol + environment.webApiUrl + '/event/' + eventId + '/scan-image';
      });
  }

  onRedeem(voucherCode: string) {
    this.voucherService.redeemVoucher(voucherCode);
  }
}
