import {
  BookingPackageType,
  BookingProductType,
  BookingTariffType,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { Package, PackageType } from '@products/models/package.model';
import {
  Product,
  ProductList,
  SelectionState,
  SendingOptionModel,
  VoucherProducts,
  isProductGroup,
  isProductTypePackage,
  isProductTypeTariff
} from '@products/models/product-selection.model';
import { Tariff, TariffPersonalizationType } from '@products/models/tariff.model';
import { VoucherCode } from '@products/models/voucher.model';
import { AppConstants } from '@shared/app-constants';
import {
  ActionTypes as ProductSelectionActionTypes,
  Actions as ProductSelectionActions
} from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';

export { SelectionState as State };

const INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND;

export const initialState: SelectionState = {
  list: {},
  ticketSendingOptions: []
};

/**
 * Add booked voucher tariff
 * @description Add and sort voucher tariff in product selection list
 * @param bookedVoucherProduct
 * @param voucherProductsListItems
 */
const setBookedVoucherTariff = (bookedVoucherProduct: BookingTariffType, voucherProductsListItems: VoucherProducts) => {
  bookedVoucherProduct.tariffs.forEach(bookedVoucherTariff => {
    const { ticketPersonId, voucherCode, voucherType, voucherCountLimit } = bookedVoucherTariff;

    const voucherProductIndex = voucherProductsListItems.findIndex(voucherProduct => {
      if (isProductTypeTariff(voucherProduct)) {
        return voucherProduct.tariffType.tariffs.some(
          (voucherTariff: Tariff) =>
            voucherTariff.ticketPersonId === ticketPersonId &&
            (voucherTariff.voucherCode === voucherCode || !voucherTariff.isVisible)
        );
      }
    });

    if (voucherProductIndex === INDEX_NOT_FOUND) {
      return;
    }

    const voucherProductListItem = voucherProductsListItems[voucherProductIndex];
    const voucherProductListItemTariffType = voucherProductListItem.tariffType;
    const voucherProductListItemTariffs = voucherProductListItemTariffType.tariffs;

    const isRedeemedVoucher = voucherProductListItemTariffs.every(
      voucherTariff => voucherTariff.voucherCode !== voucherCode
    );

    if (isRedeemedVoucher) {
      voucherProductsListItems.splice(voucherProductIndex, 1);
      voucherProductsListItems.unshift(voucherProductListItem);

      voucherProductListItemTariffType.isVisible = true;

      const voucherTariffIndex = voucherProductListItemTariffs.findIndex(
        voucherTariff => voucherTariff.ticketPersonId === ticketPersonId && !voucherTariff.isVisible
      );
      const voucherProductListItemTariffCloneToAdd: Tariff = cloneDeep(
        voucherProductListItemTariffs[voucherTariffIndex]
      );

      voucherProductListItemTariffCloneToAdd.isVisible = true;
      voucherProductListItemTariffCloneToAdd.voucherCode = voucherCode;
      voucherProductListItemTariffCloneToAdd.voucherType = voucherType;
      voucherProductListItemTariffCloneToAdd.voucherCountLimit = voucherCountLimit;

      voucherProductListItemTariffs.push(voucherProductListItemTariffCloneToAdd);
      voucherProductListItemTariffs.reverse();
    }
  });
};

/**
 * Remove voucher tariff
 * @description Add and sort voucher tariff in product selection list
 * @param bookedVoucherProduct
 * @param voucherProductsListItems
 */
const removeUnbookedVoucherTariff = (
  bookedVoucherProduct: BookingTariffType,
  voucherProductsListItems: VoucherProducts
) => {
  bookedVoucherProduct.tariffs.forEach(bookedVoucherTariff => {
    const findVoucherTariff = (voucherTariff: Tariff) =>
      voucherTariff.ticketPersonId === bookedVoucherTariff.ticketPersonId &&
      voucherTariff.voucherCode === bookedVoucherTariff.voucherCode;

    const voucherProductIndex = voucherProductsListItems.findIndex(voucherProduct => {
      if (isProductTypeTariff(voucherProduct)) {
        return voucherProduct.tariffType.tariffs.some(findVoucherTariff);
      }
    });

    if (voucherProductIndex === INDEX_NOT_FOUND) {
      return;
    }

    const voucherProductListItem = voucherProductsListItems[voucherProductIndex];
    const voucherProductListItemTariffType = voucherProductListItem.tariffType;
    const voucherProductListItemTariffs = voucherProductListItemTariffType.tariffs;
    const voucherProductListItemTariffIndex = voucherProductListItemTariffs.findIndex(findVoucherTariff);

    voucherProductListItemTariffs.splice(voucherProductListItemTariffIndex, 1);

    const areAllActiveVoucherTariffsRemovedFromTariffType = voucherProductListItemTariffs.every(
      voucherTariff => !voucherTariff.isVisible && !voucherTariff.voucherCode
    );
    if (areAllActiveVoucherTariffsRemovedFromTariffType) {
      voucherProductListItemTariffType.isVisible = false;
    }
  });
};

/**
 * Remove all unbooked vouchers from product selection list
 * @description Remove all unbooked vouchers in self registration product selection list
 * @param voucherProductListItems
 */
const removeUnbookedVoucherProductsIfIsSelfRegistration = (voucherProductListItems: VoucherProducts) => {
  voucherProductListItems.forEach(voucherProduct => {
    if (isProductTypeTariff(voucherProduct)) {
      voucherProduct.tariffType.tariffs = voucherProduct.tariffType.tariffs.filter(
        voucherTariff => !voucherTariff.isVisible && !voucherTariff.voucherCode
      );

      const areAllActiveVoucherTariffsRemovedFromTariffType = voucherProduct.tariffType.tariffs.every(
        voucherTariff => !voucherTariff.isVisible && !voucherTariff.voucherCode
      );

      if (areAllActiveVoucherTariffsRemovedFromTariffType) {
        voucherProduct.tariffType.isVisible = false;
      }
    }
  });
};

/**
 * Remove unbooked regular tariffs on anonymous voucher product redeem
 * @description Removes voucher tariffs in product selection list when anonymous product is redeemed
 * @param voucherProductListItems
 */
const removeUnbookedRegularTariffVouchersIfAnonymousVoucherProductIsRedeemed = (
  voucherProductListItems: VoucherProducts
) => {
  voucherProductListItems.forEach(voucherProduct => {
    if (isProductTypeTariff(voucherProduct)) {
      voucherProduct.tariffType.tariffs = voucherProduct.tariffType.tariffs.filter(
        voucherTariff =>
          !(voucherTariff.voucherCode && voucherTariff.personalizationType !== TariffPersonalizationType.Anonymous)
      );

      const areAllActiveVoucherTariffsRemovedFromTariffType = voucherProduct.tariffType.tariffs.every(
        voucherTariff => !voucherTariff.isVisible && !voucherTariff.voucherCode
      );

      if (areAllActiveVoucherTariffsRemovedFromTariffType) {
        voucherProduct.tariffType.isVisible = false;
      }
    }
  });
};

/**
 * Remove unbooked packages on anonymous voucher product redeem
 * @description Removes all active packages from product selection list when anonymous voucher product is redeemed
 * @param productListItems
 */
const removeUnbookedPackagesIfAnonymousVoucherProductIsRedeemed = (productListItems: ProductList) => {
  Object.keys(productListItems).forEach(listItemKey => {
    const productListItem = productListItems[listItemKey];
    if (isProductGroup(productListItem)) {
      productListItem.products.forEach(product => {
        if (isProductTypePackage(product)) {
          const packageType: PackageType = product.packageType;
          const numberOfPackagesToRemove = packageType.packages.length - 1;

          packageType.numberOfAddedPackages = 0;
          packageType.packages[0].trackByAddedPackage = 0;

          if (numberOfPackagesToRemove > 0) {
            packageType.packages.splice(-numberOfPackagesToRemove);
          }
        }
      });
    }
  });
};

/**
 * Increase package product indexes
 * @description Change package product index newly added product in product selection list
 * @param currentPackage
 * @param newPackageIndex
 */
const increasePackageProductIndexes = (currentPackage: Package, newPackageIndex: number): void => {
  currentPackage.packageIndex = newPackageIndex;
  currentPackage.products.forEach(packageProduct => {
    if (isProductTypeTariff(packageProduct)) {
      packageProduct.tariffType.tariffs.forEach(packageTariff => {
        packageTariff.packageIndex = newPackageIndex;
      });
    }
  });
};

/**
 * Add package
 * @description Add package to product selection list
 * @param product
 * @param bookedPackages
 */
const addPackages = (product: Product, bookedPackages: BookingPackageType[]): void => {
  const packageType = product.packageType;
  const currentPackageTypeBookedPackages = bookedPackages.filter(
    bookingPackage => bookingPackage.packageNumber === packageType.packageNumber
  );

  const fistPackage: Package = packageType.packages[0];
  const isOnlyFirstPackageAdded =
    packageType.packages.length === 1 &&
    packageType.numberOfAddedPackages === 0 &&
    currentPackageTypeBookedPackages.length === 1;
  if (isOnlyFirstPackageAdded) {
    packageType.numberOfAddedPackages++;
    fistPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
    return;
  }

  const areMultiplePackagesWithFistPackagedAdded =
    packageType.packages.length === 1 &&
    packageType.numberOfAddedPackages === 0 &&
    currentPackageTypeBookedPackages.length > 1;

  if (areMultiplePackagesWithFistPackagedAdded) {
    packageType.numberOfAddedPackages++;
    fistPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
    currentPackageTypeBookedPackages.splice(0, 1);
  }

  if (currentPackageTypeBookedPackages.length) {
    currentPackageTypeBookedPackages.forEach(currentProductBookingPackage => {
      const newPackage: Package = cloneDeep(fistPackage);
      increasePackageProductIndexes(newPackage, currentProductBookingPackage.packageIndex);

      packageType.numberOfAddedPackages++;
      newPackage.trackByAddedPackage = packageType.numberOfAddedPackages;
      packageType.packages.push(newPackage);
    });
  }
};

/**
 * Remove package
 * @description Remove package from product selection list
 * @param product
 * @param bookedPackages
 */
const removePackages = (product: Product, removedBookedPackages: BookingPackageType[]): void => {
  const packageIndexesToRemove = removedBookedPackages.map(removedBookedPackage => removedBookedPackage.packageIndex);
  const newPackageType: PackageType = cloneDeep(product.packageType);

  product.packageType.packages.reverse().forEach(currentPackage => {
    const removePackage = packageIndexesToRemove.includes(currentPackage.packageIndex);

    if (removePackage) {
      const isLastPackage = newPackageType.numberOfAddedPackages === 1;

      newPackageType.numberOfAddedPackages--;

      if (!isLastPackage) {
        const newPackageToRemoveIndex = newPackageType.packages.findIndex(
          newPackageToRemove => newPackageToRemove.packageIndex === currentPackage.packageIndex
        );

        newPackageType.packages.splice(newPackageToRemoveIndex, 1);
      } else {
        newPackageType.packages[0].trackByAddedPackage = newPackageType.numberOfAddedPackages;
      }
    }
  });

  product.packageType = newPackageType;
};

export const productSelectionReducer = (
  state: SelectionState = initialState,
  action: ProductSelectionActions | ProductActions
): SelectionState => {
  const cloneState: SelectionState = cloneDeep(state);

  switch (action.type) {
    case ProductActionTypes.GET_PREFERRED_PRODUCTS: {
      return {
        ...state,
        list: {}
      };
    }

    case ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST:
    case ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST: {
      const productList: ProductList = cloneDeep(action.payload);

      if (Object.keys(productList)) {
        return {
          ...state,
          list: productList
        };
      }

      return state;
    }

    case ProductSelectionActionTypes.SET_VOUCHER_PRODUCT_IN_SELECTION_LIST: {
      const { bookingProductTypes, isSelfRegistration } = cloneDeep(action.payload) as {
        bookingProductTypes: BookingProductType[];
        isSelfRegistration: boolean;
      };

      if (!bookingProductTypes.length) {
        return state;
      }

      const cloneProductList = cloneState.list;
      const cloneVoucherProductsListItem = cloneProductList.vouchers as VoucherProducts;
      const isAnonymousProductBooked = bookingProductTypes.some(bookedVoucherProduct => {
        if (isBookingProductTypeTariff(bookedVoucherProduct)) {
          return bookedVoucherProduct.tariffs.some(
            bookedVoucherTariff => !!bookedVoucherTariff.voucherCode && bookedVoucherTariff.isAnonymous
          );
        }
      });

      if (isSelfRegistration) {
        removeUnbookedVoucherProductsIfIsSelfRegistration(cloneVoucherProductsListItem);
      }

      bookingProductTypes.forEach(bookedVoucherProduct => {
        if (isBookingProductTypeTariff(bookedVoucherProduct)) {
          setBookedVoucherTariff(bookedVoucherProduct, cloneVoucherProductsListItem);
        }
      });

      const updateRemovedVoucherProductsState = isAnonymousProductBooked || isSelfRegistration;

      if (updateRemovedVoucherProductsState) {
        const removeProductsOnAnonymousProductRedeem = isAnonymousProductBooked && !isSelfRegistration;

        if (removeProductsOnAnonymousProductRedeem) {
          removeUnbookedRegularTariffVouchersIfAnonymousVoucherProductIsRedeemed(cloneVoucherProductsListItem);
          removeUnbookedPackagesIfAnonymousVoucherProductIsRedeemed(cloneProductList);
        }

        return {
          ...state,
          list: cloneProductList
        };
      }

      return {
        ...state,
        list: {
          ...state.list,
          vouchers: cloneVoucherProductsListItem
        }
      };
    }

    case ProductSelectionActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_SELECTION_LIST: {
      const removeBookedVoucherProducts: BookingProductType[] = cloneDeep(action.payload);

      if (!removeBookedVoucherProducts.length) {
        return state;
      }

      const cloneVoucherProductsListItem = cloneState.list.vouchers as VoucherProducts;
      removeBookedVoucherProducts.forEach(removeBookedVoucherProduct => {
        if (isBookingProductTypeTariff(removeBookedVoucherProduct)) {
          removeUnbookedVoucherTariff(removeBookedVoucherProduct, cloneVoucherProductsListItem);
        }
      });

      return {
        ...state,
        list: {
          ...state.list,
          vouchers: cloneVoucherProductsListItem
        }
      };
    }

    case ProductSelectionActionTypes.REMOVE_VOUCHER_CODE_PRODUCT_FROM_SELECTION_LIST: {
      const removeVoucherCode: VoucherCode = action.payload;

      if (!removeVoucherCode) {
        return state;
      }

      const cloneVoucherProductsListItem = cloneState.list.vouchers as VoucherProducts;
      cloneVoucherProductsListItem.forEach(voucherProductListItem => {
        if (isProductTypeTariff(voucherProductListItem)) {
          const voucherProductListItemTariffType = voucherProductListItem.tariffType;
          const voucherProductListItemTariffs = voucherProductListItem.tariffType.tariffs;
          const voucherProductListItemTariffIndex = voucherProductListItemTariffs.findIndex(
            (voucherTariff: Tariff) => voucherTariff.voucherCode === removeVoucherCode
          );

          if (voucherProductListItemTariffIndex === INDEX_NOT_FOUND) {
            return;
          }

          voucherProductListItemTariffs.splice(voucherProductListItemTariffIndex, 1);

          const areAllActiveVoucherTariffsRemovedFromTariffType = voucherProductListItemTariffs.every(
            voucherTariff => !voucherTariff.isVisible && !voucherTariff.voucherCode
          );
          if (areAllActiveVoucherTariffsRemovedFromTariffType) {
            voucherProductListItemTariffType.isVisible = false;
          }
        }
      });

      return {
        ...state,
        list: {
          ...state.list,
          vouchers: cloneVoucherProductsListItem
        }
      };
    }

    case ProductSelectionActionTypes.SET_PACKAGE_IN_SELECTION_LIST: {
      const bookedPackages: BookingPackageType[] = cloneDeep(action.payload);

      if (!bookedPackages.length) {
        return state;
      }

      Object.keys(cloneState.list).forEach(listItemKey => {
        const productListItem = cloneState.list[listItemKey];
        if (isProductGroup(productListItem)) {
          productListItem.products.forEach(product => {
            if (isProductTypePackage(product)) {
              addPackages(product, bookedPackages);
            }
          });
        }
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...cloneState.list
        }
      };
    }

    case ProductSelectionActionTypes.REMOVE_PACKAGE_FROM_SELECTION_LIST: {
      const removedBookedPackages: BookingPackageType[] = cloneDeep(action.payload);

      if (!removedBookedPackages.length) {
        return state;
      }

      Object.keys(cloneState.list).forEach(listItemKey => {
        const productListItem = cloneState.list[listItemKey];
        if (isProductGroup(productListItem)) {
          productListItem.products.forEach(product => {
            if (isProductTypePackage(product)) {
              removePackages(product, removedBookedPackages);
            }
          });
        }
      });

      return {
        ...state,
        list: cloneState.list
      };
    }

    case ProductSelectionActionTypes.INIT_SENDING_OPTIONS:
    case ProductSelectionActionTypes.SET_SENDING_OPTIONS: {
      const sendingOptions: SendingOptionModel[] = cloneDeep(action.payload);

      if (!sendingOptions.length) {
        return state;
      }

      return {
        ...state,
        ticketSendingOptions: sendingOptions
      };
    }

    case ProductSelectionActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_PRODUCT_SELECTION_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
