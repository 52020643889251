var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProductType } from '@products/models/products.model';
import { TariffClassification } from '@products/models/tariff.model';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { GetPreferredProducts, GetProducts, GetWorkshopProducts } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i4 from "@ngrx/store";
var LoadProductService = /** @class */ (function () {
    function LoadProductService(http, route, helperService, store) {
        this.http = http;
        this.route = route;
        this.helperService = helperService;
        this.store = store;
        this.isSelfRegistration = false;
        this.isWidget = false;
        this.productsList = {};
        this.packageNumber = null;
        this.packageIndex = 0;
    }
    LoadProductService.prototype.loadProducts = function (eventId, isWidget) {
        if (isWidget === void 0) { isWidget = false; }
        var _a = this.route.snapshot.queryParams, tt = _a.tt, pt = _a.pt;
        var getProductsAction = tt ? GetPreferredProducts : GetProducts;
        var getProductsPayload = {
            eventId: eventId,
            preferredTicketGroupNr: +tt || null,
            preferredTicketPersonNr: +pt || null
        };
        this.resetState();
        this.isWidget = isWidget;
        this.store.dispatch(new getProductsAction(getProductsPayload));
    };
    LoadProductService.prototype.loadWorkshopProducts = function (eventId) {
        this.store.dispatch(new GetWorkshopProducts(eventId));
    };
    LoadProductService.prototype.getProducts$ = function (eventId, preferredTicketGroupNr, preferredTicketPersonNr) {
        var params = new HttpParams();
        params = params.append('sr', "" + this.isSelfRegistration);
        params = params.append('preferredTicketGroupNr', "" + preferredTicketGroupNr);
        params = params.append('preferredTicketPersonNr', "" + preferredTicketPersonNr);
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/tickets/" + eventId + "/grouped-by-section", {
            params: params
        });
    };
    LoadProductService.prototype.getWorkshopProducts$ = function (eventId) {
        return this.http.get("" + environment.protocol + environment.webApiUrl + "/workshop/workshop-selection/" + eventId);
    };
    LoadProductService.prototype.resetState = function () {
        this.isSelfRegistration = this.helperService.isSelfregistration();
        this.isWidget = false;
        this.productsList = {};
        this.packageNumber = null;
        this.packageIndex = 0;
    };
    LoadProductService.prototype.getAndResetLastAddedInitialPackageIndex = function () {
        var latestAddedInitialPackageIndex = this.packageIndex - 1;
        this.packageIndex = 0;
        return latestAddedInitialPackageIndex;
    };
    LoadProductService.prototype.mapGetProductResponse = function (productsResponse) {
        var _this = this;
        if (!productsResponse.length) {
            return {};
        }
        this.productsList.vouchers = [];
        productsResponse.forEach(function (productResponse, index) {
            var productGroup = _this.mapProductGroupResponse(productResponse);
            if (productGroup.products.length) {
                _this.productsList[index] = productGroup;
            }
        });
        if (!this.productsList.vouchers.length) {
            delete this.productsList.vouchers;
        }
        return this.productsList;
    };
    LoadProductService.prototype.mapProductGroupResponse = function (productGroupResponse) {
        var groupId = productGroupResponse.groupId, groupName = productGroupResponse.groupName, groupDescription = productGroupResponse.groupDescription, items = productGroupResponse.items, expanded = productGroupResponse.expanded;
        var productGroup = {
            groupId: groupId,
            groupName: groupName,
            groupDescription: groupDescription,
            products: this.mapProductsResponse(items),
            expanded: expanded
        };
        return productGroup;
    };
    LoadProductService.prototype.mapProductsResponse = function (productsResponse) {
        var _this = this;
        var products = [];
        productsResponse.forEach(function (productResponse) {
            var mappedProducts = _this.mapProductTypeResponse(productResponse);
            mappedProducts.forEach(function (mappedProduct) {
                mappedProduct.isVoucher && !_this.isWidget
                    ? _this.productsList.vouchers.push(mappedProduct.product)
                    : products.push(mappedProduct.product);
            });
        });
        return products;
    };
    LoadProductService.prototype.mapProductTypeResponse = function (productResponse) {
        if (productResponse.itemType === ProductType.Article) {
            return this.mapArticleResponse(productResponse.article);
        }
        else if (productResponse.itemType === ProductType.Package) {
            return this.mapPackageResponse(productResponse.package);
        }
        else if (productResponse.itemType === ProductType.Tariff) {
            return this.mapTariffTypeResponse(productResponse.ticketType);
        }
    };
    LoadProductService.prototype.mapArticleResponse = function (articleResponse) {
        var mappedData = {
            isVoucher: false,
            product: {
                articleType: {
                    articles: []
                },
                productType: ProductType.Article
            }
        };
        return !!mappedData.product.articleType.articles.length ? [mappedData] : [];
    };
    LoadProductService.prototype.mapPackageResponse = function (packageResponse) {
        var packageNumber = packageResponse.packageNumber, tariff = packageResponse.tariff, variablePrice = packageResponse.variablePrice, packageTariffSheetNumber = packageResponse.packageTariffSheetNumber, packageTariffSheetValidNumber = packageResponse.packageTariffSheetValidNumber, info = packageResponse.info, items = packageResponse.items;
        this.packageNumber = packageNumber;
        var mappedPackage = this.mapPackageItemsResponse(items);
        if (!mappedPackage) {
            return [];
        }
        var packageType = {
            packageNumber: packageNumber,
            name: packageResponse.info,
            tariff: tariff,
            variablePrice: variablePrice,
            packageTariffSheetNumber: packageTariffSheetNumber,
            packageTariffSheetValidNumber: packageTariffSheetValidNumber,
            info: info,
            isDisabled: false,
            isVisible: true,
            numberOfAddedPackages: 0,
            packages: [
                {
                    packageIndex: this.packageIndex++,
                    trackByAddedPackage: 0,
                    products: mappedPackage.products
                }
            ]
        };
        var mappedData = {
            isVoucher: mappedPackage.isVoucher,
            product: {
                productType: ProductType.Package,
                packageType: packageType
            }
        };
        return [mappedData];
    };
    LoadProductService.prototype.mapPackageItemsResponse = function (packageItemsResponse) {
        var _this = this;
        var mappedData = {
            isVoucher: false,
            products: []
        };
        packageItemsResponse.forEach(function (packageItemResponse) {
            var productTypes = _this.mapProductTypeResponse(packageItemResponse);
            productTypes.forEach(function (productType) {
                mappedData.isVoucher = productType.isVoucher;
                mappedData.products.push(productType.product);
            });
        });
        return !!mappedData.products.length ? mappedData : null;
    };
    LoadProductService.prototype.mapTariffTypeResponse = function (tariffTypeResponse) {
        var _this = this;
        var ticketTypeId = tariffTypeResponse.ticketTypeId, ticketTypeName = tariffTypeResponse.ticketTypeName, preferredTicketType = tariffTypeResponse.preferredTicketType;
        var mappedData = {
            isVoucher: false,
            product: {
                productType: ProductType.Tariff,
                tariffType: {
                    ticketTypeId: ticketTypeId,
                    ticketTypeName: ticketTypeName,
                    preferredTicketType: preferredTicketType,
                    isVisible: true,
                    tariffs: []
                }
            }
        };
        tariffTypeResponse.tickets.forEach(function (ticketResponse) {
            var isVoucherTariff = ticketResponse.isVoucher;
            var shouldAddSelfRegistrationTariff = _this.isSelfRegistration && ticketResponse.classification == TariffClassification.SelfRegistration;
            var shouldAddNotSelfRegistrationTariff = !_this.isSelfRegistration && ticketResponse.classification != TariffClassification.SelfRegistration;
            if (isVoucherTariff || shouldAddSelfRegistrationTariff || shouldAddNotSelfRegistrationTariff) {
                mappedData.isVoucher = mappedData.isVoucher || isVoucherTariff;
                mappedData.product.tariffType.tariffs.push(_this.mapTariffResponse(__assign({}, ticketResponse, { ticketTypeName: ticketTypeName }), ticketTypeId));
            }
        });
        if (!mappedData.product.tariffType.tariffs.length) {
            return [];
        }
        if (mappedData.isVoucher) {
            mappedData.product.tariffType.isVisible = this.isWidget;
            var areOnlyVoucherTariffsInTariffType = mappedData.product.tariffType.tariffs.every(function (tariff) { return tariff.isVoucher; });
            if (!areOnlyVoucherTariffsInTariffType) {
                var tariffTypeWithVoucherTariffs = cloneDeep(mappedData);
                var tariffTypeWithoutVoucherTariffs = cloneDeep(mappedData);
                tariffTypeWithVoucherTariffs.product.tariffType.tariffs = tariffTypeWithVoucherTariffs.product.tariffType.tariffs.filter(function (tariff) { return tariff.isVoucher; });
                tariffTypeWithoutVoucherTariffs.isVoucher = false;
                tariffTypeWithoutVoucherTariffs.product.tariffType.isVisible = true;
                tariffTypeWithoutVoucherTariffs.product.tariffType.tariffs = tariffTypeWithoutVoucherTariffs.product.tariffType.tariffs.filter(function (tariff) { return !tariff.isVoucher; });
                return [tariffTypeWithVoucherTariffs, tariffTypeWithoutVoucherTariffs];
            }
        }
        return [mappedData];
    };
    LoadProductService.prototype.mapTariffResponse = function (tariffResponse, ticketTypeId) {
        var ticketName = tariffResponse.ticketName, info = tariffResponse.info, infoExpanded = tariffResponse.infoExpanded, price = tariffResponse.price, personTypeId = tariffResponse.personTypeId, ticketPersonId = tariffResponse.ticketPersonId, ticketTypeName = tariffResponse.ticketTypeName, tariffNr = tariffResponse.tariffNr, durationInDays = tariffResponse.durationInDays, availableTickets = tariffResponse.availableTickets, ticketLimit = tariffResponse.ticketLimit, requiresLegitimation = tariffResponse.requiresLegitimation, personalizationType = tariffResponse.personalizationType, classification = tariffResponse.classification, isVoucher = tariffResponse.isVoucher, isVisible = tariffResponse.isVisible, allowedWorkshops = tariffResponse.allowedWorkshops, allowedWorkshopsFull = tariffResponse.allowedWorkshopsFull, workshopsByDay = tariffResponse.workshopsByDay, hasDaySellLimit = tariffResponse.hasDaySellLimit, validFrom = tariffResponse.validFrom, validTill = tariffResponse.validTill, tageOffset = tariffResponse.tageOffset, packageSettings = tariffResponse.packageSettings;
        return {
            id: ticketPersonId,
            name: ticketName,
            info: info,
            infoExpanded: infoExpanded,
            price: price,
            ticketTypeName: ticketTypeName,
            ticketPersonTypeId: personTypeId,
            durationInDays: durationInDays,
            ticketPersonId: ticketPersonId,
            ticketTypeId: ticketTypeId,
            tariffNumber: tariffNr,
            availableTickets: availableTickets,
            ticketLimit: ticketLimit,
            requiresLegitimation: requiresLegitimation,
            personalizationType: personalizationType,
            classification: classification,
            isVisible: this.isWidget || isVisible,
            isVoucher: isVoucher,
            allowedWorkshops: allowedWorkshops,
            allowedWorkshopsFull: allowedWorkshopsFull,
            workshopsByDay: this.mapWorkshopsByDayResponseToWorkshopsByDay(workshopsByDay),
            hasDaySellLimit: hasDaySellLimit,
            validFrom: validFrom,
            validTill: validTill,
            daysOffset: tageOffset,
            shouldCalendarBeDisplayed: hasDaySellLimit && tageOffset === 31,
            packageSettings: packageSettings,
            packageNumber: packageSettings ? this.packageNumber : null,
            packageIndex: packageSettings ? this.packageIndex : null
        };
    };
    LoadProductService.prototype.mapWorkshopsByDayResponseToWorkshopsByDay = function (workshopsByDayResponse) {
        var workshopsByDay = [];
        workshopsByDayResponse.forEach(function (workshopByDayResponse) {
            var workshops = [];
            workshopByDayResponse.workshops.forEach(function (workshopResponse) {
                var workshop = __assign({}, workshopResponse);
                workshops.push(workshop);
            });
            workshopsByDay.push(__assign({}, workshopByDayResponse, { expanded: false }));
        });
        return workshopsByDay;
    };
    LoadProductService.ngInjectableDef = i0.defineInjectable({ factory: function LoadProductService_Factory() { return new LoadProductService(i0.inject(i1.HttpClient), i0.inject(i2.ActivatedRoute), i0.inject(i3.HelperService), i0.inject(i4.Store)); }, token: LoadProductService, providedIn: "root" });
    return LoadProductService;
}());
export { LoadProductService };
