import { OnDestroy } from '@angular/core';
import { getExhibitionHistoryList, getLanguage, getLegitimationList, getProfile, getProfileBillingAddress } from '@app/app.reducer';
import { StatusBarService } from '@app/status-bar/status-bar.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetExhibitionHistoryList, GetListOfAllExhibitions } from '@store/exhibition/exhibition.actions';
import { GetLegitimationList } from '@store/legitimation/legitimation.actions';
import { LegitimationStatus } from '@store/legitimation/legitimation.interface';
import { GetProfile, GetProfileBillingAddress } from '@store/user/user.actions';
import { UserService } from '@store/user/user.service';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(store, userService, statusBarService, translateService) {
        var _this = this;
        this.store = store;
        this.userService = userService;
        this.statusBarService = statusBarService;
        this.translateService = translateService;
        this.lowLimit = 3;
        this.hiLimit = 10000;
        this.billingAddressPresent = false;
        this.isEmailVerified = true;
        this.isProfileLoaded = false;
        this.limits = {
            legitimation: this.lowLimit,
            ticket: this.lowLimit,
            exhibition: this.lowLimit
        };
        this.limitsTranslations = {
            legitimation: 'profile.toggle.more',
            ticket: 'profile.toggle.more',
            exhibition: 'profile.toggle.more'
        };
        this.LegitimationStatus = LegitimationStatus;
        this.subscriptions = new Subscription();
        this.legitimationList$ = this.store.pipe(select(getLegitimationList));
        this.profile$ = this.store.pipe(select(getProfile));
        this.exhibitionHistoryList$ = this.store.pipe(select(getExhibitionHistoryList));
        this.subscriptions.add(this.store
            .pipe(select(getLanguage), filter(function (lang) { return !!lang; }))
            .subscribe(function (lang) { return _this.store.dispatch(new GetListOfAllExhibitions(lang)); }));
        this.store
            .pipe(select(getProfile), first(function (profile) { return !!profile; }))
            .subscribe(function (user) {
            _this.store.dispatch(new GetProfileBillingAddress(user.id));
            _this.store.dispatch(new GetLegitimationList(user.id));
        });
        this.subscriptions.add(this.store
            .pipe(select(getProfileBillingAddress), filter(function (profileBillingAddress) { return !!profileBillingAddress; }))
            .subscribe(function (profileBillingAddress) { return (_this.billingAddressPresent = !!profileBillingAddress.length); }));
        this.profile$.pipe(filter(function (profile) { return !!profile; })).subscribe(function (profile) {
            if (profile.hasOwnProperty('isEmailVerified')) {
                _this.isEmailVerified = profile.isEmailVerified;
            }
            if (!_this.isProfileLoaded) {
                _this.isProfileLoaded = true;
                // always reload profile on refresh
                _this.store.dispatch(new GetProfile(profile.id));
            }
            else {
                _this.store.dispatch(new GetExhibitionHistoryList(profile.id));
            }
        });
    }
    ProfileComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ProfileComponent.prototype.resendAccountVerification = function () {
        var _this = this;
        combineLatest([this.store.pipe(select(getLanguage)), this.store.pipe(select(getProfile))])
            .first(function (_a) {
            var language = _a[0], profile = _a[1];
            return !!language && !!profile;
        })
            .switchMap(function (_a) {
            var language = _a[0];
            return _this.userService
                .sendVerifyEmail(language)
                .switchMap(function (_a) {
                var status = _a.status;
                return _this.translateService
                    .get("profile.verify-email." + (status === 200 ? 'send' : 'notSend'))
                    .map(function (translation) { return ({ translation: translation, status: status === 200 ? 'success' : 'error' }); });
            });
        })
            .subscribe(function (_a) {
            var translation = _a.translation, status = _a.status;
            _this.statusBarService.setStatus(translation, status);
        });
    };
    ProfileComponent.prototype.toggleList = function (section) {
        if (this.lowLimit === this.limits[section]) {
            this.limits[section] = this.hiLimit;
            this.limitsTranslations[section] = 'profile.toggle.less';
        }
        else {
            this.limits[section] = this.lowLimit;
            this.limitsTranslations[section] = 'profile.toggle.more';
        }
    };
    return ProfileComponent;
}());
export { ProfileComponent };
