import {
  BookingPackageType,
  BookingProductType,
  BookingState,
  BookingTariff,
  BookingTariffType,
  BookingWorkshop,
  ProductHolderUuid,
  RemoveBookingWorkshopAssignedHolders,
  RemoveWorkshopBookingsAndSetSeats,
  SetBookingContingentReservation,
  SetProductBookingContingentReservation,
  SetProductBookingParkingReservation,
  SetWorkshopBookingsAndSetSeats,
  VoucherCountdown,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import { SetContingentReservationDate } from '@products/models/contingent.model';
import { SetParkingReservationDate } from '@products/models/parking.model';
import { AppConstants } from '@shared/app-constants';
import { getCountdownTimestamp } from '@shared/app-utils';
import { ActionTypes as BookingActionTypes, Actions as BookingActions } from '@store/products/booking/booking.actions';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';

export { BookingState as State };

const INDEX_NOT_FOUND = AppConstants.INDEX_NOT_FOUND;

const initialState: BookingState = {
  list: [],
  countdown: {
    product: null,
    workshop: null,
    voucher: []
  },
  claimedTicketHash: '',
  claimedTicketHashValid: null,
  lastVoucherTicket: null,
  isAnonymousProductSentToAPI: false,
  ticketHolderSubmitResult: false
};

/**
 * Find tariff type index
 * @description Get index of specified tariff type
 * @param tariffType
 * @param bookedProductTypes
 * @returns Ticket type index or -1 if index not found
 */
const findTariffTypeIndex = (tariffType: BookingTariffType, bookedProductTypes: BookingProductType[]): number => {
  if (!bookedProductTypes.length) {
    return INDEX_NOT_FOUND;
  }

  const { productType: tariffProductType, ticketTypeId: productTicketTypeId } = tariffType;

  const findIndexByTicketTypeId = (bookingProductType: BookingTariffType) => {
    if (!isBookingProductTypeTariff(bookingProductType)) {
      return false;
    }

    const { productType, ticketTypeId } = bookingProductType;

    return productType === tariffProductType && ticketTypeId === productTicketTypeId;
  };

  return bookedProductTypes.findIndex(findIndexByTicketTypeId);
};

/**
 * Find tariff index
 * @description Get index of specified ticket
 * @param tariff
 * @param bookedTariffTypes
 * @returns Ticket index or -1 if index not found
 */
const findTariffIndex = (tariff: BookingTariff, bookedTariffTypes: BookingTariffType): number => {
  return bookedTariffTypes.tariffs.findIndex(
    bookedTariff =>
      bookedTariff.ticketPersonId === tariff.ticketPersonId && bookedTariff.voucherCode === tariff.voucherCode
  );
};

/**
 * Find package index
 * @description Get index of specified package
 * @param productType
 * @param cartProductTypes
 * @returns Package index or -1 if index not found
 */
const findPackageIndex = (productType: BookingPackageType, bookedProductTypes: BookingProductType[]): number => {
  if (!bookedProductTypes.length) {
    return INDEX_NOT_FOUND;
  }

  const {
    productType: packageProductType,
    packageNumber: productPackageNumber,
    packageIndex: productPackageIndex
  } = productType;

  const findIndexByPackageNumberAndPackageIndex = (bookedProductType: BookingPackageType): boolean => {
    if (!isBookingProductTypePackage(bookedProductType)) {
      return false;
    }

    const { productType, packageNumber, packageIndex } = bookedProductType;

    return (
      packageProductType === productType &&
      productPackageNumber === packageNumber &&
      productPackageIndex === packageIndex
    );
  };

  return bookedProductTypes.findIndex(findIndexByPackageNumberAndPackageIndex);
};

/**
 * Get product type cart index
 * @description Get cart index of specified product type
 * @param productType
 * @param bookingProductTypes
 * @returns Product type cart index or -1 if index not found
 */
const findProductTypeBookingIndex = (
  productType: BookingProductType,
  bookedProductTypes: BookingProductType[]
): number => {
  if (!bookedProductTypes.length) {
    return INDEX_NOT_FOUND;
  }

  if (isBookingProductTypeTariff(productType)) {
    return findTariffTypeIndex(productType, bookedProductTypes);
  } else if (isBookingProductTypePackage(productType)) {
    return findPackageIndex(productType, bookedProductTypes);
  }

  return INDEX_NOT_FOUND;
};

/**
 * Validate product types
 * @description Validate if provided products have valid count
 * @param productType
 * @returns Product types or empty array
 */
const validateProductTypes = (productType: BookingProductType): BookingProductType => {
  if (isBookingProductTypeTariff(productType)) {
    productType.tariffs = productType.tariffs.filter(addedTariff => addedTariff.count > 0);
  } else if (isBookingProductTypePackage(productType)) {
    productType.productTypes = productType.productTypes.filter(addedPackageProductType => {
      if (isBookingProductTypeTariff(addedPackageProductType)) {
        addedPackageProductType.tariffs = addedPackageProductType.tariffs.filter(
          addedPackageTariff => addedPackageTariff.count > 0
        );

        return addedPackageProductType.tariffs.length;
      }
    });
  }

  return productType;
};

/**
 * Find booked tariff
 * @description Find booked tariff
 * @param bookedProductType
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @returns Booked Tariff
 */
const findBookedTariff = (
  bookedProductType: BookingTariffType,
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode: string
) =>
  bookedProductType.tariffs.find(
    bookedTariff =>
      bookedTariff.ticketTypeId === ticketTypeId &&
      bookedTariff.ticketPersonId === ticketPersonId &&
      bookedTariff.voucherCode === voucherCode
  );

/**
 * Find booked package tariff
 * @description Find booked package tariff
 * @param bookedProductType
 * @param ticketTypeId
 * @param ticketPersonId
 * @param voucherCode
 * @param packageNumber
 * @param packageIndex
 * @returns Booked Tariff
 */
const findBookedPackageTariff = (
  bookedPackageProductType: BookingTariffType,
  ticketTypeId: number,
  ticketPersonId: number,
  voucherCode: string,
  packageNumber: number,
  packageIndex: number
) =>
  bookedPackageProductType.tariffs.find(
    bookedPackageTariff =>
      bookedPackageTariff.ticketTypeId === ticketTypeId &&
      bookedPackageTariff.ticketPersonId === ticketPersonId &&
      bookedPackageTariff.voucherCode === voucherCode &&
      bookedPackageTariff.packageNumber === packageNumber &&
      bookedPackageTariff.packageIndex === packageIndex
  );

/**
 * Check valid product type
 * @description Check if product has products or product types (if package)
 * @param productType
 * @returns Boolean valid product type
 */
const isValidProductType = (productType: BookingProductType): boolean => {
  const isTariffProductTypeValid = isBookingProductTypeTariff(productType) && !!productType.tariffs.length;
  const isPackageProductTypeValid = isBookingProductTypePackage(productType) && !!productType.productTypes.length;

  return isTariffProductTypeValid || isPackageProductTypeValid;
};

/**
 * Add or remove tariff
 * @description Add or remove tariff from booking tariff types
 * @param tariffIndex
 * @param isValidTariffType
 * @param validatedTariff
 * @param bookingTariffs
 */
const addOrRemoveTariff = (
  index: number,
  isValidTariffType: boolean,
  setTariff: BookingTariff,
  bookingTariffs: BookingTariff[]
): void => {
  if (index === INDEX_NOT_FOUND) {
    if (isValidTariffType) {
      bookingTariffs.push(setTariff);
    }
  } else {
    if (isValidTariffType) {
      // Remove holder uuid assign when holders will be assigned in personalization step
      const alreadyBookedTariff = bookingTariffs[index];
      const alreadyBookedTariffHolderUuids = alreadyBookedTariff.holderUuids;
      const alreadyBookedContingentReservations = alreadyBookedTariff.contingentReservations;
      const alreadyBookedParkingReservations = alreadyBookedTariff.parkingReservations;
      const addBooking = setTariff.count > alreadyBookedTariff.count;
      const removeBooking = setTariff.count < alreadyBookedTariff.count;

      if (addBooking) {
        setTariff.holderUuids = [...alreadyBookedTariffHolderUuids, ...setTariff.holderUuids];
        setTariff.workshops = [...bookingTariffs[index].workshops, ...setTariff.workshops];
        setTariff.contingentReservations = [
          ...alreadyBookedContingentReservations,
          ...setTariff.contingentReservations
        ];
        setTariff.parkingReservations = [...alreadyBookedParkingReservations, ...setTariff.parkingReservations];
      } else if (removeBooking) {
        const removeBookingsCount = alreadyBookedTariff.count - setTariff.count;

        if (alreadyBookedTariff.workshops) {
          const removedHolderUuids = _.takeRight(alreadyBookedTariffHolderUuids, removeBookingsCount);
          alreadyBookedTariff.workshops.forEach(
            bookedWorkshop =>
              (bookedWorkshop.holderUuids = _.difference(bookedWorkshop.holderUuids, removedHolderUuids))
          );

          setTariff.workshops = alreadyBookedTariff.workshops.filter(
            bookedWorkshop => !!bookedWorkshop.holderUuids.length
          );
        }

        setTariff.holderUuids = alreadyBookedTariffHolderUuids.slice(0, -removeBookingsCount);
        setTariff.contingentReservations = alreadyBookedContingentReservations.slice(0, -removeBookingsCount);
        setTariff.parkingReservations = alreadyBookedParkingReservations.slice(0, -removeBookingsCount);
      } else {
        setTariff.holderUuids = alreadyBookedTariffHolderUuids;
      }

      const setBookingTariffPriceToParkingReservationPriceTotal = setTariff.parkingReservations.length;
      if (setBookingTariffPriceToParkingReservationPriceTotal) {
        setTariff.price = setTariffPriceToParkingReservationTotalPrice(setTariff);
      }

      bookingTariffs[index] = setTariff;
    } else {
      bookingTariffs.splice(index, 1);
    }
  }
};

/**
 * Remove empty product type
 * @description Remove empty product type from booked product types
 * @param products
 * @param bookedProductTypes
 * @param index
 */
const removeEmptyProductType = (
  products: BookingProductType[] | BookingTariff[],
  bookedProductTypes: BookingProductType[],
  index: number
): void => {
  const removeProduct = !products.length;
  if (removeProduct) {
    bookedProductTypes.splice(index, 1);
  }
};

/**
 * Checks if state voucher is expired
 * @param voucherCountdown Current state voucher countdown
 * @returns True if current state voucher expired
 */
const isVoucherExpired = (voucherCountdown: VoucherCountdown): boolean => {
  const currentTimestamp: number = Date.now();
  return voucherCountdown && currentTimestamp > voucherCountdown.expiryTimestamp;
};

/**
 * Calculate booked parking tariff price
 * @description Calculate parking tariff price based on parking reservations total price
 * @param bookedTariff
 * @return Number
 */
const setTariffPriceToParkingReservationTotalPrice = (bookedTariff: BookingTariff) => {
  return bookedTariff.parkingReservations.reduce(
    (bookedParkingReservationTotalPrice, bookedParkingReservation) =>
      bookedParkingReservationTotalPrice + bookedParkingReservation.price,
    0
  );
};

export const bookingReducer = (
  state: BookingState = initialState,
  action: BookingActions | ProductActions
): BookingState => {
  switch (action.type) {
    case BookingActionTypes.SET_PRODUCT_IN_BOOKING_LIST:
    case BookingActionTypes.SET_PREFERRED_PRODUCT_IN_BOOKING_LIST:
    case BookingActionTypes.SET_VOUCHER_PRODUCT_IN_BOOKING_LIST:
    case BookingActionTypes.SET_VOUCHER_FOR_ANONYMOUS_PRODUCT_IN_BOOKING_LIST: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
      const setProductTypes: BookingProductType[] = cloneDeep(action.payload);

      if (setProductTypes.length) {
        setProductTypes.forEach(setProductType => {
          const productTypeBookingIndex = findProductTypeBookingIndex(setProductType, cloneStateProductsList);

          if (productTypeBookingIndex === INDEX_NOT_FOUND) {
            const validatedProductType = validateProductTypes(setProductType);

            if (isValidProductType(validatedProductType)) {
              cloneStateProductsList.push(validatedProductType);
            }
          } else if (isBookingProductTypeTariff(setProductType)) {
            const foundBookedTariffType = cloneStateProductsList[productTypeBookingIndex] as BookingTariffType;
            const { tariffs: foundBookedTariffs } = foundBookedTariffType;

            setProductType.tariffs.forEach(setTariff => {
              const isValidSetTariff = setTariff.count > 0;
              const tariffIndex = findTariffIndex(setTariff, foundBookedTariffType);

              addOrRemoveTariff(tariffIndex, isValidSetTariff, setTariff, foundBookedTariffs);
            });

            removeEmptyProductType(foundBookedTariffs, cloneStateProductsList, productTypeBookingIndex);
          } else if (isBookingProductTypePackage(setProductType)) {
            const foundBookedPackageType = cloneStateProductsList[productTypeBookingIndex] as BookingPackageType;
            const foundBookedPackageProductTypes: BookingProductType[] = foundBookedPackageType.productTypes;

            setProductType.productTypes.forEach(setPackageProductType => {
              const packageBookingTicketTypeIndex = findProductTypeBookingIndex(
                setPackageProductType,
                foundBookedPackageProductTypes
              );

              if (packageBookingTicketTypeIndex === INDEX_NOT_FOUND) {
                const validatedSetPackageProductType = validateProductTypes(setPackageProductType);

                if (isValidProductType(validatedSetPackageProductType)) {
                  foundBookedPackageProductTypes.push(validatedSetPackageProductType);
                }
              } else {
                if (isBookingProductTypeTariff(setPackageProductType)) {
                  const foundBookingPackageTariffType = foundBookedPackageProductTypes[
                    packageBookingTicketTypeIndex
                  ] as BookingTariffType;
                  const { tariffs: bookedPackageTariffs } = foundBookingPackageTariffType;

                  setPackageProductType.tariffs.forEach(setPackageTariff => {
                    const isValidSetPackageTariff = setPackageTariff.count > 0;
                    const packageTariffIndex = findTariffIndex(setPackageTariff, foundBookingPackageTariffType);

                    addOrRemoveTariff(
                      packageTariffIndex,
                      isValidSetPackageTariff,
                      setPackageTariff,
                      bookedPackageTariffs
                    );
                  });

                  removeEmptyProductType(
                    bookedPackageTariffs,
                    foundBookedPackageProductTypes,
                    packageBookingTicketTypeIndex
                  );
                }
              }

              removeEmptyProductType(foundBookedPackageProductTypes, cloneStateProductsList, productTypeBookingIndex);
            });
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.REMOVE_PRODUCT_FROM_BOOKING_LIST:
    case BookingActionTypes.REMOVE_VOUCHER_PRODUCT_FROM_BOOKING_LIST:
    case BookingActionTypes.REMOVE_RELEASED_VOUCHER_PRODUCT_FROM_BOOKING_LIST: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
      const removedProductTypes: BookingProductType[] = cloneDeep(action.payload);

      if (removedProductTypes.length) {
        removedProductTypes.forEach(removedBookedProductType => {
          const productTypeBookingIndex = findProductTypeBookingIndex(removedBookedProductType, cloneStateProductsList);

          if (productTypeBookingIndex !== INDEX_NOT_FOUND) {
            if (isBookingProductTypeTariff(removedBookedProductType)) {
              const foundBookedTariffType = cloneStateProductsList[productTypeBookingIndex] as BookingTariffType;
              const { tariffs: foundBookingTariffs } = foundBookedTariffType;

              removedBookedProductType.tariffs.forEach(removedTariff => {
                const tariffIndex = findTariffIndex(removedTariff, foundBookedTariffType);

                if (tariffIndex !== INDEX_NOT_FOUND) {
                  foundBookingTariffs.splice(tariffIndex, 1);
                }
              });

              removeEmptyProductType(foundBookingTariffs, cloneStateProductsList, productTypeBookingIndex);
            } else if (isBookingProductTypePackage(removedBookedProductType)) {
              const foundBookedPackageType = cloneStateProductsList[productTypeBookingIndex] as BookingPackageType;
              const foundBookedProductTypes: BookingProductType[] = foundBookedPackageType.productTypes;

              removedBookedProductType.productTypes.forEach(removeBookedPackageProductType => {
                const packageBookingTariffTypeIndex = findProductTypeBookingIndex(
                  removeBookedPackageProductType,
                  foundBookedProductTypes
                );

                if (packageBookingTariffTypeIndex !== INDEX_NOT_FOUND) {
                  const foundBookedPackageTicketType = foundBookedProductTypes[
                    packageBookingTariffTypeIndex
                  ] as BookingTariffType;
                  const { tariffs: foundBookingPackageTariffs } = foundBookedPackageTicketType;

                  if (isBookingProductTypeTariff(removeBookedPackageProductType)) {
                    removeBookedPackageProductType.tariffs.forEach(removeBookedPackageTariff => {
                      const packageTariffIndex = findTariffIndex(
                        removeBookedPackageTariff,
                        foundBookedPackageTicketType
                      );

                      if (packageTariffIndex !== INDEX_NOT_FOUND) {
                        foundBookedPackageTicketType.tariffs.splice(packageTariffIndex, 1);
                      }
                    });

                    removeEmptyProductType(
                      foundBookingPackageTariffs,
                      foundBookedProductTypes,
                      packageBookingTariffTypeIndex
                    );
                  }
                }
              });

              removeEmptyProductType(foundBookedProductTypes, cloneStateProductsList, productTypeBookingIndex);
            }
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
      const bookedPackageTypes: BookingPackageType[] = cloneDeep(action.payload);

      if (bookedPackageTypes.length) {
        bookedPackageTypes.forEach(setProductType => {
          if (isBookingProductTypePackage(setProductType)) {
            const packageBookingIndex = findPackageIndex(setProductType, cloneStateProductsList);
            const validatedSetPackageProductType = validateProductTypes(setProductType);
            const isPackageProductTypeValid = isValidProductType(validatedSetPackageProductType);

            if (packageBookingIndex === INDEX_NOT_FOUND) {
              if (isPackageProductTypeValid) {
                cloneStateProductsList.push(validatedSetPackageProductType);
              }
            } else {
              if (isPackageProductTypeValid) {
                cloneStateProductsList[packageBookingIndex] = validatedSetPackageProductType;
              } else {
                cloneStateProductsList.splice(packageBookingIndex, 1);
              }
            }
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
      const removedProductTypes: BookingPackageType[] = cloneDeep(action.payload);

      if (removedProductTypes.length) {
        removedProductTypes.forEach(removedProductType => {
          if (isBookingProductTypePackage(removedProductType)) {
            const packageBookingIndex = findPackageIndex(removedProductType, cloneStateProductsList);

            if (packageBookingIndex !== INDEX_NOT_FOUND) {
              cloneStateProductsList.splice(packageBookingIndex, 1);
            }
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_WORKSHOP_IN_BOOKING: {
      const setWorkshopBookingsAndSetSeats: SetWorkshopBookingsAndSetSeats = cloneDeep(action.payload);

      if (!!setWorkshopBookingsAndSetSeats) {
        const { setBookingWorkshops } = setWorkshopBookingsAndSetSeats;

        if (setBookingWorkshops.length) {
          const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);

          const setWorkshop = (setBookingWorkshops: BookingWorkshop[], bookedTariff: BookingTariff) => {
            setBookingWorkshops.forEach(setBookingWorkshop => {
              const { id, holderUuids } = setBookingWorkshop;
              const bookedTariffWorkshop = bookedTariff.workshops.find(bookedWorkshop => bookedWorkshop.id === id);

              if (bookedTariffWorkshop) {
                holderUuids.forEach(setHolderUuid => bookedTariffWorkshop.holderUuids.push(setHolderUuid));
              } else {
                bookedTariff.workshops.push(setBookingWorkshop);
              }
            });
          };

          setBookingWorkshops.forEach(setBookingWorkshop => {
            const {
              productType,
              ticketTypeId,
              ticketPersonId,
              voucherCode,
              packageNumber,
              packageIndex,
              workshops
            } = setBookingWorkshop;

            cloneStateProductsList.forEach(bookedProductType => {
              if (bookedProductType.productType === productType) {
                if (isBookingProductTypeTariff(bookedProductType)) {
                  const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

                  if (!!bookedTariff) {
                    setWorkshop(workshops, bookedTariff);
                  }
                } else if (isBookingProductTypePackage(bookedProductType)) {
                  if (
                    bookedProductType.packageNumber === packageNumber &&
                    bookedProductType.packageIndex === packageIndex
                  ) {
                    bookedProductType.productTypes.forEach(bookedPackageProductType => {
                      if (isBookingProductTypeTariff(bookedPackageProductType)) {
                        const bookedPackageTariff = findBookedPackageTariff(
                          bookedPackageProductType,
                          ticketTypeId,
                          ticketPersonId,
                          voucherCode,
                          packageNumber,
                          packageIndex
                        );

                        if (!!bookedPackageTariff) {
                          setWorkshop(workshops, bookedPackageTariff);
                        }
                      }
                    });
                  }
                }
              }
            });
          });

          return {
            ...state,
            list: cloneStateProductsList
          };
        }
      }

      return state;
    }

    case BookingActionTypes.REMOVE_WORKSHOP_FROM_BOOKING: {
      const removeWorkshopBookingsAndSetSeats: RemoveWorkshopBookingsAndSetSeats = cloneDeep(action.payload);

      if (!!removeWorkshopBookingsAndSetSeats) {
        const { removeBookingWorkshops } = removeWorkshopBookingsAndSetSeats;

        if (removeBookingWorkshops.length) {
          const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);

          const removeWorkshop = (
            removeBookingWorkshopsAssignedHolders: RemoveBookingWorkshopAssignedHolders[],
            bookedTariff: BookingTariff
          ) => {
            removeBookingWorkshopsAssignedHolders.forEach(removeBookingWorkshopAssignedHolders => {
              const { workshopId, removeHolderUuids } = removeBookingWorkshopAssignedHolders;
              const bookedTariffWorkshopIndex = bookedTariff.workshops.findIndex(
                bookedWorkshop => bookedWorkshop.id === workshopId
              );

              if (bookedTariffWorkshopIndex !== INDEX_NOT_FOUND) {
                const bookedTariffWorkshop = bookedTariff.workshops[bookedTariffWorkshopIndex];

                bookedTariffWorkshop.holderUuids = _.difference(bookedTariffWorkshop.holderUuids, removeHolderUuids);

                if (!bookedTariffWorkshop.holderUuids.length) {
                  bookedTariff.workshops.splice(bookedTariffWorkshopIndex, 1);
                }
              }
            });
          };

          removeBookingWorkshops.forEach(removeBookingWorkshop => {
            const {
              productType,
              ticketTypeId,
              ticketPersonId,
              voucherCode,
              packageNumber,
              packageIndex,
              removeWorkshopsAssignedHolders
            } = removeBookingWorkshop;

            cloneStateProductsList.forEach(bookedProductType => {
              if (bookedProductType.productType === productType) {
                if (isBookingProductTypeTariff(bookedProductType)) {
                  const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

                  if (!!bookedTariff) {
                    removeWorkshop(removeWorkshopsAssignedHolders, bookedTariff);
                  }
                } else if (isBookingProductTypePackage(bookedProductType)) {
                  if (
                    bookedProductType.packageNumber === packageNumber &&
                    bookedProductType.packageIndex === packageIndex
                  ) {
                    bookedProductType.productTypes.forEach(bookedPackageProductType => {
                      if (isBookingProductTypeTariff(bookedPackageProductType)) {
                        const bookedPackageTariff = findBookedPackageTariff(
                          bookedPackageProductType,
                          ticketTypeId,
                          ticketPersonId,
                          voucherCode,
                          packageNumber,
                          packageIndex
                        );

                        if (!!bookedPackageTariff) {
                          removeWorkshop(removeWorkshopsAssignedHolders, bookedPackageTariff);
                        }
                      }
                    });
                  }
                }
              }
            });
          });

          return {
            ...state,
            list: cloneStateProductsList
          };
        }
      }

      return state;
    }

    case BookingActionTypes.SET_CONTINGENT_RESERVATION_DATE_IN_BOOKING: {
      const setContingentReservationDates: SetContingentReservationDate[] = cloneDeep(action.payload);

      if (setContingentReservationDates.length) {
        const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
        const setContingentReservation = (
          bookedTariff: BookingTariff,
          index: number,
          duration: number,
          fromDate: Date
        ) => {
          const bookedTariffContingentReservation = bookedTariff.contingentReservations[index];
          if (bookedTariffContingentReservation) {
            bookedTariffContingentReservation.duration = duration;
            bookedTariffContingentReservation.fromDate = fromDate;
            bookedTariffContingentReservation.isValid = null;
          }
        };

        setContingentReservationDates.forEach(contingentReservationDate => {
          const {
            productType,
            ticketTypeId,
            ticketPersonId,
            voucherCode,
            packageNumber,
            packageIndex,
            duration,
            bookingReservationIndex,
            day
          } = contingentReservationDate;

          cloneStateProductsList.forEach(bookedProductType => {
            if (bookedProductType.productType === productType) {
              if (isBookingProductTypeTariff(bookedProductType)) {
                const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

                if (!!bookedTariff) {
                  setContingentReservation(bookedTariff, bookingReservationIndex, duration, day);
                }
              } else if (isBookingProductTypePackage(bookedProductType)) {
                if (
                  bookedProductType.packageNumber === packageNumber &&
                  bookedProductType.packageIndex === packageIndex
                ) {
                  bookedProductType.productTypes.forEach(bookedPackageProductType => {
                    if (isBookingProductTypeTariff(bookedPackageProductType)) {
                      const bookedPackageTariff = findBookedPackageTariff(
                        bookedPackageProductType,
                        ticketTypeId,
                        ticketPersonId,
                        voucherCode,
                        packageNumber,
                        packageIndex
                      );

                      if (!!bookedPackageTariff) {
                        setContingentReservation(bookedPackageTariff, bookingReservationIndex, duration, day);
                      }
                    }
                  });
                }
              }
            }
          });
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_VALIDATED_CONTINGENT_RESERVATION_IN_BOOKING: {
      const setProductBookingContingentReservations: SetProductBookingContingentReservation[] = cloneDeep(
        action.payload
      );

      if (setProductBookingContingentReservations.length) {
        const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);

        const setValidatedContingentReservation = (
          bookedTariff: BookingTariff,
          contingentReservation: SetBookingContingentReservation
        ) => {
          const { bookingReservationIndex, toDate, isValid } = contingentReservation;
          const bookedTariffContingentReservation = bookedTariff.contingentReservations[bookingReservationIndex];
          if (bookedTariffContingentReservation) {
            bookedTariffContingentReservation.toDate = toDate;
            bookedTariffContingentReservation.isValid = isValid;
          }
        };

        setProductBookingContingentReservations.forEach(productBookingContingentReservation => {
          const {
            productType,
            ticketTypeId,
            ticketPersonId,
            voucherCode,
            packageNumber,
            packageIndex,
            contingentReservations
          } = productBookingContingentReservation;

          cloneStateProductsList.forEach(bookedProductType => {
            if (bookedProductType.productType === productType) {
              if (isBookingProductTypeTariff(bookedProductType)) {
                const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

                if (!!bookedTariff) {
                  contingentReservations.forEach(contingentReservation =>
                    setValidatedContingentReservation(bookedTariff, contingentReservation)
                  );
                }
              } else if (isBookingProductTypePackage(bookedProductType)) {
                if (
                  bookedProductType.packageNumber === packageNumber &&
                  bookedProductType.packageIndex === packageIndex
                ) {
                  bookedProductType.productTypes.forEach(bookedPackageProductType => {
                    if (isBookingProductTypeTariff(bookedPackageProductType)) {
                      const bookedPackageTariff = findBookedPackageTariff(
                        bookedPackageProductType,
                        ticketTypeId,
                        ticketPersonId,
                        voucherCode,
                        packageNumber,
                        packageIndex
                      );

                      if (!!bookedPackageTariff) {
                        contingentReservations.forEach(contingentReservation =>
                          setValidatedContingentReservation(bookedPackageTariff, contingentReservation)
                        );
                      }
                    }
                  });
                }
              }
            }
          });
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_PARKING_RESERVATION_DATE_IN_BOOKING: {
      const setParkingReservationDate: SetParkingReservationDate = cloneDeep(action.payload);

      if (!!setParkingReservationDate) {
        const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
        const {
          productType,
          ticketTypeId,
          ticketPersonId,
          voucherCode,
          packageNumber,
          packageIndex,
          bookingReservationIndex,
          since,
          until
        } = setParkingReservationDate;

        const setParkingReservation = (bookedTariff: BookingTariff, index: number, since: Date, until: Date) => {
          const bookedTariffParkingReservation = bookedTariff.parkingReservations[index];
          if (bookedTariffParkingReservation) {
            bookedTariffParkingReservation.since = since;
            bookedTariffParkingReservation.until = until;
            bookedTariffParkingReservation.isValid = null;
          }
        };

        cloneStateProductsList.forEach(bookedProductType => {
          if (bookedProductType.productType === productType) {
            if (isBookingProductTypeTariff(bookedProductType)) {
              const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

              if (!!bookedTariff) {
                setParkingReservation(bookedTariff, bookingReservationIndex, since, until);
              }
            } else if (isBookingProductTypePackage(bookedProductType)) {
              if (
                bookedProductType.packageNumber === packageNumber &&
                bookedProductType.packageIndex === packageIndex
              ) {
                bookedProductType.productTypes.forEach(bookedPackageProductType => {
                  if (isBookingProductTypeTariff(bookedPackageProductType)) {
                    const bookedPackageTariff = findBookedPackageTariff(
                      bookedPackageProductType,
                      ticketTypeId,
                      ticketPersonId,
                      voucherCode,
                      packageNumber,
                      packageIndex
                    );

                    if (!!bookedPackageTariff) {
                      setParkingReservation(bookedPackageTariff, bookingReservationIndex, since, until);
                    }
                  }
                });
              }
            }
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_VALIDATED_PARKING_RESERVATION_IN_BOOKING: {
      const setProductBookingParkingReservation: SetProductBookingParkingReservation = cloneDeep(action.payload);

      if (!!setProductBookingParkingReservation) {
        const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);
        const {
          productType,
          ticketTypeId,
          ticketPersonId,
          voucherCode,
          packageNumber,
          packageIndex,
          bookingReservationIndex,
          isValid,
          price
        } = setProductBookingParkingReservation;

        const setValidatedParkingReservationInBooking = (
          bookedTariff: BookingTariff,
          isValid: boolean,
          price: number
        ) => {
          const bookedTariffParkingReservation = bookedTariff.parkingReservations[bookingReservationIndex];
          if (bookedTariffParkingReservation) {
            bookedTariffParkingReservation.since = isValid ? bookedTariffParkingReservation.since : null;
            bookedTariffParkingReservation.until = isValid ? bookedTariffParkingReservation.until : null;
            bookedTariffParkingReservation.isValid = isValid;
            bookedTariffParkingReservation.price = price;
          }
        };

        cloneStateProductsList.forEach(bookedProductType => {
          if (bookedProductType.productType === productType) {
            if (isBookingProductTypeTariff(bookedProductType)) {
              const bookedTariff = findBookedTariff(bookedProductType, ticketTypeId, ticketPersonId, voucherCode);

              if (!!bookedTariff) {
                setValidatedParkingReservationInBooking(bookedTariff, isValid, price);

                bookedTariff.price = setTariffPriceToParkingReservationTotalPrice(bookedTariff);
              }
            } else if (isBookingProductTypePackage(bookedProductType)) {
              if (
                bookedProductType.packageNumber === packageNumber &&
                bookedProductType.packageIndex === packageIndex
              ) {
                bookedProductType.productTypes.forEach(bookedPackageProductType => {
                  if (isBookingProductTypeTariff(bookedPackageProductType)) {
                    const bookedPackageTariff = findBookedPackageTariff(
                      bookedPackageProductType,
                      ticketTypeId,
                      ticketPersonId,
                      voucherCode,
                      packageNumber,
                      packageIndex
                    );

                    if (!!bookedPackageTariff) {
                      setValidatedParkingReservationInBooking(bookedPackageTariff, isValid, price);

                      bookedPackageTariff.price = setTariffPriceToParkingReservationTotalPrice(bookedPackageTariff);
                    }
                  }
                });
              }
            }
          }
        });

        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_PRODUCT_HOLDER_UUID: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);

      const {
        holderUuid,
        productType: holderProductType,
        ticketTypeId: holderTicketTypeId,
        ticketPersonId: holderTicketPersonId,
        packageIndex: holderPackageIndex,
        packageNumber: holderPackageNumber
      }: ProductHolderUuid = cloneDeep(action.payload);

      const holderStatus = {
        isHolderUuidAssigned: false,
        isExistingHolderUuidRemoved: false
      };

      cloneStateProductsList.forEach(bookedProductType => {
        if (holderStatus.isHolderUuidAssigned && holderStatus.isExistingHolderUuidRemoved) {
          return;
        }

        if (isBookingProductTypeTariff(bookedProductType)) {
          const { tariffs, productType } = bookedProductType;

          tariffs.forEach(bookedTariff => {
            if (!holderStatus.isExistingHolderUuidRemoved && bookedTariff.holderUuids.includes(holderUuid)) {
              const indexOfTicketHolderUuid = bookedTariff.holderUuids.indexOf(holderUuid);
              bookedTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
              holderStatus.isExistingHolderUuidRemoved = true;
            } else if (
              productType === holderProductType &&
              bookedTariff.ticketTypeId === holderTicketTypeId &&
              bookedTariff.ticketPersonId === holderTicketPersonId
            ) {
              bookedTariff.holderUuids.push(holderUuid);
              holderStatus.isHolderUuidAssigned = true;
            }
          });
        } else if (isBookingProductTypePackage(bookedProductType)) {
          const { productType, packageNumber, packageIndex } = bookedProductType;
          const isTicketHolderAssignmentPackage =
            productType === holderProductType &&
            packageNumber === holderPackageNumber &&
            packageIndex === holderPackageIndex;

          bookedProductType.productTypes.forEach(bookedPackageProductType => {
            if (isBookingProductTypeTariff(bookedPackageProductType)) {
              bookedPackageProductType.tariffs.forEach(bookedPackageTariff => {
                if (!holderStatus.isExistingHolderUuidRemoved && bookedPackageTariff.holderUuids.includes(holderUuid)) {
                  const indexOfTicketHolderUuid = bookedPackageTariff.holderUuids.indexOf(holderUuid);
                  bookedPackageTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);
                  holderStatus.isExistingHolderUuidRemoved = true;
                } else if (
                  isTicketHolderAssignmentPackage &&
                  bookedPackageTariff.ticketTypeId === holderTicketTypeId &&
                  bookedPackageTariff.ticketPersonId === holderTicketPersonId
                ) {
                  bookedPackageTariff.holderUuids.push(holderUuid);
                  holderStatus.isHolderUuidAssigned = true;
                }
              });
            }
          });
        }
      });

      if (holderStatus.isHolderUuidAssigned) {
        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.REMOVE_PRODUCT_HOLDER_UUID: {
      const cloneStateProductsList: BookingProductType[] = cloneDeep(state.list);

      const {
        holderUuid,
        productType: holderProductType,
        ticketTypeId: holderTicketTypeId,
        ticketPersonId: holderTicketPersonId,
        packageIndex: holderPackageIndex,
        packageNumber: holderPackageNumber
      }: ProductHolderUuid = cloneDeep(action.payload);

      const ticketHolderRemoved = cloneStateProductsList.find(bookedProductType => {
        if (isBookingProductTypeTariff(bookedProductType)) {
          const { tariffs, productType } = bookedProductType;

          return !!tariffs.find(bookedTariff => {
            if (
              productType === holderProductType &&
              bookedTariff.holderUuids.includes(holderUuid) &&
              bookedTariff.ticketTypeId === holderTicketTypeId &&
              bookedTariff.ticketPersonId === holderTicketPersonId
            ) {
              const indexOfTicketHolderUuid = bookedTariff.holderUuids.indexOf(holderUuid);
              bookedTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);

              return !!bookedTariff;
            }
          });
        } else if (isBookingProductTypePackage(bookedProductType)) {
          const { productType, packageNumber, packageIndex } = bookedProductType;

          if (
            productType === holderProductType &&
            packageNumber === holderPackageNumber &&
            packageIndex === holderPackageIndex
          ) {
            return !!bookedProductType.productTypes.find(bookedPackageProductType => {
              if (isBookingProductTypeTariff(bookedPackageProductType)) {
                return !!bookedPackageProductType.tariffs.find(bookedPackageTariff => {
                  if (
                    bookedPackageTariff.holderUuids.includes(holderUuid) &&
                    bookedPackageTariff.ticketTypeId === holderTicketTypeId &&
                    bookedPackageTariff.ticketPersonId === holderTicketPersonId
                  ) {
                    const indexOfTicketHolderUuid = bookedPackageTariff.holderUuids.indexOf(holderUuid);
                    bookedPackageTariff.holderUuids.splice(indexOfTicketHolderUuid, 1);

                    return !!bookedPackageTariff;
                  }
                });
              }
            });
          }
        }
      });

      if (!!ticketHolderRemoved) {
        return {
          ...state,
          list: cloneStateProductsList
        };
      }

      return state;
    }

    case BookingActionTypes.SET_PRODUCT_COUNTDOWN: {
      const expiryTimestamp: number = action.payload && getCountdownTimestamp(action.payload);

      return {
        ...state,
        countdown: {
          ...state.countdown,
          product: expiryTimestamp
        }
      };
    }

    case BookingActionTypes.REMOVE_COUNTDOWNS: {
      return {
        ...state,
        countdown: cloneDeep(initialState.countdown)
      };
    }

    case BookingActionTypes.SET_VOUCHER_COUNTDOWN: {
      const voucherCountdowns: VoucherCountdown[] = cloneDeep(action.payload);
      const currentAddedVoucherIsAnonymous: boolean = voucherCountdowns.some(
        voucherCountdown => voucherCountdown.isAnonymous
      );
      const stateHasAnonymousVouchers: boolean = state.countdown.voucher.some(
        voucherCountdown => voucherCountdown.isAnonymous
      );

      const vouchersCountdown: VoucherCountdown[] =
        !stateHasAnonymousVouchers && currentAddedVoucherIsAnonymous
          ? voucherCountdowns
          : [
              ...state.countdown.voucher,
              ...voucherCountdowns.filter(
                voucherCountdown =>
                  !state.countdown.voucher.some(
                    stateVoucher => stateVoucher.voucherCode === voucherCountdown.voucherCode
                  )
              )
            ];

      const productCountdown: number = voucherCountdowns[0].expiryTimestamp;

      return {
        ...state,
        countdown: {
          ...state.countdown,
          voucher: vouchersCountdown,
          product: productCountdown
        }
      };
    }

    case BookingActionTypes.REMOVE_VOUCHER_COUNTDOWN: {
      const voucherCountdowns: VoucherCountdown[] = action.payload.voucherCountdowns;
      const tariffReleaseInMinutes: number = action.payload.tariffReleaseInMinutes;
      let voucherExpired: boolean = false;
      let newProductCountdownState: number = null;

      const newVoucherCountdownState: VoucherCountdown[] = state.countdown.voucher.filter(
        stateVoucherCountdown =>
          !voucherCountdowns.some(voucherCountdown => {
            const isSameVoucherCode: boolean = voucherCountdown.voucherCode === stateVoucherCountdown.voucherCode;

            if (!voucherExpired && isSameVoucherCode) {
              voucherExpired = isVoucherExpired(stateVoucherCountdown);
            }

            return isSameVoucherCode;
          })
      );

      if (!!state.list.length) {
        if (voucherExpired) {
          newProductCountdownState = state.countdown.product;
        } else {
          newProductCountdownState = getCountdownTimestamp(tariffReleaseInMinutes);
        }
      }

      return {
        ...state,
        countdown: {
          ...state.countdown,
          voucher: cloneDeep(newVoucherCountdownState),
          product: newProductCountdownState
        }
      };
    }

    case BookingActionTypes.SET_IS_ANONYMOUS_PRODUCT_SENT_TO_API: {
      const isAnonymousProductSentToAPI = action.payload;

      return {
        ...state,
        isAnonymousProductSentToAPI
      };
    }

    case BookingActionTypes.SET_CLAIMED_TICKET_HASH: {
      const claimedTicketHash = action.payload;

      return {
        ...state,
        claimedTicketHash
      };
    }

    case BookingActionTypes.SET_CLAIMED_TICKET_HASH_VALID: {
      const claimedTicketHashValid = action.payload;

      return {
        ...state,
        claimedTicketHashValid
      };
    }
    case BookingActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_BOOKING_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
