import * as helperActions from './helper.actions';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { HelperService } from './helper.service';

export const HELPER_DEBOUNCE = new InjectionToken<number>('Helper Debounce');

@Injectable()
export class HelperEffect {
  @Effect()
  loadAllCountries$: Observable<Action> = this.actions$.pipe(
    ofType<helperActions.GetAllCountriesAction>(
      helperActions.ActionTypes.GET_ALL_COUNTRIES
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.helperService.getListOfCountries().pipe(
        map(
          (allCountries: string[]) =>
            new helperActions.AddAllCountriesToListAction(allCountries)
        ),
        catchError(() => of(new helperActions.AddAllCountriesToListAction([])))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private helperService: HelperService,
    @Optional()
    @Inject(HELPER_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
