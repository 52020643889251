var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadProductService } from '@products/services/load-product.service';
import { ActionTypes as BookingActionTypes } from '@store/products/booking/booking.actions';
import { ActionTypes as ProductSelectionActionTypes } from '@store/products/product-selection/product-selection.actions';
import { ActionTypes as ProductActionTypes } from '@store/products/product.actions';
import { SetInitialPackageIndexStatus, SetIsPackageLoadingStatus, SetPackageIndexStatus } from '@store/products/status/package/package.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
var PackageStatusEffect = /** @class */ (function () {
    function PackageStatusEffect(actions$, store, loadProductService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.loadProductService = loadProductService;
        this.setInitialPackageIndexStatus$ = this.actions$.pipe(ofType(ProductSelectionActionTypes.SET_INITIAL_PRODUCT_SELECTION_LIST, ProductSelectionActionTypes.SET_PREFERRED_PRODUCT_SELECTION_LIST), map(function () {
            var latestAddedInitialPackageIndex = _this.loadProductService.getAndResetLastAddedInitialPackageIndex();
            return new SetInitialPackageIndexStatus(latestAddedInitialPackageIndex);
        }));
        this.setPackageIndexStatus$ = this.actions$.pipe(ofType(BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST), map(function (_a) {
            var payload = _a.payload;
            return new SetPackageIndexStatus(payload);
        }));
        this.isPackageLoadingStatus$ = this.actions$.pipe(ofType(ProductActionTypes.SET_PACKAGE_BOOKING, ProductActionTypes.REMOVE_PACKAGE_BOOKING, BookingActionTypes.SET_PACKAGE_IN_BOOKING_LIST, BookingActionTypes.REMOVE_PACKAGE_FROM_BOOKING_LIST), map(function (action) {
            var productActionTypes = [
                ProductActionTypes.SET_PACKAGE_BOOKING,
                ProductActionTypes.REMOVE_PACKAGE_BOOKING
            ];
            var isPackageLoading = productActionTypes.includes(action.type);
            return new SetIsPackageLoadingStatus(isPackageLoading);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PackageStatusEffect.prototype, "setInitialPackageIndexStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PackageStatusEffect.prototype, "setPackageIndexStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PackageStatusEffect.prototype, "isPackageLoadingStatus$", void 0);
    return PackageStatusEffect;
}());
export { PackageStatusEffect };
