<ng-container *ngIf="!productSelectionViewModel.isSelfRegistration">
  <div
    class="section-header"
    *ngIf="productGroup.groupName"
    [ngClass]="{ 'section-collapsed': productGroup.groupName && !isExpanded }"
    (click)="toggleGroup()"
  >
    <div class="section-header-text">
      <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
      <h2 *ngIf="productSelectionViewModel.isTranslationLoaded" class="section-header-name">
        {{ productGroup.groupName | translate | hideTranslation }}
      </h2>
    </div>
    <div class="section-icons">
      <svg class="icon" *ngIf="isExpanded">
        <use xlink:href="assets/defs.svg#icon-arrow-down"></use>
      </svg>
      <svg class="icon" *ngIf="!isExpanded">
        <use xlink:href="assets/defs.svg#icon-arrow-right2"></use>
      </svg>
    </div>
  </div>

  <div class="section-description" *ngIf="productGroup.groupDescription | translate" (click)="toggleGroup()">
    <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
    <span
      *ngIf="productSelectionViewModel.isTranslationLoaded"
      [appRichText]="productGroup.groupDescription | translate | hideTranslation"
    ></span>
  </div>

  <div *ngIf="!productGroup.groupName || isExpanded">
    <ng-container *ngFor="let product of productGroup.products; trackBy: productGroupProductsTrackBy">
      <div
        *ngIf="
          (product.productType === ProductType.Tariff && product.tariffType.isVisible) ||
          product.productType === ProductType.Package
        "
      >
        <app-product
          [selectedExhibition]="selectedExhibition"
          [exhibitionSettings]="exhibitionSettings"
          [productSelectionViewModel]="productSelectionViewModel"
          [productsStatusViewModel]="productsStatusViewModel"
          [productGroup]="productGroup"
          [product]="product"
        ></app-product>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="productSelectionViewModel.isSelfRegistration">
  <ng-container *ngFor="let product of productGroup.products; trackBy: productGroupProductsTrackBy">
    <div *ngIf="product.productType === ProductType.Tariff && product.tariffType.isVisible">
      <app-product
        [selectedExhibition]="selectedExhibition"
        [exhibitionSettings]="exhibitionSettings"
        [productSelectionViewModel]="productSelectionViewModel"
        [productsStatusViewModel]="productsStatusViewModel"
        [productGroup]="productGroup"
        [product]="product"
      ></app-product>
    </div>
  </ng-container>
</ng-container>
