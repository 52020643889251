<div class="page-padding-wrapper" *ngIf="loading">
  <app-loader></app-loader>
</div>
<div class="page-padding-wrapper" *ngIf="serverError && !loading">
  <span class="server-error" data-translation="recipe.save-order.error">
    {{ 'recipe.save-order.error' | translate | hideTranslation }}
  </span>
  <div class="reset-button">
    <button class="button" (click)="resetShop()">
      <span data-translation="recipe.restart-shop">
        {{ 'recipe.restart-shop' | translate | hideTranslation }}
      </span>
    </button>
  </div>
</div>
<div class="page-padding-wrapper" *ngIf="!serverError && !loading && !!price">
  <div appAdjustSideHeadline>
    <div class="section-headline">
      <h2 data-translation="personalize.buyer-info.section-title">
        {{ 'personalize.buyer-info.section-title' | translate | hideTranslation }}
      </h2>
      <div class="section-headline-side" data-translation="personalize.buyer-info.headline">
        {{ 'personalize.buyer-info.headline' | translate | hideTranslation }}
      </div>
    </div>
    <div class="section-body">
      <div class="black heavy-text">
        <span data-translation="recipe.congratulation-text.voucher">
          {{ 'recipe.congratulation-text.voucher' | translate | hideTranslation }}
        </span>
        <br />
        <span data-translation="recipe.succesfull-register.voucher">
          {{ 'recipe.succesfull-register.voucher' | translate | hideTranslation }}
        </span>
        <br />
        <div data-translation="recipe.go-to-gate-text" *ngIf="printed">
          {{ 'recipe.go-to-gate-text' | translate | hideTranslation }}
          <!-- <br>Please take the voucher from the print next to you and go directly to the gate to reive your personlized
                    badge. -->
        </div>
        <div data-translation="recipe.printed.voucher" *ngIf="printed === null">
          <br />
          {{ 'recipe.printed.voucher' | translate | hideTranslation }}
          <!-- Voucher is beeing printed -->
        </div>
        <div data-translation="recipe.printed.error" *ngIf="printed === false">
          <br />
          {{ 'recipe.printed.error' | translate | hideTranslation }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-padding-wrapper" *ngIf="!serverError && !loading && !price">
  <div appAdjustSideHeadline>
    <div class="section-headline">
      <h2 data-translation="personalize.buyer-info.section-title">
        {{ 'personalize.buyer-info.section-title' | translate | hideTranslation }}
      </h2>
      <div class="section-headline-side" data-translation="personalize.buyer-info.headline">
        {{ 'personalize.buyer-info.headline' | translate | hideTranslation }}
      </div>
    </div>
    <div class="section-body">
      <div class="black heavy-text">
        <span data-translation="recipe.congratulation-text.ticket">
          {{ 'recipe.congratulation-text.ticket' | translate | hideTranslation }}</span
        >
        <!-- Congratulation!! -->

        <br />
        <span data-translation="recipe.succesfull-register.ticket">
          {{ 'recipe.succesfull-register.ticket' | translate | hideTranslation }}
        </span>
        <!-- You successfully registered your ticket! -->
        <br />
        <div data-translation="recipe.go-to-cashier-text" *ngIf="printed">
          <br />
          {{ 'recipe.go-to-cashier-text' | translate | hideTranslation }}
          <!-- Please take the ticket from the print next to you and go to the cashier to pay your ticket. -->
        </div>
        <div data-translation="recipe.printed.ticket" *ngIf="printed === null">
          <br />
          {{ 'recipe.printed.ticket' | translate | hideTranslation }}
          <!-- Ticket is beeing printed -->
        </div>
        <div data-translation="recipe.printed.error" *ngIf="printed === false">
          <br />{{ 'recipe.printed.error' | translate | hideTranslation }}
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!serverError && !loading">
  <div class="img-animation-wrap">
    <img *ngIf="!!price" src="assets/images/GoToPay_GIF.gif" alt="Go to pay" />
    <img *ngIf="!price" src="assets/images/GoToGate_GIF.gif" alt="Go to gate" />
  </div>
  <app-self-reg-timeout (resetShop)="resetShop(false)"></app-self-reg-timeout>
  <div class="reset-button">
    <button class="button" (click)="resetShop(false)">
      <span data-translation="recipe.restart-shop"> {{ 'recipe.restart-shop' | translate | hideTranslation }}</span>
    </button>
  </div>
</div>
