<div *ngIf="packageType">
  <div class="package-type">
    <div class="package-type-header">
      <!-- <div class="package-header__info" *ngIf="packageType.info | translate">
            <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
            <span *ngIf="productSelectionViewModel.isTranslationLoaded" [appRichText]="packageType.info | translate"></span>
          </div> -->
      <div class="package-type-header__package-name">
        <h2
          [ngClass]="{
            'package-type-header__package-name--translation-loading': !productSelectionViewModel.isTranslationLoaded,
            'ng-custom-invalid': !bookingStatusViewModel.isBookedProductsCountValid
          }"
          innerText="{{
            (productSelectionViewModel.isTranslationLoaded ? packageType.name : 'translations.loading.package-name')
              | translate
              | hideTranslation
          }}"
        ></h2>
        <app-skeleton-loader *ngIf="!productSelectionViewModel.isTranslationLoaded"></app-skeleton-loader>
      </div>
      <app-package-counter
        class="package-type-header__counter"
        *ngIf="productSelectionViewModel.isTranslationLoaded"
        [packageValidationViewModel]="packageValidationViewModel"
        [isPackageLoading]="packageStatusViewModel.isPackageLoading"
        [isWorkshopDetailModalOpen]="workshopStatusViewModel.isWorkshopDetailModalOpen"
        (updatePackageTypeCountEvent)="validateCount($event)"
      ></app-package-counter>
    </div>
    <div
      *ngIf="packageValidationViewModel.packageLimitWarningMessage"
      class="ticket-selection-warning package-type-header__warning"
      [attr.data-translation]="packageValidationViewModel.packageLimitWarningMessage"
    >
      {{ packageValidationViewModel.packageLimitWarningMessage | translate | hideTranslation }}
    </div>
    <div class="package-type-packages" *ngFor="let package of packageType.packages; trackBy: packagesTrackBy">
      <app-package
        [exhibitionSettings]="exhibitionSettings"
        [productSelectionViewModel]="productSelectionViewModel"
        [tariffStatusViewModel]="tariffStatusViewModel"
        [workshopStatusViewModel]="workshopStatusViewModel"
        [bookingStatusViewModel]="bookingStatusViewModel"
        [productGroup]="productGroup"
        [packageType]="packageType"
        [package]="package"
      ></app-package>
    </div>
    <div class="package-type__subtotal" *ngIf="productSelectionViewModel.isTranslationLoaded">
      <div></div>
      <div>
        <span class="package-type__subtotal-text" data-translation="packages.total">
          {{ 'packages.total' | translate | hideTranslation }}
        </span>
        <span class="package-type__subtotal-price">
          {{
            bookingStatusViewModel.bookedPackagePricesGroupedByPackageNumber[packageType.packageNumber] || 0
              | formatPrice: exhibitionSettings.currencySettings
          }}
        </span>
      </div>
    </div>
  </div>
</div>
