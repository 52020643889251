import { AfterViewInit, Component, OnDestroy, OnInit, Renderer } from '@angular/core';
import { State, getExhibitionSettings, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { WorkshopAvailableSeats } from '@products/models/workshop-status.model';
import {
  Workshop,
  WorkshopByDate,
  WorkshopDayBorderHours,
  WorkshopDetailViewModel,
  WorkshopProduct,
  WorkshopProductList,
  WorkshopRoom,
  WorkshopTimelineItem,
  WorkshopsToday
} from '@products/models/workshop.model';
import { LoadProductService } from '@products/services/load-product.service';
import { WorkshopValidationService } from '@products/services/workshop-validation.service';
import { WorkshopService } from '@products/services/workshop.service';
import { AppConstants } from '@shared/app-constants';
import { FormsService } from '@shared/forms/forms.service';
import { ExhibitionSettingModel } from '@shared/services-with-reducers/customization/customization.interfaces';
import { HelperService } from '@shared/services-with-reducers/helpers/helper.service';
import {
  getIsWorkshopLoading,
  getWorkshopsAvailableSeatsGroupedByWorkshopId
} from '@shared/services-with-reducers/products/status/workshop/workshop.selectors';
import { WindowSizeService } from '@shared/window-size/window-size.service';
import { environment } from '@src/environments/environment';
import { getWorkshopProductList } from '@store/products/workshop/workshop.selectors';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  combineLatest as observableCombineLatest,
  fromEvent as observableFromEvent,
  of as observableOf
} from 'rxjs';
import { debounceTime, filter, first, map, throttleTime } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-workshop',
  templateUrl: './web-shop-workshop.component.html',
  styleUrls: ['./web-shop-workshop.component.scss']
})
export class WebShopWorkshopComponent implements OnInit, OnDestroy, AfterViewInit {
  origin: string;
  workshopsByDates: WorkshopByDate = {};
  workshopsByDatesArray: WorkshopsToday[] = [];
  workshopsLoaded = false;
  hourHeighInPixels = 160;
  hourMinHeightInPixels = 120;
  viewport: number;
  breakpoints: {};
  mediumBreakpoint: number;
  result;
  activeWorkshopId = null;
  ticketsWithHolders = new BehaviorSubject([]);
  environment = environment;
  workshops: WorkshopProductList;
  exhibitionSettings: ExhibitionSettingModel;
  workshopsOverlap: boolean;
  stepsFormsActionName = ['workshop', 'validation'];
  isWorkshopMandatory: boolean = false;
  limitWorkshopPerTicket: number = 1;
  isWorkshopLimitPerTicketReached: boolean = false;
  workshopMandatoryForZeroPriceTickets: boolean = false;
  isWorkshopSelected: boolean = false;
  hideDateAndTime: boolean = false;
  toggleDetailWindow: boolean = false;
  toggledWorkshop: any;
  isMobile: boolean = false;
  elementHasEventListener: boolean = false;
  roomWidth: number = 220;
  showFakeScrollBar: boolean = false;
  settings: ExhibitionSettingModel;
  showAmountOfAvailableWorkshops: boolean = true;
  hideWorkshopDate: boolean = true;
  enableWorkshopSelectionOverlapping: boolean = false;
  workshopDetailViewModel$: Observable<WorkshopDetailViewModel>;
  workshopStatusViewModel$: Observable<{
    availableSeats: WorkshopAvailableSeats;
    isWorkshopLoading: boolean;
  }>;
  private scrollLeft: Subject<{
    fakeScrollLeft: number;
    el: HTMLElement;
  }> = new Subject();
  private readonly _subscriptions = new Subscription();

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
    private windowSizeService: WindowSizeService,
    private helperService: HelperService,
    private renderer: Renderer,
    private workshopService: WorkshopService,
    private workshopValidationService: WorkshopValidationService,
    private loadProductsService: LoadProductService
  ) {
    this.origin = environment.protocol + environment.origin;
    this.origin = this.origin || window.location.origin;
  }

  ngOnInit() {
    this.workshopsLoaded = false;

    this.workshopStatusViewModel$ = combineLatest([
      this.store.pipe(select(getWorkshopsAvailableSeatsGroupedByWorkshopId)),
      this.store.pipe(select(getIsWorkshopLoading))
    ]).pipe(
      map(([workshopsAvailableSeatsGroupedByWorkshopId, isWorkshopLoading]) => ({
        availableSeats: workshopsAvailableSeatsGroupedByWorkshopId,
        isWorkshopLoading
      }))
    );

    this._subscriptions.add(
      this.store
        .pipe(
          select(getExhibitionSettings),
          filter(data => !!data)
        )
        .subscribe(settings => {
          this.settings = settings;
          this.enableWorkshopSelectionOverlapping = settings.enableWorkshopSelectionOverlapping;
          this.showAmountOfAvailableWorkshops = settings.showAmountOfAvailableWorkshops;
          this.hideWorkshopDate = settings.hideWorkshopDate;
        })
    );

    this.scrollLeft.pipe(debounceTime(100)).subscribe(scroll => {
      scroll.el.scrollLeft = scroll.fakeScrollLeft;
    });
    //this.formsService.setFormValidity(true, null, ['workshop', 'validation']);
    this.formsService.setStepValid(['workshop', 'validation']);

    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      const resize = observableFromEvent(window, 'resize');
      this.result = resize.pipe(throttleTime(100));
    } else {
      this.result = observableOf(null);
    }

    /**
     * CREATE THE RIGHT STRUCTURE OF DATA TO BE SHOWN
     **/

    this._subscriptions.add(
      this.store.pipe(select(getSelectedExhibitionId)).subscribe(eventId => {
        this.loadProductsService.loadWorkshopProducts(eventId);

        if (!!eventId) {
          if (eventId.toString() === '99') {
            this.hideDateAndTime = true;
          }
        }

        this.store
          .pipe(
            select(getExhibitionSettings),
            first(data => !!data)
          )
          .subscribe(settings => {
            this.isWorkshopMandatory = settings.isWorkshopsSelectionMandatory;
            this.limitWorkshopPerTicket = settings.workshopsPerTicket;
            this.workshopMandatoryForZeroPriceTickets = settings.workshopMandatoryForZeroPriceTickets;
          });

        observableCombineLatest([
          this.store.pipe(select(getWorkshopProductList)),
          this.workshopService.getBookedProductsAllowedWorkshopIds$()
        ])
          .pipe(first(data => !!data[0].length && !!data[1]))
          .subscribe((data: [WorkshopProductList, Array<number>]) => {
            const [workshopProductList, bookedProductsAllowedWorkshopIds] = data;
            this.workshops = workshopProductList;

            const allSchedulesByTime: WorkshopProductList = this.workshops.sort((a, b) => {
              return new Date(a.start).getTime() - new Date(b.start).getTime();
            });

            let workshopsByDates: WorkshopByDate = {};

            allSchedulesByTime.forEach(workshop => {
              workshop.disabled = !bookedProductsAllowedWorkshopIds.includes(workshop.workshopId);

              // check if the workshop starts befor it ends (avoid bugs in BE)
              if (new Date(workshop.start).getTime() < new Date(workshop.end).getTime()) {
                //
                const day = new Date(workshop.date).toLocaleDateString('en-US');

                // this dayString is used as key of workshopsByDates variable, also serves for showing date in template
                workshopsByDates = this.addWorkshopToDay(workshopsByDates, day, workshop);
              }
            });

            for (const dayKey in workshopsByDates) {
              if (workshopsByDates.hasOwnProperty(dayKey)) {
                const day = workshopsByDates[dayKey];

                day.todayWorkshops = day.todayWorkshops.filter(workshop => {
                  return (
                    !workshop.disabled || (workshop.disabled && !this.settings.showWorkshopsForSelectedTicketsOnly)
                  );
                });

                const dayBorderHours: WorkshopDayBorderHours = this.getDayBorderHours(day.todayWorkshops);

                let workshopTimeLineItems: WorkshopTimelineItem[] = [];

                for (let hour = dayBorderHours.earliest; hour < dayBorderHours.latest; hour++) {
                  if (this.isAnyWorkshopInHour(hour, day)) {
                    workshopTimeLineItems.push({
                      hourStart: hour,
                      hourEnd: null,
                      hourHeight: this.hourHeighInPixels,
                      displayEndTime: false
                    });
                  } else {
                    let last = workshopTimeLineItems.slice(-1)[0];
                    if (!last.hourEnd) {
                      workshopTimeLineItems.push({
                        hourStart: hour,
                        hourEnd: hour,
                        hourHeight: this.hourHeighInPixels,
                        displayEndTime: false
                      });
                    } else {
                      last.hourEnd = last.hourEnd + 1;
                      last.displayEndTime = true;
                    }
                  }
                }

                day.timeLineList = workshopTimeLineItems;

                // + 60 for header
                day.timeLineWrapHeight = day.timeLineList.reduce((wrapHeight, timeLineItem) => {
                  return wrapHeight + timeLineItem.hourHeight;
                }, 60);

                // add position styles to workshops
                day.todayWorkshops = day.todayWorkshops.map(
                  (workshopProduct): WorkshopProduct => {
                    const positionStyles = this.positionEvent(
                      dayBorderHours.earliest,
                      workshopProduct.start,
                      workshopProduct.end,
                      day.timeLineList
                    );

                    return {
                      ...workshopProduct,
                      styles: positionStyles
                    };
                  }
                );

                // workshops can overlap this will reposition workshops and adjust the hour line
                this.repositionOverlappingWorkshops(day);

                day.rooms = day.todayWorkshops
                  .map(workshop => workshop.roomId) // map to roomIds
                  .filter((value, index, self) => {
                    // returns list of unique roomIds
                    return self.indexOf(value) === index;
                  })
                  .map(
                    (roomId): WorkshopRoom => {
                      const roomName = this.getRoomName(workshopProductList, roomId);
                      return {
                        roomId: roomId,
                        roomName: roomName,
                        roomSortOrder: day.todayWorkshops.find(workshop => workshop.roomId === roomId).roomSortOrder,
                        workshops: day.todayWorkshops.filter(workshop => workshop.roomId === roomId)
                      };
                    }
                  )
                  .filter(room => {
                    return room.workshops.length > 0;
                  })
                  // first sort by number
                  .sort((a, b) => {
                    if (a.roomName.toLowerCase() < b.roomName.toLowerCase()) return -1;
                    if (a.roomName.toLowerCase() > b.roomName.toLowerCase()) return 1;
                    return 0;
                  })
                  // adjust sorting by sort order
                  .sort((a, b) => {
                    return a.roomSortOrder - b.roomSortOrder;
                  });
              }
            }

            this.workshopsByDates = workshopsByDates;
            this.workshopsByDatesArray = [];

            Object.keys(workshopsByDates).forEach((key, index) => {
              workshopsByDates[key].expanded = true;
              this.workshopsByDatesArray.push(workshopsByDates[key]);
            });

            this.workshopsByDatesArray = this.workshopsByDatesArray
              .filter(p => p.todayWorkshops.length > 0)
              .sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });
            this.workshopsLoaded = true;
            this.setWorkshopsSidePosition(null);
          });
      })
    );

    this.breakpoints = AppConstants.BREAKPOINTS;
    this.mediumBreakpoint = this.breakpoints['sm'];
    this.result.startWith(null).subscribe(() => {
      this.viewport = this.windowSizeService.viewportWidth();
    });

    /**
     * Handle visitors and their subscription to workshops
     */
    this._subscriptions.add(
      this.workshopService
        .getBookedProductSelectionWorkshopTariffs$()
        .subscribe(bookedProductSelectionWorkshopTariffs => {
          let haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = !this.isWorkshopMandatory;
          if (this.isWorkshopMandatory) {
            haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = this.workshopValidationService.haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
              bookedProductSelectionWorkshopTariffs,
              this.limitWorkshopPerTicket
            );
          }

          let haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = !this
            .workshopMandatoryForZeroPriceTickets;
          if (this.workshopMandatoryForZeroPriceTickets) {
            haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit = this.workshopValidationService.haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit(
              bookedProductSelectionWorkshopTariffs,
              this.limitWorkshopPerTicket
            );
          }

          if (this.isWorkshopMandatory || this.workshopMandatoryForZeroPriceTickets) {
            const validationMessage = !haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit
              ? 'workshop.not-selected'
              : !haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit
              ? 'workshop.not-zero-selected'
              : null;

            this.setValidation(
              haveAllBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit &&
                haveAllZeroPricedBookedTariffWorkshopsAssignedHoldersValidWorkshopsPerTariffLimit,
              validationMessage
            );
          }
        })
    );
  }

  ngAfterViewInit() {
    Object.keys(this.workshopsByDates).forEach((day, index) => {
      this.scrollHorizontally(index);
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getRoomName(workshops: WorkshopProductList, roomId: number): string {
    return workshops.find(workshop => {
      return workshop.roomId === roomId;
    }).roomName;
  }

  addWorkshopToDay(workshops: WorkshopByDate, date: string, workshopProduct: WorkshopProduct): WorkshopByDate {
    if (workshops.hasOwnProperty(date)) {
      workshops[date].todayWorkshops.push(workshopProduct);
    } else {
      workshops[date] = {
        date: new Date(workshopProduct.date).toISOString(),
        todayWorkshops: [workshopProduct],
        timeLineWrapHeight: 0,
        timeLineList: [],
        rooms: []
      };
    }
    return workshops;
  }

  getDayBorderHours = (workshops): WorkshopDayBorderHours => {
    return workshops
      .map(workshop => {
        const start = new Date(workshop.start);
        const end = new Date(workshop.end);

        return {
          start: start.getHours(),
          end: end.getHours() + 1 // +1 so we cover also hour only expressed by minutes
        } as any;
      })
      .reduce(
        (acc, workshop) => {
          return {
            earliest: acc.earliest < workshop.start ? acc.earliest : workshop.start,
            latest: acc.latest > workshop.end ? acc.latest : workshop.end
          };
        },
        {
          earliest: 24,
          latest: 0
        }
      );
  };

  isAnyWorkshopInHour(hour: number, workshopsToday: WorkshopsToday): boolean {
    return workshopsToday.todayWorkshops.some(workshopToday => {
      const start = new Date(workshopToday.start);
      const end = new Date(workshopToday.end);

      return start.getHours() <= hour && end.getHours() >= hour;
    });
  }

  positionEvent(earliestWorkshop, workshopStart, workshopEnd, timelineList: WorkshopTimelineItem[]) {
    const earliestMinutes = earliestWorkshop * 60;
    const start = new Date(workshopStart);
    const end = new Date(workshopEnd);

    const workshopStartMinutes = start.getHours() * 60 + start.getMinutes();
    const workshopEndMinutes = end.getHours() * 60 + end.getMinutes();

    const workshopGridGap = 14;

    let workshopTopOffset = this.getWorkshopTopOffset(
      start.getHours(),
      workshopStartMinutes,
      earliestMinutes,
      timelineList
    );
    let workshopHeightOffset = this.getRelativeHourHeight(workshopEndMinutes - workshopStartMinutes);

    if (workshopHeightOffset < this.hourMinHeightInPixels) {
      workshopTopOffset = workshopTopOffset;

      // if (start.getHours() == timelineList[0].hour && ) {
      //   workshopTopOffset = workshopTopOffset + 400;
      // }
    }

    if (workshopHeightOffset < this.hourMinHeightInPixels) {
      workshopHeightOffset = this.hourMinHeightInPixels + workshopGridGap;
    }
    // this.hourMinHeightInPixels;

    const top = 60 + workshopTopOffset + workshopGridGap / 2 + 'px';
    const height = workshopHeightOffset - workshopGridGap + 'px';

    return { top, minHeight: height };
  }

  repositionOverlappingWorkshops(dayData: WorkshopsToday): WorkshopsToday {
    const gap = 26;
    let rerun = true;

    while (rerun) {
      rerun = false;

      let additionalOffset = 0;
      let timesOfIncrease = 0;
      let overlappingHour = null;
      let overlappingInRoom = null;
      for (let i = 0; i < dayData.todayWorkshops.length; i++) {
        let prevWorkshop = dayData.todayWorkshops[i];
        for (let j = 0; j < dayData.todayWorkshops.length; j++) {
          let currWorkshop = dayData.todayWorkshops[j];

          const inSameRoom = prevWorkshop.roomId == currWorkshop.roomId;
          const notSameWorkshop = prevWorkshop.workshopId != currWorkshop.workshopId;
          const prevWorkshopStyles = prevWorkshop.styles;
          const previousWorkshopTopPosition = parseInt(prevWorkshopStyles.top);
          const prevWorkshopBottomPosition = previousWorkshopTopPosition + parseInt(prevWorkshopStyles.minHeight);
          const currWorkshopStyles = currWorkshop.styles;
          const currWorkshopTopPosition = parseInt(currWorkshopStyles.top);
          const currWorkshopBottomPosition = currWorkshopTopPosition + parseInt(currWorkshopStyles.minHeight);
          const workshopOverlap =
            prevWorkshopBottomPosition > currWorkshopTopPosition &&
            previousWorkshopTopPosition < currWorkshopBottomPosition;

          if (notSameWorkshop && inSameRoom && workshopOverlap) {
            const overlapArea = prevWorkshopBottomPosition - currWorkshopTopPosition;

            if (timesOfIncrease >= 1) {
              currWorkshop.styles.top = `${currWorkshopTopPosition + additionalOffset + gap + overlapArea + 1}px`;
            } else {
              currWorkshop.styles.top = `${currWorkshopTopPosition + overlapArea + 1}px`;
            }

            currWorkshop.styles.top = `${currWorkshopTopPosition + overlapArea + 1}px`;

            additionalOffset = parseInt(currWorkshopStyles.minHeight) - gap;

            overlappingHour = new Date(currWorkshop.start).getHours();
            overlappingInRoom = currWorkshop.roomId;
            timesOfIncrease += 1;
          }
        }

        if (additionalOffset) {
          additionalOffset = additionalOffset / timesOfIncrease;
          dayData.todayWorkshops.forEach(currWorkshop => {
            const inSameRoom = overlappingInRoom === currWorkshop.roomId;
            const notSameWorkshop = prevWorkshop.workshopId != currWorkshop.workshopId;
            const workshopHour = new Date(currWorkshop.start).getHours();
            const sameHour = overlappingHour == workshopHour;
            const nextWorkshopHour = workshopHour > overlappingHour;

            const prevDuration = Number(new Date(prevWorkshop.end)) - Number(new Date(prevWorkshop.start));
            const currDuration = Number(new Date(currWorkshop.end)) - Number(new Date(currWorkshop.start));

            if (notSameWorkshop && additionalOffset && nextWorkshopHour) {
              currWorkshop.styles.top = `${parseInt(currWorkshop.styles.top) + additionalOffset + 1}px`;
            }

            if (notSameWorkshop && !inSameRoom && additionalOffset && sameHour && prevDuration <= currDuration) {
              currWorkshop.styles.minHeight = `${parseInt(currWorkshop.styles.minHeight) + additionalOffset + 1}px`;
            }

            const currentWorkshopEnd = new Date(currWorkshop.end);
            const exceedsOverlappingHour = currentWorkshopEnd.getHours() > overlappingHour;

            if (notSameWorkshop && additionalOffset && sameHour && exceedsOverlappingHour) {
              const exceedingMinutes = currentWorkshopEnd.getMinutes();
              const exceedingDelta = (160 * exceedingMinutes) / 60;

              additionalOffset = additionalOffset - exceedingDelta;
            }
          });

          dayData.timeLineList.find((hourLine, index) => {
            if (hourLine.hourStart === overlappingHour) {
              hourLine.hourHeight = hourLine.hourHeight + additionalOffset;
              dayData.timeLineList[index] = hourLine;

              return true;
            }
          });

          dayData.timeLineWrapHeight = dayData.timeLineWrapHeight + additionalOffset;
          rerun = true;
          break;
        }
      }
    }

    return dayData;
  }

  getWorkshopTopOffset(
    workshopStartHour: number,
    workshopStartMinutes: number,
    earliestMinutes: number,
    timeLineList: WorkshopTimelineItem[]
  ): number {
    let topOffset = workshopStartMinutes - earliestMinutes;

    for (let i = 0; i < timeLineList.length; i++) {
      let timeLineItem: WorkshopTimelineItem = timeLineList[i];
      if (timeLineItem.hourStart === workshopStartHour) break;

      if (timeLineItem.hourEnd) {
        topOffset = topOffset - (timeLineItem.hourEnd - timeLineItem.hourStart) * 60;
      }
    }

    return (topOffset / 60) * this.hourHeighInPixels;
  }

  getRelativeHourHeight(delta: number): number {
    return (delta / 60) * this.hourHeighInPixels; // divede by sixty to get hour then turn it to pixels
  }

  getWorkshopSidePosition(workshop, rooms, side: 'left' | 'right') {
    const noOfRoomsThisDay = rooms.length;

    let response = '0';

    rooms.forEach((room, index) => {
      room.workshops.forEach(workshopInDay => {
        if (workshopInDay === workshop) {
          if (side === 'left') {
            response = this.roomWidth * index + 5 + 'px';
          } else if (side === 'right') {
            response = this.roomWidth * (noOfRoomsThisDay - index - 1) + 'px';
          }
        }
      });
    });

    return response;
  }

  setWorkshopsSidePosition(activeWorkshopId) {
    for (const dayKey in this.workshopsByDates) {
      if (this.workshopsByDates.hasOwnProperty(dayKey)) {
        const day = this.workshopsByDates[dayKey];
        this.isMobile = this.helperService.isMobile();

        day.todayWorkshops.forEach(workshop => {
          if (activeWorkshopId !== null && activeWorkshopId === workshop.workshopId && this.isMobile) {
            workshop.styles.left = 0;
            workshop.styles.right = 0;
            workshop.styles.zIndex = 23;
            //workshop.styles.minHeight = '300px'; commented out because it caused smaller images to scale according to minHeight
            workshop.styles.maxWidth = '100%';
          } else {
            workshop.styles.left = this.getWorkshopSidePosition(workshop, day.rooms, 'left');
            workshop.styles.right = this.getWorkshopSidePosition(workshop, day.rooms, 'right');

            workshop.styles.zIndex = 22;
            //workshop.styles.minHeight = workshop.styles.height;
            workshop.styles.width = '200px';
          }
        });
      }
    }
  }

  scrollHorizontally(index: number) {
    const workshopWrapper = document.getElementById('workshopWrapper' + index);

    if (!workshopWrapper) return;

    let workshopRoom = document.getElementById('rooms' + index);

    const fakeScrollBarWrapper = document.getElementById('fakeScrollbarWrapper' + index);
    const fakeScrollBar = document.getElementById('fakeScrollbar' + index);
    const timeColumnWidth = 50; //Configured width in css
    const timeColumnWidthOffset = workshopWrapper.offsetWidth - workshopRoom.offsetWidth; // Real rendered width

    if (workshopRoom.scrollWidth > workshopRoom.offsetWidth && !this.isMobile && !this.elementHasEventListener) {
      if (!this.helperService.isMobile()) {
        fakeScrollBar.style.display = 'block';
      }

      let fakeScrollBarChildDiv = fakeScrollBar.children[0];
      this.renderer.setElementStyle(
        fakeScrollBarChildDiv,
        'width',
        (workshopRoom.scrollWidth - timeColumnWidth).toString() + 'px'
      );
      // Move fake scrollbar right to be same width as content scrollbar due to bar synchronization
      this.renderer.setElementStyle(fakeScrollBarWrapper, 'margin-left', timeColumnWidthOffset.toString() + 'px');

      workshopRoom.addEventListener(
        'mousewheel',
        event => {
          const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
          workshopRoom.scrollLeft -= delta * 50;
          fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
          event.preventDefault();
        },
        false
      );

      workshopRoom.addEventListener(
        'scroll',
        event => {
          fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
          event.preventDefault();
        },
        false
      );
      this.elementHasEventListener = true;
    }
  }

  scrollWorkshopRoomWithFakeScrollbar(fakeScrollElement, workshopRoom) {
    this.scrollLeft.next({
      fakeScrollLeft: fakeScrollElement.scrollLeft,
      el: workshopRoom
    });
  }

  scrollFakeScrollBar(fakeScrollElement, workshopRoom) {
    this.scrollLeft.next({
      fakeScrollLeft: workshopRoom.scrollLeft,
      el: fakeScrollElement
    });
  }

  setValidation(isValid: boolean, validationMessage: string) {
    if (isValid) {
      this.formsService.removeStepValidationFeedback(this.stepsFormsActionName, 'workshop');
    } else {
      this.formsService.addStepValidationFeedback(this.stepsFormsActionName, 'workshop', validationMessage);
    }

    this.formsService.setFormValidity(isValid, null, this.stepsFormsActionName);
  }

  toggleDetail(activeWorkshopId) {
    this.toggledWorkshop = this.workshops.find(workshop => workshop.workshopId === activeWorkshopId);

    if (!!activeWorkshopId) {
      this.toggledWorkshop.top = window.pageYOffset + 100;
    }

    this.activeWorkshopId = this.activeWorkshopId === null ? activeWorkshopId : null;
    this.setWorkshopsSidePosition(this.activeWorkshopId);
    this.toggleDetailWindow = !this.toggleDetailWindow;

    if (this.toggledWorkshop) {
      this.workshopDetailViewModel$ = this.workshopService.getWorkshopDetailViewModel$(this.toggledWorkshop.workshopId);
    }
  }

  toggleWorkshopAccordion($event: MouseEvent, workshop: Workshop) {
    $event.stopPropagation();

    workshop.expanded = !workshop.expanded;
  }

  navigateToDay(day: number) {
    const element = document.getElementById(this.generateDayId(day));

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  generateDayId(day: number) {
    return `day${day}`;
  }

  getScrollY() {
    return window.pageYOffset || document.body.scrollTop;
  }

  scrollDown() {
    let target = this.getScrollY() + Math.max(document.documentElement.clientHeight, window.innerHeight || 250);

    this.scrollToTarget(target);
  }

  scrollUp() {
    let target = this.getScrollY() - Math.max(document.documentElement.clientHeight, window.innerHeight || 250);

    this.scrollToTarget(target);
  }

  scrollToTarget(target: number) {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: target,
        behavior: 'smooth'
      });
    } else {
      window.scroll(window.pageXOffset, target);
    }
  }

  convertFromTime(date: any): Date {
    if (date instanceof Date) {
      let text = `${('0' + date.getHours()).substr(-2)}:${('0' + date.getMinutes()).substr(-2)}`;
      let dateString = `2020-01-01T${text}:00.000Z`;
      return new Date(dateString);
    }

    return new Date(`${date}.000Z`);
  }
}
