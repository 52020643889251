/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../web-shop-confirmation.component.scss.shim.ngstyle";
import * as i1 from "./confirmation-workshop-prices.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i6 from "../../../shared/pipes/format-price/format-price.pipe";
import * as i7 from "./confirmation-workshop-prices.component";
var styles_ConfirmationWorkshopPricesComponent = [i0.styles, i1.styles];
var RenderType_ConfirmationWorkshopPricesComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationWorkshopPricesComponent, data: {} });
export { RenderType_ConfirmationWorkshopPricesComponent as RenderType_ConfirmationWorkshopPricesComponent };
function View_ConfirmationWorkshopPricesComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 12, "div", [["class", "ticket-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 3, "span", [["class", "title black"]], null, null, null, null, null)), (_l()(), i2.ɵted(3, null, [" ", " "])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), i2.ɵppd(5, 1), (_l()(), i2.ɵeld(6, 0, null, null, 1, "span", [["class", "count black"]], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, [" ", "x "])), (_l()(), i2.ɵeld(8, 0, null, null, 2, "span", [["class", "price black"]], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, [" ", " "])), i2.ɵppd(10, 2), (_l()(), i2.ɵeld(11, 0, null, null, 2, "span", [["class", "per-type-price price"]], null, null, null, null, null)), (_l()(), i2.ɵted(12, null, [" ", " "])), i2.ɵppd(13, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i2.ɵnov(_v.parent.parent, 0), i2.ɵunv(_v, 3, 0, i2.ɵnov(_v, 4).transform(_v.parent.context.$implicit.workshopName)))); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.count; _ck(_v, 7, 0, currVal_1); var currVal_2 = i2.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i2.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.price, _co.currencySettings)); _ck(_v, 9, 0, currVal_2); var currVal_3 = i2.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i2.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.totalPrice, _co.currencySettings)); _ck(_v, 12, 0, currVal_3); }); }
function View_ConfirmationWorkshopPricesComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ConfirmationWorkshopPricesComponent_2)), i2.ɵdid(2, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.totalPrice; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ConfirmationWorkshopPricesComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i5.HideTranslationPipe, []), i2.ɵpid(0, i6.FormatPricePipe, []), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ConfirmationWorkshopPricesComponent_1)), i2.ɵdid(3, 278528, null, 0, i4.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmationTariff.workshops; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ConfirmationWorkshopPricesComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-confirmation-workshop-prices", [], null, null, null, View_ConfirmationWorkshopPricesComponent_0, RenderType_ConfirmationWorkshopPricesComponent)), i2.ɵdid(1, 114688, null, 0, i7.ConfirmationWorkshopPricesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationWorkshopPricesComponentNgFactory = i2.ɵccf("app-confirmation-workshop-prices", i7.ConfirmationWorkshopPricesComponent, View_ConfirmationWorkshopPricesComponent_Host_0, { confirmationTariff: "confirmationTariff", currencySettings: "currencySettings" }, {}, []);
export { ConfirmationWorkshopPricesComponentNgFactory as ConfirmationWorkshopPricesComponentNgFactory };
