import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, getUserAccountTicketLimitCheck } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import {
  BookingProductType,
  BookingTariff,
  isBookingProductTypePackage,
  isBookingProductTypeTariff
} from '@products/models/booking.model';
import {
  AddAvailableTariffs,
  AvailableTariffs,
  ValidateDailyTicketPerEmailLimitBody,
  ValidateDailyTicketPerEmailLimitResult
} from '@products/models/tariff-status.model';
import { Tariff } from '@products/models/tariff.model';
import { RemoveVoucher } from '@products/models/voucher.model';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { getBookedVoucherTariffByVoucherCode } from '@store/products/booking/booking.selectors';
import { GetUserAccountTariffsLimitStatus } from '@store/products/status/tariff/tariff.actions';
import { Observable, of } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TariffStatusService {
  constructor(private store: Store<State>, private http: HttpClient, private helperService: HelperService) {}

  checkDailyTicketPerEmailLimit$(
    validateDailyTicketPerEmailLimitBody: ValidateDailyTicketPerEmailLimitBody
  ): Observable<ValidateDailyTicketPerEmailLimitResult> {
    return this.http.post<ValidateDailyTicketPerEmailLimitResult>(
      `${environment.protocol}${environment.webApiUrl}/order/validators/daily-ticket-per-email-limit`,
      validateDailyTicketPerEmailLimitBody
    );
  }

  getTicketLimitPerUserAccount$(eventId: number): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params.append('sr', `${this.helperService.isSelfregistration()}`);

    return this.http.get<number>(
      `${environment.protocol}${environment.webApiUrl}/tickets/${eventId}/ticket-limit-per-user-account`,
      { params }
    );
  }

  getAndSetUserAccountTariffLimit(): void {
    this.store
      .pipe(
        select(getUserAccountTicketLimitCheck),
        first(({ checkUserAccountTicketLimit }) => checkUserAccountTicketLimit)
      )
      .subscribe(({ exhibitionId }) => this.store.dispatch(new GetUserAccountTariffsLimitStatus(exhibitionId)));
  }

  mapTariffsToAvailableTariffs(tariffs: Tariff[]): AvailableTariffs {
    const availableTariffs: AvailableTariffs = {};

    tariffs.forEach(({ ticketPersonId, availableTickets }) => (availableTariffs[ticketPersonId] = availableTickets));

    return availableTariffs;
  }

  mapBookedProductTypesToAvailableTariffs(bookingProductTypes: BookingProductType[]): AvailableTariffs {
    const availableTariffs: AvailableTariffs = {};

    bookingProductTypes.forEach(bookingProductType => {
      if (isBookingProductTypeTariff(bookingProductType)) {
        bookingProductType.tariffs.forEach(
          bookingTariff => (availableTariffs[bookingTariff.ticketPersonId] = bookingTariff.availableTickets)
        );
      } else if (isBookingProductTypePackage(bookingProductType)) {
        bookingProductType.productTypes.forEach(bookingPackageProductType => {
          if (isBookingProductTypeTariff(bookingPackageProductType)) {
            bookingPackageProductType.tariffs.forEach(
              bookingPackageTariff =>
                (availableTariffs[bookingPackageTariff.ticketPersonId] = bookingPackageTariff.availableTickets)
            );
          }
        });
      }
    });

    return availableTariffs;
  }

  mapRemoveVoucherToAddAvailableTariffs$(removeVoucher: RemoveVoucher): Observable<AddAvailableTariffs> {
    return of(removeVoucher).pipe(
      withLatestFrom(this.store.pipe(select(getBookedVoucherTariffByVoucherCode(removeVoucher.voucherCode)))),
      map(([_, bookedVoucherTariff]) =>
        !!bookedVoucherTariff ? { [bookedVoucherTariff.ticketPersonId]: bookedVoucherTariff.count } : {}
      )
    );
  }

  mapRemovedBookingProductTypesToAvailableTariffsAfterAnonymousVoucherRedeem(
    removedBookingProductTypes: BookingProductType[],
    bookedTariffs: BookingTariff[],
    currentAvailableTariffs: AvailableTariffs
  ): AvailableTariffs {
    const availableTariffs: AvailableTariffs = {};

    const setAvailableTariffs = (removedBookingTariff: BookingTariff) => {
      const bookedTariffRemoved = bookedTariffs.find(
        ({ ticketTypeId, ticketPersonId, voucherCode, packageNumber, packageIndex }) =>
          removedBookingTariff.ticketTypeId === ticketTypeId &&
          removedBookingTariff.ticketPersonId === ticketPersonId &&
          removedBookingTariff.voucherCode === voucherCode &&
          removedBookingTariff.packageNumber === packageNumber &&
          removedBookingTariff.packageIndex === packageIndex
      );

      if (bookedTariffRemoved) {
        const { ticketPersonId, count } = bookedTariffRemoved;

        if (!availableTariffs[ticketPersonId]) {
          availableTariffs[ticketPersonId] = currentAvailableTariffs[ticketPersonId] + count;
        } else {
          availableTariffs[ticketPersonId] += count;
        }
      }
    };

    removedBookingProductTypes.forEach(removedBookingProductType => {
      if (isBookingProductTypeTariff(removedBookingProductType)) {
        removedBookingProductType.tariffs.forEach(setAvailableTariffs);
      } else if (isBookingProductTypePackage(removedBookingProductType)) {
        removedBookingProductType.productTypes.forEach(bookedPackageProductType => {
          if (isBookingProductTypeTariff(bookedPackageProductType)) {
            bookedPackageProductType.tariffs.forEach(setAvailableTariffs);
          }
        });
      }
    });

    return availableTariffs;
  }
}
