import { BookingPackageType } from '@products/models/booking.model';
import { PackageStatusState } from '@products/models/package-status.model';
import { ActionTypes as ProductActionTypes, Actions as ProductActions } from '@store/products/product.actions';
import {
  ActionTypes as PackageActionTypes,
  Actions as PackageActions
} from '@store/products/status/package/package.actions';

export { PackageStatusState as State };

export const initialState: PackageStatusState = {
  lastPackageIndex: 0,
  isPackageLoading: false
};

export const packageStatusReducer = (
  state: PackageStatusState = initialState,
  action: PackageActions | ProductActions
): PackageStatusState => {
  switch (action.type) {
    case PackageActionTypes.SET_INITIAL_PACKAGE_INDEX_STATUS: {
      const initialPackageIndex: number = action.payload;

      return {
        ...state,
        lastPackageIndex: initialPackageIndex
      };
    }

    case PackageActionTypes.SET_PACKAGE_INDEX_STATUS: {
      const bookingPackageTypes: BookingPackageType[] = action.payload;

      if (bookingPackageTypes.length) {
        const lastPackageIndex = Math.max(
          ...bookingPackageTypes.map(bookingPackageType => bookingPackageType.packageIndex),
          state.lastPackageIndex
        );

        return { ...state, lastPackageIndex };
      }

      return state;
    }

    case PackageActionTypes.SET_IS_PACKAGE_LOADING_STATUS: {
      const isPackageLoading: boolean = action.payload;

      return {
        ...state,
        isPackageLoading
      };
    }

    case PackageActionTypes.RESET_REDUCER:
    case ProductActionTypes.PRODUCTS_RESET_STATUS_REDUCER: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
