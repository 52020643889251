import { SendingOptionModel } from '@root/src/app/_pages/products/models/product-selection.model';
import { TicketSendingOptions } from '../../../../_pages/products/models/tariff.model';
import { InputBase } from '../../../forms/inputs/input-base.class';
import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { DateInput } from '../../../forms/inputs/input-date.class';
import { DropdownInput } from '../../../forms/inputs/input-dropdown.class';
import { TextInput } from '../../../forms/inputs/input-text.class';
import { TextOrDropdownInputTypes } from '../../helpers/helper.interface';
import { ExhibitionSettingModel } from '../customization.interfaces';
import { SelectOption } from './../../exhibition/exhibition.interface';

function isRequired(mandatoryBySettings, onlyMailMandatory) {
  if (onlyMailMandatory) {
    return false;
  } else {
    return mandatoryBySettings;
  }
}

export const prepareTicketHolderData = (
  settings: ExhibitionSettingModel,
  selectedTicketSendingOptions: string,
  titles: SelectOption[],
  professions: SelectOption[],
  departments: SelectOption[],
  occupationalGroups: SelectOption[]
): InputBase<any>[] => {
  const inputs = [];
  const ticketHolderSettings = settings.ticketOwnerSettings.fieldSettings;
  const addressAutocompleteSettings = settings.isGoogleAutocompleteEnabled;
  const optionRetrievalLink = selectedTicketSendingOptions === TicketSendingOptions.TicketRetrievalLink;

  if (ticketHolderSettings.Salutation.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'gender',
        label: 'personalize.holder.salutation',
        options: [
          { key: 'male', value: 'gender.male', translate: true },
          { key: 'female', value: 'gender.female', translate: true },
          { key: 'diverse', value: 'gender.diverse', translate: true },
          { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
        ],
        translate: true,
        order: ticketHolderSettings.Salutation.order,
        required: ticketHolderSettings.Salutation.isMandatory,
        cssClass: 'col-lg-2 col-md-3 col-sm-6 column-grow-1'
      })
    );
  }

  if (ticketHolderSettings.PersonTitle.isVisible) {
    if (titles.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'personalize.holder.title',
          order: ticketHolderSettings.PersonTitle.order,
          translate: true,
          required: ticketHolderSettings.PersonTitle.isMandatory,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          options: titles
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'personalize.holder.title',
          order: ticketHolderSettings.PersonTitle.order,
          translate: true,
          required: ticketHolderSettings.PersonTitle.isMandatory,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          maxLengthValidation: 50
        })
      );
    }
  }

  if (ticketHolderSettings.FirstName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'firstName',
        label: 'personalize.holder.first-name',
        order: ticketHolderSettings.FirstName.order,
        translate: true,
        required: ticketHolderSettings.FirstName.isMandatory || optionRetrievalLink,
        cssClass: 'col-lg-4 col-md-3 col-sm-6 column-grow-1',
        //minLengthValidation: 3,
        maxLengthValidation: 50
      })
    );
  }

  if (ticketHolderSettings.LastName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'lastName',
        label: 'personalize.holder.last-name',
        order: ticketHolderSettings.LastName.order,
        translate: true,
        required: ticketHolderSettings.LastName.isMandatory || optionRetrievalLink,
        cssClass: 'col-lg-4 col-md-4 col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (ticketHolderSettings.Company.isVisible) {
    inputs.push(
      new TextInput({
        key: 'company',
        label: 'personalize.holder.company',
        order: ticketHolderSettings.Company.order,
        translate: true,
        required: ticketHolderSettings.Company.isMandatory,
        cssClass: 'col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (ticketHolderSettings.PersonFunction.isVisible) {
    if (professions.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'personalize.buyer-info.position',
          order: ticketHolderSettings.PersonFunction.order,
          translate: true,
          required: ticketHolderSettings.PersonFunction.isMandatory,
          cssClass: 'col-sm-6 column-grow-1',
          options: professions
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'personalize.holder.position',
          order: ticketHolderSettings.PersonFunction.order,
          translate: true,
          required: ticketHolderSettings.PersonFunction.isMandatory,

          cssClass: 'col-sm-6 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (ticketHolderSettings.Department.isVisible) {
    if (departments.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'personalize.holder.department',
          order: ticketHolderSettings.Department.order,
          translate: true,
          required: ticketHolderSettings.Department.isMandatory,
          cssClass: 'col-sm-6 column-grow-1',
          options: departments
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'personalize.holder.department',
          order: ticketHolderSettings.Department.order,
          translate: true,
          required: ticketHolderSettings.Department.isMandatory,
          cssClass: 'col-sm-6 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (ticketHolderSettings.OccupationalGroup.isVisible) {
    if (occupationalGroups.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'personalize.holder.occupationalGroup',
          order: ticketHolderSettings.OccupationalGroup.order,
          translate: true,
          required: ticketHolderSettings.OccupationalGroup.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          options: occupationalGroups
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'personalize.holder.occupationalGroup',
          order: ticketHolderSettings.OccupationalGroup.order,
          translate: true,
          required: ticketHolderSettings.OccupationalGroup.isMandatory,
          cssClass: 'col-sm-6 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (ticketHolderSettings.Country.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'country',
        label: 'personalize.holder.country',
        options: [],
        order: ticketHolderSettings.Country.order,
        translate: true,
        required: ticketHolderSettings.Country.isMandatory,

        cssClass: 'col-sm-12 column-grow-1'
      })
    );
  }

  if (
    addressAutocompleteSettings &&
    ticketHolderSettings.SearchAddress.isVisible
  ) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'personalize.holder.address',
        order: ticketHolderSettings.SearchAddress.order,
        translate: true,
        required: false, //ticketHolderSettings.SearchAddress.isMandatory
        cssClass: 'col-md-12 col-lg-6 column-grow-1'
      })
    );
  }
  if (ticketHolderSettings.Street.isVisible) {
    inputs.push(
      new TextInput({
        key: 'street',
        label: 'personalize.holder.street',
        order: ticketHolderSettings.Street.order,
        translate: true,
        required: ticketHolderSettings.Street.isMandatory,

        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (ticketHolderSettings.ZipCode.isVisible) {
    inputs.push(
      new TextInput({
        key: 'zipCode',
        label: 'personalize.holder.zip-code',
        order: ticketHolderSettings.ZipCode.order,
        translate: true,
        required: ticketHolderSettings.ZipCode.isMandatory,
        cssClass: 'col-sm-4 column-grow-1',
        zipcodeValidation: true,
        maxLengthValidation: 12,
        zipCodeCitiesTag: 'forms.ticket-holder-data.prepare-ticket-holder-data'
      })
    );
  }

  if (ticketHolderSettings.City.isVisible) {
    inputs.push(
      new TextInput({
        key: 'city',
        label: 'personalize.holder.city',
        order: ticketHolderSettings.City.order,
        translate: true,
        required: ticketHolderSettings.City.isMandatory,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (ticketHolderSettings.Phone.isVisible) {
    inputs.push(
      new TextInput({
        key: 'telephone',
        label: 'personalize.holder.phone',
        order: ticketHolderSettings.Phone.order,
        translate: true,
        required: ticketHolderSettings.Phone.isMandatory,
        cssClass: 'col-md-6 column-grow-1',
        phoneValidation: settings
          ? !settings.isTelephoneValidationDisabled
          : false
      })
    );
  }

  const isEmailAndConfirmEmailMandatory =
    (settings.emailIsMandatoryForMobileTicket &&
      selectedTicketSendingOptions === TicketSendingOptions.MobilePerOwner) ||
    selectedTicketSendingOptions === TicketSendingOptions.TicketRetrievalLink;

  //If Email field is visible or mandatory (isEmailAndConfirmEmailMandatory), we have to add it and make it required.
  if (ticketHolderSettings.Email.isVisible || isEmailAndConfirmEmailMandatory) {
    inputs.push(
      new TextInput({
        key: 'email',
        label: 'personalize.holder.email',
        order: ticketHolderSettings.Email.order,
        translate: true,
        required: isEmailAndConfirmEmailMandatory ? isEmailAndConfirmEmailMandatory : ticketHolderSettings.Email.isMandatory,
        cssClass: 'col-md-6 column-grow-1',
        emailValidation: true,
        type: 'email'
      })
    );
  }

  //If MailConfirm field is visible or mandatory (isEmailAndConfirmEmailMandatory), we have to add it and make it required.
  if (ticketHolderSettings.MailConfirm.isVisible || isEmailAndConfirmEmailMandatory) {
    inputs.push(
      new TextInput({
        key: 'verifyEmail',
        label: 'personalize.holder.email-verify',
        order: ticketHolderSettings.MailConfirm.order,
        translate: true,
        required: isEmailAndConfirmEmailMandatory ? isEmailAndConfirmEmailMandatory : ticketHolderSettings.MailConfirm.isMandatory,
        cssClass: 'col-md-6 column-grow-1',
        emailValidation: true,
        sameAsValidation: 'email',
        type: 'email'
      })
    );
  }

  let sendToOwner: boolean = true;
  let sendToOwnerHidden: boolean = true;

  const isAllToBuyer: boolean = selectedTicketSendingOptions === TicketSendingOptions.AllToBuyer;

  const isNormalPerOwner: boolean = selectedTicketSendingOptions === TicketSendingOptions.NormalPerOwner;

  const isTicketRetrivalLink: boolean = selectedTicketSendingOptions === TicketSendingOptions.TicketRetrievalLink &&
    settings.ticketSelectionDeliveryTypes.some(option => option.value === TicketSendingOptions.TicketRetrievalLink && option.isEnabled);

  const isMobilePerOwner: boolean = selectedTicketSendingOptions === TicketSendingOptions.MobilePerOwner &&
    settings.ticketSelectionDeliveryTypes.some(option => option.value === TicketSendingOptions.MobilePerOwner && option.isEnabled);

  if (!isAllToBuyer && !isNormalPerOwner) {

    sendToOwner = isTicketRetrivalLink || isMobilePerOwner;

    for (let i = 0; i < settings.ticketSelectionDeliveryTypes.length; i++) {
      const sendingOption =  settings.ticketSelectionDeliveryTypes[i];
      if (sendingOption.isEnabled == true) {
        if (isMobilePerOwner && sendingOption.value === TicketSendingOptions.MobilePerBuyer) {
          sendToOwner = sendToOwnerHidden = false;
          break;
        } else if (isTicketRetrivalLink && sendingOption.value === TicketSendingOptions.TicketRetrievalLinkBuyer) {
          sendToOwner = sendToOwnerHidden = false;
          break;
        }
      }
    }
  }

  if (isAllToBuyer) {
    sendToOwner = false;
  }

  if (ticketHolderSettings.DateOfBirth.isVisible) {
    inputs.push(
      new DateInput({
        key: 'dateOfBirth',
        label: 'personalize.holder.dateOfBirth',
        order: ticketHolderSettings.DateOfBirth.order,
        translate: true,
        required: ticketHolderSettings.DateOfBirth.isMandatory,
        cssClass: 'col-sm-5 column-grow-1'
      })
    );
  }

  // get highest order number from already pushed input fields to inputs array, after that we can safely push all fields that do not get order from API
  let highestOrder = inputs.length > 0 ? inputs.reduce((max, input) => max.order > input.order ? max : input).order : 0;

  //Make room for Email and VerifyEmail fields in case they were at the bottom of the Order in Admin and they were not added when creating a ticket holder.
  const emailField = inputs.find(item => item.key === 'email');
  const emailOrder = ticketHolderSettings.Email.order;
  const verifyEmailOrder = ticketHolderSettings.MailConfirm.order;

  //If Email field doesn't exist, we hide sendtoowner field.
  if (!emailField) {
    sendToOwnerHidden = true;
  }

  //We set the highest order to verifyEmailOrder because usually verifyEmail comes after email and has a higher order.
  if (highestOrder < verifyEmailOrder) {
    highestOrder = verifyEmailOrder;
  }

  //In case email came after verifyEmail, we have to set the highest order to emailOrder
  if (highestOrder < emailOrder) {
    highestOrder = emailOrder;
  }

  inputs.push(
    new CheckboxInput({
      key: 'sendtoowner',
      label: '',
      order: ++highestOrder,
      translate: true,
      required: false,
      hidden: sendToOwnerHidden,
      options: [
        {
          key: 'toowner',
          value: sendToOwner,
          icon: selectedTicketSendingOptions,
          label: `personalize.sending-options.${selectedTicketSendingOptions.toLowerCase()}`,
          cssClass: 'col-sm-12',
          required: false
        }
      ],
      cssClass: 'col-sm-12'
    })
  );

  const sendingOptions = settings.ticketSelectionDeliveryTypes.map(
    (option: SendingOptionModel) => {
      return {
        key: option.value,
        value: option.value,
        translate: true
      };
    }
  );

  inputs.push(
    new DropdownInput({
      key: 'sendingOption',
      options: sendingOptions,
      translate: true,
      order: ++highestOrder,
      value: selectedTicketSendingOptions,
      required: false,
      hidden: true,
      cssClass: ''
    })
  );

  return inputs.sort((a, b) => a.order - b.order);
};
