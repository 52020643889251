import * as fromRoot from '../../../app.reducer';

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { first, switchMap } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { FormsService } from '../../../shared/forms/forms.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import {
  ExhibitionSettingModel,
  QuestionnaireDataInput
} from '../../../shared/services-with-reducers/customization/customization.interfaces';
import {
  GetAllDepartments,
  GetAllOccupationalGroups,
  GetAllProfessions,
  GetAllTitles
} from '../../../shared/services-with-reducers/exhibition/exhibition.actions';
import { SelectOption } from '../../../shared/services-with-reducers/exhibition/exhibition.interface';
import { GetAllCountriesAction } from '../../../shared/services-with-reducers/helpers/helper.actions';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { SetTicketHolderInputs } from '../../../shared/services-with-reducers/products/holder/holder.actions';
import { getTicketHolder, getTicketHolderInputs } from '../../../shared/services-with-reducers/products/holder/holder.selectors';
import { TicketHolder } from '../../products/models/holder.model';
import { prepareTicketHolderDownloadData } from './form-inputs.ticket-holder-download';

@Component({
  moduleId: module.id,
  selector: 'app-download-ticket-holder',
  templateUrl: './download-ticket-holder.component.html',
  styleUrls: ['./download-ticket-holder.component.scss']
})
export class DownloadTicketHolderComponent implements OnInit, OnDestroy {
  @Output() isValid = new EventEmitter<Object>();

  loaded: boolean = false;
  actionName = ['ticket-holder-download-data'];

  form: FormGroup;
  inputs: InputBase<any>[];

  private readonly subscriptions = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private formsService: FormsService,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {
    this.store.dispatch(new GetAllCountriesAction());
    this.store.dispatch(new GetAllTitles());
    this.store.dispatch(new GetAllProfessions());
    this.store.dispatch(new GetAllDepartments());
    this.store.dispatch(new GetAllOccupationalGroups());
  }

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(fromRoot.getExhibitionSettings)),
      this.store.pipe(select(getTicketHolder)),
      this.store.pipe(select(fromRoot.getTitles)),
      this.store.pipe(select(fromRoot.getAllTitles)),
      this.store.pipe(select(fromRoot.getProfessions)),
      this.store.pipe(select(fromRoot.getAllProfessions)),
      this.store.pipe(select(fromRoot.getDepartments)),
      this.store.pipe(select(fromRoot.getAllDepartments)),
      this.store.pipe(select(fromRoot.getOccupationalGroups)),
      this.store.pipe(select(fromRoot.getAllOccupationalGroups))
    ])
    .pipe(
      first(([
        settings,
        ticketHolder,
        titles,
        allTitles,
        professions,
        allProfessions,
        departments,
        allDepartments,
        occupationalGroups,
        allOccupationalGroups
      ]) =>
        !!settings &&
        !!ticketHolder &&
        !!titles &&
        !!allTitles &&
        !!professions &&
        !!allProfessions &&
        !!departments &&
        !!allDepartments &&
        !!occupationalGroups &&
        !!allOccupationalGroups
      ),
      switchMap(([
        settings,
        ticketHolder,
        titles,
        allTitles,
        professions,
        allProfessions,
        departments,
        allDepartments,
        occupationalGroups,
        allOccupationalGroups
      ]: [
        ExhibitionSettingModel,
        TicketHolder,
        SelectOption[],
        QuestionnaireDataInput[],
        SelectOption[],
        QuestionnaireDataInput[],
        SelectOption[],
        QuestionnaireDataInput[],
        SelectOption[],
        QuestionnaireDataInput[]
      ]) =>
        prepareTicketHolderDownloadData(
          settings,
          ticketHolder,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups,
          this.translateService
        )
      ),
      first()
    )
    .subscribe(inputs => {
      this.store.dispatch(new SetTicketHolderInputs(inputs));
      this.loaded = true;
    });

    this.subscriptions.add(
      this.store.pipe(select(getTicketHolderInputs)).subscribe((inputs: InputBase<any>[]) => {
        this.inputs = inputs;
        this.form = this.formsService.toFormGroup(inputs, this.actionName);

        // check the validity on first time and set it
        const validationCallback = () => {
          this.isValid.emit({
            formName: 'ticketholder',
            valid: !this.form.invalid,
            inputs: this.inputs,
            form: this.form
          });
        };
        this.helperService.triggerCallbackOnceFormValidationIsDone(
          this.form,
          validationCallback
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public inputsChanged = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this.formsService.updateInputs(this.inputs, inputs);
    this.store.dispatch(new SetTicketHolderInputs(this.inputs));

    this.isValid.emit({
      formName: 'ticketholder',
      valid: !this.form.invalid,
      inputs: this.inputs,
      form: this.form
    });

    if (rerender) {
      this.form = this.formsService.toFormGroup(inputs, this.actionName);
    }
  };
}
